/**
 * Конструктор для виджета текста
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/text_autosize.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const TextAutosize = ControlClass.extend({
  name: 'text_autosize', // должно совпадать с классом вьюхи

  className: 'control text_autosize',

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_autosize'];

    this.initControl(params);
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-text_autosize');
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    _.each(
      this.models,
      function(m) {
        this.listenTo(m, 'change:autosize', this.updateState);
      }.bind(this)
    );
  },

  unBindLogic: function() {
    /*this.$panel.off('click', '.left', this.left);
    this.$panel.off('click', '.center', this.center);
    this.$panel.off('click', '.right', this.right);
    this.$panel.off('click', '.justify', this.justify);*/
    /*

    this.blocks && this.blocks[0].off('selection_styles_changed', this.selectionStylesChanged);*/
  },

  onClick: function() {
    var autosize = this.models[0].get('autosize');
    this.models[0].save('autosize', !autosize);
  },

  updateState: function() {
    var autosize = !!this.models[0].get('autosize');
    this.$el.toggleClass('autosize', autosize);
    this.$('.icon').attr('data-alt', autosize ? 'Auto-size on' : 'Auto-size off');
  },

  render: function() {
    ControlClass.prototype.render.apply(this, arguments);
    this.updateState();
  },
});

export default TextAutosize;
