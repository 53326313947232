/**
 * Набор полезных функций для работы с видео
 */
import $ from '@rm/jquery';
import { Utils } from './utils';

const VideoUtils = {
  // определяем aspect ratio видео по постеру
  // просто находим размеры черных полос по краям
  getVideoAspectFromPoster: function(posterUrl, cb) {
    // возвращаем объект через который можно отменить дальнейшее его исполнение
    var returnObject = {
      abort: function() {
        pic.onload = null;
        pic.onerror = null;
      },
    };

    var result = {
      aspect_poster: null,
      aspect_real: null,
    };

    if (!posterUrl) {
      cb(result);
      return returnObject;
    }

    var pic = new Image(),
      $canvas,
      ctx,
      w,
      h,
      ow,
      oh;

    pic.onload = function() {
      ow = pic.width;
      oh = pic.height;

      // уменьшаем картинку чтобы ускорить вычисление
      w = 200;
      h = Math.floor(w * (oh / ow));

      $canvas = $('<canvas id="qqq" width="' + w + '" height="' + h + '"></canvas>')
        .css('visibility', 'hidden')
        .appendTo('body');

      ctx = $canvas[0].getContext('2d');

      ctx.drawImage(pic, 0, 0, w, h);

      var dat = ctx.getImageData(0, 0, w, h),
        t = 0,
        b = 0,
        l = 0,
        r = 0,
        x,
        y,
        res;

      // смотрим высоту черной полосы сверху
      for (y = 0; y <= h / 2; y++) {
        var res = getLineInfo(y, 'row');

        // если строка контрастная, а предыдущая или предпредыдущая черная (или если это первая строка)
        // тогда считаем что нашли границу черной полосы
        if (res.isContrast) {
          if (y <= 1 || getLineInfo(y - 1, 'row').isBlack || getLineInfo(y - 2, 'row').isBlack) t = y;
          break;
        }
      }

      // смотрим высоту черной полосы снизу
      for (y = h - 1; y > h / 2; y--) {
        var res = getLineInfo(y, 'row');

        // если строка контрастная, а предыдущая или предпредыдущая черная (или если это первая строка)
        // тогда считаем что нашли границу черной полосы
        if (res.isContrast) {
          if (y >= h - 2 || getLineInfo(y + 1, 'row').isBlack || getLineInfo(y + 2, 'row').isBlack) b = h - 1 - y;
          break;
        }
      }

      // смотрим размер черных полос по вертикали
      var vert = Math.max(t, b),
        horz = 0;

      // считаем что сверху-снизу нет черных полос
      if (!vert) {
        // смотрим ширину черной полосы слева
        for (x = 0; x <= w / 2; x++) {
          var res = getLineInfo(x, 'column');

          // если строка контрастная, а предыдущая или предпредыдущая черная (или если это первая строка)
          // тогда считаем что нашли границу черной полосы
          if (res.isContrast) {
            if (x <= 1 || getLineInfo(x - 1, 'column').isBlack || getLineInfo(x - 2, 'column').isBlack) l = x;
            break;
          }
        }

        // смотрим ширину черной полосы справа
        for (x = w - 1; x > w / 2; x--) {
          var res = getLineInfo(x, 'column');

          // если строка контрастная, а предыдущая или предпредыдущая черная (или если это первая строка)
          // тогда считаем что нашли границу черной полосы
          if (res.isContrast) {
            if (x >= w - 2 || getLineInfo(x + 1, 'column').isBlack || getLineInfo(x + 2, 'column').isBlack)
              r = w - 1 - x;
            break;
          }
        }

        horz = Math.max(r, l);
      }

      // барьеры безопасности на всякий случай
      if (vert > h / 3) vert = 0;
      if (horz > w / 3) horz = 0;

      // по умолчанию считаем аспект равным аспекту картинки
      var aspect = ow / oh;

      // считаем аспект с отрезаными полосами по вертикали или по горизонтали
      if (vert) {
        aspect = w / (h - vert * 2);
      } else if (horz) {
        aspect = (w - horz * 2) / h;
      }

      $canvas.remove();

      result.aspect_poster = ow / oh;
      result.aspect_real = aspect;

      cb(result);

      function getLineInfo(dim1, tp) {
        var dim2,
          ind,
          c1,
          c2,
          c3,
          dim2max = tp == 'row' ? w : h,
          c = 0,
          totalBlack = 0; // общее кол-во черных пикселей в линии

        var TRESHOLD = 5, // порог усредненого пиксела в строке, по которому мы считаем что строка вся черная
          NO_BLACK_TRESHOLD = 20, // порог любой из компонент цвета пиксела до которого мы считаем пиксел черным
          PIXEL_IS_NO_BLACK_PER_LINE_MAX = 3; // максимальное кол-во не черных пикселей в строке при котором строка целиком все еще считается черной
        for (dim2 = 0; dim2 < dim2max; dim2++) {
          ind = (tp == 'row' ? dim1 * w + dim2 : dim2 * w + dim1) << 2;
          c1 = dat.data[ind];
          c2 = dat.data[ind + 1];
          c3 = dat.data[ind + 2];
          c += c1 + c2 + c3;
          if (c1 < NO_BLACK_TRESHOLD && c2 < NO_BLACK_TRESHOLD && c3 < NO_BLACK_TRESHOLD) totalBlack++;
        }

        c /= 3 * dim2max; // усредняем цвет пиксела, 3 это усреднение компонент цвета

        return {
          isBlack: c < TRESHOLD && dim2max - totalBlack < PIXEL_IS_NO_BLACK_PER_LINE_MAX,
          isContrast: totalBlack < dim2max / 2, // считаем линию контрастной если в ней черных пикселей меньше чем на половину
        };
      }
    };

    pic.onerror = function() {
      cb(result);
    };

    pic.src = '/api/proxy/?url=' + encodeURIComponent(posterUrl);

    return returnObject;
  },

  // находим положение видео ифрейма внутри контейнера (речь о бг видео) так чтоюы не было видно черных полос, но было видно как можно больше видео, делаем fill в общем
  // а также, убираем:
  // у ютуба снизу рекламный баннер и лого 103px
  // у вимео контролы снизу 50px
  // у вимео контролы справа 46px
  setVideoPosition: function(params) {
    if (!params.aspect_poster || !params.$media || !params.$media.length) return;

    var tempW = params.$container.css('width'),
      tempH = params.$container.css('height');

    // если в полученных значениях нет px
    // значит элемент скрыт (или один из его родителей) и при этому у элемента не проставлены атрибуты размера явно, через инлай
    // в таком случае надо взять размеры из mag который передан и который хранит их у себя переситыва при любом ресайзе
    if (!/px/.test(tempW + tempH)) {
      if (!params.mag) return; // если mag не передан и больше узнать размеры не откуда
      var containerSize = params.mag.getContainerSizeCached(); // берем кешированную версию размеров контейнера
      tempW = containerSize.width;
      tempH = containerSize.height;
    } else {
      tempW = parseInt(tempW);
      tempH = parseInt(tempH);
    }

    var aspect_poster = params.aspect_poster,
      aspect_real = params.aspect_real || aspect_poster, // для старых фоновых видео нет данных по черным полосам (aspect_real), а также их нет для видео которы обычные, не бг (но там они и не нужны, там как раз нужен aspect_poster)
      dopBuffer = params.controls_remove ? 5 : 0, // это дополнительный буфер с каждой стороны контейнера, поскольку aspect_real рассчитывается приблизительно
      cw = tempW + dopBuffer * 2,
      ch = tempH + dopBuffer * 2,
      iw = cw, // дефолтные размеры ифрейма
      ih = ch;

    // если данных по аспектам нету, возвращаем положение видео совпадающее с размерами фрейма
    if (aspect_poster) {
      // смотрим размеры видимой области внутри самого видео (виртуальная сущность, потом надо будет на ее основе смотреть где расположены границы ифрейма)
      var vw, vh;

      // обрезать видео надо сверху-снизу
      if (cw / ch > aspect_real) {
        vw = cw;
        vh = vw / aspect_real;
      } else {
        // обрезать видео надо слева-справа
        vh = ch;
        vw = vh * aspect_real;
      }

      // теперь расчитываем размеры ифрейма так, чтобы видимой области внутри самого видео была размером vw на vh
      // если у видео черные полосы идут сверху-снизу
      if (aspect_poster < aspect_real) {
        iw = vw;
        ih = iw / aspect_poster;
      } else {
        ih = vh;
        iw = ih * aspect_poster;
      }
    }

    // теперь мы посмотрим на сколько нам еще надо увеличить размеры ифрейма
    // чтобы скрыть для ютуба рекламу и лого
    // для вимео котролы снизу и справа
    if (params.provider.toLowerCase() == 'vimeo' && params.controls_remove) {
      // у вимео контролы снизу 50px
      // у вимео контролы справа 46px
      var currentBottomPadding = (ih - ch) / 2,
        currentRightPadding = (iw - cw) / 2,
        minBottomPadding = 50,
        minRightPadding = 46,
        diffBottom = minBottomPadding - currentBottomPadding,
        diffRight = minRightPadding - currentRightPadding;

      // если на данный момент ифрейм выступает снизу контейнера менее чем на 50px
      // или справа менее чем на 46px
      // нам необходимо увеличить его настолько, чтобы и снизу и справа он вуступал на сколько нам надо
      if (diffBottom > 0 || diffRight > 0) {
        // смотрим минимальное увеличение требуемое для минимальных 50 снизу и 46 справа
        var kh = (ih + diffBottom * 2) / ih,
          kw = (iw + diffRight * 2) / iw,
          k = Math.max(kh, kw);

        iw *= k;
        ih *= k;
      }
    }

    if (params.provider.toLowerCase() == 'youtube' && params.controls_remove) {
      // у ютуба снизу рекламный баннер и лого 103px
      var currentBottomPadding = (ih - ch) / 2,
        minBottomPadding = 103,
        diff = minBottomPadding - currentBottomPadding;

      // если на данный момент ифрейм выступает снизу контейнера менее чем на 103px
      // нам необходимо увеличить его настолько, чтобы выступал на 103px
      if (diff > 0) {
        ih += diff * 2; // добавляем недостающие пикселы сверху и снизу ифрейма
        iw = ih * aspect_poster; // по новой высоте правим ширину
      }
    }

    // теперь мы знаем размеры ифрейма и нам надо просто спозиционировать его по центру области в которой нам предстоит его показывать (frame)
    var il = -dopBuffer - (iw - cw) / 2,
      it = -dopBuffer - (ih - ch) / 2;

    // $media может быть массивом объектов, например постером и видео
    params.$media.css({
      left: il,
      top: it,
      width: iw,
      height: ih,
    });
  },

  // метод для получения превью видео от видеосервиса в хорошем качестве с проверкой,
  // что такая картинка существует
  // params: {
  //	provider: Vimeo | Youtube
  //	thumbnail_url: текущий thumbnail_url
  // }
  // @returns Promise, который вернет новый thumbnail_url
  // для простоты работы с методом ошибки хэндлим внутри и всегда делаем resolve,
  // а если не удалось получить больший размер, просто вернем текущий thumbnail_url
  getHighResVideoThumbnail: function(params) {
    if (!params.provider || !params.thumbnail_url) {
      return Promise.resolve(params.thumbnail_url || '');
    }

    // функция для проверки url превью
    // т.к. youtube для несуществующей картинки возвращает заглушку шириной 120 пкс,
    // проверяем на соответствие запрошенной ширине
    function checkImage(url, requestedWidth) {
      var img = new Image();
      var imgPromise = new window.Promise(function(resolve) {
        function removeListeners() {
          img.removeEventListener('load', onLoad);
          img.removeEventListener('error', onError);
        }
        function onLoad() {
          resolve(requestedWidth <= img.naturalWidth);
          removeListeners();
        }
        function onError() {
          resolve(false);
          removeListeners();
        }
        img.addEventListener('load', onLoad);
        img.addEventListener('error', onError);
      });

      img.src = url;
      return imgPromise;
    }

    // в качестве текущего берем то, что у нас оканчивается на 'default.ext' и заменяем на запрошенное разрешение
    function replaceYoutubeUrl(url, targetRes) {
      return url.replace(/(\/)(\w*default)(\.\w+$)/i, '$1' + targetRes + '$3');
    }

    // в url вида https://i.vimeocdn.com/video/742257208_295x166.jpg
    // или https://i.vimeocdn.com/video/742257208_480.jpg
    // заменяем размер превью на нужный нам
    function replaceVimeoUrl(url, targetRes) {
      return url.replace(/(\/)(\w+_)(\d+x\d+|\d+)(\.\w+$)/i, '$1$2' + targetRes + '$4');
    }

    // строим массив для проверки превью url-ов, она будет выполняться начиная с 0 элемента,
    // если все проверки будут неуспешными, тогда вернем текущий thumbnail_url
    var thumbnailChecks = [];
    switch (params.provider.toLowerCase()) {
      // Vimeo умеет отдавать любые запрошенные размеры превью, при необходимости делая upscale.
      // Есть еще вариант использования АПИ 'https://api.vimeo.com/videos/' + videoId + '/pictures',
      // но для него vimeo требует предварительную аутентификацию OAUTH токеном и отсылкой его в
      // заголовке Authorization: Bearer <OAUTH_TOKEN> в каждом запросе, однако это слишком увеличивает
      // и время, и сложность получения превью через АПИ, учитывая, что в ответе будет та же ссылка.
      case 'vimeo':
        thumbnailChecks.push({
          url: replaceVimeoUrl(params.thumbnail_url, '1280'),
          width: 1280,
        });
        thumbnailChecks.push({
          url: replaceVimeoUrl(params.thumbnail_url, '640'),
          width: 640,
        });
        break;

      // Youtube нам предлагает такие варианты (oembed по умолчанию отдает hqdefault)
      // 120x90 = 'default'
      // 320x180 = 'mqdefault'
      // 480x360 = 'hqdefault'
      // 640x480 = 'sddefault'
      // 1280x720 = 'maxresdefault'
      case 'youtube':
        thumbnailChecks.push({
          url: replaceYoutubeUrl(params.thumbnail_url, 'maxresdefault'),
          width: 1280,
        });
        thumbnailChecks.push({
          url: replaceYoutubeUrl(params.thumbnail_url, 'sddefault'),
          width: 640,
        });
        break;
    }

    function thumbnailPromise(thumbnails) {
      if (!thumbnails || !thumbnails.length) {
        return Promise.resolve(params.thumbnail_url);
      }

      return new window.Promise(function(resolve) {
        var i = 0;
        function check(url, width, resolve) {
          checkImage(url, width).then(function(isSuccess) {
            if (isSuccess) {
              resolve(url);
            } else if (++i < thumbnails.length) {
              check(thumbnails[i].url, thumbnails[i].width, resolve);
            } else {
              resolve(params.thumbnail_url);
            }
          });
        }
        check(thumbnails[i].url, thumbnails[i].width, resolve);
      });
    }

    return thumbnailPromise(thumbnailChecks);
  },

  // запрашиваем oEmbed данные по видео
  // @param url ссылка на видео или сразу на oembed запрос
  // @param onSuccess в случае успеха вызывается коллбэк onSuccess(data)
  // @param onError в случае ошибки onError(XMLHttpRequest, textStatus, errorThrown)
  // @returns ссылка на XHR запрос, который можно отменить через .abort()
  getEmbedData: function(url, onSuccess, onError) {
    return $.ajax({
      type: 'GET',
      url: '/api/proxy/',
      dataType: 'json',
      data: {
        url: /oembed/i.test(url) ? url : this.getEmbedUrl(url),
      },
      success: function(data) {
        data.url = data.url && Utils.toHttps(data.url);
        data.thumbnail_url = data.thumbnail_url && Utils.toHttps(data.thumbnail_url);
        if (typeof onSuccess === 'function') {
          onSuccess(data);
        }
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        // если ошибка (404) наример видео не доступно, заблокировано, удалено и пр
        // и при этом ошибка не вызвана методом abort()
        if (textStatus !== 'abort' && typeof onError === 'function') {
          onError(XMLHttpRequest, textStatus, errorThrown);
        }
      },
      global: false,
    });
  },

  // формирует url для embed запроса данных по видео на основе ссылки на видео
  getEmbedUrl: function(url) {
    if (this.isVimeo(url)) {
      return 'https://vimeo.com/api/oembed.json?url=' + encodeURIComponent(url);
    } else {
      var videoId = VideoUtils.getYoutubeIdFromUrl(url);
      if (videoId) {
        return (
          'https://www.youtube.com/oembed?format=json&url=' +
          encodeURIComponent('https://www.youtube.com/watch?v=' + videoId)
        );
      }
    }
    return '';
  },

  getYoutubeIdFromUrl: function(url) {
    // парсим ссылки домена youtu.be вида https://youtu.be/cjCxYGp-2f4
    // или https://www.youtube.com/watch?v=cjCxYGp-2f4,
    // а также разные их модификации, где после youtu.be/ может стоять
    // {буква или цифра}/, embed/ watch?v=
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) return match[2];

    // если первым регекспом ничего не получили,
    // парсим ссылки домена youtube.com вида https://www.youtube.com/?foo=bar&v=cjCxYGp-2f4,
    // т.е. когда id видео болтается где-то в query строке
    // и вытаскиваем из нее часть после &v=, если в ней нет символов #, & или ?
    var regexpAdditional = /.*(youtu.*\&v=)([^#\&\?]*).*/;
    match = url.match(regexpAdditional);
    if (match && match[2].length === 11) return match[2];

    return null;
  },

  getVimeoIdFromUrl: function(url) {
    // парсим ссылку vimeo вида https://i.vimeocdn.com/video/742257208_295x166.jpg
    // или https://i.vimeocdn.com/video/742257208_1280.jpg
    // берем цифровую часть после video/ и до первого _
    var match = url.match(/(video\/)(\w+)(_\d+x\d+|_\d+)(\.\w+$)/i);
    return match && match[2] ? match[2] : null;
  },

  getVideoProvider: function(url) {
    return this.isVimeo(url) ? 'Vimeo' : this.isYoutube(url) ? 'YouTube' : '';
  },

  isVimeo: function(url) {
    return /vimeo\.com\/.+/.test(url);
  },

  isYoutube: function(url) {
    return !!this.getYoutubeIdFromUrl(url);
  },
};

export default VideoUtils;
