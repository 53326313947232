/**
 * Работа с иконками в R/m, Noun иконки и наши
 */
import _ from '@rm/underscore';

const iconsutils = {
  API_URL: '/api/authservice/noun?uri=',

  LIMIT_TO_PUBLIC_DOMAIN: false, // true для тестирования на бесплатном аккаунте

  prevSVGSearch: {},

  fetchIconsPage: function(query) {
    var uri;
    if (!query || this.searchInProgress) {
      return Promise.resolve();
    }

    var page = this.prevSVGSearch.nextPage || 0;
    if (this.prevSVGSearch.query != query) {
      page = 0;
      this.prevSVGSearch.icons = [];
    }

    uri = '/icons/' + query + '?page=' + page;
    if (this.LIMIT_TO_PUBLIC_DOMAIN) {
      uri += '&limit_to_public_domain=1';
    }

    return new Promise(
      function(resolve, reject) {
        this.requestIcons(
          uri,
          function(err, data) {
            if (err) {
              delete this.prevSVGSearch.nextPage; // Удаляем номер следующей страницы. Нечего больше грузить
              return resolve(this.prevSVGSearch.icons);
            }

            // Кэшируем результаты предыдущего поиска,
            // включая иконки, последнюю пэйджинированную страницу и строку поиска
            this.prevSVGSearch.icons = this.prevSVGSearch.icons.concat(data.icons);
            this.prevSVGSearch.query = query;
            this.prevSVGSearch.nextPage = page + 1;

            return resolve(this.prevSVGSearch.icons);
          }.bind(this)
        );
      }.bind(this)
    );
  },

  requestIcons: function(uri, callback) {
    this.searchXhr && this.searchXhr.abort();
    this.searchInProgress = true;

    this.searchXhr = $.ajax({
      method: 'GET',
      url: this.API_URL + encodeURIComponent(uri),
      dataType: 'json',
      success: function(data) {
        callback(null, data);
      },
      error: function(xhr) {
        callback(xhr);
      },
      complete: function() {
        this.searchInProgress = false;
      },
      context: this,
    });
  },

  clearSearch: function() {
    this.prevSVGSearch = {};
    this.searchXhr && this.searchXhr.abort();
    this.searchInProgress = false;
  },

  findIcon: function(query) {
    if (query.rm_id) {
      return _.findWhere(this.defaultIcons, query);
    } else return _.findWhere(this.prevSVGSearch.icons, { id: query.noun_id });
  },

  defaultIcons: [
    {
      rm_id: 'rm0',
      icon_url: '/img/constructor/blocks/shape/default-collection/00-star.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/00-star.svg',
    },
    {
      rm_id: 'rm1',
      icon_url: '/img/constructor/blocks/shape/default-collection/01-bolt.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/01-bolt.svg',
    },
    {
      rm_id: 'rm2',
      icon_url: '/img/constructor/blocks/shape/default-collection/02-bubble.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/02-bubble.svg',
    },
    {
      rm_id: 'rm3',
      icon_url: '/img/constructor/blocks/shape/default-collection/03-camera.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/03-camera.svg',
    },
    {
      rm_id: 'rm4',
      icon_url: '/img/constructor/blocks/shape/default-collection/04-cloud.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/04-cloud.svg',
    },
    {
      rm_id: 'rm5',
      icon_url: '/img/constructor/blocks/shape/default-collection/05-download1.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/05-download1.svg',
    },
    {
      rm_id: 'rm6',
      icon_url: '/img/constructor/blocks/shape/default-collection/06-download2.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/06-download2.svg',
    },
    {
      rm_id: 'rm7',
      icon_url: '/img/constructor/blocks/shape/default-collection/07-globe2.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/07-globe2.svg',
    },
    {
      rm_id: 'rm8',
      icon_url: '/img/constructor/blocks/shape/default-collection/08-globe.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/08-globe.svg',
    },
    {
      rm_id: 'rm9',
      icon_url: '/img/constructor/blocks/shape/default-collection/09-pin.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/09-pin.svg',
    },
    {
      rm_id: 'rm10',
      icon_url: '/img/constructor/blocks/shape/default-collection/10-share1.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/10-share1.svg',
    },
    {
      rm_id: 'rm11',
      icon_url: '/img/constructor/blocks/shape/default-collection/11-share2.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/11-share2.svg',
    },
    {
      rm_id: 'rm12',
      icon_url: '/img/constructor/blocks/shape/default-collection/12-send.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/12-send.svg',
    },
    {
      rm_id: 'rm13',
      icon_url: '/img/constructor/blocks/shape/default-collection/13-checkmark.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/13-checkmark.svg',
    },
    {
      rm_id: 'rm14',
      icon_url: '/img/constructor/blocks/shape/default-collection/14-x.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/14-x.svg',
    },
    {
      rm_id: 'rm15',
      icon_url: '/img/constructor/blocks/shape/default-collection/15-minus.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/15-minus.svg',
    },
    {
      rm_id: 'rm16',
      icon_url: '/img/constructor/blocks/shape/default-collection/16-plus.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/16-plus.svg',
    },
    {
      rm_id: 'rm17',
      icon_url: '/img/constructor/blocks/shape/default-collection/17-mail1.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/17-mail1.svg',
    },
    {
      rm_id: 'rm18',
      icon_url: '/img/constructor/blocks/shape/default-collection/18-mail2.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/18-mail2.svg',
    },
    {
      rm_id: 'rm19',
      icon_url: '/img/constructor/blocks/shape/default-collection/19-user.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/19-user.svg',
    },
    {
      rm_id: 'rm20',
      icon_url: '/img/constructor/blocks/shape/default-collection/20-clock.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/20-clock.svg',
    },
    {
      rm_id: 'rm21',
      icon_url: '/img/constructor/blocks/shape/default-collection/21-embed.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/21-embed.svg',
    },
    {
      rm_id: 'rm22',
      icon_url: '/img/constructor/blocks/shape/default-collection/22-flag.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/22-flag.svg',
    },
    {
      rm_id: 'rm23',
      icon_url: '/img/constructor/blocks/shape/default-collection/23-heart.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/23-heart.svg',
    },
    {
      rm_id: 'rm24',
      icon_url: '/img/constructor/blocks/shape/default-collection/24-itunes.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/24-itunes.svg',
    },
    {
      rm_id: 'rm25',
      icon_url: '/img/constructor/blocks/shape/default-collection/25-like.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/25-like.svg',
    },
    {
      rm_id: 'rm26',
      icon_url: '/img/constructor/blocks/shape/default-collection/26-music.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/26-music.svg',
    },
    {
      rm_id: 'rm27',
      icon_url: '/img/constructor/blocks/shape/default-collection/27-play.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/27-play.svg',
    },
    {
      rm_id: 'rm28',
      icon_url: '/img/constructor/blocks/shape/default-collection/28-video.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/28-video.svg',
    },
    {
      rm_id: 'rm29',
      icon_url: '/img/constructor/blocks/shape/default-collection/29-facebook.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/29-facebook.svg',
    },
    {
      rm_id: 'rm30',
      icon_url: '/img/constructor/blocks/shape/default-collection/30-twitter.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/30-twitter.svg',
    },
    {
      rm_id: 'rm31',
      icon_url: '/img/constructor/blocks/shape/default-collection/31-linkedin.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/31-linkedin.svg',
    },
    {
      rm_id: 'rm32',
      icon_url: '/img/constructor/blocks/shape/default-collection/32-pinterest.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/32-pinterest.svg',
    },
    {
      rm_id: 'rm33',
      icon_url: '/img/constructor/blocks/shape/default-collection/33-instagram.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/33-instagram.svg',
    },
    {
      rm_id: 'rm34',
      icon_url: '/img/constructor/blocks/shape/default-collection/34-googleplus.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/34-googleplus.svg',
    },
    {
      rm_id: 'rm35',
      icon_url: '/img/constructor/blocks/shape/default-collection/35-tumblr.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/35-tumblr.svg',
    },
    {
      rm_id: 'rm36',
      icon_url: '/img/constructor/blocks/shape/default-collection/36-youtube.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/36-youtube.svg',
    },
    {
      rm_id: 'rm37',
      icon_url: '/img/constructor/blocks/shape/default-collection/37-vimeo.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/37-vimeo.svg',
    },
    {
      rm_id: 'rm38',
      icon_url: '/img/constructor/blocks/shape/default-collection/38-flickr.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/38-flickr.svg',
    },
    {
      rm_id: 'rm39',
      icon_url: '/img/constructor/blocks/shape/default-collection/39-blogger.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/39-blogger.svg',
    },
    {
      rm_id: 'rm40',
      icon_url: '/img/constructor/blocks/shape/default-collection/40-dribbble.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/40-dribbble.svg',
    },
    {
      rm_id: 'rm41',
      icon_url: '/img/constructor/blocks/shape/default-collection/41-behance.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/41-behance.svg',
    },
    {
      rm_id: 'rm42',
      icon_url: '/img/constructor/blocks/shape/default-collection/42-rss.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/42-rss.svg',
    },
    {
      rm_id: 'rm43',
      icon_url: '/img/constructor/blocks/shape/default-collection/43-at.svg',
      preview_url_42: '/img/constructor/blocks/shape/default-collection/43-at.svg',
    },
  ],
};

export default iconsutils;
