<template>
  <transition name="control" :appear="appearanceAnimation" @after-leave="afterLeave">
    <div v-if="visible" class="control" :class="cssClasses" :style="{ top: y + 'px' }">
      <control-icon
        :selected="selected"
        :alt="alt"
        @click.native="onIconClick"
        :svgIcon="svgIcon"
        :iconClass="iconClass"
      />
      <transition name="panel">
        <component
          v-if="selected"
          :block="block"
          :raw_block="raw_block"
          ref="panel"
          :is="panelComponent"
          @click.native="closePopups"
          @resize="onPanelResize"
        ></component>
      </transition>
    </div>
  </transition>
</template>

<script>
import _ from '@rm/underscore';
import ControlIcon from './control-icon.vue';
import PanelRotation from './panel-rotation.vue';
import PanelButtonSettings from './panel-button-settings.vue';
import PanelTwitterSettings from './panel-twitter-settings.vue';
import PanelFacebookSettings from './panel-facebook-settings.vue';
import PanelGmapsSettings from './panel-gmaps-settings.vue';
import PanelPictureSettings from './panel-picture-settings.vue';
import PanelAudioSettings from './panel-audio-settings.vue';
import PanelShapeSettings from './panel-shape-settings.vue';
import PanelSlideshowSettings from './panel-slideshow-settings.vue';
import PanelVideo from './panel-video.vue';
import PanelCartSettings from './panel-cart-settings.vue';
import PanelProductSelector from './panel-product-selector.vue';
import PanelAddtocartSettings from './panel-addtocart-settings.vue';
import PanelCartConnections from './panel-cart-connections.vue';
import PanelPictureAlt from './panel-picture-alt.vue';
import PanelRmheaderSettings from './panel-rmheader-settings.vue';

export default {
  components: {
    ControlIcon,
    PanelAddtocartSettings,
    PanelRotation,
    PanelButtonSettings,
    PanelTwitterSettings,
    PanelFacebookSettings,
    PanelGmapsSettings,
    PanelPictureSettings,
    PanelPictureAlt,
    PanelAudioSettings,
    PanelShapeSettings,
    PanelSlideshowSettings,
    PanelVideo,
    PanelCartConnections,
    PanelCartSettings,
    PanelProductSelector,
    PanelRmheaderSettings,
  },

  computed: {
    // Из-за того, что часть классов добавляется условно, и есть класс, индивидуальный для каждого контрола,
    // придётся делать computed-объект класса
    cssClasses: function() {
      var cssClasses = {
        fade: (this.faded && !this.selected) || this.disabled,
        checked: this.selected,
        loading: this.loading,
      };
      // css-класс компонента
      cssClasses[this.cssClass] = true;
      return cssClasses;
    },

    svgIcon: function() {
      return this.raw_block.controlsSvgIcons && this.raw_block.controlsSvgIcons[this.panelComponent]
        ? this.raw_block.controlsSvgIcons[this.panelComponent]
        : null;
    },

    // Устанавливает кастомный класс для иконки в панельке контрола.
    // Класс может быть задан самим блоком через функцию controlsIconClasses(),
    // которая должна вернуть объект вида
    // { panel-component-name : func() или название нужного класса }
    iconClass: function() {
      if (this.raw_block.controlsIconClasses && typeof this.raw_block.controlsIconClasses === 'function') {
        var iconClasses = this.raw_block.controlsIconClasses();
        return iconClasses && iconClasses[this.panelComponent]
          ? typeof iconClasses[this.panelComponent] === 'function'
            ? iconClasses[this.panelComponent]()
            : iconClasses[this.panelComponent]
          : null;
      }
      return null;
    },
  },

  methods: {
    onIconClick: function() {
      if (!this.selected && !this.disabled) {
        this.select();
      } else if (this.selected) {
        this.deselect();
      }
    },
    deselect: function() {
      this.$emit('deselect');
    },
    select: function() {
      this.$emit('select');
    },

    canClosePanel: function() {
      if (!this.selected) {
        return true;
      }

      var result = null;

      _.each(this.$children, chld => {
        if (chld.canClose && result === null) {
          result = chld.canClose();
        }
      });
      return result === null ? true : result;
    },

    closePopups: function() {
      this.$refs.panel.closeOthersPopups();
    },

    onPanelResize: function() {
      this.$emit('panel-resize', this.$refs.panel.$el);
    },
  },
};
</script>

<style lang="less">
@import (reference) '../../../css/common/constants.less'; // Все существующие переменные, миксины и прочее должно вставляться через (reference) чтобы избежать повторного билдига стилей
</style>
