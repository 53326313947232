/**
 * Список доступных для работы вьюпортов, менять крайне аккуратно!
 */
import _ from '@rm/underscore';
/**
 * Глобальные константы
 */

var viewports_constants = {
  default: 'default',
  tablet_portrait: 'tablet_portrait',
  phone_portrait: 'phone_portrait',
};

var viewports = [
    // ВНИМАНИЕ, константа default исопльзуется в нескольким местах js кода напрямую
    // иконки для устройств прописаны в widgetbar.less и panels/viewports.less  искать data-viewport (например [data-viewport='tablet_landscape'])
    {
      name: 'default', // имя вьюпорта для использования в модели
      width: 1024, // ширина вьюпорта, в конструкторе задает ширину рамки, во вьювере тоже что-то задает наверно, пока не знаю
      min_height: 672, // для конструктора, минимальная высота воркспейса
      max_height: 201600, // для конструктора, максимальная высота воркспейса (не знаю откуда это значение, взял из page-format)
      caption: 'Laptop & Tablet Landscape',
      shortcut: '1',
    },
    {
      name: 'tablet_portrait',
      width: 768,
      min_height: 926,
      max_height: 201600,
      caption: 'Tablet Portrait',
      shortcut: '2',
      deprecated: true,
    },
    {
      name: 'phone_portrait',
      width: 320,
      min_height: 568,
      max_height: 201600,
      caption: 'Phone Portrait',
      shortcut: '3',
    },
  ],
  // Общие поля моделей, которые могут быть изменены во вьюпорте
  viewport_fields_common = [
    'x',
    'y',
    'w',
    'h',
    'z',
    'hidden',
    'angle',
    'flip_h',
    'flip_v',
    'pack_id',
    'fixed_position',
    'is_full_width',
    'is_full_height',
    'sticked',
    'sticked_margin',
    'animation',
    // /gwidget - видимо, сюда нужно добавить свойства-массивы уникальных значений глобальных виджетов для каждой страницы
  ],
  // расширения полей вьюпортов для конкретных виджетов
  viewport_fields = {
    background: _.union(['color', 'opacity', 'selectedType', 'picture', 'slideshow'], viewport_fields_common),
    picture: _.union(
      [
        'picture', // объект
        'cropW',
        'cropH',
        'cropX',
        'cropY',
        'originalW',
        'originalH',
        'ratio',
        'scale',
        'pic_opacity',
        'pic_color',
        'border_size',
        'border_color',
        'border_radius',
        'border_radius_max',
        'opacity',
        'anchor_link_pos',
        'preview_overlay_color',
      ],
      viewport_fields_common
    ),
    slideshow: _.union(['images_h', 'captions_h'], viewport_fields_common),
    text: _.union(['column_count', 'column_gap', 'bg_color', 'bg_opacity'], viewport_fields_common),
    shape: _.union(
      ['rasterUrl', 'raster2xUrl', 'borders', 'weight', 'anchor_link_pos', 'bg_color'],
      viewport_fields_common
    ),
    button: _.union(
      [
        'text_w',
        'text_h',
        'em_w',
        'anchor_link_pos',
        'font-size',
        'font-style',
        'font-weight',
        'font-family',
        'color',
        'color-opacity',
        'letter-spacing',
        'hover-color',
        'hover-color-opacity',
        'current-font-size',
        'current-font-style',
        'current-font-weight',
        'current-font-family',
        'current-color',
        'current-color-opacity',
        'current-letter-spacing',
        'background-color',
        'background-color-opacity',
        'hover-background-color',
        'hover-background-color-opacity',
        'current-background-color',
        'current-background-color-opacity',
        'border-radius',
        'border-width',
        'border-color',
        'border-color-opacity',
        'hover-border-width',
        'hover-border-color',
        'hover-border-color-opacity',
        'current-border-radius',
        'current-border-width',
        'current-border-color',
        'current-border-color-opacity',
      ],
      viewport_fields_common
    ),
    // для точки. она как шейп.
    hotspot: _.chain()
      .union(['borders', 'weight', 'anchor_link_pos'], viewport_fields_common)
      .value(),
    form: _.union(
      [
        'layout',
        'style',
        // дальше все объекты
        'style-colored-button-default',
        'style-colored-button-hover',
        'style-colored-fields',
        'style-outlined-button-default',
        'style-outlined-button-hover',
        'style-outlined-fields',
        'style-underlined-button-default',
        'style-underlined-button-hover',
        'style-underlined-fields',
      ],
      viewport_fields_common
    ),
  },
  viewport_listall = _.map(viewports, function(viewport) {
    return 'viewport_' + viewport.name;
  }),
  viewport_list = _.without(viewport_listall, 'viewport_default'),
  widgetViewportFields = function(type) {
    return viewport_fields[type] || viewport_fields_common;
  };

export default {
  viewports,
  viewport_fields_common,
  viewport_fields,
  viewport_listall,
  viewport_list,
  widgetViewportFields,
  viewports_constants,
};
