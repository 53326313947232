/**
 * Вовзращает строку цены с валютой
 * отформатированную для текущего региона пользователя и валюты
 * @param {Number} price
 * @param {String} currency
 */

export const formatCurrency = (price, currency) => {
  return new Intl.NumberFormat(window.navigator.language || 'en-US', {
    style: 'currency',
    currency,
  }).format(price);
};
