<template>
  <div
    class="panel slideshow-panel resizable"
    :class="{
      noun: block.current_mode === 'noun',
      'has-upload-images': hasPictures,
      uploading: uploading && !hasPictures,
      dragging: dragging,
    }"
  >
    <div class="upload">
      <div class="upload-wrapper">
        <div class="center-wrapper" @click="upload($event)">
          <div class="icon"></div>
          <div class="caption-upload-dop">{{ dragging ? 'drop here' : 'drop file here' }}</div>
        </div>
      </div>
    </div>

    <div class="picture_preloader rmpreloader">
      <div class="shadow"></div>
      <div class="arc"></div>
    </div>

    <div class="loading">
      <div class="delete" @click="cancelUpload"></div>
    </div>

    <div class="settings" v-show="block.current_mode === 'default'">
      <div class="themes_params">
        <div class="colorbox_container"></div>

        <div class="params" :data-current-theme="block.current_theme">
          <div class="param param-arrows dotted-separator">
            <div class="caption">Arrows</div>
            <rm-switcher
              v-model="block.theme_data.arrows"
              :width="40"
              :height="24"
              colorOn="#0081FF"
              colorOff="#B8B8B8"
            ></rm-switcher>
          </div>

          <div class="param param-arrows-type dotted-separator" v-show="block.theme_data.arrows">
            <div
              v-for="arrows_type in ['default', 'noun']"
              class="arrows-type"
              :key="arrows_type"
              :class="{ active: block.theme_data.arrows_type === arrows_type, [arrows_type]: true }"
              @click="onArrowsTypeChange(arrows_type)"
            >
              {{ arrows_type.charAt(0).toUpperCase() + arrows_type.slice(1) }}
            </div>
          </div>

          <div
            class="param param-arrows-view-mode solid-separator"
            v-show="block.theme_data.arrows && isMouseoverEnabled"
          >
            <div
              v-for="arrows_view_mode in ['normal', 'mouseover']"
              :key="arrows_view_mode"
              class="arrows-view-mode"
              :class="{ active: block.theme_data.arrows_view_mode === arrows_view_mode, [arrows_view_mode]: true }"
              @click="block.theme_data.arrows_view_mode = arrows_view_mode"
            >
              {{ arrows_view_mode.charAt(0).toUpperCase() + arrows_view_mode.slice(1) }}
            </div>
          </div>

          <transition name="fade">
            <div class="param param-counters dotted-separator">
              <div class="caption">Counter</div>
              <rm-switcher
                v-model="block.theme_data.counters"
                :width="40"
                :height="24"
                colorOn="#0081FF"
                colorOff="#B8B8B8"
              ></rm-switcher>
            </div>
          </transition>

          <div class="param param-counters-type solid-separator" v-show="block.theme_data.counters">
            <div
              v-for="counters_type in ['numbers', 'dots']"
              class="counters-type"
              :key="counters_type"
              :class="{
                active: block.theme_data.counters_type === counters_type,
                disabled: counters_type === 'dots' && block.theme_data.thumbnails,
                [counters_type]: true,
              }"
              @click="onCountersTypeClick"
            >
              {{ counters_type.charAt(0).toUpperCase() + counters_type.slice(1) }}
            </div>
          </div>

          <div class="param param-controls solid-separator">
            <div class="caption">Controls color</div>
            <color-picker
              @showPopup="closeOthersPopups"
              :color="block.theme_data.controls_color"
              @update:color="col => onUpdateColor(col)"
              :showAlpha="false"
            ></color-picker>
          </div>

          <div class="param param-fullscreen solid-separator">
            <div class="caption">Fullscreen</div>
            <rm-switcher
              v-model="block.theme_data.fullscreen"
              :width="40"
              :height="24"
              colorOn="#0081FF"
              colorOff="#B8B8B8"
            ></rm-switcher>
          </div>

          <div class="param param-captions solid-separator">
            <div class="caption">Captions</div>
            <rm-switcher
              v-model="block.theme_data.captions"
              :is-disabled="block.theme_data.thumbnails"
              :width="40"
              :height="24"
              colorOn="#0081FF"
              colorOff="#B8B8B8"
            ></rm-switcher>
          </div>

          <transition name="fade">
            <div class="param param-thumbnails dotted-separator">
              <div class="caption">Thumbs</div>
              <rm-switcher
                v-model="block.theme_data.thumbnails"
                :callback="onThumbnailsChange"
                :width="40"
                :height="24"
                colorOn="#0081FF"
                colorOff="#B8B8B8"
              ></rm-switcher>
            </div>
          </transition>

          <div class="param param-fill dotted-separator">
            <div class="caption">Fill</div>
            <rm-switcher
              v-model="block.theme_data.fill"
              :width="40"
              :height="24"
              colorOn="#0081FF"
              colorOff="#B8B8B8"
            ></rm-switcher>
          </div>

          <div class="param param-radius dotted-separator">
            <div class="caption">Radius</div>
            <numeric-input
              v-model="block.theme_data.radius"
              :class="{
                active: block.theme_data.radius > 0,
              }"
              :autofocus="false"
              :autosize="false"
              :min="0"
              :max="999"
              dir="v"
              :speed="5"
            />
          </div>

          <div
            class="param param-backgroundr solid-separator"
            v-show="block.theme_data.thumbnails || !block.theme_data.fill || block.theme_data.radius > 0"
          >
            <div class="caption">Bg color</div>
            <color-picker
              @showPopup="closeOthersPopups"
              :color="block.theme_data.background_color"
              @update:color="col => (block.theme_data.background_color = col)"
              :alpha="block.theme_data.background_opacity"
              @update:alpha="alpha => (block.theme_data.background_opacity = alpha)"
              :opacityMultiplier="100"
              :showAlpha="true"
            ></color-picker>
          </div>

          <div class="param param-autoplay">
            <div class="caption">Autoplay</div>
            <rm-switcher
              v-model="block.theme_data.autoplay"
              :width="40"
              :height="24"
              colorOn="#0081FF"
              colorOff="#B8B8B8"
            ></rm-switcher>
          </div>
        </div>
      </div>
    </div>
    <div class="noun-settings" v-show="block.current_mode === 'noun'">
      <div class="noun-wrapper solid-separator">
        <span
          v-for="arrow in ['left', 'right']"
          class="arrow-noun"
          :key="arrow"
          :class="{ active: activeNounArrow == arrow, [arrow]: true }"
          @click="activeNounArrow = arrow"
          >{{ arrow.charAt(0).toUpperCase() + arrow.slice(1) }}</span
        >
      </div>

      <shape-search @dataObtained="onDataObtained" ref="shapeSearch" />
    </div>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import Vue from 'vue';
import Panel from './panel.vue';
import RmSwitcher from '../../common/rm-switcher.vue';
import RmSpinner from '../../common/rm-spinner.vue';
import ColorPicker from '../../common/color-picker.vue';
import ShapeSearch from '../../common/shape-search.vue';
import NumericInput from '../../common/numeric-input.vue';
import iconsutils from '../../../js/common/iconsutils';
import { isEnabled } from '../../../js/common/flags';

export default {
  mixins: [Panel],
  components: { RmSwitcher, RmSpinner, ColorPicker, ShapeSearch, NumericInput },

  data: function() {
    return {
      uploading: false,
      dragging: false,
      activeNounArrow: 'left',
      isMouseoverEnabled: isEnabled('constructor.mouseover'),
      icons: iconsutils.prevSVGSearch.icons || iconsutils.defaultIcons,
      m: {
        pictures: [],
      },
    };
  },

  mounted: function() {
    this.uploader = this.raw_block.uploader;
    this.uploader.on('start', this.showPreloader);
    this.uploader.on('done fail', this.checkFinishUpload);

    this.raw_block.settingsPanel = this;
    this.raw_block.updateDropZone();

    this.raw_block.workspace.on('main-dragover', this.highlightDropZone, this);
  },

  computed: {
    hasPictures: function() {
      return !_.isEmpty(this.m.pictures);
    },
  },

  watch: {
    'block.current_mode': {
      handler: function() {
        Vue.nextTick(
          function() {
            this.$emit('resize');
          }.bind(this)
        );
      },
      immediate: true,
    },
  },

  methods: {
    upload: function(e) {
      this.raw_block.$('.uploadInput').trigger('click', 'stop');
    },

    onUpdateColor: function(color) {
      this.block.theme_data.controls_color = color;
      this.raw_block.needRasterize = true;
    },

    updateShapeSearchScroll: function() {
      this.$refs['shapeSearch'].$refs['rmscroll'].recalcScroll();
    },

    onCountersTypeClick: function(e) {
      if ($(e.target).hasClass('dots')) {
        if ($(e.target).hasClass('disabled')) {
          e.preventDefault();
          return;
        }
        this.block.theme_data.counters_type = 'dots';
      } else {
        this.block.theme_data.counters_type = 'numbers';
      }
    },

    onThumbnailsChange: function(val) {
      if (val) {
        this.block.theme_data.counters_type = 'numbers';
        this.block.theme_data.captions = false;
      }
    },

    onArrowsTypeChange: function(arrow) {
      if (arrow === 'noun') {
        this.updateShapeSearchScroll();
      }
      this.block.theme_data.arrows_type = arrow;
      this.block.current_mode = arrow;
      this.raw_block.trigger('arrow_type_changed');
    },

    onDataObtained: function(icon) {
      var activeArrow = this.activeNounArrow === 'left' ? 'left_arrow_noun' : 'right_arrow_noun';
      this.raw_block.getIconSVG(icon.id || icon.rm_id, icon.icon_url, activeArrow, (err, data) => {
        var set;

        this.raw_block.needRasterize = true;

        if (err) {
          return;
        }

        set = {
          noun_url: icon.icon_url,
        };

        // При задании одного типа айдишника обязательно сбрасывать другой
        // т.к. наличие или отсутствие соотв. айдишника и является признаком, чья иконка - науна или наша
        if (icon.id) {
          _.extend(set, { noun_id: icon.id, rm_id: null });
        } else if (icon.rm_id) {
          _.extend(set, { noun_id: null, rm_id: icon.rm_id });
        }

        if (this.activeNounArrow === 'left') {
          this.block.theme_data.left_icon_noun = set;
        } else {
          this.block.theme_data.right_icon_noun = set;
        }
      });
    },

    canUpload: function() {
      return !this.hasPictures && !this.uploading;
    },

    checkFinishUpload: function() {
      if (_.isEmpty(this.raw_block.queue)) {
        this.uploading = false;
      }
    },

    highlightDropZone: function(e) {
      this.dragging = true;

      clearTimeout(this.highlightTimeout);

      this.highlightTimeout = setTimeout(() => {
        this.dragging = false;
      }, 500);
    },

    showPreloader: function() {
      this.uploading = true;
    },

    cancelUpload: function() {
      this.uploading = false;
      this.raw_block.removePictures();
    },
  },

  beforeDestroy: function() {
    this.raw_block.workspace.off('main-dragover', this.highlightDropZone, this);
  },
};
</script>

<style lang="less" scoped>
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.fade-leave-to,
.fade-leave-active {
  position: absolute !important;
  &.param-counters {
    top: 28px;
  }
}
</style>
