<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import { Utils } from '../../../../js/common/utils';

export default {
  methods: {
    getAudioFromUrl: function(text) {
      var isSoundCloud, url;

      isSoundCloud = /soundcloud\.com\/.+/.test(text) || /snd\.sc\/.+/.test(text);

      // если урл не соответствует саундклауду, игнорируем
      if (!isSoundCloud) {
        this.$emit('audioWrongUrl');
        return;
      }

      text = text.replace(/\/?#?$/, ''); // Отрезаем концевые слэш и хэш. Может иногда прицепляться браузером к урлу, а ембед-сервисы их не любят.

      var audio_data = {
        url: text,
      };

      // айдишник придет после запроса вместе с данными о аудио
      url = 'http://soundcloud.com/oembed?format=json&url=' + encodeURIComponent(text);

      // получаем данные по аудио
      // сохраняем AJAX объект, чтобы потом можно было его отменить
      // this._audioDataXHR = $.getJSON('/api/proxy/', {url: url}, this.onAudioPreviewLoaded);
      this.audioEmbedXHR && this.audioEmbedXHR.abort();

      this.audioEmbedXHR = $.ajax({
        type: 'GET',
        url: '/api/proxy/',
        dataType: 'json',
        data: { url: url },
        global: false,
        success: _.bind(function(data) {
          // выдираем из кода ифрема урл который нас нужен
          // этот урл имеет правильный формат, с обязательным айдишником и типом урла
          // в отличии от того что юзер вводит в панельку
          data.parsed_url = this.parseUrl(data.html);

          // обязательно вычищаем урл, очень часто soundcloud возвращает странные урлы на картинки
          // даже в их собственном плеере есть такие строчки
          // она запросто может вернуть урл картинки которой не существует кстати
          data.thumbnail_url = data.thumbnail_url.replace('-large', '-t300x300');
          data.thumbnail_url = data.thumbnail_url.replace('-t500x500', '-t300x300');

          if (data.parsed_url) {
            // если все ок и soundcloud сказал что урл который ввел пользователь указывает на какой либо embedable ресурс
            // мы должны получить список композиция этого ресерса
            // ресурсы могут быть разными: track, track sets, user's tracks, groups, user's favorites и наверное что-то еще
            this.loadTracksData(_.extend(data, audio_data), [{ url: text }]);
          } else {
            this.$emit('audioEmbedError');
          }
        }, this),
        error: _.bind(function(XMLHttpRequest, textStatus, errorThrown) {
          // если ошибка (404) наример аудио не доступно, заблокировано, удалено и пр
          // и при этом оишбка не вызвана this.audioEmbedXHR.abort()
          if (textStatus != 'abort') this.$emit('audioEmbedError');
        }, this),
      });

      this.$emit('audioDataObtained', audio_data);
    },

    // получаем из кода переданного нам ифрема нужный нам урл
    parseUrl: function(html) {
      var regExp = /.*?src=\"(.*?)\".*?/;
      var match = html.match(regExp);

      if (!match || !match.length || !match[1]) return undefined;

      var urlparts = match[1].split('?');
      var uri = urlparts[0]; // Сам URI без параметров

      // Уберем из урла лишние параметры - те что мы можем кастомизировать через панель
      var parts = urlparts[1].split('&'); // Только параметры
      var excludes = [
        'auto_play',
        'buying',
        'liking',
        'download',
        'sharing',
        'show_artwork',
        'show_comments',
        'show_playcount',
        'color',
        'auto_advance',
        'show_user',
        'visual',
      ];
      var newParts = [];

      _.each(parts, function(part) {
        var param = part.split('=');
        if (!param[0] || _.contains(excludes, param[0])) return;

        newParts.push(part);
      });

      return uri + '?' + newParts.join('&');
    },

    // проверяем что строка начинается на http:// или https:// (а больше и не надо)
    isURL: function(text) {
      return /^http(s?)\:\/\//i.test(text);
    },

    // convert a SoundCloud resource URL to an API URL
    // код выдернут из их кастомного плеера
    scApiUrl: function(url) {
      var secureDocument = document.location.protocol === 'https:',
        resolver = (secureDocument || /^https/i.test(url) ? 'https' : 'http') + '://api.soundcloud.com/resolve?url=',
        // params = 'format=json&client_id=' + this.apiKey +'&callback=?'; // Убрано &callback=? 19.12.2016. из-за бага саундклауда, который возвращает в ответе левые символы, приводящие к ошибке парсинга jsonp (а jsonp нам и не нужен)
        params = 'format=json&client_id=' + this.apiKey;

      // force the secure url in the secure environment
      if (secureDocument) {
        url = url.replace(/^http:/, 'https:');
      }

      // check if it's already a resolved api url
      if (/api\./.test(url)) {
        return url + '?' + params;
      } else {
        return resolver + url + '&' + params;
      }
    },

    // загружает список треков ресурса
    // код выпилен из их собственного кастомного плеера
    loadTracksData: function(embedData, links) {
      var index = 0,
        playerObj = { tracks: [] },
        self = this;

      // В box версии редимэга может не быть ключа в конфиге
      if (!this.apiKey) {
        this.$emit('audioEmbedError', 'No SoundCloud API key configured.');
        return;
      }

      var loadUrl = function(link) {
        var apiUrl = self.scApiUrl(link.url);

        $.ajax({
          url: apiUrl,
          method: 'GET',
          dataType: 'json',
          success: function(data) {
            index += 1;
            if (data.tracks) {
              playerObj.tracks = playerObj.tracks.concat(data.tracks);
            } else if (data.duration) {
              // a secret link fix, till the SC API returns permalink with secret on secret response
              data.permalink_url = link.url;
              // if track, add to player
              playerObj.tracks.push(data);
            } else if (data.creator) {
              // it's a group!
              links.push({ url: data.uri + '/tracks' });
            } else if (data.username) {
              // if user, get his tracks or favorites
              if (/favorites/.test(link.url)) {
                links.push({ url: data.uri + '/favorites' });
              } else {
                links.push({ url: data.uri + '/tracks' });
              }
            } else if ($.isArray(data)) {
              playerObj.tracks = playerObj.tracks.concat(data);
            }

            if (links[index]) {
              // if there are more track to load, get them from the api
              loadUrl(links[index]);
            } else {
              // if loading finishes, anounce it to the GUI
              self.tracksDataIsLoaded(embedData, playerObj.tracks);
            }
          },
          error: function(xhr) {
            self.$emit('audioEmbedError');
            console.log('xhr : ', xhr);
          },
          context: this,
        });
      };

      // load first tracks
      loadUrl(links[index]);
    },

    // это колбек который выстрелит когда мы получим все данные по треков ресурса (loadTracksData), на который ссылается введенный пользователем урл
    // embedData это те данные которые мы получили сначала из урла через oEmbed
    // такая длинная цепочка из-за того, что oEmbed дает нам далеко не все нужные данные
    // а запрос по трекам невозможно выполнить не получив код ифрейма (из которого вы вырезаем правильный урл) который и дает нам запрос на oEmbed
    tracksDataIsLoaded: function(embedData, tracksData) {
      if (!tracksData.length) {
        this.$emit('audioEmbedError');
        return;
      }

      // если грузим только конкретный трек, тогда ставим заголовок и превьюшку в панели с этого трека
      // в противном случае будут использоваться картинка и заголовок которые выдал нам oeMbed
      if (
        /tracks/i.test(embedData.parsed_url) ||
        (embedData.thumbnail_url && /fb_placeholder/.test(embedData.thumbnail_url))
      ) {
        if (tracksData[0].artwork_url)
          embedData.thumbnail_url = tracksData[0].artwork_url.replace('-large', '-t300x300');
        else if (tracksData[0].user && tracksData[0].user.avatar_url)
          embedData.thumbnail_url = tracksData[0].user.avatar_url.replace('-large', '-t300x300');

        embedData.title = tracksData[0].title;
      }
      embedData.thumbnail_url && Utils.toHttps(embedData.thumbnail_url);
      this.$emit('audioEmbedLoaded', embedData);
    },
  },
};
</script>
