import { isEnabled } from '../../common/flags';
import ButtonBlock from './button';
import { Constants } from '../../common/utils';
import ModelClass from '../models/model';

var DEFAULT_BACKGROUND_COLOR = 'f4f4f4';

var defaultDisabledControls = [
  'cart_add_product_widget',
  'cart_settings',
  'button_icon',
  'button_edit',
  'common_animation',
  'common_position',
  'common_layer',
  'common_lock',
];

// Название в нижнем регистре тк при создание виджета в workspace.addWidget он ищится в нижнем регистре
var cartBlock = ButtonBlock.extend(
  {
    name: 'Cart',
    blockName: Constants.ecommerceCartBlockName,
    sort_index: 14,
    thumb: 'button',

    icon_color: '#f4f4f4',

    frameColor: '#0080FF',

    initial_controls: [
      'cart_connections',
      'cart_add_product_widget',
      'cart_settings',
      'button_icon',
      'button_edit',
      'common_animation',
      'common_position',
      'common_layer',
      'common_lock',
    ],

    DEFAULT_BACKGROUND_COLOR: DEFAULT_BACKGROUND_COLOR,

    animationTriggerLine: null,

    // Переопределение для стиля иконки тк пока нет самой иконки, оригинал в block.js
    getIconStyle: function() {
      var res = {};
      res['background-color'] = this.icon_color;
      return res;
    },

    controlsSvgIcons: {
      'panel-cart-connections': '/img/constructor/controls/cart_connections/stipe-connections-icon.svg',
    },

    // Заблокированые контролы
    disabledControls: defaultDisabledControls,

    // Проверяет какие контролы заблокированы
    // По логике в первом параметре должна быть модель блока
    // Но тут мы прокидываем данные из глобального объекта !!!
    checkDisabledControls: function(status, initial) {
      // Срабатывает при первой отрисовке
      if (initial) {
        if (this.workspace.eCommerceManager && this.workspace.eCommerceManager.isMagConnectedToStripe()) {
          this.disabledControls = [];
        }
        return;
      }

      if (status instanceof ModelClass) {
        return;
      }

      if (status) {
        this.disabledControls = [];
      } else {
        this.disabledControls = defaultDisabledControls;
      }

      this.trigger('disabledControls:changed', this.blockName);
    },

    initialize: function(model, workspace) {
      ButtonBlock.prototype.initialize.apply(this, arguments);
    },

    destroy: function() {
      this.stopListeningForUpdateTrigger(); // На всякий случай отменяем подписку
      ButtonBlock.prototype.destroy.apply(this, arguments);
    },

    getIndividualStyles: function() {
      var individual_styles = [
        // Default
        'background-color',
        'background-color-opacity',

        'border-radius',

        'border-width',
        'border-color',
        'border-color-opacity',

        'font-family',
        'font-style',
        'font-weight',

        'color',
        'color-opacity',
        'font-size',
        'letter-spacing',

        // Hover
        'hover-background-color',
        'hover-background-color-opacity',

        'hover-border-width',
        'hover-border-radius',
        'hover-border-color',
        'hover-border-color-opacity',

        'hover-color',
        'hover-color-opacity',

        'hover-font-family',
        'hover-font-size',

        // Current
        'current-background-color',
        'current-background-color-opacity',

        'current-border-radius',
        'current-border-width',
        'current-border-color',
        'current-border-color-opacity',

        'current-color',
        'current-color-opacity',

        'current-font-family',
        'current-font-style',
        'current-font-weight',

        'current-font-size',
        'current-letter-spacing',
      ];

      individual_styles.forEach(function(style, i, styles) {
        styles[i] = 'change:' + style;
      });

      return individual_styles.join(' ');
    },

    onDragStart: function(event, drag) {
      // Блокируем перетаскивание с созданием клона виджета
      if (event.altKey || drag.isClone) {
        return false;
      }
      ButtonBlock.prototype.onDragStart.apply(this, arguments);
    },

    /**
     * Запускает процесс обновления связующей линии при изменении виджета
     * @param {Object} animationTriggerLine - объект CubicBezier с методом update
     */
    startListeningForUpdateTrigger: function(animationTriggerLine) {
      this.animationTriggerLine = animationTriggerLine;
      this.listenTo(this, 'move resize', this.updateAnimationTrigger.bind(this));
    },

    // Останавливает процесс обновления, запускаемый в предыдущем методе
    stopListeningForUpdateTrigger: function() {
      if (this.updateAnimationTrigger) {
        this.stopListening(this, 'move resize', this.updateAnimationTrigger);
        this.animationTriggerLine = null;
      }
    },

    // Обновляем линию, копия их блока addToCart
    updateAnimationTrigger: function() {
      window.requestAnimationFrame(this.recalcAnimationTrigger.bind(this));
    },

    recalcAnimationTrigger: function() {
      this.animationTriggerLine && this.animationTriggerLine.update();
    },
  },
  {
    defaults: {
      tp: 'text', // || icon || text_and_icon
      form: 'rectangle', // || circle || square
      text: 'Cart',
      w: 120,
      h: 50,

      // Виджет глобальный
      is_global: true,

      // по центру оси x холста.
      x: Math.round(1024 / 2 - 134 / 2),

      transition: false,

      // ширина и высота элемента .text — input в Конструкторе, div во Вьювере.
      // возможно, в будущем во Вьювер можно будет не отсылать,
      // т.к. это флекс итем во флексбоксе.
      // но для Конструктора обязательно надо сохранять размеры и
      // потом выставлять их при рендере из модели.
      // т.к. инпут не флексится по контенту,
      // a также при изначальном рендере неверно рассчитывается ширина в this.recalcTextInputSize
      // для this.$textInputSizeAdjuster через width: auto. после таймаута — ок, но не вариант гадать.
      text_w: 48,
      text_h: 26,

      // ширина буквы M, для тукущего размера шрифта.
      // нужна для выставления минимальных отступов слева и справа
      // у Кнопки с только текстом и Иконкой + текстом.
      // сохраняем, т.к. не удается точно вычислять при первом рендере,
      // а ограничения на размер Кнопки (рамки в Конструкторе) надо ставить сразу.
      em_w: 16,

      // ГЛОБАЛЬНЫЕ СТИЛИ КНОПКИ НЕ РЕАЛИЗОВАНЫ. Это заглушка на будущее.
      // Глобальный стиль задается Контролом Глобальных стилей Кнопки.
      // содержит точно такой же набор стилевых параметров как и Индивидульный стиль Кнопки.
      // при выборе или переопределении (redefine) Глобального стиля Кнопки
      // помимо выставления имени Глобального стиля в модель Кнопки, происходит
      // полная замена значений индивидуальных стилевых параметров на глобальные.
      // если Кнопка имеет заданный Глобальный стиль, то на Кнопку навешивается css-класс
      // с id Глобального стиля и начнет применятся Глобальный стиль, сгенерированный
      // в <style/>
      // globalStyle: null, // id Глобального стиля.

      // Индивидуальный стиль Кнопки, всегда переписывающий Глобальный стиль css-каскадом,
      // т.к. имеет более специфичный css-селектор при генерации.
      // позволяет каждой Кнопке иметь Глобальный стиль и переписывать отдельные
      // стилевые параметры через Контрол настроек лично для себя.
      // «Default»
      'background-color': DEFAULT_BACKGROUND_COLOR, // shape color
      'background-color-opacity': 100,
      'border-radius': 15, // radius
      'border-width': 0, // border
      'border-color': '000000',
      'border-color-opacity': 100,

      'font-family': 'Arial',

      // Font Style
      'font-style': 'normal',
      'font-weight': 400,

      color: '282828', // text-color,
      'color-opacity': 100,
      'font-size': 18, // aA
      'letter-spacing': 0, // A ←→ B

      // «Hover»
      // изменяться в Ховер-состоянии могут только эти аттрибуты стиля.
      // остальные аттрибуты стиля будут наследовать значение от Статик-состояния
      // при генерации CSS Кнопки на фронте — см. RM.buttonutils.generateStylesStr() → function attr(s),
      // поэтому их незачем сохранять в модель.
      'hover-background-color': DEFAULT_BACKGROUND_COLOR, // shape color
      'hover-background-color-opacity': 80,

      'hover-border-width': 0,
      'hover-border-color': '000000',
      'hover-border-color-opacity': 100,

      'hover-color': '282828', // text-color,
      'hover-color-opacity': 100,

      'hover-font-size': 18,
      'hover-font-family': 'Arial',
      'hover-border-radius': 15,

      // "Current"
      'current-background-color': DEFAULT_BACKGROUND_COLOR,
      'current-background-color-opacity': 100,

      'current-border-radius': 5,
      'current-border-width': 0,
      'current-border-color': '000000',
      'current-border-color-opacity': 100,

      'current-font-family': 'Arial',

      'current-font-style': 'normal',
      'current-font-weight': 400,

      'current-color': 'ffffff',
      'current-color-opacity': 100,
      'current-font-size': 18,
      'current-letter-spacing': 0,

      // Иконка Кнопки
      // аттрибуты на верхнем уровне, чтобы удобней
      // было навешивать слушателей именно на то, что хочешь.

      icon_enabled: false,

      // высота Иконки — «Size» в Контроле Иконки.
      icon_h: 18,

      // ширина рассчитывается, отталкиваясь от высоты, с учетом пропорции
      // от оригинального SVG.
      icon_w: 18,

      // позиция Иконки. «Pos.» в Контроле Иконки.
      icon_pos: 'left', // || right

      // заготовка под параметр сдвига иконки.
      // 'icon_top': 0,
      // 'icon_right': 0,
      // 'icon_left': 0,
      // 'icon_bottom': 0,

      icon_noun_id: null,
      icon_rm_id: null,
      icon_noun_url: null,

      // цвет для SVG Иконки в Конструкторе.
      // по дефолту цвета Иконки должны быть такие же как и у текста,
      // чтобы в Конструкторе быть связанными cначала и менятся синхронно.
      // цвета Иконки вынесены из style, чтобы удобней было следить за их изменениями,
      // после которых может запустится растеризация SVG.
      icon_color: 'ffffff', // Icon Color
      icon_color_opacity: 100,
      'hover-icon_color': 'ffffff', // Icon Color
      'hover-icon_color_opacity': 100,
      'current-icon_color': 'ffffff', // Icon Color
      'current-icon_color_opacity': 100,

      // во Вьювере Иконка Кнопки — это .png-картинка.
      // при первом создании Кнопки дефолтная Иконка сразу отправится растеризоваться.
      icon_rasterUrl: null,
      icon_raster2xUrl: null,
      'hover-icon_rasterUrl': null,
      'hover-icon_raster2xUrl': null,
      'current-icon_rasterUrl': null,
      'current-icon_raster2xUrl': null,
      // Стили сайдбара
      'bar-color': 'ffffff',
      'bar-color-opacity': 100,
      'bar-background-color': '1a1a1a',
      'bar-background-color-opacity': 100,
      'bar-shadow': false,
      'bar-font-family': 'Arial',
      'bar-font-style': 'normal',
      'bar-font-weight': 400,
    },
  }
);

export default isEnabled('constructor.eCommerce') ? cartBlock : undefined;
