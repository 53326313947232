/**
 * Конструктор для контрола подключения корзины к сервисам
 */
import VueControlClass from '../vue-control';

const cart_connections = VueControlClass.extend({
  name: 'cart_connections',
  alt: 'Cart connections',
  panelComponent: 'panel-cart-connections',
});

export default cart_connections;
