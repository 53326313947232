/**
 * Виджет слайдшоу в бэкграунде, отвечает за отрисовку
 * используется в конструкторе и в вьюере
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';

const BackgroundSlideshowWidget = Backbone.View.extend({
  pictures: [],
  domPictures: [],
  preloadPictures: [],
  currentSlide: null,
  nextSlide: null,
  selectedSlide: null,
  defaultDelay: 4,
  defaultCrossfade: 0.5,
  isAnimationRunning: false,
  cursor: 0,
  rendered: false,
  lastSlideshowAnimationStart: null,

  initialize: function(params) {
    this.initSlideshowData(params.data);
    this.$container = params.container;
    this.$preloadContainer = params.preloadContainer;
  },

  initSlideshowData(slideshowData) {
    this.pictures = slideshowData && slideshowData.pictures ? _.sortBy(slideshowData.pictures, 'num') : [];
    this.delay = slideshowData && typeof slideshowData.delay === 'number' ? slideshowData.delay : this.defaultDelay;
    this.crossfade =
      slideshowData && typeof slideshowData.crossfade === 'number' ? slideshowData.crossfade : this.defaultCrossfade;
    // Нужно для отладки
    this.name = slideshowData && slideshowData.name ? slideshowData.name : 'slideshow' + new Date().getTime();
    this.domPictures = [];
    this.preloadPictures = [];
    this.currentSlide = null;
    this.nextSlide = null;
    this.selectedSlide = null;
    this.isAnimationRunning = false;
    this.cursor = 0;
  },

  render: function(withoutAnimation) {
    // Для сриншотера загружаем одну картинку
    if (RM.screenshot) {
      if (this.pictures.length) {
        this.addPicture(this.pictures[0]);
      } else {
        this.trigger('loaded');
      }
    } else {
      this.loadPictures();
    }

    if (!this.isAnimationRunning && !withoutAnimation) {
      this.startAnimationLoop();
    }

    this.rendered = true;
  },

  rerender: function(slideshowData) {
    this.stopAnimationLoop();
    this.initSlideshowData(slideshowData);
    this.removePictures();
    this.render(true);
  },

  runAnimation: function() {
    if (!this.isAnimationRunning) {
      // Если запускаем анимацию, но виджет еще не отрендерили
      if (!this.rendered) {
        this.render();
      } else {
        this.startAnimationLoop();
      }
    }
  },

  setDelay: function(newDelay) {
    if (newDelay < this.crossfade) {
      console.error('delay mast be longer than crossfade');
      return;
    }
    this.delay = newDelay;
  },

  setCrossfade: function(newCrossfade) {
    if (newCrossfade > this.delay) {
      console.error('delay mast be longer than crossfade');
      return;
    }
    this.crossfade = newCrossfade;
    this.updateCrossfade();
  },

  updateCrossfade: function() {
    this.domPictures.forEach(
      function(picture) {
        picture.css({
          'transition-duration': this.crossfade + 's',
        });
      }.bind(this)
    );
  },

  updatePicture: function(newPicture) {
    var updateIndex = _.findIndex(this.pictures, function(picture) {
      return picture.uuid === newPicture.uuid;
    });

    this.pictures[updateIndex] = newPicture;
    this.domPictures[updateIndex].css({
      'background-image': 'url("' + newPicture.poorUrl + '")',
    });

    this.loadFullSizePicture(newPicture);
  },

  // Вызывается из вне, при изменение позиции слайда в списке
  updatePicturePosition: function(recalcedPictures) {
    this.destroy();
    this.initSlideshowData({ pictures: recalcedPictures, delay: this.delay, crossfade: this.crossfade });
    this.render();
  },

  addPicture: function(picture) {
    // Необходимо для нужный свойст позиционирования
    // если вставляемый слайд будет показан следующим
    var isCurrentSlideLast = this.domPictures.length === this.cursor + 1;
    var zIndex = this.domPictures.length === 0 ? 2 : isCurrentSlideLast ? 1 : 0;
    var display = this.domPictures.length === 0 ? 'block' : isCurrentSlideLast ? 'block' : 'none';

    this.pictures.push(picture);
    this.domPictures.push(
      $('<div>')
        .addClass('slideshow-image fade-in')
        .css({
          'z-index': zIndex,
          display: display,
          'background-image': 'url("' + picture.poorUrl + '")',
          'transition-property': 'opacity',
          'transition-duration': this.crossfade + 's',
          'transition-timing-function': 'linear',
        })
        .appendTo(this.$container)
    );

    this.loadFullSizePicture(picture);
  },

  selectSlide: function(uuid) {
    if (!uuid) {
      return;
    }

    var selectedIndex = this.pictures.findIndex(function(picture) {
      return picture.uuid === uuid;
    });

    if (selectedIndex === -1) {
      console.warn("Did't find slide for uuid:", uuid);
      return;
    }

    if (!this.currentSlide || this.selectedSlide === this.domPictures[selectedIndex]) {
      return;
    }

    if (this.isAnimationRunning) {
      // Второй аргумент выключает отображение первого слайда при стопе анимации
      this.stopAnimationLoop();
    }

    // если текущий и выбранный слайд совпадают то достаточно остановить анимацию
    if (this.currentSlide === this.domPictures[selectedIndex]) {
      return;
    }

    var prevCursor = this.cursor;

    // тк мы переключаемся на другой слайд, то скрываем текущий "следующий" слайд
    if (this.nextSlide) {
      this.nextSlide.css({
        'z-index': 0,
        display: 'none',
      });
    }
    // а выбранный по сути делаем следующим по стилям
    this.domPictures[selectedIndex].css({
      'z-index': 1,
      display: 'block',
    });

    // Меняем текущий курсор на выбранный слайд
    this.currentSlide = this.domPictures[selectedIndex];
    this.cursor = selectedIndex;
    this.selectedSlide = this.domPictures[selectedIndex];

    // Меняем курсор на следующий слайд
    if (this.domPictures.length > 1) {
      if (this.domPictures[selectedIndex + 1]) {
        this.nextSlide = this.domPictures[selectedIndex + 1];
      } else {
        this.nextSlide = this.domPictures[0];
      }
    } else {
      this.nextSlide = null;
    }

    // Скрываем текущий элемент с фиксированной скоростью, затем возвращаем пользовательскую скорость
    this.domPictures[prevCursor].css({
      'transition-duration': '0.3s',
    });
    this.domPictures[prevCursor].removeClass('fade-in').addClass('fade-out');

    // Устанавливаем стили для предыдущего, текущего и следующего слайдов
    setTimeout(
      function() {
        this.domPictures[prevCursor]
          .css({
            'z-index': 0,
            'transition-duration': '0s',
            display: 'none',
          })
          .removeClass('fade-out')
          .addClass('fade-in')
          .css({
            'transition-duration': this.crossfade + 's',
          });

        this.currentSlide.css({
          'z-index': 2,
        });

        if (this.nextSlide) {
          this.nextSlide.css({
            'z-index': 1,
            display: 'block',
          });
        }
      }.bind(this),
      310
    );
  },

  removePictures: function() {
    this.domPictures = [];
    this.preloadPictures = [];
    this.$container.empty();
    this.$preloadContainer.empty();
  },

  removePicture: function(uuid) {
    if (!uuid) {
      return;
    }

    var removeIndex = this.pictures.findIndex(function(picture) {
      return picture.uuid === uuid;
    });

    // Если удаляем текущий слайд и слайдов больше 1 и он не выбран
    if (
      this.domPictures[removeIndex] === this.currentSlide &&
      this.domPictures.length !== 1 &&
      this.selectedSlide !== this.domPictures[removeIndex]
    ) {
      /**
       * Смысл формулы, мы вычитаем из времени отображения слайда
       * разницу между текущем временем и временем начала анимации
       * те сколько прошло от показа слайда, получаем время через
       * которое будет показан следующий слайд
       */
      var timer = this.crossfade * 1000 + this.delay * 1000 + 50;
      timer = this.lastSlideshowAnimationStart
        ? Math.abs(timer - (new Date().getTime() - this.lastSlideshowAnimationStart.getTime()))
        : timer;
      setTimeout(
        function removeElement() {
          if (this.cursor) {
            this.cursor -= 1;
          }
          // На случай если индекс элемента изменился
          var currentRemoveIndex = this.pictures.findIndex(function(picture) {
            return picture.uuid === uuid;
          });
          this.domPictures[currentRemoveIndex].remove();
          this.domPictures.splice(currentRemoveIndex, 1);
          this.pictures.splice(currentRemoveIndex, 1);
        }.bind(this),
        timer
      );
    } else {
      // Если мы удалили следующий слайд, то надо подготовить другой и запомнить его
      if (this.domPictures[removeIndex] === this.nextSlide && this.domPictures.length !== 1) {
        if (this.domPictures[removeIndex + 1]) {
          this.domPictures[removeIndex + 1].css({
            'z-index': 1,
            display: 'block',
          });
          this.nextSlide = this.domPictures[removeIndex + 1];
        } else {
          this.domPictures[0].css({
            'z-index': 1,
            display: 'block',
          });
          this.nextSlide = this.domPictures[0];
        }
      }

      // Если удаляем выбранный слайд и он не один
      if (this.selectedSlide === this.domPictures[removeIndex] && this.nextSlide) {
        var nextSlideIndex = this.domPictures.findIndex(
          function(picture) {
            return picture === this.nextSlide;
          }.bind(this)
        );
        this.currentSlide = this.domPictures[nextSlideIndex];
        this.cursor = nextSlideIndex;
        this.selectedSlide = null;
        this.currentSlide.css({
          'z-index': 2,
        });

        // Меняем курсор на следующий слайд, тк мы еще не удалили текущий слайд, то должно быть минуму 3 слайда
        if (this.domPictures.length > 2) {
          if (this.domPictures[this.cursor + 1]) {
            this.nextSlide = this.domPictures[this.cursor + 1];
          } else {
            this.nextSlide = this.domPictures[0];
          }
        } else {
          this.nextSlide = null;
        }

        if (this.nextSlide) {
          this.nextSlide.css({
            'z-index': 1,
            display: 'block',
          });
        }
        // Запускаем анимацию
        setTimeout(
          function() {
            this.startAnimationLoop();
          }.bind(this),
          0
        );
      }

      this.domPictures[removeIndex].remove();
      this.domPictures.splice(removeIndex, 1);
      this.pictures.splice(removeIndex, 1);
    }
  },

  loadPictures: function() {
    this.pictures.forEach(
      function(picture, i) {
        this.domPictures[i] = $('<div>')
          .addClass('slideshow-image fade-in')
          .css({
            'z-index': i === 0 ? 2 : i === 1 ? 1 : 0,
            display: i === 0 || i === 1 ? 'block' : 'none',
            'background-image': 'url("' + picture.poorUrl + '")',
            'transition-property': 'opacity',
            'transition-duration': this.crossfade + 's',
            'transition-timing-function': 'linear',
          })
          .appendTo(this.$container);
      }.bind(this)
    );

    this.loadFullSizePictures();
  },

  loadFullSizePictures: function() {
    this.preloadPictures = [];
    this.pictures.forEach(
      function(picture, i) {
        this.preloadPictures[i] = $('<img>')
          .addClass('preload-slideshow-image')
          .appendTo(this.$preloadContainer)
          .attr('src', picture.url)
          .on('load', onLoad.bind(this, picture.url, i))
          .on('error', onError.bind(this, i));
      }.bind(this)
    );

    function onLoad(url, i, event) {
      this.domPictures[i].css({
        'background-image': 'url("' + url + '")',
      });
      this.preloadPictures[i].remove();
      // Когда загрузилась 1ая картинка (она первая в слайдшоу) трегирим событие об успешной загрузки
      // нужно для сриншотера
      if (i === 0) {
        this.trigger('loaded');
      }
    }

    function onError(i, event) {
      console.error('on loadFullSizeSLideshowPictures Error', event);
      if (i === 0) {
        this.trigger('loaded');
      }
      if (!RM.screenshot) this.preloadPictures[i].remove();
    }
  },

  // Подгружает полноразмерное изображение для одной картинки по ее индексу
  loadFullSizePicture: function(picture) {
    var preloadPicture = $('<img>')
      .addClass('preload-slideshow-image')
      .appendTo(this.$preloadContainer)
      .attr('src', picture.url)
      .on('load', onLoad.bind(this))
      .on('error', onError.bind(this));

    function onLoad(event) {
      var updateIndex = _.findIndex(this.pictures, function(pictureItem) {
        return picture.uuid === pictureItem.uuid;
      });
      this.domPictures[updateIndex].css({
        'background-image': 'url("' + picture.url + '")',
      });
      preloadPicture.remove();
      this.trigger('loaded');
    }

    function onError(event) {
      console.error('on preloadSlideshowPicture Error', event);
      preloadPicture.remove();
      this.trigger('loaded');
      if (!RM.screenshot) preloadPicture.remove();
    }
  },

  initCursor: function() {
    this.cursor = 0;
    this.currentSlide = this.domPictures[this.cursor];

    if (this.domPictures[this.cursor + 1]) {
      this.nextSlide = this.domPictures[this.cursor + 1];
    }
  },

  startAnimationLoop: function() {
    this.isAnimationRunning = true;
    this.selectedSlide = null;

    if (!this.currentSlide) {
      this.initCursor();
    }

    // Вызываем анимацию по таймеру, setTimeout - что бы менять delay динамически
    this.slideshowLouncher = setTimeout(
      function animationRun() {
        this.fadeAnimation();
        this.slideshowLouncher = setTimeout(animationRun.bind(this), this.delay * 1000);
      }.bind(this),
      this.delay * 1000
    );
  },

  fadeAnimation: function() {
    // Време когда запустилась последняя анимация
    this.lastSlideshowAnimationStart = new Date();
    var prevCursor;

    if (this.domPictures.length < 2) {
      return;
    }

    if (!this.currentSlide || !this.nextSlide) {
      this.initCursor();
    }

    if (this.cursor + 1 < this.domPictures.length) {
      // Скрываем текущий слайд
      this.currentSlide.removeClass('fade-in').addClass('fade-out');
      prevCursor = this.cursor;

      setTimeout(
        function() {
          if (this.domPictures[prevCursor])
            this.domPictures[prevCursor]
              .css({
                'z-index': 0,
                'transition-duration': '0s',
                display: 'none',
              })
              .removeClass('fade-out')
              .addClass('fade-in')
              .css({
                'transition-duration': this.crossfade + 's',
              });
          this.domPictures[prevCursor + 1].css({
            'z-index': 2,
          });

          if (this.domPictures[prevCursor + 2]) {
            this.domPictures[prevCursor + 2].css({
              'z-index': 1,
              display: 'block',
            });
          } else {
            this.domPictures[0].css({
              'z-index': 1,
              display: 'block',
            });
          }
        }.bind(this),
        this.crossfade * 1000
      );

      this.cursor += 1;
      this.currentSlide = this.domPictures[this.cursor];

      if (this.domPictures[this.cursor + 1]) {
        this.nextSlide = this.domPictures[this.cursor + 1];
      } else {
        this.nextSlide = this.domPictures[0];
      }
    } else {
      // Конец списка
      this.currentSlide.removeClass('fade-in').addClass('fade-out');
      prevCursor = this.cursor;
      // Когда он скроется убираем его
      setTimeout(
        function() {
          if (this.domPictures[prevCursor])
            this.domPictures[prevCursor]
              .css({
                'z-index': 0,
                'transition-duration': '0s',
                display: 'none',
              })
              .removeClass('fade-out')
              .addClass('fade-in')
              .css({
                'transition-duration': this.crossfade + 's',
              });
          this.domPictures[0].css({
            'z-index': 2,
          });
          this.domPictures[1].css({
            'z-index': 1,
            display: 'block',
          });
        }.bind(this),
        this.crossfade * 1000
      );

      this.cursor = 0;
      this.currentSlide = this.domPictures[this.cursor];
      this.nextSlide = this.domPictures[this.cursor + 1];
    }
  },

  stopAnimationLoop: function(cb) {
    this.isAnimationRunning = false;

    clearTimeout(this.slideshowLouncher);

    if (cb && typeof cb === 'function') {
      cb();
    }
  },

  destroy: function() {
    this.rendered = false;
    this.stopAnimationLoop(this.removePictures.bind(this));
  },
});

export default BackgroundSlideshowWidget;
