<template>
  <div class="panel">
    <div class="param-caption">Google Maps</div>

    <div class="param" data-param="code">
      <div v-if="!m.api_key || !validateApiKey(m.api_key)">
        <input
          v-model="api_key"
          class="input"
          :class="[{ error: api_key && !validateApiKey(api_key) }]"
          placeholder="API Key"
          minlength="39"
          maxlength="39"
          required
        />
      </div>

      <div v-if="m.api_key && validateApiKey(m.api_key)" class="gmap-api-enabled">
        <span>API Key Enabled</span>

        <div
          ref="remove"
          class="remove map-icon"
          :data-alt="'Delete Google Maps API Key ' + m.api_key"
          data-alt-style="controls-gmap-icon-tooltip"
          @click="onRemove"
        >
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <circle cx="12" cy="12" r="12" />
              <path
                d="M12 10.586l4.536-4.536a1 1 0 0 1 1.414 1.414L13.414 12l4.536 4.536a1 1 0 0 1-1.414 1.414L12 13.414 7.464 17.95a1 1 0 0 1-1.414-1.414L10.586 12 6.05 7.464A1 1 0 0 1 7.464 6.05L12 10.586z"
                fill-rule="nonzero"
              />
            </g>
          </svg>
        </div>
        <a
          ref="question-enabled"
          href="http://help.readymag.com/hc/en-us/articles/360020526932-Google-Maps"
          rel="noreferrer noopener"
          target="_blank"
          class="question map-icon"
          data-alt-style="controls-gmap-icon-tooltip"
        >
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <circle cx="12" cy="12" r="12" />
              <path
                d="M8 9.259C8.06 7.371 9.294 6 11.542 6 13.615 6 15 7.259 15 9.02c0 1.219-.575 2.072-1.604 2.717-.992.614-1.272 1.02-1.272 1.809V14H10.37l-.008-.542c-.068-1.195.37-1.912 1.415-2.558.939-.597 1.256-1.02 1.256-1.808 0-.83-.635-1.427-1.589-1.427-.976 0-1.604.622-1.665 1.594H8zM11.5 18c-.904 0-1.5-.592-1.5-1.495 0-.913.596-1.505 1.5-1.505.922 0 1.5.592 1.5 1.505 0 .903-.578 1.495-1.5 1.495z"
              />
            </g>
          </svg>
        </a>
      </div>

      <a
        v-if="!m.api_key && !api_key"
        ref="question-disabled"
        href="http://help.readymag.com/hc/en-us/articles/360020526932-Google-Maps"
        rel="noreferrer noopener"
        target="_blank"
        class="question map-icon"
        data-alt-style="controls-gmap-icon-tooltip"
      >
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle cx="12" cy="12" r="12" />
            <path
              d="M8 9.259C8.06 7.371 9.294 6 11.542 6 13.615 6 15 7.259 15 9.02c0 1.219-.575 2.072-1.604 2.717-.992.614-1.272 1.02-1.272 1.809V14H10.37l-.008-.542c-.068-1.195.37-1.912 1.415-2.558.939-.597 1.256-1.02 1.256-1.808 0-.83-.635-1.427-1.589-1.427-.976 0-1.604.622-1.665 1.594H8zM11.5 18c-.904 0-1.5-.592-1.5-1.495 0-.913.596-1.505 1.5-1.505.922 0 1.5.592 1.5 1.505 0 .903-.578 1.495-1.5 1.495z"
            />
          </g>
        </svg>
      </a>
    </div>

    <rm-select
      v-model="m.current_type"
      class="map-type-select"
      :class="[{ 'no-line': m.current_type === 'directions' || m.current_type === 'custom' }]"
      :options="mapTypes"
      arrow-size="big"
    ></rm-select>

    <div class="types-param">
      <div v-if="m.current_type == 'custom'" class="type-param" data-type="custom">
        <div class="param" data-param="code">
          <textarea
            v-model="custom_json"
            class="custom-json"
            :class="{ error: is_json_invalid, empty: !custom_json }"
            placeholder="Paste JSON code"
            required
          ></textarea>
        </div>

        <div class="wizard-link">
          <a target="_blank" href="https://mapstyle.withgoogle.com/">Launch Maps Wizard</a>
        </div>
      </div>

      <div v-if="m.current_type == 'directions'" class="type-param" data-type="directions">
        <div class="param" data-param="route_type">
          <div class="route-switcher">
            <div
              v-for="dir in directions"
              class="route-item"
              :class="{ active: m.direction_route_type === dir.value }"
              @click="m.direction_route_type = dir.value"
            >
              <div class="route-icon" :class="[dir.icon]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="map-controls">
      <div class="map-control">
        <div class="caption">Controls</div>
        <rm-switcher
          v-model="m.show_controls"
          class="controls-switcher"
          color-off="#B8B8B8"
          color-on="#0080FF"
          :height="24"
          :width="40"
        ></rm-switcher>
      </div>
      <div class="map-control">
        <div class="caption">Zoom on scroll</div>
        <rm-switcher
          v-model="m.scrollwheel"
          class="controls-switcher"
          color-off="#B8B8B8"
          color-on="#0080FF"
          :height="24"
          :width="40"
        ></rm-switcher>
      </div>
    </div>
  </div>
</template>

<script>
import _ from '@rm/underscore';
import Panel from './panel.vue';
import RmSwitcher from '../../common/rm-switcher.vue';
import RmSelect from '../../common/rm-select.vue';

export default {
  mixins: [Panel],
  components: { RmSwitcher, RmSelect },

  mounted: function() {
    this.custom_json = this.m.custom_json;
    this.api_key = this.m.api_key;
  },

  data: function() {
    return {
      m: {
        direction_route_type: 'driving',
        custom_json: '',
        api_key: '',
        scrollwheel: true,
      },

      mapTypes: [
        { value: 'standard', caption: 'Standard' },
        { value: 'directions', caption: 'Directions' },
        { value: 'custom', caption: 'Custom map' },
      ],
      directions: [
        { value: 'driving', icon: 'car' },
        { value: 'transit', icon: 'train' },
        { value: 'walking', icon: 'walk' },
        { value: 'bicycling', icon: 'bicycle' },
      ],
      custom_json: '',
      api_key: '',
      is_json_invalid: false,
    };
  },

  methods: {
    validateJSON: function(text) {
      try {
        JSON.parse(text);
      } catch (e) {
        return false;
      }
      return true;
    },
    validateApiKey: function(text) {
      if (!text) return;
      return text.length === 39;
    },
    onRemove: function() {
      this.api_key = '';
      this.m.api_key = '';
    },
  },

  watch: {
    custom_json: _.debounce(function(text) {
      if (!text || this.validateJSON(text)) {
        this.m.custom_json = text;
        this.is_json_invalid = false;
      } else {
        this.is_json_invalid = true;
      }
    }, 500),
    api_key: _.debounce(function(text) {
      if (!text || this.validateApiKey(text)) {
        this.m.api_key = text;
      }
    }, 500),
    'm.api_key': function() {
      this.api_key = this.m.api_key;
    },
  },
};
</script>
