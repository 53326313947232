/**
 * Конструктор для контрола группирует выделенные виджеты
 */
import Vue from 'vue';
import _ from '@rm/underscore';
import ControlClass from '../control';
import { Utils } from '../../common/utils';
import templates from '../../../templates/constructor/controls/common_pack.tpl';

const CommonPack = ControlClass.extend({
  name: 'common_pack', // должно совпадать с классом вьюхи

  className: 'control common_pack',

  initialize: function(params) {
    this.template = templates['template-constructor-control-common_pack'];

    this.initControl(params);
  },

  /**
   * Переопределяем в потомках, если надо.
   */
  bindLogic: function() {
    RM.constructorRouter.bindGlobalKeyPress([
      { key: 'g', handler: this.onClick, optionKeys: !Modernizr.mac ? 'ctrl' : 'meta' },
    ]);
  },

  /**
   * Переопределяем в потомках, если надо.
   */
  unBindLogic: function() {
    RM.constructorRouter.unbindGlobalKeyPress('g', this.onClick);
  },

  // Показываем его когда в выделении больше одного виджета (группу виджетов считаем за один)
  restrictions: function(workspace) {
    var packs = {};

    var moreThanOne =
      _.reduce(
        workspace.getSelectedBlocks(),
        function(memo, block) {
          // если у виджета нет pack_id то просто добавляем  к кол-ву выделенных элементов
          // если же pack_id есть, тогда виджеты с одинаковым pack_id считаем за один раз
          var pack_id = block.model.get('pack_id'),
            addition = pack_id ? (packs[pack_id] ? 0 : 1) : 1;

          packs[pack_id] = 1;

          return memo + addition;
        },
        0
      ) > 1;

    var sameFixedPosition =
      _.uniq(
        _.map(workspace.getSelectedBlocks(), function(b) {
          var pos = b.model.get('fixed_position');
          pos = pos || false;
          return pos;
        })
      ).length <= 1;
    // /gwidget - возможно здесь же запрещать показ, если в выделении находятся глобальные и/или отвязанные виджеты
    return moreThanOne && sameFixedPosition;
  },

  /**
   * Переопределяем метод нажатия на иконку контрола
   */
  onClick: function() {
    var pack_id = Utils.generateUUID();

    Vue.nextTick(
      function() {
        var new_data = _.map(this.models, function(model) {
          var data = {
            _id: model.id,
            pack_id: pack_id,
          };

          // Всегда снимаем блокировку с блоков при изменении группировки. То же самое делается в common_unpack.js и block.js в onPackChange
          if (model.get('is_locked')) {
            model.set('is_locked', false);
            _.extend(data, { is_locked: false });
          }

          return data;
        });

        this.master.workspace.save_group(new_data);

        // закрываем другие открытые контролы
        this.master.deselect();
      }.bind(this)
    );
  },
});

export default CommonPack;
