import _ from '@rm/underscore';
import Device from '../../../common/device';

(function($) {
  $.fn.RMAltText = function(options) {
    var tooltips = [];
    var CSS_CLASS = 'rmalttext';
    var OPPOSITE_DIRECTIONS = {
      top: 'bottom',
      bottom: 'top',
      left: 'right',
      right: 'left',
    };

    var init = function($target) {
      var settings = {
        direction: 'top',
        offset: 9,
        manual: false,
        // html-элемент, в который помещается html-элемент тултипа
        container: null,
        keepInBounds: false,
      };

      if (options) $.extend(settings, options);
      var $container = settings.container && settings.container.length ? settings.container : $('body');

      // Если нет css-класса и не передан текст, не делаем тултип
      if ($target.hasClass(CSS_CLASS) && !settings.text) return;

      //на тач устройствах отключаем alt text
      if (!Device.isDesktop) return;

      $target.addClass(CSS_CLASS);

      var altText = $target.attr('data-alt') || settings.text;

      if (!altText) return;

      var dom =
        '<div class="rmalttext-wrapper">\
										<div class="rmalttext-content">' +
        altText +
        '</div>\
										<div class="rmalttext-corner-wrapper"><div class="rmalttext-corner"></div></div>\
									</div>';
      var $dom;
      var showHideTimeout;
      var checkVisibilityInterval;

      // Если не нужно управлять тултипом вручную, будем показывать / прятать при ховере, прятать при клике
      if (!settings.manual) {
        $target
          .on('mouseenter', mouseEnter)
          .on('mouseleave', mouseLeave)
          .on('click', hide)
          .on('rmalt-hide', hide);
      }

      $target.on('DOMNodeRemovedFromDocument', hide);

      function mouseEnter(e) {
        var $target = $(e.currentTarget);
        var showTimeout = 800,
          immediately = false;

        if (window.rmalt_current_shown && window.rmalt_current_shown !== $target) {
          window.rmalt_current_shown.trigger('rmalt-hide');
          showTimeout = 0;
          immediately = true;
        }

        showHideTimeout = setTimeout(show.bind($target[0], immediately), showTimeout);
      }

      function mouseLeave() {
        clearTimeout(showHideTimeout);
        clearInterval(checkVisibilityInterval);

        showHideTimeout = setTimeout(function() {
          window.rmalt_current_shown = null;

          $dom &&
            $dom.fadeOut(150, function() {
              $dom.remove();
              $dom = undefined;
            });
        }, 400);
      }

      function show(immediately) {
        var fadeTime = immediately ? 0 : 200;

        clearTimeout(showHideTimeout);
        clearInterval(checkVisibilityInterval);

        if (
          $target.hasClass('rmalttext-disable') ||
          $target.hasClass('active') ||
          $target.hasClass('disabled') ||
          $target.hasClass('no-hover')
        )
          return;

        if (!$dom) {
          $dom = $(dom).appendTo($container);
          $dom.find('.rmalttext-content').text($target.attr('data-alt'));

          var cssClass = $target.attr('data-alt-style') || settings.cssClass;
          cssClass && $dom.addClass(cssClass);

          $dom.css({ display: 'none' });
          updatePosition();
        }

        $dom.fadeIn(fadeTime);

        window.rmalt_current_shown = $target;

        // Событие mouseleave происходит не всегда. Из-за этого бывает,
        // что попап остается висеть на экране. Будем периодически проверять дополнительно
        // видимость на экране элемента, к которому прицеплен попап
        checkVisibilityInterval = setInterval(
          function() {
            if ($target instanceof $ && isElementVisible($target)) {
              return;
            } else {
              hide();
            }
          }.bind($target),
          200
        );
      }

      function isInBounds(box) {
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();
        return box.top >= 0 && box.bottom <= windowHeight && box.left >= 0 && box.right <= windowWidth;
      }

      function getPosition(dir, data) {
        var left;
        var top;
        dir = dir || settings.direction;
        if (dir == 'top') {
          left = data.targetX + (data.targetW - data.tipW) / 2;
          top = data.targetY - data.offset - data.tipH;
        }

        if (dir == 'bottom') {
          left = data.targetX + (data.targetW - data.tipW) / 2;
          top = data.targetY + data.targetH + data.offset;
        }

        if (dir == 'left') {
          left = data.targetX - data.offset - data.tipW;
          top = data.targetY + (data.targetH - data.tipH) / 2;
        }

        if (dir == 'right') {
          left = data.targetX + data.targetW + data.offset;
          top = data.targetY + (data.targetH - data.tipH) / 2;
        }
        left = Math.floor(left);
        top = Math.floor(top);

        return {
          left: left,
          top: top,
          // Это позиция правой и нижней стороны (как возвращает getBoundingClientRect), а не значения для css position right и bottom.
          right: left + data.tipW,
          bottom: top + data.tipH,
          relativeLeft: left - data.containerX,
          relativeTop: top - data.containerY,
        };
      }

      function updatePosition() {
        if (!$dom) {
          this.destroy();
          return;
        }

        var containerOffset = $container[0].getBoundingClientRect();
        var targetBox = $target[0].getBoundingClientRect();
        var direction = $target.attr('data-alt-pos') || settings.direction;

        var data = {
          offset: $target.attr('data-alt-offset') ? $target.attr('data-alt-offset') - 0 : settings.offset,
          tipW: $dom.outerWidth(false),
          tipH: $dom.outerHeight(false),
          targetX: targetBox.left,
          targetY: targetBox.top,
          containerX: containerOffset.left,
          containerY: containerOffset.top,
          targetW: targetBox.width,
          targetH: targetBox.height,
        };

        var position = getPosition(direction, data);

        // Если нужно, убедимся, что тултип внутри границ экрана
        if (settings.keepInBounds && !isInBounds(position)) {
          var oppositeDirection = OPPOSITE_DIRECTIONS[direction];
          var alternativeDirections = [oppositeDirection].concat(
            _.without(_.keys(OPPOSITE_DIRECTIONS), direction, oppositeDirection)
          );

          // Найдём сторону / направление, которое видно
          direction = _.find(alternativeDirections, function(alternativeDirection) {
            return isInBounds(getPosition(alternativeDirection, data));
          });
          direction = direction || 'top';
          position = getPosition(direction, data);
        }

        $dom.addClass('rmalttext-' + direction);
        $dom.css({
          left: position.relativeLeft,
          top: position.relativeTop,
        });
      }

      function hide() {
        clearTimeout(showHideTimeout);
        clearInterval(checkVisibilityInterval);

        window.rmalt_current_shown = null;

        $dom && $dom.remove();

        $dom = undefined;
      }

      function destroy() {
        hide();
        $target.removeClass(CSS_CLASS);
        $target
          .off('mouseenter', mouseEnter)
          .off('mouseleave', mouseLeave)
          .off('click', hide)
          .off('rmalt-hide', hide)
          .off('DOMNodeRemovedFromDocument', hide);
      }

      function isElementVisible(el) {
        if (el instanceof $) {
          el = el.get(0);
        }

        var rect = el.getBoundingClientRect();

        return rect.width > 0 && rect.height > 0;
      }

      return {
        hide: hide,
        show: show,
        update: updatePosition,
        destroy: destroy,
        getElement: function() {
          return $dom;
        },
      };
    };

    this.each(function() {
      var tooltip = init($(this));
      tooltips.push(tooltip);
    });

    return {
      show: function() {
        tooltips.forEach(function(tooltip) {
          tooltip && tooltip.show();
        });
      },
      hide: function() {
        tooltips.forEach(function(tooltip) {
          tooltip && tooltip.hide();
        });
      },
      destroy: function() {
        tooltips.forEach(function(tooltip) {
          tooltip && tooltip.destroy();
        });
      },
      update: function() {
        tooltips.forEach(function(tooltip) {
          tooltip && tooltip.update();
        });
      },
      getElement: function() {
        return tooltips && tooltips[0] && tooltips[0].getElement();
      },
    };
  };
})(jQuery);
