/**
 * Конструктор для контрола настроек картинки
 */
import VueControlClass from '../vue-control';
import PreloadDesignImages from '../../common/preload-design-images';

const PictureSettings = VueControlClass.extend({
  name: 'picture_settings',

  alt: 'Picture',

  panelComponent: 'panel-picture-settings',

  select: function() {
    VueControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-picture_settings');
  },
});

export default PictureSettings;
