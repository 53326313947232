/**
 * Конструктор для виджета текста
 */
import $ from '@rm/jquery';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/text_align.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const TextAlign = ControlClass.extend({
  name: 'text_align', // должно совпадать с классом вьюхи

  className: 'control text_align',

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_align'];

    this.initControl(params);
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-text_align');
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.$panel.on('click', '.left', this.left);
    this.$panel.on('click', '.center', this.center);
    this.$panel.on('click', '.right', this.right);
    this.$panel.on('click', '.justify', this.justify);

    // случаем событие изменения стиля выделения, генерируется редактором
    this.blocks && this.blocks[0].on('selection_styles_changed', this.selectionStylesChanged);

    // первоначально проставляем состояние контрола в зависимости от текущего стиля выделения в редакторе
    this.blocks && this.selectionStylesChanged(this.blocks[0].cur_selection_styles);
  },

  unBindLogic: function() {
    this.$panel.off('click', '.left', this.left);
    this.$panel.off('click', '.center', this.center);
    this.$panel.off('click', '.right', this.right);
    this.$panel.off('click', '.justify', this.justify);

    this.blocks && this.blocks[0].off('selection_styles_changed', this.selectionStylesChanged);
  },

  selectionStylesChanged: function(styles) {
    if (this.blocks[0].initiatorControl == this.name) return;
    this.updateState(styles);
  },

  updateState: function(param) {
    if (param['text-align']) {
      this.$panel.find('.left').toggleClass('curr', param['text-align'] == 'left');
      this.$panel.find('.center').toggleClass('curr', param['text-align'] == 'center');
      this.$panel.find('.right').toggleClass('curr', param['text-align'] == 'right');
      this.$panel.find('.justify').toggleClass('curr', param['text-align'] == 'justify');

      this.$icon.toggleClass(
        'left',
        param['text-align'] != 'right' && param['text-align'] != 'center' && param['text-align'] != 'justify'
      );
      this.$icon.toggleClass('right', param['text-align'] == 'right');
      this.$icon.toggleClass('center', param['text-align'] == 'center');
      this.$icon.toggleClass('justify', param['text-align'] == 'justify');
    }
  },

  left: function(e) {
    var param = { 'text-align': 'left' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'text-align': 'inherit' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleParagraph', param);
    }
  },

  center: function(e) {
    var param = { 'text-align': 'center' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'text-align': 'inherit' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleParagraph', param);
    }
  },

  right: function(e) {
    var param = { 'text-align': 'right' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'text-align': 'inherit' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleParagraph', param);
    }
  },

  justify: function(e) {
    var param = { 'text-align': 'justify' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'text-align': 'inherit' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleParagraph', param);
    }
  },
});

export default TextAlign;
