/**
 * Виджет Google Maps
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import InitUtils from '../../common/init-utils';
import Scale from '../../common/scale';

const gmapsWidget = WidgetClass.extend({
  DO_NOT_WAIT_FOR_PRELOAD: true, // флаг что при прелоадинге виджетов ждать его загрузки не надо (но не для скриншотера)

  // достаточно версии прототипа
  // initialize: function(data, page) {},

  render: function() {
    this.makeBox('widget-gmaps');

    this.$el.html($('<div class="map-container"></div>'));

    this.rendered = true;

    var key = this.api_key;
    if (this.mag && this.mag.opts && this.mag.opts.gmaps_key && this.mag.opts.gmaps_key !== this.api_key) {
      key = this.mag.opts.gmaps_key;
    }

    InitUtils.initGMapsAPI(
      key,
      function() {
        if (this.destroyed) return;

        _.defer(
          function() {
            if (this.destroyed) return;

            var geocoder;

            if (Scale.isOn()) {
              this.scale();
            }

            this.map = new google.maps.Map(this.$('.map-container').get(0), {
              zoom: this.map_zoom,
              center: new google.maps.LatLng(this.center_lat, this.center_lng),
              mapTypeId: this.map_type_id || google.maps.MapTypeId.ROADMAP,
              disableDefaultUI: !this.show_controls,
              streetViewControl: false,
              scrollwheel: this.scrollwheel === undefined || !!this.scrollwheel, // Для старых виджетов если undefined, то это тоже true
            });

            // Ставим маркер и создаем попап с адресом
            if (this.marker_lat && this.marker_lng && this.current_type !== 'directions') {
              this.marker = new google.maps.Marker({
                map: this.map,
                position: new google.maps.LatLng(this.marker_lat, this.marker_lng),
              });

              google.maps.event.addListener(
                this.marker,
                'click',
                function() {
                  this.infowindow && this.infowindow.open(this.map, this.marker);
                }.bind(this)
              );

              this.infowindow = new google.maps.InfoWindow({
                maxWidth: this.w - 100, // Чтобы попап не выходил за границы виджета
              });

              // Если есть сохраненный нормальный адрес (маркер не таскали вручную), то используем его
              if (this.formatted_address) {
                this.infowindow.setContent(this.formatted_address);
              } else {
                // Иначе делаем обратное геокодирование по координатам маркера
                geocoder = new google.maps.Geocoder();
                geocoder.geocode(
                  { latLng: new google.maps.LatLng(this.marker_lat, this.marker_lng) },
                  function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                      if (results[0]) {
                        this.infowindow.setContent(results[0].formatted_address);
                      }
                    }
                  }.bind(this)
                );
              }
            }

            // Строим маршрут
            if (
              this.current_type == 'directions' &&
              this.direction_start_lat &&
              this.direction_start_lng &&
              this.direction_end_lat &&
              this.direction_end_lng &&
              this.direction_route_type
            ) {
              this.directionsService = new google.maps.DirectionsService();
              this.directionsDisplay = new google.maps.DirectionsRenderer({
                preserveViewport: true,
              });

              this.directionsService.route(
                {
                  origin: new google.maps.LatLng(this.direction_start_lat, this.direction_start_lng),
                  destination: new google.maps.LatLng(this.direction_end_lat, this.direction_end_lng),
                  travelMode: google.maps.TravelMode[this.direction_route_type.toUpperCase()],
                },
                function(result, status) {
                  if (status == google.maps.DirectionsStatus.OK) {
                    this.directionsDisplay.setDirections(result);
                    this.directionsDisplay.setMap(this.map);

                    google.maps.event.addListener(
                      this.map,
                      'tilesloaded',
                      function() {
                        this.widgetIsLoaded();
                      }.bind(this)
                    );
                  }
                }.bind(this)
              );
            } else {
              google.maps.event.addListener(
                this.map,
                'tilesloaded',
                function() {
                  this.widgetIsLoaded();
                }.bind(this)
              );
            }

            // Кастомный стиль карты
            if (this.current_type == 'custom' && this.custom_json) {
              this.map.setOptions({ styles: JSON.parse(this.custom_json) });
            }
          }.bind(this)
        );
      }.bind(this)
    );

    return this;
  },

  scale: function() {
    var scale = this.mag.getScale(this.page.pageViewport);
    var $mapContainer = this.$('.map-container');
    if (Scale.isZoom()) {
      $mapContainer.css('zoom', 1 / scale);
    } else if (Scale.isTransform()) {
      var scalePercent = scale * 100;
      var margin = (scalePercent - 100) / 2;
      $mapContainer.css({
        transform: 'scale(' + 1 / scale + ') translate(-' + margin + '%, -' + margin + '%)',
        width: scalePercent + '%',
        height: scalePercent + '%',
      });
    }
  },

  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  // достаточно версии прототипа
  // isValid: function() {},

  start: function() {
    this.started = true;

    if (!this.rendered || this.destroyed || !this.map) {
      return this;
    }

    // Карты рендерятся некорректно (с большим сдвигом) в изначально невидимых контейнерах
    // По-этому при перелистывании на страницу специально инициируем перерисовку карты
    google.maps.event.trigger(this.map, 'resize');

    this.map.setCenter(new google.maps.LatLng(this.center_lat, this.center_lng));

    return this;
  },

  // достаточно версии прототипа
  // stop: function() {},

  destroy: function() {
    this.map && google.maps.event.clearListeners(this.map, 'tilesloaded');

    this.marker && google.maps.event.clearListeners(this.marker, 'click');

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default gmapsWidget;
