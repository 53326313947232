/**
 * Конструктор для контрола выбора продукта
 */
import VueControlClass from '../vue-control';

const ProductSelector = VueControlClass.extend({
  name: 'product_selector',
  alt: 'Select product',
  panelComponent: 'panel-product-selector',
});

export default ProductSelector;
