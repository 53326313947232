/**
 * Набор полезных функций для работы с анимациями
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import Scale from './scale';
import { Utils } from './utils';
import Device from './device';

const AnimationUtils = {
  LOOP_TYPES: {
    swing: 'swing',
    repeat: 'repeat',
  }, // Тип повторения анимации: "туда и обратно" (12321) и с начала (123123)
  POINTER_EVENTS: ['click', 'hover'],
  EXTERNAL_TRIGGER_BLOCK_EXCLUDE: ['facebook', 'twitter', 'form', 'gmaps', 'slideshow', 'audio', 'video'],
  ANIMATION_BLOCK_EXCLUDE: ['facebook', 'twitter'],
  POINTER_EVENTS_BLOCK_EXCLUDE: ['form', 'gmaps', 'slideshow', 'audio', 'video'],
  DEFAULT_LOOP_TYPE: 'swing', // Тип повторения анимации по умолчанию
  ACTIVE_TRIGGER_CLASS: 'active-trigger', // Класс для подсветки триггер-блока
  ACTIVE_TRIGGER_REMOVE_CLASS: 'active-trigger-remove', // Класс для подсветки триггер-блока
  ACTIVE_TRIGGER_HIGHLIGHT_CLASS: 'active-trigger-highlight', // Класс для подсветки триггер-блока
  ANIMATIONS_PLAYED_STORAGE_KEY: 'animationsPlayed', // Запись в localStorage или куках о проигранных анимациях (только для тех которые проигрываются один раз)

  DEG_TO_RAD: Math.PI / 180,

  // получаем префиксы анимаций под текущий браузер, и смотрим есть ли вообще анимации (но по идее в любом браузере в котором мы работаем они должны быть)
  // самопал, помесь из статей:
  // http://callmenick.com/post/listen-for-css-animation-events-with-javascript
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Detecting_CSS_animation_support
  // http://stackoverflow.com/questions/7212102/detect-with-javascript-or-jquery-if-css-transform-2d-is-availabl
  ANIMATION_PROPERTIES: (function() {
    var animationSupported = false,
      animationJS = 'animation',
      animationIterationJS = 'AnimationIteration',
      animationEndJS = 'AnimationEnd',
      animationTimingFunctionCSS = 'animation-timing-function',
      keyframeCSS = '@keyframes',
      transformJS = 'transform',
      transformCSS = 'transform',
      domPrefixes = 'Webkit Moz O ms Khtml'.split(' '),
      eventPrefixes = 'webkit moz o MS кhtml'.split(' '),
      elm = document.createElement('div');

    if (elm.style.animationName != undefined) {
      animationSupported = true;
      animationIterationJS = animationIterationJS.toLowerCase();
      animationEndJS = animationEndJS.toLowerCase();
    } else {
      for (var i = 0; i < domPrefixes.length; i++) {
        if (elm.style[domPrefixes[i] + 'AnimationName'] != undefined) {
          animationJS = domPrefixes[i] + 'Animation';
          animationIterationJS = eventPrefixes[i] + animationIterationJS;
          animationEndJS = eventPrefixes[i] + animationEndJS;
          animationTimingFunctionCSS = '-' + domPrefixes[i].toLowerCase() + '-' + animationTimingFunctionCSS;
          keyframeCSS = '@-' + domPrefixes[i].toLowerCase() + '-keyframes';
          animationSupported = true;
          break;
        }
      }
    }

    if (elm.style.transform == undefined) {
      for (var i = 0; i < domPrefixes.length; i++) {
        if (elm.style[domPrefixes[i] + 'Transform'] != undefined) {
          transformJS = domPrefixes[i] + 'Transform';
          transformCSS = '-' + domPrefixes[i].toLowerCase() + '-transform';
          break;
        }
      }
    }

    elm = null;

    return {
      animationSupported: animationSupported,
      animationJS: animationJS,
      animationIterationJS: animationIterationJS,
      animationEndJS: animationEndJS,
      animationTimingFunctionCSS: animationTimingFunctionCSS,
      keyframeCSS: keyframeCSS,
      transformJS: transformJS,
      transformCSS: transformCSS,
    };
  })(),

  createAnimationTimeline: function(params) {
    return new Timeline(params);
  },

  // запоняем объект анимация данными без пробелов, если в каком-то шаге есть выключенные эффекты, из значения возьмется и последнего шага у которого они есть или из дефолта если нет таких шагов
  // такая же функция есть во вьювере
  getNormalizedAnimation: function(animation, isFixed, isFullwidth, isFullheight, pageScale) {
    var currentParams = {
        dx: 0,
        dy: 0,
        from_opacity: 100,
        opacity: 100,
        from_rotate: 0,
        rotate: 0,
        from_scale: 100,
        scale: 100,
      },
      params = {
        use_move: ['dx', 'dy'],
        use_opacity: ['from_opacity', 'opacity'],
        use_rotate: ['from_rotate', 'rotate'],
        use_scale: ['from_scale', 'scale'],
      },
      res = _.extend({}, animation);

    // у скрол анимаций нет никаких лупов
    if (res.type == 'scroll') {
      res.loop = false;
    }

    res.steps = [];

    var prevStep = { dx: 0, dy: 0 };

    for (var i = 0; i < animation.steps.length; i++) {
      var step = _.extend({}, animation.steps[i]);

      // если у текущего шага отключен эффект , тогда занесем в текущий шаг подсчитанное на данный момент значения возьмется и последнего шага у которого они есть или из дефолта если нет таких шагов
      // [0] и [1] просто хардкодим, потому что знаем что у каждого параметра включения эффекта два параметра за которые этот переключатель отвечает
      _.each(
        params,
        _.bind(function(value, param) {
          if (!step[param]) {
            step[value[0]] = currentParams[value[0]];
            step[value[1]] = currentParams[value[1]];
          } else {
            // иначе, запомним последние заданные значения эффекта
            currentParams[value[0]] = step[value[0]];
            currentParams[value[1]] = step[value[1]];
          }
        }, this)
      );

      // для фулвидхов не берем в расчет dx с поворот
      // они могут оказаться в модели, например анимации сначала были для обычного объекта, а там можно и поворот и сдвиг по x
      // а потом его переделали в фулвидх и не трогали анимации
      if (isFullwidth) {
        step.dx = 0;
        step.rotate = 0;
        step.from_rotate = 0;
      }

      if (isFullheight) {
        step.dy = 0;
        step.rotate = 0;
        step.from_rotate = 0;
      }

      // задержка в px только для скрол анимаций для всех шагов кроме первого (для фикседов и для первого)
      if (res.type == 'scroll' && !isFixed && i == 0) {
        step.delay_px = 0;
      }

      // если у страницы есть скейл и мы имеем дело с фиксед виджетов, нам надо отскейлить dx и dy
      // с фикседами у нас всегда отдельная тема, мы их отдельно скейлим и располагаем на экране, индивидуально
      // и сдвиги контейнеров анимаций тоже надо отдельно скейлить
      if (pageScale != 1 && isFixed) {
        step.dx *= pageScale;
        step.dy *= pageScale;
      }

      // расчитываем сколько шагов анимации займет шаг для скрол анимации
      // один шаг это сдвиг скрола на один пиксел
      // в основном используется для параоакса, поэтому мы изначално производим привязку
      // к длине сдвига, т.е. если в настройка шаг сдвинут на 50px вниз и нисколько в стороны, то он пройдет это расстояние за 50px скрола
      // ну тут еще надо умножать на коэффициент speed
      // если сдвинули на 50px вниз и 50px влево (70px длина) то этот сдвиг произойдет за 70px скрола (если speed = 1 то визуально блок будет отставать от скрола, ведь скрол пройдет 70px а блок только 50px по вертикали (ну и вбок 50px))
      // проблема начинается если двига нет вообще, а есть только скейл, опасити или скрол
      // ну тут мы просто завязываемся на то, что эта анимация должна пройти за 300px скрола (от балды)
      // если кого-то это не устроит, то всегда можно потюнить параметром speed
      if (res.type == 'scroll') {
        step.calcedDuration = getStepDuration(step, prevStep);
        // Скейлить delay нужно и для хрома, и для FF, поэтому не указываем метод zoom или transform для .normalize()
        step.calcedDelay = step.delay_px ? step.delay_px * Scale.normalize(pageScale) : 0;
      } else {
        step.calcedDuration = step.duration || 0.001;
        step.calcedDelay = step.delay || 0;
      }

      // мы оптимизируем JSON при паблише и удаляем лишние свойства или хранящие дефолтные значения, тут мы их восстановим
      // https://docs.google.com/document/d/158fJc8gRM14ukbQQPOTNQYvf8TKtuUr10mFY_KzGMZ8/edit
      step.acceleration = step.acceleration || 'none';

      res.steps.push(step);

      prevStep = step;
    }

    res.trigger =
      animation.trigger && !_.isArray(animation.trigger) ? [animation.trigger] : _.clone(animation.trigger) || [];

    return res;

    function getStepDuration(step, prevStep) {
      var dx = step.dx - prevStep.dx,
        dy = step.dy - prevStep.dy,
        res;

      if (dx != 0 || dy != 0) {
        res = Math.pow(dx * dx + dy * dy, 0.5) / step.speed;
      } else {
        res = 300 / step.speed;
      }

      return Math.ceil(res); // чтоб всегда было больше 0 и целочисленное
    }
  },

  /**
   * Нормализует значение loop у анимации, приводит его к виду swing|repeat|false.
   * Это сделано потому, что раньше значение анимации было true|false.
   * @param {Boolean|String} value
   * @returns {Boolean|String}
   */
  normalizeLoopValue: function(value) {
    return value ? this.LOOP_TYPES[value] || this.DEFAULT_LOOP_TYPE : false;
  },

  /**
   * Определяет, разрешён ли внешний триггер при данном типе событий
   * @param {String} type Тип события
   * @returns {Boolean}
   */
  isExternalTriggerAllowed: function(type) {
    return this.isPointerEvent(type);
  },

  /**
   * Приводит триггер к массиву строк
   * @param {String|Object} trigger
   * @return {*}
   */
  normalizeAnimationTriggers: function(trigger) {
    return trigger && !_.isArray(trigger) ? [trigger] : _.clone(trigger) || [];
  },

  /**
   * Определяет, что это событие — событие указателя
   * @param {String} type Тип события
   * @returns {Boolean}
   */
  isPointerEvent: function(type) {
    return this.POINTER_EVENTS.indexOf(type) !== -1;
  },

  /**
   * Определяет, может ли блок стать триггером
   * @param {View|Model} trigger Блок-триггер или его модель
   * @param {View|Model} [animatedBlock] Анимируемый блок или его модель
   * @returns {Boolean}
   */
  canBeExternalTrigger: function(trigger, animatedBlock) {
    var triggerAttributes = trigger.attributes || (trigger.model && trigger.model.attributes) || {};
    var blockAttributes = animatedBlock.attributes || (animatedBlock.model && animatedBlock.model.attributes) || {};
    var hasAllowedType =
      triggerAttributes.type && this.EXTERNAL_TRIGGER_BLOCK_EXCLUDE.indexOf(triggerAttributes.type) === -1;
    // Если указан анимируемый блок, посмотрим на его глобальность: global не могут быть триггерами для above-all
    // (потому что above-all один, а global — несколько).
    // Global могут быть триггерами для обычных виджетов, потому что в контексте своей страницы они уникальны.
    var isContextIncompatible =
      animatedBlock && blockAttributes.is_above && triggerAttributes.is_global && !triggerAttributes.is_above;
    return hasAllowedType && !isContextIncompatible;
  },

  /**
   * Определяет, может ли у блока быть анимация
   * @param {View|Model} block Блок или его модель
   * @returns {Boolean}
   */
  canHaveAnimation: function(block) {
    var attributes = block.attributes || (block.model && block.model.attributes) || {};
    return attributes.type && this.ANIMATION_BLOCK_EXCLUDE.indexOf(attributes.type) === -1;
  },

  /**
   * Определяет, могут ли у блока быть pointer-события (click и hover)
   * @param {View|Model} block Блок или его модель
   * @returns {Boolean}
   */
  arePointerEventsAllowed: function(block) {
    var attributes = block.attributes || (block.model && block.model.attributes) || {};
    return attributes.type && this.POINTER_EVENTS_BLOCK_EXCLUDE.indexOf(attributes.type) === -1;
  },

  /**
   * Возвращает id блоков, которые клонируются вместе с триггерами
   * @param {Array<Backbone.View>} blocks
   * @returns {Array}
   */
  getBlocksContainingTriggers: function(blocks) {
    var ids = _.map(blocks, function(block) {
      return block.id || block._id;
    });
    // Узнаем id триггеров у копируемых блоков, если они есть
    return _.reduce(
      blocks,
      function(blockIds, block) {
        var animation = block.model ? block.model.get('animation') : block.animation;
        var triggerIds = this.normalizeAnimationTriggers(animation && animation.trigger);
        // Поищем эти триггеры среди копируемых блоков
        if (triggerIds.length && _.intersection(ids, triggerIds).length) {
          blockIds.push(block.id ? block.id : block._id);
        }
        return blockIds;
      }.bind(this),
      []
    );
  },

  /**
   * Восстанавливает связь триггер-блок
   * @param {Array} oldBlockIds
   * @param {Object} idsMap {oldId: newId}
   * @param {Array<View>} blocks
   * @returns {Array<View>} затронутые блоки
   */
  restoreTriggers: function(oldBlockIds, idsMap, blocks) {
    var affectedBlocks = [];
    _.each(
      oldBlockIds,
      function(oldBlockId) {
        var newBlockId = idsMap[oldBlockId];
        var block = _.find(blocks, { id: newBlockId });
        var animation = block && block.model && block.model.get('animation');
        var triggerIds = this.normalizeAnimationTriggers(animation && animation.trigger);
        if (triggerIds.length) {
          animation.trigger = _.map(triggerIds, function(triggerId) {
            return idsMap[triggerId];
          });
          affectedBlocks.push(block);
        }
      }.bind(this)
    );
    return affectedBlocks;
  },

  /**
   * Проверяет, есть ли в анимации масштабирование больше чем 100%
   * @param {Object} animation Атрибут блока animation, нормализованный или ненормализованный
   * @return {boolean}
   */
  isScaleUp: function(animation) {
    return Boolean(
      _.find(animation && animation.steps, function(step, i) {
        return step.use_scale && ((i === 0 && step.from_scale > 100) || step.scale > 100);
      })
    );
  },

  /**
   * Определяет, можно ли использоваться аппаратное ускорение для анимации.
   * @param {Object} animation Атрибут блока animation, нормализованный или ненормализованный
   * @return {boolean}
   */
  canBeAccelerated: function(animation) {
    // не включаем принудительное аппаратное ускорение есть в шагах есть скейлы больше 100
    // иначе анимируется некрасиво, пикселизованно и только в конце анимации перерендеривает
    // это нормальное и правильное поведение, для скорости, но нам тут важнее эстетический момент
    // на девайсах все равно форсим 3d поскольку там пикселизация не так заметна, а вот лаги более чем
    return !Device.isDesktop || !this.isScaleUp(animation);
  },

  /**
   * Возвращает id анимаций, который не меняется. Обычно это UUID анимации,
   * но для стики, фиксед и full-width это id самого виджета, потому что для них UUID анимации генерится каждый раз новый
   * см. RM.classes.Animations.initialize. Анимации этих виджетов не группируются, поэтому использовать id виджета — ок
   * @param {Object} widget Атрибуты виджета
   * @return {String}
   */
  getPermanentAnimationId: function(widget) {
    return widget.fixed_position || widget.is_full_width || widget.is_full_height || widget.sticked
      ? widget._id
      : widget.animation && widget.animation.UUID;
  },

  getAnimationsPlayed: function() {
    if (_.isUndefined(this._animationsPlayed)) {
      try {
        var entry = Modernizr.localstorage
          ? window.localStorage.getItem(this.ANIMATIONS_PLAYED_STORAGE_KEY)
          : Utils.getCookie(this.ANIMATIONS_PLAYED_STORAGE_KEY);
        var list = entry && JSON.parse(entry);
        this._animationsPlayed = _.isArray(list) ? list : [];
      } catch (e) {
        this._animationsPlayed = [];
      }
    }
    return this._animationsPlayed;
  },

  persistHasPlayed: function(id) {
    if (!this.hasPlayed(id)) {
      try {
        var entry = JSON.stringify([].concat(this.getAnimationsPlayed(), id));
        Modernizr.localstorage
          ? window.localStorage.setItem(this.ANIMATIONS_PLAYED_STORAGE_KEY, entry)
          : Utils.createCookie(this.ANIMATIONS_PLAYED_STORAGE_KEY, entry);
        delete this._animationsPlayed;
      } catch (e) {}
    }
  },

  hasPlayed: function(id) {
    return id && this.getAnimationsPlayed().indexOf(id) !== -1;
  },

  hasExpired: function(widget) {
    var permanentId = this.getPermanentAnimationId(widget);
    return widget.animation && widget.animation.playOnce && this.hasPlayed(permanentId);
  },
};

// глобальный счетчик для генерации имени анимаций
var animID = 0,
  AP = AnimationUtils.ANIMATION_PROPERTIES; // шорткат

var Timeline = Backbone.View.extend({
  initialize: function(params) {
    _.bindAll(this);

    _.extend(this, params);

    if (!AP.animationSupported) return;

    this.active = false;

    this.reversed = false;

    // считаем общую длину анимации (если обычная то в секундах, если фреймовая то в шагах)
    this.animationLength = 0;

    // если есть повороты в шагах и мы не в скрол навигации
    // тогда вместо матриц надо использовать прямую нотацию
    // ведь через матрицы не получиться анимировать к примеру 0-720 градусов
    this.hasRotation = false;

    for (var i = 0; i < this.steps.length; i++) {
      var step = this.steps[i];

      this.animationLength += step.calcedDelay + step.calcedDuration;

      if (step.use_rotate && ((i == 0 && step.from_rotate != 0) || step.rotate != 0)) {
        this.hasRotation = true;
      }
    }

    this.$el.toggleClass('force3d', !!this.force3d);

    if (this.type != 'scroll') {
      this.generateNormalAnimation();
    } else {
      this.generateFramesAnimation();
    }
  },

  generateNormalAnimation: function() {
    // считаем проценты кейреймов для шагов, у шага может быть по два значения процентов
    // это для тех случаев когда у следующего шага есть delay (т.е. мы просим предыдущий шаг проблить свое состояние на этот delay)
    var curTime = 0,
      per = [[0]],
      self = this,
      stepsLen = this.steps.length;

    for (var i = 0; i < stepsLen; i++) {
      var delayPer = this.steps[i].calcedDelay / this.animationLength,
        durationPer = this.steps[i].calcedDuration / this.animationLength;

      if (delayPer >= 0.0001) {
        per[per.length - 1].push(curTime + delayPer);
      } else {
        delayPer = 0;
      }

      curTime += delayPer + durationPer;

      if (i == stepsLen - 1) {
        curTime = 1; // жестко проставляем 1 для последнего шага, чтобы не было всяких 0.999997
      }

      per.push([curTime]);
    }

    animID++;

    this.id = animID;

    var keyframes = AP.keyframeCSS + ' animation_' + this.id + ' {\n';

    var easingMap = {
      none: 'linear',
      'ease-in': 'ease-in',
      'ease-out': 'ease-out',
      'ease-both': 'ease-in-out',
    };

    // устанавливаем анимацию в изначальное положение
    keyframes += createKeyFrame(
      per[0],
      {
        dx: 0,
        dy: 0,
        opacity: this.steps[0].from_opacity,
        rotate: this.steps[0].from_rotate,
        scale: this.steps[0].from_scale,
      },
      easingMap[this.steps[0].acceleration]
    );

    for (var i = 0; i < stepsLen; i++) {
      keyframes += createKeyFrame(
        per[i + 1],
        this.steps[i],
        this.steps[i + 1] ? easingMap[this.steps[i + 1].acceleration] : undefined
      );
    }

    keyframes += '}\n';

    this.keyframesStyle = document.createElement('style');

    this.keyframesStyle.innerHTML = keyframes;

    this.$el.append(this.keyframesStyle);

    this.$el.on(AP.animationIterationJS, this.onAnimationIteration);

    this.$el.on(AP.animationEndJS, this.onAnimationEnd);

    // изначальное состояние проставляем принудительно, иначе проскакивает рендер элемента без анимаций (если положиться на начальное состояние и просто применить их с паузой)
    // браузер далеко не сразу создает и применяет новые теги <style>
    // а у нас рендеринг жлементов идет сразу после нстройки анимаций и получается что при загрузке страницы мы видим виджеты в их обычном состоянии
    // а потом через доли секунды к ним применются начальные состояния анимаций, что нас не устраивает конечно же
    // потом их заоверрайдят параметры анимаций, как это ни странно, но анимации заданные через стили перекрывают инлайн стили (что трансформы, что опасити)
    // для лоад анимаций показываем конечное состояние
    this.applyStepState(this.screenshotMode && this.type == 'load' ? stepsLen - 1 : 'initial');

    /**
     * Создаёт keyframe
     * @param {Array<Number>} pers Массив дробей / процентов, например [0.5]
     * @param {Object} step
     * @param {String} easing
     * @returns {string}
     */
    function createKeyFrame(pers, step, easing) {
      var res = '\t';

      res +=
        _.map(pers, function(per) {
          return per * 100 + '%';
        }).join(', ') + ' {';
      res += AP.transformCSS + ': ' + self.getTransformString(step.dx, step.dy, step.rotate, step.scale / 100) + '; ';
      res += 'opacity: ' + (step.opacity == 0 ? 0 : step.opacity / 100) + '; ';
      if (easing) {
        res += AP.animationTimingFunctionCSS + ': ' + easing;
      }
      res += '}\n';

      return res;
    }
  },

  generateFramesAnimation: function() {
    // считаем проценты кейреймов для шагов, у шага может быть по два значения процентов
    // это для тех случаев когда у следующего шага есть delay (т.е. мы просим предыдущий шаг проблить свое состояние на этот delay)
    var curTime = 0,
      self = this;

    var prevParams = {
      dx: 0,
      dy: 0,
      opacity: this.steps[0].from_opacity,
      rotate: this.steps[0].from_rotate,
      scale: this.steps[0].from_scale,
    };

    this.frames = [];

    // запоминаем изначальный шаг
    this.frames.push({
      ind: 0, // это фрейм начала анимации шага
      isDelay: false,
      params: prevParams,
    });

    for (var i = 0; i < this.steps.length; i++) {
      var step = this.steps[i];

      if (step.calcedDelay) {
        this.frames.push({
          ind: curTime + 1,
          isDelay: true,
          params: prevParams,
        });
        curTime += step.calcedDelay;
      }

      prevParams = {
        dx: step.dx,
        dy: step.dy,
        opacity: step.opacity,
        rotate: step.rotate,
        scale: step.scale,
        acceleration: step.acceleration,
      };

      this.frames.push({
        ind: curTime + 1,
        isDelay: false,
        params: prevParams,
      });
      curTime += step.calcedDuration;
    }

    this.seek(0);
  },

  getTransformString: function(x, y, angle, scale) {
    var res;

    // в вебкитах баг, анимация не работает если начинается с шага с нулевым скейлом
    // решил просто для всех сделать шагов и браузеров скейл не меньше 0.001 (если меньше - то баг все равно проявляется).
    if (scale < 0.001) scale = 0.001;

    // если есть повороты в шагах и мы не в скрол навигации
    // тогда вместо матриц надо использовать прямую нотацию
    // ведь через матрицы не получиться анимировать к примеру 0-720 градусов
    if (this.hasRotation && this.type != 'scroll') {
      if (this.force3d) res = 'translate3d(' + x + 'px,' + y + 'px,0) rotate(' + angle + 'deg) scale(' + scale + ')';
      else res = 'translate(' + x + 'px,' + y + 'px) rotate(' + angle + 'deg) scale(' + scale + ')';
    } else {
      var cos = scale,
        sin = 0,
        comma = ',';

      if (angle) {
        angle *= AnimationUtils.DEG_TO_RAD;
        cos = Math.cos(angle) * scale;
        sin = Math.sin(angle) * scale;
      }

      cos = Math.abs(cos) < 0.000001 ? 0 : cos;
      sin = Math.abs(sin) < 0.000001 ? 0 : sin;

      // фикс для EDGE
      // https://www.notion.so/readymag/Project-Animations-displaying-differently-on-Edge-IE-2688932e50b74cd0b83f930b2768f7a3
      if (Modernizr.edge) {
        y *= Scale.getMag().getScale(Scale.getMag().viewport);
      }

      var a = cos + comma + sin,
        b = -sin + comma + cos,
        c = x + comma + y;

      if (this.force3d) res = 'matrix3d(' + a + ',0,0,' + b + ',0,0,0,0,1,0,' + c + ',0,1)';
      else res = 'matrix(' + a + comma + b + comma + c + ')';
    }

    return res;
  },

  applyStepState: function(stepInd) {
    this.$el.removeClass('invisible');
    var opacity;

    if (stepInd == 'initial') {
      this.$el[0].style[AP.transformJS] = this.getTransformString(
        0,
        0,
        this.steps[0].from_rotate,
        this.steps[0].from_scale / 100
      );
      opacity = this.steps[0].from_opacity;
      this.$el[0].style['opacity'] = opacity == 0 ? 0 : opacity / 100;
    } else {
      var step = this.steps[stepInd];
      this.$el[0].style[AP.transformJS] = this.getTransformString(step.dx, step.dy, step.rotate, step.scale / 100);
      opacity = step.opacity;
      this.$el[0].style['opacity'] = opacity == 0 ? 0 : opacity / 100;
    }

    // Разрешим невидимость для всех шагов кроме начального, чтобы не заслонял объекты
    // Для начального — только если есть триггер, потому что по самому объекту кликнуть будет нельзя https://trello.com/c/0di6ITIM/307-animations-remote-trigger
    var allowInvisible = stepInd != 'initial' || this.animationTrigger.length > 0;
    // по ховеру нельзхя полностью прятать элемент, псокольку тогда анимация начнется заново при каждом новом движении мыши по объекту
    // onmouseleave и enter сработают (https://trello.com/c/sHtDSYAB/55-on-hover-2)
    if (allowInvisible && opacity === 0 && this.type != 'hover') {
      this.$el.addClass('invisible');
    }
  },

  // никогда не вызывается для скрол анимаций
  play: function() {
    if (this.screenshotMode) return;

    if (!AP.animationSupported) return;

    var a = AP.animationJS;

    this.stop();

    this.$el.removeClass('invisible');

    // чтобы сменить анимацию прежнюю надо сбросить, и заставить элемент перерендериться
    this.$el.outerHeight();

    // Без requestAnimationFrame onload-анимации иногда запускались в неправильном порядке в safari
    window.requestAnimationFrame(
      function() {
        // запись одной строкой не работает в IE
        this.$el[0].style[a + 'Name'] = 'animation_' + this.id;
        this.$el[0].style[a + 'Duration'] = this.animationLength + 's';
        this.$el[0].style[a + 'IterationCount'] = this.loop ? 'infinite' : 1;
        this.$el[0].style[a + 'Direction'] = this.loop === AnimationUtils.LOOP_TYPES.repeat ? 'normal' : 'alternate';
        this.$el[0].style[a + 'FillMode'] = 'both';
        this.$el[0].style[a + 'PlayState'] = '';
      }.bind(this)
    );

    this.active = true;
    this.reversed = false;
  },

  // никогда не вызывается для скрол анимаций
  reverse: function() {
    if (this.screenshotMode) return;

    if (!AP.animationSupported) return;

    var a = AP.animationJS;

    this.stop();

    this.$el.removeClass('invisible');

    // чтобы сменить анимацию прежнюю надо сбросить, и заставить элемент перерендериться
    this.$el.outerHeight();

    // запись одной строкой не работает в IE
    this.$el[0].style[a + 'Name'] = 'animation_' + this.id;
    this.$el[0].style[a + 'Duration'] = this.animationLength + 's';
    this.$el[0].style[a + 'IterationCount'] = this.loop ? 'infinite' : 1;
    this.$el[0].style[a + 'Direction'] =
      this.loop === AnimationUtils.LOOP_TYPES.repeat ? 'reverse' : 'alternate-reverse';
    this.$el[0].style[a + 'FillMode'] = 'both';
    this.$el[0].style[a + 'PlayState'] = '';

    this.active = true;
    this.reversed = true;
  },

  // никогда не вызывается для скрол анимаций
  stop: function() {
    this.$el.removeClass('invisible');

    this.$el[0].style[AP.animationJS] = '';

    this.active = false;
    this.reversed = false;
  },

  // никогда не вызывается для скрол анимаций
  onAnimationEnd: function() {
    this.reversed = !this.reversed;
    this.active = false;

    // из-за багов в сраном-пересраном сафари приходится костылить просто всюду
    // тут проблема в том, что оъект анимируется визуально в правильное положение, а вот дум элемент реагирует на мышь и пр. в старом
    // поэтому приходится проставлять состояние через инлайн трансформ, а не через анимации в конечных состояниях и both
    // обязательно сбрасываем анимации
    this.$el[0].style[AP.animationJS] = '';

    this.applyStepState(this.reversed ? this.steps.length - 1 : 'initial');

    this.trigger('full-cycle-end');
  },

  // никогда не вызовется для скрол анимаций
  onAnimationIteration: function(e) {
    // Для повторения типа "туда и обратно" триггерим событие только в конце обратного хода
    if (this.loop === AnimationUtils.LOOP_TYPES.swing) {
      // смотрим это итерация прямого хода или обратного (суммарное прошедшее время анимации делим на длительность одного хода)
      // 0 это конец обратного хода, 1 - конец прямого
      var dir = Math.round(e.originalEvent.elapsedTime / this.animationLength) % 2;

      if (dir) {
        this.reversed = true;
      } else {
        this.reversed = false;
        // триггер только для полного цикла "туда-обратно"
        this.trigger('full-cycle-end');
      }
      // Для обычного повторения всегда триггерим событие
    } else {
      this.trigger('full-cycle-end');
    }
  },

  // никогда не вызовется для скрол анимаций
  resume: function() {
    if (this.screenshotMode) return;

    if (!AP.animationSupported) return;

    if (Device.isDesktop) {
      this.$el[0].style[AP.animationJS + 'PlayState'] = 'running';
    } else {
      this.play();
    }

    this.active = true;
  },

  // никогда не вызовется для скрол анимаций
  pause: function() {
    if (!AP.animationSupported) return;

    if (Device.isDesktop) {
      this.$el[0].style[AP.animationJS + 'PlayState'] = 'paused';
    } else {
      this.stop();
    }

    this.active = false;
  },

  // вызывается только для скрол анимаций
  seek: function(frame) {
    if (!AP.animationSupported) return;

    frame = Math.max(Math.min(frame, this.animationLength), 0);

    var len = this.frames.length,
      i;

    // ищем тот шаг в который попадает заданный номер фрейма простом перебором списка с данными фреймов
    // их там обычно будет не больше 2-3, и почти наверняка не больше 10, поэтому можно не заморачиваться с бинарным поиском ближайшего и пр.
    for (i = 0; i < len; i++) {
      if (this.frames[i].ind > frame) break;
    }

    i--;

    var calcedParams, st, ed;

    if (this.frames[i].isDelay) {
      calcedParams = calcParams(this.frames[i].params, this.frames[i].params, 0, 1);
    } else {
      st = i ? this.frames[i].ind - 1 : 0;
      ed = i == len - 1 ? this.animationLength : this.frames[i + 1].ind - 1;

      calcedParams = calcParams(this.frames[i ? i - 1 : 0].params, this.frames[i].params, frame - st, ed - st + 1);
    }

    this.$el[0].style[AP.transformJS] = this.getTransformString(
      calcedParams.dx,
      calcedParams.dy,
      calcedParams.rotate,
      calcedParams.scale / 100
    );
    this.$el[0].style['opacity'] = calcedParams.opacity == 0 ? 0 : calcedParams.opacity / 100;

    this.$el.toggleClass('invisible', calcedParams.opacity == 0); // чтобы для мыши был прозрачен

    // расчитываем значения переменных зная начальные значения, конечные и процент между ними (0..1) учитываем изинги!
    function calcParams(st, ed, ind, len) {
      if (ind == 0) return st;
      if (ind == len - 1) return ed;

      var per = ind / (len - 1);

      return {
        dx: st.dx == ed.dx ? st.dx : interpolate(st.dx, ed.dx, per, ed.acceleration),
        dy: st.dy == ed.dy ? st.dy : interpolate(st.dy, ed.dy, per, ed.acceleration),
        opacity: st.opacity == ed.opacity ? st.opacity : interpolate(st.opacity, ed.opacity, per, ed.acceleration),
        rotate: st.rotate == ed.rotate ? st.rotate : interpolate(st.rotate, ed.rotate, per, ed.acceleration),
        scale: st.scale == ed.scale ? st.scale : interpolate(st.scale, ed.scale, per, ed.acceleration),
      };
    }

    function interpolate(st, ed, per, acceleration) {
      var x;

      if (!acceleration || acceleration == 'none') {
        x = per;
      } else if (acceleration == 'ease-in') {
        x = per * per;
      } else if (acceleration == 'ease-out') {
        x = per * (2 - per);
      } else {
        x = per < 0.5 ? per * per * 2 : 2 * per * (2 - per) - 1;
      }

      return st + (ed - st) * x;
    }
  },

  destroy: function() {
    this.$el.off(AP.animationIterationJS, this.onAnimationIteration);
    this.$el.off(AP.animationEndJS, this.onAnimationEnd);

    // снимать стили важно, поскольку сам таймлайн анимации может создаваться и удаляться несколько раз (например при смене скейла страницы но без смены вьюпорта)
    // а контейнер анимации остается тот же самый, и нам не надо никаких остатков от прежнего таймлайна анимации на контейнере при его пересоздании
    this.$el[0].style[AP.animationJS] = '';
    this.$el[0].style[AP.transformJS] = '';
    this.$el[0].style['opacity'] = '';

    this.$el.removeClass('force3d');

    this.keyframesStyle && $(this.keyframesStyle).remove();

    this.reversed = false;
    this.active = false;
  },
});

export default AnimationUtils;
