import _ from '@rm/underscore';

var EmbedMatcher = {
  defaultType: 'codeinjection',

  // Матчером может быть:
  // - массив регулярок
  // - массив функций
  // - смешанный массив регулярок и функций
  // - просто функция
  matchers: {
    instagram: [
      /^\s*<blockquote\s+class=.instagram-media.*<\/blockquote>\s*<script.*src=.*instagram.+>.*<\/script>\s*$/gi,
    ],
    pinterest: [/^\s*<a.*data-pin-do=.*<\/a>([\s\S]*<script.*src=.*pinterest.+>.*<\/script>)?\s*$/i],
    facebookvideo: [
      /^\s*<div\s+id=.fb-root.*<\/div>[\s\S]*<script>.*src.*facebook.*sdk.*<\/script>[\s\S]*<div\s+class=.fb-video.*<\/div>\s*$/i,
    ],
    readymag: [/^\s*<a\s+class=.rm-mag-embed.*<\/a>[\s\S]*<script.+id=.readymag-embed-init.+><\/script>\s*$/i],
    ecommerce: [
      /^\s*<form\s+action=.*method=.*POST.+>[\s\S]*<script.*src=.*stripe.+>.*<\/script>[\s\S]*<\/form>\s*$/gim, // Stripe
      /^\s*(<!--.*-->)?[\s\S]*<script.*src=.*stripe.+>[\s\S]*<\/script>[\s\S]*(<!--.*-->)?[\s\S]*<button .*>.*<\/button>[\s\S]*<div .*>.*<\/div>[\s\S]*<script>.*stripe.*<\/script>\s*$/gim, // Stripe checkout snippet
      /^\s*<div\s+id=.+>[\s\S]*<\/div>[\s\S]*<script.*scriptUrl.*shopifycdn.+>[\s\S]*<\/script>\s*$/gim, // Shopify
      /^\s*<script\s+src=.*gumroad.+><\/script>[\s\S]*<div\s+class=.gumroad-product-embed.+>[\s\S]*<\/div>\s*$/gim, // Gumroad embed
      /^\s*<script\s+src=.*gumroad.+><\/script>[\s\S]*<a\s+class=.gumroad-button.+>[\s\S]*<\/a>\s*$/gim, // Gumroad button
      /^\s*<div\s+class=.*ecwid.+><\/div>[\s\S]*<script.+ecwid.+>.*<\/script>[\s\S]*<script.*>.*xProduct.*<\/script>\s*$/gim, // Ecwid widget
    ],
    // iframe: [
    // 	/^\s*<iframe[\s\S]*>[\s\S]*<\/iframe>\s*$/i
    // ]
    iframe: function(code) {
      code = code || '';
      var noComments = code.replace(/<!--[\s\S]*-->/gi, '');
      return /^\s*<iframe[\s\S]*>[\s\S]*<\/iframe>\s*$/i.test(noComments);
    },
  },

  matchCode: function(code, widget) {
    code = (code || '').trim();
    for (var type in this.matchers) {
      var matchers = this.matchers[type];

      // Матчер просто функция?
      if (_.isFunction(matchers)) {
        if (matchers(code, widget)) {
          return type;
        } else {
          return this.defaultType;
        }
      }

      // Матчеры - массив
      for (var m in matchers) {
        m = matchers[m];

        // В массиве функция?
        if (_.isFunction(m)) {
          if (m(code, widget)) {
            return type;
          }
        }
        // Простая регулярка
        else {
          // Обязательно клонируем регулярку, т.к. это объект и
          // при множественных вызовах функции .test() на одной и той же регулярке
          // может вести себя некорректно
          var regex = new RegExp(m.source, m.flags);
          if (regex.test(code.replace(/\n/g, ''))) {
            return type;
          }
        }
      }
    }

    return this.defaultType;
  },
};

export default EmbedMatcher;
