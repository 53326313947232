module.exports = {["template-common-404"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n    <div id="page404">\n        <table>\n            <tr>\n                <td>\n                    <div class="caption">Page Not Found.</div>\n                    <div class="message">Sorry, there’s nothing here.<br/>Visit our <a class="front-page-link" href="/">front page</a>.</div>\n                </td>\n            </tr>\n        </table>\n    </div>\n';
}
return __p;
},["template-common-mag-suspended"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="magsuspended-container">\n		<div class="img-preloader"></div>\n		<div class="center-table">\n			<div class="center-cell">\n				<div class="center-content">\n					<div class="caption">Suspended</div>\n					<!--__magterm-->\n					<div class="text">Sorry, this project has been suspended due to violation of Readymag’s <a class="link guides-link nowrap" href="https://readymag.com/readymag/terms-and-privacy/">content guidelines</a>.</div>\n				</div>\n			</div>\n		</div>\n		<a class="link contact" href="mailto:'+
((__t=(Constants.SUPPORT_MAIL))==null?'':__t)+
'">Contact us</a>\n	</div>\n';
}
return __p;
},["template-common-user-suspended"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="usersuspended-container">\n		<div class="img-preloader"></div>\n		<div class="center-table">\n			<div class="center-cell">\n				<div class="center-content">\n					<div class="caption">Suspended</div>\n					<!--__magterm-->\n					<div class="text">Sorry, this account has been suspended due to violation of Readymag’s <a class="link guides-link nowrap" href="https://readymag.com/readymag/terms-and-privacy/">content guidelines</a>.</div>\n				</div>\n			</div>\n		</div>\n		<a class="link contact" href="mailto:'+
((__t=(Constants.SUPPORT_MAIL))==null?'':__t)+
'">Contact us</a>\n	</div>\n';
}
return __p;
},["template-common-domain-errors"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="domain-errors">\n		<div class="message">\n			';
 if (state.magNotFound || state.domainNotFound) { 
__p+='\n			<div class="message-caption">Website is almost here</div>\n			<!--__magterm-->\n			<div class="message-text">To complete the setup, please hit <br class="br-phone"/>the “Map Now” <br class="br-desktop"/>button in your <br class="br-phone"/>projects’s settings.</div>\n			<a class="help-phone" href="http://help.readymag.com/hc/en-us/articles/360020588592-Domain">Need Help?</a>\n			';
 } 
__p+='\n			';
 if (state.magNotPublished) { 
__p+='\n			<div class="message-caption">Website is Offline</div>\n			<!--__magterm-->\n			<div class="message-text">This project has been unpublished.</div>\n			';
 } 
__p+='\n			';
 if (state.subscriptionExpired) { 
__p+='\n			<div class="message-caption">Website is Offline</div>\n			<div class="message-text"><a href="'+
((__t=(Constants.readymag_auth_host))==null?'':__t)+
'/settings/">Log in</a> to manage your subscription.</div>\n			';
 } 
__p+='\n		</div>\n\n		<a class="logo" href="'+
((__t=(Constants.readymag_host))==null?'':__t)+
'"></a>\n\n		';
 if (state.magNotFound || state.domainNotFound) { 
__p+='\n		<a class="help" href="http://help.readymag.com/hc/en-us/articles/360020588592-Domain">Need Help?</a>\n		';
 } 
__p+='\n	</div>\n\n';
}
return __p;
},}