/**
 * Виджет для добавления товара в корзину
 * общий для конструктора и вьюэра
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from './utils';
import templates from '../../templates/common/add-to-cart.tpl';

var triagleSvg =
  '<svg class="select-dropdown-triangle" width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="dev" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="E-comm-W-01.4" transform="translate(-632.000000, -263.000000)" fill="#FFFFFF"><g id="Add-to-cart-+-version" transform="translate(494.500000, 247.000000)"><g id="Product-version"><g id="Group" transform="translate(136.000000, 15.500000)"><path d="M3.88579937,12.2407434 L8.34920863,8.41496403 C8.76853447,8.05554188 9.39983445,8.10410342 9.7592566,8.52342926 C9.91460784,8.70467237 10,8.93550936 10,9.17422064 L10,16.8257794 C10,17.3780641 9.55228475,17.8257794 9,17.8257794 C8.76128873,17.8257794 8.53045174,17.7403872 8.34920863,17.585036 L3.88579937,13.7592566 C3.46647353,13.3998345 3.41791199,12.7685345 3.77733414,12.3492086 C3.81065883,12.3103298 3.84692056,12.2740681 3.88579937,12.2407434 Z" id="arrow-copy" transform="translate(6.500000, 13.000000) scale(-1, -1) rotate(-270.000000) translate(-6.500000, -13.000000) "></path><path d="M3.88579937,3.2407434 L8.34920863,-0.585035966 C8.76853447,-0.944458116 9.39983445,-0.895896579 9.7592566,-0.476570737 C9.91460784,-0.295327627 10,-0.0644906353 10,0.174220637 L10,7.82577936 C10,8.37806411 9.55228475,8.82577936 9,8.82577936 C8.76128873,8.82577936 8.53045174,8.7403872 8.34920863,8.58503597 L3.88579937,4.7592566 C3.46647353,4.39983445 3.41791199,3.76853447 3.77733414,3.34920863 C3.81065883,3.31032982 3.84692056,3.27406809 3.88579937,3.2407434 Z" id="arrow-copy" transform="translate(6.500000, 4.000000) scale(-1, 1) rotate(-270.000000) translate(-6.500000, -4.000000) "></path></g></g></g></g></g></svg>';

const AddToCartWidget = Backbone.View.extend(
  {
    SELECT_ICON_RIGHT: 7,
    SELECT_ICON_CLASS: 'dropdown-arrow',

    initialize: function(params) {
      _.bindAll(this);
      this.data = params.data;
      this.$container = params.$container;
      this.environment = params.environment;
      this.eCommerceManager = params.eCommerceManager;
      this.block = params.block;
      this.template = templates['template-common-add-to-cart-wrap'];
      this.selects = [];
      this.selectsMap = {};
      this.attributes = [];
      this.constraints = { width: 0, height: 0 };
      this.showSkus = false; // флаг означает, что у товара есть артикулы
      this.setData_debounced = _.debounce(this.setData, 100);
      this.setModel_debounced = _.debounce(this.setModel, 200);
      this.product = null;
      this.selectsValues = {};
      this.restrictions = {};
    },

    render: function() {
      if (this.rendered) {
        return;
      }

      this.$container.html(this.template);
      this.setElement(this.$container.find('.add-to-cart-wrap'));
      this.$button = this.$el.find('.add-to-cart-button');
      this.$buttonIcon = this.$button.find('.button-icon');
      this.$buttonText = this.$button.find('.button-text');
      this.$attrsContainer = this.$el.find('.add-to-cart-attributes');
      this.$sizesCalculatingBlock = this.$el.find('.sizes-calculating-block');
      this.getProduct();
    },

    destroy: function() {
      this.unbindDOMEvents();
    },

    /**
     * Если нет подключения или не выбран продукт вызывает рендер кнопки
     * иначе получает продукт и рендерит селекты атрибутов и кнопку
     */
    // TODO: возможно не лучшее название
    getProduct: function() {
      if (!this.eCommerceManager.isMagConnectedToStripe() || this.data.selected_product_id === 'default') {
        this.showSkus = this.checkSkus(this.data.selected_product_id);
        this.renderButton(this.data);
        this.generateIndividualStyleCSS(this.data);
        this.rendered = true;
        this.recalcDimensions({ width: this.data.w, height: this.data.h }); // необходимо для верной высоты
        this.bindDOMEvents();
        return;
      }

      this.product = this.eCommerceManager.getProductById(this.data.selected_product_id);

      if (this.product) {
        this.showSkus = this.checkSkus(null, this.product); // флаг что будет отрисован хотя бы один селект
        this.renderAttributes(this.data);
        this.renderButton(this.data);
        this.renderMargins();
        if (this.data.layout === 'horizontal') {
          this.setHorizontalLayoutClass();
        }
        this.generateIndividualStyleCSS(this.data);
        this.rendered = true;
        this.recalcDimensions({ width: this.data.w, height: this.data.h }); // необходимо для верной высоты
        this.setMinimalDimensions();
        this.bindDOMEvents();
      } else if (this.eCommerceManager.productsLoading) {
        this.eCommerceManager.events.once('ecommerce:loadproducts:complete', this.getProduct.bind(this));
      } else if (this.eCommerceManager.productsLoaded && this.environment === 'constructor') {
        // продукты загружены но выбраного нет
        this.block.changeSelectedProduct(this.block.defaultProductId);
      }
    },

    // Проверяет наличие атрибутов у продукта
    checkSkus: function(productId, productObj) {
      if (!this.eCommerceManager.isMagConnectedToStripe()) {
        return false;
      }

      var product;

      if (productObj) {
        product = productObj;
      } else if (productId) {
        product = this.eCommerceManager.getProductById(productId);
      } else if (this.data.selected_product_id && this.data.selected_product_id !== 'default') {
        product = this.eCommerceManager.getProductById(this.data.selected_product_id);
      }

      if (!product) {
        return false;
      }

      return this.haveProductAnyAttrForRendering(product);
    },

    renderAttributes: function(data) {
      this.$attrsContainer.empty().css({
        'margin-bottom': 0,
        'margin-right': 0,
      });
      this.selects = [];
      this.selectsMap = {};
      this.attributes = [];

      // Нам нечего рендерить
      if (!this.showSkus) {
        return;
      }

      var product = this.product ? this.product : this.eCommerceManager.getProductById(data.selected_product_id);

      // На всякий случай
      if (!product) {
        return;
      }

      if (!this.product) this.product = product;

      // Не в конструкторе не рендерим селекты если продукт не активен
      if (!product.active && this.environment !== 'constructor') {
        return;
      }

      if (this.environment !== 'constructor') {
        this.calculateSelectRestrictions();
      }

      product.attributes.forEach(
        function(attr) {
          var $select = this.renderAttrSelect(attr, data, this.$attrsContainer);
          this.selects.push($select);
          this.selectsMap[attr] = $select;
          this.attributes.push(attr);
        }.bind(this)
      );
    },

    renderAttrSelect: function(attr, data, $container) {
      var $select;
      if (this.environment === 'constructor') {
        $select = $('<select>', {
          class: 'attributes-input',
          value: attr,
        }).attr('name', attr);
        var $defaultOption = $('<option>')
          .attr('value', attr)
          .addClass('attributes-option default-option')
          .text(attr);
        $select.append($defaultOption);
      } else {
        $select = $('<select>')
          .attr('name', attr)
          .addClass('attributes-input');
        this.renderSelectOptions(attr, $select, true);
        $select.on('change', this.onSelectChange.bind(this));
      }

      var $selectContainer = $('<div>', { class: 'select-container' });

      var $selectInput = $('<input>', {
        type: 'text',
        class: 'fake-select',
        readonly: true,
        tabindex: -1,
        value: attr,
      });

      var $triagle = $(triagleSvg);

      $container.append($selectContainer);
      $selectContainer.append($selectInput, $triagle, $select);

      if (this.environment === 'constructor') {
        $selectContainer.append($('<div>', { class: 'select-overlay' }));
      }

      return $select;
    },

    /**
     * attr - атрибут для которого создаются опции
     * $select - dom элемент селекта в который их надо добавить
     */
    renderSelectOptions: function(attr, $select, init) {
      $select.empty();

      var $defaultOption = $('<option>')
        .attr('value', 'default')
        .addClass('attributes-option default-option')
        .text(attr);

      $select.append($defaultOption);

      // Значения опций беруться из массива "ограничений"
      if (this.restrictions[attr]) {
        this.restrictions[attr].forEach(function(attrValue) {
          var $option = $('<option>')
            .attr('value', attrValue)
            .addClass('attributes-option')
            .text(attrValue);
          $select.append($option);
        });
      }

      if (init) {
        this.selectsValues[attr] = 'default';
      } else {
        // тк перерисовали опции надо установить селекту его старое значение, иначе оно сбросится
        $select.val(this.selectsValues[attr]);
      }
    },

    /**
     * Собирает объект значений опций для каждого типа атрибутов у текущего товара
     * Должна вызываться 1 раз перед рендером селектов тк устанавливает для них значения
     */
    calculateSelectRestrictions: function() {
      this.restrictions = {};
      var matchAttrs = {}; // Значения атрибутов для сравнения
      var count = 0; // Количество не дефолтных значений
      var lastMatchKey; // Последний ключ не дефолтного значения

      // Набор выбранных значений будем использовать для поиска возможных вариантов
      for (var key in this.selectsValues) {
        if (this.selectsValues[key] !== 'default') {
          matchAttrs[key] = this.selectsValues[key];
          lastMatchKey = key;
          count += 1;
        }
      }

      this.product.articles.forEach(
        function(article) {
          // Если нашли совпадение в артикулах
          if (_.isMatch(article.attributes, matchAttrs)) {
            for (var attrName in article.attributes) {
              // Если для атрибута уже есть список значений то проверяем, что такого значения еще нет и добавляем его
              if (this.restrictions[attrName]) {
                if (this.restrictions[attrName].indexOf(article.attributes[attrName]) === -1) {
                  this.restrictions[attrName].push(article.attributes[attrName]);
                }
              } else {
                // Иначе создаем список со значением
                this.restrictions[attrName] = [article.attributes[attrName]];
              }
            }
          }
          // Вот это мне не нравится, но по другому не придумал
          // Суть в том, что когда выбран только один селект он не должен себя ограничивать
          if (count === 1) {
            if (this.restrictions[lastMatchKey]) {
              if (this.restrictions[lastMatchKey].indexOf(article.attributes[lastMatchKey]) === -1) {
                this.restrictions[lastMatchKey].push(article.attributes[lastMatchKey]);
              }
            } else {
              this.restrictions[lastMatchKey] = [article.attributes[lastMatchKey]];
            }
          }
        }.bind(this)
      );
    },

    renderButton: function(data) {
      if (!this.showSkus) {
        this.$attrsContainer.empty().css({
          'margin-bottom': 0,
          'margin-right': 0,
        });
        this.selects = [];
        this.selectsMap = {};
        this.attributes = [];
      }

      this.$buttonText.val(data.text);

      // Доп стили во viewer
      if (this.environment !== 'constructor') {
        this.$button.addClass('viewer-button');
      }

      this.$button.append(this.$buttonText);

      this.renderButtonIcon(data);
    },

    renderButtonIcon: function(newData) {
      if (newData.icon_enabled) {
        var position = newData.icon_pos === 'left' ? 'left' : 'right';
        this.$buttonIcon.css({
          display: 'block',
          height: newData.icon_h,
          width: newData.icon_w,
          [position]: this.calcIconMarginFromBtnSide(newData.icon_w),
        });
      } else {
        this.$buttonIcon.css({
          display: 'none',
        });
      }
    },

    renderMargins: function(layout) {
      var currentLayout = layout ? layout : this.data.layout;

      this.$attrsContainer.css({
        'margin-bottom': 0,
        'margin-right': 0,
      });

      if (this.showSkus && this.selects.length) {
        var lastIndex = this.selects.length - 1;
        if (currentLayout === 'horizontal') {
          this.$attrsContainer.css({
            'margin-right': this.data['select_btn_margin'],
          });
          this.selects.forEach(
            function($select, index) {
              $select.parent().css({
                'margin-bottom': 0,
                'margin-right': index < lastIndex ? this.data['attributes_margin'] : 0,
              });
            }.bind(this)
          );
        } else {
          this.$attrsContainer.css({
            'margin-bottom': this.data['select_btn_margin'],
          });
          this.selects.forEach(
            function($select, index) {
              $select.parent().css({
                'margin-bottom': index < lastIndex ? this.data['attributes_margin'] : 0,
                'margin-right': 0,
              });
            }.bind(this)
          );
        }
      }
    },

    // Устанавливает стили для всех элементов виджета в зависимости от режима
    generateIndividualStyleCSS: function(data, updateBlock, mode, select) {
      var currentMode = mode || 'default';
      var currentSelect = select || 'both';

      if (this.environment === 'constructor') {
        this.generateStyleCSS(data, currentMode, currentSelect);
      } else {
        this.generateStyleCSS(data, currentMode, currentSelect);
        this.generateHoverStyleCSS(data);
      }

      this.setButtonIconPosition(data, mode);

      if (updateBlock) {
        this.setBlockSizeAsWidgetSize();
      }
    },

    bindDOMEvents: function() {
      if (this.environment !== 'constructor') {
        this.$button.on('click', this.onAddToCartClick);
      }
    },

    unbindDOMEvents: function() {
      if (this.environment !== 'constructor') {
        this.$button.off('click', this.onAddToCartClick);
      }
    },

    /**
     * Проверяет есть ли у продукта атрибуты которые надо отрисовать
     * @param {*} product
     */
    haveProductAnyAttrForRendering: function(product) {
      if (!product.attributes || !Array.isArray(product.attributes) || !product.attributes.length) {
        return false;
      }

      return !!product.articles.length;
    },

    getSimpleMinimalDimensions: function() {
      if (this.showSkus) {
        if (this.data.layout === 'vertical') {
          return { width: 50, height: 130 };
        } else {
          return { width: 190, height: 30 };
        }
      }

      return { width: 42, height: 16 };
    },

    getDefaultWidgetSize: function() {
      if (this.showSkus) {
        if (this.data.layout === 'vertical') {
          return { width: 168, height: 180 };
        } else {
          return { width: 420, height: 50 };
        }
      }

      return { width: 168, height: 50 };
    },

    /**
     * Устанавливает минмальные размеры для блока (задает ограничения setSizeConstraints)
     * Если переданы значения то использует их
     * иначе вычисляет их с помощью getSimpleMinimalDimensions (раньше getMinimalDimensions)
     * @param {Number} width - ширина в пикселях
     * @param {Number} height - высота в пикселях
     */
    setMinimalDimensions(width, height) {
      if (!_.isUndefined(width) && !_.isUndefined(height)) {
        this.constraints = { width, height };
        this.block.setSizeConstraints(width, height);
        return;
      }

      if (this.environment === 'constructor') {
        var constraints = this.getSimpleMinimalDimensions();
        this.constraints = constraints;
        this.block.setSizeConstraints(constraints.width, constraints.height);
      }
    },

    /**
     * Пересчитывает размеры (высоты) элемнтов при изменение размеров блока
     * вызывается при изменении рамки перетягиванием мышкой
     * дополнительно вызывается один раз после рендера элемента
     * @param {height: number, width:number} params - размеры блока
     */
    recalcDimensions(params) {
      if (!this.rendered) {
        return;
      }

      var margin = this.data.select_btn_margin;
      var count = this.showSkus ? this.selects.length + 1 : 1;
      var attrsMargins = this.showSkus ? (this.selects.length - 1) * this.data.attributes_margin : 0;

      if (this.data.layout === 'vertical') {
        if (!params.height) {
          return;
        }
        var height = this.showSkus ? params.height - margin - attrsMargins : params.height;
        var elementHeight = Math.round(height / count);

        this.$button.css({
          height: elementHeight + 'px',
          width: params.width ? params.width + 'px' : '100%',
        });

        if (this.showSkus) {
          this.selects.forEach(function($select) {
            $select.parent().css({
              height: elementHeight + 'px',
              width: '100%',
            });
          });
        }
      } else {
        if (!params.width) {
          return;
        }
        var width = this.showSkus ? params.width - margin - attrsMargins : params.width;
        var elementWidth = Math.round(width / count);

        this.$button.css({
          width: elementWidth + 'px',
          height: params.height ? params.height + 'px' : '100%',
        });

        if (this.showSkus) {
          this.selects.forEach(function($select) {
            $select.parent().css({
              width: elementWidth + 'px',
              height: '100%',
            });
          });
        }
      }

      this.recalcButtonIconPositionAfterBlockChange();
    },

    recalcButtonIconPositionAfterBlockChange: function() {
      if (!this.data['icon_enabled']) {
        return;
      }

      var btnWidth = this.$button.outerWidth();
      var btnType = this.data['tp'];
      var margin = 0;
      if (btnWidth && btnWidth !== this.data['btn_w']) {
        var textWidth = this.data['text_w'];
        var iconWidth = this.data['icon_w'];
        if (btnType === 'text_and_icon') {
          var space = Math.round((btnWidth - textWidth) / 2); // Простанство доступное для между текстом и краями кнопки
          margin = space - iconWidth - iconWidth / 3; // отступ от края кнопки
          this.data['icon_pos_value'] = margin;
        } else if (btnType === 'icon') {
          margin = Math.round(btnWidth / 2) - Math.round(iconWidth / 2); // Если иконка одна, то центрируем по центру
          this.data['icon_pos_value'] = margin;
        }

        this.setButtonIconPosition();
      }
    },

    setButtonIconPosition: function(newData, mode) {
      if (newData) this.data = newData;
      if (!this.data.icon_enabled || this.data.tp === 'text') {
        return;
      }

      function attr(str) {
        var prefix = mode && mode !== 'default' ? 'hover-' : '';
        return prefix + str;
      }

      if (this.data.tp === 'text_and_icon') {
        var position = this.data.icon_pos === 'left' ? 'left' : 'right';
        var oldPosition = this.data.icon_pos === 'left' ? 'right' : 'left';
        var positionValue = this.data[attr('icon_pos_value')];

        this.$buttonIcon.css({
          [position]: positionValue + 'px',
          [oldPosition]: 'auto',
        });

        if (this.environment !== 'constructor') {
          var buttonIcon = this.$buttonIcon;
          var hoverPositionValue = this.data['hover-icon_pos_value'];
          this.$button.hover(
            function() {
              buttonIcon.css({
                [position]: hoverPositionValue + 'px',
                [oldPosition]: 'auto',
              });
            },
            function() {
              buttonIcon.css({
                [position]: positionValue + 'px',
                [oldPosition]: 'auto',
              });
            }
          );
        }
      } else if (this.data.tp === 'icon') {
        this.$buttonIcon.css({
          left: this.data['icon_pos_value'] + 'px',
          right: 'auto',
        });
      }
    },

    recalcDimensionsOnProductChange() {
      if (!this.rendered) {
        return;
      }
      var margin = this.data.select_btn_margin;
      var count = this.showSkus ? this.selects.length + 1 : 1;
      var attrsMargins = this.showSkus ? (this.selects.length - 1) * this.data.attributes_margin : 0;
      var elementHeight = this.data.btn_h;
      var elementWidth = this.data.btn_w;
      var height, width;

      // Расчитываем новую ширину и высоту блока
      if (this.data.layout === 'vertical') {
        height = this.showSkus ? elementHeight * count + attrsMargins + margin : elementHeight;
        width = elementWidth;
      } else {
        height = elementHeight;
        width = this.showSkus ? elementWidth * count + attrsMargins + margin : elementWidth;
      }

      // Размеры элементов остаются прежними
      this.$button.css({
        height: elementHeight + 'px',
        width: elementWidth + 'px',
      });
      if (this.showSkus) {
        this.selects.forEach(function($select) {
          $select.parent().css({
            height: elementHeight + 'px',
            width: elementWidth + 'px',
          });
        });
      }
      // Сохраняем новые размеры блока
      this.block.updateBlockSizes({ width, height });
    },

    // Сохраняет размеры кнопки из DOM если они изменились относительно модели
    // Размеры виджета нужны тк они автоматически не изменяются в модели виджета
    saveButtonSizes: function(widgetWidth, widgetHeight) {
      var btn_w = this.$button.outerWidth();
      var btn_h = this.$button.outerHeight();

      // Только если размеры изменились
      if (this.data.btn_w !== btn_w || this.data.btn_h !== btn_h) {
        var setData = {
          btn_w,
          btn_h,
          select_w: btn_w,
          select_h: btn_h,
          w: widgetWidth,
          h: widgetHeight,
        };
        if (this.data['icon_enabled']) {
          // Получение нужного отступа в числовом виде
          var newPos = parseInt(this.$buttonIcon.css(this.data['icon_pos']).match(/(\d)+/g)[0]);
          setData['icon_pos_value'] = newPos;
        }
        this.setModel_debounced(setData, true);
      }
    },

    // Устанавливает значения рамки блока по размерам виджета (берутся из DOM)
    setBlockSizeAsWidgetSize: function() {
      var currentLayout = this.data.layout;
      var btnH = this.$button[0].offsetHeight;
      var btnW = this.$button[0].offsetWidth;
      var totalH = btnH;
      var totalW = btnW;

      if (this.showSkus && this.selects[0]) {
        var $select = this.selects[0]; // Размеры у всех одинаковые
        var selectH = $select.parent().outerHeight();
        var selectW = $select.parent().outerWidth();

        if (currentLayout === 'horizontal') {
          totalH = btnH > selectH ? btnH : selectH;
          totalW =
            btnW +
            selectW * this.selects.length +
            this.data['select_btn_margin'] +
            this.data['attributes_margin'] * (this.selects.length - 1);
        } else {
          totalH =
            btnH +
            selectH * this.selects.length +
            this.data['select_btn_margin'] +
            this.data['attributes_margin'] * (this.selects.length - 1);
          totalW = btnW > selectW ? btnW : selectW;
        }
      }
      this.block.updateBlockSizes({ width: totalW, height: totalH });
    },

    // Проверка на то, что у всех селектов выбраны значения
    isAllSelectsNotDefault: function() {
      var allSelectsNotDefault = true;
      for (var key in this.selectsValues) {
        if (this.selectsValues[key] === 'default') {
          allSelectsNotDefault = true;
          break;
        }
      }
      return allSelectsNotDefault;
    },

    getArticlBySelectsValues: function() {
      return this.product.articles.find(
        function(article) {
          return _.isMatch(article.attributes, this.selectsValues);
        }.bind(this)
      );
    },

    // Изменение селекта
    onSelectChange(e) {
      var $select = $(e.target);
      var attr = $select.attr('name'); // Название атрибута Например: size
      // Если выбрали дефолтное значение то удаляем предыдущее значение
      this.selectsValues[attr] = $select.val(); // Новое значение Например: S
      // Устанавливаем значение для видимого селекта
      $select
        .parent()
        .find('.fake-select')
        .attr('value', $select.val() === 'default' ? attr : $select.val());
      // Пересчитываем ограничения для селектов
      this.calculateSelectRestrictions();
      // Перерисовываем селекты для других атрибутов у продукта
      this.product.attributes.forEach(
        function(productAttr) {
          this.renderSelectOptions(productAttr, this.selectsMap[productAttr]);
        }.bind(this)
      );
    },

    onAddToCartClick: function() {
      if (!this.product) {
        console.log("Product isn't define");
      }

      if (!this.isAllSelectsNotDefault()) {
        // TODO: Сделать вывод сообщения
        return;
      }

      // Получаем артикул по выбранным значениям
      let article = this.getArticlBySelectsValues();
      if (!article) {
        return;
      }

      article.productObject = { ...this.product, articles: null };

      const result = this.eCommerceManager.addToCart(article);
      // TODO: сделать нормальный вывод ошибки (нет макетов)
      if (result instanceof Error) {
        console.log(result.message);
      }
    },

    /**
     * Можно было бы вызвать rerender, но вот только он не пересчитываем размеры контейнера
     * тут товары уже загружены
     */
    onProductChange: function(data) {
      if (data) {
        this.data = data;
      }

      this.rendered = false;
      this.showSkus = this.checkSkus(this.data.selected_product_id);

      if (!this.eCommerceManager.isMagConnectedToStripe() || this.data.selected_product_id === 'default') {
        this.renderButton(this.data);
        this.generateIndividualStyleCSS(this.data, true);
        this.rendered = true;
        this.recalcDimensionsOnProductChange();
        this.setMinimalDimensions();
        this.bindDOMEvents();
        return;
      }

      this.product = this.eCommerceManager.getProductById(this.data.selected_product_id);

      if (this.product) {
        this.renderAttributes(this.data);
        this.renderButton(this.data);
        this.renderMargins();
        this.setMinimalDimensions();
        this.generateIndividualStyleCSS(this.data, true);
        this.rendered = true;
        this.recalcDimensionsOnProductChange();
        this.setMinimalDimensions();
        this.bindDOMEvents();
      }
    },

    setHorizontalLayoutClass: function() {
      if (this.$el.hasClass('horizontal-layout')) {
        return;
      }

      this.$el.addClass('horizontal-layout');
    },

    removeHorizontalLayoutClass: function() {
      if (this.$el.hasClass('horizontal-layout')) {
        this.$el.removeClass('horizontal-layout');
      }
    },

    /**
     * Пересчитывает размеры виджета при изменение layout-a
     * @param {String} layout - тип нового макета
     */
    recalcWidgetSizesAfterChangeLayout: function(layout) {
      var currentLayout = layout ? layout : this.data.layout;
      var btnH = this.$button[0].offsetHeight;
      var btnW = this.$button[0].offsetWidth;
      var totalH = btnH;
      var totalW = btnW;

      if (this.showSkus && this.selects[0]) {
        var $select = this.selects[0]; // Размеры у всех одинаковые
        var selectH = $select[0].offsetHeight;
        var selectW = $select[0].offsetWidth;

        if (currentLayout === 'horizontal') {
          totalH = btnH > selectH ? btnH : selectH;
          totalW =
            btnW +
            selectW * this.selects.length +
            this.data['select_btn_margin'] +
            this.data['attributes_margin'] * (this.selects.length - 1);
        } else {
          totalH =
            btnH +
            selectH * this.selects.length +
            this.data['select_btn_margin'] +
            this.data['attributes_margin'] * (this.selects.length - 1);
          totalW = btnW > selectW ? btnW : selectW;
        }
      }

      this.block.updateBlockSizes({ width: totalW, height: totalH });
      return { width: totalW, height: totalH };
    },

    onLayoutChange: function(newLayout) {
      this.data.layout = newLayout; // Тк data обновляется с задержкой
      var newSizes = this.recalcWidgetSizesAfterChangeLayout(newLayout);
      this.renderMargins(newLayout);
      this.setMinimalDimensions();
      this.recalcDimensions(newSizes);

      if (newLayout === 'horizontal') {
        this.setHorizontalLayoutClass();
      } else {
        this.removeHorizontalLayoutClass();
      }
    },

    applyButtonIconSize: function(newData) {
      this.$buttonIcon.css({
        height: newData.icon_h,
        width: newData.icon_w,
      });
    },

    applyButtonIconPosition: function(newData) {
      this.setButtonIconPosition(newData);
    },

    calcIconMarginFromBtnSide: function(icon_w) {
      return Math.ceil(icon_w / 2 + 10);
    },

    getButtonText: function() {
      return this.$buttonText.val().trim();
    },

    enterEditMode: function(options) {
      options = options || {};

      this.$buttonText.removeAttr('readonly').addClass('editing');

      if (options.selectText) {
        this.$buttonText.select();
      } else {
        // ставим каретку в начало текста.
        this.$buttonText.focus().setCursorPos(0);
      }

      this.block.selectEditControl();
    },

    leaveEditMode: function() {
      this.$buttonText
        .blur()
        .attr('readonly', true)
        .removeClass('editing');
    },

    setData: function(newData) {
      this.data = newData;
    },

    // Меняет значение модели, с возможность сохранить
    setModel: function(data, save) {
      this.block.model.set(data);
      if (save) {
        this.block.model.save();
      }
    },

    // TODO: Переделать на генерациюю тэга style и вынести в отдельный файл
    generateStyleCSS: function(data, mode, select) {
      function attr(str) {
        var prefix = mode && mode !== 'default' && (select === 'both' || select === 'button') ? 'hover-' : '';
        return prefix + str;
      }

      // mode string for attributes
      function msattr(str) {
        var prefix =
          mode && mode !== 'default' && (select === 'both' || select === 'selects') ? 'attrs-hover-' : 'attrs-';
        return prefix + str;
      }

      this.$button.css({
        'background-color': Utils.getRGBA(data[attr('background-color')], data[attr('background-color-opacity')] / 100),
        'border-radius': data[attr('border-radius')] + 'px',
        'box-shadow':
          Utils.getRGBA(data[attr('border-color')], data[attr('border-color-opacity')] / 100) +
          '0px 0px 0px ' +
          data[attr('border-width')] +
          'px inset',
      });

      this.$buttonIcon.css({
        top: '50%',
        'margin-top': '-' + Math.ceil(data['icon_h'] / 2) + 'px',
      });

      this.$button.find('.button-icon svg path').css({
        fill: Utils.getRGBA(data[attr('color')], data[attr('color-opacity')] / 100),
      });

      if (this.environment !== 'constructor') {
        this.$buttonIcon.css({
          'background-image': 'url(' + data[attr('icon_raster2xUrl')] + ')',
        });
      }

      this.$buttonText.css({
        'line-height': data['btn_h'] + 'px',
        'border-radius': data[attr('border-radius')] + 'px',
        color: Utils.getRGBA(data[attr('color')], data[attr('color-opacity')] / 100),
        'font-family': data[attr('font-family')],
        'font-size': data[attr('font-size')] + 'px',
        'font-weight': data[attr('font-weight')],
        'font-style': data[attr('font-style')],
        'letter-spacing': data[attr('letter-spacing')],
        'text-align': data[attr('text-align')],
      });

      if (this.showSkus) {
        this.selects.forEach(
          function($select) {
            $select.parent().css({
              'background-color': Utils.getRGBA(
                data[msattr('background-color')],
                data[msattr('background-color-opacity')] / 100
              ),
              'border-radius': data[msattr('border-radius')] + 'px',
              'box-shadow':
                Utils.getRGBA(data[msattr('border-color')], data[msattr('border-color-opacity')] / 100) +
                '0px 0px 0px ' +
                data[msattr('border-width')] +
                'px inset',
            });
            $select
              .parent()
              .find('.fake-select')
              .css({
                'border-radius': data[msattr('border-radius')] + 'px',
                color: Utils.getRGBA(data[msattr('color')], data[msattr('color-opacity')] / 100),
                'font-family': data[msattr('font-family')],
                'font-size': data[msattr('font-size')] + 'px',
                'font-weight': data[msattr('font-weight')],
                'font-style': data[msattr('font-style')],
                'letter-spacing': data[msattr('letter-spacing')],
                'text-align': data[msattr('text-align')],
                'text-align-last': data[msattr('text-align')],
              });
            $select
              .parent()
              .find('.select-dropdown-triangle path')
              .css({
                fill: Utils.getRGBA(data[msattr('color')], data[msattr('color-opacity')] / 100),
              });
            $select
              .parent()
              .find('.select-dropdown-triangle')
              .css({
                zoom: data[msattr('icon-size')],
                right: (18 * (1 / data[msattr('icon-size')])).toFixed(1) + 'px',
              });
          }.bind(this)
        );
      }
    },

    // TODO: Переделать на генерациюю тэга style и вынести в отдельный файл
    generateHoverStyleCSS: function(data) {
      var btnText = this.$buttonText;
      var buttonIcon = this.$buttonIcon;

      this.$button.hover(
        function() {
          $(this).css({
            'background-color': Utils.getRGBA(
              data['hover-background-color'],
              data['hover-background-color-opacity'] / 100
            ),
            'border-radius': data['hover-border-radius'] + 'px',
            'box-shadow':
              Utils.getRGBA(data['hover-border-color'], data['hover-border-color-opacity'] / 100) +
              '0px 0px 0px ' +
              data['hover-border-width'] +
              'px inset',
          });
          if (this.environment !== 'constructor') {
            buttonIcon.css({
              'background-image': 'url(' + data['hover-icon_raster2xUrl'] + ')',
            });
          } else {
            $(this)
              .find('.button-icon svg path')
              .css({
                fill: Utils.getRGBA(data['hover-color'], data['hover-color-opacity'] / 100),
              });
          }
          btnText.css({
            color: Utils.getRGBA(data['hover-color'], data['hover-color-opacity'] / 100),
            'border-radius': data['hover-border-radius'] + 'px',
            'font-family': data['hover-font-family'],
            'font-size': data['hover-font-size'] + 'px',
            'font-weight': data['hover-font-weight'],
            'font-style': data['hover-font-style'],
            'letter-spacing': data['hover-letter-spacing'],
            'text-align': data['hover-text-align'],
          });
        },
        function() {
          $(this).css({
            'background-color': Utils.getRGBA(data['background-color'], data['background-color-opacity'] / 100),
            'border-radius': data['border-radius'] + 'px',
            'box-shadow':
              Utils.getRGBA(data['border-color'], data['border-color-opacity'] / 100) +
              '0px 0px 0px ' +
              data['border-width'] +
              'px inset',
          });
          if (this.environment !== 'constructor') {
            buttonIcon.css({
              'background-image': 'url("' + data['icon_raster2xUrl'] + '")',
            });
          } else {
            $(this)
              .find('.button-icon svg path')
              .css({
                fill: Utils.getRGBA(data['color'], data['color-opacity'] / 100),
              });
          }
          btnText.css({
            color: Utils.getRGBA(data['color'], data['color-opacity'] / 100),
            'border-radius': data['border-radius'] + 'px',
            'font-family': data['font-family'],
            'font-size': data['font-size'] + 'px',
            'font-weight': data['font-weight'],
            'font-style': data['font-style'],
            'letter-spacing': data['letter-spacing'],
            'text-align': data['text-align'],
          });
        }
      );

      if (this.showSkus) {
        this.selects.forEach(
          function($select) {
            $select.hover(
              function() {
                $(this)
                  .parent()
                  .css({
                    'background-color': Utils.getRGBA(
                      data['attrs-hover-background-color'],
                      data['attrs-hover-background-color-opacity'] / 100
                    ),
                    'border-radius': data['attrs-hover-border-radius'] + 'px',
                    'box-shadow':
                      Utils.getRGBA(data['attrs-hover-border-color'], data['attrs-hover-border-color-opacity'] / 100) +
                      '0px 0px 0px ' +
                      data['attrs-hover-border-width'] +
                      'px inset',
                  });
                $(this)
                  .parent()
                  .find('.fake-select')
                  .css({
                    'border-radius': data['attrs-hover-border-radius'] + 'px',
                    color: Utils.getRGBA(data['attrs-hover-color'], data['attrs-hover-color-opacity'] / 100),
                    'font-family': data['attrs-hover-font-family'],
                    'font-size': data['attrs-hover-font-size'] + 'px',
                    'font-weight': data['attrs-hover-font-weight'],
                    'font-style': data['attrs-hover-font-style'],
                    'letter-spacing': data['attrs-hover-letter-spacing'],
                    'text-align': data['attrs-hover-text-align'],
                    'text-align-last': data['attrs-hover-text-align'],
                  });
                $(this)
                  .parent()
                  .find('.select-dropdown-triangle path')
                  .css({
                    fill: Utils.getRGBA(data['attrs-hover-color'], data['attrs-hover-color-opacity'] / 100),
                  });
              },
              function() {
                $(this)
                  .parent()
                  .css({
                    'background-color': Utils.getRGBA(
                      data['attrs-background-color'],
                      data['attrs-background-color-opacity'] / 100
                    ),
                    'border-radius': data['attrs-border-radius'] + 'px',
                    'box-shadow':
                      Utils.getRGBA(data['attrs-border-color'], data['attrs-border-color-opacity'] / 100) +
                      '0px 0px 0px ' +
                      data['attrs-border-width'] +
                      'px inset',
                  });
                $(this)
                  .parent()
                  .find('.fake-select')
                  .css({
                    'border-radius': data['attrs-border-radius'] + 'px',
                    color: Utils.getRGBA(data['attrs-color'], data['attrs-color-opacity'] / 100),
                    'font-family': data['attrs-font-family'],
                    'font-size': data['attrs-font-size'] + 'px',
                    'font-weight': data['attrs-font-weight'],
                    'font-style': data['attrs-font-style'],
                    'letter-spacing': data['attrs-letter-spacing'],
                    'text-align': data['attrs-text-align'],
                    'text-align-last': data['attrs-text-align'],
                  });
                $(this)
                  .parent()
                  .find('.select-dropdown-triangle path')
                  .css({
                    fill: Utils.getRGBA(data['attrs-color'], data['attrs-color-opacity'] / 100),
                  });
              }
            );

            setTimeout(
              function() {
                $select.parent().css({
                  transition: 'all ' + data['hover-tansition-delay'] + 's ease-out',
                });
                $select
                  .parent()
                  .find('.fake-select')
                  .css({
                    transition: 'all ' + data['hover-tansition-delay'] + 's ease-out',
                  });
              }.bind(this),
              100
            );
            $select
              .parent()
              .find('.select-dropdown-triangle path')
              .css({
                transition: 'all ' + data['hover-tansition-delay'] + 's ease-out',
              });
          }.bind(this)
        );
      }

      setTimeout(
        function() {
          this.$button.css({
            transition: 'all ' + data['hover-tansition-delay'] + 's ease-out',
          });
          btnText.css({
            transition: 'all ' + data['hover-tansition-delay'] + 's ease-out',
          });
          buttonIcon.css({
            transition: 'all ' + data['hover-tansition-delay'] + 's ease-out',
          });
        }.bind(this),
        100
      );
    },
  },
  {
    DEFAULT_ROW_HEIGHT: 50,
    DEFAULT_ROW_WIDTH: 168,
  }
);

export default AddToCartWidget;
