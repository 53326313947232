/**
 * Контрол виджета Picture для включения опции Preview
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import Vue from 'vue';
import SVGInjector from 'svg-injector';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/picture_preview.tpl';
import PicturePreviewClass from '../../common/picture-preview';
import { Utils } from '../../common/utils';
import ColorPickerVue from '../../../components/common/color-picker.vue';

const picture_preview = ControlClass.extend({
  name: 'picture_preview',
  className: 'control picture_preview',

  initialize: function(params) {
    this.template = templates['template-constructor-control-picture_preview'];
    this.initControl(params);

    this.updateState();

    var previewModel = this.models[0];

    this.preview = new PicturePreviewClass({
      $container: $('#main'),
      $el: this.blocks[0].$el,
      overlayColor: previewModel.get('preview_overlay_color'),
      picture: previewModel.get('picture'),
      originalW: previewModel.get('originalW'),
      width: previewModel.get('w'),
    });

    this.saveDebounced = _.debounce(function() {
      this.master.workspace.save_group(_.pluck(this.blocks, 'model'));
    }, 1000);
  },

  select: function() {
    if (this.$preview) {
      return;
    }

    ControlClass.prototype.select.apply(this, arguments);

    if (this.hasOnClickAnimation()) {
      return this.$panel.addClass('show-animation-error');
    }

    if (this.hasLink()) {
      return this.$panel.addClass('show-link-error');
    }

    this.$panel.removeClass('show-animation-error show-link-error');

    if (this.models.length === 1 && this.models[0].get('preview_enabled')) {
      this.$preview = this.preview.render();
    }
  },

  deselect: function() {
    if (this.selected && this.colorboxOpened) {
      this.colorbox.hide();
      return;
    }

    this.preview.remove(
      function() {
        delete this.$preview;
      }.bind(this)
    );
    this.colorbox.hide();

    ControlClass.prototype.deselect.apply(this, arguments);
  },

  render: function() {
    ControlClass.prototype.render.apply(this, arguments);

    var svgs = this.$el.find('.svg');
    SVGInjector(svgs);
  },

  bindLogic: function() {
    this.$('.icon-preview-toggle').on('click', this.previewToggle);
    this.$colorbox_container = this.$('.colorbox_container');
    this.models[0].on('change', this.onPictureChange);

    var currentColor = this.models[0].get('preview_overlay_color'),
      currentOpacity = currentColor ? Utils.rgba2alpha(currentColor) : 0.6;

    if (!currentColor) {
      var changeset = this.models.map(function(model) {
        return {
          _id: model.id,
          preview_overlay_color: 'rgba(0, 0, 0, ' + currentOpacity + ')',
        };
      });

      this.master.workspace.set_group(changeset);
      this.saveDebounced();
    }

    var ColorPicker = Vue.extend(ColorPickerVue);
    this.colorbox = new ColorPicker({
      el: this.$colorbox_container.get(0),
      propsData: {
        type: 'small',
        color: currentColor ? Utils.rgba2hex(currentColor).slice(1) : '000000',
        alpha: currentOpacity * 100,
        hOffset: 36,
        vOffset: -64,
      },
    });

    this.colorbox.$on('update:all', this.onColorChanged);
    this.colorbox.$on('showPopup', this.onColorboxOpened);
    this.colorbox.$on('hidePopup', this.onColorboxClosed);
  },

  onColorboxOpened: function() {
    this.colorboxOpened = true;
  },

  onColorboxClosed: function() {
    this.colorboxOpened = false;
  },

  unBindLogic: function() {
    this.$('.icon-preview-toggle').off('click', this.previewToggle);
    this.models[0].off('change', this.onPictureChange);
    this.colorbox.$off('update:all', this.onColorChanged);
    this.preview.remove(
      function() {
        delete this.$preview;
      }.bind(this)
    );
  },

  updateState: function() {
    this.previewEnabled = this.models[0].get('preview_enabled');
    this.$el.toggleClass('enabled', !!this.previewEnabled);
  },

  onColorChanged: function(result) {
    var rgb = Utils.hex2rgb(result.color),
      newColor = 'rgba(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ', ' + result.alpha / 100 + ')';

    var changeset = this.models.map(function(model) {
      return {
        _id: model.id,
        preview_overlay_color: newColor,
      };
    });

    this.master.workspace.set_group(changeset);
    this.saveDebounced();

    this.preview.changeColor(newColor);
  },

  restrictions: function(workspace) {
    // Если у всех(!) выделенных виджетов включено либо выключен превью, тогда показываем контрол
    var blocks = workspace.getSelectedBlocks();

    if (blocks.length <= 1) {
      return true;
    }

    var modelsPreview = blocks.map(function(block) {
      var model = block.model;
      return model.get('preview_enabled');
    });

    return modelsPreview.every((val, i, arr) => val === arr[0]);
  },

  previewToggle: function() {
    if (this.isOnClickDisabled()) {
      return;
    }

    this.previewEnabled = !this.previewEnabled;

    if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
      RM.constructorRouter.analytics.sendEvent(
        'Picture Settings',
        `Lightbox ${this.previewEnabled ? 'enabled' : 'disabled'}`
      );
    }

    var changeset = this.models.map(
      function(model) {
        return {
          _id: model.id,
          preview_enabled: this.previewEnabled,
        };
      }.bind(this)
    );

    this.master.workspace.set_group(changeset);
    this.saveDebounced();

    this.updateState();

    if (!this.previewEnabled) {
      this.preview.remove(
        function() {
          delete this.$preview;
        }.bind(this)
      );
      this.colorbox.hide();
    } else {
      if (this.models.length === 1) {
        this.$preview = this.preview.render();
      }
    }
  },

  hasOnClickAnimation: function() {
    // Если есть анимация onClick, тогда нельзя сделать превью
    var isAnimationSet = this.models.reduce(function(isSet, model) {
      var animation = model.get('animation');
      return isSet || (animation && animation.type === 'click' && animation.steps && animation.steps.length > 0);
    }, false);

    return isAnimationSet;
  },

  hasLink: function() {
    // Если повешана ссылка, тогда нельзя сделать превью
    var isClickSet = this.models.reduce(function(isSet, model) {
      return isSet || !!model.get('clickLink');
    }, false);

    return isClickSet;
  },

  onPictureChange: function() {
    if (this.preview) {
      var pic = this.models[0].get('picture');

      this.preview.picture = pic;
      this.preview.overlayColor = this.models[0].get('preview_overlay_color');
      this.preview.originalW = this.models[0].get('originalW');
      this.preview.height = this.models[0].get('h');
      this.preview.width = this.models[0].get('w');
    }
  },

  onClick: function() {
    if (this.selected && this.colorboxOpened) {
      this.colorbox.hide();
      return;
    }

    ControlClass.prototype.onClick.apply(this, arguments);
  },

  onEscKey: function() {
    if (this.selected && this.colorboxOpened) {
      this.colorbox.hide();
      return;
    }

    ControlClass.prototype.onEscKey.apply(this, arguments);
  },

  canControlBeClosed: function() {
    if (this.colorboxOpened) {
      this.colorbox.hide();
      return true;
    }

    ControlClass.prototype.canControlBeClosed.apply(this, arguments);
  },
});

export default picture_preview;
