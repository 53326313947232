<template>
  <div class="video-panel-search">
    <div class="video-panel-input-wrapper">
      <input
        v-model="searchUrl"
        type="text"
        spellcheck="false"
        :placeholder="placeholder"
        :class="{ 'video-panel-input': true, 'has-icon': showIcon }"
        @keyup.enter="onEnter"
      />
      <div
        v-if="showIcon"
        class="video-panel-icon input-icon"
        :class="{ 'is-remove': isRemove, 'is-upload': isUpload }"
        @click="onIconClick"
      ></div>
      <transition name="fade">
        <div v-if="highlightInput" class="video-panel-error"></div>
      </transition>
    </div>

    <transition name="fade">
      <div v-if="showSocial" class="video-panel-social">
        <div class="video-panel-social-icon video-panel-social-youtube" title="Youtube" @click="onYoutubeClick"></div>
        <div class="video-panel-social-icon video-panel-social-vimeo" title="Vimeo" @click="onVimeoClick"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from '@rm/underscore';
import { Utils } from '../../js/common/utils';
import VideoUtils from '../../js/common/videoutils';

export default {
  model: {
    event: 'change:url',
    prop: 'url',
  },

  data: function() {
    return {
      isError: false,
    };
  },
  computed: {
    searchUrl: {
      get: function() {
        return this.url;
      },
      set: function(value) {
        this.$emit('change:url', value);
      },
    },

    showIcon: function() {
      return Boolean(this.url);
    },
    isRemove: function() {
      return this.isVideoProcessed || this.isError;
    },
    isUpload: function() {
      return this.isURL(this.url) && !this.isVideoProcessed;
    },
    highlightInput: function() {
      return this.highlightOnError && this.isError;
    },
  },
  props: {
    url: String,
    highlightOnError: Boolean,
    showSocial: true,
    isVideoProcessed: false,
    placeholder: {
      type: String,
      default: 'Search, paste URL',
    },
  },
  methods: {
    onIconClick: function(e) {
      if (this.isRemove) {
        this.$emit('change:url', null);
        this.$emit('remove');
      } else {
        this.processInputVideo();
      }
    },

    onEnter: function() {
      this.processInputVideo();
    },

    processInputVideo: function() {
      if (!this.url) return;

      if (this.isURL(this.url)) {
        // если введен урл то используем видео из него
        this.getVideoFromUrl(this.url);
      } else {
        // иначе показываем панель поиска Youtube
        RM.views.searchYouTube && RM.views.searchYouTube.show(this.url, { choose: this.uploadVideoFromSearch });
      }
    },

    // Возвращает начало воспроизведения в секундах, если оно было задано в урле
    // Примеры параметров урла: t=20, t=20s, t=3m20s
    parseStartTime: function(url) {
      var timeStr,
        timeMatch,
        seconds = 0;

      timeStr = Utils.queryUrlGetParam('t', url);
      if (!timeStr) {
        return null;
      }

      timeMatch = timeStr.match(/(\d+)(m?)(\d*)(s?)/);

      if (_.isEmpty(timeMatch) || !parseInt(timeMatch[1], 10)) {
        return null;
      }

      if (timeMatch[2].toLowerCase() == 'm') {
        seconds += parseInt(timeMatch[1], 10) * 60;
      } else {
        // Если указаны минуты, превращаем их в секунды
        seconds += parseInt(timeMatch[1], 10);
      }
      if (parseInt(timeMatch[3], 10)) {
        seconds += parseInt(timeMatch[3], 10);
      } // Евсли указаны секунды к минутам, прибавляем их

      return seconds;
    },

    getVideoFromUrl: function(text) {
      var isYoutube, isVimeo, start_time;

      isVimeo = VideoUtils.isVimeo(text);
      isYoutube = VideoUtils.isYoutube(text);

      // если урл не соответствует ни одному видеохостингу, игнорируем
      if (!isYoutube && !isVimeo) {
        this.$emit('wrongUrl');
        this.isError = true;
        this.highlightremovalTimeout = setTimeout(
          function() {
            this.isError = false;
          }.bind(this),
          200
        );

        return;
      }

      start_time = this.parseStartTime(text); // В урле указано время, с которого воспроизводить?

      text = text.replace(/\/?#?$/, ''); // Отрезаем концевые слэш и хэш. Может иногда прицепляться браузером к урлу, а ембед-сервисы их не любят.
      var video_data = {
        url: text,
        provider_name: VideoUtils.getVideoProvider(text),
      };

      if (start_time) {
        video_data.start_time = start_time;
      }

      // Для Youtube айдишник видео получаем сразу из урла, а для Vimeo айдишник придет после запроса вместе с данными о видео
      if (isYoutube) {
        video_data.video_id = VideoUtils.getYoutubeIdFromUrl(text);
      }

      // получаем данные по видео
      // сохраняем AJAX объект, чтобы потом можно было его отменить
      this.videoEmbedXHR && this.videoEmbedXHR.abort();
      this.videoEmbedXHR = VideoUtils.getEmbedData(
        text,
        _.bind(function(data) {
          this.url = data.url ? data.url : this.url;
          VideoUtils.getHighResVideoThumbnail({
            provider: video_data.provider_name,
            thumbnail_url: data.thumbnail_url,
          }).then(
            _.bind(function(hiResThumbUrl) {
              data.thumbnail_url = hiResThumbUrl;
              this.$emit('loaded', _.extend(data, video_data));
            }, this)
          );
        }, this),
        _.bind(function(XMLHttpRequest, textStatus, errorThrown) {
          this.$emit('error', XMLHttpRequest, textStatus, errorThrown);
        }, this)
      );

      this.$emit('dataObtained', video_data);
    },

    // проверяем что строка начинается на http:// или https:// (а больше и не надо)
    isURL: function(text) {
      return /^http(s?)\:\/\//i.test(text);
    },

    onYoutubeClick: function() {
      RM.views.searchYouTube && RM.views.searchYouTube.show('', { choose: this.uploadVideoFromSearch });
    },

    onVimeoClick: function() {
      RM.views.searchVimeo && RM.views.searchVimeo.show('', { choose: this.uploadVideoFromSearch });
    },

    uploadVideoFromSearch: function(url) {
      this.getVideoFromUrl(url);
    },
  },
};
</script>

<style lang="less" type="text/less">
@import (reference) '../../css/common/constants.less';
@import (reference) '../../css/common/fonts.less';

@video-panel-input-height: 36px;
@video-panel-input-border-radius: 8px;

.video-panel-search {
  margin-bottom: 12px;

  .video-panel-icon {
    .center-vertically(16px, 16px);
    right: 8px;
    cursor: pointer;
    background: url('../../img/constructor/widgetbar/background/search.svg') no-repeat 0 0;
    opacity: 0.4;
    background-size: 16px 16px !important;
    .transition(0.2s, opacity, ease-out);

    &:hover {
      opacity: 0.6;
    }
    &.is-upload {
      right: 7px;
      width: 18px;
      height: 18px;
      background-image: url('../../img/constructor/controls/video_settings/social-upload.png');
      background-size: 18px 18px !important;
      &:hover {
        background-image: url('../../img/constructor/controls/video_settings/social-upload-hover.png');
      }
    }
    &.is-remove {
      right: 7px;
      width: 18px;
      height: 18px;
      background-image: url('../../img/constructor/controls/video_settings/delete.png');
      background-size: 18px 18px !important;
      &:hover {
        background-image: url('../../img/constructor/controls/video_settings/delete-hover.png');
      }
    }
    &.hidden {
      display: none;
    }
  }
}
.video-panel-input-wrapper {
  position: relative;
  width: 100%;
  height: @video-panel-input-height;
}
.video-panel-input {
  width: 100%;
  .border-box();
  height: @video-panel-input-height;
  padding: 5px 9px;
  border: none;
  border-radius: @video-panel-input-border-radius;
  font: inherit;
  background: white;
  color: inherit;
  -moz-appearance: none;
  &::-webkit-input-placeholder {
    color: rgba(151, 158, 168, 0.5);
  }
  &:-moz-placeholder {
    color: rgba(151, 158, 168, 0.5);
  }
  &.has-icon {
    padding-right: 30px;
  }
}
.video-panel-error {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1px;
  left: -1px;
  padding: 1px;
  border-radius: @video-panel-input-border-radius;
  background: #f2f2f2;
  .avenir_demi;
  font-weight: 600;
  font-size: 12px;
  color: #5d5d5d;
  letter-spacing: -0.1px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.video-panel-social {
  margin-top: 16px;
  font-size: 0;
  text-align: center;
}
.video-panel-social-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 6px;
  cursor: pointer;
  .multi-transition(e('0.3s opacity ease-in-out, 0.15s background ease-out'));
}
.video-panel-social-youtube {
  left: 0;
  background: url('../../img/constructor/controls/video_settings/social-youtube.png') no-repeat 0 0;
  background-size: 32px 32px !important;
  &:hover {
    background-image: url('../../img/constructor/controls/video_settings/social-youtube-hover.png');
  }
}
.video-panel-social-vimeo {
  left: 32px;
  background: url('../../img/constructor/controls/video_settings/social-vimeo.png') no-repeat 0 0;
  background-size: 32px 32px !important;
  &:hover {
    background-image: url('../../img/constructor/controls/video_settings/social-vimeo-hover.png');
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  .video-panel-search {
    .video-panel-input-wrapper {
      .video-panel-icon {
        &.is-upload {
          background: url('../../img/constructor/controls/video_settings/social-upload@2x.png');
          &:hover {
            background: url('../../img/constructor/controls/video_settings/social-upload-hover@2x.png');
          }
        }
        &.is-remove {
          background: url('../../img/constructor/controls/video_settings/delete@2x.png');
          &:hover {
            background: url('../../img/constructor/controls/video_settings/delete-hover@2x.png');
          }
        }
      }
    }
    .video-panel-social-youtube {
      background: url('../../img/constructor/controls/video_settings/social-youtube@2x.png');
      &:hover {
        background: url('../../img/constructor/controls/video_settings/social-youtube-hover@2x.png');
      }
    }
    .video-panel-social-vimeo {
      background: url('../../img/constructor/controls/video_settings/social-vimeo@2x.png');
      &:hover {
        background: url('../../img/constructor/controls/video_settings/social-vimeo-hover@2x.png');
      }
    }
  }
}
</style>
