/**
 * Контрол эффектов к изображениям
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/picture_crop.tpl';

var _super = ControlClass.prototype;

const PictureCrop = ControlClass.extend({
  name: 'picture_crop',

  className: 'control picture_crop',

  events: {
    'click .equilateral': 'equilateral',
    'click .fill': 'fill',
    'click .fit': 'fit',
    'click .x1': 'x1',
    'click .scale': 'setScale',
  },

  scaleParams: {
    min: 0.01,
    max: 5,
  },

  HANDLER_SIZE: 26,

  initialize: function(params) {
    this.template = templates['template-constructor-control-picture_crop'];

    this.initControl(params);

    this.block = this.blocks[0];

    if (!this.block.model.get('picture')) {
      this.$el.hide();
    }

    this.onFullWidthChange();

    this.onFullHeightChange();
  },

  onClick: function() {
    if (!this.block.$picture) return;
    if (this.$el.hasClass('inactive')) {
      return;
    }

    _super.onClick.apply(this, arguments);
  },

  render: function() {
    _super.render.apply(this, arguments);
    this.initScale();
    this.onScaleChange();

    // если мы внутри хотспота
    if (this.block.has_parent_block) {
      this.$el.addClass('hide-all-except-scale');
    }

    this.$equilateral = this.$('.equilateral');
  },

  select: function() {
    this.block.enterCropMode();

    this.$el.removeClass('fade');

    this.$handler.css('top', this.toScale(this.block.model.get('scale')));
    this.$helper.text(Math.floor(this.block.model.get('scale') * 100) + '%');
  },

  deselect: function() {
    this.block.leaveCropMode();
    _super.deselect.apply(this, arguments);
  },

  toggleEnabled: function(cropmode, transitionmode) {
    clearTimeout(this.cancelLoadingTimeout);

    if (cropmode && transitionmode) {
      if (!this.block.originalLoaded) this.$el.addClass('loading');
    } else {
      this.$el.addClass('checked');
      this.$icon.addClass('rmalttext-disable');
      this.cancelLoadingTimeout = setTimeout(
        _.bind(function() {
          this.$el.removeClass('loading');
        }, this),
        200
      );

      if (cropmode) _super.select.apply(this, arguments);
    }
  },

  onPictureLoaded: function() {
    this.$el.show();
  },

  bindLogic: function() {
    this.block.model.on('change:scale', this.onScaleChange);
    this.block.model.on('change:w change:h', this.onSizeChange);
    this.block.model.on('change:is_full_width', this.onFullWidthChange);
    this.block.model.on('change:is_full_height', this.onFullHeightChange);

    this.block.on('picture_added', this.onPictureLoaded);

    this.$scale = this.$panel.find('.scale');
    this.$handler = this.$panel.find('.handler');
    this.$helper = this.$panel.find('.helper');

    this.$handler.on('dragstart', this.onScaleStart);
    this.$handler.on('drag', this.onScale);
    this.$handler.on('dragend', this.onScaleStop);

    this.block.on('crop_click', this.onClick);
    this.block.on('transitionmode', this.toggleEnabled);
  },
  unBindLogic: function() {
    this.block.off('transitionmode', this.toggleEnabled);
    this.block.off('crop_click', this.onClick);

    if (!this.$handler) return;

    this.$handler.off('dragstart', this.onScaleStart);
    this.$handler.off('drag', this.onScale);
    this.$handler.off('dragend', this.onScaleStop);

    this.block.model.off('change:scale', this.onScaleChange);
    this.block.model.off('change:w change:h', this.onSizeChange);
    this.block.model.off('change:is_full_width', this.onFullWidthChange);
    this.block.model.off('change:is_full_height', this.onFullHeightChange);

    this.block.off('picture_added', this.onPictureLoaded);
  },

  equilateral: function() {
    if (this.$equilateral.hasClass('fade')) return;

    var box = this.block.getBoxData();

    if (box.w > box.h) {
      var newBox = { width: box.h, height: box.h, left: box.x + (box.w - box.h) / 2, top: box.y };
      this.block.frame.doResize(newBox);
      this.block.onCropResize(newBox);
      this.block.onResizeEnd();
    } else {
      var newBox = { width: box.w, height: box.w, top: box.y + (box.h - box.w) / 2, left: box.x };
      this.block.frame.doResize(newBox);
      this.block.onCropResize(newBox);
      this.block.onResizeEnd();
    }

    this.$equilateral.addClass('fade');
  },

  fill: function() {
    this.block.fill();
  },

  fit: function() {
    this.block.fit();
  },

  x1: function() {
    if (this.$('.x1').hasClass('fade')) return;

    this.block.rescale(1, { smooth: true });
  },

  onScaleStart: function(event, drag) {
    drag.offset = parseInt(this.$handler.css('top'));
    this.$handler.addClass('dragging');
  },

  onScale: function(event, drag) {
    var x = drag.deltaY + drag.offset;

    var zoom = this.fromScale(x).toFixed(2);

    if (zoom < this.scaleParams.min) {
      zoom = this.scaleParams.min;
    }

    if (zoom > this.scaleParams.max) {
      zoom = this.scaleParams.max;
    }

    this.block.rescale(zoom);
  },

  onScaleChange: function(model, zoom, options) {
    var func = options && options.smooth ? 'animate' : 'css';

    this.$handler[func]({ top: this.toScale(zoom) });
    this.$helper.text(Math.floor(zoom * 100) + '%');

    this.$('.x1').toggleClass('fade', Math.abs(this.block.model.get('scale') - 1) < 0.01);
  },

  onSizeChange: function(model, value, options) {
    if (model.get('w') != model.get('h') && this.$equilateral.hasClass('fade')) {
      this.$equilateral.removeClass('fade');
    }
  },

  onScaleStop: function() {
    this.$handler.removeClass('dragging');
  },

  setScale: function(e) {
    if ($(e.target).closest('.handler').length) return;
    var x = e.pageY - $(e.target).offset().top - this.HANDLER_SIZE / 2;

    this.block.rescale(this.fromScale(x).toFixed(2), { smooth: true });
  },

  initScale: function() {
    this._scaleHeight = parseInt(this.$scale.css('height')) - parseInt(this.$handler.css('height')) - 4;

    // y = a + b/(c+x)

    var m = this.scaleParams.min;
    var M = this.scaleParams.max;
    var L = this._scaleHeight;
    var s = Math.floor(this._scaleHeight / 2);

    var c = ((1 - m) * L) / (((M - 1) * L) / s - M + m);
    var b = (M - 1) * (c / s + 1) * c;
    var a = M - b / c;

    _.extend(this.scaleParams, {
      a: a,
      b: b,
      c: c,
    });
  },

  toScale: function(y) {
    // неизвестно откуда (наверное из хотспота), но иногда значения приходят в виде строки, а не числа
    y = y - 0;

    // для автокропа в режиме ресайза хотспота нам надо верхний предел масштабирования белать динамическим
    // так как для мелких картинок при широком хотспоте он запросто может быть больше 500%
    if (y > this.scaleParams.max) {
      this.scaleParams.max = y;
      this.initScale();
    }

    var a = this.scaleParams.a;
    var b = this.scaleParams.b;
    var c = this.scaleParams.c;

    return b / (y - a) - c;
  },

  fromScale: function(x) {
    if (x < 0) return this.scaleParams.max;

    var a = this.scaleParams.a;
    var b = this.scaleParams.b;
    var c = this.scaleParams.c;

    return a + b / (c + x);
  },

  // Показываем контрол, только если у блока есть картинка и он не растянут
  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();
    return !(
      blocks.length == 1 &&
      ((blocks[0].isPictureEmpty && blocks[0].isPictureEmpty()) || blocks[0].isFullWidth() || blocks[0].isFullHeight())
    );
  },

  onEnterKey: function() {
    if (this.selected) {
      this.master.deselect();
    }
  },

  onFullWidthChange: function() {
    this.$el.toggleClass('inactive', !!this.block.model.get('is_full_width'));
  },

  onFullHeightChange: function() {
    this.$el.toggleClass('inactive', !!this.block.model.get('is_full_height'));
  },
});

export default PictureCrop;
