/**
 * Контрол эффектов к изображениям
 * NOTE: 12.07.2019 — эта штука мертвая
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/picture_effects.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const PictureEffects = ControlClass.extend({
  name: 'picture_effects', // должно совпадать с классом вьюхи

  className: 'control picture_effects',

  saveOnDeselect: true,

  events: {
    'click .thumb': 'toggleEffect',
  },

  initialize: function(params) {
    this.template = templates['template-constructor-control-picture_effects'];

    this.initControl(params);

    this.block = this.blocks[0];

    this.block.model.on('change:picture', this.reloadEffects);

    this.options = {
      effects: this.block.effectNames,
      thumb: this.block.model.get('picture') && this.block.model.get('picture').thumbUrl,
    };

    this.block.on(
      'picture_added',
      function() {
        this.$el.show();
      },
      this
    );
    if (!this.block.model.get('picture')) {
      this.$el.hide();
    }
  },

  bindLogic: function() {
    this.$thumbs = this.$('.thumb:not(.original)');
    this.$circles = this.$('.effect');
  },

  select: function() {
    this.$('.effect').show();

    if (!this.effectsUrl) this.loadEffects();

    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-picture_effects');
  },

  loadEffects: function() {
    this.block.loadEffects({
      success: _.bind(function(effects, url) {
        this.picture = _.clone(this.block.model.get('picture'));

        var effect = this.block.model.get('picture').effect;
        this.effectsUrl = url;
        this.$thumbs.each(function(i) {
          $(this).css({ 'background-image': 'url(' + url + ')' });

          if (effects[i] == effect)
            $(this)
              .closest('.effect')
              .addClass('active');
        });

        this.$('.original')
          .css('background-image', "url('" + this.block.model.get('picture').thumbUrl + "')")
          .closest('.effect')
          .toggleClass('active', !effect);
      }, this),
    });
  },

  reloadEffects: function() {
    var picture = this.block.model.get('picture') || {};

    this.$circles && this.$circles.removeClass('active');
    if (picture.effect) {
      this.$('.thumb')
        .filter(function() {
          return $(this).data('effect') == picture.effect;
        })
        .closest('.effect')
        .addClass('active');
    } else {
      this.$('.original')
        .closest('.effect')
        .addClass('active');
    }

    this.removeLoaders();

    if (this.picture && this.picture.url == picture.url) return;

    this.deselect();
    var self = this;

    if (_.isEmpty(this.block.model.get('picture'))) return;
    this.$('.thumb').each(function(i) {
      $(this)
        .css({ 'background-image': '' })
        .closest('.effect')
        .removeClass('active');
      delete self.effectsUrl;
      self.loadEffects();
    });
  },

  save: function() {
    if (!this.updated) return;

    this.updated = false;
    this.block.model.save();
  },

  toggleEffect: function(e) {
    var effect = $(e.currentTarget).data('effect');
    var model = this.block.model;
    if (effect == 'original' && !model.get('picture').effect) return;

    this.removeLoaders();

    this.updated = true;
    this.$('.effect').removeClass('active');

    var $circle = $(e.currentTarget).closest('.effect');

    $circle.addClass('animation');
    setTimeout(function() {
      $circle.removeClass('animation');
    }, 75);

    // Если отменяем эффект - то это значит что мы грузим эффект "оригинал"
    if (model.get('picture').effect == effect || effect == 'original') {
      delete model.get('picture').effect;
      var $thumb = this.$('.original');
      this.block.workspace.insertLoaderToElem($thumb.closest('.effect'), { top: 6, left: 6 });
      this.block.getFinalImage(this.block.updatePicture);
      return;
    }

    var $thumb = $(e.currentTarget);
    this.block.workspace.insertLoaderToElem($circle, { top: 6, left: 6 });

    model.get('picture').effect = effect;
    this.block.getFinalImage(this.block.updatePicture);
  },

  removeLoaders: function() {
    this.$('.rm-loader').fadeOut('fast', function() {
      $(this).remove();
    });
  },

  // Показываем контрол, только если у блока есть картинка
  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();
    return !(blocks.length == 1 && blocks[0].isPictureEmpty && blocks[0].isPictureEmpty());
  },
});

export default PictureEffects;
