module.exports = {["template-export-pdf-typekit-substitution-news-gothic"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n<style id="export-pdf-typekit-substitution">\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/News Gothic Bold Italic/c6f4e54e-1d69-4559-b813-e2fc4ff29160-3.woff") format("woff"),\n		 url("/fonts/webtype/News Gothic Bold Italic/c6f4e54e-1d69-4559-b813-e2fc4ff29160-4.svg#web") format("svg");\n	font-style: italic;\n	font-weight: 700;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/News Gothic Bold/9aab62b7-bc6f-405a-ad75-227bcf89d9dc-3.woff") format("woff"),\n		 url("/fonts/webtype/News Gothic Bold/9aab62b7-bc6f-405a-ad75-227bcf89d9dc-4.svg#web") format("svg");\n	font-style: normal;\n	font-weight: 700;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/News Gothic Italic/6327ba72-bfd5-4038-9658-e3a4a95a5c98-3.woff") format("woff"),\n		 url("/fonts/webtype/News Gothic Italic/6327ba72-bfd5-4038-9658-e3a4a95a5c98-4.svg#web") format("svg");\n	font-style: italic;\n	font-weight: 400;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/News Gothic/915b0ec4-cd16-40de-883e-1d77c3d07840-3.woff") format("woff"),\n		 url("/fonts/webtype/News Gothic/915b0ec4-cd16-40de-883e-1d77c3d07840-4.svg#web") format("svg");\n	font-style: normal;\n	font-weight: 400;\n}\n</style>\n';
}
return __p;
},["template-export-pdf-typekit-substitution-georgia-pro"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n<style id="export-pdf-typekit-substitution">\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Georgia Pro Black/4ec59c91-f66b-4751-8456-e114e9e14673-3.woff") format("woff"),\n		 url("/fonts/webtype/Georgia Pro Black/4ec59c91-f66b-4751-8456-e114e9e14673-4.svg#web") format("svg");\n	font-style: normal;\n	font-weight: 900;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Georgia Pro Bold Italic/6f283590-c3c2-416a-95c2-ca67d04aa929-3.woff") format("woff"),\n		 url("/fonts/webtype/Georgia Pro Bold Italic/6f283590-c3c2-416a-95c2-ca67d04aa929-4.svg#web") format("svg");\n	font-style: italic;\n	font-weight: 700;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Georgia Pro Bold/6f633c58-c751-4d08-9cea-2b27f74f37de-3.woff") format("woff"),\n		 url("/fonts/webtype/Georgia Pro Bold/6f633c58-c751-4d08-9cea-2b27f74f37de-4.svg#web") format("svg");\n	font-style: normal;\n	font-weight: 700;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Georgia Pro Italic/e3bc0ad4-bdaa-4467-82a0-d90f697b7266-3.woff") format("woff"),\n		 url("/fonts/webtype/Georgia Pro Italic/e3bc0ad4-bdaa-4467-82a0-d90f697b7266-4.svg#web") format("svg");\n	font-style: italic;\n	font-weight: 400;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Georgia Pro SemiBold Italic/0e025df5-ba54-4bbb-84b4-c5b337a5b55b-3.woff") format("woff"),\n		 url("/fonts/webtype/Georgia Pro SemiBold Italic/0e025df5-ba54-4bbb-84b4-c5b337a5b55b-4.svg#web") format("svg");\n	font-style: italic;\n	font-weight: 600;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Georgia Pro SemiBold/60fe12ce-0223-4a9a-8e5c-b3e7bd381bc4-3.woff") format("woff"),\n		 url("/fonts/webtype/Georgia Pro SemiBold/60fe12ce-0223-4a9a-8e5c-b3e7bd381bc4-4.svg#web") format("svg");\n	font-style: normal;\n	font-weight: 600;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Georgia Pro/b97bef32-c0cb-4ad3-b724-1204d229731c-3.woff") format("woff"),\n		 url("/fonts/webtype/Georgia Pro/b97bef32-c0cb-4ad3-b724-1204d229731c-4.svg#web") format("svg");\n	font-style: normal;\n	font-weight: 400;\n}\n</style>\n';
}
return __p;
},["template-export-pdf-typekit-substitution-nitti"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n<style id="export-pdf-typekit-substitution">\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Nitti Light Italic/15aba0ec-4ceb-49e2-985c-a95134a60aed-3.woff") format("woff"),\n		 url("/fonts/webtype/Nitti Light Italic/15aba0ec-4ceb-49e2-985c-a95134a60aed-4.svg#web") format("svg");\n	font-style: italic;\n	font-weight: 300;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Nitti Light/6fd7c5e9-3131-4cb6-a99e-da74343b1253-3.woff") format("woff"),\n		 url("/fonts/webtype/Nitti Light/6fd7c5e9-3131-4cb6-a99e-da74343b1253-4.svg#web") format("svg");\n	font-style: normal;\n	font-weight: 300;\n}\n@font-face {\n	font-family: "'+
((__t=(id))==null?'':__t)+
'";\n	src: url("/fonts/webtype/Nitti Medium/9054f262-2d2d-480f-9360-4560f110243b-3.woff") format("woff"),\n		 url("/fonts/webtype/Nitti Medium/9054f262-2d2d-480f-9360-4560f110243b-4.svg#web") format("svg");\n	font-style: normal;\n	font-weight: 500;\n}\n</style>\n';
}
return __p;
},}