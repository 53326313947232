/**
 * Конструктор для виджета Галереи
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockCommonPicture from './common-picture';
import BlockFrameClass from '../block-frame';
import BlockClass from '../block';
import Viewports from '../../common/viewports';
import SlideshowPlayer from '../../common/slideshow-player';
import templates from '../../../templates/constructor/blocks/slideshow.tpl';
import Uploader from '../helpers/uploader';
import { Utils } from '../../common/utils';
import SVGUtils from '../../common/svgutils';

const FONTSIZE_LINE_HEIGHT_RATIO = 1.25;
const SlideShowBlock = BlockCommonPicture.extend(
  {
    // excludedFromLib: true, // Временно скрыто из палитры виджетов

    name: 'Slideshow',
    sort_index: 3, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'slideshow',

    icon_color: '#00BFDD',

    initial_controls: [
      'slideshow_settings',
      'slideshow_manager',
      'text_typography',
      'text_bius',
      'text_color',
      'common_animation',
      'common_position',
      'common_layer',
      'common_lock',
    ],

    viewport_fields: Viewports.viewport_fields.slideshow,

    // параметры для расчета ограничения минимальных размеров виджета при ресайзе
    MIN_IMAGES_WIDTH: 200,
    MIN_IMAGES_HEIGHT: 200,
    MIN_CAPTIONS_HEIGHT: 14,

    // размеры стрелок переключения слайдов
    ARROW_NOUN_ICON_WIDTH: 18,
    ARROW_NOUN_ICON_HEIGHT: 18,

    DEFAULT_ARROWS_CONTAINER: {
      LEFT:
        '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40"><path d="M23.5 11.5 L15.5 19.5 L23.5 27.5" stroke-linejoin="round" stroke-linecap="round" style="fill:none; stroke:#ffffff; stroke-width:3px"></path></svg>',
      RIGHT:
        '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40"><path d="M16.5 11.5 L24.5 19.5 L16.5 27.5" stroke-linejoin="round" stroke-linecap="round" style="fill:none; stroke:#ffffff; stroke-width:3px"></path></svg>',
    },

    MAX_ICON_CACHE_SIZE: 30,
    // параметры для правильной работы контролов текста (они те же самые что и для текстового виджета)
    params: {
      multipleName: 'multiple values', // важная константа, ставится заместо значения в this.cur_selection_styles у тех свойств которые в текущем выделении имеют несколько значений
      tabIndent: 28, // ширина в пикселах одного tab отступа
      fontSizeLineHeightRatio: FONTSIZE_LINE_HEIGHT_RATIO,
      minFontSize: 8,
      maxFontSize: 999,
      minLineHeight: 10,
      maxLineHeight: 999,
      minLetterSpacing: -99,
      maxLetterSpacing: 999,
    },

    initialize: function(model, workspace) {
      this.initBlock(model, workspace);

      this.inner_template = templates['template-constructor-block-slideshow'];

      // параметры для правильной работы контролов текста (они те же самые что и для текстового виджета)
      this.cur_selection_styles = {};
      this.cur_selection_styles_all = {};
      this.queue = [];

      this.frameClass = slideshowFrame;

      this._imgsPreload = [];

      // индикаторы загрузки картинок
      this.picloaded = {};

      // здесь вешаем только на изменение параметров темы
      // потому что onThemeParamsChange может менять еще другие параметры модели
      this.model.on('change:theme_data', this.onThemeParamsChange);

      this.currentIconsData = {};

      this.workspace.on('main-dragover', this.highlightDropZone);

      this.model.skipResponse = true;

      // заполняем переменные cur_selection_styles и cur_selection_styles_all (чтобы контролы текста могли изначально становить свое состояние)
      this.fillTextStyles();

      this.workspace.on('deselect', this.showOverlay);
      this.workspace.on('deselect', this.setToDefaultArrows);

      this.workspace.on('select', this.hideOverlay);
      this.saveDebounced = _.debounce(function() {
        this.model.save();
      }, 2000);

      this.renderCorrectIconsDebounced = _.debounce(this.renderCorrectIcons, 10);
    },

    render: function() {
      this.create();

      $(this.inner_template(this.model.attributes)).appendTo(this.$content);

      this.$el.addClass('slideshow can-drop');

      // защита от предыдущей версии виджета, просто чтобы не было ошибок и его можно было удалить в конструкторе
      if (!this.model.get('theme_data')) return;

      this.setControls();
      // создаем и изначально отрисовываем виджет
      // также передаем Мэг для того, чтобы корректно отработал .getPicsTransitionTime()
      this.slideshowPlayer = new SlideshowPlayer(
        _.clone(this.model.attributes),
        this.$content,
        'constructor',
        this,
        this.workspace && this.workspace.mag
      );

      this.onThemeParamsChange();

      this.uploader = new Uploader(this, {
        fileInput: this.$('.uploadInput'),
        unique: true, // Наш флаг о том, что для каждой картинки генерить уникальное имя
      });

      this.bindUploader(this.uploader);

      this.on('changeStyleSpan', this.textStyleChanged);
      this.on('changeStyleParagraph', this.textStyleChanged);

      // во время рендера если используются дефолтные иконки вызывать функцию не надо,
      // потому что для такого случая отработают свг стрелок, которые находятся внутри темплейта
      if (this.model.get('theme_data').arrows_type !== 'default') {
        this.renderCorrectIcons();
      }
      // с дебаунсом, чтобы в модель успели попасть правильные данные
      this.listenTo(this, 'arrow_type_changed', this.renderCorrectIconsDebounced);

      this.triggerReady();
    },

    renderCorrectIcons: function() {
      var themeData = this.model.get('theme_data'),
        arrowsType = themeData.arrows_type,
        color,
        opacity;

      if (arrowsType === 'default') {
        color = themeData.controls_color;
        opacity = themeData.controls_opacity;

        this.renderIcon({
          $svg: $(this.DEFAULT_ARROWS_CONTAINER.LEFT),
          activeArrow: 'left_arrow_default',
        });
        this.renderIcon({
          $svg: $(this.DEFAULT_ARROWS_CONTAINER.RIGHT),
          activeArrow: 'right_arrow_default',
        });
        // если цвет иконок поменяли, когда были выбраны не дефолтные иконки,
        // а потом опять перешли на дефолтные, то applyVisualState() в slideshow-player.js
        // который в том числе красит иконки отработает раньше, чем они отрендерятся,
        // поэтому приходится отдельно запускать покраску
        this.slideshowPlayer.colorArrows(color, opacity);
      } else if (arrowsType === 'noun') {
        this.getNounArrows();
      }
    },

    hideOverlay: function() {
      this.$('.jsSlideshowOverlay').hide();
    },

    showOverlay: function() {
      this.$('.jsSlideshowOverlay').show();
    },

    // устанавливает дефолтные стрелки в случае, когда перешли на науоновские иконки, но ничего там не выбрали
    setToDefaultArrows: function() {
      const themeData = this.model.get('theme_data');
      const arrowsType = themeData.arrows_type;
      const leftArrow = themeData.left_icon_noun;
      const rightArrow = themeData.right_icon_noun;

      // c defer, чтобы успели прописаться фолбэки в svgutils
      _.defer(() => {
        if (arrowsType === 'noun' && (_.isEmpty(leftArrow) || _.isEmpty(rightArrow))) {
          this.model.save({
            theme_data: _.extend(this.model.get('theme_data'), {
              arrows_type: 'default',
            }),
          });
        }
      });
    },

    bindUploader: function(uploader) {
      uploader.on('add', this.onAddUpload); // срабатывает на каждый файл
      uploader.on('send', this.onSendUpload); // срабатывает непосредственно перед началом отправки картинки
      uploader.on('done', this.onDoneUpload);
      uploader.on('fail', this.onFailUpload);
    },

    getNounArrows: function() {
      var leftArrow = this.model.get('theme_data').left_icon_noun,
        rightArrow = this.model.get('theme_data').right_icon_noun;

      if (_.isEmpty(leftArrow) && _.isEmpty(rightArrow)) {
        return;
      }
      if (!_.isEmpty(leftArrow)) {
        this.getIconSVG(
          leftArrow.noun_id || leftArrow.rm_id,
          leftArrow.noun_url,
          'left_arrow_noun',
          function(err, data) {
            if (err || !data.$svg) {
              return;
            }
          }.bind(this)
        );
      }

      if (!_.isEmpty(rightArrow)) {
        this.getIconSVG(
          rightArrow.noun_id || rightArrow.rm_id,
          rightArrow.noun_url,
          'right_arrow_noun',
          function(err, data) {
            if (err || !data.$svg) {
              return;
            }
          }.bind(this)
        );
      }
    },

    rasterizeIconsBeforeDestroy: function() {
      if (!_.isEmpty(this.currentIconsData.left_arrow_noun)) {
        this.rasterizeIconSVG({
          $svg: this.getСoloredIconSVG('left_arrow_noun'),
          activeArrow: 'left_arrow_noun',
        });
      }

      if (!_.isEmpty(this.currentIconsData.right_arrow_noun)) {
        this.rasterizeIconSVG({
          $svg: this.getСoloredIconSVG('right_arrow_noun'),
          activeArrow: 'right_arrow_noun',
        });
      }
    },

    renderIcon: function(options) {
      var $svg = options.$svg,
        arrow = options.activeArrow;

      if (['left_arrow_noun', 'left_arrow_default'].includes(arrow)) {
        this.$el
          .find('.prev-picture-arrow-bottom')
          .empty()
          .html($svg.clone())
          .toggleClass('noun', arrow === 'left_arrow_noun');

        this.$el
          .find('.prev-picture-arrow-middle')
          .empty()
          .html($svg.clone())
          .toggleClass('noun', arrow === 'left_arrow_noun');
      }
      if (['right_arrow_noun', 'right_arrow_default'].includes(arrow)) {
        this.$el
          .find('.next-picture-arrow-bottom')
          .empty()
          .html($svg.clone())
          .toggleClass('noun', arrow === 'right_arrow_noun');

        this.$el
          .find('.next-picture-arrow-middle')
          .empty()
          .html($svg.clone())
          .toggleClass('noun', arrow === 'right_arrow_noun');
      }
    },

    prepareIconSVG: function($raw_svg) {
      var $tmpSVG = $raw_svg.clone().appendTo($('body')),
        $iconSVG = $raw_svg.clone();

      $tmpSVG.get(0).setAttributeNS(null, 'viewBox', '0 0 ' + $tmpSVG.width() + ' ' + $tmpSVG.height());

      var bbox = $tmpSVG.get(0).getBBox();
      $tmpSVG.remove();

      // сохраняем соотношение сторон текущей SVG иконки.
      // пригодиться для пересчета ширины контейнера Иконки Кнопки.
      // getIconData() достанет это значение из возвращенного этим методом
      // perepareIconSVG() и закэширует его.
      $iconSVG.data('aspect_ratio', bbox.width / bbox.height);

      $iconSVG.removeAttr('width');
      $iconSVG.removeAttr('height');

      // именно так, потому что jQuery не может нормально назначить аттрибуты для SVG.
      $iconSVG.get(0).setAttributeNS(null, 'viewBox', bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height);
      $iconSVG.get(0).setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');

      $iconSVG.css({
        width: '100%',
        height: '100%',
      });

      return $iconSVG;
    },

    // метод сохраняет SVG-данные иконки в кэш для последующего реиспользования.
    cacheSVGData: function(data) {
      window.iconSVGCache.push(data);

      if (window.iconSVGCache.length > this.MAX_ICON_CACHE_SIZE) {
        window.iconSVGCache.shift();
      }
    },

    // метод ищет и возвращает SVG-данные иконки из кэша.
    getSVGCacheItem: function(id) {
      return window.iconSVGCache && _.findWhere(window.iconSVGCache, { id: id });
    },

    // клонирует SVG текущей Иконки и меняет его цвет
    getСoloredIconSVG: function(activeArrow) {
      var color = this.model.get('theme_data').controls_color,
        opacity = this.model.get('theme_data').controls_opacity,
        fill_color,
        $iconSVG = this.currentIconsData[activeArrow].$svg.clone();

      fill_color = Utils.getRGBA(color, 1);

      $iconSVG.find('*:not(.fixed-color)').each(function() {
        var $this = $(this);

        $this.css({ fill: '', 'fill-opacity': '' });

        $this.get(0).removeAttribute('fill');
        $this.get(0).removeAttribute('fill-opacity');
      });

      $iconSVG.get(0).setAttribute('fill', fill_color);
      $iconSVG.get(0).setAttribute('fill-opacity', opacity);

      return $iconSVG;
    },

    rasterizeIconSVG: function(options) {
      this.rasterizeXhr && this.rasterizeXhr.abort();
      this.rasterizeXhr = SVGUtils.rasterize({
        widgets: [this.model],
        svg: options.$svg,
        mid: this.workspace.mag.get('_id'),
        viewport: this.model.getViewport(),
        size: {
          width: this.ARROW_NOUN_ICON_WIDTH,
          height: this.ARROW_NOUN_ICON_HEIGHT,
        },
        activeArrow: options.activeArrow,
      });
    },

    getIconSVG: function(id, icon_url, activeArrow, callback) {
      var isLeft = activeArrow.includes('left'),
        iconXhr = isLeft ? 'iconXhrLeft' : 'iconXhrRight',
        iconRequestInProgress = isLeft ? 'iconLeftRequestInProgress' : 'iconRightRequestInProgress',
        cachedIconData,
        req_data,
        iconData;

      this[iconXhr] && this[iconXhr].abort();

      cachedIconData = this.getSVGCacheItem(id);

      if (cachedIconData) {
        iconData = {
          $svg: cachedIconData.$svg,
          noun_url: cachedIconData.noun_url,
          aspect_ratio: cachedIconData.$svg.data('aspect_ratio'),
        };
        this.currentIconsData[activeArrow] = iconData;
        this.renderIcon({
          $svg: this.getСoloredIconSVG(activeArrow),
          activeArrow: activeArrow,
        });
        return callback(null, iconData);
      }

      var onSuccess = function(data) {
        var $svg = $('<div></div>')
          .append($(data.svg))
          .find('svg');

        if (!$svg.length) {
          return;
        }

        $svg = this.prepareIconSVG($svg);

        iconData = {
          id: id,
          $svg: $svg,
          noun_url: data.new_url,
          aspect_ratio: $svg.data('aspect_ratio'),
        };

        this.currentIconsData[activeArrow] = iconData;
        this.cacheSVGData(iconData);

        this.renderIcon({
          $svg: this.getСoloredIconSVG(activeArrow),
          activeArrow: activeArrow,
        });

        return callback(null, {
          $svg: $svg,
          noun_url: data.new_url,
        });
      }.bind(this);

      req_data = {
        method: 'POST',
        url: '/api/authservice/noun/icon',
        data: {
          id: id,
          url: icon_url,
        },
        dataType: 'json',
        success: function(data) {
          this[iconRequestInProgress] = false;

          onSuccess(data);
        },
        error: function(xhr) {
          this[iconRequestInProgress] = false;

          return callback(xhr);
        },
        context: this,
      };

      // если id иконки начинается с «rm», то это не иконка Noun, а наша,
      // и грузить ее нужно по-другому.
      if (/^rm/.test(id)) {
        _.extend(req_data, {
          method: 'GET',
          url: icon_url,
          cache: false, // иначе Хром берет кешированную картинку и не подставляет нужные заголовки для кроссдоменного запроса и сам же блокирует запрос.
          success: function(data) {
            this[iconRequestInProgress] = false;

            onSuccess({ svg: $(data).find('svg'), new_url: icon_url });
          },
          data: null,
          dataType: null,
        });
      }

      this[iconRequestInProgress] = true;

      this[iconXhr] = $.ajax(req_data);
    },

    fillTextStyles: function() {
      // добавляем  фейковые настройки связки размера шрифта и интерлиньяжа и их пропорций (это на случай если их нет в модели, в частности для слайдшоу которые были сохданы до этого апдейта)
      // всё для панельк типографики (которая писалась для текстового виджета, и тут нам приходиться подавать ей данные в том виде, к которому она привыкла)
      var defaultTypographyPanelSettings = {
        'size-leading-linked': true,
        'size-leading-ratio': this.params.fontSizeLineHeightRatio,
      };

      this.cur_selection_styles = _.defaults(_.clone(this.model.get('text_style')), defaultTypographyPanelSettings);
      this.cur_selection_styles_all = [
        _.defaults(_.clone(this.model.get('text_style')), defaultTypographyPanelSettings),
      ];
    },

    setControls: function() {
      this.controls = this.initial_controls;
    },

    onAddUpload: function(e, data) {
      var pic = null;

      if (!this.replacingPic) {
        // Добавление новой картинки
        var maxnum = this.getMaxNum();
        pic = { num: maxnum + 1, name: data.files && data.files[0].name, uploadData: data };
        this.trigger('addUpload', pic);
      } else {
        if (this.queue.indexOf(this.replacingPic) > -1) {
          // Убираем заменяемую картинку из очереди на аплоад
          this.cancelUpload(this.replacingPic);
        }

        // Используем один и тот же объект картинки везде, поэтому удаляем старые свойства и добавляем ее в очередь аплоада
        for (var key in this.replacingPic)
          if (key != 'num' && key != 'id' && key != 'text') delete this.replacingPic[key];

        this.replacingPic.name = data.files && data.files[0] && data.files[0].name;
        this.replacingPic.uploadData = data;

        pic = this.replacingPic;
        this.replacingPic = null;
        this.trigger('replaceUpload', pic);
      }

      this.queue.push(pic);
    },

    // Событие нужно, чтобы показывать какая картинка в данный момент аплоадится
    onSendUpload: function(e, data) {
      this.trigger('nowUploading', this.queue[0]);
    },

    onDoneUpload: function(e, data) {
      var uploadedPic = this.queue.shift();

      if (!data.result) {
        this.trigger('failUpload', uploadedPic);
        return console.error('picture upload error!', data);
      }

      var pictures = _.clone(this.model.get('pictures')) || [];
      var wasEmpty = !pictures.length;

      var index = _.indexOf(pictures, uploadedPic);
      var pic = _.extend({ num: uploadedPic.num }, data.result.picture, data.result.size);

      if (index > -1) pictures[index] = _.extend(pic, { id: pictures[index].id, text: pictures[index].text });
      else pictures.push(_.extend(pic, { id: Utils.generateUUID(), text: '' }));

      this.tempSave({ pictures: pictures });

      this.queue = this.queue || [];

      this.trigger('doneUpload', pic);

      // Покажем slideshow manager, изначально он не показывается
      if (wasEmpty) this.updateControls();
    },

    onFailUpload: function(e, data, err) {
      var uploadedPic = this.queue.shift();

      var pictures = _.clone(this.model.get('pictures')) || [];
      var index = _.indexOf(pictures, uploadedPic);
      uploadedPic.error = true;
      uploadedPic.errorText = err;

      if (index > -1)
        pictures[index] = _.omit(
          _.extend(uploadedPic, { id: pictures[index].id, text: pictures[index].text }),
          'uploadData'
        );
      else pictures.push(_.omit(_.extend(uploadedPic, { id: Utils.generateUUID(), text: '' }), 'uploadData'));

      this.model.set('pictures', pictures);

      this.queue = this.queue || [];

      this.updateControls();
      this.trigger('failUpload', uploadedPic, err);
      this.tempSave({ pictures: pictures }); // не генерим Undo запись на каждый успешный аплоад - только на целиком всю очередь
    },

    deletePic: function(num) {
      var delPic = _.findWhere(this.queue, { num: num });
      if (delPic) {
        // Удаляем из очереди на аплоад
        this.cancelUpload(delPic);
      } else {
        delPic = _.findWhere(this.model.get('pictures'), { num: num }); // Удаляем из списка загруженных
        this.model.set('pictures', _.without(this.model.get('pictures'), delPic));
      }

      // if (!delPic) return //Странная ситуация, такого не должно происходить

      // Уменьшаем всем картинкам после удаляемой номер на 1
      _.each(this.getAllPics(), function(pic) {
        if (pic.num > delPic.num) pic.num -= 1;
      });

      this.trigger('reorder');

      this.model.save({}, { silent: !_.isEmpty(this.queue) }); // Если очередь на загрузку еще есть то не создаем запись Undo

      // Скрываем Controls manager если нет загруженных картинок
      if ((this.model.get('pictures') || []).length == 0) this.updateControls();
    },

    updatePic: function(num) {
      this.replacingPic = _(this.getAllPics()).findWhere({ num: num });

      this.uploader.$uploader.trigger('click', 'stop');
    },

    cancelUpload: function(delPic) {
      if (this.queue.indexOf(delPic) == 0) {
        delPic.uploadData.abort();

        // Проверяем есть ли еще картинки на аплоад в очереди, почему то на них не генерится событие, если отменить текущую загружаемую
        if (this.queue[1]) this.trigger('nowUploading', this.queue[1]);
      }

      delPic.uploadData.files[0].skipped = true; // флаг, говорящий аплоадеру что файл аплоадить не надо

      this.queue = _.without(this.queue, delPic);
    },

    removePictures: function() {
      this.uploader.abort();
      this.model.unset('pictures');
      this.model.save({}, { silent: true });
      this.queue = [];
      this.trigger('clear');
    },

    // Поменять местами картинки - картинка может стоять в очереди на аплоад
    swapPictures: function(from, to) {
      var pics = _(this.getAllPics()).sortBy(function(pic) {
        return pic.num;
      });

      var pic = _.findWhere(pics, { num: from });

      // Немного магии перестановок
      for (var i = from; i != to + (from < to ? 1 : -1); from < to ? i++ : i--) {
        if (i == from) continue;
        pics[i - 1].num = from < to ? pics[i - 1].num - 1 : pics[i - 1].num + 1;
      }

      pic.num = to;

      this.trigger('reorder');

      this.fixPicturesOrder(pics);

      if (this.saveXHR) this.saveXHR.abort();
      this.saveXHR = this.model.save({}, { silent: !_.isEmpty(this.queue) });

      this.workspace.trigger('redraw');

      this.slideshowPlayer.applyPictures(_.clone(this.model.attributes));
    },

    // Сохранение PATCH с отменой предыдущего save
    tempSave: function(data) {
      if (this.tempSaveXHR) {
        this.tempSaveXHR.abort();
        delete this.tempSaveXHR;
      }

      if (data && data.pictures) {
        this.fixPicturesOrder(data.pictures);
      }

      this.tempSaveXHR = this.model.save(data, { patch: true, skipHistory: !_.isEmpty(this.queue) }).success(
        _.bind(function() {
          delete this.tempSaveXHR;
        }, this)
      );
    },

    // Правим номера картинок
    fixPicturesOrder: function(pictures) {
      var pics = _.sortBy(pictures, 'num');
      for (var i = 0; i < pics.length; i++) {
        pics[i].num = i + 1;
      }
    },

    getMaxNum: function() {
      var pics = this.getAllPics();
      if (_.isEmpty(pics)) return 0;

      return _.max(_.pluck(pics, 'num'));
    },

    // Получить картинки вместе со стоящими в очереди на загрузку
    getAllPics: function() {
      return _.union(this.model.get('pictures') || [], this.queue);
    },

    updateDropZone: function() {
      var $el = this.$el;

      if (this.manager) $el = $el.add(this.manager.$uploadDropZone);
      if (this.settingsPanel && this.settingsPanel.canUpload()) $el = $el.add(this.settingsPanel.$el);

      this.uploader.setOptions({ dropZone: $el });
    },

    // просто функция которая в удобном виде возвращает данные по текущей теме и размерам внутренних блоков виджета
    getThemeData: function() {
      var data = {
        theme: this.model.get('current_theme'),
        thumbnails: this.model.get('theme_data').thumbnails,
        counters: this.model.get('theme_data').counters,
        images_h: this.model.get('images_h'),
        captions_h: this.model.get('captions_h'),
        left_arrow_noun: this.model.get('left_arrow_noun'),
        right_arrow_noun: this.model.get('right_arrow_noun'),
        current_mode: this.model.get('current_mode'),
        arrows_type: this.model.get('theme_data').arrows_type,
        captions: this.model.get('theme_data').captions,
        counters_type: this.model.get('theme_data').counters_type,
        active_arrows: this.model.get('active_arrows'),
      };

      data.showThumbnails = data.thumbnails;
      data.showCounters = data.counters;
      data.showCaptions = data.captions || data.theme === 'theme_captions';
      data.showBottomDots =
        data.counters &&
        ((data.active_arrows === 'middle' && data.counters_type === 'dots') || data.theme === 'theme_captions');

      return data;
    },

    // функция расcчитывает новую высоту блока в зависимости от параметров виджета (theme, thumbnails, counters...)
    // вызывается из панельки настроек виджета
    getNewBlockHeight: function() {
      var themeData = this.getThemeData(),
        block_h = 0;

      block_h =
        themeData.images_h +
        (themeData.thumbnails ? this.slideshowPlayer.THUMBNAILS_HEIGHT : 0) +
        (themeData.showCaptions ? themeData.captions_h : 0) +
        (themeData.showBottomDots ? this.slideshowPlayer.COUNTERS_HEIGHT : 0);

      return block_h;
    },

    // функция расcчитывает новую высоту внутренных блоков виджета в зависимости от текущей высоты всего виджета и его настроек
    getNewInnerElementsHeight: function(block_h, resizePoint) {
      var themeData = this.getThemeData(),
        images_h = themeData.images_h,
        captions_h = themeData.captions_h;

      // если изменения размера произошли из-за того что мы ресайзили виджет за нижнюю току ресайза и были включены captions
      // тогда вместо пересчета высоты блока картинок надо пересчитать высоту блока текста
      // 's' - south, нижняя (южная) точка
      if (resizePoint === 's' && themeData.captions) {
        captions_h =
          block_h - themeData.images_h - (themeData.showBottomDots ? this.slideshowPlayer.COUNTERS_HEIGHT : 0);
      } else {
        images_h =
          block_h -
          (themeData.thumbnails ? this.slideshowPlayer.THUMBNAILS_HEIGHT : 0) -
          (themeData.showCaptions ? themeData.captions_h : 0) -
          (themeData.showBottomDots ? this.slideshowPlayer.COUNTERS_HEIGHT : 0);
      }

      return { images_h: images_h, captions_h: captions_h };
    },

    // переопределяем метод из block.js
    // для того чтобы синхронно менять размеры виджета и еще пару особых размеров внутри виджета которые связаны между собой
    // сделано для правильного undo-redo
    getSaveBoxData: function(options) {
      options = options || {};
      var boxData = BlockClass.prototype.getSaveBoxData.apply(this, arguments);
      var innerElementsHeights = this.getNewInnerElementsHeight(boxData.h, options.resizePoint);
      boxData.images_h = innerElementsHeights.images_h;
      boxData.captions_h = innerElementsHeights.captions_h;
      return boxData;
    },

    saveBox: function(options) {
      BlockClass.prototype.saveBox.apply(this, arguments);

      this.setSizeConstraints();
    },

    textStyleChanged: function(params, dopParams) {
      var oldStyle = _.clone(this.model.get('text_style')),
        newStyle = _.extend(oldStyle, params);

      // параметры от контрола цвета приходят в двух видах (rgba() и #hex + opacity (0..1))
      if (dopParams) newStyle = _.extend(newStyle, dopParams);

      this.model.set({ text_style: newStyle });

      this.saveDebounced();
    },

    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      BlockClass.prototype.redraw.apply(this, arguments);

      var changed_attrs = this.model.changedAttributes();

      if (!_.isObject(changed_attrs)) return;

      var params = ['captions_h', 'images_h', 'w', 'h', 'theme_data', 'left_arrow_noun', 'right_arrow_noun'];
      if (_.intersection(_.keys(changed_attrs), params).length)
        // model.toJSON() использовать нельзя, т.к. она всегда выдает данные для дефолтного вьюпорта
        this.slideshowPlayer.applyVisualState(this.model.attributes);

      if (this.model.hasChanged('pictures')) this.slideshowPlayer.applyPictures(this.model.attributes);

      if (this.model.hasChanged('text_style')) {
        this.slideshowPlayer.applyTextStyles(this.model.attributes);

        this.fillTextStyles();
        this.trigger('selection_styles_changed', this.cur_selection_styles);
        this.trigger('selection_styles_all_changed', this.cur_selection_styles_all);

        this.initiatorControl = null;
      }

      // При изменении темы высота блока может поменяться. Нужно учесть это
      // при переключении в дргуой вьюпорт
      var nh = this.getNewBlockHeight();
      if (nh !== this.model.get('h')) {
        this.model.set('h', nh);
      }
    },

    css: function(params, resizePoint) {
      BlockClass.prototype.css.apply(this, arguments);

      if (params.height != undefined || params.width != undefined) {
        var innerElementsHeights = this.getNewInnerElementsHeight(params.height || this.model.get('h'), resizePoint);

        this.slideshowPlayer.applyVisualState(_.clone(this.model.attributes), {
          w: params.width,
          h: params.height,
          images_h: innerElementsHeights.images_h,
          captions_h: innerElementsHeights.captions_h,
        });
      }
    },

    onThemeParamsChange: function() {
      var themeData = this.getThemeData();
      // после изменения параметров надо расчитать новый размер виджета
      // например включение thumbnails увеличивает высоту виджета на высоту панельки с превьюшками (72px по дефолту)
      this.model.set('h', this.getNewBlockHeight());

      // устанавливаем новые ограничения на минимальные размеры виджета
      this.setSizeConstraints();

      // если тема "картинки с текстами" тогда нижний крухок ресайза показываем с плюсиком
      this.frame && this.frame.setResizeBottomPlus(themeData.showBottomDots || themeData.captions);
    },

    // устанавливаем ограничения на минимальные размеры виджета
    setSizeConstraints: function() {
      var themeData = this.getThemeData(),
        heightOfBtmElements =
          (themeData.showBottomDots ? this.slideshowPlayer.COUNTERS_HEIGHT : 0) +
          (themeData.thumbnails ? this.slideshowPlayer.THUMBNAILS_HEIGHT : 0) +
          (themeData.captions ? this.MIN_CAPTIONS_HEIGHT : 0),
        minheight_on_south_resize,
        minheight_on_other_resize;

      minheight_on_south_resize = themeData.images_h + heightOfBtmElements;
      minheight_on_other_resize = this.MIN_IMAGES_HEIGHT + heightOfBtmElements;

      _.extend(this.frame, {
        minwidth: this.MIN_IMAGES_WIDTH,
        maxwidth: 9999,
        minheight: 0,
        maxheight: 9999,
        minheight_on_south_resize: minheight_on_south_resize, // здесь задается минимальный размер виджета если мы ресайзим за нижнюю центральную, т.е. изменеем размер текста
        minheight_on_other_resize: minheight_on_other_resize, // здесь задается минимальный размер виджета если мы ресайзим за любую точку кроме нижней центрельной, т.е. изменеем размер блока с картинкой
      });
    },

    highlightDropZone: function() {
      this.$el.addClass('show-dropzone');

      clearTimeout(this.highlightTimeout);

      this.highlightTimeout = setTimeout(
        _.bind(function() {
          this.$el.removeClass('show-dropzone');
        }, this),
        500
      );
    },

    destroy: function() {
      this.workspace.off('main-dragover', this.highlightDropZone);

      this.off('changeStyleSpan', this.textStyleChanged);
      this.off('changeStyleParagraph', this.textStyleChanged);

      this.model.off();

      this.slideshowPlayer && this.slideshowPlayer.destroy();

      this.slideshowPlayer = null;

      BlockClass.prototype.destroy.apply(this, arguments);
    },
  },
  {
    defaults: {
      captions_h: 76,
      images_h: 360,
      // вид панели - defaul, noun
      current_mode: 'default',
      // стрелки, которыми переключаются слайды во вьювере - bottom, middle
      active_arrows: 'bottom',

      theme_data: {
        // вкл/выкл стрелок
        arrows: true,
        // тип стрелок - defaul, noun
        arrows_type: 'default',
        // отображение стрелок - normal, mouseover
        arrows_view_mode: 'normal',
        // тип счетчика - numbers, dots
        counters_type: 'numbers',
        captions: false,
        fullscreen: true,
        thumbnails: false,
        counters: true,
        fill: true,
        radius: 0,
        autoplay: false,
        controls_color: 'ffffff',
        controls_opacity: 1,
        background_color: '000000',
        background_opacity: 1,
        // в эти объекты потом запишутся noun_id, rm_id, noun_url,
        // и фолбэк в виде base64 на случай, если к моменту отображения во вьювере
        // еще не будут готовы урлы с растрами
        left_icon_noun: {},
        right_icon_noun: {},
      },

      text_style: {
        color: '000000',
        'font-family': 'Arial',
        'font-size': 8,
        'font-style': 'normal',
        'font-weight': '400',
        'letter-spacing': 0,
        'line-height': 10,
        opacity: 100,
        'text-align': 'center',
        'text-decoration': 'none',
        'text-transform': 'none',
        'vertical-align': 'baseline',
        'size-leading-linked': true,
        'size-leading-ratio': FONTSIZE_LINE_HEIGHT_RATIO,
      },
    },
  }
);

var slideshowFrame = BlockFrameClass.extend({
  applyExtraConstraints: function(box, resizePoint) {
    this.block.setSizeConstraints();

    // если мы ресайзим за среднюю нижнюю точку
    // тогда ограничение на минимальный размер работают по другому (только для темы с заголовками)
    // пареметры ограницений задаются в setSizeConstraints и зависят от того выбрана ли тема с заголовками или нет
    if (resizePoint == 's') {
      if (box.height < this.minheight_on_south_resize) {
        box.height = this.minheight_on_south_resize;
      }
    } else {
      if (box.height < this.minheight_on_other_resize) {
        box.height = this.minheight_on_other_resize;
      }
    }
    return box;
  },

  /**
   * Делает кружок нижнего ресайза с плюсиком
   */
  setResizeBottomPlus: function(active) {
    this.$el.toggleClass('resize-bottom-plus-slideshow', active);
  },
});

export default SlideShowBlock;
