module.exports = {["template-constructor-control-form_content"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="icon noarrow" data-alt="Fields" data-alt-pos="left" data-alt-offset="9"></div>\n\n	<div class="form-fields-panel panel js-panel">\n		<div class="form-fields-header">Fields</div>\n\n		<div class="form-fields-wrapper">\n			<div class="resizable-scroll-wrapper">\n				<div class="resizable-content-wrapper">\n					<div class="resizable-content js-fields-container"></div>\n				</div>\n				<div class="resizable-scroll"></div>\n			</div>\n			<div class="js-settings-popup-container"></div>\n		</div>\n\n		<div class="form-fields-footer js-add-block">\n			<div class="js-new-settings-container"></div>\n			<div class="form-fields-add js-add" title="Add field"></div>\n		</div>\n\n		<div class="js-dropdown-panel-container"></div>\n\n		<div class="resize-handle-top"></div>\n		<div class="resize-handle-bottom"></div>\n	</div>\n';
}
return __p;
},["template-constructor-control-form_content-field"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="form-fields-item js-field form-fields-item-'+
((__t=(tp))==null?'':__t)+
' '+
((__t=(isSortable ? 'js-sortable' : 'js-not-sortable'))==null?'':__t)+
'"\n	     data-id="'+
((__t=(id))==null?'':__t)+
'" data-type="'+
((__t=(tp))==null?'':__t)+
'">\n		';
 if (tp === 'dropdown') {  
__p+='\n			<div class="form-fields-caption-dropdown-helper">\n				<div class="form-fields-caption-dropdown-mask js-dropdown-trigger"></div>\n				<input type="text" name="" value="'+
((__t=(text))==null?'':__t)+
'" placeholder="'+
((__t=(placeholder))==null?'':__t)+
'" autocomplete="off" readonly="readonly"\n				       class="form-fields-caption form-fields-caption-'+
((__t=(tp))==null?'':__t)+
' js-field-caption js-field-caption-'+
((__t=(tp))==null?'':__t)+
'"/>\n			</div>\n		';
 } else { 
__p+='\n			<input type="text" name="" value="'+
((__t=(text))==null?'':__t)+
'" placeholder="'+
((__t=(placeholder))==null?'':__t)+
'" autocomplete="off"\n				       class="form-fields-caption form-fields-caption-'+
((__t=(tp))==null?'':__t)+
' '+
((__t=(mode))==null?'':__t)+
' js-field-caption js-field-caption-'+
((__t=(tp))==null?'':__t)+
'"/>\n		';
 }; 
__p+='\n		';
 if (showSettings) {  
__p+='\n			<div class="form-fields-type-token js-settings-trigger">'+
((__t=(typeLetter))==null?'':__t)+
'</div>\n		';
 }; 
__p+='\n		';
 if (showDelete) {  
__p+='\n			<div class="form-fields-delete js-delete-field"></div>\n		';
 }; 
__p+='\n		';
 if (showSubmitAfterArrow) {  
__p+='\n			<div class="form-fields-placeholder-selector '+
((__t=(showRemoveArrow ? 'action-icon remove-icon' : 'js-submit-dropdown-trigger'))==null?'':__t)+
'">\n				<div class="form-fields-placeholder-arrow"></div>\n			</div>\n		';
 }; 
__p+='\n	</div>\n';
}
return __p;
},["template-constructor-control-form_content-settings"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="settings-popup transition-enable js-settings-popup '+
((__t=(edit ? 'settings-popup-edit js-edit-settings' : 'settings-popup-add js-add-settings'))==null?'':__t)+
'" data-id="'+
((__t=(id))==null?'':__t)+
'">\n		<div class="settings-popup-types">\n			';
 _.each(types, function(label, key) {  
__p+='\n				<div class="settings-popup-type js-field-type '+
((__t=(key === selectedType ? 'selected' : ''))==null?'':__t)+
'" data-value="'+
((__t=(key))==null?'':__t)+
'" data-action="'+
((__t=(edit ? 'edit' : 'add'))==null?'':__t)+
'">\n					'+
((__t=(label))==null?'':__t)+
'\n				</div>\n			';
 }); 
__p+='\n		</div>\n		';
 if (showOptionalSwitcher) {  
__p+='\n			<div class="settings-popup-optional">\n				<div class="switcher-wrapper settings-popup-optional-switcher">\n					<div class="switcher-caption settings-popup-optional-caption">Optional Field</div>\n					<div class="switcher settings-popup-optional-value" data-type="optional"></div>\n				</div>\n			</div>\n		';
 }; 
__p+='\n		<div class="corner-wrapper">\n			<div class="corner"></div>\n		</div>\n	</div>\n';
}
return __p;
},["template-constructor-control-form_content-after-submit"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="submit-wrapper settings-popup js-settings-popup">\n		<div class="settings-popup-types">\n			';
 _.each(types, function(label, key) {  
__p+='\n				<div class="settings-popup-type js-submit-field-type '+
((__t=(key === selectedType ? 'selected' : ''))==null?'':__t)+
'" data-value="'+
((__t=(key))==null?'':__t)+
'" >\n					'+
((__t=(label))==null?'':__t)+
'\n				</div>\n			';
 }); 
__p+='\n		</div>\n\n		<div class="resizable-scroll-wrapper">\n			<div class="resizable-content-wrapper">\n				<div class="resizable-content">\n					<div class="styles-wrapper"></div>\n				</div>\n			</div>\n			<div class="resizable-scroll"></div>\n		</div>\n	</div>\n';
}
return __p;
},["template-constructor-control-form_content-dropdown"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="dropdown-panel js-dropdown-panel">\n		<div class="dropdown-content">\n			<div class="dropdown-header form-fields-header js-dropdown-caption-container"></div>\n\n			<div class="dropdown-wrapper">\n				<div class="resizable-scroll-wrapper">\n					<div class="resizable-content-wrapper">\n						<div class="resizable-content js-dropdown-content">\n							<div class="dropdown-options js-dropdown-options-container"></div>\n							<div class="dropdown-add dropdown-option form-fields-item js-add-option">+ Add</div>\n						</div>\n					</div>\n					<div class="resizable-scroll"></div>\n				</div>\n			</div>\n\n			<div class="dropdown-footer form-fields-footer">\n				<div class="dropdown-save js-save-dropdown">Save</div>\n				<div class="dropdown-cancel js-cancel-dropdown"></div>\n			</div>\n		</div>\n	</div>\n';
}
return __p;
},["template-constructor-control-form_content-dropdown-caption"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<input type="text" name="" value="'+
((__t=(text))==null?'':__t)+
'" placeholder="'+
((__t=(placeholder))==null?'':__t)+
'" autocomplete="off"\n						       class="dropdown-caption-value form-fields-caption js-dropdown-caption"/>\n';
}
return __p;
},["template-constructor-control-form_content-dropdown-option"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="form-fields-item dropdown-option js-dropdown-option js-sortable" data-value="'+
((__t=(text))==null?'':__t)+
'" data-number="'+
((__t=(number))==null?'':__t)+
'">\n		<input type="text" name="" value="'+
((__t=(text))==null?'':__t)+
'" placeholder="'+
((__t=(placeholder))==null?'':__t)+
'" autocomplete="off" data-number="'+
((__t=(number))==null?'':__t)+
'"\n		       class="dropdown-option-value form-fields-caption js-dropdown-option-value"/>\n		<div class="dropdown-number form-fields-type-token">'+
((__t=(number))==null?'':__t)+
'</div>\n		';
 if (showDelete) {  
__p+='\n			<div class="dropdown-option-delete form-fields-delete js-delete-option"></div>\n		';
 }; 
__p+='\n	</div>\n';
}
return __p;
},}