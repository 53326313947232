import { Utils, Constants } from '../common/utils';
import _ from '@rm/underscore';

// описание всех кейсов
// https://docs.google.com/spreadsheets/d/1Yn6NrrUX4FH1Ck6fobmprGdbUxjOuNsBYwwKuoMOw-M/edit#gid=0
var ShareUtils = {
  defaultDescription: 'Built with Readymag—a tool to design anything on the web.',
  // 140 это макс длина всего твита, 23 это фиксированная длина любого линка, 1 это пробел между твитом и линком
  maxTweetLen: 140 - 23 - 1,

  addOrigin: function(uri, origin) {
    if (!uri) return '';
    if (origin && uri.indexOf('://') == -1) return origin + uri;

    return uri;
  },

  addFilenameComponent: function(screenshot, size) {
    return Utils.addFilenameComponent.apply(this, arguments);
  },

  getPageScreenshot: function(readymag, page, options) {
    options = options || {};

    var host = window.location.protocol + '//' + window.location.host,
      screenshot = page && page.screenshot;

    return screenshot ? this.addOrigin(this.addFilenameComponent(screenshot, 1024), host) : '';
  },

  _formatTweet: function(p) {
    var res = p.prefix + p.val + p.suffix;

    p.minLength = p.minLength == undefined ? 1 : p.minLength;

    if (res.length > ShareUtils.maxTweetLen) {
      var len = Math.max(p.val.length - (res.length - ShareUtils.maxTweetLen + 1), p.minLength);
      res = p.prefix + p.val.substring(0, len) + '…' + p.suffix;
    }

    return res;
  },

  //options = {forProject: true|false, customDomain: true|false}
  //page и readymag это объекты (если у нас можели делаем toJSON)
  //page может быть undefined если указан ключ forProject
  //
  //свойства page:
  //	-title
  //	-num
  //	-screenshot
  //
  //свойства readymag (объект mag по сути):
  //	-title
  //	-emptyTitle (при вызове из вьювера флаг что на самом деле тайтл пустой, описание ниже)
  //	-description
  //	-user (объект, оттуда нам надо только свойство name)
  //	-pages (массив объектов страниц, сортировка не обязательна, у страниц должны быть те же свойства что описаны выше для page)
  //
  //P.S. не все свойства обязательны каждый раз, например pages у readymag не нужен если не указан ключ forProject
  //но все же лучше если все они будут
  getDefaultShare: function(page, readymag, options) {
    options = options || {};

    //для 404 где нет объекта readymag
    if (_.isEmpty(readymag) || !_.isObject(readymag)) return {};

    //во вьювере у нас readymag это объект с моделью внутри
    //и там мы в свое время для упрощения всегда заменяем пустой тайтл на Project при парсинге, но при этом проставляем в можель флаг emptyTitle
    //чтобы ничего нигде не переделывать нам проще тут  написать костыль, который проверит что внутри readymag есть model у него есть get и стоит флаг emptyTitle
    var magTitle = readymag.emptyTitle ? '' : readymag.title,
      title = '',
      description = readymag.description || (options.customDomain ? '' : ShareUtils.defaultDescription),
      image = '';

    if (!options.customDomain) {
      if (!options.forProject) {
        title = (page.title ? '‘' + page.title + '’' : 'Page ' + page.num) + ' from ';
      }

      title += (magTitle ? '‘' + magTitle + '’' : 'Project') + ' by ' + readymag.user.name;

      //если есть дескрипшн, тогда добавим Readymag к заголовку, в других случаях про РМ уже написано в дескрипшене, в заголовке не дублируем
      //только для шеринга проекта
      if (options.forProject && readymag.description) {
        title += ' | Readymag';
      }
    } else {
      if (!options.forProject) {
        title = (magTitle ? magTitle + ' — ' : '') + (page.title || 'Page ' + page.num);
      } else {
        title = magTitle || 'Project';
      }
    }

    if (options.forProject) {
      image = ShareUtils.getPageScreenshot(readymag, _.findWhere(readymag.pages, { num: 1 }), options);
    } else {
      image = ShareUtils.getPageScreenshot(readymag, page, options);
    }

    //блок формирования текста твита
    var tweet = '';

    if (!options.customDomain) {
      if (!options.forProject) {
        if (!magTitle && !page.title) {
          tweet = 'Page ' + page.num + ' from Project by ' + readymag.user.name;
        } else if (!magTitle && page.title) {
          tweet = ShareUtils._formatTweet({
            prefix: '‘',
            val: page.title,
            suffix: '’ from Project by ' + readymag.user.name,
          });
        } else if (magTitle && !page.title) {
          tweet = ShareUtils._formatTweet({
            prefix: 'Page ' + page.num + ' from ‘',
            val: magTitle,
            suffix: '’ by ' + readymag.user.name,
          });
        } else if (magTitle && page.title) {
          //просто для красоты условие, понятно что оно тут всегда трушное
          tweet = ShareUtils._formatTweet({
            prefix: '‘' + page.title + '’ from ‘',
            val: magTitle,
            suffix: '’ by ' + readymag.user.name,
            minLength: 20,
          });
          if (tweet.length > ShareUtils.maxTweetLen) {
            tweet = '‘' + page.title + '’ by ' + readymag.user.name;
            if (tweet.length > ShareUtils.maxTweetLen) {
              tweet = ShareUtils._formatTweet({ prefix: '‘', val: page.title, suffix: '’ by ' + readymag.user.name });
            }
          }
        }
      } else {
        if (!magTitle) {
          tweet = 'Project by ' + readymag.user.name;
        } else {
          tweet = ShareUtils._formatTweet({ prefix: '‘', val: magTitle, suffix: '’ by ' + readymag.user.name });
        }
      }
    } else {
      if (!options.forProject) {
        if (!magTitle && !page.title) {
          tweet = 'Page ' + page.num;
        } else if (!magTitle && page.title) {
          tweet = ShareUtils._formatTweet({ prefix: '', val: page.title, suffix: '' });
        } else if (magTitle && !page.title) {
          tweet = ShareUtils._formatTweet({ prefix: '', val: magTitle, suffix: ' — Page ' + page.num });
        } else if (magTitle && page.title) {
          //просто для красоты условие, понятно что оно тут всегда трушное
          tweet = ShareUtils._formatTweet({ prefix: '', val: magTitle, suffix: ' — ' + page.title, minLength: 20 });
          if (tweet.length > ShareUtils.maxTweetLen) {
            tweet = page.title;
            if (tweet.length > ShareUtils.maxTweetLen) {
              tweet = ShareUtils._formatTweet({ prefix: '', val: page.title, suffix: '' });
            }
          }
        }
      } else {
        if (!magTitle) {
          tweet = 'Project';
        } else {
          tweet = ShareUtils._formatTweet({ prefix: '', val: magTitle, suffix: '' });
        }
      }
    }

    return {
      image: image,
      title: title || '',
      description: description || '',
      tweet: tweet,
    };
  },

  //options = {forProject: true|false, customDomain: true|false, host: "readymag.com" | undefined}
  //page и readymag это объекты (если у нас можели делаем toJSON)
  //page может быть undefined если указан ключ forProject
  //
  //свойства page:
  //	-title
  //	-num
  //	-screenshot
  //	-custom_shares (если они заданы)
  //
  //свойства readymag (объект mag по сути):
  //	-title
  //	-emptyTitle (при вызове из вьювера флаг что на самом деле тайтл пустой, описание ниже)
  //	-description
  //	-user (объект, оттуда нам надо только свойство name)
  //	-pages (массив объектов страниц, сортировка не обязательна, у страниц должны быть те же свойства что описаны выше для page + _id)
  //	-master_share_pid (если есть мастер страница)
  //
  //P.S. не все свойства обязательны каждый раз, например pages у readymag не нужен если не указан ключ forProject и нет мастер страницы
  //но все же лучше если все они будут
  getFinalShare: function(page, readymag, options) {
    options = options || {};

    //для 404 где нет объекта readymag
    if (_.isEmpty(readymag) || !_.isObject(readymag)) return {};

    var res = ShareUtils.getDefaultShare(page, readymag, options);

    //на бэке идишники не строки и просто так их не сравнить
    //на фронте же этот код отработает нормально, потому что toString у обычных строк тоже естественно есть
    var masterPage =
        readymag.master_share_pid &&
        _.find(readymag.pages, function(p) {
          return p._id.toString() == readymag.master_share_pid.toString();
        }),
      //если передан флаг forProject значит всегда работаем с первой страницей
      //но если есть мастер страница для шеров, берем ее в любом случае
      page = options.forProject ? _.findWhere(readymag.pages, { num: 1 }) : page,
      sourcePage = masterPage || page,
      customShares = sourcePage.custom_shares;

    if (!_.isEmpty(customShares)) {
      res.title = customShares.title || '';
      res.description = customShares.description || '';
      res.tweet = customShares.tweet || '';

      if (customShares.image) {
        res.image = customShares.image;
      } else if (sourcePage.screenshot) {
        res.image = ShareUtils.getPageScreenshot(readymag, sourcePage, options);
      } else {
        res.image = '';
      }
    }

    return res;
  },

  //options = {customDomain: true|false}
  //page и readymag это объекты (если у нас можели делаем toJSON)
  //page может быть undefined если указан ключ forProject
  //
  //свойства page:
  //	-title
  //	-num
  //
  //свойства readymag (объект mag по сути):
  //	-title
  //	-emptyTitle (при вызове из вьювера флаг что на самом деле тайтл пустой, описание ниже)
  //	-description
  //	-user (объект, оттуда нам надо только свойство name)
  //
  //P.S. не все свойства обязательны каждый раз, но все же лучше если все они будут
  getDefaultSEO: function(page, readymag, options) {
    options = options || {};

    //для 404 где нет объекта readymag
    if (_.isEmpty(readymag) || !_.isObject(readymag)) return {};

    //во вьювере у нас readymag это объект с моделью внутри
    //и там мы в свое время для упрощения всегда заменяем пустой тайтл на Project при парсинге, но при этом проставляем в можель флаг emptyTitle
    //чтобы ничего нигде не переделывать нам проще тут  написать костыль, который проверит что внутри readymag есть model у него есть get и стоит флаг emptyTitle
    var magTitle = readymag.emptyTitle ? '' : readymag.title,
      title = '',
      description = '';

    if (!options.customDomain) {
      if (page && page.num > 1) {
        title = (page.title ? '‘' + page.title + '’' : 'Page ' + page.num) + ' from ';
      } else {
        description = readymag.description || ShareUtils.defaultDescription;
      }

      title = magTitle ? magTitle : 'Project';
    } else {
      if (page && page.num > 1) {
        title = (magTitle ? magTitle + ' — ' : '') + (page.title || 'Page ' + page.num);
      } else {
        title = magTitle || 'Project';
        description = readymag.description || '';
      }
    }

    return {
      title: title || '',
      description: description || '',
      keywords: '', //по дефолту пустые
    };
  },

  //options = {customDomain: true|false}
  //page и readymag это объекты (если у нас можели делаем toJSON)
  //page может быть undefined если указан ключ forProject
  //
  //свойства page:
  //	-title
  //	-num
  //	-seo (если они заданы)
  //
  //свойства readymag (объект mag по сути):
  //	-title
  //	-emptyTitle (при вызове из вьювера флаг что на самом деле тайтл пустой, описание ниже)
  //	-description
  //	-user (объект, оттуда нам надо только свойство name)
  //
  //P.S. не все свойства обязательны каждый раз, но все же лучше если все они будут
  getFinalSEO: function(page, readymag, options) {
    options = options || {};

    //для 404 где нет объекта readymag
    if (_.isEmpty(readymag) || !_.isObject(readymag)) return {};

    var res = ShareUtils.getDefaultSEO(page, readymag, options);

    res.title = (page.seo && page.seo.title) || res.title;
    res.description = (page.seo && page.seo.description) || res.description;
    res.keywords = (page.seo && page.seo.keywords) || res.keywords;

    return res;
  },

  _openShareWindow: function(url, w, h) {
    w = w || 626;
    h = h || 436;
    window.open(url, 'sharer', 'width=' + w + ',height=' + h + ',toolbar=0,status=0');
  },

  //вызывается с фронта из разных мест (иэ линков, из панельки шера, с файнал пейджа, из эмбедов)
  //значения params:
  //	host: под каким хостом должен быть урл страницы/проекта (можно указать http(s):// в начале, можно без, сам исправит, также и с финальным слешем)
  //	tp: для какой платформы шарим (facebook, twitter, pinterest, linkedin, gplus, email, report)
  //	source: для аналитики, просто строка откуда шарим (конструктор, файнла пейдж, панелька шера и пр.)
  //	page: объект страницы, формат как для getFinalShare (не обязателен, если шарим проект целиком)
  //	mag: объект мэга, формат как для getFinalShare
  //	forProject:  шарим проект челиком или конкретную страницу page
  //	customDomain: на кастомном домене или нет (не важно профиль или сам проект)
  //	customDomainProfile: если на кастомном домене и это домен для профиля (важно для формирования шер урла),
  //	analytics: объект аналитики, не обязателен
  share: function(params) {
    var shareData = ShareUtils.getFinalShare(params.page, params.mag, {
        forProject: params.forProject,
        customDomain: params.customDomain,
      }),
      shareUrl,
      magTitle = params.mag.emptyTitle ? '' : params.mag.title;

    //для случая с конструктором, когда передаются кастомные домены в качестве хостов
    if (!/^https?\:\/\//i.test(params.host)) {
      params.host = 'http://' + params.host;
    }

    //обязательно последний слеш для хоста
    if (!/\/$/i.test(params.host)) {
      params.host += '/';
    }

    if (params.forProject) {
      shareUrl = params.host + (params.customDomain && !params.customDomainProfile ? '' : params.mag.num_id + '/');
    } else {
      shareUrl = params.host + 'p' + params.page.num_id + '/';
    }

    //специальный шаринг из конструктора сразу после публикации
    if (params.publishedVersion) {
      if (params.tp == 'facebook') {
        ShareUtils._openShareWindow('http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl));
      }

      if (params.tp == 'twitter') {
        var text;

        if (!magTitle) {
          text = 'I’ve just published a Project';
        } else {
          text = ShareUtils._formatTweet({ prefix: 'I’ve just published a project ‘', val: magTitle, suffix: '’' });
        }

        ShareUtils._openShareWindow(
          'https://twitter.com/share?url=' + encodeURIComponent(shareUrl) + '&text=' + encodeURIComponent(text)
        );
      }

      if (params.tp == 'email') {
        var magName = encodeURIComponent(
            (params.mag.is_private ? 'private ' : '') + (magTitle ? 'project ‘' + magTitle + '’' : 'Project')
          ),
          url = 'mailto:',
          subject = 'Check out my ' + magName,
          body =
            'Hey, check out my new ' +
            magName +
            ': ' +
            shareUrl +
            (params.mag.is_private ? encodeURIComponent('\n') + 'Password: ' + params.mag.pass : '');

        window.location.href = url + '?subject=' + subject + '&body=' + body;
      }
    } else {
      //обычный шаринг

      if (params.tp == 'facebook') {
        ShareUtils._openShareWindow('http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl));
      }

      if (params.tp == 'twitter') {
        ShareUtils._openShareWindow(
          'https://twitter.com/share?url=' +
            encodeURIComponent(shareUrl) +
            '&text=' +
            encodeURIComponent(shareData.tweet)
        );
      }

      if (params.tp == 'gplus') {
        ShareUtils._openShareWindow('https://plus.google.com/share?url=' + encodeURIComponent(shareUrl));
      }

      if (params.tp == 'pinterest') {
        ShareUtils._openShareWindow(
          'http://pinterest.com/pin/create/button/?' +
            'url=' +
            encodeURIComponent(shareUrl) +
            '&media=' +
            encodeURIComponent(shareData.image) +
            '&description=' +
            encodeURIComponent(shareData.title)
        );
      }

      if (params.tp == 'linkedin') {
        ShareUtils._openShareWindow(
          'http://www.linkedin.com/shareArticle?' +
            'mini=true' +
            '&url=' +
            encodeURIComponent(shareUrl) +
            '&title=' +
            encodeURIComponent(shareData.title) +
            (shareData.description ? '&summary=' + encodeURIComponent(shareData.description) : ''),
          552,
          460
        );
      }

      if (params.tp == 'email') {
        var url = 'mailto:',
          subject = 'Check out ' + encodeURIComponent(shareData.title),
          body =
            subject +
            encodeURIComponent('\n\n') +
            shareUrl +
            (params.mag.is_private ? encodeURIComponent('\n') + 'Password: ' + params.mag.pass : '');

        window.location.href = url + '?subject=' + subject + '&body=' + body;
      }

      if (params.tp == 'report') {
        var url = 'mailto:' + Constants.SUPPORT_MAIL,
          subject = 'Report ' + encodeURIComponent(shareUrl),
          body = encodeURIComponent('Please tell us why are you reporting this: ');

        window.location.href = url + '?subject=' + subject + '&body=' + body;
      }
    }

    if (params.tp != 'report') {
      var opts =
        params.source +
        ' / ' +
        params.tp +
        ' (' +
        (params.forProject ? 'project' : 'page') +
        ') / ' +
        shareData.title +
        ' (' +
        shareUrl +
        ')';

      params.analytics && params.analytics.sendEvent('Share', opts);
    }
  },
};

export default ShareUtils;
