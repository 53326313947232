/**
 * Вьюха с аплоадером для картинок
 */
import $ from '@rm/jquery';
import Vue from 'vue';
import _ from '@rm/underscore';
import Backbone from 'backbone';
import { Constants } from '../../common/utils';
import BlockClass from '../block';

var MimeTypeRegExp = new RegExp('^image/(' + Constants.UPLOAD_IMAGE_FORMATS + ')$', 'i');
var UrlImageTypeRegExp = new RegExp('([a-z-_0-9/:.]*.(' + Constants.UPLOAD_IMAGE_FORMATS + ')(\\W.*)?$)', 'i');

('use strict');

var Uploader = Backbone.View.extend({
  // debug: true,

  DEFAULT_ERROR_MSG: 'Something went wrong!',

  // Для использования аплоадера в панели и в блоке с одинаковыми параметрами
  constructor: function(parent) {
    if (parent instanceof BlockClass) {
      this.block = parent;
    } else if (parent instanceof Vue) {
      this.block = parent.raw_block || parent.block;
      this.panel = parent;
    }

    this.initialize.apply(this, arguments);

    return this;
  },

  initialize: function(parent, options) {
    _.bindAll(this);

    var block = this.block;
    options = options || {};

    if (options.fileTypes) {
      this.fileTypes = new RegExp('^image/(' + options.fileTypes + ')$', 'i');
      this.fileTypesErrorMessage = options.fileTypesErrorMessage;
      delete options.fileTypes;
      delete options.fileTypesErrorMessage;
    }

    options = _.defaults(
      options,
      _.extend(
        {
          dataType: 'json',
          singleFileUploads: true,
          sequentialUploads: true,
          formData: {
            id: block.model.id,
            mid: block.workspace.mag.get('num_id'),
            name: block.name,
            uploadType: 'picture',
            unique: options.unique, // Наша опция для генерация уникального имени даже для одинаковых картинок
          },
          dropZone: $(), // Дроп зона может менятся в процессе работы (открыли / закрыли панель)
          pasteZone: null,
          replaceFileInput: false,
        },
        this.panel
          ? {
              dropZone: $(this.panel.$el),
              fileInput: $(
                options.uploadInputRefName ? this.panel.$refs[options.uploadInputRefName] : this.panel.$refs.uploadInput
              ),
            }
          : {}
      )
    );

    this.options = options;

    this.debug && console.log(options);

    this.$uploader = $(options.fileInput).fileupload(options);

    this.bindHandlers();

    this.uploadingData = [];
  },

  bindHandlers: function() {
    var self = this;
    this.preventDefaultOnDrop();

    // Добавление файлов к аплоаду
    this.$uploader
      .bind('fileuploadadd', function(e, data) {
        if (self.debug) console.log('add', data);
        self.uploadData = data;

        if (!data || !data.files || !data.files[0] || data.files[0].skipped) return false; // Обработка удаленного файла, стоявшего в очереди на аплоад

        var fileSize = data.files && data.files[0].size,
          fileType = data.files && data.files[0].type;
        // Проверяем на допустимый размер и формат загружаемого файла
        if (fileSize > Constants.UPLOAD_IMAGE_SIZE_LIMIT) {
          data.allowUpload = false;
          alert((data.files[0].uploadError = Constants.MSG_UPLOAD_IMAGE_SIZE_ERROR));
        } else if (!(self.fileTypes || MimeTypeRegExp).test(fileType)) {
          data.allowUpload = false;
          alert((data.files[0].uploadError = self.fileTypesErrorMessage || Constants.MSG_UPLOAD_IMAGE_SUPPORTED_ERROR));
        } else {
          data.allowUpload = true;
        }

        self.trigger('add', e, data);

        self.uploadingData.push(data);
        data.submit();
      })

      // Дроп картинки из другой вкладки браузера
      .bind('fileuploaddrop', function(e, data) {
        if (self.debug) console.log('drop', data);

        self.trigger('drop', e);

        var clipboard = e.originalEvent.dataTransfer || e.delegatedEvent.dataTransfer;
        var html = clipboard.getData('text/html');
        if (!html) return;

        var url = $(html)
          .filter('img')
          .attr('src');
        if (!url) return;

        if (UrlImageTypeRegExp.test(url)) {
          self.trigger('uploadFromUrl', url);
        } else {
          alert((data.uploadError = Constants.MSG_UPLOAD_IMAGE_SUPPORTED_ERROR));
          data.allowUpload = false;
          self.clearFileInputField();
          return false;
        }
      })

      // Проверка, разрешать ли аплоад с заданными параметрами (data)
      .bind('fileuploadsubmit', function(e, data) {
        if (self.debug) console.log('submit', data);
        if (data.allowUpload) {
          data.allowUpload = false;
          return;
        }

        // Проверяем была ли ошибка
        var err = data.uploadError || (data.files && data.files[0] && data.files[0].uploadError);
        if (err) {
          delete data.uploadError;
          if (data.files) delete data.files[0].uploadError;

          self.trigger('fail', e, data, err);
          self.clearFileInputField();
        }

        return false;
      })

      // Событие возникает непосредственно перед отправкой файла на сервер
      .bind('fileuploadsend', function(e, data) {
        if (self.debug) console.log('send', data);
        if (data.files[0].skipped) {
          // Обработка удаленного файла, стоявшего в очереди на аплоад
          return false;
        }

        self.trigger('send', e, data);
      })

      // Проксируем события через бэкбон
      .bind('fileuploadstart', function(e, data) {
        if (self.debug) console.log('start', data);
        self.uploading = true;
        self.trigger('start', e, data);
      })

      .bind('fileuploaddone', function(e, data) {
        if (self.debug) console.log('done', data);

        self.uploadingData.shift();
        self.uploading = false;
        self.clearFileInputField();

        if (data.files[0].skipped) return;

        self.trigger('done', e, data);
      })

      .bind('fileuploaddragover', function(e) {
        self.trigger('dragover', e);
      })

      .bind('fileuploaddragleave', function(e) {
        self.trigger('dragleave', e);
      })

      // Проверяем на тип ошибки при fail
      .bind('fileuploadfail', function(e, data) {
        if (self.debug) console.log('fail', data);

        self.uploadingData.shift();
        self.uploading = false;
        self.clearFileInputField();

        var status;
        var err = self.DEFAULT_ERROR_MSG;

        // Если статус 500, значит ошибка конвертации
        status = data && data.jqXHR && data.jqXHR.status;
        if (status == 500) {
          _.delay(function() {
            alert((err = Constants.MSG_UPLOAD_IMAGE_SUPPORTED_ERROR));
          }, 300); // Чтобы успела отработать анимация скрытия прелоадера
        }

        if (!data.files || data.files[0].skipped) return;
        if (data.errorThrown == 'abort') return;

        self.trigger('fail', e, data, err);
      });
  },

  setOptions: function(options) {
    this.options = _.extend(this.options, options);
    this.$uploader.fileupload(this.options);
  },

  // Записываем данные, необходимые файловому хранилищу на сервере - id виджета, имя виджета, тип аплоада (picture, video, ...)
  setFormData: function(id, name, uploadType) {
    if (!id || !name || !uploadType) return console.error('missing required parameters');

    this.$uploader.fileupload({
      formData: {
        id: id,
        name: name,
        uploadType: uploadType,
      },
    });
  },

  // Странный хак для очистки имени файла после аплоада
  clearFileInputField: function() {
    this.$uploader
      .wrap('<form>')
      .closest('form')
      .get(0)
      .reset();
    this.$uploader.unwrap();
  },

  switchOff: function() {
    this.options.dropZone.off('drop dragover', this.prevent);
    this.$uploader.fileupload('disable');
  },

  switchOn: function() {
    this.preventDefaultOnDrop();
    this.$uploader.fileupload('enable');
  },

  preventDefaultOnDrop: function() {
    this.options.dropZone.on('drop dragover', this.prevent);
  },

  prevent: function(e) {
    e.preventDefault();
  },

  abort: function() {
    this.uploadingData[0] && this.uploadingData[0].abort();
    _.each(this.uploadingData, function(data) {
      data.files[0].skipped = true;
    });
  },

  destroy: function() {
    this.abort();
    this.switchOff();

    this.off();
    this.$uploader.fileupload('destroy');
  },
});

Uploader.MimeTypeRegExp = MimeTypeRegExp;
Uploader.UrlImageTypeRegExp = UrlImageTypeRegExp;

export default Uploader;
