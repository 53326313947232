/**
 * Конструктор для виджета текста
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/common_layer.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const CommonLayerClass = ControlClass.extend({
  name: 'common_layer', // должно совпадать с классом вьюхи

  className: 'control common_layer',

  BASE_ZINDEX: 100,

  initialize: function(params) {
    this.template = templates['template-constructor-control-common_layer'];

    this.initControl(params);

    if (this.blocks && Array.isArray(this.blocks)) {
      this.block = this.blocks[0];
      this.model = this.block.model;
    }

    if (!params.noInit) {
      this.selectedModels = _.pluck(this.blocks, 'model');

      // FIXME: commented since caused slowness on stack_panel reorder
      this.fixBadZIndexes();
    }
  },

  // нормализуем зиндексы
  // просто сортируем модели по порядку с учетом их текущего зиндекса (он может быть и дробным, это для простоты перемещения виджета между другими, в _move рассказано подробнее)
  // и проставляем им всех зиндексы начиная с this.BASE_ZINDEX и всем дальше инкрементально +1
  normalizeZIndexes: function() {
    var models = this.allModels,
      start = this.BASE_ZINDEX,
      has_changed = false;

    _(models)
      .chain()
      .sortBy(function(m) {
        return m.get('z'); // отсортируем
      })
      .each(function(m) {
        // в отсортированном по порядку списке проставляем нормальные зиндексы начиная с BASE_ZINDEX и дальше +1
        var old_z = m.get('z'),
          new_z = start++;

        m.set('z', new_z, { first: true });

        if (old_z !== new_z) {
          has_changed = true;
        }
      })
      .value();

    return has_changed;
  },

  // Возьмем список моделей всех виджетов страницы и исправим неверные значения
  fixBadZIndexes: _.debounce(function() {
    this.allModels = _(this.master.workspace.blocks)
      .chain()
      .filter(function(b) {
        // Без фона и прочих
        return !b.outofbox;
      })
      .pluck('model')
      .map(function(m) {
        if (!_.isNumber(m.get('z'))) {
          m.set('z', this.BASE_ZINDEX); // Если кривой z, то поправим
        }
        return m;
      }, this)
      .value();

    // После создания-дублирования виджета он имеет максимальный z-index, метод moveToFixLayers переставит фиксед виджеты поверх него и вернет true если передвинул хоть 1 виджет
    var moved = this.moveToFixLayers(1, { fixOnlyBad: true });
    var aboveMoved = this.moveToAboveAllLayers();
    // Если при нормализации порядок поменялся, то надо сохранять новый z-index для ВСЕХ виджетов
    var has_changed = this.normalizeZIndexes();
    if (moved || has_changed || aboveMoved) this.save({ silent: true, skipHistory: true });
  }, 10),

  // Разбиваем fixed виджеты на слои "над всеми обычными" и "под всеми обычными"
  moveToFixLayers: function(dir, options) {
    var moved = false;

    // исключаем в том числе и hidden для того, чтобы если остался только один видимый виджет,
    // он мог бы переместиться над/под фикседами (как если бы он был действительно один нефиксед на странице)
    var staticWidgets = _.filter(this.allModels, function(m) {
      return !m.get('fixed_position') && !m.get('hidden') && !m.get('is_above');
    });

    if (_.isEmpty(staticWidgets)) return;

    var minZ = _.min(staticWidgets, function(m) {
      return m.get('z');
    }).get('z');
    var maxZ = _.max(staticWidgets, function(m) {
      return m.get('z');
    }).get('z');
    var fixBadStep = 0;

    var fixeds = _.filter(this.allModels, function(m) {
      return m.get('fixed_position');
    });

    _.chain(this.allModels)
      .filter(function(m) {
        return m.get('fixed_position') && (dir > 0 ? m.get('z') > minZ : m.get('z') < maxZ);
      })
      .sortBy(function(m) {
        return m.get('z');
      })
      .each(function(m) {
        // Берем только фиксед виджеты, которые находятся между статичными, если передали options.fixOnlyBad
        if (options && options.fixOnlyBad && (m.get('z') < minZ || m.get('z') > maxZ)) return;

        var baseZ = dir > 0 ? 10000 : -10000;
        if (options && options.fixOnlyBad) {
          fixBadStep++;
          // Будем последовательно увеличивать z-index на 0.0001 у всех фикседов чтобы сохранить их порядок относительно друг друга
          // Числа превратятся в валидные z-index далее в normalizeZIndexes
          var z = (dir > 0 ? maxZ : minZ) + fixBadStep * 0.0001 * (dir > 0 ? 1 : -1);
          m.set('z', z, { first: true });
        } else {
          m.set('z', baseZ + m.get('z'), { first: true });
        }

        moved = true;
      });

    return moved;
  },

  moveToAboveAllLayers: function() {
    var moved = [];

    var nonAboveWidgets = _.filter(this.allModels, function(m) {
      return !m.get('is_above') && !m.get('hidden');
    });
    var aboveWidgets = _.filter(this.allModels, function(m) {
      return m.get('is_above') && !m.get('hidden');
    });

    if (_.isEmpty(nonAboveWidgets)) return;

    var maxZ = _.max(nonAboveWidgets, function(m) {
      return m.get('z');
    }).get('z');

    _.chain(this.allModels)
      .filter(function(m) {
        return m.get('is_above') && m.get('z') < maxZ;
      })
      .each(function(m) {
        m.set('z', 20000 + m.get('z'), { first: true });
        moved.push(m);
      });

    if (!_.isEmpty(moved)) {
      _.chain(this.allModels)
        .filter(function(m) {
          return m.get('is_above') && moved.indexOf(m) == -1;
        })
        .each(function(m) {
          m.set('z', 20000 + m.get('z'), { first: true });
        });
    }

    return !_.isEmpty(moved);
  },

  restrictions: function(workspace) {
    return workspace.getSelectableBlocks().length > 1;
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-common_layer');
  },

  bindLogic: function() {
    this.$panel.on('click', '.forward', this.forward);
    this.$panel.on('click', '.backward', this.backward);
    this.$panel.on('click', '.forward-all', this.forwardAll);
    this.$panel.on('click', '.backward-all', this.backwardAll);

    // Cmd + [ — на слой ниже
    // Cmd + ] — на слой выше
    // Alt + Cmd + [ — под всеми
    // Alt + Cmd + ] — над всеми

    RM.constructorRouter.bindGlobalKeyPress([
      { key: '[', handler: this.backward, optionKeys: !Modernizr.mac ? 'ctrl' : 'meta' },
      { key: ']', handler: this.forward, optionKeys: !Modernizr.mac ? 'ctrl' : 'meta' },
      { key: '[', handler: this.backwardAll, optionKeys: !Modernizr.mac ? ['alt', 'ctrl'] : ['alt', 'meta'] },
      { key: ']', handler: this.forwardAll, optionKeys: !Modernizr.mac ? ['alt', 'ctrl'] : ['alt', 'meta'] },
    ]);
  },

  unBindLogic: function() {
    this.$panel.off('click', '.forward', this.forward);
    this.$panel.off('click', '.backward', this.backward);
    this.$panel.off('click', '.forward-all', this.forwardAll);
    this.$panel.off('click', '.backward-all', this.backwardAll);

    RM.constructorRouter.unbindGlobalKeyPress('[', this.backward);
    RM.constructorRouter.unbindGlobalKeyPress(']', this.forward);
    RM.constructorRouter.unbindGlobalKeyPress('[', this.backwardAll);
    RM.constructorRouter.unbindGlobalKeyPress(']', this.forwardAll);
  },

  forward: function(event) {
    if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
      RM.constructorRouter.analytics.sendEvent('Key Press', 'bring forward');
    }
    this._move(+1);
  },

  backward: function(event) {
    if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
      RM.constructorRouter.analytics.sendEvent('Key Press', 'bring backward');
    }

    this._move(-1);
  },

  forwardAll: function(event) {
    if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
      RM.constructorRouter.analytics.sendEvent('Key Press', 'send to front');
    }

    this._move(+999, true);
  },

  backwardAll: function(event) {
    if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
      RM.constructorRouter.analytics.sendEvent('Key Press', 'send to back');
    }

    this._move(-999, true);
  },

  _getBlocks: function() {
    var packs = {},
      groupLayers = {};

    return {
      groupLayers: groupLayers,
      blocks: _(this.master.workspace.blocks)
        .chain()
        .filter(function(w) {
          return w.model.get('type') != 'background';
        })
        .sortBy(function(w) {
          return w.model.get('z');
        })
        .reduce(function(memo, w) {
          var pack_id = w.model.get('pack_id');
          if (!pack_id) {
            memo.push(w);
          } else {
            if (packs[pack_id]) {
              packs[pack_id].push(w);
            } else {
              packs[pack_id] = [w];
              memo.push(packs[pack_id]);
            }
          }
          return memo;
        }, [])
        .reduce(
          function(memo, w) {
            var prevW = memo[memo.length - 1],
              currW = w;
            while (prevW instanceof Array) {
              prevW = prevW[prevW.length - 1];
            }
            while (currW instanceof Array) {
              currW = currW[currW.length - 1];
            }

            if (
              prevW &&
              (!!prevW.model.get('is_above') != !!currW.model.get('is_above') ||
                !!prevW.model.get('fixed_position') != !!currW.model.get('fixed_position'))
            ) {
              memo.push([w]);
              if (currW.model.get('is_above')) {
                groupLayers['above_all'] = memo[memo.length - 1];
              } else if (currW.model.get('fixed_position')) {
                if (groupLayers['regular']) {
                  groupLayers['fixed_top'] = memo[memo.length - 1];
                } else {
                  groupLayers['fixed_bottom'] = memo[memo.length - 1];
                }
              } else {
                groupLayers['regular'] = memo[memo.length - 1];
              }
            } else {
              if (!prevW) {
                if (currW.model.get('is_above')) {
                  groupLayers['above_all'] = memo[memo.length - 1];
                } else if (currW.model.get('fixed_position')) {
                  if (groupLayers['regular']) {
                    groupLayers['fixed_top'] = memo[memo.length - 1];
                  } else {
                    groupLayers['fixed_bottom'] = memo[memo.length - 1];
                  }
                } else {
                  groupLayers['regular'] = memo[memo.length - 1];
                }
              }
              memo[memo.length - 1].push(w);
            }
            return memo;
          },
          [[]]
        )
        .value(),
      packs: packs,
    };
  },

  _getBlockGroup: function(blocks, w) {
    if (blocks.indexOf(w) != -1) {
      return blocks;
    }

    var result = null;
    _.each(
      blocks,
      function(_w) {
        if (_w instanceof Array) {
          result = result || this._getBlockGroup(_w, w);
        }
      },
      this
    );
    return result;
  },

  _recalcZindex: function(blocks) {
    var flatten = _.flatten(blocks),
      z = 100,
      toSave = [];

    _.each(flatten, function(w) {
      var old_z = w.model.get('z');
      if (old_z != z) {
        w.model.set('z', z);
        toSave.push({
          _id: w.model.get('_id'),
          z: z,
        });
      }
      z++;
    });
    return toSave;
  },

  _checkForMovedGroup: function(moved, blocks) {
    var pack_id = moved.model.get('pack_id');
    if (!pack_id) {
      return moved;
    }
    var group = this._getBlockGroup(blocks, moved),
      fAllGroup = true;

    _.each(group, function(w) {
      fAllGroup = fAllGroup && w.selected;
    });

    if (fAllGroup) {
      return group;
    }

    return moved;
  },

  _move: function(dir, toEnd) {
    var data = this._getBlocks(),
      blocks = data.blocks,
      packs = data.packs,
      processed = [],
      skipped = _.chain(this.blocks.slice())
        .sortBy(function(w) {
          return w.model.get('z');
        })
        .reduce(function(meta, w, idx) {
          if (processed.indexOf(w) !== -1) {
            return meta;
          }
          processed.push(w);
          var pack_id = w.model.get('pack_id');
          if (!pack_id) {
            meta.push(w);
          } else {
            var fAllGroup = true;
            _.each(packs[pack_id], function(w) {
              fAllGroup = fAllGroup && w.selected;
            });
            if (fAllGroup) {
              meta.push(packs[pack_id]);
              processed = processed.concat(packs[pack_id]);
            } else {
              meta.push(w);
            }
          }
          return meta;
        }, [])
        .value();

    while (skipped && skipped.length) {
      var moved = skipped[0],
        currGroup = this._getBlockGroup(blocks, moved),
        grouppedSelected = _.reduce(
          currGroup,
          function(meta, w, idx) {
            if (skipped.indexOf(w) != -1 && currGroup[idx - 1] && skipped.indexOf(currGroup[idx - 1]) != -1) {
              meta[meta.length - 1].push(w);
            } else {
              if (skipped.indexOf(w) != -1) {
                meta.push([w]);
              }
            }

            return meta;
          },
          [[]]
        ),
        selCurrGroup = _.find(grouppedSelected, function(wArr) {
          return wArr.indexOf(moved) != -1;
        }),
        idx = currGroup.indexOf(selCurrGroup[0]);

      currGroup.splice(idx, selCurrGroup.length);

      var newPos = idx + dir;

      if (newPos < 0) {
        if (currGroup == data.groupLayers['fixed_top']) {
          if (!data.groupLayers['fixed_bottom']) {
            data.groupLayers['fixed_bottom'] = [];
            blocks.splice(0, 0, data.groupLayers['fixed_bottom']);
          }
          currGroup = data.groupLayers['fixed_bottom'];
          newPos = toEnd ? 0 : currGroup.length;
        } else {
          newPos = 0;
        }
      }

      if (newPos > currGroup.length) {
        if (currGroup == data.groupLayers['fixed_bottom']) {
          if (!data.groupLayers['fixed_top']) {
            data.groupLayers['fixed_top'] = [];
            if (data.groupLayers['above_all']) {
              blocks.splice(blocks.length - 1, 0, data.groupLayers['fixed_top']);
            } else {
              blocks.push(data.groupLayers['fixed_top']);
            }
          }
          currGroup = data.groupLayers['fixed_top'];
          newPos = toEnd ? currGroup.length : 0;
        } else {
          newPos = currGroup.length;
        }
      }

      Array.prototype.splice.apply(currGroup, [newPos, 0].concat(selCurrGroup));

      skipped = _.difference(skipped, selCurrGroup);
    }
    this.allModels = this._recalcZindex(blocks);
    this.save();
  },

  save: function(options) {
    options = options || {};
    var cb = options.success;

    options.success = _.bind(function(data) {
      this._saving = false;

      cb && cb(data);

      if (this._saveOptions) {
        this.save(this._saveOptions);
        this._saveOptions = null;
      }
    }, this);

    if (this._saving) {
      this._saveOptions = options;
      return;
    }

    options.skipInitial = true;

    this._saving = true;

    this.master.workspace.save_group(this.allModels, options);
  },
});

export default CommonLayerClass;
