/**
 * Модуль Backbone коллекций списков мэгов
 *@module Mag List Data
 */

import Backbone from 'backbone';
import { UserMagModel } from './user-mag';

/**
 * Базовый класс оллекции мэгов. Используется в основном при генерации списков мэгов в разных разделах
 * @class RM.collections.MagList
 */
export const MagList = Backbone.Collection.extend({
  model: UserMagModel,

  /**
   * Загружает коллекцию с сервера. По окончании загрузки вызывает коллбэки
   * @method load
   * @param options {Object} Объект с коллбэками {success: , error:}
   */
  load: function(options) {
    options = options || {};

    var callback = options.success || $.noop;

    if (this.loaded) {
      // отключаем кеширование, пока не сделаем веб сокеты
      // callback(this);
      // return null;
    }

    var self = this;
    options.success = function() {
      callback(self);
      self.loaded = true;
    };

    return this.fetch(options);
  },

  comparator: function(mag) {
    return -new Date(mag.get('major_update')).getTime();
  },
});
