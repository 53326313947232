/**
 *
 * рабочая область внутри виджета,
 * предназначенная
 * для создания и работы внутри него других виджетов.
 *
 * рабочая область создается родительским виджетом.
 *
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import workspaceInsideBlockTpl from '../../templates/constructor/workspace-inside-block.tpl';
import uploadInputTpl from '../../templates/constructor/helpers/upload-input.tpl';
import Blocks from './blocks';
import HotspotResize from './blocks/hotspot-resize';
import HotspotWidgetSelector from './blocks/hotspot-widget-selector';

const templates = { ...workspaceInsideBlockTpl, ...uploadInputTpl };

const Workspace_inside_block = Backbone.View.extend({
  template: templates['template-constructor-workspace-inside-block'],

  initialize: function(params) {
    _.bindAll(this);

    this.block = params.block;
    this.page_workspace = params.page_workspace;
    this.controls = this.page_workspace.controls;
    this.mag = this.page_workspace.mag;
    this.page = this.page_workspace.page;
    this.blocks = [];
    this.$container = params.$container;

    // задаем себя в качестве внутреннего воркспейса блока
    this.block.child_workspace = this;

    // все необходимые варианты позиций ручек ресайза
    // для данного внутриблокового воркспейса.
    this.resize_handles_positions = params.resize_handles_positions;

    this.min_width = params.min_width || 100;
    this.min_height = params.min_height || 10;

    // инициализируем блоки вложенных виджетов
    // и добавляем их в массив блоков данного внутриблокового воркспейса.
    // коллекция моделей вложенных виджетов
    // инициализируется в models/widget.js.
    this.block.model.widgets_collection.forEach(
      function(widget_model) {
        // При удалении вложенного виджета из хотспота (а это возможно только при Undo в истории
        // после самого первого добавления-создания вложенного виджета),
        // мы не удаляем модель вложенного виджета из коллекции,
        // т.к. потом геморой восстанавливать её при Redo.
        // Из-за этого может возникнуть проблема в следующем сценарии:
        // Создан хотспот, добавлен-создан вложенный виджет, анду добавления виджета,
        // анду создания хотспота, реду создания хотспота — бац хотспот создался
        // с вложенным виджетов, хотя должен быть пустым.
        // Поэтому прежде чем создавать вложенные виджет из моделей в коллекции
        // проверяем имеет ли хотспот айдишник виджета в wids,
        // что говорит о фактическом наличии вложенного виджета.
        if (this.block.model.get('wids').indexOf(widget_model.get('_id')) > -1) {
          this.addBlock(widget_model);
        }
      }.bind(this)
    );

    this.trigger_select_on_selected_block.__debounced = _.debounce(this.trigger_select_on_selected_block, 0);
    this.restoreFocusForCopyPaste.__debounced = _.debounce(this.restoreFocusForCopyPaste, 0);
    this.saveDebounced = _.debounce(this.save, 100);

    this.render();

    this.bind_events();
  },

  // изначально отрисовывает уже имеющиеся вложенные виджеты.
  // инициализирует и отрисовывает:
  // - ручки ресайза внутриблокового воркспейса,
  // - виджет-селектор.
  render: function() {
    this.rendered = true;

    this.setElement($(this.template()));

    this.$blocksWrapper = this.$el.find('.blocks-wrapper');

    this.$el.addClass('tip');

    // заранее рендерим файл инпут
    // для единственного виджета картинки,
    // который потенциально может быть в подсказке хотспот виджета.
    // заранее он нам нужен, чтобы одновременно с созданием виджета картинки
    // при первом нажатии на кнопку в виджет-селекторе
    // мы могли сразу же открыть файндер, затриггерив клик
    // на данный файл инпут.
    // пока файндер открывается виджет картинки уже должен успеть
    // асинхронно создаться и привязать к себе данный файл инпут
    // и слушать его события.
    // мы не можем дождаться инициализации виджета картинки,
    // который сам для себя бы отрендерил файл инпут и затриггерил
    // клик на него, когда виджет бы создался. не может потому что
    // браузеры не дают триггерить клик на файл инпут программно, только
    // после какого-либо действия юзера. например реального клика на кнопку
    // загрузки новой картинки, который в свою очередь программно уже может
    // затриггерить клик на файл инпут.
    // пробовался вариант такой: клик на кнопку виджет селектора → вызов метода
    // создания виджета, которому в параметры передавался колбек.
    // колбек вовращался после создания виджета и должен был затриггерить
    // клик на файл инпут. триггерил, но браузер не открывал файндер.
    // видимо, хоть все и начиналось с события клика юзера, но, т.к.
    // колбек о создании виджета асинхронный, то бразуер уже
    // не считал триггер клика на файл инпут из колбек как прямое следствие клика
    // по виджет-селектору. поэтому был выбран данное решение.
    // как виджет картинки привязывает к себе файл инпут
    // см. в blocks/picture.js → init_file_uploading().

    var upload_input_template = templates['template-constructor-helpers-upload-input'];

    this.$picture_upload_input = $(upload_input_template());

    this.$picture_upload_input.appendTo(this.$el);

    // вставляем в ДОМ сразу, иначе методы
    // отрисовки виджета не найдут элемент и не смогут застилизовать.
    this.$el.appendTo(this.$container);

    this.block.apply_tip_container_size({
      width: this.block.model.get('tip_w'),
      height: this.block.model.get('tip_h'),
    });

    this.block.apply_tip_position();
    this.block.apply_tip_bg_color();
    this.block.apply_tip_box_shadow();
    this.block.apply_tip_border_radius();

    // изначально отрисовываем уже имеющиеся вложенные виджеты.
    this.blocks.map(this.renderBlock);

    // инициализируем и отрисоывываем
    // ручки ресайза внутриблокового воркспейса.
    this.resize_handles_manager = new HotspotResize({
      workspace_inside_block: this,
      positions: this.resize_handles_positions,
    });

    this.widget_selector = new HotspotWidgetSelector({
      workspace_inside_block: this,
      container: this.$el,
    });
  },

  bind_events: function() {
    this.listenTo(this.block.model, 'change:w change:h change:tip_pos', this.block.apply_tip_position);

    this.listenTo(this.block.model, 'change:tip_w change:tip_h', this.on_tip_size_change);

    // при добавлении виджета-ребенка. триггер из models/widget.js → onWidsChange()
    this.listenTo(this.block.model, 'widget:create', this.createBlock);

    // при удалении виджета-ребенка. триггер из models/widget.js → onWidsChange()
    this.listenTo(this.block.model, 'widget:remove', this.removeBlock);

    this.listenTo(this.block.model, 'change:wids', function(model, value, options) {
      if (options.socketUpdate || options.resetPage) return;

      this.adjust_height_to_visible_content();

      this.adjust_column_widgets_positioning();

      this.block.apply_tip_position();

      if (!(options.undo || options.redo)) {
        // изменения wids сохраняем в историю.
        // плюсом сохранятся размеры внутриблокового воркспейса,
        // но у них был сет со skipHistory, так что в историю они не попадут.
        this.block.model.save(
          {
            wids: this.block.model.get('wids'),
            tip_h: this.block.model.get('tip_h'),
            // forceDefaultViewport, чтобы model.js sync() не сохранил значения во вьюпорт.
          },
          { patch: true, forceDefaultViewport: true }
        );
      }

      // побочные изменения не сохраняем в историю.
      this.blocks.forEach(function(block) {
        block.saveBox({ patch: true, skipHistory: true });
      });
    });

    this.listenTo(this.block.model.widgets_collection, 'change:hidden', function(model, value, options) {
      this.adjust_height_to_visible_content();

      this.adjust_column_widgets_positioning();

      this.block.apply_tip_position();

      var group_changeset = [
        {
          _id: this.block.model.get('_id'),
          tip_w: this.block.model.get('tip_w'),
          tip_h: this.block.model.get('tip_h'),
        },
      ];

      this.blocks.forEach(
        function(block) {
          var changeset = _.extend(
            {
              _id: block.model.get('_id'),
              hidden: block.model.get('hidden'),
            },
            block.getBoxData()
          );

          if (block.model.get('type') === 'picture') {
            // Кроп картинки притерпевает изменения при показе ранее скрытой картинки,
            // поэтому сохраняем кроп-параметры.
            // См. Widget_selector → on_widget_select()
            _.extend(changeset, {
              cropX: block.model.get('cropX'),
              cropY: block.model.get('cropY'),
              cropW: block.model.get('cropW'),
              cropH: block.model.get('cropH'),
              ratio: block.model.get('ratio'),
              scale: block.model.get('scale'),
            });

            !block.isAnimated() &&
              block.getFinalImage(
                function(error) {
                  if (error) console.log('w-picture getFinalImage error: ', error);
                },
                { silent: true, patch: true }
              );
          }

          group_changeset.push(changeset);
        }.bind(this)
      );

      this.block.workspace.save_group(group_changeset, {
        isHotspot: true,
        skipHistory: true,
        silent: true,
        viewport: 'default',
        forceDefaultViewport: true,
      });
    });

    this.listenTo(this.page, 'change:viewport', this.onChangeViewport);

    this.on('select', this.onSelect);

    this.on('w-text-entered-edit-mode', this.on_text_entered_edit_mode);

    this.on('w-text-before-leave-edit-mode', this.on_text_leaved_edit_mode);

    this.$el.on('click', this.onClick);
  },

  // для copy paste событий кроссбраузерно.
  restoreFocusForCopyPaste: function() {
    $('.copyhack').selectText();
  },

  // выставляет высоту воркспейса, равную
  // суммарно сумме высот видимых вложенных виджетов.
  adjust_height_to_visible_content: function(options) {
    options = options || {};

    var visible_blocks = this.get_visible_blocks(),
      new_height = 0;

    visible_blocks.forEach(function(block) {
      new_height += block.getBoxData().h;
    });

    var change_set = {
      _id: this.block.model.get('_id'),
      tip_h: new_height,
    };

    if (options.save) {
      this.block.model.set(change_set, { skipHistory: true });
      this.saveDebounced({}, { skipHistory: true });
    } else {
      this.block.model.set(change_set, { skipHistory: true });
    }
  },

  adjust_column_widgets_positioning: function() {
    // ставим первым блоком по порядку картинку (если она есть)
    var blocks_by_order = _.sortBy(this.get_visible_blocks(), function(block) {
        return block.model.get('type') === 'picture' ? 0 : 1;
      }),
      y = 0;

    blocks_by_order.forEach(function(block) {
      block.model.set({ x: 0, y: y }, { skipHistory: true });

      // увеличиваем отступ по вертикали для следующего по порядку виджета
      // на величину собственной высоты.
      y += block.getBoxData().h;
    });
  },

  on_tip_size_change: function(block_model, value, options) {
    // отрисовываем новую ширину подсказки.
    this.block.apply_tip_container_size({
      width: block_model.get('tip_w'),
      height: block_model.get('tip_h'),
    });

    this.adjust_column_widgets_positioning();

    this.block.apply_tip_position();
  },

  on_text_entered_edit_mode: function() {
    this.resize_handles_manager.hide();
  },

  on_text_leaved_edit_mode: function() {
    // показываем ручки-ресайза.
    this.resize_handles_manager.show();

    this.fit_text_to_content(true);
  },

  fit_text_to_content: function(save) {
    var visible_blocks = this.get_visible_blocks();

    var text_block = _.find(visible_blocks, function(block) {
      return block.model.get('type') === 'text';
    });

    if (!text_block) {
      return;
    }

    var current_text_height = text_block.model.get('h');

    var text_content_height = 0;

    text_block.$iframe_body.children().each(function() {
      // outerHeight — чтобы паддинги посчитались.
      text_content_height += $(this).outerHeight();
    });

    text_content_height = _.max([text_content_height, 10]);

    // если высота блока не равна высоте текстового контента.
    if (current_text_height != text_content_height) {
      text_block.model.set('h', text_content_height, { skipHistory: true });

      // вызовет после себя по событию из модели
      this.adjust_height_to_visible_content({ save: true });
    }
  },

  // добавляет редактируемый виджет к воркспейсу.
  createBlock: function(model, options) {
    // инициализирует блок виджета
    // и добавляет его в массив блоков данного воркспейса.
    var block = this.addBlock(model);

    // отрисовывает блок.
    this.renderBlock(block);

    if (!options.undo && !options.redo && !options.group) {
      block.select();

      // Не триггерим внутренний селект, если блок создан в результате
      // копирования родителя
      if (!options.copy) {
        this.trigger_select_on_selected_block.__debounced();
      }
    }
  },

  trigger_select_on_selected_block: function() {
    var blocks = this.getSelectedBlocks();

    // может такое быть когда например вставили невалидный картиночный виджет (кривая картинка) и он сразу удалился
    // а triggerSelectOnSelectedBlock вызывается с дебонсом и потом по цепочке создает контролы в том числе
    // а у нас блок уже удален к тому времени
    // https://trello.com/c/ipO27p6V/17-common-position-js-34-uncaught-typeerror-cannot-read-property-model-of-undefined
    if (!blocks || !blocks.length) return;

    this.trigger('select', blocks);
  },

  // инициализирует блока виджета
  // и добавляет его в массив блоков данного воркспейса.
  addBlock: function(model) {
    var block_type = model.get('type'),
      Block = Blocks[block_type],
      block = new Block(model, this);

    // значит, что вложенный виджет данного типа
    // нельзя удалить из родительского,
    // только скрыть, выставив hidden: true.
    block.immortal = true;

    if (model.get('type') === 'text') {
      // текстовому виджету не нужны базовые контролы,
      // если он вложенный. используются только контролы режима редактирования.
      block.controls = [];

      // контрол колонок в режиме редактирования также не нужен.
      block.edit_controls = _.without(block.edit_controls, 'text_columns');
    } else if (model.get('type') === 'picture') {
      block.initial_controls = ['picture_crop', 'picture_link'];

      block.vector_controls = ['picture_color', 'picture_link'];

      block.animated_controls = ['picture_crop', 'picture_link'];
    }

    this.listenTo(block, 'all', this.passChildBlockEvent);

    this.blocks.push(block);

    return block;
  },

  // Пробрасывает события из дочерних блоков.
  // Нужно, чтобы как-то сообщать родительскому виджету о событиях детей
  passChildBlockEvent: function(name, e) {
    var args = Array.prototype.slice.call(arguments, 1);
    this.trigger('childBlockEvent', name, args);
  },

  // отрисовывает блок.
  renderBlock: function(block) {
    block.render();

    // задаем визуальные параметры блока на основе модели.
    block.redrawPosition(block.model, { forceRedraw: true });
  },

  // добавляет виджет внутрь родительского виджета.
  add_widget: function(params, options) {
    params = params || {};

    var block = Blocks[params.type.toLowerCase()];

    // в дефолтах можно испоьлзовать функции, например в текстоом виджете есть
    _.defaults(
      params,
      _.mapObject(_.cloneWithObjects(block.defaults), function(val, key) {
        return _.isFunction(val) ? val() : val;
      })
    );

    // создает модель нового виджета-ребенка,
    // сохраняет её на сервер,
    // сохраняет её id в массив wids: []
    // модели данного родительского виджета (onAddWidget колбэк).
    this.block.model.addWidget(params, options);
  },

  removeBlock: function(model) {
    var block = this.findBlock(model.get('_id'));

    this.blocks = _.without(this.blocks, block);

    this.stopListening(block);

    block.destroy();
  },

  onClick: function(event) {
    // временно.
    // TODO: дать таскать за картинку.
    event.stopPropagation();

    // Хак, т.к. в Chrome 33 появился баг - при отпускании мыши над воркспейсом, а не над точкой ресайза
    // (например, при сесайзе с шифтом) у воркспейса срабатывает onClick, хотя этот клик начался вовсе не над ним
    // Во всех других браузерах и в Chrome < 33 это работает корректно
    // https://trello.com/c/ikJTRltb/102-rotation-shift
    // if (window.suppressClick && window.suppressClick > +new Date()) { return;}

    // если клик дошел от виджета
    // (вообще от любого элемента внутри .workspace-inside-block), то мы не делаем deselect.
    // проверка просто смотрит, что элемент находится внутри .workspace (closest)
    // но при этом это не сам .workspace
    if (
      !$(event.target).hasClass('workspace-inside-block') &&
      !!$(event.target).closest('.workspace-inside-block').length
    ) {
      return;
    }

    // сначала спрашиваем менеджера контролов, можем ли мы сказать всем deselect
    // менеджер опросит все открытые контролы и если кто-то из них скажет,
    // что он не может закрыться
    // тогда сообщение deselect никуда не пройдет
    // (например, когда нам надо просто закрыть какие-то панельки
    // и сообщение 'deselect' никуда не должно пройти)
    if (!this.page_workspace.controls || this.page_workspace.controls.canControlsBeClosed()) {
      this.trigger('deselect');
    }
  },

  onChangeViewport: function(page, viewport, options) {
    // Вложенные виджеты пока не поддерживают вьюпорты
  },

  redrawPacksFrames: _.noop, // Заглушка, т.к. она безусловно вызывается из блока

  redrawBottomShiftLine: _.noop, // Заглушка, т.к. она безусловно вызывается из блока

  onSelect: function() {
    var selected_blocks = this.getSelectedBlocks(),
      text_block = _.find(selected_blocks, function(block) {
        return block.model.get('type') === 'text';
      });

    // говорим менеджеру контролов страницы,
    // какие контролы надо показатся.
    // во внутриблоковом воркспейсе вложенный блок перед
    // выделением снимает выделение с других влож. блоков.
    // так что тут всегда передается один выделенный блок.
    this.page_workspace.controls.onSelect(selected_blocks);

    if (text_block && !text_block.isEditMode) {
      // если был выделен текстовый блок
      // и он еще не в режиме редактирования,
      // то входим в режим редактирования текста.

      text_block.enterEditMode();
    }
  },

  /**
   * Возвращает массив выбранных блоков
   */
  getSelectedBlocks: function() {
    return _.filter(this.blocks, function(block) {
      return block.selected;
    });
  },

  /**
   * Возвращает все блоки, которые можно выделить
   */
  getSelectableBlocks: function() {
    return _(this.blocks).filter(function(block) {
      return !block.model.get('hidden') && !block.outofbox;
    });
  },

  // возвращает все не скрытые блоки.
  get_visible_blocks: function() {
    return _.filter(this.blocks, function(block) {
      return !block.model.get('hidden');
    });
  },

  // возвращает все пропорциональные блоки.
  get_visible_proportional_blocks: function() {
    return _.filter(this.get_visible_blocks(), function(block) {
      return block.proportional;
    });
  },

  // возвращает все непропорциональные блоки.
  get_visible_nonproportional_blocks: function() {
    return _.filter(this.get_visible_blocks(), function(block) {
      return !block.proportional;
    });
  },

  /**
   * Возвращает все виджеты которые существуют на данный момент
   */
  getExistingWidgets: function() {
    return _(this.blocks)
      .chain()
      .pluck('model')
      .value();
  },

  /**
   * Находит блок по айдишнику виджета
   */
  findBlock: function(wid) {
    return _.find(this.blocks, function(block) {
      return block.model.id == wid;
    });
  },

  // метод отдающий размеры в-воркспейса
  // и его координаты в воркспейсе страницы.
  // такие координаты нужно знать
  // при ресайзе в-воркспейса, когда
  // у него колоночное позиционирование виджетов
  // и виджет картинки внутри, чтобы применять
  // ограничения по ресайзу в-воркспейса, отталкиваясь
  // от ограничений по ресайзу картинки
  // в ограниченном кроп моде
  get_box_data: function() {
    var block_box = this.block.getBoxData({ checkFixedPosition: true });

    var position_relative_to_parent_block;

    position_relative_to_parent_block = this.block.hotspotWidget.apply_tip_position(this.block.model.attributes);

    var left_rel = position_relative_to_parent_block.left;

    var top_rel = position_relative_to_parent_block.top;

    var left_abs = block_box.x + left_rel;
    var top_abs = block_box.y + top_rel;

    // Если хотспот фиксированный, то в block_box
    // y-координата вернется относительно вьюпорта (видимой области страницы),
    // т.к. мы специально запросили данные бокса с опцией checkFixedPosition: true.
    // Чтобы отдать верное значение top_abs относительно воркспейса страницы
    // надо добавить значение проскроленности к текущему значению.
    // Если это не сделать, то значение top_abs у подсказки фиксированного хотспота будет не верным
    // и бэкпик картинки будет позиционироваться неправильно.
    if (this.block.model.get('fixed_position')) top_abs += this.block.workspace.$container.scrollTop();

    return {
      width: this.block.model.get('tip_w'),
      height: this.block.model.get('tip_h'),

      // координаты относительно родительского блока.
      left_rel: left_rel,
      top_rel: top_rel,

      // координаты относительно воркспейса страницы.
      left_abs: left_abs,
      top_abs: top_abs,
    };
  },

  onDeselect: function() {
    if (this.block && this.block.selected) this.block.deselect();
    if (this.block && this.block.isEditMode) this.block.leaveEditMode();
  },

  save: function(changeset, options) {
    this.block.model.save(changeset, options);
  },

  destroy: function() {
    this.resize_handles_manager && this.resize_handles_manager.destroy();

    this.widget_selector && this.widget_selector.destroy();

    // при удалении хтспота внутренние виджеты из wids не удаляем, вроде как лишнее это
    // просто вызываем destroy на каждом созданном блоке
    this.blocks.forEach(function(block) {
      block.destroy();
    });

    delete this.blocks;

    this.off('select w-text-entered-edit-mode w-text-before-leave-edit-mode');

    this.remove();
  },
});

export default Workspace_inside_block;
