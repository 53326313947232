/**
 * Класс для тулбара с кнопками меню, шера, едит кнопки и самой панельки шера
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/viewer/toolbar.tpl';
import ShareClass from './share';
import MagMenuClass from './mag-menu';
import Device from '../common/device';

const ToolbarClass = Backbone.View.extend({
  PAGE_COUNTER_TIMEOUT: 2800,
  PAGE_COUNTER_TIMEOUT_INITIAL: 4000,

  template: templates['template-viewer-toolbar'],

  events: {
    'click .menu-button': 'toggleMenu',
    'click .share-button': 'toggleShare',
    'click .embed-close-button': 'onEmbedCloseClick',
  },

  initialize: function(params) {
    _.bindAll(this);

    _.extend(this, params);

    return this; // для вызова по цепочке
  },

  render: function() {
    // создаем вьюху стандартым методом бекбона:
    // устанавливаем el, $el и делегацию событий из списка events
    this.setElement(
      this.template({
        mag: this.mag,
        isPreview: this.isPreview,
        hasMenu: this.hasMenu,
        hasShare: this.hasShare,
        hasPageCounter: this.hasPageCounter,
        pageCounterInsideMenu: this.hasMenu && Device.isPhone,
        totalPages: this.mag.getPagesCount(),
        common: RM.common,
      })
    );

    this.$el.appendTo(this.$container);

    this.$menuButton = this.$el.find('.menu-button');
    this.$shareButton = this.$el.find('.share-button');
    this.$pageCounter = this.$el.find('.page-counter');
    this.$pageCounterWrapper = this.$pageCounter.parent(); // для телефона это кнопка меню

    if (this.hasShare) {
      this.share = new ShareClass({
        mag: this.mag,
        router: this.router,
        $container: Device.isPhone ? this.$container : this.$shareButton, // на телефоне шер не привязан к кнопке в тулбаре, а просто вылезает снизу экрана с оверлеем
      }).render();

      // на телефоне всегда показываем кнопку шера если меню отключено
      // просто при включенном меню кнопка шера показываетя только при открытом меню
      // а для десктопа этот кла прочитает панелька шера и отодвинет себя от края экрана
      if (!this.hasMenu) {
        this.$shareButton.addClass('without-menu-button');
      }
    }

    if (this.hasMenu) {
      this.magMenu = new MagMenuClass({
        mag: this.mag,
        pages: this.pages,
        router: this.router,
        isPreview: this.isPreview,
        hasProjectInfo: this.hasProjectInfo,
        publishDate: this.publishDate,
        $container: this.$container,
        $toolbar: this.$el,
        viewerType: this.viewerType,
      }).render();
    }

    this.listenTo(this.mag, 'pageChanged', this.onPageChange);
    this.listenTo(this.mag, 'finalPageShown', this.onFinalPageShow);
    this.listenTo(this.mag, 'finalPageHidden', this.onFinalPageHide);
    this.listenTo(this.mag, 'menuShown', this.onMenuShow);
    this.listenTo(this.mag, 'menuHidden', this.onMenuHide);
    this.listenTo(this.mag, 'presentationModeOn', this.onPresentationModeOn);
    this.listenTo(this.mag, 'presentationModeOff', this.onPresentationModeOff);

    // если мы не в режиме превью тогда кнопки тулбара показываем с анимацией выезжания, но не одновременно, а по очереди
    if (!this.isPreview) {
      var $buttons = this.$('.offscreen-initially:visible'),
        interval = 150,
        timeout = $buttons.length * interval + 800; // 800 изначальная пауза, чтобы синхронно со стрелками навигации

      // перебираем кнопки в том порядке в котором они идут в тулбаре,
      // но таймер на показ проставляем от большего к меньшему (т.е. меню выедет первым, хотя идет последним)
      $buttons.each(function(ind, elem) {
        setTimeout(function() {
          $(elem).removeClass('offscreen-initially');
        }, timeout);
        timeout -= interval;
      });
    }

    this.$('div[data-alt]:not(.rmalttext), a[data-alt]:not(.rmalttext)').RMAltText();

    return this; // для вызова по цепочке
  },

  destroy: function() {
    this.share && this.share.destroy();

    this.magMenu && this.magMenu.destroy();

    // удаляем вьюху стандратным методом бекбона:
    // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
    return this.remove(); // return this для вызова по цепочке
  },

  isMenuOpened: function() {
    return !!this.menuShown;
  },

  closeMenu: function() {
    this.magMenu && this.magMenu.hide();
  },

  onPageChange: function(page, params) {
    this.showPageCounter(page, params);

    this.$menuButton.removeClass('waiting-video-bg-start preloading');

    // с прежней страницы снимаем ожидание загрузки, оно нам больше не интересно
    this.prevPage && this.stopListening(this.prevPage);

    if (page) {
      if (!page.loaded) {
        this.$menuButton.addClass('preloading');
        this.listenTo(page, 'pageLoaded', this.onPageLoaded);
      }

      if (page.hasVideoBG && !page.videoBGStarted) {
        this.$menuButton.addClass('waiting-video-bg-start');
        this.listenTo(page, 'videoBGStarted', this.onVideoBGStarted);
      }

      this.prevPage = page;
    }
  },

  onPageLoaded: function() {
    this.$menuButton.removeClass('preloading');
  },

  onVideoBGStarted: function() {
    this.$menuButton.removeClass('waiting-video-bg-start');
  },

  onFinalPageShow: function() {
    this.magMenu && this.magMenu.hide();

    this.finalPageShown = true;
    this.updateVisibilityState();
  },

  onFinalPageHide: function() {
    this.finalPageShown = false;
    this.updateVisibilityState();
  },

  onPresentationModeOn: function() {
    this.isPresentationMode = true;
    this.updateVisibilityState();
  },

  onPresentationModeOff: function() {
    this.isPresentationMode = false;
    this.updateVisibilityState();
  },

  updateVisibilityState: function() {
    if (this.finalPageShown || this.isPresentationMode) {
      this.$el.stop().fadeOut(200);
    } else {
      this.$el.stop().fadeIn(200);
    }
  },

  onMenuShow: function() {
    this.menuShown = true;

    this.hidePageCounter();
  },

  onMenuHide: function() {
    this.menuShown = false;
  },

  getPageCounterSize: function(num) {
    if (!this.cachedPageCounterData) {
      this.$pageCounter.find('.cur').text('0');
      var sizeWithOneDigit = this.$pageCounter.outerWidth();

      this.$pageCounter.find('.cur').text('00');
      var sizeWithTwoDigits = this.$pageCounter.outerWidth();

      var digitSize = sizeWithTwoDigits - sizeWithOneDigit,
        baseSize = sizeWithOneDigit - digitSize;

      this.cachedPageCounterData = {
        baseSize: baseSize,
        digitSize: digitSize,
      };

      this.$pageCounter.find('.cur').text(num);
    }

    var data = this.cachedPageCounterData;

    // рассчитываем новую длину пейдж каунтера как его базовую длину с пустым текстом номера страницы (но тектсктом об общем количестве страниц)
    // плю приблизительную длину текста номера страницы (кол-во цифр в номере умножаем на длину цифры 0)
    return data.baseSize + (num + '').length * data.digitSize;
  },

  // размеры родителя для пейдж каунтера приходится рассчитывать самостоятельно
  // родитель появляется-исчезает с анимацией (в том иле и амимирует ширину в 0, чтобы другие кнопки сдвигались на его место)
  // плюс к тому он должен подстраиваться под размер пейдж каунтера, который различен для "1 of 1" и "999 of 999"
  // чтобы транзишены работали начальный и конечный размер должены быть заданы (вариант с auto не пройдет)
  updatePageCounterSize: function(num) {
    if (!this.$pageCounter.length || !this.$pageCounterWrapper.length) return;

    if (!this.menuShown && this.pageCounterShown) this.$pageCounterWrapper.css('width', this.getPageCounterSize(num));
    else this.$pageCounterWrapper.css('width', '');
  },

  showPageCounter: function(page, params) {
    if (this.hasPageCounter) {
      this.$pageCounter.find('.cur').text(page.num);

      if (params.showPageCounter) {
        clearTimeout(this.pageCounterTimeout);

        this.$el.addClass('show-page-counter');

        this.pageCounterShown = true;

        // фикс бага с исчезающим пейдж каунтером на андроиде
        // https://trello.com/c/5A8ccqjM/3-2-android
        if (Modernizr.android) {
          setTimeout(
            _.bind(function() {
              this.updatePageCounterSize(page.num);
            }, this),
            17
          );
        } else {
          this.updatePageCounterSize(page.num);
        }

        this.pageCounterTimeout = setTimeout(
          this.hidePageCounter,
          params.initial ? this.PAGE_COUNTER_TIMEOUT_INITIAL : this.PAGE_COUNTER_TIMEOUT
        );
      }
    }
  },

  hidePageCounter: function() {
    if (this.hasPageCounter) {
      clearTimeout(this.pageCounterTimeout);

      this.$el.removeClass('show-page-counter');

      this.pageCounterShown = false;

      this.updatePageCounterSize();
    }
  },

  toggleMenu: function(e) {
    this.magMenu && this.magMenu.toggle();
  },

  toggleShare: function(e) {
    // если кликнули непосредственно по кнопке, а не по панельке которая рендерится внутри кнопки
    if ($(e.target).is(this.$shareButton)) this.share && this.share.toggle();
  },

  postMessageToParent: function(type, data) {
    var payload = {
        type: type,
        data: data,
      },
      win = window.parent;

    win && win.postMessage(JSON.stringify(payload), '*');
  },

  onEmbedCloseClick: function(e) {
    this.postMessageToParent('close-fullscreen');
  },
});

export default ToolbarClass;
