/**
 * Конструктор для виджета Картинка
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockCommonPicture from './common-picture';
import BlockFrameClass from '../block-frame';
import BlockClass from '../block';
import Viewports from '../../common/viewports';
import uploadInputTpl from '../../../templates/constructor/helpers/upload-input.tpl';
import uploadButtonTpl from '../../../templates/constructor/helpers/upload-button.tpl';
import Uploader from '../helpers/uploader';
import WidgetModel from '../models/widget';
import History from '../models/history';

const templates = { ...uploadButtonTpl, ...uploadInputTpl };

const PictureBlock = BlockCommonPicture.extend({
  name: 'Picture',
  sort_index: 2, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
  thumb: 'picture',

  icon_color: '#00DDB1',

  proportional: true,
  dontSaveOnResize: true,

  initial_controls: [
    'picture_settings',
    'picture_fwpos',
    'picture_crop',
    'picture_1x1',
    'common_animation',
    'picture_link',
    'picture_preview',
    'common_rotation',
    'common_position',
    'common_layer',
    'picture_alt',
    'common_lock',
  ],

  animated_controls: [
    'picture_settings',
    'picture_fwpos',
    'picture_1x1',
    'picture_link',
    'picture_preview',
    'common_animation',
    'common_rotation',
    'common_position',
    'common_layer',
    'picture_alt',
    'common_lock',
  ],

  vector_controls: [
    'picture_settings',
    'picture_color',
    'picture_link',
    'picture_preview',
    'common_animation',
    'common_rotation',
    'common_position',
    'common_layer',
    'picture_alt',
    'common_lock',
  ],

  commonControls: ['picture_preview'],
  commonControlsPositions: [4],

  viewport_fields: Viewports.viewport_fields.picture,

  initialize: function(model, workspace) {
    this.initBlock(model, workspace);

    this.cachedEffects = [];

    this._imgsPreload = [];

    this.frameClass = pictureFrame;

    // служебный метод, чтобы его биндить к разным элементам
    this._css = function(s) {
      return parseInt(this.css(s), 10);
    };

    this.setControls();

    // индикаторы загрузки картинок
    this.picloaded = {};

    this.setScaledImage.__debounced = _.debounce(this.setScaledImage, 500);
    this.setFinal.__debounced = _.debounce(this.setFinal, 1000);

    this.model.skipResponse = true;
  },

  initVector: function() {
    this.setControls();
    this.updateControls();
    this.loadVector({ setColor: true });
  },

  updatePicture: function(params) {
    var isStretchBlock = this.isFullWidth() || this.isFullHeight();

    params = params || {};

    if (!params.load) {
      params.load = 'finalUrl';
    }

    if (Modernizr.retina && params.load == 'finalUrl') {
      params.load = 'final2xUrl';
    }

    // Для растянутых блоков всегда показываем нескейленную картинку
    if (isStretchBlock) {
      params.load = 'unscaledUrl';
    }

    if (this.isVector() || this.isAnimated()) {
      params.load = 'editedVectorUrl';
      if (!this.model.get('picture')[params.load]) params.load = 'url';
    }

    if (!this.cropmode && !this.fakingMode && !this.fwposmode) {
      this.changeControls();
    }

    var url = this.model.get('picture') && this.model.get('picture')[params.load];

    // Для растянутых блоков нескейленный урл не найден, то пробуем загрузить оригинал
    if (isStretchBlock && !url) {
      url = this.model.get('picture') && this.model.get('picture')['url'];
    }

    if (!url && !this.model.get('picture')) {
      this.$picture && this.$picture.remove();
      this.$el.addClass('empty');

      // если виджет картинки вложенный,
      // то у него нет виджетбара и иконки там.
      if (this.has_parent_block) return;

      return;
    }

    if (!url) {
      // Если залили картинку в десктопе когда вьюпорт для виджета уже был создан то возьмем декстопную картинку и отскейлим ее
      url = this.model.get('picture').url;
      params.renewRatio = true;
    }
    if (params.renewRatio) {
      // Если картинка только загружена или только изменилась, то подправим ее размеры
      _.defer(
        function() {
          this.fixSize(params);
        }.bind(this)
      );
      return;
    }

    var self = this;
    var $content = this.$content;

    this.$el.removeClass('empty');

    if (params.skipAnimation) $content.children().remove(); // this.$picture.attr('src', this.model.get('picture')[params.load])

    this._preload(params.load, function($pic) {
      if (!self.isVector && (self.cropmode || self.in_constrained_crop_mode)) {
        if (params.load != 'url') {
          return;
        }
      }

      if (self.fakingMode) return;

      if (url != (self.model.get('picture') && self.model.get('picture')[params.load])) {
        return;
      }

      var onAnimationFinish = function() {
        self.$picture = $pic;

        params.complete && params.complete.call();

        $pic.css({ opacity: 1 });
        $content
          .children()
          .not(':last')
          .remove();

        self.updateSettings();
      };

      $content
        .children()
        .stop(false, true)
        .css({ opacity: 1 });

      params.prepare && params.prepare($pic);

      if (self.$picture && !params.skipAnimation) $pic.css('opacity', 0);

      if (self.isVector()) {
        var node = document.importNode($pic.get(0), true);
        var $svgScaleWrapper = $('<div class="svg-scale-wrapper"></div>');
        $svgScaleWrapper.append(node);
        $content.append($svgScaleWrapper);
        self.$svg = $(node);
        $pic = self.$svg;
      } else $pic.appendTo($content);
      self.updateSettings($pic);

      if (self.$picture && !params.skipAnimation) {
        $pic.animate({ opacity: 1 }, 200, onAnimationFinish);
      } else {
        onAnimationFinish();
      }

      self.hideIconLoader();

      if (params.toggledHidden) return;
      if (self.cropmode) return;

      _.defer(
        _.bind(function() {
          RM.constructorRouter.workspace.hideLoader();
        }, self)
      );
    });
  },

  render: function() {
    this.create();
    this.$el.addClass('picture');

    this.proportional = this.getPictureUrl();

    // проверим - не возврат ли из режима "скрытый" у виджета
    // /gwidget - подозрительное место. Проверить
    var fromHiddenMode = this.model.changed && this.model.changed.hidden === false;

    // Картинка в хотспоте сразу входит в ограниченный кроп-мод
    // и вызывает себе updatePicture там.
    // в каждом из этих updatePicture запускался _preload внутри
    // Последним исполнялся _preload из первого вызова отсюда, который
    // не выставлял картинке кроп значения.
    !this.has_parent_block &&
      this.updatePicture({
        isNew: this.model.created,
        isFirst: true,
        toggledHidden: fromHiddenMode,
        renewRatio: !this.model.get('ratio'),
      });

    this.preloadHiRes();

    this.$el.off('dblclick');
    this.$el.on('dblclick', this.onDblClick);

    this.triggerReady();

    if (fromHiddenMode) return;

    this.frame.recalcPointsDirections();
    this.updateSettings();

    if (this.has_parent_block) {
      // если виджет картинки вложенный,
      // т.е. работает внутри другого виджета.

      this.init_file_uploading();

      this.enter_constrained_crop_mode();
    }
  },

  // инициализирует и отрисовывает
  // файл инпут и кнопку для аплоада файлов.
  // используется, когда виджет-картинки вложенный,
  // т.е. работает внутри другого виджета, например, хотспота
  // и не имеет панельки в виджетбаре, которая берет
  // на себя загрузку файлов.
  init_file_uploading: function() {
    var upload_input_template, upload_button_template;

    if (this.has_parent_block) {
      // объяснение почему берем заранее отрендеренный файл инпут
      // из воркспейса, см. в workspace-inside-block.js → render().
      this.$upload_input = this.workspace.$picture_upload_input;
    } else {
      upload_input_template = templates['template-constructor-helpers-upload-input'];

      this.$upload_input = $(upload_input_template());
    }

    upload_button_template = templates['template-constructor-helpers-upload-button'];

    this.$upload_button = $(upload_button_template());

    // инициализируем файл аплоадер.
    this.uploader = new Uploader(this, {
      fileInput: this.$upload_input,
    });

    // биндим события загрузки файла.
    this.bindUploader(this.uploader);

    // биндим открытие файндера по нажатию на кнопку.
    this.$upload_button.on(
      'click',
      function() {
        this.$upload_input.trigger('click', 'stop');
      }.bind(this)
    );

    this.model.on('change:picture', this.toggle_loaded);

    // вставляем файл инпут и кнопку в ДОМ.
    this.$upload_input.add(this.$upload_button).appendTo(this.$el);
  },

  bindUploader: function(uploader) {
    uploader.on('start', this.on_start_upload);
    uploader.on('done', this.on_done_upload);
    uploader.on('fail', this.on_fail_upload);
  },

  on_start_upload: function(e, data) {
    this.showIconLoader();

    this.originalLoaded = false;

    this.workspace.trigger('loading_original_picture');
  },

  on_done_upload: function(e, data) {
    if (!data || !data.result) {
      console.error('error file upload!');
      return;
    }

    // Загрузку первой картинки в хотспоте в историю не сохраняем,
    // т.к. нет смысла откатываться потом к заглушке (такое же поведение
    // и у обычных картинок).
    this.changePicture(data.result, { toHistory: this.model.get('picture') });

    delete this.effects;

    this.cachedEffects = [];
  },

  on_fail_upload: function() {
    this.hideIconLoader();
  },

  toggle_loaded: function() {
    if (this.model.get('picture')) {
      this.isNew = false;
    }

    if (this.model.get('picture')) {
      this.$upload_button.removeClass('uploading');

      // в changePicture будет запущен
      // updatePicture, но он не обновит
      // картинку, т.к. в его params.load
      // не будет равен 'url' — там вообще params.null
      // и в updatePicture идет return,
      // если мы в огр. кроп-моде и грузим не оригинальную картинку.
      // нужный updatePicture запустится из enter_constrained_crop_mode,
      // но, чтобы его запустить нам надо снять флаг, что мы уже в нём.
      this.in_constrained_crop_mode = false;

      this.enter_constrained_crop_mode();
    }
  },

  getIconData: function() {
    if (this.isVector()) {
      return this;
    }
    return null;
  },

  getIconStyle: function(options) {
    var res = {};

    var path = '/img/constructor/widgetbar/icons/';

    if (options && options.small) {
      res['background-color'] = this.icon_color;
      path += 'small/';
    }

    if (!this.model.get('picture')) {
      var image = path + 'picture.svg';
    } else {
      if (!this.isVector()) {
        image = this.model.get('picture').thumbUrl;
        res['background-size'] = 'cover';
      } else {
        res['background-color'] = this.icon_color;
        image = '';
      }

      if (!options || !options.small) {
        res['border-radius'] = '8px';
      }
    }
    res['background-image'] = 'url(' + image + ')';

    return res;
  },

  // подсвечиваем иконку контрола, если установлен alt текст для картинки
  controlsIconClasses: function() {
    return {
      'panel-picture-alt': this.model.get('alt') ? 'highlighted' : null,
    };
  },

  enterCropMode: function() {
    if (this.cropmode || !this.model.get('picture')) return;

    if (this.in_constrained_crop_mode) {
      // до-входим в полный кроп-мод.

      if (!this.originalLoaded) {
        return;
      }

      this._beforeCropAttrs = _.clone(this.model.attributes);

      this.cropmode = true;

      this.disableDragging = true;

      this.$el.addClass('crop');

      History.setEmptyHandlers();

      $('#main').css('overflow-y', 'hidden');

      this._modeTransition = false;

      // для того, чтобы определить
      // this.initialCrop для вложенного виджета картинки,
      // надо найти координаты влож. виджтеа в воркспейсе страницы.

      // бокс внутриблокового воркспейса содержит
      // свои координаты относительно
      // воркспейса страницы.
      var workspace_box = this.workspace.get_box_data();

      this.initialCrop = {
        left:
          workspace_box.left_abs +
          parseInt(this.$el.css('left')) -
          Math.round(this.model.get('cropX') * this.model.get('scale')),
        top:
          workspace_box.top_abs +
          parseInt(this.$el.css('top')) -
          Math.round(this.model.get('cropY') * this.model.get('scale')),
      };

      var $pic = _.clone(this.$picture);

      $pic.css({
        width: Math.round(this.model.get('originalW') * this.model.get('scale')),
        height: Math.round(this.model.get('originalH') * this.model.get('scale')),
        top: -Math.round(this.model.get('cropY') * this.model.get('scale')),
        left: -Math.round(this.model.get('cropX') * this.model.get('scale')),
      });

      this.frame.constraints = {
        top: this.initialCrop.top,
        left: this.initialCrop.left,
        width: Math.round(this.model.get('originalW') * this.model.get('scale')),
        height: Math.round(this.model.get('originalH') * this.model.get('scale')),
      };

      this.showBackPic($pic);

      this.bindPictureDrag();

      this.trigger('modechanged transitionmode', this.cropmode, this._modeTransition);
    } else {
      this.storeFixedPosition();

      this._beforeCropAttrs = _.clone(this.model.attributes);

      this._getFinalRequest && this._getFinalRequest.abort();

      this.transitionMode(true);

      this.frame.removeRotationState(); // Выходим из режима вращения
      this.frame.resetRotation(); // Поворачиваем блока прямо

      this.updatePicture({
        load: 'url',
        prepare: _.bind(function($pic) {
          this.initialCrop = {
            top: parseInt(this.$el.css('top')) - Math.round(this.model.get('cropY') * this.model.get('scale')),
            left: parseInt(this.$el.css('left')) - Math.round(this.model.get('cropX') * this.model.get('scale')),
          };

          $pic.css({
            width: Math.round(this.model.get('originalW') * this.model.get('scale')),
            height: Math.round(this.model.get('originalH') * this.model.get('scale')),
            top: -Math.round(this.model.get('cropY') * this.model.get('scale')),
            left: -Math.round(this.model.get('cropX') * this.model.get('scale')),
          });

          this.showBackPic($pic);
        }, this),
        complete: _.bind(function() {
          this.frame.enableMouseProceeding();

          this.proportional = false;

          // Убираем класс, чтобы картинку можно было таскать при залоченном виджете.
          // Это не ломает запрещение таскание блока, т.к. таскание блокируется через флаг this.disableDragging,
          // который ставится при входе в кроп-мод.
          if (this.isLocked()) this.$el.removeClass('no-drag');

          this.bindPictureDrag();

          this.frame.setConstraints(this.$backpic);
          this.originalLoaded = true;
          this._modeTransition = false;

          this.trigger('modechanged transitionmode', this.cropmode, this._modeTransition);
        }, this),
      });
    }
  },

  // ограниченный кроп-мод, в котором всегда показывается бэкпик,
  // бэкпик обрезается фреймом и при ресайзе
  // картинки она ведет себя также как и в кроп-моде.
  enter_constrained_crop_mode: function() {
    if (this.in_constrained_crop_mode || !this.model.get('picture')) {
      return;
    }

    this.in_constrained_crop_mode = true;

    this.proportional = false;

    this.originalLoaded = false;

    this.workspace.trigger('loading_original_picture');

    this.$upload_button.addClass('uploading');

    this.updatePicture({
      load: 'url',

      skipAnimation: true,

      prepare: function($pic) {
        // для того, чтобы определить
        // this.initialCrop для вложенного виджета картинки,
        // надо найти координаты влож. виджтеа в воркспейсе страницы.

        // бокс внутриблокового воркспейса содержит
        // свои координаты относительно
        // воркспейса страницы.
        var workspace_box = this.workspace.get_box_data();

        this.initialCrop = {
          left:
            workspace_box.left_abs +
            parseInt(this.$el.css('left')) -
            Math.round(this.model.get('cropX') * this.model.get('scale')),
          top:
            workspace_box.top_abs +
            parseInt(this.$el.css('top')) -
            Math.round(this.model.get('cropY') * this.model.get('scale')),
        };

        $pic.css({
          width: Math.round(this.model.get('originalW') * this.model.get('scale')),
          height: Math.round(this.model.get('originalH') * this.model.get('scale')),
          top: -Math.round(this.model.get('cropY') * this.model.get('scale')),
          left: -Math.round(this.model.get('cropX') * this.model.get('scale')),
        });

        this.frame.constraints = {
          top: this.initialCrop.top,
          left: this.initialCrop.left,
          width: Math.round(this.model.get('originalW') * this.model.get('scale')),
          height: Math.round(this.model.get('originalH') * this.model.get('scale')),
        };
      }.bind(this),

      complete: function() {
        this.originalLoaded = true;

        this.workspace.trigger('loading_original_picture');

        this.$upload_button.removeClass('uploading');
      }.bind(this),
    });
  },

  leaveCropMode: function(noSave) {
    // Защищает от двойного клика по выходу из кропа
    // Но при этом позволяет выйти, если мы находимся в процессе (transitionmode) входа в кроп режим
    if (!this.cropmode || !this.model.get('picture')) return;

    if (this.in_constrained_crop_mode) {
      this.leave_crop_mode_to_constrained_crop_mode(noSave);

      return;
    }

    // При входе в кроп-мод класс убирался,
    // чтобы картинку можно было таскать при залоченном виджете.
    if (this.isLocked()) this.$el.addClass('no-drag');

    this.hideBackPic();
    this.unbindPictureDrag();

    this.disableDragging = false;
    this.proportional = true;
    this.frame.constraints = null;

    this.transitionMode(false);

    this.frame.restoreRotation(); // Восстанавливаем поворот блока, если он был

    // из за скруглений при выходе из кропа у нас некрасиво показывается скругление
    // до тех пор пока рабочую кроповскую картинку не заменит уже откропнутсяс сервера
    // чтоюы жтого избежать мы при выходе из режима кропа
    // немного меняем формат отображения рабочей картинки кропа
    // и вместо того чтобы иметь большой див который находится внутри окошка content и кропается им (overflow:hidden)
    // мы делаем див размером с окошко content а внутри позиционируем фон
    // и тогда скругления и бордеры срабатают как надо на диве
    var imgPos = {
      top: this.$picture.position().top,
      left: this.$picture.position().left,
      width: this.$picture.width(),
      height: this.$picture.height(),
    };

    this.$picture.css({
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      'background-size': imgPos.width + 'px ' + imgPos.height + 'px',
      'background-position': imgPos.left + 'px ' + imgPos.top + 'px',
    });

    this.restoreFixedPosition(true);

    if (!noSave) {
      var self = this;

      this.ratio = this.model.get('cropW') / this.model.get('cropH');

      if (_.isEqual(this._beforeCropAttrs, this.model.attributes)) {
        this.updatePicture({
          complete: _.bind(function() {
            this.onLeavingCropMode();
          }, this),
        });

        return;
      }

      this.getFinalImage(function(error) {
        self.updatePicture({
          complete: self.onLeavingCropMode,
        });
      });
    } else {
      this.onLeavingCropMode();
    }
  },

  // когда надо выйте из главного кроп-мода,
  // но остаться в ограниченном кроп-моде.
  leave_crop_mode_to_constrained_crop_mode: function(noSave) {
    this.unbindPictureDrag();

    this._modeTransition = false;

    this.disableDragging = true;

    this.cropmode = false;

    this.trigger('modechanged transitionmode', this.cropmode, this._modeTransition);

    History.removeCustomHandlers();

    $('#main').css('overflow-y', 'auto');

    this.$el.removeClass('crop');

    this.hideBackPic();

    if (!noSave) {
      this.ratio = this.model.get('cropW') / this.model.get('cropH');

      if (this.isAnimated()) {
        this.model.save({}, { silent: true, skipHistory: true });
      } else {
        this.getFinalImage(
          function(error) {
            if (error) {
              console.log('w-picture getFinalImage error: ', error);
            }
          },
          {
            silent: true,
            skipHistory: true,
          }
        );
      }
    }
  },

  onLeavingCropMode: function() {
    this.$picture.css({
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    });

    this._modeTransition = false;

    if (this._waitingForRescale) {
      this._waitingForRescale = false;
      this.setScaledImage();
    }
  },

  // Вызывается, когда мы вышли из кроп-режима, ресайзим, а финальная картинка еще не подгружена
  onLeavingCropResize: function(box) {
    if (this.cropmode) return;

    var s = box.width / this.model.get('cropW');

    this.$picture.css({
      left: -this.model.get('cropX') * s,
      top: -this.model.get('cropY') * s,
      width: this.model.get('originalW') * s,
      height: this.model.get('originalH') * s,
    });
  },

  toggleCropMode: function() {
    if (this.isVector()) return; // векторные картинки нельзя кропить

    this.trigger('crop_click');
  },

  toggleFWPosMode: function() {
    if (this.isVector()) return; // векторные картинки нельзя кропить

    this.trigger('fwpos_click');
  },

  onDblClick: function() {
    var isStretchBlock = this.model.get('is_full_width') || this.model.get('is_full_height');

    if (isStretchBlock) {
      this.toggleFWPosMode();
    } else {
      this.toggleCropMode();
    }
    BlockClass.prototype.onDblClick.apply(this, arguments);
  },

  enterFWPosMode: function() {
    if (this.fwposmode || !this.model.get('picture')) return;

    this.disableDragging = true;

    this.fwposmode = true;

    this.$el.addClass('fwpos');

    this.$el.on('dragend', this.dragFWPosPictureStop);
    this.$el.on('dragstart', this.dragFWPosPictureStart);
    this.$el.on('drag', this.dragFWPosPicture);
  },

  leaveFWPosMode: function() {
    // Защищает от двойного клика по выходу из кропа
    if (!this.fwposmode || !this.model.get('picture')) return;

    this.disableDragging = false;

    this.fwposmode = false;

    this.$el.removeClass('fwpos');

    this.$el.off('dragend', this.dragFWPosPictureStop);
    this.$el.off('dragstart', this.dragFWPosPictureStart);
    this.$el.off('drag', this.dragFWPosPicture);
  },

  dragFWPosPictureStart: function(event, drag) {
    var isFullHeight = this.model.get('is_full_height'),
      isFullWidth = this.model.get('is_full_width');

    this.frame.disableMouseProceeding();

    this.$el.addClass('grabbing');

    // в режиме псевдокропа оставляем возможность такскать изображение:
    // для режима full width - по оси y
    // для режима full height - по оси x
    if (isFullWidth) {
      drag.prev_offsetY = drag.offsetY;
    }
    if (isFullHeight) {
      drag.prev_offsetX = drag.offsetX;
    }
  },

  // функция обрабатывает оба параметра и fwpos и fhpos
  dragFWPosPicture: function(event, drag) {
    var isFullHeight = this.model.get('is_full_height'),
      isFullWidth = this.model.get('is_full_width'),
      fwpos = this.model.get('fwpos'),
      fhpos = this.model.get('fhpos');

    if (isFullWidth && drag.prev_offsetY === undefined) {
      this.dragFWPosPictureStart(event, drag);
    }

    if (isFullHeight && drag.prev_offsetX === undefined) {
      this.dragFWPosPictureStart(event, drag);
    }

    if (isFullWidth) {
      fwpos = fwpos == undefined ? 50 : fwpos;

      fwpos -= (drag.offsetY - drag.prev_offsetY) / 5;

      fwpos = Math.min(Math.max(fwpos, 0), 100);

      this.model.set({ fwpos: fwpos });

      drag.prev_offsetY = drag.offsetY;

      drag.last_fwpos = fwpos;
    }

    if (isFullHeight) {
      fhpos = fhpos == undefined ? 50 : fhpos;

      fhpos -= (drag.offsetX - drag.prev_offsetX) / 5;

      fhpos = Math.min(Math.max(fhpos, 0), 100);

      this.model.set({ fhpos: fhpos });

      drag.prev_offsetX = drag.offsetX;

      drag.last_fhpos = fhpos;
    }
  },

  dragFWPosPictureStop: function(event, drag) {
    var isFullHeight = this.model.get('is_full_height'),
      isFullWidth = this.model.get('is_full_width');

    this.frame.enableMouseProceeding();

    if (isFullWidth && drag.last_fwpos != undefined) {
      this.model.save({ fwpos: drag.last_fwpos });
    }

    if (isFullHeight && drag.last_fhpos != undefined) {
      this.model.save({ fhpos: drag.last_fhpos });
    }

    this.$el.removeClass('grabbing');
  },

  // Режим между кропом и обычным, когда например картинка оригинала для кропа не подгрузилась
  transitionMode: function(crop) {
    this._modeTransition = true;

    this.disableDragging = crop;

    this.cropmode = crop;
    this.trigger('modechanged transitionmode', this.cropmode, this._modeTransition);

    if (crop) {
      this._scaledimageXHR && this._scaledimageXHR.abort();

      this.frame.disableMouseProceeding();
      this.$el.addClass('crop');
      this.model.off('change', this.onModelChange);
      History.setEmptyHandlers();
      $('#main').css('overflow-y', 'hidden');
    } else {
      this.$el.removeClass('crop');
      this.model.on('change', this.onModelChange);
      History.removeCustomHandlers();
      $('#main').css('overflow-y', 'auto');
    }
  },

  onCropResize: function(box) {
    if ((!this.cropmode && !this.in_constrained_crop_mode) || this._modeTransition) {
      return;
    }

    var initialCrop = this.initialCrop;

    if (this.in_constrained_crop_mode) {
      // в-картинки располагается во внутриблоковом
      // воркспейсe страницы, поэтому
      // координаты виджета будут относительно этого воркспейса.
      // а нам надо знать топ и лефт в-картинки
      // относительно воркспейса страницы.

      var workspaceBox = this.workspace.get_box_data();

      // внутриблоковый воркспейс знает свои координаты относительно
      // воркспейса страницы.
      var crop = {
        left: workspaceBox.left_abs + box.left - initialCrop.left,
        top: workspaceBox.top_abs + box.top - initialCrop.top,
      };
    } else {
      crop = {
        left: box.left - initialCrop.left,
        top: box.top - initialCrop.top,
      };
    }

    this.$picture.css({
      left: -crop.left,
      top: -crop.top,
    });

    var scale = this.model.get('scale');

    var s = function(x) {
      return x / scale;
    };

    this.model.set({
      cropX: s(crop.left),
      cropY: s(crop.top),
      cropW: Math.round(s(box.width)),
      cropH: Math.round(s(box.height)),
      ratio: (box.width / box.height).toFixed(5),
    });
  },

  showBackPic: function($pic) {
    this.hideBackPic();

    $pic = $pic || this.$picture;

    this.$backpic = $pic
      .clone()
      .css({
        position: 'absolute',
        opacity: 0.5,
        'z-index': (this.$content.css('z-index') || this.model.get('z')) - 1, // для хотспота важно взять сначала зиндекс не из модели картинки, а прямо из css (там 9999 для хотспота и для картинки внутри хоспота когда кроп режим)
      })
      .css(this.initialCrop)
      .addClass('backpic')
      .hide()
      .appendTo(this.$el.closest('.workspace'))
      .fadeIn(200);

    this.$backpic.on('dblclick', this.toggleCropMode);
  },

  hideBackPic: function() {
    this.$el
      .closest('.workspace')
      .find('.backpic')
      .animate({ opacity: 0 }, 200, function() {
        $(this).remove();
      });
  },

  bindPictureDrag: function() {
    this.$el.add(this.$backpic).on('dragend', this.dragCropPictureStop);
    this.$el.add(this.$backpic).on('dragstart', this.dragCropPictureStart);
    this.$el.add(this.$backpic).on('drag', this.dragCropPicture);
  },

  unbindPictureDrag: function() {
    this.$el.add(this.$backpic).off('dragend', this.dragCropPictureStop);
    this.$el.add(this.$backpic).off('dragstart', this.dragCropPictureStart);
    this.$el.add(this.$backpic).off('drag', this.dragCropPicture);
  },

  dragCropPictureStart: function(event, drag) {
    var el = _.bind(this._css, this.$el);
    var bp = _.bind(this._css, this.$backpic);

    drag.offset = {
      back: {
        top: bp('top'),
        left: bp('left'),
        width: bp('width'),
        height: bp('height'),
      },
      el: {
        top: el('top'),
        left: el('left'),
        width: el('width'),
        height: el('height'),
      },
    };

    if (this.has_parent_block) {
      // то координаты драга надо скорректировать,
      // т.к. они относительно в-воркспейса,
      // а не воркспейсастраницы.

      var workspace_box = this.workspace.get_box_data();

      drag.offset.el.top += workspace_box.top_abs;
      drag.offset.el.left += workspace_box.left_abs;
    }

    this.frame.disableMouseProceeding();

    this.$el.add(this.$backpic).addClass('grabbing');
  },

  dragCropPicture: function(event, drag) {
    if (!drag.offset) {
      this.dragCropPictureStart(event, drag);
    }

    var backpic = {
      top: drag.offset.back.top + drag.deltaY,
      left: drag.offset.back.left + drag.deltaX,
    };

    if (backpic.top > drag.offset.el.top) {
      backpic.top = drag.offset.el.top;
    }
    if (backpic.left > drag.offset.el.left) {
      backpic.left = drag.offset.el.left;
    }
    if (backpic.left + drag.offset.back.width < drag.offset.el.left + drag.offset.el.width) {
      backpic.left = drag.offset.el.left + drag.offset.el.width - drag.offset.back.width;
    }
    if (backpic.top + drag.offset.back.height < drag.offset.el.top + drag.offset.el.height) {
      backpic.top = drag.offset.el.top + drag.offset.el.height - drag.offset.back.height;
    }

    this.$backpic.css({
      top: backpic.top,
      left: backpic.left,
    });

    this.$picture.css({
      top: backpic.top - drag.offset.el.top, // drag.offset.pic.top  + drag.deltaY,
      left: backpic.left - drag.offset.el.left,
    });
  },

  dragCropPictureStop: function(event, drag) {
    this.initialCrop = {
      top: parseInt(this.$backpic.css('top')),
      left: parseInt(this.$backpic.css('left')),
    };

    this.frame.enableMouseProceeding();
    this.frame.setConstraints(this.$backpic);

    var pic = _.bind(this._css, this.$picture);

    var s = parseFloat(this.model.get('scale'), 10);

    this.model.set({
      cropX: Math.round(-pic('left') / s),
      cropY: Math.round(-pic('top') / s),
    });

    this.$el.add(this.$backpic).removeClass('grabbing');
  },

  setActualScale: function(params) {
    this.storeFixedPosition();

    params = params || {};

    var k = params.retina ? 2 : 1;

    this._preload(
      'url',
      _.bind(function($pic) {
        var pic = {
          picture: this.model.get('picture'),
        };

        var pos = {
          x: parseInt(this.$el.css('left')) + this.model.get('w') / 2 - this.model.get('originalW') / 2 / k,
          y: parseInt(this.$el.css('top')) + this.model.get('h') / 2 - this.model.get('originalH') / 2 / k,
          w: this.model.get('originalW') / k,
          h: this.model.get('originalH') / k,
        };

        var crop = {
          x: 0,
          y: 0,
        };

        pic.picture.finalUrl = this.model.get('picture').url;
        pic.picture.final2xUrl = pic.picture.finalUrl;
        pic.picture.unscaledUrl = pic.picture.finalUrl;
        pic.picture.effect = '';

        var original = {};
        original.w = this.model.get('originalW');
        original.h = this.model.get('originalH');

        var $content = this.$picture.parent();

        this.updateSettings($pic);

        $pic
          .css({ opacity: 0 })
          .appendTo($content)
          .animate(
            { opacity: 1 },
            200,
            _.bind(function() {
              this.$picture = $pic;
              $content
                .children()
                .not(':last')
                .remove();
              this.updateSettings();
            }, this)
          );

        this.$el.animate(
          {
            top: pos.y,
            left: pos.x,
            width: pos.w,
            height: pos.h,
          },
          _.bind(function() {
            this.model.set(
              _.extend(
                {
                  cropX: crop.x,
                  cropY: crop.y,
                  cropW: pos.w * k,
                  cropH: pos.h * k,
                  scale: 1 / k,
                  ratio: pos.w / pos.h,
                },
                pos,
                pic
              )
            );

            this.restoreFixedPosition();

            this.model.save();
          }, this)
        );
      }, this)
    );
  },

  rescale: function(s, options) {
    options = options || {};

    var el = _.bind(this._css, this.$el);
    var func = options.smooth ? 'animate' : 'css';

    var nw = this.model.get('originalW') * s;
    var nh = this.model.get('originalH') * s;

    if (nw < el('width')) {
      nw = el('width');
      s = nw / this.model.get('originalW');
      nh = this.model.get('originalH') * s;
    }

    if (nh < el('height')) {
      nh = el('height');
      s = nh / this.model.get('originalH');
      nw = this.model.get('originalW') * s;
    }

    var c = el('width') / 2;
    var d = el('height') / 2;
    var oldScale = this.model.get('scale');

    var cropLeft = ((this.model.get('cropX') * oldScale + c) * s) / oldScale - c;
    var cropTop = ((this.model.get('cropY') * oldScale + d) * s) / oldScale - d;

    if (cropLeft + 2 * c > nw) {
      cropLeft = nw - 2 * c;
    }
    if (cropLeft < 0) {
      cropLeft = 0;
    }
    if (cropTop + 2 * d > nh) {
      cropTop = nh - 2 * d;
    }
    if (cropTop < 0) {
      cropTop = 0;
    }

    var nx;
    var ny;

    if (this.in_constrained_crop_mode) {
      var workspace_box = this.workspace.get_box_data();

      // this.initialCrop = {
      // 	left : workspace_box.left_abs + parseInt(this.$el.css('left')) - Math.round(this.model.get('cropX') * this.model.get('scale')),
      // 	top  : workspace_box.top_abs + parseInt(this.$el.css('top'))  - Math.round(this.model.get('cropY') * this.model.get('scale'))
      // };

      nx = workspace_box.left_abs + el('left') - cropLeft;
      ny = workspace_box.top_abs + el('top') - cropTop;
    } else {
      nx = el('left') - cropLeft;
      ny = el('top') - cropTop;
    }

    this.$backpic[func]({
      left: nx,
      top: ny,
      width: nw,
      height: nh,
    });

    this.initialCrop = {
      left: nx,
      top: ny,
    };

    this.$picture[func]({
      left: -cropLeft,
      top: -cropTop,
      width: nw,
      height: nh,
    });

    // if (this.in_constrained_crop_mode) {

    // 	this.$backpic_for_constrained_crop_mode
    // 		[func](
    // 			{
    // 				left: -cropLeft,
    // 				top: -cropTop,
    // 				width: nw,
    // 				height: nh
    // 			}
    // 		);

    // }

    this.model.set(
      {
        scale: s,
        cropX: cropLeft / s,
        cropY: cropTop / s,
        cropW: Math.round(this.model.get('w') / s),
        cropH: Math.round(this.model.get('h') / s),
      },
      options
    );

    this.frame.setConstraints(this.$backpic);
  },

  fill: function() {
    if (!this.cropmode || this._modeTransition) return;

    var x = parseInt(this.$el.css('left'));
    var y = parseInt(this.$el.css('top'));
    var h = parseInt(this.$el.height());
    var w = parseInt(this.$el.width());

    var crop = {};
    var scale = null;
    var pic = {};

    var origin_ratio = this.model.get('originalW') / this.model.get('originalH');

    if (this.model.get('ratio') > origin_ratio) {
      scale = w / this.model.get('originalW');
      crop = {
        cropX: 0,
        cropY: Math.round((this.model.get('originalH') - h / scale) / 2),
        cropW: this.model.get('originalW'),
        cropH: Math.round(h / scale),
      };

      pic = {
        width: w,
        height: Math.round(w / origin_ratio),
        top: -Math.round(crop.cropY * scale),
        left: 0,
      };
    } else {
      scale = h / this.model.get('originalH');
      crop = {
        cropX: Math.round((this.model.get('originalW') - w / scale) / 2),
        cropY: 0,
        cropW: Math.round(w / scale),
        cropH: this.model.get('originalH'),
      };

      pic = {
        width: Math.round(h * origin_ratio),
        height: h,
        top: 0,
        left: -Math.round(crop.cropX * scale),
      };
    }

    this.model.set(
      _.extend(
        {
          x: x,
          y: y,
          w: w,
          h: h,
          scale: scale.toFixed(15),
        },
        crop
      )
    );

    this.redrawPosition(this.model, { forceRedraw: false });

    this.$picture.animate(pic);

    this.$backpic.animate(
      {
        width: pic.width,
        height: pic.height,
        top: y + pic.top,
        left: x + pic.left,
      },
      _.bind(function() {
        var bp = _.bind(this._css, this.$backpic);

        this.initialCrop = {
          top: bp('top'),
          left: bp('left'),
        };

        this.frame.setConstraints(this.$backpic);
      }, this)
    );
  },

  fit: function() {
    if (!this.cropmode || this._modeTransition) return;

    var x = parseInt(this.$el.css('left'));
    var y = parseInt(this.$el.css('top'));
    var h = parseInt(this.$el.height());
    var w = parseInt(this.$el.width());

    var crop = {
      cropX: 0,
      cropY: 0,
      cropW: this.model.get('originalW'),
      cropH: this.model.get('originalH'),
    };
    var el = {
      left: x,
      top: y,
      width: w,
      height: h,
    };
    var scale = null;
    var pic = {};

    var origin_ratio = this.model.get('originalW') / this.model.get('originalH');

    if (this.model.get('ratio') < origin_ratio) {
      scale = w / this.model.get('originalW');

      pic = {
        width: w,
        height: Math.round(w / origin_ratio),
        top: Math.round((h - w / origin_ratio) / 2),
        left: 0,
      };

      el.top = y + pic.top;
      el.height = pic.height;
    } else {
      scale = h / this.model.get('originalH');

      pic = {
        width: Math.round(h * origin_ratio),
        height: h,
        top: 0,
        left: Math.round((w - h * origin_ratio) / 2),
      };

      el.left = x + pic.left;
      el.width = pic.width;
    }

    this.$picture.animate(pic);

    this.$backpic.animate(
      {
        width: pic.width,
        height: pic.height,
        top: y + pic.top,
        left: x + pic.left,
      },
      _.bind(function() {
        var bp = _.bind(this._css, this.$backpic);

        this.initialCrop = {
          top: bp('top'),
          left: bp('left'),
        };

        this.$el.css(el);
        this.$picture.css({
          top: 0,
          left: 0,
        });

        this.model.set(
          _.extend(
            {
              x: el.left,
              y: el.top,
              w: el.width,
              h: el.height,
              scale: scale.toFixed(15),
              ratio: origin_ratio,
            },
            crop
          )
        );

        this.frame.setConstraints(this.$backpic);
      }, this)
    );
  },

  setControls: function() {
    if (this.isVector()) this.controls = this.vector_controls;
    else if (this.isAnimated()) this.controls = this.animated_controls;
    else this.controls = this.initial_controls;
  },

  changePicWithoutRescale: function(pictureData, options) {
    if (!this.isVector(pictureData.picture.url))
      pictureData.picture.unscaledUrl = pictureData.picture.coalesceUrl || pictureData.picture.url;

    var data = {
      picture: pictureData.picture,
      originalW: pictureData.size.width,
      originalH: pictureData.size.height,
    };

    this.model.set(data, { silent: true });

    this.trigger('picture_added');
    options.first = true;
    this.fixSize(options);

    // Апдейтим вьюпорты новой картинкой
    _.each(
      Viewports.viewport_list,
      function(viewport) {
        if (_.isEmpty(this.model.get(viewport)) && _.isEmpty(this.model[viewport])) return;

        this.model.set(data, { viewport: viewport.split('viewport_')[1], silent: true });
      },
      this
    );

    // у вложенных виджетов нет панели в виджетбаре, которая
    // при hide вызовет this.model.save(), поэтому запускаем вызовет this.model.save() вручную.
    if (this.has_parent_block) {
      this.model.save();

      // после сета при вызове fixSize() выше,
      // во внутриблоковом воркспейсе запускались сеты
      // на установку новой высоты воркспейса,
      // сеты новых позиций, у каждого видимого вложенного виджета.
      // все это побочное добро надо сохранить без добавления в историю.
      var changeset = {};

      changeset['tip_w'] = this.workspace.block.model.get('tip_w');
      changeset['tip_h'] = this.workspace.block.model.get('tip_h');

      this.workspace.block.model.save(changeset, {
        patch: true,
        skipHistory: true,
      });

      this.workspace.blocks.forEach(function(block) {
        block.saveBox({
          patch: true,
          skipHistory: true,
        });
      });
    }
  },

  renewPicture: function(pictureData, options) {
    this.changePicture(pictureData, options);
    delete this.effects;
    this.cachedEffects = [];
  },

  changePicture: function(pictureData, options) {
    var old = this.model.attributes;
    var viewport = this.model.getViewport();
    var isFullWidth = this.isFullWidth();
    var isFullHeight = this.isFullHeight();

    options = _.extend(options || {}, { isNew: true });

    // картинкам-гифкам внутри хотспота можно и нужно скейлиться, но обычным картинкам-гифкам нельзя.
    if ((!old.picture && !old.scale) || (!this.has_parent_block && pictureData.picture.type == 'gif')) {
      return this.changePicWithoutRescale(pictureData, options);
    }

    var self = this;

    var calcPictureParams = function(oldData) {
      oldData.ratio = oldData.ratio || oldData.w / oldData.h;
      var newWidth, newHeight, _new;

      if (isFullWidth) {
        newWidth = oldData.full_width_initial_w;
        newHeight = oldData.full_width_initial_w / oldData.ratio;
      } else if (isFullHeight) {
        newHeight = oldData.full_height_initial_h;
        newWidth = oldData.full_height_initial_h * oldData.ratio;
      } else {
        newWidth = oldData.w;
        newHeight = oldData.h;
      }
      _new = {
        originalW: pictureData.size.width,
        originalH: pictureData.size.height,
        w: newWidth,
        h: newHeight,
        ratio: oldData.ratio,
        x: oldData.x,
        y: oldData.y,
      };

      if (pictureData.size.width / pictureData.size.height > parseFloat(oldData.ratio)) {
        _new.scale = _new.h / _new.originalH;
        _new.cropY = 0;
        _new.cropH = _new.originalH;
        _new.cropX = Math.max(0, (_new.originalW - _new.w / _new.scale) / 2);
        _new.cropW = _new.w / _new.scale;
      } else if (pictureData.size.width / pictureData.size.height < parseFloat(oldData.ratio)) {
        _new.scale = _new.w / _new.originalW;
        _new.cropX = 0;
        _new.cropY = Math.max(0, (_new.originalH - _new.h / _new.scale) / 2);
        _new.cropW = _new.originalW;
        _new.cropH = _new.h / _new.scale;
      } else {
        _new.scale = _new.w / _new.originalW;
        _new.cropX = 0;
        _new.cropY = 0;
        _new.cropW = _new.originalW;
        _new.cropH = _new.originalH;
      }

      _new.picture = _.clone(pictureData.picture);

      if (!self.isVector(pictureData.picture.url)) {
        _new.picture.unscaledUrl = pictureData.picture.coalesceUrl || pictureData.picture.url;
        _new.picture.effects = '';
        if (old.picture && old.picture.effect) _new.picture.effect = old.picture.effect;
      }

      return _new;
    };

    var newData = calcPictureParams(old);

    this.model.set(newData, { silent: true, toHistory: options.toHistory });
    if (this.isVector()) {
      this.model.set({ pic_color: '', pic_opacity: 1 }, { silent: true });

      return this.fixSize();
    }

    if (this.isAnimated()) {
      // если гифка, то не ресайзим на сервере.
      // во вьювере будет использоваться оригинал.

      // т.к. мы не запускаем getFinalImage,
      // то при сейве изменений аттрибута picture в модели не будет
      // и после загрузки не выстрелит toggle_loaded,
      // поэтому эмулируем это событие.
      this.model.save({}, options);
      this.model.trigger('change:picture');
    } else {
      options.viewport = viewport;

      this.getFinalImage(
        _.bind(function() {
          this.updatePicture.apply(this, arguments);

          // Для остальных вьюпортов обновим финальную картинку
          var viewportsFinished = [];
          var needSave = false;
          var model = this.model;

          var finishViewport = function(i, notfound) {
            viewportsFinished[i] = true;
            if (_.all(viewportsFinished) && needSave) {
              model.save({}, { skipHistory: true, first: true });

              var new_viewport = this.model.getViewport();
              if (new_viewport !== viewport) {
                this.updatePicture(); // Могли уже переключиться с исходного вьюпорта, нужно обновить картинку и в новом
              }
            }
          }.bind(this);

          var viewports = _.without(
            _.union(WidgetModel.viewports, ['viewport_default']),
            'viewport_' + this.model.getViewport()
          );
          _.each(
            viewports,
            function(viewport_key, i) {
              viewportsFinished[i] = false;
              if (_.isEmpty(this.model[viewport_key])) return finishViewport(i, true);

              var data = calcPictureParams(_.extend({}, this.model.attributes, this.model[viewport_key]));

              this.requestFinalImage(
                _.extend({}, this.model.attributes, data),
                _.bind(function(newPicData) {
                  _.extend(data.picture, newPicData);

                  this.model.set(data, { viewport: viewport_key.split('viewport_')[1], silent: true });
                  needSave = true;
                  finishViewport(i);
                }, this)
              );
            },
            this
          );
        }, this),
        options
      );
    }
  },

  redraw: function(model, options) {
    options = options || {};
    // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
    // даже строго противопоказана, например для свойство анимаций
    if (!this.checkNeedRedraw(model, options)) return;

    this.proportional = this.getPictureUrl();

    options = options || {};
    var wasFullWidthSet = model.hasChanged('is_full_width') && model.get('is_full_width') && !options.viewportChange,
      wasFullWidthReset = model.hasChanged('is_full_width') && !model.get('is_full_width') && !options.viewportChange,
      wasFullHeightSet = model.hasChanged('is_full_height') && model.get('is_full_height') && !options.viewportChange,
      wasFullHeightReset =
        model.hasChanged('is_full_height') && !model.get('is_full_height') && !options.viewportChange;

    var fixDims = function() {
      // При сбросе растягивания вместе с длиной восстанавливаем высоту, чтобы восстановить пропрорции
      if (wasFullWidthReset) {
        // При сбросе растягивания slient: false, чтобы скорректированные размеры отрисовались
        this.model.set({ h: this.model.get('w') / this.model.get('ratio') }, { silent: !!wasFullWidthSet });
      }

      if (wasFullHeightReset) {
        this.model.set({ w: this.model.get('h') * this.model.get('ratio') }, { silent: !!wasFullHeightSet });
      }
    }.bind(this);

    if (!this.isDragging && !this.isScaling) {
      this.redrawPosition(model, _.extend(options, { forceRedraw: false }));

      if (wasFullWidthSet || wasFullWidthReset || wasFullHeightSet || wasFullHeightReset) {
        // При изменении режима растягивани мы должны пересчитать scale,
        // иначе далее все будет считаться неправильно
        fixDims();
        this.updatePicture();
        this.changeControls();
      }

      if (wasFullWidthReset || wasFullHeightReset) {
        this.setScaledImage(null, { skipHistory: true });
      }
    }

    if (model.hasChanged('picture')) {
      if (options.undo || options.redo) {
        this.onHistoryPictureChange(model, options);
      } else if (options.viewportChange || options.socketUpdate) {
        this.updatePicture({ skipAnimation: true });
      } else {
        this.onPictureChange(model, options);
        if (this.isVector()) this.initVector();
      }
    } else if (this.has_parent_block && (options.undo || options.redo)) {
      // В случае Undo/Redo ресайза подсказки в хотспоте с картинкой
      // model.hasChanged('picture') будет false,
      // т.к. откатывали групповое сохранение,
      // а ссылка на новую картинку сохранялась не через него,
      // Поэтому нам надо выставить верный кроп картинке.
      // Для картинки в хотспоте это лучше всего сделать так.
      this.in_constrained_crop_mode = false;
      this.enter_constrained_crop_mode();
    }

    this.ratio = model.get('ratio');
  },

  redrawPosition: function(model, options) {
    model = model || this.model;

    BlockCommonPicture.prototype.redrawPosition.apply(this, arguments);

    if (
      model.hasChanged('border_size') ||
      model.hasChanged('border_color') ||
      model.hasChanged('border_radius') ||
      model.hasChanged('border_radius_max') ||
      model.hasChanged('opacity') ||
      model.hasChanged('fwpos') ||
      model.hasChanged('fhpos')
    ) {
      this.updateSettings();
    }
  },

  setPreviewImage: function(url, options) {
    options = options || {};

    var cb = options.success || $.noop,
      eb = options.error || $.noop,
      self = this;

    this.previewUrl = url;

    this._loadingPic &&
      this._loadingPic
        .off('load')
        .off('error')
        .removeAttr('src')
        .remove();
    this._loadingPic = undefined;

    if (!url) {
      this.$preview && this.$preview.detach();
      cb();
      return;
    }

    this._loadingPic = $('<img>')
      .addClass('preload_image')
      .appendTo('body')
      .attr('src', url)
      .on('load', function() {
        if (self.previewUrl === url) {
          if (!self.$preview) {
            self.$preview = $('<div>');
          }
          self.$preview
            .css({
              'background-image': 'url("' + url + '")',
              'background-size': 'cover',
              'background-position': '50% 50%',
            })
            .appendTo(self.$el.find('.content'));
        }
        cb();
        $(this).remove();
      })
      .on('error', function() {
        eb();
        $(this).remove();
      });
  },

  updateSettings: function($picture) {
    $picture = $picture || this.$picture;

    if (!$picture) return;

    var model = this.model;

    var fwpos = model.get('fwpos'),
      fhpos = model.get('fhpos');
    fwpos = fwpos == undefined ? 50 : fwpos;
    fhpos = fhpos == undefined ? 50 : fhpos;

    if (!this.isVector()) {
      $picture.css({
        'box-shadow':
          model.get('border_size') > 0
            ? 'inset 0 0 0 ' + model.get('border_size') + 'px #' + (model.get('border_color') || '000000')
            : 'none',
        'border-radius': (model.get('border_radius_max') ? '9999' : model.get('border_radius') || 0) + 'px',
        'background-position': fhpos + '% ' + fwpos + '%',
        // Чтобы сгладить эффект, когда видно на светлом фоне, что картинка вылезает на волос за светлую тень
        '-webkit-mask-image':
          model.get('border_size') > 0
            ? 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC)'
            : '',
      });

      this.$content.css('opacity', typeof model.get('opacity') === 'undefined' ? 1 : model.get('opacity'));
    } else {
      $picture.css({
        'box-shadow': 'none',
        'border-radius': '0',
      });

      this.$content.css('opacity', 1);
    }
  },

  onPictureChange: function(model, options) {
    var prev = this.model.previous('picture');
    var curr = this.model.get('picture');

    var isNew = options && options.isNew;

    if ((!curr || !prev || curr.url == prev.url) && !isNew) return;

    this.model.set({ pic_color: '', pic_opacity: 1 }, { silent: true });
  },

  onHistoryPictureChange: function(model, options) {
    var params = {};
    var prev = this.model.previous('picture');
    var curr = this.model.get('picture');

    // не нужно пересчитывать размер или делать всякие проверки если нужна дефолтная картинка
    if (!curr) return this.updatePicture();

    if (!prev && curr) {
      this.trigger('picture_added');
    }

    if (this._modeTransition && !this.cropmode) {
      params.complete = this.onLeavingCropMode;
    }

    if (this.has_parent_block) {
      // Так правильно делать в любом случае,
      // но было написано специалльно как фикс Undo/Redo загрузки гифки.
      this.in_constrained_crop_mode = false;
      this.enter_constrained_crop_mode();
    } else {
      this.updatePicture(params);
    }
  },

  setFinal: function() {
    this.fakingMode = false;
    this.getFinalImage(this.updatePicture, { silent: true });
  },

  changeControls: function() {
    this.setControls();
    this.updateControls();
  },

  preloadHiRes: function() {
    if (this.isVector()) return;

    this._preload('unscaledUrl');
    this._preload('url');
  },

  _preload: function(alias, callback) {
    var pic = this.model.get('picture');
    if (!pic) return;

    var url = pic[alias];

    var self = this;

    if (!url) {
      return callback && callback.apply(this);
    }

    if (this.isVector()) return this.loadVector({ success: callback });

    this._imgsPreload[alias] = $(new Image())
      .attr({ src: url })
      .one('load', function() {
        self._imgsPreload[alias] = false;

        if (callback) {
          var $img = $('<div/>').css('background-image', 'url(' + url + ')');
          if (!self.rendered) return;
          callback.apply(self, [$img, url]);
        }

        $(this).remove();
      });
  },

  onResizeStart: function(event, drag) {
    if (this.cropmode || this.isVector()) return;

    this._scaledimageXHR && this._scaledimageXHR.abort();

    if (!this._modeTransition) {
      this._getFinalRequest && this._getFinalRequest.abort();

      this.updatePicture({
        load: 'unscaledUrl',
      });
    }

    this.isScaling = true;
  },

  onResizeEnd: function(event, drag, options) {
    this.isScaling = false;

    var data = _.extend({ scale: 1.0 }, this.getBoxData());
    this.model.set(data, options);

    if (!this.getPictureUrl() || this.isVector()) return this.saveBox();

    if (this.cropmode) return;
    if (!this._modeTransition) {
      this.setScaledImage(null, _.omit(options, 'skipPersist'));
    } else {
      this._waitingForRescale = true;
    }
  },

  // Чтобы ресайз в режиме не кропа не восстанавливал фиксированную позицию
  restoreFixedPosition: function(force) {
    if (!force && this.cropmode) return;

    BlockCommonPicture.prototype.restoreFixedPosition.apply(this, arguments);
  },

  getBoxData: function() {
    var box = BlockCommonPicture.prototype.getBoxData.apply(this, arguments);

    if (this.model.get('cropW')) box.scale = (box.w / this.model.get('cropW')).toFixed(15);
    else if (box.scale !== undefined) box.scale = (1.0).toFixed(15);

    return box;
  },

  setScaledImage: function(callback, options) {
    var model_data = _.clone(this.model.attributes);

    var updateDefaultViewport = function(pictureData) {
      if (this.model.getViewport() != 'default' && _.isEmpty(this.model['viewport_default'].picture)) {
        var vpData = _.cloneWithObjects(_.extend({}, this.model.attributes, { picture: pictureData }));
        vpData.hidden = this.model['viewport_default'].hidden; // Сохраняем признак скрытия
        this.model.storeViewport('default', vpData);
      }
    }.bind(this);

    if (!this.model.get('picture') || !this.model.get('picture').url) return;

    options = options || {};

    options.viewport = this.model.getViewport();

    if (this.isVector() || this.isAnimated()) {
      updateDefaultViewport(model_data.picture);
      this.model.save({}, options);
      return this.updatePicture({ complete: callback });
    }

    var w = this.model.get('w');

    this._scaledimageXHR = $.ajax({
      type: 'POST',
      data: model_data,
      url: this.RESCALE_URL,
      success: _.bind(function(data) {
        if (w != this.model.get('w')) return; // Сделали undo или redo или ручками вернули размер к исходной картинке пока шел ресайз

        var newPicture = _.extend({}, this.model.get('picture'), data);

        if (!newPicture.url) return; // Картинку могли заменить/удалить и вообще сделать много разных операций

        updateDefaultViewport(newPicture);

        this.model.save('picture', newPicture, options);
        this.updatePicture({ complete: callback });
      }, this),
    });

    return;
  },

  fixSize: function(params) {
    if (!this.model.get('originalW')) return;

    var w = parseInt(this.model.get('originalW'), 10);
    var h = parseInt(this.model.get('originalH'), 10);

    var blockW = this.model.get('w');
    var blockH = this.model.get('h');
    var ratioW = blockW / w;
    var ratioH = blockH / h;

    var new_w = blockW;
    var new_h = blockH;

    var position = {
      x: this.model.get('x'),
      y: this.model.get('y'),
    };

    if (this.has_parent_block) {
      // если виджет вложенный.

      // ширину нам менять не надо. она и так уже у блока
      // по ширине воркспейса как надо.
      // надо подстроить высоту только.
      new_h = h * ratioW;
    } else if (this.isVector() && this.isFullWidth()) {
      new_w = this.getFullWidthDims().w;
    } else if (this.isVector() && this.isFullHeight()) {
      new_h = this.getFullHeightDims().h;
    } else if ((Math.abs(1 - ratioW) < 0.05 && Math.abs(1 - ratioH) < 0.05) || (ratioW > 1.05 && ratioH > 1.05)) {
      // Если незначительные отклонения, то просто переопределим размеры блока точно под картинку
      // Или если картинка меньше блока по обеим сторонам

      new_w = w;
      new_h = h;

      position.x += blockW / 2 - w / 2;
      position.y += blockH / 2 - h / 2;
    } else {
      // Подстроим пропорции под меньшую сторону
      if (ratioW > ratioH) {
        new_w = w * ratioH;

        position.x += blockW / 2 - new_w / 2;
      } else {
        new_h = h * ratioW;

        position.y += blockH / 2 - new_h / 2;
      }
    }

    // Сохраним новые пропорции и соотношение
    this.model.set(
      {
        w: new_w,
        h: new_h,
        x: position.x,
        y: position.y,
        ratio: w / h,
        scale: new_w / w,
        cropX: 0,
        cropY: 0,
        cropW: w,
        cropH: h,
      },
      { first: params && params.first }
    );

    this.setScaledImage(
      _.bind(function() {
        if (this.isVector()) this.fixVectorScale();
      }, this),
      params
    );

    this.model.created = false;
  },

  fixVectorScale: function() {
    var saveData;
    var bbox = this.$svg.get(0).getBBox();
    var boxData = this.getBoxData();

    var scale = bbox.width / bbox.height;

    var blockScale = boxData.w / boxData.h;

    if (Math.abs(scale - blockScale) < 0.05) return;

    if (this.isFullWidth()) {
      saveData = {
        h: boxData.w / scale,
        originalW: this.model.get('full_width_initial_w'),
        originalH: this.model.get('full_width_initial_w') / scale,
      };
    } else if (scale > 1) {
      saveData = { h: boxData.h / scale, originalH: boxData.h / scale, ratio: scale };
    } else {
      saveData = { w: boxData.w * scale, originalW: boxData.w * scale, ratio: scale };
    }

    this.model.save(saveData, { skipHistory: true });
  },

  showIconLoader: function() {
    if (this.has_parent_block) {
      this.$upload_button.addClass('uploading');
    } else {
      BlockClass.prototype.showIconLoader.apply(this, arguments);
    }
  },

  hideIconLoader: function() {
    if (this.has_parent_block) {
      this.$upload_button.removeClass('uploading');
    } else {
      BlockClass.prototype.hideIconLoader.apply(this, arguments);
    }
  },

  deselect: function() {
    BlockClass.prototype.deselect.apply(this, arguments);

    this.leaveCropMode();
    this.leaveFWPosMode();
  },

  // вызывается каждый раз когда выделяем виджет(ы) на рабочей области
  // кликом, кликом с шифтом, рамкой выделения
  // (если в результате перечисленных манипуляций не оказывается ни одного виджета в выделении, тогда это событие не стреляет)
  onWorkspaceBlocksSelect: function(blocks) {
    BlockClass.prototype.onWorkspaceBlocksSelect.apply(this, arguments);

    // смотрим что у нас в текущем выделении текущий блок или еще что-то
    var isOnlyMeSelected = blocks && blocks.length == 1 && blocks[0] == this;

    // если мы находимся в режиме редактирования
    // и узнали что список текущих выделенных виджетов поменялся (например с шифтом щелкнули еще о одному)
    // тогда выходим из режима редактирования
    if (!isOnlyMeSelected) {
      this.leaveCropMode();
      this.leaveFWPosMode();
    }
  },

  isPictureEmpty: function() {
    return _.isEmpty(this.model.get('picture'));
  },

  destroy: function() {
    this.uploader && this.uploader.destroy();

    this.leaveCropMode();
    this.leaveFWPosMode();
    BlockClass.prototype.destroy.apply(this, arguments);
    this.hideIconLoader();

    this.model.off('change:picture', this.toggle_loaded);
    this.$upload_button && this.$upload_button.off('click');

    this.off();
    this.$el.off();
  },
});

var pictureFrame = BlockFrameClass.extend({
  maxwidth: 9999,
  maxheight: 9999,

  /**
   * Ресайз
   */
  onResize: function(event, drag) {
    var box = BlockFrameClass.prototype.onResize.apply(this, arguments);

    if (this.block.cropmode) {
      this.block.onCropResize(box);
    } else if (this.block._modeTransition) {
      this.block.onLeavingCropResize(box);
    }
  },

  setConstraints: function($el) {
    this.constraints = {
      top: parseInt($el.css('top')),
      left: parseInt($el.css('left')),
      width: parseInt($el.css('width')),
      height: parseInt($el.css('height')),
    };
  },
});

export default PictureBlock;
