module.exports = {["template-viewer-mag"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="mag">\n		<div class="mag-pages-container">\n			<div class="container disable-transitions">\n\n				<div class="blackout"></div>\n			</div>\n			<div class="above-pages-container"></div>\n		</div>\n\n		';
 if (hasStandaloneBottomArrow) {
__p+='\n			<div class="navigation-arrow bottom offscreen"><div class="icon sprite"></div></div>\n		';
}
__p+='\n\n\n		';
 if (isPreview) {
__p+='\n			<div class="mag-pages-viewport-device"></div>\n		';
}
__p+='\n	</div>\n\n';
}
return __p;
},}