<template>
  <div
    :class="{
      panel: true,
      'select-product-panel': true,
      'no-products-wrap': !thereAreProducts,
    }"
  >
    <div class="no-products-alert" v-if="!thereAreProducts">You have no active products to choose</div>
    <rm-select
      class="select-wrap"
      v-model="m.selected_product_id"
      :options="productsOptions"
      :arrowSize="`big`"
      :callback="onProductSelect"
      v-if="thereAreProducts"
    >
      <template v-slot:listitem="slotProps">
        <span
          :class="{
            'inactive-product': slotProps.option.value === slotProps.value && getCurrentProductActiveStatus(),
          }"
          :title="
            slotProps.option.value === slotProps.value && getCurrentProductActiveStatus() ? 'inactive product' : ''
          "
          >{{ slotProps.option.caption }}</span
        >
        <div class="point"></div>
      </template>
    </rm-select>
  </div>
</template>

<script>
import Panel from './panel.vue';
import RmSelect from '../../common/rm-select.vue';

export default {
  mixins: [Panel],
  components: { RmSelect },

  data: function() {
    var productsOptions = this.getProductsOptions();

    return {
      productsOptions,
    };
  },

  computed: {
    thereAreProducts() {
      return this.productsOptions && this.productsOptions.length > 1;
    },
    isCurrentProductInactive() {
      return this.getCurrentProductActiveStatus();
    },
  },

  methods: {
    getProductsOptions: function(productId) {
      var currentProductId = productId || this.raw_block.model.get('selected_product_id');
      return this.raw_block.workspace.eCommerceManager.getProductsLikeOptionsList(currentProductId);
    },

    getCurrentProductActiveStatus: function(productId) {
      var checkingProductId = productId || this.raw_block.model.get('selected_product_id');
      return !this.raw_block.workspace.eCommerceManager.isProductActive(checkingProductId);
    },

    /**
     * Вызывается при изменении продукта в селекте
     * Суть в том что есть состояние, когда текущий выбранный элемент
     * неактивный и тогда надо выкинуть его из списка
     * когда выбираем другой активный элемент
     */
    onProductSelect: function(option) {
      if (this.isCurrentProductInactive) {
        this.productsOptions = this.getProductsOptions(option.value);
        // this.isCurrentProductInactive = this.getCurrentProductActiveStatus(option.value);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import (reference) '../../../css/common/constants.less';

.select-product-panel {
  .inactive-product {
    color: @rm-danger-color;
  }
}
</style>
