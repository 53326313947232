<template>
  <div class="panel">
    <div class="param-caption">Rotate</div>
    <div class="input-wrapper" :class="{ negative: m.angle < 0 }">
      <span class="balance-deg">&deg;</span>
      <numeric-input
        :value="m.angle"
        :autofocus="true"
        :autosize="true"
        :wait="200"
        :min="-360"
        :max="360"
        dir="v"
        :speed="4"
        :shift-step="15"
        @keyup.enter.native="onEnter"
        @change="onInputChange"
      />
      <span class="deg">&deg;</span>
      <span class="balance-minus">-</span>
    </div>
    <div class="separator"></div>
    <div class="flip-h" @click="flipHorizontal"></div>
    <div class="flip-v" @click="flipVertical"></div>
    <div class="rotate90" @click="onRotate90Click"></div>
  </div>
</template>

<script>
import _ from '@rm/underscore';
import Panel from './panel.vue';
import NumericInput from '../../common/numeric-input.vue';

export default {
  mixins: [Panel],
  components: { NumericInput },
  data: function() {
    return {
      // Дефолтные значения панели, которых нет в дефолтных данных нового блока
      m: {
        angle: 0,
        flip_v: false,
        flip_h: false,
      },
    };
  },
  methods: {
    onEnter: function() {
      this.$parent.$emit('deselect');
    },
    onInputChange: function(val) {
      this.setAngle(val);
    },
    onRotate90Click: function() {
      this.setAngle((this.m.angle || 0) + 90);
    },
    onFirstFocus: function() {
      this.transparentSelection = false;
    },
    setAngle: function(angle) {
      // Зацикливаем угол в рамках +-360 градусов
      angle = angle >= 360 ? angle - 360 : angle;
      angle = angle <= -360 ? angle + 360 : angle;
      if (this.raw_block.workspace.spacesController) {
        var cos = Math.abs(Math.cos((this.m.angle / 180) * Math.PI)),
          sin = Math.abs(Math.sin((this.m.angle / 180) * Math.PI)),
          cosNew = Math.abs(Math.cos((angle / 180) * Math.PI)),
          sinNew = Math.abs(Math.sin((angle / 180) * Math.PI)),
          prevH = this.m.w * sin + this.m.h * cos,
          newH = this.m.w * sinNew + this.m.h * cosNew,
          newTop = this.m.y - newH / 2 + this.m.h / 2,
          restriction = this.raw_block.workspace.spacesController.getRestriction(this.raw_block.model, [
            this.raw_block.model,
          ]);
        if (!restriction.canMoveTop || newTop < restriction.top) {
          this.m.y += restriction.top - newTop;
        }
      }
      this.m.angle = angle;
    },
    flipVertical: function() {
      this.flip('vertical');
    },
    flipHorizontal: function() {
      this.flip('horizontal');
    },
    flip: function(direction) {
      var p_angle = (this.m.angle + 360) % 360; // Угол, приведенный к положительному
      var flip_v = this.m.flip_v;
      var flip_h = this.m.flip_h;
      var angle = this.m.angle;

      // Делаем "натуральный" флип. Т.е. флип всегда относительно абсолютных осей X или Y, проходящих
      // через центр виджета. (ось X всегда горизонтальна, а Y всегда вертикальна)
      if (p_angle <= 45 || (p_angle >= 135 && p_angle <= 225) || p_angle >= 315) {
        angle = -this.m.angle;
      } else if ((p_angle > 45 && p_angle < 135) || (p_angle > 225 && p_angle < 315)) {
        angle = 180 - this.m.angle;
      }
      if (direction === 'vertical') {
        flip_v = !this.m.flip_v;
      } else {
        flip_h = !this.m.flip_h;
      }

      var update = {
        angle: angle,
        flip_v: flip_v,
        flip_h: flip_h,
      };

      _.extend(this.m, update);
    },
  },
};
</script>
