<template>
  <div class="panel">
    <div class="title">Connect to stripe</div>
    <div class="connect-to-stripe">
      <rm-switcher
        :value="switcherPosition"
        :width="40"
        :height="24"
        colorOn="#0080FF"
        colorOff="#B8B8B8"
        :callback="switcherChange"
      ></rm-switcher>
    </div>
  </div>
</template>

<script>
import Panel from './panel.vue';
import RmSwitcher from '../../common/rm-switcher.vue';

export default {
  mixins: [Panel],
  components: { RmSwitcher },

  data: function() {
    var connectedToStripe = this.raw_block.workspace.eCommerceManager.isMagConnectedToStripe();
    return {
      connectedToStripe,
      loading: false, // флаг указывающий что идет процесс подключения/отключения
    };
  },

  computed: {
    switcherPosition: function() {
      return this.loading ? undefined : this.connectedToStripe;
    },
  },

  methods: {
    switcherChange: function(value) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (value) {
        this.raw_block.workspace.eCommerceManager
          .connectToStripe()
          .then(() => {
            this.connectedToStripe = true;
            this.raw_block.checkDisabledControls(true);
            this.raw_block.workspace.eCommerceManager.getProducts();
            this.loading = false;
          })
          .catch(error => {
            this.connectedToStripe = false;
            this.raw_block.checkDisabledControls(false);
            this.loading = false;
          });
      } else {
        this.raw_block.workspace.eCommerceManager
          .disconnectFromStripe()
          .then(data => {
            this.connectedToStripe = false;
            this.raw_block.checkDisabledControls(false);
            this.loading = false;
          })
          .catch(error => {
            this.connectedToStripe = true;
            this.raw_block.checkDisabledControls(true);
            this.loading = false;
          });
      }
    },
  },
};
</script>
