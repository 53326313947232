/**
 * Класс для тулбара с кнопками меню, шера, едит кнопки и самой панельки шера
 */
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/viewer/branding.tpl';

const BrandingClass = Backbone.View.extend({
  template: templates['template-viewer-branding'],

  initialize: function(params) {
    _.bindAll(this);

    _.extend(this, params);

    return this; // для вызова по цепочке
  },

  render: function() {
    this.setElement(this.template());
    this.$el.appendTo(this.$container);

    this.listenTo(this.mag, 'pageChanged', this.onPageChange);
    this.listenTo(this.mag, 'finalPageShown', this.hide);
    this.listenTo(this.mag, 'finalPageHidden', this.show);

    _.delay(
      function() {
        this.$el.removeClass('offscreen-initially');
      }.bind(this),
      800
    );

    return this; // для вызова по цепочке
  },

  onPageChange: function(page, params) {},

  hide: function() {
    this.$el.stop().fadeOut(200);
  },

  show: function() {
    this.$el.stop().fadeIn(200);
  },
});

export default BrandingClass;
