module.exports = {["template-constructor-control-hotspot-pin-settings"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="icon noarrow" data-alt="Pin Settings" data-alt-pos="left" data-alt-offset="9"></div>\n\n	<div class="panel '+
((__t=( Modernizr.isboxversion ? 'no-search' : '' ))==null?'':__t)+
'">\n\n		<div class="search-block">\n\n			<input class="search" type="text" placeholder="Search">\n\n\n			<div class="loupe">\n				<div class="rmpreloader hidden"><div class="arc"></div></div>\n			</div>\n\n		</div>\n\n\n		<div class="common-wrapper">\n\n\n			<div class="resizable-scroll-wrapper">\n				<div class="resizable-content-wrapper">\n					<div class="resizable-content">\n\n						<div class="rectangle-icon item">\n							<div class="line"></div>\n						</div>\n\n\n						<div class="circle-icon item">\n							<div class="circle"></div>\n						</div>\n\n					</div>\n				</div>\n				<div class="resizable-scroll"></div>\n			</div>\n\n\n			<div class="params-wrapper clearfix">\n\n				<div class="edit-item color-click-area" data-field="bg_color" data-field-dop="bg_opacity">\n					<div class="caption">Color</div>\n					<div class="opacity-grid"></div>\n					<div class="color-circle"></div>\n				</div>\n\n\n				<div class="edit-item combined separator" data-field="borders">\n					<div class="caption">Border</div>\n					<input spellcheck="false" type="text" value=""/>\n\n					<div class="edit-item border-color color-click-area" data-field="color" data-field-dop="opacity">\n						<div class="opacity-grid"></div>\n						<div class="color-circle"></div>\n					</div>\n				</div>\n\n				<div class="edit-item separator" data-field="tip_show_on">\n					<div class="caption">Show on</div>\n					<div class="switcher" data-active="click">\n						<div class="switcher-item" data-value="click">Click</div>\n						<div class="switcher-item" data-value="hover">Hover</div>\n					</div>\n				</div>\n\n			</div>\n\n\n		</div>\n\n\n		<div class="resize-handle-top"></div>\n		<div class="resize-handle-bottom"></div>\n\n		<div class="colorbox_container"></div>\n\n	</div>\n';
}
return __p;
},["template-constructor-control-hotspot-pin-settings-icon-item"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="icon-item item">\n		<div class="noun-icon" style="background-image: url('+
((__t=(icon.preview_url_42))==null?'':__t)+
');" '+
((__t=(icon.id ? 'data-id="' + icon.id + '"' : 'data-rm_id="' + icon.rm_id + '"'))==null?'':__t)+
' data-url="'+
((__t=(icon.icon_url))==null?'':__t)+
'">\n	</div>\n';
}
return __p;
},}