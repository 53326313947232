/**
 * Конструктор для контрола настроек виджета добавить в корзину
 */
import VueControlClass from '../vue-control';

const add_to_cart_settings = VueControlClass.extend({
  name: 'add_to_cart_settings',
  alt: 'Settings',
  panelComponent: 'panel-addtocart-settings',
});

export default add_to_cart_settings;
