/**
 * Конструктор для виджета текста
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/text_typography.tpl';
import FontSelector from '../helpers/font-selector';
import PreloadDesignImages from '../../common/preload-design-images';

const TextTypography = ControlClass.extend({
  name: 'text_typography', // должно совпадать с классом вьюхи

  className: 'control text_typography',

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_typography'];

    this.initControl(params);
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-text_typography');
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.$panel.on('click', this.closeAllPopups);

    this.fontselector = new FontSelector({ $parent: this.$panel, css: { left: -79, top: -33 } });
    this.fontselector.on('selectfont', this.fontChanged);

    this.params = (this.blocks && this.blocks[0].params) || {};

    this.$panel.on('click', '.linked', this.onLinkedClick);
    this.$panel.on('click', '.font-family-popup', this.onFontPopupClick);
    this.$panel.on('click', '.font-style-popup', this.onFontStylePopupClick);

    this.editFontSizeChangeValue = $(this.$panel.find('.font-size'))
      .RMNumericInput({
        min: this.params.minFontSize,
        max: this.params.maxFontSize,
        mouseSpeed: 4,
        onChange: this.onInputChanged,
      })
      .data('changeValue');

    this.editLineHeightChangeValue = $(this.$panel.find('.line-height'))
      .RMNumericInput({
        min: this.params.minLineHeight,
        max: this.params.maxLineHeight,
        mouseSpeed: 4,
        onChange: this.onInputChanged,
      })
      .data('changeValue');

    this.editLetterSpacingChangeValue = $(this.$panel.find('.letter-spacing'))
      .RMNumericInput({
        min: this.params.minLetterSpacing,
        max: this.params.maxLetterSpacing,
        mouseDir: 'h',
        useFloat: true,
        step: 0.2,
        shiftStep: 1,
        mouseSpeed: 4,
        onChange: this.onLetterSpacingChanged,
      })
      .data('changeValue');

    // случаем событие изменения стиля выделения, генерируется редактором
    this.blocks && this.blocks[0].on('selection_styles_changed', this.selectionStylesChanged);
    this.blocks && this.blocks[0].on('selection_styles_all_changed', this.selectionStylesAllChanged);

    // первоначально проставляем состояние контрола в зависимости от текущего стиля выделения в редакторе
    this.blocks && this.selectionStylesChanged(this.blocks[0].cur_selection_styles);
    this.blocks && this.selectionStylesAllChanged(this.blocks[0].cur_selection_styles_all);
  },

  unBindLogic: function() {
    this.$panel.off('click', this.closeAllPopups);

    this.fontselector && this.fontselector.off('selectfont', this.fontChanged);
    this.fontselector && this.fontselector.destroy();
    this.font_selector_visible = false;
    this.fontselector = null;

    this.$panel.off('click', '.linked', this.onLinkedClick);
    this.$panel.off('click', '.font-family-popup', this.onFontPopupClick);
    this.$panel.off('click', '.font-style-popup, .font-style', this.onFontStylePopupClick);

    this.blocks && this.blocks[0].off('selection_styles_changed', this.selectionStylesChanged);
    this.blocks && this.blocks[0].off('selection_styles_all_changed', this.selectionStylesAllChanged);
  },

  plusAnimation: function() {
    this.$el.flashClass('plus-animation', 1200);
  },

  selectionStylesAllChanged: function(styles_all) {
    this.$panel.find('.font-style').fadeOut(200);
    this.font_style_visible = false;

    // шрифты которые попали в выделение
    this.fonts_in_selection = [];

    // стили которые попали в выделение ({font-weight, font-style})
    this.styles_in_selection = [];

    // выпадалка стилей
    var style_popup_list = [];

    // список шрифтов в выделении (временный объект в отличие от this.fonts_in_selection)
    var used_fonts = [];

    // список начертаний в выделении (временный объект в отличие от this.styles_in_selection)
    var used_styles = [];

    // Если в выделении только один шрифт. Используется для отображения кастомных названий
    // начертаний, если они есть у шрифта
    var single_font_name;

    if (styles_all && RM.constructorRouter.fonts && styles_all.length > 0) {
      // ищем какие шрифты и стили есть в выделении
      for (var i = 0; i < styles_all.length; i++) {
        used_fonts.push(styles_all[i]['font-family']);

        // получаем стиль с которым элемент будет отрендерен браузером (см. описание функции getVisualRenderedFontStyles)
        var visual_rendered_font_styles = RM.constructorRouter.fonts.getVisualRenderedFontStyles(
          styles_all[i]['font-family'],
          styles_all[i]['font-weight'],
          styles_all[i]['font-style']
        );

        // сохраняем в таком формате, чтобы потом проще было искать уникальные
        used_styles.push(visual_rendered_font_styles['font-weight'] + '-' + visual_rendered_font_styles['font-style']);
      }

      // выбираем уникальные
      used_fonts = _.uniq(used_fonts);
      used_styles = _.uniq(used_styles);

      // сохраняем список шрифтов в выделении
      if (used_fonts.length > 0) {
        this.fonts_in_selection = used_fonts;
      }

      if (used_fonts.length == 1) {
        single_font_name = used_fonts[0];
      }

      // сохраняем список стилей в выделении
      if (used_styles.length > 0) {
        _.each(
          used_styles,
          _.bind(function(style) {
            var data = style.split('-');
            if (data.length == 2) {
              this.styles_in_selection.push({
                name: RM.constructorRouter.fonts.getStyleName(data[0], data[1], single_font_name),
                'font-weight': data[0],
                'font-style': data[1],
              });
            }
          }, this)
        );
      }

      // ищем какие общие начертания доступны у шрифтов в выделении, чтобы сформировать список стилей в выпадалку стилей
      if (used_fonts.length > 0 && used_styles.length > 0) {
        // получаем список стилей которые поддерживаются всеми шрифтами из списка used_fonts
        var common_fonts_styles_available = RM.constructorRouter.fonts.findCommonFontsStylesAvailable(used_fonts);

        _.each(common_fonts_styles_available, function(style) {
          var data = style.split('-');
          if (data.length == 2) {
            style_popup_list.push({
              name: RM.constructorRouter.fonts.getStyleName(data[0], data[1], single_font_name),
              'font-weight': data[0],
              'font-style': data[1],
            });
          }
        });
      }
    }

    var fonts_names = _.map(this.fonts_in_selection, function(css_name) {
      var font = RM.constructorRouter.fonts.findFontByCSSName(css_name);
      return font.name;
    });

    this.$panel
      .find('.font-family-popup .popup-caption')
      .text(fonts_names.join(', '))
      .css('font-family', this.fonts_in_selection.length > 0 ? this.fonts_in_selection[0] : 'Arial');

    var used_styles_names = _.pluck(this.styles_in_selection, 'name');
    this.$panel
      .find('.font-style-popup .popup-caption')
      .text(used_styles_names.join(', '))
      .css({
        'font-family': this.fonts_in_selection.length > 0 ? this.fonts_in_selection[0] : 'Arial',
        'font-weight': this.styles_in_selection.length > 0 ? this.styles_in_selection[0]['font-weight'] : '400',
        'font-style': this.styles_in_selection.length > 0 ? this.styles_in_selection[0]['font-style'] : 'normal',
      });

    // заполняем выпадалку стилей
    var $container = this.$panel.find('.font-style-wrapper');
    $container.empty();

    _.each(
      style_popup_list,
      _.bind(function(style, key) {
        var $item = $('<div>')
          .appendTo($container)
          .addClass('font-style-item')
          .text(style.name)
          .css({
            'font-family': this.fonts_in_selection.length > 0 ? this.fonts_in_selection[0] : 'Arial',
            'font-weight': style['font-weight'] + '',
            'font-style': style['font-style'],
          })
          .attr('font-weight', style['font-weight'])
          .attr('font-style', style['font-style'])
          .on('click', this.styleChanged);

        // элемент "точка" которая и ховер и текущий
        var $point = $('<div>')
          .appendTo($item)
          .addClass('point');

        // отмечаем стили которые присутствуют в выделении
        if (_.indexOf(used_styles_names, style.name) + 1) $item.addClass('curr');
      }, this)
    );

    // дизейблим выпадалку стилей если там нет стилей
    this.$panel.find('.font-style-popup').toggleClass('disabled', style_popup_list.length == 0);

    // если открыт фонт селектор, обновляем в нем текущий шрифт
    if (this.font_selector_visible) {
      // если сообщение об изменении шрифта пришло потому что мы сами выбрали другой шрифт в фонтселекоре (this.blocks[0].initiatorControl == this.name)
      // тогда не надо прокручивать шрифты в центр фонт селектора (undefined)
      // иначе, если сообщение об изменении шрифта пришло потому что мы переместили курсор или выделение, то надо прокрутить со скоростью 300 до текущего шрифта
      var auto_scroll_speed = this.blocks[0].initiatorControl == this.name ? undefined : 300;
      this.fontselector && this.fontselector.setActiveFont(this.fonts_in_selection || [], auto_scroll_speed);
    }

    // обновляем состояние кнопки связан-отвязан размер шрифта и интерлиньяж
    // смотрим по первому парагафу в выделении
    if (styles_all.length) this.$panel.find('.linked').toggleClass('active', styles_all[0]['size-leading-linked']);
  },

  selectionStylesChanged: function(styles) {
    if (this.blocks[0].initiatorControl !== 'text_typography' && this.blocks[0].initiatorControl == this.name) {
      return;
    }
    this.updateState(styles);
  },

  updateState: function(param) {
    if (param['font-size'] != undefined) {
      this.setMultipleState(this.$panel.find('.font-size'), this.editFontSizeChangeValue, param, 'font-size', true);
    }
    if (param['line-height'] != undefined) {
      this.setMultipleState(
        this.$panel.find('.line-height'),
        this.editLineHeightChangeValue,
        param,
        'line-height',
        true
      );
    }
    if (param['letter-spacing'] != undefined) {
      this.setMultipleState(
        this.$panel.find('.letter-spacing'),
        this.editLetterSpacingChangeValue,
        param,
        'letter-spacing',
        false
      );
    }
  },

  setMultipleState: function($input, changeMethod, param, attr, callChangeCallback) {
    if (!this.blocks) return;

    var $multiple_layer = $input.parent().find('.multiple-layer'),
      num;

    // в выделении несколько значений, показываем троеточие, но при этом сам инпут хранит первое значение из списка
    if (param[attr] == this.blocks[0].params.multipleName) {
      $multiple_layer.css('display', 'block');
      $input.css('opacity', 0);
      if (this.blocks[0].cur_selection_styles_all.length > 0) num = this.blocks[0].cur_selection_styles_all[0][attr];
      //! !!вообще странная ситуация, такого быть не должно, но у меня было
      else num = attr == 'line-height' ? this.params.minLineHeight : this.params.minFontSize;
    } else {
      $multiple_layer.css('display', 'none');
      $input.css('opacity', 1);
      num = param[attr];
    }

    num = parseFloat(num); // убираем px

    changeMethod(num, true);

    if (callChangeCallback) this.onInputChanged($input, num, true, true);
  },

  onLinkedClick: function() {
    var $linked = this.$panel.find('.linked'),
      $fontSize = this.$panel.find('.font-size'),
      styles_all = this.blocks[0].cur_selection_styles_all;

    $linked.toggleClass('active');

    if ($linked.hasClass('active')) $linked.attr('title', 'Unbind Font Size and Line Height');
    else $linked.attr('title', 'Bind Font Size and Line Height');

    // если связка-отвязка происходит в случае, когда в выделении несколько параграфов и у них разные значения size-leading-linked или size-leading-ratio
    // тогда надо действовать немного по-другому:
    // по логике в таком случае не стоит сохранять настройку связан-не связан для всех параграфов в выделении сразу же (а тем более новую пропорцию)
    // потому что это как-то несовсем правильно ИМХО
    // а вот в дальнейшем при любом изменении размера шрифта или интерлиньяжа
    // и произойдет сохранение связаны-отвязаны и пропорции связки (код в конце функции onInputChanged)
    if (
      this.blocks[0].cur_selection_styles['size-leading-linked'] != this.blocks[0].params.multipleName &&
      this.blocks[0].cur_selection_styles['size-leading-ratio'] != this.blocks[0].params.multipleName
    ) {
      // обновляем для параграфа в выделении флаг связаны или нет интерлиньяж и размер шрифта
      // и саму пропорцию которой они связаны
      // пропорцию можно перезатирать даже когда они не связаны, потому что эта пропорция используется только когда они связан,
      // а при каждом связывании пропорция пересчитывается на ту которая была в момент связывания
      var param = {
        'size-leading-linked': this.$panel.find('.linked').hasClass('active'),
        'size-leading-ratio': parseInt(styles_all[0]['line-height'], 10) / parseInt(styles_all[0]['font-size'], 10), // parseInt - убираем px
      };

      if (isNaN(param['size-leading-ratio'])) param['size-leading-ratio'] = this.params.fontSizeLineHeightRatio;

      if (this.blocks) {
        this.blocks[0].initiatorControl = this.name;
        this.blocks[0].trigger('changeStyleParagraph', param);
      }
    }
  },

  onInputChanged: function($input, num, fakeEvent, noEditorChange) {
    if (!this.blocks) return;

    var className = $input.attr('data-class'),
      min = className == 'line-height' ? this.params.minLineHeight : this.params.minFontSize,
      max = className == 'line-height' ? this.params.maxLineHeight : this.params.maxFontSize;

    this['springEffect-' + className] = this['springEffect-' + className] || 0;

    // получаем соотношение размера шрифта и интерлиньяжа для первого параграфа в выделении (оно будет использоваться и для других параграфов в выделении, даже если у них заданы другие параметры)
    // здесь имеется в виду не текущее соотноешение которое задано в стилях
    // а ссотношение которое задается в панельке типографики при нажатии на кнопку связки размера шрифта и интерлиньжа
    // ликбез: при нажатии этой кнопки смотрятся текущие значения размера шрифта и интерлиньяжа в панельке
    // и их соотношение прописывается в параметры самого параграфа, и в дальнейшем это соотношение используется при
    // расчете интерлиньяжа при перетаскивании размера шрифта и наоборот
    // т.е. здесь речь идет именно о настройках панельки типографики для каждого конктерного параграфа
    try {
      var currentFontSizeLineHeightRatio = this.blocks[0].cur_selection_styles_all[0]['size-leading-ratio'];

      if (isNaN(currentFontSizeLineHeightRatio))
        var currentFontSizeLineHeightRatio = this.params.fontSizeLineHeightRatio;
    } catch (e) {
      //! !!вообще странная ситуация, такого быть не должно, но было https://trello.com/c/wPlvyrHQ/209--
      console.log(e.message, e.stack);
      if (this.blocks) {
        console.log(this.blocks);
        if (this.blocks[0]) {
          console.log(this.blocks[0]);
          if (this.blocks[0].cur_selection_styles_all) {
            console.log(this.blocks[0].cur_selection_styles_all);
            if (this.blocks[0].cur_selection_styles_all[0]) {
              console.log(this.blocks[0].cur_selection_styles_all[0]);
            }
          }
        }
      }
      var currentFontSizeLineHeightRatio = this.params.fontSizeLineHeightRatio;
    }

    if (this.$panel.find('.linked').hasClass('active') && !fakeEvent && className == 'font-size') {
      var val = Math.round(num * currentFontSizeLineHeightRatio);
      this.editLineHeightChangeValue(val, true);
      this.onInputChanged(this.$panel.find('.line-height'), val, true);
    }

    if (this.$panel.find('.linked').hasClass('active') && !fakeEvent && className == 'line-height') {
      var val = Math.round(num / currentFontSizeLineHeightRatio);
      this.editFontSizeChangeValue(val, true);
      this.onInputChanged(this.$panel.find('.font-size'), val, true);
    }

    $input.toggleClass('big-number', num > 99);
    var dir = 0;
    if (this['oldVal-' + className] > num) dir = -1;
    if (this['oldVal-' + className] < num) dir = 1;
    this['oldVal-' + className] = num;

    var minY = 188;
    var maxY = 125;
    var percentage = (num - min) / (minY - maxY);
    if (percentage > 1) {
      clearTimeout(this['springTimeout-' + className]);
      this['springEffect-' + className] -= 1 * dir;
      if (this['springEffect-' + className] > 100) this['springEffect-' + className] = 100;
      if (this['springEffect-' + className] < -100) this['springEffect-' + className] = -100;
      if (this['springEffect-' + className] > 0) var springEffect = Math.sqrt(this['springEffect-' + className]);
      else var springEffect = -Math.sqrt(-this['springEffect-' + className]);

      $input.parent().removeClass('spring-effect');
      $input.parent().css({ 'margin-top': -25 + springEffect });
      this['springTimeout-' + className] = setTimeout(
        _.bind(function() {
          $input
            .parent()
            .addClass('spring-effect')
            .css({ 'margin-top': '-25px' });
          this['springEffect-' + className] = 0;
        }, this),
        200
      );

      percentage = 1;
    }
    var curY = minY + percentage * (maxY - minY);

    $input.parent().css({ top: curY });

    this.$panel.find('.' + className + '-gauge').height(minY - curY + 17);

    if (noEditorChange) return;

    var param = {};
    param[className] = num + 'px';

    this.updateState(param);

    // если установлен флаг "связанного" изменения размера шрифта и интерлиньяжа
    // тогда посылаем данные о том, что нам надо изменить оба значения сразу
    // зачем это сделано?
    // из-за ососбенностей работы функции checkForParamUpdateNeeded в текстовом виджете!
    // несмотря на то, что при "связанных значениях" this.blocks[0].trigger('changeStyleSpan', param); вызовется дважды
    // функция checkForParamUpdateNeeded не будет исполнять второй вызов поскольку он идентичен предыдущему
    // зато она запустит изменения размера шрифта и интерлиньяжа c debounce, что очень хорошо для ликвидации тормозов
    // если убрать следующий блок if  то вместо того чтобы дважды триггерить changeStyleSpan с одинаковыми font-size и line-height
    // будут дважды триггерится changeStyleSpan, первый с font-size, второй с line-height
    // из-за этого checkForParamUpdateNeeded сделает вывод о том, что параметры совсем разные (не только значения, но и ключи)
    // и их нельзя вызывать c общим обработчиком debounce и вызовет их немедленно - результат: performance hit

    if (this.$panel.find('.linked').hasClass('active')) {
      param['font-size'] = this.$panel.find('.font-size').val() + 'px';
      param['line-height'] = this.$panel.find('.line-height').val() + 'px';
    }

    // обновляем для всех параграфов в выделении флаг связаны или нет интерлиньяж и размер шрифта
    // и саму пропорцию которой они связаны
    // 1. пропорцию можно перезатирать даже когда они не связаны, потому что эта пропорция используется только когда они связан,
    //   а при каждом связывании пропорция пересчитывается на ту котора была в момент связывания
    // 2. сохранять эти параметры типографики для всех параметров необходимо по той причине, что у нас в выделении могут быть несколько параграфов
    //   и у всех у них могут быть разные настройки size-leading-linked и size-leading-ratio
    //   а при изменении размера шрифта и интерлиньяжа в расчет беруться только настройки первого парагрфа из выделения,
    //   но применяются эти изменения одинаково ко всем параграфамв выделении, и поэтому нам также надо и для всех параграфов в выделении
    //   проставить параметры типографики (size-leading-linked и size-leading-ratio) как у первого параграфа
    //   иначе потом при попытке работать с типографикий этих параграфов мы будем видеть их старые настройки (size-leading-linked и size-leading-ratio), это глупо
    param['size-leading-linked'] = this.$panel.find('.linked').hasClass('active');
    param['size-leading-ratio'] = currentFontSizeLineHeightRatio;

    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleParagraph', param);
    }
  },

  onLetterSpacingChanged: function($input, num) {
    var param = { 'letter-spacing': num + 'px' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  onFontPopupClick: function(e) {
    this.fontselector && this.fontselector.show(this.fonts_in_selection || []);
    this.font_selector_visible = true;
  },

  onFontStylePopupClick: function(e) {
    if (this.$panel.find('.font-style-popup').hasClass('disabled')) return;
    this.font_style_visible = !this.$panel.find('.font-style').is(':visible');
    this.$panel.find('.font-style').fadeToggle(200);
  },

  fontChanged: function(font_family) {
    var param = { 'font-family': font_family };
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  styleChanged: function(e) {
    var $obj = $(e.currentTarget);
    var param = {
      'font-weight': $obj.attr('font-weight'),
      'font-style': $obj.attr('font-style'),
    };

    this.closeFontStyle();

    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  closeFontSelector: function() {
    this.font_selector_visible = false;
    this.fontselector && this.fontselector.hide();
  },

  closeFontStyle: function() {
    this.font_style_visible = false;
    this.$panel.find('.font-style').fadeOut(200);
  },

  closeAllPopups: function(e) {
    if (e) {
      if ($(e.target).closest('.rmfontselector').length > 0) return;
      if ($(e.target).closest('.font-family-popup').length > 0) return;

      if ($(e.target).closest('.font-style-wrapper').length > 0) return;
      if ($(e.target).closest('.font-style-popup').length > 0) return;
    }

    if (this.font_selector_visible && this.fontselector.handleEscape()) {
      this.closeFontSelector();
    }

    if (this.font_style_visible) this.closeFontStyle();
  },

  // переопределяем метод клика по иконке контрола
  onClick: function() {
    if (this.selected) {
      if (this.font_selector_visible || this.font_style_visible) {
        this.closeAllPopups();
        return;
      }
    }

    ControlClass.prototype.onClick.apply(this, arguments);
  },

  // переопределяем метод реакции на кнопку Esc
  onEscKey: function() {
    if (this.selected) {
      if (this.font_selector_visible || this.font_style_visible) {
        this.closeAllPopups();
        return;
      }
    }

    ControlClass.prototype.onEscKey.apply(this, arguments);
  },

  /**
   * Переопределяем реакцию закрытия паельки контрола
   */
  deselect: function() {
    this.closeAllPopups();

    ControlClass.prototype.deselect.apply(this, arguments);
  },

  /**
   * Переопределяем функцию которая решает поглощает контрол событие или нет (обычно это событие deselect воркспейса)
   */
  canControlBeClosed: function() {
    if (this.font_selector_visible || this.font_style_visible) {
      this.closeAllPopups();
      return true;
    }

    return ControlClass.prototype.canControlBeClosed.apply(this, arguments);
  },
});

export default TextTypography;
