/**
 * Вьюха с колорбоксом
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../../templates/constructor/helpers/colorbox.tpl';
import Events from '../../common/events';
import PreloadDesignImages from '../../common/preload-design-images';

var Colorbox = Backbone.View.extend({
  name: 'Colorbox',

  settings_vertical: {
    width: 168,
    height: 240,
    bottom_panel_height: 72,
    hue_width: 0,
    hue_width_opened: 0,
    hue_height: 24,
    color: '6CD632',
    opacity: 1,
    swatches_max: 60,
    swatches_min: 10,
    favourites_max: 6,
    favourites_per_line: 3,
    swatches_per_line: 5,
    fav_switch_speed: 300,
    show_opacity: false,
  },

  // настройки панельки по умолчаниюдл для большой версии
  settings_big: {
    width: 208,
    height: 240,
    bottom_panel_height: 40,
    hue_width: 9,
    hue_width_opened: 20,
    color: '6CD632',
    opacity: 1,
    swatches_max: 60,
    swatches_min: 10,
    favourites_max: 6,
    favourites_per_line: 3,
    swatches_per_line: 5,
    fav_switch_speed: 300,
    show_opacity: false,
  },

  // настройки панельки по умолчанию для маленькой версии
  settings_small: {
    width: 152,
    height: 240,
    bottom_panel_height: 96,
    hue_width: 9,
    hue_width_opened: 16,
    color: '6CD632',
    opacity: 1,
    swatches_max: 60,
    swatches_min: 15,
    favourites_max: 6,
    favourites_per_line: 3,
    swatches_per_line: 5,
    fav_switch_speed: 300,
    show_opacity: true,
  },

  /**
   * Подготавливаем данные для работы колорбокса
   * params:
   *  $parent: $ объект родитель для колорбокса
   *  type: тип 'small' или 'big' (к примеру для текстового виджета 'small', в для виджета фона 'big')
   *	color: цвет по умолчанию, в виде 'FF0000' или undefined если цвет не известен (нужно для текстового виджета, когда выбраны блоки с разными цветами)
   *  opacity: прозрачность с которой надо открыться (0 - 1), или undefined если прозрачность не известна (нужно для текстового виджета, когда выбраны блоки с разными прозрачностями) (блок выбора прозрачности цвета есть только в type: 'small')
   */
  initialize: function(params) {
    this.model = RM.constructorRouter.mag.edit_params;

    // вспомогательный объект который хранит информацию при перетаскивании цветов в панельке избранных
    this.colorDragData = {};

    // вспомогательные объекты которые хранят информацию при скроллинге в панельке избранных цветов
    this.scrollDragData = {};
    this.scrollData = {
      height: 0,
      percent: 0,
      container_h: 0,
      inner_h: 0,
    };

    // настройки панельки
    this.settings = {};

    // текущий выбранный цвет в формате RGB
    this.RGB = [];

    // текущий выбранный цвет в формате HSB
    this.HSB = [];

    this.section = 'color';

    _.bindAll(this);
    if (this['settings_' + params.type]) {
      _.extend(this.settings, this['settings_' + params.type]);
    } else {
      _.extend(this.settings, this.settings_big);
    }

    _.extend(this.settings, params);

    if (typeof this.settings.color !== 'string' || this.settings.color.length != 6) {
      console.log('Error, wrong color set to colorbox: ', this.settings.color);
      this.settings.color = this.settings_big.color;
    }

    // преобразуем переданный цвет(или, если нет, дефолтный) в нужные нам для работы форматы
    this.RGB = this.hex2rgb(this.settings.color);
    this.HSB = this.rgb2hsb(this.RGB);

    // рассчитываем размеры различных элементов панельки
    this.settings.map_width = this.settings.width - (params.type != 'vertical' ? this.settings.hue_width : 0);
    this.settings.map_height = this.settings.height - this.settings.bottom_panel_height;
    this.settings.hue_height = this.settings.hue_height || this.settings.height;
    this.settings.placeholdes_height = this.settings.map_height - 22;
    if (this.settings.type == 'small') this.settings.placeholdes_height = 166;

    this.template = templates['template-constructor-helpers-colorbox'];

    this.create();
  },

  create: function() {
    // надо делать копии массивов из модели поскольку тупой backbone выдает не копии значений, а референсы

    // массив избранных цветов (большие кружки)
    this.favourites = this.model && _.clone(this.model.get('fav_colors'));
    // массив часто используемых цветов (маленькие кружки)
    this.swatches = this.model && _.clone(this.model.get('swatches'));

    this.render();

    if (this.model && this.model.attributes.colorbox_state && this.section !== this.model.attributes.colorbox_state) {
      this.switchBetweenSwatchesAndColorsBridge();
    }

    // If we have no color in swatches, we set 'color' section
    if (this.model && !this.favourites.length && !this.swatches.length && this.section === 'swatches') {
      this.switchBetweenSwatchesAndColorsBridge();
    }
  },

  /**
   * Рисуем колорбокс
   */
  render: function() {
    PreloadDesignImages('colorbox');

    this.setElement(
      $(this.template(this.settings))
        .appendTo(this.settings.$parent)
        .addClass('rmcolorbox-type-' + this.settings.type)
    );

    // сохраняем в переменных нужные нам DOM объекты
    this.$rmcolorbox_swatches_placeholdes = this.$('.rmcolorbox-swatches-placeholdes');
    this.$rmcolorbox_swatches_placeholdes_wrapper = this.$('.rmcolorbox-swatches-placeholdes-wrapper');
    this.$rmcolorbox_swatches_colors = this.$('.rmcolorbox-swatches-colors');
    this.$rmcolorbox_swatches = this.$('.rmcolorbox-swatches');
    this.$swatches_button = this.$('.rmcolorbox-swatches-button');
    this.$add_to_swatches_button = this.$('.rmcolorbox-add-to-swatches-button');
    this.$remove_from_swatches_button = this.$('.rmcolorbox-remove-from-swatches-button');
    // Если у нас нет модели то и элементов для работы с ней не должно быть
    if (!this.model) {
      this.$rmcolorbox_swatches.remove();
      this.$swatches_button.remove();
      this.$add_to_swatches_button.remove();
    }

    this.$scroll_handle = this.$('.rmcolorbox-swatches-scroll-handle');

    this.$map_block = this.$('.rmcolorbox-map');
    this.$map_handle = this.$map_block.find('.rmcolorbox-map-handle');
    this.map_ctx = this.$map_block.find('canvas')[0].getContext('2d');

    this.$rmcolorbox_color = this.$('.rmcolorbox-color');

    this.$animation_box = this.$('.rmcolorbox-animation-box');
    this.$animation_canvas = this.$animation_box.find('canvas');
    this.animation_ctx = this.$animation_canvas[0].getContext('2d');

    this.$hue_block = this.$('.rmcolorbox-hue');
    this.hue_ctx1 = this.$hue_block.find('.rmcolorbox-hue-canvas1')[0].getContext('2d');

    if (this.settings.type != 'vertical') {
      this.hue_ctx2 = this.$hue_block.find('.rmcolorbox-hue-canvas2')[0].getContext('2d');
      this.map_under_arrow_ctx = this.$('.rmcolorbox-map-under-arrow canvas')[0].getContext('2d');
    } else {
      this.$hueLine = this.$('.hue-line');
    }

    this.$opacity_block = this.$('.rmcolorbox-opacity');
    this.$opacity_handle = this.$('.rmcolorbox-opacity-handle');

    this.$input_rgb = this.$('.rmcolorbox-rgb-block-input');
    this.$input_r = this.$('.rmcolorbox-r-block-input');
    this.$input_g = this.$('.rmcolorbox-g-block-input');
    this.$input_b = this.$('.rmcolorbox-b-block-input');
    this.$input_r_g_b = this.$input_r.add(this.$input_g).add(this.$input_b);

    // навешиваем обработчики событий
    this.$hue_block.RMDrag({
      start: this.startHueDrag,
      move: this.startHueDrag,
      end: this.stopHueDrag,
      silent: true,
      preventDefault: true,
    });

    this.$map_block.RMDrag({
      start: this.startMapDrag,
      move: this.startMapDrag,
      silent: true,
      preventDefault: true,
    });

    this.$opacity_block.RMDrag({
      start: this.startOpacityDrag,
      move: this.startOpacityDrag,
      end: this.stopOpacityDrag,
      silent: true,
      preventDefault: true,
    });

    this.$scroll_handle.RMDrag({
      start: this.startScrollDrag,
      move: this.moveScrollDrag,
      end: this.stopScrollDrag,
      silent: true,
      preventDefault: true,
    });

    this.$rmcolorbox_swatches_placeholdes_wrapper.mousewheel(this.onScrollMouseWheel);

    this.$input_rgb
      .on('paste', this.rgbPaste)
      .on('keyup keydown', this.rgbKeyPressed)
      .on('blur', this.rgbBlur)
      .on('focus', this.rgbFocus);
    this.$input_r_g_b
      .on('keyup keydown', this.r_g_bKeyPressed)
      .on('blur', this.r_g_bBlur)
      .on('focus', this.r_g_bFocus);

    this.$swatches_button.on('click', this.switchBetweenSwatchesAndColors);
    this.$add_to_swatches_button.on('click', this.addToSwatches);
    this.$remove_from_swatches_button.on('click', this.deleteColor);

    // начальная отрисовка палитр и обновление кодов цветов
    this.changeColor(undefined, undefined, undefined, true);

    // switchBetweenSwatchesAndColors();

    // создаем разметку для swatches и favourites (посадочные кружки)
    if (this.favourites && this.swatches) this.createFSPlaceholdes(true);

    if (this.settings.type === 'small') this.setOpacity(this.settings.opacity);

    // создаем swatches и favourites
    if (this.favourites)
      for (var i = 0; i < this.favourites.length; i++) if (this.favourites[i]) this.addFavouritesColor(i, true);
    if (this.swatches) for (i = 0; i < this.swatches.length; i++) if (this.swatches[i]) this.addSwatchesColor(i, true);

    this.model && this.model.on('change:swatches', this.onModelSwatchesChange);
    this.model && this.model.on('change:fav_colors', this.onModelFavouritesChange);

    Events.on('panel:showed', this.setCurrentSelectedColor);
  },

  onModelSwatchesChange: function(event, data) {
    if (this.thisModelChangeInitiator) {
      this.thisModelChangeInitiator = false;
      return;
    }
    this.thisModelChangeInitiator = false;

    this.swatches = _.clone(data);

    this.createFSPlaceholdes(true);
    this.$rmcolorbox_swatches_colors.find('.rmcolorbox-swatches-color-small').remove();

    for (var i = 0; i < this.swatches.length; i++) if (this.swatches[i]) this.addSwatchesColor(i, true);

    this.$remove_from_swatches_button.removeClass('active');
  },

  onModelFavouritesChange: function(event, data) {
    if (this.thisModelChangeInitiator) {
      this.thisModelChangeInitiator = false;
      return;
    }
    this.thisModelChangeInitiator = false;

    this.favourites = _.clone(data);

    this.$rmcolorbox_swatches_colors.find('.rmcolorbox-swatches-color-big').remove();

    for (var i = 0; i < this.favourites.length; i++) if (this.favourites[i]) this.addFavouritesColor(i, true);

    this.$remove_from_swatches_button.removeClass('active');
  },

  toggleOpacity: function(show) {
    this.$el.toggleClass('without-opacity', !show);
  },

  onSwatchesChange: function() {
    this.thisModelChangeInitiator = true;
    // при сохранении надо всегда делать копию массива, иначе не будет работать onChange
    this.model.save({ swatches: _.clone(this.swatches) });
  },

  onFavouritesChange: function() {
    this.thisModelChangeInitiator = true;
    // при сохранении надо всегда делать копию массива, иначе не будет работать onChange
    this.model.save({ fav_colors: _.clone(this.favourites) });
  },

  /**
   * Вызывается когда мы изменили цвет через палитру, через избранное или через коды цветов
   * Определяет откуда вызвали и обновляет состояние всех элементов которые отражают текущий цвет
   */
  changeColor: function(hsb, rgb, initiator, initial) {
    if (initial && !hsb) hsb = this.HSB;
    if (initial && !rgb) rgb = this.RGB;

    if (initiator == 'hue' || initiator == 'sb') {
      rgb = this.hsb2rgb(hsb);
    } else {
      hsb = this.rgb2hsb(rgb);
    }

    if (hsb[0] != this.HSB[0] || initial) {
      this.fillHue(hsb[0]);
      this.fillMap(hsb[0]);
    }

    if (hsb[1] != this.HSB[1] || hsb[2] != this.HSB[2] || initial) {
      this.$map_handle.css({
        left: Math.round((this.settings.map_width * hsb[1]) / 100) + 'px',
        top: Math.round((this.settings.map_height * (100 - hsb[2])) / 100) + 'px',
      });
    }

    if (rgb[0] != this.RGB[0] || rgb[1] != this.RGB[1] || rgb[2] != this.RGB[2] || initial) {
      if (initiator != 'input-rgb') {
        this.$input_rgb.val(this.rgb2hex(rgb));
        this.setInputSize(this.$input_rgb);
      }
      if (this.settings.type != 'vertical') {
        if (initiator != 'input-r') {
          this.$input_r.val(rgb[0]);
          this.setInputSize(this.$input_r);
        }
        if (initiator != 'input-g') {
          this.$input_g.val(rgb[1]);
          this.setInputSize(this.$input_g);
        }
        if (initiator != 'input-b') {
          this.$input_b.val(rgb[2]);
          this.setInputSize(this.$input_b);
        }
      }
    }

    if ((rgb[0] != this.RGB[0] || rgb[1] != this.RGB[1] || rgb[2] != this.RGB[2]) && initiator != 'swatches') {
      this.unSelectColor();
    }

    var triggerChange = false;
    // триггерим colorchange:
    // 1. если новый цвет отличается от текущего
    // 2. или если изменения цвета произошли из-за клика по избранному цвету (чтобы можно было назначить цвет из избранных для выделения в тексте в котором несколько цветов, но первый из них совпадает с избранным https://trello.com/c/YEyFrzc4/148--)
    // 3. и если при двух вышеперечисленных пунктах не стоит флага initial (изначальной проставноки цвета при инициализации колорбокса)
    if (
      (rgb[0] != this.RGB[0] || rgb[1] != this.RGB[1] || rgb[2] != this.RGB[2] || initiator == 'swatches') &&
      !initial
    )
      triggerChange = true;

    this.$opacity_block.css('background-color', '#' + this.rgb2hex(rgb));

    this.HSB = hsb;
    this.RGB = rgb;

    if (triggerChange) this.trigger('colorchange', rgb[0], rgb[1], rgb[2], this.settings.opacity);
  },

  // внешний метод для обновления данных о текущем цвете в панели колорбокса
  // ожидает строку RGB в hex формате без #
  // применяет изменения только в том случае, если переданный RGB цвет отличается от текущего
  // если этой проверки не делать, то будут проблемы если код который реагирует на изменение цвета в колорбоксе
  // одновременно при этом сам пытается менять состояние колорбокса на тот цвет который ему был передан (например в панельке bg):
  // кружок выбора цвета начинает скакать под мышкой, это связано с тем, что когда мы двигаем кружок мышкой
  // то мы преобразуем HSB цвет в RGB и такое преобразование всегда однозначно
  // а вот когда мы делаем setColor, то чтобы показать кружок на палитре в нужном месте нам приходится преобразовывать
  // RGB в HSB, а такое преобразование далеко не всегда однозначно (чем ближе к черному цвету тем неоднозначнее)
  // например, черный цвет RGB (0,0,0) в HSB имеет 36000 значений (H,S - любые, B-0)
  // т.е. HSB(50, 0, 0), HSB(0, 30, 0), HSB(250, 100, 0), HSB(10, 10, 0) - это все черный цвет
  // кстати пространство цветов HSB меньше пространства RGB в 4.66 раза
  setColor: function(rgb) {
    rgb = this.hex2rgb(rgb);

    if (rgb[0] != this.RGB[0] || rgb[1] != this.RGB[1] || rgb[2] != this.RGB[2])
      this.changeColor(undefined, rgb, undefined, true);
  },

  /**
   * Отрисовывает боковую плашку выбора цвета
   */
  fillHue: function(cur_hue) {
    if (this.settings.type == 'vertical') {
      var img_data = this.hue_ctx1.getImageData(0, 0, this.settings.width, this.settings.hue_height),
        index = 0;

      for (var x = 0; x < this.settings.width; x++) {
        var hue = (1 - x / this.settings.width) * 360;
        var rgb = this.hsb2rgb([hue, 100, 100]);
        for (var y = 0; y < this.settings.hue_height; y++) {
          img_data.data[index + y * this.settings.width * 4] = rgb[0];
          img_data.data[index + 1 + y * this.settings.width * 4] = rgb[1];
          img_data.data[index + 2 + y * this.settings.width * 4] = rgb[2];
          img_data.data[index + 3 + y * this.settings.width * 4] = 255;
        }
        index += 4;
      }

      this.hue_ctx1.putImageData(img_data, 0, 0);
    } else {
      var img_data = this.hue_ctx1.getImageData(0, 0, this.settings.hue_width_opened - 3, this.settings.hue_height),
        index = 0;

      for (var y = 0; y < this.settings.hue_height; y++) {
        var hue = (1 - y / this.settings.hue_height) * 360;
        var rgb = this.hsb2rgb([hue, 100, 100]);
        for (var x = 0; x < this.settings.hue_width_opened - 3; x++) {
          img_data.data[index++] = rgb[0];
          img_data.data[index++] = rgb[1];
          img_data.data[index++] = rgb[2];
          img_data.data[index++] = 255;
        }
      }

      this.hue_ctx1.putImageData(img_data, 0, 0);

      img_data = this.hue_ctx2.getImageData(0, 0, 3, this.settings.hue_height);
      index = 0;

      for (var y = 0; y < this.settings.hue_height; y++) {
        var hue = (1 - y / this.settings.hue_height) * 360;
        var rgb = this.hsb2rgb([hue, 100, 100]);
        for (var x = 0; x < 3; x++) {
          img_data.data[index++] = rgb[0];
          img_data.data[index++] = rgb[1];
          img_data.data[index++] = rgb[2];
          img_data.data[index++] = 255;
        }
      }

      // arrow
      var sel_y = Math.round((this.settings.hue_height * (360 - cur_hue)) / 360) - 1,
        arrowX = [1, 1, 2, 1, 2, 3, 2, 1, 1],
        arrowY = [2, 1, 1, 0, 0, 0, -1, -1, -2];
      for (var i = 0; i < arrowX.length; i++) img_data.data[(sel_y - arrowY[i]) * 3 * 4 + (3 - arrowX[i]) * 4 + 3] = 0;

      this.hue_ctx2.putImageData(img_data, 0, 0);
    }
  },

  /**
   * Отрисовывает карту выбора насыщенности и яркости (большой цветной блок)
   */
  fillMap: function(hue) {
    var img_data = this.map_ctx.getImageData(0, 0, this.settings.map_width, this.settings.map_height),
      index = 0,
      col = this.hsb2rgb([hue, 100, 100]);

    for (var y = 0; y < this.settings.map_height; y++) {
      var per = 1 - y / (this.settings.map_height - 1);
      var st = [255 * per, 255 * per, 255 * per];
      var ed = [col[0] * per, col[1] * per, col[2] * per];
      for (var x = 0; x < this.settings.map_width; x++) {
        per = x / (this.settings.map_width - 1);
        img_data.data[index++] = st[0] + (ed[0] - st[0]) * per;
        img_data.data[index++] = st[1] + (ed[1] - st[1]) * per;
        img_data.data[index++] = st[2] + (ed[2] - st[2]) * per;
        img_data.data[index++] = 255;
      }
    }

    this.map_ctx.putImageData(img_data, 0, 0);

    this.fillMapUnderArrow(hue);
    if (this.settings.type == 'vertical') this.moveHueLine(hue);
  },

  /**
   * Расширяет карту выбора насыщенности и яркости (большой цветной блок) влево
   * чтобы она была видна под треугольной выемкой в боковой плашке выбора цвета
   */
  fillMapUnderArrow: function(hue) {
    if (this.settings.type == 'vertical') return;

    var img_data = this.map_under_arrow_ctx.getImageData(0, 0, this.settings.hue_width, this.settings.map_height),
      index = 0;

    for (var y = 0; y < this.settings.map_height; y++) {
      var per = 1 - y / (this.settings.map_height - 1);
      for (var x = 0; x < this.settings.hue_width; x++) {
        img_data.data[index++] = 255 * per;
        img_data.data[index++] = 255 * per;
        img_data.data[index++] = 255 * per;
        img_data.data[index++] = 255;
      }
    }

    this.map_under_arrow_ctx.putImageData(img_data, 0, 0);
  },

  /**
   *
   */
  addToSwatches: function(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    var last_swatch = -1;
    var empty_swatch = -1;
    for (var i = 0; i < this.swatches.length; i++) {
      if (this.swatches[i]) last_swatch = i;
      else if (empty_swatch == -1) empty_swatch = i;
    }

    if (last_swatch + 1 < this.settings.swatches_max) {
      this.swatches[last_swatch + 1] = this.rgb2hex(this.RGB);
      this.onSwatchesChange();
      this.addSwatchesColor(last_swatch + 1);
    } else {
      if (empty_swatch >= 0) {
        this.swatches[empty_swatch] = this.rgb2hex(this.RGB);
        this.onSwatchesChange();
        this.addSwatchesColor(empty_swatch);
      } else return;
    }

    var st_x = this.$map_handle.position().left + this.$map_block.position().left;
    var st_y = this.$map_handle.position().top + this.$map_block.position().top;

    var ed_x = this.$swatches_button.position().left + this.$swatches_button.width() / 2;
    var ed_y = this.$swatches_button.position().top + this.$swatches_button.height() / 2;

    this.$animation_box.removeClass('hidden');

    var length = Math.round(Math.sqrt(Math.pow(st_x - ed_x, 2) + Math.pow(st_y - ed_y, 2))),
      dx = (ed_x - st_x) / length,
      dy = (ed_y - st_y) / length,
      color = '#' + this.rgb2hex(this.RGB),
      $clone = this.$animation_canvas.clone(),
      clone_ctx = $clone[0].getContext('2d'),
      that = this;

    this.animation_ctx.clearRect(0, 0, this.$animation_canvas[0].width, this.$animation_canvas[0].height);
    animateCircle(0);

    function animateCircle(i) {
      if (i > length + 25) {
        that.$animation_box.addClass('hidden');
        $clone.remove();
        return;
      }

      blurCanvases();
      if (i <= length) drawCircle(st_x + dx * i, st_y + dy * i, color);

      setTimeout(function() {
        animateCircle(i + 6);
      }, 10);
    }

    function blurCanvases() {
      clone_ctx.clearRect(0, 0, that.$animation_canvas[0].width, that.$animation_canvas[0].height);
      clone_ctx.globalAlpha = 0.8;
      clone_ctx.drawImage(that.$animation_canvas[0], 0, 0);
      that.animation_ctx.clearRect(0, 0, that.$animation_canvas[0].width, that.$animation_canvas[0].height);
      that.animation_ctx.globalAlpha = 1;
      that.animation_ctx.drawImage($clone[0], 0, 0);
    }

    function drawCircle(x, y, color) {
      that.animation_ctx.beginPath();
      that.animation_ctx.arc(x, y, 5.5, 0, Math.PI * 2, true);
      that.animation_ctx.closePath();
      that.animation_ctx.lineWidth = 1.2;
      that.animation_ctx.strokeStyle = '#fff';
      that.animation_ctx.fillStyle = color;
      that.animation_ctx.fill();
      // animation_ctx.stroke();
    }
  },

  /**
   *
   */
  switchBetweenSwatchesAndColors: function(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.switchBetweenSwatchesAndColorsBridge(this.settings.fav_switch_speed);

    if (this.model && this.model.attributes.colorbox_state !== this.section) {
      this.model.save({
        colorbox_state: this.section,
      });
    }

    if (this.section == 'swatches') {
      this.setCurrentSelectedColor();
    }
  },

  /**
   * Toggles swatches or color.
   * @param {number} speed - Animation speed.
   */
  switchBetweenSwatchesAndColorsBridge(speed = 0) {
    this.$swatches_button.toggleClass('active');

    this.$rmcolorbox_color.fadeToggle(speed);
    this.$rmcolorbox_swatches.fadeToggle(speed);

    this.$add_to_swatches_button.fadeToggle(speed);
    this.$remove_from_swatches_button.fadeToggle(speed);

    this.$('.rmcolorbox-color-input-blocker').fadeToggle(speed);

    if (this.$swatches_button.hasClass('active')) {
      this.$swatches_button.attr('title', 'Hide Swatches');
    } else {
      this.$swatches_button.attr('title', 'Swatches');
    }

    this.section = this.section == 'color' ? 'swatches' : 'color';
  },

  // спец фильтрация в случае, если пытаемся вставить цвет вместе с префиксом #
  // оскольку сейчас ширина поля 6 символов, то последняя цифра в цвета отрезается из-за октоторпа
  // поэтому мы убираем его перед вставкой (код не универсальный и работает не во всех браузерах, но тут это не принципиально)
  rgbPaste: function(e) {
    e = e && e.originalEvent;
    if (e && e.clipboardData && e.clipboardData.getData && /text\/plain/.test(e.clipboardData.types)) {
      var data = e.clipboardData.getData('text/plain');
      if (data.indexOf('#') + 1) {
        e.preventDefault();
        document.execCommand('insertText', false, data.substr(1));
      }
    }
  },

  /**
   *
   */
  rgbKeyPressed: function(e) {
    this.setInputSize($(e.currentTarget));
    var val = $(e.currentTarget).val();
    val = val.replace(/[^0-9a-fA-F]/g, '');
    while (val.length < 6) val = '0' + val;
    var rgb = this.hex2rgb(val);
    this.changeColor(undefined, rgb, 'input-rgb');

    if (!e || e.type != 'keydown') return;

    if (e.keyCode == 13) {
      $(e.currentTarget).blur();
    }
  },

  /**
   *
   */
  rgbFocus: function() {
    this.$('.rmcolorbox-rgb-block').addClass('editing');
  },

  /**
   *
   */
  rgbBlur: function(e) {
    this.$('.rmcolorbox-rgb-block').removeClass('editing');
    $(e.currentTarget).val(this.rgb2hex(this.RGB));
    this.setInputSize($(e.currentTarget));
  },

  /**
   *
   */
  r_g_bKeyPressed: function(e) {
    this.setInputSize($(e.currentTarget));
    var param = $(e.currentTarget).data('param');
    var val = $(e.currentTarget).val();
    val = val.replace(/[^0-9]/g, '');
    val = parseInt(val, 10);
    val = Math.max(Math.min(val, 255), 0);
    if (isNaN(val)) val = 0;

    if (param == 'r') var rgb = [val, this.RGB[1], this.RGB[2]];
    if (param == 'g') var rgb = [this.RGB[0], val, this.RGB[2]];
    if (param == 'b') var rgb = [this.RGB[0], this.RGB[1], val];
    this.changeColor(undefined, rgb, 'input-' + param);

    if (!e || e.type != 'keydown') return;

    if (e.keyCode == 13 && param == 'r') {
      this.$input_r.blur();
      this.$input_g.focus();
    }
    if (e.keyCode == 13 && param == 'g') {
      this.$input_g.blur();
      this.$input_b.focus();
    }
    if (e.keyCode == 13 && param == 'b') {
      this.$input_b.blur();
    }

    if (e.keyCode == 38 || e.keyCode == 40) {
      e.preventDefault();
      var step = e.shiftKey ? 10 : 1;
      val = val + (e.keyCode == 38 ? step : -step);
      val = Math.max(Math.min(val, 255), 0);
      $(e.currentTarget).val(val);
      e.keyCode = 0;
      this.r_g_bKeyPressed(e);
    }
  },

  /**
   *
   */
  r_g_bFocus: function() {
    this.$('.rmcolorbox-r-g-b-block').addClass('editing');
  },

  /**
   *
   */
  r_g_bBlur: function(e) {
    this.$('.rmcolorbox-r-g-b-block').removeClass('editing');
    var param = $(e.currentTarget).data('param');
    if (param == 'r') $(e.currentTarget).val(this.RGB[0]);
    if (param == 'g') $(e.currentTarget).val(this.RGB[1]);
    if (param == 'b') $(e.currentTarget).val(this.RGB[2]);
    this.setInputSize($(e.currentTarget));
  },

  /**
   *
   */
  setInputSize: function($inp) {
    // создаем видимый контейнер но за пределами экрана
    // назначаем ему класс rmcolorbox чтобы стиди правильно применились ($tmp[0].className = $inp[0].className;)
    var $fake_parent = $(
      '<div style="position:absolute; top:-999px; left:-999px; width:900px; height:200px" class="rmcolorbox"></div>'
    ).appendTo('body');

    // создаем в контейнере элемент по которому будем выяснять длину текста введенного в input
    var $tmp = $('<div style="position:absolute; width:auto"></div>').appendTo($fake_parent);

    // назначаем элементу те же классы что и инпуту, чтобы знать шрифт, его размер и пр.
    $tmp[0].className = $inp[0].className;
    $tmp.text($inp.val() + '0');
    $inp.width($tmp.width());

    $fake_parent.remove();
  },

  /**
   *
   */
  startHueDrag: function(e) {
    if (this.section != 'color' && this.settings.type != 'vertical') return; // Не даем редактировать цвет при открытых swatches

    if (this.settings.type == 'vertical') {
      var pos = e.pageX - this.$hue_block.offset().left;
      pos = Math.min(Math.max(pos, 0), this.settings.width - 1);
      pos = 360 - Math.round((360 * pos) / (this.settings.width - 1));
      this.changeColor([pos, this.HSB[1], this.HSB[2]], undefined, 'hue');
      this.$hue_block.addClass('dragging');
    } else {
      pos = e.pageY - this.$hue_block.offset().top;
      pos = Math.min(Math.max(pos, 0), this.settings.hue_height - 1);
      pos = 360 - Math.round((360 * pos) / (this.settings.hue_height - 1));
      this.changeColor([pos, this.HSB[1], this.HSB[2]], undefined, 'hue');
      this.$hue_block.addClass('dragging');
    }
  },

  /**
   *
   */
  stopHueDrag: function(e) {
    this.$hue_block.removeClass('dragging');
  },

  moveHueLine: function(hue) {
    if (this.section != 'color' && this.settings.type != 'vertical') return; // Не даем редактировать цвет при открытых swatches

    var right = (hue / 360) * this.settings.width;
    right = Math.max(Math.min(this.settings.width - 2, right), -1);

    this.$hueLine.css({ right: right });
  },

  /**
   *
   */
  startMapDrag: function(e) {
    var pos_x = e.pageX - this.$map_block.offset().left;
    pos_x = Math.min(Math.max(pos_x, 0), this.settings.map_width - 1);
    pos_x = Math.round((100 * pos_x) / (this.settings.map_width - 1));

    var pos_y = e.pageY - this.$map_block.offset().top;
    pos_y = Math.min(Math.max(pos_y, 0), this.settings.map_height - 1);
    pos_y = 100 - Math.round((100 * pos_y) / (this.settings.map_height - 1));

    this.changeColor([this.HSB[0], pos_x, pos_y], undefined, 'sb');
  },

  /**
   *
   */
  startOpacityDrag: function(e) {
    var pos_x = e.pageX - this.$opacity_block.offset().left;
    var width = this.$opacity_block.width();
    var handle_width_half = this.$opacity_handle.width() / 2;
    var st = handle_width_half;
    var ed = width - handle_width_half;
    pos_x = Math.min(Math.max(pos_x, 0), width - 1);
    if (pos_x > ed) pos_x = ed;
    if (pos_x < st) pos_x = st;
    var per = ((pos_x - st) / (ed - st)).toFixed(2);
    this.$opacity_handle.css({ left: pos_x });
    this.$opacity_handle
      .find('.rmcolorbox-opacity-val')
      .text(Math.round(per * 100) + '%')
      .addClass('dragging');
    if (Math.abs(this.settings.opacity - per) > 0.001)
      this.trigger('opacitychange', this.RGB[0], this.RGB[1], this.RGB[2], per - 0);
    this.settings.opacity = per - 0;
  },

  /**
   *
   */
  stopOpacityDrag: function(e) {
    this.$opacity_handle.find('.rmcolorbox-opacity-val').removeClass('dragging');
  },

  /**
   *
   */
  setOpacity: function(opacity, animate) {
    var width = this.$opacity_block.width(),
      handle_width_half = this.$opacity_handle.width() / 2,
      st = handle_width_half,
      ed = width - handle_width_half,
      pos_x = (ed - st) * opacity + st,
      $opacityVal = this.$opacity_handle.find('.rmcolorbox-opacity-val');

    if (animate) {
      $opacityVal.addClass('dragging');

      var old_pos_x = this.$opacity_handle.position().left,
        old_opacity = this.settings.opacity;

      if (old_pos_x != pos_x)
        this.$opacity_handle.stop().animate(
          { left: pos_x },
          {
            duration: 1200,
            step: function(now, fx) {
              var percent = (now - old_pos_x) / (pos_x - old_pos_x),
                val = old_opacity + percent * (opacity - old_opacity);
              $opacityVal.text(Math.round(val * 100) + '%');
            },
            complete: function() {
              $opacityVal.removeClass('dragging');
            },
          }
        );
    } else {
      this.$opacity_handle.css({ left: pos_x });
      $opacityVal.text(Math.round(opacity * 100) + '%');
    }

    this.settings.opacity = opacity;
  },

  /**
   *
   */
  getFavouritesCoords: function(ind) {
    if (this.settings.type == 'small') {
      var base_x = 32;
      var base_y = 18;
      return [
        base_x + (ind % this.settings.favourites_per_line) * 44,
        base_y + Math.floor(ind / this.settings.favourites_per_line) * 40,
      ];
    } else if (this.settings.type == 'big') {
      base_x = 46;
      base_y = 25;
      return [
        base_x + (ind % this.settings.favourites_per_line) * 58,
        base_y + Math.floor(ind / this.settings.favourites_per_line) * 52,
      ];
    } else if (this.settings.type == 'vertical') {
      base_x = 36;
      base_y = 20;
      return [
        base_x + (ind % this.settings.favourites_per_line) * 48,
        base_y + Math.floor(ind / this.settings.favourites_per_line) * 48,
      ];
    }
  },

  /**
   *
   */
  getSwatchesCoords: function(ind) {
    if (this.settings.type == 'small') {
      var base_x = 25;
      var base_y = 95;
      return [
        Math.floor(base_x + (ind % this.settings.swatches_per_line) * 25),
        base_y + Math.floor(ind / this.settings.swatches_per_line) * 27,
      ];
    } else if (this.settings.type == 'big') {
      base_x = 38;
      base_y = 126;
      return [
        Math.floor(base_x + (ind % this.settings.swatches_per_line) * 33),
        base_y + Math.floor(ind / this.settings.swatches_per_line) * 35,
      ];
    } else if (this.settings.type == 'vertical') {
      base_x = 28;
      base_y = 104;
      return [
        base_x + (ind % this.settings.swatches_per_line) * 28,
        base_y + Math.floor(ind / this.settings.swatches_per_line) * 28,
      ];
    }
  },

  /**
   *
   */
  addFavouritesColor: function(ind, noPlaceholdesRebuild) {
    var hex = this.favourites[ind];
    var coord = this.getFavouritesCoords(ind);

    var $obj = $(
      '<div data-tp="big" data-ind="' +
        ind +
        '" class="rmcolorbox-swatches-color-big" style="background:#' +
        hex +
        ';left:' +
        coord[0] +
        'px;top:' +
        coord[1] +
        'px"></div>'
    );
    this.$rmcolorbox_swatches_colors.append($obj);
    $obj.RMDrag({
      start: this.startColorDrag,
      move: this.moveColorDrag,
      end: this.endColorDrag,
      silent: true,
      preventDefault: true,
      threshold: 3,
    });
    if (!noPlaceholdesRebuild) this.createFSPlaceholdes();
    return $obj;
  },

  /**
   *
   */
  addSwatchesColor: function(ind, noPlaceholdesRebuild) {
    var hex = this.swatches[ind];
    var coord = this.getSwatchesCoords(ind);

    var $obj = $(
      '<div data-tp="small" data-ind="' +
        ind +
        '" class="rmcolorbox-swatches-color-small" style="background:#' +
        hex +
        ';left:' +
        coord[0] +
        'px;top:' +
        coord[1] +
        'px"></div>'
    );
    this.$rmcolorbox_swatches_colors.append($obj);
    $obj.RMDrag({
      start: this.startColorDrag,
      move: this.moveColorDrag,
      end: this.endColorDrag,
      silent: true,
      preventDefault: true,
      threshold: 3,
    });
    if (!noPlaceholdesRebuild) this.createFSPlaceholdes();
    return $obj;
  },

  /**
   *
   */
  startColorDrag: function(e) {
    var $target = $(e.currentTarget);
    $target.removeClass('rmcolorbox-swatches-color-moving');
    $target.css('z-index', 9999);
    this.colorDragData = {
      $target: $target,
      mx: e.pageX,
      my: e.pageY,
      cx: $target.position().left,
      cy: $target.position().top,
      left: $target.width() / 2 + 3,
      top: $target.height() / 2 + 3,
      right: this.$rmcolorbox_swatches_colors.width() - $target.width() / 2 - 3,
      bottom: this.$rmcolorbox_swatches_colors.height() - $target.height() / 2 - 4,
      container_top: this.$rmcolorbox_swatches_placeholdes_wrapper.offset().top,
      container_height: this.$rmcolorbox_swatches_placeholdes_wrapper.height(),
      orig_inner_top: this.$rmcolorbox_swatches_colors.position().top,
      cur_inner_top: this.$rmcolorbox_swatches_colors.position().top,
      wasMovement: false,
      fillExEy: function(x, y) {
        this.ex = this.cx + x - this.mx;
        this.ey = this.cy + y - this.my + (this.orig_inner_top - this.cur_inner_top);
        this.ex = Math.min(Math.max(this.ex, this.left), this.right);
        this.ey = Math.min(Math.max(this.ey, this.top), this.bottom);
      },
    };
  },

  /**
   *
   */
  moveColorDrag: function(e) {
    this.colorDragData.wasMovement = true;
    this.colorDragData.fillExEy(e.pageX, e.pageY);
    this.colorDragData.$target.css({ left: this.colorDragData.ex + 'px', top: this.colorDragData.ey + 'px' });
    this.checkAutoScroll(e.pageY);
  },

  /**
   *
   */
  checkAutoScroll: function(y) {
    var min_y = y - this.colorDragData.container_top;
    var max_y = this.colorDragData.container_top + this.colorDragData.container_height - y;
    if (min_y < 20) this.scrollBy(-2);
    if (max_y < 20) this.scrollBy(2);
    this.colorDragData.cur_inner_top = this.$rmcolorbox_swatches_colors.position().top;
  },

  /**
   *
   */
  unSelectColor: function() {
    this.$rmcolorbox_swatches_colors.find('.rmcolorbox-swatches-color-big').removeClass('selected');
    this.$rmcolorbox_swatches_colors.find('.rmcolorbox-swatches-color-small').removeClass('selected');
    this.$remove_from_swatches_button.removeClass('active');
  },

  /**
   *
   */
  selectColor: function($obj) {
    this.$remove_from_swatches_button.addClass('active');
    $obj.addClass('selected');
    if ($obj.attr('data-tp') == 'small') var rgb = this.hex2rgb(this.swatches[$obj.attr('data-ind')]);
    if ($obj.attr('data-tp') == 'big') rgb = this.hex2rgb(this.favourites[$obj.attr('data-ind')]);
    this.changeColor(undefined, rgb, 'swatches');
  },

  /**
   *
   */
  deleteColor: function(e) {
    if (!$(e.currentTarget).hasClass('active')) return;
    var $rem_obj = this.$rmcolorbox_swatches_colors.find(
      '.rmcolorbox-swatches-color-big.selected, .rmcolorbox-swatches-color-small.selected'
    );
    if ($rem_obj.length == 1) {
      $rem_obj.addClass('rmcolorbox-swatches-color-removing');
      var that = this;
      setTimeout(function() {
        if ($rem_obj.attr('data-tp') == 'small') {
          that.swatches[$rem_obj.attr('data-ind')] = undefined;
          that.onSwatchesChange();
        }
        if ($rem_obj.attr('data-tp') == 'big') {
          that.favourites[$rem_obj.attr('data-ind')] = undefined;
          that.onFavouritesChange();
        }
        $rem_obj.remove();
        that.createFSPlaceholdes();
      }, 300);
    }
    this.unSelectColor();
  },

  /**
   *
   */
  endColorDrag: function(e) {
    this.colorDragData.$target.css('z-index', 'auto');

    if (!this.colorDragData.wasMovement) {
      // не было сдвига - значит просто клик, тогглим выделения кружка
      var sel = this.colorDragData.$target.hasClass('selected');
      this.unSelectColor();
      if (!sel) this.selectColor(this.colorDragData.$target);
      return;
    }

    this.colorDragData.$target.addClass('rmcolorbox-swatches-color-moving');

    this.colorDragData.fillExEy(e.pageX, e.pageY);

    this.colorDragData.$target.css({ left: this.colorDragData.ex + 'px', top: this.colorDragData.ey + 'px' });

    for (var i = 0; i < this.settings.favourites_max; i++) {
      var coord = this.getFavouritesCoords(i);
      var length = Math.round(
        Math.sqrt(Math.pow(coord[0] - this.colorDragData.ex, 2) + Math.pow(coord[1] - this.colorDragData.ey, 2))
      );
      if (length <= 20) break;
    }

    for (var j = 0; j < this.settings.swatches_max; j++) {
      var coord = this.getSwatchesCoords(j);
      var length = Math.round(
        Math.sqrt(Math.pow(coord[0] - this.colorDragData.ex, 2) + Math.pow(coord[1] - this.colorDragData.ey, 2))
      );
      if (length <= 13) break;
    }

    var old_tp = this.colorDragData.$target.attr('data-tp');
    var old_ind = this.colorDragData.$target.attr('data-ind');
    var new_tp = old_tp;
    var new_ind = old_ind;

    if (i < this.settings.favourites_max) {
      new_tp = 'big';
      new_ind = i;
    }

    if (j < this.settings.swatches_max) {
      new_tp = 'small';
      new_ind = j;
    }

    var top_constraint = this.$rmcolorbox_swatches_placeholdes_wrapper.offset().top;
    var bottom_constraint =
      this.$rmcolorbox_swatches_placeholdes_wrapper.offset().top +
      this.$rmcolorbox_swatches_placeholdes_wrapper.height();

    if (e.pageY < top_constraint || e.pageY >= bottom_constraint) {
      new_tp = old_tp;
      new_ind = old_ind;
    }

    this.moveColorToInd(this.colorDragData.$target[0], old_tp, old_ind, new_tp, new_ind);
  },

  /**
   *
   */
  moveColorToInd: function(obj, tp1, ind1, tp2, ind2) {
    if (tp1 == tp2 && ind1 == ind2) {
      if (tp1 == 'big') var coord = this.getFavouritesCoords(ind1);
      if (tp1 == 'small') var coord = this.getSwatchesCoords(ind1);
      $(obj).css({ left: coord[0] + 'px', top: coord[1] + 'px' });
      return;
    }

    if (tp1 == 'big') {
      var $obj1 = this.$('.rmcolorbox-swatches-color-big[data-ind=' + ind1 + ']').removeClass(
        'rmcolorbox-swatches-color-big'
      );
      var coord1 = this.getFavouritesCoords(ind1);
      var arr1 = this.favourites;
    } else {
      var $obj1 = this.$('.rmcolorbox-swatches-color-small[data-ind=' + ind1 + ']').removeClass(
        'rmcolorbox-swatches-color-small'
      );
      var coord1 = this.getSwatchesCoords(ind1);
      var arr1 = this.swatches;
    }

    if (tp2 == 'big') {
      var $obj2 = this.$('.rmcolorbox-swatches-color-big[data-ind=' + ind2 + ']').removeClass(
        'rmcolorbox-swatches-color-big'
      );
      var coord2 = this.getFavouritesCoords(ind2);
      var arr2 = this.favourites;
    } else {
      var $obj2 = this.$('.rmcolorbox-swatches-color-small[data-ind=' + ind2 + ']').removeClass(
        'rmcolorbox-swatches-color-small'
      );
      var coord2 = this.getSwatchesCoords(ind2);
      var arr2 = this.swatches;
    }

    $obj1
      .css({ left: coord2[0] + 'px', top: coord2[1] + 'px' })
      .addClass('rmcolorbox-swatches-color-' + tp2)
      .addClass('rmcolorbox-swatches-color-moving')
      .attr('data-tp', tp2)
      .attr('data-ind', ind2);

    $obj2
      .css({ left: coord1[0] + 'px', top: coord1[1] + 'px' })
      .addClass('rmcolorbox-swatches-color-' + tp1)
      .addClass('rmcolorbox-swatches-color-moving')
      .attr('data-tp', tp1)
      .attr('data-ind', ind1);

    var tmp = arr2[ind2];
    arr2[ind2] = arr1[ind1];
    arr1[ind1] = tmp;

    if (arr1 == this.favourites || arr2 == this.favourites) this.onFavouritesChange();
    if (arr1 == this.swatches || arr2 == this.swatches) this.onSwatchesChange();

    this.createFSPlaceholdes();
  },

  /**
   *
   */
  createFSPlaceholdes: function(noscroll) {
    this.$rmcolorbox_swatches_placeholdes.empty();

    for (var i = 0; i < this.settings.favourites_max; i++) {
      var coord = this.getFavouritesCoords(i);
      this.$rmcolorbox_swatches_placeholdes.append(
        '<div class="rmcolorbox-swatches-placeholder-big" style="left:' + coord[0] + 'px;top:' + coord[1] + 'px"></div>'
      );
    }

    var swatches_max = 0;
    for (i = 0; i < this.swatches.length; i++) if (this.swatches[i]) swatches_max = i;

    swatches_max += this.settings.swatches_per_line;
    swatches_max = Math.max(Math.min(swatches_max, this.settings.swatches_max - 1), this.settings.swatches_min - 1);
    swatches_max =
      Math.floor(swatches_max / this.settings.swatches_per_line) * this.settings.swatches_per_line +
      this.settings.swatches_per_line;

    var max_y = 0;
    for (i = 0; i < swatches_max; i++) {
      coord = this.getSwatchesCoords(i);
      if (coord[1] > max_y) max_y = coord[1];
      this.$rmcolorbox_swatches_placeholdes.append(
        '<div class="rmcolorbox-swatches-placeholder-small" style="left:' +
          coord[0] +
          'px;top:' +
          coord[1] +
          'px"></div>'
      );
    }

    var old_h = this.$rmcolorbox_swatches_colors.height();

    var new_h = max_y + (this.settings.type != 'vertical' ? 17 : 14);
    this.$rmcolorbox_swatches_placeholdes.height(new_h);
    this.$rmcolorbox_swatches_colors.height(new_h);

    if (new_h != old_h) this.recalcScrollhandle(noscroll);
  },

  /**
   *
   */
  recalcScrollhandle: function(noscroll) {
    this.scrollData.container_h = this.$rmcolorbox_swatches_placeholdes_wrapper.height();
    this.scrollData.inner_h = this.$rmcolorbox_swatches_colors.height();
    this.$scroll_handle.toggleClass('hidden', this.scrollData.inner_h <= this.scrollData.container_h);

    this.scrollData.height =
      (this.scrollData.container_h / 2) * (this.scrollData.container_h / this.scrollData.inner_h);
    this.scrollData.height = Math.max(this.scrollData.height, 20) + 2;

    this.scrollTo(noscroll ? 0 : 1, true);
  },

  /**
   *
   */
  scrollTo: function(percent, animation, speed) {
    speed = speed || 300;
    percent = Math.max(Math.min(percent, 1), 0);
    this.scrollData.percent = percent;

    var scroll_top = percent * (this.scrollData.container_h - this.scrollData.height);
    var top = -percent * (this.scrollData.inner_h - this.scrollData.container_h);

    if (!animation) {
      this.$scroll_handle.css({ top: scroll_top + 'px', height: this.scrollData.height - 2 + 'px' });
      this.$rmcolorbox_swatches_colors.css('top', top + 'px');
      this.$rmcolorbox_swatches_placeholdes.css('top', top + 'px');
    } else {
      this.$scroll_handle
        .stop()
        .animate({ top: scroll_top + 'px', height: this.scrollData.height - 2 + 'px' }, speed, 'linear');
      this.$rmcolorbox_swatches_colors.stop().animate({ top: top + 'px' }, speed, 'linear');
      this.$rmcolorbox_swatches_placeholdes.stop().animate({ top: top + 'px' }, speed, 'linear');
    }
  },

  /**
   *
   */
  scrollBy: function(offset, animation, speed) {
    if (this.scrollData.inner_h <= this.scrollData.container_h) return;
    var one_px = 1 / (this.scrollData.inner_h - this.scrollData.container_h);
    this.scrollTo(this.scrollData.percent + one_px * offset, animation, speed);
  },

  /**
   *
   */
  onScrollMouseWheel: function(e, d) {
    e.preventDefault();

    this.scrollBy(-d / 4, true, 250);

    return false;
  },

  /**
   *
   */
  startScrollDrag: function(e) {
    var $target = $(e.currentTarget);
    $target.addClass('dragging');
    this.scrollDragData = {
      $target: $target,
      my: e.pageY,
      cy: $target.position().top,
      top: 0,
      bottom: this.scrollData.container_h - this.scrollData.height - 2,
      fillEy: function(y) {
        this.ey = this.cy + y - this.my;
        this.ey = Math.min(Math.max(this.ey, this.top), this.bottom);
        this.percent = (this.ey - this.top) / (this.bottom - this.top);
      },
    };
  },

  /**
   *
   */
  moveScrollDrag: function(e) {
    this.scrollDragData.fillEy(e.pageY);
    this.scrollTo(this.scrollDragData.percent, false);
  },

  /**
   *
   */
  stopScrollDrag: function(e) {
    this.scrollDragData.$target.removeClass('dragging');
  },

  /**
   * Уничтожаем колорбокс
   */
  destroy: function() {
    this.model && this.model.off('change:swatches', this.onModelSwatchesChange);
    this.model && this.model.off('change:fav_colors', this.onModelFavouritesChange);
    Events.off('panel:showed', this.setCurrentSelectedColor);
    this.$el.remove();
  },

  /**
   * Проверяет, близок ли цвет 1 к цвету 2
   * @param {Array} rgba1 Цвет в формате rgba
   * @param {Array} rgba2 Цвет в формате rgba
   * @returns {boolean}
   */
  areColorsClose: function(rgba1, rgba2) {
    var hsb1 = this.rgb2hsb(rgba1);
    var hsb2 = this.rgb2hsb(rgba2);
    return (
      Math.abs(hsb1[0] - hsb2[0]) <= 5 &&
      Math.abs(hsb1[1] - hsb2[1]) <= 33 &&
      Math.abs(hsb1[2] - hsb2[2]) <= 5 &&
      Math.abs(rgba1[3] - rgba2[3]) <= 0.06
    );
  },

  /**
   * Вспомогательная функция, преобразование 'FF0000' в [255, 0, 0]
   */
  hex2rgb: function(param) {
    return [
      parseInt(param.substring(0, 2), 16),
      parseInt(param.substring(2, 4), 16),
      parseInt(param.substring(4, 6), 16),
    ];
  },

  /**
   * Вспомогательная функция, преобразование [255, 0, 0] в 'FF0000'
   */
  rgb2hex: function(param) {
    var p0 = param[0].toString(16);
    var p1 = param[1].toString(16);
    var p2 = param[2].toString(16);
    return (p0.length == 1 ? '0' + p0 : p0) + (p1.length == 1 ? '0' + p1 : p1) + (p2.length == 1 ? '0' + p2 : p2);
  },

  /**
   * Вспомогательная функция, преобразование цвета из формата HSB в RGB, на входе и на выходе массивы из 3-х элементов
   * HSB у нас везде в формате [0-359, 0-100, 0-100]
   */
  hsb2rgb: function(param) {
    var hsb = [param[0], param[1], param[2]];
    var red, grn, blu, i, f, p, q, t;
    hsb[0] %= 360;
    if (hsb[2] == 0) return [0, 0, 0];
    hsb[1] /= 100;
    hsb[2] /= 100;
    hsb[0] /= 60;
    i = Math.floor(hsb[0]);
    f = hsb[0] - i;
    p = hsb[2] * (1 - hsb[1]);
    q = hsb[2] * (1 - hsb[1] * f);
    t = hsb[2] * (1 - hsb[1] * (1 - f));
    if (i == 0) {
      red = hsb[2];
      grn = t;
      blu = p;
    } else if (i == 1) {
      red = q;
      grn = hsb[2];
      blu = p;
    } else if (i == 2) {
      red = p;
      grn = hsb[2];
      blu = t;
    } else if (i == 3) {
      red = p;
      grn = q;
      blu = hsb[2];
    } else if (i == 4) {
      red = t;
      grn = p;
      blu = hsb[2];
    } else if (i == 5) {
      red = hsb[2];
      grn = p;
      blu = q;
    }
    red = Math.floor(red * 255);
    grn = Math.floor(grn * 255);
    blu = Math.floor(blu * 255);
    return [red, grn, blu];
  },

  /**
   * Вспомогательная функция, преобразование цвета из формата RGB в HSB, на входе и на выходе массивы из 3-х элементов
   * HSB у нас везде в формате [0-359, 0-100, 0-100]
   */
  rgb2hsb: function(param) {
    var rgb = [param[0], param[1], param[2]];
    var x, f, i, hue, sat, val;
    rgb[0] /= 255;
    rgb[1] /= 255;
    rgb[2] /= 255;
    x = Math.min(rgb[0], rgb[1], rgb[2]);
    val = Math.max(rgb[0], rgb[1], rgb[2]);
    if (x == val) return [180, 0, val * 100];
    f = rgb[0] == x ? rgb[1] - rgb[2] : rgb[1] == x ? rgb[2] - rgb[0] : rgb[0] - rgb[1];
    i = rgb[0] == x ? 3 : rgb[1] == x ? 5 : 1;
    hue = Math.floor((i - f / (val - x)) * 60) % 360;
    sat = Math.floor(((val - x) / val) * 100);
    val = Math.floor(val * 100);
    return [hue, sat, val];
  },

  /**
   * After user selects swatches, we need to highlight selected color
   */
  setCurrentSelectedColor() {
    const currentHex = this.rgb2hex(this.RGB);
    const currentColorInFavourites = this.favourites.indexOf(currentHex);
    const currentColorInSwatches = this.swatches.indexOf(currentHex);

    let $el = null;

    if (currentColorInFavourites > -1) {
      $el = this.$rmcolorbox_swatches_colors.find(
        `.rmcolorbox-swatches-color-big[data-ind='${currentColorInFavourites}']`
      );
    }

    if (currentColorInSwatches > -1) {
      $el = this.$rmcolorbox_swatches_colors.find(
        `.rmcolorbox-swatches-color-small[data-ind='${currentColorInSwatches}']`
      );
    }

    if ($el) {
      this.$rmcolorbox_swatches_colors.find('.rmcolorbox-swatches-color-big').removeClass('selected');
      this.$rmcolorbox_swatches_colors.find('.rmcolorbox-swatches-color-small').removeClass('selected');
      $el.addClass('selected');
    }
  },
});

export default Colorbox;
