module.exports = {["template-common-alert"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	';

		var categories = {
			'type-duplicate':				'category-upgrade',
			'type-create':					'category-upgrade',
			'type-export-pdf':				'category-upgrade',
			'type-export-code':				'category-upgrade',
			'type-more-pages':				'category-upgrade',
			'type-upload-fonts':			'category-upgrade',
			'type-custom-seo':				'category-upgrade',
			'type-custom-sharing':			'category-upgrade',
			'type-code-injection':			'category-upgrade',
			'type-publish-noemail':			'category-confirm-email',
			'type-publish-limit':			'category-upgrade',
			'type-switch-desktop-create':	'category-mobile',
			'type-switch-desktop-continue':	'category-mobile',
			'type-browsers':				'category-browsers',
			'type-browsers-viewer':				'category-browsers',
			'type-shared-failed':			'category-shared-failed',
			'type-shared-unlock-page':		'category-shared-unlock-page',
		};

		var headers = {
			'category-upgrade':			'',
			'category-upgrade-skip':	'Push the Limits',
			'category-browsers':		'Browser Support',
			'category-shared-failed':	'Sorry, something<br/>went wrong.',
			'category-confirm-email':	'Account Activation',
		};

		var texts = {
			'type-duplicate':				'To duplicate this project you need to upgrade your account. You can find out more about our subscription plans <a href="/pricing" class="learn-more">here</a>.',
			'type-create':					'To create more projects you need to upgrade your account. You can find out more about our subscription plans <a href="/pricing" class="learn-more">here</a>.',
			'type-publish-noemail':			'Please verify your email address<br />to publish this project.',
			'type-publish-limit':			'Please upgrade your account<br />to publish more than one project.',
			'type-export-pdf':				'Please upgrade your account<br/>to export the project to PDF.<br/><a href="/pricing" class="learn-more">See the pricing</a>.',
			'type-export-code':				'Please upgrade your account<br/>in order to export the source<br/>code of your projects.<br/><a href="/pricing" class="learn-more">See the pricing</a>.',
			'type-more-pages':				'Please upgrade your account<br/>to create more pages.<br><a href="/pricing" class="learn-more">See the pricing</a>.',
			'type-custom-seo':				'Please upgrade your account<br/> to customize SEO parameters.<br/><a href="/pricing" class="learn-more">See the pricing</a>.',
			'type-custom-sharing':			'Please upgrade your account<br/>to customize social sharing info.<br/><a href="/pricing" class="learn-more">See the pricing</a>.',
			'type-code-injection':			'Please upgrade your account to<br/>make custom code work after<br/>you publish the project.<br/><a href="/pricing" class="learn-more">See the pricing</a>.',
			'type-browsers':				'Sorry, our Editor doesn’t support Internet Explorer. This may change in the future, but for now please use Chrome, Firefox or Safari.',
			'type-browsers-viewer':			'This site can\'t be displayed in Internet Explorer. Please switch to <a href="https://support.apple.com/en-jo/HT204416">Safari</a>, <a href="https://www.google.com/chrome/">Google Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>, or <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a>.',
			'type-switch-desktop-create':	'To create a project, please use your laptop or desktop computer.',
			'type-switch-desktop-continue':	'To continue, please use your laptop or desktop computer.',
			'type-shared-failed':			'Please contact <a href="mailto:support@readymag.com" class="learn-more">support</a>.',
			'type-upload-fonts':			'Please upgrade your account<br/>to upload your own fonts.<br/><a href="/pricing" class="learn-more">See the pricing</a>.',
		};

		var buttons = {
			'category-upgrade':				'<a href="/settings#change-plan" class="button main upgrade">Upgrade</a><div class="cancel-wrapper">or ' + (opts.type == 'type-code-injection' ? '<span class="button cancel" data-type="skip">Skip</span>' : '<span class="button cancel">Cancel</span>') + '</div>',
			'category-browsers':			'<div class="button main ok">Okay</div>',
			'category-mobile':				'<div class="button main ok">Okay</div>',
			'category-shared-failed':		'<div class="button close">Close</div>',
			'category-shared-unlock-page':	'<div class="button ok">Ok</div>',
			'category-confirm-email':		'<div  class="button main resend">Resend</div><div class="cancel-wrapper">or <span class="button cancel">Cancel</span></div>',
		};

		var category = categories[opts.type]
		var header = headers[category]
		var text = texts[opts.type] || opts.text
		var button = buttons[category]

		if (opts.is_contributor) {
			text = 'Please upgrade owner’s account<br/>to be able to use this feature.'
			button = '<a href="mailto:' + opts.owner_email + '" class="contact-link">Contact owner</a><div class="cancel-wrapper">' + (opts.type == 'type-code-injection' ? 'or <span class="button cancel" data-type="skip">Skip</span>' : '<span class="button cancel">Cancel</span>') + '</div>'
		}

	
__p+='\n\n	<div class="alert-popup '+
((__t=(category))==null?'':__t)+
'">\n		<div class="panel-wrapper">\n			<div class="center-table">\n				<div class="center-cell">\n					<div class="panel">\n\n						'+
((__t=((header ? '<div class="header">' + header + '</div>' : '')))==null?'':__t)+
'\n\n						<div class="icon" '+
((__t=((opts.icon ? 'style="background-image:url(' + opts.icon + ')"' : "")))==null?'':__t)+
'><div class="icon-inner"></div></div>\n\n						'+
((__t=((text ? '<div class="text">' + text + '</div>' : '')))==null?'':__t)+
'\n\n						'+
((__t=((opts.type == 'type-browsers-viewer' ? '<div class="icon additional"></div>' : '')))==null?'':__t)+
'\n\n						'+
((__t=((button ? '<div class="buttons">' + button + '</div>' : '')))==null?'':__t)+
'\n\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n';
}
return __p;
},}