/**
 * Общие функции для асинхронной инициализации разных API/
 * Функции ожно вызывать много раз. При этом коллбэки становятся в очередь
 * и вызываются после окончания инициализации
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import { Constants, Utils } from './utils';
import Events from './events';

var googleMapsAPICallbacks = [];
var facebookAPICallbacks = [];
var twitterAPICallbacks = [];
var mailchimpAPICallbacks = [];

// Глобальная обработка ошибки авторизации gmaps
// срабатывает, когда ключ неверный
window.googleMapsAPIAuthError = false;
window.gm_authFailure = () => {
  Events.trigger('gmaps:auth_error', true);
  window.googleMapsAPIAuthError = true;
};

var gmapsUrl = '//maps.googleapis.com/maps/api/js';

const InitUtils = {
  initGMapsAPI: function(apiKey, callback) {
    // Если мы перешли из вьювера в конструктор или наоборот, а ключ gmaps установлен неверный,
    // тогда нужно сбросить карту и загрузить заново, иначе виджет будет пустой
    if (window.googleMapsAPIAuthError && apiKey) {
      InitUtils.destroyGMapsAPI();
    }

    if (window.google && window.google.maps) {
      return callback();
    }

    if (!window.processGoogleMapsAPICallbacks) {
      window.processGoogleMapsAPICallbacks = function() {
        _.each(googleMapsAPICallbacks, function(cb) {
          if (_.isFunction(cb)) {
            cb();
          }
        });
      };

      var script = document.createElement('script');
      script.type = 'text/javascript';

      var paramsObject = {
        libraries: 'places',
        callback: 'processGoogleMapsAPICallbacks',
      };

      if (apiKey) {
        paramsObject.key = apiKey;
      } else {
        // Если нет ключа > будет ошибка авторизации,
        // можем задать ее сами, чтобы не пытаться подключить несколько версий gmaps api
        window.googleMapsAPIAuthError = true;
      }

      var params = Constants.IS_FILE_PROTOCOL ? '' : $.param(paramsObject);
      script.src = Utils.selectProtocol(gmapsUrl + '?' + params);
      document.body.appendChild(script);
    }

    googleMapsAPICallbacks.push(callback);
  },

  // Удаляем все копии скриптов gmaps
  destroyGMapsAPI: function() {
    var scripts = document.querySelectorAll("script[src*='" + gmapsUrl + "']");
    for (var i = 0; i < scripts.length; i++) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }

    if (window.google && window.google.maps) {
      delete window.google.maps;
    }

    delete window.processGoogleMapsAPICallbacks;
    googleMapsAPICallbacks = [];
    window.googleMapsAPIAuthError = false;
  },

  initFacebookAPI: function(callback) {
    var $fb_root,
      MAX_COUNTER = 100,
      TICK = 100,
      interval,
      counter = 0,
      app_id = Constants.FACEBOOK_APP_ID || '';

    if (!(typeof callback === 'function')) {
      return;
    }

    if (InitUtils.isFacebookInited) {
      return callback();
    }

    facebookAPICallbacks.push(callback);

    // Добавляем нужный фэйсбуку div к телу страницы
    $fb_root = $('#fb-root');
    if (!$fb_root.length) {
      $('body').prepend($('<div id="fb-root"></div>'));
    }

    // Асинхронный коллбэк фэйсбука. Вызывается, когда sdk загружен
    window.fbAsyncInit = function() {
      FB.init({
        appId: app_id,
        xfbml: false, // Сразу не парсим виджеты
        version: 'v' + Constants.FACEBOOK_API_VERSION,
      });

      _.each(facebookAPICallbacks, function(cb) {
        _.isFunction(cb) && cb();
      });

      InitUtils.isFacebookInited = true;
    };

    // Оригинальный код фэйсбука для подключения скрипта к странице
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = Utils.selectProtocol('//connect.facebook.net/en_US/sdk.js');
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  },

  initFacebookPixel: function() {
    return new Promise(resolve => {
      if (InitUtils.isFacebookPixelInited) {
        return resolve();
      }

      // Оригинальный код фэйсбука для подключения пиксела к странице.
      // Код сам умеет разруливать очередь вызовов при еще недогруженном скрипте,
      // по-этому сразу резолвим
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', Constants.FACEBOOK_PIXEL_ID);

      InitUtils.isFacebookPixelInited = true;

      return resolve();
    });
  },

  initTwitterAPI: function(callback) {
    if (InitUtils.isTwitterInited) {
      _.isFunction(callback) && callback();
      return;
    } else {
      _.isFunction(callback) && twitterAPICallbacks.push(callback);
    }

    if (InitUtils.isTwitterLoading) {
      return;
    }

    InitUtils.isTwitterLoading = true;

    $('body > script[src^="//syndication.twimg.com/tweets.json"]').remove(); // Удаляем предыдущие недокачанные, неудаленные скрипты из тела страницы.
    $.ajax({
      url: Utils.selectProtocol('//platform.twitter.com/widgets.js'),
      dataType: 'script',
      cache: false,
      success: function() {
        _.each(twitterAPICallbacks, function(cb) {
          _.isFunction(cb) && cb();
        });

        // По клику забираем фокус у ифремйма твиттера и отдаем странице
        twttr.events.bind('click', Utils.getFocusBack);

        InitUtils.isTwitterInited = true;
        InitUtils.isTwitterLoading = false;
      },
    });
  },

  initMailchimpAPI: function(callback) {
    if (InitUtils.isMailchimpInited) {
      _.isFunction(callback) && callback(InitUtils.mailchimp);
      return;
    } else {
      _.isFunction(callback) && mailchimpAPICallbacks.push(callback);
    }

    if (InitUtils.isMailchimpLoading) {
      return;
    }

    InitUtils.isMailchimpLoading = true;

    $.ajax({
      url: Utils.selectProtocol('//s3.amazonaws.com/downloads.mailchimp.com/js' + '/signup-forms/popup/embed.js'),
      dataType: 'script',
      cache: false,
      success: function() {
        if (!window.require) {
          mailchimpAPICallbacks = [];
          return;
        }

        window.require(['mojo/signup-forms/Loader'], function(L) {
          InitUtils.mailchimp = L;

          _.each(mailchimpAPICallbacks, function(cb) {
            _.isFunction(cb) && cb(InitUtils.mailchimp);
          });

          InitUtils.isMailchimpInited = true;
        });
      },
      complete: function() {
        InitUtils.isMailchimpLoading = false;
      },
    });
  },
};

export default InitUtils;
