import _ from '@rm/underscore';

/**
 * Глобальный объект
 *
 * TODO: почистить объект, в идеале избавиться от него
 */
window.RM = {
  models: {}, // здесь храняться прототипы моделей (для конструктора, главной, коллектора и вьювера)
  collections: {}, // здесь храняться прототипы коллекций (для конструктора, главной, коллектора и вьювера)
  classes: {}, // здесь храняться прототипы вьюх и других объектов (для конструктора, главной, коллектора и вьювера)
  blocks: {}, // здесь храняться прототипы блоков (для конструктора)
  controls: {}, // здесь храняться прототипы контролов блоков (для конструктора)
  panels: {}, // здесь храняться прототипы панелей виджетбара (для конструктора)
  widgets: {}, // здесь храняться прототипы виджетов (для вьювера)
  views: {}, // здесь храняться уже инстансы некоторых вьюх (для конструктора, главной, коллектора и вьювера)
  data: {}, // здесь храняться уже инстансы некоторых моделей (для конструктора, главной, коллектора и вьювера)
  common: {
    // всякая общая инфа, сейчас там только переменная mode (для конструктора, главной, коллектора и вьювера)
    disableShortcuts: {}, // объект в который все желающие записывают свое имя для блокировки глобальных шорткатов (шорткаты блокируются если объект не пустой)
  },
  templates: {}, // скомпилированные underscore темплейты
  views: {},
  customAnalyticsHandlers: {
    track: [],
    event: [],
  },
  ...(window.RM || {}),
};

/**
 * Request animation frame
 */
var lastTime = 0;
var vendors = ['ms', 'moz', 'webkit', 'o'];
for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
  window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
  window.cancelAnimationFrame =
    window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
}
if (!window.requestAnimationFrame)
  window.requestAnimationFrame = function(callback, element) {
    var currTime = new Date().getTime();
    var timeToCall = Math.max(0, 16 - (currTime - lastTime));
    var id = window.setTimeout(function() {
      callback(currTime + timeToCall);
    }, timeToCall);
    lastTime = currTime + timeToCall;
    return id;
  };

if (!window.cancelAnimationFrame)
  window.cancelAnimationFrame = function(id) {
    clearTimeout(id);
  };
