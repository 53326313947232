import $ from '@rm/jquery';
import Vue from 'vue';
import BlockClass from '../block';
import RmHeader from '../../../components/common/rm-header.vue';

const rmHeader = BlockClass.extend(
  {
    name: 'RM Header',
    thumb: 'rm-header',
    icon_color: '#282828',

    initial_controls: ['rmheader_settings', 'common_position', 'common_lock'],

    restrictions: function() {
      const permissions = RM.constructorRouter && RM.constructorRouter.me && RM.constructorRouter.me.get('permissions');
      return permissions && permissions.can_use_rm_widgets;
    },

    initialize: function(model, workspace) {
      this.initBlock(model, workspace);
    },

    render: function() {
      this.create();
      this.$el.addClass('rm-header');
      this.controls = this.initial_controls;

      const rmHeaderVue = Vue.extend(RmHeader);
      this.vue = new rmHeaderVue({
        el: this.$content.get(0),
        propsData: {
          activeLink: this.model.get('activeLink'),
        },
      });

      this.bindModelEvents();
      this.fixMobileViewport();

      this.rendered = true;

      this.triggerReady();
    },

    redraw: function() {
      this.fixMobileViewport();
      return BlockClass.prototype.redraw.apply(this, arguments);
    },

    bindModelEvents: function() {
      this.model.on('change:activeLink', this.onActiveLinkChange, this);
    },

    unbindModelEvents: function() {
      this.model.off('change:activeLink', this.onActiveLinkChange, this);
    },

    onActiveLinkChange: function(e) {
      this.vue.activeLink = e.get('activeLink');
      this.model.save();
    },

    destroy: function() {
      this.unbindModelEvents();

      if (this.vue) {
        this.vue.destroyMobile();
        this.vue.$destroy();
      }

      return BlockClass.prototype.destroy.apply(this, arguments);
    },

    /**
     * Фикс мобильных стилей в превью
     *
     * Если зайти в превью, то будет показываться десктоп стиль,
     * т.к. мы ориентируемся на @media width
     * Эта функция добавляет необходимый стиль
     */
    fixMobileViewport: function() {
      const isDesktop = this.model.getViewport() === 'default';

      $('body').toggleClass('rm-header-mobile', !isDesktop);
    },
  },
  {
    defaults: {
      activeLink: 'none',
      full_width_initial_w: 480,
      full_width_initial_x: 0,
      full_width_margin: 0,
      is_full_width: true,
      fixed_position: 'n',
      is_above: true,
      is_global: true,
      h: 72,
      x: 0,
      y: 0,
      w: $(window).width(),
    },
  }
);

export default rmHeader;
