<template>
  <div class="selector" :class="{ opened: isOpened }" @click="onClick">
    <div class="types-popup">
      <div
        v-for="option in options"
        class="type-item"
        :key="option.value"
        :class="{
          curr: option.value === value,
          disabled: !!option.disabled,
        }"
        @click="emitValue(option)"
        :title="showTitles ? option.caption : ''"
      >
        <slot name="listitem" v-bind:option="option" v-bind:value="value">
          {{ option.caption }}
          <div class="point"></div>
        </slot>
      </div>
    </div>
    <div class="caption">
      <div class="caption-inner">{{ caption }}</div>
    </div>
    <div v-if="this.arrowSize === 'small'" class="popup-arrow"></div>
    <div v-if="this.arrowSize === 'big'" class="popup-arrow-big">
      <svg width="12" height="7" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.24 6.114L1.416 1.651A1 1 0 0 1 2.175 0h7.65a1 1 0 0 1 .76 1.65L6.759 6.115a1 1 0 0 1-1.518 0z"
          fill-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</template>

<script>
// Дропдаун. Ожидает на входе в свойство options массив из объектов вида
// [{value: <уникальный айдишник айтема>, caption: <текст пункта айтема>}]
import _ from '@rm/underscore';

export default {
  data: function() {
    return {
      isOpened: false,
    };
  },

  props: {
    value: {},
    options: {
      type: Array,
      default: [],
    },
    arrowSize: {
      default: 'small',
    },
    showTitles: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    caption: function() {
      return (_.findWhere(this.options, { value: this.value }) || {}).caption;
    },
  },

  methods: {
    onClick: function(e) {
      this.isOpened = !this.isOpened;
    },

    emitValue: function(option) {
      if (!option.disabled) {
        this.$emit('input', option.value);
        this.callback(option);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import (reference) '../../css/common/constants.less';
@import (reference) '../../css/common/fonts.less';

@color: #44444c;
@height: 32px;

.selector {
  position: relative;
  margin: 12px auto 0;
  width: 100%;
  height: @height;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
  color: @color;
  .transition(0.1s);

  &:hover {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.24);
    .popup-arrow {
      opacity: 1;
    }

    .popup-arrow-big path {
      fill: #282828;
    }
  }

  .caption {
    text-align: center;
    .avenir_medium;
    font-size: 14px;
  }

  .caption-inner {
    .border-box;
    line-height: 100%;
    padding: 0 20px;
    line-height: @height;
    .overflow-ellipsis;
  }

  .popup-arrow {
    position: absolute;
    top: 13px;
    right: 9px;
    // .drop-triangle (7px, 5px, @color);
    .drop-triangle (6px, 4px, @color);
    opacity: 0.24;
    .transition(0.1s, opacity);
  }

  .popup-arrow-big {
    position: absolute;
    right: 0px;
    top: 50%;
    margin-right: 6px;
    transform: translateY(-50%);

    path {
      fill: #b8b8b8;
      .transition(0.1s, fill);
    }
  }

  &.opened .types-popup {
    .transition(0.2s, opacity, ease-in-out);
    opacity: 1;
    visibility: visible;
  }

  .types-popup {
    .transition(0.2s, all, ease-in-out);
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0666);
    background: rgba(255, 255, 255, 0.96);
    border-radius: 3px;
    text-align: left;
    width: 100%;

    .type-item {
      position: relative;
      padding: 8px 13px 8px 21px;
      font-size: 14px;
      .overflow-ellipsis;
      cursor: pointer;
      .avenir_medium;

      .point {
        position: absolute;
        width: 6px;
        height: 6px;
        left: 9px;
        top: 12px;
      }

      &:hover .point {
        background: url('../../img/constructor/panels/twitter/point-hover.png') no-repeat 0 0;
      }

      &.curr .point {
        background: url('../../img/constructor/panels/twitter/point-active.png') no-repeat 0 0 !important;
      }

      &.disabled {
        opacity: 0.7;
        cursor: default;

        &:hover .point {
          background: none;
        }
      }
    }
  }
}
</style>
