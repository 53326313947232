/**
 * Контрол эффектов к изображениям
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/shape_1x1.tpl';

const Shape1x1 = ControlClass.extend({
  name: 'shape_1x1',

  className: 'control shape_1x1',

  initialize: function(params) {
    this.template = templates['template-constructor-control-shape_1x1'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;

    this.onScaleChange.__debounced = _.debounce(this.onScaleChange, 100);
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.model.on('change', this.onScaleChange.__debounced);

    this.onScaleChange();
  },

  unBindLogic: function() {
    this.model.off('change', this.onScaleChange.__debounced);
  },

  onScaleChange: function() {
    if (!this.model) {
      return;
    }

    var inactive = this.model.get('h') == this.model.get('w');

    this.$el.toggleClass('inactive', inactive);
  },

  onClick: function() {
    if (this.$el.hasClass('inactive')) return;

    this.block.makeSquare();
    this.model.save();
    this.master.deselect();
  },

  // Показываем контрол, только если блок не растянут
  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();
    return blocks.length && !blocks[0].isFullWidth() && !blocks[0].isFullHeight();
  },
});

export default Shape1x1;
