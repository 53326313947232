/**
 * Набор полезных функций и констант для работы с текстом
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import { Utils, Constants } from './utils';
import Viewports from './viewports';
import templates from '../../templates/screenshoter/export-pdf-typekit-substitution.tpl';

/**
 * Интервал для проверки, загружен ли шрифт, в милисекундах
 * @type {number}
 */
var FONT_LOAD_CHECK_INTERVAL = 100;

/**
 * Максимальное время ожидания загрузки шрифта, в милисекундах, для проверки с помощью canvas (используется в скриншотере)
 * @type {number}
 */
var FONT_LOAD_CANSVAS_WAIT_TIME = 10000;

/**
 * Максимальное время ожидания загрузки шрифта, в милисекундах, для проверки через font loading api (используется во вьюере)
 * @type {number}
 */
var FONT_LOAD_API_WAIT_TIME = 3500;

const TextUtils = {
  // префиксы для стилей текстового виджета
  // отдельно для вьювера, самого редактора (в ифрейме) и для конструктора
  // для конструктора их два: для превью текстового виджета (когда он не в режиме редактирования) и для самого списка стилей в контроле стилей
  STYLE_PREFIXES: {
    paragraph: {
      viewer: ['.used-fonts-test p', '.rmwidget.text div p'],
      editor: ['p'],
      constructor: [
        '.used-fonts-test p',
        '.block.block-text .text-preview p',
        '.controls .control.text_styles .panel .resizable-scroll-wrapper .resizable-content-wrapper .resizable-content .paragraph-style .style-caption',
      ],
    },

    'header-h1': {
      viewer: ['.used-fonts-test h1', '.rmwidget.text div h1'],
      editor: ['h1'],
      constructor: [
        '.used-fonts-test h1',
        '.block.block-text .text-preview h1',
        '.controls .control.text_styles .panel .resizable-scroll-wrapper .resizable-content-wrapper .resizable-content .paragraph-style .style-caption',
      ],
    },

    'header-h2': {
      viewer: ['.used-fonts-test h2', '.rmwidget.text div h2'],
      editor: ['h2'],
      constructor: [
        '.used-fonts-test h2',
        '.block.block-text .text-preview h2',
        '.controls .control.text_styles .panel .resizable-scroll-wrapper .resizable-content-wrapper .resizable-content .paragraph-style .style-caption',
      ],
    },

    'header-h3': {
      viewer: ['.used-fonts-test h3', '.rmwidget.text div h3'],
      editor: ['h3'],
      constructor: [
        '.used-fonts-test h3',
        '.block.block-text .text-preview h3',
        '.controls .control.text_styles .panel .resizable-scroll-wrapper .resizable-content-wrapper .resizable-content .paragraph-style .style-caption',
      ],
    },

    'header-h4': {
      viewer: ['.used-fonts-test h4', '.rmwidget.text div h4'],
      editor: ['h4'],
      constructor: [
        '.used-fonts-test h4',
        '.block.block-text .text-preview h4',
        '.controls .control.text_styles .panel .resizable-scroll-wrapper .resizable-content-wrapper .resizable-content .paragraph-style .style-caption',
      ],
    },

    link: {
      viewer: ['.rmwidget.text div a'],
      editor: ['a'],
      constructor: [
        '.block.block-text .text-preview a',
        '.controls .control.text_link .panel .resizable-scroll-wrapper .resizable-content-wrapper .resizable-content .link-style .style-caption',
      ],
    },
  },

  // просто из объекта со стилями генерирует css нотацию этих стилей
  generateStylesStr: function(params) {
    var s = '',
      color,
      size,
      offset,
      style = params.style,
      pre = params.indentation ? '\t' : '',
      post = ';' + (params.lineBreaks ? '\n' : ''),
      attrPrefix = params.attrPrefix || '';

    if (params.tp == 'paragraph') {
      s += pre + 'font-family: ' + attr('font-family') + post;
      s += pre + 'font-style: ' + attr('font-style') + post;
      s += pre + 'font-weight: ' + attr('font-weight') + post;

      s += pre + 'font-size: ' + px(attr('font-size')) + post;
      s += pre + 'letter-spacing: ' + px(attr('letter-spacing')) + post;
      s += pre + 'line-height: ' + px(attr('line-height')) + post;

      s += pre + 'text-align: ' + attr('text-align') + post;
      s += pre + 'text-decoration: ' + attr('text-decoration') + post;
      s += pre + 'text-transform: ' + attr('text-transform') + post;

      s += pre + 'color: ' + Utils.getRGBA(attr('color'), attr('opacity') / 100) + post;

      s += pre + 'padding-top: ' + px(attr('padding-top')) + post;
      s += pre + 'padding-right: ' + px(attr('padding-right')) + post;
      s += pre + 'padding-bottom: ' + px(attr('padding-bottom')) + post;
      s += pre + 'padding-left: ' + px(attr('padding-left')) + post;
    }

    if (params.tp == 'link') {
      s += pre + 'text-decoration: none' + post;
      s += pre + 'color: ' + Utils.getRGBA(attr('color'), attr('opacity') / 100) + post;

      if (attr('u-style') != 'none') {
        // все подчеркивания делаем градиентами
        // это единственный способ который работает кросбразузерно,
        // позволяет менять размер, форму и цвет подчеркивания
        // работает с мультилайн текстом и позволяет регулировать положение линии выше/ниже
        color = Utils.getRGBA(attr('u-color'), attr('u-opacity') / 100);
        size = parseInt(attr('u-size'), 10);
        offset = parseInt(attr('u-offset'), 10) + size;

        s += pre + 'padding-bottom: ' + px(Math.max(offset, 0)) + post;

        if (attr('u-style') == 'solid') {
          s += pre + 'background: linear-gradient(to right, ' + color + ' 0%, ' + color + ' 100%)' + post;
          s += pre + 'background-size: 1px ' + px(size) + post;
        }

        if (attr('u-style') == 'dotted') {
          s +=
            pre +
            'background: linear-gradient(to right, ' +
            color +
            ' 0%, ' +
            color +
            ' 50%, transparent 50%,transparent 100%)' +
            post;
          s += pre + 'background-size: ' + px(size * 2) + ' ' + px(size) + post;
        }

        if (attr('u-style') == 'dashed') {
          s +=
            pre +
            'background: linear-gradient(to right, ' +
            color +
            ' 0%, ' +
            color +
            ' 66.6666%, transparent 66.6666%,transparent 100%)' +
            post;
          s += pre + 'background-size: ' + px(size * 3) + ' ' + px(size) + post;
        }

        if (offset < 0) s += pre + 'background-position: ' + '0 ' + (100 + offset) + '%' + post;
        else s += pre + 'background-position: ' + '0 100%' + post;

        s += pre + 'background-repeat: repeat-x' + post;
      } else {
        // это чтобы перекрыть обычные стили линка если в данный момент генерируется ховер состояние
        s += pre + 'background: none' + post;
      }
    }

    return s;

    function attr(s) {
      var res = style[attrPrefix + s];

      // в стилях линков для состояния ховера значения могут наследоваться от обычного состояния
      if (res == 'inherit' && attrPrefix) res = style[s];

      return res;
    }

    function px(s) {
      return s + (!/px/i.test(s) ? 'px' : '');
    }
  },

  // генерирует <style> с css стилями текстового виджета
  // doc - это в какой документ добавлять (в текстовом редакторе документ этот в ифрейме)
  generateCSS: function(tp, prefixType, doc, list, params) {
    if (!list) return;

    var prefixes = this.STYLE_PREFIXES[tp][prefixType],
      params = params || {},
      self = this,
      s = '';

    _.each(list, function(style) {
      if (tp == 'paragraph') {
        // Если параграф является header (h1,h2,h3,h4), задаем ему такие стили
        if (style.tag && style.tag !== 'p' && Constants.AVAILABLE_TEXT_TAGS.includes(style.tag)) {
          prefixes = []
            .concat(self.STYLE_PREFIXES['header-' + style.tag][prefixType], self.STYLE_PREFIXES[tp][prefixType])
            .filter(function(value, index, self) {
              return self.indexOf(value) === index;
            });
        }

        s +=
          _.map(prefixes, function(prefix) {
            return prefix + '.' + style._name;
          }).join(',\n') + ' {\n';
        s += self.generateStylesStr({ tp: tp, style: style, indentation: true, lineBreaks: true });
        s += '}\n\n';
      }

      if (tp == 'link') {
        // это для превью в редакторе когда переключаемся в редактировании стиля с обычного состояния на ховер состояние
        if (params.forcePseudoState && params.forcePseudoClass == style._name) {
          // ховер стиль вместо обычного стиля
          s +=
            _.map(prefixes, function(prefix) {
              return prefix + '.' + style._name;
            }).join(',\n') + ' {\n';
          s += self.generateStylesStr({
            tp: tp,
            style: style,
            attrPrefix: params.forcePseudoState + '-',
            indentation: true,
            lineBreaks: true,
          });
          s += '}\n\n';
        } else {
          // обычный стиль
          s +=
            _.map(prefixes, function(prefix) {
              return prefix + '.' + style._name;
            }).join(',\n') + ' {\n';
          s += self.generateStylesStr({ tp: tp, style: style, indentation: true, lineBreaks: true });
          s += '}\n\n';

          // Current перед ховером, чтобы ховер перекрывал current
          var hasCurrent = _.find(_.keys(style), function(key) {
            return /^current/.test(key);
          });
          if (hasCurrent) {
            s +=
              _.map(prefixes, function(prefix) {
                return prefix + '.current.' + style._name;
              }).join(',\n') + ' {\n';
            s += self.generateStylesStr({
              tp: tp,
              style: style,
              attrPrefix: 'current-',
              indentation: true,
              lineBreaks: true,
            });
            s += '}\n\n';
          }

          // ховер стиль
          s +=
            _.map(prefixes, function(prefix) {
              return prefix + '.hovered.' + style._name;
            }).join(',\n') + ' {\n';
          s += self.generateStylesStr({
            tp: tp,
            style: style,
            attrPrefix: 'hover-',
            indentation: true,
            lineBreaks: true,
          });
          s += '}\n\n';
        }

        // стиль для спанов и пр. фигни внутри линка
        s +=
          _.map(prefixes, function(prefix) {
            return prefix + '.' + style._name + ' *';
          }).join(',\n') + ' {\n';
        s += '\tcolor: inherit !important;\n';
        s += '\ttext-decoration: none !important;\n';
        s += '}\n\n';
      }
    });

    doc = doc || document;
    var id = 'text_styles_' + tp + '_' + prefixType;

    // удаляем прежний набор стилей
    $('#' + id, doc).remove();
    var style = doc.createElement('style');
    style.type = 'text/css';
    style.id = id;
    style.className = 'text_styles';
    style.appendChild(doc.createTextNode(s));
    doc.getElementsByTagName('head')[0].appendChild(style);
  },

  // функция загружает шрифты из массива fonts
  // формат типа: {provider: 'typekit', css_name: 'hmqz', name: 'Adobe Caslon Pro', variations: ['n4','i4','n6','i6','n7','i7']},
  // умеет работать с провайдерами: google, webtype, typekit, typetoday (webtype, typetoday все у нас, self-hosted)
  // и самое главное: каждый раз при вызове сканирует список уже загруженный css и смотрит какие шрифты уже доступны
  // и выкидывает их из списка fonts (так что можно вызывать сколько угодно раз)
  // причем учитывает и начертания
  // callback - опционален. Имеет смысл только для кастомных шрифтов, т.к. они подгружаются асинхронно
  appendFontsCssToDocument: function(params, callback) {
    !Modernizr.isboxversion &&
      window.TypekitPreview &&
      window.TypekitPreview.setup &&
      window.TypekitPreview.setup({
        auth_id: Constants.TYPEKIT_ID,
        auth_token: Constants.TYPEKIT_TOKEN,
        default_subset: 'all',
      });

    var $loadedFonts = $('link.fonts, style.fonts'),
      loadedFonts = [],
      requestedFonts = [],
      needToBeLoadedFonts = [],
      fontsLoadData = {};

    // смотрим какие начертания шрифтов уже загружены
    $loadedFonts.each(function() {
      var $link = $(this),
        provider = $link.attr('data-provider'),
        fontAndVariations = $link.attr('data-fonts-and-variations').split('||');

      _.each(fontAndVariations, function(variation) {
        loadedFonts.push(provider + '|' + variation);
      });
    });

    // смотрим какие начертания шрифтов запрошены к загрузке
    _.each(params.fonts, function(font) {
      _.each(font.used_variations || font.variations, function(variation) {
        requestedFonts.push(font.provider + '|' + font.css_name + '|' + variation);
      });
    });

    // смотрим какие начертания шрифтов еще не загружены
    needToBeLoadedFonts = _.difference(requestedFonts, loadedFonts);

    // формируем более подходящую для дальнейшей работы структуру данных по начертаниям которые требуется загрузить
    var newFontsHash = {};
    _.each(needToBeLoadedFonts, function(fontHash) {
      var tmp = fontHash.split('|'),
        provider = tmp[0],
        font = tmp[1],
        variation = tmp[2],
        id = provider + '|' + font;

      newFontsHash[id] = newFontsHash[id] || [];
      newFontsHash[id].push(variation);
    });

    var newFonts = [];
    _.each(newFontsHash, function(val, key) {
      var tmp = key.split('|'),
        provider = tmp[0],
        font = tmp[1],
        variations = val;

      newFonts.push({
        provider: provider,
        css_name: font,
        variations: variations,
      });
    });

    _.each(newFonts, function(font) {
      var provider = font.provider,
        fontLoadData,
        fontAndVariations = font.css_name + '|' + font.variations.join('||' + font.css_name + '|');

      if (!fontsLoadData[provider]) fontsLoadData[provider] = [];

      if (provider == 'google') {
        var variations = $.map(font.variations, function(item) {
          return (item.substr(1, 1) - 0) * 100 + (item.substr(0, 1) == 'n' ? '' : 'italic');
        });
        fontLoadData = {
          fontAndVariations: fontAndVariations,
          data: font.css_name.split(' ').join('+') + ':' + variations.join(','),
        };
      }

      if (provider == 'webtype') {
        fontLoadData = {
          fontAndVariations: fontAndVariations,
          data: font.css_name + ':' + font.variations.join(','),
        };
      }

      if (provider == 'typetoday') {
        fontLoadData = {
          fontAndVariations: fontAndVariations,
          data: font.css_name + ':' + font.variations.join(','),
        };
      }

      if (provider == 'custom') {
        // по кастомным шрифтам мы передаем еще и css_url который надо загрузить дл этого шрифта
        // ищем его в переданных данных, ведь данные всегда берутся из mag_edit_params а там по кастомным провайдерам мы дополнительно храним и css_url
        // к скриншотере немного по-другому это работает, ведь он не передает список fonts из mag_edit_params
        // а он ищет просто шрифты которые есть на странице и грузит только их
        // поэтому для того случая мы просто в скриншотере к найденому списку добавляем список всех кастомных шрифтов юзера принудительно (с полями css_url)
        var tmp = _.findWhere(params.fonts, { provider: 'custom', css_name: font.css_name });
        if (tmp && tmp.css_url)
          fontLoadData = {
            fontAndVariations: fontAndVariations,
            data: {
              css_name: font.css_name,
              css_url: tmp.css_url,
              signed_css_url: tmp.signed_css_url, // Временный урл для вновь добавленных кастомных шрифтов
            },
          };
      }

      if (provider == 'typekit') {
        fontLoadData = {
          fontAndVariations: fontAndVariations,
          data: { id: font.css_name, variations: font.variations },
        };
      }

      if (fontLoadData) fontsLoadData[provider].push(fontLoadData);
    });

    var exportPDFMode = RM.screenshot && Utils.queryUrlGetParam('pdf') == 'true';

    // если мы в режиме экспорта PDF нам нельзя использовать typekit шрифты (они нам запретили)
    // потому чуть ниже по коду мы отменяем загрузку Typekit Preview Api (и соответсенно блокируем загрузку всех их шрифтов)
    // а тут мы для всех тайпкит шрифтов тербуемых к загрузке производим танцы с бубном
    // прописываем font-face с айдишником тайпкита, но файлы шрифтов указываем от вебтайпа или гугла
    // причем мы смотрим классификацию тайпкит шрифта и замещаем его подходящим по типу, подробности в комментарии к функции
    if (exportPDFMode) {
      this.loadTypekitSubstitutionFonts(fontsLoadData['typekit']);
    }

    var hasCustom = _.has(fontsLoadData, 'custom');
    _.each(fontsLoadData, function(fontLoadData, provider) {
      // var maxItemsPerPart = provider == 'custom' ? 1 : 22, //важно, кастомные провайдеры записываем по-одному, у нас для них на каждый шрифт отдельный сss, остальные можно больше за раз (вообще это ограничение для гугла, потому что у него формирвется очень длинный урл и его надо ограничивать, иначе он просто не откроется в некоторых браузерах, ограничение страндарта на урл 4096)

      // важно, кастомные провайдеры записываем по-одному, у нас для них на каждый шрифт отдельный сss,
      // остальные можно больше за раз (вообще это ограничение для гугла,
      // потому что у него формирвется очень длинный урл и его надо ограничивать,
      // иначе он просто не откроется в некоторых браузерах, ограничение страндарта на урл 4096)
      var maxItemsPerPart = provider == 'custom' ? 1 : provider == 'google' ? 22 : fontLoadData.length,
        parts = Math.ceil(fontLoadData.length / maxItemsPerPart),
        i,
        st,
        ed,
        tmp,
        data,
        css_names,
        fontsAndVariations,
        cnt = 0;

      for (i = 0; i < parts; i++) {
        st = i * maxItemsPerPart;
        ed = Math.min((i + 1) * maxItemsPerPart, fontLoadData.length);
        (tmp = fontLoadData.slice(st, ed)),
          (data = _.pluck(tmp, 'data')),
          (fontsAndVariations = _.pluck(tmp, 'fontAndVariations'));

        $('link, style').addClass('existing');

        if (provider == 'google') {
          var prefix = (Constants.IS_FILE_PROTOCOL ? 'http://' : '//') + 'fonts.googleapis.com/css?family=',
            families = data.join('%7C'), // %7C -> '|'
            postfix = '&subset=latin,vietnamese,khmer,cyrillic-ext,greek-ext,greek,devanagari,latin-ext,cyrillic';

          addLink(prefix + families + postfix);
        }

        if (provider == 'typekit' && !Modernizr.isboxversion) {
          // этот вызов обернут в try catch не просто так
          // суть в том, чтобы остановить выполнение их гребаного скрипта ровно в тот момент, когда он создаст линк на css в хеаде документа
          // иначе он потом еще 5 секунд как минимум просто занимается тотальной ерундой типа ожидания загрузки всех начертаний шрифта
          // а это очень медленные операции (достаточно посмотреть на код Web Font Loader)
          // снизу файла есть код который помогает нам в этом

          // для неопубликованной версии используем Typekit Preview Api
          if (params.version == 'edit' && !exportPDFMode) {
            try {
              window.TypekitPreview && window.TypekitPreview.load(data);
            } catch (e) {}
          }

          // для опубликованной версии используем урл для js файла который нам вернул тайпуит при публикации набора шрифтов
          if (params.version == 'published' && params.typekit_url) {
            // обязательно, иначе если прежние открытые мэги грузили тайпкит, будет жопа, с тайпкитом вообще одна сплошная жопа, если не сказать задница
            if (typeof Typekit !== 'undefined') {
              Typekit = undefined;
            }

            // удаляем все прежние загруженные css с китами тайпкита, потому то они конские шо пиздец, не факт что браузеру станет легче, но мы хотя бы попробуем
            $('style.typekit-kit').remove();

            $.getScript(params.typekit_url, function(data, textStatus, jqxhr) {
              try {
                Typekit.load({
                  active: function() {
                    // коллбэк на окончание загрузки шрифтов
                    var $styles = $('style').filter(function(idx, s) {
                      return /typekit/i.test(s.innerHTML); // тайпкит никак не помечает добавленные собой стили, по-этому ищем вот так
                    });

                    $styles.addClass('typekit-kit');
                    applyProps($styles);
                  },
                });
              } catch (e) {}
            });
          }
        }

        if (provider == 'webtype') {
          // для вебтайпа особое поведение пока
          // поскольку у них нет апишки мы хостим их шрифты у себя
          // и у нас все они прописаны webtype-hosted-fonts.html на бэке
          // поэтому этот файл достаточно загрузить просто один раз
          // просто я реализовал единое поведение для всех провайдеров
          // чтобы потом проще было добавлять новые, или прикрутить апишку вебтайпа когда она появится
          var prefix = !RM.common.isDownloadedSource ? '' : Constants.readymag_host;

          if (!$('link.fonts[data-provider="webtype"]').length) {
            var uri = window.ServerData && window.ServerData.fonts && window.ServerData.fonts.webtype;

            addLink(prefix + uri);
          }
        }

        if (provider == 'typetoday' && !exportPDFMode) {
          // В PDF нам Type.today запретили вставлять их шрифты
          // Просто с копировано с webtype, чтобы был отдельный код, если в будущем появится апишка
          var prefix = !RM.common.isDownloadedSource ? '' : Constants.readymag_host;

          if (!$('link.fonts[data-provider="typetoday"]').length) {
            var uri = window.ServerData && window.ServerData.fonts && window.ServerData.fonts.typetoday;

            addLink(prefix + uri);
          }
        }

        if (provider == 'custom') {
          // для кастомного провайдера просто добавляем css который у нас указан для данного шрифта
          // проверка на то что файл уже загружен происходит выше, также как и для гугла или тайпкита по data-fonts-and-variations и data-provider
          // загрузчик увидит что этот шрифт и  все его начертания уже есть и не будет вообще грузть css заново
          var url = data[0].signed_css_url || data[0].css_url; // в массиве всегда один элемент, потому что maxItemsPerPart = 1

          // если наши щрифты, а не external css тогда добавляем их через style
          // иначе через link (так ка мы не можем кросс доменно с клиента получить контент этих файлов)
          if (/^\/api\/fonts\//i.test(url)) {
            // self-hosted fonts
            // оборачиваем в свой скоуп чтобы $style был правильный для
            // каждого вызова колбека аякса
            (function() {
              // кастомные шрифты добавлем не линком на css
              // а прямо создает тег стиля и в него записываем содержимое css
              // так нужно для защиты шрифта, чтобы referrer был правильныйx
              var $style = $('<style>').attr('type', 'text/css');

              $('head').append($style);

              $.get(url, function(css) {
                $style.text(css);

                // Ждем пока загрузятся все кастомные шрифты
                cnt++;
                if (cnt >= fontLoadData.length && _.isFunction(callback)) {
                  callback();
                }
              });
            })();
          } else {
            // external css
            // проверка на то что файл уже загружен происходит выше, также как и для гугла или тайпкита по data-fonts-and-variations и data-provider
            // загрузчик увидит что этот шрифт и  все его начертания уже есть и не будет вообще грузть css заново
            addLink(url);
          }
        }

        function applyProps($els) {
          $els
            .addClass('fonts')
            .attr('data-id', Utils.generateUUID())
            .attr('data-provider', provider)
            .attr('data-fonts-and-variations', fontsAndVariations.join('||'));
        }

        applyProps($('link:not(.existing), style:not(.existing)'));
        $('link, style').removeClass('existing');
      }
    });

    if (!hasCustom && _.isFunction(callback)) {
      callback();
    }

    function addLink(url) {
      var $link = $('<link>')
        .attr('type', 'text/css')
        .attr('rel', 'stylesheet')
        .attr('href', url);

      $('head').append($link);
    }
  },

  // если мы в режиме экспорта PDF нам нельзя использовать typekit шрифты (они нам запретили)
  // для всех тайпкит шрифтов тербуемых к загрузке производим танцы с бубном
  // прописываем font-face с айдишником тайпкита, но файлы шрифтов указываем от вебтайпа или гугла
  // причем мы смотрим классификацию тайпкит шрифта и замещаем его подходящим по типу:
  // "classification":[] — 				News Gothic		(i.e AW Conqueror Carved Three)
  // "classification":["sans-serif"] — 	News Gothic 	(i.e Aaux Next)
  // "classification":["decorative"] — 	News Gothic		(i.e Abigail)
  // "classification":["handmade"] — 		News Gothic		(i.e Balzano Std)
  // "classification":["blackletter"] — 	News Gothic		(i.e Baroque Text)
  // "classification":["script"] — 		News Gothic		(i.e Adage Script)
  // "classification":["slab-serif"] — 	News Gothic		(i.e Abril Text)
  // "classification":["serif"] — 			Georgia Pro		(i.e Abril Display)
  // "classification":["monospaced"] — 	Nitti			(i.e Adaptive Mono)
  loadTypekitSubstitutionFonts: function(fonts) {
    if (!fonts || !fonts.length) return;

    _.each(fonts, function(font) {
      var id = font.data.id,
        variations = font.data.variations,
        tmp = _.findWhere(TextUtils.fontsShortList['typekit'], { id: id }),
        tmp = tmp && tmp.browse_info,
        tmp = tmp && tmp.classification,
        tp = (tmp && tmp.length && tmp[0]) || 'sans-serif'; // смотрим к какому классу относится тайпкит шрифт

      var tpMap = {
        'sans-serif': 'news-gothic', // значения - это суффиксы темплейтов, смотри файл /templates/_pdf_export/typekit_substitution.html
        decorative: 'news-gothic',
        handmade: 'news-gothic',
        blackletter: 'news-gothic',
        script: 'news-gothic',
        'slab-serif': 'news-gothic',
        serif: 'georgia-pro',
        monospaced: 'nitti',
      };

      // выбираем один из трех вебкит шрифтов на подмену тайпкит шрифту (по типу)
      var templateName = 'template-export-pdf-typekit-substitution-' + (tpMap[tp] || tpMap['sans-serif']),
        template = templates[templateName],
        substitutionStyle = template({ id: id });

      $('head').append(substitutionStyle);
    });
  },

  // функция смотрит какие шрифты подключены сейчас в основном документе (ищет все link с классом fonts)
  // и переносит эти линки в ифрейм
  // каждый раз при вызове смотрит какие шрифты уже подключены к iframe и их второй раз не подключает
  appendFontsCssToIFrame: function(iframe) {
    var $mainFonts = $('link.fonts, style.fonts'),
      $iframeFonts = $('link.fonts, style.fonts', iframe),
      iframeFonts = {},
      $iframeHead = $('head', iframe);

    $iframeFonts.each(function() {
      iframeFonts[$(this).attr('data-id')] = true;
    });

    $mainFonts.each(function() {
      var $link = $(this);

      var href = this.href;

      if (!iframeFonts[$link.attr('data-id')]) {
        var $cloned_link = $link.clone();

        // Здесь ставим спец. флаг в сылку на css сэлф-хостед шрифтов, чтобы nginx не проверял подпись ссылки
        // Связано с багом сафари, из-за которого из iframe не передается referrer. По нему как раз и проверяются подписанные ссылки.
        if (href && Modernizr.safari) {
          $cloned_link.attr('href', href + '&edit_mode=true');
        }

        $cloned_link.appendTo($iframeHead);
      }
    });
  },

  /**
   * Определяет, доступна ли быстрая / нативная / пригодная для вьюера проверка загрузки шрифтов
   * @returns {boolean}
   */
  isFastFontLoadCheckAvailable: function() {
    // Считаем, что быстрая проверка доступна,
    // если поддерживается FontLoading API https://developer.mozilla.org/en-US/docs/Web/API/CSS_Font_Loading_API
    // и если поддерживаются промисы
    return Boolean(document.fonts && window.Promise);
  },

  /**
   * Возвращает промис и резолвит его, когда шрифт загружен
   * @param {String} family
   * @param {Number} weight
   * @param {String} style
   * @returns {Promise<String>}
   */
  fastWaitForFontLoad: function(family, weight, style) {
    var promise;
    // Если FontLoading API поддерживается, проверим с помощью него (используется во вьюере)
    if (document.fonts) {
      // Строка вида "normal 400 12px Roboto" (без fallback-шрифта)
      var font = style + ' ' + weight + ' 12px ' + family;
      // Сейчас document.fonts.load работает как надо в FF и Сафари, а в Хроме 53 — нет (в Хроме Canary — ок)
      // В хроме промис, который возвращает document.fonts.load() всегда почему-то resolved, вне зависимости от того, загрузились шрифты или нет,
      // но document.fonts.check, вызванный на этот промис, возвращает false.
      // document.fonts.ready в Хроме 53 не вызывается (в FF, Сафари и Canary — вызывается)
      // Проверим, действительно ли шрифт доступен. Если да, то вернём font face.
      var fontLoadPromise = document.fonts.load(font).then(
        function() {
          if (document.fonts.check(font)) {
            return font;
            // Если проверка не прошла, то есть шрифт на самом деле ещё не загрузился (как сейчас бывает в Хроме 53)
            // будем вызывать проверку по таймауту, пока не истечёт время.
          } else {
            return this.fontLoadedTimeoutCheck(font);
          }
        }.bind(this)
      );

      // С кастомными шрифтами в Safari иногда не срабатывает then, и просто ничего не происходит, сколько бы времени ни прошло
      // поэтому организуем таймаут с помощью второго конкурирующего промиса
      var timedOutPromise = new window.Promise(function(resolve, reject) {
        setTimeout(reject.bind(null, font), FONT_LOAD_API_WAIT_TIME);
      });

      promise = window.Promise.race([fontLoadPromise, timedOutPromise]);
    } else {
      promise = window.Promise.reject();
    }
    return promise;
  },

  // функция ждет загрузки шрифта и вызывает cb(true)
  // если в течение 10 секунд шрифт не будет загружен и отрендерен, все равно вызовет cb, но сb(false)
  // суть в том что мы рендерим шрифт на канвасе до тех пор, пока не увидим то отрендерился именно наш шрифт
  // т.е. на канвасе не пусто и не надпись ариалом (который у нас замещает нужный нам шрифт пока он не загрузится)
  // на самом деле функция получилась шустрой: одна проверка одного шрифта < 1мс
  // проверки идут по таймеру, с интервалом в 100мс
  // используется в скриншотере для определения что все шрифты во всех текстовых виджетах подгрузились
  // похоже это единственый (но в вебките, увы, все равно не 100% способ) определить что шрифт не только загружен, применен к верстке, но и показан! браузером
  exactWaitForFontLoad: function(family, weight, style, cb) {
    var fontReal = style + ' ' + weight + ' 12px ' + family + ', Arial',
      fontStub = style + ' ' + weight + ' 12px Arial',
      cw = 20,
      ch = 20,
      $canvas = $('<canvas width="' + cw + '" height="' + ch + '"></canvas>'),
      canvas = $canvas[0],
      context = canvas.getContext('2d'),
      initialEmpty = '',
      initialArial = '',
      start = +new Date();

    $canvas.appendTo('body').css({ position: 'absolute', left: 0, top: '-999px' });
    oneStep();

    function oneStep() {
      var timePassed = +new Date() - start > FONT_LOAD_CANSVAS_WAIT_TIME,
        res = render();

      if (res || timePassed) {
        context = undefined;
        $canvas.remove();
        cb(res, family, weight, style);
        return;
      }

      setTimeout(oneStep, FONT_LOAD_CHECK_INTERVAL);
    }

    function render() {
      var font = fontReal;

      context.clearRect(0, 0, cw, ch);

      if (!initialEmpty) {
        initialEmpty = canvas.toDataURL('image/png');
        font = fontStub;
      }

      context.font = font;
      context.fillStyle = '000';
      context.fillText('a1-&q', 0, 20);

      var n = canvas.toDataURL('image/png');

      if (!initialArial) initialArial = n;

      return n != initialEmpty && n != initialArial;
    }
  },

  /**
   * Проверяет, загружен ли шрифт, используя document.fonts.check и таймауты (на случай если document.fonts.load работает не так как надо)
   * @param {String} font Строка вида "normal 400 12px Nimbus" (без fallback-шрифта)
   * @returns {Promise<String>}
   */
  fontLoadedTimeoutCheck: function(font) {
    var start = Date.now();

    var check = function(resolve, reject) {
      var elapsed = Date.now() - start;
      // Шрифт загружен — резолвим
      if (document.fonts.check(font)) {
        resolve(font);
        // Шрифт не загружен — подождём ещё
      } else if (elapsed < FONT_LOAD_API_WAIT_TIME) {
        setTimeout(check.bind(null, resolve, reject), FONT_LOAD_CHECK_INTERVAL);
        // Шрифт не загружен, но прошло уже много времени — реджектим
      } else {
        reject(font);
      }
    };

    return new window.Promise(function(resolve, reject) {
      check(resolve, reject);
    });
  },

  // возвращает массив текстовых виджетов найденых внутри хотспота
  // работает как для конструктора там и для вьювера (там вместо моделей объекты, их и вернет)
  getHotspotTextModels: function(hotspot) {
    var textModels = [];

    // мы в конструкторе, там есть популяризованная коллекция вложенных виджетов
    if (hotspot.widgets_collection && hotspot.widgets_collection.length) {
      // Т.к. удаленные вложенные виджеты не удаляются из коллекции
      // (вложенные виджеты могут быть удалены только при Undo свеого первого добавления-создания, в остальных случая они скрываются),
      // то проверяем фактическую добавленность вложенного виджета наличием его айдишника в wids.
      textModels = hotspot.widgets_collection.filter(function(m) {
        return m.get('type') == 'text' && hotspot.get('wids').indexOf(m.get('_id')) > -1;
      });
    }

    // мы в конструкторе, но нас вызвали из роутера в getClipboardCopyData
    // там странные объекты, а не модели
    if (hotspot._nestedWidgetsJSON && hotspot._nestedWidgetsJSON.length) {
      textModels = _.filter(hotspot._nestedWidgetsJSON, function(m) {
        return m.type == 'text';
      });
    } else if (hotspot.wids && hotspot.wids.length) {
      // мы во вьювере, там нет моделей, только объекты
      textModels = _.filter(hotspot.wids, function(m) {
        return m.type == 'text';
      });
    }

    return textModels;
  },

  // функция на входе получает массив моделей виджетов,
  // а на выходе выдает полную информацию по реальным начертаниям шрифтов использованных в этих виджетах
  // функция выцепляет виджеты, в которых используются шрифты из переданной кучи и обрабатывает информацию из них
  // для слайдшоу все более мене просто, для текстового вовсе нет
  getUsedFontsFromWidgetsModels: function(params) {
    var texts = '',
      textsFontsRaw = {},
      slideShowFontsRaw = {},
      buttonFontsRaw = {},
      formFontsRaw = {},
      models = params.models;

    for (var i = 0; i < models.length; i++) {
      var model = models[i];

      // для салйдшоу просто запоминаем в ассоциативный массив связку шрифт-стиль-вес
      // вьюпорты не проверяем, у него их нет для текстовых стилей (пока нет...)
      if (get(model, 'type') == 'slideshow' && get(model, 'text_style')) {
        var style = get(model, 'text_style');
        if (style['font-family'] && style['font-weight'] && style['font-style'])
          slideShowFontsRaw[style['font-family'] + '|' + style['font-style'] + '|' + style['font-weight']] = 1;
      }

      // для виджета кнопки просто запоминаем в ассоциативный массив связку шрифт-стиль-вес
      // вьюпорты не проверяем, у него их нет для текстовых стилей (пока нет...).
      if (get(model, 'type') == 'button') {
        if (get(model, 'font-family') && get(model, 'font-weight') && get(model, 'font-style'))
          buttonFontsRaw[
            get(model, 'font-family') + '|' + get(model, 'font-style') + '|' + get(model, 'font-weight')
          ] = 1;
      }

      // для виджета формы смотрим и вьюпорты и текущий стиль для полей и для кнопки
      if (get(model, 'type') == 'form') {
        var formStyleFields = get(model, 'style-' + get(model, 'style') + '-fields'),
          formStyleButton = _.clone(get(model, 'style-' + get(model, 'style') + '-button-default'));

        if (!_.isEmpty(formStyleButton)) {
          formStyleButton['font-family'] =
            formStyleButton['font-family'] == 'inherit'
              ? formStyleFields['font-family']
              : formStyleButton['font-family'];
          formStyleButton['font-style'] =
            formStyleButton['font-style'] == 'inherit' ? formStyleFields['font-style'] : formStyleButton['font-style'];
          formStyleButton['font-weight'] =
            formStyleButton['font-weight'] == 'inherit'
              ? formStyleFields['font-weight']
              : formStyleButton['font-weight'];
        }

        formFontsRaw[
          formStyleFields['font-family'] + '|' + formStyleFields['font-style'] + '|' + formStyleFields['font-weight']
        ] = 1;

        // с проверкой, защита от поломки старых версий виджета
        formStyleButton &&
          (formFontsRaw[
            formStyleButton['font-family'] + '|' + formStyleButton['font-style'] + '|' + formStyleButton['font-weight']
          ] = 1);

        // смотрим какие вьюпорты есть
        for (var j = 0; j < Viewports.length; j++) {
          var formViewportData = model['viewport_' + Viewports[j].name];

          if (formViewportData) {
            formStyleFields = formViewportData['style-' + formViewportData['style'] + '-fields'];
            formStyleButton = _.clone(formViewportData['style-' + formViewportData['style'] + '-button-default']);

            if (!_.isEmpty(formStyleButton)) {
              formStyleButton['font-family'] =
                formStyleButton['font-family'] == 'inherit'
                  ? formStyleFields['font-family']
                  : formStyleButton['font-family'];
              formStyleButton['font-style'] =
                formStyleButton['font-style'] == 'inherit'
                  ? formStyleFields['font-style']
                  : formStyleButton['font-style'];
              formStyleButton['font-weight'] =
                formStyleButton['font-weight'] == 'inherit'
                  ? formStyleFields['font-weight']
                  : formStyleButton['font-weight'];
            }

            formStyleFields &&
              (formFontsRaw[
                formStyleFields['font-family'] +
                  '|' +
                  formStyleFields['font-style'] +
                  '|' +
                  formStyleFields['font-weight']
              ] = 1);

            // с проверкой, защита от поломки старых версий виджета
            formStyleButton &&
              (formFontsRaw[
                formStyleButton['font-family'] +
                  '|' +
                  formStyleButton['font-style'] +
                  '|' +
                  formStyleButton['font-weight']
              ] = 1);
          }
        }
      }

      // для текстового виджета
      if (get(model, 'type') == 'text' && get(model, 'text')) {
        _.extend(
          textsFontsRaw,
          this.scanTextForFontsAndVariationsRaw(
            get(model, 'text'),
            get(model, 'version'),
            params.excludeUnusedDefault,
            params.activeViewports
          )
        );
      }

      // для хотспота
      if (get(model, 'type') == 'hotspot') {
        _.each(
          this.getHotspotTextModels(model),
          _.bind(function(m) {
            var text = get(m, 'text');
            text &&
              _.extend(
                textsFontsRaw,
                this.scanTextForFontsAndVariationsRaw(text, get(m, 'version'), params.excludeUnusedDefault)
              );
          }, this)
        );
      }
    }

    // объединяем списки использованных шрифтов для виджетов слайдшоу и для текстовых виджетов
    // у них у всех должен быть одинаковый формат данных, типа:
    // {Source Sans Pro|normal|400: 1, Arimo|normal|700: 1, Turnip RE|normal|400: 1, Wallpoet|italic|700: 1, Arial|normal|700: 1}
    var rawFonts = _.extend({}, textsFontsRaw, slideShowFontsRaw, buttonFontsRaw, formFontsRaw),
      groupedFonts = {};

    // теперь нам необходимо сгруппировать все записи по имени шрифта а начертания поместить в массив в формате FVD
    _.each(rawFonts, function(val, key) {
      var tmp = key.split('|'),
        ff = tmp[0],
        fs = tmp[1].toLowerCase(),
        fw = tmp[2].toLowerCase();

      groupedFonts[ff] = groupedFonts[ff] || [];

      // Font Variation Description https://github.com/typekit/fvd
      groupedFonts[ff].push((fs == 'italic' ? 'i' : 'n') + Math.floor(fw / 100));
    });

    var fonts = [],
      res,
      self = this;
    // теперь нам необходимо ко всем найдемым font-families найти недостающие данные, типа имени шрифта, провайдера, доступные начертания и пр.
    // а также нам необходимо откорректировать список найденых до этого начертаний на предмет их физического существования у шрифта
    _.each(groupedFonts, function(used_variations, css_name) {
      if ((res = _.findWhere(TextUtils.fontsShortList['webtype'], { name: css_name }))) {
        fonts.push({
          provider: 'webtype',
          css_name: res.name,
          name: res.name,
          variations: res.variations,
          used_variations: self.calcBrowserUsedVariation(used_variations, res.variations),
        });
      } else if ((res = _.findWhere(TextUtils.fontsShortList['typetoday'], { name: css_name }))) {
        fonts.push({
          provider: 'typetoday',
          css_name: res.name,
          name: res.name,
          variations: res.variations,
          used_variations: self.calcBrowserUsedVariation(used_variations, res.variations),
        });
      } else if ((res = _.findWhere(TextUtils.fontsShortList['google'], { family: css_name }))) {
        fonts.push({
          provider: 'google',
          css_name: res.family,
          name: res.family,
          variations: res.variations,
          used_variations: self.calcBrowserUsedVariation(used_variations, res.variations),
        });
      } else if ((res = _.findWhere(TextUtils.fontsShortList['typekit'], { id: css_name }))) {
        fonts.push({
          provider: 'typekit',
          css_name: res.id,
          name: res.name,
          variations: res.variations,
          used_variations: self.calcBrowserUsedVariation(used_variations, res.variations),
        });
      } else if (params.includeCustom) {
        if ((res = _.findWhere(params.customList, { css_name: css_name }))) {
          fonts.push({
            provider: 'custom',
            css_name: res.css_name,
            css_url: res.css_url,
            name: res.name,
            variations: res.variations,
            used_variations: self.calcBrowserUsedVariation(used_variations, res.variations),
          });
        }
      }
    });

    // во вьювере нет моделей
    function get(model, property) {
      return typeof model.get === 'function' ? model.get(property) : model[property];
    }

    return fonts;
  },

  getUsedParagraphStylesFromWidgetsModels: function(models) {
    var texts = '',
      i,
      model;

    for (i = 0; i < models.length; i++) {
      model = models[i];

      // для текстового виджета
      if (get(model, 'type') == 'text' && get(model, 'text')) {
        texts += get(model, 'text');
      }

      // для хотспота
      if (get(model, 'type') == 'hotspot') {
        _.each(
          this.getHotspotTextModels(model),
          _.bind(function(m) {
            texts += get(m, 'text') || '';
          }, this)
        );
      }
    }

    var usedStyles = [],
      allStyles = RM.constructorRouter.workspace.mag.edit_params.get('paragraph_styles');

    if (texts) {
      for (i = 0; i < allStyles.length; i++) {
        var style = allStyles[i];

        // просто ищем идишник стиля в текстах, маловероятно что эти идишники будут найдены в тексте а не в классах тегов
        // это может произойти для первых четырех дефолтных стилей (paragraph-1..4), у вех остальных стилей будут guid вместо номеров, и найти их случайно в тексте навряд ли получится
        // а по поводу первых четырех - ничего страшного не случится если мы их найдем, при вставке текста мы их ве равно не добавим потому что они дефолтные и есть во всех мэгах
        // если их конечно не удалили там, но это вообще все старнные ситуации, и самое галвное не критичные, абсолютно ничего страшного если зацепятся лишние стили
        // а этот метод поиска быстр и хорош тем, что сразу находит и стили во вьюпортах и воообще, наиболее надежен в плане реализации
        if (texts.indexOf(style._name) >= 0) {
          usedStyles.push(style);
        }
      }
    }

    // во вьювере нет моделей
    function get(model, property) {
      return typeof model.get === 'function' ? model.get(property) : model[property];
    }

    return usedStyles;
  },

  getUsedLinkStylesFromWidgetsModels: function(models) {
    var texts = '',
      i,
      model;

    for (i = 0; i < models.length; i++) {
      model = models[i];

      // для текстового виджета
      if (get(model, 'type') == 'text' && get(model, 'text')) {
        texts += get(model, 'text');
      }

      // для хотспота
      if (get(model, 'type') == 'hotspot') {
        _.each(
          this.getHotspotTextModels(model),
          _.bind(function(m) {
            texts += get(m, 'text') || '';
          }, this)
        );
      }
    }

    var usedStyles = [],
      allStyles = RM.constructorRouter.workspace.mag.edit_params.get('link_styles');

    if (texts) {
      for (i = 0; i < allStyles.length; i++) {
        var style = allStyles[i];

        // просто ищем идишник стиля в текстах, маловероятно что эти идишники будут найдены в тексте а не в классах тегов
        // это может произойти для первых четырех дефолтных стилей (paragraph-1..4), у вех остальных стилей будут guid вместо номеров, и найти их случайно в тексте навряд ли получится
        // а по поводу первых четырех - ничего страшного не случится если мы их найдем, при вставке текста мы их ве равно не добавим потому что они дефолтные и есть во всех мэгах
        // если их конечно не удалили там, но это вообще все старнные ситуации, и самое галвное не критичные, абсолютно ничего страшного если зацепятся лишние стили
        // а этот метод поиска быстр и хорош тем, что сразу находит и стили во вьюпортах и воообще, наиболее надежен в плане реализации
        if (texts.indexOf(style._name) >= 0) {
          usedStyles.push(style);
        }
      }
    }

    // во вьювере нет моделей
    function get(model, property) {
      return typeof model.get === 'function' ? model.get(property) : model[property];
    }

    return usedStyles;
  },

  /**
   * функция сканирует переданный текст на предмет использованных в нем шрифтов и начертаний
   * на выходе выдает ассоциативный объект с записями типа Dosis|normal|400, Nobel|italic|700 и пр.
   * сама определяет вьпорты в тексте!
   * также поскольку текст ренедрится в основном документе, стили текстового виджета ему также доступны
   * следует обратить внимание на то, что могут вернуться начертания которых фактически в шрифте нет
   * т.е. мы задали в тескте для Dosis вес в 300, нам и вернется Dosis|normal|300, хотя на самом деле такого начертания у шрифта нет
   * но браузер об этом не может знать, поэтому результаты работы этой функции надо потом прогонять через другую корректирующую функцию
   * @param {String} text Строка с html виджета
   * @param {Number} version Версия текстового виджета (в версии 1 дефолтовым шрифтом был Source Sans, в версии 2 — Roboto)
   * @param {Boolean} excludeUnusedDefault Исключить дефолтовый шрифт, если он фактически не используется
   * (то есть когда у всех параграфов текстового виджета шрифт другой, чем шрифт по умолчанию)
   *
   * @returns {{}}
   */
  scanTextForFontsAndVariationsRaw: function(text, version, excludeUnusedDefault, activeViewports) {
    var rawFonts = {},
      fonts = {},
      usedViewports = [],
      w = document.defaultView.getComputedStyle, // шорткат
      i,
      elem,
      $elem,
      j,
      compStyle,
      nodes,
      cnt,
      styleValue,
      classValue,
      viewportName,
      hasStyle,
      hasClass,
      node;

    // смотрим какие вьюпорты есть в тексте
    // и какие из них использованы на странице(опционально)
    for (i = 0; i < Viewports.length; i++) {
      if (text.split('-' + Viewports[i].name).length > 1) {
        // по бустрому ищем описания типа -phone_portrait (в data-style-phone_portrait или data-class-phone_portrait)
        if (activeViewports && activeViewports.indexOf(Viewports[i].name) === -1) {
          continue;
        }
        usedViewports.push(Viewports[i].name);
      }
    }

    // создаем временный див, прячем его для увеличения производительности
    // и обязательно нащначаем класс used-fonts-test, чтобы у него были дефолтные стили текстового виджета
    // и стили которые юзер создал сам
    $elem = $('.used-fonts-test');
    if (!$elem.length)
      $elem = $('<div>')
        .addClass('used-fonts-test')
        .css({
          position: 'absolute',
          display: 'none !important',
          left: -9999,
          width: 999,
        })
        .appendTo('body');

    // устанавливаем версию текстового виджета, виджеты без версии считаем виджетами первой версии
    // первая версия использует дефолтным щрифт соурс санс, вторая - робото
    // важно это делать для обратной совместимости, чтобы не сломать старые виджеты
    // также таким способом легко добавить новых версий, просто поправив версию в фефолтах вновь содаваемого виджета
    // и в 3х местах прописать классы для v3 с новыми дефолтными параметрами шрифта
    $elem.removeClass('v1 v2').addClass('v' + (version || 1));

    elem = $elem[0];
    elem.innerHTML = text;
    nodes = elem.querySelectorAll('span,p,a');
    cnt = nodes.length;

    // считаем скалькулированный стиль самого бокса (у него дефолтный стиль текстового виджета)
    // это важно из-за некоторых оптимизаций в основном поиске (так где проверки что нет ни стилей ни классов)
    compStyle = w(elem, null);
    var defaultFontKey = compStyle.fontFamily + '|' + compStyle.fontStyle + '|' + compStyle.fontWeight;
    rawFonts[defaultFontKey] = 1;

    var fontKey;
    var hasDefaultFontNodes = false;

    var containsText = function(node) {
      // Найдём ноды, в которых есть текстовые элементы. Текстовые элементы, в которых есть только переносы строки — не считаются.
      return (
        _.findIndex(node.childNodes, function(childNode) {
          return childNode.nodeType === Node.TEXT_NODE && childNode.textContent.replace(/\r?\n/g, '').length;
        }) !== -1
      );
    };

    // Найдём элементы, которые содержат непосредственно текст, и проверим их шрифты
    // (раньше проверяли все ноды, у которых был атрибут style или class —
    // трудно сказать, каких нод больше, с атрибутами или с текстом)
    var nodesContainingText = _.filter(nodes, containsText);

    for (var j = 0; j < nodesContainingText.length; j++) {
      compStyle = w(nodesContainingText[j], null);
      fontKey = compStyle.fontFamily + '|' + compStyle.fontStyle + '|' + compStyle.fontWeight;
      hasDefaultFontNodes = hasDefaultFontNodes || fontKey === defaultFontKey;
      rawFonts[compStyle.fontFamily + '|' + compStyle.fontStyle + '|' + compStyle.fontWeight] = 1;
    }

    var mainElementContainsText = containsText(elem);
    // Если получается, что дефолтовый шрифт фактически не используется в этом виджете, и его просят исключить — тогда исключим
    // (шрифт всех нод, содержащих текст, отличается от дефолтового, а непосредственно в ноде блока текста нет, не считая переносов строки)
    if (excludeUnusedDefault && !hasDefaultFontNodes && !mainElementContainsText) {
      delete rawFonts[defaultFontKey];
    }

    // теперь пробегаемся по всем вьюпортам которые есть в тексте
    // суть - в том, чтобы последовательно перед проверкой стиля мы переключаем в ноде стиль и класс на те, которые установлены для вюпорта
    // пользуется тем, что querySelectorAll возвращает ноды в порядке поиска в глубину (да нам бы и в ширину подошло)
    // главное то, что чайлд никогда не будет идти в этом списке впереди родителя
    // это важно, потому что мы последовательно меняем стили у нод и для всех деток важны новые стили родителей для подсчета собственного
    // т.е. мы последовательно переписываем стили и классы нод для каждого вьюпорта прямо поверх данных предыдущего
    // код основан на логике из switchTextToViewport из text-viewports.js
    // но здесь адаптирован и оптимизирован (тот код тоже подходит, но он слишком долго работает в нашем случае, да и делает много лишней работы)
    for (i = 0; i < usedViewports.length; i++) {
      viewportName = usedViewports[i];

      if (viewportName != 'default') {
        // пробегаемся по всем нодам
        for (j = 0; j < cnt; j++) {
          node = nodes[j];

          // если данные есть, тогда заменяем ими текущий дефолтный аттрибут
          // empty спец признак что данных нет, но они существовали когда-то, т.е. не надо брать дефолтные (removeAttr)!
          if ((styleValue = node.getAttribute('data-style-' + viewportName)))
            styleValue == 'empty' ? node.removeAttribute('style') : node.setAttribute('style', styleValue);

          if ((classValue = node.getAttribute('data-class-' + viewportName)))
            classValue == 'empty' ? node.removeAttribute('class') : node.setAttribute('class', classValue);

          // если у ноды нет ни стиля ни класса, то для нее расчитывать скалькулированный стиль мы не будем, почему - смотри выше
          if (node.getAttribute('style') || node.getAttribute('class')) {
            compStyle = w(node, null);
            rawFonts[compStyle.fontFamily + '|' + compStyle.fontStyle + '|' + compStyle.fontWeight] = 1;
          }
        }

        // если впереди еще есть вьюпорты для проверки
        // тогда восстанавливаем текст до дефолтного состояния
        // это важно, потому что любой вьюпорт "накладывается" поверх дефолтного
        // будет некорректно отнаследовать вьюпорт от другого вьюпорта, кроме дефолтного
        if (i < usedViewports.length - 1) {
          elem.innerHTML = text;
          nodes = elem.querySelectorAll('span,p,a');
          cnt = nodes.length;
        }
      }
    }

    // вычищаем список
    _.each(rawFonts, function(val, key) {
      var tmp = key.split('|'),
        ff = tmp[0],
        fs = tmp[1].toLowerCase(),
        fw = tmp[2].toLowerCase();

      ff = ff.split(',')[0]; // берем первый из фонтстека
      ff = ff.replace(/'|"/g, ''); // удаляем кавычки

      // фиксим вес начертания
      fw = fw.replace(/normal/g, '400');
      fw = fw.replace(/regular/g, '400');
      fw = fw.replace(/bold/g, '700');

      fonts[ff + '|' + fs + '|' + fw] = 1;
    });

    return fonts;
  },

  // функция, которая определяет какое именно из доступных начертаний
  // будет загружено браузером для рендеринга конкретного стиля
  // все дело в том, что мы можем попросить браузер отрендерить каким-либо шрифтом с начертанием i3
  // но при этом у этого шрифта из начертаний есть только i1 и i4, вот тут-то и помогает эта функция (кстати тут правильный ответ i1)
  // алгоритм подбора наиболее подходящего начертания из доступных описан тут:
  // http://www.w3.org/TR/css3-fonts/#font-matching-algorithm
  // также я поверил его на всем тайпките: 946 шрифтов * 18 вариантов (9 обычных и 9 италиков), итого 17028 комбинаций
  // функция везде выдала правильные результаты
  // как я определял какое именно начертание пытается грузить браузер это отдельная история,
  // код в файле demos/catchVariationLoad.html
  calcBrowserUsedVariation: function(requested_variations, variations) {
    var res = {};

    for (var i = 0; i < requested_variations.length; i++) {
      var requested = requested_variations[i],
        fw = requested.substr(1, 1),
        fs = requested.substr(0, 1),
        bestScore = 99999,
        bestValue = '',
        vw,
        vs,
        score;

      for (var k = 0; k < variations.length; k++) {
        vw = variations[k].substr(1, 1) - 0;
        vs = variations[k].substr(0, 1);

        if (fw < 4) score = Math.abs(vw - fw) * 10 + (vs == fs ? 0 : 1000) + (vw <= fw ? 0 : 100);
        if (fw > 5) score = Math.abs(vw - fw) * 10 + (vs == fs ? 0 : 1000) + (vw >= fw ? 0 : 100);
        if (fw == 4) {
          if (vw == 5) score = 0 + (vs == fs ? 0 : 1000) + 0;
          else score = Math.abs(vw - fw) * 10 + (vs == fs ? 0 : 1000) + (vw <= fw ? 0 : 100);
        }
        if (fw == 5) {
          score = Math.abs(vw - fw) * 10 + (vs == fs ? 0 : 1000) + (vw <= fw ? 0 : 100);
        }

        // на всякий случай при прямом совпадении сразу ставим лучший балл
        if (fw == vw && fs == vs) score = 0;

        if (score < bestScore) {
          bestScore = score;
          bestValue = variations[k];
        }
      }

      res[bestValue] = 1;
    }

    return _.keys(res);
  },

  /**
   * Формирует псевдо-шортлист (очень короткий, для вьюера и скриншотера)
   * из шрифтов, которые используются в проекте (хранятся в маге в edit_params.fonts)
   * @param {Array} fonts
   * @returns {{google: Array, typekit: Array, webtype: Array}}
   */
  getVeryShortList: function(fonts) {
    var shortlist = {
      google: [],
      typekit: [],
      webtype: [],
      typetoday: [],
    };

    _.each(fonts, function(font) {
      var provider = font.provider;
      var details;
      switch (provider) {
        case 'google':
          details = {
            provider: provider,
            family: font.name,
            variations: font.variations,
          };
          break;

        case 'typekit':
          details = {
            provider: provider,
            id: font.css_name,
            name: font.name,
            variations: font.variations,
          };
          break;

        case 'webtype':
          details = {
            provider: provider,
            name: font.name,
            variations: font.variations,
          };
          break;

        case 'typetoday':
          details = {
            provider: provider,
            name: font.name,
            variations: font.variations,
          };
          break;
      }
      shortlist[provider] && shortlist[provider].push(details);
    });

    return shortlist;
  },

  /**
   * Возвращает нестандартное название для стандартного обозначения начертания
   * Напр. 'Hairline' вместо 'Thin' для n1
   * Такие названия встречаются, например в шрифтах Type.today
   * @param  {String} font_name - Название шрифта
   * @param  {String} fvd - стандартный fvd. Напр. n4
   * @return {String}
   */
  getCustomVariationNameByFVD: function(font_name, fvd) {
    var names = TextUtils.fontCustomVariationNames[font_name] || {};
    return names[fvd];
  },

  setShortList: function(shortlist) {
    TextUtils.fontsShortList = shortlist;
    TextUtils.fontCustomVariationNames = {};

    // TODO: Для отладки. Убрать.
    // var roboto = _.findWhere(textutils.fontsShortList.google, {family: 'Roboto'})
    // console.log('roboto : ', roboto);
    // roboto.custom_variation_names = {
    // 	"n1": "Harline"
    // }

    // Формируем список шрифтов с кастомными названиями начертаний, чтобы потом каждый раз не искать
    // их по полному списку. Вид: {'Kazimir': {'n1': 'Harline', 'n3': 'Book'}}
    _.each(TextUtils.fontsShortList, function(provider_data, provider_name) {
      _.each(provider_data, function(font) {
        if (font.custom_variation_names) {
          var font_name;

          switch (provider_name) {
            case 'google':
              font_name = font.family;
              break;
            case 'typekit':
            case 'webtype':
            case 'typetoday':
              font_name = font.name;
              break;
          }

          TextUtils.fontCustomVariationNames[font_name] = font.custom_variation_names;
        }
      });
    });
  },
};

// правим поведение бибилотеки тайпкита
// совершенно, надо сказать, варварским способом, но я уже 4-й час сижу и ничего более изящного и такого же надежного не придумал
// суть в том, чтобы остановить выполнение их гребаного скрипта ровно в тот момент, когда он создаст линк на css в хеаде документа
// иначе он потом еще 5 секунд как минимум просто занимается тотальной ерундой типа ожидания загрузки всех начертаний шрифта
// а это очень медленные операции (достаточно посмотреть на код Web Font Loader)
// (function() {
// 	//сохраняем указатель на старую функцию insertBefore
// 	//мы будем править прямо экземпляр объекта, а не его прототип
// 	var oldFunc = $('head')[0].insertBefore;
//
// 	//заменяем старую функцию на нее же + по окончанию работы insertBefore он кидает exception
// 	//тем самым просто обрушивая весь скрипт TypekitPreview и никакой код после этой функции не выполниться
// 	$('head')[0].insertBefore = function(newItem, existingItem) {
// 		oldFunc.apply(this, arguments);
// 		if (($(newItem).attr('href') || '').split('typekit').length > 1)
// 			throw null; //получайте суки
// 	};
// })(); //и сразу вызываем

// создаем калбэк который получит данные о шрифтах после загрузки файла //rm-static.s3.amazonaws.com/fonts/fontslist_short.json
// который имеент формат jsonp и загружается прямо в конструкторе как обычный скрипт
window.fontsShortListCallback = function(data) {
  TextUtils.setShortList(data);
};

export default TextUtils;
