/**
 * Вьюшка для указания точки привязки якорной ссылки
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../../templates/constructor/controls/anchor_picker.tpl';

const AnchorPicker = Backbone.View.extend({
  template: templates['template-constructor-control-anchor_picker'],

  initialize: function(params) {
    _.bindAll(this);
    _.extend(this, params);
  },

  render: function() {
    this.setElement($(this.template()));
    this.$picker = this.$el.find('.anchor-picker');
    this.$popup = this.$el.find('.popup');
    this.workspace = RM.constructorRouter.workspace;
    this.listenTo(this.workspace, 'workspace-resize', this.setOverlayPosition);
    this.setOverlayPosition();
    return this;
  },

  show: function() {
    this.render();
    this.$el.insertAfter(this.$after);

    $(window).on('resize', this.onWindowResize);
  },

  hide: function() {
    this.stopFollowing();
    $(window).off('resize', this.onWindowResize);
    this.remove();
  },

  startFollowing: function() {
    $(window).on('mousemove', this.onMouseMove);
    this.$el.on('click', this.onMouseClick);
    this.$el.addClass('target-mode');
    this.containerInitialScroll = this.$container.scrollTop();
  },

  stopFollowing: function() {
    $(window).off('mousemove', this.onMouseMove);
    this.$el.off('click', this.onMouseClick);
    this.$el.removeClass('target-mode');

    // Возвращаем конструктор в начальное положение
    if (this.containerInitialScroll !== undefined) {
      this.$container.animate({ scrollTop: this.containerInitialScroll }, 500);
    }
  },

  onMouseMove: function(e) {
    this.updateY(e.pageY + this.$container.scrollTop() - this.top);
    this.$popup.css('left', e.pageX);
  },

  onMouseClick: function(e) {
    this.stopFollowing();
    this.trigger('target-select', { y: e.offsetY });
  },

  updateY: function(y) {
    this.$picker.css('top', y);
  },

  onWindowResize: function(e) {
    this.setOverlayPosition();
  },

  setOverlayPosition: function() {
    var workspace_h = this.$after.height(),
      workspace_y = parseInt(this.$after.css('top'), 10),
      // Отступ у мобильного воркспейса сделан бордерами
      workspace_border_top = parseInt(this.$after.css('border-top-width'), 10);

    this.top = workspace_y + workspace_border_top;

    // Задаем такую же высоту, как у воркспейса, и позиционируем там же,
    // чтобы корректно позиционировать линию и иметь возможность
    // скроллить и ресайзить страницу как в режиме следования мыши, так и со статичной полоской
    this.$el.height(workspace_h);
    this.$el.css('top', this.top);
  },
});

export default AnchorPicker;
