/**
 * Модальное меню мега со списком страниц
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/viewer/mag-menu.tpl';
import Device from '../common/device';

const MagMenuClass = Backbone.View.extend({
  template: templates['template-viewer-mag-menu'],

  events: {
    'click .page': 'onPageClick',
  },

  initialize: function(params) {
    _.bindAll(this);

    _.extend(this, params);

    this.IMG_STUB = (RM.common.isDownloadedSource ? '' : RM_PUBLIC_PATH) + 'img/spacer.gif';

    if (!Device.isPhone) {
      // десктоп и планшет
      this.PAGE_WIDTH = 280;
      this.PAGE_HEIGHT = 184;
    } else {
      if (this.viewerType == 'horizontal') {
        // телефон, обычный режим просмотра мэга
        this.PAGE_WIDTH = 140;
        this.PAGE_HEIGHT = 92;
      } else {
        // телефон, режим просмотра одной большой скролл страницей
        this.PAGE_WIDTH = 240;
        this.PAGE_HEIGHT = 158;
      }
    }

    this.loadVisibleScreenshots.__debounced = _.debounce(this.loadVisibleScreenshots, 500);
    this.unloadInvisibleScreenshots.__debounced = _.debounce(this.unloadInvisibleScreenshots, 3000);

    return this; // для вызова по цепочке
  },

  render: function() {
    // создаем вьюху стандартым методом бекбона:
    // устанавливаем el, $el и делегацию событий из списка events
    this.setElement(
      this.template({
        mag: this.mag,
        pages: this.pages,
        width: this.PAGE_WIDTH,
        height: this.PAGE_HEIGHT,
        stub: this.IMG_STUB,
        viewerType: this.viewerType,
        publishDate: this.publishDate,
        hasProjectInfo: this.hasProjectInfo,
        common: RM.common,
      })
    );

    this.$el.appendTo(this.$container);

    this.$pagesWrapper = this.$('.pages-wrapper');

    var $pages = this.$('.page'),
      $logoWrapper = this.$('.logo-wrapper');

    if (this.viewerType == 'horizontal') {
      this.LOGO_SIZE = $logoWrapper.width() || 0;
      this.$('.pages').css('width', $pages.length * this.PAGE_WIDTH + this.LOGO_SIZE);
      this.$pagesWrapper.scrollLeft(99999); // специально прокручиваем ленту до конца, чтобы изначальный shiftToPage сдвинул страницы так, чтобы текущая оказалась с левого края
    } else {
      // vertical
      this.LOGO_SIZE = $logoWrapper.height() || 0;
      this.$('.pages').css('height', $pages.length * this.PAGE_HEIGHT + this.LOGO_SIZE);
      this.$pagesWrapper.scrollTop(99999); // специально прокручиваем ленту до конца, чтобы изначальный shiftToPage сдвинул страницы так, чтобы текущая оказалась с верхнего края
    }

    if (Device.isDesktop) {
      this.scroll = this.$('.scroll-wrapper')
        .RMScroll({
          $container: this.$pagesWrapper,
          $content: this.$('.pages'),
          $handle: this.$('.scroll'),
          wheelScrollSpeed: 1.3,
          gap_start: 8,
          gap_end: 8 + this.LOGO_SIZE,
          maxHandleSize: 136,
          dragScroll: true,
          tp: this.viewerType, // 'horizontal' || 'vertical'
        })
        .data('scroll');
    }

    this.$pagesWrapper.bind('scroll', this.onScroll);

    this.cacheSize();
    this.cacheScroll();
    this.cachePagesPos();

    this.loadedScreenshots = {};

    // название мэга идет в две строки, мспользуем плагин чтобы проставить троеточие
    // но только не для варианта телефона с горизонтальным меню, там другая верстка и название в одну строку
    if (!(this.viewerType == 'horizontal' && Device.isPhone)) {
      $('.menu-info .mag-title').dotdotdot({
        height: Device.isPhone ? 48 : 68,
        wrap: 'letter',
        callback: _.bind(function(isTruncated, orgContent) {
          if (isTruncated) this.$('.menu-info .mag-title').attr('title', this.mag.title);
        }, this),
      });
    }

    // если есть меню и мы не на десктопе навешиваем вытягивание меню за кнопку меню
    if (!Device.isDesktop) {
      var size = this.viewerType == 'horizontal' ? this.$el.height() : this.$el.width(),
        $items = this.$el.add(this.$toolbar);

      // добавляем $('.navigation-arrow.bottom') чтобы он двигался вместе с меню и тулбаром при их таскании
      // в вертикальном виде нижняя стрелка не должна бегать за меню
      if (this.viewerType == 'horizontal' && this.mag.navigation) $items = $items.add(this.mag.navigation.$bottom);

      // навешиваем наш самописный плагин для вытягивания-утягивания элементов
      this.menuSwipe = $items
        .RMSwipe({
          $scrollElements: this.$pagesWrapper,
          minTransitionSpeed: 200,
          maxTransitionSpeed: 400,
          rubberBand: false,

          getCurrentConstraints: _.bind(function() {
            // функция опрашивается в самом начале свайпа чтобы знать в каком направлении ожидается жест и его ограничения по сдвигу
            var res = {};

            // в качестве референсного объекта берем саму панельку меню
            // по ней будем смотреть завершения анимаций и пр.
            res['$referenceItem'] = this.$el;

            if (this.viewerType == 'horizontal') {
              if (this.shown) {
                res = {
                  up: { max: 0, noTrigger: true }, // noTrigger означает что в этом направлении триггерить жест не надо, но сам факт присутствия объекта у данного направления 'up' говорит о том, что в данном направлении будет работать пружинка и движение в этом направлении разрешено для инициации жеста
                  down: { max: size },
                };
              } else {
                res = {
                  up: { max: size },
                  down: { max: 0, noTrigger: true },
                };
              }
            } else {
              // vertical
              if (this.shown) {
                res = {
                  left: { max: 0, noTrigger: true },
                  right: { max: size },
                };
              } else {
                res = {
                  left: { max: size },
                  right: { max: 0, noTrigger: true },
                };
              }
            }

            return res;
          }, this),
          callback: _.bind(function(dir) {
            this.shown ? this.hide() : this.show();
          }, this),
        })
        .data('swipe');
    }
    if (this.isPreview) this.listenTo(RM.constructorRouter, 'renewScreenshot', this.renewScreenshot);

    this.listenTo(this.mag, 'pageChanged', this.onPageChange);
    this.listenTo(this.mag, 'finalPageShown', this.onFinalPageShow);
    this.listenTo(this.mag, 'finalPageHidden', this.onFinalPageHide);
    this.listenTo(this.mag, 'keypress-' + $.keycodes.m, this.toggle);
    this.listenTo(this.mag, 'keypress-' + $.keycodes.esc, this.hide);

    $(window).on('resize', this.onResize);

    this.$el.bind('mousewheel', this.onMouseWheel);

    return this; // для вызова по цепочке
  },

  show: function() {
    if (this.finalPageShown) return this;

    if (!this.shown) {
      this.shown = true;

      this.mag.$el.addClass('viewer-mag-menu-shown');

      this.mag.$el.toggleClass('viewer-mag-menu-no-project-info', !this.hasProjectInfo);

      this.loadOtherScreenshots();

      this.mag.trigger('menuShown');
    }

    return this; // для вызова по цепочке
  },

  toggle: function() {
    if (this.shown) return this.hide();
    // для вызова по цепочке
    else return this.show(); // для вызова по цепочке
  },

  hide: function() {
    if (this.shown) {
      this.mag.$el.removeClass('viewer-mag-menu-shown viewer-mag-menu-no-project-info');

      // перестаем грузить скрины
      this.clearLoadOtherTimeout();

      this.mag.focusCurrentPage();

      this.shown = false;

      this.mag.trigger('menuHidden');
    }

    return this; // для вызова по цепочке
  },

  destroy: function() {
    $(window).off('resize', this.onResize);

    this.$el.unbind('mousewheel', this.onMouseWheel);

    this.menuSwipe && this.menuSwipe.destroy();

    this.$pagesWrapper.unbind('scroll', this.onScroll);

    // удаляем вьюху стандратным методом бекбона:
    // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
    return this.remove(); // return this для вызова по цепочке
  },

  onMouseWheel: function(e) {
    // запрещаем прокручиваться основному контенту страницы при прокрутке колесиком над меню (внутрення лента страниц в меню будет работать как надо, мы блокируем колесо на уровне меню, чтобы оно не выходило за его пределы)
    e.preventDefault();
  },

  onResize: function() {
    this.cacheSize();

    // работает даже для скрытого меню, так надо дл правильной загрузки скриншотов
    this.scroll && this.scroll.recalc();

    this.mag.currentPage && this.shiftToPage(this.mag.currentPage.num, false);
  },

  onScroll: function() {
    this.cacheScroll();
  },

  cacheSize: function() {
    this.cachedData = this.cachedData || {};
    this.cachedData.scrollSize =
      this.viewerType == 'horizontal' ? this.$pagesWrapper.width() : this.$pagesWrapper.height();
  },

  cacheScroll: function() {
    this.cachedData = this.cachedData || {};
    this.cachedData.scrollPos =
      this.viewerType == 'horizontal' ? this.$pagesWrapper.scrollLeft() : this.$pagesWrapper.scrollTop();
  },

  // кешируем положение всех превьюшек страниц в меню
  // рассчитываем их положение, а не опрашиваем каждую, для скорости
  // похожий код есть в шаблоне
  cachePagesPos: function() {
    this.cachedData = this.cachedData || {};
    this.cachedData.pagesPos = {};

    var data = this.cachedData.pagesPos,
      viewerType = this.viewerType,
      width = this.PAGE_WIDTH,
      height = this.PAGE_HEIGHT;

    _.each(this.pages, function(page, ind) {
      data[page.num] = ind * (viewerType == 'horizontal' ? width : height);
    });
  },

  onPageChange: function(page, params) {
    if (!this.shown) {
      this.loadVisibleScreenshots.__debounced(); // с дебонсом просто для того чтобы не мешало анимации перелистывания основных страниц мэга
      !Device.isDesktop && this.unloadInvisibleScreenshots.__debounced(); // на планшетах-телефонах запускам очистку ресурсов с 3s дебонсом
    }

    // для скрытого меню без анимации
    this.shiftToPage(page.num, this.shown);

    // если в первый раз (не загружено еще ни одного скриншота) тогда загружаем все что сейчас видны
    if (_.isEmpty(this.loadedScreenshots)) this.loadVisibleScreenshots();
  },

  onFinalPageShow: function() {
    this.hide();

    this.finalPageShown = true;
  },

  onFinalPageHide: function() {
    this.finalPageShown = false;
  },

  shiftToPage: function(num, animation) {
    var $page = this.$('.page[data-num="' + num + '"]'),
      scrollSize = this.cachedData.scrollSize,
      scrollPos = this.cachedData.scrollPos,
      pageSize = this.viewerType == 'horizontal' ? this.PAGE_WIDTH : this.PAGE_HEIGHT,
      pagePos = this.cachedData.pagesPos[num],
      min = scrollPos + pageSize / 2,
      max = scrollPos + scrollSize - pageSize / 2,
      shift;

    this.$('.page.active').removeClass('active');

    $page.addClass('active');

    if (pagePos < min) {
      // превьюшка слишком слева, надо подвинуть вправо
      shift = pagePos - min;
    } else if (pagePos + pageSize > max) {
      // превьюшка слишком справа, надо подвинуть влево
      shift = pagePos + pageSize - max;
    } else {
      // превьюшка расположена в видимой части меню на расстоянии не менее чем половины размера скриншота от обоих краев
      return;
    }

    var attr = this.viewerType == 'horizontal' ? 'scrollLeft' : 'scrollTop',
      vals = {};

    vals[attr] = scrollPos + shift;

    this.$pagesWrapper.stop().animate(vals, animation ? 300 : 0);
  },

  onPageClick: function(e) {
    // если клик возник вследствие таскания ленты прямо мышкой, тогда клик обрабатывать не надо
    if (this.scroll && this.scroll.wasContentDrag) return;

    var pagenum = $(e.currentTarget).data('num');

    if (!this.mag.currentPage || pagenum != this.mag.currentPage.num) {
      this.mag.showPage(pagenum, { animation: false });
    }
  },

  renewScreenshot: function(page_id) {
    var $page = this.$('.page').filter(function() {
      return $(this).data('id') == page_id;
    });
    var page = RM.constructorRouter.mag.pages.find(function(page) {
      return page.id == page_id;
    });

    var src = page && page.getScreenshot(this.PAGE_WIDTH);

    // если картинка еще не загружена (есть атрибут data-src), тогда просто меняем этот атрибут на новый урл
    if ($page.attr('data-src')) {
      $page.attr('data-src', src);
    } else {
      // иначе меняем картинку
      $page.find('.screenshot').prop('src', src);
    }
  },

  getVisibleScreenshotRange: function() {
    var scrollSize = this.cachedData.scrollSize,
      scrollPos = this.cachedData.scrollPos,
      pageSize = this.viewerType == 'horizontal' ? this.PAGE_WIDTH : this.PAGE_HEIGHT;

    return {
      min: Math.max(0, Math.floor(scrollPos / pageSize)),
      max: Math.min(this.mag.getPagesCount() - 1, Math.floor((scrollPos + scrollSize) / pageSize)),
    };
  },

  loadVisibleScreenshots: function() {
    if (!this.mag.pages || !this.mag.pages.length) return;

    var range = this.getVisibleScreenshotRange(),
      self = this;

    this.$('.page').each(function(ind) {
      var $page = $(this),
        src = $page.attr('data-src');

      if (ind >= range.min && ind <= range.max && src && !self.loadedScreenshots[ind]) {
        var $page = $(this);
        $page.find('.screenshot').prop('src', src);
        $page.removeAttr('data-src');
        self.loadedScreenshots[ind] = true;
      }
    });
  },

  unloadInvisibleScreenshots: function() {
    // если меню открыто то не надо ничего выгружать, такое бывает так как unloadInvisibleScreenshots запускается с 3s дебонсом
    if (this.shown) return;

    var range = this.getVisibleScreenshotRange(),
      self = this;

    this.$('.page').each(function(ind) {
      var $page = $(this),
        src = $page.attr('data-src');

      if ((ind < range.min || ind > range.max) && !src && self.loadedScreenshots[ind]) {
        var $page = $(this);
        $page.attr('data-src', $page.find('.screenshot').prop('src'));
        $page.find('.screenshot').prop('src', self.IMG_STUB);
        delete self.loadedScreenshots[ind];
      }
    });
  },

  loadOtherScreenshots: function() {
    this.clearLoadOtherTimeout();

    this.loadOtherSafeTimeout = 500;
    // запускает цепочку загрузки скриншотов
    this.loadNextScreenshot();
  },

  loadNextScreenshot: function() {
    var range = this.getVisibleScreenshotRange(),
      middle = Math.floor((range.min + range.max) / 2),
      data = this.loadedScreenshots,
      max = this.mag.getPagesCount() - 1,
      forw,
      backw,
      pos;

    // смотрим в обе стороны от middle (того слайда который сейчас в меню стоит ближе всех к середине)
    // и находим ближайшую незагруженную картинку, ее и грузим, потом все повторяем до тех пор пока будет что грузить
    for (forw = middle; forw <= max; forw++) if (!data[forw]) break;
    for (backw = middle; backw >= 0; backw--) if (!data[backw]) break;

    // есливсе картинки загружены
    if (backw < 0 && forw > max) return;
    else if (forw > max)
      // если все картинки выше middle загружены
      pos = backw;
    else if (backw < 0)
      // если все картинки ниже middle загружены
      pos = forw;
    // смотрим какой индекс (forw или backw) ближе к middle
    else pos = forw - middle > middle - backw ? backw : forw;

    data[pos] = true;

    // var $page = this.$('.page:nth-child(' + (pos + 1) + ')'), //на ифоне работает через раз!
    var $page = this.$('.page').eq(pos),
      $img = $page.find('.screenshot'),
      self = this;

    // ждем загрузки картинки, после чего ищем какую следующую картинку будем грузить
    $img.one('load error', function(e) {
      clearTimeout(self.$curLoadingTimeout);
      self.loadNextScreenshot();
    });

    // на всякий случай если нет load или error в течение определенного времени (изначально 500мс) тогда идем дальше и не тормозим на проблемной картинке
    this.$curLoadingTimeout = setTimeout(function() {
      $img.off();
      self.loadOtherSafeTimeout *= 1.1; // для каждой следующей проблемной картинки увеличиваем интервал безопасного ожидания, а то можем на слабом интернете начать думать что все картинки проблемные и по таймеру заполнять очередь, лучше от этого интернет не станет
      self.loadNextScreenshot();
    }, this.loadOtherSafeTimeout);

    // фактически грузим картинку
    $img.prop('src', $page.attr('data-src'));

    // помечаем что картинка уже загружена и отображена (она все равно будет загружена и отображена, отменить загрузку мы все равно не можем раз она инициирована, но можем отменить колбек загрузки)
    $page.removeAttr('data-src');

    // сохраняем текущую загружаему картинку, чтобы потом моно было отменить цепочку загрузок
    this.$curLoadingScreenshot = $img;
  },

  clearLoadOtherTimeout: function() {
    this.$curLoadingScreenshot && this.$curLoadingScreenshot.off();
    clearTimeout(this.$curLoadingTimeout);
  },
});

export default MagMenuClass;
