<template>
  <div id="rm-footer">
    <footer>
      <div class="footer-top">
        <div class="company">
          <div class="title">Company</div>
          <ul>
            <li>
              <a :href="`${homeUrl}/about/`">About</a>
            </li>
            <li>
              <a href="mailto:jobs@readymag.com">Work with us</a>
            </li>
            <li>
              <a :href="`${homeUrl}/readymag/masters`">R/masters</a>
            </li>
          </ul>
        </div>
        <div class="updates">
          <div class="title">Keep updated</div>
          <div class="row">
            <ul>
              <li>
                <a :href="`${homeUrl}/readymag/product-updates/`">Product updates</a>
              </li>
              <li>
                <a href="http://subscribe.readymag.com/">Subscribe to newsletter</a>
              </li>
            </ul>
            <ul class="mobile-no-margin">
              <li>
                <a href="https://www.facebook.com/readymag">Facebook</a>
              </li>
              <li>
                <a href="https://twitter.com/readymag">Twitter</a>
              </li>
              <li>
                <a href="https://medium.com/@readymag">Medium</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://dribbble.com/readymag">Dribbble</a>
              </li>
              <li>
                <a href="https://www.instagram.com/readymag/">Instagram</a>
              </li>
              <li>
                <a href="https://www.pinterest.ru/readymagpins/boards/">Pinterest</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="support">
          <div class="title">Support</div>
          <ul>
            <li>
              <a href="https://help.readymag.com/">Help</a>
            </li>
            <li>
              <a href="mailto:support@readymag.com">support@readymag.com</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="inc">© 2019 Readymag Inc.</div>
        <div class="terms">
          <a :href="`${homeUrl}/readymag/terms-and-privacy/`">Terms of service</a>
          <a :href="`${homeUrl}/readymag/terms-and-privacy/2/`">Privacy policy</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Constants } from '../../js/common/utils';

export default {
  data: () => ({
    homeUrl: Constants.readymag_host,
  }),
};
</script>

<style lang="less">
@orange: #ec520b;
@font: Graphik;
@bg: rgb(40, 40, 40);

#rm-footer {
  background: @bg;
  height: 100%;
  width: 100%;

  &.transform footer {
    padding: 0 !important;
  }

  footer {
    margin: 0 auto;
    width: 1024px;
    height: 100%;
    padding: 58px 20px 16px;
    box-sizing: border-box;
  }

  .footer-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 38px;
  }

  .title {
    font-family: @font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 24px;
    color: #808080;
    border-bottom: 1px solid rgba(128, 128, 128, 0.24);
    padding-bottom: 6px;
    margin-bottom: 16px;
  }

  .company {
    width: 230px;
  }

  .support {
    width: 230px;
  }

  .updates {
    width: 482px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      a {
        color: #fff;
        text-decoration: none;
        font-family: @font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.4px;
        line-height: 24px;
        display: inline-block;

        &:hover {
          color: @orange;
        }
      }
    }
  }

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    ul {
      &:last-child {
        margin-right: 30px;
      }
    }
  }

  .footer-bottom {
    display: flex;
    align-items: flex-start;
    color: #808080;
    font-family: @font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 24px;

    a {
      color: #808080;
      font-family: @font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      text-decoration: none;

      &:hover {
        color: @orange;
      }

      &:after {
        content: ', ';
      }

      &:last-child:after {
        content: none;
      }
    }

    .inc {
      width: 252px;
    }
  }
}

.rm-footer-mobile {
  .mobile-no-margin {
    margin: 0 !important;
  }

  #rm-footer {
    .isphone & {
      padding-right: 4px;
      margin-left: -4px;
      padding-left: 4px;
    }

    footer & {
      padding-right: 0 !important;
      margin-left: 0 !important;
      padding-left: 0 !important;
    }

    footer {
      width: 100%;
    }

    .footer-top {
      display: block;
      margin-bottom: 76px;
      > div {
        width: 100%;
        display: block;
      }
    }

    .row {
      display: block;

      ul {
        &:last-child {
          margin-right: 0;
        }
      }
    }

    ul {
      margin-bottom: 36px;
    }

    .footer-bottom {
      display: block;

      > div {
        width: 100%;
        line-height: 1;
      }
    }
  }
}

.rm-footer-full-width {
  &:before {
    content: '';
    top: 0;
    right: 99%;
    width: 100%;
    height: 100%;
    background: @bg;
    display: block;
    position: absolute;
  }

  &:after {
    content: '';
    top: 0;
    left: 99%;
    width: 100%;
    height: 100%;
    background: @bg;
    display: block;
    position: absolute;
  }
}

.widget-rm-footer {
  background: @bg;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .rm-footer-mobile;
}
</style>
