/**
 * Базовый класс для блока.
 * Блок — это конструктор виджета. Способ, которым он задается.
 */
import $ from '@rm/jquery';
import Vue from 'vue';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import Viewports from '../common/viewports';
import templates from '../../templates/constructor/block.tpl';
import blockFrameTpl from '../../templates/constructor/block-frame.tpl';
import SizesVue from '../../components/constructor/workspace/sizes.vue';
import { Utils, Constants } from '../common/utils';
import MathUtils from '../common/mathutils';
import AnimationUtils from '../common/animationutils';
import PageModel from './models/page';
import BlockFrameClass from './block-frame';
import RetargetMouseScroll from '../vendor/retargetmousescroll';

/**
 * Базовый класс для блока.
 * Блок — это конструктор виджета. Способ, которым он задается.
 */
var Block = Backbone.View.extend(
  {
    SUPER_SMALL_TRESHOLD: 10, // минимальны размер блока при котором мы показываем его просто в виде точки (пока актуально только для блоков шагов анимаций)

    /**
     * Максимальный z-index блока (выше начинаются гайды, сетка и рамки трансформа). См. Constants.less, @widgets-top-level
     */
    MAX_Z_INDEX: 2000,

    TOOLTIP_OFFSET: 16,

    /**
     * Свойство, которое указывает, что блоку не надо передавать координаты прямоугольника, в котором будет виджет.
     * Наример, для виджета фона
     */
    outofbox: false,

    /**
     * Если true, то нельзя добавить из библиотеки виджетов
     */
    excludedFromLib: false,

    /**
     * Если выставлено в true, то виджет нельзя удалить
     */
    immortal: false,

    template: null,

    /**
     * События
     */
    events: {},

    // базовый список полей, которые уникальны для каждого вьюпорта
    // этим списком оперирует модель вьюпорта
    // у некоторых виджетов (например text или bg этот список может быть расширен)
    viewport_fields: Viewports.viewport_fields_common,

    controls: ['common_animation', 'common_position', 'common_layer', 'common_lock'],

    settingsOnCreate: true,

    /**
     * Переопределяется, если надо.
     */
    initialize: function(model, workspace) {
      this.initBlock(model, workspace);
    },

    /**
     * Общие для всех блоков вещи
     * должен вызываться в инициализации наследника
     */
    initBlock: function(model, workspace) {
      _.bindAll(this);
      this.model = model;

      // За признаками следит Vue, по-этому надо, чтобы они были сразу
      this.isLoading = false;
      this.selected = false;
      this.forbidVisualRotation = false;

      this.triggerAssets = {};

      if (model.get('hidden')) this.triggerReady();

      // workspace.js для виджетов на верхнем уровне
      // или workspace-inside-block.js для вложенных виджетов
      // (существующих внутри других виджетов).
      this.workspace = workspace;

      this.workspace.on('deselect', this.deselect);
      this.model.on('change', this.onModelChange);
      this.model.on('change:pack_id', this.onPackChange);
      this.model.on('change:is_locked', this.updateLockDragState);
      this.model.on('change:hidden', this.onBlockVisibilityChange);
      this.model.on('change:fixed_position', this.onFixPosition);

      this.listenTo(this.model, 'animation:trigger:add', this.onAnimationTriggerAdd);
      this.listenTo(this.model, 'animation:trigger:remove', this.onAnimationTriggerRemove);
      this.listenTo(this.model, 'animation:type:change', this.resetAnimationTriggers);

      // Пересоздавать нельзя! Только изменять существующий. За ним следит Vue
      this.latestPosSizeAngle = this.getModelBox();

      var sticked_side = this.getStickedSide();
      if (sticked_side == 'left' || sticked_side == 'right') {
        this.latestPosSizeAngle.sticked_margin = this.calcStickedMargin(
          this.latestPosSizeAngle.left,
          this.latestPosSizeAngle.width
        );
      } else if (sticked_side == 'top' || sticked_side == 'bottom') {
        this.latestPosSizeAngle.sticked_margin = this.calcStickedMargin(
          this.latestPosSizeAngle.top,
          this.latestPosSizeAngle.height
        );
      }

      this.$document = $(document);

      _.extend(this.latestPosSizeAngle, MathUtils.calcBoundingBox(this.latestPosSizeAngle));

      // наличие в модели аттрибута «parent_wid»
      // c айдишником родительского виджета означает,
      // что данный виджет создан внутри другого виджета
      // (через workspace-inside-block.js).
      // такой виджет в камментах обычно называется «вложенным».
      // например w-picture или w-text внутри хотспот виджета.
      // т.к. вложенный виджет работает в специальном
      // воркспейсе workspace-inside-block.js,
      // некоторые части логики block.js не выполняются,
      // или выполняются по-другому, т.к. эта логика
      // или не нужна вложенным виджетам или должна работать по-другому,
      // или слишком завязана на workspace.js.
      // workspace-inside-block.js работает как прокси-воркспейс
      // между вложенным виджетом и workspace.js (воркспейсом страницы),
      // позволяя создавать вложенные виджеты, добавлять другую общую логику работs с ними
      // и общаться с ворскпейсом страницы отлично от виджетов на верхнем уровне.
      this.has_parent_block = !!this.model.get('parent_wid');
    },

    /**
     * Вызывается для отрисовки блока
     * Может быть переопределен в наследниках
     */
    render: function() {
      this.create();
    },

    // Сообщаем что виджет отрендерен и готов к работе
    triggerReady: function() {
      this.model.triggerReady();
    },

    // логика вложенных виджетов — работающих внутри другого виджета
    // во внутриблоковом воркспейсе workspace-inside-block.js.
    init_as_child_block: function() {
      // чтобы скрывался без анимации опасити.
      this.$el.addClass('no-transitions').addClass('has-parent-block');

      if (this.model.get('type') === 'picture') {
        this.model.on('change:picture', this.workspace.determine_column_widgets_height_fitting);
      }
    },

    onModelChange: function(model, options) {
      this.redraw(model, options);
      this.checkDisabledControls(model);
    },

    /**
     * Собственно, отрисовка блока.
     * Вызывается в рендере.
     */
    create: function() {
      this.setElement(
        $(
          this.template({
            block: this.model.attributes,
            templates: { ...blockFrameTpl },
          })
        )
      );

      if (this.has_parent_block) {
        // если виджет вложен в другой.
        this.$el.appendTo(this.workspace.$blocksWrapper);
      } else if (!this.isStandalone) {
        this.$el.appendTo(this.workspace.$el.children().first());
      } else {
        this.$el.appendTo(this.$standaloneContainer); // это для фреймов шагов анимаций
      }

      this.toggleInPackClass();

      // обязательно определяем this.$content при рендере и используем только это закешированное значение
      // дело в том, что у нас есть хотспот внутри которого есть другие виджеты и свои .content
      // в также из-за анимаций, где внутри блоков анимаций есть блоки виджетов у которых тоже свои .content
      this.$content = this.$('.content');

      var FrameClass = this.frameClass || BlockFrameClass;

      this.frame = new FrameClass({ block: this });

      if (this.frameColor) {
        this.$('.frameborder').css({
          'border-color': this.frameColor,
        });
      }

      this.bindEvents();

      this.rendered = true;

      this.checkRetargetScroll();

      this.workspace.on('workspace-resize', this.onWorkspaceResize);

      if (this.model.get('is_full_width')) {
        this.$el.addClass('full-width');
      }

      if (this.model.get('is_full_height')) {
        this.$el.addClass('full-height');
      }

      if (this.has_parent_block) {
        // если виджет вложенный.

        this.init_as_child_block();
      }

      this.checkDisabledControls(this.model, true);
    },

    toggleInPackClass: function(value) {
      // Можно добавить класс in-pack, даже если виджет не в группе / у него нет pack id
      // (нужно чтобы скрыть рамки виджетов, когда их выделено несколько).
      // Нельзя убрать класс in-pack, если виджет в группе.
      var isInPack = Boolean(value || this.model.get('pack_id'));
      this.$el.toggleClass('in-pack', isInPack);
      // NOTE: Здесь нельзя управлять видимостью плашки sizes, потому что она не всегда прячется, когда виджет в группе.
      // Например, если виджет выбран из панели слоёв, даже если он в группе, плашка и рамка показывается.
    },

    /**
     * Возвращает полный урл для картинки виджета
     */
    getThumb: function(format) {
      if (!this.thumb) return '';

      var prefix = RM_PUBLIC_PATH + 'img/constructor/widgetbar/icons/',
        postfix = '.png',
        thumb;

      if (Modernizr.retina) {
        if (format === 'small') thumb = this.thumb + '-92';
        else thumb = this.thumb + '-184';
      } else {
        if (format === 'small') thumb = this.thumb + '-46';
        else thumb = this.thumb + '-92';
      }

      return prefix + thumb + postfix;
    },

    /**
     * Изменяет блок
     * resizePoint - опционально содержит идентификатор точки за которую ресайзили (только если css вызывался из функции ресайза точками)
     */
    css: function(params, resizePoint) {
      params = params || {};

      var $el = params.clone ? this.$cloneEl : this.$el,
        transformStr;

      //			var pickParams = ['width', 'height', 'top', 'left', 'bottom', 'right', 'margin-left', 'margin-top']

      if (params['z-index']) {
        // Фиксированным блокам нужно проставлять z-index у самого .block а не у .content, иначе он не будет взаимодействовать с "обычными" виджетами
        var blockZ = this.model.get('fixed_position') ? params['z-index'] : '';
        var contentZ = blockZ ? '' : params['z-index'];
        var position = blockZ ? 'fixed' : '';

        $el.css({ 'z-index': blockZ, position: position });

        this.$content.css({ 'z-index': contentZ });
      }

      // применяем поворот  и отражения по горизонтали-вертикали, если есть
      // для начала получаем текущее состояние поворота
      // после чего обновляем в нем значения поворота-отражения если таковые есть в params
      var rotation = _.extend(_.clone(this.latestPosSizeAngle), params);

      // Все трансформации накладываются не на сам блок, а на его содержимое
      // Трансформация создает свой stacking context, и если трансформировать блоки,
      // то z-index содержимого блоков перестанет действовать глобально для воркспейса
      // В результате невозможно, например, будет вывести рамку выделенного виджета поверх других
      if (
        (rotation['angle'] || rotation['flip_v'] || rotation['flip_h']) &&
        !this.forbidVisualRotation &&
        !this.isFullWidth() &&
        !this.isFullHeight()
      ) {
        // добавляем  rotateX(0deg) rotateY(0deg) для сглаживания текста и картинок при повороте

        // rotateX(0deg) rotateY(0deg) убрано из-за бага хрома, когда шейпы оказывались поверх остальных виджетов
        // 11.05.2014 https://trello.com/c/ysEsGYOg/59-design-odyssey-2
        transformStr =
          //					(rotation['angle'] ? ' rotateZ(' + rotation['angle'] + 'deg)  rotateX(0deg) rotateY(0deg)' : '') +
          (rotation['angle'] ? ' rotateZ(' + rotation['angle'] + 'deg)' : '') +
          (rotation['flip_v'] ? 'scaleY(-1)' : '') +
          (rotation['flip_h'] ? 'scaleX(-1)' : '');
      } else {
        transformStr = '';
      }

      Utils.applyTransform($el.children('div:not(.skip-rotate)'), transformStr);

      // переменная latestPosSizeAngle хранит текущие координаты и размеры блока и поворот
      // даже в момент перетаскивания блока или поворота мышью за точку (поскольку при перетаскивании-вращении данные в можели не меняються до тех пор пока не отпустим мышь)
      // нужна для getBoxData
      // может содержать неполные данные, т.е. например только положение или только поворот
      // в этом случае все недостающие данные можно брать из модели, значит там актуалка (этим рулит getBoxData)
      if (!params.clone) {
        _.extend(
          this.latestPosSizeAngle,
          _.pick(params, 'width', 'height', 'left', 'top', 'angle', 'flip_v', 'flip_h', 'z-index')
        );

        // Если угол не передан в параметрах, то не рассчитываем синусы и косинусы лишний раз
        // проверяем именно на undefined, т.е. переданы параметры или нет
        if (params.angle != undefined) {
          this.latestPosSizeAngle.sinAngle = Math.sin((params.angle * Math.PI) / 180); // для ускорения всех расчетов
          this.latestPosSizeAngle.cosAngle = Math.cos((params.angle * Math.PI) / 180); // для ускорения всех расчетов
        }

        // если изменилось что-либо, что относиться к повороту
        // то нам надо заново пересчитать по точкам ресайза какая точка в какую "часть света" теперь указывает
        // проверяем именно на undefined, т.е. переданы параметры или нет
        if (params.angle != undefined || params.flip_v != undefined || params.flip_h != undefined) {
          this.frame && this.frame.recalcPointsDirections();
        }

        _.extend(this.latestPosSizeAngle, MathUtils.calcBoundingBox(this.latestPosSizeAngle));
      }

      var cssParams = {};

      // Если блок повернут на угол, кратный 90,
      // производим корректировку высоты таким образом, чтобы
      // высота и ширина были обе: либо четные, либо нечетные
      // Иначе размывается все содержимое блока, включая рамку, точки и контент
      if (
        this.latestPosSizeAngle.angle &&
        this.latestPosSizeAngle.angle % 90 == 0 &&
        Math.round(this.latestPosSizeAngle.height) % 2 !== Math.round(this.latestPosSizeAngle.width) % 2
      ) {
        cssParams = _.extend(_.pick(params, 'width', 'height', 'left', 'top', 'margin-top', 'margin-left'), {
          height: Math.round(this.latestPosSizeAngle.height),
          width: Math.round(this.latestPosSizeAngle.width) + 1,
        });
      } else cssParams = _.pick(params, 'width', 'height', 'left', 'top', 'margin-top', 'margin-left');

      if (this.model.get('fixed_position') && !_.isEmpty(cssParams)) {
        var size = _.pick(this.latestPosSizeAngle, 'width', 'height');

        _.extend(cssParams, size);

        // Во вьюпортах нужно фиксировать виджет относительно рамки воркспейса а не границ экрана
        if (this.model.getViewport() != 'default') {
          position = this.workspace.position;
          if (['n', 'c', 's'].indexOf(this.model.get('fixed_position')) == -1) cssParams.left += position.left;
          if (['e', 'c', 'w'].indexOf(this.model.get('fixed_position')) == -1) cssParams.top += position.top;
        }

        // Конечный css получается общей для вьювера и конструктора функцией, на выходе могут получиться margin-left и margin-top вместо left и top
        _.extend(cssParams, Utils.getFixedPositionCSS(this.model.get('fixed_position'), cssParams, 1));
      }

      var sticked_side = this.getStickedSide();
      if (sticked_side == 'left' || sticked_side == 'right') {
        this.latestPosSizeAngle.sticked_margin = this.calcStickedMargin(
          this.latestPosSizeAngle.left,
          this.latestPosSizeAngle.width
        );
      } else if (sticked_side == 'top' || sticked_side == 'bottom') {
        this.latestPosSizeAngle.sticked_margin = this.calcStickedMargin(
          this.latestPosSizeAngle.top,
          this.latestPosSizeAngle.height
        );
      }

      $el.css(cssParams);

      // если виджет не вложенный.
      // у вложенного виджета воркспейс не имеет
      // метода redrawPacksFrames(), выдаст ошибку.
      // также не стит ничего делать если блок isStandalone (без виджета, например шаг анимации)
      if (!this.has_parent_block && !this.isStandalone) {
        // вызываем пересчет размеров-положения рамок групп
        // но только в том случае, если вызов css не был инициирован из функции moveBlocks
        // в таком случае там отдельно будет вызов после того как все блоки будут смещены (чтобы по сто раз не вызывалось при перемещении группы виджетов)
        if (!params.doNotRedrawPacksFrames) this.workspace.redrawPacksFrames({ tp: 'css' });

        if (!params.doNotRedrawBottomShiftLine) this.workspace.redrawBottomShiftLine({ tp: 'css' });
      }

      // помечаем супер маленькие блоки особым классом, чтобы они не выделенные рисовались просто точкой
      var pos = this.latestPosSizeAngle,
        superSmall =
          pos.width >= 0 &&
          pos.width <= this.SUPER_SMALL_TRESHOLD &&
          pos.height >= 0 &&
          pos.height <= this.SUPER_SMALL_TRESHOLD;

      this.$el.toggleClass('super-small', superSmall);
    },

    onWorkspaceResize: function() {
      if (this.model.get('is_full_width')) {
        this.model.set(this.getFullWidthDims());
      }

      if (this.model.get('is_full_height')) {
        this.model.set(this.getFullHeightDims());
      }

      if (this.model.get('sticked')) {
        this.setStickedDims();
      }
    },

    getFullWidthDims: function(margin) {
      var fixed = this.model.get('fixed_position'),
        dims = {},
        ignoreMargin,
        ignoreScrollbar = false,
        // Ширина скроллбара нужна для ее учета при отрисовке растянутых фикседов,
        // чтобы они не наезжали на скроллбар
        scrollbar_w = this.workspace.$container.width() - this.workspace.$container[0].clientWidth;

      // Может быть и 0
      if (margin == undefined) {
        margin = parseInt(this.model.get('full_width_margin'), 10) || 0;
      }

      if (fixed) {
        // Если есть привязка к югу, центру, или северу
        ignoreMargin = ['s', 'c', 'n'].indexOf(fixed) > -1;

        ignoreScrollbar = _.contains(fixed, 'e') || _.contains(fixed, 'w'); // Привязка к западу или востоку

        dims.x = (ignoreMargin ? 0 : margin) - Math.ceil((ignoreScrollbar ? 0 : scrollbar_w) / 2);
      } else {
        dims.x = -this.workspace.position.left + margin;
      }

      dims.w = this.workspace.$container.width() - margin * 2 - (ignoreScrollbar ? 0 : scrollbar_w);

      return dims;
    },

    // возвращает координату y относильно воркспейса и высоту блока
    // расчеты для fixed и не fixed делается по-разному, так как
    // в случае не fixed виджет, допустим, картинки, должан растягиваться на всю высоту страницы,
    // а в случае fixed на высоту окна браузера;
    // margin - расстояние между виджетом и страницей сверху и снизу
    getFullHeightDims: function(margin) {
      var fixed = this.model.get('fixed_position'),
        dims = {},
        containerHeight = this.workspace.$container.height(),
        pageHeight = this.workspace.page.getCurrentViewportParam('height'),
        ignoreMargin;

      // Может быть и 0
      if (margin == undefined) {
        margin = parseInt(this.model.get('full_height_margin'), 10) || 0;
      }

      if (fixed) {
        // Если есть привязка к западу, центру, или востоку
        ignoreMargin = ['w', 'c', 'e'].indexOf(fixed) > -1;

        dims.y = ignoreMargin ? 0 : margin;
        dims.h = containerHeight - margin * 2;
      } else {
        dims.y = -this.workspace.position.top + margin;
        dims.h = (containerHeight >= pageHeight ? containerHeight : pageHeight) - margin * 2;
      }

      return dims;
    },

    // рассчитываем x относительно ворспейса для заданной точки привязки и марджина от края экрана
    //
    // Если высота страницы меньше высоты экрана, тогда вычитаем отступ от страницы до экрана.
    // Это нужно для кейса bottom/top sticked block — on all pages:
    // Допустим, если одна страница длиннее экрана, а другая нет, тогда стикед блок на обеих страницах прилипнет к экрану,
    // хотя по идее должен прилипнуть к нижней границе страницы, а не экрана (тоже самое с верней границей)
    // тоже самое для мобильного вьюпорта, где всегда есть отступ
    // PS так же относится и к calcStickedMargin
    getStickedDims: function(side, marg) {
      var sticked = side || this.model.get('sticked'),
        margin = marg != undefined ? marg : parseInt(this.model.get('sticked_margin'), 10) || 0,
        containerWidth = this.workspace.$container.width(),
        containerHeight = this.workspace.$container.height(),
        pageHeight = this.workspace.page.getCurrentViewportParam('height'),
        scrollbar_w = containerWidth - this.workspace.$container[0].clientWidth,
        isMobileViewport = this.model.getViewport() !== 'default',
        dims = {};

      if (sticked == 'left') {
        dims.x = -this.workspace.position.left + margin;
        dims.y = this.model.get('y');
      } else if (sticked == 'right') {
        dims.x = containerWidth - scrollbar_w - margin - this.model.get('w') - this.workspace.position.left;
        dims.y = this.model.get('y');
      } else if (sticked == 'top') {
        dims.x = this.model.get('x');
        dims.y = margin + (pageHeight < containerHeight ? 0 : -this.workspace.position.top);
      } else if (sticked == 'bottom') {
        dims.x = this.model.get('x');
        // Здесь учитываем именно высоту страницы, а не контейнера
        dims.y =
          (pageHeight < containerHeight || isMobileViewport ? pageHeight - this.workspace.position.top : pageHeight) -
          margin -
          this.model.get('h') +
          this.workspace.position.top;
      }

      return dims;
    },

    setStickedDims: function() {
      // Если у виджета есть position sticky != bottom в недесктопном вьюпорте > сбрасываем позицию
      const sticked_side = this.model.get('sticked');
      if (sticked_side && sticked_side !== 'bottom' && this.model.getViewport() !== 'default') {
        this.model.unset('sticked');
      }

      if (!this.model.get('sticked')) {
        return;
      }
      this.model.set(this.getStickedDims());
    },

    // обратная операция к getStickedDims
    // рассчитываем марджин от края экрана на основе текущего x относительно ворспейса
    // distance - left или top
    // size - width или height
    calcStickedMargin: function(distance, size) {
      var sticked = this.model.get('sticked'),
        containerWidth = this.workspace.$container.width(),
        containerHeight = this.workspace.$container.height(),
        pageHeight = this.workspace.page.getCurrentViewportParam('height'),
        scrollbar_w = containerWidth - this.workspace.$container[0].clientWidth,
        isMobileViewport = this.model.getViewport() !== 'default',
        margin = 0;

      if (sticked == 'left') {
        margin = distance + this.workspace.position.left;
      } else if (sticked == 'right') {
        margin = containerWidth - scrollbar_w - size - distance - this.workspace.position.left;
      } else if (sticked == 'top') {
        margin = distance + (pageHeight < containerHeight ? 0 : -this.workspace.position.top);
      } else if (sticked == 'bottom') {
        // Здесь учитываем именно высоту страницы, а не контейнера
        margin =
          (pageHeight < containerHeight || isMobileViewport ? pageHeight - this.workspace.position.top : pageHeight) -
          size -
          distance +
          this.workspace.position.top;
      }

      return margin;
    },

    getIconStyle: function(options) {
      var res = {};

      var path = RM_PUBLIC_PATH + 'img/constructor/widgetbar/icons/';

      if (options && options.small) {
        path += 'small/';

        if (this.icon_color) {
          res['background-color'] = this.icon_color;
        }
      }

      var image = path + this.model.get('type') + '.svg';

      res['background-image'] = 'url(' + image + ')';

      return res;
    },

    bindEvents: function() {
      this.$el.on('click', this.onClick);
      this.$el.on('dblclick', this.onDblClick);

      if (!this.has_parent_block) {
        // если виджет не вложенный.

        // обязательно запрещать таскать за :input, который БЕЗ аттрибута readonly.
        // например, виджет Кнопки сам решает, когда навесить
        // на свой инпут аттрибут readonly, чтобы таскание Кнопки за текст не запрещалось.
        $(this.$el)
          .drag('start', this.onDragStart, { not: '.no-drag, :input:not([readonly]), .size-tool' })
          .drag(this.onDragMove, { delay: 100 })
          .drag('end', this.onDragEnd);

        this.workspace.on('select', this.onWorkspaceBlocksSelect);
        this.updateLockDragState();
      }
    },

    // смотрит поменялся ли список конролов которые нужно показать в виджете
    // и если да, то перепоказывает нужные (используется в виджетах шейпов и слайдшоу)
    updateControls: function(opts) {
      opts = opts || {};

      var workspaceControls;

      var workspace_for_restrictions_check;

      if (this.has_parent_block) {
        // если виджет вложенный,
        // то его менеджер контролов находится в воркспейсе страницы,
        // а не в внутриблоковом воркспейсе, в котором он существует.

        workspaceControls = this.workspace && this.workspace.page_workspace && this.workspace.page_workspace.controls;

        workspace_for_restrictions_check = this.workspace.page_workspace;
      } else {
        workspaceControls = this.workspace && this.workspace.controls;

        workspace_for_restrictions_check = this.workspace;
      }

      if (!workspaceControls) return;

      // смотрим что у нас сейчас в контролах
      // мы должны убирать или добавлять контролы только в том случае
      // если у нас сейчас выделен текущий блок и только он
      // поскольку изменения типа могут прийти при ундо-редо, а при этом блок может быть не выделен, или может быть выделено несколько блоков
      // в любом случае менять контролы мы должны только в том случае если они сейчас показаны конкретно для текущего блока
      if (
        !workspaceControls.selectedBlocks ||
        workspaceControls.selectedBlocks.length != 1 ||
        workspaceControls.selectedBlocks[0].id != this.id
      ) {
        return;
      }

      var currentVisibleControls = _.pluck(workspaceControls.controls, 'name');
      // Фильтруем контролы через их restrictions, чтобы корректно сравнить с уже фактически показанными

      const Controls = require('./controls').default;

      var controlsToBeVisible = _.filter(
          this.controls,
          function(c) {
            return (
              !Controls[c].prototype.restrictions ||
              !!Controls[c].prototype.restrictions.call(this, workspace_for_restrictions_check)
            );
          }.bind(this)
        ),
        isControlChanged =
          _.difference(controlsToBeVisible, currentVisibleControls).length ||
          _.difference(currentVisibleControls, controlsToBeVisible).length;

      if (isControlChanged) {
        workspaceControls.setControls(this.controls, this, opts);
      }
    },

    // доскролливает до виджета, если он не видет на экране
    scrollTo: function() {
      var element_is_bellow = this.$el.offset().top > document.body.clientHeight;
      var element_is_above = this.$el.offset().top < 0;

      if (element_is_above || element_is_bellow) {
        var $m = $('#main');
        $m.stop().animate(
          { scrollTop: $m.scrollTop() + this.$el.offset().top - 100 },
          '200',
          'swing',
          function() {
            _.delay(
              function() {
                this.frame.sizes && this.frame.sizes.applyPosition();
              }.bind(this),
              400
            );
          }.bind(this)
        );
      }
    },

    selectFromWidgetBar: function(event, selectGroup) {
      if (!event) {
        event = {
          shiftKey: false,
          ctrlKey: false,
        };
      }
      this.workspace.trigger('block:click', this, event);

      if (!event.shiftKey && !(event.ctrlKey || event.metaKey)) {
        this.workspace.trigger('deselect', this);
      }

      if ((event.ctrlKey || event.metaKey) && this.selected && !_.isEmpty(this.workspace.getSelectedBlocks())) {
        this.deselect();
        if (!_.isEmpty(this.workspace.getSelectedBlocks())) {
          this.workspace.trigger('select', this.workspace.getSelectedBlocks(), [this]);
        } else {
          this.workspace.trigger('deselect', this);
        }
        return; // мы сделали действие: поэтому не смотрим дальше
      }

      this.select(!selectGroup);

      if (!_.isEmpty(this.workspace.getSelectedBlocks())) {
        // добавляем/удаляем группы в выделении
        var redefinedSelection = {
          selected: this.workspace.getSelectedBlocks(),
          deselected: [],
        };
        if (selectGroup) {
          redefinedSelection = this.workspace.redefineSelectionWithPacks(this.workspace.getSelectedBlocks());
        }
        this.workspace.trigger('select', redefinedSelection.selected, redefinedSelection.deselected);
      }
    },

    onDblClick: function() {
      // если у нас просто блок не привязаный к виджетам (типа фреймы шагов анимаций) то клик не обрабатываем
      if (this.isStandalone) return;
      if (!this.has_parent_block && this.model.get('pack_id')) {
        this.selectFromWidgetBar();
      }
    },

    /**
     * Вызывается при клике по блоку
     * disableControlsCheck - флаг, если true, тогда мы не будем опрашивать Controls
     * этот флаг устанавливается при клике по виджету из виджетбара
     * чтобы когда мы там кликнули по виджету текущий всегда закрывался (даже если у него есть контролы с открытыми панельками)
     */
    onClick: function(event, disableControlsCheck) {
      this.workspace.trigger('block:click', this, event);

      // если у нас просто блок не привязаный к виджетам (типа фреймы шагов анимаций) то клик не обрабатываем
      if (this.isStandalone) return;

      if (!this.has_parent_block) {
        // если виджет не вложенный.

        if (this.outofbox) return;

        if (window.suppressClick && window.suppressClick > +new Date()) {
          return;
        }
        // onClick также вызывается из виджетбара при клике по иконке виджета, поэтому для невидимых блокируем выделениеъ
        if (this.model.get('hidden')) return;

        if (this._justResized) {
          this._justResized = false;
          return;
        }

        if (!event) {
          event = {
            shiftKey: false,
          };
        }

        // При любом клике на блоке в режиме вращения - ничего не делаем
        if (this.frame && this.frame.rotationState) {
          return;
        }

        // если клинкнули по виджету а он уже выделен (и при этом кликали без шифта, и при этом у нас не множественное выделение)
        // тогда ничего не делаем и выходим (также учитывается клик по иконке виджета в виджетбаре)
        if (this.selected && !event.shiftKey && this.workspace.getSelectedBlocks().length == 1) {
          if (this.child_workspace) {
            // для виджетов с вложенным в них виджетами особоая обработка.
            // такой родительски виджет может быть уже выделен,
            // а в внутриблоковом воркспейса выделен вложенный виджет
            // и для этого вложенного виджеты показаны контролы.
            // в такой ситауции мы хотим, чтобы показались контролы
            // родительского. местная логика не предназначена для реселетка уже
            // выделенного виджета и репоказа контролов, поэтому
            // написано следующее исключение.
            // говорим менеджеру контролов, что род. виджет,
            // якобы, снова выделен и менеджер покажет для него контролы.

            // чтобы вложенные виджеты подсказки деселектнулись.
            this.child_workspace.trigger('deselect');

            this.workspace.controls.onSelect([this]);
          }

          return;
        }

        // сначала спрашиваем менеджера контролов
        // менеджер опросит все открытые контролы и если кто-то из них скажет что он не может закрыться
        // тогда выходим и не отрабатываем быльше onClick
        // (например когда нам надо просто закрыть какие-то панельки)
        if (!disableControlsCheck && this.workspace.controls && !this.workspace.controls.canControlsBeClosed()) return;

        // Кликнули с шифтом на выделенном виджете
        if (event.shiftKey && this.selected && !_.isEmpty(this.workspace.getSelectedBlocks())) {
          this.cutFromSelection();
          return; // мы сделали действие: поэтому не смотрим дальше
        }

        if (!event.shiftKey) {
          this.workspace.trigger('deselect', this);
        }

        // Если на воркспейсе остались выделенные блоки (как при shift-клике на другом блоке), то блок добавляется к выделению.
        // Не показывать его собственную рамку.
        // (При обычном клике сначала на один блок, потом на другой до вызова this.select на воркспейсе триггерится deselect,
        // и к этому моменту выделенных блоков не остаётся)
        this.select(false, { noFrame: this.workspace.getSelectedBlocks().length > 0 || this.model.get('pack_id') });

        if (!_.isEmpty(this.workspace.getSelectedBlocks())) {
          // добавляем/удаляем группы в выделении
          var redefinedSelection = this.workspace.redefineSelectionWithPacks(this.workspace.getSelectedBlocks());
          this.workspace.trigger('select', redefinedSelection.selected, redefinedSelection.deselected);
        }
      } else {
        if (this._justResized) {
          this._justResized = false;
          return;
        }

        if (window.suppressClick && window.suppressClick > +new Date()) {
          return;
        }

        // сначала спрашиваем менеджера контролов
        // менеджер опросит все открытые контролы и если кто-то из них скажет что он не может закрыться
        // тогда выходим и не отрабатываем быльше onClick
        // (например когда нам надо просто закрыть какие-то панельки)
        if (
          !disableControlsCheck &&
          this.workspace.page_workspace.controls &&
          !this.workspace.page_workspace.controls.canControlsBeClosed()
        ) {
          return;
        }

        this.workspace.trigger('deselect', this);

        this.select();

        this.workspace.trigger('select');
      }
    },

    // снимает выделение с блока при этом оставляет выделение на других блоках если есть
    // используется в виджетбаре, чтобы сбрасывать выделение с виджетов при их скрытии
    // еще используется в функции onClick (выше)
    // и еще где-то наверное используется
    cutFromSelection: function() {
      // если у нас просто блок не привязаный к виджетам (типа фреймы шагов анимаций)
      if (this.isStandalone) return;

      if (!this.selected) return;

      if (!this.workspace) return; // хотспот с контентом странно клонируется через alt+drag.

      this.deselect();

      if (this.workspace.getSelectedBlocks().length == 0) {
        this.workspace.controls.hide();
        this.workspace.trigger('deselect');
      } else if (this.workspace.redefineSelectionWithPacks) {
        // добавляем/удаляем группы в выделении
        var redefinedSelection = this.workspace.redefineSelectionWithPacks(this.workspace.getSelectedBlocks(), this);

        if (!_.isEmpty(redefinedSelection.selected))
          this.workspace.trigger('select', redefinedSelection.selected, redefinedSelection.deselected);

        if (_.isEmpty(redefinedSelection.selected)) this.workspace.trigger('deselect');
      }
    },

    /**
     * Выделяет блок
     */
    select: function(fromWidgetBar, options) {
      options = options || {};
      if (!this.isSelectable()) return;

      if (!this.has_parent_block) {
        // Если виджет не вложенный, отрисуем fixed-линию.
        // Явно передадим флаг selected, потому что this.selected может быть ещё не выставлен к этому моменту
        this.recalcFixedLine(true);
      }
      this.setupAnimationTriggers(fromWidgetBar);
      this.listenTo(this.workspace, 'paste-animation', this.resetAnimationTriggers);

      if (this.selected) return;

      if (!options.noFrame) {
        this.frame && this.frame.show(fromWidgetBar);
      }

      this.selected = true;

      // для copy paste событий кроссбраузерно.
      // с дебонсом потому что это весьма долгая операция и при выборе большого числа виджетов она выстреливает на каждом
      // что очень долго и бессмысленно
      this.workspace.restoreFocusForCopyPaste.__debounced();

      this.trigger('select');
      // внутри хотспота усеченый воркспейс. Проверим есть в нем метод или нет
      this.workspace.checkBundleDragAbility && this.workspace.checkBundleDragAbility();
    },

    addToSelection: function() {
      this.selected = true;
      this.frame && this.frame.show();
    },

    /**
     * Вызывается когда workspace сказал deselect
     */
    deselect: function(block) {
      if (block == this) return;

      // оптимизация по скорости, когда много виджетов деселект идет всем и нижеследующий код весьма тормозит на большом кол-ве
      if (!this.selected) return;

      this.frame && this.frame.hide();
      // this.panel && this.panel.hide();
      this.hidePanel();

      this.frame && this.frame.removeRotationState();

      this.selected = false;
      this.toggleInPackClass(false);

      this.removeFixedLine();

      this.teardownAnimationTriggers();
      this.stopListening(this.workspace, 'paste-animation', this.resetAnimationTriggers);

      this.child_workspace && this.child_workspace.trigger('deselect');

      this.trigger('deselect');
    },

    isSelectable: function() {
      return !this.outofbox;
    },

    isLocked: function() {
      return !!this.model.get('is_locked');
    },

    isFullWidth: function() {
      return !!this.model.get('is_full_width');
    },

    isFullHeight: function() {
      return !!this.model.get('is_full_height');
    },

    /**
     * Событие начала перетаскивания
     * Вызывается также и из workspace.js при перетаскивании группы (там просто эмулируется перетаскивание выделенных виджетов группы за любой из них)
     */
    onDragStart: function(event, drag) {
      // this.disableDragging = true;

      // флаг запрета перемещения мышью для всех виджетов, сейчас используется только при переходе текстового виджета в режим редактирования
      if (this.workspace.disableDragging) return;

      this.workspace.freezeDeltaX = undefined;

      // если у нас просто блок не привязаный к виджетам (типа фреймы шагов анимаций) то тогда добавляем только его в драг
      drag.blocks = this.isStandalone ? [this] : this.workspace.getSelectedBlocks();

      // флаг запрета перемещения мышью только этого виджета, сейчас используется в картиночном виджете в режме кропа, и в google maps в режиме редактирования
      if (
        _.any(drag.blocks, function(block) {
          return block.disableDragging;
        })
      )
        return;

      // если блок не вгруппе тогда массив из самого себя
      // иначе массив из всех блоков в группе
      // это для того чтобы когда таскаем виджет в группе, но при этом группа не выделена (а соответственно и виджет тоже)
      // чтобы таскались все виджеты в группе
      var currentBlockOrPack = this.model.get('pack_id')
        ? this.workspace.getPackBlocks(this.model.get('pack_id'))
        : [this];

      // добавляем спец клас к странице, чтобы все рамки выделение блоков и групп были полупрозрачными
      $('html').addClass('blocks-is-moving');

      if (_.isEmpty(drag.blocks)) {
        // если ничто не выделено, то будем двигать то, за что потянули
        // или сам блок если он не в группе, или все блоки в группе
        drag.blocks = currentBlockOrPack;
      } else {
        // Если виджет не выбран, но мы пытаемся двигать что-то, то надо проверить
        // не хотим ли мы потянуть виджеты, которые выбраны и находятся под ним
        if (!this.selected) {
          var scrollTop = this.workspace.$container.scrollTop();
          var offset = this.workspace.position;

          if (
            !_.any(drag.blocks, function(block) {
              return block.contains({
                x: drag.startX - offset.left,
                y: drag.startY - offset.top + scrollTop,
              });
            })
          ) {
            drag.blocks = currentBlockOrPack;
          }
        }
      }

      // В каждом блоке сохраняем его начальные визуальные данные, включая координаты повернутых вершин и габариты
      var hasSelectedBlocks = false;
      _.each(drag.blocks, function(b) {
        b.initialBoxData = b.getBoxData({ includeBoundingBox: true });
        b._maxShift = 0;
        hasSelectedBlocks = hasSelectedBlocks || !!b.selected;
      });

      // ели двигаем блоки которые не выделены (можем двигать и пачку таких если они в группе)
      // тогда надо для них нарисовани нижнюю или верхнюю точечную линию при зажатых V/F
      if (!hasSelectedBlocks) {
        this.workspace.redrawBottomShiftLine({ tp: 'move-unselected-start', blocks: drag.blocks });
      }

      if (RM.constructorRouter.gg.snap) {
        RM.constructorRouter.gg.handleMoveStart(
          _.filter(drag.blocks, function(b) {
            return !b.model.get('fixed_position');
          })
        );
      }

      drag.scrollTop = this.workspace.$container.scrollTop();

      this.isDragging = true;

      // Если bundle drag неактивен но bottomBlocksToMove создан, то очистим его, иначе в dragend произойдет сохранение всех виджетов как будто был bundle drag
      if (this.workspace.bottomBlocksToMove && !this.workspace.bottomBlocksToMove.active)
        delete this.workspace.bottomBlocksToMove;
    },

    /**
     * Событие перетаскивания
     * Вызывается также и из workspace.js при перетаскивании группы (там просто эмулируется перетаскивание выделенных виджетов группы за любой из них)
     */
    onDragMove: function(event, drag) {
      var blocks_to_move;
      // флаг запрета перемещения мышью только этого виджета, сейчас используется в картиночном виджете в режме кропа, и в google maps в режиме редактирования
      if (
        _.any(drag.blocks, function(block) {
          return block.disableDragging;
        })
      )
        return;

      // флаг запрета перемещения мышью для всех виджетов, сейчас используется только при переходе текстового виджета в режим редактирования
      if (this.workspace.disableDragging) return;

      // если у нас просто блок не привязаный к виджетам (типа фреймы шагов анимаций) тогда для него точно не делаем клонирование по алту
      if (!drag.isClone && !this.isStandalone) {
        drag.isClone = event.altKey;
        if (drag.isClone) {
          _.each(drag.blocks, function(block) {
            block.clone();
          });
        }
      }

      // Залоченные блоки двигать нельзя
      blocks_to_move = _.filter(drag.blocks, function(b) {
        return !b.isLocked();
      });
      var stY = drag.startY + drag.scrollTop,
        edY = event.pageY + this.workspace.$container.scrollTop();

      drag.deltaY = edY - stY;

      this.moveBlocks(this.workspace, blocks_to_move, drag, event);

      // если у нас просто блок не привязаный к виджетам (типа фреймы шагов анимаций) тогда для него не надо обновлять ни паки ни фреймы анимаций
      if (!this.isStandalone) {
        // вызываем перерисовку рамок групп поскольку
        // вызов этой функции из функции css заблокирован в случае перемещения блоков (иначе он вызывался б там длякаждого виджета который выделен при таскании)
        this.workspace.redrawPacksFrames({ tp: 'move' });
        this.workspace.redrawBottomShiftLine({ tp: 'move' });
      }

      Utils.autoWindowScroll(
        event,
        this.workspace.$container,
        _.bind(function() {
          this.onDragMove(event, drag);
        }, this)
      );
    },

    // Сдвиг нижележащих блоков бандл драгом
    moveBottomBlocks: function(workspace, relativeDelta, isClone, correction) {
      var bbm = workspace.bottomBlocksToMove; // шорткатим
      // если зажата клавиша V
      // тогда смотрим список нижележащих виджетов которые надо сдвинуть вместе с текущими
      // если этого списка пока нет, сформируем его
      if (bbm) {
        var shift = relativeDelta + correction.y - (bbm.deltaYcorrection || 0);

        if (isClone) shift = Math.max(shift, 0);

        if (bbm.active) {
          // сдвигаем нижележащие виджеты
          // но только по вертикали
          _.each(bbm.list, function(block, ind) {
            var initBox = bbm.initial[ind],
              newY = shift + initBox.y;

            block.css({
              doNotRedrawPacksFrames: true,
              doNotRedrawBottomShiftLine: true,
              left: initBox.x,
              top: newY,
            });

            block.trigger('move', {
              left: initBox.x,
              top: newY,
              width: initBox.w,
              height: initBox.h,
            });
          });

          if (bbm.list && bbm.list.length) {
            workspace.page.setCurrentViewportParam('type', 'scroll');
            workspace.page.setCurrentViewportParam('height', Math.max(bbm.initPageHeight + shift, bbm.minPageHeight));
          }
        }

        bbm.prevDeltaY = relativeDelta;
      }
      workspace.checkBundleDragAbility();
    },

    collectBottomBloсks: function(workspace, blocks, event) {
      var bbm = workspace.bottomBlocksToMove; // шорткатим
      // если зажата клавиша V
      // тогда смотрим список нижележащих виджетов которые надо сдвинуть вместе с текущими
      // если этого списка пока нет, сформируем его
      if (bbm && ((bbm.kbActivate && event.type == 'keydown') || event.type == 'drag')) {
        if (!bbm.list) {
          _.extend(bbm, workspace.calcBottomBlocksToMove(blocks, bbm));

          // важная вещь, новый список виджетов для сдвига снизу мерджим с предыдущим списком виджетов
          // это отдельный массив tosave, суть в том, что при таскании мы можем произвольно зажимать/отжимать v
          // не отпуская кнопку мыши и у нас будет менять список нижних виджетов которые нужно сдвинуть
          // т.е. на всем протяжении таскания мы должны хранить информацию обо всех виджетах
          bbm.tosave = _.uniq((bbm.tosave || []).concat(bbm.list));

          // сохраняем делту сдвига на момент когда нажали V
          // ведь мы можем сначала тащить виджеты а потом в процессе зажать V
          // и дельту надо корректировать
          bbm.deltaYcorrection = bbm.prevDeltaY || 0;
        }
      }
    },

    // ВНИМАНИЕ! в этой функции нельзя использовать this, поскольку
    // 1. он там не нужен по логике нигде
    // 2. эта функция вызывается напрямую из прототипа в workspace.js
    moveBlocks: function(workspace, blocks, delta, event) {
      var leftest = 999999,
        topmost = 999999,
        rightest = -999999,
        bottommost = -999999;

      var isKeyboardEvent = event && event.type == 'keydown';
      var commonShiftDirection = null;

      // Есть ли вы выделении растянутые блоки?
      var hasFullWidthBlocks = _.any(blocks, function(b) {
          return b.isFullWidth();
        }),
        hasConstraintHorzMove = _.any(blocks, function(b) {
          return !!b.constraintHorzMove;
        }),
        hasFullHeightBlocks = _.any(blocks, function(b) {
          return b.isFullHeight();
        }),
        hasConstraintVertMove = _.any(blocks, function(b) {
          return !!b.constraintVertMove;
        });
      // шаги анимаций тоже вызывают moveBlocks из прототипа, но воркспейс не передают, у них его и нет, да он им и не нужен
      // тут он нам нужен для того, чтобы найти нижележащие виджеты под текущими
      // эта логика разбита на две части, первая  часть (эта) вызывается до сдвига основных виджетов (чтобьы правильно понять какие из нижелажащих надо захватить)
      // вторая часть логики идет после сдвига основных виджетов, потому что там используются данные корректировки по снепу рассчитанные при сдвиге основных виджетов
      if (workspace) {
        Block.prototype.collectBottomBloсks(workspace, blocks, event);
      }

      var canMoveAllBlocksOverSpace =
          workspace && workspace.spacesController && workspace.spacesController.checkAllBlocksNear(blocks),
        needGhost = false,
        ghostDelta = 0;

      _.each(blocks, function(block) {
        var deltaX = workspace && workspace.freezeDeltaX !== undefined ? workspace.freezeDeltaX : delta.deltaX,
          deltaY = delta.deltaY - (block.resetDelta || 0);
        var initBox = block.initialBoxData || block.getBoxData({ includeBoundingBox: true });

        // Bundle drag за треугольник - двигаем только по вертикали

        if (workspace && workspace._inTriangleBundleDrag) {
          deltaX = 0;
        }

        var position = block.model.get('fixed_position');

        if (workspace && workspace.spacesController && !position) {
          var restriction = workspace.spacesController.getRestriction(
              block.model,
              canMoveAllBlocksOverSpace ? blocks : []
            ),
            newTop = deltaY + initBox.bb_y,
            newBottom = deltaY + initBox.bb_y + initBox.bb_h,
            sourceDeltaY = deltaY;
          if (newTop < restriction.top) {
            deltaY = Math.floor(restriction.top - initBox.bb_y);
            if (
              workspace.freezeDeltaX === undefined &&
              canMoveAllBlocksOverSpace &&
              workspace.spacesController.needFreeze(block)
            ) {
              workspace.freezeDeltaX = deltaX;
            }
          } else if (newBottom > restriction.bottom) {
            deltaY = Math.floor(restriction.bottom - (initBox.bb_y + initBox.bb_h));
            if (
              workspace.freezeDeltaX === undefined &&
              canMoveAllBlocksOverSpace &&
              workspace.spacesController.needFreeze(block)
            ) {
              workspace.freezeDeltaX = deltaX;
            }
          } else {
            workspace.freezeDeltaX = undefined;
          }

          if (Math.abs(sourceDeltaY - deltaY) && event.type === 'drag' && canMoveAllBlocksOverSpace) {
            needGhost = true;
            ghostDelta = sourceDeltaY - deltaY;
          }

          /*if (Math.abs(sourceDeltaY - deltaY) > 100 && canMoveAllBlocksOverSpace) {
          if (workspace.spacesController.moveBlockToGroup(block, Math.sign(sourceDeltaY)) !== null) {
            block.redrawPosition();
            block.resetDelta = delta.deltaY;
            initBox = block.initialBoxData = block.getBoxData({ includeBoundingBox: true });
            deltaY = 0;
          }
        }*/
        }

        if (position) {
          // Для сдвига фиксированных блоков нужно
          // учитывать смещение мыши только в пределах вьюпорта, БЕЗ учета скрола,
          // т.к. position: fixed позиционирует виджет относительно вьюпорта, а не страницы.
          // Если же учитывать скрол все будет хорошо только до тех, пока не произойдет utils.autoWindowScroll
          // При автоскроле фиксед продолжит двигаться
          // и уедет за пределы вьюпорта и перестанет быть виден.
          if (event && event.type !== 'keydown') {
            // При сдвиге стрелкой переданный в этом случае deltaY отработает нормально,
            // т.к. при сдвиге стрелками нет автоскрола.
            // pageY — ок для определения позиции относительно вьюпорта,
            // т.к. у документа нету скрола, он у внутреннго элемента.
            deltaY = event.pageY - delta.startY;
          }

          // Если виджет фиксированный и его координаты заданы в bottom или right, то смещение нужно брать с коэф -1
          if (position.indexOf('e') > -1) deltaX = -deltaX;
          if (position.indexOf('s') > -1) deltaY = -deltaY;
        }

        position = {
          x: deltaX + initBox.x,
          y: deltaY + initBox.y,
          w: initBox.w, // важно чтобы они передались дальше через _position
          h: initBox.h,
          // x: deltaX + block.model.get('x'),
          // y: deltaY + block.model.get('y')
        };

        // Вычисляем координаты повернутого блока на основе начальных данных и дельты
        // getBoxData здесь делать нельзя, т.к. он на каждом шаге изменяется,
        // и когда начнутся коррекции снэпа эти параметры будут расходиться с оригинальным position
        var rotated = {
          x: deltaX + initBox.bb_x,
          y: deltaY + initBox.bb_y,
        };

        // таскать с зажатым шифтом
        if (event && event.shiftKey) {
          var x = Math.abs(deltaX);
          var y = Math.abs(deltaY);

          // если мы еще пока не знаем в какую сторону нам двигать с шифтом (вертикаль, горизонталь или диагонали)
          // тогда пытаемся это определить
          if (!block._shiftDirection && !isKeyboardEvent) {
            if (x >= 4) block._shiftDirection = 'horz';
            if (y >= 4) block._shiftDirection = 'vert';
            if ((x >= 4 || y >= 4) && deltaX / deltaY < 2 && deltaX / deltaY > 0.5) block._shiftDirection = 'diag1';
            if ((x >= 4 || y >= 4) && deltaX / deltaY > -2 && deltaX / deltaY < -0.5) block._shiftDirection = 'diag2';
            block._maxShift = Math.max(x, y);
          }

          // Если в выделении есть растянутые блоки, то с шифтом всегда двигаем по вертикали
          if (hasFullWidthBlocks || hasConstraintHorzMove) {
            block._shiftDirection = 'vert';
          }

          if (hasFullHeightBlocks || hasConstraintVertMove) {
            block._shiftDirection = 'horz';
          }

          if (block._shiftDirection && !isKeyboardEvent) {
            var shiftDelta = deltaX;

            // Переключаем режим с горизонтали на вертикаль если смещение по вертикали стало больше чем максимально сместили по горизонтали за время движения
            if (block._shiftDirection == 'horz' || block._shiftDirection == 'vert') {
              if (Math.abs(deltaX) > block._maxShift) {
                block._maxShift = Math.abs(deltaX);
                block._shiftDirection = 'horz';
              }

              if (Math.abs(deltaY) > block._maxShift) {
                block._maxShift = Math.abs(deltaY);
                block._shiftDirection = 'vert';
              }
            }
            if (!workspace.spacesController) {
              if (block._shiftDirection == 'horz') position.y = initBox.y;
              if (block._shiftDirection == 'vert') position.x = initBox.x;

              if (block._shiftDirection == 'diag1') {
                position.x = initBox.x + shiftDelta;
                position.y = initBox.y + shiftDelta;
              }

              if (block._shiftDirection == 'diag2') {
                position.x = initBox.x + shiftDelta;
                position.y = initBox.y - shiftDelta;
              }
            }

            commonShiftDirection = block._shiftDirection;
          }
        }

        leftest = Math.min(leftest, rotated.x);
        topmost = Math.min(topmost, rotated.y);
        rightest = Math.max(rightest, rotated.x + initBox.bb_w);
        bottommost = Math.max(bottommost, rotated.y + initBox.bb_h);

        // Если в выделении есть растянутые блоки, то всегда двигаем по вертикали
        if (hasFullWidthBlocks || hasConstraintHorzMove) {
          position.x = initBox.x;
        }

        if (hasFullHeightBlocks || hasConstraintVertMove) {
          position.y = initBox.y;
        }

        block._position = BlockFrameClass.ceilObject(position);
      });

      if (needGhost && !event.altKey) {
        workspace &&
          workspace.spacesController.updateGhost(
            blocks,
            ghostDelta,
            delta.deltaX - workspace.freezeDeltaX,
            $('> .workspace', workspace.$el),
            event
          );
      } else {
        workspace && workspace.spacesController && workspace.spacesController.removeGhost();
      }

      var correction = {
        x: 0,
        y: 0,
      };

      if (
        RM.constructorRouter.gg.snap &&
        !_.all(blocks, function(b) {
          return b.model.get('fixed_position');
        })
      ) {
        correction = RM.constructorRouter.gg.handleSnapMove(
          {
            x: leftest,
            y: topmost,
          },
          rightest - leftest,
          bottommost - topmost
        );

        if (commonShiftDirection == 'horz') correction.y = 0;
        if (commonShiftDirection == 'vert') correction.x = 0;

        // Отменяем горизонтальный снеппинг для всех блоков, если в выделении есть растянутые блоки
        if (hasFullWidthBlocks || hasConstraintHorzMove) {
          correction.x = 0;
        }

        if (hasFullHeightBlocks || hasConstraintVertMove) {
          correction.y = 0;
        }
      }

      var cloneParams = {};
      if (delta.isClone) cloneParams.clone = true;

      _.each(blocks, function(block) {
        block.css(
          _.extend(
            {
              doNotRedrawPacksFrames: true,
              doNotRedrawBottomShiftLine: true,
              left: block._position.x + correction.x,
              top: block._position.y + correction.y,
            },
            cloneParams
          )
        );

        block.recalcFixedLine();

        block.recalcStickedLine();

        block.trigger('move', {
          left: block._position.x + correction.x,
          top: block._position.y + correction.y,
          width: block._position.w,
          height: block._position.h,
        });
      });

      // вторая часть логики сдвига, идет после блока где сдвигаются основные виджеты
      // описание чуть выше, в первой части
      if (workspace) {
        var relativeDelta = delta.isCloneByKeys ? delta.deltaYOrig : delta.deltaY;
        Block.prototype.moveBottomBlocks(workspace, relativeDelta, delta.isClone, correction);
      }
    },

    /**
     * Событие окончания перетаскивания
     * Вызывается также и из workspace.js при перетаскивании группы (там просто эмулируется перетаскивание выделенных виджетов группы за любой из них)
     */
    onDragEnd: function(event, drag) {
      Utils.autoWindowScrollClear();

      // флаг запрета перемещения мышью только этого виджета, сейчас используется в картиночном виджете в режме кропа, и в google maps в режиме редактирования
      if (
        _.any(drag.blocks, function(block) {
          return block.disableDragging;
        })
      )
        return;

      // флаг запрета перемещения мышью для всех виджетов, сейчас используется только при переходе текстового виджета в режим редактирования
      if (this.workspace.disableDragging) return;

      // Сбрасываем у всех таскаемых блоков, чтобы не влияло на движение стрелками
      var hasSelectedBlocks = false;
      _.each(drag.blocks, function(b) {
        b.initialBoxData = null;
        b.resetDelta = null;
        b._shiftDirection = null;
        hasSelectedBlocks = hasSelectedBlocks || !!b.selected;
      });

      // ели двигаем блоки которые не выделены (можем двигать и пачку таких если они в группе)
      // тогда надо для них нарисовани нижнюю или верхнюю точечную линию при зажатых V/F
      if (!hasSelectedBlocks) {
        this.workspace.redrawBottomShiftLine({ tp: 'move-unselected-end' });
      }

      if (drag.isClone) {
        // Клонируем только незалоченные блоки
        this.workspace.cloneBlocks(
          _.filter(drag.blocks, function(b) {
            return !b.isLocked();
          })
        );
      } else {
        // это для шагов анимаций
        if (this.isStandalone) {
          _.first(drag.blocks).saveBox();
        } else {
          this.workspace.spacesController && this.workspace.spacesController.moveBlocksToHighlighted(drag.blocks);
          this.workspace.spacesController && this.workspace.spacesController.removeGhost();

          var bbm = this.workspace.bottomBlocksToMove; // шорткатим
          var spacesAffected =
            (this.workspace.spacesController && this.workspace.spacesController.getBlocksToSave()) || [];
          var new_data = _.map(
            [].concat(drag.blocks, (bbm && bbm.tosave) || [], spacesAffected),
            function(block) {
              return _.extend(
                {
                  _id: block.id,
                },
                block.getBoxData({ includeFreeze: drag.deltaX - this.workspace.freezeDeltaX })
              );
            }.bind(this)
          );

          // если сдвигали с v/f у нас менялас ьи высота страницы, сохраним изменения если они есть
          if (
            bbm &&
            bbm.initPageHeight != this.workspace.page.getCurrentViewportParam('height') &&
            bbm.tosave &&
            bbm.tosave.length
          ) {
            this.workspace.page.save();
          }

          // показываем интелектуальную подсказку если:
          // страница высотой больше 2000
          // сейчас не зажата v/f
          // эта подсказка показывается впервые
          // сдвигаем виджеты вниз, причем больше по вертикали, чем по горизонтали
          // сдвигаем много виджетов (больше 5)
          if (
            this.workspace.page.getCurrentViewportParam('height') > 2000 &&
            drag.deltaY > 0 &&
            drag.deltaY > Math.abs(drag.deltaX) &&
            !(bbm && bbm.active) &&
            drag.blocks.length > 5
          ) {
            RM.constructorRouter.helpPanel.showTip({
              name: 'bottom-blocks-to-move',
              showOnce: true,
            });
          }

          this.workspace.save_group(new_data);
        }
      }

      // убираем спец клас со страницы, чтобы все рамки выделение блоков и групп снова стали обычными, а не полупрозрачными
      $('html').removeClass('blocks-is-moving');

      if (RM.constructorRouter.gg.snap) {
        RM.constructorRouter.gg.handleMoveEnd();
      }

      if (!drag.isClone) this.triggerRedraw();
      this.isDragging = false;

      if (this.model.get('type') === 'hotspot') {
        // после таскания за подсказку, а т.е.
        // за какой-то вложенный виджет, блочим клик по нему.
        $(event.target).one('click', function(e) {
          e.stopImmediatePropagation();
        });
      }

      this.workspace.clearBottomBlocksToMove();
      this.workspace.checkBundleDragAbility();
    },

    triggerRedraw: function() {
      // не запскаем пересчите скриншотов песли работаетм с блоками без виджетов (типа шагов анимаций)
      if (this.isStandalone) return;

      this.workspace.trigger('redraw', { is_global: this.model.get('is_global') });
    },

    // Для фиксированных виджетов пересчитывает визуальную линию привязки к точке окна
    recalcFixedLine: function(selected) {
      var position = this.model.get('fixed_position') || this.restoreFixed;
      var packId = this.model.get('pack_id');
      var isInPack = packId && this.workspace.packs && this.workspace.packs[packId];
      var isSelected = this.selected || selected;
      if (position && !isInPack && isSelected) {
        this.updateFixedLine(position);
      } else {
        this.removeFixedLine();
      }
    },

    updateFixedLine: function(position) {
      if (!this.$fixed_svg) {
        this.$fixed_svg = $('<svg class="fixed-line"><line /></svg>');
        this.workspace.$('.workspace').append(this.$fixed_svg);
      }
      var frameOffset = this.frame.$el.offset();
      var boxData = this.getBoxData();
      var line = this.$fixed_svg.find('line').get(0);
      var x1,
        x2,
        y1,
        y2,
        workspaceX,
        workspaceY,
        isFullHeight = this.isFullHeight(),
        cw = this.workspace.$container.width(),
        ch = this.workspace.$container.height();

      workspaceX = workspaceY = 0;

      if (this.model.getViewport() != 'default') {
        workspaceX = this.workspace.position.left;
        workspaceY = this.workspace.position.top;
      }

      x1 = workspaceX;
      y1 = workspaceY;

      x2 = frameOffset.left;
      y2 = frameOffset.top;

      if (position.indexOf('s') > -1) {
        y1 = ch - workspaceY;
        y2 += boxData.h;
      }
      if (position.indexOf('e') > -1) {
        x1 = cw - workspaceX;
        x2 += boxData.w;
      }

      if (['n', 'c', 's'].indexOf(position) > -1) {
        x1 = cw / 2;
        x2 += boxData.w / 2;
      }
      if (['w', 'c', 'e'].indexOf(position) > -1) {
        y1 = ch / 2;
        y2 += boxData.h / 2;
      }

      if (isFullHeight) {
        y2 = y1;
      }

      line.setAttributeNS(null, 'x1', x1);
      line.setAttributeNS(null, 'x2', x2);
      line.setAttributeNS(null, 'y1', y1);
      line.setAttributeNS(null, 'y2', y2);
    },

    removeFixedLine: function() {
      this.$fixed_svg && this.$fixed_svg.remove();
      delete this.$fixed_svg;
    },

    // Для стики виджетов пересчитывает визуальную линию привязки к точке окна
    recalcStickedLine: function() {
      var sticked = this.model.get('sticked'),
        css = {
          left: '', // важно задать '' для обоих изначально поскольку у нас может быть переключение стики и важно сбросить прежнюю привязку
          right: '',
          top: '',
          bottom: '',
          width: '',
          height: '',
        };

      if (!sticked) {
        if (this.$stickedLine) {
          this.$stickedLine.remove();
          delete this.$stickedLine;
        }
      } else {
        var is_horizontal = sticked == 'left' || sticked == 'right';

        if (!this.$stickedLine) {
          this.$stickedLine = $('<div>')
            .addClass('sticked-line skip-rotate')
            .appendTo(this.$el);
        }

        this.$stickedLine.removeClass('line-horizontal line-vertical');
        this.$stickedLine.addClass(is_horizontal ? 'line-horizontal' : 'line-vertical');

        if (is_horizontal) {
          css.width = this.calcStickedMargin(this.latestPosSizeAngle.left, this.latestPosSizeAngle.width);
          css[sticked] = -css.width; // прибиваем либо к левому либо к правому краю
        } else {
          css.height = this.calcStickedMargin(this.latestPosSizeAngle.top, this.latestPosSizeAngle.height);
          css[sticked] = -css.height; // прибиваем либо к верхнему либо к нижнему краю
        }

        this.$stickedLine.css(css);
      }
    },

    clone: function() {
      if (!this.isLocked()) {
        this.$cloneEl = $($.clone(this.$el.get(0)));
        this.$cloneEl.data('id', this.$cloneEl.data('id') + '-clone');
        this.$cloneEl.insertAfter(this.$el);
      }
      this.deselect();
    },

    /**
     * Мигает рамкой блока, не выбирая блок
     */
    blink: function() {
      var $el = this.$el;
      $el.addClass('frame-blink');

      Utils.waitForTransitionEnd($el, 2500, 'opacity', function() {
        $el.removeClass('frame-blink');
      });
    },

    // Возвращает название виджета, например в stack-panel.vue
    getStandardName: function() {
      var type = this.model.get('type'),
        nameMap = {
          ellipse: 'oval',
        };

      if (type === 'text') {
        return $(this.model.get('text')).text();
      }

      if (type === 'shape') {
        var tp = this.model.get('tp');
        return Utils.capitalize(nameMap[tp] || tp);
      }

      if (type === Constants.ecommerceCartBlockName || type === Constants.addToCartBlockName) {
        return this.name;
      }

      return Utils.capitalize(type);
    },

    /**
     * Подсвечивает или убирает подсветку с рамки блока
     * @param {Boolean} willShow Показать или убрать подсветку
     * @param {String} cssClass Сss класс подсветки
     */
    highlight: function(willShow, cssClass) {
      var targetElement = this.animationMode
        ? (this.workspace.animationSteps.currentStep || this.workspace.animationSteps.initialStep).$el
        : this.$el;
      cssClass && targetElement && targetElement.toggleClass(cssClass, Boolean(willShow));
    },

    /**
     * Возвращает все триггер-блоки
     * @returns {Object}
     */
    getTriggerBlocks: function() {
      return _.reduce(
        this.getAnimationTriggers(),
        function(blocks, triggerId) {
          var block = this.workspace.findBlock(triggerId);
          if (block) {
            blocks.push(block);
          }
          return blocks;
        }.bind(this),
        []
      );
    },

    /**
     * Возвращает id триггера или триггеров, приведённый к массиву
     * @return {Array}
     */
    getAnimationTriggers: function() {
      var animation = this.model.get('animation');
      return AnimationUtils.normalizeAnimationTriggers(animation && animation.trigger);
    },

    /**
     * Записывает триггер в модель, приводя к массиву, если нужно
     * @param {Array} triggerIds
     */
    setAnimationTriggers: function(triggerIds) {
      var animation = this.model.get('animation');
      if (animation) {
        var animationUpdate = _.extend({}, animation, {
          trigger: triggerIds && !_.isArray(triggerIds) ? [triggerIds] : triggerIds || [],
        });
        this.model.set('animation', animationUpdate);
      }
    },

    removeAnimationTriggers: function() {
      _.each(this.getAnimationTriggers(), this.removeAnimationTrigger.bind(this));
    },

    removeAnimationTrigger: function(triggerId) {
      var triggerIds = this.getAnimationTriggers();
      if (triggerIds.indexOf(triggerId) !== -1) {
        this.setAnimationTriggers(_.without(triggerIds, triggerId));
        this.model.trigger('animation:trigger:remove', triggerId);
      }
    },

    /**
     * Определяет, что среди id триггеров есть id несуществующего блока (мы не запрещаем удалять триггер-блок)
     * @returns {boolean}
     */
    isTriggerBlockMissing: function() {
      return Boolean(
        _.find(
          this.getAnimationTriggers(),
          function(triggerId) {
            return !this.workspace.findBlock(triggerId);
          }.bind(this)
        )
      );
    },

    /**
     * Определяет, нарисован ли уже тултип для этой группы
     * @param {Number} packId
     * @returns {Boolean}
     */
    isPackAlreadySetUp: function(packId) {
      // В заданной группе поищем блок, у которого уже есть обвязка
      return Boolean(
        packId &&
          this.workspace.getPackBlocks &&
          _.find(this.workspace.getPackBlocks(packId), function(block) {
            return block.ownTooltip;
          })
      );
    },

    /**
     * Возвращает анимируемые блоки. Если блок в группе, возвращает только один, первый попавшийся, блок этой группы
     * @return {Array}
     */
    getAnimatedBlocks: function() {
      var blocksHash = _.reduce(
        this.workspace.blocks,
        function(blocksAndPacks, block) {
          var animation = block.model.get('animation');
          if (animation && animation.type !== 'none' && block.getAnimationTriggers().indexOf(this.id) !== -1) {
            var key = this.model.get('pack_id') || block.id;
            blocksAndPacks[key] = blocksAndPacks[key] || block;
          }
          return blocksAndPacks;
        }.bind(this),
        {}
      );
      return _.values(blocksHash);
    },

    getTooltipTarget: function(singleWidgetSelected) {
      var packId = this.model.get('pack_id');
      var $pack = packId && this.workspace.$('#pack_' + packId);
      // Если есть группа, будем рисовать тултип над ней, а не над всем блоком
      // В режиме анимаций рисовать тултип к первому шагу
      return this.animationMode
        ? this.workspace.animationSteps.initialStep.$el
        : $pack && $pack.length && !singleWidgetSelected
        ? $pack.find('.content')
        : this.$content;
    },

    getTooltipContainer: function() {
      // Контейнер для html-элементов тултипа. Указывается для того, чтобы тултипы не помещались в конец body и не оказывались поверх всплывающих окон
      return this.workspace.$('.workspace');
    },

    setupAnimationTriggers: function(singleWidgetSelected) {
      var animation = this.model.get('animation') || {};
      var animatedBlocks = this.getAnimatedBlocks();
      var triggerBlocks = AnimationUtils.isExternalTriggerAllowed(animation.type) ? this.getTriggerBlocks() : [];
      _.each(animatedBlocks, this.setupAnimationTrigger.bind(this, true));
      _.each(triggerBlocks, this.setupAnimationTrigger.bind(this, false));

      var hasAnimatedBlocks = animatedBlocks.length && (animatedBlocks.length > 1 || animatedBlocks[0].id !== this.id);
      if (
        (hasAnimatedBlocks || triggerBlocks.length) &&
        !this.isPackAlreadySetUp(this.model.get('pack_id')) &&
        !this.ownTooltip
      ) {
        this.ownTooltip = this.getTooltipTarget(singleWidgetSelected).RMAltText({
          text:
            animatedBlocks.length && triggerBlocks.length
              ? 'Animated / Trigger'
              : animatedBlocks.length
              ? 'Trigger'
              : 'Animated',
          offset: this.TOOLTIP_OFFSET,
          manual: true,
          cssClass: 'animation-trigger-tooltip animation-trigger-own-tooltip',
          container: this.getTooltipContainer(),
          keepInBounds: true,
        });
        this.ownTooltip.show();
        this.bindAnimationTriggerHandlers();
      }
    },

    teardownAnimationTriggers: function() {
      // Получим id связанных блоков, для которых фактически есть обвязка (а не тех блоков, id которые есть в модели, потому что в модели один из id может быть удалён)
      var linkedBlockIds = _.keys(this.triggerAssets);
      _.each(linkedBlockIds, this.teardownAnimationTrigger.bind(this));
      this.ownTooltip && this.ownTooltip.destroy();
      delete this.ownTooltip;
      this.unbindAnimationTriggerHandlers();
    },

    /**
     * Показывает тултип над связанным блоком (триггером или анимируемым)
     * @param {Boolean} isBlockAnimated Является ли связанный блок триггером или анимируемым
     * @param {Object} linkedBlock
     */
    setupAnimationTrigger: function(isBlockAnimated, linkedBlock) {
      if (linkedBlock) {
        var packId = this.model.get('pack_id');
        var linkedBlockId = linkedBlock.id;
        var linkedPackId = linkedBlock.model.get('pack_id');
        var $linkedPack = linkedPackId && this.workspace.$('#pack_' + linkedPackId);
        this.triggerAssets[linkedBlockId] = this.triggerAssets[linkedBlockId] || {};

        if (
          !this.triggerAssets[linkedBlockId].tooltip &&
          linkedBlockId !== this.id &&
          (!packId || linkedPackId !== packId) &&
          !linkedBlock.ownTooltip
        ) {
          this.triggerAssets[linkedBlockId].tooltip = ($linkedPack || linkedBlock.$content).RMAltText({
            text: $linkedPack || isBlockAnimated ? 'Animated' : 'Trigger',
            offset: this.TOOLTIP_OFFSET,
            manual: true,
            cssClass: 'animation-trigger-tooltip',
            container: this.getTooltipContainer(),
            keepInBounds: true,
          });
          this.triggerAssets[linkedBlockId].tooltip.show();
          $linkedPack
            ? $linkedPack.addClass(AnimationUtils.ACTIVE_TRIGGER_CLASS)
            : linkedBlock.highlight(true, AnimationUtils.ACTIVE_TRIGGER_CLASS);
          this.triggerAssets[linkedBlockId].updateLinkedTooltipBound = this.updateLinkedTooltip.bind(
            this,
            linkedBlockId
          );
          // Нельзя следить за движением группы, но достаточно следить за одним из её блоков
          this.listenTo(linkedBlock, 'move resize', this.triggerAssets[linkedBlockId].updateLinkedTooltipBound);
          this.listenTo(
            linkedBlock && linkedBlock.model,
            'change:x change:y change:w change:h',
            this.triggerAssets[linkedBlockId].updateLinkedTooltipBound
          );
        }
      }
    },

    /**
     * Прячет тултипы над связанным блоком (триггером или анимируемым)
     * @param {String} [linkedBlockId]
     */
    teardownAnimationTrigger: function(linkedBlockId) {
      var linkedBlock = this.workspace.findBlock(linkedBlockId);

      if (linkedBlock) {
        linkedBlock.highlight(false, AnimationUtils.ACTIVE_TRIGGER_CLASS);
      }

      // Убрать подсветку с триггер-блоков, если она осталась
      // (иногда id предыдущего триггера и его блок в этот момент уже неизвестен, как при копипасте анимации без триггера поверх текущей, с триггером)
      this.workspace.$('.' + AnimationUtils.ACTIVE_TRIGGER_CLASS).removeClass(AnimationUtils.ACTIVE_TRIGGER_CLASS);

      if (this.triggerAssets[linkedBlockId]) {
        this.triggerAssets[linkedBlockId].tooltip && this.triggerAssets[linkedBlockId].tooltip.destroy();

        this.stopListening(linkedBlock, 'move resize', this.triggerAssets[linkedBlockId].updateLinkedTooltipBound);
        this.stopListening(
          linkedBlock && linkedBlock.model,
          'change:x change:y change:w change:h',
          this.triggerAssets[linkedBlockId].updateLinkedTooltipBound
        );
        delete this.triggerAssets[linkedBlockId];
      }
    },

    /**
     * Подписывается на события анимируемого блока, при которых нужно перерисовывать тултип
     */
    bindAnimationTriggerHandlers: function() {
      if (!this.animationTriggerHandlersBound) {
        this.listenTo(this, 'move resize', this.updateOwnTooltip);
        this.listenTo(this, 'animationMode', this.resetAnimationTriggers);
        this.listenTo(this, 'animation:stepsPopup:open', this.onStepsPopupOpen);
        this.listenTo(this, 'animation:stepsPopup:closed', this.onStepsPopupClosed);

        // Подпишемся на изменение угла, а не на событие rotate, потому что событие rotate вызывается только при повороте за ручки.
        // При повороте из панели — не вызывается.
        // Подпишемся на изменение  x, y, w и h. Подписаться на move и resize — не всегда достаточно,
        // потому что модель может измениться через другие действия, например undo / redo
        this.listenTo(
          this.model,
          'change:angle change:x change:y change:w change:h change:sticked change:fixed_position change:is_full_width change:is_full_height',
          this.updateOwnTooltip
        );
        // Будем слушать и ресайз, на случай если один из блоков зафиксирован относительно экрана
        this.listenTo(this.workspace, 'workspace-resize', this.updateTooltips);
        // Будем слушать скролл, потому что это влияет на позиционирование тултипов
        $('#main').on('scroll', this.updateTooltips);
        this.animationTriggerHandlersBound = true;
      }
    },

    /**
     * Отписывается от событий анимированного блока
     */
    unbindAnimationTriggerHandlers: function() {
      this.stopListening(this, 'move resize', this.updateOwnTooltip);
      this.stopListening(this, 'animationMode', this.resetAnimationTriggers);
      this.stopListening(this, 'animation:stepsPopup:open', this.onStepsPopupOpen);
      this.stopListening(this, 'animation:stepsPopup:closed', this.onStepsPopupClosed);

      this.stopListening(
        this.model,
        'change:angle change:x change:y change:w change:h change:sticked change:fixed_position change:is_full_width change:is_full_height',
        this.updateOwnTooltip
      );
      this.stopListening(this.workspace, 'workspace-resize', this.updateTooltips);
      $('#main').off('scroll', this.updateTooltips);
      delete this.animationTriggerHandlersBound;
    },

    updateTooltips: function() {
      window.requestAnimationFrame(
        function() {
          _.each(this.getAnimationTriggers(), this.recalcLinkedTooltip);
          this.recalcOwnTooltip();
        }.bind(this)
      );
    },

    updateLinkedTooltip: function(triggerId) {
      window.requestAnimationFrame(this.recalcLinkedTooltip.bind(this, triggerId));
    },

    updateOwnTooltip: function() {
      window.requestAnimationFrame(this.recalcOwnTooltip.bind(this));
    },

    recalcLinkedTooltip: function(triggerId) {
      if (this.triggerAssets[triggerId]) {
        this.triggerAssets[triggerId].tooltip && this.triggerAssets[triggerId].tooltip.update();
      }
    },

    recalcOwnTooltip: function() {
      this.ownTooltip && this.ownTooltip.update();
    },

    onStepsPopupOpen: function($popupTrigger) {
      var $tooltip = this.ownTooltip && this.ownTooltip.getElement();
      var tooltipBox = $tooltip && $tooltip[0] && $tooltip[0].getBoundingClientRect();
      var popupBox;
      if ($popupTrigger) {
        var $popup = $popupTrigger.find('.js-steps-popup');
        popupBox = $popup && $popup.length && $popup[0].getBoundingClientRect();
      }
      // Спрячем тултип анимаций при пересечении с попапом добавления шага анимации (попап нельзя сделать поверх тултипа)
      if (tooltipBox && popupBox && MathUtils.doBoundingBoxesIntersect(tooltipBox, popupBox)) {
        this.ownTooltip.hide();
      }
    },

    onStepsPopupClosed: function() {
      // Когда попап добавления шага спрятан, проверить, есть ли скрытый тултип. Если есть, показать.
      if (this.ownTooltip && !this.ownTooltip.getElement()) {
        this.ownTooltip.show();
      }
    },

    getSaveBoxData: function(options) {
      var boxData = this.getBoxData();
      // Убираем x из сохраняемых данных для растягиваемых блоков.
      // Оно не только бесполезно, но и вредно, т.к. туда сохраняют данныхе смещения фиксед блоки
      this.model.get('is_full_width') ? (boxData = _.omit(boxData, 'x')) : boxData;
      this.model.get('is_full_height') ? (boxData = _.omit(boxData, 'y')) : boxData;

      return boxData;
    },

    getSaveBoxOptions: function(options) {
      return options || {};
    },

    /**
     * Сохраняет линейные параметры виджета
     * resizePoint - опционально содержит идентификатор точки за которую ресайзили (если блок просто драгали то эта переменная пустая)
     */
    saveBox: function(options) {
      this._saveXHR && this._saveXHR.abort();
      this._saveXHR = this.model.save(this.getSaveBoxData(options), this.getSaveBoxOptions(options));
    },

    // установленный флаг includeBoundingBox то дополнительно функция вернет
    // размер и координаты "ограничевающего бокса" блока (ели нет поворота они будут такими же как координаты размеры-блока)
    // это нужно при ротайшенах и используется в снепинге, в контроле выравнивания, в формировании рамки группы и еще наверное где-нибудь
    // ВНИМАНИЕ! эта функция частенько вызывается для того, чтобы сохранить текущие размеры-положения блока в модели
    // в таком случае естественно флаг includeBoundingBox должен быть выключен
    getBoxData: function(params) {
      params = params || {};
      // можно взять данные о размерах, положении и повороте блока прямо из this.latestPosSizeAngle
      // в принципе можно всегда использовать первый вариант, когда данные беруться из DOM, но этот вариант горрраздо быстрее
      var data = this.latestPosSizeAngle;

      var is_full_width = this.model.get('is_full_width');

      var is_full_height = this.model.get('is_full_height');

      var res = {
        w: data.width,
        h: data.height,
        x: data.left,
        y: data.top,
      };

      if (params.includeFreeze) {
        res.x += params.includeFreeze;
      }

      if (params.includeRotationData) {
        res.angle = data.angle;
        res.flip_v = data.flip_v;
        res.flip_h = data.flip_h;
      }

      if (params.includeBoundingBox) {
        res.bb_w = data.bb_width;
        res.bb_h = data.bb_height;
        res.bb_x = data.bb_left;
        res.bb_y = data.bb_top;
      }

      // Получаем координаты фиксированного виджета относительно экрана а не воркспейса
      if (this.model.get('fixed_position') && params.checkFixedPosition) {
        var old = _.clone(res);

        var blockPosition = this.$el.offset();
        var workspacePosition = this.workspace.position;
        res.y = blockPosition.top - workspacePosition.top;

        res.x = blockPosition.left - workspacePosition.left;

        if (params.includeBoundingBox) {
          res.bb_x = res.x + (res.bb_x - old.x);
          res.bb_y = res.y + (res.bb_y - old.y);
        }
      }

      if (is_full_width) {
        var fw_dims = this.getFullWidthDims();
        res.x = fw_dims.x;
        res.w = fw_dims.w;
      }

      if (is_full_height) {
        var fh_dims = this.getFullHeightDims();
        res.y = fh_dims.y;
        res.h = fh_dims.h;
      }

      if (this.model.get('sticked')) {
        res.sticked_margin = data.sticked_margin;
      }

      return res;
    },

    /**
     * Экспортирует данные из модели в формат latestPosSizeAngle (или getBoundingClientRect(), но с дополнительными свойствами)
     * @returns {{left, top, width, height, angle: number, flip_v: boolean, flip_h: boolean, sinAngle: number, cosAngle: number}}
     */
    getModelBox: function() {
      var angle = this.model.get('angle') || 0;
      var angleInRad = angle ? (angle * Math.PI) / 180 : 0;
      return {
        left: this.model.get('x'),
        top: this.model.get('y'),
        width: this.model.get('w'),
        height: this.model.get('h'),
        angle: angle,
        flip_v: !!this.model.get('flip_v'),
        flip_h: !!this.model.get('flip_h'),
        sinAngle: Math.sin(-angleInRad), // для ускорения всех расчетов
        cosAngle: Math.cos(-angleInRad), // для ускорения всех расчетов
      };
    },

    getPack: function() {
      var packId = this.model.get('pack_id');
      return packId && this.workspace.packs[packId];
    },

    /**
     * Реакция на смену pack_id, в зависимости от того стоит он или нет, решаем показывать или нет собственную рамку виджета (css класс .in-pack)
     */
    onPackChange: function(model, value, options) {
      this.toggleInPackClass();

      // заставляем всех пересмотреть текущее выделение
      // в частности и контролы группировки/разгруппировки и рамки групп которые тоже слушают событие select
      if (!_.isEmpty(this.workspace.getSelectedBlocks()))
        this.workspace.trigger('select', this.workspace.getSelectedBlocks());

      this.workspace.redrawPacksFrames({ tp: 'pack-change' });

      // Всегда снимаем блокировку с блоков при изменении группировки. То же самое делается в common_pack.js и common_unpack.js в onClick
      if (this.isLocked() && (options.undo || options.redo)) {
        model.save({ is_locked: false }, { patch: true, skipHistory: true });
      }

      this.recalcFixedLine();

      if (this.selected) this.resetAnimationTriggers();
    },

    // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
    // даже строго противопоказана, например для свойство анимаций
    checkNeedRedraw: function(model, options) {
      options = options || {};
      var changed_attrs = model.changedAttributes();

      if (options.pid && this.workspace && this.workspace.page.id !== options.pid) {
        return false;
      }

      // Может быть ситуация, когда блок уже есть, слушает события и они приходят,
      // а воркспейс еще не отрендерен, перерисовка невозможна. Это бывает для глобальных виджетов.
      if (!this.workspace.rendered) {
        return false;
      }

      // костыль, если поменяли только анимации, выходим, не надо ничего делать
      // иначе при сдвиге шагов анимаций, исходные виджеты реалинировали на смену свойства animation и перерисовывались
      // почти во всех код был написал херовато и реагировал на свойства, которые его не касаются
      if (_.keys(changed_attrs).length == 1 && changed_attrs.animation) {
        return false;
      }

      return true;
    },

    /**
     * Можно переопределять в потомках
     */
    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      this.redrawPosition(model, _.extend(options, { forceRedraw: false }));
    },

    // Запрещает или разрешает таскать блок
    // ВАЖНО! В отличие от признака this.disableDragging
    // событие драга всплывает дальше, к workspace
    // Это нужно, чтобы поверх залоченного можно было стартовать рамку выделения
    updateLockDragState: function() {
      this.$el.toggleClass('no-drag', this.isLocked());
    },

    redrawPosition: function(model, options) {
      options = options || {};
      model = model || this.model;

      var changeset = {},
        content_changeset = {},
        boxData = options.forceRedraw ? {} : this.getBoxData({ includeRotationData: true });

      // Изменяем класс растягивания здесь, т.к. это самое подходящее место. Прототип redraw вызывается не у всех.
      var changedAttrs = this.model.changedAttributes(),
        wasChangedFullWidth = _.has(changedAttrs, 'is_full_width'),
        wasChangedFullHeight = _.has(changedAttrs, 'is_full_height');

      if (wasChangedFullWidth) {
        this.$el.toggleClass('full-width', !!this.model.get('is_full_width'));
      }
      if (wasChangedFullHeight) {
        this.$el.toggleClass('full-height', !!this.model.get('is_full_height'));
      }

      var fixed = model.get('fixed_position');
      var is_full_width = !!model.get('is_full_width');
      var is_full_height = !!model.get('is_full_height');

      if (model.get('x') != boxData.x || fixed) {
        changeset.left = model.get('x');
      }

      if (is_full_width) {
        var fw_dims = this.getFullWidthDims();
        changeset.left = fw_dims.x;
      }

      if (model.get('y') != boxData.y || fixed) {
        changeset.top = model.get('y');
      }

      if (is_full_height) {
        var fh_dims = this.getFullHeightDims();
        changeset.top = fh_dims.y;
      }

      if (model.get('w') != boxData.w || fixed || is_full_width) {
        changeset.width = model.get('w');
      }

      // При апдейте через сокеты вычисляем свою ширину, а не того, кто редактирует
      if (options.socketUpdate && is_full_width) {
        changeset.width = fw_dims.w;
      }
      // if (is_full_width) { changeset.width = fw_dims.w; }

      if (options.socketUpdate && is_full_height) {
        changeset.height = fh_dims.h;
      }

      if (model.get('h') != boxData.h || fixed || is_full_height) {
        changeset.height = model.get('h');
      }

      if (model.get('z') != this.$content.css('z-index')) {
        changeset['z-index'] = model.get('z');
      }

      // вернул обратно вариант, когда undefined  приводим к 0 или false для нужд сравнения
      // для angle, flip_v и flip_h, поскольку засада в том, что этих полей у виджетов может и не быть изначально (чтобы не засирать ненужными данными большинство виджетов), отсюда растут корни всех бед
      // есть коммит 4993 (*фикс двух багов: не проставлялиь размеры-положение-угол блока при его скрытии-р.. 2014-02-05 17:00:26 +0400)
      // который утверждает что раньше так и было, но это приводило к багам
      // я проверил, бага не будет если в условии вместо "model.get('angle') != boxData.angle" поставить "(model.get('angle') || 0) != boxData.angle"
      // а с флипами вроде и не было багов
      // поэтому я вернул старое поведение (и допилил условие для угла)
      // потому что оно:
      // 1. логичное, ведь по сути undefined дейстивтельно и 0 и false для нас
      // 2. лечит баг когда изменения любого поля в модели виджета приводило к формированию ченджсета {angle:0, flip_v: false, flip_h: false}
      // и соответственно вызову css, так как не пустой ченджсе получался, а там были вызовы на перерисовку рамок анимаций и случалось зацикливание,
      // когда мы эти самые рамки анимаций двигали на воркспейсе и меняли атрибут animation который сам по себе никак не должен приводить к вызову css
      if ((model.get('angle') || 0) != boxData.angle) {
        changeset.angle = model.get('angle') || 0;
      }

      if (!!model.get('flip_v') != boxData.flip_v) {
        changeset.flip_v = !!model.get('flip_v');
      }

      if (!!model.get('flip_h') != boxData.flip_h) {
        changeset.flip_h = !!model.get('flip_h');
      }

      if (!_.isEmpty(changeset)) {
        if (options.doNotRedrawPacksFrames) {
          changeset.doNotRedrawPacksFrames = true;
        }

        if (options.doNotRedrawBottomShiftLine) {
          changeset.doNotRedrawBottomShiftLine = true;
        }

        this.css(changeset);
      }

      if (fixed || options.undo || options.redo) this.recalcFixedLine();

      if (model.get('sticked') || _.has(changedAttrs, 'sticked')) {
        // перерисовываем стики линию для стики виджетов, а также для тех которые были стики, но только что перестали ими быть
        this.recalcStickedLine();

        // пересчитываем sticked_margin если меняли просто x или y в текущем вьюпорте
        // если вьюпорт поменялся, не пересчитываем (иначе считается неверный sticked_margin)
        if (
          (changedAttrs.x || changedAttrs.y) &&
          !_.has(changedAttrs, 'sticked') &&
          this.workspace.page.getPreviousViewport() === this.workspace.page.getCurrentViewport()
        ) {
          var margin = this.getBoxData().sticked_margin;
          if (margin && !isNaN(margin) && this.model.get('sticked_margin') != margin) {
            this.model.set({ sticked_margin: margin }, { first: true });
          }
        }
      }

      if (!options.skipTriggerRedraw && !options.forceRedraw) this.triggerRedraw();
    },

    /**
     * Показывает-скрывает контрол настроек виджета
     */
    showPanel: function() {
      this.workspace.controls.selectFirst();
    },

    /**
     * Скрывает редактор виджета внизу
     *
     * @param $icon - dom-element - иконка в виджетбаре
     */
    hidePanel: function() {
      this.panel && this.panel.hide();
    },

    /**
     * Проверяет есть ли пересечения у блока и области
     */
    inArea: function(area) {
      if (this.model.get('hidden')) return;

      var isFixed = this.model.get('fixed_position');

      var boxData = this.getBoxData({
        includeBoundingBox: true,
        includeRotationData: true,
        checkFixedPosition: true,
      });

      // Для фиксированных блоков getBoxData отдает
      // у-координату относительно вьюпорта (видимой области страницы).
      // Из-за этого на проскроленной странице фиксед не попадет в область выделения,
      // несмотря на то, что визуально попадает.
      // Добавляем значение проскроленности к у-координате фикседа.
      if (isFixed) boxData.bb_y += this.workspace.$container.scrollTop();

      // для начала проверяем на пересечение области выделения с bounding box блока
      var res =
        Math.max(area.x, boxData.bb_x) < Math.min(area.x + area.w, boxData.bb_x + boxData.bb_w) &&
        Math.max(area.y, boxData.bb_y) < Math.min(area.y + area.h, boxData.bb_y + boxData.bb_h);

      // в случае, если область выделения пересекается с bounding box блока
      // и к блоку примен какой либо поворот, тогда нам надо запусть более сложный механизм определения пересечения
      // обычного прямоугольника с повернутым прямоугольником
      // проверка на res позволет избежать ненужных вычислений, поскольку если рамка выделения не пересекается с bounding box повернутого блока,
      // то это означает, что она не пересечется и с самим повернутым блоком
      if (res && boxData.angle) {
        var data;
        // calcRotatedBox вычислит координаты относительно воркспейса если из не передать.

        if (isFixed) {
          // У фикседов точка отсчета - вьюпорт (видимая область страницы).
          data = {
            // Добавляем значение проскроленности к у-координате фикседа,
            // чтобы повернутый фиксед попадал в выделение
            // на проскроленных страницах.
            top: boxData.y + this.workspace.$container.scrollTop(),
            left: boxData.x,
            width: boxData.w,
            height: boxData.h,
            sinAngle: this.latestPosSizeAngle.sinAngle,
            cosAngle: this.latestPosSizeAngle.cosAngle,
          };
        }

        var rotatedBox = MathUtils.calcRotatedBox(data || this.latestPosSizeAngle);

        res = MathUtils.isConvexPolygonsIntersects(rotatedBox, [
          { x: area.x, y: area.y },
          { x: area.x + area.w, y: area.y },
          { x: area.x + area.w, y: area.y + area.h },
          { x: area.x, y: area.y + area.h },
        ]);
      }

      return res;
    },

    /**
     * Проверяет содержит ли блок точку
     */
    contains: function(dot) {
      if (this.model.get('hidden')) return;

      var boxData = this.getBoxData({
        includeBoundingBox: true,
        includeRotationData: true,
      });

      // для начала проверяем не входит ли точка в bounding box блока
      var res =
        boxData.bb_x < dot.x &&
        boxData.bb_x + boxData.bb_w > dot.x &&
        boxData.bb_y < dot.y &&
        boxData.bb_y + boxData.bb_h > dot.y;

      // в случае, если точка входит в bounding box блока
      // и к блоку примен какой либо поворот, тогда нам надо запусть более сложный механизм определения пересечения
      // содержит ли повернутый прямоугольник точку
      // проверка на res позволет избежать ненужных вычислений, поскольку если точка не входит в bounding box повернутого блока,
      // то это означает, что она не входит и в сам повернутый блок
      if (res && boxData.angle) {
        var rotatedBox = MathUtils.calcRotatedBox(this.latestPosSizeAngle);

        res = MathUtils.isConvexPolygonContainsPoint(rotatedBox, dot);
      }

      return res;
    },

    showIconLoader: function() {
      this.isLoading = true;
    },

    hideIconLoader: function() {
      // this.loader && this.loader.fadeOut(100, function() { $(this).remove() });
      // delete this.loader;
      this.isLoading = false;
    },

    onBlockVisibilityChange: function(model) {
      var hidden = !!model.get('hidden');
      this.$el.toggleClass('invisible', hidden);
      if (hidden) {
        this.triggerRedraw();
        this.cutFromSelection();
      }
    },

    // Сохраняем данные о фиксированной позиции и делаем виджет абсолютным
    // Например для вращения или кропа
    storeFixedPosition: function() {
      if (this.model.get('fixed_position') && this.model.get('type') !== 'pack') {
        this.restoreFixed = this.model.get('fixed_position');
        var boxData = this.getBoxData({ checkFixedPosition: true });
        boxData.y = boxData.y + this.workspace.$container.scrollTop();
        this.model.set(_.extend(boxData, { fixed_position: '' }), { skipHistory: true });
      }
    },

    restoreFixedPosition: function() {
      if (this.restoreFixed) {
        var boxData = this.getBoxData();
        var offset = this.$el.offset();
        var workspace_offset_left = this.model.getViewport() != 'default' ? this.workspace.position.left : 0;
        var workspace_offset_top = this.model.getViewport() != 'default' ? this.workspace.position.top : 0;
        var restoreData = {
          x:
            (this.restoreFixed.indexOf('e') > -1 ? $(window).width() - offset.left - boxData.w : offset.left) -
            workspace_offset_left,
          y:
            (this.restoreFixed.indexOf('s') > -1 ? $(window).height() - offset.top - boxData.h : offset.top) -
            workspace_offset_top,
          w: boxData.w,
          h: boxData.h,
          fixed_position: this.restoreFixed,
        };

        if (['n', 'c', 's'].indexOf(this.restoreFixed) > -1)
          restoreData.x -= $('#main').width() / 2 - boxData.w / 2 - workspace_offset_left;
        if (['e', 'c', 'w'].indexOf(this.restoreFixed) > -1)
          restoreData.y -= $('#main').height() / 2 - boxData.h / 2 - workspace_offset_top;

        this.model.set(restoreData, { skipHistory: true });
        delete this.restoreFixed;
      }
    },

    // Восстанавливает первоначальную ширину и положение ранее растянутого блока
    resetFullWidth: function() {
      if (!this.model.get('is_full_width')) {
        return;
      }

      this.model.set({
        is_full_width: false,
        x: this.model.get('full_width_initial_x') || this.model.get('x'),
        w: this.model.get('full_width_initial_w') || this.model.get('w'),
      });
    },

    // Восстанавливает первоначальную высоту и положение ранее растянутого блока
    resetFullHeight: function() {
      if (!this.model.get('is_full_height')) {
        return;
      }

      this.model.set({
        is_full_height: false,
        y: this.model.get('full_height_initial_y') || this.model.get('y'),
        h: this.model.get('full_height_initial_h') || this.model.get('h'),
      });
    },

    onFixPosition: function(model, value, options) {
      this.css({
        'z-index': this.model.get('z'),
        width: this.model.get('w'),
        height: this.model.get('h'),
        top: this.model.get('y'),
        left: this.model.get('x'),
        'margin-left': 0,
        'margin-top': 0,
      });

      if (options && (options.redo || options.undo) && !_.isEmpty(this.workspace.getSelectedBlocks()))
        this.workspace.trigger('select', this.workspace.getSelectedBlocks());

      this.checkRetargetScroll();
      this.recalcFixedLine();
    },

    onAnimationTriggerAdd: function() {
      this.resetAnimationTriggers();
    },

    onAnimationTriggerRemove: function(triggerId) {
      this.resetAnimationTriggers();
    },

    resetAnimationTriggers: function() {
      this.teardownAnimationTriggers();
      // Подождём пока правильно отрисуется рамка, чтобы правильно нарисовать тултип к ней
      window.requestAnimationFrame(this.setupAnimationTriggers.bind(this));
    },

    checkRetargetScroll: function() {
      this.retargetScroll && this.retargetScroll.restore && this.retargetScroll.restore();

      if (this.model.get('fixed_position'))
        this.retargetScroll = RetargetMouseScroll(this.$el.get(0), this.workspace.$container.get(0));
    },

    // Обработчик нужен для ситуации, когда изменяется набор выбранных блоков,
    // при этом необязательно вызывается deselect у текущего (добавление с Shift, например)
    // Обработчик переопределяется в потомках!
    onWorkspaceBlocksSelect: function(blocks) {
      // если у нас просто блок не привязаный к виджетам (типа фреймы шагов анимаций)
      if (this.isStandalone) return;

      // смотрим что у нас в текущем выделении текущий блок или еще что-то
      var isOnlyMeSelected = blocks && blocks.length == 1 && blocks[0] == this;

      // Если выбран кто-то еще кроме нас, выходим из режима вращения
      if (!isOnlyMeSelected) {
        this.frame && this.frame.removeRotationState();
        this.toggleInPackClass(true);
      } else {
        // При исключении блоков из выделения shift-кликом, когда остаётся последний блок, нужно показать рамку
        this.frame && !this.frame.isFrameVisible() && this.frame.show();
        this.toggleInPackClass(false);
      }
    },

    // Признак того, что изменения произошли на редактируемой мной странице и
    // не в результате апдейта по сокетам.
    // change_options - опции из события change модели
    isRealChangeByMe: function(change_options) {
      change_options = change_options || {};

      return !!(
        !change_options.socketUpdate &&
        this.workspace == RM.constructorRouter.workspace &&
        RM.constructorRouter.workspace.page &&
        RM.constructorRouter.isPageLockedByMe(RM.constructorRouter.workspace.page.get('_id'))
      );
    },

    toggleAnimationMode: function(state) {
      this.$el.toggleClass('animation-mode', !!state);

      this.animationMode = !!state;

      // также прячем рамки групп, если виджет в группе
      if (this.model.get('pack_id')) {
        this.isPackFrameHidden = state;
        this.workspace.redrawPacksFrames({ tp: 'animation-mode' });
      }

      this.workspace.redrawBottomShiftLine({ tp: 'animation-mode' });

      this.trigger('animationMode', state);
    },

    /**
     * Получить сторону sticked
     * Проверяем, если при переходе в мобильный вьюпорт у нас остался sticked_side != bottom, тогда очищаем sticked
     * @return {String}
     */
    getStickedSide: function() {
      var sticked_side = this.model.get('sticked');

      if (!sticked_side) {
        return;
      }

      if (this.model.getViewport() !== 'default' && sticked_side !== 'bottom') {
        this.model.set('sticked', undefined);
        sticked_side = undefined;
      }

      return sticked_side;
    },

    /**
     * Функция для блокировки контролов в зависимости от данных модели
     * тут просто заглушка, должна быть реализована в потомках при необходимости
     * @param {RM.models.Widget} model - backbone модель виджета
     * @param {boolean} initial - флаг, должен ставится при первом запуске
     */
    checkDisabledControls: function(model, initial) {
      return [];
    },

    /**
     * Стирает блок
     */
    destroy: function() {
      this.frame && this.frame.removeRotationState();
      this.frame && this.frame.destroy();

      this.workspace.off('select', this.onWorkspaceBlocksSelect);
      this.destroyed = true;

      this.cutFromSelection();
      this.$el.empty().remove();

      this.stopListening(this.model, 'change');

      // Иногда блок — это animationStep, и у него своя модель, у которой нет методов класса RM.classes.Widget (как isGlobal)
      if (this.model.isGlobal && !this.model.isGlobal()) this.triggerRedraw();
      this.rendered = false;

      this.workspace.off('deselect', this.deselect);
      this.model.off('change', this.onModelChange);
      this.model.off('change:pack_id', this.onPackChange);
      this.model.off('change:is_locked', this.updateLockDragState);
      this.model.off('change:fixed_position', this.onFixPosition);

      this.model.off('change:hidden', this.onBlockVisibilityChange);

      this.stopListening(this.model, 'animation:trigger:add');
      this.stopListening(this.model, 'animation:trigger:remove');
      this.stopListening(this.model, 'animation:type:change');

      this.workspace.off('workspace-resize', this.onWorkspaceResize);

      this.$el.off();

      if (this.panel) {
        this.panel.destroy();
        delete this.panel;
      }

      // на данный момент данное событие слушает панелька видео, чтобы отменить ajax запрос на получение данных
      this.trigger('destroy');
    },
  },
  {
    // Статические методы модели, вызываются через RM.blocks[type].methodName()

    // При создании вьюпорта у виджета его текущие аттрибуты расширятся результатом данного метода
    // вызывается из прототипа, к this не обращаться!
    // расширяется в конкретных блоках, но обязательно в начале вызвать этого предка (смотри например реализацию в background блоке)
    getViewportDefaults: function(model, viewportName, options) {
      options = options || {};
      // смотрим ширину дефолтного вьюпорта и текущего
      // и считаем на какое расстояние по горизонтали надо сдвинуть виджет в новом вьюпорте
      // чтобы визуально относительно дефолтного ворспейса он остался на месте
      var defaultWidth = PageModel.getViewportSetting('width', 'default'),
        currentWidth = PageModel.getViewportSetting('width', viewportName),
        deltaWidth = Math.round((currentWidth - defaultWidth) / 2),
        isFullWidth = model.get('is_full_width'),
        removingFullWidth = isFullWidth && viewportName !== 'default',
        isFullHeight = model.get('is_full_height'),
        removingFullHeight = isFullHeight && viewportName !== 'default',
        fixedPosition = model.get('fixed_position'),
        result;

      result = {
        x: model.get('x'),
        y: model.get('y'),
      };

      // если запрос на корректировку дефолтных данных модели виджета во вьюпорте пришел потому
      // что мы либо скопировали виджет через ctrl+c/v либо склонировали с альтом +мышь/стрелки
      // тогда корректировать положение виджета не надо, а то он начинает скакать после дублирования
      // https://trello.com/c/HXo1iLDE/170-viewport-9
      if (options.clone || options.copy) deltaWidth = 0;

      // анимации не переносим во вьюпорты с десктопа, если надо - создаем их там заново ручками
      if (model.get('animation') && viewportName !== 'default') {
        result.animation = undefined;
      }

      if (fixedPosition) {
        // Нехорошо, но только воркспейс может правильно сказать свои координаты
        result.x = result.x - RM.constructorRouter.workspace.position.left;
        result.y = result.y - RM.constructorRouter.workspace.position.top;

        if (['n', 'c', 's'].indexOf(options.fixed || fixedPosition) > -1) delete result.x;
        if (['w', 'c', 'e'].indexOf(options.fixed || fixedPosition) > -1) delete result.y;
      } else {
        result.x = result.x + deltaWidth;
      }

      // Растянутые блоки не имеют смысла в не-дефолтном вьюпорте.
      // Сбрасываем признак растянутости и передаем в качестве x и w - начальные параметры блока,
      // до включения растягивания.
      if (removingFullWidth) {
        result.is_full_width = false;
        result.w = model.get('full_width_initial_w') || model.get('w');
        // Пересчитываем x только если это не fixed. (Если fixed, должен остать)
        if (!fixedPosition) {
          result.x = (model.get('full_width_initial_x') || model.get('x')) + deltaWidth;
        }
      }

      if (removingFullHeight) {
        result.is_full_height = false;
        result.h = model.get('full_height_initial_h') || model.get('h');

        if (!fixedPosition) {
          result.y = model.get('full_height_initial_y') || model.get('y');
        }
      }

      return result;
    },
  }
);

Block.prototype.template = templates['template-constructor-block'];

// Экспорт
RM.vue = RM.vue || {};
RM.vue.Sizes = Vue.extend(SizesVue);

export default Block;
