<template>
  <div class="panel">
    <div class="row shape-type">
      <div
        v-for="shape in shapes"
        :class="{ shape: true, [shape]: true, current: m.form === shape }"
        @click="changeShapeHandler(shape)"
      ></div>
    </div>

    <div class="row state-switcher-wrapper">
      <div
        v-for="type in states"
        :class="{ 'state-switcher': true, [`caption-${type}`]: true, current: type === state }"
        @click="changeStateHandler(type)"
      >
        {{ type }}
      </div>
    </div>

    <div v-if="state === 'default'" class="row">
      <div class="caption">Shape Color</div>
      <color-picker
        class="round"
        :color="m['background-color']"
        :alpha="m['background-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('background-color', $event)"
        @update:alpha="changePropHandler('background-color-opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'hover'" class="row">
      <div class="caption">Shape Color</div>
      <color-picker
        class="round"
        :color="m['hover-background-color']"
        :alpha="m['hover-background-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('hover-background-color', $event)"
        @update:alpha="changePropHandler('hover-background-color-opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'current'" class="row">
      <div class="caption">Shape Color</div>
      <color-picker
        class="round"
        :color="m['current-background-color']"
        :alpha="m['current-background-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('current-background-color', $event)"
        @update:alpha="changePropHandler('current-background-color-opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'default' && m.form !== 'circle'" class="row">
      <div class="caption input-correction">Radius</div>
      <numeric-input
        :autosize="true"
        :value="m['border-radius']"
        :class="{
          'num-input': true,
          'one-digit': m['border-radius'] < 10,
          'two-digit': m['border-radius'] < 100 && m['border-radius'] > 9,
          'three-digit': m['border-radius'] > 99,
        }"
        :min="0"
        :max="999"
        @change="changePropHandler('border-radius', $event)"
      ></numeric-input>
    </div>

    <div v-if="state === 'current' && m.form !== 'circle'" class="row">
      <div class="caption input-correction">Radius</div>
      <numeric-input
        :autosize="true"
        :value="m['current-border-radius']"
        :class="{
          'num-input': true,
          'one-digit': m['current-border-radius'] < 10,
          'two-digit': m['current-border-radius'] < 100 && m['current-border-radius'] > 9,
          'three-digit': m['current-border-radius'] > 99,
        }"
        :min="0"
        :max="999"
        @change="changePropHandler('current-border-radius', $event)"
      ></numeric-input>
    </div>

    <div v-if="state === 'default'" class="row">
      <div class="caption input-correction">Border</div>
      <numeric-input
        :autosize="true"
        :value="m['border-width']"
        :class="{
          'num-input': true,
          'one-digit': m['border-width'] < 10,
          'two-digit': m['border-width'] < 100 && m['border-width'] > 9,
          'three-digit': m['border-width'] > 99,
        }"
        :min="0"
        :max="999"
        @change="changePropHandler('border-width', $event)"
      ></numeric-input>
    </div>

    <div v-if="state === 'hover'" class="row">
      <div class="caption input-correction">Border</div>
      <numeric-input
        :autosize="true"
        :class="{
          'num-input': true,
          'one-digit': m['hover-border-width'] < 10,
          'two-digit': m['hover-border-width'] < 100 && m['hover-border-width'] > 9,
          'three-digit': m['hover-border-width'] > 99,
        }"
        :value="m['hover-border-width']"
        @change="changePropHandler('hover-border-width', $event)"
      ></numeric-input>
    </div>

    <div v-if="state === 'current'" class="row">
      <div class="caption input-correction">Border</div>
      <numeric-input
        :autosize="true"
        :class="{
          'num-input': true,
          'one-digit': m['current-border-width'] < 10,
          'two-digit': m['current-border-width'] < 100 && m['current-border-width'] > 9,
          'three-digit': m['current-border-width'] > 99,
        }"
        :value="m['current-border-width']"
        :min="0"
        :max="999"
        @change="changePropHandler('current-border-width', $event)"
      ></numeric-input>
    </div>

    <div v-if="state === 'default'" class="row">
      <div class="caption">Border Color</div>
      <color-picker
        class="round"
        :color="m['border-color']"
        :alpha="m['border-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('border-color', $event)"
        @update:alpha="changePropHandler('border-color-opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'hover'" class="row">
      <div class="caption">Border Color</div>
      <color-picker
        class="round"
        :color="m['hover-border-color']"
        :alpha="m['hover-border-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('hover-border-color', $event)"
        @update:alpha="changePropHandler('hover-border-color-opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'current'" class="row">
      <div class="caption">Border Color</div>
      <color-picker
        class="round"
        :color="m['current-border-color']"
        :alpha="m['current-border-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('current-border-color', $event)"
        @update:alpha="changePropHandler('current-border-color-opacity', $event)"
      ></color-picker>
    </div>
    <!-- Font Family -->
    <div v-if="state === 'default'" class="row font-family-click-area" title="Font Family">
      <font-family-selector
        :family="m['font-family']"
        @showPopup="closeOthersPopups"
        @change="changePropHandler('font-family', $event)"
      ></font-family-selector>
    </div>

    <div v-if="state === 'current'" class="row font-family-click-area" title="Font Family">
      <font-family-selector
        :family="m['current-font-family']"
        @showPopup="closeOthersPopups"
        @change="changePropHandler('current-font-family', $event)"
      ></font-family-selector>
    </div>

    <!-- Font Style -->
    <div v-if="state === 'default'" class="row font-style-click-area" title="Font Style">
      <font-style-selector
        :font-style="m['font-style']"
        :style="{ 'font-family': m['font-family'] }"
        :font-weight="m['font-weight']"
        :font-family="m['font-family']"
        @showPopup="closeOthersPopups"
        @update:fontStyle="changePropHandler('font-style', $event)"
        @update:fontWeight="changePropHandler('font-weight', $event)"
      ></font-style-selector>
    </div>

    <div v-if="state === 'current'" class="row font-style-click-area" title="Font Style">
      <font-style-selector
        :font-style="m['current-font-style']"
        :style="{ 'font-family': m['current-font-family'] }"
        :font-weight="m['current-font-weight']"
        :font-family="m['current-font-family']"
        @showPopup="closeOthersPopups"
        @update:fontStyle="changePropHandler('current-font-style', $event)"
        @update:fontWeight="changePropHandler('current-font-weight', $event)"
      ></font-style-selector>
    </div>

    <div v-if="state === 'default'" class="row">
      <div class="caption">Text Color</div>
      <color-picker
        class="round"
        :color="m['color']"
        :alpha="m['color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('color', $event)"
        @update:alpha="changePropHandler('color-opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'hover'" class="row">
      <div class="caption">Text Color</div>
      <color-picker
        class="round"
        :color="m['hover-color']"
        :alpha="m['hover-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('hover-color', $event)"
        @update:alpha="changePropHandler('hover-color-opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'current'" class="row">
      <div class="caption">Text Color</div>
      <color-picker
        class="round"
        :color="m['current-color']"
        :alpha="m['current-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('current-color', $event)"
        @update:alpha="changePropHandler('current-color-opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'default'" class="row">
      <div class="caption">Icon Color</div>
      <color-picker
        class="round"
        :color="m['icon_color']"
        :alpha="m['icon_color_opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('icon_color', $event)"
        @update:alpha="changePropHandler('icon_color_opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'hover'" class="row">
      <div class="caption">Icon Color</div>
      <color-picker
        class="round"
        :color="m['hover-icon_color']"
        :alpha="m['hover-icon_color_opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('hover-icon_color', $event)"
        @update:alpha="changePropHandler('hover-icon_color_opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'current'" class="row">
      <div class="caption">Icon Color</div>
      <color-picker
        class="round"
        :color="m['current-icon_color']"
        :alpha="m['current-icon_color_opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('current-icon_color', $event)"
        @update:alpha="changePropHandler('current-icon_color_opacity', $event)"
      ></color-picker>
    </div>

    <div v-if="state === 'hover'" class="row">
      <div class="caption">Transition</div>
      <rm-switcher v-model="m['transition']" color-on="#2d90ff"></rm-switcher>
    </div>

    <div v-if="state === 'default'" class="row">
      <div class="font-size-icon"></div>
      <numeric-input
        class="num-input"
        :autosize="true"
        :value="m['font-size']"
        :min="8"
        :max="999"
        @change="changePropHandler('font-size', $event)"
      ></numeric-input>
    </div>

    <div v-if="state === 'current'" class="row">
      <div class="font-size-icon"></div>
      <numeric-input
        class="num-input"
        :autosize="true"
        :value="m['current-font-size']"
        :min="8"
        :max="999"
        @change="changePropHandler('current-font-size', $event)"
      ></numeric-input>
    </div>

    <div v-if="state === 'default'" class="row">
      <div class="letter-spacing-icon"></div>
      <numeric-input
        class="num-input"
        :autosize="true"
        :value="m['letter-spacing']"
        :min="-99"
        :max="999"
        :step="0.1"
        :use-float="true"
        @change="changePropHandler('letter-spacing', $event)"
      ></numeric-input>
    </div>

    <div v-if="state === 'current'" class="row">
      <div class="letter-spacing-icon"></div>
      <numeric-input
        class="num-input"
        :autosize="true"
        :value="m['current-letter-spacing']"
        :min="-99"
        :max="999"
        :step="0.1"
        :use-float="true"
        @change="changePropHandler('current-letter-spacing', $event)"
      ></numeric-input>
    </div>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import Panel from './panel.vue';
import FontFamilySelector from '../../common/font-family-selector.vue';
import FontStyleSelector from '../../common/font-style-selector.vue';
import RmSwitcher from '../../common/rm-switcher.vue';
import NumericInput from '../../common/numeric-input.vue';
import ColorPicker from '../../common/color-picker.vue';

export default {
  mixins: [Panel],
  components: { FontFamilySelector, FontStyleSelector, RmSwitcher, NumericInput, ColorPicker },
  computed: {
    maxBorderWidth() {
      return Math.ceil(Math.min(this.w, this.h) / 2);
    },
  },
  watch: {
    state(state) {
      $('.common-button', this.raw_block.$el).toggleClass('hovered', state == 'hover');
      $('.common-button', this.raw_block.$el).toggleClass('current', state == 'current');
    },
  },
  data() {
    return {
      shapes: ['rectangle', 'circle', 'square'],
      states: ['default', 'hover', 'current'],
      state: 'default',
    };
  },
  destroyed() {
    this.setCorrectIconColor('default');
    $('.common-button', this.raw_block.$el).toggleClass('hovered', false);
    $('.common-button', this.raw_block.$el).toggleClass('current', false);
  },
  methods: {
    changeShapeHandler(shape) {
      this.m.form = shape;
      this.closeOthersPopups(null);
    },
    changeStateHandler(state) {
      this.state = state;
      this.closeOthersPopups(null);
      this.setCorrectIconColor(state);
    },
    changePropHandler(propType, val) {
      this.m[propType] = val;
    },
    setCorrectIconColor(state) {
      this.raw_block.onIconColorChange(this.m, null, {}, state);
    },
  },

  mounted: function() {
    var link = this.m.clickLink;

    if (!link || !this.m.is_global || !$('.controls .control.picture_link .placeholder-selector').hasClass('hidden')) {
      this.states.pop();
      return;
    }

    var isUrl = /^http(s?)\:\/\//i.test(link) || /^mailto\:/i.test(link) || /^tel\:/i.test(link);

    if (!isUrl) {
      var pageID = link;

      // получаем страницу мэга по её айдишнику
      var pageNum = RM.constructorRouter.mag.getPageUri(pageID);

      // если страница не существует
      if (!pageNum) {
        this.states.pop();
      }
    } else {
      this.states.pop();
    }
  },
};
</script>
