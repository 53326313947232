/**
 * Код для работы с вьюпортами внутри текстового виджета, используется и в конструкторе и во фронте
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';

const TextViewportsClass = function() {};
TextViewportsClass.prototype = {
  // аттрибуты DOM нод внутри текстового виджета которые должны быть уникальными между вьюпортами
  // список ограничен, потому что например стили являются уникальными, а айдишники страниц (data-pid) которые используются у <a> должны быть сквозными
  // список использется в функциях которые включают/выключают текст внутри виджета в разные состояния вьюпорта
  text_viewport_attributes: [
    'style',
    'class',
    'data-size-leading-linked',
    'data-size-leading-ratio',
    'data-anchor-link-pos',
  ],

  // функция переключает текст на нужный вьюпорт
  // все настройки вьюпортов храняться внутри текста в атрибутах нод
  // это такой мини вьюпортинг наподобие глобаной реализации вьюпортов (когда модель виджета меняет свои атрибуты)
  // в модели текст ВСЕГДА хранится в состоянии дефолтного вьюпорта (а данные остальных вьюпортов рассованы по спец атрибутам внутри текстовых нод)
  // поэтому при редактировании текста (или при превью) мы его переводим в режим вьюпорта, а при сохранении обратно в режим дефолтного вьюпорта
  //! !!! эта логика в урезаном и оптимизированном виде также присутствует в scanTextForFontsAndVariationsRaw в textutils.js
  switchTextToViewport: function(text, viewportName) {
    text = text || '';

    // если дефолтный вьюпорт тогда просто возвращаем текст, поскольку ничего делать не надо
    // форматирование текста и так в можели всегда охранено в режиме дефолтного вьюпорта
    if (viewportName == 'default') return text;

    // запишиваем всю верстку во временный див
    var $content = $('<div>').html(text),
      attrsList = this.text_viewport_attributes,
      self = this;

    // бежим по всем текстовым нодам и проставляем всем атрибутам из text_viewport_attributes
    // значения из соответствующих аттрибутов для требуемого вьюпорта (если такие данные кончено же имеются)
    $content.find('*').each(function() {
      var $item = $(this);

      _.each(attrsList, function(attrName) {
        var viewportAttrName = self.getViewportAttributeName(attrName, viewportName),
          defaultAttrName = self.getViewportAttributeName(attrName, 'default');

        // получаем данные для текущего атрибута (перебираемого из text_viewport_attributes)
        // из нужного вьюпорта
        var viewportValue = $item.attr(viewportAttrName),
          defaultValue = $item.attr(attrName);

        // сохраняем данные из текущего дефолтного атрибута во временный, чтобы потом их можно было восстановить при сохранении виджета
        $item.removeAttr(defaultAttrName);
        if (defaultValue) {
          $item.attr(defaultAttrName, defaultValue);
        }

        // если данные есть, тогда заменяем ими текущий дефолтный аттрибут
        // empty спец признак что данных нет, но они существовали когда-то, т.е. не надо брать дефолтные!
        if (viewportValue) {
          if (viewportValue == 'empty') $item.removeAttr(attrName);
          else $item.attr(attrName, viewportValue);
        }
      });
    });

    var res = $content.html();
    $content.empty().remove();
    return res;
  },

  getViewportAttributeName: function(attrName, viewportName) {
    var viewportAttrName = attrName + '-' + viewportName;

    // у аттрибутов типа style надо еще прибавить спереди data- что получить правильное название свойства для вьюпорта
    if (!/^data/.test(viewportAttrName)) viewportAttrName = 'data-' + viewportAttrName;

    return viewportAttrName;
  },
};

export default TextViewportsClass;
