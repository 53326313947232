/**
 * Класс для страницы, хранит в себе виджеты и управлет их загрузкой запуском и отображением
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import pageTpl from '../../templates/viewer/page.tpl';
import backgroundTpl from '../../templates/viewer/widgets/background.tpl';
import { Utils } from '../common/utils';
import Scale from '../common/scale';
import AnimationUtils from '../common/animationutils';
import Animations from './animations';
import TextUtils from '../common/textutils';
import VideoUtils from '../common/videoutils';
import Viewports from '../common/viewports';
import GlobalWidgets from '../common/global-widget';
import RetargetMouseScroll from '../vendor/retargetmousescroll';
import Widgets from './widgets';
import Device from '../common/device';

const templates = { ...pageTpl, ...backgroundTpl };

const Page = Backbone.View.extend(
  {
    template: templates['template-viewer-page'],

    initialize: function(params) {
      _.bindAll(this);

      this.mag = params.mag;
      this.router = params.router;
      this.$container = params.$container;
      this.viewerType = params.viewerType;
      this.totalPages = params.totalPages;
      this.isStickyVerticalViewer = params.isStickyVerticalViewer;
      this.widgetsData = params.pageData.wids;

      _.defaults(params.pageData, {
        title: '',
        width: this.mag.getViewportSetting('width'),
        height: this.mag.getViewportSetting('min_height'),
      });

      // Взять параметры страницы
      _.extend(this, _.omit(params.pageData, ['wids', 'widgets'])); // widgets это легаси параметр для старых мэгов (там баг был что это поле присутствовало)

      this.contentPosition = {
        left: 0,
        top: 0,
      };

      return this;
    },

    render: function() {
      // создаем вьюху стандартым методом бекбона:
      // устанавливаем el, $el и делегацию событий из списка events
      this.setElement(
        this.template({
          isStickyVerticalViewer: this.isStickyVerticalViewer,
        })
      );

      this.$el.appendTo(this.$container);

      if (!RM.screenshot && !Modernizr.csspositionsticky) {
        $('.polyfill-sticky').Stickyfill();
      }

      this.zIndex = this.totalPages - this.num + 2;

      // расставляем страницы в правильном порядке
      if (this.viewerType == 'vertical') {
        this.$el.css('z-index', this.zIndex); // +2 чтобы оставить 1 для final page
      }

      this.$content = this.$('.page-content-container');

      this.$scrollWrapper = this.$('.content-scroll-wrapper');
      this.$scrollWrapper.one('scroll', this.fixSafariScroll);

      this.$fixedBgContainer = this.$('.page-fixed-bg-container');

      this.$fixedPositionContainer = this.$('.fixed-position-container');
      this.$fixedPositionContainerTop = this.$('.fixed-position-container-top');

      this.$contentBounds = this.$('.content-bounds');

      // смотрим какой вьюпорт будет использовать страница (на основе вьюпорта в режиме которого работает мэг - this.mag.viewport)
      // прсто у данной конкретной страницы этот вьюпорт может быть выключен и не настроен в конструкторе
      // и тогда this.getPageViewport() выберет самый подходящий из доступных
      this.pageViewport = this.getPageViewport();

      if (this.viewerType == 'horizontal') {
        this.$scrollWrapper.bind('scroll', this.onScroll);
      }

      this.createLQBackground();

      if (RM.screenshot == this._id) {
        this.$el.css({
          overflow: 'hidden',
          height: this.height,
          width: 1024,
          position: 'relative',
        });

        if (Utils.queryUrlGetParam('pdf') === 'true') {
          // В пдфках у нас могут быть длинные скролл страницы и фикседы должны учитываться во весь размер
          this.$fixedPositionContainer.css({ height: '100%' });
          this.$fixedPositionContainerTop.css({ height: '100%' });
        }

        this.onResize(); // Т.к. у скриншотера нет полноценного объекта mag и некому вызвать начальный resize

        this.on(
          'pageLoaded',
          function() {
            this.mag.router.sendReadyEvent({ page: this });
          },
          this
        );
      }

      // NOTE: 26.08.2019 я временно отключил этот фикс, т.к. кажется все работает и без него
      // + работает даже на старых айфонах ¯\_(ツ)_/¯
      // this.listenTo(this, 'form:focus', this.onFormFocus);
      // this.listenTo(this, 'form:blur', this.onFormBlur);
      // this.listenTo(this, 'page:scrollWithFormInFocus', this.onScrollWithFormInFocus);

      return this;
    },

    show: function() {
      if (!this.shown) {
        this.$el.removeClass('hidden');

        this.shown = true;

        // только для горизонтального вида
        // для вертикального ресайз происходит всегда для всех страниц при ресайзе окна, поэтому дулать тут повторную работу не надо
        // (а для горизонтального только для видимых)
        if (this.viewerType == 'horizontal') this.onResize();

        // если виджеты на странице не созданы (или были когда-то созданы но сейас уничтожены, не важно)
        // либо если виджеты есть, но мы видим что созданы они были под другой вьюпорт, который уже не совпадает с текущим
        if (!this.widgets || this.lastViewportUsedForWidgetsCreation != this.pageViewport)
          this.createWidgetsForCurrentViewport();
      }

      return this;
    },

    start: function(params) {
      // если уже стоит флаг старта страницы ничего делать не надо

      // forceStart используется, если виджеты нужно еще раз принудительно стартовать
      // Пример: аудиовиджет не начнет autoplay если вкладка изначально
      // открыта скрытой (клик по колесу мыши)
      if (this.started && !(params && params.forceStart)) return;

      this.started = true;
      this.trigger('started');

      params = params || {};

      // пробегаемся по всем виджетам на странице и просим их запуститься
      _.each(this.widgets, function(widget) {
        // запускаем либо все виджеты, либо только с определенным типом (использется в мэге в onPageVisibilityChange)
        if (!params || !params.widgetTypes || _.indexOf(params.widgetTypes, widget.type) >= 0) widget.start();
      });

      this.focusPage();

      this.$scrollWrapper.addClass('accelerated-scroll');

      this.animations && this.animations.start();

      // Above page виджеты не входят в набор анимаций страницы. Стартуем (и стопим) их отдельно
      this.mag.aboveGlobalAnimations && this.mag.aboveGlobalAnimations.start();

      return this;
    },

    stop: function(params) {
      // если не стоит флаг старта страницы ничего делать не надо
      if (!this.started) return;

      params = params || {};

      this.started = false;
      this.trigger('stopped');

      this.$scrollWrapper.removeClass('accelerated-scroll');

      // пробегаемся по всем виджетам на странице и просим их остановиться
      _.each(this.widgets, function(widget) {
        // останавливаем либо все виджеты, либо только с определенным типом (использется в мэге в onPageVisibilityChange)
        if (!params || !params.widgetTypes || _.indexOf(params.widgetTypes, widget.type) >= 0) widget.stop();
      });

      this.animations && this.animations.stop();
      this.mag.aboveGlobalAnimations && this.mag.aboveGlobalAnimations.stop();

      return this;
    },

    getUrl: function() {
      // и для кастомного домена и для обычного работаем одинаково
      return window.location.protocol + '//' + window.location.hostname + '/' + 'p' + this.num_id + '/';
    },

    // устанавливает положение страницы слева от видимой части экрана, справа или же целиком в экране
    // либо снизу-сверху для режима вертикального вьювера
    setPosition: function(pos, neighbour) {
      // оптимизируем обращения к дум, когда много страниц есть небольшой эффект от этого
      if (this.lastPosition == pos && this.lastNeighbour == neighbour) return;

      this.$el
        .removeClass('center-page prev-page next-page neighbour')
        .addClass(pos + '-page ' + (neighbour ? 'neighbour' : ''));

      this.lastPosition = pos;
      this.lastNeighbour = neighbour;
    },

    /**
     * Скроллит внутренний контент страницы
     * @param {Number} offset
     */
    scrollOnVerticalMode: function(offset) {
      this.hasScrollOnVerticalMode = true;

      var pageScroll,
        pageInnerScroll,
        maxDeltaScroll = this.pageHeight - this.pageBgHeight;

      // дельта скрола
      if (offset < 0) {
        pageScroll = -offset;
        pageInnerScroll = 0;
      } else if (maxDeltaScroll >= offset) {
        pageScroll = 0;
        pageInnerScroll = -offset;
      } else {
        pageScroll = maxDeltaScroll - offset;
        pageInnerScroll = -maxDeltaScroll;
      }

      // сохраняем координаты видимой области на экране
      // (для горизонтального вьювера это делается в функции onScroll)
      // в координатах виджетов (т.е. учитываем положение page-content-container и масштабирование)
      // нам это надо чтоюы быстро выбрать виджеты которые сейчас должны бить видимы на экране
      // с учетом текущего скрола, скейла и положения контейнера с контентом страницы
      this.visibleWidgetsCoords = this.visibleWidgetsCoords || {};
      this.visibleWidgetsCoords.scrollTop = -pageInnerScroll;

      // для телефона с его стики forceApply надо false, т.е.обновлять скрол анимацию только если страница сейчас текущая
      // а для десктопа надо всегда, независимо от того какая сейчас страница текущая
      // связано с тем, что на телефоне в стики режиме страница становится активной когда вылезет снизу чуть выше кнопки меню
      // и предыдущей странице нам нельзя принудительно говорить чтобы она сбросила положение ведь ее низ все еще виде на экране, хоть она уже и не активна
      this.animations && this.animations.onScroll({ forceApply: !this.isStickyVerticalViewer });

      if (this.isStickyVerticalViewer) return;

      this.$el.css('top', 0); // чтобы перекрыть динамические классы для next-page

      // TranslateZ(0) - включаем аппаратное ускорение для текущей страницы в режиме вертикального вьювера (но не для режима стики на телефоне)
      // иначе в маковском сафари баги с тем что любой виджет в котором есть аппаратное ускорение (слайдшоу там или повернутый текст)
      // начинает так влиять на страницу, что вся страница сним начинает перекрывать даже меню
      // в маковском сафари финт с .backface-visibility(hidden); для включения аппаратного ускорения не работает
      Utils.applyTransform(this.$el, pageScroll ? 'translateY(' + pageScroll + 'px) translateZ(0)' : '');

      // если есть фиксированные виджеты то двигать контейнер с основным контентом страницы можно только
      // с помощью top, трансформом нельзя поскольку он "спекает" контент в один слой и фиксед может
      // быть только снизу или только сверху
      // правда сейчас это уже не актуально, поскольку у нас теперь фикседы могут быть либо снизу либо сверху все
      // но для старых мэгов оставляем как легаси, т.к. в них фикседы могут идти вперемежку с обычными
      if (this.hasFixedWidgets) {
        this.$contentBounds.css('top', pageInnerScroll);
      } else {
        Utils.applyTransform(this.$contentBounds, pageInnerScroll ? 'translateY(' + pageInnerScroll + 'px)' : '');
      }
    },

    resetScrollOnVerticalMode: function(offset) {
      if (!this.hasScrollOnVerticalMode) return;

      this.hasScrollOnVerticalMode = false;

      // сохраняем координаты видимой области на экране
      // (для горизонтального вьювера это делается в функции onScroll)
      // в координатах виджетов (т.е. учитываем положение page-content-container и масштабирование)
      // нам это надо чтоюы быстро выбрать виджеты которые сейчас должны бить видимы на экране
      // с учетом текущего скрола, скейла и положения контейнера с контентом страницы
      this.visibleWidgetsCoords = this.visibleWidgetsCoords || {};
      this.visibleWidgetsCoords.scrollTop = 0;

      // для телефона с его стики forceApply надо false, т.е. сбрасывать в 0 только если страница сейчас текущая
      // а для десктопа надо всегда, независимо от того какая сейчас страница текущая
      // связано с тем, что на телефоне в стики режиме страница становится активной когда вылезет снизу чуть выше кнопки меню
      // и предыдущей странице нам нельзя принудительно говорить чтобы она сбросила положение ведь ее низ все еще виде на экране, хоть она уже и не активна
      this.animations && this.animations.onScroll({ forceApply: !this.isStickyVerticalViewer });

      if (this.isStickyVerticalViewer) return;

      this.$el.css('top', ''); // чтобы сбросить

      Utils.applyTransform(this.$el, '');

      // если есть фиксированные виджеты то двигать контейнер с основным контентом страницы можно только
      // с помощью top, трансформом нельзя поскольку он "спекает" контент в один слой и фиксед может
      // быть только снизу или только сверху
      // правда сейчас это уже не актуально, поскольку у нас теперь фикседы могут быть либо снизу либо сверху все
      // но для старых мэгов оставляем как легаси, т.к. в них фикседы могут идти вперемежку с обычными
      if (this.hasFixedWidgets) {
        this.$contentBounds.css('top', 0);
      } else {
        Utils.applyTransform(this.$contentBounds, '');
      }
    },

    hide: function() {
      if (this.shown) {
        this.$el.addClass('hidden');

        this.shown = false;
      }

      return this;
    },

    /**
     * Удаление всех виджетов
     * @param {Array} except массив типов виджетов, которые удалять не надо
     */
    destroyAllWidgets: function(except) {
      // снимаем отметку что страница загружена (а пометку что стартована не снимаем)
      this.loaded = false;

      this.videoBGStarted = false;

      _.each(
        this.widgets,
        function(widget, i) {
          // если у виджета в одном вьюпорте не было анимации, а в другом появилась (и наоборот),
          // тогда except не действует — этот виджет все равно нужно будет перерендерить
          // либо если виджет отсутствует либо скрыт в другом вьюпорте, то надо удалить
          var needToRemove = this.widgetsData.some(function(w) {
            var isAnimated =
              w._id === widget._id &&
              Boolean(w.animation && w.animation.type && w.animation.type != 'none' && !_.isEmpty(w.animation.steps));
            var isHidden =
              w._id === widget._id &&
              ((w.viewport_default && w.viewport_default.hidden) ||
                (w.viewport_tablet_portrait && w.viewport_tablet_portrait.hidden) ||
                (w.viewport_phone_portrait && w.viewport_phone_portrait.hidden) ||
                w.hidden);
            return isAnimated || isHidden;
          });

          if (!except || !except.includes(widget.type) || needToRemove) {
            widget.destroy();
            // .off() обязательно после destroy,
            // потому что на destroy виджет пошлет всем кто его слушает прощальный привет в виде события destroyed, чтобы не ждали его лоада, если кто-то надеялся
            widget.off();

            delete this.widgets[i];
          }
        }.bind(this)
      );

      this.animations && this.animations.destroy();

      delete this.animations;

      // из-за того что fireReady выстреливает с 200мс задержкой
      // были ситуации когда вьюпорт сменили и вызвали destroyAllWidgets (который удалил все виджеты и проставит странице this.loaded = false)
      // после чего создали новые виджеты для нового вьюпорта и пытаемся их грузить, но они не грузятся в loadNextWidgetsPack в mag.js
      // а все потому, что для прежнего вьюпорта все виджеты успели загрузиться сразу перед сменой вьюпорта и вызвали с отсрочкой fireReady
      // и у нас этот fireReady выстрелил после destroyAllWidgets и обратно проставил this.loaded = true
      // а loadNextWidgetsPack не будет грузить виджеты если у страницы стоит loaded, ситуация редкая, но возникала, отловить ее было полной задницей
      clearTimeout(this.fireReadyTimeout);
    },

    destroy: function() {
      this.retargetScroll && this.retargetScroll.restore && this.retargetScroll.restore();

      this.resetWaitForAnimationEnd();

      this.destroyLQBackground({ animate: false });

      this.destroyAllWidgets();

      // удаляем вьюху стандратным методом бекбона:
      // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
      return this.remove(); // return this для вызова по цепочке
    },

    /**
     * Фиксит position: sticky фона страницы в ios safari при фокусе на форме
     * @param {Number} scrollTop
     */
    onFormFocus: function(scrollTop) {
      // В ios safari (и остальных браузерах, потому что они все по сути сафари) есть баг:
      // когда появляется экранная клавиатура, элементы с position: fixed становятся по факту position: absolute http://stackoverflow.com/q/14492613
      // То же самое для position: sticky
      // И они спозиционированны относительно body, а не своей родительской страницы почему-то,
      // поэтому берём scrollTop всего мага, а не локальный scrollTop этой страницы
      if (Modernizr.safari || !Device.isDesktop) {
        // Если scrollTop не задан, используем собственный скролл страницы (scrollTop не передаётся для горизонтального вьюера, потому что там нет глобального скролла)
        scrollTop = _.isUndefined(scrollTop) ? this.$scrollWrapper && this.$scrollWrapper.scrollTop() : scrollTop;
        // Будем фиксить только фон, потому что ничего плохого, если на время фокуса / экранной клавиатуры fixed-элементы уедут — будет больше места на экране
        this.$fixedBgContainer.css({ top: scrollTop });
      }
    },

    /**
     * Возвращает фон страницы на место в ios safari при потере фокуса на форме
     */
    onFormBlur: function() {
      // Вернём фон на место
      if (Modernizr.safari || !Device.isDesktop) {
        this.$fixedBgContainer.css({ top: 0 });
      }
    },

    /**
     * Следит за скроллом, пока форма в фокусе в ios safari
     * @see onFormFocus
     * @param {Number} scrollTop
     */
    onScrollWithFormInFocus: function(scrollTop) {
      if (Modernizr.safari || !Device.isDesktop) {
        scrollTop = _.isUndefined(scrollTop) ? this.$scrollWrapper && this.$scrollWrapper.scrollTop() : scrollTop;
        this.$fixedBgContainer.css({ top: scrollTop });
      }
    },

    isSwipeInProgress: function() {
      return this.$el.hasClass('swiping');
    },

    // используется в режиме вертикального вьювера
    getPageZIndex: function() {
      return this.zIndex || 1;
    },

    getImmediatePack: function() {
      // Не кэшировать, иначе после выполнения destroyAllWidgets в кэше будут оставаться ссылки на виджеты
      return _.filter(this.widgets, function(widget) {
        // Добавим в эту пачку те виджеты, которые можно рендерить сразу (почти все виджеты, кроме фона, картинок, видео и аудио)
        // Фикседы, которые не-картинки, тоже попадают сюда, и потом не попадают в пачку BGandFixeds. Фон — не попадает.
        return widget.canRenderImmediately() && !widget.rendered;
      });
    },

    // возвращает пачки виджетов которые попадают во фрейм высотой в экран в таком порядке:
    // фон + фикседы + текущей видимые виджеты на экране, учитываем скрол и скейл,
    // потом пачки в порядке: одна вниз, одна вверх, все вниз (по одной), все вверх (по одной)
    // функция сама должна знать какие виджеты уже загружены или в процессе загрузки и не добавлять лишних в пачки
    // контейнеры анимаций не проверяем, работаем со всеми виджетами так, как будто у нас нет анимации совсем
    // с анимациями тут расчитать это очень сложно, и самое главное не нужно, тут дело всего лишь в приоритете, все равно все будет загружено
    getWidgetsPacks: function() {
      if (!this.widgets) return [];

      // верхняя и нижняя координата области в координатном пространстве виджетов
      // которая сейчас видна на экране (с учетом положения контейнера виджетов, скейла и скрола)
      var vwc = this.visibleWidgetsCoords,
        top = (vwc.top + (vwc.scrollTop || 0)) / vwc.scale,
        bottom = (vwc.bottom + (vwc.scrollTop || 0)) / vwc.scale,
        height = bottom - top,
        inverseHeight = 1 / height; // для ускорения расчетов, не велико ускорение, но когда в мэге 5000 виджетов может помочь (если конечно интерпретатор сам не делает таких оптимизаций)

      var res = [],
        i,
        widget,
        BGAndFixeds = [];

      // сперва всегда идет фоновый виджет, если он еще не загружался конечно же
      // он в пачке будет один единственный
      if (this.BGWidget && !this.BGWidget.rendered && !this.BGWidget.loaded) {
        BGAndFixeds.push(this.BGWidget);
      }

      // потом идут все скопом фиксед виджеты, если есть
      if (this.fixedWidgets && this.fixedWidgets.length) {
        var fixedWidget;

        for (i = 0; i < this.fixedWidgets.length; i++) {
          fixedWidget = this.fixedWidgets[i];

          if (
            !fixedWidget.rendered &&
            !fixedWidget.loaded &&
            // Фикседы, которые можно было рендерить сразу, уже есть в первой приоритетной пачке
            !fixedWidget.canRenderImmediately()
          ) {
            BGAndFixeds.push(fixedWidget);
          }
        }
      }

      // сначала пробегаемся по всем виджетам и проставляем им диапазон экранов в которых они видны
      // экраны нумеруются так: текущий видимый 0, следующий за ним вниз 1, и так далее, экраны верх идут с отрицательными индексами
      // если виджет пересекает несколько экранов то мы относим его к более приоритетному экрану
      // приоритеры такие: текущий, один вниз, один вверх, все остальные вниз, все остальные вверх
      var wbs = [], // widgets by screen
        ind = 0,
        minScreen = 99999,
        maxScreen = -99999,
        screenInd,
        atLeastOneWidget = false,
        result;

      for (i = 0; i < this.widgets.length; i++) {
        widget = this.widgets[i];

        if (
          !widget.rendered &&
          !widget.loaded &&
          widget.type != 'background' && // bg и фикседы мы уже добавили выше, вне цикла
          !widget.fixed_position
        ) {
          result = Page.getScreenIndex(widget, top, inverseHeight, true);

          screenInd = result.index;
          minScreen = Math.min(result.start, minScreen);
          maxScreen = Math.max(result.end, maxScreen);

          // Сохраним индекс экрана: пригодится при логировании таймингов загрузки виджетов
          widget.screenIndex = screenInd;

          if (screenInd !== Infinity) {
            wbs[screenInd] = wbs[screenInd] || [];
            wbs[screenInd].push(widget);
          }

          atLeastOneWidget = true;
        }
      }

      // первая пачка это виджеты которые видимы сейчас на экране
      // вторая пачка это виджеты на экран ниже
      // третья пачка это виджеты на экран выше
      // четвертая это виджеты на два экрана ниже
      // пятая на три и так до самого низа
      // потом идут пачки вверх: на два экрана выше, на три, на четыре и так до верха
      if (atLeastOneWidget) {
        // при определенных условиях бывало что нет ни одного вджета который следовало бы загрузить, и циклы бегали с -99999 до 99999
        if (wbs[0]) res.push(wbs[0]);
        if (wbs[1]) res.push(wbs[1]);
        if (wbs[-1]) res.push(wbs[-1]);
        for (i = 2; i <= maxScreen; i++) if (wbs[i]) res.push(wbs[i]);
        for (i = -2; i >= minScreen; i--) if (wbs[i]) res.push(wbs[i]);
      }

      // если есть бг виджет или фиксед виджеты (незагруженные) тогда их надо поставить в самую первую пачку
      if (BGAndFixeds.length) {
        // если есть пачки виджетов поимо фикседов и бг, тогда добавим бг и фиксед в первую пачку в начало
        if (res.length) {
          res[0] = BGAndFixeds.concat(res[0]);
        } else {
          // если же никаких других пачек виджетов нет, тогда в единственной пачке будут бг виджет и фиксед виджеты
          res = [BGAndFixeds];
        }
      }

      return res;
    },

    // функция начинает рендеринг виджетов в пачке и дожидается их загрузки вызывая потом cb
    // рендерит только те которые еще не рендерились
    loadWidgetsPack: function(pack, cb) {
      var widgetsToWait = 0,
        widgetsLoaded = 0,
        widgetsRendered = 0,
        renderingIsOver = false,
        i,
        self = this;

      // бежим по всем виджетам в пачке
      // берем только те которые не загружены и не загружаются в данный момент
      for (i = 0; i < pack.length; i++) {
        var widget = pack[i];

        // если виджет создан и загружается или уже загружен тогда ничего с ним делать не надо
        // и не уничтожен (если он уничтожен, он все равно останется в памяти и GC его не уберет пока мы не удалим на него все ссылки, в данном случае ссылка на него есть в пачке, поэтому мы можем считать его свойство destroyed которое он проставит сам когда его попросят удалиться)
        if (!widget.rendered && !widget.loaded && !widget.destroyed) {
          if (!widget.canRenderImmediately()) {
            widgetsToWait++;
            // обязательно слушаем destroyed, если виджеты на странице уничтожат
            // после того как loadWidgetsPack получитт команду на загрузку виджетов с этой страницы
            // нам важно прекратить загрузку текущей пачки и вызвать колбек cb в мэг, чтобы продолжились грузиться другие пачки виджетов
            // ВАЖНО! может быть синхронным! т.е. onWidgetLoad вызвать тут же!
            widget.on('loaded destroyed', onWidgetLoad, widget);
          }

          widgetsRendered++;

          // единстенное место во всем вьювере откуда вызывается рендеринг виджета и его загрузка (кроме скриншот режима, у него отдельный метод лоадинга loadForScreenshotMode)
          widget.render();
        }
      }

      renderingIsOver = true;

      // если вообще нет виджетов в пачке которые надо начинать грузить (рендерить и ожидать загрузки если выражаться точнее)
      if (!widgetsRendered) cb();

      // поскольку виджет может вызвать событие loaded сразу же
      // нам надо проверить что может быть к концу рендера все виджеты уже вернули loaded
      // например если первый виджет в пачке текстовый, то он сразу вернет loaded
      // onWidgetLoad не станет вызывать cb потому что рендеринг еще не окончен
      // зато тут колбек cb вызовется, потому что widgetsToWait == widgetsLoaded
      if (widgetsToWait == widgetsLoaded) cb();

      function onWidgetLoad() {
        widgetsLoaded++;
        // поскольку виджет может вызвать событие loaded сразу же
        // нам надо проверить что мы отрендерили уже все виджеты из пачки которые хотели
        // иначе например если первый виджет в пачке текстовый, то он сразу вернет loaded и условие widgetsToWait == widgetsLoaded выполниться
        if (renderingIsOver && widgetsToWait == widgetsLoaded) cb();
      }
    },

    loadForScreenshotMode: function() {
      _.each(this.widgets, function(widget) {
        widget.render().start();
      });
    },

    createWidgetsForCurrentViewport: function() {
      // удаляем все прежние виджеты, если есть
      this.destroyAllWidgets(['video']);

      // кешируем фоновый и фиксированные виджеты, чтобы потом иметь к ним быстрый доступ
      this.BGWidget = null;
      this.fixedWidgets = [];

      var viewport = this.pageViewport,
        widgets = [],
        widgetsViewportData = [],
        minZ = 99999,
        page = this,
        self = this;

      _.each(
        this.widgetsData,
        function(widgetData) {
          var widgetViewportData = self.getWidgetViewportData(widgetData, viewport);

          // Если это анимация, которая должна проигрываться один раз одному пользователю, и она уже была проиграна, то спрячем этот виджет
          var animationExpired = !this.mag.isPreview && AnimationUtils.hasExpired(widgetViewportData);
          if (!Widgets[widgetViewportData.type] || widgetViewportData.hidden || animationExpired) return;

          // получаем диапазон зиндексов в котором живут обычные виджеты
          if (!widgetViewportData.fixed_position && widgetViewportData.z && widgetViewportData.type != 'background') {
            minZ = Math.min(minZ, widgetViewportData.z);
          }

          widgetsViewportData.push(widgetViewportData);
        }.bind(this)
      );

      // не нашли ни одного обычного виджета, все что есть фикседы (ну или вообще нет виджетов)
      // ставим minZ в 0 для того чтобы все фиксед виджеты попали в fixedPositionContainerTop на девайсах
      // критично для андроида, поскольку нижний контейнер для фикседов не прокликивается, только верхний
      // а если у нас нет нормальных виджетов то получается все фикседы попадают в нижний и кликнуть на них нельзя https://trello.com/c/mcKNhP5p/310-android
      if (minZ == 99999) {
        minZ = 0;
      }

      _.each(
        widgetsViewportData,
        function(widgetViewportData) {
          // для фикседов смотрим, если он ниже всех обычных виджетов страницы,
          // тогда кидаем его в нижний контейнер фикседов
          // если же есть хоть один виджет на странице с зиндексом меньшим, тогда считаем, что виджет должен быть поверх всего
          // ВАЖНО! Для гориз. вьювера десктопа все фикседы кидаются в один контейнер (нижний), т.к. верхним должен оставаться всегда контейнер
          // основного контента и ловить события скролла. При этом нижние и верхние фикседы располагаются исключительно за счет своего
          // собственного z-index
          // На девайсах и стики вьювере контейнеры должны располагаться именно в правильном порядке: (нижие фикседы - контент - верхние фиксеты)
          // position: sticky заставляет контейнеры вести себя как static, поэтому сквозные z-index у всех виджетов уже не работают
          // И в тики вьювере верхние фикседы действительно попадают в верхний контейнер
          // см. mag.less - там слоям проставлется реальны порядок z-index
          var isScaledLayoutTransform = Scale.isAllowed() && Scale.isTransform();
          if (
            widgetViewportData.fixed_position &&
            (widgetViewportData.z < minZ ||
              (Device.isDesktop && !this.mag.isStickyVerticalViewer && !isScaledLayoutTransform))
          ) {
            widgetViewportData.$fixedContainer = self.$fixedPositionContainer;
          } else {
            widgetViewportData.$fixedContainer = self.$fixedPositionContainerTop;
          }

          // Перед созданием виджета нужно посмотреть, удалили мы его или нет (например видео виджеты не удаляем)
          // если не удалили, используем уже созданную копию
          var createdWidget = null;
          if (this.widgets && this.widgets.length) {
            createdWidget = this.widgets.find(function(w) {
              return w ? w._id === widgetViewportData._id : false;
            });
          }

          // Создается виджет заданного типа с заданными параметрами
          var widget = createdWidget || new Widgets[widgetViewportData.type](widgetViewportData, page);

          // если виджет видео, значит мы его не удаляли, поэтому нам надо обновить его стили и перерисовать
          if (widget.type === 'video') {
            widget.updateWidgetData(widgetViewportData, page);
            widget.applyBoxStyle();

            widget.updateVideoFrameSize(widgetViewportData.w, widgetViewportData.h);
          }

          // кешируем фоновый и фиксированные виджеты, чтобы потом иметь к ним быстрый доступ
          if (widget.type == 'background') {
            self.BGWidget = widget;
            self.hasVideoBG = widget.hasVideoBG();
          }

          // оставить только те виджеты, которые либо не имеют валидаторов, либо содержат валидные данные
          if (widget.isValid()) {
            if (widget.fixed_position) self.fixedWidgets.push(widget);
            widgets.push(widget);
          }
        }.bind(this)
      );

      // создаем контроллер анимаций который найдет все  группы анимаций и создаст для них управляющие объекты
      this.animations = new Animations({
        page: this,
        widgets: widgets,
      });

      // сразу сортируем виджеты в порядке сверху-вниз
      // чтобы именно в таком порядке их засовывать в пачки прелоадинга
      this.widgets = _.sortBy(widgets, 'y');

      // запускаем опрос виджетов на предмет их загруженности
      this.signalOnLoading();

      // это для шрифтов текстового виджета (для лоадинга в режиме скриншотера)
      this._onFontsLoad = [];
      this._widgetsWithFontsToLoad = [];
      this._fontLoaderCalled = 0;
      // обязательно не берем в расчет виджеты кнопки с tp: 'icon',
      // они не будут запускать ожидание загрузки шрифта в скриншотере,
      // если посчитать их тут, то this.onAllFontsLoad не выстрелит
      // и скриншот не сделается.
      this._widgetsWithFontsCount = _.reduce(
        this.widgets,
        function(cnt, w) {
          return cnt + (w.hasFontsToLoad() ? 1 : 0);
        },
        0
      );

      this._widgetsWithLoadAnimation = _.reduce(
        this.widgets,
        function(cnt, w) {
          return cnt + (w.hasLoadAnimation() ? 1 : 0);
        },
        0
      );

      this.hasFixedWidgets = !!this.fixedWidgets.length;

      // Со включенным scale layout, если для масштабирования страницы используется transform (как в FF)
      // будем разруливать верхние и нижние фикседы как на мобильных, помещая в верхний и нижний фиксед-контейнер.
      // (В остальных браузерах масштабируется с css zoom, который не создаёт stacking-контекста и проблем с порядком фикседов и не-фикседов)
      var isScaledLayoutTransform = Scale.isAllowed() && Scale.isTransform();
      // если есть фикседы то ставим либо старое поведение для них (все в одном контейнере, без кинетики)
      // либо новое поведение (в двух контейнерах, кинетика, могут быть либо снизу всех либо над всеми обычными виджетами)
      this.$el
        .removeClass('page-with-fixeds-desktop page-with-fixeds-sticky-or-mobile')
        .toggleClass('page-with-fixeds-desktop', this.hasFixedWidgets && Device.isDesktop)
        .toggleClass(
          'page-with-fixeds-sticky-or-mobile',
          !!(this.hasFixedWidgets && (this.isStickyVerticalViewer || !Device.isDesktop || isScaledLayoutTransform))
        );

      if (this.hasFixedWidgets && !this.retargetScroll && this.viewerType == 'horizontal' && Device.isDesktop) {
        this.retargetScroll =
          RetargetMouseScroll && RetargetMouseScroll(this.$fixedPositionContainer.get(0), this.$scrollWrapper.get(0));
      }

      this.lastViewportUsedForWidgetsCreation = viewport;
    },

    // по переданным данным виджета и вьюпорту возвращает данные виджета в режиме этого вьюпорта
    getWidgetViewportData: function(widgetData, viewport) {
      // клонируем настройки вьюпорта для дефолтного вьюпорта
      // слабое место - клонируем только первый уровень вложенности
      // а клонировать надо, poor widgets design - многие виджеты портят переданные данные
      // а нам надо их сохранить потому что потом они нам будут нужны для переключения между вьюпортами
      var widgetViewportData = _.clone(widgetData);
      var viewportDefaults = {};
      var opts = {};
      var uniqueButtonFields = [
        'text_w',
        'text_h',
        'em_w',
        'anchor_link_pos',
        'font-size',
        'font-style',
        'font-weight',
        'font-family',
        'color',
        'color-opacity',
        'letter-spacing',
        'hover-color',
        'hover-color-opacity',
        'current-font-size',
        'current-font-style',
        'current-font-weight',
        'current-font-family',
        'current-color',
        'current-color-opacity',
        'current-letter-spacing',
        'background-color',
        'background-color-opacity',
        'hover-background-color',
        'hover-background-color-opacity',
        'current-background-color',
        'current-background-color-opacity',
        'border-radius',
        'border-width',
        'border-color',
        'border-color-opacity',
        'hover-border-width',
        'hover-border-color',
        'hover-border-color-opacity',
        'current-border-radius',
        'current-border-width',
        'current-border-color',
        'current-border-color-opacity',
      ];

      // если выбран мобильный вьюпорт и есть настройки виджета для него
      // тогда расширяем его настройками дефолтный вьюпорт
      // слабое место - экстендим только первый уровень вложенности
      if (viewport != 'default' && widgetData['viewport_' + viewport]) {
        _.each(Viewports.viewport_fields[widgetData.type] || Viewports.viewport_fields_common, function(field) {
          if (widgetData.type == 'shape' && (field == 'borders' || field == 'weight' || field == 'bg_color')) {
            return; // костыль для старых виджетов shape, где borders и weights и bg_color было сквозным для всех вьюпортов
          }

          if (widgetData.type == 'background' && field == 'picture') return; // костыль для старых виджетов Background, где picture было сквозным для всех вьюпортов

          viewportDefaults[field] = undefined;
          // Не забудем проставить undefined в специальные свойства типа _hidden для глобальных виджетов
          if (GlobalWidgets.isGlobalKey(field)) {
            var globalField = GlobalWidgets.getGlobalPrefixedKey(field);
            viewportDefaults[globalField] = undefined;
          }
          // // Если поле не найдено в данных вьюпорта, но должно там быть, то берем значение этого поля
          // // из дефолтного вьюпорта.
          // // Пока только для кнопок. Сделано, потому что из-за бага терялись стили кнопок в мобильных вьюпортах.
          // // Аналогичный код есть в widget.js:parse в конструкторе
          viewportDefaults[field] =
            widgetData.type === 'button' &&
            !_.has(widgetData['viewport_' + viewport], field) &&
            uniqueButtonFields.includes(field)
              ? widgetData[field]
              : undefined;
        });

        // Иногда в данных вьюпорта типа viewport_phone_portrait оказывается _id, ещё и не совпадающий с основным.
        _.extend(
          widgetViewportData,
          viewportDefaults,
          _.omit(widgetData['viewport_' + viewport], ['_id', 'modelType'])
        );
      }

      // Устанавливаем уникальные для страницы свойства глобального виджета
      if (widgetData.is_global) {
        opts.props = widgetData.is_above ? ['hidden'] : null; // Для отвязанных виджетов восстанавливаем только уникальные свойство hidden
        GlobalWidgets.fillUniqueValues(widgetViewportData, this._id, opts);
      }

      return widgetViewportData;
    },

    getWidgetById: function(id) {
      return _.findWhere(this.widgets, { _id: id });
    },

    getWidgetDataById: function(id) {
      return _.findWhere(this.widgetsData, { _id: id });
    },

    signalOnLoading: function() {
      var WIDGET_FORCE_READY_TIMEOUT = 20000;

      var widgetsToWait = this.widgets.length,
        self = this;

      // Крайне важно, чтобы для скриншотера страница рано или поздно сказала о своей загрузке
      // По-этому даем виджетам максимальный таймаут самим сказать, что они загружены
      // Иначе мы скажем это за них
      var widgetReadyTimeouts = {};

      _.each(
        this.widgets,
        function(widget) {
          if (RM.screenshot) {
            widgetReadyTimeouts[widget._id] = setTimeout(function() {
              widget.widgetIsLoaded();
            }, WIDGET_FORCE_READY_TIMEOUT);
          }
          widget.on('loaded', onWidgetLoad, widget);
        },
        this
      );

      if (this.BGWidget && this.hasVideoBG) {
        this.BGWidget.on('videoBGStarted', onVideoBGStarted, this.BGWidget);
      }

      // по идее не должно быть так что виджетов нет, но мало ли на странице все виджеты невалидные были или еще чего и не были созданы
      if (widgetsToWait == 0) this.fireReadyTimeout = setTimeout(fireReady, 200);

      function onWidgetLoad() {
        widgetsToWait--;
        clearTimeout(widgetReadyTimeouts[this._id]);

        if (this.type == 'background') self.destroyLQBackground({ animate: true });

        if (this.hasLoadAnimation()) {
          self._widgetsWithLoadAnimation--;
          if (!self._widgetsWithLoadAnimation) {
            self.loadAnimationsReady = true;
            self.trigger('loadAnimationsReady');
          }
        }

        if (!widgetsToWait) self.fireReadyTimeout = setTimeout(fireReady, 200);
        self.trigger('loaded' + this._id);
      }

      function onVideoBGStarted() {
        self.videoBGStarted = true;

        _.delay(function() {
          self.trigger('videoBGStarted');
        }, 1300);
      }

      function fireReady() {
        self.loaded = true;
        self.trigger('pageLoaded');
      }
    },

    getFontsVariations: function(widgets, excludeUnusedDefault) {
      var fonts = TextUtils.getUsedFontsFromWidgetsModels({
        includeCustom: true,
        customList: _.filter(this.mag.edit_params.fonts, { provider: 'custom' }),
        models: widgets,
        excludeUnusedDefault: excludeUnusedDefault,
        activeViewports: [this.getPageViewport()],
      });

      var variationsToLoad = [];
      _.each(fonts, function(font) {
        for (var i = 0; i < font.used_variations.length; i++) {
          variationsToLoad.push({
            fontFamily: font.css_name,
            fontWeight: (font.used_variations[i].substr(1, 1) - 0) * 100,
            fontStyle: font.used_variations[i].substr(0, 1) == 'n' ? 'normal' : 'italic',
          });
        }
      });
      return variationsToLoad;
    },

    /**
     * Добавляет шрифты, загрузки которых нужно дождаться. Не загружает шрифты.
     * Начинает проверять только когда опросил все виджеты со шрифтами на странице.
     * Это происходит не сразу, потому что виджеты рендерятся пачками, и следующая пачка ждёт загрузки (в основном, загрузки изображений) текущей
     * в пачки группируются по экранам (если страница длинная или экран короткий пачек может быть очень много).
     * @param {Backbone.View} widget
     * @param {Function} callback
     */
    addFontsToLoad: function(widget, callback) {
      this._widgetsWithFontsToLoad.push(widget);
      this._onFontsLoad.push(callback);
      this._fontLoaderCalled++;

      if (this._fontLoaderCalled == this._widgetsWithFontsCount) {
        var variationsToLoad = this.getFontsVariations(this._widgetsWithFontsToLoad);

        if (_.isEmpty(variationsToLoad)) {
          this.onAllFontsLoad();
          return;
        }

        this._fontsToLoadLeft = variationsToLoad.length;

        _.each(
          variationsToLoad,
          _.bind(function(v) {
            TextUtils.exactWaitForFontLoad(v.fontFamily, v.fontWeight, v.fontStyle, this.onOneFontLoad);
          }, this)
        );
      }
    },

    onOneFontLoad: function(res, family, weight, style) {
      this._fontsToLoadLeft--;

      if (this._fontsToLoadLeft == 0) {
        _.delay(this.onAllFontsLoad, 500);
      }
    },

    onAllFontsLoad: function() {
      _.each(this._onFontsLoad, function(cb) {
        cb && cb();
      });
    },

    // проверяет изменился ли вьюпорт страницы или нет в связи со сменой вьюпорта мэга
    // и если изменился то меняет у себя это свойство
    updateViewport: function() {
      var newPageViewport = this.getPageViewport(),
        oldPageViewport = this.pageViewport;

      if (newPageViewport != oldPageViewport) {
        this.pageViewport = newPageViewport;

        this.createLQBackground();

        // создаем по новой, но только для видимых страниц
        // остальные сами пересоздадут, если увидят что при их показе у них устаревший вьюпорт
        if (this.shown) {
          this.createWidgetsForCurrentViewport();
          if (this.started) {
            this.started = false;
            this.start();
          }
        }
      }
    },

    // ожидает конца анимации перемещения страницы
    // навешивать надо до трансформа
    waitForAnimationEnd: function(cb) {
      this.resetWaitHook = Utils.waitForTransitionEnd(
        this.$el,
        this.mag.getPageTransitionTime() + 1000,
        'transform',
        cb
      );
    },

    // сбросить ожидание окончания анимации без выполнения колбека ожидания
    resetWaitForAnimationEnd: function() {
      this.resetWaitHook && this.resetWaitHook();
    },

    backToTop: function() {
      this.scrollTo(0);
    },

    scrollTo: function(y) {
      this.$scrollWrapper.finish();
      this.$scrollWrapper && this.$scrollWrapper.animate({ scrollTop: y }, 500);
    },

    focusPage: function() {
      if (!Device.isDesktop) return;

      // никакого фокусирования при вертикалном виде
      // во-первых оно там не нужно - скрол-то глобальный, а фокусирование делалось для локального скрола стрелками клавиатуры
      // во-вторых оно все портит, посокльку страница смещена транслейтом вверх например, а при фокусе глобальный скрол вытягивает ее в область видимости
      if (this.viewerType == 'vertical') return;

      // временный костыль
      // все проблеммы со сдвигом страниц возникают тогда, когда мы пытаемся сфокусировать страницу
      // которая сейчас анимируется транзишеном
      // пока просто запретим, позже буду думать как фиксить правильнее
      if (this.$el[0].getBoundingClientRect().left != 0) return;

      this.$content.focus();

      // Если быстро долистать до final page, то весь контент сдвигается вверх из-за анимации scale
      // последней страницы. Только в webkit-браузерах.
      // https://trello.com/c/vcd1dORx/279-mac-enpage-https-test-readymag-com-u33457269-woumpah
      this.mag.$el.scrollTop(0);
    },

    markAsLast: function() {
      this.lastPage = true;
      this.$el.addClass('last');
    },

    isLastPage: function() {
      return !!this.lastPage;
    },

    isBottomArrowVisible: function() {
      return !this.wasScrolled && this.scrollable;
    },

    scrollPageALittle: function() {
      this.$scrollWrapper.animate({ scrollTop: $(window).height() / 2 }, 500);
    },

    // this.mag.currentViwport хранит имя вьюпорта который наиболее точно подходит для отображения мега на текущем устройстве
    // но для каждой конкретной страницы могут быть ситуации когда этот вьюпорт не настраивался
    // и поэтому мы должны решить данные с какого вьюпорта мы будем показывать взамен
    // вопрос этот сложный, пока я решил так - если данных по вьюпорту нет, значит используем дефолтный (десктопный)
    getPageViewport: function() {
      var magViewport = this.mag.viewport,
        self = this;

      // если мэг использует дефолтный (десктопный) вьюпорт, тогда его и используем для отображения страницы, тут думать не надо
      if (magViewport == 'default' || RM.screenshot) return 'default';

      // если мэг использует вьюпорт, который для данной страницы не настраивался (отключен)
      // тогда пытаемся найти наиболее подходящий из включенных
      // сейчас алгоритм простой: выбираем среди них ближайший по ширине к ширине девайса (похожий код также в mag.js getMagViewport)
      // потом возможно усложним алго
      if (!this['viewport_' + magViewport] || !this['viewport_' + magViewport].enabled) {
        var deviceWidth = _.findWhere(Viewports.viewports, { name: magViewport }).width,
          closestViewportIndex = _.reduce(
            Viewports.viewports,
            function(memo, obj, ind) {
              // если вьюпорт выключен тогда его не проверяем на ширину
              // но при этом смотрим что то не дефолтный вьюпорт, он вегда включен, даже если нет объекта viewport_default
              if (obj.name != 'default' && (!self['viewport_' + obj.name] || !self['viewport_' + obj.name].enabled)) {
                return memo;
              }

              if (Math.abs(obj.width - deviceWidth) <= Math.abs(Viewports.viewports[memo].width - deviceWidth))
                return ind;
              else return memo;
            },
            0
          );

        return Viewports.viewports[closestViewportIndex].name;
      } else {
        // ну а если вьюпорт для данной страницы включен, тогда используем его
        return magViewport;
      }
    },

    // если страница видима - тогда обновляем размеры
    onResize: function(params) {
      params = params || {};

      if (!this.shown && this.viewerType == 'horizontal') return;

      // рассчитываем размеры текущей страницы с учетом выбраного для ее отображения вьюпорта
      // и масштабирования, если вьюпорт по ширине не совпадает с шириной устройства

      var containerSize = this.mag.getContainerSizeCached(), // берем кешированную версию размеров контейнера
        // ширина вьюпорта для pageViewport
        width = this.mag.getViewportSetting('width', this.pageViewport),
        // ширина девайса или в режиме превью констрктора - области виртуального девайса
        // причем не для pageViewport, а для magViewport - т.е. вьюпорта-устройства выбранного для отображения всего мэга,
        // и не важно что страница может использовать дефолтный отмасштабированный, важно в каком виртуальном устройстве мы кажем весь мег
        scale = this.mag.getScale(this.pageViewport),
        height = (this['viewport_' + this.pageViewport] && this['viewport_' + this.pageViewport].height) || this.height; // "или" потому, что для дефолтного вьюпорта нет viewport_default.height он лежит в корне в this.height
      var pageDimensions = {
        width: width,
        height: height,
        scaledWidth: Math.round(width * scale),
        scaledHeight: Math.round(height * scale),
        scale: scale,
      };

      var w = Math.min(pageDimensions.scaledWidth, containerSize.width),
        h = pageDimensions.scaledHeight,
        top = Math.max((containerSize.height - h) / 2, 0),
        left = Math.max((containerSize.width - w) / 2, 0);

      var pageCss = {
        width: pageDimensions.width, // здесь указываем реальные, неотскейленые размеры
        height: pageDimensions.height,
        top: top,
        left: left,
      };

      // Масштабируем основной контент страницы. Контейнер с фикседами не масштабируем,
      // потому что каждый фиксед-виджет масштабируется по отдельности
      if (scale >= 1 && Scale.isAllowed() && Scale.isZoom()) {
        pageCss.zoom = scale;
        pageCss.left = 0;
      }

      this.$content.removeAttr('style');
      this.$content.css(pageCss);

      this.contentPosition = {
        left: left,
        top: top,
      };

      if (Scale.normalize(scale, 'transform') === 1) {
        // проверка с определенной точностью, плавающая запятая как-никак
        // отменяем скейл страницы
        Utils.applyTransform(this.$content, '');
        pageDimensions.scale = 1; // приводим к нормальному виду
      } else {
        // применяем скейл страницы чтобы вписать по ширине во вьюпорт
        Utils.applyTransform(this.$content, 'scale(' + pageDimensions.scale + ')');
      }

      this.contentHeight = pageDimensions.height;

      this.pageHeight = Math.max(containerSize.height, pageDimensions.scaledHeight);

      this.pageBgHeight = containerSize.height;

      this.pageWidth = pageDimensions.width; // Используется для позиционирования full-width виджетов

      if (this.viewerType == 'vertical' && this.isStickyVerticalViewer) {
        this.$el.css({
          height: this.pageHeight,
          top: params.absolutePosition,
        });
      }

      this.scale = scale;

      this.$contentBounds.css({
        width: containerSize.width,
        height: this.pageHeight, // обрезаем контент если он не влазит по размерам окна и страницы
      });

      // если у нас сменился скейл надо все анимации проапдейтить, с фикседами там заморочки
      if (this.prevScale != undefined && this.prevScale != this.scale) {
        // обязательно до триггера resize
        // чтобы сначала контроллер анимаций подтюнил bbox анимаций, а затем уже сами виджеты поправились внутри этих контейнеров
        this.animations && this.animations.updateTimelines();

        if (Scale.isOn(this.scale) && this.animations) {
          this.animations.resetStarted();
          this.animations.start.__debounced();
        }
      }

      // обязательно до триггера resize
      // чтобы сначала контроллер анимаций подтюнил bbox анимаций, а затем уже сами виджеты поправились внутри этих контейнеров
      this.animations && this.animations.onResize();

      // это для бг виджета и растягиваемых виджетов (и фикседов когда у страницы есть скейл)
      this.trigger('resize', {
        containerSize: containerSize,
        contentPosition: this.contentPosition,
      });

      // помечаем страницу как "помещающуюся на экране не полностью",
      // чтобы потом показывать для нее нижнюю стрелку навигации
      this.scrollable = h - this.mag.SCROLLABLE_TRESHOLD > containerSize.height;

      this.mag.recalcBottomArrowState(this);

      // сохраняем координаты видимой области на экране
      // в координатах виджетов (т.е. учитываем положение page-content-container и масштабирование)
      // нам это надо чтоюы быстро выбрать виджеты которые сейчас должны бить видимы на экране
      // с учетом текущего скрола, скейла и положения контейнера с контентом страницы
      // сохраненная тут переменная скейла также используется в slideshow-player.js → getPicsTransitionTime
      this.visibleWidgetsCoords = this.visibleWidgetsCoords || {};
      this.visibleWidgetsCoords.top = -top;
      this.visibleWidgetsCoords.bottom = -top + containerSize.height;
      this.visibleWidgetsCoords.scale = scale;

      this.resizeLQBackground();

      this.prevScale = this.scale;

      return this.pageHeight;
    },

    // Вызывается при скроле окна
    onScroll: function() {
      // помечаем страницу как проскроленую, чтобы потом не показывать для нее нижнюю стрелку навигации
      this.wasScrolled = true;

      this.mag.recalcBottomArrowState(this);

      // сохраняем координаты видимой области на экране
      // (для вертикального вьювера это делается в функции scrollOnVerticalMode)
      // в координатах виджетов (т.е. учитываем положение page-content-container и масштабирование)
      // нам это надо чтоюы быстро выбрать виджеты которые сейчас должны бить видимы на экране
      // с учетом текущего скрола, скейла и положения контейнера с контентом страницы
      this.visibleWidgetsCoords = this.visibleWidgetsCoords || {};
      this.visibleWidgetsCoords.scrollTop = this.$scrollWrapper.scrollTop();

      this.mag.onCurrentPageScroll(this, this.visibleWidgetsCoords.scrollTop);

      this.animations && this.animations.onScroll();
    },

    isCurrent: function() {
      return this.num === this.mag.currentPage.num;
    },

    createLQBackground: function() {
      if (RM.screenshot) return;

      // получаем данные БГ виджета в режиме текущего вьюпорта
      var data = this.getWidgetViewportData(_.findWhere(this.widgetsData, { type: 'background' }), this.pageViewport);

      if (this.LQBG) {
        // смотрим, если фон тот же тогда просто отресайзим текущий и ничего больше
        if (
          (data.selectedType == 'picture' &&
            this.LQBG.selectedType == 'picture' &&
            data.picture &&
            this.LQBG.picture &&
            data.picture.poorUrl == this.LQBG.picture.poorUrl) ||
          (data.selectedType == 'video' &&
            this.LQBG.selectedType == 'video' &&
            data.video &&
            this.LQBG.video &&
            data.video.thumbnail_url == this.LQBG.video.thumbnail_url)
        ) {
          this.resizeLQBackground();
          return;
        } else {
          this.destroyLQBackground({ animate: false });
        }
      }

      // задаем цвет для всего контейнера фонов
      // чтобы при быстрой прокрутке страниц у нас были все фоны, а то пока настоящий бг виджет загрузится еще (он же тоже встает в очередь хоть и с приоритетом, даже если у него только прсотой цвет)
      this.$fixedBgContainer.css('background-color', '#' + data.color);

      // если бг виджет в текущем вьюпорте настроен на картинку или видео (т.е. не просто тупой цвет)
      // тогда создаем LQBackground
      if (
        (data.picture && data.selectedType == 'picture' && data.picture.poorUrl) ||
        (data.video && data.selectedType == 'video' && data.video.thumbnail_url)
      ) {
        this.LQBG = _.extend({}, data);

        var template = templates['template-viewer-widget-background'];

        this.LQBG.$el = $(template({ data: data }));

        this.LQBG.$el.addClass('low-quality').appendTo(this.$fixedBgContainer);

        if (this.LQBG.selectedType == 'picture') {
          // грузить надо через лоадер, поскольку страница может быть display:none, некоторые браузеры (особенно мобильные) не будут ничего грузить на невидимых страницах если просто поставить background-image
          var $poorImgLoader = $('<img/>');

          $poorImgLoader
            .on(
              'load',
              _.bind(function() {
                // проверка что this.LQBG еще существует
                this.LQBG &&
                  this.LQBG.$el.find('.picture-background').css({
                    'background-image': 'url(' + this.LQBG.picture.poorUrl + ')',
                  });
              }, this)
            )
            .attr('src', this.LQBG.picture.poorUrl);
        }

        if (this.LQBG.selectedType == 'video') {
          // ie впадает в ступор если мы грузим контент с http
          // поэтому пробуем сразу и один раз грузануть его с https, если его там нет - значит нет
          // var thumbnail_url = this.LQBG.video.thumbnail_url.replace('https://', 'http://'),

          var thumbnail_url = this.LQBG.video.thumbnail_url.replace('http://', 'https://'),
            $poster = $('<img/>')
              .addClass('poster')
              .css('opacity', 0)
              .appendTo(this.LQBG.$el.find('.video-background .video-container'));

          $poster
            .on(
              'load',
              _.bind(function() {
                // сбрасываем стили width и height
                // они могут проставиться в функии реагирующей на ресайз до того как картинка загрузится
                // это происходит например при переключении вьюпортов в превью
                $poster.css({ width: '', height: '' }).css('opacity', 1);

                var w = $poster[0].width,
                  h = $poster[0].height;

                // для старых видео сохраняем aspect_poster
                // он нам еще пригодится в onresize low-quality виджета
                // проверка что this.LQBG еще существует
                if (this.LQBG && !this.LQBG.video.aspect_poster) this.LQBG.video.aspect_poster = w / h;

                this.resizeLQBackground();
              }, this)
            )
            .attr('src', thumbnail_url);
        }
      }
    },

    resizeLQBackground: function() {
      if (RM.screenshot) return;

      if (!this.LQBG) return;

      // кастомный ресайз только для постера видео виджета
      if (this.LQBG.selectedType != 'video') return;

      VideoUtils.setVideoPosition({
        mag: this.mag,
        $container: this.LQBG.$el.find('.video-background .video-container'),
        $media: this.LQBG.$el.find('.video-background .video-container .poster'),
        provider: this.LQBG.video.provider_name,
        aspect_poster: this.LQBG.video.aspect_poster,
        aspect_real: this.LQBG.video.aspect_real,
        controls_remove: true,
      });
    },

    destroyLQBackground: function(params) {
      if (RM.screenshot) return;

      if (!this.LQBG) return;

      params = params || {};

      // анимация (если задана) перехода из плохого фона в хороший (а по факту просто фейд плохого, а потом его удаление)
      // должна работать только на видимых страницах
      // а также анимация работает только для картинок
      // для видео постеров пока не надо, потому что то что мы прелоадим в точности соответствует тому, что
      // видео фон сам выставляет потом в качестве постера (постеров плохого качества мы не делаем)
      // поэтому там анимация смнеы не нужна - картинки идентичны
      params.animate = params.animate && this.LQBG.selectedType == 'picture' && this.started;

      var $tmp = this.LQBG.$el;

      // сразу помечаем что плохого фона больше нет (то что он удаляется с анимацией уже ни кого не должно волновать)
      this.LQBG = null;

      if (params.animate) {
        $tmp.css('opacity', 0); // тут работают транзишены
        setTimeout(function() {
          $tmp.remove();
        }, 400);
      } else {
        $tmp.remove();
      }
    },

    // Фикс-костыль.
    // Не понятно почему Safari 9 начал смещать контейнер при загрузке страницы
    // https://trello.com/c/kYfQYGDK/261--
    fixSafariScroll: function(e) {
      if (!Modernizr.safari || !Device.isDesktop) {
        return;
      }

      var shift = this.$scrollWrapper.scrollTop();

      if (shift !== 0) {
        this.$scrollWrapper.scrollTop(0);
      }
    },
  },
  {
    // Статические методы

    /**
     * Возвращает индекс экрана для виджета
     * @param {Object} widget
     * @param {Number} top Координата верха экрана
     * @param {Number} inverseHeight Инвертированная высота экрана, например: 1 / 799
     * @param {Boolean} withImmediatePack Флаг. Если передан, возвращать Infinity для виджетов, которые могут рендерится сразу,
     * не важно на каком экране они геометрически находятся
     * @returns {*} (Infinity — приоритетный, 0 — первый, 1 — второй, -1 — выше первого экрана)
     */
    getScreenIndex: function(widget, top, inverseHeight, withImmediatePack) {
      var screenIndex;
      // st - экран в который попадает начало виджета, его верх (повороты мы тут не учитываем чтобы не усложнять, не велика беда если виджет попадет не в тот экран, все равно все будет загружено)
      // ed - экран в который попадает конец виджета, его низ
      var start = Math.floor((widget.y - top) * inverseHeight),
        end = Math.ceil((widget.y + widget.h - top) * inverseHeight) - 1;

      // Если виджет можно рендерить сразу, пропустим их — они в специальной «немедленной» пачке
      if (withImmediatePack && widget.canRenderImmediately()) {
        // Здесь нельзя присваивать screenInd = -1, потому что st виджета тоже может быть равен -1,
        // и в результате проверки screenInd ниже этот виджет может не попасть никуда (в этом случае не вызовется pageLoad)
        screenIndex = Infinity;
      } else if (widget.hasLoadAnimation()) {
        // Виджеты с load анимациями должны быть в одной пачке
        // из-за того что анимации запускаются все разом, дожидаясь рендера всех виджетов с load анимациями
        // получается эффект что не запускаются анимация load пока не прорисуются все виджеты
        screenIndex = 0;
      } else if (start <= 0 && end >= 0) {
        // первый экран самый приоритетный
        screenIndex = 0;
      } else if (start > 0) {
        // если виджет идет ниже текущего экрана, относим его к первому экрану вниз с которым виджет пересекается
        screenIndex = start;
      } else if (end < 0) {
        // если виджет идет выше текущего экрана, относим его к первому экрану вниз с которым виджет пересекается
        screenIndex = end;
      }

      return {
        start: start,
        end: end,
        index: screenIndex,
      };
    },
  }
);

export default Page;
