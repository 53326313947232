/**
 * контрол настроек подсказки хотспота.
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import PreloadDesignImages from '../../common/preload-design-images';
import { Utils } from '../../common/utils';
import Colorbox from '../helpers/colorbox';
import templates from '../../../templates/constructor/controls/hotspot_tip_settings.tpl';

const HotspotTipSettings = ControlClass.extend({
  name: 'hotspot_tip_settings', // должно совпадать с классом вьюхи.

  className: 'control hotspot_tip_settings',

  saveOnDeselect: true,
  saveOnDestroy: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-hotspot-tip-settings'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;

    this.apply_edit_params_visual_state.__debounced = _.debounce(this.apply_edit_params_visual_state, 20);

    this.bind_model_events();
  },

  bindLogic: function() {
    // кэшируем поиск часто используемых элементов.
    this.$colorbox_container = this.$('.colorbox_container');
    this.$border_radius_param = this.$panel.find('.edit-wrapper .edit-item[data-field="tip_border-radius"]');
    this.$shadow_param = this.$panel.find('.edit-wrapper .edit-item[data-field="tip_box-shadow"]');
    this.$position_param = this.$panel.find('.edit-item[data-field="tip_pos"]');

    // при клике в контейнер панельки,
    // скрываем все выпадалки (Колорбокс, Фонт селектор.)
    this.$panel.on('click', '.edit-wrapper', this.close_all_edit_popups);

    // Position.
    this.$position_param.find('.position').on('click', this.on_position_click);

    // инициализация, рендеринг Колорбокса для панели Контрола настроек.
    // навешивание слушателей на него.
    // будет использоваться для изменения параметров Кнопки, связанных с выбором цвета.
    this.$panel.on('click', '.edit-wrapper .color-click-area', this.on_colorbox_show_click);
    this.colorbox = new Colorbox({ $parent: this.$colorbox_container, type: 'small' });
    this.colorbox.on('colorchange', this.color_opacity_changed);
    this.colorbox.on('opacitychange', this.color_opacity_changed);

    // Radius. инициализация плагина для инпута.
    this.border_radius_edit_param_input = $(this.$border_radius_param.find('input'))
      .RMNumericInput({
        min: 0,
        max: 999,
        mouseSpeed: 4,
        onChange: this.on_edit_input_changed,
      })
      .data('changeValue');

    this.shadow_switcher = $(this.$shadow_param.find('.switcher'))
      .RMSwitcher(
        {
          state: this.model.get('tip_box-shadow'),
          width: 50,
          height: 28,
          'text-size-0': 12,
          'text-size-1': 12,
          'color-0': '#0078ff',
        },
        function(state) {
          // отложено для завершения анимации переключения свитчера.
          _.delay(
            function() {
              this.model.set('tip_box-shadow', state);
            }.bind(this),
            300
          );
        }.bind(this)
      )
      .data('switcher');

    // изначально применяем визуальное состояние панели.
    this.apply_edit_params_visual_state();
    this.apply_tip_position_param_visual_state();

    this.bind_model_events();
  },

  unBindLogic: function() {},

  on_position_click: function(e) {
    var position = $(e.currentTarget).data('position');

    this.model.set('tip_pos', position);
  },

  on_edit_input_changed: function($input, new_num) {
    var editParam = $input.closest('.edit-item').attr('data-field'),
      setObject = {};

    setObject[editParam] = new_num;

    this.model.set(setObject);

    this.adjust_inputs_padding();
  },

  // добавляем экстра-отступ цифровым инпутам, когда одна цифра в значении,
  // чтобы не нарушать вертикальный ритм значений параметров.
  adjust_inputs_padding: function() {
    var inputs = ['tip_border-radius'];

    for (var i = 0; i < inputs.length; i++) {
      var $input = this.$panel.find('.edit-wrapper .edit-item[data-field="' + inputs[i] + '"] input'),
        val = $input.val();

      $input.toggleClass('extra-padding-for-one-symbol', val.length === 1);

      $input.toggleClass('extra-padding-for-two-symbols', val.length === 2);
    }
  },

  bind_model_events: function() {
    var params = ['tip_pos', 'tip_background-color', 'tip_background-color-opacity', 'tip_border-radius'];

    params.forEach(function(style, i, styles) {
      styles[i] = 'change:' + style;
    });

    params = params.join(' ');

    // при изменении какого-либо из аттрибутов настроек подсказки.
    this.listenTo(this.model, params, this.apply_edit_params_visual_state);

    this.listenTo(this.model, 'change:tip_pos', this.apply_tip_position_param_visual_state);
  },

  // перерисовка состояния всех параметров редактирования
  // в панели, отталкиваясь от значений, установленных в модель кнопки.
  apply_edit_params_visual_state: function() {
    var $parent = this.$panel.find('.edit-wrapper');

    // Color
    $parent
      .find('.edit-item[data-field="tip_background-color"] .color-circle')
      .css(
        'background-color',
        Utils.getRGBA(
          this.get_edit_value('tip_background-color'),
          this.get_edit_value('tip_background-color-opacity') / 100
        )
      );

    // Radius
    this.border_radius_edit_param_input(this.get_edit_value('tip_border-radius'), true);

    this.adjust_inputs_padding();
  },

  // перерисовает состояние параметра Position,
  // отталкиваясь от значения в модели.
  apply_tip_position_param_visual_state: function() {
    this.$position_param.find('.current').removeClass('current');

    this.$position_param.find('.' + this.model.get('tip_pos')).addClass('current');
  },

  get_edit_value: function(attr) {
    return this.model.get(attr);
  },

  // при клике по полю с цветом какого-либо параметра панели — показ\скрытие Колорбокса.
  on_colorbox_show_click: function(e) {
    var $item = $(e.currentTarget),
      field = $item.attr('data-field'),
      field_dop = $item.attr('data-field-dop'),
      fadeSpeed = 200;

    if (this.colorbox_visible) {
      // смотрим кликнули по полю для текущего Колорбокса или по другому.
      if (field == this.colorbox_for_field) {
        this.close_colorbox();
        return;
      } else {
        this.close_colorbox(true);
        fadeSpeed = 0;
      }
    }

    // скрываем все впадалки, показываем Колорбокс.
    this.close_all_edit_popups();
    this.colorbox_visible = true;

    // позиционируем колорбокс органично расположению параметра.
    this.$colorbox_container.css({
      top: $item.position().top + $item.height() + 'px',
    });

    this.$colorbox_container.fadeIn(fadeSpeed);

    // сохраняем для color_opacity_changed.
    this.colorbox_for_field = field;
    this.colorbox_for_field_dop = field_dop;

    // устанавливаем параметры в Колорбоксе.
    this.colorbox.setOpacity(this.get_edit_value(field_dop) / 100);
    this.colorbox.setColor(this.get_edit_value(field));
  },

  // закрывает Колорбокс.
  close_colorbox: function(noAnimation) {
    this.colorbox_visible = false;

    this.$colorbox_container.fadeOut(noAnimation ? 0 : 200);
  },

  // при изменении цвета-прозрачности через колорбокс.
  color_opacity_changed: function(r, g, b, a) {
    var model = _.clone(this.model.attributes),
      new_color = this.colorbox.rgb2hex([r, g, b]),
      new_color_opacity = a * 100,
      changeset = {};

    changeset[this.colorbox_for_field] = new_color;
    changeset[this.colorbox_for_field_dop] = new_color_opacity;

    this.model.set(changeset);
  },

  // расширяем метод клика по иконке Контрола.
  onClick: function() {
    if (this.selected) {
      if (this.colorbox_visible) {
        this.close_all_edit_popups();
        return;
      }
    }

    ControlClass.prototype.onClick.apply(this, arguments);
  },

  // закрыть все выпадалки.
  close_all_edit_popups: function(e) {
    if (e) {
      // ничего не делаем, если кликнули в:

      // любой параметр цвета.
      if ($(e.target).closest('.color-click-area').length > 0) return;
    }

    // скрываем Колорбокс.
    if (this.colorbox_visible) this.close_colorbox();
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-button_settings');
  },

  deselect: function() {
    this.close_all_edit_popups();

    ControlClass.prototype.deselect.apply(this, arguments);
  },

  /**
   * расширяем функцию которая решает поглощает контрол событие
   * или нет (обычно это событие deselect воркспейса).
   */
  canControlBeClosed: function() {
    // если у нас открыт контрол (показана панелька),
    // а в ней открыли колорбокс для какого-то параметра,
    // то при клике куда-либо снаружи панельки — закроется колорбокс,
    // а панель останется еще открытой.
    if (this.colorbox_visible) {
      this.close_all_edit_popups();
      return true;
    }

    return ControlClass.prototype.canControlBeClosed.apply(this, arguments);
  },

  // запускается при this.deselect() & this.destroy(),
  // т.к. у контрола стоят флаги saveOnDeselect: true & saveOnDestroy.
  // логика в control.js предварительно проверяет,
  // изменилась ли модель, чтобы не запускать this.save() лишний раз.
  save: function() {
    this.model.save();
  },

  /**
   * переопределено,
   * чтобы менять состояние Кнопки в Default при дестрое Контрола.
   * важно, т.к. дестрой Контрола происходит при переходе в Превью.
   */
  destroy: function(animation) {
    ControlClass.prototype.destroy.apply(this, arguments);
  },
});

export default HotspotTipSettings;
