/**
 * Виджет AddToCart
 */
import { isEnabled } from '../../common/flags';
import WidgetClass from '../widget';
import AddToCartWidget from '../../common/add-to-cart-widget';

var addToCartWidget = WidgetClass.extend({
  render: function() {
    this.makeBox('widget-add-to-cart');

    this.addToCartWidget = new AddToCartWidget({
      data: this,
      environment: 'viewer',
      $container: this.$el,
      eCommerceManager: this.mag.eCommerceManager,
    });

    this.addToCartWidget.render();
    this.addToCartWidget.recalcDimensions({ width: this.w, height: this.h });

    this.rendered = true;

    this.widgetIsLoaded();

    return this;
  },

  destroy: function() {
    this.addToCartWidget && this.addToCartWidget.destroy();
    this.addToCartWidget = null;

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default isEnabled('viewer.eCommerce') ? addToCartWidget : undefined;
