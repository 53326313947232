/**
 * Текстовый виджет
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import { Constants } from '../../common/utils';
import InitUtils from '../../common/init-utils';
import TextViewportsClass from '../../common/text-viewports';
import templates from '../../../templates/viewer/widgets/text.tpl';
import SoundCite from '@rm/soundcite';
import Device from '../../common/device';

const TextWidget = WidgetClass.extend({
  DO_NOT_WAIT_FOR_PRELOAD: true, // флаг что при прелоадинге виджетов ждать его загрузки не надо (но не для скриншотера)

  antialiasRotation: true,

  // достаточно версии прототипа
  // initialize: function(data, page) {},

  mailchimpMatcher: /__rm_mailchimp_(.+)/i,
  anchorRegexp: /__rm_anchor/i,
  shareRegexp: /^share\.(facebook|twitter|pinterest|gplus|linkedin|email)\.(mag|page)$/i,

  render: function() {
    this.makeBox('text');

    if (this.hasHoverAnimation) {
      this.$el.addClass('has-onhover-animation');
    }

    this.rendered = true;

    // обязательные дефолты, потому что для оптимизации жейсона пустые или дефолтные значения выпиливаются из него
    this.bg_color = this.bg_color == undefined ? 'ffffff' : this.bg_color;
    this.bg_opacity = this.bg_opacity == undefined ? 0 : this.bg_opacity;
    this.column_count = this.column_count == undefined ? 1 : this.column_count;
    this.column_gap = this.column_gap == undefined ? 16 : this.column_gap;

    var rbg = [
      parseInt(this.bg_color.substring(0, 2), 16),
      parseInt(this.bg_color.substring(2, 4), 16),
      parseInt(this.bg_color.substring(4, 6), 16),
    ];

    if (this.bg_opacity > 0.99) this.bg = 'rgb(' + rbg[0] + ',' + rbg[1] + ',' + rbg[2] + ')';
    else this.bg = 'rgba(' + rbg[0] + ',' + rbg[1] + ',' + rbg[2] + ', ' + this.bg_opacity + ')';

    // включаем вьюпорт внутри текста (если он есть, иначе дефолт врубится)
    this.text = this.switchTextToViewport(this.text, this.insideHotspot ? 'default' : this.page.pageViewport);

    var paragraph_styles =
      (this.mag.model.attributes &&
        this.mag.model.attributes.edit_params &&
        this.mag.model.attributes.edit_params.paragraph_styles) ||
      [];

    // Устанавливаем правильные тэги: h1,h2,h3,h4,p
    // Только для viewer
    var htmlCode = $.parseHTML(this.text);
    htmlCode = htmlCode
      .map(function(el) {
        var $el = $(el);
        var className = $el.attr('class');
        if (className) {
          var type = paragraph_styles.find(function(el) {
            return el._name.toLowerCase() === className.toLowerCase();
          });

          if (type && type.tag && type.tag !== 'p' && Constants.AVAILABLE_TEXT_TAGS.includes(type.tag)) {
            return $('<' + type.tag + ' class="' + className + '" style="' + ($el.attr('style') || '') + '" />').append(
              $el.contents()
            )[0].outerHTML;
          }
        }

        return el.outerHTML;
      })
      .filter(el => el);
    this.text = htmlCode.join('');
    // хак для всех браузеров, кроме chrome. Pre-wrap не дает образовывать nbsp из последовательности пробелов,
    // но в safari, firefox, edge если задан pre-wrap, не работает свойство justify, решить проблему
    // мирным путем не удалось, поэтому отрубаем pre-wrap при включении justify
    // https://www.notion.so/readymag/34249-Text-jusify-doesn-t-work-in-Safari-21316c693ce84bb09c9434eaa9a2ad91
    this.isNotChrome = !Modernizr.chrome && this.text.includes('justify');

    var template = templates['template-viewer-widget-text'],
      $html = $(template(this));

    this.seamlessFontsShow(
      function() {
        this.$el.removeClass('fonts-pending');
      }.bind(this),
      function() {
        this.$el.addClass('fonts-pending');
      }.bind(this)
    );

    this.$el.append($html);

    this.$el.on(Device.isDesktop ? 'mouseenter' : 'touchstart', 'a', this.onLinkMouseEnter);
    this.$el.on(Device.isDesktop ? 'mouseleave' : 'touchend', 'a', this.onLinkMouseLeave);
    this.$el.on('click', 'a', this.onLinkClick);

    // устанавливаем версию текстового виджета, виджеты без версии считаем виджетами первой версии
    // первая версия использует дефолтным щрифт соурс санс, вторая - робото
    // важно это делать для обратной совместимости, чтобы не сломать старые виджеты
    // также таким способом легко добавить новых версий, просто поправив версию в фефолтах вновь содаваемого виджета
    // и в 3х местах прописать классы для v3 с новыми дефолтными параметрами шрифта
    $html.addClass('v' + (this.version || 1));

    // похожий код есть в шейповом и картиночном виджетах
    // пробегаемся по всем линкам и у тех которые ведут на страницы мега ставим обработчик клика
    // если линк ведет на страницу которой не существует то удаляем href - дизейблим линк
    // также для mailto удаляем target="blank"
    var self = this;

    this.$el.find('a').each(function() {
      var $link = $(this),
        pidAttr = $link.attr('data-pid'),
        href = $link.attr('href'),
        pageID = pidAttr,
        isUrl = false;

      // если указан урл Back to Top, тогда просто заменяем его на номер текущей страницы
      // обработчик кликов по странице сам поймет что еслипыта.тся совершить переход на текущую же страницу, тогда надо проскролить
      if (/^back\sto\stop$/i.test($link.attr('href'))) pageID = self.page._id;

      var sameDomainPageID = href && self.page.mag.matchSameDomainLink(href);
      if (sameDomainPageID == $link.attr('href') || pageID) sameDomainPageID = undefined;

      pageID = pageID || sameDomainPageID;

      // если линк ведет на страницу мэга
      if (pageID) {
        // получаем страницу мэга по её айдишнику
        var pageNum = self.page.mag.getPageNum(pageID),
          pageUrl = pageNum && self.page.mag.getPageUri ? '/' + self.page.mag.getPageUri(pageID) : 'javascript:void(0)';
        // если страница существует
        if (pageNum) {
          $link
            .attr('href', pageUrl)
            .addClass('maglink')
            .attr('target', '');
        } else {
          $link.removeAttr('href');
        }

        if (pageID == self.page._id) {
          $link.addClass('back-to-top');
          if (pidAttr && (self.is_global || self.hasGlobalParent)) {
            $link.addClass('current');
          }
        }
      } else if (/^__rm_goback$/i.test($link.attr('href'))) {
        $link.attr('href', 'javascript:void(0)').addClass('goback-link');
      } else if (self.mailchimpMatcher.test($link.attr('href'))) {
        var mailchimp_data = $link.attr('href').match(self.mailchimpMatcher)[1];
        $link
          .attr('href', 'javascript:void(0)')
          .attr('data-mailchimp', mailchimp_data)
          .addClass('mailchimp-link');
        InitUtils.initMailchimpAPI(); // Сразу начинаем грузить Mailchimp API, чтобы меньше была задержка при клике
      } else if (self.shareRegexp.test($link.attr('href'))) {
        var shareData = $link.attr('href').match(self.shareRegexp);
        $link
          .attr('href', 'javascript:void(0)')
          .attr('data-share-provider', shareData[1])
          .attr('data-share-type', shareData[2])
          .addClass('share-link');
      } else if (self.anchorRegexp.test($link.attr('href'))) {
        $link.attr('href', 'javascript:void(0)').addClass('anchor-link');

        // Когда будем кликать на ссылку, ее страница в вертикальном вьювере
        // может быть и не текущей (видна чуть-чуть), мы должный ее идентифицировать

        var pid = $link.attr('data-page-uri');
        var page = _.findWhere(self.mag.pages, { _id: pid }) || self.page;
        $link.attr('data-page-uri', page.uri || page.num);
      } else {
        isUrl = true;
        $link.addClass('external-link');
      }

      if ($link.attr('href') && ($link.attr('href').indexOf('mailto') == 0 || $link.attr('href').indexOf('tel') == 0))
        $link.attr('target', '');

      // Для скриншотера убираем все ссылки кроме внешних, чтобы они не появлялись в PDF-ках
      if (!isUrl && RM.screenshot) {
        $link.removeAttr('href');
      }
    });

    // ожидаем загрузку всех шрифтов со всех текстовых виджетов на странице только в режиме скриншотера
    // для вьювера этого делать не надо из соображений скорости, да и там не важно этого знать, считаем что виджет грузится мгновенно
    if (RM.screenshot && !this.insideHotspot) this.waitForUsedFontsLoad();
    else this.widgetIsLoaded();

    // создаем миниаудиоплееры прямо в тексте, у них там спец.верстка двух видов:
    // <span class="soundcite" data-id="55728529" data-start="0" data-end="211437" data-plays="1">listen</span>
    // <span class="soundcite" data-url="https://soundcite.knightlab.com/media/bach-cello-suite.mp3" data-start="0" data-end="164000" data-plays="1">listen</span>
    this.$el.find('span.soundcite').each(function(ind, el) {
      // для скриншотера не создаем плеер (а то он начинает грузить медиа)
      // но он будет показан уже в рабочем виде благодаря стилям в цсс
      if (!RM.screenshot) {
        SoundCite.createPlayer(el);
      }
    });

    return this;
  },

  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  // не рендерим пустые виджеты
  // проверяем именно на отсутствие контента, поскольку верстка с пустыми спанами, параграфами, nbsp и br может быть
  isValid: function() {
    var plainText = $('<div>')
      .html(this.text)
      .text();

    return $.trim(plainText) != '';
  },

  hasFontsToLoad: function() {
    return true;
  },

  start: function() {
    this.started = true;
    if (Modernizr.chrome) {
      // Фикс https://readymag.dapulse.com/boards/55520426/pulses/65024879 (при быстром перелистывании не рендерится текст с веб-шрифтами)
      this.$el.addClass('force-repaint-content');
    }
    return this;
  },

  stop: function() {
    this.started = false;
    this.$el.removeClass('force-repaint-content');

    if (!RM.screenshot && !this.destroyed && this.rendered) {
      this.$el &&
        this.$el.find('span.soundcite').each(function(ind, el) {
          SoundCite.pausePlayer(el);
        });
    }

    return this;
  },

  destroy: function() {
    this.$el &&
      this.$el.find('span.soundcite').each(function(ind, el) {
        SoundCite.destroyPlayer(el);
      });

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },

  waitForUsedFontsLoad: function() {
    if (this.hasFontsToLoad()) {
      this.page.addFontsToLoad(this, this.widgetIsLoaded);
    } else {
      this.widgetIsLoaded();
    }
  },

  // Вызывается только в вертикальном вьювере только при смене страниц скроллом
  checkLink: function() {
    // Для глобальных виджетов добавим css-класс для ссылок на текущую страницу
    if (this.is_global || this.hasGlobalParent) {
      var $links = this.$el.find('a[data-pid]');
      $links.removeClass('current back-to-top');
      if (this.page) {
        $links
          .filter('[data-pid="' + this.page._id + '"]')
          .addClass('current back-to-top')
          .removeClass('hovered'); // Убираем ховер до следующего 'mouseenter', чтобы видно было изменение стиля под мышкой (если стиль ховера совпадает с основным стилем ссылки)
      }
    }

    WidgetClass.prototype.checkLink.apply(this, arguments);
  },

  onLinkMouseEnter: function(e) {
    var UUID = $(e.currentTarget).attr('data-uuid'),
      $links = this.$el.find("a[data-uuid='" + UUID + "']");
    $links.addClass('hovered');
  },

  onLinkMouseLeave: function(e) {
    var UUID = $(e.currentTarget).attr('data-uuid'),
      $links = this.$el.find("a[data-uuid='" + UUID + "']");
    $links.removeClass('hovered');
  },

  // проверяем что кликнули по линку создания мэга из темплейта
  onLinkClick: function(e) {
    if (e.which == 2 || e.metaKey || e.ctrlKey) return; // оставляем дефолтное поведение браузера

    var $link = $(e.currentTarget),
      href = $.trim($link.attr('href')),
      tmp = href.match(/\/start\-with\-template\/(\d+)$/i),
      templateID = tmp && tmp[1];

    // вторая проверка для режима превью
    if (templateID && RM.viewerRouter && RM.viewerRouter.createMagFromTemplate) {
      e.preventDefault();
      RM.viewerRouter.createMagFromTemplate('template-link', templateID);
    }
  },
}).extend(TextViewportsClass.prototype);

export default TextWidget;
