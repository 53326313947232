module.exports = {["template-viewer-widget-background"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="rmwidget widget-background" style="background-color: #'+
((__t=(data.color))==null?'':__t)+
'">\n\n        ';
 if (data.selectedType == 'picture') { 
__p+='\n            <div class="picture-background" style="opacity: '+
((__t=(data.opacity || 1))==null?'':__t)+
';"></div>\n        ';
 } 
__p+='\n\n        ';
 if (data.selectedType == 'video') { 
__p+='\n            <div class="video-background" style="opacity: '+
((__t=(data.videoopacity || 1))==null?'':__t)+
';">\n                <div class="video-container"></div>\n                <div class="overlay"></div>\n            </div>\n		';
 } 
__p+='\n\n		';
 if (data.selectedType == 'slideshow') { 
__p+='\n			<div class="slideshow-background">\n				<div class="slideshow-container"></div>\n				<div class="slideshow-preload"></div>\n			</div>\n		';
 } 
__p+='\n	</div>\n';
}
return __p;
},}