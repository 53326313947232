module.exports = {["template-viewer-mag-menu"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="mag-menu '+
((__t=((!hasProjectInfo ? 'no-project-info' : '')))==null?'':__t)+
'">\n		<div class="menu-wrapper">\n\n			';
 if (hasProjectInfo) { 
__p+='\n				<div class="menu-info">\n\n					<a class="link '+
((__t=( !common.isDownloadedSource? '': 'inactive-link'))==null?'':__t)+
'" href="'+
((__t=(mag.model.user.getLink()))==null?'':_.escape(__t))+
'">\n						<div class="userpic" style="background-image:url(\''+
((__t=(mag.model.user.getUserpic(64)))==null?'':__t)+
'\')"></div>\n						<div class="username">'+
((__t=(mag.user.name))==null?'':_.escape(__t))+
'</div>\n					</a>\n					<div class="published-by" data_caption="published by" data_date="'+
((__t=(publishDate))==null?'':__t)+
'"></div>\n					<div class="mag-title">'+
((__t=(mag.title))==null?'':_.escape(__t))+
'</div>\n				</div>\n			';
 } 
__p+='\n\n			<div class="scroll-wrapper">\n\n				';
 if (!common.isDomainViewer && !Modernizr.android) { 
__p+='\n					<a class="logo-wrapper" href="/">\n						<div class="made-with"> Made with</div>\n						<div class="logo sprite"></div>\n					</a>\n				';
 } 
__p+='\n\n				<div class="pages-wrapper has-'+
((__t=(viewerType))==null?'':__t)+
'-scroll">\n					<div class="pages">\n\n						';
_.each(pages, function(page, ind) {
							var attr = viewerType == 'horizontal' ? 'left' : 'top',
								val = ind * (viewerType == 'horizontal' ? width : height);
						
__p+='\n							<div class="page" data-id="'+
((__t=(page._id))==null?'':__t)+
'" data-num="'+
((__t=(page.num))==null?'':__t)+
'" data-src="'+
((__t=(mag.model.getPageScreenshot(width, {_id: page._id})))==null?'':__t)+
'" style="width:'+
((__t=(width))==null?'':__t)+
'px;height:'+
((__t=(height))==null?'':__t)+
'px;'+
((__t=(attr))==null?'':__t)+
':'+
((__t=(val))==null?'':__t)+
'px">\n								<img class="screenshot" src="'+
((__t=(stub))==null?'':__t)+
'" />\n								<div class="num '+
((__t=((page.num > 99 ? 'over99' : '')))==null?'':__t)+
'">'+
((__t=(page.num))==null?'':__t)+
'</div>\n							</div>\n						';
});
__p+='\n\n						';
 if (!common.isDomainViewer && Modernizr.android) { 
__p+='\n							<a class="logo-wrapper" href="/">\n								<div class="made-with"> Made with</div>\n								<div class="logo sprite"></div>\n							</a>\n						';
 } 
__p+='\n\n					</div>\n				</div>\n				<div class="scroll"></div>\n			</div>\n		</div>\n	</div>\n\n';
}
return __p;
},}