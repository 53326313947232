<template>
  <div id="rm-header">
    <header>
      <div class="logo" v-if="!isAuthorized">
        <a :href="`${homeUrl}`">
          <svg
            width="80"
            height="80"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 80"
            preserveAspectRatio="xMidYMid meet"
          >
            <g fill="none" fill-rule="evenodd">
              <circle :fill="activeLink ? '#000000' : '#ec520b'" fill-rule="nonzero" cx="40" cy="40" r="40" />
              <path
                d="M57.746 59.179v-6.216c0-1.24-.678-1.863-1.801-1.863-1.334 0-2.433.99-2.48 2.191v5.88h-2.254v-6.215c0-1.24-.678-1.864-1.801-1.864-1.334 0-2.433.99-2.48 2.191v5.88h-2.254v-9.717h2.028l.226.827.11.101.108-.101c.453-.624 1.576-1.037 2.48-1.037 1.622 0 2.792.64 3.385 1.653l.561-.624c.562-.624 1.692-1.037 2.59-1.037 2.48 0 3.836 1.45 3.836 3.517v6.418h-2.254v.016zM23.442 28.913h-.414v6.707h-2.215V20h5.381c2.418 0 4.539 1.606 4.539 4.383 0 2.674-2.262 4.382-4.68 4.382l4.976 6.418v.43h-2.526l-5.061-6.7zm2.877-2.214c1.217 0 2.332-.85 2.332-2.23s-1.115-2.23-2.332-2.23h-3.244v4.46h3.244z"
                stroke="#FFF"
                stroke-width=".2"
                fill="#FFF"
              />
              <path
                d="M20.116 58.672l38.23-38.23 1.213 1.211-38.23 38.23-1.213-1.211z"
                stroke="#FFF"
                stroke-width=".6"
                fill="#FFF"
              />
            </g>
          </svg>
        </a>
      </div>

      <div class="avatar" v-if="isAuthorized">
        <a
          :class="{ 'with-avatar': userpic }"
          :href="profileUrl"
          :style="{ backgroundImage: userpic && `url('${addAvatarSizeSuffix(userpic, 48)}')` }"
          >{{ userpic ? '' : initials }}</a
        >
      </div>

      <div class="links">
        <a :href="`${homeUrl}/features`" :class="{ active: activeLink === 'features' }" @click="linkClick">Features</a>
        <a :href="`${homeUrl}/pricing/`" :class="{ active: activeLink === 'pricing' }" @click="linkClick">Pricing</a>
        <a
          :href="`${homeUrl}/explore/`"
          :class="{ active: activeLink === 'examples' || activeLink === 'explore' }"
          @click="linkClick"
          >Examples</a
        >
        <a href="https://learn.readymag.com/" :class="{ active: activeLink === 'learn' }" @click="linkClick">Learn</a>
      </div>

      <div class="buttons" v-if="isAuthorized">
        <a :href="`${profileUrl}/logout`">Log out</a>
      </div>

      <div class="buttons" v-if="!isAuthorized">
        <a :href="`${homeUrl}/join/`">Join</a>
        <a :href="`${homeUrl}/login/`">Log in</a>
      </div>
    </header>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import Vue from 'vue';
import { Utils, Constants } from '../../js/common/utils';
import RmHeaderMobile from './rm-header-mobile.vue';

const availableSizes = [64, 96, 128, 192, 256];

const getActualSize = size => {
  const size2x = size * 2;
  return availableSizes.reduce(
    (closestSize, currentSize) => (size2x <= currentSize && size2x > closestSize ? currentSize : closestSize),
    availableSizes[0]
  );
};

export default {
  data: () => ({
    isAuthorized: false,
    userpic: null,
    username: null,
    profileUrl: Constants.readymag_auth_host,
    homeUrl: Constants.readymag_host,
  }),

  props: {
    activeLink: String,
  },

  computed: {
    initials() {
      const name = this.username;
      if (!name) return '';

      const [firstName, lastName] = name.split(' ');
      return lastName ? firstName[0] + lastName[0] : firstName[0];
    },
  },

  mounted: function() {
    // Мобильная шапка должна жить в body, чтобы быть выше всех и не реагировать на scale layout
    const MobileHeader = Vue.extend(RmHeaderMobile);
    const $mobileHeaderContainer = $('<div>').appendTo('body');

    this.mobileVue = new MobileHeader({
      el: $mobileHeaderContainer[0],

      propsData: {
        activeLink: this.activeLink,
      },
    });

    Utils.loadLoggedUser(user => {
      this.isAuthorized = !_.isEmpty(user);
      this.userpic = user && user.pic;
      this.username = user && user.name;
      this.mobileVue.isAuthorized = this.isAuthorized;
    });
  },

  methods: {
    addAvatarSizeSuffix: (url, size) => {
      if (!url) return '';

      const extension = url.split('.').pop();
      const actualSize = getActualSize(size);
      return url && actualSize ? url.replace(`.${extension}`, `_${actualSize}.${extension}`) : '';
    },

    destroyMobile: function() {
      $(this.mobileVue.$el).remove();
      this.mobileVue.$destroy();
    },

    linkClick: function(e) {
      const currentUrl = window.location.href.replace(/\/$/, '');
      const linkUrl = $(e.target)
        .attr('href')
        .replace(/\/$/, '');

      if (currentUrl === linkUrl) {
        e.preventDefault();
        $('html').animate({ scrollTop: 0 }, 300);
      }
    },
  },
};
</script>

<style lang="less">
@orange: #ec520b;
@font: Graphik;

#rm-header {
  header {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    cursor: default;

    > div {
      width: 100%;
    }
  }

  .logo {
    height: 40px;

    a {
      display: inline-block;
      height: 40px;
    }

    svg {
      height: 40px;
      width: 40px;
      transition: transform 0.6s ease-in-out;
    }
  }

  .avatar {
    height: 40px;
    display: inline-block;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background-color: #f4f4f4;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 40px;
      width: 40px;
      border-radius: 50%;

      font-weight: 400;
      font-size: 16px;
      line-height: 1.25;
      letter-spacing: -0.04px;
      color: rgb(40, 40, 40);
      text-transform: uppercase;
      font-family: @font;

      &.with-avatar {
        background-color: transparent;
      }
    }
  }

  .links {
    text-align: center;

    a {
      display: inline-block;
      font-family: @font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-right: 16px;
      letter-spacing: -0.1px;
      text-decoration: none;
      color: #000;

      .text-on-dark & {
        color: #fff;
      }

      .white-theme.scrolled & {
        color: #000 !important;

        &:hover,
        &.active {
          color: @orange !important;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: @orange;
      }
    }

    a.active {
      color: @orange;
    }
  }

  .buttons {
    text-align: right;
    font-size: 0;

    a {
      background-color: rgba(244, 244, 244, 0.96);
      border-radius: 200px;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-family: @font;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.1px;
      font-size: 16px;
      display: inline-block;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0;
      text-decoration: none;
      color: #000;

      &:hover {
        color: #fff;
        background-color: @orange;
      }
    }
  }
}

.rm-header-mobile {
  #rm-header {
    header {
      align-items: center;
      padding: 6px 6px;

      .avatar {
        width: auto;
        height: 48px;

        a {
          height: 48px;
          width: 48px;
        }
      }

      .logo {
        height: 48px;

        a {
          height: 48px;
        }

        svg {
          height: 48px;
          width: 48px;
        }
      }

      .links {
        display: none;
      }

      .buttons {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .rm-header-mobile;

  header.menu {
    height: 60px !important;
  }

  .widget-rm-header {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    margin-left: 0 !important;
  }

  .login-popup {
    top: 0 !important;
  }
}
</style>
