/**
 * Виджет добавления товара в корзину
 */
import _ from '@rm/underscore';
import $ from '@rm/jquery';
import BlockClass from '../block';
import { isEnabled } from '../../common/flags';
import { Constants } from '../../common/utils';
import AddToCartWidgetClass from '../../common/add-to-cart-widget';
import CubicBezier from '../../common/cubic-bezier';
import FormBlock from './form';
import ButtonBlock from './button';
import ModelClass from '../models/model';
import AddToCartFrame from '../add-to-cart-frame';

var defaultDisabledControls = ['product_selector'];
var selectionStates = ['both', 'button', 'selects'];

var addToCartBlock = BlockClass.extend(
  {
    name: 'AddToCart',
    blockName: Constants.addToCartBlockName,
    sort_index: 999,
    thumb: 'addtocart',
    excludedFromLib: true, // Не отображать в меню добавления виджета

    // stylingState: 'default',

    icon_color: '#282828',

    MAX_ICON_CACHE_SIZE: 30,

    RASTERIZE_MULTIPLIER: 2,

    frameColor: '#0080FF',

    cartBlock: null, // Ссылка на блок с корзиной, нужна для рисования стрелочки к корзине

    defaultProductId: 'default',

    initial_controls: [
      'product_selector',
      'add_to_cart_settings',
      'button_icon',
      'button_edit',
      'common_animation',
      'common_position',
      'common_layer',
      'common_lock',
    ],

    disabledControls: defaultDisabledControls,

    getIconStyle: function() {
      var res = {};
      res['background-color'] = this.icon_color;
      return res;
    },

    controlsSvgIcons: {
      'panel-product-selector': '/img/constructor/controls/product_selector/select-product.svg',
    },

    controlsIconClasses: function() {
      return {
        'panel-product-selector': this.getProductSelectorIconClass(),
      };
    },

    getProductSelectorIconClass: function() {
      return this.model && this.model.get('selected_product_id') !== 'default'
        ? this.workspace.eCommerceManager.isProductActive(this.model.get('selected_product_id'))
          ? 'active-product-selected'
          : 'inactive-prodict-selected'
        : 'product-not-selected';
    },

    // Проверяет какие контролы заблокированы
    // По логике в первом параметре должна быть модель блока
    // Но тут мы прокидываем данные из глобального объекта !!!
    checkDisabledControls: function(status, initial) {
      // Срабатывает при первой отрисовке
      if (initial) {
        if (this.workspace.eCommerceManager && this.workspace.eCommerceManager.isMagConnectedToStripe()) {
          this.disabledControls = [];
        } else {
          this.disabledControls = defaultDisabledControls;
        }
        return;
      }

      if (status instanceof ModelClass) {
        return;
      }

      if (status) {
        this.disabledControls = [];
      } else {
        this.disabledControls = defaultDisabledControls;
      }

      this.trigger('disabledControls:changed', this.blockName);
    },

    initialize: function(model, workspace) {
      this.mag = workspace.mag;
      this.initBlock(model, workspace);
      this.frameClass = AddToCartFrame; // кастомный класс рамки
      this.isEditMode = false;
      this.rendered = false;
      this._oldAttrs = _.clone(this.model.attributes);
      this.stylingState = 'default';
      this.selectionState = 'both';

      this.onIconChange.__debounced = _.debounce(this.onIconChange, 20);
      this.onTextInput.__debounced = _.debounce(this.onTextInput, 20);
      this.rasterizeSVG.__debounced_both = _.debounce(this.rasterizeSVG, 100);
      this.saveWidgetData.__debounced = _.debounce(this.saveWidgetData, 100);
    },

    render: function() {
      this.create();
      this.$el.addClass('add-to-cart-block');
      this.controls = this.initial_controls;

      this.addToCartWidget = new AddToCartWidgetClass({
        data: _.cloneWithObjects(this.model.attributes),
        environment: 'constructor',
        $container: this.$content,
        block: this,
        eCommerceManager: this.workspace.eCommerceManager,
      });

      this.addToCartWidget.render();

      this.$buttonWrap = this.$content.find('.button-wrap');
      this.$button = this.addToCartWidget.$button;
      this.$buttonText = this.addToCartWidget.$buttonText;
      this.$buttonIcon = this.addToCartWidget.$buttonIcon;
      this.$attributesBox = this.addToCartWidget.$attrsContainer;

      this.initIcon();

      this.bindDOMEvents();

      this.bindLinkingLineEvents();

      this.bindModelEvents();

      this.rendered = true;

      this.triggerReady();
    },

    haveProductAttributes: function() {
      if (this.addToCartWidget) {
        return this.addToCartWidget.showSkus;
      }
      return false;
    },

    destroy: function() {
      this.unbindDOMEvents();
      this.unbindLinkingLineEvents();
      // удаляем всех слушателей изменений модели, созданные этой вьюхой
      this.model.off(null, null, this);
      BlockClass.prototype.destroy.apply(this, arguments);
    },

    bindDOMEvents: function() {
      this.$el.on('mousedown', this.onBlockMousedown);
      this.$button.on('dblclick', this.onButtonDoubleClick);
      this.$attributesBox.on('dblclick', this.onAttrsDoubleClick);
      this.$buttonText.on('input', this.onTextInput);
    },

    unbindDOMEvents: function() {
      this.$el.off('mousedown', this.onBlockMousedown);
      this.$button.off('dblclick', this.onButtonDoubleClick);
      this.$attributesBox.off('dblclick', this.onAttrsDoubleClick);
      this.$buttonText.off('input', this.onTextInput);
    },

    bindLinkingLineEvents: function() {
      // На изменение виджета обновляем стрелку между виджетами
      this.listenTo(this, 'move resize', this.updateAnimationTrigger.bind(this));
      this.listenTo(
        this.model,
        'change:angle change:sticked change:fixed_position change:is_full_width change:is_full_height',
        this.updateAnimationTrigger
      );
      this.listenTo(this.workspace, 'workspace-resize', this.updateAnimationTrigger.bind(this));
      $('#main').on('scroll', this.updateAnimationTrigger);
    },

    unbindLinkingLineEvents: function() {
      this.stopListening(this, 'move resize', this.updateAnimationTrigger);
      this.stopListening(
        this.model,
        'change:angle change:sticked change:fixed_position change:is_full_width change:is_full_height',
        this.updateAnimationTrigger
      );
      this.stopListening(this.workspace, 'workspace-resize', this.updateAnimationTrigger);
      $('#main').off('scroll', this.updateAnimationTrigger);
    },

    // Синхронизируем данные в виджете
    onModelChange: function(model) {
      BlockClass.prototype.onModelChange.call(this, model);
      this.addToCartWidget.setData(_.cloneWithObjects(model.attributes)); // Обновляем данные модели в виджете
    },

    bindModelEvents: function() {
      // Изменение типа кнопки
      this.model.on('change:tp', this.onTypeChange, this);

      // Иконка в кнопке
      this.model.on('change:icon_enabled', this.determineButtonType, this);

      // срабатывает на случай изменения текста в модели через Undo/Redo
      this.model.on(
        'change:text',
        function(model, value, options) {
          if (options.undo || options.redo || options.socketUpdate) {
            this.$buttonText.val(this.model.get('text'));
          }
        },
        this
      );

      // c дебонсом, т.к. эти изменения приходят вместе друг за другом
      this.model.on(
        'change:icon_enabled change:icon_noun_id change:icon_rm_id change:icon_noun_url',
        function(model, value, options) {
          this.onIconChange.__debounced(model, value, options);
        },
        this
      );
      // при изменении размера Иконки в модели.
      this.model.on(
        'change:icon_h',
        function(model, value, options) {
          this.recalcIconContainerSize(model, value, options);
        },
        this
      );
      // при изменении позиции Иконки в модели.
      this.model.on(
        'change:icon_pos',
        function() {
          this.onIconPositionChange();
        },
        this
      );
      // Изменение продукта
      this.model.on('change:selected_product_id', this.onProductChange, this);

      this.model.on('change:layout', this.onLayoutChange, this);

      this.model.on(
        'change:font-size change:font-family',
        function() {
          this.recalcTextSizeAndIconPosition();
          this.addToCartWidget.setButtonIconPosition();
        }.bind(this),
        this
      );

      this.model.on(
        'change:hover-font-size change:hover-font-family',
        function() {
          this.recalcTextSizeAndIconPosition('hover-');
          this.addToCartWidget.setButtonIconPosition();
        }.bind(this),
        this
      );

      var individual_styles = this.getIndividualStyles();

      this.model.on(
        'change:icon_color change:icon_color_opacity',
        function() {
          const type = this.model.get('tp');
          if (type === 'icon' || type === 'text_and_icon') {
            this.rasterizeSVG.__debounced_both({ default: true });
          }
        }.bind(this),
        this
      );

      this.model.on(
        'change:hover-icon_color change:hover-icon_color_opacity',
        function() {
          const type = this.model.get('tp');
          if (type === 'icon' || type === 'text_and_icon') {
            this.rasterizeSVG.__debounced_both({ hover: true });
          }
        }.bind(this),
        this
      );

      this.model.on(
        individual_styles,
        function() {
          var model = _.clone(this.model.attributes);
          this.addToCartWidget.generateIndividualStyleCSS(model, true, this.stylingState, this.selectionState);
        }.bind(this),
        this
      );
    },

    changeStylingState: function(newState) {
      this.stylingState = newState;
      var model = _.clone(this.model.attributes);
      this.addToCartWidget.generateIndividualStyleCSS(model, true, this.stylingState, this.selectionState);
    },

    getIndividualStyles: function() {
      var individualStyles = [
        'background-color',
        'background-color-opacity',
        'attrs-background-color',
        'attrs-background-color-opacity',
        'border-radius',
        'attrs-border-radius',
        'border-width',
        'attrs-border-width',
        'border-color',
        'attrs-border-color',
        'border-color-opacity',
        'attrs-border-color-opacity',
        'font-family',
        'attrs-font-family',
        'font-size',
        'attrs-font-size',
        'color',
        'attrs-color',
        'color-opacity',
        'attrs-color-opacity',
        'text-align',
        'attrs-text-align',
        'attrs-icon-size',
        'hover-background-color',
        'hover-background-color-opacity',
        'attrs-hover-background-color',
        'attrs-hover-background-color-opacity',
        'hover-border-radius',
        'attrs-hover-border-radius',
        'hover-border-width',
        'attrs-hover-border-width',
        'hover-border-color',
        'attrs-hover-border-color',
        'hover-border-color-opacity',
        'attrs-hover-border-color-opacity',
        'hover-font-family',
        'attrs-hover-font-family',
        'hover-font-size',
        'attrs-hover-font-size',
        'hover-color',
        'attrs-hover-color',
        'hover-color-opacity',
        'attrs-hover-color-opacity',
        'hover-tansition-delay',
      ];

      individualStyles.forEach(function(style, i, styles) {
        styles[i] = 'change:' + style;
      });

      return individualStyles.join(' ');
    },

    // Сбрасывает состояние выделение на стандартное
    setDefaultSelectionState: function() {
      if (this.selectionState !== 'both') {
        this.selectionState = 'both';
        this.trigger('addToCartChangeSelectionState', this.selectionState);
        var model = _.clone(this.model.attributes);
        this.addToCartWidget.generateIndividualStyleCSS(model, true, this.stylingState, this.selectionState);
        // if (this.stylingState !== 'default') {
        //   this.addToCartWidget.generateIndividualStyleCSS(this.model, true, this.stylingState, this.selectionState);
        // }
      }
    },

    setSelectionState: function(state) {
      if (selectionStates.indexOf(state) === -1) {
        return;
      }

      this.selectionState = state;
      this.trigger('addToCartChangeSelectionState', this.selectionState);
      var model = _.clone(this.model.attributes);
      this.addToCartWidget.generateIndividualStyleCSS(model, true, this.stylingState, this.selectionState);
      // if (this.stylingState !== 'default') {
      //   this.addToCartWidget.generateIndividualStyleCSS(this.model, true, this.stylingState, this.selectionState);
      // }
    },

    // Выделение блока
    select: function() {
      if (this.selected) return;
      this.checkDisabledControls(null, true);

      // Ищем блок корзины
      this.cartBlock = this.workspace.eCommerceManager.getEcommerceCartBlock();

      // Создаем стрелку между блоками
      if (this.cartBlock) {
        this.animationTriggerLine = new CubicBezier({
          $el: this.workspace.$('.workspace'),
          fromBlock: this,
          toBlock: this.cartBlock,
          cssClass: 'animation-trigger-line',
        });
        this.cartBlock.startListeningForUpdateTrigger(this.animationTriggerLine);
      }
      // console.log('this.workspace.controls.', this.workspace.controls);
      // setTimeout(() => {
      //   console.log('add_to_cart_settings', this.workspace.controls.findControl('add_to_cart_settings'));
      // }, 100);

      FormBlock.prototype.select.apply(this, arguments);
    },

    deselect: function(block) {
      if (block === this) return;
      if (!this.selected) return;

      // Сбрасываем состояние выделения виджета
      this.setDefaultSelectionState();

      this.deleteAnimationTrigger();

      if (this.isEditMode) {
        this.leaveEditMode({ saveWidgetData: true });
        this.workspace.trigger('redraw');
      }

      FormBlock.prototype.deselect.apply(this, arguments);
    },

    // Обновляем стрелку между блоками
    updateAnimationTrigger: function() {
      if (this.selected) {
        window.requestAnimationFrame(this.recalcAnimationTrigger.bind(this));
      }
    },

    recalcAnimationTrigger: function() {
      this.animationTriggerLine && this.animationTriggerLine.update();
    },

    deleteAnimationTrigger: function() {
      this.animationTriggerLine && this.animationTriggerLine.remove();
      delete this.animationTriggerLine;
      if (this.cartBlock) {
        this.cartBlock.stopListeningForUpdateTrigger();
        this.cartBlock = null;
      }
    },

    // Выделение нескольких блоков
    onWorkspaceBlocksSelect: function(blocks) {
      BlockClass.prototype.onWorkspaceBlocksSelect.apply(this, arguments);

      if (!this.rendered) return;

      // смотрим, что у нас в текущем выделении текущий блок или еще что-то.
      var isOnlyMeSelected = blocks && blocks.length === 1 && blocks[0] === this;
      if (this.isEditMode && !isOnlyMeSelected) {
        this.leaveEditMode({
          deselectControl: true,
          saveWidgetData: true,
        });
        this.workspace.trigger('redraw');
      }

      // Если выбрано несколько виджетов
      if (!isOnlyMeSelected) {
        this.deleteAnimationTrigger();
      }
    },

    onButtonDoubleClick: function() {
      // если виджет входит в группу, тогда не отрабатывать двойной клик по нему
      if (this.model.get('pack_id')) {
        BlockClass.prototype.onDblClick.apply(this, arguments);
        return;
      }

      if (!this.selected) return;

      if (this.addToCartWidget.showSkus) {
        // Переключение состояния выделения
        if (this.selectionState === 'both') {
          this.setSelectionState('button');
          this.workspace.controls.findControl('add_to_cart_settings').select();
        } else if (this.selectionState === 'selects') {
          this.setSelectionState('button');
        } else {
          !this.isEditMode && this.enterEditMode();
        }
      } else {
        !this.isEditMode && this.enterEditMode();
      }
    },

    onAttrsDoubleClick: function() {
      if (!this.selected) return;

      // имеет смысл только когда рендерятся атрибуты
      if (this.addToCartWidget.showSkus) {
        if (this.selectionState === 'both') {
          this.setSelectionState('selects');
          this.workspace.controls.findControl('add_to_cart_settings').select();
        } else if (this.selectionState === 'button') {
          this.setSelectionState('both');
        }
      }
    },

    // Клик по блоку
    onBlockMousedown: function(e) {
      // Если кликнули мимо инпута то выходим из режима редактирования
      if (this.isEditMode && !$(e.target).is(this.addToCartWidget.$buttonText)) {
        this.leaveEditMode({
          deselectControl: true,
          saveWidgetData: true,
        });
      }

      if (this.addToCartWidget.showSkus) {
        if (this.selectionState === 'button' && !$(e.target).is(this.addToCartWidget.$buttonText)) {
          this.setDefaultSelectionState();
        }

        if (this.selectionState === 'selects' && !$(e.target).is(this.$attributesBox)) {
          this.setDefaultSelectionState();
        }
      }
    },

    // Редактирование текста кнопки
    enterEditMode: function(options) {
      this.isEditMode = true;
      if (this.addToCartWidget) {
        this.addToCartWidget.enterEditMode(options);
      }
      this.determineButtonType({ enter_edit_mode: true });
    },

    leaveEditMode: function(options) {
      options = options || {};

      if (this.addToCartWidget) {
        this.addToCartWidget.leaveEditMode(options);
        this.model.set('text', this.addToCartWidget.getButtonText());
      }

      if (options.deselectControl) {
        this.deselectEditControl();
      }

      this.isEditMode = false;

      this.determineButtonType();

      this.recalcTextSizeAndIconPosition();
      this.recalcTextSizeAndIconPosition('hover-');
      this.addToCartWidget.setButtonIconPosition();

      if (options.saveWidgetData) {
        this.saveWidgetData();
      }
    },

    // Для включения контрола редактирования текста кнопки
    selectEditControl: function() {
      var workspaceControls = this.workspace && this.workspace.controls;
      if (!workspaceControls) return;
      var control = workspaceControls.findControl('button_edit');
      if (!control) return;
      control.master.select(control);
    },

    deselectEditControl: function() {
      var workspaceControls = this.workspace && this.workspace.controls;
      if (!workspaceControls) return;
      var control = workspaceControls.findControl('button_edit');
      if (!control) return;
      control.master.deselect();
    },

    // При изменении текста в кнопке
    onTextInput: function(e) {
      this.recalcTextSizeAndIconPosition();
      this.recalcTextSizeAndIconPosition('hover-');
      this.addToCartWidget.setButtonIconPosition();
    },

    // Вызывается при измении блока при помощи его рамок
    css: function(params, resizePoint) {
      BlockClass.prototype.css.apply(this, arguments);
      if (params.height !== undefined || params.width !== undefined) {
        this.addToCartWidget.recalcDimensions(params);
      }
    },

    // Событие происходит после изменения размеров блока, один раз когда мышка отпушенна
    onResizeEnd: function(event, drag, options) {
      var blockSize = this.getSaveBoxData();
      this.addToCartWidget.saveButtonSizes(blockSize.w, blockSize.h);
    },

    changeSelectedProduct: function(productId) {
      this.model.save({
        selected_product_id: productId,
      });
    },

    // Выбрали другой продукт
    onProductChange: function(model) {
      this.addToCartWidget.onProductChange(_.cloneWithObjects(model.attributes));
      this.updateAnimationTrigger();
    },

    // Настройки внешнего вида виджета

    // Изменение направления макета
    onLayoutChange: function(model) {
      this.addToCartWidget.onLayoutChange(model.get('layout'));
      this.updateAnimationTrigger();
    },

    // Устанавливает минимальные значения рамки изменения
    setSizeConstraints: function(w, h) {
      _.extend(this.frame, {
        minwidth: w,
        maxwidth: 9999,
        minheight: h,
        maxheight: 9999,
      });
    },

    // Работа с кнопкой добавить товар

    // Устанавливает тип для кнопки
    determineButtonType: function(options) {
      options = options || {};

      var icon_enabled = this.model.get('icon_enabled'),
        button_has_text = options.enter_edit_mode ? true : this.addToCartWidget.getButtonText().length,
        determined_button_type;

      if (icon_enabled && button_has_text) {
        determined_button_type = 'text_and_icon';
      } else if (icon_enabled && !button_has_text) {
        determined_button_type = 'icon';
      } else {
        determined_button_type = 'text';
      }

      this.model.set('tp', determined_button_type);
    },

    onTypeChange: function() {
      this.recalcButtonIconPosition();
      this.recalcButtonIconPosition('hover-');
    },

    recalcTextSizeAndIconPosition(state) {
      this.recalcButtonTextSize(state);
      this.recalcButtonIconPosition(state);
    },

    // Вычисляет минимальные размеры для текста кнопки
    // Используется только для расчета положения иконки
    recalcButtonTextSize: function(state) {
      var prefix = state ? state : '';
      var adjusted_w,
        adjusted_h,
        em_w,
        em_h,
        model = _.clone(this.model.attributes),
        fontSize = model[prefix + 'font-size'],
        letterSpacing = model[prefix + 'letter-spacing'],
        fontFamily = model[prefix + 'font-family'],
        fontStyle = model[prefix + 'font-style'],
        fontWeight = model[prefix + 'font-weight'];

      // Создаем скрытый блок с текстом и стилями как у тектового блока в кнопке
      if (!this.$textInputSizeAdjuster || !this.$textInputSizeAdjuster.length) {
        this.$textInputSizeAdjuster = $('<div/>');
        this.$textInputSizeAdjuster
          .css({
            position: 'absolute',
            left: -9999 + 'px',
            top: -9999 + 'px',
            'white-space': 'pre',
            width: 'auto',
          })
          .appendTo(this.$button);
      }

      this.$textInputSizeAdjuster.text(this.addToCartWidget.getButtonText());

      this.$textInputSizeAdjuster.css({
        'font-family': fontFamily,
        'font-style': fontStyle,
        'font-weight': fontWeight,
        'font-size': fontSize + 'px',
        'letter-spacing': letterSpacing,
      });

      // измеряем размер текста
      adjusted_w = this.$textInputSizeAdjuster.width();
      adjusted_h = this.$textInputSizeAdjuster.height();

      // измеряем ширину буквы M для выставления минимального
      // воздуха слева и справа у контента Кнопки
      // если кнопка с текстом
      this.$textInputSizeAdjuster.css('letter-spacing', 0).text('M');
      em_w = this.$textInputSizeAdjuster.width();
      em_w = Math.ceil(em_w / 2) * 2; // округляем и делаем четной.
      em_h = this.$textInputSizeAdjuster.height();

      this.$textInputSizeAdjuster.empty();

      // Если у кнопки пустой текст, то размер текста = размер отступов
      if (this.addToCartWidget.getButtonText().trim().length === 0) {
        adjusted_w = em_w;
        adjusted_h = em_h;
      }

      adjusted_w = Math.ceil(adjusted_w / 2) * 2;
      adjusted_h = Math.ceil(adjusted_h / 2) * 2;

      this.model.set({
        [prefix + 'text_w']: adjusted_w,
        [prefix + 'text_h']: adjusted_h,
        [prefix + 'em_w']: em_w,
      });
    },

    // Расчет позиции иконки, если она показывается
    recalcButtonIconPosition: function(state) {
      var prefix = state ? state : '';
      if (!this.model.get('icon_enabled')) {
        return;
      }

      var btnWidth = this.$button.outerWidth();
      var btnType = this.model.get('tp');
      if (btnWidth) {
        var textWidth = this.model.get(prefix + 'text_w');
        var iconWidth = this.model.get('icon_w');
        if (btnType === 'text_and_icon') {
          var space = Math.round((btnWidth - textWidth) / 2); // Простанство доступное для между текстом и краями кнопки
          var margin = space - iconWidth - iconWidth / 3; // отступ от края кнопки
          this.model.set({
            [prefix + 'icon_pos_value']: Math.round(margin),
          });
          this.saveWidgetData.__debounced();
        } else if (btnType === 'icon') {
          var margin = Math.round(btnWidth / 2) - Math.round(iconWidth / 2); // Если иконка одна, то центрируем по центру
          this.model.set({
            [prefix + 'icon_pos_value']: margin,
          });
          this.saveWidgetData.__debounced();
        }
      }
    },

    // заглушка для работы иконки
    recalcButtonSize: function() {},

    // ----- Работа с иконкой кнопки -----

    // Вызывается в render для отрисовки иконки
    initIcon: function() {
      if (this.model.get('icon_enabled')) {
        this.getIconSVG(
          this.model.get('icon_noun_id') || this.model.get('icon_rm_id'),
          this.model.get('icon_noun_url'),
          function(err, data) {
            if (err || !data.$svg) {
              return;
            }

            // рендерим SVG Иконки, предварительно покрасив.
            this.renderIcon({ $svg: this.getСoloredIconSVG() });

            // если Кнопка создана в первый раз, то у неё
            // Иконка не растеризована и урла не будет → отправляем на растер.
            if (!this.model.get('icon_rasterUrl')) {
              this.rasterizeSVG({ default: true, hover: true, current: true });
            }
          }.bind(this)
        );
      }
    },

    renderIcon: function(options) {
      ButtonBlock.prototype.renderIcon.apply(this, arguments);
    },

    recalcIconContainerSize: function(model, value, options) {
      var button_type = this.model.get('tp');

      if (button_type === 'text') {
        // тк может быть это вызвано отключением иконки
        this.addToCartWidget.renderButtonIcon(_.clone(this.model.attributes));
        // this.recalcButtonSize();
        return;
      }

      var icon_h = this.model.get('icon_h');
      var icon_w = Math.floor(icon_h * this.currentIconData.aspect_ratio);
      this.model.set({ icon_w: icon_w });

      this.recalcButtonIconPosition();
      this.recalcButtonIconPosition('hover-');
      var newData = _.clone(this.model.attributes);
      this.addToCartWidget.renderButtonIcon(newData);
      this.addToCartWidget.setButtonIconPosition(newData);

      if (!(options.onIconChange || options.undo || options.redo)) {
        this.rasterizeSVG.__debounced_both({ default: true, hover: true, current: true });
      }
    },

    rasterizeSVG: function(options) {
      ButtonBlock.prototype.rasterizeSVG.apply(this, arguments);
    },

    // Методы для работы с иконкой кнопки
    onIconChange: function(model, value, options) {
      if (typeof value === 'boolean' && value === false) {
        this.$buttonIcon.empty();
        this.recalcIconContainerSize(model, value, { onIconChange: true });
        return;
      }
      ButtonBlock.prototype.onIconChange.apply(this, arguments);
    },

    onIconPositionChange: function() {
      this.addToCartWidget.applyButtonIconPosition(_.clone(this.model.attributes));
    },

    onIconColorChange: function(model, value, options, state) {
      ButtonBlock.prototype.onIconColorChange.apply(this, arguments);
    },

    getСoloredIconSVG: function(options) {
      return ButtonBlock.prototype.getСoloredIconSVG.apply(this, arguments);
    },

    getDefaultIconSVG: function() {
      return ButtonBlock.prototype.getDefaultIconSVG.apply(this, arguments);
    },

    getIconSVG: function(id, icon_url, callback) {
      return ButtonBlock.prototype.getIconSVG.apply(this, arguments);
    },

    prepareIconSVG: function($raw_svg) {
      return ButtonBlock.prototype.prepareIconSVG.apply(this, arguments);
    },

    cacheSVGData: function(data) {
      ButtonBlock.prototype.cacheSVGData.apply(this, arguments);
    },

    getSVGCacheItem: function(id) {
      return ButtonBlock.prototype.getSVGCacheItem.apply(this, arguments);
    },

    updateBlockSizes: function(sizes) {
      this.model.save({
        w: sizes.width,
        h: sizes.height,
      });
    },

    saveWidgetData: function() {
      // если модель не изменилась, то ничего не делаем.
      if (_.isEqual(this.model.toJSON(), this._oldAttrs)) return;
      this.saveXHR && this.saveXHR.abort();

      // сохраняем слепок модели, которую мы сохраняем.
      this._oldAttrs = _.clone(this.model.toJSON());
      this.saveXHR = this.model.save();
    },
  },
  {
    defaults: {
      // Часть полей взята от кнопки что бы переиспользовать ее методы
      // Глобальные параметры виджета
      h: AddToCartWidgetClass.DEFAULT_ROW_HEIGHT,
      w: AddToCartWidgetClass.DEFAULT_ROW_WIDTH,
      x: 'center',
      selected_product_id: 'default', // id продукта
      layout: 'vertical', // лэйаут как у формы
      tp: 'text', // || icon || text_and_icon (тип кнопки)
      em_w: 16, // Отступы у кнопки, что-то вроде padding
      select_w: 110, // Размеры селекта
      select_h: 30,
      btn_w: 110, // Размеры кнопки
      btn_h: 30,
      text_w: 88, // размеры текста в конопке
      text_h: 18,
      'hover-text_w': 88,
      'hover-text_h': 18,
      'hover-em_w': 16,
      select_btn_margin: 10, // Отступ между селектом и кнопкой
      attributes_margin: 10, // отступ между селектами атрибутов
      // Текст кнопки
      text: 'Add to cart',
      // Стили кнопки
      'background-color': '282828',
      'background-color-opacity': 100,
      'border-radius': 30,
      'border-width': 0,
      'border-color': '000000',
      'border-color-opacity': 100,
      'font-family': 'Arial',
      'font-style': 'normal',
      'font-weight': 400,
      color: 'ffffff',
      'color-opacity': 100,
      'font-size': 18,
      'letter-spacing': 'inherit',
      'text-align': 'center',
      'hover-tansition-delay': 0,
      'hover-background-color': '181818',
      'hover-background-color-opacity': 100,
      'hover-border-radius': 30,
      'hover-border-width': 0,
      'hover-border-color': '000000',
      'hover-border-color-opacity': 100,
      'hover-font-family': 'Arial',
      'hover-font-style': 'normal',
      'hover-font-weight': 400,
      'hover-color': 'ffffff',
      'hover-color-opacity': 100,
      'hover-font-size': 18,
      'hover-letter-spacing': 'inherit',
      // Стили иконки
      icon_color: 'ffffff',
      icon_color_opacity: 100,
      icon_enabled: false,
      icon_h: 18,
      icon_w: 18,
      icon_noun_id: null,
      icon_noun_url: null,
      icon_pos: 'left',
      icon_pos_value: 0,
      icon_raster2xUrl: null,
      icon_rasterUrl: null,
      icon_rm_id: null,
      'current-icon_color': 'ffffff',
      'current-icon_color_opacity': 100,
      'current-icon_rasterUrl': null,
      'current-icon_raster2xUrl': null,
      'hover-icon_color': 'ffffff',
      'hover-icon_color_opacity': 100,
      'hover-icon_raster2xUrl': null,
      'hover-icon_rasterUrl': null,
      // Стили селектов атрибутов
      'attrs-background-color': '282828',
      'attrs-background-color-opacity': 100,
      'attrs-border-radius': 30,
      'attrs-border-width': 0,
      'attrs-border-color': '000000',
      'attrs-border-color-opacity': 100,
      'attrs-font-family': 'Arial',
      'attrs-font-style': 'normal',
      'attrs-font-weight': 400,
      'attrs-font-size': 18,
      'attrs-color': 'ffffff',
      'attrs-color-opacity': 100,
      'attrs-letter-spacing': 'inherit',
      'attrs-text-align': 'center',
      'attrs-hover-background-color': '181818',
      'attrs-hover-background-color-opacity': 100,
      'attrs-hover-border-radius': 30,
      'attrs-hover-border-width': 0,
      'attrs-hover-border-color': '000000',
      'attrs-hover-border-color-opacity': 100,
      'attrs-hover-font-family': 'Arial',
      'attrs-hover-font-style': 'normal',
      'attrs-hover-font-weight': 400,
      'attrs-hover-color': 'ffffff',
      'attrs-hover-color-opacity': 100,
      'attrs-hover-font-size': 18,
      'attrs-hover-letter-spacing': 'inherit',
      'attrs-icon-size': 1.0,
    },
  }
);

export default isEnabled('constructor.eCommerce') ? addToCartBlock : undefined;
