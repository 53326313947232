/**
 * Конструктор для виджета Google Maps
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockFrameClass from '../block-frame';
import BlockClass from '../block';
import templates from '../../../templates/constructor/blocks/gmaps.tpl';
import InitUtils from '../../common/init-utils';
import Events from '../../common/events';

const GmapsBlock = BlockClass.extend(
  {
    name: 'Google Maps',
    sort_index: 11, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'gmaps',

    icon_color: '#88693D',

    initial_controls: [
      'gmaps_settings',
      'gmaps_edit',
      'common_animation',
      'common_position',
      'common_layer',
      'common_lock',
    ],

    initialize: function(model, workspace) {
      this.mag = workspace.mag;
      this.frameClass = gmapsFrame;
      this.initBlock(model, workspace);
      this.initOpts();
    },

    render: function() {
      BlockClass.prototype.render.apply(this, arguments);
      this.triggerReady();

      this.$el.addClass('gmaps');

      this.$content.html(templates['template-constructor-block-gmaps']());
      this.$search = $(templates['template-constructor-block-gmaps-search-control']()); // Панелька с инпутами
      this.search = this.$search.get(0);
      this.$source = this.$search.find('.rm-search.source'); // Инпут поиска расположения или начала маршрута
      this.$destination = this.$search.find('.rm-search.destination'); // Инпут поиска конца маршрута

      this.$apiKeyBlock = this.$content.find('.rm-gmaps-key');
      this.$apiKeyInput = this.$apiKeyBlock.find('input'); // Инпут ввода api ключа
      this.$apiKeyApply = this.$apiKeyBlock.find('button'); // Apply api key

      // Минимальные габариты
      _.extend(this.frame, {
        minwidth: 240,
        minheight: 210,
      });

      this.setControls();
      this.generateMap(this.bindLogic);
    },

    generateMap: function(callback) {
      var apiKey = this.model.get('api_key');
      this.$apiKeyBlock[!apiKey ? 'removeClass' : 'addClass']('hidden');

      InitUtils.initGMapsAPI(
        apiKey,
        function() {
          var type = this.model.get('current_type');

          var marker_lat,
            marker_lng,
            direction_start_lat,
            direction_start_lng,
            direction_end_lat,
            direction_end_lng,
            direction_route_type;

          _.defer(
            function() {
              // Без defer несколько блоков/виджетов отрисовываются с глюками рендеринга
              this.map = new google.maps.Map(this.$('.map-container').get(0), {
                zoom: this.model.get('map_zoom'),
                center: new google.maps.LatLng(this.model.get('center_lat'), this.model.get('center_lng')),
                mapTypeId: this.model.get('map_type_id') || google.maps.MapTypeId.ROADMAP,
                streetViewControl: false,
              });

              this.marker = new google.maps.Marker({
                map: this.map,
                draggable: true,
              });

              // Функционал автозавершения/геокодирования ввода
              this.sourceAutocomplete = new google.maps.places.Autocomplete(this.$source.get(0), {});
              this.destinationAutocomplete = new google.maps.places.Autocomplete(this.$destination.get(0), {});

              // Для построения и отображения маршрута
              this.directionsService = new google.maps.DirectionsService();
              this.directionsDisplay = new google.maps.DirectionsRenderer();

              marker_lat = this.model.get('marker_lat');
              marker_lng = this.model.get('marker_lng');
              if (marker_lat && marker_lng && !(type == 'directions')) {
                this.dropMarker(new google.maps.LatLng(marker_lat, marker_lng));
              }

              direction_start_lat = this.model.get('direction_start_lat');
              direction_start_lng = this.model.get('direction_start_lng');
              direction_end_lat = this.model.get('direction_end_lat');
              direction_end_lng = this.model.get('direction_end_lng');
              direction_route_type = this.model.get('direction_route_type');
              // Строим маршрут, если есть данные
              if (
                direction_start_lat &&
                direction_start_lng &&
                direction_end_lat &&
                direction_end_lng &&
                direction_route_type
              ) {
                this.source = new google.maps.LatLng(direction_start_lat, direction_start_lng);
                this.destination = new google.maps.LatLng(direction_end_lat, direction_end_lng);
              }

              this.onMapTypeChange();
              this.onShowControlsChange();

              this.redraw(this.model, {
                skipTriggerRedraw: true,
                doNotRedrawPacksFrames: true,
                doNotRedrawBottomShiftLine: true,
              });

              if (callback) {
                callback();
              }
            }.bind(this)
          );
        }.bind(this)
      );
    },

    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      BlockClass.prototype.redraw.apply(this, arguments);
    },

    onMapTypeChange: function(model) {
      var type = this.model.get('current_type');

      this.$destination.toggleClass('hidden', type !== 'directions'); // Второй инпут нужен только в режиме маршрутов
      this.marker.setVisible(type !== 'directions'); // Маркер виден только не в режиме маршрутов
      if (type !== 'directions') {
        this.clearRoute();
      } else {
        this.drawRoute(!model); // Не зумим карту если вызывали onMapTypeChange напрямую, без параметров
      }

      // Режим кастомной стилизации карты
      if (type == 'custom' && this.model.get('custom_json')) {
        this.applyCustomStyle();
      } else {
        this.clearCustomStyle();
      }
    },

    onSourceChage: function() {
      var place = this.sourceAutocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      this.source = place.geometry.location;

      // Если в режиме маршрута, то изменилось начало маршрута, иначе просто ищем локацию для маркера
      if (this.model.get('current_type') == 'directions') {
        return this.drawRoute();
      }

      this.marker.setVisible(false);

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17); // Why 17? Because it looks good.
      }

      // ВАЖНО. Сохраняем полученный из геолокации форматированный адрес в модель,
      // т.к. при повтороном рендеринге его получить будет нельзя -  он основывается на том,
      // что пользователь ввел в инпут поиска (может быть дом, а может и крупный объект типа города)
      this.model.set('formatted_address', place.formatted_address);

      this.dropMarker(place.geometry.location);
    },

    onDestinationChange: function() {
      var place = this.destinationAutocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      this.destination = place.geometry.location;

      this.drawRoute();
    },

    drawRoute: function(preserveViewport) {
      var type = this.model.get('direction_route_type');

      if (!(this.source && this.destination && type)) {
        return;
      }

      this.directionsDisplay.setMap(null);
      this.directionsService.route(
        {
          origin: this.source,
          destination: this.destination,
          travelMode: google.maps.TravelMode[type.toUpperCase()],
        },
        function(result, status) {
          var center;
          if (status == google.maps.DirectionsStatus.OK) {
            this.directionsDisplay.setDirections(result);
            this.directionsDisplay.setOptions({ preserveViewport: preserveViewport || false });
            this.directionsDisplay.setMap(this.map);

            // Сохраняем параметры маршрута в модель
            this.model.set({
              direction_start_lat: this.source.lat(),
              direction_start_lng: this.source.lng(),
              direction_end_lat: this.destination.lat(),
              direction_end_lng: this.destination.lng(),
            });

            // Сохраняем параметры центра и зума, т.к. рендерер маршрута сдвигает и зумит карты.
            // Чтобы потом сначла не рисовалась обычная карта, если мы не заходили в режим редактирования, а просто переключили тип карты
            google.maps.event.trigger(this.map, 'resize');
            _.defer(
              function() {
                center = this.map.getCenter();
                this.model.set({
                  center_lat: center.lat(),
                  center_lng: center.lng(),
                  map_zoom: this.map.getZoom(),
                });
              }.bind(this)
            );
          } else {
            if (!this.model.get('api_key')) {
              return;
            }

            this.showMessage("Sorry, there's no route for selected directions.");
          }
        }.bind(this)
      );
    },

    clearRoute: function() {
      this.directionsDisplay.setMap(null);
    },

    dropMarker: function(location) {
      this.marker.setPosition(location);
      this.marker.setAnimation(google.maps.Animation.DROP);
      this.marker.setVisible(true);
      this.markerDropped = true;
    },

    setControls: function() {
      this.controls = this.initial_controls;
    },

    bindLogic: function() {
      this.listenTo(this.model, 'change:current_type', this.onMapTypeChange);
      this.listenTo(this.model, 'change:custom_json', this.applyCustomStyle);
      this.listenTo(this.model, 'change:direction_route_type', this.onRouteTypeChange);
      this.listenTo(this.model, 'change:show_controls', this.onShowControlsChange);
      this.listenTo(this.model, 'change:api_key', this.onApiKeyChange);
      this.listenTo(this.model, 'change', this.hideMessage);
      google.maps.event.addListener(this.sourceAutocomplete, 'place_changed', this.onSourceChage);
      google.maps.event.addListener(this.destinationAutocomplete, 'place_changed', this.onDestinationChange);
      google.maps.event.addListener(this.marker, 'dragend', this.onMarkerDragEnd);
      google.maps.event.addListener(this.map, 'maptypeid_changed', this.onMapTypeIdChange);
      this.$source.on('click', this.onInputClick);
      this.$destination.on('click', this.onInputClick);
      this.$el.on(
        'dblclick',
        function() {
          if (!this.inEditState) {
            this.trigger('editMapClick');
          }
        }.bind(this)
      );

      // API ключ
      Events.on('gmaps:update_key', this.updateKey);
      this.$apiKeyInput.on('click', this.onApiKeyInputClick);
      this.$apiKeyApply.on('click', this.onApiKeyApply);
    },

    unbindLogic: function() {
      this.stopListening(this.model);

      if (window.google && window.google.maps) {
        google.maps.event.clearListeners(this.sourceAutocomplete, 'place_changed');
        google.maps.event.clearListeners(this.destinationAutocomplete, 'place_changed');
        google.maps.event.clearListeners(this.marker, 'dragend');
      }

      this.$source.off('click', this.onInputClick);
      this.$destination.off('click', this.onInputClick);
      this.$el.off('dblclick');

      Events.off('gmaps:update_key', this.updateKey);
      this.$apiKeyInput.off('click', this.onApiKeyInputClick);
      this.$apiKeyApply.off('click', this.onApiKeyApply);
    },

    enterEditState: function() {
      this.inEditState = true;
      this.disableDragging = true; // Запрещаем таскание блока, чтобы таскать карту
      this.$('.map-overlay').hide();
      this.$el.addClass('edit-map');
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.search); // Показываем инпуты поиска
      _.delay(
        function() {
          this.$source.focus();
        }.bind(this),
        300
      );
      this.onShowControlsChange();
    },

    exitEditState: function() {
      var center,
        markerPosition,
        changes = {};

      if (!this.inEditState) {
        return;
      }
      this.disableDragging = false;
      this.$('.map-overlay').show();
      this.$el.removeClass('edit-map');
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].pop();
      this.inEditState = false;

      this.onShowControlsChange();

      // Сохраняем параметры карты
      center = this.map.getCenter();
      _.extend(changes, {
        center_lat: center.lat(),
        center_lng: center.lng(),
        map_zoom: this.map.getZoom(),
      });

      // Сохраняем параметры маркера
      if (this.markerDropped) {
        markerPosition = this.marker.getPosition();
        _.extend(changes, {
          marker_lat: markerPosition.lat(),
          marker_lng: markerPosition.lng(),
        });
      }

      this.model.set(changes);
    },

    onMarkerDragEnd: function() {
      var position = this.marker.getPosition();
      this.model.set({
        marker_lat: position.lat(),
        marker_lng: position.lng(),

        // После перетаскивания маркера нормальный, полученный из гелокации адрес, теряет смысл
        // при отображении попапа с адресом в виджете будем делать геолокация объекта по координатам маркера
        formatted_address: '',
      });
    },

    // Тип карты Google
    onMapTypeIdChange: function() {
      var map_type_id = this.map.getMapTypeId();
      this.model.set({ map_type_id: map_type_id });
    },

    onInputClick: function(e) {
      var $target = $(e.currentTarget);
      _.defer(function() {
        $target.select();
      });
    },

    applyCustomStyle: function() {
      var styles = this.model.get('custom_json');

      if (!styles) {
        this.map.setOptions({ styles: null });
      } else {
        this.map.setOptions({ styles: JSON.parse(styles) }); // Джейсон уже отвалидирован в коде панели тулбара
      }
    },

    clearCustomStyle: function() {
      this.map.setOptions({ styles: null });
    },

    onRouteTypeChange: function() {
      this.drawRoute();
    },

    onShowControlsChange: function() {
      var show = this.inEditState || this.model.get('show_controls');

      // Чтобы лишний раз не перерерисовывать, а то смаргивает
      if (show == this.isControlsVisible) {
        return;
      }

      this.map.setOptions({
        panControl: show,
        zoomControl: show,
        mapTypeControl: show,
        scaleControl: show,
      });

      this.isControlsVisible = show;
    },

    /**
     * Колбэк на изменение апи ключа карт
     *
     * Когда меняется ключ и он валидный, удаляем старый gmaps и рендерим карту заново
     * Здесь же задаем этот ключ для всех других моделей gmaps в этом проекте
     * т.к. нельзя подгрузить больше несколько скриптов гугла с разными ключами
     */
    onApiKeyChange: function(model, key) {
      this.model.save();

      Events.trigger('gmaps:update_key', this.model.get('api_key'), this.model.get('_id'));
      this.regenerateMap();

      // Сохраняем ключ в mag.opts
      this.viewOpts.saveOpts({ gmaps_key: key });
    },

    /**
     * Обновление ключа (вызывается из другой модели)
     *
     * Обновление ключа внутри данной модели, при изменении других (чтобы у всех карт был один ключ)
     * Проверяем по id, чтобы не обновлять ключ модели, где его только что поменяли
     */
    updateKey: function(key, id) {
      if (this.model.get('_id') !== id && this.model.get('api_key') !== key) {
        this.model.set('api_key', key, { silent: true });
        this.model.save();

        this.regenerateMap();
      }
    },

    regenerateMap: function() {
      InitUtils.destroyGMapsAPI();

      _.defer(
        function() {
          this.generateMap();
        }.bind(this)
      );
    },

    onApiKeyInputClick: function(e) {
      e.stopImmediatePropagation();
    },

    onApiKeyApply: function(e) {
      e.stopImmediatePropagation();

      var value = this.$apiKeyInput.val();

      if (value && value.length === 39) {
        this.model.set('api_key', value);

        this.$apiKeyInput.val('');
        this.$apiKeyApply.addClass('hidden');
      }
    },

    /**
     * Инициализация модели для mag.opts
     */
    initOpts: function() {
      var self = this;
      var ViewOpts = Backbone.Model.extend({ url: '/api/mag/opts/' + this.mag.get('num_id') });
      this.viewOpts = new ViewOpts(this.mag.get('opts') || {});

      this.viewOpts.saveOpts = function() {
        this.save.apply(this, arguments);
        self.mag.set('opts', this.toJSON());
      };
    },

    showMessage: function(message) {
      this.$('.message-overlay')
        .text(message)
        .removeClass('invisible');
    },

    hideMessage: function() {
      this.$('.message-overlay').addClass('invisible');
    },

    deselect: function() {
      BlockClass.prototype.deselect.apply(this, arguments);

      this.exitEditState();
    },

    destroy: function() {
      this.unbindLogic();
      BlockClass.prototype.destroy.apply(this, arguments);
    },
  },
  {
    defaults: {
      current_type: 'standard', // 'standard', 'directions', 'custom'
      center_lat: 30,
      center_lng: -47,
      map_zoom: 2,
      show_controls: true,
      scrollwheel: true,
    },
  }
);

var gmapsFrame = BlockFrameClass.extend({
  /**
   * Ресайз
   */
  onResize: function(event, drag) {
    BlockFrameClass.prototype.onResize.apply(this, arguments);
    // Карте нужно вручную сообщить, что изменились габариты контейнера
    this.block.map && window.google && window.google.maps && google.maps.event.trigger(this.block.map, 'resize');
  },
});

export default GmapsBlock;
