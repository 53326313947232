/**
 *
 *	Ресайз workspace_inside_block для виджета Hotspot
 *
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';

const HotspotResizeClass = Backbone.View.extend({
  initialize: function(options) {
    options = options || {};

    _.bindAll(this);

    if (!(options.positions || options.positions.length)) return;

    this.positions = options.positions;
    this.workspace_inside_block = options.workspace_inside_block;

    this.render();

    this.bind_events();
  },

  render: function() {
    this.resize_handles_fragment = window.document.createDocumentFragment();

    // создаем элементы ручек-ресайза
    // для заданных краев блока.
    this.positions.forEach(
      _.bind(function(position) {
        var $resize_handle = $('<div/>');

        $resize_handle
          .addClass('resize-handle')
          .addClass(position)
          .attr('data-pos', position)
          .appendTo(this.resize_handles_fragment);
      }, this)
    );

    // вставляем в ДОМ.
    this.workspace_inside_block.$el.append(this.resize_handles_fragment);

    this.$resize_handles = this.workspace_inside_block.$el.children('.resize-handle');

    this.apply_resize_handles_visual_state();
  },

  // показывает и скрывает ручки ресайза
  // в зависимости от набора виджетов в внутриблоковм воркспейсе
  // а также положения внутриблокового воркспейса (для хотспота).
  apply_resize_handles_visual_state: function() {
    var workspace_position = this.workspace_inside_block.block.model.get('tip_pos');

    // у подсказки хотспота (она же внутриблоковый воркспейс)
    // в разных положения надо показывать\скрывать разные ручки-ресайза.

    var visible_blocks = this.workspace_inside_block.get_visible_blocks();

    if (!visible_blocks.length) {
      // если не создано ни одного вложенного виджета,
      // или все вложенные виджеты скрыты.

      // скрываем все ручки ресайза.
      // в случае подсказки хотспот виджета,
      // покажется виджет-селектор в изначальном состоянии,
      // когда видимых виджетов нет.
      this.$resize_handles.addClass('hidden');

      return;
    }

    var picture_block = _.find(visible_blocks, function(block) {
      return block.model.get('type') === 'picture';
    });

    // сначала показываем все ручки-ресайза,
    // т.к. какая-то может быть скрыта, хотя
    // при новой позиции подсказки или новом наборе вложенныех виджетов
    // она должна быть показана.
    this.$resize_handles.removeClass('hidden');

    // строгая проверка на false для того, чтобы изначально когда картинку даже не пытались загрузить этот код не срабатывал (там originalLoaded == undefined),
    // тут повсюду дерьмокод, я не стал портить картину и поднакинул
    if (picture_block && picture_block.originalLoaded === false) {
      // скрываем все ручки ресайза.
      // если оригинальная картинка еще не подгрузилась.
      // логика для события
      // loading_original_picture в-воркспейса,
      // которое триггерит картинка,
      // когда при инициализации и входе в огр. кроп моде
      // грузит оригинал или при смене картинки.
      this.$resize_handles.addClass('hidden');
    } else if (visible_blocks.length === 1) {
      // если в воркспейсе (подсказке)
      // только один видимый виджет.

      var block = visible_blocks[0];

      var block_type = block.model.get('type');

      if (block_type === 'text') {
        // если в воркспейсе только текстовый виджет,
        // то скрываем ручки ресайза высоты.
        // текстовый виджет нельзя ресайзить по высоте,
        // чтобы не было неконтролируемых пустот по высоте
        // в подсказке — их можно задать паддингами текста.
        // при редактировании текста мы сами подстраиваем
        // текстовый виджет под высотку контента,
        // а вслед за этим и подсказку.
        // при ресайзе текста по ширине мы подстраиваем его
        // высоту под высоту контента — высота контента текста
        // может измениться, т.к больше текста влезет или не влезет
        // по высоте с новой высотой.

        this.$resize_handles.filter('[data-pos="bottom"]').addClass('hidden');

        this.$resize_handles.filter('[data-pos="top"]').addClass('hidden');
      } else if (block_type === 'picture') {
        // если в воркспейса только виджет картинки,
        // то ничего не делаем, все ручки ресайза
        // уже показаны или скрыты совсем.
      }
    } else {
      // если в воркспейсе (подсказке)
      // несколько видимых виджетов,
      // то ничего не делаем, все ручки ресайза
      // уже показаны.
    }

    // затем скрываем ручку-ресайза со стороны точки.
    if (workspace_position === 'top') {
      this.$resize_handles.filter('[data-pos="bottom"]').addClass('hidden');
    } else if (workspace_position === 'right') {
      this.$resize_handles.filter('[data-pos="left"]').addClass('hidden');
    } else if (workspace_position === 'bottom') {
      this.$resize_handles.filter('[data-pos="top"]').addClass('hidden');
    } else if (workspace_position === 'left') {
      this.$resize_handles.filter('[data-pos="right"]').addClass('hidden');
    }
  },

  bind_events: function() {
    // навешиваем обработчики таскания за края
    // внутриблокового воркспейса.
    $(this.$resize_handles)
      .drag('start', this.on_resize_start)
      .drag(this.on_resize)
      .drag('end', this.on_resize_end);

    this.listenTo(this.workspace_inside_block.block.model, 'change:tip_pos', this.apply_resize_handles_visual_state);
    this.listenTo(this.workspace_inside_block.block.model, 'change:wids', this.apply_resize_handles_visual_state);
    this.listenTo(
      this.workspace_inside_block.block.model.widgets_collection,
      'change:hidden',
      this.apply_resize_handles_visual_state
    );
    this.listenTo(this.workspace_inside_block, 'loading_original_picture', this.apply_resize_handles_visual_state);
  },

  on_resize_start: function(e, drag) {
    var $handle = $(e.currentTarget),
      handle_pos = $handle.data('pos'),
      box_on_drag_start = this.workspace_inside_block.get_box_data(),
      min_width = this.workspace_inside_block.min_width,
      min_height = this.workspace_inside_block.min_height,
      max_width = 9999,
      max_height = 9999,
      constraints = null,
      visible_blocks = this.workspace_inside_block.get_visible_blocks();

    // нужно в on_resize.
    drag.scrollTop = this.workspace_inside_block.page_workspace.$container.scrollTop();

    var picture_block = _.find(visible_blocks, function(block) {
      return block.model.get('type') === 'picture';
    });

    var text_block = _.find(visible_blocks, function(block) {
      return block.model.get('type') === 'text';
    });

    if (text_block && text_block.isEditMode) return; // Нельзя менять размеры если редактируем текст

    if (handle_pos === 'top' || handle_pos === 'bottom') {
      // меняем высоту.

      if (visible_blocks.length > 1) {
        // если в в-воркспейсе
        // несколько видимых виджетов.

        text_block = _.find(visible_blocks, function(block) {
          return block.model.get('type') === 'text';
        });

        var text_box;

        var text_height;

        if (text_block) {
          // если среди видимых виджетов
          // присутствует виджет текста,
          // то минимальная высота в-воркспейса равна
          // высоте текстового блока + мин допустимая высоте в-воркспейса.
          text_box = text_block.getBoxData();

          text_height = text_box.h;

          min_height = text_height + this.workspace_inside_block.min_height;
        }
      }
    } else if (handle_pos === 'left' || handle_pos === 'right') {
      // меняем ширину.
    }

    if (picture_block) {
      // если в в-воркспейсе есть видимый виджет картинки,
      // то в-воркспейс при ресайзе будет иметь ограничения
      // такие же как у виджета картинки при кроп-ресайзе.

      // кроп картинки опирается на положения
      // бэкпика в воркспейса страницы.
      // картинка из-за смены позиции или ресайза подсказки
      // может изменить свое положение в воркспейсе страницы.
      // бэкпик картинки никак не перемещается вслед
      // за ней.
      // поэтому каждый раз при ресайзе подсказки
      // мы должны виртуально переопределить
      // где должен располагаться бэкпик картинки,
      // что при ресайзе подсказки верно отработали

      picture_block.model.off('change', picture_block.redraw);

      var workspace_box = box_on_drag_start;

      var picture_scale = picture_block.model.get('scale');

      var initialCrop = {
        left:
          workspace_box.left_abs +
          parseInt(picture_block.$el.css('left')) -
          Math.round(picture_block.model.get('cropX') * picture_scale),
        top:
          workspace_box.top_abs +
          parseInt(picture_block.$el.css('top')) -
          Math.round(picture_block.model.get('cropY') * picture_scale),
      };

      picture_block.initialCrop = initialCrop;

      var picture_original_w = picture_block.model.get('originalW');

      var picture_original_h = picture_block.model.get('originalH');

      picture_block.frame.constraints = {
        top: initialCrop.top,
        left: initialCrop.left,
        width: Math.round(picture_original_w * picture_scale),
        height: Math.round(picture_original_h * picture_scale),
      };

      constraints = picture_block.frame.constraints;
    }

    this.resize_params = {
      $handle: $handle,
      handle_pos: handle_pos,
      box_on_drag_start: box_on_drag_start,
      box_position: this.workspace_inside_block.block.model.get('tip_pos'),
      min_height: min_height,
      min_width: min_width,
      max_width: max_width,
      max_height: max_height,
      constraints: constraints,
      visible_blocks: visible_blocks,
      max_block_height: this.workspace_inside_block.block.model.get('tip_h'),
      picture_block: picture_block,
      picture_initial_crop_on_drag_start: picture_block && _.clone(picture_block.initialCrop),
      text_block: text_block,
    };
  },

  on_resize: function(e, drag) {
    var handle_pos = this.resize_params.handle_pos;

    // клонируем, иначе изменения бокса будут сохранятся
    // в референсный объект и при ресайзе мы будем всегда
    // иметь разный бокс, а нам во время ресайза
    // надо знать именно оригинальный бокс до начала ресайза.
    var box = _.clone(this.resize_params.box_on_drag_start);

    var start_y = drag.startY + drag.scrollTop;

    var end_y = e.pageY + this.workspace_inside_block.page_workspace.$container.scrollTop();

    drag.deltaY = end_y - start_y;

    var constraints;

    if (this.resize_params.constraints) {
      constraints = this.resize_params.constraints;
    }

    var visible_blocks = this.resize_params.visible_blocks;

    var picture_block = this.resize_params.picture_block;

    var text_block = this.resize_params.text_block;

    if (text_block && text_block.isEditMode) return; // Нельзя менять размеры если редактируем текст
    var text_content_height = 0;

    var box_position = this.resize_params.box_position;

    if (handle_pos === 'top' || handle_pos === 'bottom') {
      // меняем высоту.

      if (handle_pos === 'top') {
        box.top_abs += drag.deltaY;

        if (box_position === 'left' || box_position === 'right') {
          // * 2, т.к. растет в обе стороны визуально.
          box.height -= 2 * drag.deltaY;
        } else {
          box.height -= drag.deltaY;
        }
      } else if (handle_pos === 'bottom') {
        if (box_position === 'left' || box_position === 'right') {
          box.top_abs -= drag.deltaY;

          // * 2, т.к. растет в обе стороны визуально.
          box.height += 2 * drag.deltaY;
        } else {
          box.height += drag.deltaY;
        }
      }
    } else if (handle_pos === 'left' || handle_pos === 'right') {
      // меняем ширину.

      if (handle_pos === 'left') {
        if (box_position === 'top' || box_position === 'bottom') {
          box.left_abs += drag.deltaX;

          // * 2, т.к. растет в обе стороны визуально
          // при таком box_position.
          box.width -= 2 * drag.deltaX;
        } else {
          box.left_abs += drag.deltaX;

          box.width -= drag.deltaX;
        }
      } else if (handle_pos === 'right') {
        if (box_position === 'top' || box_position === 'bottom') {
          box.left_abs -= drag.deltaX;

          // * 2, т.к. растет в обе стороны визуально
          // при таком box_position.
          box.width += 2 * drag.deltaX;
        } else {
          box.width += drag.deltaX;
        }
      }
    }

    // проверяем ширину и высоту на минимально
    // и максимально допустимые значения.
    // если есть отклонение, то выставляем
    // предельное значение.

    if (box.height < this.resize_params.min_height) {
      // корректируем координаты в-воркспейса,
      // чтобы не сработали констрейнты.
      if (box_position === 'left' || box_position === 'right') {
        box.top_abs -= (this.resize_params.min_height - box.height) / 2;
      } else if (box_position === 'top') {
        box.top_abs -= this.resize_params.min_height - box.height;
      }

      box.height = this.resize_params.min_height;
    } else if (box.height > this.resize_params.max_height) {
      box.height = this.resize_params.max_height;
    }

    if (box.width < this.resize_params.min_width) {
      // корректируем координаты в-воркспейса,
      // чтобы не сработали констрейнты.
      if (box_position === 'top' || box_position === 'bottom') {
        box.left_abs -= (this.resize_params.min_width - box.width) / 2;
      } else if (box_position === 'left') {
        box.left_abs -= this.resize_params.min_width - box.width;
      }

      box.width = this.resize_params.min_width;
    } else if (box.width > this.resize_params.max_width) {
      box.width = this.resize_params.max_width;
    }

    if (handle_pos === 'left' || handle_pos === 'right') {
      // при ресайзе по ширине.

      visible_blocks.forEach(function(block) {
        var block_box = block.getBoxData();

        // выставляем каждому виджету ширину,
        // равную ширине в-воркспейса.
        block.frame.doResize(
          {
            left: block_box.x,
            top: block_box.y,
            width: box.width,
            height: block_box.h,
          },
          false
        );
      });

      if (text_block) {
        // если в воркспейсе есть в-текста,
        // то при ресайзе по ширине может
        // понадобитсья подогнать его высоту под высоту его контента,
        // а потом подогнать высоту подсказки под это дело
        if (picture_block) {
          var text_box = text_block.getBoxData();

          // вычитаем текущую высот текста из в-воркспейса.
          box.height -= text_box.h;

          if (box_position === 'top') {
            box.top_abs += text_box.h;
          } else if (box_position === 'left' || box_position === 'right') {
            box.top_abs += text_box.h / 2;
          }
        }

        text_block.$el
          .find('.text-preview')
          .children()
          .each(function() {
            // outerHeight — чтобы паддинги посчитались.
            text_content_height += $(this).outerHeight();
          });

        text_box = text_block.getBoxData();

        // выставляем каждому в-текста высоту,
        // равную высоте его контента в-воркспейса.
        text_block.frame.doResize(
          {
            left: text_box.x,
            top: text_box.y,
            width: text_box.w,
            height: text_content_height,
          },
          false
        );

        if (picture_block) {
          // добавляею скорректированную по контенту
          // высоту текста к высоте в-воркспейса.
          box.height += text_content_height;

          if (box_position === 'top') {
            box.top_abs -= text_content_height;
          } else if (box_position === 'left' || box_position === 'right') {
            box.top_abs -= text_content_height / 2;
          }

          // корректируем положение бэкпика
          // и кроп значения.

          var picture_box = picture_block.getBoxData();

          var backpic_top =
            box.top_abs +
            picture_box.y -
            Math.round(picture_block.model.get('cropY') * picture_block.model.get('scale'));

          if (picture_block.$backpic) {
            picture_block.$backpic.css('top', backpic_top);
          }

          picture_block.initialCrop.top = backpic_top;

          picture_block.frame.constraints.top = backpic_top;
        } else {
          // высота в-воркспейса = скорректированной по контенту
          // высоте текста.
          box.height = text_content_height;
        }

        // ставим новую высоту подсказки именно в референс,
        // чтобы следующий on_resize знал актуальную высоту подсказки
        // на момент ресайза.
        this.resize_params.box_on_drag_start.height = box.height;

        this.resize_params.box_on_drag_start.top_abs = box.top_abs;
      }
    } else if (handle_pos === 'top' || handle_pos === 'bottom') {
      if (picture_block) {
        // если есть виджет картинки, то при ресайзе в-воркспейса по высоте
        // виджет картинки будет занимать свободное по высоте пространтсво
        // делая себе кроп-ресайз.

        var other_blocks = _.reject(visible_blocks, function(block) {
          return block.model.get('type') === 'picture';
        });

        var picture_height = box.height;

        other_blocks.forEach(function(block) {
          var block_box = block.getBoxData();

          picture_height -= block_box.h;
        });

        var original_box = picture_block.getBoxData();

        picture_block.frame.doResize(
          {
            left: original_box.x,
            top: original_box.y,
            width: original_box.w,
            height: picture_height,
          },
          false
        );
      }
    }

    // Для верного Undo\Redo обязательно групповой сет, чтобы при групповом сейве в on_resize_end
    // уже были установлены корректные _firstAttributes у модели группы.
    // При первом таком групповом сете (в самом начале ресайза)
    // _firstAttributes и выставятся и тем самым
    // зафиксирует в модели группы состояние хотспота до начала ресайза,
    // чтобы потом к нему откатиться.
    this.workspace_inside_block.block.workspace.set_group(
      [
        {
          _id: this.workspace_inside_block.block.model.get('_id'),
          tip_w: box.width,
          tip_h: box.height,
        },
      ],
      { viewport: 'default', forceDefaultViewport: true }
    );

    // ограничения по ресайзу подсказки.
    // могут задаваться при колоночном позиционировании виджетов
    // и наличии виджета картинки внутри, чтобы корректно
    // работать совместно с ограниченным-кроп модом картинки.
    if (constraints) {
      var _box = {
        width: box.width,
        height: box.height,
        left_abs: box.left_abs,
        top_abs: box.top_abs,
      };

      var picture_initial_crop_on_drag_start = this.resize_params.picture_initial_crop_on_drag_start;

      if (text_block) {
        text_box = text_block.getBoxData();

        _box.height -= text_box.h;
      }

      if ((handle_pos === 'left' || handle_pos === 'right') && _box.width >= constraints.width) {
        // если ресайзим в-воркспейс по ширине и
        // картинка полностью вписалась в в-воркспейс по ширине.
        // значит смещение кропа не получится делать
        // делаем апскейл картинки.
        picture_box = picture_block.getBoxData();
        var x = picture_box.x;
        var y = picture_box.y;
        var h = picture_box.h;
        var w = _box.width;
        var pic_orig_w = picture_block.model.get('originalW');
        var pic_orig_h = picture_block.model.get('originalH');
        var origin_ratio = pic_orig_w / pic_orig_h;
        var crop = {};
        var scale = null;
        var pic = {};

        if (picture_block.model.get('ratio') > origin_ratio) {
          scale = w / pic_orig_w;

          crop = {
            cropX: 0,
            cropY: Math.round((pic_orig_h - h / scale) / 2),
            cropW: pic_orig_w,
            cropH: Math.round(h / scale),
          };

          pic = {
            width: w,
            height: Math.round(w / origin_ratio),
            top: -Math.round(crop.cropY * scale),
            left: 0,
          };
        } else {
          scale = h / pic_orig_h;

          crop = {
            cropX: 0,
            cropY: 0,
            cropW: Math.round(w / scale),
            cropH: pic_orig_h,
          };

          pic = {
            // Math.max, т.к. иногда при переходе через границу авткроп → автоскейл
            // ширина картинки рассчитывалась меньше
            // ширины подсказки и появлялись зазоры
            // между картинкой и подсказкой и был виден фон подсказки,
            // а такого быть не должно, картинка должна ровно вписыватсья в ширину
            // подсазки при данном сценарии ресайза.
            width: Math.max(Math.round(h * origin_ratio), _box.width),
            height: h,
            top: 0,
            left: 0,
          };
        }

        picture_block.model.set(
          _.extend(
            {
              scale: scale.toFixed(15),
              ratio: (w / h).toFixed(5),
            },
            crop
          ),
          { skipHistory: true }
        );

        picture_block.$picture && picture_block.$picture.css(pic);

        var backpic_css = {
          width: pic.width,
          height: pic.height,
          top: _box.top_abs + y + pic.top,
          left: _box.left_abs + x + pic.left,
        };

        if (picture_block.$backpic) {
          picture_block.$backpic.css(backpic_css);
        }

        picture_block.initialCrop = {
          top: _box.top_abs + y + pic.top,
          left: _box.left_abs + x + pic.left,
        };

        picture_block.frame.constraints = backpic_css;
      } else if ((handle_pos === 'top' || handle_pos === 'bottom') && _box.height >= constraints.height) {
        // если ресайзим в-воркспейс по высоте и
        // картинка полностью вписалась в отведенное
        // ей место в-воркспейсе по высоте.
        // значит смещение кропа не получится делать
        // делаем апскейл картинки.
        picture_box = picture_block.getBoxData();
        x = picture_box.x;
        y = picture_box.y;
        h = _box.height;
        w = picture_box.w;
        pic_orig_w = picture_block.model.get('originalW');
        pic_orig_h = picture_block.model.get('originalH');
        origin_ratio = pic_orig_w / pic_orig_h;
        crop = {};
        scale = null;
        pic = {};

        if (picture_block.model.get('ratio') > origin_ratio) {
          scale = w / pic_orig_w;

          crop = {
            cropX: 0,
            cropY: 0,
            cropW: pic_orig_w,
            cropH: Math.round(h / scale),
          };

          pic = {
            width: w,
            // Math.max, т.к. иногда при переходе через границу авткроп → автоскейл
            // высота картинки рассчитывалась меньше
            // высоты подсказки и появлялись зазоры
            // между картинкой и подсказкой и был виден фон подсказки,
            // а такого быть не должно, картинка должна ровно вписыватсья в высоту
            // подсазки при данном сценарии ресайза.
            height: Math.max(Math.round(w / origin_ratio), _box.height),
            top: 0,
            left: 0,
          };
        } else {
          scale = h / pic_orig_h;

          crop = {
            cropX: Math.round((pic_orig_w - w / scale) / 2),
            cropY: 0,
            cropW: Math.round(w / scale),
            cropH: pic_orig_h,
          };

          pic = {
            width: Math.round(h * origin_ratio),
            height: h,
            top: 0,
            left: -Math.round(crop.cropX * scale),
          };
        }

        picture_block.model.set(
          _.extend(
            {
              scale: scale.toFixed(15),
              ratio: (w / h).toFixed(5),
            },
            crop
          ),
          { skipHistory: true }
        );

        picture_block.$picture.css(pic);

        backpic_css = {
          width: pic.width,
          height: pic.height,
          top: _box.top_abs + y + pic.top,
          left: _box.left_abs + x + pic.left,
        };

        if (picture_block.$backpic) {
          picture_block.$backpic.css(backpic_css);
        }

        picture_block.initialCrop = {
          top: _box.top_abs + y + pic.top,
          left: _box.left_abs + x + pic.left,
        };

        picture_block.frame.constraints = backpic_css;
      } else if (_box.left_abs + _box.width > constraints.left + constraints.width) {
        // если мы превысили предел по ресайзу справа.

        // рассчитываем насколько мы превысили ограничение.
        var over_contstr = constraints.left + constraints.width - _box.left_abs - _box.width;

        // смещаем кроп.
        // picture_block.onCropResize
        // верно сместит картинку согласно initialCrop.
        picture_block.initialCrop.left = picture_initial_crop_on_drag_start.left - over_contstr;

        if (picture_block.$backpic) {
          // если виден бэкпик,
          // то смещаем его.

          picture_block.$backpic.css('left', picture_block.initialCrop.left);
        }

        picture_box = picture_block.getBoxData();

        picture_block.onCropResize({
          width: picture_box.w,
          height: picture_box.h,
          left: picture_box.x,
          top: picture_box.y,
        });
      } else if (_box.left_abs < constraints.left) {
        // если мы превысили предел по ресайзу слева.

        // рассчитываем насколько мы превысили ограничение.
        over_contstr = constraints.left - _box.left_abs;

        // смещаем кроп.
        // picture_block.onCropResize
        // верно сместит картинку согласно initialCrop.
        picture_block.initialCrop.left = picture_initial_crop_on_drag_start.left - over_contstr;

        if (picture_block.$backpic) {
          // если виден бэкпик,
          // то смещаем его.

          picture_block.$backpic.css('left', picture_block.initialCrop.left);
        }

        picture_box = picture_block.getBoxData();

        picture_block.onCropResize({
          width: picture_box.w,
          height: picture_box.h,
          left: picture_box.x,
          top: picture_box.y,
        });
      } else if (_box.top_abs < constraints.top) {
        // если мы превысили предел по ресайзу сверзу.

        // рассчитываем насколько мы превысили ограничение.
        over_contstr = constraints.top - box.top_abs;

        // смещаем кроп.
        // picture_block.onCropResize
        // верно сместит картинку согласно initialCrop.
        picture_block.initialCrop.top = picture_initial_crop_on_drag_start.top - over_contstr;

        if (picture_block.$backpic) {
          // если виден бэкпик,
          // то смещаем его.

          picture_block.$backpic.css('top', picture_block.initialCrop.top);
        }

        picture_box = picture_block.getBoxData();

        picture_block.onCropResize({
          width: picture_box.w,
          height: picture_box.h,
          left: picture_box.x,
          top: picture_box.y,
        });
      } else if (_box.top_abs + _box.height > constraints.top + constraints.height) {
        // достигли ограничения по ресайзу снизу.

        // рассчитываем насколько мы превысили ограничение.
        over_contstr = constraints.top + constraints.height - (_box.top_abs + _box.height);

        // смещаем кроп.
        // picture_block.onCropResize
        // верно сместит картинку согласно initialCrop.
        picture_block.initialCrop.top = picture_initial_crop_on_drag_start.top - over_contstr;

        if (picture_block.$backpic) {
          // если виден бэкпик,
          // то смещаем его.

          picture_block.$backpic.css('top', picture_block.initialCrop.top);
        }

        picture_box = picture_block.getBoxData();

        picture_block.onCropResize({
          width: picture_box.w,
          height: picture_box.h,
          left: picture_box.x,
          top: picture_box.y,
        });
      } else {
        picture_box = picture_block.getBoxData();

        picture_block.onCropResize({
          width: picture_box.w,
          height: picture_box.h,
          left: picture_box.x,
          top: picture_box.y,
        });
      }
    }
  },

  on_resize_end: function(e) {
    // подготавливаем чейнджсет
    // для группового сейва нескольких виджетов:
    // для родительского блока сохраняем ширину и высоту внутриблокового воркспейса,
    // а также сохраняем боксы вложенных виджетов.
    var picture_block,
      group_changeset = [
        {
          _id: this.workspace_inside_block.block.model.get('_id'),
          tip_w: this.workspace_inside_block.block.model.get('tip_w'),
          tip_h: this.workspace_inside_block.block.model.get('tip_h'),
        },
      ];

    this.resize_params.visible_blocks.forEach(
      function(block) {
        // используется в block.js → onClick(),
        // чтобы после ресайза вокрспейса не проходил первый клик
        // вложенному виджету.
        block._justResized = true;

        var changeset = _.extend(
          {
            _id: block.model.get('_id'),
          },
          block.getBoxData()
        );

        if (block.model.get('type') === 'picture') {
          picture_block = block;

          // при колоночном позиционировании виджетов
          // во внутриблоковом ворспейсе
          // картинка работает в особом кроп моде
          // при ресайзе воркспейса они помимо смены
          // своих размеров блока также и кропается,
          // поэтому сохраняем её аттрибуты кропа.

          _.extend(changeset, {
            cropX: block.model.get('cropX'),
            cropY: block.model.get('cropY'),
            cropW: block.model.get('cropW'),
            cropH: block.model.get('cropH'),
            ratio: block.model.get('ratio'),
            scale: block.model.get('scale'),
          });

          !block.isAnimated() &&
            block.getFinalImage(
              function(error) {
                if (error) console.log('w-picture getFinalImage error: ', error);
              },
              { silent: true, patch: true }
            );
        }

        group_changeset.push(changeset);

        block.model.set(changeset, { skipHistory: true, forceDefaultViewport: true });
      }.bind(this)
    );

    // Делаем групповой сейв нескольких виджетов.
    this.workspace_inside_block.block.workspace.save_group(group_changeset, {
      isHotspot: true,
      viewport: 'default',
      forceDefaultViewport: true,
    });

    if (picture_block) {
      picture_block.model.on('change', picture_block.redraw);
    }
  },

  // показать рамку
  show: function() {
    this.workspace_inside_block.$el.addClass('resize-handles-visible');
  },

  // скрыть рамку
  hide: function() {
    this.workspace_inside_block.$el.removeClass('resize-handles-visible');
  },

  destroy: function() {
    this.resize_handles_fragment && $(this.resize_handles_fragment).remove();

    this.stopListening();
  },
});

export default HotspotResizeClass;
