/**
 * Класс для панели поделиться, вызываемой из кнопки меню тулбара
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/viewer/share.tpl';
import ShareUtils from '../libs/shareutils';
import { Constants } from '../common/utils';
import Device from '../common/device';

const ShareClass = Backbone.View.extend(
  {
    template: templates['template-viewer-share'],

    events: {
      'click .buttons-wrapper .button': 'share',
      'click .send-report': 'share',

      'click .open-embed': 'openEmbed',
      'click .close': 'hide',

      'click .embed-wrapper .back': 'closeEmbed',
      'click .embed-wrapper .embed-code': 'selectEmbedCode',
      'keyup .embed-wrapper .embed-code': 'onKeyUp',

      'click .type-selector': 'onTypeSelectorClick',

      'click .size-selector': 'onSizeSelectorClick',

      click: 'onClick',
    },

    initialize: function(params) {
      _.bindAll(this);

      _.extend(this, params);

      return this; // для вызова по цепочке
    },

    render: function() {
      // создаем вьюху стандартым методом бекбона:
      // устанавливаем el, $el и делегацию событий из списка events
      this.setElement(
        this.template({
          hasOverlay: Device.isPhone,
          common: RM.common,
        })
      );

      this.$el.appendTo(this.$container);

      // по умолчанию выбираем full mode
      this.selectFullMode();

      // навешиваем утягивание шера, когда он открыт (только для телефона)
      if (Device.isPhone) {
        this.shareSwipe = this.$el
          .RMSwipe({
            minTransitionSpeed: 200,
            maxTransitionSpeed: 400,
            rubberBand: false,

            // опрашиваеся после отпускания пальца если мы задетектили что жест был достаточен для триггера действия
            // от резальтата зависит будет ли плагин сам доводить анимацию объектов до нового состояния и потом вызовет колбек
            // или же сразу вызовет колбек
            beforeAutoAnimation: _.bind(function(dir, shift) {
              return { autoAnimate: true, immediateCallback: true };
            }, this),

            getCurrentConstraints: _.bind(function($target) {
              // функция опрашивается в самом начале свайпа чтобы знать в каком направлении ожидается жест и его ограничения по сдвигу
              var res = {},
                $item = this.$el.filter('.share-popup');

              // в качестве референсного объекта берем саму панельку шера
              // потому что только она двигается и только на нее можно навешщивать ожидание транзишена и пр.
              // оверлей ведь статичен
              res['$referenceItem'] = $item;

              res['$moveItems'] = $item;
              res['up'] = { max: 0, noTrigger: true }; // noTrigger:true означает что в этом направлении триггерить жест не надо, но сам факт присутствия объекта у данного направления 'up' говорит о том, что в данном направлении будет работать пружинка и движение в этом направлении разрешено для инициации жеста
              res['down'] = { max: $item.height() };

              return res;
            }, this),

            callback: _.bind(function(dir) {
              this.hide();
            }, this),
          })
          .data('swipe');
      }

      this.listenTo(this.mag, 'pageChanged', this.onPageChange);
      this.listenTo(this.mag, 'finalPageShown', this.onFinalPageShow);
      this.listenTo(this.mag, 'keypress-' + $.keycodes.esc, this.hide);

      $('body').on('click', this.hideOnBodyClick);

      return this; // для вызова по цепочке
    },

    show: function() {
      if (!this.shown) {
        clearTimeout(this.hideTimeout);
        this.$el.removeClass('hidden');
        this.$el.height(); // заставляем элемент перерисоваться до начала animation frame, чтобы сработал последующий транзишен на .show-popup
        this.$el.addClass('show-popup');
        this.$container.addClass('no-hover'); // это важно чтобы ховер стили не работали и альты RMAltText
        this.shown = true;

        this.updateEmbed(); // Чтобы начала грузиться превьюшка в панельке эмбеда
      }

      return this; // для вызова по цепочке
    },

    toggle: function() {
      if (this.shown) return this.hide();
      // для вызова по цепочке
      else return this.show(); // для вызова по цепочке
    },

    hide: function() {
      if (this.shown) {
        this.$el.removeClass('show-popup');
        this.$container.removeClass('no-hover'); // это важно чтобы ховер стили работали и альты RMAltText
        // обязательно надо прятать, панелька (а для телефона и оверлей) находтся в собственных слоях рендеринга
        // и чтобы они не занимали память под текстуры их надо прятать через display:none пока они не нужны
        this.hideTimeout = setTimeout(
          _.bind(function() {
            this.closeEmbed();
            this.$el.addClass('hidden');
          }, this),
          400
        );
        this.shown = false;
      }

      return this; // для вызова по цепочке
    },

    destroy: function() {
      clearTimeout(this.hideTimeout);

      $('body').off('click', this.hideOnBodyClick);

      this.shareSwipe && this.shareSwipe.destroy();

      // удаляем вьюху стандратным методом бекбона:
      // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
      return this.remove(); // return this для вызова по цепочке
    },

    hideOnBodyClick: function(e) {
      if (Device.isPhone) return;

      if ($(e.target).closest(this.$container).length) return;

      this.hide();
    },

    onPageChange: function(page, params) {
      this.hide();
    },

    onFinalPageShow: function() {
      this.hide();
    },

    onClick: function(e) {
      if (Device.isPhone && $(e.currentTarget).hasClass('share-popup-overlay')) this.hide();
    },

    onKeyUp: function(e) {
      e.stopPropagation();
    },

    openEmbed: function(e) {
      if (this.router.analytics) {
        this.router.analytics.sendEvent('Embed', 'contents (' + this.shareMode + ')');
      }

      this.selectEmbedCode();

      this.$el.addClass('show-embed');
    },

    // обновляет код эмбеда
    updateEmbed: function() {
      var src,
        currentPage = this.mag.currentPage || this.mag.pages[0],
        size = this.$('.size-selector').attr('data-selected'),
        SIZES = {
          responsive: 'responsive',
          big: 512,
          small: 288,
        };

      this.$('.embed-wrapper .embed-code').val(
        ShareClass.getEmbedCode({
          shareMode: this.shareMode,
          cur_page_num_id: currentPage.num_id,
          mag_num_id: this.mag.num_id,
          size: SIZES[size],
        })
      );

      src = this.mag.model.getPageScreenshot(256, {
        _id: this.shareMode == 'page' ? currentPage._id : this.mag.coverPid,
      });

      if (this.currentEmbedSrc !== src) {
        this.$('.thumb-pic').attr('src', src);
        this.currentEmbedSrc = src;
      }
    },

    closeEmbed: function(e) {
      this.$el.removeClass('show-embed');
    },

    share: function(e) {
      ShareUtils.share({
        host: (RM.common.embedDomainName || window.location.protocol + '//' + window.location.hostname) + '/',
        tp: $(e.currentTarget).attr('data-tp'), // facebook, twitter, pinterest ...
        source: 'share panel', // для аналитики
        page: this.mag.currentPage || this.mag.pages[0],
        mag: _.extend(this.mag.model.toJSON(), { user: this.mag.user }),
        forProject: this.shareMode == 'full',
        customDomain: !!(RM.common.isDomainViewer || RM.common.embedDomainName),
        customDomainProfile: !!(RM.common.customDomainProfile || RM.common.embedDomainType == 'User'),
        analytics: this.router.analytics,
      });
    },

    onTypeSelectorClick: function(e) {
      if (Device.isPhone) {
        // на ифоне переключалка состоит из двух кнопок
        var $obj = $(e.target);

        if ($obj.closest('.type-full').length) this.selectFullMode();

        if ($obj.closest('.type-page').length) this.selectPageMode();
      } else {
        // а на десктопе-планшете это один переключатель, который меняет свое состояние при клике
        var tp = this.$('.type-selector').attr('data-tp') || 'page';

        if (tp == 'page') this.selectFullMode();
        else this.selectPageMode();
      }
    },

    onSizeSelectorClick: function(e) {
      var $selector = $(e.currentTarget),
        oldSize = $selector.attr('data-selected'),
        sizes,
        index;

      sizes = _.map($selector.find('.size'), function(size) {
        return $(size).attr('data-size');
      });
      index = sizes.indexOf(oldSize) + 1;
      index = index >= sizes.length ? 0 : index; // Зацикливаем

      $selector.attr('data-selected', sizes[index]);

      this.updateEmbed();
    },

    selectPageMode: function() {
      this.$('.type-selector').attr('data-tp', 'page');
      this.shareMode = 'page';
      this.updateEmbed();
    },

    selectFullMode: function() {
      this.$('.type-selector').attr('data-tp', 'full');
      this.shareMode = 'full';
      this.updateEmbed();
    },

    selectEmbedCode: function(e) {
      // Для моб. девайсов вообще не делаем автоселект, иначе не появляется контекстное меню (Copy, ...)
      if (Device.isDesktop) {
        this.$('.embed-wrapper .embed-code')
          .get(0)
          .setSelectionRange(0, 9999);
      }
    },
  },
  {
    /**
     * Возвращает код эмбеда
     * @param opts
     */
    getEmbedCode: function(opts) {
      var currentLocation,
        uri,
        url,
        link,
        script_url = Constants.EMBED_SCRIPT_INIT,
        script = '<script async src="' + script_url + '" id="readymag-embed-init"></script>',
        params;

      // Если есть параметр RM.common.embedDomainName -
      // значит вьювер открыт с основного сайта в режиме эмбеда с эмуляцией кастомного домена
      currentLocation = RM.common.embedDomainName || window.location.protocol + '//' + window.location.hostname;

      uri = opts.shareMode == 'full' ? opts.mag_num_id : 'p' + opts.cur_page_num_id;
      params = ' data-uri="' + uri + '" ';

      params += ' data-width="' + opts.size + '" ';

      // в режиме кастомного домена нужен домен с которого эмбеддим
      if (RM.common.isDomainViewer || !!RM.common.embedDomainName) {
        params += ' data-domain="' + currentLocation + '" ';

        // Если домен привязан к мэгу, то uri мэга не нужен
        if (!RM.common.customDomainProfile && !(RM.common.embedDomainType == 'User')) {
          uri = opts.shareMode == 'full' ? '' : 'p' + opts.cur_page_num_id;
        }
      }

      // Нужно для тестирования, чтобы передать origin, с которого будет открываться эмбед
      // Иначе он всегда открывается с embed.readymag.com
      if (!Constants.IS_LIVE) {
        params += ' data-origin="' + Constants.readymag_embed_host + '" ';
      }

      url = currentLocation + '/' + uri;
      link = '<a class="rm-mag-embed" href="' + url + '"' + params + ' target="_blank">' + url + '</a>\n';

      return link + script;
    },
  }
);

export default ShareClass;
