import common_layer from './common_layer';
import common_align from './common_align';
import common_animation from './common_animation';
import common_pack from './common_pack';
import common_unpack from './common_unpack';
import common_rotation from './common_rotation';
import common_position from './common_position';
import text_styles from './text_styles';
import text_typography from './text_typography';
import text_bius from './text_bius';
import text_align from './text_align';
import text_autosize from './text_autosize';
import anchor_picker from './anchor_picker';
import text_link from './text_link';
import text_color from './text_color';
import text_columns from './text_columns';
import text_edit from './text_edit';
import picture_effects from './picture_effects';
import picture_crop from './picture_crop';
import picture_fwpos from './picture_fwpos';
import picture_link from './picture_link';
import picture_1x1 from './picture_1x1';
import picture_color from './picture_color';
import picture_settings from './picture_settings';
import slideshow_manager from './slideshow_manager';
import shape_color from './shape_color';
import shape_settings from './shape_settings';
import shape_1x1 from './shape_1x1';
import shape_icon from './shape_icon';
import gmaps_edit from './gmaps_edit';
import gmaps_settings from './gmaps_settings';
import common_lock from './common_lock';
import button_edit from './button_edit';
import button_settings from './button_settings';
import button_icon from './button_icon';
import hotspot_add from './hotspot_add';
import hotspot_pin_settings from './hotspot_pin_settings';
import hotspot_tip_settings from './hotspot_tip_settings';
import form_content from './form_content';
import form_endpoints from './form_endpoints';
import form_styles from './form_styles';
import video_settings from './video_settings';
import audio_settings from './audio_settings';
import twitter_settings from './twitter_settings';
import facebook_settings from './facebook_settings';
import iframe_edit from './iframe_edit';
import cart_connections from './cart_connections';
import cart_settings from './cart_settings';
import cart_add_product_widget from './cart-add-product-widget';
import product_selector from './product-selector';
import add_to_cart_settings from './add-to-cart_settings';
import slideshow_settings from './slideshow_settings';
import picture_preview from './picture_preview';
import picture_alt from './picture_alt';
import rmheader_settings from './rmheader_settings';

export default {
  common_layer,
  common_align,
  common_animation,
  common_pack,
  common_unpack,
  common_rotation,
  common_position,
  text_styles,
  text_typography,
  text_bius,
  text_align,
  text_autosize,
  anchor_picker,
  text_link,
  text_color,
  text_columns,
  text_edit,
  picture_effects,
  picture_crop,
  picture_fwpos,
  picture_link,
  picture_1x1,
  picture_color,
  picture_settings,
  slideshow_manager,
  shape_color,
  shape_settings,
  shape_1x1,
  shape_icon,
  gmaps_edit,
  gmaps_settings,
  common_lock,
  button_edit,
  button_settings,
  button_icon,
  hotspot_add,
  hotspot_pin_settings,
  hotspot_tip_settings,
  form_content,
  form_endpoints,
  form_styles,
  video_settings,
  audio_settings,
  twitter_settings,
  facebook_settings,
  iframe_edit,
  cart_connections,
  cart_settings,
  cart_add_product_widget,
  product_selector,
  add_to_cart_settings,
  slideshow_settings,
  picture_preview,
  picture_alt,
  rmheader_settings,
};
