/**
 * Предок всех моделей
 */
import Backbone from 'backbone';
import _ from '@rm/underscore';
import SocketModel from './socket-model';
import Events from '../../common/events';

var superClass = SocketModel.prototype;

const ModelClass = SocketModel.extend({
  save: function(key, value, options) {
    var attrs,
      self = this;

    // Handle both `("key", value)` and `({key: value})` -style calls.
    if (_.isObject(key) || key == null) {
      attrs = key;
      options = value;
    } else {
      attrs = {};
      attrs[key] = value;
    }

    // Костыль для Mag, чтобы изменения не вызвали апдейт флага changed на сервере
    if (options && options.skipMagChanging && this.urlRoot) {
      options.url = this.url() + (this.url().indexOf('?') > -1 ? '&' : '?') + 'skip_changed=true';
    }

    options = options ? _.clone(options) : {};

    if (!options.skipPersist) {
      var xhr = superClass.save.apply(this, arguments);
    }

    if (!xhr) return; // При ошибке метода validate

    // Обработчики сохранения модели. Нужны для алерта о потере соединения
    xhr.error(function(xhr, err) {
      if (err == 'abort') return; // При отмене запроса из кода не генерим ошибку

      if (xhr.status && xhr.status < 500) return; // Не реагируем на ошибки 400, 404, 403

      self.wasNotSaved = true;
      RM.constructorRouter.raiseConnectionError();
    });
    xhr.success(function() {
      if (self.wasNotSaved) {
        self.trigger('re-saved');
      }
      self.wasNotSaved = false;
    });

    if (this.skipResponse) {
      xhr.skipResponse = true;
    }

    Events.trigger('save:model', { model: this, setAttrs: attrs, options: options });

    !this.skipMagChanging &&
      !options.skipMagChanging &&
      RM.constructorRouter.mag &&
      RM.constructorRouter.mag.set('changed', true);

    return xhr;
  },

  // Переопределение сохранения через patch при использовании вьюпортов
  sync: function(method, model, options) {
    if (method.toLowerCase() == 'patch') {
      if (model.collection && model.collection.page) {
        var viewport = model.collection.page.getCurrentViewport();

        if (viewport != 'default' && !(options && options.forceDefaultViewport) && !this.isNested) {
          var json = model.toJSON();

          var saveData = {};
          // Берем из вьюпорта только те ключи что могт переопределятся
          saveData['viewport_' + viewport] = _.pick(json['viewport_' + viewport], _.keys(options.attrs));
          if (_.isEmpty(saveData['viewport_' + viewport])) delete saveData['viewport_' + viewport];

          // Добавляем ключи, которые присутствуют только в дефолтном вьюпорте
          var commonKeys = _.difference(_.keys(options.attrs), _.keys(saveData['viewport_' + viewport]));
          _.extend(saveData, _.pick(model.attributes, commonKeys));

          options.attrs = saveData;
        }
      }
    }
    return superClass.sync.apply(this, arguments);
  },

  /**
   * Экспериментальное переопределение метода parse, чтобы никогда не брать данные с сервера.
   * Это нас только путает, если у нас медленное соединение.
   */
  parse: function(data, options) {
    if (options && options.xhr) {
      if (options.xhr.skipResponse) {
        superClass.parse.apply(this, arguments);

        if (this.isNew()) return data;

        return;
      }
    }

    return superClass.parse.apply(this, arguments);
  },

  /**
   * При изменениях храним первую версию
   */
  set: function(key, value, options) {
    // Handle both `"key", value` and `{key: value}` -style arguments.
    var attrs, _options;
    if (_.isObject(key) || key == null) {
      attrs = key;
      _options = value;
    } else {
      attrs = {};
      attrs[key] = value;
      _options = options;
    }
    _options = _options || {};

    // Если пытаемся назначить свойства а вьюпорт уже поменялся, то просто обновим внутренние объекты.
    // Для сетов из хотспота этого не делаем, т.к. сеты и сейвы во вьюпортах там происходят в дефолтный вьюпорт всегда.
    if (
      _options.viewport &&
      !_options.forceDefaultViewport &&
      !_options.xhr &&
      this.getViewport &&
      this.getViewport() != _options.viewport
    ) {
      this['viewport_' + _options.viewport] = _.extend(this['viewport_' + _options.viewport] || {}, attrs);
      return this;
    }

    var prevAttrs = this.cloneJSON();
    var setResult = Backbone.Model.prototype.set.apply(this, arguments);

    Events.trigger('set:model', { model: this, prevAttrs: prevAttrs, setAttrs: attrs, options: _options });

    return setResult;
  },

  // Клонируем полный json виджета для работы с историей
  cloneJSON: function() {
    var json = this.toJSON();

    _.each(json, function(value, key) {
      json[key] = _.cloneWithObjects(value);
    });
    return json;
  },
});

export default ModelClass;
