import _ from '@rm/underscore';
import Backbone from 'backbone';
import { Constants } from './utils';
import extendAnalyticsUsers from './analytics_users';
import extendAnalyticsCommon from './analytics_common';
import extendAnalyticsPublic from './analytics_public';

let Analytics;
if (location.origin === Constants.readymag_auth_host && extendAnalyticsUsers) {
  Analytics = Backbone.Model.extend(_.extend(_.extend({}, extendAnalyticsUsers), extendAnalyticsCommon));
} else if (extendAnalyticsPublic) {
  Analytics = Backbone.Model.extend(_.extend(_.extend({}, extendAnalyticsPublic), extendAnalyticsCommon));
} else {
  console.error('Analytics initialization error!');
}

if (window.RM && window.RM.customAnalyticsHandlers && !_.isEmpty(window.RM.customAnalyticsHandlers.send)) {
  var _saveSendEvent = Analytics.prototype.sendEvent;

  Analytics.prototype.sendEvent = function() {
    if (!window.ga) return;
    var args = _saveSendEvent.apply(this, arguments);
    if (window.RM.customAnalyticsHandlers && _.isFunction(window.RM.customAnalyticsHandlers.run)) {
      window.RM.customAnalyticsHandlers.run('event', args);
    }
    return args;
  };
}

if (window.RM && window.RM.customAnalyticsHandlers && !_.isEmpty(window.RM.customAnalyticsHandlers.track)) {
  var _saveTrackPage = Analytics.prototype.trackPage;

  Analytics.prototype.trackPage = function() {
    if (!window.ga) return;
    var args = _saveTrackPage.apply(this, arguments);
    if (args && window.RM.customAnalyticsHandlers && _.isFunction(window.RM.customAnalyticsHandlers.run)) {
      window.RM.customAnalyticsHandlers.run('track', args);
    }
    return args;
  };
}

export default Analytics;
