import $ from '@rm/jquery';
import Vue from 'vue';
import WidgetClass from '../widget';
import RmHeader from '../../../components/common/rm-header.vue';

const rmHeader = WidgetClass.extend({
  ignoreScale: true,

  render: function() {
    this.makeBox('widget-rm-header');

    const rmHeaderVue = Vue.extend(RmHeader);

    this.vue = new rmHeaderVue({
      el: $('<div>')
        .appendTo(this.$el)
        .get(0),
      propsData: {
        activeLink: this.activeLink,
      },
    });

    this.rendered = true;
    this.widgetIsLoaded();

    return this;
  },

  destroy: function() {
    this.vue && this.vue.$destroy();

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default rmHeader;
