/**
 * Модель для настроек мэга
 */
import _ from '@rm/underscore';
import SocketModel from './socket-model';

const MagEditParams = SocketModel.extend({
  id: '_params',

  isNew: function() {
    return false;
  },

  mag: {},

  name: 'MagEditParams',

  initialize: function(data, mag) {
    _.bindAll(this);
    MagEditParams.prototype.mag = mag;

    this.url = function() {
      return '/api/mag/' + this.mag.get('num_id') + '/edit_params';
    };

    this.set(data);
  },

  getChangedAttributes: function() {
    return this.changedAttributes();
  },

  toggle: function(param) {
    this.save(param, !this.get(param));
  },
});

export default MagEditParams;
