module.exports = {["template-constructor-control-picture_effects"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="icon" data-alt="Effects" data-alt-pos="left" data-alt-offset="9"></div>\n\n\n	<div class="panel">\n				<div class="effect">\n					<div class="thumb original" title="Original" style="background-image: url(\''+
((__t=(options.thumb ))==null?'':__t)+
'\')"></div>\n				</div>\n\n		';
 _.each(options.effects, function(name, i) {
				var y = '-' + (i * Constants.THUMB_SIZE) + 'px';
		
__p+='\n				<div class="effect">\n					<div class="thumb" title="'+
((__t=(Utils.capitalize(name)))==null?'':__t)+
'" data-effect="'+
((__t=(name))==null?'':__t)+
'" style="background-position: 0 '+
((__t=(y))==null?'':__t)+
';"></div>\n				</div>\n\n		';
 }); 
__p+='\n	</div>\n';
}
return __p;
},}