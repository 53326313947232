module.exports = {["template-viewer-widget-text"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	';
var cc = column_count == 1 ? 'auto' : column_count;
__p+='\n	<div style=" -webkit-nbsp-mode:normal; -webkit-line-break:after-white-space; white-space:'+
((__t=(isNotChrome ? 'normal' : 'pre-wrap'))==null?'':__t)+
'; column-count:'+
((__t=(cc))==null?'':__t)+
';-webkit-column-count:'+
((__t=(cc))==null?'':__t)+
' !important;-moz-column-count:'+
((__t=(cc))==null?'':__t)+
';column-gap:'+
((__t=(column_gap))==null?'':__t)+
'px;-webkit-column-gap:'+
((__t=(column_gap))==null?'':__t)+
'px !important;-moz-column-gap:'+
((__t=(column_gap))==null?'':__t)+
'px;background:'+
((__t=(bg))==null?'':_.escape(__t))+
'">'+
((__t=(text))==null?'':__t)+
'</div>\n';
}
return __p;
},}