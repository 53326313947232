module.exports = {["template-constructor-control-button_icon"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="icon noarrow" data-alt="Icon" data-alt-pos="left" data-alt-offset="9"></div>\n\n	<div class="panel '+
((__t=( Modernizr.isboxversion ? 'no-search' : '' ))==null?'':__t)+
'">\n\n		<div class="search-block">\n\n			<input class="search" type="text" placeholder="Search">\n\n\n			<div class="loupe">\n				<div class="rmpreloader hidden"><div class="arc"></div></div>\n			</div>\n\n		</div>\n\n\n		<div class="common-wrapper">\n\n\n			<div class="resizable-scroll-wrapper">\n				<div class="resizable-content-wrapper">\n					<div class="resizable-content">\n\n						<div class="disable-icon item">\n							<div class="line"></div>\n						</div>\n\n\n						<!--<div class="last-icon item">-->\n							<!--<div class="noun-icon"></div>-->\n						<!--</div>-->\n\n					</div>\n				</div>\n				<div class="resizable-scroll"></div>\n			</div>\n\n\n			<div class="params-wrapper clearfix">\n\n				<div class="edit-item" data-field="icon_h">\n					<div class="caption">Size</div>\n					<input spellcheck="false" type="text" value=""/>\n				</div>\n\n\n				<div class="edit-item" data-field="icon_pos">\n					<div class="caption">Pos.</div>\n					<div class="positions">\n						<div class="right position" data-position="right">\n							<div class="fill"></div>\n						</div>\n						<div class="left position" data-position="left">\n							<div class="fill"></div>\n						</div>\n					</div>\n				</div>\n\n			</div>\n\n		</div>\n\n\n		<div class="resize-handle-top"></div>\n		<div class="resize-handle-bottom"></div>\n\n	</div>\n';
}
return __p;
},["template-constructor-control-button_icon-item"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="icon-item item">\n		<div class="noun-icon" style="background-image: url('+
((__t=(icon.preview_url_42))==null?'':__t)+
');" '+
((__t=(icon.id ? 'data-id="' + icon.id + '"' : 'data-rm_id="' + icon.rm_id + '"'))==null?'':__t)+
' data-url="'+
((__t=(icon.icon_url))==null?'':__t)+
'">\n	</div>\n';
}
return __p;
},}