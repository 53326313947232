<template>
  <div class="panel cart-settings-panel">
    <div class="title-row">
      <div class="title">Cart button</div>
    </div>

    <div class="styling-state-switcher">
      <span :class="{ btn: true, active: stylingState === 'default' }" @click="onChangeStylingState('default')"
        >Default</span
      >
      <span :class="{ btn: true, active: stylingState === 'hover' }" @click="onChangeStylingState('hover')">Hover</span>
    </div>

    <div class="separator-filled"></div>

    <div class="settings-panel" v-if="stylingState === 'default'">
      <div class="row">
        <div class="caption">Shape color</div>
        <color-picker
          class="round"
          :color="m['background-color']"
          :alpha="m['background-color-opacity']"
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('background-color', $event)"
          @update:alpha="changePropHandler('background-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Radius</div>
        <numeric-input
          :autosize="true"
          :value="m['border-radius']"
          :class="{
            'num-input': true,
            'one-digit': m['border-radius'] < 10,
            'two-digit': m['border-radius'] < 100 && m['border-radius'] > 9,
            'three-digit': m['border-radius'] > 99,
          }"
          :min="0"
          :max="999"
          @change="changePropHandler('border-radius', $event)"
        ></numeric-input>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Border</div>
        <numeric-input
          :autosize="true"
          :value="m['border-width']"
          :class="{
            'num-input': true,
            'one-digit': m['border-width'] < 10,
            'two-digit': m['border-width'] < 100 && m['border-width'] > 9,
            'three-digit': m['border-width'] > 99,
          }"
          :min="0"
          :max="maxBorderWidth"
          @change="changePropHandler('border-width', $event)"
        ></numeric-input>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Border color</div>
        <color-picker
          class="round"
          :color="m['border-color']"
          :alpha="m['border-color-opacity']"
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('border-color', $event)"
          @update:alpha="changePropHandler('border-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <font-family-selector
          :family="m['font-family']"
          @showPopup="closeOthersPopups"
          @change="changePropHandler('font-family', $event)"
        ></font-family-selector>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Size</div>
        <numeric-input
          :autosize="true"
          :value="m['font-size']"
          class="num-input"
          :min="8"
          :max="999"
          @change="changePropHandler('font-size', $event)"
        ></numeric-input>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Text color</div>
        <color-picker
          class="round"
          :color="m['color']"
          :alpha="m['color-opacity']"
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('color', $event)"
          @update:alpha="changePropHandler('color-opacity', $event)"
        ></color-picker>
      </div>
    </div>

    <div class="settings-panel" v-if="stylingState === 'hover'">
      <div class="row">
        <div class="caption">Shape color</div>
        <color-picker
          class="round"
          :color="m['hover-background-color']"
          :alpha="m['hover-background-color-opacity']"
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('hover-background-color', $event)"
          @update:alpha="changePropHandler('hover-background-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Radius</div>
        <numeric-input
          :autosize="true"
          :value="m['hover-border-radius']"
          :class="{
            'num-input': true,
            'one-digit': m['hover-border-radius'] < 10,
            'two-digit': m['hover-border-radius'] < 100 && m['hover-border-radius'] > 9,
            'three-digit': m['hover-border-radius'] > 99,
          }"
          :min="0"
          :max="999"
          @change="changePropHandler('hover-border-radius', $event)"
        ></numeric-input>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Border</div>
        <numeric-input
          :autosize="true"
          :value="m['hover-border-width']"
          :class="{
            'num-input': true,
            'one-digit': m['hover-border-width'] < 10,
            'two-digit': m['hover-border-width'] < 100 && m['hover-border-width'] > 9,
            'three-digit': m['hover-border-width'] > 99,
          }"
          :min="0"
          :max="maxBorderWidth"
          @change="changePropHandler('hover-border-width', $event)"
        ></numeric-input>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Border color</div>
        <color-picker
          class="round"
          :color="m['hover-border-color']"
          :alpha="m['hover-border-color-opacity']"
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('hover-border-color', $event)"
          @update:alpha="changePropHandler('hover-border-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <font-family-selector
          :family="m['hover-font-family']"
          @showPopup="closeOthersPopups"
          @change="changePropHandler('hover-font-family', $event)"
        ></font-family-selector>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Size</div>
        <numeric-input
          :autosize="true"
          :value="m['hover-font-size']"
          class="num-input"
          :min="8"
          :max="999"
          @change="changePropHandler('hover-font-size', $event)"
        ></numeric-input>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Text color</div>
        <color-picker
          class="round"
          :color="m['hover-color']"
          :alpha="m['hover-color-opacity']"
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('hover-color', $event)"
          @update:alpha="changePropHandler('hover-color-opacity', $event)"
        ></color-picker>
      </div>
    </div>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import Panel from './panel.vue';
import RmSwitcher from '../../common/rm-switcher.vue';
import ColorPicker from '../../common/color-picker.vue';
import NumericInput from '../../common/numeric-input.vue';
import FontFamilySelector from '../../common/font-family-selector.vue';

export default {
  mixins: [Panel],
  components: { RmSwitcher, ColorPicker, NumericInput, FontFamilySelector },

  data: function() {
    return {
      stylingState: 'default',
    };
  },

  computed: {
    maxBorderWidth() {
      return Math.ceil(Math.min(this.m.w, this.m.h) / 2);
    },
  },

  watch: {
    stylingState(state) {
      $('.common-button', this.raw_block.$el).toggleClass('hovered', state === 'hover');
      $('.common-button', this.raw_block.$el).toggleClass('current', state === 'current');
    },
  },

  destroyed() {
    this.setCorrectIconColor('default');
    $('.common-button', this.raw_block.$el).toggleClass('hovered', false);
    $('.common-button', this.raw_block.$el).toggleClass('current', false);
  },

  methods: {
    onChangeStylingState: function(newState) {
      if (newState && newState !== this.stylingState) {
        this.stylingState = newState;
      }
      this.closeOthersPopups(null);
      this.setCorrectIconColor(newState);
    },
    changePropHandler(propType, val) {
      // Костыли для того что бы менять цвет иконки при изменение цвета шрифта
      var edited = {};
      if (propType === 'color') edited['icon_color'] = val;
      if (propType === 'hover-color') edited['hover-icon_color'] = val;
      if (propType === 'color-opacity') edited['icon_color_opacity'] = val;
      if (propType === 'hover-color-opacity') edited['hover-icon_color_opacity'] = val;

      if (_.isEmpty(edited)) {
        this.m[propType] = val;
      } else {
        edited[propType] = val;
        this.m = Object.assign(this.m, edited);
      }
    },
    setCorrectIconColor(state) {
      this.raw_block.onIconColorChange(this.m, null, {}, state);
    },
  },
};
</script>

<style lang="less">
@import (reference) '../../../css/common/constants.less';
@import (reference) '../../../css/common/fonts.less';

@panel-bg-color: #f4f4f4;
@panel-border-color: #404040;
@panel-disable-color: fade(@new-black, 40%);

.cart-settings-panel {
  width: 168px;
  height: 360px;
  box-sizing: border-box;
  padding: 13px 15px;
  right: 47px !important;
  top: -94px;
  border-radius: 8px;
  background-color: fade(@panel-bg-color, 96%);
  border: 1px solid fade(@panel-border-color, 8%);
  .native_font;
  font-size: 12px;
  color: @new-black;

  .title-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .title {
      position: relative;
      top: -4px;
      left: -1px;
      letter-spacing: -0.4px;
    }
  }

  .styling-state-switcher {
    margin-top: 24px;
    margin-bottom: 13px;

    .btn {
      color: #808080;

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: @new-black;
      }

      & + span {
        margin-left: 9px;
      }
    }
  }

  .settings-panel {
    .row {
      width: 100%;
      height: 38px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        border-bottom: none;
      }

      .color-picker-container.round {
        width: 26px;
        height: 26px;
      }

      .num-input {
        padding: 0;
        border: 0;
        font-size: 24px;
        color: @new-black;
        background: transparent;
        text-align: right;
        cursor: ns-resize;
        transition: all 0.1s ease-out;
      }

      .font-family-container {
        .expand-arrow {
          width: 7px;
          height: 12px;
          background-size: 12px 7px;
          background-image: url('../../../img/constructor/controls/cart_settings/expand-arrow.svg');
          flex: 0 0 12px;
          position: relative;
          top: 3px;
          left: 1px;
        }

        &:hover {
          .expand-arrow {
            background-image: url('../../../img/constructor/controls/cart_settings/expand-arrow-active.svg');
          }
        }
      }
    }
  }
}
</style>
