<template>
  <div class="panel">
    <div class="panel-title">Twitter</div>

    <rm-select
      v-model="m.current_type"
      :options="[
        { value: 'type_timeline', caption: 'User Timeline' },
        { value: 'type_embed', caption: 'Embed Tweet' },
        { value: 'type_tweet', caption: 'Tweet Button' },
        { value: 'type_follow', caption: 'Follow Button' },
        { value: 'type_hashtag', caption: 'Hashtag Button' },
      ]"
    ></rm-select>

    <div v-if="m.current_type == 'type_timeline'">
      <textarea v-model="m.type_timeline.code" placeholder="Paste embed code here" class="textarea"></textarea>
      <div class="center">
        <a class="embed-tutorial" target="_blank" href="https://dev.twitter.com/docs/embedded-timelines"
          >How to embed Timeline</a
        >
      </div>
    </div>

    <div v-if="m.current_type == 'type_embed'">
      <textarea v-model="m.type_embed.code" placeholder="Paste embed code here" class="textarea"></textarea>
      <div class="center">
        <a class="embed-tutorial" target="_blank" href="https://dev.twitter.com/docs/embedded-tweets"
          >How to embed Tweet</a
        >
      </div>
    </div>

    <div v-if="m.current_type == 'type_tweet'">
      <div class="input-wrapper">
        <rm-variant-input :data="m.type_tweet"></rm-variant-input>
      </div>
      <div class="sep-line"></div>
      <div class="row">
        <div class="caption">Large button</div>
        <rm-switcher
          v-model="m.type_tweet.large_button"
          color-on="#3366cc"
          color-off="rgba(75, 75, 75, 0.4)"
        ></rm-switcher>
      </div>
    </div>

    <div v-if="m.current_type == 'type_follow'">
      <div class="input-wrapper">
        <rm-apply-input v-model="m.type_follow.username" :prefix="'@'"></rm-apply-input>
      </div>
      <div class="sep-line"></div>
      <div class="row">
        <div class="caption">Show name</div>
        <rm-switcher
          v-model="m.type_follow.show_name"
          color-on="#3366cc"
          color-off="rgba(75, 75, 75, 0.4)"
        ></rm-switcher>
      </div>
      <div class="row">
        <div class="caption">Large button</div>
        <rm-switcher
          v-model="m.type_follow.large_button"
          color-on="#3366cc"
          color-off="rgba(75, 75, 75, 0.4)"
        ></rm-switcher>
      </div>
    </div>

    <div v-if="m.current_type == 'type_hashtag'">
      <div class="input-wrapper">
        <rm-apply-input v-model="m.type_hashtag.hash" :prefix="'#'"></rm-apply-input>
      </div>
      <div class="sep-line"></div>
      <div class="row">
        <div class="caption">Large button</div>
        <rm-switcher
          v-model="m.type_hashtag.large_button"
          color-on="#3366cc"
          color-off="rgba(75, 75, 75, 0.4)"
        ></rm-switcher>
      </div>
    </div>
  </div>
</template>
<script>
import Panel from './panel.vue';
import RmVariantInput from '../../common/rm-variant-input.vue';
import RmSwitcher from '../../common/rm-switcher.vue';
import RmSelect from '../../common/rm-select.vue';
import RmApplyInput from '../../common/rm-apply-input.vue';

export default {
  mixins: [Panel],
  components: { RmVariantInput, RmSwitcher, RmSelect, RmApplyInput },
};
</script>
