/**
 * Конструктор для виджета текста
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import BlockClass from '../block';
import BlockFrameClass from '../block-frame';
import Viewports from '../../common/viewports';
import TextUtils from '../../common/textutils';
import { Utils } from '../../common/utils';
import History from '../models/history';
import TextViewportsClass from '../../common/text-viewports';
import templates from '../../../templates/constructor/blocks/text.tpl';
import rangy from 'rangy';
import SoundCite from '@rm/soundcite';

var FISH_TEXTS = [
  'The follies of others are obvious, but our own hide from us; we love to parse others’ missteps but we cover up our own, as the cheat conceals the false die. It is in the nature of men to lay blame: we see others’ mistakes while our own passions swell, carrying us ever farther from improvement. — Buddhist wisdom',
  'No one can long stand on tiptoe. He who thrusts himself forward is not a source of light. No glory comes to the self-satisfied, no honor to the boastful. The proud will not rise high. In the court of good sense, such people are like table discards, repellent to all. The sensible person does not rely on self. — Lao-Tzu',
  'Simplicity in life, language and habits strengthens a nation, but a life of luxury, fancy language and effeminate habit leads to weakness and death. — John Ruskin',
  'Truth is not a matter of talking but comes only through labor and observation. And when you have one truth in hand, two others, like the first leaves of a dicotyledonous plant, will probably appear. — John Ruskin',
  'All things have a beginning and an end. So it is with mankind: there is a beginning and end to all  human affairs. He who understands what is a beginning and what is an end is closer to the truth. — Confucius',
  'Most people are dismayed if deprived of their pleasures. The right course belongs to him who relishes even the passing away of the reason for his joy and is not bitter. — Pascal',
  'When we are clear about things, we have knowledge; with knowledge, we seek the path of truth; when the search is rewarded, the heart becomes good; with the heart made good, the moral view of things that leads to virtue is attained. — Confucius',
  'Wisdom has three faces: when seen from afar, it is grand and severe; on closer approach, it is tender and welcoming; when we are close enough to hear it, the words of  wisdom are severe and demanding. — Chinese wisdom',
  'The sun constantly floods the world with its light, but its light is not exhausted; so your mind should send its light in all directions and not be exhausted. It should flow everywhere, without diminishment, and when it encounters an obstacle, it should react, not with annoyance or anger but should serenely clarify all that yearns to accept it, without any lessening or weariness, bringing light to all that is turned to it, leaving in shadow only that which turns its face away. — Marcus Aurelius',
  'As torches and fireworks pale to invisibility in the sun, so the mind, even the mind of genius, and beauty pale and are eclipsed by the heart’s goodness. — Schopenhauer',
  'Music and sweet treats bring the wanderer to a stop, but wisdom has neither flavor nor smell. When looked for, it is invisible; when listened for, it is inaudible, yet its goodness is inexhaustible. The most powerful thing in the world cannot be seen, heard or touched. — Lao-Tzu',
  'The best ideas are usually those that come without effort, out of the blue. The great intellectual creations come easily. Only a great person can create something great, and such a one works without strain. — John Ruskin',
  'He who talks much rarely puts his words into action. But the wise person fears that his words may not be translated into action. The wise do not utter empty words, lest their actions be inconsistent with their words. — Chinese wisdom',
  'Fear ignorance, but fear false knowledge even more. Turn your eyes from the deceptive world and do not trust your feelings, for they lie; rather look inward to the impersonal, eternal self. Truly, ignorance is like a sealed and airless chamber; in it, the soul sits like a caged bird, neither singing nor spreading its wings. But ignorance is better than any school of thought that is not guided by the wisdom of the soul. — Buddhist wisdom',
  'Some seek their good or happiness in power; others in science, and others in the pleasures of the flesh. But those who come truly close to the good understand that it cannot consist in what can only be mastered by a few. They understand that the true good of humankind is that which can be achieved by all people, without division and without envy; its nature  is such that none can lose it if they do not so choose. — Pascal',
  'The wise person asks only of himself; the unwise man demands everything from others. — Chinese wisdom',
  'To know much and not to put one’s self forward as knowing is the height of morality. To know little and put one’s self forward as knowledgeable is an illness. Only by understanding the disease can one expunge it. — Lao-Tzu',
  'Speak only of that which is as clear to you as the morning, or be silent. — Talmud',
  'Conquer ferocity with love, evil with good, miserliness with generosity, the liar with truth. — Buddhist wisdom',
  'People seek satisfaction, throwing themselves from one thing to another because they feel the emptiness of their lives but do not yet realize the emptiness of the new delight that attracts them. — Pascal',
  'The skilled warrior does not rage. The skilled fighter is not angry. He who is skilled in the use of people is not provocative. This is the virtue of non-resistance. This is being in agreement with Heaven. — Lao-Tzu',
  'The first and principal characteristic of a good and wise person is his consciousness that he knows very little, that many people know more and he seeks always to learn, to study, and not to teach. Those who wish to teach or direct neither teach nor lead well. — John Ruskin',
  'The virtuous person seeks to follow the direct path to the end. What is to be feared is to go halfway and then to weaken. — Chinese wisdom',
];

var FISH_TEXT_FOR_HOTSPOT_TIP =
  '<p style="line-height: 22px; padding: 16px 16px; text-align: center; font-family: Georgia; font-size: 16px;" data-size-leading-linked="false" data-size-leading-ratio="1.25"><span>Text</span></p>';
var FISH_TEXT_FOR_HOTSPOT_TIP_EMPTY =
  '<p style="line-height: 22px; padding: 16px 16px; text-align: center; font-family: Georgia; font-size: 16px;" data-size-leading-linked="false" data-size-leading-ratio="1.25"><span></span></p>';

// обращаю внимание на то, что снизу есть еще .extend(TextViewportsClass.prototype)
const TextBlock = BlockClass.extend(
  {
    name: 'Text',
    thumb: 'text',
    sort_index: 1, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.

    icon_color: '#FFFFFF',

    controls: [
      'text_edit',
      'text_autosize',
      'common_animation',
      'common_rotation',
      'common_position',
      'common_layer',
      'common_lock',
    ],

    edit_controls: [
      'text_styles',
      'text_typography',
      'text_bius',
      'text_align',
      'text_link',
      'text_color',
      'text_columns',
    ],

    viewport_fields: Viewports.viewport_fields.text,

    // аттрибуты DOM нод внутри текстового виджета которые должны быть уникальными между вьюпортами
    // список ограничен, потому что например стили являются уникальными, а айдишники страниц (data-pid) которые используются у <a> должны быть сквозными
    // список использется в функциях которые включают/выключают текст внутри виджета в разные состояния вьюпорта
    // этот список прописан в другой вьюхе, которая расширяет данную (поскольку используется также и во вьювере)
    text_viewport_attributes: [],

    validElements: {
      // id и class нужны для букмарков
      // class, data-id, data-url, data-start, data-end и data-plays нужны для встраиваемого плеера soundcite (https://soundcite.knightlab.com/#create-new)
      '#span': ['style', 'id', 'class', 'data-id', 'data-url', 'data-start', 'data-end', 'data-plays'],
      '#p': ['style', 'class', 'data-size-leading-linked', 'data-size-leading-ratio'],
      br: [],
      a: ['data-pid', 'data-uuid', 'style', 'class', 'href', 'target', 'data-anchor-link-pos'],
    },

    params: {
      multipleName: 'multiple values', // важная константа, ставится заместо значения в this.cur_selection_styles у тех свойств которые в текущем выделении имеют несколько значений
      tabIndent: 28, // ширина в пикселах одного tab отступа
      fontSizeLineHeightRatio: 1.25, // дефолтное значение пропорции размера шрифта и интерлиньяжа
      minFontSize: 8,
      maxFontSize: 999,
      minLineHeight: 8,
      maxLineHeight: 999,
      minLetterSpacing: -99,
      maxLetterSpacing: 999,
    },

    /*
     *классы .rmwidget &.text * (widgets.less), .text-preview (blocks.less) и EDITOR_STYLES (text.js) должны быть одинаковы
     *сейчас проставил ручками потом поправлю. TO FIX.
     */
    EDITOR_STYLES:
      'body {' +
      'width: 100%;' +
      'height: 100%;' +
      'overflow: hidden;' +
      'margin:0;' +
      'padding:0;' +
      '-webkit-margin-before: 0em;' +
      '-webkit-margin-after: 0em;' +
      '-webkit-margin-start: 0px;' +
      '-webkit-margin-end: 0px;' +
      'word-wrap: break-word;' +
      'white-space: pre-wrap;' +
      'background: transparent;' +
      '-webkit-font-smoothing: antialiased;' +
      'text-rendering: optimizeLegibility !important;' +
      '-webkit-column-fill: auto !important;' +
      '-moz-column-fill: auto !important;' +
      'column-fill: auto !important;' +
      '-webkit-nbsp-mode: normal' +
      '}' +
      'body.v1 {' +
      'font-family: "Source Sans Pro";' +
      'font-weight: 400;' +
      'font-style: normal;' +
      'font-size: 16px;' +
      'line-height: 20px;' +
      '}' +
      'body.v2 {' +
      'font-family: "Roboto";' +
      'font-weight: 400;' +
      'font-style: normal;' +
      'font-size: 18px;' +
      'line-height: 26px;' +
      '}' +
      'span.nbsp {' +
      'position: relative;' +
      'z-index: -1;' +
      '}' +
      'span.nbsp:before {' +
      'content: "\u22C0";' +
      'position: absolute;' +
      'color: #1cdeb4;' +
      'font-size: .4em;' +
      'font-weight: bold;' +
      'left: 0;' +
      'top: .7em;' +
      '}' +
      'h1, h2 {' +
      'margin: 0;' +
      '}' +
      'p {' +
      'color: #000000;' /* это важно, это дефолтный цвет плюс к этому мы постоянно будем менять цвет body программно, чтобы цвет курсора совпадал с цветом текста на котором тот стоит*/ +
      'margin: 0;' +
      'padding: 0;' +
      '-webkit-margin-before: 0em;' +
      '-webkit-margin-after: 0em;' +
      '-webkit-margin-start: 0px;' +
      '-webkit-margin-end: 0px;' +
      '}' +
      '.margin-st, .margin-ed {' +
      'margin: 0;' +
      'padding: 0;' +
      '-webkit-margin-before: 0em;' +
      '-webkit-margin-after: 0em;' +
      '-webkit-margin-start: 0px;' +
      '-webkit-margin-end: 0px;' +
      'height: 1px;' +
      '}' +
      '.margin-st {' +
      'margin-bottom: -1px;' +
      '}' +
      '.margin-ed {' +
      'margin-top: -1px;' +
      '}' +
      'a {' +
      'color: inherit;' +
      'text-decoration: underline;' +
      '}' +
      'a * {' +
      'text-decoration: underline;' +
      '}' +
      'a, span {' +
      'line-height: 1px !important;' +
      'font-size: inherit !important;' + // фиксы по текстовому редактору когда спанам в результате определенных манипуляций с текстом браузер прописывал размер шрифта и интерлиньяж (не идеальное решение (и крайне редко, но все же косячит), но лучше не придумать, если только tinymce не переписать полностью)
      '}' +
      'span.soundcite {' + // блок стилей для встраиваемого плеера soundcite (чисто для ифрейма конструктора), соотносится со стилями .soundcite-loaded.soundcite-play внутри /common/soundcite.less который используется в превью и вьювере
      'position: relative;' +
      'padding: 0 0.2em 0 1.3em;' +
      'display: inline;' +
      'line-height: 120% !important;' +
      'background: rgba(0, 0, 0, 0.1);' +
      '}' +
      'span.soundcite:before {' +
      'content: "";' +
      'position: absolute;' +
      'top: 0.21em;' +
      'left: 0.3em;' +
      'border-width: 0.4em;' +
      'border-style : solid;' +
      'border-right-color: transparent;' +
      'border-bottom-color: transparent;' +
      'border-top-color: transparent;' +
      'border-left-width : 0.6em;' +
      '}',

    /**
     * Переопределяем метод инициализации
     */
    initialize: function(data, workspace) {
      this.initBlock(data, workspace);

      this.frameClass = textFrame;

      this.pid = workspace.page.id;

      this.page = workspace.page;

      this.inner_template = templates['template-constructor-block-text'];

      this.setInitialParams();

      this.changeStyleParagraph.__debounced = _.debounce(this.changeStyleParagraph, 40);
      this.changeClassParagraph.__debounced = _.debounce(this.changeClassParagraph, 40);
      this.changeStyleSpan.__debounced = _.debounce(this.changeStyleSpan, 40);
      this.changeColumns.__debounced = _.debounce(this.changeColumns, 40);
      this.changeLink.__debounced = _.debounce(this.changeLink, 40);

      this.onChangeInEditor_throttle500 = _.throttle(this.onChangeInEditor.bind(this), 500);
      this.onChangeInEditor_throttle1500 = _.throttle(this.onChangeInEditor.bind(this), 1500);
      this.onChangeInEditor_throttle = function() {
        this.onChangeInEditor_throttle500();
        this.onChangeInEditor_throttle1500();
      }.bind(this);

      this.recalcStyleDebounced = _.debounce(this.recalcStyle, 50);

      this.model.on('change', this.onModelChange);

      RM.constructorRouter.on('font-added', this.onFontAdd);

      !this.model.isNested && this.page.on('change:viewport', this.onViewportChange);

      // для хотспота особые режимы работы с марджинами
      if (this.has_parent_block) {
        this.needSideMarginsSynhronized = true;
        this.needClearMarginsOnEnter = true;
        this.needPreserveLastPadding = true;
        this.needIgnoreSlylesMargins = true;
      }
    },

    onFontAdd: function() {
      // при добавлении шрифта в фонт селектор редактор должен обновить список шрифтов внутри ифрейма
      // просто посмотрит что добавилось в основной документ (какие css) и добавит недостающие в ифрейм
      if (this.isEditorActive()) TextUtils.appendFontsCssToIFrame(this.iframe_document);
    },

    // функция переключает текст на нужный вьюпорт
    // все настройки вьюпортов храняться внутри текста в атрибутах нод
    // это такой мини вьюпортинг наподобие глобаной реализации вьюпортов (когда модель виджета меняет свои атрибуты)
    // в модели текст ВСЕГДА хранится в состоянии дефолтного вьюпорта (а данные остальных вьюпортов рассованы по спец атрибутам внутри текстовых нод)
    // поэтому при редактировании текста (или при превью) мы его переводим в режим вьюпорта, а при сохранении обратно в режим дефолтного вьюпорта
    // эта функция прописана в другой вьюхе, которая расширяет данную (поскольку используется также и во вьювере)
    switchTextToViewport: function(text, viewportName) {},

    // функция переключает текст с текущего вьюпорта на дефолтный, это нужно для сохранения данных в виджете
    // все настройки вьюпортов храняться внутри текста в атрибутах нод
    // это такой мини вьюпортинг наподобие глобаной реализации вьюпортов (когда модель виджета меняет свои атрибуты)
    // в модели текст ВСЕГДА хранится в состоянии дефолтного вьюпорта (а данные остальных вьюпортов рассованы по спец атрибутам внутри текстовых нод)
    // поэтому при редактировании текста (или при превью) мы его переводим в режим вьюпорта, а при сохранении обратно в режим дефолтного вьюпорта
    switchTextToDefault: function(text) {
      text = text || '';

      var viewportName = this.model.isNested ? 'default' : this.page.getCurrentViewport();

      // если дефолтный вьюпорт тогда просто возвращаем текст, поскольку ничего делать не надо
      // форматирование текста и так в можели всегда cохранено в режиме дефолтного вьюпорта
      if (viewportName == 'default') return text;

      // запишиваем всю верстку во временный див
      var $content = $('<div>').html(text),
        attrsList = this.text_viewport_attributes,
        self = this;

      // бежим по всем текстовым нодам и сохраняем все изменения которые произошли в атрибутах из text_viewport_attributes
      // в соответсвующие аттрибуты для требуемого вьюпорта
      $content.find('*').each(function() {
        var $item = $(this);

        _.each(attrsList, function(attrName) {
          var viewportAttrName = self.getViewportAttributeName(attrName, viewportName),
            defaultAttrName = self.getViewportAttributeName(attrName, 'default');

          // получаем данные для текущего атрибута (перебираемого из text_viewport_attributes)
          // и из дефолтного вьюпорта и предыдущие сохраненные данные по текущему вьюпорту (тоже нужны чтобы понять что поменялось)
          var defaultValue = $item.attr(defaultAttrName),
            currentValue = $item.attr(attrName);

          // удаляем текущий атрибут, поскольку в дефолтном вьюпорте его может не быть (пустой)
          $item.removeAttr(attrName);

          $item.removeAttr(defaultAttrName);
          // возвращаем предыдущее сохраненное значение дефолтного вьюпорта, если есть
          if (defaultValue) {
            $item.attr(attrName, defaultValue);
          }

          // сохраняем текущее значение атрубита в спец атрибуте для текущего вьюпорта
          // при этом соответственно сохраняться внутри вьюпорта и унаследованные от дефолтного вьюпорта
          // пока точно не понятно правильное это поведение или нет, будем тестить
          // если надо сохранять все кроме унаследованных от дефолта - то это гораздо сложнее, но тоже возможно
          $item.attr(viewportAttrName, currentValue || 'empty');
        });
      });

      var res = $content.html();
      $content.empty().remove();
      return res;
    },

    // генерирует список валидных дум нод и их атрибутов, которые разрешены в текстовом редакторе
    // при этом автоматом расширяет списки атрибутов для вьюпортов
    // глядя на списки this.text_viewport_attributes в паре с Viewports.viewports
    generateValidElementsList: function() {
      // до вьюпортов была просто вот такая константная строчка
      // "#span[style|class|id],#p[style|class|data-size-leading-linked|data-size-leading-ratio],br,a[data-pid|data-uuid|style|rel|rev|charset|hreflang|tabindex|accesskey|type|name|href|target|title|class|onfocus|onblur]"

      var viewports = _.pluck(Viewports.viewports, 'name'),
        self = this;

      // добавляем в списки названия атрибутов для вьюпортов
      _.each(this.validElements, function(value, key) {
        var viewportAttributesExtension = [];

        _.each(value, function(attr) {
          // смотрим должен ли очередной атрибут быть уникальным для вьюпортов
          // если да, тогда добавляем этот атрибут с именем для каждого вьюпорта
          if (_.indexOf(self.text_viewport_attributes, attr) >= 0) {
            Array.prototype.push.apply(
              viewportAttributesExtension,
              _.map(viewports, function(viewport) {
                return self.getViewportAttributeName(attr, viewport);
              })
            );
          }
        });
        // добавляем в исходный массив названия атрибутов для вьюпортов
        Array.prototype.push.apply(value, viewportAttributesExtension);
      });

      // собираем данные в строку в виде который поймет tinymce
      var res = _.map(this.validElements, function(value, key) {
        return key + (value.length ? '[' + value.join('|') + ']' : '');
      });

      return res.join(',');
    },

    // эта функция прописана в другой вьюхе, которая расширяет данную (поскольку используется также и во вьювере)
    getViewportAttributeName: function(attrName, viewportName) {},

    setInitialParams: function() {
      this.editor = null;
      this.editorState = 0; // 0 - не создан, 1- создается, 2- создан
      this.isEditMode = false;

      this.cur_selection_styles = {};
      this.cur_selection_styles_all = []; // массив
      this.oldChangeParam = {};
    },

    // по непонятным причинам был баг когда у телефонного вьюпорта не было свойств колонок и фона https://trello.com/c/3lyliQym/297--
    // поэтому эта функция проверяет что есть поля bg_color, bg_opacity, column_count, column_gap
    // те которых нет, дописывает в модель дефолтами
    validate: function() {
      var upd = {},
        fields = ['bg_color', 'bg_opacity', 'column_count', 'column_gap'];

      _.each(
        fields,
        _.bind(function(f) {
          if (this.model.get(f) == undefined) {
            upd[f] = TextBlock.defaults[f];
          }
        }, this)
      );

      if (!_.isEmpty(upd)) {
        this.model.set(upd, { silent: true });
      }
    },

    /**
     * Переопределяем метод отрисовки виджета
     */
    render: function() {
      if (this.rendered) return;

      this.create();

      this.setInitialParams();

      // по непонятным причинам был баг когда у телефонного вьюпорта не было свойств колонок и фона
      this.validate();

      // это важно при клонировании виджетов, чтобы в момент рендера сразу проставлялся текст текущего вьюпорта
      var params = _.pick(this.model.attributes, ['_id', 'w', 'h', 'text']);
      params.text = this.switchTextToViewport(params.text, this.page.getCurrentViewport());

      if (this.model.created && this.has_parent_block) {
        params.text = FISH_TEXT_FOR_HOTSPOT_TIP;
        this.model.save({ text: params.text }, { silent: true });
      }

      this.selectOnEnter = this.model.created;

      $(this.inner_template(params)).appendTo(this.$content);

      // создаем элемент с фоном виджета
      // с ним все не очень просто, поскольку в обычном режиме виджета он имеет его z-уровень
      // а в режиме редактирования он также должен оставаться на прежнем уровне, несмотря на то,
      // что все остальные элементы виджета (тест, колонки, марджины и пр.) выходят на самый верхний уровень поверх всех виджетов
      // поскольку вся верстка которую мы указываем в text.html располагается не прямо в виджете
      // а в его элементе .content, нам приходится самим создавать верстку для фона виджета
      // напрямую в виджете, иначе у нас проблемы с z-index
      var bgTemplate = templates['template-constructor-block-text-bg'];
      this.$bg = $(bgTemplate({})).prependTo(this.$el);
      this.$bg.css({ 'z-index': this.model.get('z') });

      this.$el.addClass('block-text');
      this.$el.toggleClass('text-autosize', !!this.model.get('autosize'));

      this.$preview = this.$('.text-preview');
      this.$preview_mask = this.$('.text-mask');

      // устанавливаем версию текстового виджета, виджеты без версии считаем виджетами первой версии
      // первая версия использует дефолтным щрифт соурс санс, вторая - робото
      // важно это делать для обратной совместимости, чтобы не сломать старые виджеты
      // также таким способом легко добавить новых версий, просто поправив версию в фефолтах вновь содаваемого виджета
      // и в 3х местах прописать классы для v3 с новыми дефолтными параметрами шрифта
      this.$preview.addClass('v' + (this.model.get('version') || 1));

      // добавляем шрифт roboto в фонтселектор
      if (this.model.created && this.model.get('version') == 2) {
        RM.constructorRouter.fonts.addFonts(
          [
            {
              provider: 'google',
              css_name: 'Roboto',
              name: 'Roboto',
              variations: ['n1', 'i1', 'n3', 'i3', 'n4', 'i4', 'n5', 'i5', 'n7', 'i7', 'n9', 'i9'],
            },
          ],
          'manual'
        );
      }

      this.applyCssColumnCount(this.$preview, this.model.get('column_count'));
      this.applyCssColumnGap(this.$preview, this.model.get('column_gap'));
      this.applyCssBgColor(this.model.get('bg_color'), this.model.get('bg_opacity'));

      // закомментил, потому что при dblclick this.onDblClick вызывается дважды
      // this.$preview_mask.on('dblclick', this.onDblClick);

      // если только  что создали виджет
      if (this.model.created) {
        this.model.created = false;
        this.enterEditMode();
        this.frame && this.frame.show();
      }

      this.triggerReady();
    },

    destroy: function() {
      this.model.off('change', this.onModelChange);
      this.off('resize', this.onChangeInEditor_throttle);

      RM.constructorRouter.off('font-added', this.onFontAdd);

      this.page.off('change:viewport', this.onViewportChange);

      this.leaveEditMode(true);

      // флаг что виджет удален
      // у нас много операций которые выполняются отложенно и по таймеру, они должны знать что виджета больше нет
      this.deleted = true;

      BlockClass.prototype.destroy.apply(this, arguments);
    },

    createEditor: function() {
      this.editorState = 1; // 0 - не создан, 1- создается, 2- создан

      // превращаем textarea в tinymce редактор
      tinyMCE.init({
        mode: 'exact',
        theme: 'simple',
        popup_css: false,
        elements: 'textarea_' + this.model.id, // id textarea который мы назначили ему при создании из темплейта
        accessibility_warnings: false,
        custom_shortcuts: false,
        language_load: false,
        doctype: '<!doctype html>', // блядская строчка, полдня в пизду
        init_instance_callback: this.editorIsReady,
        verify_css_classes: false,
        custom_undo_redo: false,

        // запрещаем конвертирование урлов в линках и пр. элементах
        // в частности без этого флага происходит преобразование абсолютных урлов в относительные
        // если домен урла совпадает с текущим доменом сайта
        // например создаем линк: http://mag4.local.ru/pervushinag/55/
        // а он при сохранении превращается в ../../../pervushinag/55/ (чего нам конено же не надобно)
        // поэтому запрещаем любые манипуляции с урлами
        convert_urls: false,

        // verify_html: true,
        valid_elements: this.generateValidElementsList(),
        // valid_children: "body[p], p[#text,span,br], span[#text]",

        // плагин "paste", позволяет перехватить событие paste и предварительно обработать контент
        // самому перехватывать - лучше сразу повеситься
        plugins: 'paste',
        paste_auto_cleanup_on_paste: true,
        paste_max_consecutive_linebreaks: 99,
        paste_text_sticky: true, // просим плагин чтобы он сам преобразовывал HTML -> Plain Text
        paste_text_sticky_default: true,

        paste_beforegrab: _.bind(function() {
          // перед начало обработки paste сохраняем текущий скролл воркспейса
          // потому что в некоторых ситуациях операции которые производит плагин проскроливают весь ворксейс (потому что временно меняется положение каретки)
          // https://trello.com/c/Bd1aKE5e/86--
          this.workspaceScrollBeforePaste = $('#main').scrollTop();
        }, this),

        paste_postprocess: _.bind(function() {
          // восстанавливаем скролл воркспейса после операции вставки, за подробностями смотри выше paste_beforegrab
          $('#main').scrollTop(this.workspaceScrollBeforePaste);
          this.somethingChangedInEditor('paste');
        }, this),

        paste_text_linebreaktype: 'custom',
      });
    },

    /**
     * Переопределяем метод изменения размера-положения виджета для того чтобы также изменять размеры некоторых внутренних элементов редактора
     */
    css: function(params) {
      // назначаем нашему фону такой же z-index как и у собержимого виджета
      // за нас этого никто не сделает
      params = params || {};

      if (params['z-index'] !== undefined)
        this.$bg.css({ 'z-index': this.model.get('fixed_position') ? '' : params['z-index'] });

      BlockClass.prototype.css.apply(this, arguments);

      params = _.pick(params, 'width', 'height');

      if (!_.isEmpty(params)) {
        this.$editor && this.$editor.css(params);
        this.$iframe_body && this.$iframe_body.css(params);

        this.somethingChangedInEditor('dimensions');
      }
    },

    /**
     * Проверяет, помещается ли текст в блок. Только для режима редактирования
     * @returns {Boolean}
     */
    doesContentFit: function() {
      var fits = true;
      if (this.$iframe_document && this.$iframe_document) {
        var dw = this.$iframe_document.width(),
          dh = this.$iframe_document.height(),
          bw = this.$iframe_body.width(),
          bh = this.$iframe_body.height();
        // спец хак для сафари и хрома
        // если число колонок = 1 и при этом есть параграфы у которых выравнивание стоит по центру
        // то у этих браузеров размер ифрейм документа становился больше размера body ровно на 1px (через раз)
        // из-за этого снизу виджета показывался плюсик, якобы текст не умешается
        // потому в такой ситуации считаем что текст помещается в окно ифрейма (просто уменьшаем dw на 1px)
        if (dw - bw == 1 && this.model.get('column_count') == 1) dw--;

        fits = !(dw > bw || dh > bh);
      }
      return fits;
    },

    сheckContentFitToBox: function() {
      if (!this.$iframe_document || !this.$iframe_body) return;
      var fit = this.doesContentFit();
      this.frame && this.frame.setResizeBottomPlus(!fit);

      // если виджет текста вложенный, а значит
      // работает во внутриблоковом воркспейсе.
      // и, если это воркспейс позиционирует виджеты в колонку
      // то текстовый виджет по высоте
      // всегда должен быть равен высоте своего контента.
      if (this.has_parent_block) {
        this.workspace.fit_text_to_content();
      }
    },

    // функция также используется в block-frame.js для определения момента, когда красить нижнюю границу красным;
    // в отличие от getContentBoxHeight() данная функция возвращает большую высоту, решили делать таким образом,
    // чтобы избежать обрезания букв у самого нижнего параграфа:
    // https://www.notion.so/readymag/a67817091ab84a80a3e8a83c6499bcb6
    getAllowableHeight: function() {
      var paragraphs = this.isEditMode ? this.$iframe_body.find('p') : this.$preview.find('p'),
        length = paragraphs.length,
        phsHeight = 0,
        halfLine = 0,
        underlinedLinkExtraPadding = 0,
        allowableHeight = 0;

      paragraphs.each(
        _.bind(function(i, paragraph) {
          phsHeight += parseInt($(paragraph).outerHeight(), 10);

          // у параграфов может быть разная высота строки, но нас интересует только последний,
          // потому что именно у него может обрезаться текст
          if (i === length - 1) {
            halfLine = parseInt($(paragraph).css('line-height'), 10) / 2;

            // решили вне зависимости на какой бы строчке не располагалась ссылка,
            // если у нее есть подчеркивание, то необходимо закладывать это расстояние в допустимую высоту;
            // в padding-bottom попадает как сам padding, так и высота underline
            if ($(paragraph).find('.link-1').length) {
              underlinedLinkExtraPadding = parseInt(
                $(paragraph)
                  .find('.link-1')
                  .css('padding-bottom'),
                10
              );
            }
          }
        }, this)
      );

      allowableHeight = phsHeight + halfLine + underlinedLinkExtraPadding;

      return allowableHeight;
    },

    getContentBoxHeight: function() {
      var editMode = this.isEditMode;
      var height = 0;

      if (editMode) {
        // 16 итераций
        var maxHeight = Math.pow(2, 16);
        var middle;

        var setHeightInEditMode = function(height) {
          this.$editor && this.$editor.css({ height: height });
          this.$iframe_body && this.$iframe_body.css({ height: height });
        }.bind(this);

        // Подберём высоту методом бисекции
        while (height !== maxHeight) {
          middle = height + Math.floor((maxHeight - height) / 2);

          setHeightInEditMode(middle);
          // Текст помещается, но блок может быть больше чем нужно — ограничим высоту сверху средним значением
          if (this.doesContentFit()) {
            maxHeight = middle;
            // Текст не помещается — ограничим высоту снизу средним значением
          } else {
            height = middle + 1;
          }
        }
      } else {
        var originalCssText = this.$preview[0].style.cssText;
        // Уберём ограничение высоты текста (100%) и замеряем получившуюся высоту
        // column-fill нужно выставлять для FF: с column-fill: auto и height: auto весь текст становится в одну колонку
        this.$preview.css({ height: 'auto', '-moz-column-fill': 'balance', 'column-fill': 'balance' });
        height = this.$preview.height();
        // Восстановим инлайн-стиль как был
        this.$preview[0].style.cssText = originalCssText;
      }
      return height;
    },

    fitContentToBox: function(e) {
      this.model.set({ h: this.getAllowableHeight() });

      if (e && e.type === 'keydown' && RM && RM.constructorRouter && RM.constructorRouter.analytics) {
        RM.constructorRouter.analytics.sendEvent('Key Press', 'fit frame to text');
      }

      this.saveBox();
    },

    select: function() {
      BlockClass.prototype.select.apply(this, arguments);
      RM.constructorRouter.bindGlobalKeyPress([
        {
          key: 'x',
          handler: this.fitContentToBox,
          optionKeys: !Modernizr.mac ? ['ctrl', 'shift'] : ['meta', 'shift'],
        },
      ]);
    },

    /**
     * Переопределяем метод deselect виджета для того чтобы при потере фокуса сохранять изменения сделанные пользователем в тексте
     */
    deselect: function(block) {
      BlockClass.prototype.deselect.apply(this, arguments);

      RM.constructorRouter.unbindGlobalKeyPress('x', this.fitContentToBox);

      if (block == this) return;

      if (this.isEditMode) {
        this.leaveEditMode();
        this.workspace.trigger('redraw');
      }
    },

    // вызывается каждый раз когда выделяем виджет(ы) на рабочей области
    // кликом, кликом с шифтом, рамкой выделения
    // (если в результате перечисленных манипуляций не оказывается ни одного виджета в выделении, тогда это событие не стреляет)
    onWorkspaceBlocksSelect: function(blocks) {
      BlockClass.prototype.onWorkspaceBlocksSelect.apply(this, arguments);

      // защита от ситуация когда два текстовых виджета могут находиться в состоянии редактирования
      // дело в том, что если делать клик по виджету с зажатым шифтом, то у нас это означает добавить/удалить из текущего списка выделенных виджетов
      // а при таком действии deselect не вызывается (смотрим block.js) и соответственно если у нас уже есть текстовый виджет который в режиме редактирования
      // и мы делаем двойной клик с зажатым шифтом по другому текстовому виджету, то старый текстовый виджет не выходит из режима редактирования так как нет события deselect

      if (!this.rendered) return;

      // смотрим что у нас в текущем выделении текущий блок или еще что-то
      var isOnlyMeSelected = blocks && blocks.length == 1 && blocks[0] == this;

      // если мы находимся в режиме редактирования
      // и узнали что список текущих выделенных виджетов поменялся (например с шифтом щелкнули еще о одному)
      // тогда выходим из режима редактирования
      if (this.isEditMode && !isOnlyMeSelected) {
        this.leaveEditMode();
        this.workspace.trigger('redraw');
      }
    },

    onChangeInEditor: function() {
      if (this.model.get('autosize')) {
        this.fitContentToBox();
        this.trigger('resizeOnFit', this.model);
      }
    },

    /**
     * Вызывается когда tinymce создал редактор из textarea, возвращает инстанс редактора
     */
    editorIsReady: function(instance) {
      // если ошибка и редактор не создан - выходим нафиг, чтобы дальше не плодить ошибки
      if (!instance) return;

      // если виджет удалили в момент создания редактора
      if (this.deleted) return;

      this.editor = instance;

      this.editor.onChange.add(this.onChangeInEditor_throttle);
      this.editor.onNodeChange.add(this.onChangeInEditor_throttle);
      this.editor.onKeyPress.add(this.onChangeInEditor_throttle);

      this.$editor = this.$('textarea')
        .add(this.$('table'))
        .add(this.$('iframe'));

      this.$iframe_body = this.$('iframe')
        .contents()
        .find('body');
      this.iframe_document = this.$('iframe').contents()[0];
      this.$iframe_document = $(this.iframe_document);
      this.iframe = this.$('iframe')[0];

      // устанавливаем версию текстового виджета, виджеты без версии считаем виджетами первой версии
      // первая версия использует дефолтным щрифт соурс санс, вторая - робото
      // важно это делать для обратной совместимости, чтобы не сломать старые виджеты
      // также таким способом легко добавить новых версий, просто поправив версию в фефолтах вновь содаваемого виджета
      // и в 3х местах прописать классы для v3 с новыми дефолтными параметрами шрифта
      this.$iframe_body.addClass('v' + (this.model.get('version') || 1));

      /* закомментировал, что-то работает не так и рушит верстку скопированного контента :(
    //при копировании из редактора текстового виджета добавляем
    //специальные пометки, чтобы моно было понять, что текст скопирован из нашего редактора
    //нужно для вставки текста с форматированием (если мы знаем что источник текста наш редактор, то форматирование можно оставлять (но наверное по требованию, типа ctrl+shift+v))
    this.iframe_document.oncopy = _.bind(function() {

      //сохраняем текущее положение курсора
      var bookmark = this.editor.selection.getBookmark();

      var selection = this.iframe.contentWindow.getSelection();
      var newdiv = this.iframe_document.createElement('div');
      newdiv.style.position = 'absolute';
      newdiv.style.left = '-9999px';
      newdiv.style.top = '-9999px';
      newdiv.innerHTML = selection + '<span class="rmcontent">&zwnj;</span>';
      this.$iframe_body.append(newdiv);
      selection.selectAllChildren(newdiv);

      _.defer(_.bind(function() {
        $(newdiv).remove();
        //восстанавливаем выделение
        this.editor.selection.moveToBookmark(bookmark);
      }, this));
    }, this);
    */

      // добавляем наши стили для редактора
      var style = this.iframe_document.createElement('style');
      style.type = 'text/css';
      style.appendChild(this.iframe_document.createTextNode(this.EDITOR_STYLES));
      this.iframe_document.getElementsByTagName('head')[0].appendChild(style);

      this.on('changeStyleParagraph', this.onChangeStyleParagraph);
      this.on('changeClassParagraph', this.onChangeClassParagraph);
      this.on('changeStyleSpan', this.onChangeStyleSpan);
      this.on('changeColumns', this.onChangeColumns);
      this.on('changeLink', this.onChangeLink);

      this.applyCssColumnCount(this.$iframe_body, this.model.get('column_count'));
      this.applyCssColumnGap(this.$iframe_body, this.model.get('column_gap'));
      this.applyCssBgColor(this.model.get('bg_color'), this.model.get('bg_opacity'));

      this.css({ width: this.model.get('w'), height: this.model.get('h') });

      this.marginController = new MarginController(this);

      this.columnsController = new ColumnsController(this);

      this.undoController = new UndoController(this);

      this.$iframe_body.on(
        'mousemove mouseup',
        _.bind(function(e) {
          e.pageX = this.$el.offset().left + e.clientX;
          e.pageY = this.$el.offset().top + e.clientY;

          if (!this.disableMouseEventsRedirection) $(document).trigger(e);
        }, this)
      );

      this.$iframe_document.on(
        'paste',
        _.bind(function() {
          // c defer чтобы функция успевала удалить вставляемые <br>
          _.defer(
            _.bind(function() {
              var selection = this.editor.selection,
                bookmark = selection.getBookmark(),
                text = this.$iframe_body[0].innerHTML,
                filteredText;

              // FIXME: удостовериться, что эта строка ничего не сломала и удалить
              // filteredText = text.replace(/<br\/?>/gi, '');
              filteredText = text;

              this.$iframe_body[0].innerHTML = filteredText;
              selection.moveToBookmark(bookmark);
            }, this)
          );
        }, this)
      );

      this.$iframe_document.on(
        'mouseup',
        _.bind(function(e) {
          // вешаем реакцию на отжатие клавиши мыши
          // здесь очень интерeсный момент, этот код будет работать даже если мышь отжали за пределами iframe,
          // оказывается что в ситуации, когда клавишу мыши зажали
          // внутри iframe, а потом отпустили уже за его пределами, событие mouseup выстрелит
          // не на основном документе (document), а все также на iframe, вот только не на его body, а на iframe_document
          // это пиздец кстати, нигде это не описано
          if (this.mouseSelectionStart) {
            this.somethingChangedInEditor('mouseup');
            this.trigger('textWidgetMouseSelectionEnd');
            this.mouseSelectionStart = false;
          }
        }, this)
      );

      // при нажатии кнопки мыши в редакторе, предполагаем что будем делать выделение
      this.$iframe_document.on(
        'mousedown',
        _.bind(function(e) {
          this.somethingChangedInEditor('mousedown');
          this.trigger('textWidgetMouseSelectionStart');
          this.mouseSelectionStart = true;
        }, this)
      );

      // ловим нажатие клавиш на клавиатуре (изменение контента либо выделения)
      this.$iframe_document.on(
        'keydown',
        _.bind(function(e) {
          this.somethingChangedInEditor('keydown');
        }, this)
      );

      this.editor.onKeyDown.addToTop(
        _.bind(function(ed, evt) {
          // временно сохраняем состояние выделения (есть/нет) до нажатия ентера
          // чтобы после его нажатия, когда все уже отработало, знать, было или нет выделение
          this.isCollapsedBeforeKeyProceed =
            this.editor && this.editor.selection && this.editor.selection.isCollapsed();

          // по fn+Enter (он же Enter на num-паде справа) закрываем редактор
          // обязательно addToTop, а не просто add, чтобы выполняться до дефолтного обработчика tinymce
          // иначе перед выходом из редактора отработает enter и добавиться лишний параграф
          if (evt.keyCode == 13 && evt.location == 3) {
            evt.preventDefault();
            evt.stopPropagation();

            if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
              RM.constructorRouter.analytics.sendEvent('Key Press', 'quit edit mode');
            }

            if (this.isEditMode) {
              this.leaveEditMode();
              this.workspace.trigger('redraw');

              this.workspace && this.workspace.controls && this.workspace.controls.setControls(this.controls, this);
            }
          }

          if (evt.keyCode == 8) {
            // backspace
            var sel = rangy.getSelection(this.iframe),
              node = sel.anchorNode.nodeType == 3 ? sel.anchorNode.parentNode : sel.anchorNode,
              $soundcite = $(node).closest('span.soundcite');

            if (sel.isCollapsed) {
              // у нас при удалеении плеера остается в начале &#8203;
              // который мы специально проставляем в editor_plugin при пастинге плеера
              // так вот, если мы видим, что в результате удаления внутри плеера ничего не осталось или остался 8203 тогда грохаем плеер
              if ($soundcite.length) {
                if (
                  $soundcite.text().length == 0 ||
                  ($soundcite.text().length == 1 && $soundcite.text().charCodeAt(0) == 8203)
                ) {
                  $soundcite.remove();
                }
              }
            }
          }

          // если требуется всегда сохранять нижний паддинг
          // т.е. при удалении последнего параграфа его паддинг перебрасывать на новый последний параграф
          if (this.needPreserveLastPadding) {
            delete this.$paragraphsBeforeRemove;
            if (
              evt.keyCode == 8 ||
              evt.keyCode == 46 ||
              (this.editor && this.editor.selection && !this.editor.selection.isCollapsed())
            ) {
              // backspace or delete or has selection
              this.$paragraphsBeforeRemove = this.$iframe_body.find('p');
            }
          }

          // по Enter проверяем что мы не внутри soundcite плеера, если так, то блокируем Enter (что с шифтом, что без)
          // обязательно addToTop, а не просто add, чтобы выполняться до дефолтного обработчика tinymce
          if (evt.keyCode == 13 && evt.location == 0) {
            var sel = rangy.getSelection(this.iframe),
              node = sel.anchorNode.nodeType == 3 ? sel.anchorNode.parentNode : sel.anchorNode;

            if (sel.isCollapsed) {
              if ($(node).closest('span.soundcite').length) {
                evt.preventDefault();
                evt.stopPropagation();
              }
            }
          }
        }, this)
      );

      this.editor.onKeyDown.add(
        _.bind(function(ed, evt) {
          // если требуется всегда сохранять нижний паддинг
          // т.е. при удалении последнего параграфа его паддинг перебрасывать на новый последний параграф
          if (this.needPreserveLastPadding) {
            var restoreParagraphsStyle = _.bind(function() {
              if (!this.$paragraphsBeforeRemove || !this.$paragraphsBeforeRemove.length) return;

              var $curParagraphs = this.$iframe_body.find('p'),
                prevFirst = this.$paragraphsBeforeRemove.first()[0],
                prevLast = this.$paragraphsBeforeRemove.last()[0];

              // когда у единственном пустом параграфе нажали на backspace удаляется даже и он (tinymce вроде как не должен такого позволять, а может я не так настроил что-то)
              // а нам этого не надо, поэтому восстановим пустой параграф
              // или если все выделели и нажали delete у нас все параграфы удаляться и редактор добавит один параграф пустой и без стилей, нам этого не надо, тоже восстанавливаем дефолтное состояние
              if (
                !$curParagraphs.length ||
                ($curParagraphs.length == 1 &&
                  !$curParagraphs.eq(0).text() &&
                  $curParagraphs[0] != prevFirst &&
                  $curParagraphs[0] != prevLast)
              ) {
                this.editor.setContent(FISH_TEXT_FOR_HOTSPOT_TIP_EMPTY);
                $curParagraphs = this.$iframe_body.find('p');
              }

              var curFirst = $curParagraphs.first()[0],
                curLast = $curParagraphs.last()[0];

              // проверяем что был удален последний параграф
              if (curLast != prevLast) {
                // восстанавливаем паддинги у нового последнего параграфа берем как у прошлого последнего
                // а у первого берем как у прошлого первого
                // это нужно для того, чтобы при полном удалении всех параграфов восстановить у вновь созданного параграфа все паддинги как были до этого у первого и последнего параграфа
                // при полном удалении удаляются все параграфы и создается один новый параграф вообще без паддингов

                curFirst.style.paddingTop = prevFirst.style.paddingTop;
                curFirst.style.paddingLeft = prevFirst.style.paddingLeft;
                curFirst.style.paddingRight = prevFirst.style.paddingRight;

                curLast.style.paddingLeft = prevLast.style.paddingLeft;
                curLast.style.paddingRight = prevLast.style.paddingRight;
                curLast.style.paddingBottom = prevLast.style.paddingBottom;

                curFirst.setAttribute('data-mce-style', curFirst.getAttribute('style')); // это для TinyMce
                curLast.setAttribute('data-mce-style', curLast.getAttribute('style'));
              }
            }, this);

            restoreParagraphsStyle();

            // если нажали не ентер, бекспейс, или делейт и при этом перед нажатием было выделение
            // то надо вызвать restoreParagraphsStyle еще раз с дефером
            // по непонятной причине тайни мсе в таком случае все еще показывает наличие всех параграфов
            // которые должны уже быть удалены и заменны введенным символом к моменту вызова обработчика onKeyDown
            if (evt.keyCode != 8 && evt.keyCode != 46 && evt.keyCode != 13 && !this.isCollapsedBeforeKeyProceed) {
              _.defer(restoreParagraphsStyle);
            }
          }

          // флаг needClearMarginsOnEnter включает особое поведение марджинов (нужно для текстового виджета внутри хотспота)
          // когда он включен все при создании нового параграфа, у него обнуляется верхний марджин, а у предыдущего параграфа обнуляется нижний
          if (this.needClearMarginsOnEnter) {
            // проверяем что нажали обычный enter (и без шифта, который просто делает мягкий перенос)
            if (evt.keyCode == 13 && evt.location !== 3 && !evt.shiftKey) {
              var sel = rangy.getSelection(this.iframe),
                range;

              // дальше работаем только если выделение сколлапсировано,
              // в противном случае ничего не делаем (там по ентеру просто происходит удаление выделеного)
              if (this.isCollapsedBeforeKeyProceed) {
                try {
                  range = sel.getRangeAt(0);
                } catch (e) {}

                if (range) {
                  // получаем параграф в которых стоит курсор
                  var paragraph = this.getClosestParagraph(range.startContainer);

                  if (paragraph) {
                    paragraph.style.paddingTop = 0;
                    // это для TinyMce
                    paragraph.setAttribute('data-mce-style', paragraph.getAttribute('style'));

                    var prevParagraph = $(paragraph).prev('p');
                    if (prevParagraph.length) {
                      prevParagraph[0].style.paddingBottom = 0;
                      // это для TinyMce
                      prevParagraph[0].setAttribute('data-mce-style', prevParagraph[0].getAttribute('style'));
                    }
                  }
                }
              }
            }
          }
        }, this)
      );

      // запрещаем drag&drop в тексте потому, что,
      // во-первых, это нахер не нужно, только мешает,
      // во-вторых, нам все равно это нормально не отследить (особенно в сафари)
      // на события драга завязывается используюя диспатчер tinymce!
      // почему-то если через jQuery (this.$iframe_body.bind('dragend drago...) то вообще ничего нельзя выделить мышкой

      this.editor.dom.bind(
        this.editor.getBody(),
        ['dragend', 'dragover', 'draggesture', 'dragdrop', 'drop', 'drag'],
        _.bind(function(e) {
          // тут интересный момент
          // поскольку нам нужно знать когда мы начали выделение мышкой, а когда закончили
          // (а делаем мы это в предыдущих обработчиках (ловим mousedown-mouseup))
          // то в случае если мы начали тянуть за выделение протащили и отпустили его
          // у нас не выстреливает событие mouseup (и соответственно не триггерится textWidgetMouseSelectionEnd)
          // поэтому тут мы проверяем что пришло событие dragend занчит мышь отпустили (типа mouseup)
          // в ФФ событие dragend не происходит, но зато там нормально работает mouseup в нашем случае
          if (e.type == 'dragend' && this.mouseSelectionStart) {
            this.somethingChangedInEditor('mouseup');
            this.trigger('textWidgetMouseSelectionEnd');
            this.mouseSelectionStart = false;
          }

          e.preventDefault();
          e.stopPropagation();

          return false;
        }, this)
      );

      this.$('iframe').bind('mousewheel', this.onMouseWheel);

      setTimeout(
        _.bind(function() {
          // если виджет удалили в момент создания редактора
          if (this.deleted) return;

          this.editorState = 2; // 0 - не создан, 1- создается, 2- создан

          if (!this.leaveEditorBeforeItIsLoaded) {
            this.enterEditMode();
            this.editor.show();
          }

          this.somethingChangedInEditor('editorIsReady');
        }, this),
        100
      );

      // выделяем весь контент в редакторе в случае если там рыба
      setTimeout(
        _.bind(function() {
          if (this.selectOnEnter) {
            this.editor.selection.select(this.editor.getBody(), true);
            delete this.selectOnEnter;
          }
        }, this),
        200
      );

      this.bindShortcuts();
    },

    // слушаем событие начала ресайза
    // при этом отключаем редирект событий мыши из ифрейма в документ
    // и показываем поверх редактора прозрачный слой который вместо него будет ловить события мыши
    // связано с тем, что в хроме есть баг, когда в редакторе много текста и мы проскролили его до самого низа
    // а после чего пытаемся ресайзить виджет за любую точку ресайза (в сторону увеличения размера)
    // у нас начинает произвольно дергаться высота/ширина виджета
    // баг в том что у нас срабатывают события мыши на ифрейме, причем с непонятными координатами (а он эти события редиректит в документ)
    // хотя мышь на самом деле на ифрейм не попадает (точки ресайза вынесены за пределы виджета в режиме редактирования)
    onResizeStart: function(event, drag) {
      this.disableMouseEventsRedirection = true;
      this.$preview_mask.addClass('visible-while-resizing');
    },

    // слушаем событие конца ресайза, и возвращаем все обратно
    onResizeEnd: function(event, drag) {
      this.disableMouseEventsRedirection = false;
      this.$preview_mask.removeClass('visible-while-resizing');
    },

    onMouseWheel: function(e, d) {
      var scrollTo = -Math.ceil(d * 0.5);

      if (scrollTo) {
        if (scrollTo > 0 && scrollTo < 1) scrollTo = 1;
        if (scrollTo < 0 && scrollTo > -1) scrollTo = -1;

        if (this.model.get('column_count') > 1) {
          var oldScrollLeft = this.$iframe_document.scrollLeft();
          this.$iframe_document.scrollLeft(scrollTo + oldScrollLeft);
          e.preventDefault();
        } else {
          var oldScrollTop = this.$iframe_document.scrollTop();
          this.$iframe_document.scrollTop(scrollTo + oldScrollTop);
          if (oldScrollTop != this.$iframe_document.scrollTop()) e.preventDefault();
        }
      }
    },

    onDblClick: function() {
      // фикс бага https://trello.com/c/boBhnNnV/153--
      // вркатце суть в том, что сразу несколько текстовых виджетов могло быть в состоянии редактироания одновременно
      // это происходило из-за того, что простые клики по виджетам блокируются если какой либо из текущих виджетов говорит
      // что у него есть открытые панельки, которые надо закрыть вместо того, чтобы выделять виджеты там где кликнули
      // а двойные клики мы не обрабатываем и не блокируем, но текстовый виджет их слушает и по двойному клику переходит в режим редактирования
      // а вот если есть уже один текстовый виджет в режиме редактированяи у которого открыт один попап внутри другого (например фонт селектор открыт из типографики)
      // то по двойному клику закрывается фонт селектор, а за ним сразу типографика, но при этом выхода из редактора не происходит, потому, что до deselect дело не дошло, оба клика проглотили попапы
      // и двойной клик по другому виджету открывает еще один активный редактор
      // поэтому тут добавляем простую проверку, что виджет выделен, поскольку в вышеописанной ситуации он выделен не будет
      // так как оба клика были проглочены попами текущего редактора и select на текстовом виджете по которому делаем второй клик не происходит, profit
      if (!this.selected) return;

      // https://www.notion.so/readymag/Text-W-Text-W-ecb9bcd41fc54f45a513d436f97375f8
      // проверка на длину массива нужна, чтобы убедиться, что выбран только один текстовый виджет из группы,
      // первый дблклик выделяет виджет в грппе, а вторым дблкликом можно войти в режим редактирования
      if (!this.model.get('pack_id') || this.workspace.getSelectedBlocks().length === 1) {
        this.enterEditMode();
      } else {
        BlockClass.prototype.onDblClick.apply(this, arguments);
      }
    },

    getClosestParagraph: function(node) {
      var paragraph;

      if (!node) return paragraph;

      while (true) {
        var tag = node.tagName;

        if (tag == 'P' || tag == 'p') {
          paragraph = node;
          break;
        }

        node = node.parentNode;
        if (!node || !node.tagName) break;
      }

      return paragraph;
    },

    // выделяет в редакторе область, которая начинается с первой и заканчивается на последней ноде
    selectNodesBoundingSelection: function(firstNode, lastNode) {
      var range, startContainer, startOffset, endContainer, endOffset;

      // выделяем первую и узнаем для созданного выделения его начало: startContainer и startOffset
      this.editor.selection.select(firstNode);
      range = this.editor.selection.getRng(true);
      startContainer = range.startContainer;
      startOffset = range.startOffset;

      // выделяем последнюю и узнаем для созданного выделения его конец: endContainer и endOffset
      this.editor.selection.select(lastNode);
      range = this.editor.selection.getRng(true);
      endContainer = range.endContainer;
      endOffset = range.endOffset;

      // теперь создаем свое выделние в которое попадут обе новы
      range = this.iframe_document.createRange();
      range.setStart(startContainer, startOffset);
      range.setEnd(endContainer, endOffset);
      this.editor.selection.setRng(range);
    },

    bindShortcuts: function() {
      var that = this;

      // общие форткаты для мака и для винды
      this.$iframe_body
        .bind('keydown', 'tab', increaseParagraphIndent)
        .bind('keydown', 'backspace', decreaseParagraphIndent)
        .bind('keydown', 'alt+up', decreaseLineHeight)
        .bind('keydown', 'alt+down', increaseLineHeight)
        .bind('keydown', 'alt+left', decreaseLetterSpacing)
        .bind('keydown', 'alt+right', increaseLetterSpacing);

      // шорткаты для винды
      this.$iframe_body
        .bind('keydown', 'alt+ctrl+t', toggleTypographyPanel)
        .bind('keydown', 'ctrl+shift+x', this.fitContentToBox.bind(this))
        .bind('keydown', 'ctrl+k', toggleLinkPanel)
        .bind('keydown', 'ctrl+b', toggleBold)
        .bind('keydown', 'ctrl+i', toggleItalic)
        .bind('keydown', 'ctrl+u', toggleUnderline)
        .bind('keydown', 'ctrl+shift+¾', increaseFont) // по непонятным причинам нажатие на клавишу ">" (она же ".") вместе с ctrl+shift возвращает код 190, а это символ ¾
        .bind('keydown', 'ctrl+shift+¼', decreaseFont) // по непонятным причинам нажатие на клавишу "<" (она же ",") вместе с ctrl+shift возвращает код 189, а это символ ¼
        .bind('keydown', 'ctrl+shift+a', selectParagraph)
        .bind('keydown', 'ctrl+shift+l', alignLeft)
        .bind('keydown', 'ctrl+shift+r', alignRight)
        .bind('keydown', 'ctrl+shift+c', alignCenter);

      // шорткаты для мака
      this.$iframe_body
        .bind('keydown', 'alt+meta+t', toggleTypographyPanel)
        .bind('keydown', 'meta+shift+x', this.fitContentToBox.bind(this))
        .bind('keydown', 'meta+k', toggleLinkPanel)
        .bind('keydown', 'meta+b', toggleBold)
        .bind('keydown', 'meta+i', toggleItalic)
        .bind('keydown', 'meta+u', toggleUnderline)
        .bind('keydown', 'meta+shift+¾', increaseFont) // по непонятным причинам нажатие на клавишу ">" (она же ".") вместе с ctrl+shift возвращает код 190, а это символ ¾
        .bind('keydown', 'meta+shift+¼', decreaseFont) // по непонятным причинам нажатие на клавишу "<" (она же ",") вместе с ctrl+shift возвращает код 189, а это символ ¼
        .bind('keydown', 'meta+shift+a', selectParagraph)
        .bind('keydown', 'meta+shift+l', alignLeft)
        .bind('keydown', 'meta+shift+r', alignRight)
        .bind('keydown', 'meta+shift+c', alignCenter);

      this.bindSymbolShortcuts();

      // показываем-скрываем панель типографики
      function toggleTypographyPanel() {
        if (that.workspace && that.workspace.controls) {
          var control = that.workspace.controls.findControl('text_typography');
          // если контрол типографики сейчас создан
          // тогда имитируем клик по его иконке (чтобы закрыть-открыть его),
          // не забываем в качестве контекста иcполнения функции указать сам контрол
          if (control) control.onClick.apply(control);
        }
        return false;
      }

      // показываем-скрываем панель типографики
      function toggleLinkPanel() {
        if (that.workspace && that.workspace.controls) {
          var control = that.workspace.controls.findControl('text_link');
          // если контрол типографики сейчас создан
          // тогда имитируем клик по его иконке (чтобы закрыть-открыть его),
          // не забываем в качестве контекста иcполнения функции указать сам контрол
          if (control) {
            control.onClick.apply(control);
            control.focusInputField.apply(control);
          }
        }

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'create a link');
        }
        return false;
      }

      // получаем css значение для текущего выделения, для свойства param
      // если в выделении несколько значений параметра, вернет первый
      function getStyle(param) {
        var val;
        if (that.cur_selection_styles && that.cur_selection_styles[param] != undefined) {
          val = that.cur_selection_styles[param];
          // если в выделении несколько значений параметра, берем первое
          if (val == that.params.multipleName)
            if (
              that.cur_selection_styles_all &&
              that.cur_selection_styles_all.length > 0 &&
              that.cur_selection_styles_all[0][param] != undefined
            )
              val = that.cur_selection_styles_all[0][param];
            else val = undefined;
        }
        return val; // вернет значение или undefined если что-то пошло не так
      }

      function toggleBold() {
        var val = getStyle('font-weight');
        if (val) that.trigger('changeStyleSpan', { 'font-weight': val == 700 ? '400' : '700' }); // '400','700' именно в кавычках!

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'make bold');
        }
        return false;
      }

      function toggleItalic() {
        var val = getStyle('font-style');
        if (val) that.trigger('changeStyleSpan', { 'font-style': val == 'italic' ? 'normal' : 'italic' });

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'make italic');
        }
        return false;
      }

      function toggleUnderline() {
        var val = getStyle('text-decoration');
        if (val) that.trigger('changeStyleSpan', { 'text-decoration': val == 'underline' ? 'none' : 'underline' });

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'make underlined');
        }
        return false;
      }

      function increaseFont() {
        var val = getStyle('font-size');
        if (val) {
          val++;
          if (val > that.params.maxFontSize) val = that.params.maxFontSize;
          that.trigger('changeStyleParagraph', { 'font-size': val + 'px' });
        }

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'increase font size');
        }
        return false;
      }

      function decreaseFont() {
        var val = getStyle('font-size');
        if (val) {
          val--;
          if (val < that.params.minFontSize) val = that.params.minFontSize;
          that.trigger('changeStyleParagraph', { 'font-size': val + 'px' });
        }

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'decrease font size');
        }
        return false;
      }

      function increaseLineHeight() {
        var val = getStyle('line-height');
        if (val) {
          val++;
          if (val > that.params.maxLineHeight) val = that.params.maxLineHeight;
          that.trigger('changeStyleParagraph', { 'line-height': val + 'px' });
        }

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'increase line height');
        }
        return false;
      }

      function decreaseLineHeight() {
        var val = getStyle('line-height');
        if (val) {
          val--;
          if (val < that.params.minLineHeight) val = that.params.minLineHeight;
          that.trigger('changeStyleParagraph', { 'line-height': val + 'px' });
        }

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'decrease line height');
        }
        return false;
      }

      function increaseLetterSpacing() {
        if (!that.editor || !that.editor.selection || that.editor.selection.isCollapsed()) return;

        var val = getStyle('letter-spacing');
        if (val != undefined) {
          // может быть 0, что нормально
          val = Utils.decimals(val + 0.2, 2); // Чтобы не было js-фокусов с дробными числами. Вместо нуля может получиться напр, -3.2782554615362614e-8
          if (val > that.params.maxLetterSpacing) val = that.params.maxLetterSpacing;
          that.trigger('changeStyleSpan', { 'letter-spacing': val + 'px' });
        }
        return false;
      }

      function decreaseLetterSpacing() {
        if (!that.editor || !that.editor.selection || that.editor.selection.isCollapsed()) return;

        var val = getStyle('letter-spacing');
        if (val != undefined) {
          // может быть 0, что нормально
          val = Utils.decimals(val - 0.2, 2); // Чтобы не было js-фокусов с дробными числами. Вместо нуля может получиться напр, -3.2782554615362614e-8
          if (val < that.params.minLetterSpacing) val = that.params.minLetterSpacing;
          that.trigger('changeStyleSpan', { 'letter-spacing': val + 'px' });
        }
        return false;
      }

      function selectParagraph() {
        if (!that.editor || !that.editor.selection) return;

        if (!that.isEditorActive()) return;

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'select current paragraph');
        }
        // получаем текущее выделение
        var sel = rangy.getSelection(that.iframe),
          range;

        try {
          range = sel.getRangeAt(0);
        } catch (e) {
          return;
        }

        // получаем параграфы в которых выделение начинается и заканчивается
        var stParagraph = that.getClosestParagraph(range.startContainer);
        var edParagraph = that.getClosestParagraph(range.endContainer);

        that.selectNodesBoundingSelection(stParagraph, edParagraph);

        return false;
      }

      function alignLeft() {
        that.trigger('changeStyleParagraph', { 'text-align': 'left' });

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'align left');
        }
        return false;
      }

      function alignRight() {
        that.trigger('changeStyleParagraph', { 'text-align': 'right' });

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'align right');
        }
        return false;
      }

      function alignCenter() {
        that.trigger('changeStyleParagraph', { 'text-align': 'center' });

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'align center');
        }
        return false;
      }

      // обработчик нажатия tab или backspace, добавляет или убавляет соответственно отступ параграфа
      // dir: -1 - уменьшить отступ, +1 прибавить отступ
      // функция проверяет что текущее выделение отсутствует (т.е. только каретка видна)
      // и что каретка находится в самом начале параграфа
      function changeParagraphIndent(dir) {
        if (!that.editor || !that.editor.selection || !that.editor.selection.isCollapsed()) return;

        var range = that.editor.selection.getRng(),
          node = that.editor.selection.getNode(),
          offset = range.startOffset;

        if (!node) return;

        // курсор не в начале элемента, значит точно не в начале параграфа
        if (offset > 0) return;

        // если мы в начале какого-то вложенного блока, но не параграфа
        // проверяем что в родительском элементе мы идем первыми
        // например вы в спане со смещением 0, смотрим где находится этот спан в родительском элементе:
        // если он идет в DOM первым и перед ним нет других нод (не включая текстовые), значит мы находимся и в начале родителя
        // циклично проделываем это пока не наткнемся на параграф или не поймем что курсор точно не в начале параграфа
        while (node && $(node)[0].tagName && $(node)[0].tagName.toLowerCase() != 'p') {
          if ($(node).index() > 0) return;
          node = node.parentNode;
        }

        if (!node || !$(node)[0].tagName || $(node)[0].tagName.toLowerCase() != 'p') return;

        // определяем текущий text-indent у параграфа
        var text_indent = 0;
        if (that.iframe_document.defaultView && that.iframe_document.defaultView.getComputedStyle) {
          var compStyle = that.iframe_document.defaultView.getComputedStyle(node, null);
          if (compStyle) text_indent = parseInt(compStyle.getPropertyValue('text-indent'), 10);
          if (isNaN(text_indent)) text_indent = 0;
        }
        if (dir < 0 && text_indent == 0) return;

        text_indent = text_indent + that.params.tabIndent * dir;
        if (text_indent < 0) text_indent = 0;

        that.trigger('changeStyleParagraph', { 'text-indent': text_indent + 'px' });

        return false; // тут именно false, индикатор что мы отработали сообщение
      }

      // вызывается когда нажали tab
      function increaseParagraphIndent() {
        changeParagraphIndent(+1);

        return false; // для табуляции всегда возвращаем false (поглощаем событие чтобы tab вообще не отрабатывался)
      }

      // вызывается когда нажали backspace
      function decreaseParagraphIndent(e) {
        return changeParagraphIndent(-1); // поглощаем backspace только в том случае когда нажали его в начале параграфа и у параграфа есть отступ который надо удалить
      }
    },

    bindSymbolShortcuts: function() {
      var symbolShortcuts = {
        // Шорткат alt + space не работает (перехватывается?)
        '&ensp;': 'alt+shift+space',
        '&nbsp;': 'alt+ctrl+space',
        '&thinsp;': 'alt+ctrl+shift+space',
      };
      var isMacOs = Modernizr.mac;

      _.each(
        symbolShortcuts,
        function(shortcut, code) {
          // Если в шорткате есть ctrl и это мак, добавим такой же только с command
          if (shortcut.indexOf('ctrl') !== -1 && isMacOs) {
            this.$iframe_body.bind('keydown', shortcut.replace('ctrl', 'meta'), this.insertCharacter.bind(this, code));
          } else {
            this.$iframe_body.bind('keydown', shortcut, this.insertCharacter.bind(this, code));
          }
        }.bind(this)
      );
    },

    /**
     * Переводит виджет в режим редактирования
     */
    enterEditMode: function(e) {
      // защита от ситуация когда два текстовых виджета могут находиться в состоянии редактирования
      // дело в том, что если делать клик по виджету с зажатым шифтом, то у нас это означает добавить/удалить из текущего списка выделенных виджетов
      // а при таком действии deselect не вызывается (смотрим block.js) и соответственно если у нас уже есть текстовый виджет который в режиме редактирования
      // и мы делаем двойной клик с зажатым шифтом по другому текстовому виджету, то старый текстовый виджет не выходит из режима редактирования так как нет события deselect
      // в общем туту мы запрещаем входить в режим редактирования по двойному клику если был зажат шифт
      if (e && e.shiftKey) return;

      if (this.editorState == 1) {
        // редактор уже грузится
        return; // сам покажется когда закончит грузиться
      }

      if (this.editorState == 0) {
        // редактор еще не создавался
        this.createEditor(); // создаем редактор, по окончанию он сам снова вызовет enterEditMode, поскольку загрузка асинхронная
        return;
      }

      this.$editor && this.$editor.css('display', 'block');
      this.frame && this.frame.setEditorState();
      this.$preview && this.$preview.css('display', 'none');
      this.$preview_mask && this.$preview_mask.css('display', 'none');

      this.initiatorControl = 'user';
      this.isEditMode = true;

      this.marginController && this.marginController.show();
      this.columnsController && this.columnsController.show();

      this.frame.removeRotationState(); // Выходим из режима вращения
      this.frame.resetRotation(); // Поворачиваем блока прямо

      // переключаем глобальный обработчик UNDO-REDO на себя
      this.undoController.historyTriggers = History.setCustomHandlers({
        undo: this.undoController.undo,
        redo: this.undoController.redo,
        getLength: this.undoController.getLength,
      });

      // Сохраняем первоначальные атрибуты (чтобы не было лишних сохранений, а то были глюки с глобальным ундо-редо)
      this.prevModelAttributes = _.clone(this.model.attributes);

      // сохраняем промежуточное состояние редактора по таймеру
      clearInterval(this.intermediateSaveInterval);
      this.intermediateSaveInterval = setInterval(this.intermediateSave, 20000);

      RM.constructorRouter.textStyles.generateCSS('paragraph', 'editor', this.iframe_document);
      RM.constructorRouter.textStyles.generateCSS('link', 'editor', this.iframe_document);

      // добавляем наборы шрифтов
      TextUtils.appendFontsCssToIFrame(this.iframe_document);

      setTimeout(
        _.bind(function() {
          if (!this.isEditorActive()) return;

          this.editor.focus();

          this.editor.setContent(
            this.switchTextToViewport(
              this.model.get('text'),
              this.model.isNested ? 'default' : this.page.getCurrentViewport()
            )
          );

          // принудительно заставляем пересмотреть что у нас сейчас выделено, чтобы пересчитать состояние контролов
          this.somethingChangedInEditor('enterEditMode');
        }, this),
        0
      );

      this.workspace && this.workspace.controls && this.workspace.controls.setControls(this.edit_controls, this);

      this.workspace.trigger('w-text-entered-edit-mode');
    },

    isEditorActive: function() {
      if (this.deleted) return false;

      if (!this.isEditMode) return false;

      if (!this.editor) return false;

      if (this.editorState != 2) return false; // редактор еще не до конца загружен или еще не виден

      return true;
    },

    applyCssColumnCount: function($obj, column_count) {
      var cc = column_count == 1 ? 'auto' : column_count; // не ставим column-count:1 (в фф глюки), вместо него ставим auto
      $obj &&
        $obj.css({
          '-webkit-column-count': cc + '', // +'' обязательно!!! ебаный jquery
          '-moz-column-count': cc + '',
          'column-count': cc + '',
        });
    },

    applyCssColumnGap: function($obj, column_gap) {
      $obj &&
        $obj.css({
          '-webkit-column-gap': column_gap,
          '-moz-column-gap': column_gap,
          'column-gap': column_gap,
        });
    },

    applyCssBgColor: function(bg_color, bg_opacity) {
      var color = '',
        rbg = [
          parseInt(bg_color.substring(0, 2), 16),
          parseInt(bg_color.substring(2, 4), 16),
          parseInt(bg_color.substring(4, 6), 16),
        ];

      if (bg_opacity > 0.99) color = 'rgb(' + rbg[0] + ',' + rbg[1] + ',' + rbg[2] + ')';
      else color = 'rgba(' + rbg[0] + ',' + rbg[1] + ',' + rbg[2] + ', ' + bg_opacity + ')';

      this.$bg.css('background', color);
    },

    /**
     * Выходит из режима редактирования
     *
     */
    leaveEditMode: function(widgetIsDeleting) {
      if (this.editorState == 0) {
        // редактор не создавался
        return;
      }

      if (this.editorState == 1) {
        // редактор уже грузится
        // флаг чтобы редактор не показался после загрузки поскольку в процессе загрузки мы вышли из режима редактирования
        this.leaveEditorBeforeItIsLoaded = true;
        return;
      }

      if (!this.isEditorActive()) return;

      this.changeSpanToNbsp();

      this.workspace.trigger('w-text-before-leave-edit-mode');

      this.isEditMode = false;

      // сбрасываем таймер промежуточного сохранения
      clearInterval(this.intermediateSaveInterval);

      // хак чтобы iframe tinymce потерял фокус и его снова принял основной документ
      Utils.getFocusBack();

      this.$editor && this.$editor.css('display', 'none');
      this.frame && this.frame.removeEditorState();
      this.$preview && this.$preview.css('display', 'block');
      this.$preview_mask && this.$preview_mask.css('display', 'block');

      this.marginController && this.marginController.hide();

      this.columnsController && this.columnsController.hide();

      this.frame.restoreRotation(); // Восстанавливаем поворот блока, если он был

      // переключаем глобальный обработчик UNDO-REDO обратно на страницу
      History.removeCustomHandlers();
      this.undoController.historyTriggers = {};

      if (!widgetIsDeleting) this.saveWidgetData();

      this.workspace.trigger('w-text-leaved-edit-mode');
    },

    /**
     * Чтобы при изменении размеров виджета на сервер
     * сохранялись только размеры бокса и его положение — {patch: true},
     * а не все данные, включая контент.
     */
    getSaveBoxOptions: function(options) {
      return _.extend({ patch: true }, options);
    },

    saveWidgetData: function() {
      // приводим контент сиз текстового редактора к дефолтному вьюпорт
      // т.е. запихиваем все вьюпортозависимые свойства внутрь спец атрибудтов у текстовых нод
      // это все потому, что в модели текст хранится всегда в одном виде - в виде дефолтного вьюпорта
      var text = this.switchTextToDefault(this.editor.getContent());

      // дополнительно санируем контент и фиксим возможные неприятности
      var $content = $('<div>').html(text);

      // защита, если вдруг при работе с марджинами мы не удалили временные div
      $content.find('div').remove();

      // ищем пустые спаны и линки с nbsp внутри (и просто пустые) и удаляем
      // кроме тех, которые составляют единственный контент параграфа
      // их надо оставить чтобы сохранить "плейсхолдер" форматирования (цвет там, шрифт и пр.), конечно, если оно там было
      // + делаем это несколько раз, потому что спаны могут быть вложеными друг в друга
      do {
        var old = $content.html();

        $content
          .find('span:econtains(' + '\u00a0' + '), a:econtains(' + '\u00a0' + ')')
          .filter(function() {
            return (
              $(this)
                .closest('p')
                .text() != '\u00a0'
            );
          })
          .remove();

        $content
          .find("span:econtains(''), a:econtains('')")
          .filter(function() {
            return (
              $(this)
                .closest('p')
                .text() != ''
            );
          })
          .remove();

        if (old == $content.html()) break;
      } while (true);

      text = $content.html();

      // для хотспота, если все удалили восстановим исходное форматирование, но уберем дефолтный текст
      if (!text && this.has_parent_block) {
        text = FISH_TEXT_FOR_HOTSPOT_TIP_EMPTY;
      }

      if (
        text != this.prevModelAttributes.text ||
        !_.isEqual(
          _.omit(this.model.attributes, 'text', 'x', 'y', 'w', 'h', 'z', 'angle', 'flip_h', 'flip_v'),
          _.omit(this.prevModelAttributes, 'text', 'x', 'y', 'w', 'h', 'z', 'angle', 'flip_h', 'flip_v')
        )
      ) {
        this.model.save({ text: text });
      }

      // сохраняем данные модели которая было сохранена, чтобы при следующей попытке сохранения сверить, есть ли изменения и ничего не сохранять если не было
      this.prevModelAttributes = _.clone(this.model.attributes);

      $content.empty().remove();
    },

    // промежуточное соханение данных виджета по таймеру
    // происходит при любом данных виджета
    // и только если данные изменились
    intermediateSave: function() {
      if (!this.isEditorActive()) {
        clearTimeout(this.intermediateSaveInterval);
        return;
      }

      this.saveWidgetData();
    },

    /**
     * Устанавливает текст в редактор и в превью при смене вьюпорта
     */
    onViewportChange: function() {
      if (!this.rendered) return;

      var text = this.switchTextToViewport(this.model.get('text'), this.page.getCurrentViewport());
      this.setPreviewHtml(text);
      this.editor && this.editor.setContent(text);
    },

    /**
     * Устанавливает текст в редактор и в превью по событию (при выходе из него и при ундо-редо)
     */
    onModelChange: function(model) {
      var $frameBorder;

      // У блока в этом методе происходит перерендер
      BlockClass.prototype.onModelChange.apply(this, arguments);
      if (!this.rendered) return;

      // по непонятным причинам был баг когда у телефонного вьюпорта не было свойств колонок и фона
      this.validate();

      if (_.has(model.changedAttributes(), 'text')) {
        var text = this.switchTextToViewport(
          model.get('text'),
          this.model.isNested ? 'default' : this.page.getCurrentViewport()
        );
        this.setPreviewHtml(text);

        // в принципе в редактор можно не проставлять
        // поскольку текст в него автоматом проставляется при открытии редактора
        // а при ундо-редо редактор закрыт
        // но если мы его проставляем тут заранее (например при ундо-редо)
        // то потом приоткрытии редактора не будет скачка форматирования
        if (!this.isEditorActive()) this.editor && this.editor.setContent(text);
      }

      if ('autosize' in model.changed) {
        $frameBorder = this.$el.find('.frameborder');

        this.$el.toggleClass('text-autosize', !!this.model.get('autosize'));

        if ($frameBorder.hasClass('red-border') && this.model.get('autosize')) {
          $frameBorder.removeClass('red-border');
        }

        _.defer(
          function() {
            this.fitContentToBox();
            this.trigger('resizeOnFit', this.model);
          }.bind(this)
        );
      }

      this.applyCssColumnCount(this.$preview, model.get('column_count'));
      this.applyCssColumnCount(this.$iframe_body, model.get('column_count'));

      this.applyCssColumnGap(this.$preview, model.get('column_gap'));
      this.applyCssColumnGap(this.$iframe_body, model.get('column_gap'));

      this.applyCssBgColor(this.model.get('bg_color'), this.model.get('bg_opacity'));
    },

    setPreviewHtml: function(text) {
      this.$preview && this.$preview.html(text);
      // Зададим фоновый цвет для плееров soundcite. По задумке у этого фона должен быть такой же цвет, как у текста, но прозрачнее.
      this.$preview.find('span.soundcite').each(function(ind, el) {
        SoundCite && SoundCite.setElementsColor(el);
      });
    },

    // рассчитывает кол-во колонок на которые разбился текст и их координаты по горизонтали
    calcColumnsPositions: function() {
      var bw = this.$el[0].offsetWidth,
        dw = this.$iframe_document.width(),
        columnGap = this.model.get('column_gap'),
        columnCount = this.model.get('column_count'),
        columnWidth = (bw + columnGap) / columnCount - columnGap,
        realColumnCount = Math.round((dw + columnGap) / (columnWidth + columnGap)),
        realColumnWidth = (dw + columnGap) / realColumnCount - columnGap,
        columns = [],
        x = 0,
        i,
        st,
        ed;

      for (i = 0; i < realColumnCount; i++) {
        st = Math.round(x);
        ed = Math.round(x + realColumnWidth - 1);

        columns.push({
          st: st,
          ed: ed,
          w: ed - st + 1,
        });

        x += realColumnWidth + columnGap;
      }

      return columns;
    },

    // валидирует-верифицирует-санирует текст в редакторе на лету
    // иногда требуется, операция не быстрая
    sanitizeContent: function() {
      // если сейчас происходит операция вставки текста из буфера (а она асинхронная и долгая)
      // тогда просто выходим, иначе можем натворить бед и обработать контен который мы не прочистили от стилей и пр. в editor_plugin.js
      if (this.$iframe_body.attr('data-pasting-in-action') == '1') return;

      // удаляем останки букмарков, на всякий случай
      this.$iframe_body.find('span[data-mce-type="bookmark"]').remove();

      // сохраняем текущее положение курсора
      var bookmark = this.editor.selection.getBookmark();

      // убираем у букмарков идентификатор того что они букмарки (их может быть два, если выделение не схлопнуто - начало и конец)
      // иначе при getContent букмарки будут вычищены, но помечаем их для себя, чтобы потом восстановить
      this.$iframe_body
        .find('span[data-mce-type="bookmark"]')
        .removeAttr('data-mce-type')
        .addClass('bookmark');

      // получаем вычищенный контент, тот который соответствует настройкам valid_elements в tinymce
      var text = this.editor.getContent();

      // устанавливаем в редактор вычищенный контент
      this.editor.setContent(text);

      // восстанавливаем идентификаторы букмарков
      this.$iframe_body
        .find('span.bookmark')
        .attr('data-mce-type', 'bookmark')
        .removeClass('bookmark')
        .text(''); // убираем неразрывный пробел изнутри букмарка (он там появляется после getContent -> setContent, потому что у нас есть правило #span в valid_elements, что запрещает иметь пустые спаны (ведь пустые спаны удаляются, что нам не надо))

      // восстанавливаем выделение
      this.editor.selection.moveToBookmark(bookmark);
    },

    // функция фиксит баги chrome с лишними &nbsp; в тексте
    // суть в том, что если вы стоим например в конце или а начале спана и нажимаем пробел
    // то перед спаном (если стоим вначале) или внутри спана в конце (если стоим в конце) добавляется &nbps;
    // хотя должен добавляться пробел простой
    // например "<span>ABC<span>CDE", мы стоим в конце ABC и нажали на SPACE,
    // в хроме мы получим "<span>ABC&nbsp;<span>CDE", что как бы не корректно
    // но все не так просто:
    // во-первых, не стоит трогать ноды которые состоят только из nbsp; поскольку там nbsp это пейсхолдер
    // во-вторых, вместо &nbsp; в тексте моет быть просто юникод символ 0160 (апдейт: this.editor.getContent() вернет &nbsp; так что все ок)
    // в-третьих, если есть "<span>ABC<span> CDE", то по логике если мы стоим в конце ABC
    // и нажали на SPACE тогда нам все же надо добавить &nbps (а не обычный пробел); в конец спана с ABC,
    // иначе, если поставим обычный пробел, то верстка станет такой: "<span>ABC <span> CDE",
    // а браузер отобразит два подряд идущих пробела как один
    // (т.е. после нажатия на SPACE визуально ничего не измениться, будет виден один пробел, просто курсор перескочит на его конец)
    // (в фф к слову так и происходит)
    // с другой стороны, если мы поставим &nbps; после ABC а потом сотрем пробел перед CDE то мы получим исходную ситуацию,
    // то конечно тоже не коректно
    // т.е. вывод который мы можем сделать такой: при любом редактировании текста нам надо постоянно следить за ВСЕМ текстом
    // чтобы не было &nbsp; слева И справа от которого стоит НЕ пробел (верстка не учитывается, смотрится просто текстовый контент)
    // &nbsp; в самом начале и в конце текста также должны быть заменены на пробелы
    // плюс к этому нельзя трогать, &nbsp; если нода целиком состоит только из него (типа <p>&nbsp;</p>)
    removeRedundandNBSPs: function() {
      var text = this.$iframe_body[0].innerHTML,
        filteredText = innerNBSPremove(text),
        bookmark;

      // заменяем текст в редакторе если он поменялся в результате чистки
      // но при этом сохраняем позицию курсора или выделения!
      if (filteredText != text) {
        // сохраняем текущее положение курсора
        bookmark = this.editor.selection.getBookmark();

        // убираем у букмарков внутреннее содержимое (\uFEFF), регулярка на нем стопиться (почему - даже разбираться не стал)
        this.$iframe_body.find('span[data-mce-type="bookmark"]').html('');

        // по-новой получаем контент редактора, поскольку мы проставили там метки (букмарки) с положением курсора-выделения
        text = this.$iframe_body[0].innerHTML;
        filteredText = innerNBSPremove(text);
        this.$iframe_body[0].innerHTML = filteredText;

        // восстанавливаем выделение
        this.editor.selection.moveToBookmark(bookmark);
      }

      function innerNBSPremove(str) {
        str = str.replace(/\>&nbsp;\</gim, '>{{{NBSP_PLACEHOLDER}}}<'); // сберегаем от вычищения nbsp которые являются единственным контентом тега
        str = str.replace(/([^\>])&nbsp;([^\<])/gim, '$1{{{NBSP_PLACEHOLDER}}}$2'); // сохраняем от вычищения nbsp между словами
        str = str.replace(/([^\>\<$|\s])&nbsp;/gim, '$1{{{NBSP_PLACEHOLDER}}}'); // сохраняем от вычищения nbsp, которые разделены одним символом(не тэгом и не пустым пространством)
        str = str.replace(/\<\/p\>/gim, '</p>\n'); // специально в конце параграфов проставляем переносы строк, чтобы следующая регулярка не убирала nbsp в начале и в конце параграфа (она их там просто не найдет)
        str = str.replace(/([^\s\>])((?:<[^\>]*?>)*)&nbsp;((?:<[^\>]*?>)*)([^\s\<])/gi, '$1$2 $3$4'); // собственно чистка
        str = str.replace(/\{\{\{NBSP_PLACEHOLDER\}\}\}/gim, '&nbsp;'); // восстанавливаем обратно nbsp которые мы не должны были трогать
        str = str.replace(/\<\/p\>\n+/gim, '</p>'); // убираем проставленные ранее переносы строк в конце параграфов
        return str;
      }
    },

    // основной обработчик любых изменений в виджете
    somethingChangedInEditor: function(tp, formatParams) {
      // если изменили размеры виджета или марджины, то сразу без задержки пересчет
      // enterEditMode также надо запускать без debounce (на него реагирует undoController чтобы зафиксировать изначальное состояние редактора)
      if (tp == 'dimensions' || tp == 'margins' || tp == 'enterEditMode') {
        this.recalcStyle(tp, formatParams);

        if (tp == 'enterEditMode') {
          if (this.$iframe_body[0].innerHTML.indexOf('&nbsp;') !== -1) {
            this.changeNbspToSpan();
          }
        }
        return;
      }

      /* в противном случае, обрабатываем с debounce
      paste
      mouseup
      mousedown
      keydown
      editorIsReady
      enterEditMode
      formatApplied
      undo
      redo
    */

      // санируем контент после вставки
      // на самом деле somethingChangedInEditor('paste') выстрелит не после вставки а сразу непосредственно перед ней
      // поэтому setTimeout
      // санация здесь нужна по той причине что иногда бывают артефакты рендеринга после вставки
      // а полная перезапись контента и восстановление выделения (то что делает между делом sanitizeContent) помогает
      // избавиться от этих артефактов
      // к тому же лишняя верификация html кода не повредит (собственно для чего и служит sanitizeContent)
      if (tp == 'paste') {
        setTimeout(this.sanitizeContent, 0);
      }

      this.recalcStyleDebounced(tp, formatParams);
    },

    /**
     * Вызывается каждый раз при изменении  в редакторе или перемещении курсора
     * исользует плагин rangy для определения элементов попавших в выделение
     * не учитываются элементы которые в выделение попали формално, т.е. в не имеют текста в выделении, а только разметку
     */

    recalcStyle: function(tp, formatParams) {
      // поскольку recalcStyle иногда происходит c debounce возможны ситуации, когда он вызовется тогда когда редактор уже закрыли
      if (!this.isEditorActive()) return;

      // если сейчас происходит операция вставки текста из буфера (а она асинхронная и долгая)
      // тогда просто выходим, иначе можем натворить бед и обработать контен который мы не прочистили от стилей и пр. в editor_plugin.js
      if (this.$iframe_body.attr('data-pasting-in-action') == '1') return;

      // проверяем, а не получилось ли так, что в верстке появились запрещенные теги (font, strong, u, b, i и пр.)
      // вообще у нас сейчас могут быть p, span, a, br
      // просто tinymce иногда пропускает некоторые моменты и не фиксит самоуправство браузеров которые вставляют всякую ерунду
      if (this.$iframe_body.find(':not(p,span,a,br)').length > 0) this.sanitizeContent();

      this.сheckContentFitToBox();

      if (tp == 'dimensions') {
        // если изменения пришли от изменения размеров, тогда пересчет рамо колонок и паддингов надо производить с небольщой задержкой
        // иначе могут быть непверные расположения из-за того что размер поменялся, а scrollTop и Left еще старые будут, они не сразу изменеяются при обновлении высоты фрейма
        // например если текс тне умещался и мы стоит на последнем параграе, то в ифрейме есть скролл
        // и если мы резко увеличим высоту так, что весь текст поместиться, у нас автоматом сбросится внутренний скрол, но не сразу а с задержкой
        // вот эту ситуацию и надо обруливать
        setTimeout(
          _.bind(function() {
            // обновим рамки марджинов
            this.marginController && this.marginController.recalc();

            // обновим рамки колонок
            this.columnsController && this.columnsController.recalc();
          }, this),
          0
        );
      } else {
        // обновим рамки марджинов
        this.marginController && this.marginController.recalc();

        // обновим рамки колонок
        this.columnsController && this.columnsController.recalc();
      }

      // если изменили размеры виджета - больше ничего не делаем
      if (tp == 'dimensions') return;

      this.removeRedundandNBSPs();

      if (tp == 'keydown') {
        this.changeNbspToSpan();
      }

      var text = this.editor.getContent();

      // определяем не появилось ли выделение всего контента через cmd+a
      var range = this.editor.selection.getRng(true);
      if (range.startContainer.tagName == 'BODY' || range.endContainer.tagName == 'BODY') var fullSelection = true;

      // получаем текущее выделение
      var sel = rangy.getSelection(this.iframe);

      // полное выделение нужно пофиксить так, как будто мы его сделали не через cmd+a,
      // а мышкой выделили от начала до конца (этим занимается плагин rangy, rangy.getSelection возвращает расово правильное выделение, его мы потом и применяем)
      // так мы избавимся от багов, когда выделение заканчивалось на br и getBookmark в tinymce встраивал метку выделени внутрь br
      // а также это фиксит баги пастинга текста
      // https://trello.com/c/9MaKB8UF/54--
      // https://trello.com/c/VLkNNtuZ/58--
      if (fullSelection) {
        range = sel.getRangeAt(0);
        var fixedRange = this.editor.dom.createRng();
        fixedRange.setStart(range.startContainer, range.startOffset);
        fixedRange.setEnd(range.endContainer, range.endOffset);
        this.editor.selection.setRng(fixedRange);
      }

      // сохраняем изменения в стек undo, функция сама решит надо или не надо
      if (tp != 'undo' && tp != 'redo')
        this.undoController.add(text, tp == 'margins' ? 'formatApplied' : tp, sel, formatParams);

      /* дальше идет код расчета стилей в выделении */

      if (this.initiatorControl == 'user') this.oldChangeParam = {};

      var nodes = [],
        node = sel.anchorNode.nodeType == 3 ? sel.anchorNode.parentNode : sel.anchorNode;

      // node instanceof HTMLDocument делать нельзя, поскольку  HTMLDocument внутри ифрейма свой и эти объекты разные
      if (node.toString() == '[object HTMLDocument]') return;

      var startNode, endNode;

      if (sel.isCollapsed) {
        nodes.push(node);
        startNode = node;
        endNode = node;
      } else {
        if (sel.rangeCount) {
          var range = sel.getRangeAt(0);
          var elms = range.getNodes([3]); // выбираем только текстовые ноды (nodeType = 3) !!!
          startNode = range.startContainer;
          endNode = range.endContainer;
          for (var i = 0, el; (el = elms[i]); i++) if (el.nodeValue != '') nodes.push(el.parentNode);
          nodes = $.unique(nodes);
        }
      }

      startNode = startNode.nodeType == 3 ? startNode.parentNode : startNode;
      endNode = endNode.nodeType == 3 ? endNode.parentNode : endNode;

      // находим в выделенных нодах теги линков <a> (смотрим также и в родителях)
      var link_nodes = [],
        startOnLink,
        endOnLink;
      for (var i = 0; i < nodes.length; i++) {
        var node = nodes[i],
          tag;

        while (true) {
          tag = node.tagName;

          if (tag == 'P' || tag == 'p') break;

          if (tag == 'A' || tag == 'a') {
            link_nodes.push(node);

            // определяем что начало и конец выделения стоят на линках
            if (nodes[i] == startNode) startOnLink = node;
            if (nodes[i] == endNode) endOnLink = node;
            break;
          }

          node = node.parentNode;
          if (!node || !node.tagName) break;
        }
      }

      // если начало и конец выделения принадлежат одному линку то надо спрятать контрол цвета
      var control = this.workspace && this.workspace.controls && this.workspace.controls.findControl('text_color');

      if (startOnLink && endOnLink && $(startOnLink).attr('data-uuid') == $(endOnLink).attr('data-uuid')) {
        control && control.setDisabled();
      } else {
        control && control.setEnabled();
      }

      this.calcSelectionStyle(nodes, link_nodes);

      this.collapsedSelection = sel.isCollapsed;

      if (sel.isCollapsed && this.cur_selection_styles)
        this.$iframe_body.css('color', '#' + this.cur_selection_styles['color']);
    },

    // return computed value for an element's style property
    getComputedStyle: function(elem, props) {
      var stylesValues = [];
      if (elem) {
        if (this.iframe_document.defaultView && this.iframe_document.defaultView.getComputedStyle) {
          var compStyle = this.iframe_document.defaultView.getComputedStyle(elem, null);
          if (!compStyle) return [];
          _.each(props, function(prop) {
            var val = compStyle.getPropertyValue(prop).toLowerCase();

            if (elem.tagName && elem.tagName == 'A' && prop == 'text-decoration') val = 'none';

            stylesValues.push({ prop: prop, val: val });
          });
        }
      }
      return stylesValues;
    },

    calcSelectionStyle: function(nodes, link_nodes) {
      function rgb2hex(r, g, b) {
        var p0 = r.toString(16);
        var p1 = g.toString(16);
        var p2 = b.toString(16);
        return (p0.length == 1 ? '0' + p0 : p0) + (p1.length == 1 ? '0' + p1 : p1) + (p2.length == 1 ? '0' + p2 : p2);
      }

      function filterStyle(style) {
        _.each(style, function(s) {
          if (s.prop == 'font-weight') {
            if (s.val == 'normal') s.val = '400';
            if (s.val == 'bold') s.val = '700';
          }

          if (s.prop == 'color') {
            var color, opacity;
            var rgb = /\s*rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)\s*/;
            var rgba = /\s*rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d+|\d*\.\d+)\s*\)\s*/;
            var m = s.val.match(rgb);
            if (!m) m = s.val.match(rgba);
            if (m) {
              color = rgb2hex(m[1] - 0, m[2] - 0, m[3] - 0);
              opacity = Math.round((m[4] || 1) * 100);
            }
            s.val = color;
            style.push({ prop: 'opacity', val: opacity });
          }

          if (s.prop == 'font-size') {
            s.val = parseInt(s.val, 10);
          }

          if (s.prop == 'line-height') {
            s.val = parseInt(s.val, 10);
          }

          if (s.prop == 'text-align') {
            if (s.val != 'start' && s.val != 'left' && s.val != 'right' && s.val != 'justify' && s.val != 'center')
              s.val = 'start';
          }

          if (s.prop == 'text-decoration') {
            // последние исследования британских ученых показали, что браузерная фича getComputedStyle
            // возвращает значения для атрибута стиля text-decoration в виде "underline solid rgb(0, 0, 0)"
            // это по ходу баг и появился в новых версиях хромого
            var tmp = s.val.split(' ');
            s.val = tmp.length ? tmp[0] : s.val;
          }

          if (
            s.prop == 'padding-left' ||
            s.prop == 'padding-right' ||
            s.prop == 'padding-top' ||
            s.prop == 'padding-bottom'
          ) {
            s.val = parseInt(s.val, 10);
          }

          if (s.prop == 'letter-spacing') {
            if (s.val == 'normal') s.val = '0';
            s.val = parseFloat(s.val);
          }

          if (s.prop == 'font-family') {
            s.val = s.val.replace(/\'|\"/gim, '');
            var font = RM.constructorRouter.fonts.findFontByCSSName(s.val);
            if (font && font.css_name) s.val = font.css_name;
          }
        });
      }

      this.cur_selection_styles_all = [];

      _.each(
        nodes,
        _.bind(function(node) {
          var style = this.getComputedStyle(node, [
            'text-align',
            'color',
            'text-transform',
            'vertical-align',
            'font-weight',
            'font-style',
            'text-decoration',
            'letter-spacing',
            'font-family',
          ]);

          // font-size и line-height должны расчитываться отдельно, для параграфа, которому принадлежит node
          var paragraph = this.getClosestParagraph(node);

          if (paragraph) {
            var paragraphStyle = this.getComputedStyle(paragraph, [
              'font-size',
              'line-height',
              'padding-top',
              'padding-left',
              'padding-right',
              'padding-bottom',
            ]);

            style = _.union(style, paragraphStyle);

            // также считываем имя класса стиля параграфа
            style.push({
              prop: 'paragraph-class',
              val: paragraph.className,
            });

            // также считываем связаны ли интерльнияж и размер шрифта для данного параграфа (связаны или нет - это важно для панельки типаографики)
            // по дефолту - связаны
            var sizeLeadingLinked = paragraph.getAttribute('data-size-leading-linked');
            style.push({
              prop: 'size-leading-linked',
              val: !sizeLeadingLinked ? true : sizeLeadingLinked == 'true',
            });

            // также считываем пропорцию которой свзаны интерлиньж и размер шрифта (пропорция это тоже для панельки типаографики)
            // по дефолту - 1.25 (params.fontSizeLineHeightRatio)
            style.push({
              prop: 'size-leading-ratio',
              val: (paragraph.getAttribute('data-size-leading-ratio') || this.params.fontSizeLineHeightRatio) - 0,
            });
          }

          if (style.length > 0) {
            filterStyle(style);
            this.cur_selection_styles_all.push(style);
          }
        }, this)
      );

      if (this.cur_selection_styles_all.length == 0) return;

      var old_selection_styles = this.cur_selection_styles;
      this.cur_selection_styles = {};

      var styles_all = [];
      var first = true;
      _.each(
        this.cur_selection_styles_all,
        _.bind(function(style) {
          var style_all = {};

          _.each(
            style,
            _.bind(function(s) {
              style_all[s.prop] = s.val;

              if (this.cur_selection_styles[s.prop] != s.val)
                this.cur_selection_styles[s.prop] = this.params.multipleName;
              if (first) this.cur_selection_styles[s.prop] = s.val;
            }, this)
          );

          styles_all.push(style_all);

          first = false;
        }, this)
      );

      this.cur_selection_styles_all = styles_all;

      var equal = true;
      for (var i in this.cur_selection_styles) {
        if (this.cur_selection_styles[i] != old_selection_styles[i]) {
          equal = false;
          break;
        }
      }

      // сохраняем первый линк который есть в выделении, если он есть
      this.cur_selection_styles.link_nodes = link_nodes;

      /* if (!equal) */ this.trigger('selection_styles_changed', this.cur_selection_styles);
      this.trigger('selection_styles_all_changed', this.cur_selection_styles_all);
      this.initiatorControl = 'user';
    },

    onChangeStyleParagraph: function(param) {
      this.checkForParamUpdateNeeded(this.changeStyleParagraph, param);
    },

    onChangeClassParagraph: function(param) {
      this.checkForParamUpdateNeeded(this.changeClassParagraph, param);
    },

    onChangeStyleSpan: function(param) {
      this.checkForParamUpdateNeeded(this.changeStyleSpan, param);
    },

    onChangeColumns: function(param) {
      this.checkForParamUpdateNeeded(this.changeColumns, param);
    },

    onChangeLink: function(param) {
      this.checkForParamUpdateNeeded(this.changeLink, param);
    },

    // определяем нужно ли нам вообще применить данный параметр
    // и нужно ли его применить немедленно или debounce
    // например когда мы просто меняем цвет, и пришло новое значение цвета, то вызываем изменение с debounce
    // а если подряд пришли сообщения о изменении font-weight и line-height (это происходит когда значения "залинкованы" и меняются одновременно)
    // то для обоих надо вызвать немедленно функцию изменения, иначе debounce проглотит ожно из них и вызовется для последнего
    checkForParamUpdateNeeded: function(func, param) {
      // если старый объект изменения не равен предыдущему, то применим это изменение
      // но посмотрим, надо ли применить немедленно или debounce
      if (!_.isEqual(this.oldChangeParam, param)) {
        var old_keys = _.keys(this.oldChangeParam),
          new_keys = _.keys(param),
          keys_equal = _.difference(old_keys, new_keys).length == 0;

        // пытаемся менять один и тот же параметр, тогда изменим его, но с задержкой
        if (keys_equal) func.__debounced(param);
        else func(param);
      }

      this.oldChangeParam = param;
      // обязательно вызываем

      if (param && param.url) {
        param.url = encodeURI(param.url);
      }
      this.somethingChangedInEditor('formatApplied', param);
    },

    changeStyleParagraph: function(param) {
      if (!param) return;
      if (!this.isEditorActive()) return;
      // у нас в param могут передаваться size-leading-linked и size-leading-ratio
      // это настройки панельки типографики для параграфа
      // эти параметры должны сохраняться как аттрибуты параграфа,
      // а не как аттрибуты стиля параграфа
      // поэтому мы разбиваем param на два списка: аттрибуты и стили
      var attributes = _.pick(param, 'size-leading-linked', 'size-leading-ratio'),
        styles = _.omit(param, 'size-leading-linked', 'size-leading-ratio'),
        settings = { selector: 'p' };

      if (!_.isEmpty(attributes))
        // добавляем data- ко всем ключам объекта
        // преобразуем все значения в строки (для атрибутов так надо)
        settings.attributes = _.object(
          _.map(_.keys(attributes), function(key) {
            return 'data-' + key;
          }),
          _.map(_.values(attributes), function(value) {
            return value + '';
          })
        );

      // хак для всех браузеров, кроме chrome. Pre-wrap не дает образовывать nbsp из последовательности пробелов,
      // но в safari, firefox, edge если задан pre-wrap, не работает свойство justify, решить проблему
      // мирным путем не удалось, поэтому отрубаем pre-wrap при включении justify
      // https://www.notion.so/readymag/34249-Text-jusify-doesn-t-work-in-Safari-21316c693ce84bb09c9434eaa9a2ad91
      if (!Modernizr.chrome) {
        styles['white-space'] = Object.values(styles).indexOf('justify') > -1 ? 'normal' : 'pre-wrap';
      } else {
        styles['white-space'] = 'inherit';
      }

      if (!_.isEmpty(styles)) settings.styles = styles;

      this.editor.formatter.register('temp', settings);

      this.editor.formatter.apply('temp');
    },

    changeClassParagraph: function(param) {
      if (!param) return;
      if (!this.isEditorActive()) return;

      // помечаем все параграфы попавшие в выделение временный классом
      this.editor.formatter.register('temp', {
        selector: 'p',
        classes: 'temp-class',
      });

      this.editor.formatter.apply('temp');

      // находим все нужные нам параграфы
      var $ps = $('.temp-class', this.iframe_document);

      $ps.each(
        _.bind(function(ind, p) {
          var $p = $(p);

          // флаг needIgnoreSlylesMargins включает особое поведение марджинов (нужно для текстового виджета внутри хотспота)
          // когда он включен параграфы прописанные в стилях параграфов не оказывают влияния на параграфы
          // для этого действуем просто: при назначении стиля оверрайдим отступы через инлайн стиль, но только те, которые сейчас не заданы еще через инлайн
          if (this.needIgnoreSlylesMargins) {
            var paddingsToOverride = {},
              v;

            v = p.style.paddingLeft;
            paddingsToOverride['paddingLeft'] = parseInt(v, 10) ? v : 0;

            v = p.style.paddingTop;
            paddingsToOverride['paddingTop'] = parseInt(v, 10) ? v : 0;

            v = p.style.paddingBottom;
            paddingsToOverride['paddingBottom'] = parseInt(v, 10) ? v : 0;

            v = p.style.paddingRight;
            paddingsToOverride['paddingRight'] = parseInt(v, 10) ? v : 0;
          }

          // проставляем параграфам нужный класс
          if (param.class) p.className = param.class;

          if (param.remove) p.className = 'none';

          // а также удаляем у них инлайн стили
          $p.removeAttr('style').removeAttr('data-mce-style');

          // если надо очистить все стили внутри
          if (param.override) {
            $p.find('*')
              .removeAttr('style')
              .removeAttr('data-mce-style');
          }

          // а теперь сложный момент
          // нам также надо очистить стили внутри некоторых других элементов внутри параграфа
          // если внутри параграфа есть текстовые ноды на первом уровне среди других дум элементов, то очищать стили ни у кого не надо
          // в противном случае, если на первом уровне только дум элементы то смотрим (кроме <br>)
          // у всех дум элементов смотрим какие у них расчитаны стили отображения
          // все те аттрибуты стилей которые есть ВО ВСЕХ дум элементах и которые имеют ОДНО и ТО ЖЕ значение
          // мы должны исключить из этих дум элементов
          // зачем все это?
          // затем, что у нас может быть например параграф внутри которого только один спан у когорого задан цвет
          // внешне это выглядит так, как будто цвет задан у параграфа и применяя класс к параграфу мы должны выкинуть все инлайн стили параграфа как бы перезаписывая их параметрами класса
          // и соответсвенно у спана в данном случае мы также должны их выкинуть
          // иными словами любой аттрибут стиля который прослеживается визуально везде внутри параграфа и имеет одно и то же значение должен быть удален
          // примеры верстки до и после применения класса test:
          // 1. ДО    : <p style="color:#ff0000"><span style="font-weight:700">Some <span style="color:#fff">Text</span></span></p>
          // 1. ПОСЛЕ : <p class="test"><span>Some <span style="color:#fff">Text</span></span></p>
          //
          // 2. ДО    : <p style="color:#ff0000">Another Text<span style="font-weight:700">Some Text</span></p>
          // 2. ПОСЛЕ : <p class="test">Another Text<span style="font-weight:700">Some Text</span></p>
          //
          // 3. ДО    : <p style="color:#ff0000"><span style="font-weight:700">Some Text</span><br/><span style="font-weight:700; color:#000">Another Text</span></p>
          // 3. ПОСЛЕ : <p class="test"><span>Some Text</span><br/><span style="color:#000">Another Text</span></p>

          // сначала смотрим есть ли в параграфе текстовые ноды на первом уровне
          // если есть, значит больше ничего делать не надо
          for (var sib = p.firstChild; sib; sib = sib.nextSibling) {
            if (sib.nodeType == 3 && sib.nodeValue != '') break;
          }

          if (!sib && !param.override) {
            var appliedStyles = {};

            // пробегаемся по всем текстовым нодам (не пустым)
            scanTextNodes(
              p,
              _.bind(function(obj) {
                // получаем скалькулированные стили для ноды (только те которые могут быть у спана или линка, т.е. паддингов и фонт сайзов не надо)
                var style = this.getComputedStyle(obj.parentNode, [
                  'font-weight',
                  'font-style',
                  'font-family',
                  'letter-spacing',
                  'color',
                  'text-transform',
                  'vertical-align',
                  'text-decoration',
                ]);

                // здесь не надо делать filterStyle(style);
                // поскольку полученные нами здесь значения используются только для того,
                // чтобы определить сколько различных значений параметра может быть: одно или больше
                // сами значения нам не интересны
                _.each(style, function(param, ind) {
                  if (!appliedStyles[param.prop]) appliedStyles[param.prop] = {};
                  appliedStyles[param.prop][param.val] = true;
                });
              }, this)
            );

            // теперь у нас в appliedStyles есть список всех атрибутов стилей,
            // а внутри каждого атрибута сисок найденных значений
            // теперь среди них нам надо выбрать все атрибуты у которых внутри только одно свойство
            // эти атрибуты стилей нам потом и надо будет удалить из всех нод внутри параграфа

            var attributesToRemove = [];
            _.each(appliedStyles, function(value, key) {
              if (_.keys(value).length == 1) attributesToRemove.push(key);
            });

            // пробегаемся по всем нодам внутри параграфа (br не учитываем)
            // и у каждой удаляем все атрибуты стиля из attributesToRemove
            $p.find('*').each(
              _.bind(function(ind, obj) {
                if (!obj.tagName || obj.tagName.toLowerCase() == 'br') return;

                _.each(attributesToRemove, function(value, ind) {
                  obj.style.removeProperty(value);
                });

                var newStyle = $.trim(obj.style.cssText);

                if (!newStyle) {
                  $(obj)
                    .removeAttr('style')
                    .removeAttr('data-mce-style');
                } else {
                  $(obj).attr('data-mce-style', newStyle);
                }
              }, this)
            );
          }

          // флаг needIgnoreSlylesMargins включает особое поведение марджинов (нужно для текстового виджета внутри хотспота)
          // когда он включен параграфы прописанные в стилях параграфов не оказывают влияния на параграфы
          // для этого действуем просто: при назначении стиля оверрайдим отступы через инлайн стиль, но только те, которые сейчас не заданы еще через инлайн
          if (this.needIgnoreSlylesMargins) {
            p.style.paddingLeft = paddingsToOverride['paddingLeft'];
            p.style.paddingTop = paddingsToOverride['paddingTop'];
            p.style.paddingBottom = paddingsToOverride['paddingBottom'];
            p.style.paddingRight = paddingsToOverride['paddingRight'];

            // это для TinyMce
            p.setAttribute('data-mce-style', p.getAttribute('style'));
          }
        }, this)
      );

      function scanTextNodes(element, cb) {
        if (element.childNodes.length > 0)
          for (var i = 0; i < element.childNodes.length; i++) scanTextNodes(element.childNodes[i], cb);

        if (element.nodeType == 3 && element.nodeValue != '') cb(element);
      }
    },

    changeStyleSpan: function(param) {
      if (!param) return;
      if (!this.isEditorActive()) return;

      this.editor.formatter.register('temp', {
        inline: 'span',
        //	selector: 'a',
        styles: param,
      });

      this.editor.formatter.apply('temp');
    },

    changeColumns: function(param) {
      if (!param) return;
      if (!this.isEditorActive()) return;

      if (param.column_count != undefined) {
        this.model.set({ column_count: param.column_count });
        this.applyCssColumnCount(this.$iframe_body, param.column_count);
      }

      if (param.column_gap != undefined) {
        this.model.set({ column_gap: param.column_gap });
        this.applyCssColumnGap(this.$iframe_body, param.column_gap);
      }

      if (param.bg_color != undefined && param.bg_opacity != undefined) {
        this.model.set({ bg_color: param.bg_color, bg_opacity: param.bg_opacity });
        this.applyCssBgColor(param.bg_color, param.bg_opacity);
      }
    },

    //! !! работа с линками и с data-uuid и пр. также осуществляется в плагине paste в процедуре _insertPlainText !!!
    changeLink: function(param) {
      if (!param) return;
      if (!this.isEditorActive()) return;

      var link_nodes = (this.cur_selection_styles && this.cur_selection_styles.link_nodes) || [],
        collapsed = this.collapsedSelection,
        UUID,
        $links;

      // просят удалить линк
      if (param.action == 'remove') {
        // если текущее выделение - курсор и мы стоим на линке, то выделяем все части линка под курсором
        if (collapsed && link_nodes.length) {
          UUID = $(link_nodes[0]).attr('data-uuid');
          $links = this.$iframe_body.find("a[data-uuid='" + UUID + "']");
          this.selectNodesBoundingSelection($links[0], $links[$links.length - 1]);
        }

        // удаляем линки или их части которые попали в выделение полностью или частично (именно mceInsertLink, а не unlink)
        this.editor.execCommand('mceInsertLink', false, '');
      }

      // просят удалить линк
      if (param.action == 'create') {
        // если текущее выделение - курсор и мы стоим на линке, то выделяем все части линка под курсором
        if (collapsed && link_nodes.length) {
          UUID = $(link_nodes[0]).attr('data-uuid');
          $links = this.$iframe_body.find("a[data-uuid='" + UUID + "']");
        } else {
          UUID = Utils.generateUUID();
          // прописываем создаваемогу линку href='temp-url' чтобы потом его можно было найти (именно mceInsertLink, а не createlink)
          this.editor.execCommand('mceInsertLink', false, 'temp-url');
          // находим линк который создали (или несколько, если линк получился составной)
          $links = this.$iframe_body.find('a[href="temp-url"]');
        }

        var pageParams = {};
        if (param.clickPage) {
          pageParams['data-page-uri'] = param.clickPage;
        }

        // если передано значение null то аттрибут будет удален, такое поведение нам и нужно
        $links.attr(
          _.extend(
            {
              href: param.url,
              'data-mce-href': param.url,
              'data-uuid': UUID,
              'data-pid': RM.constructorRouter.mag.getPageId(param.url) || null, // null это важно, только при null жиквери удалит аттрибут
              class: param.class,
              'data-mce-class': param.class,
              target: param.target,
              'data-anchor-link-pos': param.anchor_pos || null, // null это важно, только при null жиквери удалит аттрибут
            },
            pageParams
          )
        );

        if ($links.length > 0) this.selectNodesBoundingSelection($links[0], $links[$links.length - 1]);
      }

      // просят сменить класс линков в выделении
      if (param.action == 'change-class' && link_nodes.length) {
        // $(link_nodes) - преобразуем обычный массив обычных дум нод в жиквери массив жиквери нод
        // если передано значение null то аттрибут будет удален, такое поведение нам и нужно
        $(link_nodes).attr({ class: param.class, 'data-mce-class': param.class });
      }

      // просят сменить таргет линков в выделении
      if (param.action == 'change-target' && link_nodes.length) {
        // $(link_nodes) - преобразуем обычный массив обычных дум нод в жиквери массив жиквери нод
        // если передано значение null то аттрибут будет удален, такое поведение нам и нужно
        $(link_nodes).attr({ target: param.target });
      }
    },

    changeNbspToSpan: function() {
      var selection = this.editor.selection,
        bookmark = selection.getBookmark(),
        text = this.$iframe_body[0].innerHTML,
        filteredText;

      filteredText = text.replace(/<span class="nbsp">(&nbsp;)?(.*?)<\/span>/g, '$1$2');
      filteredText = filteredText.replace(/&nbsp;/g, '<span class="nbsp">&nbsp;</span>');
      this.$iframe_body[0].innerHTML = filteredText;
      this.editor.selection.moveToBookmark(bookmark);
    },

    changeSpanToNbsp: function() {
      var text = this.$iframe_body[0].innerHTML,
        filteredText;
      filteredText = text.replace(/<span class="nbsp">(&nbsp;)?(.*?)<\/span>/g, '$1$2');
      this.$iframe_body[0].innerHTML = filteredText;
    },

    // TODO Можеть быть вынести в плагин вместе с шорткатами выше.
    insertCharacter: function(htmlCode, event) {
      this.editor.execCommand('mceInsertContent', false, htmlCode);
      if (htmlCode === '&nbsp;') {
        this.changeNbspToSpan();

        if (RM && RM.constructorRouter && RM.constructorRouter.analytics) {
          RM.constructorRouter.analytics.sendEvent('Key Press', 'insert non-breaking space');
        }
      }
      event.preventDefault();
    },
  },
  {
    defaults: {
      text: function() {
        return '<p>' + FISH_TEXTS[Math.floor(Math.random() * FISH_TEXTS.length)] + '</p>';
      },
      column_count: 1,
      column_gap: 16,
      bg_color: 'ffffff',
      bg_opacity: 0,
      version: 2,
    },
  }
).extend(TextViewportsClass.prototype);

/**
 * Класс для марджинов
 * Часть манипуляций с DOM написана на чистом JS - для производительности
 */
var MarginController = Backbone.View.extend({
  params: {
    maxTopMargin: 999,
    maxBottomMargin: 999,
    maxLeftMargin: 999,
    maxRightMargin: 999,
    handleThreshold: 4, // размер области в px где сработает ховер на границу параграфа
    minHandleSize: 43, // минимальный размер границы параграфа, начиная с которого меняется внешний вид контрола за который тянем марджин (синенький язычок)
  },

  initialize: function(block) {
    _.bindAll(this);

    this.block = block;

    this.block.$iframe_document.on('scroll', this.onScroll);

    // эта вьюха рендерит рамки вокруг параграфов
    this.marginBlocks = new MarginBlocks(this, this.block.$('.margins-wrapper-inner')[0]);

    // эта вьюха управляет полоской и язычком за который можно тянуть отступы
    this.marginHandle = new MarginHandle(this, this.block.$el[0]);

    this.onScroll();
  },

  onScroll: function() {
    this.scrollTop = this.block.$iframe_document.scrollTop();
    this.scrollLeft = this.block.$iframe_document.scrollLeft();

    this.marginBlocks.onScroll(this.scrollTop, this.scrollLeft);
    this.marginHandle.onScroll(this.scrollTop, this.scrollLeft);
  },

  recalc: function() {
    if (!this.block.isEditorActive()) return;

    // сохраняем исходные размеры области редактирования
    this.dw = this.block.$iframe_document.width();
    this.dh = this.block.$iframe_document.height();

    // получаем текущее выделение
    var sel = rangy.getSelection(this.block.iframe),
      range;

    try {
      range = sel.getRangeAt(0);
    } catch (e) {
      return;
    }

    // получаем параграфы в которых выделение начинается и заканчивается
    var stParagraph = this.block.getClosestParagraph(range.startContainer);
    var edParagraph = this.block.getClosestParagraph(range.endContainer);

    // в ФФ бывает так, что startContainer или endContainer указывают на div#main - например когда pgUp нажимаем
    if (
      (!stParagraph && range.startContainer.tagName != 'BODY') ||
      (!edParagraph && range.endContainer.tagName != 'BODY')
    ) {
      this.marginBlocks.proceed([]);
      this.marginHandle.proceed([], []);
      return;
    }

    // такое бывает в ФФ (например по CTRL+A в выделении лежит весь body)
    // в таком случае сами ищем все параграфы в боди
    if (!stParagraph || !edParagraph) {
      var allParagraphs = this.block.$iframe_body.find('p');
      if (allParagraphs.length == 0) {
        this.marginBlocks.proceed([]);
        this.marginHandle.proceed([], []);
        return;
      }

      if (!stParagraph) stParagraph = allParagraphs.get(0);
      if (!edParagraph) edParagraph = allParagraphs.get(-1);
    }

    // получаем параграфы которые находятся между stParagraph и edParagraph (ведь они у нас тоже в выделение попали)
    var paragraphs = [{ paragraph: stParagraph }];
    while (stParagraph && stParagraph !== edParagraph) {
      stParagraph = stParagraph.nextSibling;

      // бывает так, что между параграфами идут текстовые ноды с \n\n\n..., визуально они не видны, но существуют
      // пожтому отдельно проверяем чтобы в список добавлялись только параграфы
      if (stParagraph.tagName == 'P') paragraphs.push({ paragraph: stParagraph });
    }

    // если верстка многоколоночная
    // то после каждого параграфа в списке создаем вспомогательные объекты
    // по которым будем расчитывать положение параграфа
    if (this.block.model.get('column_count') > 1) {
      // вставляем временный див нулевой высоты перед первым параграфом
      var node = this.insertDiv('margin-st', 'before', paragraphs[0].paragraph);
      paragraphs[0].st = node;

      for (var i = 0; i < paragraphs.length; i++) {
        // вставляем временные дивы нулевой высоты между текущим и следующим параграфом
        var node = this.insertDiv('margin-ed', 'after', paragraphs[i].paragraph);
        paragraphs[i].ed = node;
        if (i + 1 < paragraphs.length) paragraphs[i + 1].st = node;
      }

      // расчитываем позиции колонок
      var columns = this.block.calcColumnsPositions();
    }

    // на данный момент в paragraphs мы имеем список параграфов для которых надо отобразить марджины
    // пробегаемся по всем параграфам, и для каждого находим блоки на которые параграф разбит (в случае колоночной верстки)
    // а также те линии за которые можно тянуть чтобы изменить отступы

    var marginBlocks = [],
      marginHandles = [];

    _.each(
      paragraphs,
      _.bind(function(item) {
        // получаем данные по расположению отрендеренного параграфа
        // два объекта: где расположены рамки и где расположены линии за которые можно тянуть
        var paragraphData = this.calcParagraphDimensions(item, columns);

        // собираем все в один массив
        _.each(
          paragraphData.marginBlocks,
          _.bind(function(marginBlock) {
            marginBlocks.push(marginBlock);
          }, this)
        );

        // собираем все в один массив
        _.each(
          paragraphData.marginHandles,
          _.bind(function(marginHandle) {
            marginHandles.push(marginHandle);
          }, this)
        );
      }, this)
    );

    // удаляем временные дивы
    if (this.block.model.get('column_count') > 1) {
      var parent = paragraphs[0].paragraph.parentNode;
      parent.removeChild(paragraphs[0].st);

      for (var i = 0; i < paragraphs.length; i++) parent.removeChild(paragraphs[i].ed);
    }

    // передаем данные по блокам которые надо нарисовать в marginBlocks
    this.marginBlocks.proceed(marginBlocks);

    // передаем данные по границам за которые можно тянуть в marginHandles
    // этот код запустит кумулятивный reflow-repaint поскольку он смотрит размеры редактора (getEditorDimensions)
    // (кумулятивный из-за того, что marginBlocks.proceed нарисует рамки (границы параграфов), но все его изменения в DOM будут группироваться, поскольку мы не запрашиваем там никаких computed style или offsetWidth)
    // поэтому следующий код идет последним, чтобы не было еще reflow-repaint после выхода из потока исполнения
    this.marginHandle.proceed(marginHandles, _.pluck(paragraphs, 'paragraph'));
  },

  insertDiv: function(cl, where, target) {
    var node = this.block.iframe_document.createElement('div');
    node.className = cl;
    if (where == 'before') target.parentNode.insertBefore(node, target);
    else target.parentNode.insertBefore(node, target.nextSibling);

    return node;
  },

  // по горизонтальной координате начала параграфа определяет номер колонки (по списку координат колонок columns)
  getColumnIndex: function(columns, x) {
    var minDist = 9999,
      minInd = 0,
      dist;

    for (var i = 0; i < columns.length; i++) {
      dist = Math.abs(x - columns[i].st);
      if (dist < minDist) {
        minDist = dist;
        minInd = i;
      }
    }

    return minInd;
  },

  // расчитывает координаты параграфа
  // в случае если у нас многоколоночная верстка, возвращает массив блоков с координатами (на сколько колонок параграф разбился, столько блоков и есть)
  calcParagraphDimensions: function(item, columns) {
    var paragraph = item.paragraph;

    // узнаем текущие марджины у параграфа
    // надо использовать getComputedStyle, потому что марджины могут быть заданы не только через инлайн стили, но и через классы
    var compStyle = this.block.iframe_document.defaultView.getComputedStyle(paragraph, null),
      mt = parseInt(compStyle.getPropertyValue('padding-top'), 10),
      mr = parseInt(compStyle.getPropertyValue('padding-right'), 10),
      mb = parseInt(compStyle.getPropertyValue('padding-bottom'), 10),
      ml = parseInt(compStyle.getPropertyValue('padding-left'), 10),
      mtOrig = mt,
      mbOrig = mb;

    // для одноколоночной верстки все просто
    if (this.block.model.get('column_count') == 1) {
      var BB = paragraph.getBoundingClientRect(),
        w1 = BB.width,
        w2 = w1,
        x1 = BB.left + this.scrollLeft,
        x2 = x1,
        y1 = BB.top + this.scrollTop,
        y2 = y1 + BB.height;
    } else {
      // для многоколоночной смотрим положение див-хелперов спереди и позади параграфа
      var stBB = item.st.getBoundingClientRect(),
        edBB = item.ed.getBoundingClientRect(),
        w1 = stBB.width,
        w2 = edBB.width,
        x1 = stBB.left + this.scrollLeft,
        x2 = edBB.left + this.scrollLeft,
        y1 = stBB.top + this.scrollTop + (item.st.className == 'margin-ed' ? +1 : 0),
        y2 = edBB.top + this.scrollTop + 1;
    }

    var marginBoxes = [];

    if (this.block.model.get('column_count') > 1) {
      // находим на сколько колонок разбился параграф
      // в columns ищем индекс колонки в которой параграф начался
      // и индекс колонки в которой закончился
      var startInd = this.getColumnIndex(columns, x1),
        endInd = this.getColumnIndex(columns, x2);

      for (var i = startInd; i <= endInd; i++) {
        var column = columns[i],
          h;

        if (startInd == endInd) h = y2 - y1;
        else if (i == startInd) h = this.dh - y1;
        else if (i == endInd) h = y2;
        else h = this.dh;

        var marginBox = {
          x: column.st,
          w: column.w,
          y: i == startInd ? y1 : 0,
          h: h,
        };

        marginBoxes.push(marginBox);
      }
    } else {
      // для одноколоночной верски всегда будет один блок
      var marginBox = {
        x: x1,
        y: y1,
        w: w1,
        h: y2 - y1,
      };
      marginBoxes.push(marginBox);
    }

    var cnt = marginBoxes.length;

    for (var i = 0; i < cnt; i++) {
      var box = marginBoxes[i];

      // если заданы такие марджины, что они сами по себе шире блока параграфа
      // тогда лимитируем их
      if (ml + mr > box.w) {
        if (ml > box.w) {
          ml = box.w;
          mr = 0;
        } else {
          mr = box.w - ml;
        }
      }

      box.mr = mr;
      box.ml = ml;

      // переносим верхний марджин который не поместился в колонке на следующую колонку
      box.mt = mt;
      if (box.h < mt) box.mt = box.h;
      mt = Math.max(mt - box.h, 0);
    }

    for (var i = cnt - 1; i >= 0; i--) {
      var box = marginBoxes[i];

      // переносим нижний марджин который не поместился в колонке на предыдущую колонку
      box.mb = mb;
      if (box.h < mb) box.mb = box.h;
      mb = Math.max(mb - box.h, 0);
    }

    // из полученныз данных в marginBoxes формируем для списка
    // один для вьюхи которая рендерит рамки
    // второй для вьюхи которая контролирует редактирование отступов
    var marginBlocks = [],
      marginHandles = [],
      marginHandle,
      foundFirstColumnWithTopMargin = false; // индикатор того, что мы нашли колонку в которой можно менять верхний отступ

    for (var i = 0; i < cnt; i++) {
      var box = marginBoxes[i];

      // расчитываем где рисовать блок который оторажает отступы параграфа

      var marginBlock = {
        // приставка "o" это внешняя рамка параграфа - область которую занимает параграф с учетом отступов (Outer)
        ox: box.x,
        oy: box.y,
        ow: box.w,
        oh: box.h,
        // отступы
        mt: box.mt,
        mb: box.mb,
        ml: box.ml,
        mr: box.mr,
      };

      // внутренний блок показывающий марджины никогда не должен быть вырожден
      // т.е. его ширина всегда должны быть >= 2
      // и он всегда должен быть внутри рамки параграфа
      // такое случается если задана конские марджины, больше чем ширина параграфа

      var w = box.w - box.ml - box.mr,
        x = box.x + box.ml;

      if (w == 0) {
        w = 2;
        x--;
      } else if (w == 1) {
        w = 2;
      }

      if (x + w > box.x + box.w) x = box.x + box.w - w;
      if (x < box.x) x = box.x;

      // приставка "i" это внутренняя рамка параграфа - область которую занимает параграф внутри отступов (Inner)
      marginBlock.ix = x;
      marginBlock.iy = box.y + box.mt;
      marginBlock.iw = w;
      marginBlock.ih = box.h - box.mt - box.mb;

      marginBlocks.push(marginBlock);

      // расчитываем за какую линию в этом блоке можно потянуть и куда именно
      // (для одного параграфа идет по одному блоку на каждую колонку)
      // всего в одном блоке может быть максимум 4 линии за которые можно тянуть
      // но может и не быть ни одной (например если блок просто отображает конский отступ сверху или снизу, а контет полностью в других колонках)

      // -1-
      // проверяем можно ли в этой блоке менять верхний марджин
      // надо определить в какой блоке-колонке, потому что не обязательно в первом
      if (!foundFirstColumnWithTopMargin && marginBlock.oh - marginBlock.mt - marginBlock.mb > 0) {
        marginHandle = {
          tp: 'top', // тип марджина который будем тянуть
          column: i, // номер колонки, он нам потом еще очень пригодится
          p: paragraph, // параграф (DOM node) к которому относится колонка, потом тоже пригодится
          val: mtOrig, // числовое значение марджина
          max: this.params.maxTopMargin,

          // координаты линии за которую можно тянуть (берем из данных которые подготовили выше, для рамок)
          // тут координаты верхней границы параграфа внутри марджина
          x: marginBlock.ix,
          y: marginBlock.iy,
          size: marginBlock.iw,
        };

        marginHandles.push(marginHandle);

        foundFirstColumnWithTopMargin = true;
      }

      // -2-
      // проверяем можно ли в этом блоке менять нижний марджин
      // нижний марджин можно менять только в самом последнем блоке
      // потому что тянем там не за внутреннюю рамку, а за внешнюю
      if (i == cnt - 1) {
        marginHandle = {
          tp: 'bottom',
          column: i,
          p: paragraph,
          val: mbOrig,
          max: this.params.maxBottomMargin,

          // тут координаты нижней границы параграфа СНАРУЖИ марджина
          x: marginBlock.ox,
          y: marginBlock.oy + marginBlock.oh - 1,
          size: marginBlock.ow,
        };

        marginHandles.push(marginHandle);
      }

      // -3,4-
      // проверяем можно ли в этом блоке менять левый-правый марджин
      // можно только если блок не вырожден, т.е. в нем есть контент
      // а то могут быть блоки в которых отображается только марджин (верхний или нижний)
      if (marginBlock.oh - marginBlock.mt - marginBlock.mb > 0) {
        marginHandle = {
          tp: 'left',
          column: i,
          p: paragraph,
          val: box.ml, // здесь именно box.ml, а не просто ml

          // ограничиваем максимальное значение отступа для текущего состояния параграфа
          // т.е. максимум можно вплотную в правой границе, но не больше
          max: Math.min(marginBlock.ow - marginBlock.mr, this.params.maxLeftMargin),

          // тут координаты левой границы параграфа внутри марджина
          x: marginBlock.ix,
          y: marginBlock.iy,
          size: marginBlock.ih,
        };

        marginHandles.push(marginHandle);

        marginHandle = {
          tp: 'right',
          column: i,
          p: paragraph,
          val: box.mr, // здесь именно box.mr, а не просто mr

          // ограничиваем максимальное значение отступа для текущего состояния параграфа
          // т.е. максимум можно вплотную в левой границе, но не больше
          max: Math.min(marginBlock.ow - marginBlock.ml, this.params.maxRightMargin),

          // тут координаты правой границы параграфа внутри марджина
          x: marginBlock.ix + marginBlock.iw - 1,
          y: marginBlock.iy,
          size: marginBlock.ih,
        };

        marginHandles.push(marginHandle);
      }
    }

    return { marginBlocks: marginBlocks, marginHandles: marginHandles };
  },

  show: function() {
    this.marginBlocks.show();
    this.marginHandle.show();
    this.onScroll();
  },

  hide: function() {
    this.marginBlocks.hide();
    this.marginHandle.hide();
  },
});

// вьюха которая рисует рамки параграфа и марджина (зелененькие)
var MarginBlocks = Backbone.Model.extend({
  // создаем DOM разметку
  initialize: function(controller, container) {
    _.bindAll(this);

    this.controller = controller;
    this.container = container;

    // кеш для созданных рамок в DOM
    // чтобы не создавать их каждый раз, а просто перерендеривать
    this.marginBlocks = [];
  },

  onScroll: function(scrollTop, scrollLeft) {
    $(this.container).css({ top: -scrollTop, left: -scrollLeft });
  },

  proceed: function(marginBlocks) {
    // отключаем все блоки использованные ранее
    var len = this.prevUsedBlocks ? this.prevUsedBlocks : this.marginBlocks.length;
    for (var i = 0; i < len; i++) this.clear(this.marginBlocks[i]);

    for (var i = 0; i < marginBlocks.length; i++) {
      var marginBlock = marginBlocks[i];

      // если у нас в кеше нет созданного блок - то создаем его
      if (!this.marginBlocks[i]) this.marginBlocks[i] = this.createNewMarginBlock();

      this.render(this.marginBlocks[i], marginBlock);
    }

    this.prevUsedBlocks = marginBlocks.length;
  },

  createNewMarginBlock: function() {
    var self = this,
      marginBlock = {};

    marginBlock.t = createDiv('margin-top-block');
    marginBlock.b = createDiv('margin-bottom-block');
    marginBlock.l = createDiv('margin-left-block');
    marginBlock.r = createDiv('margin-right-block');

    marginBlock.outer = createDiv('margin-outer-frame');
    marginBlock.inner = createDiv('margin-inner-frame');

    function createDiv(className) {
      var node = document.createElement('div');
      node.className = className;
      self.container.appendChild(node);
      return node;
    }

    return marginBlock;
  },

  render: function(marginBlockDom, data) {
    // внешняя рамка показывает границы параграфа с марджинами
    setStyle(marginBlockDom.outer, data.ox, data.oy, data.ow, data.oh);

    // внутренняя рамка показывает границы параграфа внутри марджинов
    if (data.mb != 0 || data.mt != 0 || data.mr != 0 || data.ml != 0)
      setStyle(marginBlockDom.inner, data.ix, data.iy, data.iw, data.ih);

    // рисуем полупрозрачную заливку области марджинов
    if (data.mt != 0) setStyle(marginBlockDom.t, data.ox, data.oy, data.ow, data.mt);

    if (data.mb != 0) setStyle(marginBlockDom.b, data.ox, data.oy + data.oh - data.mb, data.ow, data.mb);

    if (data.ml != 0) setStyle(marginBlockDom.l, data.ox, data.oy + data.mt, data.ml, data.oh - data.mt - data.mb);

    if (data.mr != 0)
      setStyle(marginBlockDom.r, data.ox + data.ow - data.mr, data.oy + data.mt, data.mr, data.oh - data.mt - data.mb);

    function setStyle(node, x, y, w, h) {
      var st = node.style;
      st.left = x + 'px';
      st.top = y + 'px';
      st.width = w < 0 ? 0 : w + 'px';
      st.height = h < 0 ? 0 : h + 'px';
      st.display = 'block';
    }
  },

  clear: function(marginBlockDom) {
    marginBlockDom.inner.style.display = 'none';
    marginBlockDom.outer.style.display = 'none';
    marginBlockDom.t.style.display = 'none';
    marginBlockDom.b.style.display = 'none';
    marginBlockDom.l.style.display = 'none';
    marginBlockDom.r.style.display = 'none';
  },

  show: function() {
    this.container.parentNode.style.display = 'block';
  },

  hide: function() {
    this.container.parentNode.style.display = 'none';
  },
});

// вьюха которая управляем изменением марджинов
// рисует синюю полочку с ярлыком и следит когда его показывать-скрывать и пр.
var MarginHandle = Backbone.View.extend({
  initialize: function(controller, container) {
    _.bindAll(this);

    this.controller = controller;
    this.container = container;

    // поскольку вся верстка которую мы указываем в text.html располагается не прямо в виджете
    // а в его элементе .content, нам приходится самим создавать верстку для контрола марджинов
    // напрямую в виджете, иначе у нас проблемы с z-index и контрол всегда будет под рамкой самого виджета и точками ресайза
    this.template = templates['template-constructor-block-text-margins'];
    this.setElement($(this.template({})).appendTo(this.container));

    this.initialData = undefined; // изначальные данные на основе которых контрол был показан (тип, номер колонки, положение, параграф и пр.)
    this.data = undefined; // последние актуальные данные по контролу
    this.mx = 0;
    this.my = 0;
    this.mxOnFocusEnter = 0;
    this.myOnFocusEnter = 0;
    this.scrollLeft = 0;
    this.scrollTop = 0;
    this.marginHandles = [];
    this.state = 'idle';
    this.paragraphs = [];
    this.mouseProceeding = true;

    this.$el.RMDrag({
      start: this.startHandleDrag,
      move: this.moveHandleDrag,
      end: this.endHandleDrag,
      silent: true,
      preventDefault: true,
    });

    this.controller.block.on('textWidgetMouseSelectionStart', this.onMouseSelectionStart);
    this.controller.block.on('textWidgetMouseSelectionEnd', this.onMouseSelectionEnd);

    this.$marginInput = this.$el.find('.margin-input');

    this.$marginInput.RMNumericInput({
      needMinMaxCurChanger: true,
      min: 0,
      max: 999,
      onChange: this.onInputChange,
      mouseUse: false,
    });

    // ручки чтобы поменять значение в инпуте и ограничения Min-Max (потому что RMNumericInput еще фильтрует и много чего делает, чтобы лажу не ввели, и некоторые свои данные обновлет)
    this.inputChangeValue = this.$marginInput.data('changeValue');
    this.inputChangeMinMax = this.$marginInput.data('changeMinMax');

    this.$marginInput.on('focus', this.onInputFocus).on('blur', this.onInputBlur);
  },

  // если в текстовом редакторе начали что-то выделять мышкой:
  // прячем контрол (хотя не могу представить ситуацию когда он может быть виден если начали выделять)
  // и запрещаем обрабатвать событие мыши mousemove
  onMouseSelectionStart: function() {
    this.hideHandle();
    this.mouseProceeding = false;
  },

  // если в текстовом редакторе закончили что-то выделять мышкой:
  // разрешаем обрабатвать событие мыши mousemove
  // и заново его вызываем
  onMouseSelectionEnd: function() {
    this.mouseProceeding = true;
    this.onMouseMove();
  },

  startHandleDrag: function(e) {
    this.$marginInput.blur();

    this.state = 'dragging';
    this.oldDelta = 0;

    this.showHandle('show');
    this.onMouseMove();

    this.controller.block.trigger('textWidgetMarginDragStart');
  },

  moveHandleDrag: function(e) {
    if (!this.data) return;

    var delta = this.data.tp == 'top' || this.data.tp == 'bottom' ? e.deltaY : e.deltaX,
      dir = this.data.tp == 'right' ? -1 : 1,
      newVal = this.data.val + (delta - this.oldDelta) * dir;

    // хитрые манитуляции с this.oldDelta - предыдущим смещением
    // суть в том, что если мы сдвинули например левую границу очень сильно влево, так что она стала меньше 0
    // но поскольку меньше 0 нельзя, то граница становиться в 0, но при этом хочется чтобы обратно увеличиваться граница
    // начала не тогда когда мы просто поведем мышку в обратное направление, а когда мыша пройде мимо той точки,
    // когда граница стала в 0
    // чтобы лучше это понять можно просто в следующих двух условиях везде заменить на this.oldDelta = delta
    // и поиграться в конструкторе с максимальными - минимальными отступами
    if (newVal < 0) {
      this.applyStyle(this.data.tp, 0);
      this.oldDelta = delta - newVal * dir;
    } else if (newVal > this.data.max) {
      this.applyStyle(this.data.tp, this.data.max);
      this.oldDelta = delta + (this.data.max - newVal) * dir;
    } else {
      this.applyStyle(this.data.tp, newVal);
      this.oldDelta = delta;
    }
  },

  endHandleDrag: function(e) {
    this.controller.block.trigger('textWidgetMarginDragEnd');

    if (e.moved) {
      this.hideHandle();
      this.state = 'idle';
      this.onMouseMove();
    } else {
      // если не сдвинули мышь, то считаем кликом
      this.mxOnFocusEnter = this.mx;
      this.myOnFocusEnter = this.my;
      this.$marginInput.focus();
      this.$marginInput.select();
      // ВАЖНО!!!!
      // Раньше было так:
      // this.$marginInput.select();
      // this.$marginInput.focus();
      // В результате в хроме происходил необъяснимый сдвиг рабочей области,
      // а TinyMCE через некоторое время вываливается с ошибкой  Uncaught RangeError: Maximum call stack size exceeded.
      // https://trello.com/c/Q07glQl0/132--
    }
  },

  onInputFocus: function() {
    this.state = 'focus';
  },

  onInputBlur: function() {
    this.state = 'idle';

    // закомментировал эту строчку, потому что при повторном клике по полю ввода в язычке он исчезал (язычок)
    // this.onMouseMove();

    if (this.controller.block.isEditMode) this.controller.block.editor.focus();
  },

  onInputChange: function($input, num) {
    if (!this.data) return;

    this.applyStyle(this.data.tp, num);
  },

  applyStyle: function(tp, val) {
    // флаг needSideMarginsSynhronized включает особое поведение марджинов (нужно для текстового виджета внутри хотспота)
    // когда он включен все изменения по боковым марджинам применяются ко всем параграфам, а не только к тем, что в выделении
    // также он синхронизирует измение левых и правых отступов
    var needSideMarginsSynhronized = this.controller.block.needSideMarginsSynhronized,
      paragraphs =
        needSideMarginsSynhronized && (tp == 'left' || tp == 'right')
          ? this.controller.block.$iframe_body.find('p')
          : this.paragraphs;

    for (var i = 0; i < paragraphs.length; i++) {
      var paragraph = paragraphs[i];
      if (tp == 'top') paragraph.style.paddingTop = val + 'px';
      if (tp == 'bottom') paragraph.style.paddingBottom = val + 'px';
      if (tp == 'left') {
        paragraph.style.paddingLeft = val + 'px';
        if (needSideMarginsSynhronized) {
          paragraph.style.paddingRight = val + 'px';
        }
      }
      if (tp == 'right') {
        paragraph.style.paddingRight = val + 'px';
        if (needSideMarginsSynhronized) {
          paragraph.style.paddingLeft = val + 'px';
        }
      }

      // это для tinymce
      paragraph.setAttribute('data-mce-style', paragraph.getAttribute('style'));
    }

    var params = {};
    params['padding-' + tp] = val + 'px';
    this.controller.block.somethingChangedInEditor('margins', params);
  },

  onMouseMove: function(e) {
    if (!this.shown) return;

    if (e) {
      this.mx = e.pageX;
      this.my = e.pageY;
    }

    if (!this.mouseProceeding) return;

    this.getEditorDimensions();

    // режим - просто ищем попадает ли курсор на какую либо границу параграфа за которую можно тянуть
    if (this.state == 'idle') {
      var threshold = this.controller.params.handleThreshold;

      // смотрим только если мыша в пределах виджета
      if (
        !(
          this.mx < this.editorLeft - threshold ||
          this.mx >= this.editorLeft + this.editorWidth + threshold ||
          this.my < this.editorTop - threshold ||
          this.my >= this.editorTop + this.editorHeight + threshold
        )
      ) {
        // пробегаемся по все доступным границам параграфа за которые нам дозволено тянуть
        // и проверяем не находится ли мыша прямо над ними
        var found = false;

        for (var i = 0; i < this.marginHandles.length; i++) {
          // находим координаты линий на экране
          var marginHandle = this.marginHandles[i],
            x = this.editorLeft - this.scrollLeft + marginHandle.x,
            y = this.editorTop - this.scrollTop + marginHandle.y,
            w,
            h;

          if (marginHandle.tp == 'top') {
            h = threshold;
            w = marginHandle.size;
            y -= threshold - 1;
          }
          if (marginHandle.tp == 'bottom') {
            h = threshold;
            w = marginHandle.size;
          }
          if (marginHandle.tp == 'left') {
            w = threshold;
            h = marginHandle.size;
            x -= threshold - 1;
          }
          if (marginHandle.tp == 'right') {
            w = threshold;
            h = marginHandle.size;
          }

          if (!(this.mx < x || this.mx >= x + w || this.my < y || this.my >= y + h)) {
            // здесь действительно проверка на идентичность объектов по указателям, а не по содержимому
            if (this.curHoverMarginHandle != marginHandle) {
              // синюю полоску показываем сразу же
              this.data = _.clone(marginHandle);
              this.initialData = _.clone(marginHandle);
              this.showHandle('only-line');

              this.curHoverMarginHandle = marginHandle;
            }

            found = true;
            break; // обязательно! иначе может найти несколько границ под одной точкой
          }
        }

        if (!found) this.hideHandle();
      } else {
        this.hideHandle();
      }
    }

    if (this.state == 'dragging') {
      this.updateHandleLabelPos();
    }
  },

  getEditorDimensions: function() {
    var BB = this.controller.block.$el[0].getBoundingClientRect();

    this.editorWidth = BB.width;
    this.editorHeight = BB.height;
    this.editorLeft = BB.left;
    this.editorTop = BB.top;
  },

  // показывает контрол управления марджином (синия полоска двухпиксельная и язычок)
  showHandle: function(tp) {
    var data = this.data;

    if (!data) return;

    var x = data.x - this.scrollLeft,
      y = data.y - this.scrollTop,
      w,
      h;

    data.limited_x = data.x;
    data.limited_y = data.y;
    data.limited_size = data.size;

    // если "синяя" граница которую мы собираемся нарисовать вылезает за пределы виджета
    if (data.tp == 'top' || data.tp == 'bottom') {
      // если вся вылезла - скрываем ее и язычок
      var outOfBox = false;
      if (y < 0 || y >= this.editorHeight) outOfBox = true;
      if (x >= this.editorWidth || x + data.size <= 0) outOfBox = true;
      this.$el.toggleClass('out-of-box', outOfBox);

      // если вылезла частично влево или справо
      // в случае с top-bottom марджинами она может вылезти только в одну из сторон, но не в обе одновременно
      // это происходит в многоколоночной верстке, если есть внутренний скрол например до середины колонки
      // и мы у этой колонки начинаем менять top-bottom марджин
      // в limited_ мы сохраняем координаты полоски с учетом обрезки (используются в других местах)
      if (x < 0) {
        data.limited_x = data.x - x;
        data.limited_size = data.size + x; // раз x < 0, значит он отрицательный, поэтому data.size +
        x = 0;
      } else if (x + data.size > this.editorWidth) {
        data.limited_size = this.editorWidth - x;
      }
    }

    // если "синяя" граница которую мы собираемся нарисовать вылезает за пределы виджета
    if (data.tp == 'left' || data.tp == 'right') {
      // если вся вылезла - скрываем ее и язычок
      var outOfBox = false;
      if (x < 0 || x >= this.editorWidth) outOfBox = true;
      if (y >= this.editorHeight || y + data.size <= 0) outOfBox = true;
      this.$el.toggleClass('out-of-box', outOfBox);

      // если вылезла частично вверх или вниз или в оба
      if (y < 0 && y + data.size > this.editorHeight) {
        data.limited_y = data.y - y;
        data.limited_size = this.editorHeight;
        y = 0;
      } else if (y < 0) {
        data.limited_y = data.y - y;
        data.limited_size = data.size + y; // раз y < 0, значит он отрицательный, поэтому data.size +
        y = 0;
      } else if (y + data.size > this.editorHeight) {
        data.limited_size = this.editorHeight - y;
      }
    }

    var threshold = this.controller.params.handleThreshold;

    if (data.tp == 'top') {
      h = threshold;
      w = data.limited_size;
      y -= threshold - 1;
    }
    if (data.tp == 'bottom') {
      h = threshold;
      w = data.limited_size;
    }
    if (data.tp == 'left') {
      w = threshold;
      h = data.limited_size;
      x -= threshold - 1;
    }
    if (data.tp == 'right') {
      w = threshold;
      h = data.limited_size;
    }

    // если граница стала меньше чем определенное значение, то меняем внешний вид контрола (синего язычка)
    this.$el.toggleClass('small', data.limited_size < this.controller.params.minHandleSize);

    this.$el.css({
      left: x,
      top: y,
      width: w,
      height: h,
    });

    if (tp == 'show' || tp == 'update') {
      this.inputChangeValue && this.inputChangeValue(data.val, true);
      this.inputChangeMinMax && this.inputChangeMinMax(0, data.max);
    }

    if (tp == 'show' || tp == 'only-line') {
      this.$el
        .removeClass('margin-top')
        .removeClass('margin-bottom')
        .removeClass('margin-left')
        .removeClass('margin-right')
        .addClass('margin-' + data.tp);
    }

    if (tp == 'show') {
      this.$el.removeClass('inactive');
    }

    if (tp == 'only-line') {
      this.$el.addClass('only-line');
    }
  },

  hideHandle: function() {
    this.$el.addClass('inactive');
    this.$el.removeClass('only-line');
    this.$el.removeClass('out-of-box');
    this.data = undefined;
    this.initialData = undefined;
    this.state = 'idle';
    this.curHoverMarginHandle = undefined;

    // на всяки случай, вдруг у нас не сбросится этот флаг если по какой-либо причине не выстрелит onMouseSelectionEnd
    this.mouseProceeding = true;
  },

  // двигаем язычок вслед за мышкой
  updateHandleLabelPos: function(useMousePosOnFocusEnter) {
    if (!this.data) return;

    var $label = this.$el.find('.margin-handle-label-wrapper'),
      mx = useMousePosOnFocusEnter ? this.mxOnFocusEnter : this.mx,
      my = useMousePosOnFocusEnter ? this.myOnFocusEnter : this.my;

    if (this.data.tp == 'top' || this.data.tp == 'bottom') {
      var dx = mx - (this.editorLeft - this.scrollLeft + this.data.limited_x),
        labelWidth = $label.width();
      dx -= Math.round(labelWidth / 2);
      dx = Math.min(Math.max(dx, 0), this.data.limited_size - labelWidth);
      $label.css({ left: dx, top: 'auto' });
    } else {
      var dy = my - (this.editorTop - this.scrollTop + this.data.limited_y),
        labelHeight = $label.height();
      dy -= Math.round(labelHeight / 2);
      dy = Math.min(Math.max(dy, 0), this.data.limited_size - labelHeight);
      $label.css({ top: dy, left: 'auto' });
    }
  },

  proceed: function(marginHandles, paragraphs) {
    this.marginHandles = marginHandles;
    this.paragraphs = paragraphs;

    // если у нас показан контрол и при этом пришли изменения из редактора
    // то контрол надо перерисовать в другом положении
    if (this.initialData) {
      // тут непростой момент
      // например когда мы навели на границу параграфа и показался контрол (полоска и язычок) (или зажали контрол и двигаем уже, или вводим руками в текстовое поле)
      // мы можем например удалить параграф (изменить число колонок, размер виджета, интерлиньяж и пр.)
      // при этом контроллер вызовет proceed с новыми данными по параграфам
      // в новом состоянии редактора этот контрол должен быть уже в другом месте (если параграф вообще жив и мы его не удалил)
      // а функция getClosestDataToInitial определяет новое правильное положение контрола
      this.data = this.getClosestDataToInitial();

      if (this.data) {
        // нашли новое положение контрола - обновляем его положение на экране
        this.getEditorDimensions(); // здесь надо заново узнать положение и размер виджета
        this.showHandle('update');

        // поскольку в режиме ручного редактирования (когда фокус на инпуте - state == 'focus') реакция на мышь отсутствует
        // т.е. язычок не должен исчезать или двигаться если отодвинули мышь
        // то нам надо вручную попросить пересчитать положение язычка (иначе он может выползти за пределы линии, если в момент фокуса например левый марджин был большой и блок текста был высокий, мы уменьшаем марджин, блок текста уменьшается по высоте, синяя линия тоже, и язычок начинает выходить на границы)
        // при этом мы передаем параметр useMousePosOnFocusEnter, который говорит о том
        // что нам надо смотреть не на текущее положение мыши, а на то положение, которое было в момент получения фокуса
        if (this.state == 'focus') this.updateHandleLabelPos(true);
      } else {
        // либо контрол не создан, либо мы только что удалили параграф к которому он относился - закрываем лавочку
        this.hideHandle();
      }
    }

    this.onMouseMove();
  },

  // находит среди доступных границ за которые можно двигать (this.marginHandles)
  // ту, которая больше всего соответствует состоянию контрола на момент его появления
  // например, если у нас параграф разбился на 3 колонки, мы навели на левую границу в 3-й колонке
  // и уменьшили ее, то соответственно параграф уменьшился в высоте (поскольку стал шире и текст перераспределился)
  // и он могу уменьшиться так, что стал разбиваться на 2 колонки, а не на 3
  // а у нас уже контрол нарисован в 3-й колонке, вот незадача
  // так вот, эта функция как раз и находит ближайшее правильное положение контрола в новых сложившихся условиях
  // среди всех доступных границ она найдет те, которые относятся к параграфу для которого контрол изначально создавался
  // потом среди них она  будет искать ту границу которая ближе всего к изначально (в нашем примере это будет левая граница во 2-й колонке, поскольку 3-й нету)
  // еще можно почитать в proceed про эту функцию
  getClosestDataToInitial: function() {
    var candidates = [];

    for (var i = 0; i < this.marginHandles.length; i++) {
      var marginHandle = this.marginHandles[i];

      if (marginHandle.p == this.initialData.p && marginHandle.tp == this.initialData.tp) {
        candidates.push(marginHandle);
      }
    }

    // candidates - массив из всех типов границ которые совпадают по типу и параграфу с изначальной границей для которой был показан контрол
    // нам остается только пробежаться по нему
    // и взять ту границу, индекс колонки которой ближе всего к исходной
    if (candidates.length) {
      var minDist = 99999,
        ind = -1;

      for (var i = 0; i < candidates.length; i++) {
        var dist = Math.abs(candidates[i].column - this.initialData.column);
        if (dist < minDist) {
          minDist = dist;
          ind = i;
        }
      }

      return _.clone(candidates[ind]);
    } else {
      return undefined;
    }
  },

  onScroll: function(scrollTop, scrollLeft) {
    this.scrollTop = scrollTop;
    this.scrollLeft = scrollLeft;
    this.showHandle('update');
  },

  show: function() {
    this.shown = true;
    $(document).on('mousemove', this.onMouseMove);
    this.onMouseMove();

    this.$el.removeClass('hidden');
  },

  hide: function() {
    this.shown = false;
    $(document).off('mousemove', this.onMouseMove);

    this.$el.addClass('hidden');

    this.hideHandle();
  },
});

// вьюха которая управляет Undo-Redo
var UndoController = Backbone.Model.extend({
  initialize: function(block) {
    _.bindAll(this);

    this.block = block;

    this.undoIndex = -1;
    this.undoStack = [];

    this.add.__debounced = _.debounce(this.add, 50);

    this.historyTriggers = {};

    // ctrl+Z, ctrl+shift+Z
    this.block.$iframe_body.bind('keydown', 'ctrl+z', this.undo).bind('keydown', 'ctrl+shift+z', this.redo);

    this.block.$iframe_body.bind('keydown', 'meta+z', this.undo).bind('keydown', 'meta+shift+z', this.redo);
  },

  // formatParams - это только для tp == 'formatApplied' (например {line-height: "26px", font-size: "21px"})
  add: function(content, tp, sel, formatParams) {
    if (!this.block.isEditorActive()) return;

    var self = this,
      currentViewport = self.block.page.getCurrentViewport(), // используется и в addToStack
      content = content || '<p></p>', // используется и в addToStack
      tagsCount = countTags(content), // используется и в addToStack
      lastUndoStep = this.undoStack[this.undoIndex]; // чтобы укоротить запись в дальнейшем обращении к последнему состоянию в стеку ундо

    // если стек пуст, или предыдущее значение содержимое не равно текущему
    // или если текущий вьюпорт не соответствует вьюпорту при котором было добавлено в стек ундо последнее состояние
    if (this.undoIndex == -1 || lastUndoStep.content != content || currentViewport != lastUndoStep.viewport) {
      // сохраняем исходное состояние в редакторе в стек
      // при первоначальном зходе в редактор или при заходе в редактор после смены вьюпорта
      if (tp == 'enterEditMode') {
        // если предыдущее состояние стека это "исходное состояние" редактора созданное ранее при переходе в другой вьюпорт
        // то это значит, что новую точку создавать не надо, надо просто поменять предыдущю на новые данные
        // (чтобы не плодить "исходные состояния" вьюпортов в стеке ундо когда мы просто заходили в редакторы в конкретном вьюпорте, но ничего в нем не меняли)
        if (this.undoIndex >= 0 && lastUndoStep.start) {
          this.undoIndex--; // просто сдвигаем указатель на 1 назад

          this.undoStack.length = this.undoIndex + 1; // очищаем всю будущую историю

          // обновляем закешированную переменную
          lastUndoStep = this.undoStack[this.undoIndex];
        }

        // просто фиксируем "исходное состояние" в стеке ундо
        // и помещаем его "исходным", на нем будет останавливаться ундо когда закончаться операции ундо в стеке помеченные как сделанные в конкретном вьюпорте
        // но делаем это только в том случае если либо это вообще первоначальный вызов редактора у ундостека нету
        // либо предыдущее состояние создано для другого вьюпорта
        // это для того чтобы вообще убрать "исходное состояние", если мы прсото пробежались по вьюпортам и везде открывали редактор, а потом вернулись в тот вьюпорт, в котором были созданы последние изменения для стека ундо
        if (this.undoIndex == -1 || currentViewport != lastUndoStep.viewport) {
          addToStack({ mergable: false, start: true });
        }

        // из-за того, что выше может вызваться this.undoIndex--, но при этом не вызваться дальнейшый addToStack
        // поэтому нам надо всем сказать что положение указателя в стеке возможно обновилось

        this.historyTriggers &&
          this.historyTriggers.triggerChange &&
          this.historyTriggers.triggerChange(this.block.pid);
      }

      // если измения произошли из-за втавки из буфера
      if (tp == 'paste') addToStack({ mergable: false });

      // если измения произошли из-за изменения стилей (марджинов, параграфов, линков или спанов)
      if (tp == 'formatApplied') {
        // проверяем есть ли в стеке предыдущее состояние у которого заданы formatParams
        if (this.undoIndex >= 0 && lastUndoStep.formatParams && currentViewport == lastUndoStep.viewport) {
          // предыдущее состояние было получено в результате изменения формата
          // поэтому смотрим, совпадают ли ключи (т.е. изменяются одни и те же стили)
          var old_keys = _.keys(lastUndoStep.formatParams),
            new_keys = _.keys(formatParams),
            keys_equal = _.difference(old_keys, new_keys).length == 0;

          // если список ключей совпадает, тогда смотрим, есть ли в списке параметры,
          // не имеющие ограниченного набора значений (т.е. цвет, размер шрифта, интерлиньж, марджины и т.д.)
          if (keys_equal && !isOnlyEnumerable(new_keys)) {
            // новое состояние в стек не заносим
            // мы уже определили что юзер просто меняет один и тот же стиль, поэтому мерджим данные с предыдущим состоянием в стеке
            // если при этом не менялось выделение

            var curSel = getRelativeSelection(),
              lastSel = lastUndoStep.initialSelection;

            // выделение не изменилось - значит мерджим
            if (
              curSel.startOffset == lastSel.startOffset &&
              curSel.endOffset == lastSel.endOffset &&
              _.isEqual(curSel.startNodePosition, lastSel.startNodePosition) &&
              _.isEqual(curSel.endNodePosition, lastSel.endNodePosition)
            ) {
              lastUndoStep.content = content;
              lastUndoStep.formatParams = formatParams;
              lastUndoStep.selection = getRelativeSelection();
              lastUndoStep.initialSelection = _.clone(lastUndoStep.selection);
              this.undoStack.length = this.undoIndex + 1; // очищаем всю будущую историю
            }
            // иначе, выделение поменялось, значит новое состояние (например, до этого меняли цвет, потом выделили другой кусок и снова стали менять цвет)
            else addToStack({ mergable: false, formatParams: formatParams });
          }
          // иначе, список параметров изменился, значит новое состояние (например, до этого менял интерлиньяж, а сейчас стали менять цвет)
          else addToStack({ mergable: false, formatParams: formatParams });
        }
        // если нет, добавляем в стек новое состояние (например, до этого вводили текст, а сейчас стали менять стиль)
        else addToStack({ mergable: false, formatParams: formatParams });
      }

      // если нажали кнопу на клаве - тогда действуем по ситуации
      if (tp == 'keydown') {
        // раз попали сюда - значит:
        // нажали клавишу, при этом текст изменился с последнего значения в стеке undo

        if (this.undoIndex >= 0) {
          // сначала смотрим изменилось ли кол-во тегов с предыдущего раза (т.е. поменялась верстка, например нажали Enter или удалили выделение с тегами или еще чего)
          if (lastUndoStep.tagsCount != tagsCount) addToStack({ mergable: false });
          else {
            // раз тут, значит верстка не поменялась, просто ввели или удалили символы внутри параграфа или спана или линка

            // заменяем все спец. символы на заглушки (&nbsp; -> '$')
            // чтобы правильно считать изменение длины
            var prev = replaceSpecial(lastUndoStep.content),
              curr = replaceSpecial(content),
              increasing = prev.length <= curr.length; // именно <= !!! потому что иногда бывает замена tinymce &nbsp; на вводимый символ, потому что &nbsp; это плейсхолдер для пустого тега (в таком случае prev.length == curr.length, но по факту добавили символ, т.е. increasing = true)

            // если с предыдущим состоянием нельзя объединять, то добавляем новое состояние в стек
            if (!lastUndoStep.mergable) addToStack({ mergable: true, increasing: increasing });
            // здесь true, потому что с этим состоянием дальше можно группировать
            else {
              // тут проверяем нужно ли объеденить изменение с предыдущим
              // или создать новое состояние
              // просто смотрим на какую длину изменился текст с предыдущего раза
              // если больше чем на 2 символа (вот такой тупой подход, умнее не придумал, в 99% случаев дает отличный результат)
              // при этом если до этого размер текста увеличивался, а тут вдруг уменьшился, или наоборот - то это тоже новые состояния
              // типа: вбивали вбивали, потом начали удалять бекспейсом (текст до удаления фиксируем) потом начали снова вбивать (текст после удаления фиксируем)

              // если изменилось больше чем на 2 символа то значит либо очень быстро вбивали (зажали кнопу)
              // либо кнопу нажали на выделении и удалили большой кусок
              // в обоих случаях надо создать новое состояние в стеке
              if (Math.abs(prev.length - curr.length) > 2) addToStack({ mergable: true, increasing: increasing });
              else {
                // ну а раз тут, значит просто тупо вводили или удаляли тектс по одному символу
                // поэтому смотрим если мейчас мы текста добавили и до этого добавляли
                // или до того удаляли и щас удаляем
                // тогда объединяем с предыдущим состоянием
                // иначе новое
                if (increasing != lastUndoStep.increasing || currentViewport != lastUndoStep.viewport)
                  addToStack({ mergable: true, increasing: increasing });
                else {
                  // если мы думаем что мы просто вводим или удаляем и нам надо объединить
                  // тогда смотрим где был курсор до этого, если модуль расстояния будет > 2 или разные ноды начальные
                  // то создаем новое
                  // например есть два спана: мы сначала ввели что-то в первый
                  // потом передвинули курсор и ввели что-то во второй (или в первом куда то ушли от того места где вводили)
                  // в таком случае нам в стеке также нужно зафиксировать состояние, чтобы это были разные UNDO
                  var curSel = getRelativeSelection(),
                    lastSel = lastUndoStep.initialSelection;

                  if (
                    Math.abs(curSel.startOffset - lastSel.startOffset) > 2 ||
                    !_.isEqual(curSel.startNodePosition, lastSel.startNodePosition)
                  ) {
                    addToStack({ mergable: true, increasing: increasing });
                  } else {
                    lastUndoStep.content = content;
                    lastUndoStep.selection = getRelativeSelection();
                    lastUndoStep.initialSelection = _.clone(lastUndoStep.selection);
                    this.undoStack.length = this.undoIndex + 1; // очищаем всю будущую историю
                  }
                }
              }
            }
          }
        }
      }
    } else {
      // обновляем выделение в последнем состоянии стека (чтобы потом восстановить можно было)
      if (this.undoIndex >= 0) {
        lastUndoStep.selection = getRelativeSelection();
      }
    }

    // возвращет true если с массиве keys
    // ВСЕ стили имеют перечислимый тип (ограниченный набор значений)
    // к ним мы причисляем все стили кроме:
    //  font-size
    //  line-height
    //  letter-spacing
    //  color
    //  padding-*
    function isOnlyEnumerable(keys) {
      var list = [
        'font-size',
        'line-height',
        'letter-spacing',
        'color',
        'padding-top',
        'padding-bottom',
        'padding-left',
        'padding-right',
      ];

      return _.intersection(keys, list).length == 0;
    }

    // функция расчитывает положение текущего выделения (или курсора)
    // в таком формате по которому потом это выделение можно будет восстановить
    function getRelativeSelection() {
      // sel - объект плагина rangy по которому мы вычислим где находится выделение
      var range = sel.getRangeAt(0) || {};
      if (!range.startContainer || range.startContainer.tagName == 'BODY') return undefined;

      var res = {};

      res.startOffset = range.startOffset;
      res.endOffset = range.endOffset;
      if (range.startContainer == range.endContainer) {
        res.sameNode = true;
        res.startNodePosition = getNodePosition(range.startContainer);
        res.endNodePosition = res.startNodePosition;
      } else {
        res.sameNode = false;
        res.startNodePosition = getNodePosition(range.startContainer);
        res.endNodePosition = getNodePosition(range.endContainer);
      }

      return res;
    }

    // функция по DOM елементу (node) сохраняет в массив путь к нему
    // (рекурсивно, каждый номер это индекс элемента в родителе)
    function getNodePosition(node) {
      var res = [];

      while (true) {
        var tag = node.tagName;

        if (tag == 'BODY') break;

        res.push(whichChild(node));

        node = node.parentNode;
        if (!node || !node.tagName) break;
      }

      return res;

      function whichChild(elem) {
        var i = 0;
        while ((elem = elem.previousSibling) != null) {
          if (elem.textContent) ++i;
        }
        return i;
      }
    }

    function replaceSpecial(content) {
      // заменяем все спец. символы на заглушки (&nbsp; -> '$')
      return content.replace(/&\S+?;/gim, '$');
    }

    function countTags(content) {
      // просто находим кол-во '<' (в тексте их быть не может)
      // считает и открывающие и закрывающие, но нам это не важно
      // мы потом смотрим разницу между предыдущим countTags и текущим (нам важно равны или нет)
      return content.split('<').length - 1;
    }

    // mergable - можно ли группировать с данным элементом последующие
    // например когда просто с клавиатуры вбивается все эти изменения группируются в одно
    // до тех пор пока мы не изменним что-нибудь серьезно - например сили или удалим параграф или еще что-то что должно идти отдельным шагом в стеке undo-Redo
    // increasing - показывает увеличивается или уменьшается размер текста с предудущего состояния (бывает задан только для mergable == true)
    function addToStack(params) {
      params = params || {};
      self.undoIndex++;
      self.undoStack[self.undoIndex] = {
        content: content, // берется из локальной переменной описаной выше
        mergable: params.mergable,
        tagsCount: tagsCount, // берется из локальной переменной описаной выше
        increasing: params.increasing,
        formatParams: params.formatParams,
        viewport: currentViewport, // берется из локальной переменной описаной выше
        start: params.start,
        selection: getRelativeSelection(),
      };
      self.undoStack[self.undoIndex].initialSelection = _.clone(self.undoStack[self.undoIndex].selection);
      self.undoStack.length = self.undoIndex + 1; // очищаем всю будущую историю

      // обновляем закешированную переменную
      lastUndoStep = self.undoStack[self.undoIndex];
      self.historyTriggers && self.historyTriggers.triggerChange && self.historyTriggers.triggerChange(self.block.pid);
    }
  },

  undo: function(e) {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (!this.block.isEditorActive()) return;

    if (this.undoIndex > 0) {
      // запрещаем откат до состояния которое было сделано не в текущем вьюпорте
      if (this.undoStack[this.undoIndex - 1].viewport != this.block.page.getCurrentViewport()) return;

      this.undoIndex--;

      this.restoreState(this.undoIndex);

      this.block.somethingChangedInEditor('undo');

      this.historyTriggers.triggerChange && this.historyTriggers.triggerChange(this.block.pid);
      this.historyTriggers.triggerUndo && this.historyTriggers.triggerUndo(this.block.pid);
    }
  },

  redo: function(e) {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (!this.block.isEditorActive()) return;

    if (this.undoIndex < this.undoStack.length - 1) {
      // запрещаем откат до состояния которое было сделано не в текущем вьюпорте
      if (this.undoStack[this.undoIndex + 1].viewport != this.block.page.getCurrentViewport()) return;

      this.undoIndex++;

      this.restoreState(this.undoIndex);

      this.block.somethingChangedInEditor('redo');

      this.historyTriggers.triggerChange && this.historyTriggers.triggerChange(this.block.pid);
      this.historyTriggers.triggerRedo && this.historyTriggers.triggerRedo(this.block.pid);
    }
  },

  getLength: function(pageId) {
    // расчитываем сколько шагов ундо мы можем сделать по ундо стеку
    // но с учетом того, что эти шаги должны были быть сделаны в том же вьюпорте что и сейчас
    var currentViewportUndoLength = 0,
      currentViewport = this.block.page.getCurrentViewport();

    while (
      this.undoIndex - currentViewportUndoLength >= 0 &&
      this.undoStack[this.undoIndex - currentViewportUndoLength].viewport == currentViewport
    ) {
      currentViewportUndoLength++;
    }

    return {
      undo: currentViewportUndoLength - 1,
      redo: this.undoStack.length - 1 - this.undoIndex,
      page: pageId,
    };
  },

  restoreState: function(ind) {
    this.block.editor.setContent(this.undoStack[ind].content || '');

    // восстанавливаем выделение
    var sel = this.undoStack[ind].selection,
      self = this;

    if (sel) {
      var startNode = getNodeByPosition(sel.startNodePosition),
        endNode;

      if (sel.sameNode) endNode = startNode;
      else endNode = getNodeByPosition(sel.endNodePosition);

      if (startNode && endNode)
        // теперь показываем выделение
        try {
          var range = this.block.iframe_document.createRange();
          range.setStart(startNode, sel.startOffset);
          range.setEnd(endNode, sel.endOffset);
          this.block.editor.selection.setRng(range);
        } catch (e) {}
    }

    function getNodeByPosition(pos) {
      var node = self.block.$iframe_body[0];

      for (var i = pos.length - 1; i >= 0; i--) {
        if (node && node.childNodes && node.childNodes.length && node.childNodes.length >= pos[i] + 1) {
          node = node.childNodes[pos[i]];
        } else {
          node = undefined;
          break;
        }
      }

      return node;
    }
  },
});

/**
 * Класс для колонок (показывает границы колонок)
 * Часть манипуляций с DOM написана на чистом JS - для производительности
 */
var ColumnsController = Backbone.View.extend({
  initialize: function(block) {
    _.bindAll(this);

    this.block = block;
    this.container = this.block.$('.columns-wrapper-inner')[0];

    // кеш для созданных рамок в DOM
    // чтобы не создавать их каждый раз, а просто перерендеривать
    this.columnBlocks = [];

    this.block.$iframe_document.on('scroll', this.onScroll);

    this.onScroll();
  },

  onScroll: function() {
    this.scrollTop = this.block.$iframe_document.scrollTop();
    this.scrollLeft = this.block.$iframe_document.scrollLeft();

    $(this.container).css({ top: -this.scrollTop, left: -this.scrollLeft });
  },

  recalc: function() {
    if (!this.block.isEditorActive()) return;

    // проверяем что верстка многоколоночная
    if (this.block.model.get('column_count') > 1) {
      this.block.$('.columns-wrapper').removeClass('single-column');

      var columns = this.block.calcColumnsPositions(),
        gapsCount = columns.length - 1;

      // отключаем все блоки использованные ранее
      var len = this.prevUsedBlocks ? this.prevUsedBlocks : this.columnBlocks.length;
      for (var i = 0; i < len; i++) this.columnBlocks[i].style.display = 'none';

      for (var i = 0; i < gapsCount; i++) {
        // если у нас в кеше нет созданного блок - то создаем его
        if (!this.columnBlocks[i]) this.columnBlocks[i] = this.createNewColumnBlock();

        this.render(this.columnBlocks[i], columns[i].ed, columns[i + 1].st - columns[i].ed - 1);
      }

      this.prevUsedBlocks = gapsCount;
    } else {
      this.block.$('.columns-wrapper').addClass('single-column');
    }
  },

  createNewColumnBlock: function() {
    var node = document.createElement('div');
    node.className = 'gap';
    this.container.appendChild(node);
    return node;
  },

  render: function(node, pos, width) {
    var st = node.style;
    st.left = pos + 'px';
    st.width = width + 'px';
    st.display = 'block';
  },

  show: function() {
    this.block.$('.columns-wrapper').removeClass('hidden');
    this.onScroll();
  },

  hide: function() {
    this.block.$('.columns-wrapper').addClass('hidden');
  },
});

var textFrame = BlockFrameClass.extend({
  customResizeHandler: function(box) {
    if (this.block.model.get('autosize')) {
      this.block.$el.css('width', box.width);
      box.height = this.block.getContentBoxHeight();
    }
    return box;
  },
});

export default TextBlock;
