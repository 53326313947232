/**
 * Модуль для отображения страницы запроса пароля приватного мэга
 *@module MagPassword View
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { UserModel } from '../constructor/models/user';
import templates from '../../templates/viewer/mag-password.tpl';
import { Constants } from '../common/utils';

/**
 * Класс Backbone View для отображения страницы запроса пароля приватного мэга
 * @class MagPasswordClass
 */
const MagPasswordClass = Backbone.View.extend({
  className: 'magpassword',

  events: {
    'click .access': 'onAccessClick',
    'keydown #magpassword-input': 'onPasswordKeydown',
    'input #magpassword-input': 'onPasswordInput',
  },

  initialize: function(params) {
    _.bindAll(this);
    _.extend(this, params);

    this.router = params.router;
    this.template = templates['template-viewer-mag-password'];
  },

  render: function(params) {
    var self = this,
      $img,
      user;

    _.extend(this, params);

    user = new UserModel(this.mag.user); // Создаем модель для использования метода getUserpic

    $img = $(new Image()).load(function() {
      // Для предзагрузки фона страницы
      self.$el.html(
        self.template({
          user: user,
          Constants,
        })
      );
      if (self.$parent) {
        self.$parent.find(self.className).remove();
        self.$parent.append(self.$el);
      }
      self.delegateEvents();

      $('#magpassword-input').focus();

      // Анимируем появление контента
      _.delay(function() {
        self.$('.back, .center-content').removeClass('invisible');
        _.delay(function() {
          self.$('.author, .powered').removeClass('invisible');
        }, 700);
      }, 200);
    });
    $img.prop('src', '/img/viewer/mag-password/bg.jpg');
  },

  onAccessClick: function() {
    var pass = $.trim($('#magpassword-input').val());

    if (!pass) {
      this.shake();
      return;
    }

    this.xhr && this.xhr.abort();
    this.xhr = $.ajax({
      url: '/api/readymag/authorize',
      method: 'POST',
      data: {
        pass: pass,
        num_id: this.mag.num_id,
      },
      success: function(res) {
        this.proceed();
      },
      error: function(xhr) {
        $('#magpassword-input')
          .focus()
          .select();
        this.shake();
      },
      context: this,
    });
  },

  proceed: function() {
    $('body').removeClass('black'); // Убираем черный фон, который был включен при загрузке приватного мэга

    this.remove();

    // Кастомный код и Google Tag Manager должны подключаться сразу при загрузке страницы,
    // по-этому, если они заданы, перегружаем всю страницу
    if (this.mag.has_code_injection || (this.mag.opts && this.mag.opts.gtm_id)) {
      window.location.reload();
    } else {
      // Юзаем недокументированные свойства и методы, но нормального способа перезагрузить текущий урл нет
      Backbone.history.loadUrl(Backbone.history.fragment);
    }
  },

  onPasswordKeydown: function(e) {
    if (e.keyCode == 13) {
      this.onAccessClick();
    }
  },

  onPasswordInput: function(e) {
    this.$('.access').toggleClass('disabled', !this.$('.password-input').val());
  },

  shake: function() {
    var self = this;

    this.$('.password-wrapper').addClass('shake');
    _.delay(function() {
      self.$('.password-wrapper').removeClass('shake');
    }, 500);
  },
});

export default MagPasswordClass;
