module.exports = {["template-constructor-block-gmaps"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n    <div class="rm-search-block">\n        <div class="map-container"></div>\n        <div class="map-overlay"></div>\n        <div class="message-overlay invisible"></div>\n    </div>\n    <div class="rm-gmaps-key hidden">\n        <div class="key-wrapper">\n            <div>\n                <div class="key-legend">To use Google Maps widget you need a Google Maps API key. Learn how to get an API key <a href="http://help.readymag.com/hc/en-us/articles/360020526932-Google-Maps" rel="noreferrer noopener" target="_blank">here</a>.</div>\n                <div class="key-input-wrap">\n                    <input type="text" class="key-input" placeholder="Paste Google Maps API Key here" required minlength="39" maxlength="39">\n                    <button>Apply</button>\n                </div>\n            </div>\n        </div>\n    </div>\n';
}
return __p;
},["template-constructor-block-gmaps-search-control"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n    <div class="rm-search-block">\n        <input type="text" class="rm-search source">\n        <input type="text" class="rm-search destination">;\n    </div>\n';
}
return __p;
},}