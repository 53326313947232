module.exports = {["template-viewer-mag-password"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="magpassword-container">\n		<div class="img-preloader"></div>\n		<div class="back invisible"></div>\n		<div class="center-table">\n			<div class="center-cell">\n				<div class="center-content invisible">\n					<!--__magterm-->\n					<div class="caption-private">This is a private project.<br/><span class="nowrap">Enter the password:</span></div>\n					<div class="password-wrapper">\n						<div class="input-wrapper">\n							<input type="password" class="password-input" id="magpassword-input" required>\n							<div class="access disabled">Access</div>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n		';
 if (!user.get('permissions') || !user.get('permissions').white_label) { 
__p+='\n			<div class="author invisible">\n				<div class="userpic">\n					<a href="'+
((__t=(user.getLink()))==null?'':_.escape(__t))+
'"><img src="'+
((__t=(user.getUserpic(128)))==null?'':_.escape(__t))+
'" alt=""></a>\n					<a href="'+
((__t=(user.getLink()))==null?'':_.escape(__t))+
'"><div class="username">'+
((__t=(user.get('name')))==null?'':_.escape(__t))+
'</div></a>\n				</div>\n				<div class="caption">Published by</div>\n			</div>\n			<div class="powered invisible">\n				<div class="caption-powered">Made with</div>\n				<a href="'+
((__t=(Constants.readymag_host))==null?'':__t)+
'"><div class="logo-powered"></div></a>\n			</div>\n		';
 } 
__p+='\n	</div>\n';
}
return __p;
},}