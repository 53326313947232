module.exports = {["template-constructor-block-text"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="margins-wrapper">\n		<div class="margins-wrapper-inner">\n		</div>\n	</div>\n\n	<div class="columns-wrapper">\n		<div class="columns-wrapper-inner">\n		</div>\n	</div>\n\n	<textarea id="textarea_'+
((__t=(_id))==null?'':__t)+
'" style="display: none; width:'+
((__t=(w))==null?'':__t)+
'px;height:'+
((__t=(h))==null?'':__t)+
'px">'+
((__t=(text))==null?'':_.escape(__t))+
'</textarea>\n	<div class="text-preview" style="-webkit-nbsp-mode:normal; -webkit-line-break:after-white-space;">'+
((__t=(text))==null?'':__t)+
'</div>\n	<div class="text-mask"></div>\n\n';
}
return __p;
},["template-constructor-block-text-margins"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="margin-handle hidden inactive">\n		<div class="margin-handle-label-wrapper">\n			<div class="margin-handle-label">\n				<input class="margin-input" type="text"  spellcheck="false" value="0" data-mouseSpeed="2" />\n			</div>\n		</div>\n	</div>\n\n';
}
return __p;
},["template-constructor-block-text-bg"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="bg"></div>\n';
}
return __p;
},}