import $ from '@rm/jquery';
import _ from '@rm/underscore';

const SVGUtils = {
  /**
   * Растеризация SVG иконок
   *
   * @param {Backbone.Model[]} params.widgets Массив с моделями виджетов
   * @param {string} params.mid Mag ID
   * @param {jQuery} params.svg jQuery объект с SVG
   * @param {string} params.viewport Viewport для сохранения
   * @param {object} params.size Размеры SVG
   * @param {number} params.size.height Высота
   * @param {number} params.size.width Ширина
   * @param {string} [params.prefix] Префикс для path (для виджета button: без префикса rasterUrl, с префиксом prefix_rasterUrl)
   * @param {string} params.activeArrow Для виджета slideshow передается признак, т.к. для slideshow необходимо сохранять не просто в модель
   * @param {function} [params.onSuccess] Success callback
   * @param {function} [params.onError] Error callback
   *
   * @return {jQuery.XHR}
   */
  rasterize: function(params) {
    if (
      !params ||
      !params.widgets ||
      !params.mid ||
      !params.svg ||
      !params.viewport ||
      !params.size ||
      !params.size.height ||
      !params.size.width
    )
      return;

    // Обязательный хак, xml schema svg может содержать набор левых аттрибутов,
    // которые вырежутся "обычным" сериализатором.
    var imported = document.importNode(params.svg[0], true);
    var svg = new XMLSerializer().serializeToString(imported);

    // Сохраняем фолбэк в виде base64, пока бэк не сделает растр и не сохранит правильные урлы
    var b64svg = window.btoa(unescape(encodeURIComponent(svg)));
    var b64url = 'data:image/svg+xml;base64,' + b64svg;
    var tmpUrls = {
      [params.prefix ? params.prefix + '_rasterUrl' : 'rasterUrl']: b64url,
      [params.prefix ? params.prefix + '_raster2xUrl' : 'raster2xUrl']: b64url,
      [params.prefix ? params.prefix + '_raster3xUrl' : 'raster3xUrl']: b64url,
    };

    var themeData;

    if (!params.activeArrow) {
      params.widgets.forEach(function(model) {
        model.save(tmpUrls, {
          skipHistory: true,
          silent: true,
        });
      });
    } else {
      if (params.activeArrow === 'left_arrow_noun') {
        params.widgets.forEach(function(model) {
          themeData = model.get('theme_data');
          // очищаем модель на случай если несколько раз меняли иконку, а потом перешли
          // во вьювер, при этом урлы с растрами для текущей иконки еще не успели прийти с бэка
          // и на их месте остались урлы от "старой" иконки;
          // код во вьювере, не обнаружив s3 урлов, использует фолбэк, который ниже сохраняется в theme_data.left_icon_noun/right
          model.unset('left_arrow_noun', {
            skipHistory: true,
            silent: true,
            patch: true,
          });
          model.save(
            {
              theme_data: _.extend(themeData, {
                left_icon_noun: _.extend(themeData.left_icon_noun, tmpUrls),
              }),
            },
            {
              skipHistory: true,
              silent: true,
              patch: true,
            }
          );
        });
      }
      if (params.activeArrow === 'right_arrow_noun') {
        params.widgets.forEach(function(model) {
          themeData = model.get('theme_data');
          model.unset('right_arrow_noun', {
            skipHistory: true,
            silent: true,
            patch: true,
          });
          model.save(
            {
              theme_data: _.extend(themeData, {
                right_icon_noun: _.extend(themeData.right_icon_noun, tmpUrls),
              }),
            },
            {
              skipHistory: true,
              silent: true,
              patch: true,
            }
          );
        });
      }
    }

    var wids = params.widgets.map(function(model) {
      return model.get('_id');
    });

    var data = {
      wids: wids,
      svg: svg,
      size: params.size,
      viewport: params.viewport,
      mid: params.mid,
      prefix: params.prefix,
      arrow: params.activeArrow,
    };

    return $.ajax({
      url: '/api/images/rasterize',
      type: 'POST',
      data: data,
      success: params.onSuccess,
      error: params.onError,
    });
  },
};

export default SVGUtils;
