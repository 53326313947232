<template>
  <div v-if="!isHidden" class="panel setting-panel">
    <div class="panel-header">Cart bar settings</div>
    <div class="separator-dotted"></div>
    <div class="row">
      <div class="caption">Bar color</div>
      <color-picker
        class="round"
        :color="model['bar-background-color']"
        :alpha="model['bar-background-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('bar-background-color', $event)"
        @update:alpha="changePropHandler('bar-background-color-opacity', $event)"
      ></color-picker>
    </div>
    <div class="separator-dotted"></div>
    <div class="row">
      <div class="caption">Shadow</div>
      <rm-switcher :value="model['bar-shadow']" :callback="changeShadow"></rm-switcher>
    </div>
    <div class="separator-dotted"></div>
    <div class="row">
      <font-family-selector
        :family="model['bar-font-family']"
        @showPopup="closeOthersPopups"
        @change="changePropHandler('bar-font-family', $event)"
      ></font-family-selector>
    </div>
    <div class="separator-dotted"></div>
    <div class="row">
      <font-style-selector
        :font-style="model['bar-font-style']"
        :style="{ 'font-family': model['bar-font-family'] }"
        :font-weight="model['bar-font-weight']"
        :font-family="model['bar-font-family']"
        @showPopup="closeOthersPopups"
        @update:fontStyle="changePropHandler('bar-font-style', $event)"
        @update:fontWeight="changePropHandler('bar-font-weight', $event)"
      ></font-style-selector>
    </div>
    <div class="separator-dotted"></div>
    <div class="row">
      <div class="caption">Text color</div>
      <color-picker
        class="round"
        :color="model['bar-color']"
        :alpha="model['bar-color-opacity']"
        @showPopup="closeOthersPopups"
        @update:color="changePropHandler('bar-color', $event)"
        @update:alpha="changePropHandler('bar-color-opacity', $event)"
      ></color-picker>
    </div>
  </div>
</template>
<script>
import _ from '@rm/underscore';
import ColorPicker from '../../common/color-picker.vue';
import NumericInput from '../../common/numeric-input.vue';
import FontFamilySelector from '../../common/font-family-selector.vue';
import FontStyleSelector from '../../common/font-style-selector.vue';
import RmSwitcher from '../../common/rm-switcher.vue';

export default {
  components: { ColorPicker, NumericInput, FontFamilySelector, FontStyleSelector, RmSwitcher },

  mounted: function() {
    // Вотчим и эмитим сразу весь объект данных для бэкбон модели,
    // а модель сама триггернет change только нато, что фактически изменится
    this.$watch(
      'model',
      newValue => {
        this.$emit('change', newValue);
      },
      { deep: true }
    );
  },

  methods: {
    // Закрываем все панели внутренних компонентов
    closeOthersPopups: function(emitter) {
      if (!emitter) return;

      _.each(this.$children, function(ctrl) {
        if (ctrl != emitter) {
          ctrl.visibleBox = false;
        }
      });
    },

    changePropHandler: function(propType, val) {
      this.model[propType] = val;
    },

    changeShadow: function(value) {
      this.model['bar-shadow'] = value;
    },
  },
};
</script>
<style lang="less">
@import (reference) '../../../css/common/constants.less';
@import (reference) '../../../css/common/fonts.less';

@panel-bg-color: #f4f4f4;
@panel-border-color: #404040;
@panel-disable-color: fade(@new-black, 40%);

.panel.setting-panel {
  width: 168px;
  height: 248px;
  box-sizing: border-box;
  position: absolute;
  top: -254px;
  right: 16px;
  padding: 13px 15px;
  border-radius: 8px;
  background-color: fade(@panel-bg-color, 96%);
  border: 1px solid fade(@panel-border-color, 8%);
  .native_font;
  font-size: 12px;
  color: @new-black;

  .panel-header {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 13px;
    font-size: 16px;
    text-align: left;
    letter-spacing: -0.4px;
  }

  .row {
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .color-picker-container.round {
      width: 26px;
      height: 26px;
    }

    .font-family-container {
      .expand-arrow {
        width: 7px;
        height: 12px;
        background-size: 12px 7px;
        background-image: url('../../../img/constructor/controls/add_to_cart_settings/expand-arrow.svg');
        flex: 0 0 12px;
        position: relative;
        top: 3px;
        left: 1px;
      }

      &.container-disabled {
        &:hover {
          .expand-arrow {
            background-image: url('../../../img/constructor/controls/add_to_cart_settings/expand-arrow.svg');
          }
        }
      }

      &:hover {
        .expand-arrow {
          background-image: url('../../../img/constructor/controls/add_to_cart_settings/expand-arrow-active.svg');
        }
      }
    }

    .font-style-container {
      .expand-arrow {
        width: 7px;
        height: 12px;
        background-size: 12px 7px;
        background-image: url('../../../img/constructor/controls/add_to_cart_settings/expand-arrow.svg');
        flex: 0 0 12px;
        position: relative;
        top: 3px;
        left: 1px;
      }

      &:hover {
        .expand-arrow {
          background-image: url('../../../img/constructor/controls/add_to_cart_settings/expand-arrow-active.svg');
        }
      }

      .box_container {
        left: -10px;
      }
    }
  }
}
</style>
