/**
 * Виджет Фейсбука
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import InitUtils from '../../common/init-utils';
import templates from '../../../templates/constructor/blocks/facebook.tpl';

const FacebookWidget = WidgetClass.extend({
  DO_NOT_WAIT_FOR_PRELOAD: true, // флаг что при прелоадинге виджетов ждать его загрузки не надо (но не для скриншотера)

  initialize: function() {
    WidgetClass.prototype.initialize.apply(this, arguments);

    // Совместимость с депрецированным плагином Like Box (теперь это Page)
    if (this.current_type == 'type_like_box') {
      this.current_type = 'type_page';
      this.type_page = this.type_like_box;
    }

    return this;
  },

  render: function() {
    this.makeBox('widget-facebook');

    this.rendered = true;

    // во всех нормальных виджетах если старт произошел до рендера,
    // то старт еще раз выстреливает (если флаг старта до сих пор не снят) при вызове widgetIsLoaded
    // в виджетах ифрейма, фб и видео этот механизм не работает, поскольку при рендеринге не запускается никакого рендеринга
    // который мог бы вызвать widgetIsLoaded, поскольку в них весь рендер вынесен в start (по определенным причинам)
    // и поэтому если старт в них вызовут до рендера (а такое случается сплошь и рядом поскольку рендер синхронный)
    // то ничего не произойдет
    // поэтому принудительно при рендере проверяем что флаг старта еще стоит и стартуем фактичекую загрузку виджета
    if (this.started) {
      this.start();
    } else {
      // если же нам сказали рендер, но мы видим что виджет не просили активизироваться (!this.started)
      // это значит что рендерим не на той странице которая сейчас по центру экрана
      // для режима вьювера выстрелим событие загрузки сразу, чтобы не затупил прелоадер
      // а скриншотер все нормально отработает и дождется лоада, поскольку у него одна страница и она и стартится и рендерится
      !RM.screenshot && this.widgetIsLoaded();
    }

    return this;
  },

  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  // достаточно версии прототипа
  // isValid: function() {},

  // Рендерим через start для того, чтобы как было не было отрендеренных фб виджетов
  // на соседних невидимых страницах. Из-за этого куча проблем.
  // udate: сейчас виджеты рендерятся только на видимых страницах, но в будущем возможно будут и на невидимых, поэтому оставляем этот код
  // да и загрузка социалки достаточно тяжелая операция, лучше ее делать только на непосредственно показанной странице
  start: function() {
    this.started = true;

    if (this.destroyed || !this.rendered || this.innerRendered) return this;

    this.innerRendered = true; // флаг чтобы не отрабатывать повторный старт, который вызовет widgetIsLoaded

    var self = this,
      template,
      data,
      types = ['type_like_button', 'type_page', 'type_comments'];

    if (!this.current_type) {
      this.widgetIsLoaded();
      return this;
    }

    data = this[this.current_type];
    data.w = this.w;
    data.h = this.h;

    // Задаем перманентный урл мэга или страницы, чтобы он не отличался в конструкторе и на фронте
    if (data.use_own_url === 'mag') {
      data.url = this.page.mag.getUrl();
    } else if (data.use_own_url === 'page' || !data.url) {
      data.url = this.page.getUrl();
    }

    template = templates['template-constructor-block-facebook-' + this.current_type];

    this.$el
      .html(template({ data: data }))
      .removeClass(types.join(' '))
      .addClass(this.current_type);

    // // Добавляем класс анимации
    // _.delay(function() {
    //	self.$el.addClass('animate');
    // }, 300);

    if (this.current_type === 'type_comments') {
      this.$el.css({
        'overflow-x': 'hidden',
        'overflow-y': 'auto',
      });
    } else if (this.current_type !== 'type_like_button') {
      // Нельзя обрезать Like Button, иначе не будет виден попап
      this.$el.css({
        overflow: 'hidden',
      });
    }

    InitUtils.initFacebookAPI(function() {
      if (self.destroyed || !self.innerRendered) return;

      // Парсим только наш конкретный эелемент, а не все на странице
      window.FB.XFBML.parse(self.$el.get(0), function() {
        _.delay(function() {
          if (self.destroyed || !self.innerRendered) return;

          self.widgetIsLoaded();

          // Для Like Button подписываемся на события окна, чтобы ловить сообщения от фэйсбука
          // и реагировать на них перемещением виджета если попап не убирается в документ
          if (self.current_type === 'type_like_button') {
            self.iframe_w = self.$('iframe').width();
            self.iframe_h = self.$('iframe').height();
          }
        }, 500);
      });
    });

    return this;
  },

  stop: function() {
    this.started = false;

    // Попробовали не очищать виджет при стопе с 03.12.2018
    // this.$el.empty();
    // this.innerRendered = false;

    return this;
  },

  //достаточно версии прототипа
  //destroy: function() {},
});

export default FacebookWidget;
