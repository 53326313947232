/**
 * конструктор для виджета Button
 *
 *
 * Заметки о глобальных вещах в архитектуре конструктора Кнопки:
 *
 * → вход в режим редактирования текста Кнопки происходит только
 * по дабл-клику на Кнопку или нажатию Контрола «Edit Text».
 *
 * → изменения стилей Кнопки, происходят только после изменений в модели.
 *   если где-то делается this.model.set('something'), то
 *   есть this.model.on('change:something', rerenderSmething) в коде этого блока.
 *   все на изменениях модели, чтобы быть в гаромонии с Undo/Redo и практически
 *   все общее методы рендеринга в Конструктора и во Вьювере, котоыре лежать
 *   в common/button-widget.js
 *   разве что обработчик change для 'w' & 'h' в block.js, как у всех блоков.
 *
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockClass from '../block';
import Viewports from '../../common/viewports';
import ButtonWidgetClass from '../../common/button-widget';
import { Utils } from '../../common/utils';
import TextUtils from '../../common/textutils';
import SVGUtils from '../../common/svgutils';
import Colorbox from '../helpers/colorbox';

var DEFAULT_BACKGROUND_COLOR = '0078ff';

const ButtonBlock = BlockClass.extend(
  {
    name: 'Button',
    sort_index: 7, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'button',

    icon_color: '#FF5A57',

    viewport_fields: Viewports.viewport_fields.button,

    initial_controls: [
      'button_settings',
      'button_icon',
      'button_edit',
      'picture_link',
      'common_animation',
      'common_position',
      'common_layer',
      'common_lock',
    ],

    // используются в Контроле настроек индивидуального стиля и
    // Контроле глобальных стилей Кнопки.
    MIN_FONT_SIZE: 8,
    MAX_FONT_SIZE: 999,
    MIN_LETTER_SPACING: -99,
    MAX_LETTER_SPACING: 999,
    DEFAULT_BACKGROUND_COLOR: DEFAULT_BACKGROUND_COLOR,
    RASTERIZE_MULTIPLIER: 2,

    settingsOnCreate: false, // Иначе конфликтует с входом в режим редактирования при создании

    initialize: function(model, workspace) {
      this.mag = workspace.mag;

      this.initBlock(model, workspace);

      this.isEditMode = false; // меняется в enterEditMode & leaveEditMode.
      this.buttonState = 'default'; // || hover. меняется в Контроле настроек Кнопки.

      // сохраняем слепок модели, чтобы потом в this.saveWidgetData()
      // сверять изменилось ли что-либо в модели и нужно ли сохранять
      // на её сервер.
      this._oldAttrs = _.clone(this.model.attributes);

      this.onTextInput.__debounced = _.debounce(this.onTextInput, 20);

      this.onIconChange.__debounced = _.debounce(this.onIconChange, 20);

      this.onIconColorChange.__debounced = _.debounce(this.onIconColorChange, 20);

      this.recalcIconContainerSize.__debounced = _.debounce(this.recalcIconContainerSize, 20);

      this.onTextContainerSizeChange.__debounced = _.debounce(this.onTextContainerSizeChange, 20);

      // используются разные дебонсы на запрос растеризации иконки
      // в разных состояниях,
      // т.к. если цвета иконки связаны, то дефолт цвет меняют ховер и,
      // если общий дебонс на растеризацию будет, то последний сразу отменит
      // пердыдущий.
      this.rasterizeSVG.__debounced_default = _.debounce(this.rasterizeSVG, 100);
      this.rasterizeSVG.__debounced_hover = _.debounce(this.rasterizeSVG, 100);
      this.rasterizeSVG.__debounced_current = _.debounce(this.rasterizeSVG, 100);
      this.rasterizeSVG.__debounced_both = _.debounce(this.rasterizeSVG, 100);
    },

    render: function() {
      var params,
        model = _.clone(this.model.attributes),
        buttonType = model.tp;

      this.create();

      this.$el.addClass('button');

      // создаем и изначально отрисовываем виджет.
      params = {
        model: model,
        $container: this.$content,
        environment: 'constructor',
        mag: this.mag,
        block: this,
      };
      this.buttonWidget = new ButtonWidgetClass(params);

      // кэшируем поиск часто используемых элементов.
      this.$button = this.$('.common-button');
      this.$textInput = this.$button.children('.text');
      this.$buttonIcon = this.$button.children('.icon');

      // если Кнопка с Иконкой, то рендерим Иконку.
      if (buttonType === 'icon' || buttonType === 'text_and_icon') {
        // помимо того, что getIconSVG скачает SVG, также важно,
        // что getIconSVG обновляет данные this.currentIconData.
        this.getIconSVG(
          this.model.get('icon_noun_id') || this.model.get('icon_rm_id'),
          this.model.get('icon_noun_url'),
          function(err, data) {
            if (err || !data.$svg) {
              return;
            }

            // рендерим SVG Иконки, предварительно покрасив.
            this.renderIcon({ $svg: this.getСoloredIconSVG() });

            // если Кнопка создана в первый раз, то у неё
            // Иконка не растеризована и урла не будет → отправляем на растер.
            if (!this.model.get('icon_rasterUrl')) {
              this.rasterizeSVG({ default: true, hover: true, current: true });
            }
          }.bind(this)
        );
      }

      // устанавливаем изначальные ограничения на размеры Кнопки (Рамки в Конструкторе).
      this.recalcButtonSize();

      // т.к. мы не в режиме редактирования текста Кнопки,
      // то отключаем инпут.
      // в дальнейшем при взаимодействия юзера с виджетом, включением и отключинем инпута
      // занимается логика в this.enterEditMode() & this.leaveEditMode().
      this.disableTextInput();

      // Позаботимся о том, чтобы цвет фона был контрастен к цвету выделения текста
      this.handleSelectionColor();

      this.bindDOMEvents();

      this.bindModelEvents();

      this.controls = this.initial_controls;

      // для каждой точки ресайза в DOM проставляет свойство data-visual-direction.
      // в данном случае это делается,
      // чтобы сразу после рендера Кнопки, при наведении на точки ресайза,
      // показывались стрелки, т.к. они в стилях зависят от аттрибута
      // data-visual-direction.
      this.frame.recalcPointsDirections();

      // если только что создали виджет — входим в режим
      // редактирования текста.
      if (this.model.created) {
        // даем время виджету отрендериться.
        setTimeout(
          function() {
            this.enterEditMode({
              // выделяем текст, чтобы было удобно сразу
              // переписать его.
              selectText: true,
            });
          }.bind(this),
          500
        );
      }

      this.triggerReady();
    },

    redraw: function(model, options) {
      if (this.proportional && model.get('w') != model.get('h')) {
        this.recalcButtonSize();
      }

      return BlockClass.prototype.redraw.apply(this, arguments);
    },

    bindDOMEvents: function() {
      this.$el.on('mousedown', this.onButtonMousedown);
      this.$button.on('dblclick', this.onButtonDoubleClick);
      this.$textInput.on('input', this.onTextInput);
    },

    unbindDOMEvents: function() {
      this.$el.off('mousedown', this.onButtonMousedown);
      this.$button.off('dblclick', this.onButtonDoubleClick);
      this.$textInput.off('input', this.onTextInput);
    },

    // вся перерисовка Кнопки происходит исключительно
    // по событияем изменения модели, чтобы корректно отрабатывал Undo/Redo, который
    // пачкой откатывает все model.set, сделанные к моменту последнего model.save.
    // также удобно то, что все отрисовочные методы находятся в общем коде
    // для Конструктора и Вьювера common/button-widget.js. эти отрисовочные методы
    // отрабатывает исключительно от переданных данных модели,
    // что и придает им универсальность.
    bindModelEvents: function() {
      this.model.on('change:tp', this.onTypeChange, this);

      this.model.on(
        'change:form',
        function(model, value, options) {
          // аргументы, которые передает Бекбон при изменении модели,
          // обязательно надо передавать дальше onFormChange,
          // т.к. там важно знать откуда — изменения  от юзера или Undo/Redo.
          this.onFormChange(model, value, options);
        },
        this
      );

      this.model.on('change:icon_enabled', this.determineButtonType, this);

      // меняем текст в инпуте при его изменении в модели,
      // срабатывает на случай изменения текста в модели через Undo/Redo.
      this.model.on(
        'change:text',
        function(model, value, options) {
          if (options.undo || options.redo || options.socketUpdate) {
            this.$textInput.val(this.model.get('text'));
          }
        },
        this
      );

      this.model.on(
        'change:text_w change:text_h',
        function() {
          this.onTextContainerSizeChange();
        },
        this
      );

      var individual_styles = this.getIndividualStyles();

      this.model.on(
        individual_styles,
        function() {
          var model = _.clone(this.model.attributes);

          this.buttonWidget.generateIndividualStyleCSS({
            env: 'constructor',
            model: model,
          });

          // пересчитает размер инпута и Кнопки по данным из модели.
          this.onTextInput();
        },
        this
      );

      this.model.on(
        'change:current-font-family change:current-font-weight change:current-font-style',
        function() {
          // ждет пока загрузится выбранный веб-шрифт для Кнопки
          // и запускает пересчет размеров инпута и Кнопки по данным из модели.
          this.waitOnExactWebFontLoaded();
        },
        this
      );

      this.model.on(
        'change:font-family change:font-weight change:font-style',
        function() {
          // ждет пока загрузится выбранный веб-шрифт для Кнопки
          // и запускает пересчет размеров инпута и Кнопки по данным из модели.
          this.waitOnExactWebFontLoaded();
        },
        this
      );

      // c дебонсом, т.к. эти изменения приходят вместе друг за другом.
      this.model.on(
        // обязательно icon_enabled тоже слушаем, т.к.
        // если отменили иконку, а потом опять выбрали ту же,
        // то change от icon_noun_id icon_rm_id icon_noun_url не будет,
        // т.к. эти значения из модели не затирались (это может пригодиться
        // в будущем).
        'change:icon_enabled change:icon_noun_id change:icon_rm_id change:icon_noun_url',
        function(model, value, options) {
          // аргументы, которые передает Бекбон при изменении модели,
          // обязательно надо передавать дальше в onIconChange,
          // т.к. там мы проверяем, что если изменения модели пришли от Undo\Redo,
          // то мы не растеризуем Иконку.
          this.onIconChange.__debounced(model, value, options);
        },
        this
      );

      // при изменении размера Иконки в модели.
      this.model.on(
        'change:icon_h',
        function(model, value, options) {
          // аргументы, которые передает Бекбон при изменении модели,
          // обязательно надо передавать дальше в onIconContainerSizeChange,
          // т.к. там мы проверяем, что если изменения модели пришли от onIconChange,
          // то мы не растеризуем Иконку, onIconChange сам запускает растеризацию.
          this.recalcIconContainerSize(model, value, options);
        },
        this
      );

      // при изменении позиции Иконки в модели.
      this.model.on(
        'change:icon_pos',
        function(model, value, options) {
          this.onIconPositionChange();

          this.recalcButtonSize();
        },
        this
      );

      // c дебонсом, т.к. эти изменения могут прийти вместе друг за другом.
      this.model.on(
        'change:icon_color change:icon_color_opacity',
        function(model, value, options) {
          // аргументы, которые передает Бекбон при изменении модели,
          // обязательно надо передавать дальше в onIconColorChange,
          // т.к. там мы проверяем, что если изменения модели пришли от onIconChange,
          // то мы не растеризуем Иконку, onIconChange сам запускает растеризацию.
          this.onIconColorChange(model, value, options);
        },
        this
      );

      // c дебонсом, т.к. эти изменения могут прийти вместе друг за другом.
      this.model.on(
        'change:hover-icon_color change:hover-icon_color_opacity',
        function(model, value, options) {
          // аргументы, которые передает Бекбон при изменении модели,
          // обязательно надо передавать дальше в onIconColorChange,
          // т.к. там мы проверяем, что если изменения модели пришли от onIconChange,
          // то мы не растеризуем Иконку, onIconChange сам запускает растеризацию.
          this.onIconColorChange(model, value, options, 'hover');
        },
        this
      );

      // c дебонсом, т.к. эти изменения могут прийти вместе друг за другом.
      this.model.on(
        'change:current-icon_color change:current-icon_color_opacity',
        function(model, value, options) {
          this.onIconColorChange(model, value, options, 'current');
        },
        this
      );
    },

    getIndividualStyles: function() {
      var individual_styles = [
        // Default
        'background-color',
        'background-color-opacity',

        'border-radius',

        'border-width',
        'border-color',
        'border-color-opacity',

        'font-family',
        'font-style',
        'font-weight',

        'color',
        'color-opacity',
        'font-size',
        'letter-spacing',

        // Hover
        'hover-background-color',
        'hover-background-color-opacity',

        'hover-border-width',
        'hover-border-color',
        'hover-border-color-opacity',

        'hover-color',
        'hover-color-opacity',

        // Current
        'current-background-color',
        'current-background-color-opacity',

        'current-border-radius',
        'current-border-width',
        'current-border-color',
        'current-border-color-opacity',

        'current-color',
        'current-color-opacity',

        'current-font-family',
        'current-font-style',
        'current-font-weight',

        'current-font-size',
        'current-letter-spacing',
      ];

      individual_styles.forEach(function(style, i, styles) {
        styles[i] = 'change:' + style;
      });

      return individual_styles.join(' ');
    },

    onFormChange: function(model, value, options) {
      var adjust_height_to_content_for_rectangle = false;

      if (!(options.undo || options.redo)) {
        var form = this.model.get('form');

        // только, если изменения от юзера,
        // т.е. сам нажал на форму прямоугольника.
        adjust_height_to_content_for_rectangle = true;
      }

      this.recalcButtonSize({
        onFormChange: true,
        adjust_height_to_content_for_rectangle: adjust_height_to_content_for_rectangle,
      });
    },

    onIconPositionChange: function() {
      // если Кнопка с текстом и Иконкой,
      // то правильный margin выставит в зависимости от
      // положения Иконки к тексту.
      this.buttonWidget.applyIconContainerSize(_.clone(this.model.attributes));

      this.buttonWidget.applyIconPosition(_.clone(this.model.attributes));
    },

    onTextContainerSizeChange: function() {
      this.buttonWidget.applyTextContainerSize(_.clone(this.model.attributes));
    },

    // при изменении аттрибутов Default цвета Иконки в модели.
    onIconColorChange: function(model, value, options, state) {
      state = state || 'default';
      var button_type = this.model.get('tp');

      if (button_type === 'text') return;

      // перендериваем SVG Иконки, предварительно перекрасив.
      this.renderIcon({ $svg: this.getСoloredIconSVG({ buttonState: state }) });

      if (!(options.onIconChange || options.undo || options.redo)) {
        // не отправляем Иконку на растеризацию отсюда, если:
        // → изменения аттрибутов цвета Иконки
        // выставлялись в модель в onIconChange,
        // onIconChange сам это сделает.
        // → изменение аттрибутов Иконки в модели НЕ из-за Undo/Redo,
        // при Undor/Redo урлы на старые растеризованные картинки уже вернулись.
        // сами же картинки не удаляются из S3 - растеризовать опять не надо.

        var debounced = this.rasterizeSVG['__debounced_' + state];
        if (_.isFunction(debounced)) {
          var params = {};
          params[state] = true;
          debounced(params);
        }
      }
    },

    // клонирует SVG текущей Иконки и меняет его цвет.
    // возвращает $iconSVG.
    // запрашивающий перекраску сам разбирается, что делать
    // c перекрашенным SVG.
    getСoloredIconSVG: function(options) {
      options = options || {};

      var model = _.clone(this.model.attributes),
        state,
        color,
        opacity,
        fill_color,
        $iconSVG = this.currentIconData.$svg.clone();

      // сначала проверяем в опциях цвет какого состояния применить
      // при покраске SVG Иконки.
      // покраску в определенный цвет через опции запрашивает rasterizeSVG(),
      // в остальных случаях цвет берется от текущего состояния Кнопки в Конструкторе.
      if (options.buttonState === 'default') {
        state = 'default';
      } else if (options.buttonState === 'hover' || this.buttonState === 'hover') {
        state = 'hover';
      } else if (options.buttonState === 'current' || this.buttonState === 'current') {
        state = 'current';
      } else {
        state = 'default';
      }

      if (state === 'hover') {
        color = model['hover-icon_color'];
        opacity = model['hover-icon_color_opacity'] / 100;
      } else if (state === 'current') {
        color = model['current-icon_color'] || model['icon_color'];
        opacity =
          (!_.isUndefined(model['current-icon_color_opacity'])
            ? model['current-icon_color_opacity']
            : model['icon_color_opacity']) / 100;
      } else {
        // берем цвет из Default состояния.

        color = model['icon_color'];
        opacity = model['icon_color_opacity'] / 100;
      }

      // нельзя задавать у SVG fill через rgba, только через rgb.
      // иначе растеризатор inkscape на бэке сделает картинку полностью прозрачной.
      // вместо этого прозрачность задается в атрибуте fill-opacity ниже.
      fill_color = Utils.getRGBA(color, 1);

      $iconSVG.find('*:not(.fixed-color)').each(function() {
        var $this = $(this);

        $this.css({ fill: '', 'fill-opacity': '' });

        $this.get(0).removeAttribute('fill');
        $this.get(0).removeAttribute('fill-opacity');
      });

      $iconSVG.get(0).setAttribute('fill', fill_color);
      $iconSVG.get(0).setAttribute('fill-opacity', opacity);

      return $iconSVG;
    },

    // вызывается каждый раз когда выделяем виджет(ы) на рабочей области
    // кликом, кликом с шифтом, рамкой выделения
    // (если в результате перечисленных манипуляций не оказывается
    // ни одного виджета в выделении, тогда это событие не стреляет)
    // точно такой же код в блоке виджета Текст.
    onWorkspaceBlocksSelect: function(blocks) {
      BlockClass.prototype.onWorkspaceBlocksSelect.apply(this, arguments);

      // защита от ситуация, когда два текстовых виджета могут находиться
      // в состоянии редактирования
      // дело в том, что если делать клик по виджету с зажатым шифтом,
      // то у нас это означает добавить/удалить из текущего списка выделенных виджетов,
      // а при таком действии deselect не вызывается (смотрим block.js),
      // и, соответственно, если у нас уже есть текстовый виджет, который в режиме редактирования,
      // и мы делаем двойной клик с зажатым шифтом по другому текстовому виджету,
      // то старый текстовый виджет не выходит из режима редактирования так как нет события deselect.

      if (!this.rendered) return;

      // смотрим, что у нас в текущем выделении текущий блок или еще что-то.
      var isOnlyMeSelected = blocks && blocks.length == 1 && blocks[0] == this;

      // если мы находимся в режиме редактирования,
      // и узнали, что список текущих выделенных виджетов поменялся
      // (например с шифтом щелкнули еще о одному)
      // тогда выходим из режима редактирования с опцией
      // сохранения данных виджета.
      if (this.isEditMode && !isOnlyMeSelected) {
        this.leaveEditMode({
          deselectControl: true,
          saveWidgetData: true,
        });

        // с дебонсом 500 запустит перегенерацию скриншота страницы.
        this.workspace.trigger('redraw');
      }
    },

    onButtonMousedown: function(e) {
      // если мы находимся в режиме редактирования текста и нажали
      // на что угодно в Кнопке кроме ипута текста (в том числе и на Рамку,
      // т.к. слушатель клика навешан на this.$el),
      // то выходим из режима редактирования текста
      // с опцией сохранения данных виджета.
      if (this.isEditMode && !$(e.target).is('.text')) {
        this.leaveEditMode({
          deselectControl: true,
          saveWidgetData: true,
        });
      }
    },

    onButtonDoubleClick: function() {
      // если виджет входит в группу, тогда не отрабатывать двойной клик по нему
      if (this.model.get('pack_id')) {
        BlockClass.prototype.onDblClick.apply(this, arguments);
        return;
      }

      // фикс бага https://trello.com/c/boBhnNnV/153--
      // вркатце суть в том, что сразу несколько текстовых виджетов могло быть в состоянии редактироания одновременно
      // это происходило из-за того, что простые клики по виджетам блокируются если какой либо из текущих виджетов говорит
      // что у него есть открытые панельки, которые надо закрыть вместо того, чтобы выделять виджеты там где кликнули
      // а двойные клики мы не обрабатываем и не блокируем, но текстовый виджет их слушает и по двойному клику переходит в режим редактирования
      // а вот если есть уже один текстовый виджет в режиме редактированяи у которого открыт один попап внутри другого (например фонт селектор открыт из типографики)
      // то по двойному клику закрывается фонт селектор, а за ним сразу типографика, но при этом выхода из редактора не происходит, потому, что до deselect дело не дошло, оба клика проглотили попапы
      // и двойной клик по другому виджету открывает еще один активный редактор
      // поэтому тут добавляем простую проверку, что виджет выделен, поскольку в вышеописанной ситуации он выделен не будет
      // так как оба клика были проглочены попами текущего редактора и select на текстовом виджете по которому делаем второй клик не происходит, profit
      if (!this.selected) return;

      !this.isEditMode && this.enterEditMode({ selectText: true });
    },

    enterEditMode: function(options) {
      options = options || {};

      this.isEditMode = true;

      // контейнер текста может быть скрыт, если
      // у Кнопки тип icon.
      // важно имеено фейкнуть смену типа Кнопки, т.к.
      // смена типа в модели повлечет за собой визуальные тюнинги,
      // например, добавление отступа между Иконкой и текстом.
      this.determineButtonType({ enter_edit_mode: true });

      this.enableTextInput();

      if (options.selectText) {
        this.$textInput.select();
      } else {
        // ставим каретку в начало текста.
        $(this.$textInput)
          .focus()
          .setCursorPos(0);
      }

      // всегда при входе в режим редактирования текста
      // выделяем соответствующий Контрол.
      var workspaceControls = this.workspace && this.workspace.controls,
        control;

      if (!workspaceControls) return;

      control = workspaceControls.findControl('button_edit');
      if (!control) return;

      control.master.select(control);
    },

    leaveEditMode: function(options) {
      options = options || {};

      this.model.set('text', this.$textInput.val());

      this.isEditMode = false;

      this.$textInput.blur();

      this.disableTextInput();

      this.determineButtonType();

      if (options.saveWidgetData) {
        // сохраняем данные виджета.
        // опция ставится в местной логике button.js
        // при выходе их режима редактирования текста.
        // Контрол button_edit имеет свою логику запуска сохранения,
        // привязанную к флагам saveOnDeselect: true & saveOnDestroy: true.

        this.saveWidgetData();
      }

      if (options.deselectControl) {
        // снимаем выделение с Контрола входа
        // в режим редактирования,
        // остальные станут активно-подсвеченными.

        var workspaceControls = this.workspace && this.workspace.controls,
          control;

        if (!workspaceControls) return;

        control = workspaceControls.findControl('button_edit');
        if (!control) return;

        control.master.deselect();
      }
    },

    disableTextInput: function() {
      this.$textInput
        // не дает фокуситься в инпут.
        // обязательно отключаем через readonly, а не через disabled,
        // т.к. при disabled Фаерфокс проглатывает все события мыши
        // и не работает дабл-клик в текст и таскание виджета.
        .attr('readonly', true);
    },

    // отмена всего, что делает this.disableTextInput()
    enableTextInput: function() {
      this.$textInput.removeAttr('readonly');
    },

    onTextInput: function() {
      // стоит отметить, что редактируя текст Кнопки в инпуте,
      // CTRL(CMD) + Z и CTRL(CMD) + SHIFT + Z выполняют свою нативную функцию undo/redo
      // для текста. и делают это согласно принципам браузера. и нажатия этих комбинаций клавиш
      // в момент редактирования текста не вызывает Undo/Redo в Конструкторе,
      // т.к. нажатия в принципе не обрабатываются Роутером Конструктора
      // раз идут из инпута (см. constructor/router.js → onKeyDown).

      // выставляем ширину и высоту инпута по ширине и высоте введенного текста.
      this.recalcTextInputSize();

      // чтобы помещался контент в Кнопку
      // пересчитываем ширину и высоту Кнопки (Рамки в Конструкторе),
      // и выставляем больший размер, если контент не помещается.
      this.recalcButtonSize();
    },

    // выставляем ширину инпута по ширине введенного текста.
    recalcTextInputSize: function() {
      var adjusted_w,
        adjusted_h,
        em_w,
        em_h,
        currentState = this.$button.hasClass('current') ? 'current-' : '',
        model = _.clone(this.model.attributes),
        fontSize = model[`${currentState}font-size`],
        letterSpacing = model[`${currentState}letter-spacing`],
        fontFamily = model[`${currentState}font-family`],
        fontStyle = model[`${currentState}font-style`],
        fontWeight = model[`${currentState}font-weight`];

      if (!this.$textInputSizeAdjuster || !this.$textInputSizeAdjuster.length) {
        this.$textInputSizeAdjuster = $('<div/>');

        this.$textInputSizeAdjuster
          .css({
            position: 'absolute',
            left: -9999 + 'px',
            'white-space': 'pre',
            width: 'auto',
          })
          .appendTo(this.$textInput.parent());
      }

      this.$textInputSizeAdjuster[0].className = this.$textInput[0].className;
      this.$textInputSizeAdjuster.addClass('fake');
      this.$textInputSizeAdjuster.text(this.$textInput.val());

      // применяем из модели самые последние аттрибуты стиля, которые
      // могут повлиять на размер блока.
      // т.к. стили Кнопки после измненения модели часто
      // генерятся в <style/> с дебонсом,
      // поэтому  $textInputSizeAdjuster будет наследовать старые стили.
      this.$textInputSizeAdjuster.css({
        'font-family': fontFamily,
        'font-style': fontStyle,
        'font-weight': fontWeight,
        'font-size': fontSize + 'px',
        'letter-spacing': letterSpacing + 'px',
      });

      // измеряем размер текста.
      adjusted_w = this.$textInputSizeAdjuster.width();
      adjusted_h = this.$textInputSizeAdjuster.height();

      // измеряем ширину буквы M для выставления минимального
      // воздуха слева и справа у контента Кнопки в this.recalcButtonSize,
      // если Кнопка с текстом.
      this.$textInputSizeAdjuster.css('letter-spacing', 0).text('M');
      em_w = this.$textInputSizeAdjuster.width();
      em_w = Math.ceil(em_w / 2) * 2; // округляем и делаем четной.
      em_h = this.$textInputSizeAdjuster.height();

      // если текста в инпуте нет, то
      // задаем минимально приличный размер инпуту,
      // чтобы он не скукожился и каретка видна была.
      // если текста так и не будет при leaveEditMode и Кнопка будет
      // с иконкой, то текстовый контейнер будет скрыт, т.к.
      // не срашно, что он с размером остается.
      if (this.$textInput.val().trim().length === 0) {
        adjusted_w = em_w;
        adjusted_h = em_h;
      }

      // ширина текста округляется в большую сторону и делается четной.
      adjusted_w = Math.ceil(adjusted_w / 2) * 2;

      // добавляем к ширине текста половину ширины текущего размера буквы М.
      // т.к текст в инпуте всегда text-align: center; то получается
      // по 1/2 em_w слева и справа воздуха для текста.
      // для чего это:
      // → чтобы всегда влезал в инпут наклонный (италик) текст
      // → чтобы практически всегда текстс положительным межбуквенным расстоянием
      // не сдвигался в режиме радктирования текста, когда каретка в конце текста,
      // и текст обрезается по инпут в фокусе.
      // это нативное поведение такое, т.к. каретку надо поставить после межбуквенного.
      // → 1/2 em_w - минимальный отступом между иконкой и текстом.
      adjusted_w = adjusted_w + em_w;

      // высота текста округляется в большую сторону и делается четной.
      // note: adjusted_h в $textInputSizeAdjuster считался
      // с line-height: 1.4, чтобы все висячие
      // штуки у букв влезли.
      adjusted_h = Math.ceil(adjusted_h / 2) * 2;

      this.model.set({
        text_w: adjusted_w,
        text_h: adjusted_h,
        em_w: em_w,
      });
    },

    // чтобы помещался контент в Кнопку
    // пересчитываем ширину и высоту Кнопки (Рамки в Конструкторе),
    // и выставляем больший размер, если контент не помещается.
    // + минимальные отступы для воздуха вокруг контента.
    recalcButtonSize: function(options) {
      options = options || {};

      var model = _.clone(this.model.attributes),
        type = model.tp,
        form = model.form,
        w = model.w,
        h = model.h,
        x = model.x,
        y = model.y,
        text_w = model.text_w,
        text_h = model.text_h,
        em_w = model.em_w,
        icon_w = model.icon_w,
        icon_h = model.icon_h,
        icon_pos = model.icon_pos,
        iconMargin,
        content_w,
        content_h,
        new_w,
        new_h,
        setObject = {};

      if (type === 'text_and_icon') {
        content_w = text_w + icon_w;

        // добавляем минимальный воздух слева и справа от контента em_w*2
        // равный текущей ширине буквы M (рассчитывается в recalcTextInputSize).
        content_w = content_w + em_w * 2;

        // также закладываем отступ между Иконкой и текстом в общую ширину контента.
        content_w = content_w + this.buttonWidget.calcIconMarginFromText(icon_w);

        if (icon_pos === 'left' || icon_pos === 'right') {
          // что выше Иконка или контейнер текста, то и задает высоту контента.
          if (icon_h > text_h) {
            content_h = icon_h;

            // закладываем минимальный воздух сверху и снизу для контента,
            // отталкиваясь от высоты (размера) Иконки.
            content_h = content_h + this.buttonWidget.calcIconTopBottomMargin(icon_h) * 2;
          } else {
            content_h = text_h;

            // закладываем минимальный воздух сверху и снизу для Контента,
            // отталкиваясь от размера шрифта.
            content_h = content_h + this.buttonWidget.calcTextTopBottomMargin(model['font-size']) * 2;
          }
        }
      } else if (type === 'text') {
        content_w = text_w;

        // добавляем минимальный воздух слева и справа от контента
        // равный текущей ширине буквы M (рассчитывается в recalcTextInputSize).
        content_w = content_w + em_w * 2;

        content_h = text_h;

        // закладываем минимальный воздух сверху и снизу для Контента,
        // отталкиваясь от размера шрифта.
        content_h = content_h + this.buttonWidget.calcTextTopBottomMargin(model['font-size']);
      } else if (type === 'icon') {
        // закладываем минимальный воздух сверху, снизу,
        // слева и справа для Контента,
        // отталкиваясь от ширины (размера) Иконки.
        iconMargin = this.buttonWidget.calcIconMarginFromText(icon_w);

        content_w = icon_w + iconMargin;
        content_h = icon_h + iconMargin;
      }

      // если контент не влезает в Кнопку по ширине, то задаем ей новую ширину.
      if (content_w > w) new_w = content_w;

      // если контент не влезает в Кнопку по высоте, то задаем ей новую высоту.
      if (content_h > h) new_h = content_h;

      var w = new_w || w,
        h = new_h || h,
        shift = Math.floor((w - h) / 2);

      if (form === 'circle' || form === 'square') {
        if (w > h) {
          _.extend(setObject, {
            w: w,
            h: w,

            // оставляем центр виджета на месте.
            y: y - shift,
            x: x - Math.floor((w - this.model.get('w')) / 2),
          });
        } else {
          _.extend(setObject, {
            w: h,
            h: h,

            // оставляем центр виджета на месте.
            x: x + shift,
            y: y - (h - this.model.get('h')) / 2,
          });
        }

        // чтобы при ресайзе виджета за точки ресайза,
        // стороны оставались равными.
        this.proportional = true;
      } else if (form === 'rectangle') {
        if (new_w) {
          _.extend(setObject, {
            w: new_w,

            // оставляем центр виджета на месте.
            x: x - Math.floor((new_w - this.model.get('w')) / 2),
          });
        }

        if (new_h) {
          _.extend(setObject, {
            h: new_h,

            // оставляем центр виджета на месте.
            y: y - (new_h - this.model.get('h')) / 2,
          });
        } else if (options.adjust_height_to_content_for_rectangle) {
          _.extend(setObject, {
            h: content_h,

            // оставляем центр виджета на месте.
            y: y + Math.floor(((new_w || w) - content_h) / 2),
          });
        }

        this.proportional = false;
      }

      // анимируем изменение размеров и радиуса.
      if (options.onFormChange) {
        this.$el.addClass('resize-transition');
      }

      if (setObject.w && form === 'circle') {
        this.buttonWidget.applyCircleRadius();
      } else {
        this.$button.css('border-radius', '');
      }

      // изменение модели заставляет виджет (рамку) перерисоваться,
      // запуская в block.js → событие 'change' → redraw().
      this.model.set(setObject);

      // отключаем транзишен для анимации изменения размеров.
      setTimeout(
        function() {
          // попадаем в следующий кадр анимации,
          // иначе транзишн раньше времени отключиться,
          // и его не будет.
          window.requestAnimationFrame(
            function() {
              this.$el.removeClass('resize-transition');
            }.bind(this)
          );
        }.bind(this),

        // 300 - transition duration, 150 - запас.
        300 + 150
      );

      // устанавливаем ограничения на минимальные размеры Кнопки (Рамки в Конструкторе),
      // отталкиваясь от размеров контента. в размеры контента уже
      // заложены минимальные воздух вокруг себя логикой выше.
      this.setSizeConstraints(content_w, content_h);
    },

    // устанавливаем ограничения на минимальные размеры Кнопки (Рамки в Конструкторе).
    setSizeConstraints: function(w, h) {
      _.extend(this.frame, {
        minwidth: w,
        maxwidth: 9999,
        minheight: h,
        maxheight: 9999,
      });
    },

    determineButtonType: function(options) {
      options = options || {};

      var icon_enabled = this.model.get('icon_enabled'),
        button_has_text = options.enter_edit_mode ? true : this.$textInput.val().trim().length,
        determined_button_type;

      if (icon_enabled && button_has_text) {
        determined_button_type = 'text_and_icon';
      } else if (icon_enabled && !button_has_text) {
        determined_button_type = 'icon';
      } else {
        determined_button_type = 'text';
      }

      this.model.set('tp', determined_button_type);
    },

    onTypeChange: function() {
      // добавит\уберет пропорциональный отступ между Иконкой и текстом.
      // если тип Кнопки Иконка + текст — добавит,
      // если тип Кнопки Иконка — уберет.
      this.buttonWidget.applyIconContainerSize(_.clone(this.model.attributes));

      // скрытие\показ контейнеров текста и иконки.
      this.buttonWidget.applyButtonType(this.model.get('tp'));

      // обязательно определить новый минимальный размер Кнопки.
      this.recalcButtonSize();
    },

    // ждет пока загрузится выбранный веб-шрифт для Кнопки
    // и запускает пересчет размеров инпута и Кнопки по данным из модели.
    waitOnExactWebFontLoaded: function() {
      var systemFonts = ['arial', 'courier new', 'georgia', 'times new roman', 'trebuchet ms', 'verdana', 'tahoma'],
        model = _.clone(this.model.attributes);

      // если шрифт системный, то ничего не ждем и не делаем.
      if (_.indexOf(systemFonts, model['font-family'].toLowerCase()) > -1) {
        return;
      }

      TextUtils.exactWaitForFontLoad(model['font-family'], model['font-weight'], model['font-style'], this.onTextInput);
    },

    saveWidgetData: function() {
      // если модель не изменилась, то ничего не делаем.
      // /gwidget - подозрительное место, проверить, учитываются ли спец. атрибуты
      if (_.isEqual(this.model.toJSON(), this._oldAttrs)) return;

      this.saveXHR && this.saveXHR.abort();

      // сохраняем слепок модели, которую мы сохраняем.
      this._oldAttrs = _.clone(this.model.toJSON());

      this.saveXHR = this.model.save();
    },

    /**
     * Переопределяем метод deselect.
     */
    deselect: function(block) {
      BlockClass.prototype.deselect.apply(this, arguments);

      if (block == this) return;

      // если мы были в режиме редактирования перед деселектом.
      if (this.isEditMode) {
        // выйдет из режима редактирования текста
        // c опцией cохранения данных виджета.
        this.leaveEditMode({ saveWidgetData: true });

        // с дебонсом 500 запустит перегенерацию скриншота страницы.
        this.workspace.trigger('redraw');
      }
    },

    /**
     * Чтобы при изменении размеров виджета на сервер
     * сохранялись только размеры бокса и его положение — {patch: true},
     * а не все данные, включая контент.
     */
    getSaveBoxOptions: function(options) {
      return _.extend({ patch: true }, options);
    },

    destroy: function() {
      this.buttonWidget && this.buttonWidget.destroy();
      this.buttonWidget = null;

      this.unbindDOMEvents();

      // удаляем всех слушателей изменений модели,
      // созданные этой вьюхой.
      this.model.off(null, null, this);

      BlockClass.prototype.destroy.apply(this, arguments);
    },

    // ///// методы по работе с иконками — скопированы из shape.js

    MAX_ICON_CACHE_SIZE: 30,

    // метод запускается при изменении объекта icon в модели Кнопки.
    // это событие может прийдти после установки новых значений в объект icon
    // в модели Кнопки, произведенных в методе onIconItemClick() Контрола Иконки Кнопки
    // или после Undo/Redo, которые тоже устанавливает данные в модель.
    // вызов getIconSVG согласно внутренней логике возьмет данные из кэша
    // (лимит 30 SVG-шек и он вряд ли исчерпается.
    // а если исчерпается, тогда скачает данные, что поделать).
    // поэтому не страшно, что getIconSVG запустится как в Контроле Иконки,
    // так и здесь после этого.
    onIconChange: function(model, value, options) {
      model = _.clone(this.model.attributes);

      // также важно, что getIconSVG обновляет данные this.currentIconData.
      this.getIconSVG(
        model['icon_noun_id'] || model['icon_rm_id'],
        model['icon_noun_url'],
        function(err, data) {
          if (err || !data.$svg) {
            return;
          }

          if (!(options.undo || options.redo)) {
            // т.к. ширина по пропорциям новой иконки
            // будет другая,
            // то пересчитываем размер контейнера Иконки.
            // также запустит this.recalcButtonSize()
            // делаем это, если изменение аттрибутов Иконки в модели НЕ из-за Undo/Redo,
            // т.е. из-за изменений юзера — выбрал другую Иконку,
            // т.е. Иконка первый раз появляется.
            // при Undor/Redo размеры Иконки и Кнопки уже вернулись.
            this.recalcIconContainerSize(null, null, {
              onIconChange: true,
            });
          }

          // рендерим SVG Иконки, предварительно покрасив.
          this.renderIcon({ $svg: this.getСoloredIconSVG() });

          if (!(options.undo || options.redo)) {
            // делаем растеризацию,
            // если изменение аттрибутов Иконки в модели НЕ из-за Undo/Redo,
            // т.е. из-за изменений юзера — выбрал другую Иконку,
            // т.е. Иконка первый раз появляется.
            // при Undor/Redo урлы на старые растеризованные картинки уже вернулись.
            // сами же картинки не удаляются из S3 - растеризовать опять не надо.

            // размеры Иконки выставлены, покрашена, отрендерена.
            // растеризуем.
            this.rasterizeSVG({ default: true, hover: true, current: true });
          }
        }.bind(this)
      );
    },

    areValuesLinked: function(state1, state2, key1, key2) {
      var model = _.clone(this.model.attributes);
      var prefix1 = state1 === 'default' ? '' : state1 + '-';
      var prefix2 = state2 === 'default' ? '' : state2 + '-';

      return model[prefix1 + key1] == model[prefix2 + (key2 || key1)];
    },

    // если у текста и Иконки один и тот же ховер-цвет,
    // то считаем их связанными.
    isTextAndIconColorLinked: function(state, options) {
      state = state || 'default';
      options = options || {};

      if (options.compare_only_color) {
        return this.areValuesLinked(state, state, 'color', 'icon_color');
      } else {
        return (
          this.areValuesLinked(state, state, 'color', 'icon_color') &&
          this.areValuesLinked(state, state, 'color-opacity', 'icon_color_opacity')
        );
      }
    },

    isTextAndIconSizeLinked: function(state) {
      state = state || 'default';
      return this.areValuesLinked(state, state, 'font-size', 'icon_h');
    },

    // пересчитываем размер контейнера Иконки.
    // запускается при смене Иконки или изменении её размера «Size» (выcоты) в Контроле Иконки.
    // высота у нас всегда фиксированная и задается параметром Size в Контроле Иконки,
    // а вот ширина будет пересчитываться, чтобы соответствовать соотношению сторон
    // как у оригинальной SVG-шки.
    recalcIconContainerSize: function(model, value, options) {
      var button_type = this.model.get('tp');

      if (button_type === 'text') return;

      var icon_h = this.model.get('icon_h'),
        icon_w;

      // this.currentIconData.aspect_ratio = w/h оригинальной SVG-шки.
      icon_w = Math.floor(icon_h * this.currentIconData.aspect_ratio);

      this.model.set({
        icon_w: icon_w,
      });

      // чтобы помещался контент в Кнопку
      // пересчитываем ширину и высоту Кнопки (Рамки в Конструкторе).
      this.recalcButtonSize();

      this.buttonWidget.applyIconContainerSize(_.clone(this.model.attributes));

      if (!(options.onIconChange || options.undo || options.redo)) {
        // делаем растеризацию,

        // не делаем:
        // если изменения аттрибутов размера Иконки
        // выставлялись в модель в onIconChange,
        // то не отправляем Иконку на растеризацию отсюда,
        // onIconChange сам это сделает.
        // если изменение аттрибутов Иконки в модели из-за Undo/Redo,
        // т.е. из-за изменений юзера,
        // при Undor/Redo урлы на старые растеризованные картинки уже вернулись.
        // сами же картинки не удаляются из S3 - растеризовать опять не надо.

        this.rasterizeSVG.__debounced_both({ default: true, hover: true, current: true });
      }
    },

    rasterizeSVG: function(options) {
      options = options || {};
      if (!(options.default || options.hover || options.current)) return;

      var rasterize = function(state) {
        state = state || 'default';

        // получаем SVG Иконки в цвете нужного состояния.
        // актуальные цвета Иконки должны быть обязательно выставлены
        // в модель до растеризации.
        var $svg = this.getСoloredIconSVG({ buttonState: state });

        if (!($svg || $svg.length)) {
          return;
        }

        var prefixMap = {
          default: 'icon',
          hover: 'hover-icon',
          current: 'current-icon',
        };

        var prefix = !_.isUndefined(prefixMap[state]) ? prefixMap[state] : prefixMap.default;

        // размеры Иконки обзательно должны быть пересчитаны и
        // выставлены на контейнер Иконки до растеризации,
        // чтобы на растеризацию Иконка ушла с актуальными размерами.
        var size = {
          width: this.model.get('icon_w') * this.RASTERIZE_MULTIPLIER,
          height: this.model.get('icon_h') * this.RASTERIZE_MULTIPLIER,
        };

        var request = state + '_icon_SVG_rasterize_Xhr';
        this[request] && this[request].abort();

        var callback = options[state + 'Callback'];

        this[request] = SVGUtils.rasterize({
          widgets: [this.model],
          svg: $svg,
          mid: this.workspace.mag.get('_id'),
          viewport: this.model.getViewport(),
          size: size,
          prefix: prefix,
          onSuccess: function(data) {
            callback && callback(null, data);
          },
          onError: function(data) {
            callback && callback(data);
          },
        });
      }.bind(this);

      if (options.default) {
        rasterize('default');
      }
      if (options.hover) {
        rasterize('hover');
      }
      if (options.current) {
        rasterize('current');
      }
    },

    // рендерит SVG элемент Иконки в контейнер Иконки Кнопки,
    renderIcon: function(options) {
      options = options || {};

      var $svg;

      if (options.$svg) {
        $svg = options.$svg;
      } else {
        // если SVG не задан, то
        // берем и предварительно красим
        // this.currentIconData.$svg

        $svg = this.getСoloredIconSVG();
      }

      // рендерим, предварительно удаляя старый SVG.
      this.$buttonIcon.empty().html($svg.clone());
    },

    // SVG утки. Нужен для моментального отрбражения шейпа типа иконка, если еще не выбрана реальная иконка
    getDefaultIconSVG: function() {
      return this.prepareIconSVG(
        $(
          '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="283.5px" height="283.5px" viewBox="0 0 283.5 283.5" enable-background="new 0 0 283.5 283.5" xml:space="preserve"><g><g><path fill-rule="evenodd" clip-rule="evenodd" fill="#231F20" d="M258.3,114.1c-14.1,0-23.1,25-30.1,33.8 c-7,8.8-33.3,2.7-44.1,0.7c-10.7-2.1-38.7-3.9-38.7-3.9s47.4-18,47.4-65c0-47.7-54.9-75.3-73.5-78.1c-17-2.8-23.8,5.6-25.4,7.8 c-1.6,2.2-4.2,4.1-8,5.9c-3.9,1.8-1.5-0.1-15.4,9.8c-13.9,9.8-41.8-4-44.1-4.6c-2.3-0.5-6.8-1.8-8,4.6c-1.5,9.6,6.7,25.4,6.7,25.4 s-2.2,0.2-8,3.3c-5.8,3.1-2.8,7.7-1.3,9.1c1.4,1.4,27.9,16.3,32.1,18.9c10.6,5.3,20.3,31.6-7.3,51.4C-3.1,163,0.4,198.7,0.4,198.7 s-12,85.2,126.9,85.2c139,0,155.6-96.1,155.6-124.2C283,131.5,272.4,114.1,258.3,114.1z M89.5,50.2c-7.1,6.5-15.7,8-19.3,3.5 c-3.6-4.6-0.7-13.5,6.3-20c7.1-6.5,15.7-8,19.3-3.5C99.4,34.7,96.6,43.7,89.5,50.2z M76.1,38.9c-4.7,4.3-6.6,10.3-4.2,13.3 c2.4,3,8.1,2,12.9-2.3c4.7-4.3,6.6-10.3,4.2-13.3C86.6,33.6,80.9,34.6,76.1,38.9z"/></g></g></svg>'
        )
      );
    },

    // используется в Контроле Иконки Кнопки в onIconItemClick(),
    // а также при первом рендеринге Кнопки с иконкой в Конструкторе.
    getIconSVG: function(id, icon_url, callback) {
      this.iconXhr && this.iconXhr.abort();

      var cachedIconData, req_data, iconData;

      // если id не задан — возвращаем дефолтную иконку.
      if (!id) {
        var $svg = this.getDefaultIconSVG();

        iconData = {
          $svg: $svg,
          noun_url: null,
          aspect_ratio: $svg.data('aspect_ratio'),
        };

        this.currentIconData = iconData;

        return callback(null, iconData);
      }

      // cмотрим, есть ли SVG иконки в кэше.
      cachedIconData = this.getSVGCacheItem(id);

      // возвращаем SVG иконки из кэша, если там нашлось.
      if (cachedIconData) {
        iconData = {
          $svg: cachedIconData.$svg,
          noun_url: cachedIconData.noun_url,
          aspect_ratio: cachedIconData.$svg.data('aspect_ratio'),
        };

        this.currentIconData = iconData;

        return callback(null, iconData);
      }

      // подготавливаемся к запросу SVG иконки.

      var onSuccess = function(data) {
        // данные могут быть самые разные, включая SVG на верхнем уровне.
        var $svg = $('<div></div>')
          .append($(data.svg))
          .find('svg');

        if (!$svg.length) {
          return;
        }

        $svg = this.prepareIconSVG($svg);

        iconData = {
          id: id,
          $svg: $svg,
          noun_url: data.new_url,
          aspect_ratio: $svg.data('aspect_ratio'),
        };

        this.currentIconData = iconData;

        // сохраняем SVG-данные иконки в кэш для последующего реиспользования.
        this.cacheSVGData(iconData);

        return callback(null, {
          $svg: $svg,
          noun_url: data.new_url,
        });
      }.bind(this);

      req_data = {
        method: 'POST',
        url: '/api/authservice/noun/icon',
        data: {
          id: id,
          url: icon_url,
        },
        dataType: 'json',
        success: function(data) {
          this.iconRequestInProgress = false;

          onSuccess(data);
        },
        error: function(xhr) {
          this.iconRequestInProgress = false;

          return callback(xhr);
        },
        context: this,
      };

      // если id иконки начинается с «rm», то это не иконка Noun, а наша,
      // и грузить ее нужно по-другому.
      if (/^rm/.test(id)) {
        _.extend(req_data, {
          method: 'GET',
          url: icon_url,
          cache: false, // иначе Хром берет кешированную картинку и не подставляет нужные заголовки для кроссдоменного запроса и сам же блокирует запрос.
          success: function(data) {
            this.iconRequestInProgress = false;

            onSuccess({ svg: $(data).find('svg'), new_url: icon_url });
          },
          data: null,
          dataType: null,
        });
      }

      this.iconRequestInProgress = true;

      // делаем запрос на SVG иконки.
      this.iconXhr = $.ajax(req_data);
    },

    prepareIconSVG: function($raw_svg) {
      var $tmpSVG = $raw_svg.clone().appendTo($('body')),
        $iconSVG = $raw_svg.clone();

      $tmpSVG.get(0).setAttributeNS(null, 'viewBox', '0 0 ' + $tmpSVG.width() + ' ' + $tmpSVG.height());

      var bbox = $tmpSVG.get(0).getBBox();
      $tmpSVG.remove();

      // сохраняем соотношение сторон текущей SVG иконки.
      // пригодиться для пересчета ширины контейнера Иконки Кнопки.
      // getIconData() достанет это значение из возвращенного этим методом
      // perepareIconSVG() и закэширует его.
      $iconSVG.data('aspect_ratio', bbox.width / bbox.height);

      $iconSVG.removeAttr('width');
      $iconSVG.removeAttr('height');

      // именно так, потому что jQuery не может нормально назначить аттрибуты для SVG.
      $iconSVG.get(0).setAttributeNS(null, 'viewBox', bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height);
      $iconSVG.get(0).setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');

      $iconSVG.css({
        width: '100%',
        height: '100%',
      });

      return $iconSVG;
    },

    // метод сохраняет SVG-данные иконки в кэш для последующего реиспользования.
    cacheSVGData: function(data) {
      window.iconSVGCache.push(data);

      if (window.iconSVGCache.length > this.MAX_ICON_CACHE_SIZE) {
        window.iconSVGCache.shift();
      }
    },

    // метод ищет и возвращает SVG-данные иконки из кэша.
    getSVGCacheItem: function(id) {
      return window.iconSVGCache && _.findWhere(window.iconSVGCache, { id: id });
    },

    handleSelectionColor: function(backgroundRgba) {
      backgroundRgba = backgroundRgba || Colorbox.prototype.hex2rgb(this.model.get('background-color')).concat(1);
      var defaultBackgroundRgba = Colorbox.prototype.hex2rgb(this.DEFAULT_BACKGROUND_COLOR).concat(1);
      this.$button.toggleClass(
        'is-contrast-selection',
        Colorbox.prototype.areColorsClose(backgroundRgba, defaultBackgroundRgba)
      );
    },
  },
  {
    defaults: {
      tp: 'text', // || icon || text_and_icon
      form: 'rectangle', // || circle || square
      text: 'Edit',
      w: 134,
      h: 50,

      // по центру оси x холста.
      x: Math.round(1024 / 2 - 134 / 2),

      transition: false,

      // ширина и высота элемента .text — input в Конструкторе, div во Вьювере.
      // возможно, в будущем во Вьювер можно будет не отсылать,
      // т.к. это флекс итем во флексбоксе.
      // но для Конструктора обязательно надо сохранять размеры и
      // потом выставлять их при рендере из модели.
      // т.к. инпут не флексится по контенту,
      // a также при изначальном рендере неверно рассчитывается ширина в this.recalcTextInputSize
      // для this.$textInputSizeAdjuster через width: auto. после таймаута — ок, но не вариант гадать.
      text_w: 48,
      text_h: 26,

      // ширина буквы M, для тукущего размера шрифта.
      // нужна для выставления минимальных отступов слева и справа
      // у Кнопки с только текстом и Иконкой + текстом.
      // сохраняем, т.к. не удается точно вычислять при первом рендере,
      // а ограничения на размер Кнопки (рамки в Конструкторе) надо ставить сразу.
      em_w: 16,

      // ГЛОБАЛЬНЫЕ СТИЛИ КНОПКИ НЕ РЕАЛИЗОВАНЫ. Это заглушка на будущее.
      // Глобальный стиль задается Контролом Глобальных стилей Кнопки.
      // содержит точно такой же набор стилевых параметров как и Индивидульный стиль Кнопки.
      // при выборе или переопределении (redefine) Глобального стиля Кнопки
      // помимо выставления имени Глобального стиля в модель Кнопки, происходит
      // полная замена значений индивидуальных стилевых параметров на глобальные.
      // если Кнопка имеет заданный Глобальный стиль, то на Кнопку навешивается css-класс
      // с id Глобального стиля и начнет применятся Глобальный стиль, сгенерированный
      // в <style/>
      // globalStyle: null, // id Глобального стиля.

      // Индивидуальный стиль Кнопки, всегда переписывающий Глобальный стиль css-каскадом,
      // т.к. имеет более специфичный css-селектор при генерации.
      // позволяет каждой Кнопке иметь Глобальный стиль и переписывать отдельные
      // стилевые параметры через Контрол настроек лично для себя.
      // «Default»
      'background-color': DEFAULT_BACKGROUND_COLOR, // shape color
      'background-color-opacity': 100,
      'border-radius': 5, // radius
      'border-width': 0, // border
      'border-color': '000000',
      'border-color-opacity': 100,

      'font-family': 'Arial',

      // Font Style
      'font-style': 'normal',
      'font-weight': 400,

      color: 'ffffff', // text-color,
      'color-opacity': 100,
      'font-size': 18, // aA
      'letter-spacing': 0, // A ←→ B

      // «Hover»
      // изменяться в Ховер-состоянии могут только эти аттрибуты стиля.
      // остальные аттрибуты стиля будут наследовать значение от Статик-состояния
      // при генерации CSS Кнопки на фронте — см. buttonutils.generateStylesStr() → function attr(s),
      // поэтому их незачем сохранять в модель.
      'hover-background-color': DEFAULT_BACKGROUND_COLOR, // shape color
      'hover-background-color-opacity': 80,

      'hover-border-width': 0,
      'hover-border-color': '000000',
      'hover-border-color-opacity': 100,

      'hover-color': 'ffffff', // text-color,
      'hover-color-opacity': 100,

      //"Current"
      'current-background-color': DEFAULT_BACKGROUND_COLOR,
      'current-background-color-opacity': 100,

      'current-border-radius': 5,
      'current-border-width': 0,
      'current-border-color': '000000',
      'current-border-color-opacity': 100,

      'current-font-family': 'Arial',

      'current-font-style': 'normal',
      'current-font-weight': 400,

      'current-color': 'ffffff',
      'current-color-opacity': 100,
      'current-font-size': 18,
      'current-letter-spacing': 0,

      // Иконка Кнопки
      // аттрибуты на верхнем уровне, чтобы удобней
      // было навешивать слушателей именно на то, что хочешь.

      icon_enabled: false,

      // высота Иконки — «Size» в Контроле Иконки.
      icon_h: 18,

      // ширина рассчитывается, отталкиваясь от высоты, с учетом пропорции
      // от оригинального SVG.
      icon_w: 18,

      // позиция Иконки. «Pos.» в Контроле Иконки.
      icon_pos: 'left', // || right

      // заготовка под параметр сдвига иконки.
      // 'icon_top': 0,
      // 'icon_right': 0,
      // 'icon_left': 0,
      // 'icon_bottom': 0,

      icon_noun_id: null,
      icon_rm_id: null,
      icon_noun_url: null,

      // цвет для SVG Иконки в Конструкторе.
      // по дефолту цвета Иконки должны быть такие же как и у текста,
      // чтобы в Конструкторе быть связанными cначала и менятся синхронно.
      // цвета Иконки вынесены из style, чтобы удобней было следить за их изменениями,
      // после которых может запустится растеризация SVG.
      icon_color: 'ffffff', // Icon Color
      icon_color_opacity: 100,
      'hover-icon_color': 'ffffff', // Icon Color
      'hover-icon_color_opacity': 100,
      'current-icon_color': 'ffffff', // Icon Color
      'current-icon_color_opacity': 100,

      // во Вьювере Иконка Кнопки — это .png-картинка.
      // при первом создании Кнопки дефолтная Иконка сразу отправится растеризоваться.
      icon_rasterUrl: null,
      icon_raster2xUrl: null,
      'hover-icon_rasterUrl': null,
      'hover-icon_raster2xUrl': null,
      'current-icon_rasterUrl': null,
      'current-icon_raster2xUrl': null,
    },
  }
);

export default ButtonBlock;
