/**
 * Конструктор для виджета iFrame
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockFrameClass from '../block-frame';
import BlockClass from '../block';
import embedutils from '../../common/embed-plugins';

const IframeBlock = BlockClass.extend(
  {
    name: 'Embed & Code',
    sort_index: 13, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'iframe',

    icon_color: 'black',

    iframe_controls: ['iframe_edit'],

    initialize: function(model, workspace) {
      this.mag = workspace.mag;

      this.frameClass = embedFrame;
      this.controls = _.union(this.iframe_controls, this.controls);

      this.initBlock(model, workspace);
    },

    onCreate: function() {
      this.showPanel();
    },

    // Подбирает подходящий плагин, исходя из введенного юзером текста
    configurePlugin: function(raw_code) {
      var PluginClass = embedutils.findPlugin(this.model.toJSON()),
        plugin;

      if (PluginClass) {
        plugin = new PluginClass({ block: this });

        return plugin;
      } else {
        return null;
      }
    },

    render: function() {
      BlockClass.prototype.render.apply(this, arguments);

      this.$el.addClass('iframe');
      this.$el.on('dblclick', this.onDoubleClick);

      // изначально отрисовываем виджет
      this.redraw(this.model, {
        skipTriggerRedraw: true,
        doNotRedrawPacksFrames: true,
        doNotRedrawBottomShiftLine: true,
      });
      this.triggerReady();
    },

    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      var $data,
        code,
        changed_attrs = this.model.changedAttributes(),
        only_moved_or_resized = false,
        need_redraw = true,
        code_changed = false,
        w,
        h,
        pluginClass,
        $container;

      BlockClass.prototype.redraw.apply(this, arguments);

      if (_.isObject(changed_attrs)) {
        only_moved_or_resized = _.without(_.keys(changed_attrs), 'x', 'y', 'z', 'w', 'h').length == 0;
        need_redraw = !only_moved_or_resized;
        code_changed = _.contains(_.keys(changed_attrs), 'code');
      }

      if (!need_redraw) {
        return;
      }

      this.$content.empty();
      this.$container = $('<div class="container"></div>'); // Внутри контейнера плагин будет рендерить свой контент
      this.$content.append(this.$container);
      this.$content.append($('<div class="overlay"></div>'));

      this.$el.removeAttr('data-plugin');

      code = model.get('code');

      this.plugin = null;

      if (code) {
        this.plugin = this.configurePlugin(code);

        if (!this.plugin) {
          return;
        }

        this.plugin.parse(
          code,
          function(parsed) {
            $data = this.plugin.sanitize(parsed); // Созданный элемент

            if ($data && $data.length) {
              // Пусть плагин сам себя отрисует и вернет нам размеры получившегося
              this.plugin.draw(
                $data,
                this.$container,
                function(w, h) {
                  this.$el.attr('data-plugin', this.plugin.embed_type);

                  if (code_changed || this.plugin.forceFrameSize) {
                    if (w && !this.isFullWidth()) {
                      model.set('w', w);
                    }
                    if (h && !this.isFullHeight()) {
                      model.set('h', h);
                    }
                  }

                  this.proportional = this.plugin.proportional;

                  if (this.plugin.resizable) {
                    this.$content.find(this.plugin.containerSelector).css({
                      width: '100%',
                      height: '100%',
                      'max-width': '',
                      'max-height': '',
                    });
                  }
                }.bind(this)
              );
            }
          }.bind(this)
        );
      }

      this.$el.toggleClass('empty', !code);
    },

    getIconStyle: function(options) {
      var res = {};
      var color_map = {
        pinterest: 'white',
        instagram: 'white',
        readymag: '#FF4828',
        fbvideo: '#3C5A99',
      };
      var path = RM_PUBLIC_PATH + 'img/constructor/widgetbar/icons/';
      var type = this.model.get('type');
      var embed_type = this.plugin && this.plugin.embed_type;
      var image = type + (embed_type ? '-' + embed_type || '' : '') + '.svg';
      var bg_color = embed_type ? color_map[embed_type] : null;

      if (options && options.small) {
        path += 'small/';
        res['background-color'] = bg_color || this.icon_color;
      }

      res['background-image'] = 'url(' + path + image + ')';
      return res;
    },

    showPanel: function() {
      this.openCodeEditor({ model: this.model });
    },

    hidePanel: function() {
      this.closeCodeEditor();
    },

    openCodeEditor: function(data) {
      RM.constructorRouter.openCodeEditor(data);
    },

    closeCodeEditor: function(data) {
      RM.constructorRouter.closeCodeEditor();
    },

    onDoubleClick: function(e) {
      if (!RM.constructorRouter.isCodeEditorVisible) {
        this.trigger('editCodeClick');
      }
    },

    deselect: function() {
      RM.constructorRouter.closeCodeEditor();
      BlockClass.prototype.deselect.apply(this, arguments);
    },

    destroy: function() {
      var withoutSaving = true;
      RM.constructorRouter.closeCodeEditor(withoutSaving);
      BlockClass.prototype.destroy.apply(this, arguments);
    },
  },
  {
    defaults: {
      use_iframe: true,
    },
  }
);

// На будущее, чтобы плагин мог реагировать в процессе ресайза
var embedFrame = BlockFrameClass.extend({
  customResizeHandler: function(box, resizePoint, resizeByMouse) {
    if (this.block.plugin && _.isFunction(this.block.plugin.customResizeHandler)) {
      box = this.block.plugin.customResizeHandler.apply(this, arguments);
    }
    return box;
  },
});

export default IframeBlock;
