/**
 * Класс для сервисных страниц, которые являются конечными,
 * и не предусматривают дальнейшей навигации по сайту
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/common/service-pages.tpl';
import { Constants } from './utils';

const ServicePage = Backbone.View.extend({
  TEMPLATES: {
    '404': 'template-common-404',
    'mag-suspended': 'template-common-mag-suspended',
    'user-suspended': 'template-common-user-suspended',
    'domain-error': 'template-common-domain-errors',
  },

  initialize: function(params) {
    _.bindAll(this);
    _.extend(this, params);

    this.router = this.router || RM.homepageRouter || RM.collectorRouter || RM.constructorRouter;
    this.template = templates[this.TEMPLATES[this.type]];
    this.setElement(this.$container);

    // if (this.router && this.router.analytics) {
    //	this.router.analytics.sendEvent('Alert Show', {
    //		'label': 'create mag ie',
    //		'_alert_source': 'create mag ie'
    //	});
    // }
  },

  render: function() {
    this.$el.html(this.template({ ...this.templateData, Constants }));
    this.postRenderTasks();
    return this;
  },

  postRenderTasks: function() {
    switch (this.type) {
      case 'domain-error':
        _.delay(
          _.bind(function() {
            this.$('.domain-errors').addClass('show-elements');
          }, this),
          1000
        );
        break;
    }
  },
});

export default ServicePage;
