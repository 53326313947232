module.exports = {["template-constructor-block-twitter-type_tweet"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<a href="https://twitter.com/share" class="twitter-share-button" data-url="'+
((__t=(url))==null?'':_.escape(__t))+
'" data-lang="en" data-size="'+
((__t=(large_button ? 'large' : 'medium'))==null?'':__t)+
'" data-text="'+
((__t=( typeof text !== 'undefined' ? text : ''))==null?'':_.escape(__t))+
'">Tweet</a>\n	<div class="overlay"></div>\n';
}
return __p;
},["template-constructor-block-twitter-type_follow"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<a href="https://twitter.com/'+
((__t=(username ? username : 'Readymag'))==null?'':_.escape(__t))+
'" class="twitter-follow-button" data-show-count="false" data-show-screen-name="'+
((__t=(show_name ? 'true' : 'false'))==null?'':__t)+
'" data-lang="en" data-size="'+
((__t=(large_button ? 'large' : 'medium'))==null?'':__t)+
'">Follow @'+
((__t=(username))==null?'':_.escape(__t))+
'</a>\n	<div class="overlay"></div>\n';
}
return __p;
},["template-constructor-block-twitter-type_hashtag"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<a href="https://twitter.com/intent/tweet?button_hashtag='+
((__t=(hash))==null?'':_.escape(__t))+
'" class="twitter-hashtag-button" data-size="'+
((__t=(large_button ? 'large' : 'medium'))==null?'':__t)+
'">Tweet #'+
((__t=(hash))==null?'':_.escape(__t))+
'</a>\n	<div class="overlay"></div>\n';
}
return __p;
},["template-constructor-block-twitter-type_timeline"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	'+
((__t=(code))==null?'':__t)+
'\n	<div class="overlay"></div>\n';
}
return __p;
},["template-constructor-block-twitter-type_embed"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	'+
((__t=(code))==null?'':__t)+
'\n	<div class="overlay"></div>\n';
}
return __p;
},}