/**
 * Патчинг _ как было сделано в utils.js
 */
import _ from 'underscore';

window._ = _;

// vmkcom: Возвращаем старую реализацию bindAll. Новая не позволяет использовать без списка функций
// Bind all of an object's methods to that object. Useful for ensuring that
// all callbacks defined on an object belong to it.
_.bindAll = function(obj) {
  var funcs = Array.prototype.slice.call(arguments, 1);
  if (funcs.length === 0) funcs = _.functions(obj);
  _.each(funcs, function(f) {
    obj[f] = _.bind(obj[f], obj);
  });
  return obj;
};

_.mixin({
  // Клонирует объект, вместе с клонами обьектов и массивов первого уровня вложенности
  // Реализаций deep clone нормальных по сей день никто не написал
  // JSON.parse(JSON.stringify(obj)) убивает даты
  // UPD: объекты во вложенных массивах он тоже клонирует! нужно для слайдшоу и для анимаций (т.е. в данном случае имеет место быть второй уровень вложенности)
  // UPD: функции тоже клонирует, используется в добавлении виджетов в воркспейсе (и во внутреннем воркспейсе хотспота)
  cloneWithObjects: function(obj) {
    obj = _.clone(obj);
    _.each(obj, function(val, key) {
      // клонируем обьекты массива
      if (_.isArray(val)) {
        var cloned = [];
        // опасный код, мы полагаемся на каноничное юзание массивов, в жизни все может быть иначе и индексы поудйт не с 0, и length может указывать совсем не то
        for (var i = 0; i < val.length; i++) cloned.push(_.clone(val[i]));
        obj[key] = cloned;
      }
      // клонируем объект (функция тоже объект, поэтому отдельно проверяем)
      else if (_.isObject(val) && !_.isFunction(val)) {
        obj[key] = _.clone(obj[key]);
      } else if (_.isFunction(val)) {
        obj[key] = obj[key];
      }
    });

    return obj;
  },

  // Проверяет включает ли в себя массив данных container массив данных data
  // _.isContainsData([{a: 1}, {b: 2}, {c: {d: 4}}],  [{b:2}, {c: {d: 4}}]) == true
  isContainsData: function(container, data) {
    return _.all(data, function(dataElement) {
      // Каждый элемент data

      return _.any(container, function(containerElement) {
        // Должен найтись среди элементов container

        return _.isEqual(containerElement, dataElement);
      });
    });
  },

  // получить список ключей obj2, которые отличаются / отсутствуют в obj1
  // _.objectDifference({a: 1, b: 3, viewport: {b: 2, c: 3}}, {a: 1, b:2, viewport: {b: 2, d: 4, c: 4}}) = {b: 2, viewport: {b: 2, d: 4, c: 4}}
  objectDifference: function(obj1, obj2) {
    var changes = {};

    _.each(obj2, function(value, key) {
      if (_.isObject(value) && !_.isArray(value)) {
        changes[key] = _.objectDifference(obj1[key], obj2[key]);
        if (_.isEmpty(changes[key]) && !_.isEmpty(obj2[key])) delete changes[key];
        else changes[key] = obj2[key];
        return;
      }

      if (!obj1 || !obj2 || !_.isEqual(obj1[key], obj2[key])) {
        changes[key] = obj2[key];
        return;
      }
    });

    return changes;
  },

  deepExtend: function(obj) {
    var parentRE = /#{\s*?_\s*?}/,
      source,
      isAssign = function(oProp, sProp) {
        return _.isUndefined(oProp) || _.isNull(oProp) || _.isFunction(oProp) || _.isNull(sProp) || _.isDate(sProp);
      },
      procAssign = function(oProp, sProp, propName) {
        // Perform a straight assignment
        // Assign for object properties & return for array members
        return (obj[propName] = _.clone(sProp));
      },
      hasRegex = function(oProp, sProp) {
        return _.isString(sProp) && parentRE.test(sProp);
      },
      procRegex = function(oProp, sProp, propName) {
        // Perform a string.replace using parentRE if oProp is a string
        if (!_.isString(oProp)) {
          // We're being optimistic at the moment
          // throw new Error('Trying to combine a string with a non-string (' + propName + ')');
        }
        // Assign for object properties & return for array members
        return (obj[propName] = sProp.replace(parentRE, oProp));
      },
      hasArray = function(oProp, sProp) {
        return _.isArray(oProp) || _.isArray(sProp);
      },
      procArray = function(oProp, sProp, propName) {
        // extend oProp if both properties are arrays
        if (!_.isArray(oProp) || !_.isArray(sProp)) {
          throw new Error('Trying to combine an array with a non-array (' + propName + ')');
        }
        var tmp = _.deepExtend(obj[propName], sProp);
        // Assign for object properties & return for array members
        return (obj[propName] = _.reject(tmp, _.isNull));
      },
      hasObject = function(oProp, sProp) {
        return _.isObject(oProp) || _.isObject(sProp);
      },
      procObject = function(oProp, sProp, propName) {
        // extend oProp if both properties are objects
        if (!_.isObject(oProp) || !_.isObject(sProp)) {
          throw new Error('Trying to combine an object with a non-object (' + propName + ')');
        }
        // Assign for object properties & return for array members
        return (obj[propName] = _.deepExtend(oProp, sProp));
      },
      procMain = function(propName) {
        var oProp = obj[propName],
          sProp = source[propName];

        // The order of the 'if' statements is critical

        // Cases in which we want to perform a straight assignment
        if (isAssign(oProp, sProp)) {
          procAssign(oProp, sProp, propName);
        }
        // sProp is a string that contains parentRE
        else if (hasRegex(oProp, sProp)) {
          procRegex(oProp, sProp, propName);
        }
        // At least one property is an array
        else if (hasArray(oProp, sProp)) {
          // procArray(oProp, sProp, propName);
          procAssign(oProp, sProp, propName); // Мы хотим заменять массивы один на другой, а не мержить их содержимое
        }
        // Both properties are objects
        else if (_.isObject(oProp) && _.isObject(sProp)) {
          procObject(oProp, sProp, propName);
        }
        // Everything else
        else {
          // Let's be optimistic and perform a straight assignment
          procAssign(oProp, sProp, propName);
        }
      },
      procAll = function(src) {
        source = src;
        Object.keys(source).forEach(procMain);
      };

    _.each(Array.prototype.slice.call(arguments, 1), procAll);

    return obj;
  },
});

export default _;
