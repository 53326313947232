/**
 * Добавление полей для виджета Form
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import ControlResizableClass from '../control-resizable';
import { Utils } from '../../common/utils';
import Controls from './';
import templates from '../../../templates/constructor/controls/form_content.tpl';

// Вынесем некоторые константы из вью, потому что они используются и во вью контрола, и во вью панелии дропдауна
var DEFAULT_INPUT_PLACEHOLDER = 'Field Text';

var PLACEHOLDERS = {
  text: 'Text',
  number: 'Number',
  name: 'Name',
  email: 'Email',
  phone: 'Phone number',
  dropdown: 'Dropdown',
  dropdownEditMode: 'Edit dropdown',
  submit: 'Text',
  textAfterSubmit: 'Text after submit',
  toUrlAfterSubmit: 'URL',
  toPageAfterSubmit: 'Jump to page',
  option: 'Edit option',
  checkbox: 'Checkbox',
};

/**
 * Возвращает текст инпута, который будет использоваться в шаблоне
 * @param {String} type
 * @param {String} caption
 * @returns {String}
 */
var getTemplateInputText = function(type, caption) {
  // Если у поля нет кэпшена или он такой же, как кэпшен по умолчанию, в инпуте в контроле не должно быть текста (только плейсхолдер)
  return !caption || isDefaultCaption(type, caption) ? null : caption;
};

/**
 * Определяет, дефолтовый это кэпшн или нет
 * @param {String} type
 * @param {String} caption
 * @returns {Boolean}
 */
var isDefaultCaption = function(type, caption) {
  var lowercaseCaption = caption && caption.toLowerCase();
  var isLikeTypePlaceholder = lowercaseCaption === getPlaceholder(type).toLowerCase();
  // Для дропдаунов замороченная логика из-за того, что в общем списке полей мы пишем "dropdown", а в панели — edit dropdown
  if (type === 'dropdown' || type === 'dropdownEditMode') {
    isLikeTypePlaceholder =
      lowercaseCaption === getPlaceholder('dropdown').toLowerCase() ||
      lowercaseCaption === getPlaceholder('dropdownEditMode').toLowerCase();
  }
  return isLikeTypePlaceholder || lowercaseCaption === DEFAULT_INPUT_PLACEHOLDER.toLowerCase();
};

/**
 * Возвращает текст, который будет использоваться как плейсхолдер в шаблоне и как кэпшен для инпута по умолчанию
 * @param {String} type
 * @returns {String}
 */
var getPlaceholder = function(type) {
  return PLACEHOLDERS[type] || DEFAULT_INPUT_PLACEHOLDER;
};

var debounceOrImmediate = function(callback, delay) {
  var debounced = _.debounce(callback, delay || 300);
  return function(event) {
    if (event.keyCode === 13) {
      callback(event);
      $(event.target).blur();
    } else {
      debounced(event);
    }
  };
};

var _super = ControlResizableClass.prototype;

const FormContent = ControlResizableClass.extend({
  name: 'form_content', // должно совпадать с классом вьюхи
  className: 'control form_content',

  events: {
    // !!! добавить в onPanelClick закрытие дропдауна after submit
    'click .js-panel': 'onPanelClick',
    'click .js-add': 'onAddClick',
    'click .js-field-type': 'onFieldTypeClick',
    'click .js-submit-field-type': 'onSubmitFieldClick',
    'click .js-dropdown-trigger': 'onDropdownTriggerClick',
    'click .js-settings-trigger': 'onSettingsTriggerClick',
    'click .js-delete-field': 'onDeleteFieldClick',
    'keydown .js-field-caption': 'onFieldCaptionKeydown',
    'click .js-submit-dropdown-trigger': 'onSubmitArrowClick',
    'click .action-icon:not(.remove-icon)': 'onActionIconClick',
    'click .remove-icon': 'onRemoveIconClick',
    'click .search-wrapper .placeholder-selector .placeholder-item': 'clickPlaceholder',
    'focus .form-fields-caption-submitAfter.toPageAfterSubmit ': 'changeAfterSubmitToPageInput',
    'focusout .form-fields-caption-submitAfter.toPageAfterSubmit ': 'changeAfterSubmitToPageInput',
  },

  MIN_PANEL_HEIGHT: 350,
  MAX_PANEL_HEIGHT: 504, // Как панель контрола стилей формы

  SCROLL_GAP_START: 4,
  SCROLL_GAP_END: 4,

  // Показываются в попапе в этом же порядке
  FIELD_TYPES: {
    text: 'Text',
    number: 'Number',
    name: 'Name',
    email: 'Email',
    phone: 'Phone Number',
    dropdown: 'Dropdown',
    checkbox: 'Checkbox',
  },

  SUBMIT_FIELD_TYPES: {
    textAfterSubmit: 'Text after submit',
    toUrlAfterSubmit: 'Website URL',
    toPageAfterSubmit: 'Jump to Page',
  },

  SUBMIT_MODES: {
    textAfterSubmit: 'textAfterSubmit',
    toUrlAfterSubmit: 'toUrlAfterSubmit',
    toPageAfterSubmit: 'toPageAfterSubmit',
  },

  PAGE_AFTER_SUBMIT_SHORT_PLACEHOLDER: 'Jump to p.',

  saveOnDestroy: true,
  saveOnDeselect: true,

  /**
   * Маппинг свойства sort на id, который используется в html, {sort: id}
   * @type {Object.<String, String>}
   */
  idMap: {},

  /**
   * Вью с панелями дропдауна, {id: view}
   * @type {Object.<String, Backbone.View>}
   */
  dropdownPanels: {},

  /**
   * Список моделей для панели дропдауна
   * @type {Array<Backbone.Model>}
   */
  dropdownModels: [],

  initialize: function(params) {
    this.template = templates['template-constructor-control-form_content'];
    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;
    _.bindAll(this);
  },

  bindLogic: function() {
    this.listenTo(this.model, 'field:add', this.onFieldsChange);
    this.listenTo(this.model, 'field:delete', this.onFieldsChange);
    this.listenTo(this.model, 'field:tp:change', this.onFieldTypeChange);
    this.listenTo(this.model, 'field:caption:change', this.onFieldCaptionChange);

    this.updateCaptionDebounced = debounceOrImmediate(this.updateCaption.bind(this), 300);
  },

  unBindLogic: function() {
    this.stopListening(this.model);
  },

  render: function() {
    _super.render.apply(this, arguments);

    this.$content = $(this.$el.find('.js-fields-container'));
    this.$settingsPopupContainer = this.$el.find('.js-settings-popup-container');
    this.$dropdownPanelContainer = this.$el.find('.js-dropdown-panel-container');
    this.$addBlock = this.$el.find('.js-add-block');
    this.$scrollable = this.$el.find('.resizable-content-wrapper');

    // Сортировка
    this.$content.sortable({
      items: '.js-sortable',
      distance: 10,
      axis: 'y',
      scrollSpeed: 10,
      containment: 'parent',
      tolerance: 'pointer',
      start: this.onSortStart.bind(this),
      stop: this.onSortEnd.bind(this),
    });

    this.renderContent();
    // Сразу после рендера выставим минимальную высоту панели, чтобы перед показом панели адаптировать высоту, если нужно.
    this.updatePanelHeight(this.MIN_PANEL_HEIGHT);

    this.$addBlock.append(
      this.renderSettingsPopup({
        edit: false,
      })
    );

    this.$addSettingsPopup = this.$el.find('.js-add-settings');
    this.$submitPopup = this.$el.find('.submit-wrapper');

    if (this.isPageMode()) {
      this.changeAfterSubmitToPageInput();
    }
  },

  select: function() {
    _super.select.apply(this, arguments);
    // Адаптируем высоту под контент
    this.resizeToFit();
  },

  /**
   * Рендерит и помещает в dom поля и всё что связано с ними: попапы, дропдауны
   */
  renderContent: function() {
    this.$content.empty().append(this.renderFields());
    // Обновим сортировку после рендера полей
    this.refreshSort();
    // Обновим скролл
    this.resizableScroll && this.resizableScroll.recalc();

    // Рендерим попапы для полей в другом контейнере, чем сами поля,
    // чтобы попапы не обрезались контейнером c overflow: hidden, в котором находятся поля.
    this.$settingsPopupContainer.empty().append(this.renderSettingsPopups());
    this.$addBlock.append(this.renderSubmitPopup());
    this.$dropdownPanelContainer.empty().append(this.renderDropdowns());
  },

  renderFields: function() {
    var $fields = $(document.createDocumentFragment());
    this.idMap = {};

    _.each(
      this.model.get('fields'),
      function(field, index, fields) {
        // Свяжем поле и попап через сгенерированный id
        var id = 'field-' + Utils.generateUUID();
        this.idMap[field.sort] = id;

        // Поле
        var fieldHtml = this.renderField(
          _.extend(
            {
              id: id,
              isOnly: fields.length === 1,
            },
            field
          )
        );
        $fields.append(fieldHtml);
      }.bind(this)
    );

    $fields.append('<div class="button-header">Button</div>');
    // Кнопка submit
    var buttonHtml = this.renderField({
      id: null,
      caption: this.model.get('button-caption'),
      tp: 'submit',
    });
    $fields.append(buttonHtml);
    // текст на кнопке после submit
    var buttonAfterSubmitHtml = this.renderField({
      id: null,
      caption: this.model.get('button_caption_after_submit'),
      tp: 'submitAfter',
    });
    $fields.append(buttonAfterSubmitHtml);

    return $fields;
  },

  renderField: function(field) {
    var template = templates['template-constructor-control-form_content-field'];

    return template({
      tp: field.tp,
      typeLetter: field.tp === 'number' ? '№' : field.tp[0],
      text: getTemplateInputText(field.tp === 'submitAfter' ? this.model.get('submit_mode') : field.tp, field.caption),
      sort: field.sort,
      id: field.id,
      placeholder: getPlaceholder(field.tp === 'submitAfter' ? this.model.get('submit_mode') : field.tp),
      showSettings: field.tp !== 'submit' && field.tp !== 'submitAfter',
      showDelete: field.tp !== 'submit' && !field.isOnly && field.tp !== 'submitAfter',
      isSortable: field.tp !== 'submit' && field.tp !== 'submitAfter',
      showSubmitAfterArrow: field.tp === 'submitAfter',
      mode: field.tp === 'submitAfter' ? this.model.get('submit_mode') : '',
      showRemoveArrow:
        this.model.get('submit_mode') !== this.SUBMIT_MODES.textAfterSubmit && this.model.get('link_after_submit'),
    });
  },

  reRenderField: function(id, previousType) {
    var fields = this.model.get('fields');
    var predicate = this.getPredicate(id);
    var field = this.getField(predicate);
    var $fieldElement = this.$el.find('.js-field[data-id=' + id + ']');

    var fieldHtml = this.renderField(
      _.extend(
        {
          id: id,
          isOnly: fields.length === 1,
        },
        field
      )
    );
    $fieldElement.replaceWith(fieldHtml);
    // Обновим сортировку после ре-рендера
    this.refreshSort();
    // Перерендерим дропдауны, если тип поменялся на дропдаун или с дропдауна на что-то другое
    if (field.tp === 'dropdown' || previousType === 'dropdown') {
      this.$dropdownPanelContainer.empty().append(this.renderDropdowns());
    }
  },

  renderSettingsPopups: function() {
    // Попап с настройками
    var $popups = $(document.createDocumentFragment());
    _.each(
      this.model.get('fields'),
      function(field) {
        var id = this.idMap[field.sort];
        var html = this.renderSettingsPopup({
          showOptionalSwitcher: true,
          selectedType: field.tp,
          edit: true,
          optional: field.optional,
          id: id,
        });
        $popups.append(html);
      }.bind(this)
    );

    return $popups;
  },

  renderSettingsPopup: function(settings) {
    var template = templates['template-constructor-control-form_content-settings'];
    settings = _.extend(
      {
        types: this.FIELD_TYPES,
        showOptionalSwitcher: false,
        selectedType: null,
        edit: false,
        id: null,
        sort: null,
        _,
      },
      settings
    );

    var html = template(settings);
    var $html = $(html);

    var $switcher = $($html.find('.switcher'));
    if ($switcher.length) {
      $switcher.RMSwitcher(
        {
          state: settings.optional,
          height: 26,
          width: 44,
          'color-0': '#0078ff',
          'color-1': '#c9c8c9',
          'text-color-0': 'transparent',
          'text-color-1': 'transparent',
          id: settings.id,
        },
        this.onSwitcherChange.bind(this)
      );
    }
    return $html;
  },

  renderSubmitPopup: function(settings) {
    var template = templates['template-constructor-control-form_content-after-submit'];
    settings = _.extend(
      {
        types: this.SUBMIT_FIELD_TYPES,
        selectedType: this.model.get('submit_mode') || this.SUBMIT_MODES.textAfterSubmit,
        edit: false,
        id: null,
        sort: null,
        _,
      },
      settings
    );

    var html = template(settings);
    var $html = $(html);
    return $html;
  },

  renderDropdowns: function() {
    var $dropdowns = $(document.createDocumentFragment());
    var dropdowns = _.where(this.model.get('fields'), { tp: 'dropdown' });
    this.destroyDropdowns();

    _.each(
      dropdowns,
      function(field) {
        var id = this.idMap[field.sort];
        var dropdownPanel = this.renderDropdown(field);
        // Закэшируем вью с панелью дропдауна
        this.dropdownPanels[id] = dropdownPanel;
        $dropdowns.append(dropdownPanel.el);
      }.bind(this)
    );
    return $dropdowns;
  },

  renderDropdown: function(dropdown) {
    // Будем работать с копией поля, а иначе вложенный вью панели дропдауна постоянно будет по-тихому изменять поле в основной модели с формой.
    var model = new Backbone.Model(_.clone(dropdown));
    this.dropdownModels.push(model);

    // Подпишемся на изменения этой модели чтобы обновлять основную модель
    this.listenTo(model, 'change:caption', this.onDropdownCaptionChange);
    this.listenTo(model, 'change:items', this.onDropdownItemsChange);
    this.listenTo(model, 'change:height', this.onDropdownHeightChange);
    this.listenTo(model, 'save', this.onDropdownSave);

    var dropdownPanel = new DropdownPanel({ model: model });
    dropdownPanel.render();
    return dropdownPanel;
  },

  onSortStart: function() {
    this.hideSettingsPopups();
    this.$el.find(':input').blur();
    this.$el.addClass('grabbing');
  },

  onSortEnd: function() {
    this.$el.removeClass('grabbing');
    this.reorder();
    // Уберём стили, которые проставляет jquery sortable: из-за них случается странный эффект:
    // если снова взять перемещённое поле, то оно как бы прилетает сверху.
    this.$el.find('.js-field').attr('style', '');
  },

  onScrollRecalc: function() {
    // При пересчёте скролла в основной панели контрола пересчитаем скролл и всех панелей дропдаунов
    _.each(this.dropdownPanels, function(panel) {
      panel.resizableScroll && panel.resizableScroll.recalc();
    });
  },

  onSwitcherChange: function(state, switcher) {
    var id = switcher.settings.id;
    var predicate = this.getPredicate(id);
    this.block.changeField(predicate, { optional: state });
  },

  onPanelClick: function(event) {
    // Если это не клик внутри попапа с настройками и не по триггеру этого попапа, закрыть попап
    if (
      !$(event.target).closest('.js-settings-trigger, .js-add, .js-settings-popup, .js-submit-dropdown-trigger').length
    ) {
      this.hideSettingsPopups();
    }
  },

  onAddClick: function() {
    this.toggleSettingsPopup(this.$addSettingsPopup);
  },

  onFieldTypeClick: function(event) {
    event.stopPropagation();

    var $type = $(event.target).closest('.js-field-type');
    var type = $type.data('value');
    var action = $type.data('action');

    // Добавление
    if (action === 'add') {
      this.addField({ tp: type });
      this.hideSettingsPopups();
      this.scrollToBottom();

      if (this.isPageMode()) {
        this.changeAfterSubmitToPageInput();
      }
      // Изменение
    } else {
      var predicate = this.getPredicate(event.target);
      this.changeFieldType(predicate, type);
    }
  },

  onSubmitFieldClick: function(e) {
    e.stopPropagation();
    var mode = $(event.target).data('value');

    if (mode !== this.model.get('submit_mode')) {
      this.setAfterSubmitMode(mode);
      this.reRenderAfterSubmit(mode);
    }
    this.hideSettingsPopups();
  },

  isPageMode: function() {
    return this.model.get('submit_mode') && this.model.get('submit_mode') === this.SUBMIT_MODES.toPageAfterSubmit;
  },

  reRenderAfterSubmit: function(mode) {
    var $submitInput = this.$el.find('.form-fields-item-submitAfter'),
      field = {
        tp: 'submitAfter',
        selectedType: mode,
      },
      fieldHtml = this.renderField(_.extend(field));

    this.$el.find('.js-submit-field-type').removeClass('selected');
    this.$el.find('.js-submit-field-type[data-value=' + mode + ']').addClass('selected');
    $submitInput.replaceWith(fieldHtml);
  },

  changeAfterSubmitToPageInput: function(e) {
    var $submitInput = e ? $(e.currentTarget) : this.$el.find('.js-field-caption-submitAfter'),
      $oldVal = $submitInput.val(),
      placeholder = this.PAGE_AFTER_SUBMIT_SHORT_PLACEHOLDER,
      // необходимо всегда отправлять очищенное без 'Jump to page' значение
      $purifiedVal = $oldVal.indexOf(placeholder) !== -1 ? $.trim($oldVal.replace(placeholder, '')) : $oldVal;

    if ($oldVal.indexOf(placeholder) === -1 && this.getUrlData($purifiedVal).result) {
      $submitInput.val(placeholder + ' ' + $oldVal);
    } else {
      $submitInput.val($purifiedVal);
    }
  },

  // проверка на урл или номер страницы которая есть в меге
  getUrlData: function(text) {
    var mode = this.model.get('submit_mode'),
      mag = RM.constructorRouter.mag,
      checkInput = Controls.text_link.prototype.getUrlData(text);

    text = checkInput.url;

    if (checkInput.isValidPageNumber) {
      text = mag.getPageId(text);
    }

    return {
      result:
        (checkInput.isURL && mode === this.SUBMIT_MODES.toUrlAfterSubmit) ||
        (checkInput.isValidPageNumber && this.isPageMode()),
      url: text,
    };
  },

  setAfterSubmitMode: function(mode) {
    this.model.set('submit_mode', mode);
  },

  onDropdownTriggerClick: function(event) {
    var id = $(event.target)
      .closest('[data-id]')
      .data('id');
    var dropdownPanel = this.dropdownPanels[id];
    this.hideSettingsPopups();
    dropdownPanel && dropdownPanel.show();
  },

  onSettingsTriggerClick: function(event) {
    var $target = $(event.target);
    var id = $target.closest('[data-id]').data('id');
    var $popup = this.$el.find('.js-settings-popup[data-id=' + id + ']');
    // Вычислим позицию попапа
    var popupTop = $target.offset().top - this.$scrollable.offset().top + $target.height() / 2;
    var popupBottom = this.$scrollable.height() - popupTop;
    this.toggleSettingsPopup($popup, { bottom: popupBottom });
  },

  onDeleteFieldClick: function(event) {
    var predicate = this.getPredicate(event.target);

    this.deleteField(predicate);

    if (this.isPageMode()) {
      this.changeAfterSubmitToPageInput();
    }
  },

  onFieldCaptionKeydown: function(event) {
    // При нажатии enter сразу обновим caption в модели и закончим редактирование, в остальных случаях обновим caption раз в интервал
    this.updateCaptionDebounced(event);
  },

  updateCaption: function(event) {
    var $target = $(event.target),
      predicate = this.getPredicate($target),
      caption = $target.val(),
      $field = $target.closest('.js-field'),
      type = $field.data('type');

    // при отсутствии caption'a текст берется из PLACEHOLDERA
    // Кнопка
    if (type === 'submit') {
      this.changeButton(caption ? caption : getPlaceholder('submit'));
    } else if (type === 'submitAfter') {
      // при отсутствии caption'a показывается галочка
      this.changeButtonAfterSubmit(caption ? caption : '');
      if (this.model.get('submit_mode') !== this.SUBMIT_MODES.textAfterSubmit) {
        this.updateClasses(caption);
      }
      // при вводе несуществующих страниц сразу сбрасываем значение в моделе
      if (this.isPageMode() && !this.getUrlData(caption).result) {
        this.changeButtonAfterSubmit('');
        this.model.set('link_after_submit', '');
      }
      // Обычные инпуты
    } else {
      var field = this.getField(predicate);
      caption = caption ? caption : getPlaceholder(field.tp);
      this.block.changeField(predicate, { caption: caption });
    }
  },

  updateClasses: function(val) {
    var $arrowContainer = this.$el.find('.form-fields-placeholder-selector');
    if ($arrowContainer.hasClass('remove-icon')) {
      $arrowContainer.removeClass('remove-icon');
    }
    $arrowContainer.toggleClass('js-submit-dropdown-trigger', !this.getUrlData(val).result);
    $arrowContainer.toggleClass('action-icon', this.getUrlData(val).result);
  },

  onActionIconClick: function() {
    var $arrowContainer = this.$el.find('.form-fields-placeholder-selector'),
      linkAfterSubmit = this.model.get('button_caption_after_submit');

    $arrowContainer.addClass('remove-icon');
    this.model.set('link_after_submit', this.getUrlData(linkAfterSubmit).url);
  },

  onRemoveIconClick: function() {
    var $arrowContainer = this.$el.find('.form-fields-placeholder-selector'),
      $submitInput = this.$el.find('.form-fields-caption-submitAfter');
    $arrowContainer.removeClass('action-icon remove-icon').addClass('js-submit-dropdown-trigger');
    $submitInput.val('');
    this.changeButtonAfterSubmit('');
    this.model.set('link_after_submit', '');
  },

  onFieldsChange: function() {
    this.renderContent();
  },

  onFieldTypeChange: function(field, previousType) {
    var id = this.idMap[field.sort];
    var $popup = this.$el.find('.js-settings-popup[data-id=' + id + ']');

    // Подсветим выбранный тип.
    $popup.find('.js-field-type').removeClass('selected');
    $popup.find('.js-field-type[data-value=' + field.tp + ']').addClass('selected');

    // Ре-рендерим поле
    this.reRenderField(id, previousType);
  },

  onFieldCaptionChange: function(field) {
    // Для дропдауна придётся обновлять текст в dom вручную
    if (field.tp === 'dropdown') {
      var id = this.idMap[field.sort];
      var $field = this.$el.find('.js-field[data-id=' + id + ']');
      $field.find('.js-field-caption').val(getTemplateInputText(field.tp, field.caption));
    }
  },

  onDropdownItemsChange: function(dropdownModel, items) {
    this.block.changeField({ sort: dropdownModel.get('sort') }, { items: _.clone(items) });
  },

  onDropdownHeightChange: function(delta) {
    // Если нужно увеличить высоту панели, увеличим.
    // Если нужно уменьшить, то сначала проверим, будут ли помещаться поля после уменьшения. Если будут, то уменьшим.
    if (delta > 0 || (delta < 0 && this.getContentOverflow(delta) <= 0)) {
      this.updatePanelHeight(this.$panel.height() + delta);
    }
  },

  onDropdownCaptionChange: function(dropdownModel, caption) {
    // Изменим кэпшен дропдауна, убедившись что кэпшен не пустой
    this.block.changeField({ sort: dropdownModel.get('sort') }, { caption: caption || getPlaceholder('dropdown') });
  },

  onDropdownSave: function(dropdownAttributes) {
    var changes = {
      caption: dropdownAttributes.caption,
      items: _.clone(dropdownAttributes.items),
    };
    this.block.changeField({ sort: dropdownAttributes.sort }, changes);
    this.save();
  },

  onPanelScroll: function() {
    this.hideSettingsPopups();
  },

  toggleSettingsPopup: function($popup, positon) {
    if ($popup.is('.shown')) {
      this.hideSettingsPopup($popup);
    } else {
      this.showSettingsPopup($popup, positon);
    }
  },

  showSettingsPopup: function($popup, position) {
    this.hideSettingsPopups();
    // Если указана позиция, выставим её перед тем как показывать попап
    if (!_.isUndefined(position)) {
      $popup.css(position);
      // Уберём класс чтобы не мешал определить расположение попапа по умолчанию.
      $popup.removeClass('below');
      // Если не помещается — выходит за верхний край окна, покажем внизу
      if ($popup[0].getBoundingClientRect().top < 0) {
        $popup.addClass('below');
      }
    }
    $popup.addClass('shown');
  },

  hideSettingsPopup: function($popup) {
    $popup.removeClass('shown');
  },

  hideSettingsPopups: function() {
    this.hideSettingsPopup(this.$el.find('.js-settings-popup'));
  },

  hasSettingsPopupsOpen: function() {
    return Boolean(this.$el.find('.js-settings-popup.shown').length);
  },

  onSubmitArrowClick: function(e) {
    var $target = $(e.target),
      popupTop = $target.offset().top - this.$scrollable.offset().top + 1.5 * $target.height(),
      popupBottom = this.$scrollable.height() - popupTop;

    this.toggleSettingsPopup(this.$submitPopup, { bottom: popupBottom });
  },

  hideDropdownPanels: function() {
    _.each(this.dropdownPanels, function(panel) {
      panel.hide();
    });
  },

  hasDropdownPanelsOpen: function() {
    return Boolean(
      _.find(this.dropdownPanels, function(panel) {
        return panel.isShown();
      })
    );
  },

  /**
   * Возвращает ключ-значение, по которым можно идентифицировать поле формы в модели в массиве fields.
   * @param {jQuery|HTMLElement|String} element Триггер события (например, кнопка), поле или попап, или id (который используется в html)
   * @returns {Object}
   */
  getPredicate: function(element) {
    // Если передан id, используем его. Если передан элемент, поищем ближайший к нему элемент с id
    var id = _.isString(element)
      ? element
      : $(element)
          .closest('[data-id]')
          .data('id');
    // Зная id, который используется в html, найдём sort
    var sort = _.invert(this.idMap)[id];
    return {
      sort: sort ? parseInt(sort) : sort,
    };
  },

  addField: function(data) {
    // Если не использовать clone и просто получать массив полей, то в методе model.set не стриггерится события change:fields и change,
    // потому что массив уже будет изменён после пуша в него нового поля.
    var fields = _.clone(this.model.get('fields'));
    var sort = _.max(fields, 'sort').sort || 0;
    var field = {
      tp: data.tp,
      caption: getPlaceholder(data.tp),
      optional: false,
      sort: sort + 1,
    };
    if (field.tp === 'dropdown') {
      field.items = this.getDefaultDropdownItems();
    }
    fields.push(field);
    this.model.set('fields', fields);
    // Кастомное событие, используется в контроле (может использоваться в блоке формы, наверное)
    this.model.trigger('field:add', field);
    _.defer(
      function() {
        this.block.trigger('resize');
      }.bind(this)
    );
  },

  changeField: function(predicate, changes) {
    // Если не использовать clone и просто получать массив полей, то в методе model.set не стриггерится события change:fields и change
    // потому что массив будет измененён до вызова model.set
    var fields = _.clone(this.model.get('fields'));
    var index = _.findIndex(fields, predicate);
    var field = fields[index];

    // Запомним предыдущие значения чтобы передать их при вызове кастомных событий
    var previousValues = {};
    _.each(changes, function(value, key) {
      previousValues[key] = _.clone(field[key]);
    });

    // Склонируем изменяемое поле по той же причине, что и выше (массив мы склонировали, а элементы в нём — нет)
    field = _.extend(_.clone(field), changes);
    // Если опции обнулены, удалим их совсем
    if (field.items === null) {
      delete field.items;
    }
    fields[index] = field;
    this.model.set('fields', fields);

    // Кастомные события вида field:caption:change, указывающее, что именно изменилось в поле.
    _.each(
      changes,
      function(value, key) {
        // Передадим предыдущее значение свойства поля: например, его желательно знать при смене типа поля на дропдаун и обратно
        this.model.trigger('field:' + key + ':change', field, previousValues[key]);
      }.bind(this)
    );
    // Общее кастомное событие по аналогии с field:add и field:delete
    this.model.trigger('field:change', field);
  },

  changeFieldType: function(predicate, newType) {
    var field = this.getField(predicate);
    // Будем менять поле только если тип действительно изменился
    if (field && field.tp !== newType) {
      var changes = { tp: newType };
      // Если у поля был дефолтовый кэпшн, поменяем его тоже
      if (isDefaultCaption(field.tp, field.caption)) {
        changes.caption = getPlaceholder(newType);
      }
      // Если тип меняется на dropdown, добавим две дефолтовые опции
      if (newType === 'dropdown') {
        changes.items = this.getDefaultDropdownItems();
        // Если тип меняется с дропдауна на другой тип, обнулим опции
      } else if (field.tp === 'dropdown') {
        changes.items = null;
      }
      this.block.changeField(predicate, changes);
    }
  },

  getField: function(predicate) {
    var field = _.find(this.model.get('fields'), predicate);
    return field ? _.clone(field) : field;
  },

  getDefaultDropdownItems: function() {
    return [getPlaceholder('option'), getPlaceholder('option')];
  },

  changeButton: function(caption) {
    this.model.set('button-caption', caption);
  },

  changeButtonAfterSubmit: function(caption) {
    this.model.set('button_caption_after_submit', caption);
  },

  deleteField: function(predicate) {
    var fields = this.model.get('fields');
    // Удалять только если это не последнее поле
    if (fields.length > 1) {
      fields = _.reject(fields, predicate);
      this.model.set('fields', fields);
      // Кастомное событие, используется в контроле
      this.model.trigger('field:delete', predicate);
    }
  },

  reorder: function() {
    // Поля в новом порядке
    var reorderedFields = [];
    // Маппинг новой сортировки на внутренние id полей {newSort: id}
    var newIdMap = {};

    // Обновим свойство sort у полей
    _.each(
      this.$content.find('.js-sortable'),
      function(element, newSort) {
        var predicate = this.getPredicate(element);
        var field = this.getField(predicate);
        // Ремаппинг
        newIdMap[newSort] = this.idMap[field.sort];
        field.sort = newSort;
        reorderedFields.push(field);
      }.bind(this)
    );

    // Обновим модель, чтобы сортировка отразилась в блоке конструктора
    this.model.set('fields', reorderedFields);

    // У себя сделаем ремаппинг внутренних id полей — кажется, этого достаточно. Ре-рендерить не нужно.
    this.idMap = newIdMap;

    // Дропдауны придётся ре-рендерить (ну или хитро обновлять), потому что в их моделях есть старое значение поля sort
    this.$dropdownPanelContainer.empty().append(this.renderDropdowns());

    this.model.trigger('fields:reorder');
  },

  refreshSort: function() {
    $(this.$content).sortable('refresh');
  },

  scrollToBottom: function() {
    this.$scrollable.animate({ scrollTop: this.$content.height() - this.$scrollable.height() });
  },

  getContentOverflow: function(delta) {
    return this.$content.height() - (this.$content.parent().height() + (delta || 0));
  },

  resizeToFit: function() {
    // Соберём все overflow и адаптируем высоту панели под самый большой из них
    var overflows = [];
    overflows.push(this.getContentOverflow());
    _.each(this.dropdownPanels, function(panel) {
      overflows.push(panel.getContentOverflow());
    });
    var maxOverflow = Math.max.apply(null, overflows);
    this.updatePanelHeight(this.$panel.height() + maxOverflow);
  },

  /**
   * Разрешает или не разрешает закрывать панель контрола
   */
  canControlBeClosed: function() {
    // Если есть открытые попапы, сначала закроем их
    if (this.hasSettingsPopupsOpen()) {
      this.hideSettingsPopups();
      return true;
      // Панели дропдаунов не закрываем отдельно — закрываем вместе с панелью
      // Если нет открытых попапов, можно скрывать панель контрола
    } else {
      return _super.canControlBeClosed.apply(this, arguments);
    }
  },

  save: function() {
    this.model.save({});
  },

  destroyDropdowns: function() {
    _.each(this.dropdownPanels, function(dropdownPanel) {
      dropdownPanel.destroy();
    });
    this.dropdownPanels = {};

    _.each(
      this.dropdownModels,
      function(model) {
        this.stopListening(model);
      }.bind(this)
    );
    this.dropdownModels = [];
  },

  destroy: function() {
    this.destroyDropdowns();
    _super.destroy.apply(this, arguments);
  },
});

/**
 * Вью, отвечающий за панель редактирования дропдауна
 * @type {Backbone.View}
 */
var DropdownPanel = Backbone.View.extend({
  SCROLL_GAP_START: 4,
  SCROLL_GAP_END: 4,

  originalState: null,

  events: {
    'click .js-add-option': 'onAddOptionClick',
    'click .js-delete-option': 'onDeleteOptionClick',
    'click .js-cancel-dropdown': 'onCancelDropdownClick',
    'click .js-save-dropdown': 'onSaveDropdownClick',
    'keydown .js-dropdown-caption': 'onCaptionKeydown',
    'keydown .js-dropdown-option-value': 'onOptionKeydown',
  },

  initialize: function(params) {
    this.model = params.model;
    this.template = templates['template-constructor-control-form_content-dropdown'];
  },

  rememberState: function() {
    var attributes = this.model.attributes;
    // Глубокое копирование
    this.originalState = JSON.parse(JSON.stringify(attributes));
  },

  render: function() {
    var html = this.template();
    this.setElement(html);

    this.$scrollable = this.$el.find('.resizable-content-wrapper');
    this.$content = this.$el.find('.js-dropdown-content');
    this.$captionContainer = this.$el.find('.js-dropdown-caption-container');
    this.$optionsContainer = $(this.$el.find('.js-dropdown-options-container'));

    this.resizableScroll = $(this.$el.find('.resizable-scroll-wrapper'))
      .RMScroll({
        $container: this.$el.find('.resizable-content-wrapper'),
        $content: this.$el.find('.resizable-content'),
        $handle: this.$el.find('.resizable-scroll'),
        wheelScrollSpeed: 0.4,
        gap_start: this.SCROLL_GAP_START,
        gap_end: this.SCROLL_GAP_END,
      })
      .data('scroll');

    // Сортировка
    this.$optionsContainer.sortable({
      items: '.js-sortable',
      distance: 10,
      axis: 'y',
      scrollSpeed: 10,
      containment: 'parent',
      tolerance: 'pointer',
      start: this.onSortStart.bind(this),
      stop: this.onSortEnd.bind(this),
    });

    this.renderContent();

    this.bindLogic();
    return this;
  },

  renderContent: function() {
    this.$captionContainer.empty().append(this.renderCaption());
    this.$optionsContainer.empty().append(this.renderDropdownOptions());
    // Обновим сортировку после рендера опций
    this.refreshSort();
    // Обновим скролл
    this.resizableScroll && this.resizableScroll.recalc();
  },

  renderCaption: function() {
    var template = templates['template-constructor-control-form_content-dropdown-caption'];
    var caption = this.model.get('caption');
    return template({
      text: getTemplateInputText('dropdownEditMode', caption),
      placeholder: getPlaceholder('dropdownEditMode'),
    });
  },

  renderDropdownOptions: function() {
    var $options = $(document.createDocumentFragment());
    var template = templates['template-constructor-control-form_content-dropdown-option'];
    _.each(
      this.model.get('items'),
      function(value, index, options) {
        var html = template({
          text: getTemplateInputText('option', value),
          number: index + 1,
          placeholder: getPlaceholder('option'),
          showDelete: options.length > 1,
        });
        $options.append(html);
      }.bind(this)
    );

    return $options;
  },

  bindLogic: function() {
    this.listenTo(this.model, 'item:add', this.onModelChange);
    this.listenTo(this.model, 'item:delete', this.onModelChange);
    this.listenTo(this.model, 'revert', this.onModelChange);
    this.listenTo(this.model, 'reorder', this.onModelChange);

    this.updateCaptionDebounced = debounceOrImmediate(this.updateCaption.bind(this), 300);
    this.updateOptionDebounced = debounceOrImmediate(this.updateOption.bind(this), 300);
  },

  unbindLogic: function() {
    this.stopListening(this.model);
  },

  refreshSort: function() {
    $(this.$optionsContainer).sortable('refresh');
  },

  onSortStart: function() {
    this.$el.find(':input').blur();
    this.$el.addClass('grabbing');
  },

  onSortEnd: function() {
    this.$el.removeClass('grabbing');
    this.reorder();
    // Уберём стили, которые проставляет jquery sortable: из-за них случается странный эффект:
    // если снова взять перемещённое поле, то оно как бы прилетает сверху.
    // (хотя это незаметно в дропдауне, потому что он ре-рендерится после каждой сортировки)
    this.$el.find('.js-dropdown-option').attr('style', '');
  },

  onAddOptionClick: function() {
    this.addOption();
    this.$el.find('.js-dropdown-option-value:last').focus();
    var overflow = this.getContentOverflow();
    // Если список опций не помещается, скажем родительскому вью поменять высоту так чтобы помещалось
    if (overflow > 0) {
      this.model.trigger('change:height', overflow);
    }
    this.scrollToBottom();
  },

  onDeleteOptionClick: function(event) {
    var number = $(event.target)
      .closest('[data-number]')
      .data('number');
    var index = number ? parseInt(number) - 1 : -1;
    this.deleteOption(index);
  },

  onCancelDropdownClick: function() {
    // При клике на cancel откатываем изменения.
    // В остальных случаях (даже если не нажимать save, а просто закрыть) изменения автоматически записываются в модель
    this.revert();
    this.hide();
  },

  onSaveDropdownClick: function() {
    this.save();
    this.hide();
  },

  onCaptionKeydown: function(event) {
    // При нажатии enter сразу обновим caption в модели и закончим редактирование, в остальных случаях обновим caption раз в интервал
    this.updateCaptionDebounced(event);
  },

  onOptionKeydown: function(event) {
    // При нажатии enter сразу обновим items в модели и закончим редактирование, в остальных случаях обновим items раз в интервал
    this.updateOptionDebounced(event);
  },

  updateCaption: function(event) {
    var $target = $(event.target);
    // Позаботимся о том чтобы кэпшен был не пустой
    var caption = $target.val() || getPlaceholder('dropdown');
    this.model.set('caption', caption);
  },

  updateOption: function(event) {
    var $target = $(event.target);
    var newValue = $target.val();
    var index = parseInt($target.data('number')) - 1;
    var items = _.clone(this.model.get('items'));
    // Не позволяем записать пустое значение
    items[index] = newValue || getPlaceholder('option');
    this.model.set('items', items);
  },

  onModelChange: function() {
    this.renderContent();
  },

  addOption: function() {
    var items = _.clone(this.model.get('items'));
    var value = getPlaceholder('option');
    items.push(value);
    this.model.set('items', items);
    // Кастомное событие
    this.model.trigger('item:add', value);
  },

  deleteOption: function(index) {
    var items = _.clone(this.model.get('items'));
    // Удаляет из массива один элемент с индексом index
    items.splice(index, 1);
    this.model.set('items', items);
    // Кастомное событие
    this.model.trigger('item:delete', index);
  },

  reorder: function() {
    var reorderedItems = [];
    var items = this.model.get('items');
    _.each(this.$optionsContainer.find('.js-sortable'), function(element) {
      var number = parseInt($(element).data('number'));
      var item = items[number - 1];
      reorderedItems.push(item);
    });

    this.model.set('items', reorderedItems);
    this.model.trigger('reorder');
  },

  scrollToBottom: function() {
    this.$scrollable.animate({ scrollTop: this.$content.height() - this.$scrollable.height() });
  },

  /**
   * Возвращает число, насколько контент не помещается
   * @param {Number} [delta]
   * @returns {Number} отрицательное, если помещается. Положительное, если не помещается
   */
  getContentOverflow: function(delta) {
    return this.$content.height() - (this.$content.parent().height() + (delta || 0));
  },

  isShown: function() {
    return this.$el.is('.shown');
  },

  show: function() {
    this.$el.addClass('shown');
    // Запомним исходное состояние дропдауна, чтобы можно было отменить изменения
    this.rememberState();
  },

  hide: function() {
    this.$el.removeClass('shown');
  },

  save: function() {
    this.model.trigger('save', this.model.attributes);
  },

  revert: function() {
    this.model.set('caption', this.originalState.caption);
    this.model.set('items', _.clone(this.originalState.items));
    this.model.trigger('revert');
  },

  destroy: function() {
    this.unbindLogic();
  },
});

export default FormContent;
