/**
 * Конструктор для контрола, добавляющего новый виджет addToCart
 */
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/cart_add_product_widget.tpl';
import { Constants } from '../../common/utils';
import SVGInjector from 'svg-injector';

const CartAddProductWidget = ControlClass.extend({
  name: 'cart_add_product_widget', // должно совпадать с классом вьюхи

  className: 'control cart_add_product_widget',

  initialize: function(params) {
    this.template = templates['template-constructor-control-cart_add_product_widget'];
    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;
  },

  render: function() {
    ControlClass.prototype.render.apply(this, arguments);

    var svgs = this.$el.find('.svg');
    SVGInjector(svgs);
  },

  onClick: function() {
    if (this.isOnClickDisabled()) {
      return;
    }

    this.block && this.block.workspace && this.block.workspace.createWidget(Constants.addToCartBlockName);
  },
});

export default CartAddProductWidget;
