/**
 * Работа с девайсом юзера
 *
 * TODO: перенести сюда все тесты девайса из Modernizr.tests
 */
import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);

let device = md.mobile() ? 'phone' : 'desktop';
if (md.tablet() && md.tablet() !== 'UnknownMobile') device = 'tablet';

/**
 * Метод установки класса для html страницы
 * Так делает Modernizr и очень много завязано на эти классы
 */
const setPageClass = () => {
  const desktopClass = [device === 'desktop' ? 'isdesktop' : 'no-isdesktop'];
  const tabletClass = device === 'tablet' ? ['istablet', 'touch'] : ['no-istablet', 'no-touch'];
  const phoneClass = [device === 'phone' ? 'isphone' : 'no-isphone'];

  const result = [...desktopClass, ...tabletClass, ...phoneClass];

  document.querySelector('html').classList.add(...result);
};

export default {
  type: device,
  isDesktop: device === 'desktop',
  isTablet: device === 'tablet',
  isPhone: device === 'phone',
  setPageClass,
};
