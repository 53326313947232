/**
 * Конструктор для контрола поворота виджета
 */
import PreloadDesignImages from '../../common/preload-design-images';
import VueControlClass from '../vue-control';

const AudioSettings = VueControlClass.extend({
  name: 'audio_settings',
  alt: 'Settings',
  panelComponent: 'panel-audio-settings',

  select: function() {
    VueControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-audio_settings');
  },
});

export default AudioSettings;
