/**
 * Модуль для отображения алерта тайпкита об ограничении на 5 шрифтов, используется в конструкторе в фонт-эксплорере и фонт-селекторе
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../../templates/constructor/helpers/typekit-popup.tpl';

const TypekitPopup = Backbone.View.extend({
  initialize: function(options) {
    _.bindAll(this);

    _.extend(this, options); // $el, router

    this.destroy.__debounced = _.debounce(this.destroy, 0);

    if (this.$parent.hasClass('typekit-popup-shown')) return;

    this.template = templates['template-constructor-helpers-typekit-popup'];

    this.render();

    _.defer(this.show);
  },

  TOP_POPUP_TRESHOLD: 170, // Минимальный top, при котором попап еще показывается сверху

  render: function() {
    var left, top;

    this.setElement($(this.template({})));

    this.$parent.addClass('typekit-popup-shown');

    this.$parent.on('mouseleave', this.setPopupTimeout);
    this.$parent.on('mouseenter', this.resetPopupTimeout);
    this.$parent.on('click', this.destroy.__debounced);

    left = Math.round(this.$parent.offset().left + this.$parent.width() / 2);
    top = Math.round(this.$parent.offset().top);

    this.$el.toggleClass('popup-below', top - this.TOP_POPUP_TRESHOLD < 0);

    $('body').append(this.$el);

    this.$el.css({
      left: left,
      top: top,
    });
  },

  show: function(e) {
    this.$el.addClass('shown');
  },

  setPopupTimeout: function() {
    if (!this.$parent.hasClass('typekit-popup-shown')) return;

    this.popupTimer = setTimeout(this.destroy.__debounced, 500);
  },

  resetPopupTimeout: function() {
    clearTimeout(this.popupTimer);
  },

  destroy: function() {
    this.$parent.removeClass('typekit-popup-shown');

    this.$parent.off('mouseleave', this.setPopupTimeout);
    this.$parent.off('mouseenter', this.resetPopupTimeout);
    this.$parent.off('click', this.destroy.__debounced);

    clearTimeout(this.popupTimer);

    this.$el.removeClass('shown');

    setTimeout(
      _.bind(function() {
        this.$el.off().remove();
      }, this),
      300
    );
  },
});

export default TypekitPopup;
