/**
 * Конструктор для контрола, блокирующего/разблокирующего выделенные элементы
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/common_lock.tpl';

const CommonLock = ControlClass.extend({
  name: 'common_lock', // должно совпадать с классом вьюхи

  className: 'control common_lock',

  initialize: function(params) {
    this.template = templates['template-constructor-control-common_lock'];
    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;
  },

  render: function() {
    ControlClass.prototype.render.apply(this, arguments);
    this.updateState();
  },

  /**
   * Шотркат для лоченья
   */
  bindLogic: function() {
    RM.constructorRouter.bindGlobalKeyPress([
      { key: 'l', handler: this.onClick }, // Так же определяется в роутере конструктора
    ]);
    _.each(
      this.models,
      function(m) {
        this.listenTo(m, 'change:is_locked', this.onLockChange);
      }.bind(this)
    );
  },

  /**
   * Шотркат для лоченья
   */
  unBindLogic: function() {
    RM.constructorRouter.unbindGlobalKeyPress('l', this.onClick);
    _.each(
      this.models,
      function(m) {
        this.stopListening(m, 'change:is_locked');
      }.bind(this)
    );
  },

  /**
   * Переопределяем метод нажатия на иконку контрола.
   * Лочим выделенные блоки только если все выделенные блоки разлочены,
   * иначе, - разлочим все блоки
   */
  onClick: function() {
    if (this.isOnClickDisabled()) {
      return;
    }

    var going_to_lock = _.all(this.models, function(m) {
      return !m.get('is_locked');
    });

    var new_data = _.map(this.models, function(model) {
      return {
        _id: model.id,
        is_locked: going_to_lock,
      };
    });

    this.master.workspace.save_group(new_data);

    this.master.deselect();
  },

  onLockChange: function() {
    this.updateState();
  },

  updateState: function() {
    var locked = _.any(this.models, function(m) {
      return m.get('is_locked');
    });
    this.$el.toggleClass('locked', locked);
    this.$('.icon').attr('data-alt', locked ? 'Unlock' : 'Lock');

    // Если находим модель с pack_id (т.е. блок в группе). Присваиваем/снимаем дом элементу группы
    // класс, который предотвратит драг на этом элементе. Это нужно, чтобы при залоченном блоке
    // событие провалилось до workspace, и оно могло нарисовать поверх рамку
    _.each(this.models, function(m) {
      var pack_id = m.get('pack_id');
      if (pack_id) {
        $('#pack_' + pack_id).toggleClass('no-drag', locked);
      }
    });
  },
});

export default CommonLock;
