/**
 * конструктор для Контрола, который
 * переводит виджет Кнопки в режим редактирования текста или выводит из него.
 *
 * выделением Контрола в Контрол баре управляет
 * логика в block.leaveEditMode() & block.enterEditMode()
 * через Мастер контролов, т.к. много несколько способов выйти и зайти
 * в режим редактирования текста и всю логику с этим связанную удобно держать
 * в одном месте.
 */
import ControlClass from '../control';
import PreloadDesignImages from '../../common/preload-design-images';
import templates from '../../../templates/constructor/controls/button_edit.tpl';

const ButtonEdit = ControlClass.extend({
  name: 'button_edit', // должно совпадать с классом вьюхи.

  className: 'control button_edit',

  // запустит this.save() при this.deselect() Контрола.
  saveOnDeselect: true,

  // запустит this.save() при дестрое Контрола.
  // важно, т.к. дестрой Контрола происходит при переходе в Превью.
  // и, если панель была открыта, сделаны изменения, то это позволит их сохранить.
  saveOnDestroy: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-button_edit'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-button_edit');
  },

  onClick: function() {
    if (this.isOnClickDisabled()) {
      return;
    }

    var block = this.blocks && this.blocks[0];

    if (block.isEditMode) {
      block.leaveEditMode({ deselectControl: true });
    } else {
      block.enterEditMode();
    }
  },

  /**
   * метод переопределен.
   */
  onEscKey: function() {
    var block = this.blocks && this.blocks[0];

    if (block.isEditMode) {
      block.leaveEditMode({ deselectControl: true, saveWidgetData: true });
    }
  },

  /**
   * метод переопределен.
   */
  onEnterKey: function() {
    var block = this.blocks && this.blocks[0];

    if (block.isEditMode) {
      block.leaveEditMode({ deselectControl: true, saveWidgetData: true });
    }
  },

  // запускается при deselect() & destroy(),
  // т.к. у Контрола стоят флаги saveOnDeselect: true & saveOnDestroy.
  // логика deselect() & destroy() в control.js предварительно проверяет,
  // изменилась ли модель, чтобы не запускать this.save() лишний раз.
  save: function() {
    var block = this.blocks && this.blocks[0];

    block.saveWidgetData();
  },

  /**
   * переопределен.
   * этот Контрол не открывает никаких панелек и решение
   * о его закрытие отдается на откуп менеджеру контролов controls-manager.js.
   */
  canControlBeClosed: function() {
    return false;
  },

  /**
   * переопределено,
   * чтобы выходить из режима редактирования при деселекте Контрола.
   */
  deselect: function() {
    var block = this.blocks && this.blocks[0];

    if (block.isEditMode) {
      // выходим из режима редактирования, но без опции сохранения,
      // т.к. у контрола стоит флаг saveOnDeselect: true,
      // запустит this.save() при this.deselect() Контрола.
      block.leaveEditMode();
    }

    ControlClass.prototype.deselect.apply(this, arguments);
  },

  /**
   * переопределено,
   * чтобы выходить из режима редактирования при дестрое Контрола.
   * важно, т.к. дестрой Контрола происходит при переходе в Превью.
   */
  destroy: function() {
    var block = this.blocks && this.blocks[0];

    if (block && block.isEditMode) {
      // выходим из режима редактирования, но без опции сохранения,
      // т.к. у контрола стоит флаг saveOnDestroy: true,
      // запустит this.save() при this.destroy() Контрола.
      block.leaveEditMode();
    }

    ControlClass.prototype.destroy.apply(this, arguments);
  },
});

export default ButtonEdit;
