/**
 * Контрол эффектов к изображениям
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/picture_1x1.tpl';

const Picture1x1 = ControlClass.extend({
  name: 'picture_1x1',

  className: 'control picture_1x1',

  initialize: function(params) {
    this.template = templates['template-constructor-control-picture_1x1'];

    this.initControl(params);

    this.block = this.blocks[0];

    this.block.on(
      'picture_added',
      function() {
        this.$el.show();
      },
      this
    );

    if (!this.block.model.get('picture')) {
      this.$el.hide();
    }
    this.onScaleChange.__debounced = _.debounce(this.onScaleChange, 100);

    this.block.model.on('change', this.onScaleChange.__debounced);
    this.block.on('modechanged', this.onScaleChange);
    this.onScaleChange();

    this.$el.hover(
      _.bind(function() {
        if (this.$el.hasClass('inactive-all')) {
          return;
        }

        this.$('.icon1x2').addClass('visible');
        clearTimeout(this.hoverTimeout);
      }, this),
      _.bind(function() {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = setTimeout(
          _.bind(function() {
            this.$('.icon1x2').removeClass('visible');
          }, this),
          200
        );
      }, this)
    );

    this.$el.on('click', '.icon1x2', _.bind(this.on1x2Click, this));
  },

  onClick: function() {
    if (this.saving) return;

    if (!this.block.$picture) return;
    if (this.$el.hasClass('inactive')) return;
    if (this.$el.hasClass('inactive-all')) return;

    if (this.block.cropmode) this.block.leaveCropMode(true);

    this.saving = true;
    this.block.model.once(
      'sync',
      function() {
        delete this.saving;
      },
      this
    );

    this.master.deselect();
    this.block.setActualScale();
    RM.analytics && RM.analytics.sendEvent('Picture Scale', 'actual size');
  },

  on1x2Click: function() {
    if (this.saving) return;

    if (!this.block.$picture) return;
    if (this.$el.hasClass('inactive1x2')) return;

    if (this.block.cropmode) this.block.leaveCropMode(true);

    this.saving = true;
    this.block.model.once(
      'sync',
      function() {
        delete this.saving;
      },
      this
    );

    this.master.deselect();
    this.block.setActualScale({ retina: true });

    RM.analytics && RM.analytics.sendEvent('Picture Scale', 'half size');
  },

  onScaleChange: function() {
    var noCrop =
      this.block.model.get('cropX') == 0 &&
      this.block.model.get('cropY') == 0 &&
      this.block.model.get('cropW') == this.block.model.get('originalW') &&
      this.block.model.get('cropH') == this.block.model.get('originalH');

    var inactive = Math.abs(this.block.model.get('scale') - 1) < 0.01 && noCrop;

    var inactive1x2 = Math.abs(this.block.model.get('scale') - 0.5) < 0.01 && noCrop;

    if (inactive) {
      this.$el.addClass('inactive');
    } else {
      this.$el.removeClass('inactive');
    }

    if (inactive1x2) {
      this.$el.addClass('inactive1x2');
    } else {
      this.$el.removeClass('inactive1x2');
    }
  },

  fade: function() {
    this.$el.addClass('fade');
  },

  unfade: function() {
    this.$el.removeClass('fade');
  },

  // Показываем контрол, только если у блока есть картинка и он не растянут
  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();
    return !(
      blocks.length == 1 &&
      ((blocks[0].isPictureEmpty && blocks[0].isPictureEmpty()) || blocks[0].isFullWidth() || blocks[0].isFullHeight())
    );
  },

  unBindLogic: function() {
    this.block.model.off('change', this.onScaleChange.__debounced, this);
    this.block.off('modechanged', this.onScaleChange);
  },
});

export default Picture1x1;
