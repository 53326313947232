/**
 * Простой макрос для работы с событиями перетаскивания
 *
 * TODO: requestFrame
 */
(function($, undefined) {
  $.fn.RMDrag = function(options) {
    if (!options) return this;

    // Можно указать три обработчика подряд
    if (typeof options === 'function') {
      options = {
        start: arguments[0],
        move: arguments[1],
        stop: arguments[2],
      };
    }

    //Другой способ называть обработчики
    options.start = options.start || options.begin || options.down;
    options.stop = options.stop || options.end || options.up;
    options.move = options.move || options.drag;

    // Глушить события по умолчанию
    options.silent = options.silent === undefined ? true : options.silent;

    // Названия событий
    var events = {
      start: 'mousedown',
      move: 'mousemove',
      end: 'mouseup',
    };

    var maxVelocity = {
      X: 200,
      Y: 200,
    };

    // Threshold для драга
    var threshold = !isNaN(Number(options.threshold)) ? Number(options.threshold) : 1;

    // Расширение объекта события
    var _e;

    var el = this[0];
    var $el = $(el);

    this.on(events.start, handlerStart);

    function handlerStart(e) {
      stopAndPrevent(e);

      if (window._moveInProcess) return;

      _e = {
        startX: e.pageX,
        startY: e.pageY,
        deltaX: 0,
        deltaY: 0,
        velocityX: 0,
        velocityY: 0,
        moved: false,
        startTime: Number(new Date()),
        duration: 0,
      };

      extend(e);

      $(document)
        .on(events.move, handlerMove)
        .on(events.end, handlerEnd);

      if (typeof options.start === 'function') {
        options.start.call(el, e);
      }

      if (options.dragClass && typeof options.dragClass === 'string') {
        $el.addClass(options.dragClass);
      }
    }

    function handlerMove(e) {
      stopAndPrevent(e);

      var deltaX = e.pageX - _e.startX;
      var deltaY = e.pageY - _e.startY;

      if (Math.max(Math.abs(deltaX), Math.abs(deltaY)) < threshold) return;

      _e.moved = true;

      var velocityX = deltaX - _e.deltaX || deltaX;
      var velocityY = deltaY - _e.deltaY || deltaY;
      if (Math.abs(velocityX) > maxVelocity.X) velocityX = _e.velocityX;
      if (Math.abs(velocityY) > maxVelocity.Y) velocityY = _e.velocityY;

      _e.deltaX = deltaX;
      _e.deltaY = deltaY;
      _e.velocityX = velocityX;
      _e.velocityY = velocityY;

      extend(e);

      if (typeof options.move === 'function') {
        options.move.call(el, e);
      }
      window._moveInProcess = true;
    }

    function handlerEnd(e) {
      stopAndPrevent(e);

      _e.duration = Number(new Date()) - _e.startTime;

      if (_e.moved) {
        // pervushinag: Глобальный спецфлаг, чтобы игнорировать ложный click в Chrome >= 33
        // при mouseup после драга.
        // Такой же код есть в jquery.event.drag-2.2.js
        window.suppressClick = 100 + +new Date();
      }

      extend(e);

      $(document)
        .off(events.move, handlerMove)
        .off(events.end, handlerEnd);

      if (typeof options.stop === 'function') {
        options.stop.call(el, e);
      }

      window._moveInProcess = false;

      if (options.dragClass && typeof options.dragClass === 'string') {
        $el.removeClass(options.dragClass);
      }
    }

    //Добавляет в событие нужные свойства
    function extend(e) {
      for (prop in _e) {
        e[prop] = _e[prop];
      }
    }

    function stopAndPrevent(e) {
      if (e) {
        options.preventDefault && e.preventDefault();
        options.silent && e.stopPropagation();
      }
    }

    // maintain chainability
    return this;
  };
})(jQuery);
