module.exports = {["template-constructor-block"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="block '+
((__t=(block.hidden ? 'invisible' : ''))==null?'':__t)+
'" data-id="'+
((__t=(block._id))==null?'':__t)+
'">\n		<div class="content"></div>\n		'+
((__t=( templates['template-block-frame']() ))==null?'':__t)+
'\n	</div>\n';
}
return __p;
},}