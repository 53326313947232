/*
 * Как работать с флагами:
 * Флаги приходят с backend-a и попадают в window.ServerData.featureFlags
 * На продакшене они берутся из Unleash
 * Локально их можно определить в backend-е с помощью docker-compose.override.yml
 * wiki: https://www.notion.so/readymag/Feature-Flags-fec89cc3db5e4750bab55b917e3c0c74
 * Получить значение флага, пример (для флага FEATURE_CONSTRUCTOR__E_COMMERCE):
 * import { isEnabled } from './flags';
 * isEnabled('constructor.eCommerce');
 */
const flags = window.ServerData.featureFlags ? { ...window.ServerData.featureFlags } : {};

export const isEnabled = flag => flags[flag] || false;
