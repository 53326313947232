<template>
  <div class="rm-switcher">
    <div class="rmswitcher-body" :style="[bodyStyle, bodyColorStyle]">
      <div ref="panelBack" class="rmswitcher-panel-back"></div>
      <div class="rmswitcher-panel" :style="[panelStyle, panelPosStyle]" @click="clickHanlder">
        <div class="rmswitcher-panel-on" :style="[textStyle, textOnStyle]">{{ textOff }}</div>
        <div class="rmswitcher-panel-off" :style="[textStyle, textOffStyle]">{{ textOn }}</div>
        <div class="rmswitcher-panel-slider" :style="handleStyle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseCSSColor } from 'csscolorparser';

export default {
  // двухсторонний биндинг
  // https://vuejs.org/v2/guide/components.html#Customizing-Component-v-model
  model: {
    event: 'stateChange',
  },
  props: {
    value: {}, // Не задан тип Boolean, чтобы компонент мог принимать значение undefined

    isDisabled: {
      type: Boolean,
      default: false,
    },

    // Визуальные свойства, которые у не-Vue свитчера оверрайдились
    // частично параметрами, частично CSS-ом
    width: {
      type: Number,
      default: 37,
    },
    height: {
      type: Number,
      default: 20,
    },
    shadow: {
      default: 'none',
    },
    colorOn: {
      default: '#0074FF',
    },
    colorOff: {
      default: 'rgba(26, 26, 26, 0.4)',
    },
    textOn: {
      // default: 'On'
      default: '',
    },
    textOff: {
      // default: 'Off'
      default: '',
    },
    textSizeOn: {
      default: 12,
    },
    textSizeOff: {
      default: 12,
    },
    textColorOn: {
      default: '#fff',
    },
    textColorOff: {
      default: '#fff',
    },
    handleColor: {
      default: '#fff',
    },
    handleShadow: {
      default: 'none',
    },
    handleSpace: {
      // Зазор между кругляшком и фоном свитчера.
      default: 1,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },

  data: function() {
    return {
      internalState: false,
      bodyColorStyle: null,
      panelPosStyle: null,
      panelBackOpacity: null,
    };
  },

  created: function() {
    this.internalState = this.value;
  },

  mounted: function() {
    this.updateSwitcher();
  },

  methods: {
    setSwitcherPos: function(percent) {
      this.cur_state = percent;

      this.bodyColorStyle = {
        'background-color': this.colorTransform(this.colorOn, this.colorOff, percent),
      };
      this.panelPosStyle = {
        left: Math.round(this.shiftWidth * percent) + 'px',
      };
      this.panelBackOpacityStyle = {
        opacity: (1 - percent).toFixed(2),
      };
    },

    updateSwitcher: function() {
      this.setSwitcherPos(this.internalState ? 0 : this.internalState === undefined ? 0.5 : 1);
    },

    colorTransform: function(color1, color2, percent) {
      color1 = parseCSSColor(color1);
      color2 = parseCSSColor(color2);

      return `rgba(${Math.max(
        Math.min(parseInt(percent * (color2[0] - color1[0]) + color1[0], 10), 255),
        0
      )},${Math.max(Math.min(parseInt(percent * (color2[1] - color1[1]) + color1[1], 10), 255), 0)},${Math.max(
        Math.min(parseInt(percent * (color2[2] - color1[2]) + color1[2], 10), 255),
        0
      )},${Math.max(Math.min(parseFloat(percent * (color2[3] - color1[3]) + color1[3], 10), 1), 0)})`;
    },

    oneStepSwitcherPos: function(state) {
      if (state === undefined) {
        clearInterval(this.timer);
        return this.setSwitcherPos(0.5);
      }

      state = state ? 0 : 1;
      var dir = state < this.internalState ? -1 : 1;
      var new_state = Math.max(Math.min(this.cur_state + 0.1 * dir, 1), 0);
      if ((dir > 0 && new_state >= state) || (dir < 0 && new_state <= state)) {
        new_state = state;
        clearInterval(this.timer);
      }
      this.setSwitcherPos(new_state);
    },
    clickHanlder() {
      if (this.isDisabled) {
        return false;
      }
      this.internalState = !this.internalState;
      this.callback(this.internalState);
    },
    // TODO: antipattern! this only for accessing from Backbone view. should be removed after refactoring
    disableFromOutside() {
      this.isDisabled = true;
    },
  },

  computed: {
    handleSize: function() {
      return this.height - this.handleSpace * 2;
    },

    bodyStyle: function() {
      return {
        width: this.width + 'px',
        height: this.height + 'px',
        'box-shadow': this.shadow,
        'border-radius': Math.floor(this.height / 2) + 'px',
        opacity: this.isDisabled ? 0.5 : 1,
        cursor: this.isDisabled ? 'default' : 'pointer',
      };
    },

    fullWidth: function() {
      return (this.width - this.handleSize - this.handleSpace) * 2 + this.handleSize;
    },

    textWidth: function() {
      return Math.round(this.width * 0.75 - 8 - 8);
    },

    shiftWidth: function() {
      return this.width - this.fullWidth;
    },

    panelStyle: function() {
      return {
        width: this.fullWidth + 'px',
        height: this.height + 'px',
      };
    },

    textStyle: function() {
      return {
        width: this.textWidth + 'px',
        'line-height': this.height + 'px',
      };
    },

    textOffStyle: function() {
      return {
        'font-size': this.textSizeOn + 'px',
        color: this.textColorOn,
      };
    },

    textOnStyle: function() {
      return {
        'font-size': this.textSizeOff + 'px',
        color: this.textColorOff,
      };
    },

    handleStyle: function() {
      return {
        'margin-left': '-' + Math.floor(this.handleSize / 2) + 'px',
        width: this.handleSize + 'px',
        height: this.handleSize + 'px',
        'border-radius': Math.floor(this.handleSize / 2) + 'px',
        'box-shadow': this.handleShadow,
        background: this.handleColor,
        top: this.handleSpace + 'px',
      };
    },
  },

  watch: {
    internalState: function(val) {
      clearInterval(this.timer);
      this.timer = setInterval(
        function() {
          this.oneStepSwitcherPos(val);
        }.bind(this),
        20
      );
      this.$emit('stateChange', val);
    },

    value: function(val) {
      this.internalState = val;
    },
  },
};
</script>

<style lang="less">
.rmswitcher-body {
  user-select: none;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.rmswitcher-panel {
  position: relative;
  top: 0;
}

.rmswitcher-panel-on,
.rmswitcher-panel-off {
  position: absolute;
  top: 0;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.rmswitcher-panel-slider {
  position: absolute;
  top: 1px;
  left: 50%;
}
</style>
