module.exports = {["template-pack"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="pack" id="pack_'+
((__t=( id ))==null?'':__t)+
'">\n		<div class="content"></div>\n		'+
((__t=( templates['template-block-frame']() ))==null?'':__t)+
'\n	</div>\n';
}
return __p;
},}