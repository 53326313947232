<template>
  <div class="panel picture-settings" :class="{ uploading: uploading, 'has-picture': hasPicture, dragging: dragging }">
    <div class="param-caption">Picture</div>
    <div class="upload">
      <div class="upload-wrapper">
        <div class="center-wrapper" @click="uploadOrDelete">
          <div class="icon"></div>
          <div class="caption-upload-dop">{{ dragging ? 'drop here' : 'drop file here' }}</div>
        </div>
      </div>
      <input ref="uploadInput" class="uploadInput" type="file" name="upload" data-url="/api/upload/" />

      <div class="social">
        <div class="search">
          <input
            v-model="searchText"
            type="text"
            spellcheck="false"
            placeholder="Search or paste URL"
            :class="{ 'has-icon': searchText != '' }"
            @keyup.enter="uploadFromSearch"
          />
          <div
            class="icon"
            :class="{ hidden: !trimmedSearchText, 'upload-icon': isURL(trimmedSearchText) }"
            @click="uploadFromSearch"
          ></div>
        </div>

        <div class="search-icons">
          <div class="flickr" title="Flickr" @click="searchFlickr"></div>
          <div class="unsplash" title="Unsplash" @click="searchUnsplash"></div>
        </div>

        <div class="separator"></div>
      </div>
    </div>

    <div class="picture_preloader rmpreloader">
      <div class="shadow"></div>
      <div class="arc"></div>
    </div>

    <div class="loading">
      <div class="delete" @click="cancelUpload"></div>
    </div>

    <div class="settings">
      <div class="param param-component">
        <div class="param-caption">Border</div>
        <numeric-input
          v-model="m.border_size"
          :autofocus="true"
          :autosize="false"
          :min="0"
          :max="999"
          dir="v"
          :speed="5"
        />

        <div class="border-color" title="Color">
          <color-picker
            ref="colorpicker"
            class="color round"
            :color="m.border_color"
            :show-alpha="false"
            :show-arrow-icon="true"
            @update:color="updateColor"
          ></color-picker>
        </div>

        <div class="separator"></div>
      </div>

      <div class="param param-component">
        <div class="param-caption">Radius</div>
        <numeric-input
          v-model="m.border_radius"
          :class="{ inactive: m.border_radius_max }"
          :autofocus="true"
          :autosize="false"
          :min="0"
          :max="999"
          dir="v"
          :speed="5"
          @change="onBorderRadiusChange"
        />

        <div
          class="border-radius-max"
          :class="{ active: m.border_radius_max }"
          title="Radius: 100%"
          @click="toggleBorderRadiusMax"
        ></div>
        <div class="separator"></div>
      </div>

      <div class="param-component opacity">
        <transition name="fade">
          <div v-if="!opacityActive" key="title" class="param-caption">Opacity</div>
          <div v-if="opacityActive" key="percent" class="percent param-caption">{{ opacityPercents }}</div>
        </transition>
        <rm-opacity-drag ref="opacity" v-model="m.opacity" @opacity-active="showPercents"></rm-opacity-drag>
      </div>
    </div>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import Vue from 'vue';
import { Utils } from '../../../js/common/utils';
import Uploader from '../../../js/constructor/helpers/uploader';
import NumericInput from '../../common/numeric-input.vue';
import ColorPicker from '../../common/color-picker.vue';
import RmOpacityDrag from '../../common/rm-opacity-drag.vue';
import Panel from './panel.vue';

export default {
  components: { NumericInput, ColorPicker, RmOpacityDrag },
  mixins: [Panel],

  data: function() {
    return {
      searchText: '',
      uploading: false,
      dragging: false,
      opacityActive: false,
      m: {
        border_size: 0,
        border_color: 'ffffff',
        border_radius: 0,
        border_radius_max: false,
        opacity: 1,
      },
    };
  },

  mounted: function() {
    this.uploader = new Uploader(this);
    this.uploader.on('start', this.onStartUpload);
    this.uploader.on('done', this.onDoneUpload);
    this.uploader.on('fail', this.onFailUpload);
    this.uploader.on('uploadFromUrl', this.uploadFromUrl);

    this.raw_block.workspace.on('main-dragover', this.highlightDropZone, this);

    this.$watch('block.picture', function() {
      if (this.block.picture && this.block.picture.finalUrl) {
        this.uploading = false;
        this.searchText = '';
      }
    });

    this.$watch('raw_block.isLoading', function() {
      this.uploading = this.raw_block.isLoading;
    });

    this.$emit('resize');
  },

  computed: {
    trimmedSearchText: function() {
      return $.trim(this.searchText);
    },

    hasPicture: function() {
      Vue.nextTick(
        function() {
          this.$emit('resize');
        }.bind(this)
      );
      return this.block.picture && this.block.picture.url && !this.uploading;
    },

    opacityPercents: function() {
      return this.$refs.opacity.percent;
    },
  },

  methods: {
    uploadOrDelete: function(e) {
      if ($(e.target).hasClass('uploadInput')) return;

      $(this.$refs.uploadInput).trigger('click', 'stop');
    },

    uploadFromSearch: function(e) {
      let t = $.trim(this.searchText);
      if (!t) return;

      if (this.isURL(t)) {
        // если введен урл то загружаем картинку из него
        this.uploadFromUrl(t);
      } else {
        // иначе показываем панель поиска бинга
        RM.views.searchBing && RM.views.searchBing.show(t, { upload: this.uploadFromUrl });
      }
    },

    uploadFromUrl: function(text) {
      // загружаем картинку из урла
      this.uploading = true;

      this.raw_block.showIconLoader();
      this.raw_block.loadFromUrl(text, {
        success: () => {
          // this.setPreviewImage();
        },
        error: () => {
          this.onFailUpload();
        },
        save: false,
      });
    },

    cancelUpload: function() {
      this.uploading = false;
      this.raw_block.removePicture();
    },

    searchFlickr: function() {
      RM.views.searchFlickr && RM.views.searchFlickr.show('', { upload: this.uploadFromUrl });
    },

    searchUnsplash: function() {
      RM.views.searchUnsplash &&
        RM.views.searchUnsplash.show('', { upload: this.uploadFromUrl, preview: this.block.setPreviewImage });
    },

    onStartUpload: function(e, data) {
      this.uploading = true;
    },

    onDoneUpload: function(e, data) {
      if (!data || !data.result) {
        console.error('error file upload!');
        return;
      }

      this.raw_block.renewPicture(data.result);
    },

    onFailUpload: function() {
      this.uploading = false;
      this.searchText = '';
    },

    highlightDropZone: function(e) {
      this.dragging = true;

      if ($(this.$el).find(e.target).length) this.raw_block.$el.removeClass('.replace');

      clearTimeout(this.highlightTimeout);

      this.highlightTimeout = setTimeout(() => {
        this.dragging = false;
      }, 500);
    },

    updateColor: function(col) {
      this.m.border_color = col;
    },

    showPercents: function(show) {
      this.opacityActive = show;
    },

    toggleBorderRadiusMax: function() {
      this.m.border_radius_max = !this.m.border_radius_max;
    },

    onBorderRadiusChange: function() {
      this.m.border_radius_max = false;
    },

    isURL: Utils.filters.isURL,
  },

  beforeDestroy: function() {
    this.raw_block.workspace.off('main-dragover', this.highlightDropZone, this);
  },
};
</script>

<style lang="less" scoped>
@import (reference) '../../../css/common/constants.less';

.fade-enter,
.fade-leave-to {
  .transition(10.2s, all, ease-in-out) !important;
}
.fade-leave-to,
.fade-leave-active {
  opacity: 0;
}
</style>
