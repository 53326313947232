/**
 * Конструктор для контрола поворота виджета
 */
import VueControlClass from '../vue-control';

const GmapsSettings = VueControlClass.extend({
  name: 'gmaps_settings',
  alt: 'Settings',
  panelComponent: 'panel-gmaps-settings',
});

export default GmapsSettings;
