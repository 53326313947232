/**
 *
 * рабочая область внутри виджета,
 * предназначенная
 * для создания и работы внутри него других виджетов.
 *
 * рабочая область создается родительским виджетом.
 *
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../../templates/constructor/workspace-inside-block-widget-selector.tpl';

const HotspotWidgetSelector = Backbone.View.extend({
  events: {
    'click .widget': 'on_widget_select',
  },

  initialize: function(options) {
    _.bindAll(this);

    this.workspace = options.workspace_inside_block;
    this.model = this.workspace.block.model;
    this.$container = options.$container || this.workspace.$el;

    this.template = templates['template-constructor-workspace-inside-block-widget-selector'];

    this.render();

    this.bind_events();
  },

  render: function() {
    this.setElement($(this.template()));

    this.$el.appendTo(this.$container);

    this.updateSelectorStateAndPos();
  },

  bind_events: function() {
    this.listenTo(this.model, 'change:tip_pos', this.updateSelectorStateAndPos);

    this.listenTo(this.model, 'change:wids', function() {
      // виджет добавился\удалился.
      // снимаем блокировку с виджет-селектора,
      // которая в this.on_widget_select().
      this.$el.data('processing', false);

      this.updateSelectorStateAndPos();
    });

    this.listenTo(this.model.widgets_collection, 'change:hidden', function() {
      // виджет показался\скрылся.
      // снимаем блокировку с виджет-селектора,
      // которая в this.on_widget_select().
      this.$el.data('processing', false);

      this.updateSelectorStateAndPos();
    });
  },

  updateSelectorStateAndPos: function(e) {
    var $widgets_buttons = this.$('.widget'),
      visible_blocks = this.workspace.get_visible_blocks(),
      has_visible_blocks = !_.isEmpty(visible_blocks);

    $widgets_buttons.removeClass('enabled');

    _.each(
      visible_blocks,
      _.bind(function(block) {
        $widgets_buttons.filter('[data-block-type=' + block.model.get('type') + ']').addClass('enabled');
      }, this)
    );

    // прячем тень вокруг воркспейса если есть и blocks-wrapper так как у нас сейчас виден толко виджет-селектор
    this.workspace.$el.toggleClass('no-widgets', !has_visible_blocks);

    // в компактном режиме виджет селектор всегда слева, кроме случая, когда тип хотспота расположен справа от точки
    this.$el
      .toggleClass('compact', !!has_visible_blocks)
      .toggleClass('right-pos', !!(has_visible_blocks && this.model.get('tip_pos') == 'left'));

    // для компактного режима сбрасываем все ручные настройки положения виджет селектора (которые могу примениться нижу)
    // а для режима когда нет ни одного виджета плашку виджет-селектора надо отцентрировать самим
    // поскольку иначе она спозиционируется не там если до этого были созданы виджеты и ширина типса менялась
    // и в свзи с этим ширина воркспейса будет иной, чем 240 - ширина виджета-селектора
    this.$el.css({
      left: has_visible_blocks ? '' : this.model.get('tip_w') / 2 - this.$el.width() / 2 + 'px',
    });
  },

  on_widget_select: function(e) {
    this.workspace.trigger('deselect');

    this.workspace.page_workspace.controls.hide();

    // в данном методе перед созданием вложенного виджета
    // происходит проверка можем ли мы создать виджет.
    // проверяются разные лимиты.
    // лимит можно с точностью проверить, только
    // после создания виджета.
    // т.е. не можем проверять можно ли создать один виджет,
    // пока не создался предыдущий.
    // но виджет создается не мгновенно, а после сохранения на сервер,
    // поэтому при нажатии на кнопку в виджет селекторе,
    // сразу отключаем виджет селектор.
    // он включется опять при измении аттрибута wids
    // в модели родительском виджете или
    // при изменении аттрибута hidden
    // у одного из вложенных виджетов.
    // console.log(this.$el.data('processing'))
    if (this.$el.data('processing')) return;

    this.$el.data('processing', true);

    var $widget = $(e.currentTarget),
      widget_type = $widget.data('block-type'),
      block = _.filter(this.workspace.blocks, function(block) {
        return block.model.get('type') === widget_type;
      })[0];

    if (block) {
      // виджет уже создан
      // нажатие на кнопку в виджет-селектре должно отрабатывать
      // как «скрыть\показать данный виджет»

      if (block.model.get('hidden')) {
        // необходимо задать блоку ширину равную
        // ширине воркспейса и сделать фит-скейл для картинки.
        // для текста подгон своей высоты под высоту
        // своего контента.

        var box = block.frame.doResize(
          {
            left: block.model.get('x'),
            top: block.model.get('y'),
            width: this.workspace.get_box_data().width,
            height: block.model.get('h'),
          },
          false
        );

        if (block.model.get('type') === 'picture') {
          // делаем fill scale,
          // отталкиваясь от ширины.
          var w = box.width,
            h = block.getBoxData().h,
            crop = {},
            pic = {},
            scale,
            origin_ratio = block.model.get('originalW') / block.model.get('originalH');

          if (block.model.get('ratio') > origin_ratio) {
            scale = w / block.model.get('originalW');

            crop = {
              cropX: 0,
              cropY: Math.round((block.model.get('originalH') - h / scale) / 2),
              cropW: block.model.get('originalW'),
              cropH: Math.round(h / scale),
            };

            pic = {
              width: w,
              height: Math.round(w / origin_ratio),
              top: -Math.round(crop.cropY * scale),
              left: 0,
            };
          } else {
            scale = h / block.model.get('originalH');

            crop = {
              cropX: Math.round((block.model.get('originalW') - w / scale) / 2),
              cropY: 0,
              cropW: Math.round(w / scale),
              cropH: block.model.get('originalH'),
            };

            pic = {
              width: Math.round(h * origin_ratio),
              height: h,
              top: 0,
              left: -Math.round(crop.cropX * scale),
            };
          }

          block.model.set(
            _.extend(
              {
                // сетим ширину и высоту,
                // т.к. далее change:hidden вызовет
                // redraw, берущее знач. из модели,
                // а они (ширина) будут старые.
                w: w,
                h: h,
                scale: scale.toFixed(15),
                ratio: (w / h).toFixed(5),
              },
              crop
            ),
            {
              // чтобы не вызывать redraw
              silent: true,
            }
          );

          block.$picture && block.$picture.css(pic);
        } else if (block.model.get('type') === 'text') {
          var text_content_height = 0;

          block.$el
            .find('.text-preview')
            .children()
            .each(function() {
              // outerHeight — чтобы паддинги посчитались.
              text_content_height += $(this).outerHeight();
            });

          var text_box = block.getBoxData();

          // выставляем каждому в-текста высоту,
          // равную высоте его контента в-воркспейса.
          block.frame.doResize(
            {
              left: text_box.x,
              top: text_box.y,
              width: text_box.w,
              height: text_content_height,
            },
            false
          );

          // сетим, т.к. при сете хиддена
          // вызовется redraw, который
          // отресайзит по данным из модели
          // и все испортит в боксе
          // и сохранится не то.
          block.model.set(block.getBoxData(), { silent: true });
        }

        // показываем виджет.
        // сет запустит сет на новую высоту и позицию в-воркспейса.
        // также данный сет запустит сет новых позиций видимых вложенных виджетов.
        block.model.save({ hidden: false }, { toHistory: true });

        if (block.model.get('type') === 'picture') {
          // после сета хиддена подсказка норм отресайзилась
          // и спозиционировала виджеты,
          // сделаем вход в ограниченный кроп. мод
          // картинки.

          block.in_constrained_crop_mode = false;

          block.enter_constrained_crop_mode();
        }

        // выделяем показавыемый блок, чтоыб показать контролы.
        block.select();

        this.workspace.trigger('select');
      } else {
        // скрываем виджет.
        // сет запустит сет на новую высоту в-воркспейса.
        // показанный виджет уже имеет новую ширину и размер заранее выставленную.
        // также данный сет запустит сет новых позиций видимых вложенных виджетов.
        block.model.save({ hidden: true }, { toHistory: true });
      }
    } else {
      //! exists

      this.workspace.add_widget({
        type: widget_type,
        w: this.model.get('tip_w'), // необходимо задать блоку ширину и высоту перед показом. выставляем вложенному виджету такую же ширину как и у внутриблокового воркспейса,в котором он работает.
        h: widget_type === 'picture' ? 180 : widget_type === 'text' ? 54 : 400,
      });

      if (widget_type === 'picture') {
        // при создании виджета картинки для подсказки хотспот-виджета,
        // триггерим клик на заранее отрендеренный файл инпут,
        // который виджет картинки привяжет к себе, как только
        // создаться асинхронно. должно успеть произойти
        // пока анимация показа файндер происходит :-).

        this.workspace.$picture_upload_input.trigger('click');
      }
    }
  },

  destroy: function() {
    this.remove();
  },
});

export default HotspotWidgetSelector;
