/**
 * Обработчик управлением прозрачности
 */
import _ from '@rm/underscore';

(function($, undefined) {
  var opacity_block_width = 176; // из css, динамически получать результаты сложно поскольку панельки не видны
  var opacity_handle_width = 28; // из css, динамически получать результаты сложно поскольку панельки не видны

  $.fn.rmOpacityDrag = function(options) {
    this.each(function() {
      var $this = $(this);

      var $opacity_handle = $this.find('.opacity-handle');
      var callback = function(opacity) {
        var cb = options && options.onchange ? options.onchange : $.noop;

        $this.data('opacity', opacity);
        cb(opacity);
      };

      var endDragCallback = options && options.onenddrag ? options.onenddrag : $.noop;

      var width = options.width || opacity_block_width,
        handleWidthHalf = (options.handleWidth || opacity_handle_width) / 2;

      var gap = options.gap || 0;

      var setColor = function(r, g, b) {
        var st = 'rgba(' + r + ', ' + g + ', ' + b + ', 1)';
        var ed = 'rgba(' + r + ', ' + g + ', ' + b + ', 0)';
        $this.css('background', '-webkit-linear-gradient(left, ' + st + ' 0%, ' + ed + ' 100%)');
        $this.css('background', '-linear-gradient(to right, ' + st + ' 0%, ' + ed + ' 100%)');
      };

      var startOpacityDrag = function(e) {
        var pos_x = e.pageX - $this.offset().left;
        var st = handleWidthHalf + (gap ? gap + 1 : 0);
        var ed = width - handleWidthHalf - (gap ? gap - 1 : 0);
        pos_x = Math.min(Math.max(pos_x, 0), width - 1);

        if (pos_x > ed) pos_x = ed;
        if (pos_x < st) pos_x = st;
        var per = ((pos_x - st) / (ed - st)).toFixed(2);
        $opacity_handle
          .css({ left: pos_x })
          .addClass('opacity-dragging')
          .find('.opacity-val .val')
          .text(Math.round(per * 100));

        callback(per - 0);
      };

      var stopOpacityDrag = function(e) {
        $opacity_handle.removeClass('opacity-dragging');

        endDragCallback();
      };

      var setOpacityHandlePos = function(opacity) {
        var st = handleWidthHalf + (gap ? gap + 1 : 0);
        var ed = width - handleWidthHalf - (gap ? gap - 1 : 0);
        var pos_x = (ed - st) * opacity + st;

        $opacity_handle
          .css({ left: pos_x })
          .find('.opacity-val .val')
          .text(Math.round(opacity * 100));
      };

      var change = function(params) {
        if (!params) return;

        if (params.setcolor) {
          setColor(params.setcolor[0], params.setcolor[1], params.setcolor[2]);
        }

        if (_.has(params, 'opacity')) {
          setOpacityHandlePos(params.opacity);
        }
      };

      $this.data('rmopacitydrag-change', change);

      $this.RMDrag({
        start: startOpacityDrag,
        move: startOpacityDrag,
        end: stopOpacityDrag,
        silent: true,
        preventDefault: true,
      });

      setOpacityHandlePos(options.opacity);
    });

    return this;
  };
})(jQuery);
