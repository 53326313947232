/**
 * Конструктор для контрола который переводит текстовый виджет в режим редактирования
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/text_edit.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const TextEdit = ControlClass.extend({
  name: 'text_edit', // должно совпадать с классом вьюхи

  className: 'control text_edit',

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_edit'];

    this.initControl(params);
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-text_edit');
    _.defer(
      function() {
        this.blocks && this.blocks[0].enterEditMode();
      }.bind(this)
    );
  },
});

export default TextEdit;
