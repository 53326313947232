module.exports = {["template-constructor-control-slideshow_manager"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="icon preloader noarrow" data-alt="Images" data-alt-pos="left" data-alt-offset="9"></div>\n\n	<div class="panel">\n		<div class="resizable-scroll-wrapper">\n			<div class="resizable-content-wrapper">\n				<div class="resizable-content">\n\n				</div>\n			</div>\n			<div class="resizable-scroll"></div>\n		</div>\n		<div class="resize-handle-top"></div>\n		<div class="resize-handle-bottom"></div>\n\n		<div class="add-block">\n			<div class="add" title="Add Images"></div>\n		</div>\n\n		<div class="error-panel">\n			<div class="error-panel-text"></div>\n			<div class="corner-wrapper"><div class="corner"></div></div>\n		</div>\n\n		<div class="drop-text">Drop here</div>\n	</div>\n';
}
return __p;
},["template-constructor-control-slideshow_manager-image"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="slideshow-image-wrapper '+
((__t=( pic.error? 'error': ''))==null?'':__t)+
'" data-num="'+
((__t=(pic.num))==null?'':__t)+
'" ';
 if (pic.errorText) { 
__p+='data-error="'+
((__t=(pic.errorText))==null?'':__t)+
'" ';
 } 
__p+=' >\n		<div class="slideshow-image">\n			<div class="num">'+
((__t=(pic.num))==null?'':_.escape(__t))+
'</div>\n			<div class="image"';
 if (pic.thumbUrl) { 
__p+=' style="background-image: url(\''+
((__t=(pic.thumbUrl))==null?'':__t)+
'\')" ';
 } 
__p+=' data-id="'+
((__t=(pic.id))==null?'':_.escape(__t))+
'">\n				<div class="rmpreloader">\n					<div class="shadow"></div>\n					<div class="arc"></div>\n				</div>\n			</div>\n			<div class="remove" title="Delete"></div>\n			<div class="update" title="Replace"></div>\n		</div>\n	</div>\n';
}
return __p;
},}