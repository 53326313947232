/**
 * контрол-плюсик (+) у хотспот виджета,
 * который создает новый хотспот виджет
 * в той же группе хотспот виджетов.
 *
 * принадлежность к группе означает, что точки
 * этих хотспот виджетов имеют одинаковый визуальный стиль.
 * при изменении стиля точки одного из хотспот виджетов в группе,
 * этот стиль точки автоматически применяется
 * у других хотспот виджетов в группе.
 *
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import Blocks from '../blocks';
import templates from '../../../templates/constructor/controls/hotspot_add.tpl';

const HotspotAdd = ControlClass.extend({
  name: 'hotspot_add',

  className: 'control hotspot_add',

  pin_attributes: [
    'hotspots_group_id',
    'pin_type',
    'w',
    'h',
    'bg_color',
    'bg_opacity',
    'color',
    'opacity',
    'borders',
    'noun_id',
    'rm_id',
    'noun_url',
    'rasterUrl',
    'raster2xUrl',
    'tip_show_on',

    // эти параметры хоть и не сквозные внутри группы при изменениях одного хотспота
    // но при создании по (+) нового хотспота в группе ихначально их надо взять из настроек того, который сейчас выделен
    'tip_background-color',
    'tip_background-color-opacity',
    'tip_border-radius',
    'tip_box-shadow',
    // 'tip_pos',
    'is_global',
    'is_above',
  ],

  initialize: function(params) {
    this.template = templates['template-constructor-control-hotspot_add'];

    this.initControl(params);
  },

  // переопределяем метод нажатия на иконку контрола.
  // создаем новый хотспот виджет в той же группе.
  // стили точки клонируем.
  onClick: function() {
    ControlClass.prototype.onClick.apply(this, arguments);

    var block = this.blocks[0],
      model = _.clone(block.model.attributes),
      default_model = Blocks['hotspot'].defaults,
      new_hotspot_widget_model = {
        type: 'hotspot',
      };

    // сначала в модель нового хотспот виджета
    // копируем дефолтные параметры модели.
    _.extend(new_hotspot_widget_model, default_model);

    // затем аттрибуты стиля точки и hotspots_collection_id
    // проставляем такие же как у хотспота,
    // от которого создаем.

    // /gwidget - брать так же признак глобальности и отвязанности. Сделат либо здесь, либо добавить в атрибуты выше
    var position_attributes = {};
    if (model.is_above) {
      position_attributes = {
        fixed_position: 'n',
        x: 0,
        y: 460,
      };
    }

    _.extend(new_hotspot_widget_model, _.pick(model, this.pin_attributes), position_attributes);

    RM.constructorRouter.workspace.addWidget(new_hotspot_widget_model);
  },
});

export default HotspotAdd;
