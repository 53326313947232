<template>
  <div class="panel">
    <div class="panel-title">Facebook</div>

    <rm-select
      v-model="m.current_type"
      :options="[
        { value: 'type_like_button', caption: 'Like Button' },
        { value: 'type_page', caption: 'Page' },
        { value: 'type_comments', caption: 'Comments' },
      ]"
    ></rm-select>

    <div v-if="m.current_type == 'type_like_button'">
      <div class="input-wrapper">
        <rm-variant-input :data="m.type_like_button"></rm-variant-input>
      </div>

      <div>
        <rm-spinner
          v-model="m.type_like_button.layout"
          :variants="[
            {
              value: 'standard',
              title: 'Standard Layout',
            },
            {
              value: 'button_count',
              title: 'Button Count',
            },
            {
              value: 'box_count',
              title: 'Box Count',
            },
          ]"
        ></rm-spinner>
      </div>

      <div class="sep-line"></div>
      <div class="row">
        <div class="caption">Share Button</div>
        <rm-switcher v-model="m.type_like_button.share_button" color-on="#2d90ff" color-off="#313131"></rm-switcher>
      </div>
      <div class="row">
        <div class="caption">Show Faces</div>
        <rm-switcher v-model="m.type_like_button.show_faces" color-on="#2d90ff" color-off="#313131"></rm-switcher>
      </div>

      <div class="row">
        <div class="caption">Color</div>
        <div class="theme-picker" :class="m.type_like_button.theme">
          <div class="theme light" @click.stop="m.type_like_button.theme = 'light'"></div>
          <div class="theme dark" @click.stop="m.type_like_button.theme = 'dark'"></div>
        </div>
      </div>
    </div>

    <div v-if="m.current_type == 'type_page'">
      <div class="input-wrapper">
        <rm-apply-input v-model="m.type_page.url"></rm-apply-input>
      </div>
      <div class="sep-line"></div>
      <div class="row">
        <div class="caption">Show Stream</div>
        <rm-switcher v-model="m.type_page.show_stream" color-on="#2d90ff" color-off="#313131"></rm-switcher>
      </div>
      <div class="row">
        <div class="caption">Small Header</div>
        <rm-switcher v-model="m.type_page.small_header" color-on="#2d90ff" color-off="#313131"></rm-switcher>
      </div>
      <div class="row">
        <div class="caption">Show Faces</div>
        <rm-switcher v-model="m.type_page.show_faces" color-on="#2d90ff" color-off="#313131"></rm-switcher>
      </div>
      <div class="row">
        <div class="caption">Color</div>
        <div class="theme-picker" :class="m.type_page.theme">
          <div class="theme light" @click.stop="m.type_page.theme = 'light'"></div>
          <div class="theme dark" @click.stop="m.type_page.theme = 'dark'"></div>
        </div>
      </div>
    </div>

    <div v-if="m.current_type == 'type_comments'">
      <div class="input-wrapper">
        <rm-variant-input :data="m.type_comments"></rm-variant-input>
      </div>
      <div class="sep-line"></div>
      <div class="row">
        <div class="caption">Number of posts</div>
        <numeric-input v-model="m.type_comments.num_posts" :autosize="true" :min="1" :max="99"></numeric-input>
      </div>
      <div class="row">
        <div class="caption">Color</div>
        <div class="theme-picker" :class="m.type_comments.theme">
          <div class="theme light" @click.stop="m.type_comments.theme = 'light'"></div>
          <div class="theme dark" @click.stop="m.type_comments.theme = 'dark'"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Panel from './panel.vue';
import RmVariantInput from '../../common/rm-variant-input.vue';
import RmSwitcher from '../../common/rm-switcher.vue';
import RmSpinner from '../../common/rm-spinner.vue';
import RmSelect from '../../common/rm-select.vue';
import RmApplyInput from '../../common/rm-apply-input.vue';
import NumericInput from '../../common/numeric-input.vue';

export default {
  mixins: [Panel],
  components: { RmVariantInput, RmSwitcher, RmSpinner, RmSelect, RmApplyInput, NumericInput },
};
</script>
