/**
 * Контрол эффектов к изображениям
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlResizableClass from '../control-resizable';
import templates from '../../../templates/constructor/controls/slideshow_manager.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const SlideShowManager = ControlResizableClass.extend({
  name: 'slideshow_manager', // должно совпадать с классом вьюхи

  className: 'control slideshow_manager',

  events: {},

  MIN_PANEL_HEIGHT: 275, // Чтобы влезло 4 картинки без скролла

  MAX_PANEL_HEIGHT: 640,

  CREATE_SPEED: 250 + 20,

  initialize: function(params) {
    _.bindAll(this);

    this.template = templates['template-constructor-control-slideshow_manager'];
    this.image_template = templates['template-constructor-control-slideshow_manager-image'];

    this.initControl(params);

    this.block = this.blocks[0];

    this.block.manager = this;

    this.block.workspace.on('main-dragover', this.highlightDropZone);

    this.slideshowPlayer = this.block.slideshowPlayer;
  },

  bindLogic: function() {
    this.block.on('addUpload', this.onAddImage, this);
    this.block.on('addUpload replaceUpload', this.showLoader, this);
    this.block.on('nowUploading', this.onStartUploading, this);
    this.block.on('doneUpload', this.onDoneUpload, this);
    this.block.on('failUpload', this.onFailUpload, this);
    this.block.model.on('change:pictures', this.redrawAll, this);

    this.block.on('clear', this.clearImages);

    this.$panel.on('click', '.add-block .add', this.addClick);

    this.$panel.on('click', '.slideshow-image-wrapper .remove', this.onRemoveImage);
    this.$panel.on('click', '.slideshow-image-wrapper .update', this.onUpdateImage);

    // это для показа попапа с ошибкой для ошибочной картинки
    this.$panel.on('mouseenter', '.slideshow-image-wrapper .image', this.onImageMouseEnter);
    this.$panel.on('mouseleave', '.slideshow-image-wrapper .image', this.onImageMouseLeave);

    this.$panel.on('click', '.slideshow-image-wrapper .image', this.onImageClick);

    this.$resizableContent = $(this.$panel.find('.resizable-content'));

    this.block.on('reorder', this.reorder, this);

    // делаем все картинки "таскабельными" чтобы задачать сортировку картинок (их порядок)
    this.$resizableContent.sortable({
      distance: 10,
      axis: 'y',
      // revert: true,
      scrollSpeed: 20,
      containment: this.$('.resizable-scroll-wrapper'),

      tolerance: 'pointer',
      cursorAt: { top: 50 },
      start: _.bind(function(e, obj) {
        this.onImageMouseLeave(); // прячем панельку с ошибкой при начале драга
        obj.item.find('.slideshow-image').addClass('dragging');
        this.$resizableContent.css('height', this.$resizableContent.height() + 30);
      }, this),
      stop: _.bind(function(e, obj) {
        setTimeout(
          _.bind(function() {
            obj.item.find('.slideshow-image').removeClass('dragging');
          }, this),
          50
        );

        // Принудительно бросаем в конец если оттащили элемент дальше чем высота контейнера
        if (obj.position.top > this.$resizableContent.height() - 30 - obj.item.height()) {
          obj.item
            .detach()
            .closest('.slideshow-image-wrapper')
            .appendTo(this.$resizableContent);
        }
        this.block.swapPictures(obj.item.data('num'), obj.item.index() + 1);

        this.$resizableContent.css('height', 'auto');
      }, this),
    });

    this.$uploadDropZone = this.$el;
    this.block.updateDropZone();

    this.renderPictures();

    // навешиваем реакцию на вмену текущей картинки в самом слайдшоу плеере
    // это когда юзер стрелками бегает по картинкам в слайдшоу
    // также он выстрелит при клике по картинке в этом контроле
    // контрол скажет слайшоу плееру перейти на кликнутую картинку, а то в свою очередь скажет контролу что текущая показанная картинка поменялась
    this.slideshowPlayer && this.slideshowPlayer.on('currentImageChanged', this.onCurrentImageChanged);

    if (this.block.queue.length) this.showLoader();
  },

  unBindLogic: function() {
    this.block.off(null, null, this);
    this.$panel.off('click', '.add-block .add', this.addClick);
    this.$panel.off('click', '.slideshow-image-wrapper .remove', this.onRemoveImage);
    this.$panel.off('click', '.slideshow-image-wrapper .update', this.onUpdateImage);

    this.$panel.off('mouseenter', '.slideshow-image-wrapper .image', this.onImageMouseEnter);
    this.$panel.off('mouseleave', '.slideshow-image-wrapper .image', this.onImageMouseLeave);

    this.$panel.off('click', '.slideshow-image-wrapper .image', this.onImageClick);

    this.slideshowPlayer && this.slideshowPlayer.off();

    delete this.$uploadDropZone;
  },

  renderPictures: function(options) {
    var pics = _.sortBy(this.block.getAllPics(), function(pic) {
      return pic.num;
    });

    var html = '';
    _.each(
      pics,
      function(pic) {
        html += this.image_template({ pic: pic });
      },
      this
    );
    if (options && options.clear) this.$panel.find('.resizable-content').html('');

    this.$panel.find('.resizable-content').append(html);
    var loadingPic = this.block.queue[0];

    if (loadingPic) {
      this.onStartUploading(loadingPic);
    }

    this.rendered = true;

    this.resizableScroll && this.resizableScroll.recalc();

    $(this.$panel.find('.resizable-content')).sortable('refresh');
  },

  redrawAll: function(model, pictures, options) {
    if (options && (options.undo || options.redo)) {
      if (this.rendered) {
        this.$el.addClass('updating');

        setTimeout(
          _.bind(function() {
            this.renderPictures({ clear: true });
            this.$el.removeClass('updating');
            // при полной перерисовке списка картинок заново отмечаем текущую в плеере
            this.slideshowPlayer &&
              this.onCurrentImageChanged(this.slideshowPlayer.currentImageID, false, 'change-pictures');
          }, this),
          50
        );
      }
    }

    if (_.isEmpty(model.get('pictures'))) this.clearImages();
  },

  clearImages: function() {
    this.$('.resizable-content').empty();

    this.resizableScroll && this.resizableScroll.recalc();

    var $resizable = $(this.$panel.find('.resizable-content'));
    $resizable.data('ui-sortable') && $resizable.sortable('refresh');
  },

  reorder: function() {
    this.$('.slideshow-image-wrapper:not(.deleting)').each(function(i) {
      $(this)
        .data('num', i + 1)
        .find('.num')
        .text(i + 1);
    });
  },

  // когда плеер сказал что текущая видимая в нем картинка поменялась
  // ну там на превьюшки в нем потыкали, или на стрелки прокрутки картинок
  onCurrentImageChanged: function(id, animate, initiator) {
    var $image = this.$panel.find('.slideshow-image-wrapper .image[data-id="' + id + '"]');

    if (!$image.length) $image = this.$panel.find('.slideshow-image-wrapper .image:first');

    if (!$image.length) return;

    this.$panel.find('.slideshow-image').removeClass('active');
    $image.closest('.slideshow-image').addClass('active');

    // если переход на картинку не был инициирован юзером (путем нажатия на стрелки или превьюшку в сладшоу-плеере, или нажатия на картинку в менеджере)
    // тогда следующий код не должен исполняться
    // control-show - вызывается при показе контрола менеджера
    if (initiator != 'switch-to-image' && initiator != 'control-show') return;

    // смотрим видна ли текущая картинка (+ следующая и предыдущая) в менеджере
    // если нет, тогда скролим так чтобы была видна
    $image = $image.closest('.slideshow-image-wrapper');
    var $wrapper = this.$panel.find('.resizable-content-wrapper'),
      top = $image.position().top,
      h = $wrapper.height(),
      imageHeight = $image.height(),
      newScroll,
      contentPadding = 8;

    if (top + imageHeight * 2 - $wrapper.scrollTop() > h - contentPadding) {
      newScroll = top + imageHeight * 2 - h + contentPadding;
    } else if (top - imageHeight - $wrapper.scrollTop() < contentPadding) {
      newScroll = top - imageHeight - contentPadding;
    }

    if (newScroll != undefined) {
      if (animate) $wrapper.stop().animate({ scrollTop: newScroll }, 200);
      else $wrapper.scrollTop(newScroll);
    }
  },

  // вызывается при первоначальном показе контрола или при изменении высоты его панельки
  onScrollRecalc: function(initiator) {
    this.slideshowPlayer && this.onCurrentImageChanged(this.slideshowPlayer.currentImageID, false, initiator);
  },

  // клик по картинке -0 просим слайдшоу плеер перемотаться на него
  // тот потом нам скажет что текущая видимая картинка поменялась и менеджет сам отметит нужную картинку активной у себя
  // через обработчик onCurrentImageChanged
  onImageClick: function(e) {
    var id = $(e.target).attr('data-id');

    if (!id) return;

    this.slideshowPlayer && this.slideshowPlayer.moveToImage(id, true, 'switch-to-image');
  },

  // показываем панельку с ошибкой при наведении на ошибочную картинку
  onImageMouseEnter: function(e) {
    var $pict = $(e.currentTarget),
      $pictWrapper = $pict.closest('.slideshow-image-wrapper'),
      $panel = this.$('.error-panel');

    if ($pict.closest('.slideshow-image').hasClass('dragging')) return;

    if (!$pictWrapper.hasClass('error')) return;

    var w = $pict.width(),
      l = $pict.offset().left - this.$panel.offset().left,
      t = $pict.offset().top - this.$panel.offset().top;

    $panel.find('.error-panel-text').text($pictWrapper.attr('data-error'));

    $panel.addClass('shown').css({ left: l + w / 2, top: t - $panel.outerHeight() });
  },

  onImageMouseLeave: function(e) {
    this.$('.error-panel').removeClass('shown');
  },

  addClick: function() {
    this.block.$('.uploadInput').trigger('click', 'stop');
  },

  // анимация добавления заглушки картинки в менеджер
  onAddImage: function(pic) {
    var $image = $(this.image_template({ pic: pic }));

    $image.appendTo(this.$panel.find('.resizable-content'));

    $image
      .addClass('enable-transition')
      .addClass('collapsed')
      .addClass('collapsed-half');

    setTimeout(
      _.bind(function() {
        $image.removeClass('collapsed');

        setTimeout(
          _.bind(function() {
            $image.removeClass('collapsed-half');

            setTimeout(
              _.bind(function() {
                $image.removeClass('enable-transition');

                this.resizableScroll && this.resizableScroll.recalc();

                $(this.$panel.find('.resizable-content')).sortable('refresh');
              }, this),
              this.CREATE_SPEED
            );
          }, this),
          this.CREATE_SPEED
        );
      }, this),
      50
    );
  },

  showLoader: function() {
    this.$el.addClass('loading');
  },

  onStartUploading: function(pic) {
    var $image = this.getImage(pic);

    $image.addClass('loading');
  },

  onDoneUpload: function(pic) {
    var $image = this.getImage(pic);

    $image.find('.rmpreloader').fadeOut('fast', function() {
      $image.removeClass('loading');
      $(this).attr('style', '');
    });
    $image
      .find('.image')
      .css('background-image', 'url("' + pic.thumbUrl + '")')
      .attr('data-id', pic.id);

    if (!this.block.queue.length) this.$el.removeClass('loading');
  },

  onFailUpload: function(pic, err) {
    var $image = this.getImage(pic);

    if (pic.error) {
      $image.addClass('error').attr('data-error', err);
    }

    if (!this.block.queue.length) this.$el.removeClass('loading');
  },

  // анимация удаления картинки из менеджера
  onRemoveImage: function(e) {
    var $image = $(e.target).closest('.slideshow-image-wrapper');
    var num = $image.addClass('deleting').data('num');

    this.block.deletePic(num);

    $image.addClass('enable-transition');

    setTimeout(
      _.bind(function() {
        $image.addClass('removed-half');

        setTimeout(
          _.bind(function() {
            $image.addClass('removed');

            setTimeout(
              _.bind(function() {
                $image.removeClass('enable-transition').remove();

                this.resizableScroll && this.resizableScroll.recalc();
              }, this),
              this.CREATE_SPEED
            );
          }, this),
          this.CREATE_SPEED
        );
      }, this),
      50
    );
  },

  getImage: function(pic) {
    return this.$('.slideshow-image-wrapper').filter(function() {
      return $(this).data('num') === pic.num;
    });
  },

  onUpdateImage: function(e) {
    var $image = $(e.target)
      .closest('.slideshow-image-wrapper')
      .removeClass('error')
      .data('error', '');
    var num = $image.attr('style', '').data('num');

    this.block.updatePic(num);
  },

  highlightDropZone: function() {
    this.$panel.addClass('show-dropzone');

    clearTimeout(this.highlightTimeout);

    this.highlightTimeout = setTimeout(
      _.bind(function() {
        this.$panel.removeClass('show-dropzone');
      }, this),
      500
    );
  },

  select: function() {
    ControlResizableClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-slideshow_manager');
  },

  restrictions: function(workspace) {
    var models = _.pluck(workspace.getSelectedBlocks(), 'model');
    return _.all(models, function(m) {
      return !m.get('type') == 'slideshow' || (m.get('pictures') || []).length > 0;
    });
  },

  destroy: function() {
    ControlResizableClass.prototype.destroy.apply(this, arguments);

    this.rendered = false;
  },
});

export default SlideShowManager;
