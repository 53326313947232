<template>
  <div
    class="icon preloader noarrow"
    :data-alt="alt"
    data-alt-pos="left"
    data-alt-offset="9"
    :class="{
      [iconClass]: !!iconClass,
      'rmalttext-disable': selected,
    }"
    v-svginject
  >
    <img class="svg svg-icon" v-if="svgIcon" :src="svgIcon" />
  </div>
</template>

<script>
export default {
  props: {
    selected: false,
    alt: null,
    svgIcon: null,
    iconClass: null,
  },
};
</script>
