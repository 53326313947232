<template>
  <div class="rm-cookies-alert" :class="{ hidden: cookiesAccepted }">
    <span>
      We use cookies.
      <a href="https://readymag.com/readymag/terms-and-privacy/5/" target="_blank">Learn more</a>
    </span>
    <button @click="btnClick">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.2"
        baseProfile="tiny"
        x="0px"
        y="0px"
        viewBox="0.125 0.22499990463256836 127.75 127.74999237060547"
        xml:space="preserve"
        preserveAspectRatio="xMidYMid meet"
        fill="rgb(40,40,40)"
        fill-opacity="1"
        style="width: 100%; height: 100%;"
      >
        <g>
          <path
            fill-rule="evenodd"
            d="M77.5,64.1l47.6-47.6c3.7-3.7,3.7-9.8,0-13.5c-3.7-3.7-9.8-3.7-13.5,0L64,50.6L16.4,3   C12.7-0.7,6.6-0.7,2.9,3c-3.7,3.7-3.7,9.8,0,13.5l47.6,47.6L2.9,111.7c-3.7,3.7-3.7,9.8,0,13.5c3.7,3.7,9.8,3.7,13.5,0L64,77.6   l47.6,47.6c3.7,3.7,9.8,3.7,13.5,0c3.7-3.7,3.7-9.8,0-13.5L77.5,64.1z"
          />
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({
    clicked: false,
  }),
  computed: {
    cookiesAccepted() {
      const isAccepted = !!JSON.parse(localStorage.getItem('cookiesAccepted'));
      return this.clicked || (this.isConstructor ? false : isAccepted);
    },
    isConstructor() {
      return !!RM.constructorRouter;
    },
  },
  methods: {
    btnClick() {
      if (!this.isConstructor) {
        localStorage.setItem('cookiesAccepted', 'true');
      }

      this.clicked = true;
    },
  },
};
</script>

<style lang="less">
.rm-cookies-alert {
  border-radius: 8px;
  background-color: #f4f4f4;
  border-color: #ffffff;
  width: 324px;
  height: 84px;
  font-family: Graphik;
  box-sizing: border-box;
  margin: 16px;
  color: #282828;
  padding: 0 26px;
  align-items: center;
  justify-content: space-between;
  display: flex;

  span {
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 1;
    font-weight: 400;
  }

  a {
    color: #282828;
    text-decoration: none;

    &:hover {
      color: #ec520b;
    }
  }

  button {
    height: 22px;
    width: 22px;
    display: inline-block;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;

    &:hover svg {
      fill: #ec520b !important;
    }
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .workspace-container[data-viewport='phone_portrait'] &,
  body.constructor.preview & {
    width: 100%;
    margin: 16px 0;
  }
}

.workspace-container[data-viewport='phone_portrait'],
body.constructor.preview {
  .rm-cookies,
  .widget-rm-cookies {
    padding: 0 40px;
    box-sizing: border-box;
  }
}

// TODO: удалить, когда удалим homepage.html
#rm-cookies-wrapper {
  position: fixed;
  z-index: 556;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  width: auto;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  #rm-cookies-wrapper {
    width: 100%;
    padding: 16px 20px;
  }

  .rm-cookies-alert {
    margin: 0;
    width: 100%;

    span {
      font-size: 14px !important;
    }
  }

  .widget-rm-cookies {
    left: 0 !important;
    margin-left: 0 !important;
    width: 100vw !important;
    padding: 0px 20px !important;
    box-sizing: border-box;
  }
}
</style>
