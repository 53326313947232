/**
 * Базовый класс для контрола
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from '../common/utils';
import Events from '../common/events';
import PreloadDesignImages from '../common/preload-design-images';

const ControlClass = Backbone.View.extend({
  saveOnDeselect: false,
  saveOnDestroy: false,
  disabled: false,

  initialize: function(params) {
    this.initControl(params);
  },

  initControl: function(params) {
    _.extend(this, params);
    _.bindAll(this);
    if (this.blocks) {
      this.block = this.blocks[0];
    }
  },

  /**
   * Метод отрисовки контрола
   */
  render: function() {
    if (this.rendered) return;

    this.rendered = true;

    this.$container.append(this.$el);

    // если просят показать контрол с анимацией (выкатывается из прозрачности)
    if (this.appearanceAnimation) {
      Utils.vueTransitionsShow(this.$el, 'control');
    }

    this.$el.css('top', this.y);

    // если контрол по вертикали должен перейти из одного положения в другое
    // используется когда пересоздается один и тот же контрол и нам надо сделать вид что старый не удалился а виизуально этот тот же контрол просто подвинулся на новое место по вертикали
    if (this.fromY != this.y) {
      this.$el.css({ top: this.fromY });

      window.requestAnimationFrame(
        _.bind(function() {
          this.$el.css({ top: this.y });
        }, this)
      );
    }

    if (this.template) {
      var template = this.template({ options: this.options });
      this.$el.append(template);
      this.$icon = this.$('.icon');
      this.$panel = this.$('.panel');

      this.$icon.on('click', this.onClick.bind(this));
    }

    this.bindLogic();

    $(window).on('keyup', this.onWindowKeyUp.bind(this));

    // Надо проверять не задизейблен ли контрол и подписаться на изменения состояния
    this.setControlsStatusByBlockData();

    if (this.block) {
      this.block.on('disabledControls:changed', this.onDisabledControlsChanged.bind(this));
    }

    return this; // Для наследников. Что рендер не прерван.
  },

  moveToNewPos: function(newPos) {
    this.$el.css('top', newPos);
  },

  /**
   * Переопределяем в потомках, если надо.
   */
  bindLogic: function() {},

  /**
   * Переопределяем в потомках, если надо.
   */
  unBindLogic: function() {},

  /**
   * При клике
   */
  onClick: function() {
    // onClick может быть переопределен в наследниках
    // если это так то этот код надо добавить в них !
    if (this.isOnClickDisabled()) {
      return;
    }

    if (!this.selected) {
      if (!this.$el.hasClass('disabled')) {
        this.master.select(this);
      }
    } else {
      this.master.deselect();
    }
  },

  makeModelSnapshot: function() {
    if (this._oldAttrs && this._oldAttrs._id == this.model.attributes._id) {
      // снепшот уже есть, не нужно перетирать
      // в некоторых случаях этот код вызывается при установке определенных значений в панельку и это ломает сохранение
      return;
    }
    this._oldAttrs = this.model && _.cloneWithObjects(this.model.attributes);
  },

  clearModelSnapshot: function(wasSelected) {
    this.saveOnDeselect && wasSelected && this.canSave() && this.save();
    delete this._oldAttrs;
  },

  /**
   * Выбор контрола
   * Вызывается мастером
   */
  select: function() {
    if (this.selected) {
      return;
    }

    this.makeModelSnapshot();
    PreloadDesignImages('controls');

    this.selected = true;

    this.faded = false;
    this.$el.removeClass('fade');
    this.$el.addClass('checked');
    this.showPanel();
    this.$icon.addClass('rmalttext-disable');
  },

  showPanel: function() {
    Utils.vueTransitionsShow(this.$panel, 'panel');
    Events.trigger('panel:showed', this);
  },

  hidePanel: function() {
    return Utils.vueTransitionsHide(this.$panel, 'panel');
  },

  setEnabled: function() {
    this.$el.removeClass('disabled');
    this.disabled = false;
  },

  setDisabled: function() {
    this.$el.addClass('disabled');
    this.disabled = true;

    this.selected && this.master.deselect();
  },

  onDisabledControlsChanged: function(blockName) {
    this.setControlsStatusByBlockData();
  },

  // Проверяет есть ли в блоке поле disabledControls
  // (оно должно быть массивом строк)
  // и если название контрола есть в массиве, то выключаем контрол
  setControlsStatusByBlockData: function() {
    if (this.block && this.block.disabledControls) {
      if (this.block.disabledControls.indexOf(this.name) !== -1) {
        this.setDisabled();
      } else {
        this.setEnabled();
      }
    }
  },

  isOnClickDisabled: function() {
    return this.disabled;
  },

  /**
   * Затемненеи контрола
   * Вызывается мастером
   */
  fade: function() {
    this.deselect();
    this.faded = true;
    this.$el.addClass('fade');
  },

  removeFade: function() {
    this.faded = false;
    this.$el.removeClass('fade');
  },

  deselect: function() {
    var wasSelected = this.selected;
    this.selected = false;

    this.faded = false;
    this.$el.removeClass('fade');

    if (!this.$panel) return;

    this.hidePanel().then(
      function() {
        this.$el.removeClass('checked');
      }.bind(this)
    );

    this.$icon.removeClass('rmalttext-disable');

    this.clearModelSnapshot(wasSelected);
  },

  canSave: function() {
    return (
      this.model &&
      this._oldAttrs &&
      !_.isEqual(this.model.attributes, this._oldAttrs) &&
      this.block &&
      (this.model.isGlobal || !this.block.destroyed)
    );
  },

  onEscKey: function() {
    if (this.selected) {
      this.master.deselect();
    }
  },

  onEnterKey: function() {},

  onWindowKeyUp: function(e) {
    if (e.keyCode == $.keycodes.esc) {
      this.onEscKey();
    }
    if (e.keyCode == $.keycodes.enter) {
      this.onEnterKey();
    }
  },

  /**
   * Стирается контрол
   */
  destroy: function(animation) {
    this.saveOnDestroy && this.canSave() && this.save();

    this.unBindLogic();

    $(window).off('keyup', this.onWindowKeyUp.bind(this));

    if (this.block) {
      this.block.off('disabledControls:changed', this.onDisabledControlsChanged);
    }

    this.$panel && this.$panel.remove();

    if (animation) {
      Utils.vueTransitionsHide(this.$el, 'control').then(
        function() {
          this.$el.remove();
        }.bind(this)
      );
    } else {
      this.$el.remove();
    }

    this.destroyed = true;
  },

  /**
   * Вызываетеся мастером (менеджером контролов)
   * Функция должна вернуть TRUE если контрол считает что он "поглотит" это событие (например закроет выезжающую панельку)
   * И FALSE если контрол отдает решение на откуп менеджеру контролов (Controls)
   * если хотя бы один из видимых контролов вернет TRUE ни один из них не будет закрыт,
   * и функция которая вызвала функцию canControlsBeClosed в мастере отработает соответсвенно
   * (сейчас воркспейс просто не будет генерировать deselect, а блок не будет реагировать на клик по нему)
   *
   * Переопределять в потомках! сейчас стандартное поведение: если открыта панелька - закрыть её и вернуть TRUE, иначе вернуть FALSE
   */
  canControlBeClosed: function() {
    if (this.selected) {
      this.master.deselect();
      return true;
    } else {
      return false;
    }
  },

  getControlParam: function(param) {
    var controls = RM.constructorRouter.mag.edit_params.get('controls');
    if (!controls) return;

    return controls[this.name] && controls[this.name][param];
  },

  saveControlParam: function(obj) {
    var controls = RM.constructorRouter.mag.edit_params.get('controls');
    var saveControl = {};
    saveControl[this.name] = obj;
    if (controls && _.isEqual(controls[this.name], obj)) return;

    this.paramsSaveXHR = RM.constructorRouter.mag.edit_params.save('controls', _.extend({}, controls, saveControl));
  },

  // Выбирает вертикальную позицию панели, исходя из высоты столбца контролов,
  // положения контрола в столбце, и высоты самой панели
  updatePanelVerticalPosition: function() {
    if (!this.$panel && this.$panel.length) {
      return;
    }

    var controlsHeight = this.master.getControlsHeight();
    var panelHeight = this.$panel.height();
    var iconTop = this.master.getControlRelativePosition(this); // Вертикальная позиция иконки среди всех контролов (не настоящий top)
    var iconHeight = this.$el.height();
    var top = '';

    // Панель больше высоты всех контролов. Просто центрируем по вертикали
    if (panelHeight >= controlsHeight) {
      top = -Math.ceil((panelHeight - controlsHeight) / 2) - iconTop;

      // Панель при центрировании по контролу будет вылезать за верхнюю границу контролов.
      // Выравниваем по верхней границе контролов
    } else if (panelHeight / 2 > iconTop) {
      top = -iconTop;

      // Панель при центрировании по контролу будет вылезать за нижнюю границу контролов.
      // Выравниваем по нижней границе контролов
      // } else if ((panelHeight / 2) > (controlsHeight / 2 - iconTop + iconHeight / 2)) {
    } else if (panelHeight / 2 > controlsHeight - iconTop + iconHeight / 2) {
      top = controlsHeight - iconTop - panelHeight;
    }

    // Если top не был задан, то он просто уберется из инлайн-стиля, и останется позиционирование через css
    this.$panel.css('top', top);
  },

  /**
   * TODO: сделать возможность сохранять те данные, которые были изменены, в общем случае.
   * Переопределять в потомках
   */
  save: function() {},
});

export default ControlClass;
