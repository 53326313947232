/**
 * Конструктор для контрола стилей текста
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlResizableClass from '../control-resizable';
import Colorbox from '../helpers/colorbox';
import FontSelector from '../helpers/font-selector';
import templates from '../../../templates/constructor/controls/text_styles.tpl';
import { Constants } from '../../common/utils';
import PreloadDesignImages from '../../common/preload-design-images';

const TextStyles = ControlResizableClass.extend({
  name: 'text_styles', // должно совпадать с классом вьюхи

  className: 'control text_styles',

  MIN_PANEL_HEIGHT: 275,

  MAX_PANEL_HEIGHT: 640,

  // список атрибутов с которыми мы работаем
  // НЕ ТРОГАТЬ ЭТОТ СПИСОК!
  STYLE_PROPERTIES: [
    'color',
    'opacity',
    'font-family',
    'font-size',
    'font-style',
    'font-weight',
    'letter-spacing',
    'line-height',
    'text-align',
    'text-decoration',
    'text-transform',
    'vertical-align',
    'padding-top',
    'padding-right',
    'padding-bottom',
    'padding-left',
  ],

  // это для сохранения сортировки списка стилей
  saveOnDestroy: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_styles'];
    this.style_template = templates['template-constructor-control-text_styles-style'];

    this.initControl(params);

    this.block = this.blocks[0];

    this.refreshEditorCssDebounced = _.debounce(this.refreshEditorCss, 100);
  },

  bindLogic: function() {
    // сохраняем шорткат на объект-модель который позволяет работать с данными стилей
    this.textStyles = RM.constructorRouter.textStyles;

    this.textStyles.on('change:paragraph_styles', this.onStylesChange);

    this.$panel.on('click', '.add-block .add', this.addClick);

    this.$panel.on('click', '.paragraph-style', this.onParagraphStyleClick);
    this.$panel.on('click', '.paragraph-style .tag-changer', this.changeStyleTag);
    this.$panel.on('click', '.paragraph-style .menu-button', this.toggleMenu);

    this.$panel.on('click', '.style-menu .redefine-button', this.onRedefineClick);
    this.$panel.on('click', '.style-menu .revert-button', this.onRevertClick);
    this.$panel.on('click', '.style-menu .edit-button', this.onEditClick);
    this.$panel.on('click', '.style-menu .duplicate-button', this.onDuplicateClick);
    this.$panel.on('click', '.style-menu .delete-button', this.onDeleteClick);

    // настройки текстового виджета
    this.params = (this.block && this.block.params) || {};

    // случаем событие изменения стиля выделения, генерируется редактором
    this.block && this.block.on('selection_styles_all_changed', this.selectionStylesAllChanged.bind(this));

    // сортинг стилей
    $(this.$panel.find('.styles-wrapper')).sortable({
      distance: 10,
      axis: 'y',
      // revert: true,
      scrollSpeed: 20,
      containment: 'parent',
      tolerance: 'pointer',
      start: _.bind(function(e, obj) {
        this.closeMenu(false);
      }, this),
      stop: _.bind(function(e, obj) {
        this.reorder();
      }, this),
    });

    // отрисовываем изначально список стилей
    this.renderParagraphStyles();

    // тут идут листенеры которые работают с режимом редактирования стиля
    this.$panel.on('click', '.add-block .save', this.saveEditClick);
    this.$panel.on('click', '.add-block .cancel', this.cancelEditClick);

    this.$panel.on('input', '.edit-content .caption', this.onEditCaptionChange);

    this.$panel.on('click', '.edit-content .switcher', this.onSwitcherClick);

    this.$panel.on('click', '.edit-content .color-click-area', this.onColorboxShowClick);

    this.$panel.on('click', '.edit-content .font-family-click-area', this.onFontSelectorShowClick);

    this.$panel.on('click', '.edit-content .font-style-click-area', this.onFontStyleShowClick);

    this.$panel.on('click', '.edit-scroll-wrapper', this.closeAllEditPopups);

    this.$panel.on('dblclick', '.paragraph-style', this.onDoubleClick);

    this.$panel.on('click', '.edit-content .tag-changer', this.changeStyleTag);

    this.colorbox = new Colorbox({ $parent: this.$('.colorbox_container'), type: 'small' });
    this.colorbox.on('colorchange', this.colorOpacityChanged);
    this.colorbox.on('opacitychange', this.colorOpacityChanged);

    this.fontselector = new FontSelector({ $parent: this.$('.fontselector_container') });
    this.fontselector.on('selectfont', this.fontChanged);

    this.editFontSizeChangeValue = $(this.$panel.find('.edit-content .font-size'))
      .RMNumericInput({
        min: this.params.minFontSize,
        max: this.params.maxFontSize,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editLineHeightChangeValue = $(this.$panel.find('.edit-content .line-height'))
      .RMNumericInput({
        min: this.params.minLineHeight,
        max: this.params.maxLineHeight,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editLetterSpacingChangeValue = $(this.$panel.find('.edit-content .letter-spacing'))
      .RMNumericInput({
        min: this.params.minLetterSpacing,
        max: this.params.maxLetterSpacing,
        mouseDir: 'h',
        useFloat: true,
        step: 0.2,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editPaddingLeftChangeValue = $(this.$panel.find('.edit-content .padding-left'))
      .RMNumericInput({
        min: 0,
        max: 999,
        mouseSpeed: 4,
        mouseDir: 'h',
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editPaddingRightChangeValue = $(this.$panel.find('.edit-content .padding-right'))
      .RMNumericInput({
        min: 0,
        max: 999,
        mouseSpeed: 4,
        mouseDir: 'h',
        invertMouse: true,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editPaddingTopChangeValue = $(this.$panel.find('.edit-content .padding-top'))
      .RMNumericInput({
        min: 0,
        max: 999,
        mouseSpeed: 4,
        invertMouse: true,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editPaddingBottomChangeValue = $(this.$panel.find('.edit-content .padding-bottom'))
      .RMNumericInput({
        min: 0,
        max: 999,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editScroll = $(this.$el.find('.edit-scroll-wrapper'))
      .RMScroll({
        $container: this.$el.find('.edit-content-wrapper'),
        $content: this.$el.find('.edit-content'),
        $handle: this.$el.find('.edit-scroll'),
        wheelScrollSpeed: 0.4,
        gap_start: 16,
        gap_end: 16,
        onScroll: this.onEditScroll.bind(this),
      })
      .data('scroll');
  },

  unBindLogic: function() {
    this.block.off(null, null, this);

    this.textStyles.off('change:paragraph_styles', this.onStylesChange);

    this.$panel.off('click', '.add-block .add', this.addClick);

    this.$panel.off('click', '.paragraph-style', this.onParagraphStyleClick);
    this.$panel.off('click', '.paragraph-style .tag-changer', this.changeStyleTag);
    this.$panel.off('click', '.paragraph-style .menu-button', this.toggleMenu);

    this.$panel.off('click', '.style-menu .redefine-button', this.onRedefineClick);
    this.$panel.off('click', '.style-menu .revert-button', this.onRevertClick);
    this.$panel.off('click', '.style-menu .edit-button', this.onEditClick);
    this.$panel.off('click', '.style-menu .duplicate-button', this.onDuplicateClick);
    this.$panel.off('click', '.style-menu .delete-button', this.onDeleteClick);

    this.$panel.off('dblclick', '.paragraph-style', this.onDoubleClick);

    this.$panel.off('click', '.edit-content .tag-changer', this.changeStyleTag);

    this.block && this.block.off('selection_styles_all_changed', this.selectionStylesAllChanged);

    this.$panel.off('click', '.add-block .save', this.saveEditClick);
    this.$panel.off('click', '.add-block .cancel', this.cancelEditClick);

    this.$panel.off('input', '.edit-content .caption', this.onEditCaptionChange);

    this.$panel.off('click', '.edit-content .switcher', this.onSwitcherClick);

    this.$panel.off('click', '.edit-content .color-click-area', this.onColorboxShowClick);

    this.$panel.off('click', '.edit-content .font-family-click-area', this.onFontSelectorShowClick);

    this.$panel.off('click', '.edit-content .font-style-click-area', this.onFontStyleShowClick);

    this.$panel.off('click', '.edit-scroll-wrapper', this.closeAllEditPopups);

    this.colorbox && this.colorbox.off('colorchange', this.colorOpacityChanged);
    this.colorbox && this.colorbox.off('opacitychange', this.colorOpacityChanged);
    this.colorbox && this.colorbox.destroy();
    this.colorbox = null;

    this.fontselector && this.fontselector.off('selectfont', this.fontChanged);
    this.fontselector && this.fontselector.destroy();
    this.fontselector = null;
  },

  // если какой-то коллаборатор изменил стили пока у нас открыта панелька
  // то просто перерендерим все стили в панелеке, чтобы долго не думать и не делать сложных механизмов синхронизации как в font-slector
  onStylesChange: function(model, attr, opts) {
    opts = opts || {};

    if (opts.socketUpdate) {
      this.renderParagraphStyles();
    }
  },

  // первоначальное создаение списка стилей в панельке
  renderParagraphStyles: function() {
    var stylesList = this.textStyles.getStylesListSorted('paragraph');

    var html = '';
    _.each(
      stylesList,
      function(style) {
        html += this.style_template(style);
      },
      this
    );

    this.$panel.find('.styles-wrapper').html(html);

    this.resizableScroll && this.resizableScroll.recalc();

    $(this.$panel.find('.styles-wrapper')).sortable('refresh');
  },

  // вызывается при каждом перетасквании стилей внутри панельки
  reorder: function() {
    var stylesOrder = [];

    this.$('.paragraph-style').each(function() {
      stylesOrder.push($(this).attr('data-id'));
    });

    this.textStyles.reorder('paragraph', stylesOrder);
  },

  // вызывается при любых изменениях внутри текстового редактора (включая клики мышкой, сдвиг курсора и пр.)
  selectionStylesAllChanged: function(styles_all) {
    if (!this.selected) return;

    // определяем текущие выделенные в панельке стили
    var prevActiveStyles = {};
    this.$('.paragraph-style.active').each(function() {
      prevActiveStyles[$(this).attr('data-id')] = true;
    });

    this.closeMenu(true);

    var usedStyles = {},
      allUsedStyles = {};

    // пробегаемся по всем найденным наборам стилей в выделении
    _.each(
      styles_all,
      _.bind(function(style, ind) {
        // ищем название стиля (класс) среди описаний стилей
        // если не нашли, считаем что класс не задан (например когда-то был назначен параграфу, а потом удален из панельки стилей)
        var styleRef = this.textStyles.findStyle('paragraph', style['paragraph-class']),
          styleName = styleRef ? styleRef._name : '',
          // выкидываем ненужные нам свойства (типа linkNode, _sort и пр.) + задаем порядок следования свойств
          // чтобы _.values с помощью которого мы генерируем хеш перечислял значения именно в этом порядке свойств
          pairs = _.pick.apply(undefined, _.union([style], this.STYLE_PROPERTIES)),
          key = _.values(pairs).join('|'); // хеш стиля

        if (!usedStyles[styleName]) usedStyles[styleName] = {};

        allUsedStyles[key] = true;

        // запоминаем первый стиль в выделении на кнопку Add style from selection
        if (ind == 0) this.$panel.find('.add-block .add').attr('data-redefine', key);

        // запоминаем хеш найденного стиля
        // получаем структуру вида:
        // {
        // 	paragraph-1: {
        // 		1d1a1b|100|arial|46|normal|700|0|58|start|none|none|baseline: true
        // 	}
        // 	paragraph-2: {
        // 		d1a1b|100|arial|28|normal|400|0|35|start|none|none|baseline: true,
        // 		ff0054|100|arial|28|normal|400|0|35|start|none|none|baseline: true
        // 	}
        // }
        // из которой мы понимаем, что в выделении попали параграфы с двумя разными стилями 	paragraph-1 и 	paragraph-2
        // и у параграфов со стилем paragraph-1 только один скалькулированный стиль внутри них
        // а у параграфов со стилем paragraph-2 два скалькулированных стиля внутри них
        if (styleRef) {
          // получаем хеш стиля который задан в настройках для класса styleName
          var refPairs = _.pick.apply(undefined, _.union([styleRef], this.STYLE_PROPERTIES)),
            refKey = _.values(refPairs).join('|'); // хеш стиля

          // если один из стилей в выделении совпадает со стилем класса примененного к параграфу то не добавлем его
          // нам он не интересен, поскольку нам инетересны только те скалькулированные стили внутри выделения которые отличаются от описания класса
          if (key != refKey) usedStyles[styleName][key] = true;
        } else usedStyles[styleName][key] = true;
      }, this)
    );

    // теперь мы знаем какие классы использованы в параграфах
    // и по каждому классу знаем какие внутри выделения есть стили которые отличаются от описания класса

    this.$('.paragraph-style').removeClass('active is-changed can-redefine can-override');

    _.each(
      usedStyles,
      _.bind(function(style, _name) {
        if (!_name) {
          this.$('.paragraph-style[data-id="paragraph-none"]').addClass('active');
          return;
        }

        var $style = this.$('.paragraph-style[data-id="' + _name + '"]');

        $style.addClass('active');

        // если есть хеши стилей которые отличаются от описания класса тогда считаем что класс переопределен внутри параграфа с выделением
        if (!_.isEmpty(style)) $style.addClass('is-changed can-override');
      }, this)
    );

    var allUsedStylesCount = _.keys(allUsedStyles).length;

    // если всего в выделении не больше 2-х стилей, т.е. 1 или 2
    // значит возможно некоторые классы можно переопределить под стиль выделения
    // в случае 2-х стилей можно переопределить в том случае если один из них совпадает со стилем класса (для переопределения соответственно берем другой)
    // в случае 1-го стиля можно переопределить в том случае если он НЕ совпадает со стилем класса
    if (allUsedStylesCount <= 2) {
      var allUsedStylesHashes = _.keys(allUsedStyles);

      this.$('.paragraph-style').each(
        _.bind(function(ind, obj) {
          var $obj = $(obj);

          // получаем хеш стиля который задан в настройках для класса styleName
          var styleRef = this.textStyles.findStyle('paragraph', $obj.attr('data-id'));

          // пустой или удален
          if (!styleRef) return;

          var refPairs = _.pick.apply(undefined, _.union([styleRef], this.STYLE_PROPERTIES)),
            refKey = _.values(refPairs).join('|'), // хеш стиля
            hashToRedefine;

          if (allUsedStylesCount == 1 && refKey != allUsedStylesHashes[0]) hashToRedefine = allUsedStylesHashes[0];

          if (allUsedStylesCount == 2 && refKey == allUsedStylesHashes[0]) hashToRedefine = allUsedStylesHashes[1];

          if (allUsedStylesCount == 2 && refKey == allUsedStylesHashes[1]) hashToRedefine = allUsedStylesHashes[0];

          hashToRedefine && $obj.addClass('can-redefine').attr('data-redefine', hashToRedefine);
        }, this)
      );
    }

    // определяем новые выделенные в панельке стили
    var curActiveStyles = {};
    this.$('.paragraph-style.active').each(function() {
      curActiveStyles[$(this).attr('data-id')] = true;
    });

    // прокручиваем к первому стилю
    if (!_.isEqual(prevActiveStyles, curActiveStyles)) {
      var $style = this.$('.paragraph-style.active').first();
      if ($style.length) {
        // смотрим виден ли текущий стиль в панелькк
        // если нет, тогда скролим так чтобы был виден
        var $wrapper = this.$panel.find('.resizable-content-wrapper'),
          top = $style.position().top,
          h = $wrapper.height(),
          styleHeight = $style.outerHeight(),
          newScroll,
          contentPadding = 8;

        if (top + styleHeight - $wrapper.scrollTop() > h - contentPadding) {
          newScroll = top + styleHeight - h + contentPadding;
        } else if (top - $wrapper.scrollTop() < contentPadding) {
          newScroll = top - contentPadding;
        }

        if (newScroll != undefined) {
          $wrapper.stop().animate({ scrollTop: newScroll }, 200);
        }
      }
    }
  },

  // при клике по стилю - применяем его к текущему выделению
  onParagraphStyleClick: function(e) {
    // если нажали на что-то внутри плашки с классом, а не на саму плашку (например на кружок с дропдаун меню)
    if ($(e.target).closest('.menu-button').length || $(e.target).closest('.tag-changer').length) return;

    var styleName = $(e.target)
      .closest('.paragraph-style')
      .attr('data-id');

    if (this.block) {
      // this.blocks[0].initiatorControl = this.name;
      if (styleName == 'paragraph-none') this.block.trigger('changeClassParagraph', { remove: true });
      else this.block.trigger('changeClassParagraph', { class: styleName });
    }
  },

  getNextStyleTag: function(currentTag) {
    // p > h1 > h2 > h3 > h4 > p
    var availableTags = Constants.AVAILABLE_TEXT_TAGS;
    var currentTagIndex = availableTags.indexOf(currentTag);
    if (currentTagIndex > -1) {
      return availableTags[currentTagIndex + 1] || availableTags[0];
    }

    return currentTag;
  },

  changeStyleTag: function(e) {
    if (this.currentEditStyle) {
      // Изменение тега из редактора стиля
      const newTag = this.getNextStyleTag(this.currentEditStyle.tag || 'p');
      this.currentEditStyle.tag = newTag;

      this.setEditParams(this.currentEditStyle);
      this.refreshEditorCssDebounced();
      this.renderParagraphStyles();
    } else {
      // Изменение тега из списка стилей
      var $target = $(e.target);
      var $el = $target.closest('.paragraph-style');
      var styleName = $el.attr('data-id');
      var currentTag = $el.attr('data-tag');
      var style = this.textStyles.findStyle('paragraph', styleName);

      style.tag = this.getNextStyleTag(currentTag);
      this.textStyles.changeStyle('paragraph', style._name);
      $target.text(style.tag);
      $el.attr('data-tag', style.tag);
    }
  },

  // клик по иконке меню внутри стиля
  toggleMenu: function(e) {
    var $button = $(e.currentTarget),
      state = $button.hasClass('active'),
      $menu = this.$('.style-menu');

    // если меню для денного стиля сейчас показано - тогда закроем его
    if (state) {
      this.closeMenu(true);
    } else {
      // если открыто меню другого стиля, закроем его мгновенно
      if (this.$('.paragraph-style .menu-button.active').length > 0) this.closeMenu(false);

      // с задержкой показываем меню для текущего стиля (не помню точо зачем задержка, вроде связана с вызовом closeMenu строкой выше)
      // меню позиционируем так чтобы оно выплывало прямо под иконкой
      // все дело в том, что меню у нас находится в dom иерархии гораздо выше, чтобы оно не обрезалось overflow:hidden
      setTimeout(
        _.bind(function() {
          var $style = $button.closest('.paragraph-style'),
            pos =
              $style.position().top +
              $button.position().top +
              $button.height() -
              this.$panel.find('.resizable-content-wrapper').scrollTop() -
              5;

          $menu
            .css('top', pos)
            .addClass('shown')
            .attr('data-id', $style.attr('data-id'))
            .toggleClass('can-redefine', $style.hasClass('can-redefine'))
            .toggleClass('can-override', $style.hasClass('can-override'));

          $button.addClass('active');
        }, this),
        40
      );
    }
  },

  // закрыть меню, с анимацией или без
  closeMenu: function(animate) {
    var $menu = this.$('.style-menu');

    if (!animate) $menu.removeClass('transition-enable');

    $menu.removeClass('shown');

    if (!animate) {
      setTimeout(function() {
        $menu.addClass('transition-enable');
      }, 20);
    }

    // во всех стилях кнопку вызова меню возвращаем в исходное состояние
    this.$('.paragraph-style .menu-button').removeClass('active');
  },

  // при скроле внтури панели закрываем меню, поскольку оно у нас в пропивном случае останется на месте и будет не красиво
  onPanelScroll: function(data) {
    this.closeMenu(true);
  },

  // переопределяем стиль на стиль из выделения
  onRedefineClick: function(e) {
    var $button = $(e.currentTarget),
      $menu = $button.closest('.style-menu'),
      $style = this.$('.paragraph-style[data-id="' + $menu.attr('data-id') + '"]'),
      s = $style.attr('data-redefine'), // типа 1d1a1b|100|arial|46|normal|400|0|58|start|none|none|baseline|0|0|0|0
      newStyle = _.object(this.STYLE_PROPERTIES, s.split('|'));

    this.textStyles.redefineStyle('paragraph', $menu.attr('data-id'), newStyle);

    RM.constructorRouter.textStyles.generateCSS('paragraph', 'editor', this.block.iframe_document);
    RM.constructorRouter.textStyles.generateCSS('paragraph', 'constructor', document);

    this.resizableScroll && this.resizableScroll.recalc();

    this.block.somethingChangedInEditor('styleRedefined');
  },

  // переписываем стиль всех параграфов в выделении на текущий и чистим внутри них все инлайн стили
  onRevertClick: function(e) {
    var $button = $(e.currentTarget),
      $menu = $button.closest('.style-menu'),
      styleName = $menu.attr('data-id');

    if (this.block) {
      // this.blocks[0].initiatorControl = this.name;
      if (styleName == 'paragraph-none') this.block.trigger('changeClassParagraph', { remove: true, override: true });
      else this.block.trigger('changeClassParagraph', { class: styleName, override: true });
    }
  },

  // клонируем стиль
  onDuplicateClick: function(e) {
    var $button = $(e.currentTarget),
      $menu = $button.closest('.style-menu'),
      styleName = $menu.attr('data-id'),
      style = this.textStyles.duplicateStyle('paragraph', styleName),
      $style = this.$('.paragraph-style[data-id="' + styleName + '"]');

    if (!style) return;

    RM.constructorRouter.textStyles.generateCSS('paragraph', 'editor', this.block.iframe_document);
    RM.constructorRouter.textStyles.generateCSS('paragraph', 'constructor', document);

    this.block.somethingChangedInEditor('styleAdded');

    var $elem = $(this.style_template(style));

    $elem.addClass('enable-transition').css('margin-top', -$style.outerHeight());

    $elem.insertAfter($style);

    setTimeout(
      _.bind(function() {
        $elem.css('margin-top', 0);

        setTimeout(
          _.bind(function() {
            $elem.removeClass('enable-transition');

            $(this.$panel.find('.styles-wrapper')).sortable('refresh');

            this.resizableScroll && this.resizableScroll.recalc();
          }, this),
          250 + 20
        );
      }, this),
      50
    );
  },

  // удаляем стиль
  onDeleteClick: function(e) {
    var $button = $(e.currentTarget),
      $menu = $button.closest('.style-menu'),
      styleName = $menu.attr('data-id'),
      $style = this.$('.paragraph-style[data-id="' + styleName + '"]');

    this.closeMenu(true);

    $style.addClass('enable-transition');

    setTimeout(
      _.bind(function() {
        $style.addClass('shift-left');

        setTimeout(
          _.bind(function() {
            $style.css('margin-top', -$style.outerHeight());

            setTimeout(
              _.bind(function() {
                $style.remove();

                $(this.$panel.find('.styles-wrapper')).sortable('refresh');

                this.resizableScroll && this.resizableScroll.recalc();

                RM.constructorRouter.textStyles.deleteStyle('paragraph', styleName);

                RM.constructorRouter.textStyles.generateCSS('paragraph', 'editor', this.block.iframe_document);
                RM.constructorRouter.textStyles.generateCSS('paragraph', 'constructor', document);

                this.block.somethingChangedInEditor('styleDeleted');
              }, this),
              250 + 20
            );
          }, this),
          250 + 20
        );
      }, this),
      50
    );
  },

  // добавить новый стиль, параметры стиля берутся из первого найденного стиля в текущем выделении
  addClick: function(e) {
    var styleHash = $(e.currentTarget).attr('data-redefine'),
      newStyle = _.object(this.STYLE_PROPERTIES, styleHash.split('|')),
      style = this.textStyles.addStyle('paragraph', newStyle);

    RM.constructorRouter.textStyles.generateCSS('paragraph', 'editor', this.block.iframe_document);
    RM.constructorRouter.textStyles.generateCSS('paragraph', 'constructor', document);

    this.block.somethingChangedInEditor('styleAdded');

    var $elem = $(this.style_template(style));

    $elem.addClass('enable-transition').addClass('collapsed');

    this.$panel.find('.styles-wrapper').append($elem);

    this.$el.find('.resizable-content-wrapper').scrollTop(9999);

    setTimeout(
      _.bind(function() {
        $elem.removeClass('collapsed');

        setTimeout(
          _.bind(function() {
            $elem.removeClass('enable-transition');

            $(this.$panel.find('.styles-wrapper')).sortable('refresh');

            this.resizableScroll && this.resizableScroll.recalc();
          }, this),
          250 + 20
        );
      }, this),
      50
    );
  },

  // при пересчете скрола (например при изменени размера панельки)
  // заодно пересчитываем скрол в панельке редактирования стиля
  onScrollRecalc: function(initiator) {
    this.editScroll && this.editScroll.recalc();
  },

  //открытие панели редактирования стиля
  openMenu: function(styleName) {
    var style = this.textStyles.findStyle('paragraph', styleName);
    this.originalEditStyle = _.clone(style);
    this.currentEditStyle = style;
    this.setEditParams(this.currentEditStyle);

    this.$panel.addClass('toggle-opaque');

    this.closeMenu(true);

    setTimeout(
      _.bind(function() {
        this.$panel.addClass('show-style-edit');
      }, this),
      250 + 50
    );
  },

  // открытие панельки редактирования стиля через button
  onEditClick: function(e) {
    var $button = $(e.currentTarget),
      $menu = $button.closest('.style-menu'),
      styleName = $menu.attr('data-id');
    this.openMenu(styleName);
  },

  // открытие панельки редактирования стиля двойным кликом
  onDoubleClick: function(e) {
    var styleName = $(e.target)
      .closest('.paragraph-style')
      .attr('data-id');
    this.openMenu(styleName);
  },

  // закрытие панельки редактирования стиля
  editClose: function() {
    this.closeAllEditPopups();

    RM.constructorRouter.textStyles.generateCSS('paragraph', 'editor', this.block.iframe_document);
    RM.constructorRouter.textStyles.generateCSS('paragraph', 'constructor', document);

    this.resizableScroll && this.resizableScroll.recalc();

    this.block.somethingChangedInEditor('styleChanged');

    this.$panel.removeClass('show-style-edit');

    setTimeout(
      _.bind(function() {
        this.$panel.removeClass('toggle-opaque');
      }, this),
      350 + 50
    );

    // Очищаем редактируемый стиль
    this.currentEditStyle = undefined;
  },

  // устанавливаем состояние панельки редактирование в соответсвии с нужным стилем (params)
  setEditParams: function(params) {
    var $parent = this.$panel.find('.edit-content');

    // название стиля
    $parent.find('.caption').val(params._caption);

    var font = RM.constructorRouter.fonts.findFontByCSSName(params['font-family']);

    // имя шрифта
    $parent
      .find('.font-family')
      .text(font.name)
      .css('font-family', font.css_name);

    // стиль шрифта
    var fontStyleName = RM.constructorRouter.fonts.getStyleName(params['font-weight'], params['font-style'], font.name);
    $parent
      .find('.font-style')
      .text(fontStyleName)
      .css({
        'font-family': font.css_name,
        'font-style': params['font-style'],
        'font-weight': params['font-weight'],
      });

    // цыет
    $parent.find('.color-value').text('#' + params.color);
    $parent.find('.color-circle').css({ background: '#' + params.color, opacity: params.opacity / 100 });

    // размер шрифта, интерлиньяж и межбуквенный интервал
    this.editFontSizeChangeValue(params['font-size'], true);
    this.editLineHeightChangeValue(params['line-height'], true);
    this.editLetterSpacingChangeValue(params['letter-spacing'], true);

    // выравниваение
    $parent.find('.text-align-left').toggleClass('active', params['text-align'] == 'left');
    $parent.find('.text-align-center').toggleClass('active', params['text-align'] == 'center');
    $parent.find('.text-align-right').toggleClass('active', params['text-align'] == 'right');
    $parent.find('.text-align-justify').toggleClass('active', params['text-align'] == 'justify');

    // text-transform, text-decoration
    $parent.find('.text-transform').toggleClass('active', params['text-transform'] == 'uppercase');
    $parent.find('.text-underline').toggleClass('active', params['text-decoration'] == 'underline');
    $parent.find('.text-strike').toggleClass('active', params['text-decoration'] == 'line-through');

    // марджины
    this.editPaddingLeftChangeValue(params['padding-left'], true);
    this.editPaddingRightChangeValue(params['padding-right'], true);
    this.editPaddingTopChangeValue(params['padding-top'], true);
    this.editPaddingBottomChangeValue(params['padding-bottom'], true);

    // тэг
    $parent.find('.tag-changer span').text(params.tag || 'p');
  },

  // при изменениях в интупе с именем стиля
  onEditCaptionChange: function(e) {
    this.currentEditStyle._caption = $(e.target).val();
  },

  // при изменениях в размер шрифта, интерлиньяж и межбуквенный интервал и марджинах
  onEditInputChanged: function($input, new_num) {
    this.currentEditStyle[$input.attr('data-param')] = new_num;

    this.refreshEditorCssDebounced();
  },

  // при переключении выравнивания и text-transform, text-decoration
  onSwitcherClick: function(e) {
    var param = $(e.target).attr('data-param'),
      val = $(e.target).attr('data-val');

    if (this.currentEditStyle[param] == val) this.currentEditStyle[param] = param == 'text-align' ? 'start' : 'none';
    else this.currentEditStyle[param] = val;

    this.setEditParams(this.currentEditStyle);

    this.refreshEditorCssDebounced();
  },

  // при изменении цвета-прозрачности через колорбокс
  colorOpacityChanged: function(r, g, b, a) {
    this.currentEditStyle.color = this.colorbox && this.colorbox.rgb2hex([r, g, b]);
    this.currentEditStyle.opacity = a * 100;

    this.setEditParams(this.currentEditStyle);

    this.refreshEditorCssDebounced();
  },

  // при смене шрифта через фонтселектор
  fontChanged: function(font_family) {
    this.currentEditStyle['font-family'] = font_family;

    this.setEditParams(this.currentEditStyle);

    this.refreshEditorCssDebounced();
  },

  // при выборе стиля шрифта в выпадалке стиля шрифта
  styleChanged: function(e) {
    this.currentEditStyle['font-weight'] = $(e.currentTarget).attr('data-font-weight');
    this.currentEditStyle['font-style'] = $(e.currentTarget).attr('data-font-style');

    this.setEditParams(this.currentEditStyle);

    this.refreshEditorCssDebounced();

    this.closeFontStyle();
  },

  // переформировать новый <style> для редактора
  refreshEditorCss: function() {
    RM.constructorRouter.textStyles.generateCSS('paragraph', 'editor', this.block.iframe_document);

    this.block.somethingChangedInEditor('styleChanging');
  },

  // при клике по полю с цветом (показ-прятание колорбокса)
  onColorboxShowClick: function(e) {
    if (this.colorbox_visible) {
      this.closeColorBox();
      return;
    }

    this.closeAllEditPopups();

    this.colorbox_visible = true;

    this.$('.colorbox_container').fadeIn(200);

    // устанавливаем параметры в колорбоксе
    this.colorbox.setOpacity(this.currentEditStyle.opacity / 100);
    this.colorbox.setColor(this.currentEditStyle.color);

    this.positionPopup(this.$('.colorbox_container'), this.$('.edit-content .color-click-area'));
  },

  // при клике по полю с именем шрифта (показ-прятание фонтселектора)
  onFontSelectorShowClick: function(e) {
    if (this.fontselector_visible) {
      this.closeFontSelector();
      return;
    }

    this.closeAllEditPopups();

    this.fontselector_visible = true;

    this.fontselector && this.fontselector.show([this.currentEditStyle['font-family']]);

    this.positionPopup(this.$('.fontselector_container'), this.$('.edit-content .font-family-click-area'));
  },

  // при клике по полю с стилем шрифта (показ-прятание попапа стиля шрифта)
  onFontStyleShowClick: function(e) {
    if (this.fontstyle_visible) {
      this.closeFontStyle();
      return;
    }

    this.closeAllEditPopups();

    // заполняем выпадалку стилей
    var $container = this.$('.fontstyle_container .font-style-wrapper');
    $container.empty();

    var font = RM.constructorRouter.fonts.findFontByCSSName(this.currentEditStyle['font-family']);

    if (!font) return;

    _.each(
      font.variations,
      _.bind(function(variation) {
        var variation_weight = (variation.substr(1, 1) - 0) * 100,
          variation_style = variation.substr(0, 1) == 'n' ? 'normal' : 'italic',
          variation_name = RM.constructorRouter.fonts.getStyleName(variation_weight, variation_style, font.name);

        var $item = $('<div>')
          .appendTo($container)
          .addClass('font-style-item')
          .text(variation_name)
          .css({
            'font-family': font.css_name,
            'font-weight': variation_weight + '',
            'font-style': variation_style,
          })
          .attr('data-font-weight', variation_weight)
          .attr('data-font-style', variation_style)
          .on('click', this.styleChanged);

        // элемент "точка" которая и ховер и текущий
        var $point = $('<div>')
          .appendTo($item)
          .addClass('point');

        // отмечаем текущий стиль
        if (
          variation_weight == this.currentEditStyle['font-weight'] &&
          variation_style == this.currentEditStyle['font-style']
        ) {
          $item.addClass('curr');
        }
      }, this)
    );

    this.fontstyle_visible = true;

    this.$('.fontstyle_container').fadeIn(200);

    this.positionPopup(this.$('.fontstyle_container'), this.$('.edit-content .font-style-click-area'));
  },

  // позиционирует блок попапа так, чтобы он был по центру панели, но не ниже чем соответствующий пункт панельки редактирования
  positionPopup: function($container, $menuItem) {
    var $content = $container.children().first(),
      popupH = $content.height(),
      panelH = this.$panel.height(),
      centerY = Math.floor((panelH - popupH) / 2),
      menuItemY = $menuItem.position().top - this.$panel.find('.edit-content-wrapper').scrollTop();

    $container.css({ top: Math.min(centerY, menuItemY) });
  },

  // закрывает колорбокс
  closeColorBox: function() {
    this.colorbox_visible = false;
    this.$('.colorbox_container').fadeOut(200);
  },

  // закрывает фонтселектор
  closeFontSelector: function() {
    this.fontselector_visible = false;
    this.fontselector.hide();
  },

  // закрывает попап стиля шрифта
  closeFontStyle: function() {
    this.fontstyle_visible = false;
    this.$('.fontstyle_container').fadeOut(200);
  },

  // закрыть все выпадалки
  closeAllEditPopups: function(e) {
    if (e) {
      if ($(e.target).closest('.colorbox_container').length > 0) return;
      if ($(e.target).closest('.color-click-area').length > 0) return;

      if ($(e.target).closest('.fontselector_container').length > 0) return;
      if ($(e.target).closest('.font-family-click-area').length > 0) return;

      if ($(e.target).closest('.fontstyle_container').length > 0) return;
      if ($(e.target).closest('.font-style-click-area').length > 0) return;
    }

    if (this.colorbox_visible) this.closeColorBox();

    if (this.fontselector_visible) this.closeFontSelector();

    if (this.fontstyle_visible) this.closeFontStyle();
  },

  // при скроле внутри панельки редактирования стиля закрываем все попапы
  onEditScroll: function() {
    this.closeAllEditPopups();
  },

  // клик по кнопке сохранения отредактированного стиля
  saveEditClick: function() {
    var $style = this.$('.paragraph-style[data-id="' + this.currentEditStyle._name + '"]');

    $style.find('.style-caption').text(this.currentEditStyle._caption);

    this.textStyles.changeStyle('paragraph', this.currentEditStyle._name);

    this.editClose();
  },

  // по нажатию на "Enter" происходит сохранение отредактированного стиля:
  onEnterKey: function() {
    if (this.$panel.hasClass('show-style-edit')) {
      this.saveEditClick();
    }
  },

  // клик по кнопке отмены редактирования стиля
  cancelEditClick: function() {
    this.textStyles.restoreStyle('paragraph', this.currentEditStyle._name, this.originalEditStyle);

    this.editClose();
  },

  // вызывается при уничтожении контрола - сохраняет сортировку стилей внутри панели (если она поменялась)
  save: function() {
    if (_.isEqual(this.textStyles.getOrder('paragraph'), this.textStyles.orderBefore['paragraph'])) return;

    this.textStyles.save();
  },

  /**
   * Переопределяем реакцию закрытия паельки контрола
   */
  deselect: function() {
    this.closeAllEditPopups();

    ControlResizableClass.prototype.deselect.apply(this, arguments);
  },

  // расширяем метод уничтожения контрола, если он в режиме редактирования стиля - просто сохраняем все изменения
  destroy: function() {
    if (this.$panel.hasClass('show-style-edit')) this.saveEditClick();

    // принудительно вызываем save для сохранения порядка стилей
    // в прототипе destroy это уже не вызывается для данного контрола, потому что него нет модели
    // т.е. это костыль такой
    this.save();

    ControlResizableClass.prototype.destroy.apply(this, arguments);
  },

  // расширяем метод который срабатывает при открытии панели контрола
  select: function() {
    ControlResizableClass.prototype.select.apply(this, arguments);

    // при открытии панельки проставляем состояние контрола в зависимости от текущего стиля выделения в редакторе
    // (при закрытой панельке в ней ничего не обновляется, для скорости)
    this.block && this.selectionStylesAllChanged(this.block.cur_selection_styles_all);

    PreloadDesignImages('controls-text_styles');
  },

  // расширяем метод клика по иконке контрола
  onClick: function() {
    if (this.selected) {
      if (this.colorbox_visible || this.fontselector_visible || this.fontstyle_visible) {
        this.closeAllEditPopups();
        return;
      }

      if (this.$('.style-menu').hasClass('shown')) {
        this.closeMenu(true);
        return;
      }
    }

    ControlResizableClass.prototype.onClick.apply(this, arguments);
  },

  // расширяем метод реакции на кнопку Esc
  onEscKey: function() {
    if (this.selected) {
      if (this.colorbox_visible || this.fontselector_visible || this.fontstyle_visible) {
        this.closeAllEditPopups();
        return;
      }

      if (this.$('.style-menu').hasClass('shown')) {
        this.closeMenu(true);
        return;
      }
    }

    ControlResizableClass.prototype.onEscKey.apply(this, arguments);
  },

  /**
   * расширяем функцию которая решает поглощает контрол событие или нет (обычно это событие deselect воркспейса)
   */
  canControlBeClosed: function() {
    if (this.colorbox_visible || this.fontselector_visible || this.fontstyle_visible) {
      this.closeAllEditPopups();
      return true;
    }

    if (this.$('.style-menu').hasClass('shown')) {
      this.closeMenu(true);
      return true;
    }

    return ControlResizableClass.prototype.canControlBeClosed.apply(this, arguments);
  },
});

export default TextStyles;
