<template>
  <div class="font-style-container">
    <span class="example" :style="exStyle" @click="toggleVisible">{{ styleVariants[key].caption }}</span>
    <div class="expand-arrow" @click="toggleVisible"></div>
    <div class="box_container" :class="{ visible: visibleBox, 'from-top': fromTop }" :style="boxStyles">
      <div
        v-for="(font, ind) in styleVariants"
        class="font-elem"
        :class="key == ind ? 'curr' : ''"
        :style="font.style"
        @click="selectFont(font, ind)"
      >
        {{ font.caption }}
        <div class="point"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from '@rm/underscore';

var styleVariants = {
  normal: {
    caption: 'Regular',
    style: {
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },
  italic: {
    caption: 'Italic',
    style: {
      fontWeight: 400,
      fontStyle: 'italic',
    },
  },
  bold: {
    caption: 'Bold',
    style: {
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
  bolditalic: {
    caption: 'Bold Italic',
    style: {
      fontWeight: 700,
      fontStyle: 'italic',
    },
  },
};
export default {
  model: {
    event: 'change',
  },

  data: function() {
    return {
      visibleBox: false,
      boxStyles: {},
      styleVariants: {},
    };
  },

  props: {
    fontStyle: {
      type: String,
      default: '',
    },

    fontFamily: {
      type: String,
      default: '',
    },

    fontWeight: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    exStyle: function() {
      return {
        'font-style': this.fontStyle,
        'font-weight': this.fontWeight,
      };
    },

    fromTop: function() {
      return _.keys(this.styleVariants).length > 5;
    },

    key: function() {
      var key = (this.fontStyle == 'italic' ? 'i' : 'n') + ('' + this.fontWeight)[0];
      if (!this.styleVariants[key]) {
        if (this.styleVariants['n4']) {
          key = 'n4';
        } else {
          key = _.keys(this.styleVariants)[0];
        }
        this.$emit('update:fontStyle', key[0] == 'i' ? 'italic' : 'normal');
        this.$emit('update:fontWeight', parseInt(key[1], 10) * 100);
      }
      return key;
    },
  },

  watch: {
    fontStyle: function() {},
    fontFamily: {
      handler: function(fontFamily) {
        var params = RM.constructorRouter.fonts.findFontByCSSName(fontFamily),
          style = {
            i: 'italic',
            n: 'normal',
          };

        this.styleVariants = {};
        _.map(params.variations, v => {
          this.styleVariants[v] = {
            caption: this.generateName(v),
            style: {
              fontWeight: parseInt(v[1], 10) * 100,
              fontStyle: style[v[0]],
            },
          };
        });
      },
      immediate: true,
    },
  },

  methods: {
    generateName: function(style) {
      var capts = {
        '1': 'Thin',
        '2': 'ExtraLight',
        '3': 'Light',
        '4': 'Regular',
        '5': 'Medium',
        '6': 'SemiBold',
        '7': 'Bold',
        '8': 'ExtraBold',
        '9': 'Black',
      };
      var isItalic = style[0] == 'i',
        name = capts[style[1]];
      if (isItalic && name == 'Regular') {
        return 'Italic';
      }
      return name + (isItalic ? ' Italic' : '');
    },
    selectFont: function(font) {
      this.$emit('update:fontStyle', font.style.fontStyle);
      this.$emit('update:fontWeight', font.style.fontWeight);
    },

    toggleVisible: function() {
      if (!this.visibleBox) {
        this.$emit('showPopup', this);
      }
      this.visibleBox = !this.visibleBox;
    },
  },
};
</script>

<style lang="less">
@import (reference) '../../css/common/constants.less';
.font-style-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .example {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    flex: 0 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    cursor: pointer;
  }

  .box_container {
    -webkit-transform: translate3d(0, 0, 0);
    position: absolute;
    z-index: 1;
    display: none;
    width: 152px;
    top: 27px;
    left: -18px;
    border: 1px solid #dddddd;
    background: white;
    cursor: pointer;
    &.from-top {
      top: auto;
      bottom: 27px;
    }
    &.visible {
      display: block;
    }
    .font-elem {
      padding: 7px 23px 8px;
      position: relative;
      .point {
        position: absolute;
        width: 6px;
        height: 6px;
        left: 10px;
        top: 12px;
      }

      &:hover .point {
        background: url('../../img/constructor/controls/text_styles/point-hover.png') no-repeat 0 0;
      }

      &.curr .point {
        background: url('../../img/constructor/controls/text_styles/point-active.png') no-repeat 0 0 !important;
      }
    }
  }

  .expand-arrow {
    flex: 0 0 17px;
    width: 7px;
    height: 5px;
    background-repeat: no-repeat;
    background-size: 7px 5px;
    background-image: url('../../img/constructor/controls/button_settings/arrow.png');
    .transition();
    cursor: pointer;
  }
  &:hover {
    .expand-arrow {
      background-image: url('../../img/constructor/controls/button_settings/arrow-active.png');
    }
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  .font-style-container {
    .expand-arrow {
      background-size: 7px 5px;
      background-image: url('../../img/constructor/controls/button_settings/arrow@2x.png');
    }
    &:hover {
      .expand-arrow {
        background-size: 7px 5px;
        background-image: url('../../img/constructor/controls/button_settings/arrow-active@2x.png');
      }
    }
    .box_container {
      .font-elem {
        &:hover .point {
          background-image: url('../../img/constructor/controls/text_styles/point-hover@2x.png');
          background-size: 6px 6px;
        }
        &.curr .point {
          background-image: url('../../img/constructor/controls/text_styles/point-active@2x.png') !important;
          background-size: 6px 6px !important;
        }
      }
    }
  }
}
</style>
