/**
 * Конструктор для контрола настроек слайдшоу
 */

import PreloadDesignImages from '../../common/preload-design-images';
import VueControlClass from '../vue-control';

const SlideshowSettings = VueControlClass.extend({
  name: 'slideshow_settings',
  alt: 'Settings',
  panelComponent: 'panel-slideshow-settings',

  select: function() {
    VueControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-slideshow_settings');

    this.block.updateDropZone();
  },
  // смена мода приведет к закрытию попапа
  closePopup: function() {
    this.model.set('current_mode', 'default');
  },

  // при нажатии на esc вначале закроет попап, если он открыт
  onEscKey: function() {
    if (this.selected) {
      if (this.checkNeedClosePopup()) {
        this.closePopup();
        return;
      }
    }

    VueControlClass.prototype.onEscKey.apply(this, arguments);
  },

  // проверит не открыт ли попап
  checkNeedClosePopup: function() {
    return this.model.get('current_mode') !== 'default';
  },

  // клик по воркспейсу не сгенерить deselect, если возвращается true
  canControlBeClosed: function() {
    if (this.checkNeedClosePopup()) {
      this.closePopup();
      return true;
    }

    VueControlClass.prototype.canControlBeClosed.apply(this, arguments);
  },

  /**
   * Переопределяем реакцию закрытия паельки контрола
   */
  deselect: function() {
    // нужно чтобы клик по кнопке контрола не закрывал панель
    if (this.checkNeedClosePopup()) {
      this.closePopup();
      return;
    }
    VueControlClass.prototype.deselect.apply(this, arguments);
  },

  destroy: function() {
    if (this.block.needRasterize) {
      this.block.rasterizeIconsBeforeDestroy();
      delete this.block.needRasterize;
    }

    VueControlClass.prototype.destroy.apply(this, arguments);
  },
});

export default SlideshowSettings;
