<template>
  <div id="mobile-header" :class="{ opened: menuOpened }">
    <div class="hamburger">
      <button class="open-menu" @click="onTridotClick">
        <span>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 4">
            <path
              fill="#282828"
              fill-rule="evenodd"
              d="M2 4C.8954 4 0 3.1046 0 2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2zm6 0c-1.1046 0-2-.8954-2-2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2zm6 0c-1.1046 0-2-.8954-2-2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2z"
            />
          </svg>
        </span>
      </button>
    </div>
    <div class="inner">
      <div class="links">
        <a :href="`${homeUrl}/features`" :class="{ active: activeLink === 'features' }">Features</a>
        <a :href="`${homeUrl}/pricing/`" :class="{ active: activeLink === 'pricing' }">Pricing</a>
        <a :href="`${homeUrl}/explore/`" :class="{ active: activeLink === 'examples' || activeLink === 'explore' }"
          >Examples</a
        >
        <a href="https://learn.readymag.com/" :class="{ active: activeLink === 'learn' }">Learn</a>

        <a :href="`${homeUrl}/join/`" v-if="!isAuthorized">Join or Log in</a>
        <a :href="`${profileUrl}/logout`" v-if="isAuthorized">Log out</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Utils, Constants } from '../../js/common/utils';

export default {
  data: () => ({
    isAuthorized: false,
    profileUrl: Constants.readymag_auth_host,
    homeUrl: Constants.readymag_host,
    menuOpened: false,
  }),

  props: {
    activeLink: String,
  },

  methods: {
    onTridotClick: function() {
      this.menuOpened = !this.menuOpened;
    },
  },
};
</script>

<style lang="less">
@orange: #ec520b;
@font: Graphik;

#mobile-header {
  display: none;

  .constructor:not(.preview) & {
    display: none !important;
  }

  .hamburger {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    margin: 6px 6px;
    z-index: 99999999;

    .open-menu {
      width: 48px;
      height: 48px;
      background: #fff;
      border-radius: 50%;
      border: none;
      padding: 0;
      display: inline-block;

      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    transform: translateX(100%);
    background: #fff;
    transition: transform 0.3s ease-in-out;
    box-sizing: border-box;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .links {
    box-sizing: border-box;
    padding: 68px 20px;
    width: 100%;

    a {
      display: block;
      color: #000;
      text-decoration: none;
      font-family: @font;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      letter-spacing: -2.4px;
      margin-bottom: 40px;

      &.active {
        color: @orange;
        cursor: default;
        pointer-events: none;
      }

      &:hover {
        color: @orange;
      }
    }
  }

  &.opened {
    .inner {
      transform: translateX(0);
    }
  }
}

.rm-header-mobile {
  #mobile-header {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .rm-header-mobile;
}
</style>
