import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import BlockClass from '../block';
import BlockFrameClass from '../block-frame';
import templates from '../../../templates/constructor/helpers/animation-steps.tpl';
import { Utils } from '../../common/utils';
import AnimationUtils from '../../common/animationutils';

/**
 * конструктор для блока шага анимации
 */

// один шаг анимации, наследуется от блока
var AnimationStep = BlockClass.extend({
  controls: [],

  initialize: function(options) {
    _.extend(this, options);

    this.isStandalone = true;
    this.proportional = true; // ресайзим всегда пропорционально и от центра
    this.resizeFromCenter = true;

    this.addButtonTemplate = templates['template-constructor-helpers-animation_steps_add_button'];

    this.frameClass = BlockFrameClass.extend({
      minwidth: 0,
      minheight: 0,
    });

    this.initBlock(this.model, this.workspace);
  },

  render: function() {
    this.create();

    this.$el.addClass('animation-step');

    this.$mainContent = this.$('.content');

    this.$addButton = $(this.addButtonTemplate());

    this.$addButton.find('.effect-popup-item[data-value="use_rotate"]').toggle(!this.hideRotateEffect);

    this.$addButton.on('click', '.effect-popup-item', this.addStepWithEffectClick);

    this.$el.append(this.$addButton);

    // у нас внутри будут виджеты у которых тоже есть .block и .content
    // нужны доп. классы чтобы отличать родительский content и вложеный
    this.$mainContent.addClass('main-content');
  },

  addStepWithEffectClick: function(e) {
    this.trigger('add-step', $(e.currentTarget).attr('data-value'));

    e.stopPropagation();
  },

  select: function() {
    BlockClass.prototype.select.apply(this, arguments);

    this.updateAddButtonPos();
  },

  setContent: function($innerContent) {
    this.$innerContent = $innerContent;

    this.$innerContent.addClass('fantoms');

    this.$mainContent.html(this.$innerContent);

    this.redrawOpacity();

    this.redrawScale();
  },

  show: function() {
    this.$el.show();
  },

  hide: function() {
    this.$el.hide();
  },

  onClick: function() {
    if (!this.selected) {
      this.select();
    }
  },

  flashTransitions: function() {
    clearTimeout(this.flashTransitionsTimeout);

    this.$el.addClass('animation-transitions');

    this.flashTransitionsTimeout = setTimeout(
      _.bind(function() {
        this.$el.removeClass('animation-transitions');
      }, this),
      200
    );
  },

  redrawOpacity: function() {
    if (!this.$innerContent) return;

    this.$innerContent.css({
      opacity: this.model.get('opacity') / 100,
    });
  },

  redrawScale: function() {
    if (!this.$innerContent) return;

    // скейлим внутренний контент с клонами виджетов
    Utils.applyTransform(
      this.$innerContent,
      this.model.get('scale') == 100 ? '' : 'scale(' + this.model.get('scale') / 100 + ')'
    );
  },

  updateAddButtonPos: function() {
    if (!this.selected) return; // оптимизация

    if (!this.$addButton) return;

    var l = this.latestPosSizeAngle;

    // позиционируем ромбик с плюсом
    var dot_y = l.height + 18,
      cx = l.width / 2, // находим центр вращения (центр бокса)
      cy = l.height / 2,
      dy = cy - dot_y,
      dot_x = cx + l.sinAngle * dy;
    dot_y = cy - l.cosAngle * dy;

    this.$addButton.css({
      left: Math.round(dot_x),
      top: Math.round(dot_y),
    });

    Utils.applyTransform(this.$addButton, '');

    // позиционирем рормбик так, чтобы он всегда был виден на экране
    var pos = this.$addButton.offset(),
      rombSize = 30,
      top = pos.top + rombSize / 2,
      left = pos.left + rombSize / 2,
      offsetTop = 0,
      offsetLeft = 0,
      wh = $(window).height(),
      ww = $(window).width(),
      padding = 32;

    if (top < padding) {
      offsetTop = top - padding;
    } else if (top > wh - padding) {
      offsetTop = top - (wh - padding);
    }

    if (left < padding) {
      offsetLeft = left - padding;
    } else if (left > ww - padding) {
      offsetLeft = left - (ww - padding);
    }

    if (offsetTop || offsetLeft) {
      Utils.applyTransform(
        this.$addButton,
        'translate(' + Math.round(-offsetLeft) + 'px, ' + Math.round(-offsetTop) + 'px)'
      );
    }
  },

  // позиционируем панельку так, чтобы она полностью была видна при открытии
  updateAddButtonPopupPos: function() {
    if (!this.$addButton) return;

    if (!this.selected) return;

    var pos = this.$addButton.offset(),
      cornerSize = 15,
      rombSize = 30,
      top = pos.top + rombSize / 2,
      left = pos.left + rombSize / 2,
      wh = $(window).height(),
      ww = $(window).width(),
      $popup = this.$addButton.find('.effect-popup'),
      ph = $popup.height(),
      pw = $popup.width(),
      padding = 12;

    $popup
      .css('margin-top', '')
      .find('.corner-wrapper')
      .css('margin-top', '');

    // если с боков достаточно места то просто решаем где показать попап сверху или снизу
    if (left > padding + pw / 2 && left < ww - pw / 2) {
      if (top > padding + ph + rombSize / 2 + cornerSize) {
        $popup.attr('data-pos', 'top');
      } else {
        $popup.attr('data-pos', 'bottom');
      }
    } else {
      if (left < ww / 2) {
        $popup.attr('data-pos', 'right');
      } else {
        $popup.attr('data-pos', 'left');
      }

      // если ромбик в углах и панельку надо смещать
      if (top < ph / 2 + padding) {
        $popup
          .css('margin-top', ph / 2 + padding - top)
          .find('.corner-wrapper')
          .css('margin-top', -(ph / 2 + padding - top));
      }

      if (top > wh - (ph / 2 + padding)) {
        $popup
          .css('margin-top', -(top - wh + ph / 2 + padding))
          .find('.corner-wrapper')
          .css('margin-top', top - wh + ph / 2 + padding);
      }
    }
  },

  css: function() {
    BlockClass.prototype.css.apply(this, arguments);

    this.updateAddButtonPos();
  },

  // вызывается при смене модели
  // обработаем отдельно свойства opacity и scale
  // базовый css блока который в итоге отрабатывает в redraw не знает этих свойств, они чисто для AnimationStep
  redraw: function() {
    if (!this.$mainContent) return;

    this.redrawOpacity();

    this.redrawScale();

    BlockClass.prototype.redraw.apply(this, arguments);
  },
});

// менеджер шагов анимаций
const AnimationStepsClass = Backbone.View.extend({
  className: 'animation-steps-container',

  StepModel: Backbone.Model.extend({
    save: function() {
      this.set.apply(this, arguments);
      return null; // чтобы XHR никто не сохранял и не пытался абортить
    },
    sync: function() {
      return null; // чтобы XHR никто не сохранял и не пытался абортить
    },
    getViewport: function() {
      return self.blocks[0].model.getViewport();
    },
  }),

  // сразу и рендерится, чтобы огород не городить
  initialize: function(params) {
    _.bindAll(this);

    _.extend(this, params);

    // просто любая модель из выделенных блоков
    // когда мы работаем с анимациями в выделении у них у всех одинаковые данные по анимациям
    this.someModel = this.blocks[0].model;

    this.workspace.switchOnAnimationMode(this);

    // бестолковая скрочка, просто откуда-то скопировал где похожее было
    this.$el.appendTo(this.workspace.$el.children().first());

    this.$workspaceScroll = this.workspace.$el.parent();

    // запрещаем таскать сам шаг изначального состояния (полько ресайз и поворот) проставляя в модели is_locked
    // а также навешивая StopPropagation иначе при драге будет рисоваться рамка воркспейса
    // остальные шаги сами на себя навешивают  драги и это им не помешает
    $(this.$el).drag('start', function(e) {
      e.stopPropagation();
    });

    // враппер в котором лежат все линии и все шаги
    // для включения и выключения режима превью
    this.$stepsLinesWrapper = $('<div class="steps-lines-wrapper">');

    this.$el.append(this.$stepsLinesWrapper);

    // враппер в котором лежит клон всех исходных виджетов
    // для режима превью
    this.$previewAnimator = $('<div class="preview-animator">');

    this.$el.append(this.$previewAnimator);

    // добавляем обработку попапа
    this.popupManager.addPopup({
      $parent: this.$el,
      name: 'add-step-selector',
      selector: '.block .add-button',
      popupSelector: '.block .add-button .effect-popup',
      cbOpen: function($popupTrigger) {
        this.currentStep && this.currentStep.updateAddButtonPopupPos();
        _.each(this.blocks, function(block) {
          block.trigger('animation:stepsPopup:open', $popupTrigger);
        });
      }.bind(this),
      cbClose: function() {
        _.each(this.blocks, function(block) {
          block.trigger('animation:stepsPopup:closed');
        });
      }.bind(this),
    });

    this.updateContainerDims();

    this.updateFantoms();

    this.updateSteps();

    _.each(this.blocks, function(block) {
      block.toggleAnimationMode(true);
    });

    this.$previewAnimator.css({ visibility: 'hidden' });

    this.isPreviewMode = false;

    $(window).on('resize', this.onResize);

    this.$workspaceScroll.on('scroll', this.onWorkspaceScroll);
  },

  startPreviewMode: function() {
    if (this.isPreviewMode) return;

    this.isPreviewMode = true;

    this.$previewAnimator.css({ visibility: 'inherit' });

    this.$stepsLinesWrapper.css({ visibility: 'hidden' });

    this.updatePreviewMode({ immediate: true });
  },

  updatePreviewMode: function(params) {
    params = params || {};

    if (!this.isPreviewMode) return;

    var self = this;

    clearTimeout(this.updatePreviewModeTimeout);

    if (params.immediate) {
      f();
    } else {
      this.updatePreviewModeTimeout = setTimeout(f, 500);
    }

    function f() {
      self.previewTimeline && self.previewTimeline.destroy();

      var animation = AnimationUtils.getNormalizedAnimation(
          self.someModel.get('animation'),
          self.isFixed,
          self.isFullwidth,
          self.isFullheight,
          1
        ),
        force3d = true,
        i;

      // отключаем принудительное аппаратное ускорение есть в шагах есть скейлы больше 100
      // иначе анимируется некрасиво, пикселизованно и только в конце анимации перерендеривает
      // это нормальное и правильное поведение, для скорости, но нам тут важнее эстетический момент
      for (i = 0; i < animation.steps.length; i++) {
        var step = animation.steps[i];
        if (step.use_scale && ((i == 0 && step.from_scale > 100) || step.scale > 100)) {
          force3d = false;
          break;
        }
      }

      self.previewTimeline = AnimationUtils.createAnimationTimeline({
        el: self.$previewAnimator,
        steps: animation.steps,
        type: animation.type,
        force3d: force3d,
        loop: AnimationUtils.normalizeLoopValue(animation.loop),
        // Конструктор таймлайна экстендит себя параметрами, а свойство trigger (от backbone.events) у него уже есть
        animationTrigger: animation.trigger,
        screenshotMode: false,
      });

      if (animation.type != 'scroll') {
        self.previewTimeline.play();
      } else {
        self.onWorkspaceScroll();
      }
    }
  },

  stopPreviewMode: function() {
    if (!this.isPreviewMode) return;

    this.isPreviewMode = false;

    this.$previewAnimator.css({ visibility: 'hidden' });

    this.$stepsLinesWrapper.css({ visibility: 'inherit' });

    this.previewTimeline && this.previewTimeline.destroy();
  },

  togglePreviewMode: function() {
    this.isPreviewMode ? this.stopPreviewMode() : this.startPreviewMode();
  },

  onWorkspaceScroll: function() {
    this.currentStep && this.currentStep.updateAddButtonPos();

    if (!this.previewTimeline) return;

    if (!this.isPreviewMode) return;

    var animation = this.someModel.get('animation');

    if (animation.type != 'scroll') return;

    var currentScroll = Math.max(0, this.$workspaceScroll.scrollTop()); // оттяжка на маках может давать отрицательный скрол

    // фикседы скролятся сразу
    if (this.isFixed) {
      this.previewTimeline.seek(currentScroll);
      return;
    }

    var percentageMap = {
      top: 1,
      center: 0.5,
      bottom: 0,
    };

    var per100 = this.baseDimensions.top,
      per0 = per100 - this.$workspaceScroll.height(),
      perStart = percentageMap[animation.steps[0].start_point || 'bottom'],
      scrollStart = perStart * (per100 - per0) + per0 + animation.steps[0].start_offset || 0;

    if (scrollStart <= 0) {
      this.previewTimeline.seek(currentScroll);
    } else {
      this.previewTimeline.seek(Math.max(0, currentScroll - scrollStart));
    }
  },

  updateContainerDims: function() {
    var bBox;

    _.each(
      this.blocks,
      function(block) {
        var blockBox = block.getBoxData({ includeBoundingBox: true, checkFixedPosition: true });

        bBox = bBox || {
          t: Number.POSITIVE_INFINITY,
          l: Number.POSITIVE_INFINITY,
          b: Number.NEGATIVE_INFINITY,
          r: Number.NEGATIVE_INFINITY,
        };

        bBox.t = Math.min(bBox.t, blockBox.bb_y);
        bBox.l = Math.min(bBox.l, blockBox.bb_x);
        bBox.b = Math.max(bBox.b, blockBox.bb_y + blockBox.bb_h);
        bBox.r = Math.max(bBox.r, blockBox.bb_x + blockBox.bb_w);
      },
      this
    );

    // размеры группы виджетов к которым применяем анимации
    this.baseDimensions = {
      left: bBox.l + (this.isFixed ? this.workspace.position.left : 0),
      top: bBox.t + (this.isFixed ? this.workspace.position.top : 0),
      width: bBox.r - bBox.l,
      height: bBox.b - bBox.t,
    };

    this.$el.css({
      width: bBox.r - bBox.l,
      height: bBox.b - bBox.t,
      position: this.isFixed ? 'fixed' : 'absolute',
    });

    this.$previewAnimator.css(this.baseDimensions);
  },

  createStep: function(params) {
    params = params || {};

    // в качестве шагов анимации будем использовать немного модифицированные базовые блоки
    // в них уже реализовано таскание, ресайз, повороты
    // модель тоже берем базовую (не передаем ее блок создаст сам, этот умеет)
    var step = new AnimationStep({
        model: new this.StepModel(),
        workspace: this.workspace,
        constraintHorzMove: this.isFullwidth,
        constraintVertMove: this.isFullheight,
        hideRotateEffect: this.isFullwidth || this.isFullheight,
        $standaloneContainer: this.$stepsLinesWrapper,
      }),
      self = this;

    // при выделении этого шага снимаем выделение со всех остальных
    step.on('select', function() {
      self.currentStep = this;
      if (self.steps) {
        // бежим по всем шагам + 1 (<=)
        // чтобы последним проверить отдельный, изначальный шаг
        for (var i = 0; i <= self.steps.length; i++) {
          var $line = self.$lines && self.$lines[i] && self.$lines[i].find('line'),
            step = i == self.steps.length ? self.initialStep : self.steps[i];

          if (this != step) {
            step.deselect();
            $line && $line.attr('stroke-dasharray', '4,2');
          } else {
            if (!self.isIndirectSelect) {
              self.trigger('select', step.model.get('id') == 'initial' ? 0 : step.model.get('id')); // изначальное состояние также указывает на нудевой шаг (он там и прописан через from_)
            }
            $line && $line.removeAttr('stroke-dasharray');
          }
        }
      }
    });

    // изначальный шаг нельзя таскать (только ресайзить и поворачивать)
    // пользуемся стандартным функционалом блока
    step.model.set({
      id: params.id,
      is_locked: params.id == 'initial',
    });

    step.render();

    step.setContent(this.getFantom());

    if (params.id != 'initial') {
      step.on(
        'move',
        _.bind(function(changeSet) {
          // пересчитываем координаты блока обратно в координаты шага и его масштаб
          // операция обратная тому, что делается в setStepModel
          // триггерим ивенты чтоюы контрол анимаций сам обновил данные в панельке и в моделях
          this.trigger('move', {
            id: step.model.get('id'),
            dx: Math.round(
              changeSet.left - this.baseDimensions.left + (changeSet.width - this.baseDimensions.width) / 2
            ),
            dy: Math.round(
              changeSet.top - this.baseDimensions.top + (changeSet.height - this.baseDimensions.height) / 2
            ),
          });

          // надо обновить щаги в которых параметры наследуются
          this.updateSteps({ exceptStep: step });
        }, this)
      );
    }

    step.on(
      'resize',
      _.bind(function(changeSet) {
        if (params.id == 'initial') {
          this.trigger('scale', {
            id: 0, // значения изначального шага у нас также хранятся в первом в списке шаге с префиксами from_ (у первого шага индекс нулевой)
            from_scale: Math.round((100 * changeSet.width) / this.baseDimensions.width), // префикс from_
          });
        } else {
          this.trigger('scale', {
            id: step.model.get('id'),
            scale: Math.round((100 * changeSet.width) / this.baseDimensions.width),
          });
        }

        // при ресайзе у нас меняются только размеры бокса, а параметр scale у нас внутри блока не расчитывается, он про него не знает
        // а нам он нужен чтобюы скейлить внутренний контент
        // потому при ресайзе мысами одновляем в модели этот параметр
        // вызваем с сайлентом, мы не хотим чтобы весь redraw для виджета сработал
        step.model.set(
          {
            scale: (100 * changeSet.width) / this.baseDimensions.width,
          },
          { silent: true }
        );

        // сами обновим внутренний скейл контента
        step.redrawScale();

        // надо обновить щаги в которых параметры наследуются
        this.updateSteps({ exceptStep: step });
      }, this)
    );

    // сообщаем панельке что надо добавить шаг с выбранным эффектом после текущего
    step.on(
      'add-step',
      _.bind(function(use_effect) {
        this.trigger('add-step', {
          after_step: step.model.get('id'),
          use_effect: use_effect,
        });

        this.popupManager.closePopup('add-step-selector');
      }, this)
    );

    return step;
  },

  setStepModel: function(step, params) {
    var base = this.baseDimensions,
      nw = (base.width * params.scale) / 100,
      nh = (base.height * params.scale) / 100;

    step.model.set(
      {
        x: base.left + base.width / 2 + params.dx - nw / 2,
        y: base.top + base.height / 2 + params.dy - nh / 2,
        w: nw,
        h: nh,
        z: 1,
        angle: params.rotate,
        opacity: params.opacity, // этого свойства у базовых блоков нет, утт они нужны нам именно для варианта блока AnimationStep
        scale: params.scale, // и этого тоже
        aspect: base.width / base.height, // и этого
      },
      { silent: true }
    );

    // просим перерсоваться самому, поскольку silent: true
    // на нужно изначально проставлять без триггера change потому что у нас есть слушатель,
    // который слушает изменения в модели при сдвиге/ресайзе/повороте и сообщает контролу анимаций
    // что надо изменить данные шага за который отвечает данный фрейм
    step.redraw(step.model);
  },

  getFantom: function(block) {
    var $content = block ? block.$el.clone() : this.$fantoms.clone();

    // для картинок грязно хакаем - просим поставить картинку - оригинал, чтобы нормально работать с зумами
    if (
      block &&
      block.model.get('type') == 'picture' &&
      block.model.get('picture') &&
      block.model.get('picture').unscaledUrl
    ) {
      $content.find('.content div').css('background-image', 'url(' + block.model.get('picture').unscaledUrl + ')');
    }

    // костыль для шейпа (и иконки хотспота еще)
    // у нас используются идишники для масок и клиппинга внутри шейпа и если их просто клонировать то перестают показываться шейпы с этими идишниками везде
    var ids = [];
    $content.find('defs mask, defs clippath').each(function() {
      ids.push($(this).attr('id'));
    });

    // костыль для хотспота
    // удаляем открытый типс
    if (block && block.model.get('type') == 'hotspot') {
      $content.find('.tip').remove();
    }

    // Удаляем сдублированную плашку размеров
    $content.find('.size-tool').remove();

    // заменяем все старые идишники новыми
    if (ids.length) {
      var content = $content[0].outerHTML;
      for (var i = 0; i < ids.length; i++) {
        var newId = Utils.generateUUID(),
          regex = new RegExp('(' + Utils.escapeSpecial(ids[i], false) + ')', 'g');
        content = content.replace(regex, newId);
      }
      $content = $(content);
    }

    return $content;
  },

  // клонируем исходные виджеты в отдельный контейнер
  // настоящие мы потом спрячем при показе
  // а эти склонированные виджеты (только DOM без логики, оболочки визуальные в общем)
  // будем пихать в каждый шаг и там скейлить и пр.
  // назовем их фантомами, для разнообразия
  updateFantoms: function() {
    this.$fantoms && this.$fantoms.remove();

    this.$fantoms = $('<div>').css({
      position: 'relative',
      left: 0,
      top: 0,
      width: this.baseDimensions.width,
      height: this.baseDimensions.height,
    });

    _.each(
      this.blocks,
      _.bind(function(block) {
        // клонируем весь блок исходного виджета
        // обязательно через getFantom чтобы профиксить шейпы
        // с нимим баг из-за идишников масок и клипингов
        // если вдруг в думе появятся два с одинаковыми идишниками - и кто-либо из них перерисуется - крантец, оба исчезают или еще что-то
        // к слову block.$el.clone() в ДУМ не вставляется, но при перерисовке исходных виджетов все равно возникает проблема
        // в общем если сразу фиксить на новые идишникик проблем вроде нет

        var $clone = this.getFantom(block);

        // удаляем фреймбордер
        $clone.find('.frameborder').remove();

        $clone.removeClass('animation-mode');

        if (this.isFixed) {
          // фикседы располагаем всегда по центру контейнера баундинг бокса
          // в нулях нельзя ведь могут быть повороты и повернутые части вылезут за пределы
          // потому как в нулях будет расположен исходный блок, без трансофрмаций
          $clone.css({
            position: 'absolute',
            left: '50%',
            top: '50%',
            bottom: '',
            right: '',
            'margin-left': -block.model.get('w') / 2,
            'margin-top': -block.model.get('h') / 2,
          });
        } else {
          // сдвигаем его так, чтобы они все шли в контейнере от нуля
          $clone.css({
            left: '-=' + this.baseDimensions.left,
            top: '-=' + this.baseDimensions.top,
          });
        }

        this.$fantoms.append($clone);
      }, this)
    );

    this.$previewAnimator.html(this.getFantom());
  },

  updateSteps: function(params) {
    // линии надо создавать/обновлять до шагов, потому что в шагах обновляются состояния линий выделена/не выделена
    // проще так в одном месте пояснить, чем переколбашивать чтобы порядок был не важен
    this.updateLines();

    params = params || {};

    // запоняем объект анимация данными без пробелов, если в каком-то шаге есть выключенные эффекты, из значения возьмется и последнего шага у которого они есть или из дефолта если нет таких шагов
    // фильтруем поля которых быть не должно и пр.
    var animation = AnimationUtils.getNormalizedAnimation(
      this.someModel.get('animation'),
      this.isFixed,
      this.isFullwidth,
      this.isFullheight,
      1
    );

    this.initialStep = this.initialStep || this.createStep({ id: 'initial' });

    if (params.animateStepInd === 0) {
      this.initialStep.flashTransitions();
    }

    if (params.exceptStep != this.initialStep) {
      this.setStepModel(this.initialStep, {
        dx: 0,
        dy: 0,
        opacity: animation.steps[0].from_opacity,
        rotate: animation.steps[0].from_rotate,
        scale: animation.steps[0].from_scale,
      });
    }

    this.steps = this.steps || [];

    for (var i = 0; i < animation.steps.length; i++) {
      this.steps[i] = this.steps[i] || this.createStep({ id: i });
      // при включении/выключении эффектов нам надо шаг с которым это происходит анимировать, чтобы было ясно что произошло визуально
      // просим включить транзишены на несколько долей секунды
      // также просим это сделать для всех последующих шагов, ведь у них могут быть наследуемые параметры и они тоже должны поменяться с анимацией
      if (params.animateStepInd != undefined && params.animateStepInd <= i) {
        this.steps[i].flashTransitions();
      }

      if (params.exceptStep != this.steps[i]) {
        this.setStepModel(this.steps[i], animation.steps[i]);
      }
    }

    // остальные удаляем, если фреймов сейчас сделано больше чем шагов
    for (var i = animation.steps.length; i < this.steps.length; i++) {
      this.steps[i].model.destroy();
      this.steps[i].destroy();
      this.steps[i].off();
    }

    this.steps.length = animation.steps.length;
  },

  updateLines: function() {
    var dx = 0,
      dy = 0,
      prevDx = 0,
      prevDy = 0,
      lineWeight = 4; // с запасом, так-то 2

    this.$lines = this.$lines || [];

    // запоняем объект анимация данными без пробелов, если в каком-то шаге есть выключенные эффекты, из значения возьмется и последнего шага у которого они есть или из дефолта если нет таких шагов
    // фильтруем поля которых быть не должно и пр.
    var animation = AnimationUtils.getNormalizedAnimation(
      this.someModel.get('animation'),
      this.isFixed,
      this.isFullwidth,
      this.isFullheight,
      1
    );

    for (var i = 0; i < animation.steps.length; i++) {
      if (!this.$lines[i]) {
        this.$lines[i] = $('<svg class="step-line"><line /></svg>');
        this.$lines[i].appendTo(this.$stepsLinesWrapper);
      }

      var stepData = animation.steps[i],
        line = this.$lines[i].find('line').get(0),
        x1 = this.baseDimensions.width / 2 + prevDx,
        y1 = this.baseDimensions.height / 2 + prevDy,
        x2 = this.baseDimensions.width / 2 + stepData.dx,
        y2 = this.baseDimensions.height / 2 + stepData.dy,
        w = Math.abs(x1 - x2) + lineWeight * 2, // отступы по краям чтобы жирной линии было где рисоваться
        h = Math.abs(y1 - y2) + lineWeight * 2;

      this.$lines[i].css({
        width: w,
        height: h,
        left: this.baseDimensions.left + Math.min(x1, x2) - lineWeight,
        top: this.baseDimensions.top + Math.min(y1, y2) - lineWeight,
      });

      this.$lines[i].show();

      line.setAttributeNS(null, 'x1', lineWeight);
      line.setAttributeNS(null, 'x2', w - lineWeight);

      if ((x1 < x2 && y1 < y2) || (x1 > x2 && y1 > y2)) {
        line.setAttributeNS(null, 'y1', lineWeight);
        line.setAttributeNS(null, 'y2', h - lineWeight);
      } else {
        line.setAttributeNS(null, 'y1', h - lineWeight);
        line.setAttributeNS(null, 'y2', lineWeight);
      }

      prevDx = stepData.dx;
      prevDy = stepData.dy;
    }

    // остальные прячем, если фреймов сейчас сделано больше чем шагов
    for (var i = animation.steps.length; i < this.$lines.length; i++) {
      this.$lines[i].hide();
    }
  },

  selectStep: function(ind) {
    this.isIndirectSelect = true;
    this.steps[ind].select();
    this.isIndirectSelect = false;
  },

  // сдвиг шагов по стрелкам клавиатуры
  // вызывается из воркспейса когда мы в режиме анимаций (вместо того чтобы двигать выделенные блоки)
  // там вызывается из роутера
  // код очень похож на тот что используется в воркспейсе в функции moveBlocks
  moveSelected: function(event, direction) {
    // изначальный шаг нельзя двигать
    var steps = _.filter(_.union(this.steps, this.initialStep), function(s) {
      return !s.isLocked() && s.selected;
    });

    if (_.isEmpty(steps)) return;

    // Не двигаем по горизонтали растянутые блоки
    if (this.isFullwidth && _(['left', 'right']).contains(direction)) return;

    if (this.isFullheight && _(['up', 'down']).contains(direction)) return;

    var translation = event.shiftKey ? 10 : 1;

    var tmpSnap = RM.constructorRouter.gg.snap;
    RM.constructorRouter.gg.snap = false;

    var move = {
      deltaY: 0,
      deltaX: 0,
    };

    switch (direction) {
      case 'up':
        move.deltaY = -translation;
        break;
      case 'right':
        move.deltaX = translation;
        break;
      case 'down':
        move.deltaY = translation;
        break;
      case 'left':
        move.deltaX = -translation;
        break;
    }

    AnimationStep.prototype.moveBlocks(null, steps, move);

    RM.constructorRouter.gg.snap = tmpSnap;

    event.stopPropagation();
    event.preventDefault();
  },

  // удаление шагов по Del и Backspace
  // вызывается из воркспейса когда мы в режиме анимаций (вместо того чтобы удалять выделенные блоки)
  // там вызывается из роутера
  removeSelected: function() {
    var steps = _.filter(_.union(this.steps, this.initialStep), function(s) {
      return s.selected;
    });

    if (_.isEmpty(steps)) return;

    // сами ничего не делаем, просто просим панельку все сделать за нас и все обработать как надо
    this.trigger('remove-step', steps[0].model.get('id'));
  },

  onResize: function() {
    if (this.isFullwidth || this.isFullheight || this.isFixed || this.isSticked) {
      this.updateContainerDims();
      this.updateSteps();
    }

    if (this.isFullwidth) {
      clearTimeout(this.updateFantomsTimer);

      this.updateFantomsTimer = setTimeout(
        _.bind(function() {
          this.updateFantoms();

          this.initialStep.setContent(this.getFantom());

          for (var i = 0; i < this.steps.length; i++) {
            this.steps[i].setContent(this.getFantom());
          }
        }, this),
        100
      );
    }

    this.onWorkspaceScroll();
  },

  destroy: function() {
    this.stopPreviewMode();

    _.each(this.blocks, function(block) {
      block.toggleAnimationMode(false);
    });

    this.$fantoms && this.$fantoms.remove();

    if (this.initialStep) {
      this.initialStep.model.destroy();
      this.initialStep.destroy();
      this.initialStep.off();
    }

    if (this.steps) {
      for (var i = 0; i < this.steps.length; i++) {
        this.steps[i].model.destroy();
        this.steps[i].destroy();
        this.steps[i].off();
      }
    }

    this.remove();

    this.workspace.switchOffAnimationMode();

    $(window).off('resize', this.onResize);

    this.$workspaceScroll.off('scroll', this.onWorkspaceScroll);
  },
});

export default AnimationStepsClass;
