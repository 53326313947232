/**
 * Данные и методы для работы с глобальными виджетами в конструкторе и вьювере
 */
import _ from '@rm/underscore';
import AnimationUtils from './animationutils';
import { Utils } from './utils';
import Viewports from './viewports';

const GlobalWidgets = {
  GLOBAL_UNIQUE_KEYS: ['hidden', 'z'], // Свойства глобальных виджетов, которые должны быть уникальными для каждой страницы
  GLOBAL_UNIQUE_KEY_PREFIX: '_', // Префикс названия свойств глобальных виджетов, содержащих уникальные значения для каждой страницы
  FADE_DURATION: 200,

  /**
   * Возвращает значение заданного атрибута глобального виджета,
   * актуальное для текущей страницы. Ищет его переданном наборе атрибутов
   * @param  {String} attr - название атрибута
   * @param  {Object} viewport_data - переданный набор атрибутов, включая массивы с уник. значениями (если треубется найти свойство не в текущих атрибутах)
   * @param  {String} pid - айдишник страницы, для которой возвращать уникальное значение
   */
  getUniqueValue: function(attr, viewport_data, pid) {
    var _valRecords = viewport_data[this.GLOBAL_UNIQUE_KEY_PREFIX + attr] || [];
    var _record = _.findWhere(_valRecords, { pid: pid });

    // Если не находим запись с уникальным свойством для данной страницы,
    // то берем начальное (дефолтное значение свойства)
    _record = _record || _.findWhere(_valRecords, { pid: 'default' }) || {};

    return _record.value === undefined ? viewport_data[attr] : _record.value; // Запись для {pid: 'default'}, по-идее должна быть всегда, но подстрахуемся и возвратим ориг. значение если ее нет
  },

  /**
   * Очищает старые настройки уникальных атрибутов глобального виджета,
   * перед включением глобальности
   */
  resetGlobalData: function(model, currentVP) {
    var data = {},
      self = this;
    _.each(Viewports.viewport_listall, function(viewport) {
      _.each(self.GLOBAL_UNIQUE_KEYS, function(key) {
        if (viewport === 'viewport_' + currentVP) {
          model.set(self.GLOBAL_UNIQUE_KEY_PREFIX + key, []);
        } else {
          if (!_.isEmpty(model[viewport])) {
            model[viewport][self.GLOBAL_UNIQUE_KEY_PREFIX + key] = [];
          }
        }
      });
    });
    return data;
  },

  /**
   * Проверяет, может ли это свойство быть индивидуальным для каждого инстанса глобального виджета
   * @param {String} key Свойство. Например, "hidden"
   * @returns {Boolean}
   */
  isGlobalKey: function(key) {
    return this.GLOBAL_UNIQUE_KEYS.indexOf(key) !== -1;
  },

  /**
   * Возвращает специальное свойство с префиксом, в котором хранятся индивидуальные значения свойства для инстансов глобального виджета
   * @param {String} key Свойство. Например, "hidden"
   * @returns {String}
   */
  getGlobalPrefixedKey: function(key) {
    return this.isGlobalKey(key) ? this.GLOBAL_UNIQUE_KEY_PREFIX + key : null;
  },

  /**
   * Заполняет и возвращает объект со свойствами-массивами уникальных значений заданных свойств
   * для каждой страницы. Возвращаются полные массивы со свойствами для текущей страницы и для тех
   * страниц, для которых они были созданы ранее
   * Напр. если для свойства hidden: false вернется объект вида (т.е. массив уже содержит уник. свойства для двух страниц)
   * , "_hidden": [{value: false, pid: "582eadee4a7a5e39c32a54f0"}, {value: true, pid: "582eadee4a7a5e39c32a54f1"}]
   * @param {Object} attrs - объект с аттрибутами и значениями, переданный в set модели. (обязательно объект, key/val не поддерживается)
   * @param  {Object} viewport_data - данные, в которых  будем искать и создавать уник. свойства
   * @param  {String} pid - айдишник страницы, для которой будем задавать уникальные свойства
   * @return {Object} - объект со свойствами-массивами
   */

  fillUniqueAttributeSets: function(attrs, viewport_data, pid) {
    var uniqueAttrs = {};

    if (!viewport_data.is_global) {
      return attrs;
    }

    _(this.GLOBAL_UNIQUE_KEYS).each(
      function(gkey) {
        if (!_.has(attrs, gkey) || attrs[gkey] === undefined) {
          return;
        }

        var _valRecords = viewport_data[this.GLOBAL_UNIQUE_KEY_PREFIX + gkey] || [];
        var _record = _.findWhere(_valRecords, { pid: pid });
        var _defRecord = _.findWhere(_valRecords, { pid: 'default' });

        if (_record) {
          _record.value = attrs[gkey];
        } else {
          _valRecords.push({ pid: pid, value: attrs[gkey] });
        }

        // В записи вида {pid: 'default', value: false} единственный раз сохраняется
        // начальное значение аттрибута, чтобы подставлять его в основное значение аттрибута,
        // если уникальное значение для страницы ниразу не устанавливалось.
        // Иными словами, если мы скрываем глобальный виджет на одной странице, то чтобы он
        // не скрывался на других
        if (!_defRecord) {
          _valRecords.push({ pid: 'default', value: viewport_data[gkey] === undefined ? null : viewport_data[gkey] }); // null, иначе при сохранении ключи с undefined отбрасываются
        }

        uniqueAttrs[this.GLOBAL_UNIQUE_KEY_PREFIX + gkey] = _valRecords;
      }.bind(this)
    );

    return uniqueAttrs;
  },

  /**
   * Заполняет для всех глобальных аттрибутов дефолтную запись для страницы
   * Т.е. если например в данных не было поля _hidden, то оно появится в виде:
   * _hidden: [{pid: 'default', value: true}]
   * @param  {[type]} viewport_data данные вьюпорта
   */
  ensurePageDefRecord: function(viewport_data) {
    _(this.GLOBAL_UNIQUE_KEYS).each(
      function(gkey) {
        var _valRecords = viewport_data[this.GLOBAL_UNIQUE_KEY_PREFIX + gkey] || [];
        var _defRecord = _.findWhere(_valRecords, { pid: 'default' });

        // В записи вида {pid: 'default', value: false} единственный раз сохраняется
        // начальное значение аттрибута, чтобы подставлять его в основное значение аттрибута,
        // если уникальное значение для страницы ниразу не устанавливалось.
        // Иными словами, если мы скрываем глобальный виджет на одной странице, то чтобы он
        // не скрывался на других
        if (!_defRecord) {
          _valRecords.push({ pid: 'default', value: viewport_data[gkey] === undefined ? null : viewport_data[gkey] }); // null, иначе при сохранении ключи с undefined отбрасываются
        }

        viewport_data[this.GLOBAL_UNIQUE_KEY_PREFIX + gkey] = _valRecords;
      }.bind(this)
    );
  },

  /**
   * Восстанавливает в переданных данных уникальные свойства для заданной страницы
   * Важно! В переданных данных должны содержаться и массивы уникальных свойств,
   * в которых и будут искаться значения для страницы
   * @param  {Object} viewport_data - данные для вьюпорта, включая массивы уник. свойтств
   * @param  {String} pid - айдишник страницы
   * @param  {Object} opts - опции. {props: ['hidden']} - чтобы восстановить значения для избранных полей
   */
  fillUniqueValues: function(viewport_data, pid, opts) {
    opts = opts || {};
    // Для свойств, которые должны быть уникальны для странцы,
    // восстанавливаем нужное значение из спец. свойства-массива
    _.each(
      viewport_data,
      function(val, key) {
        if (!_.contains(this.GLOBAL_UNIQUE_KEYS, key) || (opts.props && !_.contains(opts.props, key))) {
          return;
        }

        var uniqueVal = this.getUniqueValue(key, viewport_data, pid);
        viewport_data[key] = uniqueVal === undefined ? val : uniqueVal;
      },
      this
    );
  },

  /**
   * Проверяет, спрятан ли виджет
   * @param {Object} viewport_data Aтрибуты виджета в одном из вьюпортов
   * @param {String} pid Id страницы
   * @param {Boolean} isPreview Флаг, находимся ли в режиме превью
   */
  isHidden: function(viewport_data, pid, isPreview) {
    // Виджет спрятан если он спрятан или если у него анимация, которая должна проигрываться один раз одному пользователю, и она уже проигрывалась
    return (
      this.getUniqueValue('hidden', viewport_data, pid) || (!isPreview && AnimationUtils.hasExpired(viewport_data))
    );
  },

  /**
   * Показывает виджет с фейдом
   * @param {RM.classes.Widget} widget
   * @param {Boolean} [immediately] Сразу без фейда
   */
  show: function(widget, immediately) {
    // Приводим к булеву значению, потому что в toggleClass, например, нужно передавать false чтобы убрать класс
    // (если передать undefined, то класс не уберётся)
    immediately = Boolean(immediately);
    widget.$el.toggleClass('no-transition', immediately);
    widget.$el.addClass('above-all-fade');
    if (!widget.$el.hasClass('fade-in') || immediately) {
      widget.cancelHide && widget.cancelHide();
      widget.show();
      _.delay(function() {
        widget.$el.addClass('fade-in');
      }, 50);
    }
  },

  /**
   * Прячет виджет с фейдом
   * @param {RM.classes.Widget} widget
   * @param {Boolean} [immediately] Сразу без фейда
   * @returns {Promise} Если виджет уже спрятан, промис выполняется сразу. Если не спрятан, то после того как закончится транзишен и вызовется hide()
   */
  hide: function(widget, immediately) {
    // Приводим к булеву значению, потому что в toggleClass, например, нужно передавать false чтобы убрать класс
    // (если передать undefined, то класс не уберётся)
    immediately = Boolean(immediately);
    widget.$el.addClass('above-all-fade');
    return new window.Promise(function(resolve) {
      if (widget.$el.hasClass('fade-in') || immediately) {
        widget.$el.toggleClass('no-transition', immediately);
        widget.$el.removeClass('fade-in');
        // Если нужно, спрячем виджет сразу и сразу резолвим промис
        if (immediately) {
          widget.hide();
          resolve();
          // Если нужно прятать с фейдом, подождём окончания транзишена
        } else {
          widget.cancelHide = Utils.waitForTransitionEnd(
            widget.$el,
            GlobalWidgets.FADE_DURATION,
            'opacity',
            function() {
              widget.hide();
              resolve();
            }
          );
        }
      } else {
        resolve();
      }
    });
  },
};

export default GlobalWidgets;
