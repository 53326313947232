/**
 * @desc Базовый интерфейс между контрол-менеджером на backbone и контролами на vue
 */
import $ from '@rm/jquery';
import Vue from 'vue';
import _ from '@rm/underscore';
import ControlClass from './control';
import PreloadDesignImages from '../common/preload-design-images';
import ControlVue from '../../components/constructor/controls/control.vue';

const VueControlClass = ControlClass.extend({
  /**
   * Имя контрола для менеджера контролов. Он же id элемента
   */
  name: null,

  /**
   * Имя vue-компонента контрол-панели
   */
  panelComponent: null,

  $el: null,

  /**
   * Alt-текст для иконки
   */
  alt: null,

  saveOnDeselect: true,
  saveOnDestroy: true,

  /**
   * Контролами управляет controls-manager.js, который ничего не знает о Vue.
   * Если какой-либо контрол был скрыт/не отрендерен при вызове control.func(),
   * то в этот момент его this.vue будет undefined.
   * Это вызывает некорректную работу тех методов контрола, которые зависят от this.vue,
   * поэтому их необходимо обрачивать в waitForRender.
   */
  waitForRender: function(func, args) {
    return this.isRendered()
      ? func.apply(this, args)
      : Vue.nextTick(
          function() {
            return func.apply(this, args);
          }.bind(this)
        );
  },

  initialize: function(options) {
    options = options || {};
    ControlClass.prototype.initialize.apply(this, arguments);

    this.block = this.blocks[0];
    this.model = options.models && options.models.length && options.models[0];

    this.y = options.y || options.fromY;

    this.bindShortcuts();
  },

  getData: function() {
    return {
      cssClass: this.name,
      panelComponent: this.panelComponent,
      faded: false,
      selected: false,
      disabled: false,
      y: this.y,
      visible: false,
      alt: this.alt,
      // Свойствами модели могут быть массивы и объекты
      block: JSON.parse(JSON.stringify(this.model.attributes)),
      raw_block: this.blocks[0],
      loading: false,
      appearanceAnimation: this.appearanceAnimation,
    };
  },

  onModelChange: function() {
    this.waitForRender(this._onModelChange.bind(this), arguments);
  },

  _onModelChange: function(model) {
    // обновляем данные из модели, берем все данные в модели но не перезаписываем undefined ключи чтобы не получить в numeric input пустых значений вместо 0
    this.vue.block = _.extend(
      {},
      this.vue.block,
      _.pick(_.cloneWithObjects(model.attributes), function(v) {
        return v !== undefined;
      })
    );
  },

  onPanelChange: function(update) {
    this.model.set(_.cloneWithObjects(update));
  },

  render: function() {
    if (!this.isRendered()) {
      var proxy = this;

      var tapIntoEmit = function(control) {
        var originalEmit = control.$emit;
        // Подменяем метод emit контрола так, чтобы он информировал прокси контрола о каждом событии
        control.$emit = function() {
          proxy.trigger.apply(proxy, arguments);
          originalEmit.apply(control, arguments);
        };
      };

      this.vue = new Vue({
        mixins: [ControlVue],

        data: this.getData(),

        mounted: function() {
          // Проксируем все события контрола на инстанс vue
          if (!tapIntoEmit.called) {
            tapIntoEmit(this);
            tapIntoEmit.called = true;
          }
        },

        updated: function() {
          // Проксируем все события контрола на инстанс vue
          if (!tapIntoEmit.called) {
            tapIntoEmit(this);
            tapIntoEmit.called = true;
          }
        },

        methods: {
          // Срабатывает после окончания транзишена
          afterLeave: function() {
            this.$el.remove();
            this.$destroy();
          },
        },
      });
      this.vue.$on('panel-resize', this.onPanelResize);

      this.vue.visible = true;

      this.$container.append('<div id="' + this.name + '">');
      this.vue.$mount('#' + this.name);

      this.$el = $(this.vue.$el);

      this.on(
        'select',
        function() {
          this.master.select(this);
        }.bind(this)
      );

      this.on(
        'deselect',
        function() {
          this.master.deselect();
        }.bind(this)
      );

      this.on('change', this.onPanelChange.bind(this));
      this.listenTo(this.model, 'change', this.onModelChange.bind(this));

      // Надо проверять не задизейблен ли контрол
      this.setControlsStatusByBlockData();
      this.block.on('disabledControls:changed', this.onDisabledControlsChanged.bind(this));

      this.bindLogic();
    }
  },

  bindShortcuts: function() {
    this.shortcutHandler = this.master.select.bind(this.master, this);
    if (this.keyboardShortcut) {
      RM.constructorRouter.bindGlobalKeyPress([
        {
          key: this.keyboardShortcut,
          handler: this.shortcutHandler,
        },
      ]);
    }

    this.onWindowKeyUpBound = this.onWindowKeyUp.bind(this);
    $(window).on('keyup', this.onWindowKeyUpBound);
  },

  unbindShortcuts: function() {
    if (this.keyboardShortcut) {
      RM.constructorRouter.unbindGlobalKeyPress(this.keyboardShortcut, this.shortcutHandler);
    }
    $(window).off('keyup', this.onWindowKeyUpBound);
  },

  isRendered: function() {
    return Boolean(this.vue);
  },

  onPanelResize: function(panel) {
    this.$panel = $(panel);
    this.updatePanelVerticalPosition();
  },

  select: function() {
    this.waitForRender(this._select.bind(this), arguments);
  },

  _select: function() {
    this.makeModelSnapshot();
    PreloadDesignImages('controls');

    _.extend(this.vue, {
      selected: true,
      faded: false,
    });

    this.selected = true;
  },

  deselect: function() {
    this.waitForRender(this._deselect.bind(this), arguments);
  },

  _deselect: function() {
    if (!this.vue) return;

    var wasSelected = this.vue.selected;
    _.extend(this.vue, {
      selected: false,
      faded: false,
    });

    this.selected = false;
    this.clearModelSnapshot(wasSelected);
  },

  fade: function() {
    this.waitForRender(this._fade.bind(this), arguments);
  },

  _fade: function() {
    this.deselect();
    _.extend(this.vue, {
      faded: true,
    });
  },

  setEnabled: function() {
    this.waitForRender(this._setEnabled.bind(this), arguments);
  },

  _setEnabled: function() {
    this.vue.disabled = false;
  },

  setDisabled: function() {
    this.waitForRender(this._setDisabled.bind(this), arguments);
  },

  _setDisabled: function() {
    _.extend(this.vue, {
      selected: false,
      disabled: true,
    });
  },

  canControlBeClosed: function() {
    this.waitForRender(this._canControlBeClosed.bind(this), arguments);
  },

  _canControlBeClosed: function() {
    if (this.vue && this.vue.selected) {
      if (this.vue.canClosePanel()) {
        this.master.deselect();
      }
      return true;
    } else {
      return false;
    }
  },

  moveToNewPos: function() {
    this.waitForRender(this._moveToNewPos.bind(this), arguments);
  },

  _moveToNewPos: function(newPos) {
    if (this.vue) {
      this.vue.y = newPos;
    } else {
      this.y = newPos;
    }
  },

  onEscKey: function() {
    this.waitForRender(this._onEscKey.bind(this), arguments);
  },

  _onEscKey: function() {
    if (this.vue.selected) {
      this.master.deselect();
    }
  },

  save: function() {
    this.model.save({});
  },

  destroy: function(animated) {
    // TODO При удалении блока панель вращается вместе с контролом, вместо того чтобы исчезать
    if (!this.vue) return;

    this.vue.visible = false;

    // Если без анимации, удаляем элемент сразу, не дождавшись конца транзишена,
    // если с ней, то сработает хук afterLeave у vue-инстанса контрола
    if (!animated) {
      this.vue.$el.remove();
      this.vue.$destroy();
    }

    this.block.off('disabledControls:changed', this.onDisabledControlsChanged);
    this.unBindLogic();
    this.unbindShortcuts();
    this.stopListening(this.model, 'change');
    this.clearModelSnapshot(this.vue.selected);
  },
});

export default VueControlClass;
