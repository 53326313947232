import Backbone from 'backbone';
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import { Utils } from '../common/utils';
import Scale from '../common/scale';
import templates from '../../templates/viewer/picture-preview.tpl';

/**
 * Picture preview
 *
 * @param {jQuery} $container — контейнер окна
 * @param {jQuery} $el — контейнер исходного изображения
 * @param {string} overlayColor — цвет подложки
 * @param {string} picture — объект picture из модели виджета
 * @param {number} originalW — ширина оригинала изображения
 * @param {number} width — ширина виджета изображения
 */

const TRANSITION_TIME = 300;
const PicturePreview = Backbone.View.extend({
  initialize: function(params) {
    _.bindAll(this);
    _.extend(this, params);
  },

  render: function() {
    if (!this.picture) return;

    this.removing = false;

    $('body').addClass('picture-preview-loading');

    this.originalRect = this.getRect(this.$el[0]);
    this.orientation = this.getOrientation();
    this.originalStyle = this.getOriginalStyle();

    const previewTemplate = templates['template-picture-preview-box'];
    this.$preview = $(
      previewTemplate({
        overlayColor: this.overlayColor,
        previewSrc: this.getPreviewSrc(),
        originalStyle: this.originalStyle,
      })
    );

    this.$container.append(this.$preview);
    $(window).on('scroll', this.onScroll);
    $('body').on('keypress keyup keydown', this.onKeyPress);

    // Запускаем анимацию
    setTimeout(this.setVisible, 100);

    return this.$preview;
  },

  imagePreload: function() {
    if (this.removing || !this.picture) return;

    const originalSrc =
      this.picture.unscaledUrl || this.picture.final3xUrl || this.picture.final2xUrl || this.picture.finalUrl;
    const previewSrc = this.getPreviewSrc();

    // Не грузим оригинал, если исходник уже и есть оригинал
    if (previewSrc === originalSrc) {
      $('body').removeClass('picture-preview-loading');
      return;
    }

    this.img = new Image();
    this.img.onload = () => {
      // Возможно превью закрыли до момента загрузки хайреса
      $('body').removeClass('picture-preview-loading');
      if (!this.$preview) return;

      setTimeout(() => {
        if (this.removing) return;

        const img = this.$preview.find('.picture-preview-box .picture-preview-box-image');
        img.attr('src', this.img.src).removeAttr('srcset');
      }, 100);
    };

    this.img.src = originalSrc;
  },

  remove: function(cb) {
    this.removing = true;

    $('body').removeClass('picture-preview-loading');
    $('body').off('keypress keyup keydown', this.onKeyPress);
    $(window).off('scroll', this.onScroll);

    // Аборт прелоада картинки
    if (this.img) {
      this.img.src = '';
      delete this.img;
    }

    if (this.$preview) {
      const $el = this.$preview.find('.picture-preview-box');
      $el.removeClass('visible loaded').removeClass(this.orientation);

      Utils.waitForTransitionEnd($el.find('.picture-preview-box-overlay'), TRANSITION_TIME, 'opacity', () => {
        this.$preview && this.$preview.remove();
        delete this.$preview;
      });

      cb && cb();
    }
  },

  changeColor: function(newColor) {
    this.$preview && this.$preview.find('.picture-preview-box-overlay').css('background-color', newColor);
  },

  onScroll: function() {
    this.remove();
  },

  /**
   * Запускает анимацию появления и подгрузку оригинала
   * только когда загрузится и отобразится превью
   */
  setVisible: function() {
    if (this.removing) return;

    const previewImg = this.$preview.find('.picture-preview-box img')[0];
    const onLoad = () => {
      previewImg.removeEventListener('load', onLoad);

      this.$preview
        .find('.picture-preview-box')
        .addClass('visible')
        .addClass(this.orientation);

      Utils.waitForTransitionEnd(
        this.$preview.find('.picture-preview-box .picture-preview-box-overlay'),
        TRANSITION_TIME,
        'opacity',
        this.imagePreload
      );
    };

    setTimeout(() => {
      if (previewImg.complete) {
        onLoad();
      } else {
        previewImg.addEventListener('load', onLoad);
      }
    }, 100);
  },

  /**
   * Достает src, который в данный момент отображается в виджете
   */
  getPreviewSrc: function() {
    const img = this.$el.find('img')[0];

    // currentSrc будет в случае наличия srcset
    return (
      (img && (img.currentSrc || img.src)) ||
      (this.picture && (this.picture.final3xUrl || this.picture.final2xUrl || this.picture.finalUrl))
    );
  },

  /**
   * Собирает объект стилей виджета
   *
   * Нужен для того, чтобы транзишн начинался в рамке виджета и туда же возвращался
   */
  getOriginalStyle: function() {
    if (!this.originalRect) return {};

    // Если у страницы включен Scale Layout с использованием Zoom,
    // то нужно сделать поправку в позиции на величину зума
    const scaleRatio = this.getScale();

    const result = {
      top: Number(this.originalRect.top + this.originalRect.height * 0.5) * scaleRatio + 'px',
      left: Number(this.originalRect.left + this.originalRect.width * 0.5) * scaleRatio + 'px',
    };

    if (this.orientation === 'portrait') {
      result.height = this.originalRect.height * scaleRatio + 'px';
    } else if (this.orientation === 'landscape') {
      result.width = this.originalRect.width * scaleRatio + 'px';
    }

    return result;
  },

  /**
   * Получает тип ориентации
   *
   * Пытается вписать картинку в экран и определяет ориентацию в зависимости от того,
   * входит картинка в экран по ширине или высоте
   */
  getOrientation: function() {
    const ratio = this.originalRect.height / this.originalRect.width;
    const windowWidth = $(window).width();
    const windowHeight = $(window).height();

    const newWidth = windowHeight / ratio;
    return newWidth < windowWidth ? 'portrait' : 'landscape';
  },

  bindClick: function() {
    if (this.$preview) {
      this.$preview.on('click', () => {
        this.remove();
      });
    }
  },

  onKeyPress: function(e) {
    if (e.keyCode === 27) {
      this.remove();
    }
  },

  getScale: function() {
    const isZoom = Scale.isAllowed() && Scale.isZoom() && Scale.isOn();
    return isZoom ? Scale.getRatio() : 1;
  },

  getRect: function(el) {
    const rect = _.extend({}, el.getBoundingClientRect());

    // В сафари есть старый баг
    // Если у страницы включен scale layout (zoom: x), то getBoundingClientRect будет возвращать позицию top со сдвигом на позицию скролла
    // Поэтому сдвигаем позицию руками 🤦‍
    if (Modernizr.safari) {
      const scaleRatio = this.getScale();
      rect.top = rect.top + ($(window).scrollTop() - $(window).scrollTop() / scaleRatio);
    }

    return rect;
  },
});

export default PicturePreview;
