/**
 * Конструктор для виджета текста
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/text_link.tpl';
import Colorbox from '../helpers/colorbox';
import AnchorPicker from './anchor_picker';
import { Utils } from '../../common/utils';
import PreloadDesignImages from '../../common/preload-design-images';

const TextLink = ControlClass.extend({
  name: 'text_link', // должно совпадать с классом вьюхи

  className: 'control text_link',

  urlRegexp: /^http(s?)\:\/\//i,

  shortUrlRegexp: /^[\da-z\.-]+\.[a-z-]{2,}.*/i,

  telRegexp: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/i,

  backRegexp: /^(back|scroll)\sto\stop$/i,
  backLink: 'Back to Top',
  backDisplayName: 'Scroll to Top',

  gobackRegexp: /^(__rm_goback|go\sback)$/i,
  gobackLink: '__rm_goback',
  gobackDisplayName: 'Go Back',

  mailchimpRegexp: /^(__rm_mailchimp_.*|<script.*mailchimp\.com.*\{.*baseUrl.*uuid.*lid.*\}.*)$/i,
  mailchimpMatcher: /start\((\{.*\})\).*\}\)/i, // Вынимает json-данные из вставленного кода Mailchimp,
  mailchimpLink: '__rm_mailchimp_',
  mailchimpDisplayName: 'Mailchimp Popup',

  anchorRegexp: /^(__rm_anchor|\d+px)$/i,
  anchorMatcher: /^(\d+)px$/i,
  anchorLink: '__rm_anchor',

  shareRegexp: /^share\.(facebook|twitter|pinterest|gplus|linkedin|email)\.(mag|page)$/i,

  NEW_DEFAULT_STYLE: {
    color: '0078ff',
    opacity: 100,
    'u-style': 'solid',
    'u-color': '0078ff',
    'u-opacity': 100,
    'u-size': 1,
    'u-offset': 0,
    'hover-color': 'inherit', // по дефолту наследуется
    'hover-opacity': 'inherit', // по дефолту наследуется
    'hover-u-style': 'none',
    'hover-u-color': 'inherit', // по дефолту наследуется
    'hover-u-opacity': 'inherit', // по дефолту наследуется
    'hover-u-size': 'inherit', // по дефолту наследуется
    'hover-u-offset': 'inherit', // по дефолту наследуется
    'current-color': 'inherit', // по дефолту наследуется
    'current-opacity': 'inherit', // по дефолту наследуется
    'current-u-style': 'none',
    'current-u-color': 'inherit', // по дефолту наследуется
    'current-u-opacity': 'inherit', // по дефолту наследуется
    'current-u-size': 'inherit', // по дефолту наследуется
    'current-u-offset': 'inherit', // по дефолту наследуется
  },

  // это для сохранения сортировки списка стилей
  saveOnDestroy: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_link'];
    this.style_template = templates['template-constructor-control-text_link-style'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;

    this.refreshEditorCssDebounced = _.debounce(this.refreshEditorCss, 100);
  },

  // разбито на две части чтобы было удобнее переопределять в потомках
  bindLogic: function() {
    this.bindLogicCommon();

    this.bindLogicSpecific();
  },

  bindLogicCommon: function() {
    this.$input = this.$panel.find('.search-wrapper input');
    this.$action_icon = this.$panel.find('.search-wrapper .action-icon');
    this.$placeholder = this.$panel.find('.search-wrapper .placeholder');
    this.$placeholder_icon = this.$panel.find('.search-wrapper .placeholder-selector');
    this.$target_input = this.$panel.find('.target-wrapper input');
    this.$target_wrapper = this.$panel.find('.target-wrapper');
    this.$search_wrapper = this.$panel.find('.search-wrapper');
    this.$target_icon = this.$panel.find('.target-icon');

    this.$input.on('input', this.onInputChange);
    this.$input.on('keyup', this.onInputKey);
    this.$action_icon.on('click', this.inputIconClick);
    this.$target_input.on('change', this.onTargetInputChange);
    this.$target_icon.on('click', this.anchorOnTargetClick);

    this.$panel.on('click', '.placeholder-selector', this.togglePlaceholderPopup);
    this.$panel.on('click', '.placeholder-selector .placeholder-item', this.clickPlaceholder);
    this.$panel.on('click', this.closePlaceholderPopup);
  },

  // ========= полностью переопределен в линках для картинок & шейпов ============
  bindLogicSpecific: function() {
    this.resizableScroll = this.$el
      .find('.resizable-scroll-wrapper')
      .RMScroll({
        $container: this.$el.find('.resizable-content-wrapper'),
        $content: this.$el.find('.resizable-content'),
        $handle: this.$el.find('.resizable-scroll'),
        wheelScrollSpeed: 0.4,
        gap_start: 8,
        gap_end: 8,
        onScroll: this.onPanelScroll.bind(this),
      })
      .data('scroll');

    $(window).on('resize', this.repositionPanel);

    // сохраняем шорткат на объект-модель который позволяет работать с данными стилей
    this.textStyles = RM.constructorRouter.textStyles;

    this.textStyles.on('change:link_styles', this.onStylesChange);

    this.$panel.on('click', '.add-block .add', this.addClick);

    this.$panel.on('click', '.link-style', this.onLinkStyleClick);
    this.$panel.on('click', '.link-style .menu-button', this.toggleMenu);

    this.$panel.on('click', '.style-menu .edit-button', this.onEditClick);
    this.$panel.on('dblclick', '.link-style .style-caption', this.onEditClick);
    this.$panel.on('click', '.style-menu .duplicate-button', this.onDuplicateClick);
    this.$panel.on('click', '.style-menu .delete-button', this.onDeleteClick);

    // сортинг стилей
    this.$panel.find('.styles-wrapper').sortable({
      distance: 10,
      axis: 'y',
      // revert: true,
      scrollSpeed: 20,
      containment: 'parent',
      tolerance: 'pointer',
      start: _.bind(function(e, obj) {
        this.closeMenu(false);
      }, this),
      stop: _.bind(function(e, obj) {
        this.reorder();
      }, this),
    });

    // отрисовываем изначально список стилей
    this.renderLinkStyles();

    // случаем событие изменения стиля выделения, генерируется редактором
    this.block && this.block.on('selection_styles_changed', this.selectionStylesChanged);

    // первоначально проставляем состояние контрола в зависимости от текущего стиля выделения в редакторе
    this.block && this.selectionStylesChanged(this.block.cur_selection_styles);

    // тут идут листенеры которые работают с режимом редактирования стиля
    this.$panel.on('click', '.add-block .save', this.saveEditClick);
    this.$panel.on('click', '.add-block .cancel', this.cancelEditClick);

    this.$panel.on('click', '.edit-wrapper .state-switcher-wrapper .state-switcher', this.onStateSwitchClick);

    this.$panel.on('input', '.edit-wrapper .edit-item[data-field="_caption"] input', this.onEditCaptionChange);

    this.$panel.on('click', '.edit-wrapper .color-click-area', this.onColorboxShowClick);

    this.$panel.on('click', '.edit-wrapper .underline-style-click-area', this.onUnderlineStyleShowClick);

    this.$panel.on('click', '.underline_style_container .underline-style-item', this.underlineStyleChanged);

    this.$panel.on('click', '.edit-wrapper', this.closeAllEditPopups);

    this.colorbox = new Colorbox({ $parent: this.$('.colorbox_container'), type: 'small' });
    this.colorbox.on('colorchange', this.colorOpacityChanged);
    this.colorbox.on('opacitychange', this.colorOpacityChanged);

    this.editUnderlineWidthChangeValue = this.$panel
      .find('.edit-wrapper .edit-item[data-field="u-size"] input')
      .RMNumericInput({
        min: 1,
        max: 99,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editPaddingChangeValue = this.$panel
      .find('.edit-wrapper .edit-item[data-field="u-offset"] input')
      .RMNumericInput({
        min: -99,
        max: 99,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editHoverUnderlineWidthChangeValue = this.$panel
      .find('.edit-wrapper .edit-item[data-field="hover-u-size"] input')
      .RMNumericInput({
        min: 1,
        max: 99,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editHoverPaddingChangeValue = this.$panel
      .find('.edit-wrapper .edit-item[data-field="hover-u-offset"] input')
      .RMNumericInput({
        min: -99,
        max: 99,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editCurrentUnderlineWidthChangeValue = this.$panel
      .find('.edit-wrapper .edit-item[data-field="current-u-size"] input')
      .RMNumericInput({
        min: 1,
        max: 99,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    this.editCurrentPaddingChangeValue = this.$panel
      .find('.edit-wrapper .edit-item[data-field="current-u-offset"] input')
      .RMNumericInput({
        min: -99,
        max: 99,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');
  },

  // разбито на две части чтобы было удобнее переопределять в потомках
  unBindLogic: function() {
    this.unBindLogicCommon();

    this.unBindLogicSpecific();
  },

  unBindLogicCommon: function() {
    this.$input.off('input', this.onInputChange);
    this.$input.off('keyup', this.onInputKey);
    this.$action_icon.off('click', this.inputIconClick);
    this.$target_input.off('change', this.onTargetInputChange);

    this.$panel.off('click', '.placeholder-selector', this.togglePlaceholderPopup);
    this.$panel.off('click', '.placeholder-selector .placeholder-item', this.clickPlaceholder);
    this.$panel.off('click', this.closePlaceholderPopup);
  },

  // ========= полностью переопределен в линках для картинок & шейпов ============
  unBindLogicSpecific: function() {
    $(window).off('resize', this.repositionPanel);

    this.block && this.block.off(null, null, this);

    this.textStyles.off('change:link_styles', this.onStylesChange);

    this.$panel.off('click', '.add-block .add', this.addClick);

    this.$panel.off('click', '.link-style', this.onLinkStyleClick);
    this.$panel.off('click', '.link-style .menu-button', this.toggleMenu);

    this.$panel.off('click', '.style-menu .edit-button', this.onEditClick);
    this.$panel.off('click', '.style-menu .duplicate-button', this.onDuplicateClick);
    this.$panel.off('click', '.style-menu .delete-button', this.onDeleteClick);

    this.$panel.off('click', '.add-block .save', this.saveEditClick);
    this.$panel.off('click', '.add-block .cancel', this.cancelEditClick);

    this.$panel.off('click', '.edit-wrapper .state-switcher-wrapper .state-switcher', this.onStateSwitchClick);

    this.$panel.off('input', '.edit-wrapper .edit-item[data-field="_caption"] input', this.onEditCaptionChange);

    this.$panel.off('click', '.edit-wrapper .color-click-area', this.onColorboxShowClick);

    this.$panel.off('click', '.edit-wrapper .underline-style-click-area', this.onUnderlineStyleShowClick);

    this.$panel.off('click', '.edit-wrapper', this.closeAllEditPopups);

    this.colorbox && this.colorbox.off('colorchange', this.colorOpacityChanged);
    this.colorbox && this.colorbox.off('opacitychange', this.colorOpacityChanged);
    this.colorbox && this.colorbox.destroy();
    this.colorbox = null;

    this.block && this.block.off('selection_styles_changed', this.selectionStylesChanged);
  },

  repositionPanel: function() {
    this.$panel.css('margin-top', '');

    var h = this.$panel.height(),
      t = this.$panel.offset().top,
      wh = Math.max($(window).height(), 324) - 10;

    if (h + t > wh) {
      this.$panel.css('margin-top', wh - h - t);
    }
  },

  toggleCurrentStateSwitch: function(pageId) {
    var hasCurrentState = this.hasCurrentState(pageId);
    // Если состояния current для этой ссылки нет, но мы в нём находимся — тогда переключимся на состояние default
    if (this.isInState('current') && !hasCurrentState) {
      this.switchState('default');
    }
    // Показываем таб Current только для глобальных текстовых виджетов, на которых навешана ссылка на страницу
    this.$panel.find('.state-switcher.caption-current').toggle(hasCurrentState);
  },

  canHaveCurrentState: function() {
    var isGlobal = this.model.get('is_global');
    var workspace = this.block.workspace;
    var hasGlobalParent =
      workspace && workspace.block && workspace.block.model && workspace.block.model.get('is_global');
    return Boolean(isGlobal || hasGlobalParent);
  },

  hasCurrentState: function(pageId) {
    return Boolean(this.canHaveCurrentState() && pageId);
  },

  initCurrentStyle: function() {
    var pickCurrent = function(style) {
      return _.pick(style, function(value, key) {
        return /^current/.test(key);
      });
    };
    var currentStyle = pickCurrent(this.currentEditStyle);
    var defaultCurrentStyle = pickCurrent(this.NEW_DEFAULT_STYLE);

    // Если это ссылка в глобальном виджете, а стиля для current-ссылок ещё нет или этот стиль неполный — заполним недостающие значения из дефолта
    if (this.canHaveCurrentState() && _.values(currentStyle).length < _.values(defaultCurrentStyle).length) {
      _.extend(this.currentEditStyle, defaultCurrentStyle, currentStyle);
    }
  },

  togglePlaceholderPopup: function(e) {
    if (e) {
      if ($(e.target).closest('.placeholder-selector .placeholder-popup').length > 0) return;
    }

    if (this.placeholder_popup_visible) {
      this.closePlaceholderPopup();
      return;
    }

    this.anchorExitTargetMode();

    this.placeholder_popup_visible = true;
    this.$('.placeholder-selector').addClass('opened');
  },

  closePlaceholderPopup: function(e) {
    if (e) {
      if ($(e.target).closest('.placeholder-selector').length > 0) return;
    }

    this.placeholder_popup_visible = false;
    this.$('.placeholder-selector').removeClass('opened');
  },

  clickPlaceholder: function(e) {
    var tp = $(e.currentTarget).attr('data-type');

    this.setPlaceholder(tp);

    if (tp == 'back') {
      this.$input.val(this.backDisplayName);
      this.onInputChange();
      this.applyLink();
    }

    if (tp == 'goback') {
      this.$input.val(this.gobackDisplayName);
      this.onInputChange();
      this.applyLink();
    }

    if (tp == 'anchor') {
      this.anchorEnterMode();
      if (!this.$input.val()) {
        this.anchorEnterTargetMode();
      }
    } else {
      this.anchorExitMode();
    }

    this.focusInputField();
  },

  setPlaceholder: function(tp) {
    var $item = this.$panel.find('.search-wrapper .placeholder-selector .placeholder-item[data-type="' + tp + '"]');

    this.$placeholder.text(this.$placeholder.attr('data-' + (tp || 'initial')));

    this.closePlaceholderPopup();

    this.$panel.find('.search-wrapper .placeholder-selector .placeholder-item').removeClass('active');

    $item.addClass('active');
  },

  anchorEnterMode: function(val) {
    if (!this.isAnchorMode) {
      this.$search_wrapper.addClass('anchor-mode');
      // this.$input.prop('disabled', true);  //TODO: Разобраться. Если инпут задизаблен, то он не становится valid, если программно присвоить значение

      this.anchorPicker = new AnchorPicker({
        $container: $('#main'),
        $after: $('.workspace-container:visible'), // Воркспейсов может быть много. Нам нужен видимый
      });
      this.anchorPicker.show();
    }

    if (val) {
      this.anchorPicker.updateY(val);
    }

    this.isAnchorMode = true;
  },

  anchorExitMode: function() {
    if (!this.isAnchorMode) {
      return;
    }

    this.$search_wrapper.removeClass('anchor-mode');
    // this.$input.prop('disabled', false);

    if (this.anchorPicker) {
      this.anchorPicker.hide();
      this.anchorPicker = null;
    }

    this.isAnchorMode = false;
  },

  anchorOnTargetClick: function() {
    var isActive = this.$target_icon.hasClass('active');

    if (isActive) {
      this.anchorExitTargetMode();
    } else {
      this.anchorEnterTargetMode();
    }
  },

  anchorEnterTargetMode: function() {
    this.$target_icon.addClass('active');
    this.anchorPicker.startFollowing();
    this.listenToOnce(this.anchorPicker, 'target-select', this.anchorOnTargetSelect);

    this.isAnchorTargetMode = true;
  },

  anchorExitTargetMode: function() {
    _.defer(
      function() {
        this.isAnchorTargetMode = false;
      }.bind(this)
    );

    if (!this.anchorPicker) {
      return;
    }

    this.$target_icon.removeClass('active');
    this.anchorPicker.stopFollowing();
    // this.$input.prop('disabled', false);
    // _.defer(function() {
    // 	if (this.isAnchorMode) { this.$input.prop('disabled', true); } // Функция может вызываться синхронно в последовательности выхода из режима. Проверим, в нем ли мы еще
    // }.bind(this));
  },

  anchorOnTargetSelect: function(e) {
    var y = e.y;
    this.anchorExitTargetMode();

    this.$input.val(y + 'px');

    this.applyLink();
  },

  // если какой-то коллаборатор изменил стили пока у нас открыта панелька
  // то просто перерендерим все стили в панелеке, чтобы долго не думать и не делать сложных механизмов синхронизации как в font-slector
  onStylesChange: function(model, attr, opts) {
    opts = opts || {};

    if (opts.socketUpdate) {
      this.renderLinkStyles();
    }
  },

  // первоначальное создаение списка стилей в панельке
  renderLinkStyles: function() {
    var stylesList = this.textStyles.getStylesListSorted('link');

    var html = '';
    _.each(
      stylesList,
      function(style) {
        html += this.style_template(style);
      },
      this
    );

    this.$panel.find('.styles-wrapper').html(html);

    this.resizableScroll && this.resizableScroll.recalc();

    this.$panel.find('.styles-wrapper').sortable('refresh');
  },

  // вызывается при каждом перетасквании стилей внутри панельки
  reorder: function() {
    var stylesOrder = [];

    this.$('.link-style').each(function() {
      stylesOrder.push($(this).attr('data-id'));
    });

    this.textStyles.reorder('link', stylesOrder);
  },

  selectionStylesChanged: function(styles) {
    //			if (this.blocks[0].initiatorControl == this.name) return;
    this.updateState(styles);
  },

  // ========= полностью переопределен в линках для картинок & шейпов ============
  onTargetInputChange: function() {
    var checked = this.$target_input.prop('checked');

    // this.updateState(param);
    if (this.block) {
      // this.blocks[0].initiatorControl = this.name;
      this.block.trigger('changeLink', { action: 'change-target', target: checked ? '_blank' : null }); // null это очень важно, undefined нельзя это жикверевский заеб
    }
  },

  // ========= полностью переопределен в линках для картинок & шейпов ============
  updateState: function(param) {
    var link_nodes = param && param.link_nodes,
      has_any_link = !!(link_nodes && link_nodes.length);
    var pageID;

    this.$panel.toggleClass('collapsed', !has_any_link);
    this.anchorExitMode();

    setTimeout(this.repositionPanel, 400);

    this.closeMenu(false);

    if (this.placeholder_popup_visible) this.closePlaceholderPopup();

    // если линков в выделении нет
    if (!has_any_link) {
      this.$input.val('');
      this.onInputChange();
      this.setPlaceholder('initial');
    } else {
      // если есть линки или линк
      // смотрим это части одного линка или разных
      var uuids = [],
        classes = [],
        targets = [];

      _.each(link_nodes, function(link) {
        uuids.push($(link).attr('data-uuid'));
        targets.push($(link).attr('target'));
        classes.push(link.className);
      });

      uuids = _.uniq(uuids);
      classes = _.uniq(classes);
      targets = _.uniq(targets);

      this.$target_wrapper.addClass('disabled');

      // в выделении несколько линков и они НЕ части одного целого
      if (uuids.length > 1) {
        this.$input.val('');
        this.setPlaceholder('multiple');
      } else {
        // все линки части одного целого, можно работать просто с первым link_nodes[0]

        // пытаемся получить айдишник страницы, если линк ведет на страницу мега (аттрибут data-pid у линка)
        var $link = $(link_nodes[0]);
        pageID = $link.attr('data-pid');

        // если линк ведет на страницу мэга
        if (pageID) {
          this.setPlaceholder('page');

          // получаем страницу мэга по её айдишнику
          var pagUri = RM.constructorRouter.mag.getPageUri(pageID);

          // если страница существует
          if (pagUri) this.$input.val(pagUri);
          else {
            this.$input.val('');
            this.setPlaceholder('no-page');
          }
        } else {
          // если обычный урл, т.е. pageID == undefined
          var preparedLink = $link.attr('href');

          if (/^mailto\:/i.test(preparedLink)) this.setPlaceholder('email');
          else if (/^tel\:/i.test(preparedLink)) this.setPlaceholder('phone');
          else if (this.backRegexp.test(preparedLink)) {
            this.setPlaceholder('back');
            preparedLink = this.backDisplayName; // Заменяем хранимое значение ссылки на отображаемое
          } else if (this.gobackRegexp.test(preparedLink)) {
            this.setPlaceholder('goback');
            preparedLink = this.gobackDisplayName; // Заменяем хранимое значение ссылки на отображаемое
          } else if (this.mailchimpRegexp.test(preparedLink)) {
            this.setPlaceholder('mailchimp');
            preparedLink = this.mailchimpDisplayName; // Заменяем хранимое значение ссылки на отображаемое
          } else if (this.anchorRegexp.test(preparedLink)) {
            this.setPlaceholder('anchor');
            var y = parseInt($link.attr('data-anchor-link-pos'), 10) || 0;
            preparedLink = y + 'px';
            this.anchorEnterMode(y);
          } else {
            this.setPlaceholder('url');
            this.$target_wrapper.removeClass('disabled');
          }

          this.$input.val(preparedLink.replace(/^mailto\:/i, '').replace(/^tel\:/i, ''));
        }
      }

      this.onInputChange();
      this.$action_icon.addClass('remove-icon');
      this.$action_icon.removeClass('hidden');
      this.$placeholder_icon.addClass('hidden');

      // если у всех линков в выделении target=_blank тогда зачекаем инпут (уник по всем таргеам находит одного)
      this.$target_input.prop('checked', targets.length == 1 && targets[0] == '_blank');
      // если у линков в выделении разные таргеты, тогда переводим инпут в неопределенное состояние (уник по всем таргеам находит больше одного)
      this.$target_input.prop('indeterminate', targets.length > 1);

      this.showActiveStyles(classes);
    }

    this.toggleCurrentStateSwitch(pageID);

    this.$icon.toggleClass('highlighted', !!(link_nodes && link_nodes.length));
  },

  onInputChange: function() {
    // скрываем иконку есди введен не урл
    this.$action_icon.toggleClass('hidden', !this.getUrlData(this.$input.val()).result);

    // убираем иконку удаления при любом изменении поля
    this.$action_icon.removeClass('remove-icon');

    // если в поле ввода пусто показываем стрелку попапа с подсказкой
    this.$placeholder_icon.toggleClass('hidden', this.$input.val() != '');

    // // Если ранее были в режиме якоря и все стерли, снова переходим в режим
    // if (this.$placeholder.text() == this.$placeholder.attr('data-anchor') && this.$input.val() == '') {
    // 	this.anchorEnterMode();
    // } else {
    // 	// Если что-то пытаемся вводить вручную, выходим из режима якоря
    // 	this.anchorExitMode();
    // }
  },

  // проверка на урл или номер страницы которая есть в меге
  getUrlData: function(text) {
    var mag = RM.constructorRouter.mag,
      me = RM.constructorRouter.me;

    text = $.trim(text);

    // проверяем что строка начинается на http:// или https:// (а больше и не надо)
    var isURL = this.urlRegexp.test(text);

    // Если ввели урл страницы из конструктора или превью, или урлы опубликованного этого мэга,
    // то вычленяем из него номер или uri страницы.
    // Поддерживаются урлы вида
    // /edit/343/2/
    // /edit/343/preview/pageuri/
    // /edit/343/preview/
    // /useruri/343/
    // /useruri/343/3/
    // /useruri/343/pageuri/
    // /useruri/maguri/
    // /useruri/maguri/3/
    // /useruri/maguri/pageuri/
    var magEditLinkRegexp = new RegExp(
      '/(edit|' + me.get('uri') + ')/(\\d+|' + mag.get('uri') + ')/(preview/)?(.*)',
      'i'
    );
    var match = text.match(magEditLinkRegexp);

    if (match && (mag.get('uri') == match[2] || mag.get('num_id') == match[2])) {
      // Обрабатываем ссылки только на текущий мэг
      text = (match[4] || '1').replace('/', ''); // У первой страницы в превью урл заканчивается просто на /preview/ без номера страницы
    }

    // проверяем на то, что в меге есть страница с таким номером которая введена (если ее нет или вместо страницы урл результат будет undefined)
    var isValidPageNumber = text != '' && !!mag.getPageId(text);

    // проверяем на то, ввели email (можно в начале mailto:)
    text = text.replace(/^mailto\:/i, ''); // убираем mailto: в начеле строки, если есть
    var isEmail = Utils.isValidEmailLink(text);

    // проверяем на номер телефона
    text = text.replace(/^tel\:/i, '');
    var isTel = this.telRegexp.test(text);

    var isBack = this.backRegexp.test(text);
    var isGoBack = this.gobackRegexp.test(text);
    var isMailchimp = this.mailchimpRegexp.test(text);
    var isAnchor = this.anchorRegexp.test(text);
    var isShare = this.shareRegexp.test(text);

    // если это не валидный урл, не номер страницы и не емейл
    // тогда смотрим, а не введен ли урл без http(s)
    if (!(isURL || isValidPageNumber || isEmail || isTel || isMailchimp || isShare)) {
      isURL = this.shortUrlRegexp.test(text);
      if (isURL) text = 'http://' + text;
    }

    if (isMailchimp) {
      text = this.mailchimpLink + this.getMailchimpData(text);
    }

    if (isAnchor) {
      text = text.match(this.anchorMatcher)[1];
    }

    return {
      result:
        isURL || isValidPageNumber || isEmail || isTel || isBack || isGoBack || isMailchimp || isAnchor || isShare,
      isURL: isURL,
      isEmail: isEmail,
      isTel: isTel,
      isValidPageNumber: isValidPageNumber,
      isBack: isBack,
      isGoBack: isGoBack,
      isMailchimp: isMailchimp,
      isAnchor: isAnchor,
      isShare: isShare,
      url: text,
    };
  },

  getMailchimpData: function(text) {
    var match = text.match(this.mailchimpMatcher),
      is_json;

    try {
      is_json = match && match[1] && !!JSON.parse(match[1]);
    } catch (e) {
      is_json = false;
    }

    return is_json ? match[1] : '';
  },

  inputIconClick: function(e) {
    if (this.$action_icon.hasClass('remove-icon')) {
      this.removeLink();
      this.anchorExitMode();
    } else {
      this.applyLink();
    }
  },

  // ========= полностью переопределен в линках для картинок & шейпов ============
  onInputKey: function(e) {
    var link_nodes = this.block && this.block.cur_selection_styles && this.block.cur_selection_styles.link_nodes;

    if (e.keyCode == $.keycodes.enter) this.applyLink();

    // если у нас в выделении или под курсором линк, мы все стерли в поле ввода и нажали на del, тогда удаляем линк
    if (e.keyCode == $.keycodes.del && this.$input.val() == '' && link_nodes && link_nodes.length) {
      this.removeLink();
    }
  },

  // ========= полностью переопределен в линках для картинок & шейпов ============
  applyLink: function() {
    var url = this.$input.val(),
      anchorY,
      data;

    // если введен урл или номер страницы которая есть в меге
    var checkInput = this.getUrlData(url);
    if (!checkInput.result) return;

    if (checkInput.isEmail) checkInput.url = 'mailto:' + checkInput.url;

    if (checkInput.isTel) checkInput.url = 'tel:' + checkInput.url;

    if (checkInput.isBack) checkInput.url = this.backLink;

    if (checkInput.isGoBack) {
      checkInput.url = this.gobackLink;
    }

    if (checkInput.isAnchor) {
      anchorY = checkInput.url; // Сохраняем значение координаты и заменяем его служебной ссылкой
      checkInput.url = this.anchorLink;
    }

    var link_nodes = this.block && this.block.cur_selection_styles && this.block.cur_selection_styles.link_nodes,
      className = 'link-1', // дефолтный стиль для всех новых линков
      target = checkInput.isURL ? '_blank' : null; // дефолтный таргет для всех новых линков, null это очень важно, undefined нельзя это жикверевский заеб

    // смотрим первый линк в текущем выделении (если он есть)
    // и берем из него данные по классу и по таргету, на основе этих данных будет создан новый линк
    if (link_nodes.length) {
      // берем класс из первого линка в выделении
      className = link_nodes[0].className;
      // проверяем что класс наш
      className = /^link\-/.test(className) ? className : null; // null это очень важно, undefined нельзя это жикверевский заеб

      // если ввели урл, тогда берем таргет из первого линка в выделении
      // в противном случае берем дефолтный указаный выше
      if (checkInput.isURL) {
        target = $(link_nodes[0]).attr('target');
        // для нас таргет может быть только в двух состояниях: пустой или _blank
        target = target == '_blank' ? target : null; // null это очень важно, undefined нельзя это жикверевский заеб
      }
    }

    // this.updateState(param);
    if (this.block) {
      // this.blocks[0].initiatorControl = this.name;

      data = { action: 'create', url: checkInput.url, class: className, target: target };
      if (anchorY) {
        _.extend(data, { anchor_pos: anchorY, clickPage: this.blocks[0].workspace.page.id });
      }

      this.block.trigger('changeLink', data);

      // FIXME: Экстендить параметром anchor_pos, если он есть
    }
  },

  // ========= полностью переопределен в линках для картинок & шейпов ============
  removeLink: function() {
    // this.updateState(param);
    if (this.block) {
      // this.blocks[0].initiatorControl = this.name;
      this.block.trigger('changeLink', { action: 'remove' });
    }
  },

  focusInputField: function() {
    this.$panel.find('.search-wrapper input').focus();
  },

  // вызывается при любых изменениях внутри текстового редактора (включая клики мышкой, сдвиг курсора и пр.)
  showActiveStyles: function(classes) {
    if (!this.selected || !classes) return;

    // определяем текущие выделенные в панельке стили
    var prevActiveStyles = {},
      curActiveStyles = {};

    this.$('.link-style.active').each(function() {
      prevActiveStyles[$(this).attr('data-id')] = true;
    });

    this.closeMenu(true);

    // сбрасываем отметку со всех
    this.$('.link-style').removeClass('active');

    // помечаем стили линков в выделении
    for (var i = 0; i < classes.length; i++) this.$('.link-style[data-id="' + classes[i] + '"]').addClass('active');

    // помечаем новые выделенные в панельке стили
    this.$('.link-style.active').each(function() {
      curActiveStyles[$(this).attr('data-id')] = true;
    });

    // прокручиваем к первому стилю
    if (!_.isEqual(prevActiveStyles, curActiveStyles)) {
      var $style = this.$('.link-style.active').first();
      if ($style.length) {
        // смотрим виден ли текущий стиль в панелькк
        // если нет, тогда скролим так чтобы был виден
        var $wrapper = this.$panel.find('.resizable-content-wrapper'),
          top = $style.position().top,
          h = $wrapper.height(),
          styleHeight = $style.outerHeight(),
          newScroll,
          contentPadding = 0;

        if (top + styleHeight - $wrapper.scrollTop() > h - contentPadding) {
          newScroll = top + styleHeight - h + contentPadding;
        } else if (top - $wrapper.scrollTop() < contentPadding) {
          newScroll = top - contentPadding;
        }

        if (newScroll != undefined) {
          $wrapper.stop().animate({ scrollTop: newScroll }, 200);
        }
      }
    }
  },

  // при клике по стилю - применяем его к текущему выделению
  onLinkStyleClick: function(e) {
    // если нажали на что-то внутри плашки с классом, а не на саму плашку (например на кружок с дропдаун меню)
    if ($(e.target).closest('.menu-button').length) return;

    var className = $(e.target)
      .closest('.link-style')
      .attr('data-id');

    // this.updateState(param);
    if (this.block) {
      // this.blocks[0].initiatorControl = this.name;
      this.block.trigger('changeLink', { action: 'change-class', class: className });
    }
  },

  // клик по иконке меню внутри стиля
  toggleMenu: function(e) {
    var $button = $(e.currentTarget),
      state = $button.hasClass('active'),
      $menu = this.$('.style-menu');

    // если меню для данного стиля сейчас показано - тогда закроем его
    if (state) {
      this.closeMenu(true);
    } else {
      // если открыто меню другого стиля, закроем его мгновенно
      if (this.$('.link-style .menu-button.active').length > 0) this.closeMenu(false);

      // с задержкой показываем меню для текущего стиля (не помню точо зачем задержка, вроде связана с вызовом closeMenu строкой выше)
      // меню позиционируем так чтобы оно выплывало прямо под иконкой
      // все дело в том, что меню у нас находится в dom иерархии гораздо выше, чтобы оно не обрезалось overflow:hidden
      // но при этому мы еще контролируем, чтобы оно не вылезло за границы экрана снизу
      setTimeout(
        _.bind(function() {
          var $style = $button.closest('.link-style'),
            buttonMiddle =
              this.$('.resizable-scroll-wrapper').position().top +
              $style.position().top +
              $button.position().top -
              this.$panel.find('.resizable-content-wrapper').scrollTop(),
            buttonHeight = $button.height(),
            pos = buttonMiddle + 7,
            wh = Math.max($(window).height(), 304) - 10;

          $menu
            .css('top', pos)
            .addClass('shown')
            .addClass('shift-bottom')
            .removeClass('shift-left')
            .attr('data-id', $style.attr('data-id'));

          var menuBottom = $menu.offset().top + $menu.height();

          // если панельки вылезает за края экрана
          // тогда открываем ее сбоку от кнопки
          // +8 это ее анимация вниз после открытия
          if (menuBottom + 8 > wh) {
            var shift = menuBottom - wh,
              minShift = buttonHeight / 2 + 7, // +7 это отступ уголка панели  от центра кнопки,
              maxShift = minShift + $menu.height() - buttonHeight,
              shift = Math.min(Math.max(shift, minShift + 4), maxShift - 8); // + 4 это доп отступ тобы уголок с краем панели нормально согласовывался в крайних положениях

            $menu
              .css('top', pos - shift)
              .addClass('shift-left')
              .removeClass('shift-bottom')
              .find('.corner-wrapper')
              .css('top', shift - minShift + 4);

            $button.addClass('shift-left');
          } else {
            $button.removeClass('shift-left');
          }

          $button.addClass('active');
        }, this),
        40
      );
    }
  },

  // закрыть меню, с анимацией или без
  closeMenu: function(animate) {
    var $menu = this.$('.style-menu');

    if (!animate) $menu.removeClass('transition-enable');

    $menu.removeClass('shown');

    if (!animate) {
      setTimeout(function() {
        $menu.addClass('transition-enable');
      }, 20);
    }

    // во всех стилях кнопку вызова меню возвращаем в исходное состояние
    this.$('.link-style .menu-button').removeClass('active');
  },

  // при скроле внтури панели закрываем меню, поскольку оно у нас в пропивном случае останется на месте и будет не красиво
  onPanelScroll: function(data) {
    this.closeMenu(true);
  },

  // клонируем стиль
  onDuplicateClick: function(e) {
    var $button = $(e.currentTarget),
      $menu = $button.closest('.style-menu'),
      styleName = $menu.attr('data-id'),
      style = this.textStyles.duplicateStyle('link', styleName),
      $style = this.$('.link-style[data-id="' + styleName + '"]');

    if (!style) return;

    RM.constructorRouter.textStyles.generateCSS('link', 'editor', this.block.iframe_document);
    RM.constructorRouter.textStyles.generateCSS('link', 'constructor', document);

    this.block.somethingChangedInEditor('styleAdded');

    var $elem = $(this.style_template(style));

    $elem.addClass('enable-transition').css('margin-top', -$style.outerHeight());

    $elem.insertAfter($style);

    setTimeout(
      _.bind(function() {
        $elem.css('margin-top', 0);

        setTimeout(
          _.bind(function() {
            $elem.removeClass('enable-transition');

            this.$panel.find('.styles-wrapper').sortable('refresh');

            this.resizableScroll && this.resizableScroll.recalc();
          }, this),
          250 + 20
        );
      }, this),
      50
    );
  },

  // удаляем стиль
  onDeleteClick: function(e) {
    var $button = $(e.currentTarget),
      $menu = $button.closest('.style-menu'),
      styleName = $menu.attr('data-id'),
      $style = this.$('.link-style[data-id="' + styleName + '"]');

    this.closeMenu(true);

    $style.addClass('enable-transition');

    setTimeout(
      _.bind(function() {
        $style.addClass('shift-left');

        setTimeout(
          _.bind(function() {
            $style.css('margin-top', -$style.outerHeight());

            setTimeout(
              _.bind(function() {
                $style.remove();

                this.$panel.find('.styles-wrapper').sortable('refresh');

                this.resizableScroll && this.resizableScroll.recalc();

                RM.constructorRouter.textStyles.deleteStyle('link', styleName);

                RM.constructorRouter.textStyles.generateCSS('link', 'editor', this.block.iframe_document);
                RM.constructorRouter.textStyles.generateCSS('link', 'constructor', document);

                this.block.somethingChangedInEditor('styleDeleted');
              }, this),
              250 + 20
            );
          }, this),
          250 + 20
        );
      }, this),
      50
    );
  },

  // добавить новый стиль, параметры стиля берутся из первого найденного стиля в текущем выделении
  addClick: function(e) {
    var style = this.textStyles.addStyle('link', this.NEW_DEFAULT_STYLE);

    RM.constructorRouter.textStyles.generateCSS('link', 'editor', this.block.iframe_document);
    RM.constructorRouter.textStyles.generateCSS('link', 'constructor', document);

    this.block.somethingChangedInEditor('styleAdded');

    var $elem = $(this.style_template(style));

    $elem.addClass('enable-transition').addClass('collapsed');

    this.$panel.find('.styles-wrapper').append($elem);

    this.$el.find('.resizable-content-wrapper').scrollTop(9999);

    setTimeout(
      _.bind(function() {
        $elem.removeClass('collapsed');

        setTimeout(
          _.bind(function() {
            $elem.removeClass('enable-transition');

            this.$panel.find('.styles-wrapper').sortable('refresh');

            this.resizableScroll && this.resizableScroll.recalc();
          }, this),
          250 + 20
        );
      }, this),
      50
    );
  },

  // переформировать новый <style> для редактора
  refreshEditorCss: function() {
    var pseudoState =
      this.currentStyleEditMode === 'hover' || this.currentStyleEditMode === 'current'
        ? this.currentStyleEditMode
        : false;
    var params = {
      forcePseudoState: pseudoState,
      forcePseudoClass: pseudoState ? this.getEditValue('_name') : false,
    };
    RM.constructorRouter.textStyles.generateCSS('link', 'editor', this.block.iframe_document, params);

    this.block.somethingChangedInEditor('styleChanging');
  },

  // открытие панельки редактирования стиля
  onEditClick(evt) {
    // different event types can trigger showing of an edit panel
    // and for each event type we have to search for data-id in defferent dom elements
    let evtTypesMap = {
      dblclick(evt) {
        return $(evt.currentTarget).closest('.link-style');
      },
      click() {
        return $(evt.currentTarget).closest('.style-menu');
      },
    };
    let className = evtTypesMap[evt.type](evt);
    let styleName = $(evt.currentTarget)
      .closest(className)
      .attr('data-id');
    let style = this.textStyles.findStyle('link', styleName);

    this.originalEditStyle = _.clone(style);
    this.currentEditStyle = style;

    this.initCurrentStyle();

    this.setEditParams();

    // принудительно переключаем в состояние дефолта, а не ховера
    this.switchState('default');

    this.$panel.addClass('toggle-opaque');

    this.closeMenu(true);

    setTimeout(
      _.bind(function() {
        this.$panel.addClass('show-style-edit');
      }, this),
      250 + 50
    );
  },

  // закрытие панельки редактирования стиля
  editClose: function() {
    this.closeAllEditPopups();

    RM.constructorRouter.textStyles.generateCSS('link', 'editor', this.block.iframe_document);
    RM.constructorRouter.textStyles.generateCSS('link', 'constructor', document);

    this.resizableScroll && this.resizableScroll.recalc();

    this.block.somethingChangedInEditor('styleChanged');

    this.$panel.removeClass('show-style-edit');

    setTimeout(
      _.bind(function() {
        this.$panel.removeClass('toggle-opaque');
      }, this),
      350 + 50
    );
  },

  isInState: function(state) {
    return this.$panel.find('.edit-wrapper').hasClass(state + '-state');
  },

  onStateSwitchClick: function(e) {
    // чтобы не было случайных переходов межуд обычным и ховер состоянием,
    // когда юзер пытаясь закрыть колорбокс кликает по пустому месту панели и попадаем по тим кнопкам переключения режима
    if (this.colorbox_visible || this.underline_style_visible) return;

    if ($(e.currentTarget).hasClass('caption-default')) this.switchState('default');

    if ($(e.currentTarget).hasClass('caption-hover')) this.switchState('hover');

    if ($(e.currentTarget).hasClass('caption-current')) this.switchState('current');
  },

  switchState: function(state) {
    var $editWrapper = this.$panel.find('.edit-wrapper');
    $editWrapper.removeClass('hover-state default-state current-state');

    $editWrapper.addClass(state + '-state');
    if (state === 'hover' || state === 'current') {
      // важно, поскольку у нас в ховере могут быть стили наследуемые от обычного состояния
      // и их надо заново отрисовать, вдруг они поменялись в обычном состоянии
      this.setEditParams();
    }

    // это для refreshEditorCss
    this.currentStyleEditMode = state;

    // важно, поскольку нам при переключении между обычным состоянием и ховером надо
    // в редакторе также отображать состояние либо обычное либо ховера
    // refreshEditorCss смотрит какое в данный момент выбрано состояние и соответственно генирирует нужные стили
    // т.е. это чисто на момент редактирования стиля чтобы видеть что из себя представляют в реальности настройки как для обычного состояния так и для ховера
    this.refreshEditorCssDebounced();
  },

  getEditValue: function(s) {
    if (!this.currentEditStyle) return;

    // Для стилей current-, которых нет в currentEditStyle (которые берутся из mag.edit_params) по умолчанию, возьмём значения из NEW_DEFAULT_STYLE
    var isUndefinedCurrentValue = _.isUndefined(this.currentEditStyle[s]) && /^current/.test(s);
    var res = isUndefinedCurrentValue ? this.NEW_DEFAULT_STYLE[s] : this.currentEditStyle[s];

    // в стилях линков для состояния ховера значения могут наследоваться от обычного состояния
    if (res == 'inherit') res = this.currentEditStyle[s.replace(/^(hover|current)\-/, '')];

    return res;
  },

  // устанавливаем состояние панельки редактирование в соответсвии с нужным стилем
  setEditParams: function() {
    var $parent = this.$panel.find('.edit-wrapper');

    // название стиля
    $parent.find('.edit-item[data-field="_caption"] input').val(this.getEditValue('_caption'));

    // стиль подчеркивания
    $parent.find('.edit-item[data-field="u-style"] .underline-style').attr('data-type', this.getEditValue('u-style'));
    $parent
      .find('.edit-item[data-field="hover-u-style"] .underline-style')
      .attr('data-type', this.getEditValue('hover-u-style'));
    $parent
      .find('.edit-item[data-field="current-u-style"] .underline-style')
      .attr('data-type', this.getEditValue('current-u-style'));

    // цвет
    $parent
      .find('.edit-item[data-field="color"] .color-circle')
      .css({ background: '#' + this.getEditValue('color'), opacity: this.getEditValue('opacity') / 100 });
    $parent
      .find('.edit-item[data-field="u-color"] .color-circle')
      .css({ background: '#' + this.getEditValue('u-color'), opacity: this.getEditValue('u-opacity') / 100 });
    $parent
      .find('.edit-item[data-field="hover-color"] .color-circle')
      .css({ background: '#' + this.getEditValue('hover-color'), opacity: this.getEditValue('hover-opacity') / 100 });
    $parent.find('.edit-item[data-field="current-color"] .color-circle').css({
      background: '#' + this.getEditValue('current-color'),
      opacity: this.getEditValue('current-opacity') / 100,
    });
    $parent.find('.edit-item[data-field="hover-u-color"] .color-circle').css({
      background: '#' + this.getEditValue('hover-u-color'),
      opacity: this.getEditValue('hover-u-opacity') / 100,
    });
    $parent.find('.edit-item[data-field="current-u-color"] .color-circle').css({
      background: '#' + this.getEditValue('current-u-color'),
      opacity: this.getEditValue('current-u-opacity') / 100,
    });

    // размер подчеркивания и отступ
    this.editUnderlineWidthChangeValue(this.getEditValue('u-size'), true);
    this.editPaddingChangeValue(this.getEditValue('u-offset'), true);
    this.editHoverUnderlineWidthChangeValue(this.getEditValue('hover-u-size'), true);
    this.editHoverPaddingChangeValue(this.getEditValue('hover-u-offset'), true);
    this.editCurrentUnderlineWidthChangeValue(this.getEditValue('current-u-size'), true);
    this.editCurrentPaddingChangeValue(this.getEditValue('current-u-offset'), true);

    this.fixInputsPadding();

    this.checkNoUnderline();
  },

  // искуственный костыль для красоты, смотрим инпуты отступа и толщины линии
  // и в cлучае если значение оканцивается на 1 или -1 добавляем отступ справа от цифры, а то единица ломает стройный вертикальный флоу
  fixInputsPadding: function() {
    var inputs = ['u-size', 'u-offset', 'hover-u-size', 'hover-u-offset', 'current-u-size', 'current-u-offset'];

    for (var i = 0; i < inputs.length; i++) {
      var $input = this.$panel.find('.edit-wrapper .edit-item[data-field="' + inputs[i] + '"] input');
      $input.toggleClass('padding-for-one', Math.abs($input.val()) % 10 == 1);
    }
  },

  // при изменениях в интупе с именем стиля
  onEditCaptionChange: function(e) {
    this.currentEditStyle._caption = $(e.target).val();
  },

  // при изменениях в размер шрифта, интерлиньяж и межбуквенный интервал и марджинах
  onEditInputChanged: function($input, new_num) {
    this.currentEditStyle[$input.closest('.edit-item').attr('data-field')] = new_num;

    this.fixInputsPadding();

    this.refreshEditorCssDebounced();
  },

  // при клике по полю с цветом (показ-прятание колорбокса)
  onColorboxShowClick: function(e) {
    var $item = $(e.currentTarget),
      field = $item.attr('data-field'),
      field_dop = $item.attr('data-field-dop'),
      fadeSpeed = 200;

    if (this.colorbox_visible) {
      // смотрим кликнули по полю для текущего колорбокса или по другому
      if (field == this.colorbox_for_field) {
        this.closeColorBox();
        return;
      } else {
        this.closeColorBox(true);
        fadeSpeed = 0;
      }
    }

    this.closeAllEditPopups();

    this.colorbox_visible = true;

    this.$('.colorbox_container').fadeIn(fadeSpeed);

    this.colorbox_for_field = field;
    this.colorbox_for_field_dop = field_dop;

    // спец. костыль: цвет подчеркивания наследует цвет текста (только для обычного состояни, не ховера)
    // сделано просто, если в момент открытия колорбокса для цвета текста он совпадает с цветом подчеркивания
    // то любые изменения цвета текса также переносим и на цвет подчеркивания
    // в такой схеме потом сожно поменть цвет подчеркивания отдельно и цвета текста и пожчеркивания отвяжутся друг от друга (ну до тех пор пока их снова не сделают одинаковыми)
    this.synchonizeColors =
      field == 'color' &&
      this.getEditValue('color') == this.getEditValue('u-color') &&
      this.getEditValue('opacity') == this.getEditValue('u-opacity');

    // устанавливаем параметры в колорбоксе
    this.colorbox.setOpacity(this.getEditValue(field_dop) / 100);
    this.colorbox.setColor(this.getEditValue(field));
  },

  // при изменении цвета-прозрачности через колорбокс
  colorOpacityChanged: function(r, g, b, a) {
    this.currentEditStyle[this.colorbox_for_field] = this.colorbox && this.colorbox.rgb2hex([r, g, b]);
    this.currentEditStyle[this.colorbox_for_field_dop] = a * 100;

    if (this.synchonizeColors) {
      this.currentEditStyle['u-color'] = this.colorbox && this.colorbox.rgb2hex([r, g, b]);
      this.currentEditStyle['u-opacity'] = a * 100;
    }

    this.setEditParams();

    this.refreshEditorCssDebounced();
  },

  // при клике по полю с стилем подчеркивания (показ-прятание попапа стиля подчеркивания)
  onUnderlineStyleShowClick: function(e) {
    if (this.underline_style_visible) {
      this.closeUnderlineStyle();
      return;
    }

    this.closeAllEditPopups();

    this.underline_style_visible = true;

    this.$('.underline_style_container').fadeIn(200);

    var $item = $(e.currentTarget),
      field = $item.attr('data-field');

    this.underline_style_for_field = field;

    // отмечаем текущий стиль
    this.$('.underline_style_container .underline-style-item[data-type="' + this.getEditValue(field) + '"]')
      .addClass('active')
      .siblings('.underline-style-item')
      .removeClass('active');
  },

  // при выборе стиля шрифта в выпадалке стиля шрифта
  underlineStyleChanged: function(e) {
    this.currentEditStyle[this.underline_style_for_field] = $(e.currentTarget).attr('data-type');

    this.setEditParams();

    this.checkNoUnderline();

    this.refreshEditorCssDebounced();

    this.closeUnderlineStyle();
  },

  checkNoUnderline: function() {
    var styles = ['u-style', 'hover-u-style', 'current-u-style'];

    for (var i = 0; i < styles.length; i++) {
      var val = this.getEditValue(styles[i]),
        $block = this.$panel.find('.edit-wrapper .edit-item[data-field="' + styles[i] + '"]').closest('.params-block');
      $block.toggleClass('no-underline', val == 'none');
    }
  },

  // закрывает колорбокс
  closeColorBox: function(noAnimation) {
    this.colorbox_visible = false;
    this.$('.colorbox_container').fadeOut(noAnimation ? 0 : 200);
  },

  // закрывает попап стиля подчеркивания
  closeUnderlineStyle: function() {
    this.underline_style_visible = false;
    this.$('.underline_style_container').fadeOut(200);
  },

  // закрыть все выпадалки
  closeAllEditPopups: function(e) {
    if (e) {
      if ($(e.target).closest('.colorbox_container').length > 0) return;
      if ($(e.target).closest('.color-click-area').length > 0) return;

      if ($(e.target).closest('.underline_style_container').length > 0) return;
      if ($(e.target).closest('.underline-style-click-area').length > 0) return;
    }

    if (this.colorbox_visible) this.closeColorBox();

    if (this.underline_style_visible) this.closeUnderlineStyle();
  },

  // клик по кнопке сохранения отредактированного стиля
  saveEditClick: function() {
    var $style = this.$('.link-style[data-id="' + this.getEditValue('_name') + '"]');

    $style.find('.style-caption').text(this.getEditValue('_caption'));

    this.textStyles.changeStyle('link', this.getEditValue('_name'));

    this.editClose();
  },

  // клик по кнопке отмены редактирования стиля
  cancelEditClick: function() {
    this.textStyles.restoreStyle('link', this.getEditValue('_name'), this.originalEditStyle);

    this.editClose();
  },

  // вызывается при уничтожении контрола - сохраняет сортировку стилей внутри панели (если она поменялась)
  // ========= полностью переопределен в линках для картинок & шейпов ============
  save: function() {
    if (_.isEqual(this.textStyles.getOrder('link'), this.textStyles.orderBefore['link'])) return;

    this.textStyles.save();
  },

  /**
   * Переопределяем реакцию закрытия паельки контрола
   */
  deselect: function() {
    this.closeAllEditPopups();

    if (this.placeholder_popup_visible) this.closePlaceholderPopup();

    this.anchorExitMode();

    ControlClass.prototype.deselect.apply(this, arguments);
  },

  // расширяем метод уничтожения контрола, если он в режиме редактирования стиля - просто сохраняем все изменения
  // ========= полностью переопределен в линках для картинок & шейпов ============
  destroy: function() {
    if (this.$panel.hasClass('show-style-edit')) this.saveEditClick();

    this.anchorExitMode();

    // принудительно вызываем save для сохранения порядка стилей
    // в прототипе destroy это уже не вызывается для данного контрола, потому что него нет модели
    // т.е. это костыль такой
    this.save();

    ControlClass.prototype.destroy.apply(this, arguments);
  },

  // расширяем метод который срабатывает при открытии панели контрола
  // ========= полностью переопределен в линках для картинок & шейпов ============
  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    // при открытии панельки проставляем состояние контрола в зависимости от текущего стиля выделения в редакторе
    // (при закрытой панельке в ней ничего не обновляется, для скорости)
    this.block && this.selectionStylesChanged(this.block.cur_selection_styles);

    this.resizableScroll && this.resizableScroll.recalc();

    this.repositionPanel();

    PreloadDesignImages('controls-text_link');
  },

  // расширяем метод клика по иконке контрола
  onClick: function() {
    if (this.selected) {
      if (this.colorbox_visible || this.underline_style_visible) {
        this.closeAllEditPopups();
        return;
      }

      if (this.$('.style-menu').hasClass('shown')) {
        this.closeMenu(true);
        return;
      }

      if (this.placeholder_popup_visible) {
        this.closePlaceholderPopup();
        return;
      }
    }

    ControlClass.prototype.onClick.apply(this, arguments);
  },

  // расширяем метод реакции на кнопку Esc
  onEscKey: function() {
    if (this.selected) {
      if (this.colorbox_visible || this.underline_style_visible) {
        this.closeAllEditPopups();
        return;
      }

      if (this.$('.style-menu').hasClass('shown')) {
        this.closeMenu(true);
        return;
      }

      if (this.placeholder_popup_visible) {
        this.closePlaceholderPopup();
        return;
      }

      if (this.isAnchorTargetMode) {
        this.anchorExitTargetMode();
        return;
      }
    }

    ControlClass.prototype.onEscKey.apply(this, arguments);
  },

  /**
   * расширяем функцию которая решает поглощает контрол событие или нет (обычно это событие deselect воркспейса)
   */
  canControlBeClosed: function() {
    if (this.colorbox_visible || this.underline_style_visible) {
      this.closeAllEditPopups();
      return true;
    }

    if (this.$('.style-menu').hasClass('shown')) {
      this.closeMenu(true);
      return true;
    }

    if (this.placeholder_popup_visible) {
      this.closePlaceholderPopup();
      return true;
    }

    if (this.isAnchorTargetMode) {
      return true;
    }

    return ControlClass.prototype.canControlBeClosed.apply(this, arguments);
  },
});

export default TextLink;
