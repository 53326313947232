/**
 * Конструктор для контрола цвета шейпа
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import Colorbox from '../helpers/colorbox';
import templates from '../../../templates/constructor/controls/picture_color.tpl';

const PictureColor = ControlClass.extend({
  name: 'picture_color', // должно совпадать с классом вьюхи

  className: 'control picture_color',

  saveOnDeselect: true,
  saveOnDestroy: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-picture-color'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;

    this.block.on(
      'picture_added',
      function() {
        this.$el.toggle(this.block.isVector());
      },
      this
    );

    if (!this.block.model.get('picture')) {
      this.$el.hide();
    }
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.colorbox = new Colorbox({ $parent: this.$panel, type: 'small' });

    this.colorbox.on('colorchange', this.colorChanged);
    this.colorbox.on('opacitychange', this.colorChanged);

    this.model.on('change:pic_color', this.onColorChange);
    this.model.on('change:pic_opacity', this.onColorChange);

    this.updateState();
  },

  unBindLogic: function() {
    this.colorbox && this.colorbox.off('colorchange', this.colorChanged);
    this.colorbox && this.colorbox.off('opacitychange', this.colorChanged);
    this.colorbox && this.colorbox.destroy();

    this.model.off('change:pic_color', this.onColorChange);
    this.model.off('change:pic_opacity', this.onColorChange);
  },

  updateIconState: function(color, opacity) {
    if (!color || opacity == undefined) return;
    if (!this.$icon) return;

    this.$icon.find('.color-circle').css({
      background: '#' + color,
      opacity: opacity,
    });
  },

  updateColorboxState: function(color, opacity) {
    if (!color || opacity == undefined) return;
    if (!this.colorbox) return;

    this.colorbox.setOpacity(opacity);
    this.colorbox.setColor(color);
  },

  colorChanged: function(r, g, b, a) {
    var color = this.colorbox && this.colorbox.rgb2hex([r, g, b]);
    this.updateIconState(color, a);

    this.model.set({ pic_color: color, pic_opacity: a });

    if (!this.block.$svg) return;

    this.block.setVectorColor(color, a);
  },

  updateState: function() {
    var color = this.model.get('pic_color') || '000000',
      opacity = this.model.get('pic_opacity') || 1;

    this.updateIconState(color, opacity);
    this.updateColorboxState(color, opacity);

    if (!this.block.$svg) return;
  },

  onColorChange: function(model, val, options) {
    if (options.undo || options.redo) this.updateState();
  },

  save: function() {
    this.block.saveSvg(_.bind(this.model.save, this.model));
  },
});

export default PictureColor;
