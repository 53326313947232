var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-title"},[_vm._v("Audio")]),_vm._v(" "),(!_vm.m.url)?_c('a',{staticClass:"soundcloud-logo",attrs:{"href":"https://soundcloud.com/","target":"_blank"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search-input",class:{ 'with-icon': (_vm.isUrl && !_vm.m.url) || _vm.m.url },attrs:{"placeholder":"URL of track or user"},domProps:{"value":(_vm.search)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadAudio($event)},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_vm._v(" "),(_vm.isUrl && !_vm.m.url)?_c('div',{staticClass:"sicon apply-btn",on:{"click":function($event){$event.stopPropagation();return _vm.loadAudio($event)}}}):_vm._e(),_vm._v(" "),(_vm.m.url)?_c('div',{staticClass:"sicon remove-btn",on:{"click":function($event){$event.stopPropagation();return _vm.resetAudio($event)}}}):_vm._e()]),_vm._v(" "),(_vm.m.url)?_c('a',{staticClass:"soundcloud-logo-small",attrs:{"href":"https://soundcloud.com/","target":"_blank"}}):_vm._e(),_vm._v(" "),(!!_vm.errorMessage)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_vm._v(" "),(!!_vm.m.url)?_c('div',{staticClass:"settings"},[_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.m.title))]),_vm._v(" "),_c('div',{staticClass:"separator"}),_vm._v(" "),_c('rm-spinner',{attrs:{"variants":[
        {
          value: 'visual',
          title: 'Visual Player',
        },
        {
          value: 'standard',
          title: 'Original Player',
        },
        {
          value: 'minimal',
          title: 'Minimal',
        } ]},model:{value:(_vm.m.current_type),callback:function ($$v) {_vm.$set(_vm.m, "current_type", $$v)},expression:"m.current_type"}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"caption"},[_vm._v("Comments")]),_vm._v(" "),_c('rm-switcher',{model:{value:(_vm.m.comments),callback:function ($$v) {_vm.$set(_vm.m, "comments", $$v)},expression:"m.comments"}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"caption"},[_vm._v("Artwork")]),_vm._v(" "),_c('rm-switcher',{model:{value:(_vm.m.artwork),callback:function ($$v) {_vm.$set(_vm.m, "artwork", $$v)},expression:"m.artwork"}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"caption"},[_vm._v("Social")]),_vm._v(" "),_c('rm-switcher',{model:{value:(_vm.m.socials),callback:function ($$v) {_vm.$set(_vm.m, "socials", $$v)},expression:"m.socials"}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"caption"},[_vm._v("Color")]),_vm._v(" "),_c('color-picker',{attrs:{"color":_vm.m.color,"show-alpha":false},on:{"showPopup":_vm.closeOthersPopups,"update:color":function (col) { return (_vm.m.color = col); }}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }