/**
 * Вьюха с панелью выбора шрифта
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import FontExplorer from './font-explorer';
import templates from '../../../templates/constructor/helpers/font-selector.tpl';
import Alert from '../../common/alert';
import TypekitPopup from './typekit-popup';
import PreloadDesignImages from '../../common/preload-design-images';

RM.views.fontExplorer = new FontExplorer();

var FontSelector = Backbone.View.extend({
  name: 'FontSelector',

  /**
   * Подготавливаем данные для работы панельки
   * params:
   *  $parent: $ объект родитель
   *  css: объект который хранит смещения в родителе, например {left: -79, top: 15}
   *  пока поддерживаются только left и top
   */
  initialize: function(params) {
    this.model = RM.constructorRouter.fonts;

    // настройки панельки
    this.settings = {};

    this.old_fonts = [];

    _.bindAll(this);

    _.extend(this.settings, params);

    // Сохраняем мэг
    this.mag = RM.constructorRouter.mag.toJSON();

    // Если это наш мэг то проверяем наши права, иначе права владельца (но тут возможно стоит всегда права владельца проверять)
    this.settings.canUploadFonts = RM.constructorRouter.isOwner
      ? (RM.constructorRouter.me.get('permissions') || {}).can_use_custom_fonts
      : this.mag.user.permissions.can_use_custom_fonts;

    this.settings.canEditCustomFonts = this.mag.doesUserHaveAccess; // doesUserHaveAccess - у нас есть права на мэг

    this.template = templates['template-constructor-helpers-font-selector'];

    this.item_template = templates['template-constructor-helpers-font-selector-item'];

    this.create();
  },

  create: function() {
    this.render();

    this.switchToCategory(this.model.get('fonts_tab') || 'custom');

    this.model.on('change:fonts', this.onFontsChange);
  },

  /**
   * Рисуем панельку
   */
  render: function() {
    var left = (this.settings.css && this.settings.css.left) || 0;
    var top = (this.settings.css && this.settings.css.top) || 0;

    this.setElement(
      $(this.template({})).css({
        display: 'none',
        left: left,
        top: top,
      })
    );
    this.settings.$parent.append(this.$el);

    this.$addFontPopup = this.$('.add-font-popup');

    this.$el.on(
      'click',
      '.tabs-switcher .caption-system',
      _.bind(function() {
        this.switchToCategory('system');
        RM.analytics && RM.analytics.sendEvent('Font Selector Switch to Category', 'system');
      }, this)
    );

    this.$el.on(
      'click',
      '.tabs-switcher .caption-custom',
      _.bind(function() {
        this.switchToCategory('custom');
        RM.analytics && RM.analytics.sendEvent('Font Selector Switch to Category', 'custom');
      }, this)
    );

    this.$el.on('click', this.onGlobalClickHandler);

    this.$el.on('mouseenter', '.category-element .caption', this.checkTitleShowForLongFontName);
    this.$el.on('click', '.category-element .edit-custom-font', this.onEditCustomFontClick);

    this.$el.on('click', '.add-font-button', this.onAddFontButtonClick);

    this.onFontsChange();

    this.scroll = {
      system: this.createScroll('system'),
      custom: this.createScroll('custom'),
    };

    if (!this.settings.canEditCustomFonts) {
      this.$('.add-font-button[data-type=upload]').remove();
    }
  },

  onFontsChange: function() {
    var old_fonts = this.old_fonts,
      cur_fonts = this.model.get('fonts'),
      old_fonts_ids = _.map(old_fonts, function(font) {
        return font.provider + '|' + font.css_name;
      }),
      cur_fonts_ids = _.map(cur_fonts, function(font) {
        return font.provider + '|' + font.css_name;
      }),
      self = this;

    clearTimeout(this.reinitTimeout);

    // находим шрифты которые добавились, удалились, остались на месте с последнего раза
    var added = _.difference(cur_fonts_ids, old_fonts_ids),
      removed = _.difference(old_fonts_ids, cur_fonts_ids),
      same = _.intersection(old_fonts_ids, cur_fonts_ids);

    // добавляем шрифт в панельку
    _.each(added, function(font_id) {
      var tmp = font_id.split('|'),
        provider = tmp[0],
        css_name = tmp[1],
        $container = self.$(
          '.categories .scroll-wrapper.' + (provider == 'system' ? 'system' : 'custom') + ' .category'
        ),
        font = self.model.findFontByCSSName(css_name),
        provider_data = self.model.getProviderData(provider);

      var $newElem = $(
        self.item_template({
          provider_data: provider_data,
          font: font,
          canUploadFonts: self.settings.canUploadFonts,
          canEditCustomFonts: self.settings.canEditCustomFonts,
        })
      ).addClass('collapsed');

      setTimeout(function() {
        $newElem.removeClass('collapsed');
      }, 15);

      // ищем после какого шрифта в списке вставить текущий чтобы не нарушить сортировку
      if ($container.find('.category-element').length == 0) {
        // в списке шрифтов пусто
        $newElem.appendTo($container);
      } else {
        var found = false;
        $container.find('.category-element').each(function() {
          var $item = $(this);
          if (
            $item
              .find('.caption')
              .text()
              .toLowerCase() > font.name.toLowerCase()
          ) {
            $newElem.insertBefore($item);
            found = true;
            return false;
          }
        });
        if (!found) $newElem.appendTo($container);
      }
    });

    // удаляем шрифт
    _.each(removed, function(font_id) {
      var tmp = font_id.split('|'),
        provider = tmp[0],
        css_name = tmp[1],
        $container = self.$(
          '.categories .scroll-wrapper.' + (provider == 'system' ? 'system' : 'custom') + ' .category'
        ),
        font = self.model.findFontByCSSName(css_name),
        provider_data = self.model.getProviderData(provider);

      var $remObj = $container.find(
        '.category-element[data-provider="' + provider + '"][data-css_name="' + css_name + '"]'
      );

      $remObj.addClass('collapsed');

      setTimeout(function() {
        $remObj.remove();
      }, 300);
    });

    // смотрим шрифты у которых изменилось поле hidden или название
    _.each(same, function(font_id) {
      var tmp = font_id.split('|'),
        provider = tmp[0],
        css_name = tmp[1],
        $container = self.$(
          '.categories .scroll-wrapper.' + (provider == 'system' ? 'system' : 'custom') + ' .category'
        ),
        font = self.model.findFontByCSSName(css_name),
        $item = $container.find(
          '.category-element[data-provider="' + provider + '"][data-css_name="' + css_name + '"]'
        );

      $item
        .toggleClass('hidden', !!font.hidden)
        .find('.caption')
        .text(font.name);

      if (font.hidden) {
        $item.removeClass('active'); // если нас просят спрятать шрифт который сейчас активен, тогда не делаем его сейчас активным, чтобы визуально шрифт спрятался, при повторном выборе текста с этим шрифтов он будет виден серым как раньше https://trello.com/c/qNnpdqCv/68--
      }
    });

    this.old_fonts = _.cloneWithObjects(cur_fonts);

    this.reinitTimeout = setTimeout(this.reinitScrolls, 250);
  },

  checkTitleShowForLongFontName: function(e) {
    var caption = e.currentTarget,
      $caption = $(caption);

    if (caption.offsetWidth < caption.scrollWidth && !$caption.attr('title')) $caption.attr('title', $caption.text());
  },

  onEditCustomFontClick: function(e) {
    var cssName = $(e.currentTarget)
        .closest('.category-element')
        .attr('data-css_name'),
      fonts = this.model.get('fonts'),
      fontParams = _.findWhere(fonts, { css_name: cssName });

    this.showFontUploadPanel(fontParams);

    RM.analytics && RM.analytics.sendEvent('Font Selector Edit Custom Font');
  },

  scrollFontsToActive: function(tp, speed) {
    var $scroll_container = this.$('.categories .scroll-wrapper.' + tp),
      $category_container = this.$('.categories .scroll-wrapper.' + tp + ' .category-wrapper'),
      $active_element = $category_container.find('.category-element.active').eq(0),
      visible = true;

    if ($active_element.length != 1) return;

    if (!$scroll_container.is(':visible')) {
      $scroll_container.css('display', 'block');
      visible = false;
    }

    var element_top = $active_element.position().top,
      element_height = $active_element.height(),
      container_height = $category_container.height(),
      target_top = Math.floor(element_top - (container_height - element_height) / 2);

    if (!visible) {
      $category_container.stop().scrollTop(target_top);
      $scroll_container.css('display', 'none');
    } else {
      $category_container.stop().animate({ scrollTop: target_top }, speed);
    }
  },

  reinitScrolls: function() {
    _.each(this.scroll, function(scroll) {
      scroll.recalc();
    });
  },

  createScroll: function(tp) {
    var $scroll_container = $(this.$('.categories .scroll-wrapper.' + tp));

    return $scroll_container
      .RMScroll({
        $container: $scroll_container.find('.category-wrapper'),
        $content: $scroll_container.find('.category-wrapper .category'),
        $handle: $scroll_container.find('.scroll'),
        gap_start: 8,
        gap_end: 8,
      })
      .data('scroll');
  },

  onGlobalClickHandler: function(e) {
    var $obj = $(e.target),
      $item = $obj.closest('.category-element'),
      css_name = $item.attr('data-css_name');

    // кликнули по иконке добавления - удаления
    if ($obj.hasClass('action') && $obj.parent().hasClass('category-element')) {
      // Если пользователь не владелец мэга и у него есть доступ к нему
      var mag_user, mag_num_id;
      if (!RM.constructorRouter.isOwner && this.settings.canEditCustomFonts) {
        mag_user = this.mag.user._id;
        mag_num_id = this.mag.num_id;
      }
      var res = this.model.changeFontHidden(css_name, !$item.hasClass('hidden'), mag_user, mag_num_id);
      if (res.error) new TypekitPopup({ $parent: $obj });

      var analyticsLabel = $item.hasClass('hidden') ? 'delete' : 'restore';
      RM.analytics && RM.analytics.sendEvent('Font Selector Edit List', analyticsLabel);
    }

    // кликнули по названию шрифта, и шрифт не задизаблен, - выбираем его
    if ($obj.hasClass('caption') && $obj.parent().hasClass('category-element')) {
      if ($item.hasClass('disabled')) {
        // Для пользовательских шрифтов показываем алерт
        if ($item.hasClass('personal')) {
          this.showUpgradeAlert({ type: 'type-upload-fonts' }, 'use custom font');
        }

        return;
      }

      this.setActiveFont([css_name]);
      this.trigger('selectfont', css_name);
    }

    if (this.isAddFontPopupVisible && !$obj.closest('.add-font').length) {
      this.toggleAddFontPopup();
    }
  },

  setActiveFont: function(active_fonts, auto_scroll_speed) {
    this.$('.categories .category-element').removeClass('active');
    _.each(
      active_fonts,
      _.bind(function(font) {
        this.$('.categories .category-element[data-css_name="' + font + '"]').addClass('active');
      }, this)
    );

    // если задано значение auto_scroll_speed, то скролим шрифты до первого активного (во всех панельках)
    // со скоростью auto_scroll_speed
    if (auto_scroll_speed !== undefined) {
      this.scrollFontsToActive('system', auto_scroll_speed);
      this.scrollFontsToActive('custom', auto_scroll_speed);
    }
  },

  show: function(active_fonts) {
    this._initialTab = this.model.get('fonts_tab');
    this._initialFonts = this.model.get('fonts');

    PreloadDesignImages('fontselector');

    this.$el.fadeIn(150);
    this.setActiveFont(active_fonts, 0);
    this.reinitScrolls();

    RM.analytics && RM.analytics.sendEvent('Font Selector Show');
  },

  hide: function(e) {
    this.$el.fadeOut(150);

    if (!this._initialTab && !this._initialFonts) return; // Если не открывали панель то нечего и сохранять

    if (
      !_.isEqual(this.model.get('fonts_tab'), this._initialTab) ||
      !_.isEqual(this.model.get('fonts'), this._initialFonts)
    ) {
      this.model.save({}, { silent: true });
    }
  },

  switchToCategory: function(cat) {
    if (cat != 'system' && cat != 'custom') cat = 'custom';

    this.model.set({ fonts_tab: cat }, { silent: true });

    this.$('.tabs-switcher .caption-' + cat)
      .addClass('active')
      .siblings()
      .removeClass('active');

    if (cat == 'system')
      this.$('.add-font')
        .stop()
        .fadeOut();
    else
      this.$('.add-font')
        .stop()
        .fadeIn();

    var $old = this.$('.categories > div:visible').eq(0),
      $new = this.$('.categories .scroll-wrapper.' + cat);

    if ($old.is($new)) return;

    if ($old.length == 0) {
      // еще не показано не одной панельки со шрифтами, текущую показываем без анимации
      $new.css('display', 'block');
    } else {
      var animationDir = $old.index() < $new.index() ? 1 : -1;
      // с анимацией показываем новую панельку шрифтов и удаляем старую
      $new
        .css({ display: 'block', opacity: 0, 'margin-left': 8 * animationDir })
        .animate({ opacity: 1, 'margin-left': 0 }, 300);
      $old.animate({ opacity: 0, 'margin-left': -8 * animationDir }, 300, function() {
        this.setAnimation = false;
        $old.css('display', 'none');
      });
    }

    this.reinitScrolls();
  },

  toggleAddFontPopup: function() {
    this.$addFontPopup.toggleClass('invisible', !!this.isAddFontPopupVisible);

    this.isAddFontPopupVisible = !this.isAddFontPopupVisible;
  },

  onAddFontButtonClick: function(e) {
    var $item = $(e.currentTarget),
      type = $item.attr('data-type');

    switch (type) {
      case 'library':
        this.toggleFontExplorer();
        break;
      case 'upload':
        if (this.canUploadFonts()) {
          this.showFontUploadPanel();
        }
        break;
    }
  },

  canUploadFonts: function() {
    if (!this.settings.canUploadFonts) {
      this.showUpgradeAlert({ type: 'type-upload-fonts' }, 'upload font');
    }
    return this.settings.canUploadFonts;
  },

  showUpgradeAlert: function(opts, alert_source) {
    _.extend(opts, {
      is_contributor: !RM.constructorRouter.isOwner,
      owner_email: RM.constructorRouter.mag.get('user').email,
    });

    this.alertDuplicate =
      this.alertDuplicate ||
      new Alert({
        $parent: $('body > .popups'),
        router: RM.constructorRouter,
        opts: opts,
        alert_source: alert_source,
      });
    this.alertDuplicate.render();
  },

  toggleFontExplorer: function() {
    RM.views.fontExplorer.toggle();
  },

  showFontUploadPanel: function(fontParams) {
    var $el = this.$('.upload-font');
    this.fontUploader = new FontUploader({
      el: $el,
      allFonts: this.model,
      fontParams: fontParams,
      mag: this.mag,
    });

    this.fontUploader.show();

    // Аплоадер выстелит событие при попытке удалить последнее начертание у нескрытого шрифта
    this.listenTo(
      this.fontUploader,
      'hide-font',
      function(e) {
        var css_name = e.css_name;
        this.$('.category-element[data-css_name=' + css_name + ']')
          .find('.action')
          .click();
      }.bind(this)
    );

    this.listenTo(
      this.fontUploader,
      'update-font',
      function(e) {
        var css_name = e.css_name;
        this.trigger('selectfont', css_name); // Чтобы перерендерился список начертаний в text_typography.js
      }.bind(this)
    );

    this.listenToOnce(
      this.fontUploader,
      'hide',
      function() {
        this.stopListening(this.fontUploader);
        this.fontUploader = null;
        this.isFontUploaderVisible = false;
      }.bind(this)
    );

    this.isFontUploaderVisible = true;
  },

  // Если возвращается true, то это значит, что процесс родителя не надо прерывать. Например, родтитель может закрыть себя.
  // Если false, то нужно прервать цепочку обработок Esc
  handleEscape: function() {
    return (this.isFontUploaderVisible && this.fontUploader.handleEscape()) || !this.isFontUploaderVisible;
  },

  /**
   * Уничтожаем панельку
   */
  destroy: function() {
    this.fontuploader && this.fontuploader.remove();
    this.fontuploader = null;

    this.model.off('change:fonts', this.onFontsChange);
    this.hide();
    this.$el.remove();
  },
});

export const FontUploader = Backbone.View.extend({
  className: 'upload-font',

  template: templates['template-constructor-helpers-font-selector-upload'],
  templateWeights: templates['template-constructor-helpers-font-selector-upload-weights'],

  events: {
    'keydown .font-name-input': 'onFontNameKeydown',
    'input .font-name-input': 'onFontNameInput',

    'click .uf-section.actions .done:not(.disabled)': 'hide',
    'click .button.add-weight:not(.disabled)': 'onAddWeightClick',
    'click .weight-item:not(.disabled)': 'onWeightItemClick',
    'click .button.select': 'onSelectButtonClick',
    'click .uf-panel.weights .delete-weight': 'onDeleteWeightClick',
  },

  WEIGTS: {
    n1: 'Thin',
    n2: 'ExtraLight',
    n3: 'Light',
    n4: 'Regular',
    n5: 'Medium',
    n6: 'SemiBold',
    n7: 'Bold',
    n8: 'ExtraBold',
    n9: 'Black',
    i1: 'Thin Italic',
    i2: 'ExtraLight Italic',
    i3: 'Light Italic',
    i4: 'Italic',
    i5: 'Medium Italic',
    i6: 'SemiBold Italic',
    i7: 'Bold Italic',
    i8: 'ExtraBold Italic',
    i9: 'Black Italic',
  },

  SAVE_URL: '/api/customfonts',

  initialize: function(params) {
    _.bindAll(this);
    _.extend(this, params);

    var Model = Backbone.Model.extend({
      urlRoot: this.SAVE_URL,
      idAttribute: '_id',
      defaults: {
        is_personal: true,
      },
    });

    params.fontParams = params.fontParams || {};
    var fontParams = _.omit(params.fontParams, 'variations');
    var variations = _.map(params.fontParams.variations || [], function(v) {
      return { type: v };
    });

    this.isNew = _.isEmpty(fontParams);
    this.model = new Model(fontParams || {}); // Модель с основными параметрами шрифта
    this.weights = new Backbone.Collection(variations); // Отдельная коллекция с начертаниями
    // this.weights = new Backbone.Collection([
    //	{type: 'i7'},
    //	{type: 'n4'},
    // ]);

    // Сортировка начертаний по весу+италик
    this.weights.comparator = function(w) {
      var type = w.get('type');
      // Переставляем букву в конец из заменяем на цифру для правильной сортировки
      return type.slice(1) + type[0].replace('n', '1').replace('i', '2');
    };
    this.weights.sort();
  },

  render: function(data) {
    this.$el.html($(this.template(this.model.toJSON())));

    this.$buttonAddWeight = this.$('.button.add-weight');
    this.$buttonSelect = this.$('.button.select');
    this.$buttonUpload = this.$('.button.upload');
    this.$buttonDone = this.$('.uf-section.actions .done');

    this.$fileUploader = $('#font-file-upload');

    this.listenTo(this.weights, 'add remove', this.renderVariations);
    this.listenTo(this.weights, 'change', this.updateVariation);
    this.bindMainFileupload();

    var $scroll_container = $(this.$('.uf-panel.weights .scroll-wrapper'));
    this.weightScroll = $scroll_container
      .RMScroll({
        $container: $scroll_container.find('.results-wrapper'),
        $content: $scroll_container.find('.results'),
        $handle: $scroll_container.find('.scroll'),
        gap_start: 8,
        gap_end: 88,
      })
      .data('scroll');

    this.renderVariations();
  },

  renderVariations: function() {
    this.$('.uf-panel.weights .list').html(
      this.templateWeights({
        weights: this.weights.toJSON(),
        names: this.WEIGTS,
        isUploadInProgress: _.findWhere(this.weights, { isUploading: true }), // Для запрета удаления любого начертания во время загрузки
        _,
      })
    );

    this.updateWeightSelector();
    this.bindWeightFileuploads();
    this.weightScroll.recalc();
  },

  updateVariation: function(model) {
    var type = model.get('type'),
      $weight = this.$('.uf-panel.weights .weight[data-type=' + type + ']');

    $weight.find('.name').text(this.WEIGTS[type]);
    $weight.find('.reupload-weight').toggleClass('animate', !!model.get('isUploading'));

    // Запрещаем нажимать все кнопки удаления
    this.$('.delete-weight').toggleClass('disabled', !!model.get('isUploading'));
    // Прячем все файловые инпуты, чтобы их нельзя было нажать
    this.$('.uf-panel.weights .list').toggleClass('uploading', !!model.get('isUploading'));

    this.$buttonAddWeight.toggleClass('disabled', !!model.get('isUploading'));
  },

  updateWeightSelector: function() {
    // Дизаблим существующие начертания
    _.each(
      _.keys(this.WEIGTS),
      function(w) {
        var alreadyAdded = !!this.weights.findWhere({ type: w });
        this.$('.weight-item[data-var=' + w + ']').toggleClass('disabled', alreadyAdded);
      }.bind(this)
    );
  },

  bindMainFileupload: function() {
    $(this.$fileUploader)
      .fileupload({
        dataType: 'json',
        fileInput: this.$fileUploader,
        paramName: 'upload',
        dropZone: this.$el,
        singleFileUploads: true,
        acceptFileTypes: /(\.|\/)(woff)$/i,
        pasteZone: null,
        add: this.onUploadAdd,
        start: this.onUploadStart,
        done: this.onUploadDone,
        fail: this.onUploadFail,
        always: this.onUploadComplete,
      })
      .bind(
        'fileuploaddragover',
        function(e) {
          if (this.currentPanel !== 'uploader') {
            return false;
          }

          // Подсвечиваем всю плашку белым, если на панели с кнопкой загрузки шрифта
          clearTimeout(this.dragoverTimeout);
          this.dragoverTimeout = setTimeout(
            function() {
              this.$el.removeClass('file-dragover');
            }.bind(this),
            300
          );
          if (this.$el.hasClass('file-dragover')) {
            return;
          }
          this.$el.addClass('file-dragover');
        }.bind(this)
      )
      .bind(
        'fileuploaddrop',
        function(e, data) {
          // Разрешаем дропать только на панельке с кнопкой Upload .woff
          if (this.currentPanel !== 'uploader') {
            return false;
          }
        }.bind(this)
      );
  },

  bindWeightFileuploads: function() {
    this.reUploadXHR && this.reUploadXHR.abort();

    var $buttons = $(this.$('.uf-panel.weights .font-file-re-upload'));
    $buttons.fileupload({
      dataType: 'json',
      fileInput: this.$buttons,
      paramName: 'upload',
      singleFileUploads: true,
      acceptFileTypes: /(\.|\/)(woff)$/i,
      pasteZone: null,
      add: this.onReuploadEvent,
      start: this.onReuploadEvent,
      done: this.onReuploadEvent,
      fail: this.onReuploadEvent,
      always: this.onReuploadEvent,
    });
  },

  onReuploadEvent: function(e, data) {
    var $input = $(e.target),
      $weight = $input.closest('.weight'),
      type = $weight.attr('data-type'),
      weight = this.weights.findWhere({ type: type });

    if (!weight) {
      return;
    }

    switch (e.type) {
      case 'fileuploadadd':
        var isAccepatable = this.checkFile(data);
        if (!isAccepatable) {
          return;
        }

        this.reUploadXHR = data.submit(); // Сохраняем запрос для аборта
        break;
      case 'fileuploadstart':
        weight.set('isUploading', true);
        break;
      case 'fileuploaddone':
        weight.set('woff', data.result.woffUrl);
        this.saveFont();
        break;
      case 'fileuploadalways':
        weight.set('isUploading', false);
        break;
    }
  },

  onUploadAdd: function(e, data) {
    var isAccepatable = this.checkFile(data);
    if (!isAccepatable) {
      return;
    }

    // Сохраняем запрос для аборта
    this.uploadXHR = data.submit();
  },

  checkFile: function(data) {
    return /\.woff$/i.test((data.files || [{}])[0].name);
  },

  onUploadStart: function(e, data) {
    var model = new Backbone.Model({
      type: this.selectedVar,
    });

    this.weights.add(model);
    model.set('isUploading', true);
    this.switchToPanel('weights');
    this.$buttonAddWeight.addClass('disabled');

    RM.analytics && RM.analytics.sendEvent('Font Selector Custom Font Panel Upload Weight');
  },

  onUploadDone: function(e, data) {
    var weight = this.weights.findWhere({ type: this.selectedVar });

    weight.set('woff', data.result.woffUrl);
    this.saveFont();
  },

  onUploadFail: function(e, data) {
    var weight = this.weights.findWhere({ type: this.selectedVar });
    this.weights.remove(weight); // Перерисуется само
  },

  onUploadComplete: function(e, data) {
    var weight = this.weights.findWhere({ type: this.selectedVar });

    weight && weight.set('isUploading', false);
    this.$buttonAddWeight.removeClass('disabled');
  },

  show: function() {
    PreloadDesignImages('fontuploader');

    this.render();
    this.switchToPanel('weights');

    this.onFontNameInput();

    _.defer(
      function() {
        this.$el.removeClass('invisible');

        // Ждем анимацию
        _.delay(
          function() {
            this.isNew && this.$('.font-name-input').focus();
            this.weightScroll.recalc();
          }.bind(this),
          200
        );
      }.bind(this)
    );

    RM.analytics && RM.analytics.sendEvent('Font Selector Show Custom Font Panel');
  },

  hide: function(e) {
    this.uploadXHR && this.uploadXHR.abort();
    this.reUploadXHR && this.reUploadXHR.abort();

    this.$el.addClass('invisible');
    this.undelegateEvents();

    _.delay(
      function() {
        this.$el.empty();
        this.trigger('hide');
      }.bind(this),
      200
    );
  },

  onFontNameKeydown: function(e) {
    var val = this.$('.font-name-input')
      .val()
      .trim();

    if (e.keyCode == $.keycodes.enter && val && val !== this.model.get('name')) {
      this.saveFont();
    }
  },

  onFontNameInput: function() {
    var val = this.$('.font-name-input')
      .val()
      .trim();

    this.$('.uf-section.weight-list').toggleClass('invisible', !val);
    this.$buttonDone.toggleClass('disabled', !val);
  },

  switchToPanel: function(name) {
    this.currentPanel = name;
    this.$('.uf-panel.' + name)
      .removeClass('invisible')
      .siblings('.uf-panel')
      .addClass('invisible');
  },

  hidePanel: function(selector) {
    this.$('.uf-panel' + selector)
      .removeClass('invisible')
      .siblings('.uf-panel')
      .addClass('invisible');
  },

  onAddWeightClick: function() {
    this.switchToPanel('selector');
  },

  onWeightItemClick: function(e) {
    var $item = $(e.currentTarget);

    this.selectedVar = $item.attr('data-var');
    this.$buttonSelect.text(this.WEIGTS[this.selectedVar]);

    this.switchToPanel('uploader');

    RM.analytics &&
      RM.analytics.sendEvent('Font Selector Custom Font Panel Select Weight', this.WEIGTS[this.selectedVar]);

    // _.delay(function() {
    //	this.$fileUploader.click();
    // }.bind(this), 300);
  },

  onSelectButtonClick: function(e) {
    this.switchToPanel('selector');
  },

  onDeleteWeightClick: function(e) {
    var $weight = $(e.currentTarget).closest('.weight'),
      type = $weight.attr('data-type'),
      weight = this.weights.findWhere({ type: type });

    // Удаляем еще недогруженное начертание
    // Строка удалится из коллбэка onUploadFail
    if ($weight.hasClass('uploading')) {
      this.uploadXHR && this.uploadXHR.abort();
      return;
    }

    if (!this.isNew && this.weights.length < 2) {
      // Если удаляем последнее начертание у еще нескрытого шрифта,
      // то вместо этого просим селектор скрыть шрифт
      if (!this.model.get('hidden')) {
        this.trigger('hide-font', { css_name: this.model.get('css_name') });
      }

      this.hide();
      return;
    }

    // defer - для корректной проверки в контроле text_typography на источник клика
    // Если сделать без дефера, произойдет немедленный ре-рендер, и текущий таргет клика
    // уже не будет иметь нужного родителя, т.к. будет изъят из dom
    _.defer(
      function() {
        this.weights.remove(weight);
        this.saveFont();
      }.bind(this)
    );
  },

  handleEscape: function() {
    if (this.currentPanel !== 'weights') {
      this.switchToPanel('weights');
    } else {
      this.hide();
    }

    return false;
  },

  saveFont: function() {
    var $nameInput = this.$('.font-name-input'),
      name = $nameInput.val().trim() || this.model.get('name'),
      variations = this.weights.map(function(w) {
        return _.pick(w.toJSON(), 'type', 'woff');
      });

    if (!name || !variations.length) {
      return;
    }

    var params = {
      name: name,
      variations: variations,
      is_personal: true,
    };

    // Если пользователь не владелец мэга и у него есть доступ к нему
    if (!RM.constructorRouter.isOwner && this.mag.doesUserHaveAccess) {
      params.mag_user = this.mag.user._id;
      params.mag_num_id = this.mag.num_id;
    }

    this.model.save(params, {
      url: this.SAVE_URL, // Нестандартный, не-REST урл
      success: function(model) {
        this.isNew = false;

        // Обновляем коллекцию
        var variationsArray = _.pluck(model.get('variations'), 'type');
        this.weights.reset(model.get('variations'));
        $nameInput.val(model.get('name'));
        var css_name = this.model.get('css_name');

        // Добавляем шрифт в список в селекторе
        // Это добавляет шрифт к мэгу (edit_params)
        this.allFonts.addFonts(
          [
            {
              provider: 'custom',
              css_name: css_name,
              css_url: this.model.get('css_url'),
              signed_css_url: this.model.get('signed_css_url'),
              name: this.model.get('name'),
              variations: variationsArray,
              is_personal: true,
              _id: this.model.get('_id'), //ВАЖНО!! Так устанавливается связь кастом шрифта со шрифтом в mag_edit_params
            },
          ],
          'font-upload'
        );

        this.trigger('update-font', { css_name: css_name });
      }.bind(this),
    });
  },

  /**
   * Уничтожаем панельку
   */
  destroy: function() {
    // this.model.off("change:fonts", this.onFontsChange);
    // this.hide();
    // this.$el.remove();
  },
});

export default FontSelector;
