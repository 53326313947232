// Модуль для Google universal analytics
// https://developers.google.com/analytics/devguides/collection/analyticsjs/advanced

import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from './utils';

var defaultDimensions = {
  // page type
  dimension1: 'other',

  // user id
  dimension2: 'anonymous',

  // subscription
  dimension3: null,

  // first touch points first source|medium|campaign|referrer|landing page
  dimension4: 'none|direct|none|none|none',

  // first campaign
  dimension5: 'none',

  // first medium
  dimension6: 'none',
};

const extendAnalyticsPublic = {
  initialize: function(options) {
    _.bindAll(this);
    _.extend(this, options);

    // сохраняем текущий роутер
    if (!this.router) this.router = RM.viewerRouter || RM.homepageRouter || RM.collectorRouter;

    this.lastTrackedUrl = '';

    // проверяем shortlink_redirect
    this.shortlink_redirect = Utils.getCookie('shortlink_redirect');

    this._saveFirstInteraction();

    if (RM.common.readymagTracker) {
      Utils.loadLoggedUser(
        _.bind(function(user) {
          if (_.isEmpty(user) || !window.ga) return;
          window.ga && ga('readymag.set', '&uid', user._id);
          this.userId = user._id;
        }, this)
      );
    }

    // // @TODO: сделать с учетом кастомной аналитики
    // $(_.bind(function() {
    // 	this.trackPage();
    // }, this));
  },

  sendEvent: function(action, params, _value, _cb) {
    if (!window.ga) return;

    // сборка параметров действия
    var functionParams = {
      action: action,
    };

    if (_.isObject(params)) {
      functionParams = _.extend(functionParams, params);
    } else {
      functionParams.label = params || undefined;
      functionParams.value = _value || undefined;
    }

    var actionParams = this._extendParams(functionParams);

    if (actionParams.label && actionParams.label.match(/enterprise/)) {
      actionParams.label = actionParams.label.replace(/enterprise/, 'custom');
    }

    // все параметры берем в явном виде и описываем перед отправкой из this.actionParams
    // этот же объект передается дальше другим триггерам аналитики
    var sendObj = {
      hitType: 'event',
      eventCategory: actionParams.category,
      eventAction: actionParams.action,
      eventLabel: actionParams.label,
      eventValue: actionParams.value,
      page: actionParams.page,
      location: actionParams.href,
      screenName: actionParams.screenName,
      hitCallback: _cb || actionParams.cb || undefined,
    };

    // если наш трекер, добавляем данные
    if (RM.common.readymagTracker) {
      sendObj.dimension1 = actionParams.dims && actionParams.dims.pageType;
      sendObj.dimension2 = actionParams.dims && actionParams.dims.userId;
      sendObj.dimension3 = null;

      if (this._rmdata) {
        sendObj.dimension4 = this._rmdata.dimension;
        sendObj.dimension5 = this._rmdata.first_campaign;
        sendObj.dimension6 = this._rmdata.first_medium;
      }

      var dimension10 = this.getABTestingValue();
      if (dimension10) {
        sendObj.dimension10 = dimension10;
      }

      // отправка событий в дефолтную аналитику
      window.ga('readymag.send', sendObj);
    }

    // обработка отправки в пользовательскую аналитику
    if (RM.common.userTracker && actionParams.category === 'Project Actions') {
      window.ga('t0.send', sendObj);
    }

    if (!RM.common.isDomainViewer) {
      this.sendFacebookPixelEvent(actionParams.action, actionParams);
    }

    return sendObj;
  },

  trackPage: function(params) {
    if (!window.ga) return;

    var actionParams = this._extendParams(params);

    // редирект с readymag.com/api/shortlink/
    // там передаются UTM метки
    if (this.shortlink_redirect) {
      actionParams = _.extend(actionParams, this._processRedirectUTM());
    }

    if (actionParams.page === this.lastTrackedUrl) return;

    this.lastTrackedUrl = actionParams.page;

    var sendObj = {
      page: actionParams.page,
      location: actionParams.href,
      screenName: actionParams.screenName,
      campaignName: actionParams.campaignName,
      campaignSource: actionParams.campaignSource,
      campaignMedium: actionParams.campaignMedium,
      hitCallback: actionParams.cb || undefined,
    };

    // если наш трекер, добавляем данные
    if (RM.common.readymagTracker) {
      sendObj.dimension1 = actionParams.dims && actionParams.dims.pageType;
      sendObj.dimension2 = actionParams.dims && actionParams.dims.userId;
      sendObj.dimension3 = null;
      sendObj.metric1 = actionParams.countProjectView;

      if (this._rmdata) {
        sendObj.dimension4 = this._rmdata.dimension;
        sendObj.dimension5 = this._rmdata.first_campaign;
        sendObj.dimension6 = this._rmdata.first_medium;
      }

      var dimension10 = this.getABTestingValue();
      if (dimension10) {
        sendObj.dimension10 = dimension10;
      }

      // отправка событий в дефолтную аналитику
      window.ga('readymag.send', 'pageview', sendObj);
    }

    if (RM.common.userTracker) {
      window.ga('t0.send', 'pageview', sendObj);
    }

    if (!RM.common.isDomainViewer) {
      this.sendFacebookPixelEvent('PageView');
    }

    return sendObj;
  },

  /*
    ================================
    Вспомогательные методы
    ================================
  */

  // Общая функция сбора параметров перед отправокой.
  _extendParams: function(params) {
    var params = params || {},
      result = params;

    // добавляем информацию о dimensions
    if (!RM.common.isDownloadedSource && !RM.common.homepageRewrite) {
      result.dims = this.router.getAnalyticsDimensions && this._parseDimensions(this.router.getAnalyticsDimensions());
    }

    if (this.userId && result.dims) {
      result.dims.userId = this.userId;
    }

    if (!result.page && !RM.common.isDownloadedSource && !RM.common.homepageRewrite) {
      result.page = '/' + Backbone.history.getFragment();
    } else if (!result.page && RM.common.isDownloadedSource && !RM.common.homepageRewrite) {
      result.page = Backbone.history.location.pathname;
    }

    // удаляем query
    if (result.page && result.page.indexOf('?') !== -1) {
      result.page = result.page.substr(0, result.page.indexOf('?'));
    }

    // убираем завершающий слэш
    result.page = this._correctUrl(result.page || window.location.href);

    result.href = window.location.href;

    // если есть action -> ищем категорию
    if (result.action) {
      result.category = this.getActionCategory(result.action);
    }

    // this._triggerProjectView устанавливается true один раз при загрузке проекта
    // только для событий trackPage
    if (this._triggerProjectView && !result.action) {
      result.countProjectView = 1;
      this._triggerProjectView = false;
    }

    result.screenName = this._setScreenName();

    return result;
  },

  _correctUrl: function(url) {
    if (url === '/') return url;
    if (url[url.length - 1] === '/') return url.substr(0, url.length - 1);
    return url;
  },

  _setScreenName: function() {
    var result;
    // так как у нас веб-приложение, воспользуемся механизмом наименования экранов
    // будем считать, что экран – это веб-страница со всеми состояниями, которые появляются без перезагрузки

    // password page for private project
    if (RM.viewerRouter && RM.viewerRouter.mag && RM.viewerRouter.magPasswordView && RM.viewerRouter.mag.is_private) {
      result = 'Private project ' + RM.viewerRouter.mag.num_id;
    }

    // заголовок публичного проекта
    if (RM.viewerRouter && RM.viewerRouter.mag && !RM.viewerRouter.mag.is_private) {
      result = RM.viewerRouter.mag.title;
    }

    return result;
  },

  _parseDimensions: function(data) {
    return {
      pageType: data.pageType || defaultDimensions.dimension1,
      userId: data.userId || defaultDimensions.dimension2,
      subscription: data.subscription || defaultDimensions.dimension3,
    };
  },

  _processRedirectUTM: function() {
    if (!this.shortlink_redirect) return;
    var query = JSON.parse(atob(this.shortlink_redirect));
    Utils.deleteCookie('shortlink_redirect');
    return {
      campaignName: query['utm_campaign'],
      campaignSource: query['utm_source'],
      campaignMedium: query['utm_medium'],
    };
  },

  _saveFirstInteraction: function() {
    var hasCookie =
      Utils.getCookie('_rmdata') ||
      (Modernizr.localstorage && window.localStorage && window.localStorage.getItem('_rmdata'));

    if (hasCookie) {
      // if we have cookie, return the existing data
      this._rmdata = JSON.parse(atob(hasCookie));
      return;
    }

    var sep = '|';

    var referrer = document.referrer || 'direct';
    var landingPage = encodeURI(window.location.href);

    var campaign = Utils.queryUrlGetParam('utm_campaign');
    var source = Utils.queryUrlGetParam('utm_source');
    var medium = Utils.queryUrlGetParam('utm_medium');

    var dimensionStr = [campaign, source, medium, referrer, landingPage].join(sep);

    var obj = {
      dimension: dimensionStr,
      first_medium: medium,
      first_campaign: campaign,
      first_source: source,
      rm_url: landingPage,
      rm_referrer: referrer,
    };

    var encodedObj = btoa(JSON.stringify(obj));

    this._rmdata = obj;

    Utils.createCookie('_rmdata', encodedObj);
    if (Modernizr.localstorage) {
      window.localStorage && window.localStorage.setItem('_rmdata', encodedObj);
    }
  },

  /*
    ================================
    Категории
    ================================
  */

  getActionCategory: function(action) {
    // @TODO: для новой главной поменять условие
    if (RM.homepageRouter) {
      return 'Homepage Actions';
    } else if (action === 'First Widget Pack Loaded') {
      return 'Performance';
    } else {
      return 'Project Actions';
    }
  },
};

export default extendAnalyticsPublic;
