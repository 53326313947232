/**
 * Модель, отсылающая обновления через сокеты
 */
import Backbone from 'backbone';
import _ from '@rm/underscore';

const SocketModel = Backbone.Model.extend({
  parse: function(data, options) {
    if (options && options.xhr && !this.isNew() && (!options || !options.noSocketUpdate)) {
      if (_.isEmpty(data)) data = this.toJSON(_.pick(options, 'viewport', 'fixViewports'));

      this.triggerSocketUpdate(data, options);
    }

    return Backbone.Model.prototype.parse.apply(this, arguments);
  },

  triggerSocketUpdate: function(data, options) {
    var update_data = data;

    if (options && options.patch) {
      update_data = _.extend({}, options.attrs, this.pick('_id', 'pid'));
    }

    update_data = this.getChangedAttributes ? this.getChangedAttributes() : update_data;

    if (_.isEmpty(update_data)) update_data = this.toJSON();

    if (!update_data.pid && this.getPageId && !this.name == 'Page') update_data.pid = this.getPageId();

    if (this.getViewport && this.getViewport() != 'default') {
      var pid = update_data.pid || (this.getPageId && this.getPageId());
      return this.triggerViewportUpdate(pid);
    }

    RM.constructorRouter.trigger('update_from_server', this.name, update_data);
  },

  triggerViewportUpdate: function(update_data) {
    RM.constructorRouter.trigger('update_from_server', 'Viewport', update_data);
  },
});

export default SocketModel;
