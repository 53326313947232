/**
 * Виджет Картинка
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import { Constants, Utils } from '../../common/utils';
import Scale from '../../common/scale';
import PicturePreviewClass from '../../common/picture-preview';

const PictureWidget = WidgetClass.extend({
  // достаточно версии прототипа
  // initialize: function(data, page) {},

  antialiasRotation: true,

  mailchimpMatcher: /__rm_mailchimp_(.+)/i,
  anchorRegexp: /__rm_anchor/i,
  shareRegexp: /^share\.(facebook|twitter|pinterest|gplus|linkedin|email)\.(mag|page)$/i,

  render: function() {
    this.makeBox('widget-picture');

    if (this.canShowPreview()) {
      this.initPreview();
    }

    if (this.hasHoverAnimation) {
      this.$el.addClass('has-onhover-animation');
    }

    this.rendered = true;

    var url = this.getPictureUrl();

    if (/\.gif/.test(url)) this.isGIF = true;

    this.$el.html('');

    if (_.last(url.split('.')).indexOf('svg') == 0 && !Constants.IS_FILE_PROTOCOL) {
      this.loadVector(url);
    } else {
      var hasBordersOrRadiusOrStretchBlock = !!(
        this.border_size > 0 ||
        this.border_radius > 0 ||
        this.border_radius_max ||
        this.is_full_width ||
        this.is_full_height
      );

      if (this.insideHotspot && this.isGIF) {
        var scale = this.scale;

        $('<div/>')
          .appendTo(this.$el)
          .css({
            position: 'absolute',
            'background-image': 'url(' + url + ')',
            'background-position': '50% 50%',
            // Math.max, чтобы кропнутая гифка не была меньше бокса картинки
            // и не было зазоров между картинкой и подсказкой.
            width: Math.max(Math.round(this.originalW * scale), this.w),
            height: Math.max(Math.round(this.originalH * scale), this.h),
            top: -Math.round(this.cropY * scale),
            left: -Math.round(this.cropX * scale),
          })
          .attr('url', url);
      } else if (hasBordersOrRadiusOrStretchBlock) {
        // создаем фактическую картинку, на которой можно работать со скруглениями и бордерами и позиционированием картинки (для фуллвидтх)
        $('<div/>')
          .appendTo(this.$el)
          .css({
            'background-image': 'url(' + url + ')',
            'background-position':
              (this.fhpos == undefined ? 50 : this.fhpos) + '% ' + (this.fwpos == undefined ? 50 : this.fwpos) + '%',
            'box-shadow':
              this.border_size > 0
                ? 'inset 0 0 0 ' + this.border_size + 'px #' + (this.border_color || '000000')
                : 'none',
            'border-radius': (this.border_radius_max ? '9999' : this.border_radius || 0) + 'px',
            opacity: typeof this.opacity === 'undefined' ? 1 : this.opacity,
          })
          .attr('url', url);
      }

      // создаем прозрачную картинку которую можно сохраняться на компе драгом (на маке)
      // настояшую картинку показываем в диве через bg потому что только так можно нормально работать со скруглениями и тенями
      // делаем это только в том случае, если есть бордеры или скругления, иначе работаем по старинке - просто один img (saveble класс сброшен)
      // также только для этой картинки прописываем alt атрибут, если он установлен,
      // но не добавляем его на контейнеры <div> выше, чтобы не дублировать alt
      $('<img/>')
        .appendTo(this.$el)
        .toggleClass('saveable', !!(hasBordersOrRadiusOrStretchBlock || (this.insideHotspot && this.isGIF)))
        .css('opacity', typeof this.opacity === 'undefined' ? 1 : this.opacity)
        .load(
          _.bind(function() {
            this.$el.find('img').attr('url', url);
            this.widgetIsLoaded();
          }, this)
        )
        .on('error', this.widgetIsLoaded)
        .attr('src', url)
        .attr('srcset', this.getSrcset())
        .attr('alt', this.getAltText());
    }

    this.checkLink();

    return this;
  },

  canShowPreview: function() {
    var hasAnimation =
        this.animation && this.animation.type === 'click' && this.animation.steps && this.animation.steps.length > 0,
      hasLink = !!this.clickLink;

    return this.preview_enabled && !hasAnimation && !hasLink;
  },

  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  isValid: function() {
    return !!this.getPictureUrl();
  },

  start: function() {
    this.started = true;

    if (Modernizr.safari && this.isGIF) {
      // гифки в новом сафари перестают анимироваться при листании https://trello.com/c/jtN385R1/340--
      this.$el.flashClass('fix-safari-gif', 100);
    }

    return this;
  },

  // достаточно версии прототипа
  // stop: function() {},

  // достаточно версии прототипа
  // destroy: function() {},

  getPictureUrl: function() {
    if (!this.picture) return '';

    // если у нас finalUrl SVG, отдаем его
    if (this.picture.finalUrl && _.last(this.picture.finalUrl.split('.')).indexOf('svg') == 0) {
      return this.picture.finalUrl;
    }

    var finalUrl;
    if (Scale.isOn(this.page.scale)) {
      finalUrl = this.picture.unscaledUrl || this.picture.final2xUrl || this.picture.finalUrl;
    } else if (Modernizr.retina) {
      finalUrl =
        (window.devicePixelRatio >= 3 ? this.picture.final3xUrl || this.picture.final2xUrl : this.picture.final2xUrl) ||
        this.picture.finalUrl;
    } else {
      finalUrl = this.picture.finalUrl;
    }
    // для картиночных виджетов с анимациями в превью режиме без разбора показываем unscaledUrl
    // а в опубликованной версии этого делать не надо (т.е. не в превью режиме)
    // т.к. при паблише мы нормальные картинки делаем нужного максимального размера исходя из максимального зума в анимации
    if (this.is_full_width || this.is_full_height || (this.page.mag.isPreview && this.animation)) {
      finalUrl = this.picture.unscaledUrl || this.picture.final2xUrl || this.picture.finalUrl;
    }

    return finalUrl || this.picture.effectUrl || this.picture.editedVectorUrl || this.picture.url;
  },

  getAltText: function() {
    return this.picture && this.alt ? _.escape(this.alt) : undefined;
  },

  getSrcset: function(withRetina = true) {
    if (!this.picture) return [];

    if (!this.originalW) return [];

    if (this.isGIF) return [];

    if (Scale.isAllowed()) return [];

    if (this.animation) return [];

    if (RM.screenshot) return [];

    // если у нас finalUrl SVG, отдаем его
    if (this.picture.finalUrl && _.last(this.picture.finalUrl.split('.')).indexOf('svg') == 0) {
      return [];
    }

    var w = Math.floor(this.w),
      sizedSet = [
        { url: this.picture.unscaledUrl, size: this.originalW },
        { url: this.picture.final2xUrl && this.picture.final3xUrl, size: w * 3 },
        { url: this.picture.final2xUrl, size: w * 2 },
        { url: this.picture.finalUrl, size: w },
      ];

    sizedSet = sizedSet
      .filter(
        function(img) {
          return !!img.url && this.originalW >= img.size;
        }.bind(this)
      )
      .map(function(img) {
        return img.url + ' ' + img.size + 'w';
      });

    if (!withRetina) return sizedSet;

    var retinaSet = [this.picture.finalUrl + ' 1x'];

    if (this.picture.final2xUrl) {
      retinaSet.push(this.picture.final2xUrl + ' 2x');
    }

    if (this.picture.final2xUrl && this.picture.final3xUrl) {
      retinaSet.push((this.picture.final2xUrl && this.picture.final3xUrl) + ' 3x');
    }

    return retinaSet.concat(sizedSet);
  },

  cleanupSVG: function(svgData) {
    var $svg = $(
        $(svgData)
          .find('svg')
          .get(0)
          .cloneNode(true)
      ),
      svgStr = $svg[0].outerHTML;
    _.chain($svg.find('[id]'))
      .map(function(el) {
        var id = el.getAttribute('id');
        return {
          id: id,
          newId: Utils.generateUUID(),
        };
      })
      .sortBy(function(el) {
        return -el.id.length;
      })
      .each(function(el) {
        // для того чтобы не заботится об экранировании спец. символов
        svgStr = svgStr.split('#' + el.id).join('#' + el.newId);
        svgStr = svgStr.split('id="' + el.id + '"').join('id="' + el.newId + '"');
      });
    return svgStr;
  },

  loadVector: function(url) {
    this.$el.addClass('svg');
    this.svgAjax = $.get(
      url + '?c',
      _.bind(function(svgData) {
        svgData = $(this.cleanupSVG(svgData));
        svgData.get(0).removeAttribute('width');
        svgData.get(0).removeAttribute('height');
        this.$svg = svgData;
        var svg = svgData.get(0).cloneNode(true);
        var viewbox = this.$svg.attr('viewBox');

        // Удаляем элемент title, иначе над svg в сафари он всплывает в виде тултипа
        var $title = this.$svg.find('title');
        $title.remove();

        var img = new Image();

        $(img).on(
          'load error',
          _.bind(function() {
            // В Chrome 50 сломался триггер load для SVG в которых пустые аттрибуты width & height (именно пустые а не отсутствующие)

            $(img)
              .appendTo(window.document.body)
              .css({ width: '100%', height: '100%' });
            var $svg = $(svg);

            $svg.get(0).setAttribute('viewBox', '0 0 ' + $(img).width() + ' ' + $(img).height());
            $svg.appendTo(window.document.body);
            var bbox = $svg.get(0).getBBox();

            this.$svg.get(0).setAttribute('viewBox', bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height);
            this.$svg.get(0).setAttribute('preserveAspectRatio', 'xMidYMid meet');

            $(img).remove();

            $svg.remove();

            var $svgScaleWrapper = $('<div class="svg-scale-wrapper"></div>');
            this.$svg.appendTo($svgScaleWrapper);
            $svgScaleWrapper.appendTo(this.$el);
            this.widgetIsLoaded();
          }, this)
        );

        img.src = url;
      }, this)
    ).fail(this.widgetIsLoaded);
  },

  initPreview: function() {
    this.preview = new PicturePreviewClass({
      $container: $('body'),
      $el: this.$el,
      picture: this.picture,
      overlayColor: this.preview_overlay_color,
      originalW: this.originalW,
      width: this.w,
    });

    this.$el.addClass('preview-enabled');

    this.$el.on(
      'click',
      function() {
        this.preview.render();
        this.preview.bindClick();
      }.bind(this)
    );
  },
});

export default PictureWidget;
