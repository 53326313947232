import PreloadDesignImages from '../../common/preload-design-images';
import VueControlClass from '../vue-control';

const VideoSettings = VueControlClass.extend({
  name: 'video_settings',
  alt: 'Video',
  panelComponent: 'panel-video',

  select: function() {
    VueControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-video_settings');
  },

  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();
    return blocks.length === 1 && blocks[0].model.get('type') === 'video';
  },
  onPanelChange: function(update) {
    VueControlClass.prototype.onPanelChange.apply(this, arguments);
    // При изменении id видео (то есть при загрузке / удалении) — сохранить
    if (this.model.changed.hasOwnProperty('video_id')) {
      this.model.save({});
    }
  },
});

export default VideoSettings;
