<template>
  <a class="info" :href="href" rel="noreferrer noopener" target="_blank" />
</template>

<script>
export default {
  props: {
    href: String,
  },
};
</script>

<style lang="less" scoped>
@import (reference) '../../css/common/constants.less';
@import (reference) '../../css/common/fonts.less';

.info {
  position: absolute;
  top: 2px;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  .avenir_demi;
  border-radius: 50%;
  .border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.15s border-color ease-in-out;

  &::after {
    content: '?';
    position: absolute;
    top: 0;
    left: 0;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.3);
    transition: 0.15s color ease-in-out;
  }

  &:hover {
    border-color: white;

    &::after {
      color: white;
    }
  }
}
</style>
