/**
 * Конструктор для контрола анимаций
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import ControlResizableClass from '../control-resizable';
import templates from '../../../templates/constructor/controls/common_animation.tpl';
import AnimationUtils from '../../common/animationutils';
import History from '../models/history';
import { Utils } from '../../common/utils';
import ControlClass from '../control';
import AnimationStepsClass from '../helpers/animation-steps';

const CommonAnimationClass = ControlResizableClass.extend({
  name: 'common_animation', // должно совпадать с классом вьюхи

  className: 'control common_animation',

  MIN_PANEL_HEIGHT: 472,

  MAX_PANEL_HEIGHT: 640,

  DISABLED_HEIGHT: 88, // высота панельки когда анимации выключены
  DISABLED_HEIGHT_RESET: 112, // высота панельки когда анимации выключены, но они были заданы (появляется кнопка reset)

  MESSAGE_MIXED_HEIGHT: 128, // высота панельки при сообщении что в выделении замешаны и фикседы и нормальные и фулвидхи
  MESSAGE_MULTIPLE_HEIGHT: 112, // высота панельки при сообщении что в выделении в выделении одни фикседы или фулвидхи и их больше одного
  MESSAGE_NON_ANIMATABLE_HEIGHT: 96, // высота панельки при сообщении что в выделении есть не-анимируемые виджеты
  MESSAGE_UNITE_HEIGHT: 160, // высота панельки при сообщении что в выделении разные анимации

  // параметры дефолтного шага анимации
  // undefined важное значение
  // по нему мы определяем при каждом включении/выключении эффекта
  // был ли он изменен ранее или это первое его включение
  DEFAULT_ANIMATION_STEP: {
    duration: 0.5, // длительность анимации в секундах (load, click, hover)
    delay: 0, // задержка начала анимации в секундах (load, click, hover)
    speed: 1, // коэффициент скорости анимации в зависимости от скрола (scroll)
    delay_px: 0, // задержка начала анимации в пикселах (scroll)
    dx: undefined, // сдвиг виджета по x
    dy: undefined, // сдвиг виджета по y
    opacity: undefined, // прозрачность
    rotate: undefined, // поворот
    scale: undefined, // масштаб
    acceleration: 'none', // none, ease-in, ease-out, ease-both

    // параметры которые интересны только у первого шага
    start_point: 'bottom', // в какой момент начинать анимацию по скролу, когда верх объекта доедет по экрану до : top, center, bottom
    start_offset: 0, // работает в связке со start_point, и хранит абсолютый сдвиг в пикселах о точки привязки, только для анимаций по скролу
    from_opacity: 100, // изначальная прозрачность
    from_rotate: 0, // изначальный поворот
    from_scale: 100, // изначальный масштаб

    // флаги которые говорят какие эффекты использованы в шаги (и только их показываем и учитываем при расчетах во вьювере и воркспейсе)
    use_move: false,
    use_opacity: false,
    use_rotate: false,
    use_scale: false,
  },

  PRESETS: {
    'fade-and-scale': {
      loop: false,
      selected: 0,
      steps: [
        {
          duration: 1.2,
          delay: 0,
          speed: 1,
          delay_px: 0,
          opacity: 100,
          scale: 100,
          acceleration: 'ease-out',
          start_point: 'bottom',
          start_offset: 0,
          from_opacity: 0,
          from_rotate: 0,
          from_scale: 80,
          use_move: false,
          use_opacity: true,
          use_rotate: false,
          use_scale: true,
        },
      ],
    },
    'fade-in': {
      loop: false,
      selected: 0,
      steps: [
        {
          duration: 1.6,
          delay: 0,
          speed: 1,
          delay_px: 0,
          opacity: 100,
          scale: 100,
          acceleration: 'ease-out',
          start_point: 'bottom',
          start_offset: 0,
          from_opacity: 0,
          from_rotate: 0,
          from_scale: 80,
          use_move: false,
          use_opacity: true,
          use_rotate: false,
          use_scale: false,
        },
      ],
    },
    'scale-big': {
      loop: false,
      selected: 0,
      steps: [
        {
          duration: 0.8,
          delay: 0,
          speed: 1,
          delay_px: 0,
          opacity: 100,
          scale: 100,
          acceleration: 'ease-out',
          start_point: 'bottom',
          start_offset: 0,
          from_opacity: 0,
          from_rotate: 0,
          from_scale: 256,
          use_move: false,
          use_opacity: true,
          use_rotate: false,
          use_scale: true,
        },
      ],
    },
    'move-and-fade': {
      loop: false,
      selected: 0,
      steps: [
        {
          duration: 0.8,
          delay: 0,
          speed: 1,
          delay_px: 0,
          dx: 0,
          dy: -32,
          opacity: 100,
          acceleration: 'ease-both',
          start_point: 'bottom',
          start_offset: 0,
          from_opacity: 0,
          from_rotate: 0,
          from_scale: 100,
          use_move: true,
          use_opacity: true,
          use_rotate: false,
          use_scale: false,
        },
      ],
    },
    pulse: {
      loop: 'swing',
      selected: 0,
      steps: [
        {
          duration: 1.2,
          delay: 0,
          speed: 1,
          delay_px: 0,
          dx: 142,
          dy: 0,
          scale: 90,
          acceleration: 'ease-both',
          start_point: 'bottom',
          start_offset: 0,
          from_opacity: 100,
          from_rotate: 0,
          from_scale: 100,
          use_move: false,
          use_opacity: false,
          use_rotate: false,
          use_scale: true,
        },
      ],
    },

    rotate: {
      loop: 'repeat',
      selected: 0,
      steps: [
        {
          duration: 1.8,
          delay: 0,
          speed: 1,
          delay_px: 0,
          dx: 0,
          dy: 0,
          rotate: 360,
          acceleration: 'none',
          start_point: 'bottom',
          start_offset: 0,
          from_opacity: 100,
          from_rotate: 0,
          from_scale: 100,
          use_move: false,
          use_opacity: false,
          use_rotate: true,
          use_scale: false,
        },
      ],
    },
  },

  TRIGGER_COUNT_LIMIT: 3,

  events: {
    'click .js-trigger-button': 'onTriggerButtonClick',
    'click .js-cancel-trigger': 'onTriggerCancelClick',
    'click .js-remove-trigger': 'onTriggerRemoveClick',
    'mouseenter .js-trigger-name': 'onTriggerNameMouseEnter',
    'mouseleave .js-trigger-name': 'onTriggerNameMouseLeave',
    'mouseenter .js-remove-trigger': 'onTriggerRemoveMouseEnter',
    'mouseleave .js-remove-trigger': 'onTriggerRemoveMouseLeave',
  },

  initialize: function(params) {
    this.template = templates['template-constructor-control-common_animation'];
    this.step_template = templates['template-constructor-control-common_animation-step'];

    this.initControl(params);

    this.isPreviewMode = false;

    this.block = this.blocks && this.blocks[0];
    // просто любая модель из выделенных блоков
    // когда мы работаем с анимациями в выделении у них у всех одинаковые данные по анимациям
    this.someModel = this.models && this.models[0];
    this.triggerButtonsState = {};

    this.updateControlState();
  },

  bindLogic: function() {
    this.master.workspace.on('paste-animation', this.onAnimationPaste);

    this.$loop_types = this.$panel.find('.js-loop-types');
    this.$loop_repeat = this.$panel.find('.js-loop-repeat');
    this.$loop_swing = this.$panel.find('.js-loop-swing');

    this.$playOnce = this.$panel.find('.js-play-once');

    this.$preview_switcher = this.$panel.find('.bottom-block .preview');

    this.$steps_container = this.$panel.find('.resizable-content');

    this.$step_selector = this.$panel.find('.js-step-selector');

    this.$step_selector.find('.js-prev-step').on('click', this.prevStepClick);

    this.$step_selector.find('.js-next-step').on('click', this.nextStepClick);

    this.$steps_container.on('click', '.param-wrapper.start-block .start-popup-item', this.startItemClick);

    this.$steps_container.on(
      'click',
      '.param-wrapper.acceleration-block .acceleration-popup-item',
      this.accelerationItemClick
    );

    this.$steps_container.on(
      'mouseenter mouseleave',
      '.param-wrapper.acceleration-block .acceleration-popup-item',
      this.accelerationItemMouseEnterLeave
    );

    this.$steps_container.on('click', '.remove-effect', this.removeEffectClick);

    this.$steps_container.on('click', '.effects-presets-wrapper .effects-item', this.addStepClick);

    this.$steps_container.on('click', '.effects-presets-wrapper .presets-item', this.usePresetClick);

    this.$steps_container.on('click', '.effects-presets-wrapper .presets-item .preview', this.previewPresetClick);

    this.$panel.on('click', '.unite-button', this.uniteClick);

    this.$loop_repeat.on('click', this.onLoopTypeClick);
    this.$loop_swing.on('click', this.onLoopTypeClick);

    this.$playOnce.on('click', this.onPlayOnceClick);

    this.$preview_switcher.on('click', this.togglePreviewMode);

    this.$panel.on('click', '.top-block .type-selector .type-popup-item', this.typeSelectorItemClick);

    this.$panel.on('click', '.bottom-block .add-effect .effect-popup-item', this.addEffectItemClick);

    this.$panel.on('click', '.top-block .reset-button', this.resetClick);

    // менеджер попапов, отвечает за открытие закрытие попапов по кликам по ним, по пустому месту и по другим попапам
    // контент попапов его не интересует
    this.popupManager = new PopupManager();

    // добавляем обработку попапа выбора типа анимации
    this.popupManager.addPopup({
      $parent: this.$panel,
      name: 'type-selector',
      selector: '.top-block .type-selector',
      popupSelector: '.top-block .type-popup',
    });

    // Попап с шагами
    this.popupManager.addPopup({
      $parent: this.$panel,
      name: 'steps',
      selector: '.js-step-selector:not(.one-step)',
      popupSelector: '.js-step-popup',
    });

    // добавляем обработку попапа выбора еффекта в шаге
    this.popupManager.addPopup({
      $parent: this.$panel,
      name: 'add-effect',
      selector: '.bottom-block .add-effect',
      popupSelector: '.bottom-block .effect-popup',
      cbOpen: this.updateEffectPopupState,
    });

    // добавляем обработку попапа выбора точки старта анимации для скрол анимации
    this.popupManager.addPopup({
      $parent: this.$panel,
      name: 'start-selector',
      selector: '.resizable-content .param-wrapper.start-block',
      popupSelector: '.resizable-content .param-wrapper.start-block .start-popup',
    });

    // добавляем обработку попапа выбора типа акселерации
    this.popupManager.addPopup({
      $parent: this.$panel,
      name: 'acceleration-selector',
      selector: '.resizable-content .param-wrapper.acceleration-block',
      popupSelector: '.resizable-content .param-wrapper.acceleration-block .acceleration-popup',
      cbOpen: this.updateAccelerationPopupState,
    });

    RM.constructorRouter.bindGlobalKeyPress([{ key: 'a', handler: this.onClick }]);

    this.listenTo(this.someModel, 'animation:trigger:add', this.onAnimationTriggerAdd);
    this.listenTo(this.someModel, 'animation:trigger:remove', this.onAnimationTriggerRemove);

    this.$step_selector.find('.js-step-popup').sortable({
      items: '.js-step-popup-item',
      distance: 10,
      axis: 'y',
      scrollSpeed: 10,
      containment: 'parent',
      tolerance: 'pointer',
      start: this.onStepsSortStart.bind(this),
      stop: this.onStepsSortEnd.bind(this),
    });

    $('')
      .add(this.$loop_swing)
      .add(this.$loop_repeat)
      .RMAltText({
        cssClass: 'loop-type-tooltip',
        offset: -4,
      });
  },

  unBindLogic: function() {
    this.master.workspace.off('paste-animation', this.onAnimationPaste);

    this.$step_selector.find('.js-prev-step').off('click', this.prevStepClick);

    this.$step_selector.find('.js-next-step').off('click', this.nextStepClick);

    this.$steps_container.off('click', '.param-wrapper.start-block .start-popup-item', this.startItemClick);

    this.$steps_container.off(
      'click',
      '.param-wrapper.acceleration-block .acceleration-popup-item',
      this.accelerationItemClick
    );

    this.$steps_container.off(
      'mouseenter mouseleave',
      '.param-wrapper.acceleration-block .acceleration-popup-item',
      this.accelerationItemMouseEnterLeave
    );

    this.$steps_container.off('click', '.remove-effect', this.removeEffectClick);

    this.$steps_container.off('click', '.effects-presets-wrapper .effects-item', this.addStepClick);

    this.$steps_container.off('click', '.effects-presets-wrapper .presets-item', this.usePresetClick);

    this.$steps_container.off('click', '.effects-presets-wrapper .presets-item .preview', this.previewPresetClick);

    this.$panel.off('click', '.unite-button', this.uniteClick);

    this.removeTriggerButtons();

    this.$loop_repeat.off('click', this.onLoopTypeClick);
    this.$loop_swing.off('click', this.onLoopTypeClick);

    this.$playOnce.off('click', this.onPlayOnceClick);

    this.$preview_switcher.off('click', this.togglePreviewMode);

    this.$panel.off('click', '.top-block .type-selector .type-popup-item', this.typeSelectorItemClick);

    this.$panel.off('click', '.bottom-block .add-effect .effect-popup-item', this.addEffectItemClick);

    this.$panel.off('click', '.top-block .reset-button', this.resetClick);

    this.popupManager.destroy();
    delete this.popupManager;

    RM.constructorRouter.unbindGlobalKeyPress('a', this.onClick);

    this.stopListening(this.someModel, 'animation:trigger:add');
    this.stopListening(this.someModel, 'animation:trigger:remove');
  },

  onPlayOnceClick: function() {
    this.setAnimationData({ playOnce: !(this.someModel.get('animation') || {}).playOnce });
    this.updatePlayOnce();
  },

  updatePlayOnce: function() {
    this.$playOnce.toggleClass('active', Boolean((this.someModel.get('animation') || {}).playOnce));
  },

  startPreviewMode: function() {
    if (this.isPreviewMode) return;

    this.isPreviewMode = true;

    this.$preview_switcher.text('Stop').addClass('active');

    this.animationSteps && this.animationSteps.startPreviewMode();
  },

  updatePreviewMode: function() {
    if (!this.isPreviewMode) return;

    this.animationSteps && this.animationSteps.updatePreviewMode();
  },

  stopPreviewMode: function() {
    if (!this.isPreviewMode) return;

    this.isPreviewMode = false;

    this.$preview_switcher.text('Preview').removeClass('active');

    this.animationSteps && this.animationSteps.stopPreviewMode();
  },

  togglePreviewMode: function() {
    this.isPreviewMode ? this.stopPreviewMode() : this.startPreviewMode();
  },

  // при событии пастинга анимаций (у нас для них copy/paste есть по ctrl+shift+c/v в роутере обруливается)
  // нам надо перерисовать иконку контрола что появились анимации и открыть панельку
  // а если она была открыта тогда надо updateAll чтобы перерисовать анимации
  onAnimationPaste: function() {
    if (this.selected) {
      this.updateAll();
    } else {
      this.onClick(); // открываем панельку
    }
  },

  // рендерит в панельке шаг анимации (в дополнение к существующим)
  renderStep: function(data, ind) {
    var templateData = _.extend({ index: ind }, data);
    var $step = $(this.step_template(templateData));

    if (ind > 0) {
      this.$steps_container
        .find('.step')
        .eq(ind - 1)
        .after($step);
    } else {
      this.$steps_container.append($step);
    }

    $step.find('input').each(
      _.bind(function(ind, item) {
        var cb = $(item)
          .RMNumericInput({
            onChange: this.onParamInputChange,
            labelSelector: '.units:not(.for-centering)',
            autoSize: true,
          })
          .data('changeValue');

        // колбек для изменения значения сохраняем прямо в инпуте ($ data умеет сохрянять все что угодно)
        $(item).data('cb', cb);
      }, this)
    );

    var $switcher = $step.find('.js-start-when-in-view-switcher');
    if ($switcher.length) {
      $switcher.RMSwitcher(
        {
          state: templateData.startWhenInView,
          height: 26,
          width: 44,
          'color-0': '#0078ff',
          'color-1': '#c9c8c9',
          'text-color-0': 'transparent',
          'text-color-1': 'transparent',
        },
        this.onSwitcherChange.bind(this)
      );
    }
  },

  // удаляет из панельки шаг анимации (по индексу шага)
  destroyStep: function(ind) {
    this.$steps_container
      .find('.step')
      .eq(ind)
      .remove();
  },

  destroyAllSteps: function(ind) {
    this.$steps_container.find('.step').remove();
  },

  updateStepsIndexes: function() {
    this.$steps_container.find('.step').each(function(ind, item) {
      $(item).attr('data-ind', ind);
    });
  },

  prevStepClick: function(event) {
    event.stopPropagation();
    this.switchStep({
      updateWorkspaceSelectedStep: true,
      cur: (this.someModel.get('animation').selected || 0) - 1,
    });
  },

  nextStepClick: function(event) {
    event.stopPropagation();
    this.switchStep({
      updateWorkspaceSelectedStep: true,
      cur: (this.someModel.get('animation').selected || 0) + 1,
    });
  },

  onStepPopupItemClick: function(event) {
    var $step = $(event.target).closest('.js-step-popup-item');
    var index = parseInt($step.data('ind'), 10);
    if (index !== this.someModel.get('animation').selected) {
      this.switchStep({
        updateWorkspaceSelectedStep: true,
        cur: index,
      });
    }
  },

  onAnimationStepsSelect: function(ind) {
    this.switchStep({
      updateWorkspaceSelectedStep: false, // запрещаем обратно вызывать функци селекта на воркспейс, чтобюы не было зацикливания, ну и еще там проблемы иначе с выделением блока изначального состояния
      cur: ind,
    });
  },

  onStepsSortStart: function() {
    this.$('.js-step-popup').addClass('grabbing');
  },

  onStepsSortEnd: function() {
    this.$('.js-step-popup').removeClass('grabbing');
    this.reorderSteps();
  },

  reorderSteps: function() {
    var animation = this.someModel.get('animation');
    var steps = animation.steps;
    var selectedIndex = animation.selected;

    var reorderedSteps = [];
    _.each(
      this.$step_selector.find('.js-step-popup-item'),
      function(element, newIndex) {
        var oldIndex = $(element).data('ind');
        if (oldIndex === selectedIndex) {
          this.setAnimationData({
            selected: newIndex,
          });
        }
        var step = steps[oldIndex];
        reorderedSteps.push(step);
      }.bind(this)
    );

    this.setAnimationData({
      steps: reorderedSteps,
    });
    // Перерисуем шаги в самом блоке на воркспейсе
    this.animationSteps && this.animationSteps.updateSteps();

    this.showSelectedStep({ updateWorkspaceSelectedStep: true });
  },

  switchStep: function(params) {
    var animation = this.someModel.get('animation'),
      total = animation.steps.length;

    if (params.cur >= total || params.cur < 0) return;

    this.setAnimationData(
      {
        selected: params.cur,
      },
      { historyMergeID: 'selected', skipPreviewUpdate: true }
    );

    this.showSelectedStep({
      updateWorkspaceSelectedStep: params.updateWorkspaceSelectedStep,
    });

    this.__scrollRecalc_debounced();

    RM.analytics && RM.analytics.sendEvent('Animation Switch Steps');
  },

  addStepClick: function(e) {
    var effect = $(e.currentTarget).attr('data-value');

    this.addStep({
      use_effect: effect,
    });

    RM.analytics && RM.analytics.sendEvent('Animation Choose Effect', effect.split('_').pop());
  },

  addStep: function(params) {
    params = params || {};

    var steps = _.cloneWithObjects(this.someModel.get('animation').steps),
      step = _.cloneWithObjects(this.DEFAULT_ANIMATION_STEP),
      changeSet = {},
      stepInd;

    if (params.after_step != 'initial') {
      stepInd = params.after_step != undefined ? params.after_step + 1 : steps.length;
    } else {
      stepInd = 0;

      // переносим данные начального состояния
      changeSet.from_opacity = steps[0].from_opacity;
      changeSet.from_rotate = steps[0].from_rotate;
      changeSet.from_scale = steps[0].from_scale;

      // переносим параметры какие эффекты были включены у первого шага, а какие нет, ведь от этого зависит и эффекты начального состояния
      // use_move не проверяем, ведь у начального состония нет сдвига
      if (steps[0].use_opacity) {
        changeSet.use_opacity = true;
        changeSet.opacity = steps[0].from_opacity;
      }

      if (steps[0].use_rotate) {
        changeSet.use_rotate = true;
        changeSet.rotate = steps[0].from_rotate;
      }

      if (steps[0].use_scale) {
        changeSet.use_scale = true;
        changeSet.scale = steps[0].from_scale;
      }

      _.extend(step, changeSet);
    }

    // вставляем шаг после заданного, либо в конец, если не передано после какого
    steps.splice(stepInd, 0, step);

    this.setAnimationData({
      selected: stepInd,
      steps: steps,
    });

    // если это первый шаг который мы добавили, то всю панельку надо перерендерить и все состояния изменить
    if (steps.length == 1) {
      // полность перерендериваем панельку и ее состояние, показываем шаги
      this.updateAll();

      // с анимацие добавлеем эффект к вновь созданному шагу
      this.addEffectItem(params.use_effect);

      return;
    }

    this.renderStep(step, stepInd);

    this.updateStepsIndexes();

    // заново перерендериваем рамки полностью, ведь добавился шаг и дум элемента рамки этого щага еще нет, лучше все полностью перерисовать
    this.animationSteps && this.animationSteps.updateSteps();

    this.showSelectedStep({
      updateWorkspaceSelectedStep: true,
    });

    this.__scrollRecalc_debounced();

    // а теперь добавлем эффект, делаем это после всего
    // чтоюы анимация была видна применения эффекта на новом шаге
    this.addEffectItem(params.use_effect);

    RM.analytics && RM.analytics.sendEvent('Animation Add Step', stepInd);
  },

  usePresetClick: function(e) {
    if ($(e.target).closest('.preview').length) return;

    var presetName = $(e.currentTarget).attr('data-value');

    this.setAnimationData(this.PRESETS[presetName]);

    this.updateAll();

    RM.analytics && RM.analytics.sendEvent('Animation Use Preset', presetName);
  },

  previewPresetClick: function(e) {
    // alert('Не все сразу');
  },

  // слушае события от шагов анимации, когда они скажут что какой-то шаг попросили удалиться
  // сами они не будут удаляться а просто скажут панельке, панелька сама все сделает в можели и попросит потом все шаги обновиться по модели
  // не охота было размазывать логику ралботы с моделью по двум местам, в итоге все просят панельку когда надо что-то обновить в модели
  removeStep: function(stepInd) {
    var steps = _.cloneWithObjects(this.someModel.get('animation').steps),
      changeSet = {};

    // если просят удалить первый шаг или шаг изначального состояния и шагов всего одиy
    // тогда удаляем единственный шаг и панелька переходит в состояние когда тип выбран а вместо шагов плашка сыбора эффекта для первого шаги либо пресеты анимаций
    if ((stepInd == 0 || stepInd == 'initial') && steps.length == 1) {
      this.setAnimationData({
        selected: undefined,
        steps: [],
      });

      this.updateAll();

      return;
    }

    // если же проят удалить первый шаг и есть еще шаги, тогда данные изначального состояния переносим из первого шага в следующий
    if (stepInd == 0 && steps.length > 1) {
      // запоняем объект анимация данными без пробелов, если в каком-то шаге есть выключенные эффекты, из значения возьмется и последнего шага у которого они есть или из дефолта если нет таких шагов
      // фильтруем поля которых быть не должно и пр.
      // делаем это до "steps[stepInd][use_effect] = true;" ведь там мы эффект включим а значения эффекту еще не проставим
      // а нам надо как раз наоборот, с выключеннмы эффектом, чтобы получить текущие унаследованные значения
      var normalizedAnimation = AnimationUtils.getNormalizedAnimation(
        this.someModel.get('animation'),
        this.isFixed,
        this.isFullwidth,
        this.isFullheight,
        1
      );

      // переносим данные начального состояния
      changeSet.from_opacity = steps[0].from_opacity;
      changeSet.from_rotate = steps[0].from_rotate;
      changeSet.from_scale = steps[0].from_scale;

      // переносим параметры какие эффекты были включены у первого шага, а какие нет, ведь от этого зависит и эффекты начального состояния
      // use_move не проверяем, ведь у начального состония нет сдвига
      if (steps[0].use_opacity) {
        changeSet.opacity = normalizedAnimation.steps[1].opacity; // берем либо прописанное значение (если use_opacity и так включен) либо унаследованное
        changeSet.use_opacity = true;
      }

      if (steps[0].use_rotate) {
        changeSet.rotate = normalizedAnimation.steps[1].rotate; // берем либо прописанное значение (если use_opacity и так включен) либо унаследованное
        changeSet.use_rotate = true;
      }

      if (steps[0].use_scale) {
        changeSet.scale = normalizedAnimation.steps[1].scale; // берем либо прописанное значение (если use_opacity и так включен) либо унаследованное
        changeSet.use_scale = true;
      }

      _.extend(steps[1], changeSet);
    }

    // если просят удалить шаг изначального состояния ничего не делаем
    if (stepInd == 'initial') return;

    steps.splice(stepInd, 1);

    this.setAnimationData({
      selected: stepInd == steps.length ? stepInd - 1 : stepInd,
      steps: steps,
    });

    this.destroyStep(stepInd);

    this.updateStepsIndexes();

    // заново перерендериваем рамки полностью, ведь удалился шаг и надо удалить дум элемента рамки этого щага, лучше все полностью перерисовать
    this.animationSteps && this.animationSteps.updateSteps({ animateStepInd: stepInd });

    this.showSelectedStep({
      updateWorkspaceSelectedStep: true,
    });

    this.__scrollRecalc_debounced();

    RM.analytics && RM.analytics.sendEvent('Animation Remove Step', stepInd);
  },

  resetClick: function() {
    // полностью сбрасываем анимации у всех
    this.setAnimationData({});

    this.updateAll();
  },

  // объединение анимаций, если в выделении виджета с разными группами анимаций, или у одних заданы анимации, а у других совсем нет
  // тогда нам надо выбрать ту группу анимаций которая встречается чаще всего среди виджетов и назначить ее всем
  // выбираем среди тех у кого анимации включены!
  // если таких нет, тогда выберем среди тех у кого выключены
  uniteClick: function() {
    var UUIDS = { enabled: {}, disabled: {} },
      maxCnt = { enabled: 0, disabled: 0 },
      maxModel = { enabled: null, disabled: null };

    _.each(
      this.models,
      _.bind(function(model) {
        var animation = model.get('animation') || {},
          UUID = animation.UUID || 'none',
          key = animation.type && animation.type != 'none' ? 'enabled' : 'disabled';

        UUIDS[key][UUID] = (UUIDS[key][UUID] || 0) + 1;

        if (UUID != 'none' && UUIDS[key][UUID] > maxCnt[key]) {
          maxModel[key] = model;
          maxCnt[key] = UUIDS[key][UUID];
        }
      }, this)
    );

    // устанавливаем всем моделям одну группу анимаций (и одинаковые параметры)
    this.setAnimationData(maxModel.enabled ? maxModel.enabled.get('animation') : maxModel.disabled.get('animation'), {
      skipHistory: true,
    });

    this.updateAll();
  },

  // слушаем от воркспейса события сдвига, поворота или ресайза шагов анимации
  onAnimationStepsChange: function(changeSet) {
    // изначальный шаг это тот же нулевой шаг, но с параметрами from_ вместо
    var stepInd = changeSet.id,
      steps = _.cloneWithObjects(this.someModel.get('animation').steps),
      updateCurrentStepParams = false;

    delete changeSet.id;

    if (!steps[stepInd]) return;

    _.extend(steps[stepInd], changeSet);

    if (changeSet.dx != undefined && !steps[stepInd].use_move) {
      steps[stepInd].use_move = true;
      updateCurrentStepParams = true;
    }

    if ((changeSet.scale != undefined || changeSet.from_scale != undefined) && !steps[stepInd].use_scale) {
      steps[stepInd].use_scale = true;
      updateCurrentStepParams = true;
    }

    this.setAnimationData(
      {
        steps: steps,
      },
      { historyMergeID: _.keys(changeSet).join('-') }
    ); // формируем ключ изменяемых полей, по нему мы потом в итории поймем что меняем то же самое и можно смерджить с предыдущим шагом, ключ будет вида

    if (updateCurrentStepParams) {
      this.updateCurrentStepParams({ visibility: true });
    }

    if (stepInd == this.someModel.get('animation').selected) {
      this.updateCurrentStepInputs(_.keys(changeSet));
    }
  },

  typeSelectorItemClick: function(e) {
    var animation = this.someModel.get('animation') || {},
      oldType = animation.type || 'none',
      newType = $(e.currentTarget).attr('data-value'),
      isPreviewEnabled = this.checkPreviewEnabled(newType),
      stateChanged = (oldType === 'none' && newType !== 'none') || (oldType !== 'none' && newType === 'none'); // свотрим переключение состояния вкл/выкл анимации

    // Если включена фича превью, то нельзя добавить on click анимацию
    if (isPreviewEnabled) {
      this.setAnimationData({
        type: newType,
      });

      this.popupManager.closePopup('type-selector');
      this.updateAll();
      this.setAnimationData({ type: 'none' });
      this.someModel.trigger('animation:type:change', 'none', oldType);

      this.animationSteps && this.animationSteps.destroy();
      return;
    }

    this.setAnimationData({
      type: newType,
    });

    this.popupManager.closePopup('type-selector');

    if (stateChanged || this.someModel.get('preview_enabled')) {
      this.updateAll();
    } else {
      this.updateType();

      this.updateCurrentStepParams({ visibility: true, start: true, delay: true, duration: true, delay_px: true });

      this.updateCurrentStepInputs();
    }

    if (oldType !== newType) {
      this.someModel.trigger('animation:type:change', newType, oldType);
    }

    RM.analytics && RM.analytics.sendEvent('Animation Type Change', newType);
  },

  addEffectItemClick: function(e) {
    this.addEffectItem($(e.currentTarget).attr('data-value'));

    this.popupManager.closePopup('add-effect');
  },

  addEffectItem: function(use_effect) {
    var animation = this.someModel.get('animation'),
      stepInd = animation.selected || 0,
      changeSet = {};

    // если этот эффект уже есть у данного шага ничего не делаем
    if (animation.steps[stepInd][use_effect]) return;

    var steps = _.cloneWithObjects(animation.steps);

    // запоняем объект анимация данными без пробелов, если в каком-то шаге есть выключенные эффекты, из значения возьмется и последнего шага у которого они есть или из дефолта если нет таких шагов
    // фильтруем поля которых быть не должно и пр.
    // делаем это до "steps[stepInd][use_effect] = true;" ведь там мы эффект включим а значения эффекту еще не проставим
    // а нам надо как раз наоборот, с выключеннмы эффектом, чтобы получить текущие унаследованные значения
    var normalizedAnimation = AnimationUtils.getNormalizedAnimation(
      animation,
      this.isFixed,
      this.isFullwidth,
      this.isFullheight,
      1
    );

    steps[stepInd][use_effect] = true;

    // при первом включении эффекта добавлем выставляем такие параметры чтобы на воркспейсе он визуально изменился
    // важно в параметрах отталкиваться от текущего унаследованного значения (используя объект normalizedAnimation)
    if (use_effect == 'use_move' && steps[stepInd].dx == undefined) {
      changeSet.dx = normalizedAnimation.steps[stepInd].dx;
      changeSet.dy = normalizedAnimation.steps[stepInd].dy + 10;
    }

    if (use_effect == 'use_opacity' && steps[stepInd].opacity == undefined) {
      changeSet.opacity = Math.round(normalizedAnimation.steps[stepInd].opacity * 0.7);
    }

    if (use_effect == 'use_rotate' && steps[stepInd].rotate == undefined) {
      changeSet.rotate = normalizedAnimation.steps[stepInd].rotate + 45;
    }

    if (use_effect == 'use_scale' && steps[stepInd].scale == undefined) {
      changeSet.scale = Math.round(normalizedAnimation.steps[stepInd].scale * 0.7);
    }

    _.extend(steps[stepInd], changeSet);

    this.setAnimationData({
      steps: steps,
    });

    this.updateCurrentStepParams({ visibility: true });

    // заново перерендериваем рамки полностью
    this.animationSteps && this.animationSteps.updateSteps({ animateStepInd: stepInd });

    this.__scrollRecalc_debounced();

    // обновлем все, чтобы не плодить код который определяет какие надо
    this.updateCurrentStepInputs();
  },

  removeEffectClick: function(e) {
    var animation = this.someModel.get('animation'),
      stepInd =
        $(e.target)
          .closest('.step')
          .attr('data-ind') - 0,
      remove_effect = $(e.currentTarget).attr('data-value');

    var steps = _.cloneWithObjects(animation.steps);

    steps[stepInd][remove_effect] = false;

    this.setAnimationData({
      steps: steps,
    });

    this.updateCurrentStepParams({ visibility: true });

    // заново перерендериваем рамки полностью
    this.animationSteps && this.animationSteps.updateSteps({ animateStepInd: stepInd });

    this.__scrollRecalc_debounced();
  },

  onTriggerButtonClick: function(event) {
    var triggerId = $(event.target)
      .closest('[data-id]')
      .data('id');
    // Если триггер не выбран, при клике на кнопку сменим её состояние на "ожидание"
    var state = this.getTriggerButtonState(triggerId);
    var blockId = this.block.id;
    if (state.status === 'none') {
      this.updateTriggerButton(triggerId, 'pending');
    } else if (state.status === 'onOtherPage' && state.pageNumber) {
      // В ситуации когда для анимации глобального виджета триггер находится на другой страницы
      // мы переходим на страницу с триггером и выделяем этот же виджет на другой странице
      event.stopPropagation();

      RM.constructorRouter.once('pageChange', newWorkspace => {
        setTimeout(() => {
          var block = _.find(newWorkspace.blocks, b => {
            return b.id == blockId;
          });
          block && block.onClick();
        }, 0);
      });
      RM.constructorRouter.showWorkspace(state.pageNumber);
    }
  },

  onTriggerCancelClick: function(event) {
    var triggerId = $(event.target)
      .closest('[data-id]')
      .data('id');
    event.stopPropagation();
    this.removeTriggerButton(triggerId);
  },

  onTriggerRemoveClick: function(event) {
    event.stopPropagation();
    var triggerId = $(event.target)
      .closest('[data-id]')
      .data('id');
    var triggerIds = this.block.getAnimationTriggers();
    if (triggerIds.indexOf(triggerId) !== -1) {
      this.highlightActiveTriggerBlock(triggerId, false, true);
      this.setAnimationData({ trigger: _.without(triggerIds, triggerId) });
      this.someModel.trigger('animation:trigger:remove', triggerId);
    }

    // @TODO: тип анимации надо указать
    RM.analytics && RM.analytics.sendEvent('Animation Trigger Remove');
  },

  onTriggerNameMouseEnter: function(event) {
    this.highlightActiveTriggerBlock(
      $(event.target)
        .closest('[data-id]')
        .data('id'),
      true,
      false
    );
  },

  onTriggerNameMouseLeave: function() {
    this.highlightActiveTriggerBlock(
      $(event.target)
        .closest('[data-id]')
        .data('id'),
      false,
      false
    );
  },

  onTriggerRemoveMouseEnter: function(event) {
    this.highlightActiveTriggerBlock(
      $(event.target)
        .closest('[data-id]')
        .data('id'),
      true,
      true
    );
  },

  onTriggerRemoveMouseLeave: function(event) {
    this.highlightActiveTriggerBlock(
      $(event.target)
        .closest('[data-id]')
        .data('id'),
      false,
      true
    );
  },

  onTriggerClick: function(block, event) {
    if (AnimationUtils.canBeExternalTrigger(block, this.block)) {
      event.stopPropagation();
      // Запомним id триггера
      var triggerId = block.id;
      this.highlightActiveTriggerBlock(triggerId, false, false);
      this.setAnimationData({
        trigger: this.removeMissingTriggers(this.block.getAnimationTriggers()).concat(triggerId),
      });
      this.someModel.trigger('animation:trigger:add', triggerId);

      // @TODO: тип анимации надо указать
      RM.analytics && RM.analytics.sendEvent('Animation Trigger Click');
    }
  },

  /**
   * При наведении курсора на любой блок показывает его рамку трансформа. Вызывается в режиме выбора триггера.
   * @param {Event} event
   */
  onTriggerMouseEnter: function(event) {
    this.highlightPendingTriggerBlock(
      $(event.target)
        .closest('.block')
        .data('id'),
      true
    );
  },

  /**
   * Убирает рамку трансформа, когда курсор не на блоке. Вызывается в режиме выбора триггера.
   * @param {Event} event
   */
  onTriggerMouseLeave: function(event) {
    this.highlightPendingTriggerBlock(
      $(event.target)
        .closest('.block')
        .data('id'),
      false
    );
  },

  onLoopTypeClick: function(event) {
    if (this.$loop_types.hasClass('disabled')) return;
    var $target = $(event.target);

    this.setAnimationData({
      loop: $target.hasClass('checked') ? false : $target.data('value'),
    });

    this.updateLoop();
  },

  highlightPendingTriggerBlock: function(blockId, willShow) {
    var block = blockId && this.master.workspace.findBlock(blockId);
    // Подсветим блок только если он может быть триггером
    block &&
      block.highlight(
        willShow && AnimationUtils.canBeExternalTrigger(block, this.block),
        AnimationUtils.ACTIVE_TRIGGER_HIGHLIGHT_CLASS
      );
  },

  highlightActiveTriggerBlock: function(triggerId, willShow, isRemove) {
    var triggerBlock = triggerId && this.master.workspace.findBlock(triggerId);
    var isSelf = triggerBlock && this.blocks.length === 1 && this.block.id === triggerBlock.id;
    var cssClass = isRemove
      ? AnimationUtils.ACTIVE_TRIGGER_REMOVE_CLASS
      : AnimationUtils.ACTIVE_TRIGGER_HIGHLIGHT_CLASS;
    if (triggerBlock) {
      triggerBlock.highlight(willShow, cssClass);
    }
    var triggerExtras = this.block.triggerAssets && this.block.triggerAssets[triggerId];
    if (triggerExtras) {
      var tooltipElement = isSelf
        ? this.block.ownTooltip && this.block.ownTooltip.getElement()
        : triggerExtras.tooltip && triggerExtras.tooltip.getElement();
      tooltipElement && tooltipElement.toggleClass(cssClass, Boolean(willShow));
    }
  },

  /**
   * Привязывает или отвязывает хэндлеры в зависимости от состояния кнопок с триггерами
   * @param {Object} state
   */
  updateTriggerButtonHandlers: function(state) {
    var statuses = _.map(state, function(trigger) {
      return trigger.status;
    });
    var status = statuses.indexOf('pending') !== -1 ? 'pending' : statuses.indexOf('active') !== -1 ? 'active' : 'none';
    // Все остальные блоки кроме уже выбранных. Разрешаем выбирать триггером текущий блок, одиночный или в группе
    var otherBlocks = this.master.workspace.getBlockElements(AnimationUtils.EXTERNAL_TRIGGER_BLOCK_EXCLUDE);
    var otherBlocksExceptTriggers = otherBlocks.not(
      _.map(this.block.getAnimationTriggers(), function(triggerId) {
        return '[data-id="' + triggerId + '"]';
      }).join(', ')
    );

    var subscribeTrigger = function() {
      // Подпишемся на клик по любому блоку
      this.master.workspace.on('block:click', this.onTriggerClick);
      // Слушаем ховер на другие блоки
      otherBlocksExceptTriggers.on('mouseenter', this.onTriggerMouseEnter);
      otherBlocksExceptTriggers.on('mouseleave', this.onTriggerMouseLeave);
    }.bind(this);

    var unsubscribeTrigger = function() {
      // Отпишемся от клика по любому блоку
      this.master.workspace.off('block:click', this.onTriggerClick);
      // Перестанем слушать ховер на другие блоки
      otherBlocks.off('mouseenter', this.onTriggerMouseEnter);
      otherBlocks.off('mouseleave', this.onTriggerMouseLeave);
    }.bind(this);

    switch (status) {
      case 'pending':
        this.waitingForTriggerClick = true;
        subscribeTrigger();
        // На время выбора триггера выйдем из режима шагов анимации, иначе практически невозможно подсветить и выбрать триггером блок внутри анимируемой группы
        if (this.block.animationMode) {
          this.willRestoreAnimationMode = true;
          this.destroyAnimationSteps();
        }
        break;

      case 'active':
      case 'onOtherPage':
      case 'none':
        // Отложим сброс флага об ожидании триггера, чтобы с этим флагом не закрывать контрол по внешнему клику при выборе триггера.
        _.delay(
          function() {
            this.waitingForTriggerClick = false;
          }.bind(this)
        );
        unsubscribeTrigger();
        // Если на время выбора триггера пришлось выйти из режима шагов анимации, восстановим этот режим
        if (this.willRestoreAnimationMode) {
          this.willRestoreAnimationMode = false;
          this.initializeAnimationSteps();
        }
        break;
    }
  },

  /**
   * Реакция на добавление / удаление триггера в модели
   * @param {String} triggerId
   * @param {String} nextStatus
   * @param {Number} pageNumber
   */
  renderTriggerButton: function(triggerId, nextStatus, pageNumber) {
    var triggerBlock = triggerId && this.master.workspace.findBlock(triggerId);
    var isSelf = triggerBlock && this.blocks.length === 1 && this.block.id === triggerBlock.id;
    return templates['template-constructor-control-common_animation-trigger']({
      id: triggerId,
      status: nextStatus,
      pageNumber: pageNumber,
      name: triggerBlock
        ? isSelf
          ? 'Self'
          : triggerBlock.model.get('name') || triggerBlock.getStandardName()
        : 'Trigger',
    });
  },

  getTriggerButtonState: function(triggerId) {
    return (this.triggerButtonsState && this.triggerButtonsState[triggerId]) || {};
  },

  getStepIndex: function(element) {
    return (
      $(element)
        .closest('.step')
        .attr('data-ind') - 0
    );
  },

  changeStepField: function(index, changes) {
    var animation = this.someModel.get('animation');

    var steps = _.cloneWithObjects(animation.steps);

    _.extend(steps[index], changes);

    this.setAnimationData({
      steps: steps,
    });
  },

  onSwitcherChange: function(state) {
    // Флаг start when in view можно проставить только у первого шага
    this.changeStepField(0, { startWhenInView: state });
  },

  startItemClick: function(e) {
    var startPoint = $(e.currentTarget).attr('data-value');

    this.changeStepField(this.getStepIndex(e.target), { start_point: startPoint });

    this.updateCurrentStepParams({ start: true });
  },

  accelerationItemClick: function(e) {
    var acceleration = $(e.currentTarget).attr('data-value');

    this.changeStepField(this.getStepIndex(e.target), { acceleration: acceleration });

    this.popupManager.closePopup('acceleration-selector');

    this.updateCurrentStepParams({ acceleration: true });
  },

  accelerationItemMouseEnterLeave: function(e) {
    var $ball = $(e.currentTarget)
        .siblings('.acceleration-popup-preview')
        .find('.ball'),
      next = e.type == 'mouseenter' ? $(e.currentTarget).attr('data-value') : '',
      cur = $ball.attr('data-acceleration') || '';

    if (!cur) {
      $ball.attr('data-acceleration', next);
    } else {
      var iterationEventName = AnimationUtils.ANIMATION_PROPERTIES.animationIterationJS;

      $ball.off().one(iterationEventName, function(e) {
        // смотрим это итерация прямого хода или обратного (суммарное прошедшее время анимации делим на длительность одного хода)
        // 0 это конец обратного хода, 1 - коне прямого
        var dir = Math.round(e.originalEvent.elapsedTime / 0.7) % 2;

        // если мы просим завершить анимацию, то сделдать это надо в конце обратного хода
        // иначе быдет резки рывок из конца в начало
        if (dir == 0) {
          $ball.attr('data-acceleration', '');
          $ball.outerHeight();
          $ball.attr('data-acceleration', next);
        } else {
          // если это конец прямого, дождемся конца обратного
          $ball.off().one(iterationEventName, function(e) {
            $ball.attr('data-acceleration', '');
            $ball.outerHeight();
            $ball.attr('data-acceleration', next);
          });
        }
      });
    }
  },

  onParamInputChange: function($input, value) {
    var stepInd = this.getStepIndex($input),
      steps = _.cloneWithObjects(this.someModel.get('animation').steps),
      leftInputVal = $('.move-block .numeric-input.left-align')[0].value,
      rightInputVal = $('.move-block .numeric-input.right-align')[0].value,
      param = $input.attr('data-param');

    if (leftInputVal > 9999 || leftInputVal < -9999 || (rightInputVal > 9999 || rightInputVal < -9999)) {
      $('.move-block .numeric-input').css('font-size', '18px');
    } else {
      $('.move-block .numeric-input').css('font-size', '22px');
    }

    if (!steps[stepInd]) return;

    steps[stepInd][param] = value;

    this.setAnimationData(
      {
        steps: steps,
      },
      { historyMergeID: param }
    );

    // если меняются параметры влияющие на отображение рамок в воркспейсе
    // тогда просим перерисовать их
    if (_.contains(['rotate', 'scale', 'opacity', 'dx', 'dy', 'from_rotate', 'from_scale', 'from_opacity'], param)) {
      // просим рамки перерисоваться исходя из новых значений
      this.animationSteps && this.animationSteps.updateSteps();
    }

    if (param == 'start_offset') {
      this.updateCurrentStepParams({ start: true });
    }

    if (_.contains(['delay', 'duration', 'delay_px'], param)) {
      var data = {};
      data[param] = true;
      this.updateCurrentStepParams(data);
    }
  },

  onAnimationTriggerAdd: function(triggerId) {
    this.updateTriggerButton(triggerId, 'active');
  },

  onAnimationTriggerRemove: function(triggerId) {
    this.removeTriggerButton(triggerId);
  },

  initializeAnimationSteps: function() {
    // создаем объект который будет управлять отображением и тасканием рамок шагов анимации
    this.animationSteps = new AnimationStepsClass({
      blocks: this.blocks,
      workspace: this.master.workspace,
      isFixed: this.isFixed,
      isFullwidth: this.isFullwidth,
      isFullheight: this.isFullheight,
      isSticked: this.isSticked,
      popupManager: this.popupManager, //передаем менеджер, у нас у шагов есть ромбик с плюсом, а в нем попап, хочется чтоюы он взаимодействовал с попапами в панельке: при открытии его в панельке чтобы закрывались и наоборот, чтобы при открытом попапе клик в пустое место на экране закрывал его и пр.
    });

    // Присваиваем триггерам, которые выще блока / группы z-index выше анимационных шагов, чтобы триггеры оставались видны.
    var animationStepsZIndex = parseInt(this.animationSteps.$el.css('z-index'), 10);
    var animatedBlockZIndex = _.max(
      _.map(this.blocks, function(block) {
        return block.model.attributes.z;
      })
    );
    _.each(this.block.getTriggerBlocks(), function(block, index) {
      var originalElementZIndex = block.$el.css('z-index');
      var actualZIndex = block.model.attributes.z;
      // NOTE: Присваиваем z-index именно элементу block, а не вложенному элементу content, как это сделал бы block.css(),
      // потому что иначе рамка триггера всё ещё будет под текущим блоком, хотя контент — над блоком.
      animatedBlockZIndex < actualZIndex ? block.$el.css({ 'z-index': animationStepsZIndex + index + 1 }) : null;
      block.$el.data({ 'original-element-z-index': originalElementZIndex });
    });

    this.updateSteps();

    this.updateStepsIndexes();

    this.showSelectedStep({
      updateWorkspaceSelectedStep: true,
    });

    // слушаем события сдвига и ресайза шагов на воркспейсе
    this.animationSteps.on('move scale', this.onAnimationStepsChange);

    this.animationSteps.on('add-step', this.addStep);

    this.animationSteps.on('remove-step', this.removeStep);

    this.animationSteps.on('select', this.onAnimationStepsSelect);
  },

  destroyAnimationSteps: function() {
    this.animationSteps && this.animationSteps.destroy();
    // Восстанавливаем z-index'ы триггеров
    _.each(this.block.getTriggerBlocks(), function(block) {
      // У обычных блоков у элемента .block нет изначального z-index'а (он есть у элемента .content),
      // Но у фикседов z-index присваивается элементу .block, поэтому временный z-index нельзя просто сбросить
      var originalElementZIndex = block.$el.data('original-element-z-index');
      block.$el.css({
        'z-index': originalElementZIndex && originalElementZIndex !== 'auto' ? originalElementZIndex : null,
      });
    });
  },

  updateAll: function(params) {
    params = params || {};

    // определяем по первому виджету в выделении,
    // потому что фикседы и фулвидхи только по одному могут быть, мы их в группах анимаций не разрешаем использовать
    this.isFullwidth = !!this.someModel.get('is_full_width');
    this.isFullheight = !!this.someModel.get('is_full_height');
    this.isFixed = !!this.someModel.get('fixed_position');
    this.isSticked = !!this.someModel.get('sticked');

    this.isMobileViewport = this.master.workspace.page.getCurrentViewport() != 'default';

    // для мобильных вьюпортов прячем ховер анимацию
    this.$panel.find('.top-block .type-selector .type-popup-item[data-value="hover"]').toggle(!this.isMobileViewport);

    this.updateControlState();

    this.destroyAnimationSteps();

    this.updateType();

    // проверяем что с выделенными виджетами все в порядке и анимац включены
    if (!this.checkWidgetsCompatibility()) {
      // После checkWidgetsCompatibility, который добавляет css-классы, нужно пересчитать высоту блоков ещё раз
      this.updateBlockHeight();
      this.stopPreviewMode();
      return;
    }

    this.updateBlockHeight();

    // закрываем все попапы
    this.popupManager && this.popupManager.closeAllPopups();

    this.initializeAnimationSteps();

    this.updateLoop();

    this.updatePlayOnce();

    // NOTE: Ре-рендер триггеров и расчёт высоты верхнего блока важно делать в конце общего обновления,
    // иначе высота верхнего блока рассчитается неправильно
    this.resetTriggerButtons();

    this.__scrollRecalc_debounced && this.__scrollRecalc_debounced();
  },

  updateControlState: function() {
    this.$el.toggleClass(
      'has-animation',
      _.any(this.models, function(model) {
        return model.get('animation') && model.get('animation').type && model.get('animation').type != 'none';
      })
    );
  },

  // проверяет состав виджетов в выделении, ситуации:
  // 1. Есть фикседы/фулвидхи/фулхайты/стики и обычные виджеты: пишем что нельзя миксовать нормальные виджеты и фикседы/фулвидхи/фулхайты/стики, возвращаем FALSE
  // 2. Все виджеты фикседы и их больше одного и они привязаны к разным точкам: пишем, что анимации для фикседов нельзя группировать, возвращаем FALSE
  // 2а. Все виджеты стики и их больше одного: пишем, что анимации для стики нельзя группировать, возвращаем FALSE
  // 3. Все виджеты фулвидхи и их больше одного: пишем, что анимации для фулвидхов нельзя группировать, возвращаем FALSE
  // 3a. Все виджеты фулхайты и их больше одного: пишем, что анимации для фулхайтов нельзя группировать, возвращаем FALSE
  // 4. Если у среди виджетов есть разные UUID анимаций, или есть виджеты у которых есть анимации и у которых нет: пишем что надо бы объединить анимации в одну группу, и кнопку apply, возвращаем FALSE
  // 5. Если анимации у виджетов выключены, по показываем только часть панельки где их можно включить и выходим
  // 6. если мы тут значит у нас либо один фиксед в выделении, либо несколько виджетов но у всех либо одна група анимаций, либо вообще нет анимаций, это ок возвращаем TRUE
  /**
   * @returns {boolean}
   */
  checkWidgetsCompatibility: function() {
    var normalCount = 0,
      fixedsCount = 0,
      fixeds = [],
      fullwidthsCount = 0,
      fullheightsCount = 0,
      stickedsCount = 0,
      nonAnimatableCount = 0,
      UUIDS = {};

    _.each(
      this.models,
      _.bind(function(model) {
        if (model.get('fixed_position')) {
          fixedsCount++;
          fixeds.push(model.get('fixed_position'));
        }
        if (model.get('is_full_width')) fullwidthsCount++;
        if (model.get('is_full_height')) fullheightsCount++;

        if (model.get('sticked')) stickedsCount++;
        if (!AnimationUtils.canHaveAnimation(model)) {
          nonAnimatableCount++;
        }
        if (
          !model.get('fixed_position') &&
          !model.get('is_full_width') &&
          !model.get('is_full_height') &&
          !model.get('sticked')
        )
          normalCount++;

        var animation = model.get('animation') || {};
        UUIDS[animation.UUID || 'none'] = true;
      }, this)
    );

    this.$panel.removeClass(
      'show-mixed-types show-multiple-fixeds show-multiple-stickeds show-multiple-fullwidths show-multiple-fullheights show-multiple-animations animation-disabled with-reset animation-without-steps show-non-animatable'
    );

    // если у нас только один виджет то анимации всегда разрешены, независимо от его типа
    if (this.models.length > 1) {
      // 1. Есть фикседы или фулвидхи и обычные виджеты: пишем что нельзя миксовать нормальные виджеты и фикседы/фулвидхи/стики, возвращаем FALSE
      // проверка что есть нормальный и один из фикседа/фулвидха/стики
      // или что нормальных нет но есть либо стики и фиксед, либо стики и фулвидх, либо фиксед и фулвидх
      if (
        (normalCount && (fixedsCount || fullwidthsCount || fullheightsCount || stickedsCount)) ||
        (!normalCount &&
          ((fixedsCount && fullwidthsCount) ||
            (fixedsCount && fullheightsCount) ||
            (fixedsCount && stickedsCount) ||
            (stickedsCount && fullwidthsCount) ||
            (stickedsCount && fullheightsCount) ||
            (fullwidthsCount && fullheightsCount)))
      ) {
        this.$panel.addClass('show-mixed-types');
        this.toggleFixedPanelSize(true, this.MESSAGE_MIXED_HEIGHT);
        RM.analytics && RM.analytics.sendEvent('Animation Message Mixed Types');
        return false;
      }

      // если мы тут, значит у нас только 4 варианта осталось: либо все нормальные, либо все фикседы, либо все фулвидхи, либо все стики (и всех больше 1)

      // 2. Все виджеты фикседы и их больше одного и они привязаны к разным точкам: пишем, что анимации для фикседов нельзя группировать, возвращаем FALSE
      if (fixedsCount) {
        //&& _.uniq(_.compact(fixeds)).length != 1) {
        this.$panel.addClass('show-multiple-fixeds');
        this.toggleFixedPanelSize(true, this.MESSAGE_MULTIPLE_HEIGHT);
        RM.analytics && RM.analytics.sendEvent('Animation Message Multiple Fixed');
        return false;
      }

      // 2а. Все виджеты стики и их больше одного: пишем, что анимации для стики нельзя группировать, возвращаем FALSE
      if (stickedsCount) {
        this.$panel.addClass('show-multiple-stickeds');
        this.toggleFixedPanelSize(true, this.MESSAGE_MULTIPLE_HEIGHT);
        RM.analytics && RM.analytics.sendEvent('Animation Message Multiple Sticky');
        return false;
      }

      // 3. Все виджеты фулвидхи и их больше одного: пишем, что анимации для фулвидхов нельзя группировать, возвращаем FALSE
      if (fullwidthsCount) {
        this.$panel.addClass('show-multiple-fullwidths');
        this.toggleFixedPanelSize(true, this.MESSAGE_MULTIPLE_HEIGHT);
        RM.analytics && RM.analytics.sendEvent('Animation Message Multiple Full Width');
        return false;
      }

      // 3a. Все виджеты фулхайты и их больше одного: пишем, что анимации для фулхайтов нельзя группировать, возвращаем FALSE
      if (fullheightsCount) {
        this.$panel.addClass('show-multiple-fullheights');
        this.toggleFixedPanelSize(true, this.MESSAGE_MULTIPLE_HEIGHT);
        RM.analytics && RM.analytics.sendEvent('Animation Message Multiple Full Height');
        return false;
      }

      // 4. Среди виджетов есть не-анимируемые виджеты
      if (nonAnimatableCount) {
        this.$panel.addClass('show-non-animatable');
        this.toggleFixedPanelSize(true, this.MESSAGE_NON_ANIMATABLE_HEIGHT);
        RM.analytics && RM.analytics.sendEvent('Animation Message Cannot Animate Group');
        return false;
      }

      // если мы тут, значит у нас все виджеты нормальные и их больше одного

      // 5. Если у среди виджетов есть разные UUID анимаций, или есть виджеты у которых есть анимации и у которых нет: пишем что надо бы объединить анимации в одну группу, и кнопку apply, возвращаем FALSE
      if (_.keys(UUIDS).length > 1) {
        this.$panel.addClass('show-multiple-animations');
        this.toggleFixedPanelSize(true, this.MESSAGE_UNITE_HEIGHT);
        RM.analytics && RM.analytics.sendEvent('Animation Message Unite Animations Confirm');
        return false;
      }
    }

    var animation = this.someModel.get('animation') || {};

    // 6. Если анимации у виджетов выключены, по показываем только часть панельки где их можно включить и выходим
    if (!animation.type || animation.type == 'none') {
      this.$panel.addClass('animation-disabled');

      // если есть анимации, показываем кнопку reset
      if (!_.isEmpty(animation)) {
        this.$panel.addClass('with-reset');
        this.toggleFixedPanelSize(true, this.DISABLED_HEIGHT_RESET);
      } else {
        this.toggleFixedPanelSize(true, this.DISABLED_HEIGHT);
      }

      return false;
    } else {
      // Если анимации у виджетов включены, но шагов нет
      if (_.isEmpty(animation.steps)) {
        // показываем список эффектов для первого шага и список пресетов
        this.$panel.addClass('animation-without-steps');

        // для full height и full width отключаем возможность выбора rotate
        this.$panel
          .find('.effects-presets-wrapper .effects-item[data-value="use_rotate"]')
          .toggle(!(this.isFullwidth || this.isFullheight));
        this.$panel
          .find('.effects-presets-wrapper .presets-item[data-value="rotate"]')
          .toggle(!(this.isFullwidth || this.isFullheight));

        // удаляем все отрендеренные ранее шаги, поскольку они лежат в ресайз контейнере
        // в нем же лежит  список эффектов для первого шага и список пресетов
        // поэтому мы должны здесь удалить отрендеренные ранее шаги которых уже нет в модели
        this.destroyAllSteps();

        // отменяем фиксированую высот панельки и возвращаем ресайзабл состояние и прежний размер
        this.toggleFixedPanelSize(false);

        this.resizableScroll && this.resizableScroll.changeGaps({ gap_start: 16 });

        // обновляем скрол в списке эффектов и пресетов
        this.__scrollRecalc_debounced && this.__scrollRecalc_debounced();

        return false;
      }
    }

    // чтобы было ниже блоков start, delay, duration и пр.
    this.resizableScroll && this.resizableScroll.changeGaps({ gap_start: 88 });

    // отменяем фиксированую высот панельки и возвращаем ресайзабл состояние и прежний размер
    this.toggleFixedPanelSize(false);

    return true;
  },

  updateEffectPopupState: function($selector) {
    var animation = this.someModel.get('animation') || {},
      stepInd = animation.selected || 0,
      step = animation.steps[stepInd],
      params = ['use_move', 'use_opacity', 'use_rotate', 'use_scale'];

    $selector.find('.effect-popup-item[data-value="use_rotate"]').toggle(!(this.isFullwidth || this.isFullheight));

    _.each(params, function(param) {
      $selector.find('.effect-popup-item[data-value="' + param + '"]').toggleClass('used', !!step[param]);
    });
  },

  updateAccelerationPopupState: function($selector) {
    var noRoom = $selector.position().top < 130;

    // если сверху меньше 130px тогда показываем попап ниже и без уголка
    // благо по дизайну у нас это может быть только в одном случае, когда нет ни одного эффекта и мы не в скрол анимации (там всегда есть параметр speed и delay_px или start)
    $selector.toggleClass('shift-popup', noRoom);
  },

  // реакция на смену типа
  updateType: function() {
    var animation = this.someModel.get('animation') || {},
      tp = animation.type || 'none', // изначально нет вообще animation.type
      $item = this.$panel.find('.top-block .type-selector .type-popup-item[data-value="' + tp + '"]');

    // обновляем состояние переключалки вкл/выкл анимаций
    $item
      .addClass('curr')
      .siblings()
      .removeClass('curr');
    this.$panel.find('.top-block .type-selector .type-caption').text($item.text());
    this.$panel.toggleClass('show-trigger', AnimationUtils.isExternalTriggerAllowed(tp) && !_.isEmpty(animation.steps));
    this.$panel.toggleClass('show-loops', tp !== 'scroll');
    this.$panel.toggleClass('show-start-condition', tp === 'load');
    this.$panel.toggleClass('show-play-once', tp === 'click');
    this.toggleRequiresTrigger();
    this.checkPreviewEnabled();

    // Пересчитаем скролл, потому что панель с триггером и без, с петлями и без — разного размера
    window.requestAnimationFrame(this.resizableScroll.recalc.bind(this.resizableScroll));

    this.updateBlockHeight();
  },

  toggleRequiresTrigger: function() {
    var animation = this.someModel.get('animation') || {};
    this.$panel.toggleClass(
      'show-requires-trigger',
      Boolean(
        animation.type === 'click' &&
          !this.block.getAnimationTriggers().length &&
          this.someModel.get('type') === 'video'
      ) && !_.isEmpty(animation.steps)
    );
    this.updateCurrentStepInputs();
  },

  checkPreviewEnabled: function(type) {
    var animationType = type || (this.someModel.get('animation') || {}).type,
      previewEnabled = Boolean(this.someModel.get('preview_enabled')),
      clickAndPreviewEnabled = Boolean(animationType === 'click' && previewEnabled);

    this.$panel.toggleClass('show-cannot-use-with-picture-preview', clickAndPreviewEnabled);
    this.updateCurrentStepInputs();

    return clickAndPreviewEnabled;
  },

  resetTriggerButtons: function() {
    this.updateTriggerButtons(
      _.reduce(
        this.block.getAnimationTriggers(),
        function(state, triggerId) {
          state[triggerId] = this.verifyTriggerState(triggerId, 'active');
          return state;
        }.bind(this),
        {}
      )
    );
  },

  removeTriggerButton: function(triggerId) {
    if (triggerId) {
      // Убираем кнопку триггер, кроме кнопки добавления нового триггера (с id: new). Её не убираем, только сбрасываем status в none.
      var state =
        triggerId === 'new'
          ? _.extend({}, this.triggerButtonsState, { new: { status: 'none' } })
          : _.omit(this.triggerButtonsState, triggerId);
    }
    this.updateTriggerButtons(state);
  },

  removeTriggerButtons: function() {
    this.updateTriggerButtons({});
  },

  updateTriggerButton: function(triggerId, status) {
    if (triggerId) {
      var update = {};
      update[triggerId] = this.verifyTriggerState(triggerId, status);
      var state = _.extend({}, _.omit(this.triggerButtonsState, 'new'), update);
      this.updateTriggerButtons(state);
    }
  },

  updateTriggerButtons: function(nextState) {
    // Уберём триггеры, которых фактически нет
    nextState = _.omit(nextState, function(entry, triggerId) {
      return triggerId !== 'new' && entry && entry.status === 'none';
    });
    // Позаботимся о том, чтобы всегда была кнопка «добавить новый триггер», если мы не превышаем лимит триггеров
    !nextState.new && _.keys(nextState).length < this.TRIGGER_COUNT_LIMIT ? (nextState.new = { status: 'none' }) : null;

    var $buttons = $(document.createDocumentFragment());
    _.each(
      nextState,
      function(trigger, triggerId) {
        $buttons.append(this.renderTriggerButton(triggerId, trigger.status, trigger.pageNumber));
      }.bind(this)
    );
    this.$panel
      .find('.js-trigger-buttons-container')
      .empty()
      .append($buttons);
    this.updateTriggerButtonHandlers(nextState);
    this.triggerButtonsState = nextState;
    this.updateBlockHeight();
    this.toggleRequiresTrigger();
    this.checkPreviewEnabled();
  },

  updateBlockHeight: function() {
    var type = (this.someModel.get('animation') || {}).type || 'none';
    var $topBlock = this.$panel.find('.js-top-block');
    var $topBlockContent = this.$panel.find('.js-top-block-content');
    var $steps = this.$panel.find('.js-step-selector');
    var actualHeight = $topBlockContent.outerHeight();
    $topBlock.css({ height: type === 'none' ? '' : actualHeight });
    this.$panel
      .find('.resizable-scroll-wrapper')
      .css({ top: actualHeight + (($steps[0] && $steps[0].offsetHeight) || 0) });
    $steps.css({ top: actualHeight });
    this.__scrollRecalc_debounced();
  },

  /**
   * Проверяет действительный статус триггера и возвращает исправленный статус, если нужно. Также возвращает номер страницы, если триггер на другой странице.
   * @param {String} triggerId
   * @param {String} status
   * @return {Object} {status: {String}, pageNumber: [Number]}
   */
  verifyTriggerState: function(triggerId, status) {
    if (['pending', 'none'].indexOf(status) !== -1) {
      return { status: status };
    } else {
      var triggerBlock = triggerId && this.master.workspace.findBlock(triggerId);
      var triggerPage = triggerId && !triggerBlock ? this.master.workspace.mag.getWidgetPage(triggerId) : null;

      return triggerBlock
        ? { status: 'active' }
        : triggerPage && triggerPage.id !== this.master.workspace.page.id
        ? {
            status: 'onOtherPage',
            pageNumber: triggerPage.attributes.num,
          }
        : { status: 'none' };
    }
  },

  removeMissingTriggers: function(triggerIds) {
    return _.filter(
      triggerIds,
      function(triggerId) {
        var triggerBlockOnThisPage = triggerId && this.master.workspace.findBlock(triggerId);
        return triggerBlockOnThisPage || this.master.workspace.mag.getWidgetPage(triggerId);
      }.bind(this)
    );
  },

  updateCurrentStepParams: function(params) {
    var animation = this.someModel.get('animation') || {},
      tp = animation.type || 'none', // изначально нет вообще animation.type
      stepInd = animation.selected || 0,
      steps = animation.steps;

    // такое мжет быть когда удалили единственный оставшийся шаг или при reset
    // тут нормально просто выйти
    if (_.isEmpty(steps)) return;

    var $step = this.$steps_container.find('.step').eq(stepInd),
      step = steps[stepInd];

    // смотрим какие параметры данного шага должны быть видны, а какие спрятаны по тем или иным причинам
    if (params.visibility) {
      // длительность и задержка в секундах для всех типов анимаций кроме скрол
      $(' .param-wrapper.delay-block, .param-wrapper.duration-block', $step).toggleClass(
        'hide-unallowed',
        tp == 'scroll'
      );

      // задержка в px только для скрол анимаций для всех шагов кроме первого (для фикседов и для первого)
      $('.param-wrapper.delay_px-block', $step).toggleClass(
        'hide-unallowed',
        tp != 'scroll' || (stepInd == 0 && !this.isFixed)
      );

      // скорость только для скрол анимаций
      $('.param-wrapper.speed-block', $step).toggleClass('hide-unallowed', tp != 'scroll');

      // позиция начала анимации только для скрол анимаций и только для первого шага (а для фикседов и там не надо)
      $('.param-wrapper.start-block', $step).toggleClass(
        'hide-unallowed',
        tp != 'scroll' || stepInd > 0 || this.isFixed
      );

      // прячем поворот и сдвиг по х для фулвидха
      $('.param-wrapper.move-block', $step).toggleClass('hide-dx-input', this.isFullwidth);
      $('.param-wrapper.move-block', $step).toggleClass('hide-dy-input', this.isFullheight);
      $('.param-wrapper.rotate-block', $step).toggleClass('hide-unallowed', this.isFullwidth || this.isFullheight);

      // изначальные состояния видны только для начального шага
      $('.param-wrapper.opacity-block, .param-wrapper.rotate-block, .param-wrapper.scale-block', $step).toggleClass(
        'hide-from-input',
        stepInd > 0
      );

      // обновляем видимость параметров move, scale, rotate, opacity
      $('.param-wrapper.move-block', $step).toggleClass('hide-unused', !step.use_move);
      $('.param-wrapper.opacity-block', $step).toggleClass('hide-unused', !step.use_opacity);
      $('.param-wrapper.rotate-block', $step).toggleClass('hide-unused', !step.use_rotate);
      $('.param-wrapper.scale-block', $step).toggleClass('hide-unused', !step.use_scale);
    }

    // обновляем превью и пр. в блоке и попапе start
    if (params.start) {
      // позиция начала анимации только для скрол анимаций и только для первого шага (а для фикседов и там не надо)
      if (!$('.param-wrapper.start-block', $step).hasClass('hide-unallowed')) {
        var $item = $(
          '.param-wrapper.start-block .start-popup .start-popup-item[data-value="' + step.start_point + '"]',
          $step
        );

        // обновляем состояние переключалки вкл/выкл анимаций
        $item
          .addClass('curr')
          .siblings('.start-popup-item')
          .removeClass('curr');
        $('.param-wrapper.start-block .start-value', $step).text($item.text());

        $('.param-wrapper.start-block .start-popup', $step).toggleClass('has-offset', !!step.start_offset);

        var previewHeight = 63,
          startY = { top: 0, center: 0.5, bottom: 1 }[step.start_point] * previewHeight,
          offset = (-previewHeight * step.start_offset) / $(window).height(), // считаем визуально высоте превью как высоту окна, чтобы адекватное превью было
          endY = Math.max(Math.min(startY + offset, previewHeight), 0);

        $('.param-wrapper.start-block .start-preview .start-point', $step).css('top', startY);
        $('.param-wrapper.start-block .start-preview .start-dist', $step).css({
          top: Math.min(startY, endY),
          bottom: previewHeight - Math.max(startY, endY),
        });
        $('.param-wrapper.start-block .start-preview .start-offset', $step).css('top', endY);
      }
    }

    if (params.acceleration) {
      var $item = $(
        '.param-wrapper.acceleration-block .acceleration-popup .acceleration-popup-item[data-value="' +
          step.acceleration +
          '"]',
        $step
      );

      // обновляем состояние переключалки вкл/выкл анимаций
      $item
        .addClass('curr')
        .siblings('.acceleration-popup-item')
        .removeClass('curr');
      $('.param-wrapper.acceleration-block .acceleration-value', $step).text($item.text());
    }

    // обновляем данные в блоках delay, duration и delay_px
    _.each(['delay', 'duration', 'delay_px'], function(val) {
      // если попросили обновить этот параметра и если он виден
      if (params[val] && !$('.param-wrapper.' + val + '-block', $step).hasClass('hide-unallowed')) {
        var blockHeight = 76,
          bgHeight;

        if (val == 'delay_px') {
          bgHeight = Math.min((blockHeight * step[val]) / $(window).height(), blockHeight); // считаем визуально высоте превью как высоту окна, чтобы адекватное превью было
        } else {
          bgHeight = Math.min(step[val] * 20, blockHeight); // 100мс - 2px в столбике-превью
        }

        $('.param-wrapper.' + val + '-block .bg', $step).height(bgHeight);
      }
    });
  },

  // обновляем значения в инпутах, важно потому что у нас у инпутов autoSize
  // а он не будет работать если инпут спрятан, потому мы постоянно при изменениях вызываем пересчеты содержимого и размеров инпутов
  updateCurrentStepInputs: function(keys) {
    var animation = this.someModel.get('animation') || {},
      stepInd = animation.selected || 0,
      // если не передали ключей обновляем все инпуты
      keys = keys || [
        'start_offset',
        'delay',
        'duration',
        'delay_px',
        'speed',
        'dx',
        'dy',
        'opacity',
        'rotate',
        'scale',
      ];

    // такое мжет быть когда удалили единственный оставшийся шаг или при reset
    // тут нормально просто выйти
    if (_.isEmpty(animation.steps)) return;

    var $step = this.$steps_container.find('.step').eq(stepInd);

    _.each(keys, function(key) {
      var $input = $step.find('input[data-param="' + key + '"]'),
        cb = $input.data('cb'); // получаем колбек для изменения значения в инпуте через плагин RMNumericInput, его там сохранили при навешивании плагина, важно менять через него, чтобы плагин знал текущее значение (никаких тригеров на инпуте не высреливает при программной смене)
      cb && cb(animation.steps[stepInd][key], true);

      if (key == 'opacity' || key == 'rotate' || key == 'scale') {
        key = 'from_' + key;
        var $input = $step.find('input[data-param="' + key + '"]'),
          cb = $input.data('cb'); // получаем колбек для изменения значения в инпуте через плагин RMNumericInput, его там сохранили при навешивании плагина, важно менять через него, чтобы плагин знал текущее значение (никаких тригеров на инпуте не высреливает при программной смене)
        cb && cb(animation.steps[stepInd][key], true);
      }
    });
  },

  updateStepsPopup: function() {
    var animation = this.someModel.get('animation');
    var steps = animation.steps;
    var selectedIndex = animation.selected || 0;
    var $content = this.$step_selector.find('.js-step-popup-content');
    var template = templates['template-constructor-control-common_animation-step-popup-item'];

    var $steps = $(document.createDocumentFragment());
    _.each(steps, function(step, index) {
      var stepHtml = template({
        index: index,
        text: 'Step ' + (index + 1),
        isSelected: index === selectedIndex,
      });
      $steps.append(stepHtml);
    });
    $content.empty().append($steps);
    // Через свойство events у вью — не привязывается
    $content.off('click.popup-item');
    $content.on('click.popup-item', '.js-step-popup-item', this.onStepPopupItemClick);
  },

  // реакция на включение-выключение анимаций
  updateLoop: function() {
    // обновляем состояние переключалки вкл/выкл лупа
    this.$loop_types.find('[data-value]').removeClass('checked');
    var loopType = AnimationUtils.normalizeLoopValue(this.someModel.get('animation').loop);
    loopType && this.$loop_types.find('[data-value=' + loopType + ']').addClass('checked');
  },

  // отрендерить все шаги анимаций
  updateSteps: function() {
    // удаляем все шаги что были до этого
    this.destroyAllSteps();

    // заново создаем шаги из модели
    _.each(this.someModel.get('animation').steps, this.renderStep);
  },

  showSelectedStep: function(params) {
    params = params || {};
    var animation = this.someModel.get('animation'),
      total = animation.steps.length,
      stepInd = animation.selected || 0;

    this.$step_selector.toggleClass('one-step', total == 1);

    this.$step_selector.find('.js-step-caption').text(total == 1 ? 'Step 1' : 'Step ' + (stepInd + 1) + '/' + total);

    this.$step_selector.find('.js-prev-step').toggleClass('disabled', stepInd == 0);
    this.$step_selector.find('.js-next-step').toggleClass('disabled', stepInd == total - 1);

    // прячем все шаги кроме выделенного
    this.$steps_container
      .find('.step')
      .hide()
      .eq(stepInd)
      .show();

    this.updateCurrentStepParams({
      visibility: true,
      acceleration: true,
      start: true,
      delay: true,
      duration: true,
      delay_px: true,
    });

    this.updateCurrentStepInputs();

    this.updateStepsPopup();

    if (params.updateWorkspaceSelectedStep) {
      this.animationSteps.selectStep(stepInd);
    }
  },

  // групповая смена данных по анимациям среди всех выбранных виджетов
  setAnimationData: function(data, options) {
    options = options || {};

    // старые данные по анимациям берем из первого виджета, это не важно, они у всех одинаковые
    var oldAnimation = this.someModel.get('animation');

    // если данных по анимациям пока нет, тогда создаем дефолтный объект
    if (_.isEmpty(oldAnimation)) {
      oldAnimation = {
        loop: false,
        selected: 0,
        steps: [],
      };
    }

    // если мы передали {} значит мы хотим сбросить все анимации
    if (!_.isEmpty(data)) {
      data = _.extend(_.cloneWithObjects(oldAnimation), data);
    }

    var new_data = _.map(this.models, function(model) {
      return {
        _id: model.get('_id'),
        animation: data,
      };
    });

    if (!options.skipHistory && !options.redo && !options.undo && this.undoController) {
      // песли ундо стек пуст, тогда проставим изначальное состояние
      if (this.undoController.isEmpty()) {
        this.undoController.add(_.cloneWithObjects(this.someModel.get('animation')));
      }
      // записываем в ундо стек новое состояние
      // делаем это с большим дебонсом, чтобы избежать многократных вызовов когда инпуты меняем мышкой/клавой или сдвигаем блоки мышкой/клавой
      // также важно для добавления шага, когда он создается за два этепа, сначала сам шаг а потом добавляется эффект (чтоб была видна анимация его добавления)
      // нам важно отловить этот как один шаг
      // параметр historyMergeID говорит о том, что если предущий шаг в истории был записан с таким же ключом, то это значит что его можно заменить, а не добавлять новый
      // актуально когда инпуты меняем мышкой/клавой или сдвигаем блоки мышкой/клавой
      // также важно при смене шагов, простые хожедния по шагах мы все равно храним одним действием в истории
      // сначала пробовал вообще смену активного шага в историю не заносить, но работать неприятно когда что-то поменял в одном шаге
      // потом перешел в другой и то-то поменял в нем, то ундо сразу вернет обратно изменения в последнем выделенном шаге и сразу выделит другой шаг, т.е. не будет понятно что произошло
      this.undoController.add.__debounced(data, { historyMergeID: options.historyMergeID });
    }

    this.master.workspace.set_group(new_data);

    if (!options.skipPreviewUpdate && this.isPreviewMode) {
      this.updatePreviewMode();
    }

    this.hasChanges = true;
  },

  // вызывается при уничтожении/закрытии контрола - сохраняет все изменения по анимациям - если они есть
  save: function() {
    if (this.hasChanges) {
      // помечаем все блоки в выделении одним UUID для анимаций
      // чтобы во вьювере знать, что эти блоки анимируются одной анимацией в одном контейнере
      // то что мы его меняем при каждом сейве нормально, нам важно только чтобы он был одинаковым для текщих выделенных виджетов которым мы назначаем анимацию
      // это проще в реализации
      // например тут автоматом отрабатывает кейс когда назначили анимации группе виджетов, а потом выбрали несколько виджетов из этой группы и начали менять анимации у них
      // тогда у них проставятся новые UUID и у нас станет две группы анимаций
      // если же мы захотим потом их объединить, то при выделении их всех снова мы увидим предупреждение, что группы анимаций разные, давайте их объединим в одну
      if (!_.isEmpty(this.someModel.get('animation'))) {
        // если объект анимаций не пустой, может быть пустой после reset
        this.setAnimationData(
          {
            UUID: Utils.generateUUID(),
          },
          { skipHistory: true, skipPreviewUpdate: true }
        );
      }

      this.master.workspace.save_group(this.models, { skipHistory: true });

      delete this.hasChanges;
    }

    ControlResizableClass.prototype.save.apply(this, arguments);
  },

  onPanelScroll: function(data) {
    // this.$panel.find('.resizable-scroll-wrapper').toggleClass('scrolled', data.scroll_pos > 0);
  },

  // расширяем метод который срабатывает при открытии панели контрола
  select: function() {
    ControlResizableClass.prototype.select.apply(this, arguments);

    this.undoController = new UndoController({
      control: this,
      pid: this.models[0].get('pid'),
    });

    this.updateAll();

    // чтобы не было анимации трансформации панели при открытии (сбрасываем все транзишены)
    this.$panel.hide();
    this.$panel.outerHeight();
    this.$panel.show();
  },

  // расширяем метод который срабатывает при закрытии панели контрола
  deselect: function() {
    this.stopPreviewMode();

    this.destroyAnimationSteps();

    // При искуственном вызове fade, вызывается deselect, а popupManager не существует еще
    if (this.popupManager && this.popupManager.closeAllPopups) {
      this.popupManager.closeAllPopups();
    }

    // принудительно вызываем save для сохранения изменений
    // в прототипе deselect это уже не вызывается для данного контрола, потому что него нет одной модели (их там несколько)
    // т.е. это костыль такой
    this.save();

    this.undoController && this.undoController.destroy();
    delete this.undoController;

    ControlResizableClass.prototype.deselect.apply(this, arguments);
  },

  // переопределяем метод клика по иконке контрола
  onClick: function() {
    if (this.selected && this.popupManager.openedPopup && this.popupManager.closeAllPopups()) {
      return;
    }

    ControlClass.prototype.onClick.apply(this, arguments);
  },

  // переопределяем метод реакции на кнопку Esc
  onEscKey: function() {
    if (this.isPreviewMode) {
      this.stopPreviewMode();
      return;
    }

    if (this.selected && this.popupManager.openedPopup && this.popupManager.closeAllPopups()) {
      return;
    }

    ControlClass.prototype.onEscKey.apply(this, arguments);
  },

  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();

    // Если среди выделенных блоков есть блоки, для которых анимация разрешена, покажем контрол
    // (Если среди них есть блоки, для которых анимация запрещена, будем показывать сообщение об этом)
    return _.find(blocks, function(block) {
      return AnimationUtils.canHaveAnimation(block);
    });
  },

  // переопределяем функцию которая решает поглощает контрол событие или нет (обычно это событие deselect воркспейса)
  canControlBeClosed: function() {
    if (this.popupManager.openedPopup && this.popupManager.closeAllPopups()) {
      return true;
      // Если ждём клика по триггеру, не закрываем панель по внешнему клику (котороый, вероятно, на триггер)
    } else if (this.waitingForTriggerClick) {
      return true;
    } else {
      return ControlClass.prototype.canControlBeClosed.apply(this, arguments);
    }
  },

  destroy: function() {
    // поскольку у нас нет deselect при destroy, и править я это не буду чтобы не сломать той логики которая вокруг этого в контролах уже выставлена
    // просто вызову deselect конкретно для данного контрола, хотя по идее надо бы для всех
    if (this.selected) {
      this.deselect();
    }
    // при удалении триггеров запрещаем пересоздавать инстанс AnimationSteps
    this.willRestoreAnimationMode = false;
    this.removeTriggerButtons();

    ControlResizableClass.prototype.destroy.apply(this, arguments);
  },
});

// вьюха которая управляет Undo-Redo
// взял за пример как сделано в текстовом виджете, а там Backbone.Model не помню уже из-за чего, скорее всего просто по ошибке, ну да пофиг
var UndoController = Backbone.Model.extend({
  initialize: function(params) {
    _.bindAll(this);

    this.control = params.control;
    this.pid = params.pid;

    this.undoIndex = -1;
    this.undoStack = [];

    this.add.__debounced = _.debounce(this.add, 300);

    // переключаем глобальный обработчик UNDO-REDO на себя
    this.historyTriggers = History.setCustomHandlers({
      undo: this.undo,
      redo: this.redo,
      getLength: this.getLength,
    });
  },

  isEmpty: function() {
    return this.undoIndex == -1;
  },

  add: function(animation, options) {
    options = options || {};

    // если стек пуст то это первое добавление данных (специальное добавление изначального состояния)
    // или если предыдущее значение не равно текущему
    if (this.undoIndex == -1 || !_.isEqual(this.undoStack[this.undoIndex].animation, animation)) {
      // групповой сет/сохранение виджетов не умеет работать с сохранением undefined
      // а такое может быть если изначально создать анимации в виджете, а потом откатить все до начала
      // у нас пойдет групповой сет c animation: undefined который в принципе не накосячит (ведь у нас и до этого не было этого свойства)
      // но лучше подстраховаться, потому что повторяю, чтобы сбросить объект с помошью группового сета/сейва надо передать ему {}, а не undefined
      if (_.isEmpty(animation)) {
        animation = {};
      }

      // если передан historyMergeID, проверяем не был ли записано предыдущее состояние истории с этим же ключом изменений
      // если да, тогда можем просто заменить этот шаг на новые значения, не увеличивая кол-во шагов в истории
      // обязательно проверяем на && options.historyMergeID, ведь undefined == undefined
      if (
        !(
          this.undoIndex >= 0 &&
          options.historyMergeID &&
          options.historyMergeID == this.undoStack[this.undoIndex].historyMergeID
        )
      ) {
        this.undoIndex++;
      }

      this.undoStack[this.undoIndex] = {
        animation: animation,
        historyMergeID: options.historyMergeID,
      };

      this.undoStack.length = this.undoIndex + 1; // очищаем всю прошлую будущую историю

      this.historyTriggers.triggerChange(this.pid);
    }
  },

  undo: function(e) {
    if (this.undoIndex > 0) {
      this.undoIndex--;

      this.control.setAnimationData(this.undoStack[this.undoIndex].animation, { undo: true });

      this.control.updateAll({ undo: true });

      this.historyTriggers.triggerChange(this.pid);

      this.historyTriggers.triggerUndo(this.pid);
    }
  },

  redo: function(e) {
    if (this.undoIndex < this.undoStack.length - 1) {
      this.undoIndex++;

      this.control.setAnimationData(this.undoStack[this.undoIndex].animation, { redo: true });

      this.control.updateAll({ redo: true });

      this.historyTriggers.triggerChange(this.pid);

      this.historyTriggers.triggerRedo(this.pid);
    }
  },

  getLength: function(pageId) {
    return {
      undo: this.undoIndex,
      redo: this.undoStack.length - 1 - this.undoIndex,
      page: pageId,
    };
  },

  // переопределяем метод модели destory на свой, не помню почему для UndoController использовал бекбоновскую модель
  // просто посмотрел как сделал в текстовом виджете и тут сделал похоже
  destroy: function() {
    // переключаем глобальный обработчик UNDO-REDO обратно на страницу
    History.removeCustomHandlers();
  },
});

function PopupManager(params) {
  this.popups = {};

  this.openedPopup = '';

  _.bindAll(this);

  this.closeAllPopups.__debounced = _.debounce(this.closeAllPopups, 50);
}

PopupManager.prototype.addPopup = function(data) {
  data.onClick = _.bind(function(e) {
    // если клик из открытой панели
    if (e && $(e.target).closest(data.popupSelector).length > 0) return;

    this.onClick(data.name, e.currentTarget);
  }, this);

  data.$parent.on('click', data.selector, data.onClick);

  data.$parent.on('click', this.closeAllPopups.__debounced);

  this.popups[data.name] = data;
};

PopupManager.prototype.onClick = function(name, target) {
  // если этот попап уже открыт - закрываем его
  if (this.openedPopup == name) {
    this.closePopup(name);
  } else {
    this.openPopup(name, target);
  }
};

PopupManager.prototype.closePopup = function(name) {
  // если просим закрыть попап который уже закрыт
  if (this.openedPopup != name) return;

  // или которого вообще нет
  if (!this.popups[name]) return;

  this.openedPopup = '';

  $(this.popups[name].selector, this.popups[name].$parent).removeClass('opened');

  this.popups[name].cbClose && this.popups[name].cbClose();
};

PopupManager.prototype.openPopup = function(name, target) {
  // если просим открыть попап который уже открыт
  if (this.openedPopup == name) return;

  // или которого вообще нет
  if (!this.popups[name]) return;

  // если сейчас уже открыт другой попап, закроем его
  if (this.openedPopup) {
    this.closePopup(this.openedPopup);
  }

  this.openedPopup = name;

  // используем таргет по которому кликнули, а не по селектору
  // ведь у нас моет быть много попапов одного типа, не стоит открывать их все при клике по одному
  // например попапы акселерации в шагах или попапы добавления шага в шагах анимаций на воркспейсе
  $(target).addClass('opened');

  this.popups[name].cbOpen && this.popups[name].cbOpen($(target));
};

PopupManager.prototype.closeAllPopups = function(e) {
  if (!this.openedPopup) return true;

  // если клик не из открытой панели
  if (e && $(e.target).closest(this.popups[this.openedPopup].selector).length > 0) return false;

  this.closePopup(this.openedPopup);

  return true;
};

PopupManager.prototype.destroy = function() {
  for (var i in this.popups) {
    this.popups[i].$parent.off('click', this.popups[i].selector, this.popups[i].onClick);
    this.popups[i].$parent.off('click', this.closeAllPopups.__debounced);
  }

  delete this.popups;
};

export default CommonAnimationClass;
