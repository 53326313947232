/**
 * Конструктор для виджета текста
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/text_bius.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const TextBius = ControlClass.extend({
  name: 'text_bius', // должно совпадать с классом вьюхи

  className: 'control text_bius',

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_bius'];

    this.initControl(params);

    _.bindAll(this);
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-text_bius');
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.$panel.on('click', '.all-caps', this.allСaps);
    this.$panel.on('click', '.small-caps', this.smallСaps);
    this.$panel.on('click', '.sup', this.sup);
    this.$panel.on('click', '.sub', this.sub);
    this.$panel.on('click', '.bold', this.bold);
    this.$panel.on('click', '.italic', this.italic);
    this.$panel.on('click', '.underline', this.underline);
    this.$panel.on('click', '.strike', this.strike);
    // случаем событие изменения стиля выделения, генерируется редактором
    this.blocks && this.blocks[0].on('selection_styles_changed', this.selectionStylesChanged);

    // первоначально проставляем состояние контрола в зависимости от текущего стиля выделения в редакторе
    this.blocks && this.selectionStylesChanged(this.blocks[0].cur_selection_styles);
  },

  unBindLogic: function() {
    this.$panel.off('click', '.all-caps', this.allСaps);
    this.$panel.off('click', '.small-caps', this.smallСaps);
    this.$panel.off('click', '.sup', this.sup);
    this.$panel.off('click', '.sub', this.sub);
    this.$panel.off('click', '.bold', this.bold);
    this.$panel.off('click', '.italic', this.italic);
    this.$panel.off('click', '.underline', this.underline);
    this.$panel.off('click', '.strike', this.strike);
    this.blocks && this.blocks[0].off('selection_styles_changed', this.selectionStylesChanged);
  },

  selectionStylesChanged: function(styles) {
    if (this.blocks[0].initiatorControl == this.name) return;
    this.updateState(styles);
  },

  updateState: function(param) {
    if (param['text-transform']) {
      this.$panel.find('.all-caps').toggleClass('curr', param['text-transform'] == 'uppercase');
      this.$panel.find('.small-caps').toggleClass('curr', param['text-transform'] == 'lowercase');
    }
    if (param['vertical-align']) {
      this.$panel.find('.sup').toggleClass('curr', param['vertical-align'] == 'super');
      this.$panel.find('.sub').toggleClass('curr', param['vertical-align'] == 'sub');
    }
    if (param['font-weight']) {
      this.$panel.find('.bold').toggleClass('curr', param['font-weight'] == '700');
    }
    if (param['font-style']) {
      this.$panel.find('.italic').toggleClass('curr', param['font-style'] == 'italic');
    }
    if (param['text-decoration']) {
      this.$panel.find('.underline').toggleClass('curr', param['text-decoration'] == 'underline');
      this.$panel.find('.strike').toggleClass('curr', param['text-decoration'] == 'line-through');
    }
  },

  allСaps: function(e) {
    var param = { 'text-transform': 'uppercase' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'text-transform': 'none' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  smallСaps: function(e) {
    var param = { 'text-transform': 'lowercase' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'text-transform': 'none' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  sup: function(e) {
    var param = { 'vertical-align': 'super' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'vertical-align': 'baseline' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  sub: function(e) {
    var param = { 'vertical-align': 'sub' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'vertical-align': 'baseline' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  bold: function(e) {
    var param = { 'font-weight': '700' }; // '700' именно в кавычках!
    if ($(e.currentTarget).hasClass('curr')) param = { 'font-weight': '400' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  italic: function(e) {
    var param = { 'font-style': 'italic' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'font-style': 'normal' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  underline: function(e) {
    var param = { 'text-decoration': 'underline' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'text-decoration': 'none' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },

  strike: function(e) {
    var param = { 'text-decoration': 'line-through' };
    if ($(e.currentTarget).hasClass('curr')) param = { 'text-decoration': 'none' };
    this.updateState(param);
    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', param);
    }
  },
});

export default TextBius;
