/**
 * контрол настроек для точки хотспот виджета.
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlResizableClass from '../control-resizable';
import ControlClass from '../control';
import { Utils } from '../../common/utils';
import Colorbox from '../helpers/colorbox';
import templates from '../../../templates/constructor/controls/hotspot_pin_settings.tpl';

const HotspotPinSettings = ControlResizableClass.extend({
  name: 'hotspot_pin_settings', // должно совпадать с классом вьюхи.

  className: 'control hotspot_pin_settings',

  MIN_PANEL_HEIGHT: 274,

  MAX_PANEL_HEIGHT: 640,

  // чтобы не прижимался вплотную
  // к разделительной линии между поиском и иконками.
  SCROLL_GAP_START: 4,
  SCROLL_GAP_END: 4,

  API_URL: '/api/authservice/noun?uri=',

  LIMIT_TO_PUBLIC_DOMAIN: false, // true для тестирования на бесплатном аккаунте
  DEFAULT_COLLECTION_ID: 1335, // Noun ID коллекции, которую нужно показывать по-умолчанию

  saveOnDestroy: true,
  saveOnDeselect: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-hotspot-pin-settings'];
    this.templateItem = templates['template-constructor-control-hotspot-pin-settings-icon-item'];

    params = params || {};

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;
  },

  render: function() {
    ControlResizableClass.prototype.render.apply(this, arguments);

    this.$result = this.$('.panel .resizable-content');

    // если есть кэшированные результаты поиска, восстанавливаем их.
    if (window.prevSVGSearch) {
      this.nextPage = window.prevSVGSearch.nextPage;
      this.$('.search').val(window.prevSVGSearch.phrase);
      this.search_string = window.prevSVGSearch.phrase;
      this.drawSearchResults(window.prevSVGSearch);
      this.$('.loupe').addClass('clear');
    } else {
      this.getDefaultIconsCollection();
    }

    // изначально применяем визуальное состояние Панели.
    this.apply_type_related_visual_state();
    this.apply_edit_params_visual_state();
  },

  bindLogic: function() {
    this.bind_model_events();

    this.$el.on('click', '.resizable-content .item', this.on_icon_click);
    this.$('.search').on('keyup', this.onSearchKeyUp);
    this.$('.loupe:not(.preloading)').on('click', this.onLoupeClick);

    // кэшируем поиск часто используемых элементов.
    this.$rectangleIcon = this.$panel.find('.rectangle-icon');
    this.$circleIcon = this.$panel.find('.circle-icon');
    this.$border_edit_param = this.$panel.find('[data-field="borders"]');
    this.$borderWidthInput = $(this.$border_edit_param.children('input'));
    this.$bg_color_edit_param = this.$panel.find('[data-field="bg_color"]');
    this.$colorbox_container = this.$panel.find('.colorbox_container');

    this.$panel.find('.edit-item[data-field="tip_show_on"] .switcher').on('click', this.toggleShowMode);

    // при клике в контейнер панельки,
    // скрываем все выпадалки (колорбокс).
    this.$panel.on('click', '.edit-wrapper', this.closeAllEditPopups);

    // инициализация, рендеринг колорбокса.
    // общий колорбокс будет использоваться для изменения параметров Кнопки, связанных с выбором цвета.
    this.$panel.on('click', '.color-click-area', this.onColorboxShowClick);
    this.colorbox = new Colorbox({ $parent: this.$colorbox_container, type: 'small' });
    this.colorbox.on('colorchange', this.on_color_or_opacity_changed);
    this.colorbox.on('opacitychange', this.on_color_or_opacity_changed);

    // Border. инициализация плагина для инпута.
    this.borderWidthEditParamInput = this.$borderWidthInput
      .RMNumericInput({
        min: 0,
        max: 999,
        mouseSpeed: 4,
        onChange: this.on_edit_input_changed,
      })
      .data('changeValue');
  },

  unBindLogic: function() {
    this.$el.off('click', '.resizable-content .item', this.on_icon_click);
    this.$('.search').off('keyup', this.onSearchKeyUp);
    this.$('.loupe:not(.preloading)').off('click', this.onLoupeClick);

    // удаляем всех слушателей изменений модели,
    // созданные этой вьюхой.
    this.model && this.model.off(null, null, this);
  },

  toggleShowMode: function() {
    var val = this.$panel.find('.edit-item[data-field="tip_show_on"] .switcher').attr('data-active');
    val = val == 'click' ? 'hover' : 'click';

    var group_changeset = [];

    this.block.hotspots_group_collection.models.forEach(function(model) {
      group_changeset.push({
        _id: model.get('_id'),
        tip_show_on: val,
      });
    });

    this.block.workspace.set_group(group_changeset);
  },

  getEditValue: function(styleAttribute) {
    return this.model.get(styleAttribute);
  },

  // закрыть все выпадалки.
  closeAllEditPopups: function(e) {
    if (e) {
      // ничего не делаем, если кликнули в:

      // любой параметр цвета.
      if ($(e.target).closest('.color-click-area').length > 0) return;
    }

    // скрываем Колорбокс.
    if (this.colorbox_visible) this.closeColorBox();
  },

  // вся перерисовка состояния редактируемых параметров
  // в панели происходит исключительно
  // по событияем изменения модели хотспота, чтобы корректно отрабатывал Undo/Redo, который
  // пачкой откатывает все model.set, сделанные к моменту последнего model.save.
  bind_model_events: function() {
    this.listenTo(this.model, 'change:pin_type', this.apply_type_related_visual_state);

    this.listenTo(
      this.model,
      'change:bg_color change:bg_opacity change:color change:opacity change:borders change:tip_show_on',
      this.apply_edit_params_visual_state
    );
  },

  on_edit_input_changed: function($input, new_num) {
    var group_changeset = [];

    this.block.hotspots_group_collection.models.forEach(function(model) {
      group_changeset.push({
        _id: model.get('_id'),
        borders: new_num,
      });
    });

    this.block.workspace.set_group(group_changeset);
  },

  // при изменении цвета-прозрачности через колорбокс.
  on_color_or_opacity_changed: function(r, g, b, a) {
    var changeset = {};
    changeset[this.colorbox_for_field] = this.colorbox.rgb2hex([r, g, b]);
    changeset[this.colorbox_for_field_dop] = a;

    var group_changeset = [];

    this.block.hotspots_group_collection.models.forEach(function(model) {
      group_changeset.push(
        _.extend(
          {
            _id: model.get('_id'),
          },
          changeset
        )
      );
    });

    this.block.workspace.set_group(group_changeset);

    this.needRasterize = true;
  },

  // расширяем метод клика по иконке контрола.
  onClick: function() {
    if (this.selected) {
      if (this.colorbox_visible) {
        this.closeAllEditPopups();
        return;
      }
    }

    ControlResizableClass.prototype.onClick.apply(this, arguments);
  },

  apply_edit_params_visual_state: function() {
    this.$panel
      .find('.edit-item[data-field="tip_show_on"] .switcher')
      .attr('data-active', this.getEditValue('tip_show_on'));

    this.apply_type_related_visual_state();

    // Colors
    // Color
    this.$panel
      .find('.edit-item[data-field="bg_color"] .color-circle')
      .css('background-color', Utils.getRGBA(this.getEditValue('bg_color'), this.getEditValue('bg_opacity')));

    // Border Color
    this.$panel
      .find('.edit-item[data-field="color"] .color-circle')
      .css('background-color', Utils.getRGBA(this.getEditValue('color'), this.getEditValue('opacity')));

    // Numerics
    // Border
    this.borderWidthEditParamInput(this.getEditValue('borders'), true);
  },

  apply_type_related_visual_state: function() {
    var pin_type = this.model.get('pin_type');

    this.$rectangleIcon.add(this.$circleIcon).removeClass('selected');

    if (pin_type === 'ellipse') {
      this.$circleIcon.addClass('selected');

      this.$bg_color_edit_param.removeClass('hidden');

      this.$border_edit_param.removeClass('hidden');

      this.$('.icon-item').removeClass('selected');
    } else if (pin_type === 'icon') {
      this.$bg_color_edit_param.removeClass('hidden');

      this.$border_edit_param.addClass('hidden');
    } else if (pin_type === 'rectangle') {
      this.$rectangleIcon.addClass('selected');

      this.$bg_color_edit_param.addClass('hidden');

      this.$border_edit_param.addClass('hidden');

      this.$('.icon-item').removeClass('selected');
    }

    var $params_wrapper = this.$('.params-wrapper');

    var new_params_wrapper_height = 0;

    $params_wrapper
      .children('.edit-item')
      .filter(':not(.hidden)')
      .each(function() {
        new_params_wrapper_height += $(this).height();
      });

    $params_wrapper.height(new_params_wrapper_height);

    this.$('.resizable-scroll-wrapper').height('calc(100% - ' + new_params_wrapper_height + 'px)');

    this.__scrollRecalc_debounced();
  },

  onSearchKeyUp: function(e) {
    if (e.keyCode !== $.keycodes.enter) {
      return;
    }

    this.doSearch();
  },

  onLoupeClick: function(e) {
    if (this.$('.loupe').hasClass('clear')) {
      this.clearSearch();
    } else {
      this.doSearch();
    }
  },

  doSearch: function() {
    var string = this.$('.search')
      .val()
      .trim();
    if (!string || this.search_string == string || this.requestInProgress) {
      return;
    }

    this.search_string = string;
    this.$result.find('.icon-item').remove();
    delete window.prevSVGSearch;
    this.$('.loupe').addClass('clear');
    // this.__scrollRecalc_debounced();

    this.nextPage = 1;

    this.getDataPage(this.nextPage);
  },

  clearSearch: function() {
    this.$('.loupe').removeClass('clear');
    delete window.prevSVGSearch;
    this.$('.search').val('');
    this.search_string = '';
    this.$result.find('.icon-item').remove();
    this.getDefaultIconsCollection();
  },

  getDataPage: function(page) {
    var uri;

    if (!this.search_string || this.requestInProgress) {
      return;
    }

    uri = '/icons/' + this.search_string + '?page=' + page;
    if (this.LIMIT_TO_PUBLIC_DOMAIN) {
      uri += '&limit_to_public_domain=1';
    }

    this.requestIcons(
      uri,
      function(err, data) {
        if (err) {
          delete this.nextPage; // Удаляем номер следующей страницы. Нечего больше грузить
          return;
        }

        // Кэшируем результаты предыдущего поиска,
        // включая иконки, последнюю пэйджинированную страницу и строку поиска
        // FIXME: переделать на кэш внутри компонента, не надо патчить window
        window.prevSVGSearch = window.prevSVGSearch || { icons: [] };
        window.prevSVGSearch.icons = window.prevSVGSearch.icons.concat(data.icons);
        window.prevSVGSearch.phrase = this.search_string;
        window.prevSVGSearch.nextPage = this.nextPage;

        this.drawSearchResults(data);
      }.bind(this)
    );
  },

  requestIcons: function(uri, callback) {
    this.searchXhr && this.searchXhr.abort();
    this.searchInProgress = true;
    this.showSearchPreloader();

    this.searchXhr = $.ajax({
      method: 'GET',
      url: this.API_URL + encodeURIComponent(uri),
      dataType: 'json',
      success: function(data) {
        callback(null, data);
      },
      error: function(xhr) {
        callback(xhr);
      },
      complete: function() {
        this.searchInProgress = false;
        this.hideSearchPreloader();
      }.bind(this),
      context: this,
    });
  },

  getDefaultIconsCollection: function() {
    this.drawSearchResults(this.defaultIconsCollection);
  },

  drawSearchResults: function(data) {
    if (!(_.isArray(data.icons) && data.icons.length)) {
      delete this.nextPage; // Удаляем номер следующей страницы. Нечего больше грузить
      return;
    }

    _.each(
      data.icons,
      function(icon) {
        this.$result.append(this.templateItem({ icon: icon }));
      }.bind(this)
    );

    this.__scrollRecalc_debounced();
  },

  // расширяем метод, который срабатывает при открытии панели Контрола.
  select: function() {
    ControlResizableClass.prototype.select.apply(this, arguments);

    _.delay(
      function() {
        this.$('.search')
          .focus()
          .select();
      }.bind(this),
      400
    );
  },

  save: function() {
    this.block.workspace.save_group(this.block.hotspots_group_collection.models);

    // растеризуем иконку и сохраняем для всех хотспотов в группе
    if (this.needRasterize) {
      this.block.rasterize_icon_SVG();
      delete this.needRasterize;
    }

    ControlResizableClass.prototype.save.apply(this, arguments);
  },

  on_icon_click: function(e) {
    if (this.$('.resizable-content').hasClass('preloading')) {
      return;
    }

    this.$('.resizable-content .item').removeClass('selected');

    var $item = $(e.currentTarget),
      pin_type,
      changeset,
      group_changeset = [];

    if ($item.hasClass('selected')) return;

    if ($item.hasClass('icon-item')) pin_type = 'icon';
    if ($item.hasClass('circle-icon')) pin_type = 'ellipse';
    if ($item.hasClass('rectangle-icon')) pin_type = 'rectangle';

    if (pin_type == 'ellipse' || pin_type == 'rectangle') {
      $item.addClass('selected');

      this.block.icon_XHR && this.block.icon_XHR.abort();

      changeset = {
        pin_type: pin_type,
        noun_url: null,
        noun_id: null,
        rm_id: null,
      };

      this.block.hotspots_group_collection.models.forEach(function(model) {
        var dop_changeset = {};

        if (pin_type == 'ellipse') {
          var x = model.get('x'),
            w = model.get('w'),
            h = model.get('h'),
            shift = Math.floor((w - h) / 2);

          (dop_changeset.w = h), (dop_changeset.x = x + shift); // оставляем центр виджета на месте.
        }

        group_changeset.push(
          _.extend(
            {
              _id: model.get('_id'),
            },
            changeset,
            dop_changeset
          )
        );
      });

      this.block.workspace.set_group(group_changeset);
    }

    if (pin_type == 'icon') {
      var $icon = $item.find('.noun-icon'),
        noun_id = $icon.attr('data-id'), // урл иконки Noun.
        rm_id = $icon.attr('data-rm_id'), // урл нашей иконки.
        icon_url = $icon.attr('data-url');

      this.hideIconPreloader();
      this.showIconPreloader($item);

      this.block.get_icon_SVG(
        noun_id || rm_id,
        icon_url,
        function(err, data) {
          this.hideIconPreloader();

          if (err) return;

          $item.addClass('selected');

          // при задании одного типа айдишника надо обязательно сбрасывать другой,
          // т.к. наличие или отсутствие соответствующего
          // айдишника и является признаком принадлежности иконки - Noun или наша.
          changeset = {
            pin_type: 'icon',
            noun_url: icon_url,
            noun_id: noun_id || null,
            rm_id: rm_id || null,
          };

          // важно для render_pin_shape который внутри вызовет формирование generateShapeSVG
          // а тот в свою очередь посмотрит на pin_type
          // был баг что при первоначальной установке иконки свг генерировался для дефолтного эллипса
          // поскольку pin_type был ellipse https://trello.com/c/OfwOx6yQ/40--
          this.block.render_pin_shape({ pin_type: 'icon' }); // нужно для adjust_box_to_icon

          var newBoxSizePos = this.block.adjust_box_to_icon(),
            shiftX = newBoxSizePos.x - this.model.get('x'),
            shiftY = newBoxSizePos.y - this.model.get('y');

          this.block.hotspots_group_collection.models.forEach(
            function(model) {
              group_changeset.push(
                _.extend(
                  {
                    _id: model.get('_id'),
                  },
                  changeset,
                  newBoxSizePos,
                  {
                    x: model.get('x') + shiftX,
                    y: model.get('y') + shiftY,
                  }
                )
              );
            }.bind(this)
          );

          this.block.workspace.set_group(group_changeset);

          this.needRasterize = true;
        }.bind(this)
      );
    }
  },

  // при клике по полю с цветом какого-либо параметра панели — показ\скрытие Колорбокса.
  onColorboxShowClick: function(e) {
    var $item = $(e.currentTarget),
      field = $item.attr('data-field'),
      field_dop = $item.attr('data-field-dop'),
      fadeSpeed = 200;

    if (this.colorbox_visible) {
      // смотрим кликнули по полю для текущего Колорбокса или по другому.
      if (field == this.colorbox_for_field) {
        this.closeColorBox();
        return;
      } else {
        this.closeColorBox(true);
        fadeSpeed = 0;
      }
    }

    // скрываем все впадалки, показываем Колорбокс.
    this.closeAllEditPopups();
    this.colorbox_visible = true;

    // позиционируем колорбокс органично расположению параметра.
    if (field === 'bg_color' || field === 'color') {
      // для бг-цвета и цвета бордера
      // колорбокс показываем выше параметра, чтобы в видимую область влез.
      // эти параметры близко к нижнему краю экрана.

      if (field === 'color') {
        // т.к. параметры цвета бордера находятся внутри параметра ширины бордера,
        // то отступ контейнера цвета сверху надо считывать от контейнера ширины.
        this.$colorbox_container.css({
          top:
            $item.parent('.edit-item').position().top -
            this.$colorbox_container.height() +
            this.$panel.find('.resizable-content-wrapper').height() +
            this.$panel.find('.common-wrapper').position().top +
            'px',
        });
      } else {
        this.$colorbox_container.css({
          top:
            $item.position().top -
            this.$colorbox_container.height() +
            this.$panel.find('.resizable-content-wrapper').height() +
            this.$panel.find('.common-wrapper').position().top +
            'px',
        });
      }
    }

    this.$colorbox_container.fadeIn(fadeSpeed);

    // сохраняем для on_color_or_opacity_changed.
    this.colorbox_for_field = field;
    this.colorbox_for_field_dop = field_dop;

    // устанавливаем параметры в Колорбоксе.
    this.colorbox.setOpacity(this.getEditValue(field_dop));
    this.colorbox.setColor(this.getEditValue(field));
  },

  // закрывает колорбокс.
  closeColorBox: function(noAnimation) {
    this.colorbox_visible = false;

    this.$colorbox_container.fadeOut(noAnimation ? 0 : 200);
  },

  onPanelScroll: function(scroll_data) {
    if (!this.selected) {
      return;
    }

    // если до конца прокрутки осталось менее 32 пикселей и есть еще что грузить
    if (
      scroll_data.content_size - scroll_data.container_size - scroll_data.scroll_pos < 32 &&
      this.nextPage &&
      !this.searchInProgress
    ) {
      this.getDataPage(++this.nextPage);
    }
  },

  abortRequests: function() {
    this.searchXhr && this.searchXhr.abort();
    this.searchInProgress = false;
  },

  showSearchPreloader: function() {
    this.$('.loupe').addClass('preloading');
  },

  hideSearchPreloader: function() {
    this.$('.loupe').removeClass('preloading');
  },

  showIconPreloader: function($icon) {
    $icon.append($('<div class="rmpreloader"><div class="arc"></div></div>'));

    this.$('.resizable-content')
      .add($icon)
      .addClass('preloading');
  },

  hideIconPreloader: function($icon) {
    this.$('.resizable-content')
      .find('.rmpreloader')
      .remove();
    this.$('.resizable-content')
      .add(this.$('.icon-item'))
      .removeClass('preloading');
  },

  /**
   * расширяем функцию которая решает поглощает контрол событие
   * или нет (обычно это событие deselect воркспейса).
   */
  canControlBeClosed: function() {
    // если у нас открыт Контрол (показана панелька),
    // а в ней открыли Колорбокс для какого-то параметра,
    // то при клике куда-либо снаружи панельки — закроется Колорбокс,
    // а панель останется еще открытой.
    if (this.colorbox_visible) {
      this.closeAllEditPopups();
      return true;
    }

    return ControlClass.prototype.canControlBeClosed.apply(this, arguments);
  },

  deselect: function() {
    this.closeAllEditPopups();

    ControlClass.prototype.deselect.apply(this, arguments);
  },

  destroy: function() {
    this.abortRequests();

    ControlResizableClass.prototype.destroy.apply(this, arguments);
  },
});

HotspotPinSettings.prototype.defaultIconsCollection = {
  icons: [
    {
      rm_id: 'rm44',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/44-oval.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/44-oval.svg',
    },
    {
      rm_id: 'rm45',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/45-plus.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/45-plus.svg',
    },
    {
      rm_id: 'rm46',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/46-expl.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/46-expl.svg',
    },
    {
      rm_id: 'rm47',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/47-que.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/47-que.svg',
    },
    {
      rm_id: 'rm48',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/48-photo1.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/48-photo1.svg',
    },
    {
      rm_id: 'rm49',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/49-photo2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/49-photo2.svg',
    },
    {
      rm_id: 'rm50',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/50-info.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/50-info.svg',
    },
    {
      rm_id: 'rm51',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/51-info2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/51-info2.svg',
    },
    {
      rm_id: 'rm0',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/00-star.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/00-star.svg',
    },
    {
      rm_id: 'rm1',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/01-bolt.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/01-bolt.svg',
    },
    {
      rm_id: 'rm2',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/02-bubble.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/02-bubble.svg',
    },
    {
      rm_id: 'rm3',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/03-camera.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/03-camera.svg',
    },
    {
      rm_id: 'rm4',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/04-cloud.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/04-cloud.svg',
    },
    {
      rm_id: 'rm5',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/05-download1.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/05-download1.svg',
    },
    {
      rm_id: 'rm6',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/06-download2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/06-download2.svg',
    },
    {
      rm_id: 'rm7',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/07-globe2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/07-globe2.svg',
    },
    {
      rm_id: 'rm8',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/08-globe.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/08-globe.svg',
    },
    {
      rm_id: 'rm9',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/09-pin.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/09-pin.svg',
    },
    {
      rm_id: 'rm10',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/10-share1.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/10-share1.svg',
    },
    {
      rm_id: 'rm11',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/11-share2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/11-share2.svg',
    },
    {
      rm_id: 'rm12',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/12-send.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/12-send.svg',
    },
    {
      rm_id: 'rm13',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/13-checkmark.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/13-checkmark.svg',
    },
    {
      rm_id: 'rm14',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/14-x.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/14-x.svg',
    },
    {
      rm_id: 'rm15',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/15-minus.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/15-minus.svg',
    },
    {
      rm_id: 'rm16',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/16-plus.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/16-plus.svg',
    },
    {
      rm_id: 'rm17',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/17-mail1.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/17-mail1.svg',
    },
    {
      rm_id: 'rm18',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/18-mail2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/18-mail2.svg',
    },
    {
      rm_id: 'rm19',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/19-user.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/19-user.svg',
    },
    {
      rm_id: 'rm20',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/20-clock.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/20-clock.svg',
    },
    {
      rm_id: 'rm21',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/21-embed.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/21-embed.svg',
    },
    {
      rm_id: 'rm22',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/22-flag.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/22-flag.svg',
    },
    {
      rm_id: 'rm23',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/23-heart.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/23-heart.svg',
    },
    {
      rm_id: 'rm24',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/24-itunes.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/24-itunes.svg',
    },
    {
      rm_id: 'rm25',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/25-like.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/25-like.svg',
    },
    {
      rm_id: 'rm26',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/26-music.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/26-music.svg',
    },
    {
      rm_id: 'rm27',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/27-play.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/27-play.svg',
    },
    {
      rm_id: 'rm28',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/28-video.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/28-video.svg',
    },
    {
      rm_id: 'rm29',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/29-facebook.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/29-facebook.svg',
    },
    {
      rm_id: 'rm30',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/30-twitter.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/30-twitter.svg',
    },
    {
      rm_id: 'rm31',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/31-linkedin.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/31-linkedin.svg',
    },
    {
      rm_id: 'rm32',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/32-pinterest.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/32-pinterest.svg',
    },
    {
      rm_id: 'rm33',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/33-instagram.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/33-instagram.svg',
    },
    {
      rm_id: 'rm34',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/34-googleplus.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/34-googleplus.svg',
    },
    {
      rm_id: 'rm35',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/35-tumblr.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/35-tumblr.svg',
    },
    {
      rm_id: 'rm36',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/36-youtube.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/36-youtube.svg',
    },
    {
      rm_id: 'rm37',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/37-vimeo.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/37-vimeo.svg',
    },
    {
      rm_id: 'rm38',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/38-flickr.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/38-flickr.svg',
    },
    {
      rm_id: 'rm39',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/39-blogger.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/39-blogger.svg',
    },
    {
      rm_id: 'rm40',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/40-dribbble.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/40-dribbble.svg',
    },
    {
      rm_id: 'rm41',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/41-behance.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/41-behance.svg',
    },
    {
      rm_id: 'rm42',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/42-rss.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/42-rss.svg',
    },
    {
      rm_id: 'rm43',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/43-at.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/43-at.svg',
    },
  ],
};

export default HotspotPinSettings;
