/**
 * Конструктор для контрола, блокирующего/разблокирующего выделенные элементы
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import { Constants } from '../../common/utils';
import Viewports from '../../common/viewports';
import GlobalWidgets from '../../common/global-widget';
import templates from '../../../templates/constructor/controls/common_position.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const CommonPosition = ControlClass.extend({
  saveOnDeselect: true,
  saveOnDestroy: true,

  name: 'common_position', // должно совпадать с классом вьюхи

  className: 'control common_position',

  events: {
    'click li': 'toggleFixed',
    'click .position-wrapper .sticked-left': 'toggleSticked',
    'click .position-wrapper .sticked-right': 'toggleSticked',
    'click .position-wrapper .sticked-top': 'toggleSticked',
    'click .position-wrapper .sticked-bottom': 'toggleSticked',
    'click .position-wrapper .full-width:not(.margin-input):not(.full-screen-video)': 'toggleFullWidth',
    'click .position-wrapper .full-height:not(.margin-input):not(.full-screen-video)': 'toggleFullHeight',
    'click .position-wrapper .full-screen-video.full-height': 'toggleFullScreen',
    'click .position-wrapper .full-screen-video.full-width': 'toggleFullScreen',
    'mouseover .position-wrapper .margin-input': 'addHoveredClass',
    'mouseleave .position-wrapper .margin-input': 'removeHoveredClass',
    'mouseover .position-wrapper .togglers-wrapper .full-height.active': 'addFullWidthClass',
    'mouseleave .position-wrapper .togglers-wrapper .full-height': 'removeFullWidthClass',
    'focus .position-wrapper .margin-input': 'addHoveredClass',
    'focusout .position-wrapper .margin-input': 'removeHoveredClass',
    ///gwidget добавить обработчики для Show on all pages и Above pages
  },

  POS_LIST: ['nw', 'n', 'ne', 'w', 'c', 'e', 'sw', 's', 'se'],

  ALLOW_FULL_WIDTH: ['shape', 'picture', 'video', 'iframe', 'rmfooter', 'rmheader'], // Для каких типов блоков разершено растягивание по горизонтали
  ALLOW_FULL_HEIGHT: ['shape', 'picture', 'video', 'iframe'], // по вертикали

  initialize: function(params) {
    this.template = templates['template-constructor-control-common_position'];
    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;
  },

  bindLogic: function() {
    // Свойства, вызывающие обновление панели
    var LISTENED = [
      'fixed_position',
      'is_full_width',
      'full_width_margin',
      'is_full_height',
      'full_height_margin',
      'sticked',
      'sticked_margin',
      'is_global',
      'is_above',
    ];

    var events = _(LISTENED)
      .map(function(l) {
        return 'change:' + l;
      })
      .join(' ');

    this.listenTo(
      this.model,
      events,
      function() {
        _.defer(this.updatePanel);
      }.bind(this)
    );
  },

  unBindLogic: function() {
    this.stopListening(this.model);
  },

  select: function() {
    this.updatePanel();

    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-common_position');
  },

  render: function() {
    var was_rendered = !!ControlClass.prototype.render.apply(this, arguments);
    if (!was_rendered) {
      this.updatePanel();
      return;
    }

    this.$fullwidthMarginInput = $(this.$('.full-width.margin-input'));

    this.$fullwidthMarginInput.RMNumericInput({
      onChange: function($input, val) {
        this.setFullwidth();
      }.bind(this),
    });

    this.$fullheightMarginInput = $(this.$('.full-height.margin-input'));

    this.$fullheightMarginInput.RMNumericInput({
      onChange: function($input, val) {
        this.setFullheight();
      }.bind(this),
    });

    // используем RMDrag для того, чтобы у юзера была возможность изменять
    // значение марджина тасканием мышки
    $(this.$('.margin-input')).RMDrag({
      start: this.addHoveredClass,
      end: this.removeHoveredClass,
      silent: true,
    });

    var switcherParams = {
      width: 40,
      height: 24,
      'color-0': '#0078ff',
      'color-1': '#c9c8c9',
      'text-0': '',
      'text-1': '',
    };

    var isDisabledGlobalSwitcher = false;

    // Если у проекта есть корзина, то надо проверить есть ли среди выделенных блоков корзина
    if (
      this.blocks &&
      this.blocks[0] &&
      this.blocks[0].mag &&
      this.blocks[0].workspace.eCommerceManager.haveEcommerceCartWidget
    ) {
      var cartBlock = this.blocks.find(function(block) {
        return block.blockName === Constants.ecommerceCartBlockName;
      });
      // Если она есть то дизейблим переключатель глобального виджета тк корзина всегда глобальна
      if (cartBlock) {
        isDisabledGlobalSwitcher = true;
      }
    }

    var setSwitcherState = function(switcher, property) {
      var all_on = true;
      var all_off = true;

      _.each(this.blocks, function(b) {
        if (!b.model.get(property)) {
          all_on = false;
        }
        if (b.model.get(property)) {
          all_off = false;
        }
      });

      if (!all_on && !all_off) {
        switcher.setUndefinedState();
      } else {
        switcher.setState(all_on, true);
      }
    }.bind(this);

    this.globalSwitcher = $(this.$('.switcher.global'))
      .RMSwitcher(
        isDisabledGlobalSwitcher ? Object.assign({}, switcherParams, { disabled: true }) : switcherParams,
        function(state) {
          this.setGlobal(state);
        }.bind(this)
      )
      .data('switcher');

    setSwitcherState(this.globalSwitcher, 'is_global');

    this.aboveSwitcher = $(this.$('.switcher.above'))
      .RMSwitcher(
        switcherParams,
        function(state) {
          this.setAbove(state);
        }.bind(this)
      )
      .data('switcher');

    setSwitcherState(this.aboveSwitcher, 'is_above');

    this.updatePanel();

    return this;
  },

  // добавляет класс для full width при ховере на full height, если включены и full height и full width;
  // нужно для изменения opacity в controls.less
  addFullWidthClass: function() {
    $('.position-wrapper .togglers-wrapper .full-width.active').addClass('light-blue');
  },

  // удаляет класс, добавленный выше
  removeFullWidthClass: function() {
    var $fullWidth = $('.position-wrapper .togglers-wrapper .full-width');

    if ($fullWidth.hasClass('light-blue')) {
      $fullWidth.removeClass('light-blue');
    }
  },

  addHoveredClass: function(e) {
    if ($(e.currentTarget).hasClass('full-width')) {
      this.$el.removeClass('full-height-hovered');
    } else {
      this.$el.removeClass('full-width-hovered');
    }

    this.$el.addClass(e.currentTarget.classList[0] + '-hovered');
  },

  removeHoveredClass: function(e) {
    _.defer(
      function() {
        if (!$(e.target).hasClass('dragging') && !$(e.target).hasClass('focused')) {
          this.$el.removeClass(this.$el.hasClass('full-width-hovered') ? 'full-width-hovered' : 'full-height-hovered');
        }
      }.bind(this)
    );
  },

  toggleFixed: function(e) {
    var $target = $(e.currentTarget),
      position = this.POS_LIST[$target.index()];

    if (!$target.hasClass('active')) {
      // кликнули по неотмеченой точке - всем блокам в выделении прописываем данную точку привязки фикседа
      this.setFixed(position);
    } else {
      // кликнули по отмеченой точке - убираем фиксед у всех фиксед блоков в выделении с данным типом привязки
      this.resetFixed(position);
    }
  },

  // в случае с виджетом видео решили не давать возможность использовать full height отдельно от full width;
  // фунцкия регулирует эту возожность, разрешая вкл/выкл full width и full screen(width + height)
  toggleFullScreen: function() {
    var $fullWidth = $('.position-wrapper .full-width:not(.margin-input)'),
      $fullHeight = $('.position-wrapper .full-height:not(.margin-input)');

    if ($fullWidth.hasClass('disabled') || $fullHeight.hasClass('disabled')) {
      return;
    }

    if ($fullWidth.hasClass('active') && !$fullHeight.hasClass('active')) {
      this.setFullheight({ storeHeight: true });
    } else if (!$fullWidth.hasClass('active') && !$fullHeight.hasClass('active')) {
      this.setFullheight({ storeHeight: true });
      this.setFullwidth({ storeWidth: true });
    } else {
      this.resetFullheight();
      this.resetFullwidth();
    }
  },

  setFixedPositionInViewports: function() {
    var workspaceViewport = this.master.workspace.page.getCurrentViewport();
    var prefix = 'viewport_';
    var blocks = this.getBlocksWithHotspotBlocks();
    // hash with viewports mapped to arrays of blocks have to be fixed
    let blocksToFixed = {};

    // Проверим все блоки во всех вьюпортах. Там где нет fixed_position — поставим
    _.each(
      blocks,
      function(block) {
        var widget = block.model;
        var previousViewport = workspaceViewport;

        _.each(
          Viewports.viewport_listall,
          function(viewport) {
            viewport = viewport.replace(prefix, '');
            var viewportData = viewport !== previousViewport ? widget[prefix + viewport] : widget.attributes;
            var viewportInitialized = !_.isEmpty(viewportData);
            var isNotFixedInViewport = viewportInitialized && !viewportData.fixed_position;
            var isVisibleInViewport = viewportInitialized && !viewportData.hidden;

            if (viewportInitialized && isNotFixedInViewport) {
              // Если вьюпорт инициализирован, и виджет в нём не-fixed, и в этом вьюпорте виджет виден — переключимся на вьюпорт, потом вычислим fixed_position
              if (viewport !== previousViewport && isVisibleInViewport) {
                previousViewport = viewport;
                blocksToFixed[viewport] = blocksToFixed[viewport] || [];
                blocksToFixed[viewport].push(block);
                // Если мы сейчас на нужном вьюпорте и виджет виден, сразу вычислим fixed_position
              } else if (isVisibleInViewport) {
                blocksToFixed[viewport] = blocksToFixed[viewport] || [];
                blocksToFixed[viewport].push(block);
                // Если виджет не виден, просто выставим fixed_position: 'n' без пересчитывания координат
              } else {
                viewportData.fixed_position = 'n';
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    // traverse hash to set blocks fixed
    _.each(blocksToFixed, (blocks, viewport) => {
      viewport = viewport.replace(prefix, '');
      _.each(blocks, block => {
        // set required viewport
        if (viewport !== workspaceViewport) {
          let widget = block.model;
          widget.storeViewport(workspaceViewport);
          widget.restoreViewport(viewport);
        }
      });
      this.setFixed('c', blocks);
      _.each(blocks, block => {
        // reverse setted viewport
        if (viewport !== workspaceViewport) {
          let widget = block.model;
          widget.storeViewport(viewport);
          widget.restoreViewport(workspaceViewport);
        }
      });
    });

    //this.master.workspace.save_group(_.pluck(blocks, 'model'));
    this.master.workspace.set_group(_.pluck(blocks, 'model'));
  },

  setFixed: function(position, blocks) {
    blocks = blocks ? (_.isArray(blocks) ? blocks : [blocks]) : this.blocks;

    this.resetSticked(blocks);

    var new_data = [],
      pack = {
        t: Number.POSITIVE_INFINITY,
        l: Number.POSITIVE_INFINITY,
        b: Number.NEGATIVE_INFINITY,
        r: Number.NEGATIVE_INFINITY,
      },
      self = this,
      maxZ = _.max(this.master.workspace.blocks, function(b) {
        return b.model.get('z');
      }).model.get('z'),
      currMinZ = _.min(this.blocks, function(b) {
        return b.model.get('z');
      }).model.get('z');

    _.each(blocks || this.blocks, function(block) {
      var blockBox = block.getBoxData({ includeBoundingBox: true, checkFixedPosition: true });

      pack.t = Math.min(pack.t, blockBox.bb_y);
      pack.l = Math.min(pack.l, blockBox.bb_x);
      pack.b = Math.max(pack.b, blockBox.bb_y + blockBox.bb_h);
      pack.r = Math.max(pack.r, blockBox.bb_x + blockBox.bb_w);
    });

    _.each(blocks, function(b) {
      var x = 0,
        y = 0,
        boxData = b.getBoxData({ includeBoundingBox: true, checkFixedPosition: true });

      if (['n', 'c', 's'].indexOf(position) != -1) x = boxData.w / 2 - (pack.r - pack.l) / 2;
      if (['w', 'c', 'e'].indexOf(position) != -1) y = boxData.h / 2 - (pack.b - pack.t) / 2;

      if (position.indexOf('s') > -1) {
        y = boxData.y - pack.b + boxData.h - y;
        if (y !== 0) {
          y *= -1;
        }
      } else {
        y += boxData.y - pack.t;
      }

      if (position.indexOf('e') > -1) {
        x = boxData.x - pack.r + boxData.w - x;
        if (x !== 0) {
          x *= -1;
        }
      } else {
        x += boxData.x - pack.l;
      }

      var rec = {
        _id: b.model.id,
        fixed_position: position,
        x: x,
        y: y,
      };

      if (!b.model.get('fixed_position')) {
        // если изначально виджет был не фикседом - то нужно ему задать самый большой  z
        // чтобы он оказался сверху
        // всегда делать не надо - чтобы не нарушался порядок в виджет баре
        rec.z = b.model.get('z') + maxZ - currMinZ + 1;
      }

      // без defer в block.js в getFullHeightDims() происходит неверный
      // расчет full-height, т.к. на момент сброса fixed, там виджет все еще является fixed'ом
      _.defer(
        function() {
          if (b.model.get('is_full_height')) {
            self.setFullheight();
          }
        }.bind(self)
      );

      new_data.push(rec);
    });

    // this.master.workspace.save_group(new_data);
    this.master.workspace.set_group(new_data);
  },

  resetFixed: function(position) {
    var new_data = [],
      self = this;

    // // Выключаем режим над всеми страницами. Он может быть только если все fixed
    // this.aboveSwitcher.setState(false)

    // если передали position, тогда фиксед сбрасываем только у блоков с переданной точкой привязки
    // иначе сбрасываем у всех у кого есть любой фиксед
    _.each(this.blocks, function(b) {
      if (b.model.get('fixed_position') && (!position || b.model.get('fixed_position') == position)) {
        var offset = b.$el.offset(),
          workspacePosition = b.workspace.position;

        new_data.push({
          _id: b.model.id,
          fixed_position: '',
          x: offset.left - workspacePosition.left,
          y: offset.top - workspacePosition.top,
        });
        // без defer в block.js в getFullHeightDims() происходит неверный
        // расчет full-height, т.к. на момент сброса fixed, там виджет все еще является fixed'ом
        _.defer(
          function() {
            if (b.model.get('is_full_height')) {
              self.setFullheight();
            }
          }.bind(self)
        );
      }
    });

    if (!_.isEmpty(new_data)) {
      // this.master.workspace.save_group(new_data);
      this.master.workspace.set_group(new_data);
      this.setAbove(false);
    }
  },

  toggleFullHeight: function(e) {
    var $target = $(e.currentTarget);

    if ($target.hasClass('disabled')) {
      return;
    }

    if (!$target.hasClass('active')) {
      this.setFullheight({ storeHeight: true });
    } else {
      this.removeFullWidthClass();
      this.resetFullheight();
    }
  },

  toggleFullWidth: function(e) {
    var $target = $(e.currentTarget);
    // full screen для видео разруливает toggleFullScreen
    if ($target.hasClass('disabled') || $target.hasClass('.full-screen-video')) {
      return;
    }

    if (!$target.hasClass('active')) {
      this.setFullwidth({ storeWidth: true });
    } else {
      this.resetFullwidth();
    }
  },

  setFullheight: function(params) {
    this.resetSticked(null, { vertical_only: true });

    params = params || {};

    var new_data = [],
      margin = parseInt(this.$fullheightMarginInput.val(), 10) || 0,
      fh_dims = this.block.getFullHeightDims(margin); // Сразу указываем новый маржин, т.к. он уже нужен для вычисления того, что еще предстоит изменить в модели
    _.each(this.blocks, function(b) {
      var data = {
        _id: b.model.get('_id'),
        is_full_height: true,
        h: fh_dims.h,
        full_height_margin: margin,
      };

      // Сохраняем начальные положение и высоту (не вызывается при смене марджина)
      if (params.storeHeight) {
        _.extend(data, {
          full_height_initial_y: b.model.get('y'),
          full_height_initial_h: b.model.get('h'),
        });

        // Сбрасываем параметры вращения, если они были
        if (b.model.get('angle')) {
          data.angle = 0;
        }

        if (b.model.get('flip_v')) {
          data.flip_v = false;
        }

        if (b.model.get('flip_h')) {
          data.flip_h = false;
        }
      }

      new_data.push(data);
    });

    // this.master.workspace.save_group(new_data);
    this.master.workspace.set_group(new_data);
  },

  setFullwidth: function(params) {
    this.resetSticked(null, { horizontal_only: true });

    params = params || {};

    var new_data = [],
      margin = parseInt(this.$fullwidthMarginInput.val(), 10) || 0,
      fw_dims = this.block.getFullWidthDims(margin); // Сразу указываем новый маржин, т.к. он уже нужен для вычисления того, что еще предстоит изменить в модели

    _.each(this.blocks, function(b) {
      var data = {
        _id: b.model.get('_id'),
        is_full_width: true,
        w: fw_dims.w,
        full_width_margin: margin,
      };

      // Сохраняем начальные положение и ширину (не вызывается при смене марджина)
      if (params.storeWidth) {
        _.extend(data, {
          full_width_initial_x: b.model.get('x'),
          full_width_initial_w: b.model.get('w'),
        });

        // Сбрасываем параметры вращения, если они были
        if (b.model.get('angle')) {
          data.angle = 0;
        }

        if (b.model.get('flip_v')) {
          data.flip_v = false;
        }

        if (b.model.get('flip_h')) {
          data.flip_h = false;
        }
      }

      new_data.push(data);
    });

    // this.master.workspace.save_group(new_data);
    this.master.workspace.set_group(new_data);
  },

  resetFullheight: function() {
    var new_data = [],
      self = this;

    _.each(this.blocks, function(b) {
      if (b.model.get('is_full_height')) {
        new_data.push({
          _id: b.model.get('_id'),
          is_full_height: false,
          y: b.model.get('full_height_initial_y') || b.model.get('y'),
          h: b.model.get('full_height_initial_h') || b.model.get('h'),
        });
      }
      // при включенном full-width нужно передать ширину,
      // чтобы не сбросилось на начальную
      if (b.model.get('is_full_width')) {
        new_data.push({
          _id: b.model.get('_id'),
          w: b.getFullWidthDims().w,
        });
      }

      _.defer(
        function() {
          if (b.model.get('fixed_position')) {
            self.setFixed(b.model.get('fixed_position'), b);
          }
        }.bind(self)
      );
    });

    if (!_.isEmpty(new_data)) {
      this.master.workspace.set_group(new_data);
    }
  },

  resetFullwidth: function() {
    var new_data = [],
      self = this;

    _.each(this.blocks, function(b) {
      if (b.model.get('is_full_width')) {
        new_data.push({
          _id: b.model.get('_id'),
          is_full_width: false,
          x: b.model.get('full_width_initial_x') || b.model.get('x'),
          w: b.model.get('full_width_initial_w') || b.model.get('w'),
        });
      }

      if (b.model.get('is_full_height')) {
        new_data.push({
          _id: b.model.get('_id'),
          h: b.getFullHeightDims().h,
        });
      }

      _.defer(
        function() {
          if (b.model.get('fixed_position')) {
            self.setFixed(b.model.get('fixed_position'), b);
          }
        }.bind(self)
      );
    });

    if (!_.isEmpty(new_data)) {
      // this.master.workspace.save_group(new_data);
      this.master.workspace.set_group(new_data);
    }
  },

  toggleSticked: function(e) {
    var $target = $(e.currentTarget),
      side = $target.attr('data-side');

    if ($target.hasClass('disabled')) {
      return;
    }

    if (!$target.hasClass('active')) {
      // кликнули по неотмеченой точке - всем блокам в выделении прописываем данную точку привязки
      this.setSticked(side);
    } else {
      // кликнули по отмеченой точке - убираем стики у всех стики блоков в выделении с данным типом привязки
      this.resetSticked(null, { side: side });
    }
  },

  setSticked: function(side) {
    this.resetFixed();

    // Сбрасываем full-width только для гориз. стика. С вертикальным он совместим
    if (side == 'left' || side == 'right') {
      this.resetFullwidth();
    }

    if (side == 'top' || side == 'bottom') {
      this.resetFullheight();
    }

    var pack = {
      t: Number.POSITIVE_INFINITY,
      l: Number.POSITIVE_INFINITY,
      b: Number.NEGATIVE_INFINITY,
      r: Number.NEGATIVE_INFINITY,
    };

    _.each(this.blocks, function(b) {
      var blockBox = b.getBoxData({ includeBoundingBox: true });
      pack.t = Math.min(pack.t, blockBox.bb_y);
      pack.l = Math.min(pack.l, blockBox.bb_x);
      pack.b = Math.max(pack.b, blockBox.bb_y + blockBox.bb_h);
      pack.r = Math.max(pack.r, blockBox.bb_x + blockBox.bb_w);
    });

    var new_data = [];

    _.each(this.blocks, function(b) {
      var margin = 0;

      // учитываем поворот виджета чтобы прибить точно к краю
      // также учитываем положение в группе, если работаем с несколькими виджетами
      var boxData = b.getBoxData({ includeBoundingBox: true });
      if (side == 'left') {
        margin = boxData.x - pack.l;
      } else if (side == 'right') {
        margin = pack.r - (boxData.x + boxData.w);
      } else if (side == 'top') {
        margin = boxData.y - pack.t;
      } else if (side == 'bottom') {
        margin = pack.b - (boxData.y + boxData.h);
      }

      var dims = b.getStickedDims(side, margin);

      new_data.push({
        _id: b.model.id,
        sticked: side,
        sticked_margin: margin,
        x: dims.x,
        y: dims.y,
      });
    });

    if (!_.isEmpty(new_data)) {
      // this.master.workspace.save_group(new_data);
      this.master.workspace.set_group(new_data);
      this.setAbove(false);
    }

    if (side == 'top') {
      this.master.workspace.scrollToTop();
    } else if (side == 'bottom') {
      this.master.workspace.scrollToBottom();
    }
  },

  resetSticked: function(blocks, opts) {
    opts = opts || {};
    var new_data = [];

    // если передали side, тогда стики сбрасываем только у блоков с переданной точкой привязки
    // иначе сбрасываем у всех у кого есть любой стики
    _.each(blocks || this.blocks, function(b) {
      var sticked_side = b.model.get('sticked');

      if (sticked_side && (!opts.side || sticked_side == opts.side)) {
        // Не сбрасываем вертикальные стики, если передана опция
        if (opts.horizontal_only && sticked_side !== 'left' && sticked_side !== 'right') {
          return;
        }

        // Не сбрасываем горизонтальные стики, если передана опция(случай с full-height)
        if (opts.vertical_only && sticked_side !== 'bottom' && sticked_side !== 'top') {
          return;
        }

        var dims = b.getStickedDims();

        new_data.push({
          _id: b.model.id,
          sticked: '',
          x: dims.x,
          y: dims.y,
        });
      }
    });

    if (!_.isEmpty(new_data)) {
      // this.master.workspace.save_group(new_data);
      this.master.workspace.set_group(new_data);
    }
  },

  setGlobal: function(state) {
    var new_data = [];
    var affectedPages;

    var blocks = this.getBlocksWithHotspotBlocks();

    _.each(blocks, function(b) {
      new_data.push({
        _id: b.model.id,
        is_global: state,
      });
    });

    // Отключаем глобальность. Нужно перепривязать виджеты к текущей странице.
    if (!state) {
      affectedPages = this.master.workspace.mag.changeParentPage(_.pluck(blocks, 'model'), this.master.workspace.page, {
        silent: true,
      });
      this.changedPages = this.changedPages || new Backbone.Collection();
      this.changedPages.add(affectedPages);

      this.aboveSwitcher.setState(state);
    } else {
      _.each(
        blocks,
        function(b) {
          var vp = this.master.workspace.page.getCurrentViewport();

          GlobalWidgets.resetGlobalData(b.model, vp);
        }.bind(this)
      );
    }

    if (!_.isEmpty(new_data)) {
      // this.master.workspace.save_group(new_data);
      this.master.workspace.set_group(new_data);
      this.master.workspace.trigger('widget:context:change', blocks);
    }
  },

  setAbove: function(state) {
    var new_data = [];
    var nonFixedBlocks;

    var blocks = this.getBlocksWithHotspotBlocks();

    if (state !== undefined && this.aboveSwitcher) {
      this.aboveSwitcher.setState(state);
    }

    if (state) {
      this.globalSwitcher.setState(state);
    }

    _.each(blocks, function(b) {
      new_data.push({
        _id: b.model.id,
        is_above: state,
      });
    });

    // Включаем фикс только у тех блоков, у которых его еще нет
    // У некоторых блоков он может быть уже включен, и привязка может быть к разным точкам
    if (state) {
      this.setFixedPositionInViewports();
    }

    RM.constructorRouter.workspace.common_layer && RM.constructorRouter.workspace.common_layer.fixBadZIndexes();

    if (!_.isEmpty(new_data)) {
      // this.master.workspace.save_group(new_data);
      this.master.workspace.set_group(new_data);
      this.master.workspace.trigger('widget:context:change', blocks);
    }
  },

  getBlocksWithHotspotBlocks: function() {
    var blocks = [];

    _.each(this.blocks, function(b) {
      if (b.model.get('type') == 'hotspot') {
        blocks = _.union(blocks, b.getGroupBlocks());
      }
    });

    return _.union(blocks, this.blocks);
  },

  updatePanel: function() {
    var fullwidthEnabled = true,
      fullheightEnabled = true,
      hasStickedLeft = false,
      hasFullwidth = false,
      hasFullheight = false,
      hasStickedRight = false,
      hasStickedTop = false,
      hasStickedBottom = false,
      fullScreenVideo = false,
      hasFixed = false,
      hasGlobal = false,
      maxWidthMargin = 0,
      maxHeightMargin = 0;

    var $positions = this.$('ul li').removeClass('active');

    var isDefaultViewport = this.master.workspace.page.getCurrentViewport() == 'default';

    _.each(
      this.blocks,
      function(b) {
        hasStickedLeft = hasStickedLeft || b.model.get('sticked') == 'left';
        hasFullwidth = hasFullwidth || b.model.get('is_full_width');
        hasFullheight = hasFullheight || b.model.get('is_full_height');
        hasStickedRight = hasStickedRight || b.model.get('sticked') == 'right';
        hasStickedTop = hasStickedTop || b.model.get('sticked') == 'top';
        hasStickedBottom = hasStickedBottom || b.model.get('sticked') == 'bottom';
        hasFixed = hasFixed || b.model.get('fixed_position');
        hasGlobal = hasFixed || b.model.get('is_global');
        fullScreenVideo = b.model.get('video_id');

        fullwidthEnabled =
          fullwidthEnabled &&
          _.contains(this.ALLOW_FULL_WIDTH, b.model.get('type')) &&
          !(b.model.get('type') == 'shape' && b.model.get('tp') == 'icon'); // В выделении нет шейпов-иконок

        // для full height решили отключили line, потому что она нормально не увeличивается, да и в принципе не нужен для нее full height
        fullheightEnabled =
          fullheightEnabled &&
          _.contains(this.ALLOW_FULL_HEIGHT, b.model.get('type')) &&
          !(b.model.get('type') == 'shape' && (b.model.get('tp') == 'icon' || b.model.get('tp') == 'line'));

        if (b.model.get('fixed_position') && !b.restoreFixed) {
          var index = this.POS_LIST.indexOf(b.model.get('fixed_position'));
          $positions.eq(index).addClass('active');
        }

        // Вычисляем максимальный маржин у выделенных растянутых блоков
        if (b.model.get('is_full_width')) {
          maxWidthMargin = Math.max(maxWidthMargin, b.model.get('full_width_margin') || 0);
        }

        if (b.model.get('is_full_height')) {
          maxHeightMargin = Math.max(maxHeightMargin, b.model.get('full_height_margin') || 0);
        }
      }.bind(this)
    );

    this.$fullwidthMarginInput.val(maxWidthMargin);

    this.$fullheightMarginInput.val(maxHeightMargin);

    this.$('.position-wrapper .sticked-left')
      .toggleClass('disabled', !isDefaultViewport)
      .toggleClass('active', !!hasStickedLeft);
    this.$('.position-wrapper .full-width')
      .toggleClass('disabled', !isDefaultViewport)
      .toggleClass('hidden', !fullwidthEnabled)
      .toggleClass('active', !!hasFullwidth)
      .toggleClass('full-screen-video', !!fullScreenVideo && !!hasFullwidth && !!hasFullheight);

    this.$('.position-wrapper .full-height')
      .toggleClass('disabled', !isDefaultViewport)
      .toggleClass('hidden', !fullheightEnabled)
      .toggleClass('active', !!hasFullheight)
      .toggleClass('full-screen-video', !!fullScreenVideo);

    this.$('.position-wrapper .sticked-right')
      .toggleClass('disabled', !isDefaultViewport)
      .toggleClass('active', !!hasStickedRight);

    this.$('.position-wrapper .sticked-top')
      .toggleClass('disabled', !isDefaultViewport)
      .toggleClass('active', !!hasStickedTop);

    this.$('.position-wrapper .sticked-bottom').toggleClass('active', !!hasStickedBottom);

    this.$el
      // .toggleClass('hide-bottom', !isDefaultViewport) // только для десктопного вьюпорта
      .toggleClass('full-width-margin', !!(fullwidthEnabled && hasFullwidth) && isDefaultViewport)
      .toggleClass('full-height-margin', !!(fullheightEnabled && hasFullheight) && isDefaultViewport)
      .toggleClass(
        'control-active',
        !!(
          hasStickedLeft ||
          hasFullwidth ||
          hasFullheight ||
          hasStickedRight ||
          hasStickedTop ||
          hasStickedBottom ||
          hasFixed ||
          hasGlobal
        )
      );

    this.updatePanelVerticalPosition();
  },

  save: function() {
    var save_data = _.pluck(this.getBlocksWithHotspotBlocks(), 'model');

    if (!_.isEmpty(this.changedPages)) {
      save_data = save_data.concat(this.changedPages.models);
    }

    var currVP = this.master.workspace.page.getCurrentViewport();

    if (currVP != 'default') {
      // эта панелька хитрее чем остальные - она пишет во все вьюпорты
      // это приводит к тому что после сохранения, данные в model.attributes перестают соответствовать текущему вьюпорту
      // из-за того что изменяется дефолтный вьюпорт и при сохранении бекбон делает model.set(changes),
      // а в корне changes лежат изменения для дефолтного вьюпорта
      // в результате мешанина из данных 2-х вьюпортов текущего и дефолтного
      //
      // решаем просто - если текущий вьюпорт не дефолтный сначала сохраним данные из attributes в model.viewport_[имя вьюпорта]
      // а после сохранения - восстанавливаем, тем самым данные не потеряются
      _.each(save_data, w => {
        w.storeViewport(currVP);
      });
    }

    this.master.workspace.save_group(save_data, { viewport: 'default' });
    if (currVP != 'default') {
      _.each(save_data, w => {
        w.restoreViewport(currVP);
      });
    }

    this.master.workspace.common_layer.fixBadZIndexes();
  },
});

export default CommonPosition;
