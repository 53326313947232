<template>
  <div
    v-show="is_visible"
    class="size-tool skip-rotate"
    @click="event => event.stopPropagation()"
    @dblclick="event => event.stopPropagation()"
  >
    <numeric-input
      class="width"
      :value="w"
      :autosize="true"
      :disabled="is_width_disabled"
      :mouse-use="!is_width_disabled"
      ref="widthInput"
      :debounce-keyboard="500"
      :min="minwidth"
      :max="maxwidth"
      @change="value => resize('width', value)"
    />
    <span>×</span>
    <numeric-input
      class="height"
      :value="h"
      :autosize="true"
      :disabled="is_height_disabled"
      :mouse-use="!is_height_disabled"
      ref="heightInput"
      :debounce-keyboard="500"
      :min="minheight"
      :max="maxheight"
      @change="value => resize('height', value)"
    />
  </div>
</template>

<script>
import _ from '@rm/underscore';
import BlockFrame from '../../../js/constructor/block-frame';
import NumericInput from '../../common/numeric-input.vue';

export default {
  components: {
    NumericInput,
  },

  props: {
    block: Object,
    frame: Object,
  },

  data: function() {
    return {
      minwidth: this.frame.minwidth,
      maxwidth: this.frame.maxwidth,
      minheight: this.frame.minheight,
      maxheight: this.frame.maxheight,
    };
  },

  computed: {
    w: function() {
      return parseInt(this.block.latestPosSizeAngle.width);
    },
    h: function() {
      return parseInt(this.block.latestPosSizeAngle.height);
    },
    x: function() {
      return parseInt(this.block.latestPosSizeAngle.left);
    },
    y: function() {
      return parseInt(this.block.latestPosSizeAngle.top);
    },
    angle: function() {
      return this.block.latestPosSizeAngle.angle;
    },
    sin: function() {
      return this.block.latestPosSizeAngle.sinAngle;
    },
    cos: function() {
      return this.block.latestPosSizeAngle.cosAngle;
    },
    fixed_position: function() {
      return this.block.model.get('fixed_position');
    },
    is_visible: function() {
      return !this.block.forbidVisualRotation && this.block.workspace.mag.edit_params.get('sizes');
    },
    is_width_disabled: function() {
      return !this.frame.canChangeDimension('width');
    },
    is_height_disabled: function() {
      return !this.frame.canChangeDimension('height');
    },
  },

  mounted: function() {
    const _c = classname => this.$children.find(c => c.$el.classList.contains(classname));

    this.$w = _c('width');
    this.$h = _c('height');
    this.applyPosition();
  },

  methods: {
    resize: function(dimension, value) {
      var isWidth = dimension === 'width';
      var width = isWidth ? value : parseInt(this.$w.$el.value);
      var height = !isWidth ? value : parseInt(this.$h.$el.value);
      // Вычисляем ограничения как если бы ресайзили за правый хэндл (когда ширина) или нижний (когда высота).
      // Если увеличивать только ширину, а в ограничении использовать угловой хэндл se, то блок не будет даунсайзиться.
      var constraintsDirection = isWidth ? 'e' : 's';
      // Фактически (визуально) ресайзим как если бы тянули за угловой хэндл se. Это интуитивнее, потому что в этом же углу находится сама плашка.
      var actualDirection = 'se';

      // Важно триггерить beforeChange до вычисления бокса.
      // Например, это важно для fixed-блоков, у которых перед началом ресайза fixed-позиция заменяется на обычную,
      // модель обновляется и latestPosSizeAngle пересчитывается.
      // Если триггерить beforeChange после вычисления бокса, то хотя fixed-позиция и заменится до ресайза,
      // в ресайз передастся бокс с неправильным смещением. Баг https://readymag.monday.com/boards/55520426/pulses/80511162
      this.$emit('beforeChange', {}, actualDirection);

      var box = this.frame.applyConstraints(
        {
          // Используем дробные значения для left и top
          // Для блоков без поворота (и групп) left и top не должны изменяться — возьмём их из модели
          // Для блоков с поворотом — из latestPosSizeAngle, который обновляется во время ресайза
          // Баг https://readymag.monday.com/boards/55520426/pulses/86646021
          left: this.angle ? this.block.latestPosSizeAngle.left : this.block.model.attributes.x,
          top: this.angle ? this.block.latestPosSizeAngle.top : this.block.model.attributes.y,
          width: width,
          height: height,
        },
        { direction: constraintsDirection, isSidePointProportional: false, isSizes: true }
      );

      if (this.angle) {
        var rotatedBoxDelta = BlockFrame.getRotatedBoxDelta(
          {
            // Используем для исходного бокса this.block.latestPosSizeAngle.left (дробный) а не this.w (целый),
            // иначе расчёт rotatedBoxDelta будет не точный
            left: this.block.latestPosSizeAngle.left,
            top: this.block.latestPosSizeAngle.top,
            width: this.block.latestPosSizeAngle.width,
            height: this.block.latestPosSizeAngle.height,
          },
          box,
          this.angle,
          constraintsDirection
        );
        box.left += rotatedBoxDelta.left;
        box.top += rotatedBoxDelta.top;
      }

      // Важно убедиться, что значения минимума и максимума — целые, потому что иногда ширина / высота выставляется в минимум / максимум.
      // С плавающими значениями и одновременно флагом useFloat: false поведение плашки sizes будет непредсказуемым
      // Баг https://readymag.monday.com/boards/55520426/pulses/85993120
      box.width = Math.round(box.width);
      box.height = Math.round(box.height);
      this.minwidth = isWidth && width < box.width ? box.width : this.frame.minwidth;
      this.maxwidth = isWidth && width > box.width ? box.width : this.frame.maxwidth;
      this.minheight = !isWidth && height < box.height ? box.height : this.frame.minheight;
      this.maxheight = !isWidth && height > box.height ? box.height : this.frame.maxheight;
      // Триггерим событие change, только если значение ширины или высоты (после применения ограничений) изменилось.
      // Помогает, например, от дёргания группы, если её пытаться даунсайзить когда она и так минимального размера.
      // Событие afterChange, как и beforeChange, триггерим в любом случае
      var didChange = (isWidth && box.width !== this.w) || (!isWidth && box.height !== this.h);
      if (didChange) {
        this.$emit('change', box, actualDirection);
      }
      this.$emit('afterChange', box, actualDirection);
    },

    // Умное позиционирование плашки
    applyPosition: function() {
      var GAP = 8;
      var dot_y = this.h + GAP;
      var dot_x = this.w + GAP;
      var cx = this.w / 2; // находим центр вращения (центр бокса)
      var cy = this.h / 2;
      var shift_left;
      var shift_top;

      // Перемещаем центр вращения в ноль
      dot_x -= cx;
      dot_y -= cy;

      // Вращаем точку
      var new_x = dot_x * this.cos - dot_y * this.sin;
      var new_y = dot_x * this.sin + dot_y * this.cos;

      // Возвращаем центр на место (в середину бокса)
      dot_x = new_x + cx;
      dot_y = new_y + cy;

      this.$el.style.left = Math.round(dot_x) + 'px';
      this.$el.style.top = Math.round(dot_y) + 'px';

      // Задаем сдвиги плашки в зависимости от поворота
      var br = this.$el.getBoundingClientRect();
      var norm_angle = this.angle >= 0 ? this.angle : 360 + this.angle;
      shift_left = 0;
      shift_top = 0;
      if (norm_angle > 0 && norm_angle <= 90) {
        shift_left = (norm_angle / 90) * -br.width;
      } else if (norm_angle > 90 && norm_angle <= 180) {
        shift_left = -br.width;
        shift_top = ((norm_angle - 90) / 90) * -br.height;
      } else if (norm_angle > 180 && norm_angle <= 270) {
        shift_left = ((270 - norm_angle) / 90) * -br.width;
        shift_top = -br.height;
      } else if (norm_angle > 270 && norm_angle <= 360) {
        shift_top = ((360 - norm_angle) / 90) * -br.height;
      }

      // Применяем стили, и снова замеряем, не вылезет ли плашка за границы окна
      this.$el.style.transform = 'translateX(' + shift_left + 'px) translateY(' + shift_top + 'px)';
      br = this.$el.getBoundingClientRect();
      var scrollbar_w = this.block.workspace.$container.width() - this.block.workspace.$container[0].clientWidth;
      var ww = window.innerWidth - scrollbar_w;
      var wh = window.innerHeight;

      if (br.left < GAP) {
        shift_left = shift_left - br.left + GAP;
      } else if (br.left + br.width + GAP > ww) {
        shift_left = shift_left + (ww - br.left - br.width - GAP);
      }

      if (br.top < GAP) {
        shift_top = shift_top - br.top + GAP;
      } else if (br.top + br.height + GAP > wh) {
        shift_top = shift_top + (wh - br.top - br.height - GAP);
      }

      this.$el.style.transform = 'translateX(' + shift_left + 'px) translateY(' + shift_top + 'px)';
    },
  },

  watch: {
    angle: 'applyPosition',
    x: 'applyPosition',
    y: 'applyPosition',
    w: 'applyPosition',
    h: 'applyPosition',
    fixed_position: 'applyPosition',
    is_visible: function(value) {
      // Когда плашка становится видимой, нужно обновить размер инпутов с шириной и высотой
      if (value) {
        _.delay(
          function() {
            this.$refs.widthInput.updateSize();
            this.$refs.heightInput.updateSize();
          }.bind(this)
        );
      }
    },
  },
};
</script>

<style lang="less">
@import (reference) '../../../css/common/fonts.less';
@import (reference) '../../../css/common/constants.less';

.size-tool {
  white-space: nowrap;
  background: #f2f2f2;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  z-index: 2505;
  padding: 6px 8px 6px 8px;
  .user-select(none);

  .minimal-constructor-ui & {
    opacity: 0;
    visibility: hidden;
  }

  span {
    color: #b3b3b3;
    user-select: none;
    background: #f2f2f2;
    font-size: 16px;
    display: inline-block;
    margin: 3px -4px;
    vertical-align: middle;
  }

  input {
    .input-reset;
    font-size: 16px;
    display: inline-block;
    color: #0078ff;
    border: none;
    text-align: center;
    .avenir_demi;
    vertical-align: middle;
    letter-spacing: -0.5px;

    &:disabled {
      color: #b3b3b3;
      .user-select(none);
    }
  }

  .width {
    margin-right: -1px;
  }

  .height {
    margin-left: -1px;
  }
}
</style>
