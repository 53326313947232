/**
 * Плагины для обработки разных эмбедов в виджете Embed (ex iFrame)
 */

// Common utils
// ====================
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils, Constants } from '../common/utils';
import InitUtils from '../common/init-utils';
import VideoUtils from '../common/videoutils';
import EmbedMatcher from '../libs/embed-matcher';
import postscribe from 'postscribe';
import { sanitize } from 'validator';

const EmbedPlugins = {};
const EmbedUtils = {};

EmbedUtils.findPlugin = function(widget) {
  var pluginName = EmbedMatcher.matchCode(widget.code, widget);

  return _.find(EmbedPlugins, function(plugin, k) {
    return k.toLowerCase() == pluginName;
  });
};

// Base
// ====================

const EmbedPluginClass = Backbone.View.extend({
  embed_type: null, // Тип эмбеда. Используется для названий классов у элементов и т.п.
  containerSelector: null, // Селектор элемента-контейнера, у которого будет замеряться размер.
  resizable: true, // будет ли элемент ресайзиться внутри контента блока. Т.е. элементу будут заданы высота и ширина 100%!important
  proportional: false,
  forceFrameSize: false, // Рамка всегда отрисовывается по размерам эмбеда, после его рендеринга

  initialize: function(opts) {
    this.block = opts.block; // Блок
    this.widget = opts.widget; // Виджет во вьювере
    _.bindAll(this);

    this.isInViewer = RM.viewerRouter || (RM.constructorRouter && RM.constructorRouter.preview);
  },

  // Функция возвращает только то, что нужно из введенного пользователем кода, отрезая все лишнее
  // Например, теги script
  parse: function(raw_code, callback) {
    return callback(raw_code);
  },

  // Возвращает уже созданный jQuery элемент из очищенного кода
  sanitize: function(raw_code) {
    raw_code = sanitize(raw_code).xss();
    return $(raw_code);
  },

  // Отрисовывает контент. Чаще всего асинхронно. В callback возвращаются w, h контента,
  // которые плагин сам знает, как определять после отрисовки. Блок будет использовать их для размеров рамки
  draw: function(callback) {
    callback();
  },
});

// Instagram
// ====================

// Очередь коллбэков. Нужна для их регистрации до того как загрузится скрипт Instagram
EmbedUtils.instagramEmbedCallbacks = [];

// Подгружает скрипт Instagram и процессит уже ожидающие коллбэки от виджетов
EmbedUtils.initInstagram = function(callback) {
  if (window.instgrm && window.instgrm.Embeds && _.isFunction(window.instgrm.Embeds.process)) {
    instgrm.Embeds.process();
    return callback();
  }

  EmbedUtils.instagramEmbedCallbacks.push(callback);

  EmbedUtils.instagramInitXhr && EmbedUtils.instagramInitXhr.abort();
  $('body > script[src^="//platform.instagram.com"]').remove(); // Удаляем предыдущие недокачанные, неудаленные скрипты из тела страницы.
  EmbedUtils.instagramInitXhr = $.ajax({
    url: Utils.selectProtocol('//platform.instagram.com/en_US/embeds.js'),
    dataType: 'script',
    cache: true, // иначе jQuery добавляет к урлу параметр вида ?_=1545722496659, и инстаграм не отдает свой скрипт
    success: function() {
      _.each(EmbedUtils.instagramEmbedCallbacks, function(cb) {
        if (_.isFunction(cb)) {
          cb();
        }
      });
    },
  });
};

EmbedPlugins.Instagram = EmbedPluginClass.extend({
  embed_type: 'instagram',
  containerSelector: 'iframe',
  resizable: true,
  // proportional: true,

  initialize: function(opts) {
    EmbedPluginClass.prototype.initialize.apply(this, arguments);

    if (this.block) {
      _.extend(this.block.frame, {
        minwidth: 164,
        minheight: 264,
      });
    }
  },

  parse: function(raw_code, callback) {
    return callback(raw_code.replace(/<script.*script>/i, ''));
  },

  draw: function($data, $container, callback) {
    var $target,
      isHeightPositive,
      w,
      h,
      iframeInterval,
      ticks_counter = 0,
      timeout = 100,
      MAX_LOADER_TICKS = 20, // 2s мы можем позволить себе крутить лоадер
      MAX_TICKS = 50, // 5s мы надеемся дождаться $target
      loaderTicksCallbackFired = false;

    $container.html($data);

    EmbedUtils.initInstagram(
      function() {
        iframeInterval = setInterval(
          function() {
            ticks_counter++;

            $target = $container.children(this.containerSelector);

            // плагин считаем загрузившимся, если высота его контейнера больше нуля.
            isHeightPositive = $target.height();

            // плагин загрузился или хватит крутить лоадер — возвращаем колбек.
            if (isHeightPositive || ticks_counter > MAX_LOADER_TICKS) {
              // плагин загрузился или хватит ждать $target
              if (isHeightPositive || ticks_counter > MAX_TICKS) {
                // для Конструктора свои уникальные действия.
                // также срабатывает в Превью, но задача этой логики
                // сократить кол-во излишних операций для Вьювера
                if (isHeightPositive && RM.common.mode === 'constructor') {
                  w = $target.width();
                  h = $target.height();
                }

                clearInterval(iframeInterval);

                callback(w, h);

                return;
              }

              if (loaderTicksCallbackFired) return;

              loaderTicksCallbackFired = true;

              callback();
            }
          }.bind(this),
          timeout
        );
      }.bind(this)
    );
  },

  customResizeHandler: function(box, resizePoint, resizeByMouse) {
    return box;
  },
});

// Pinterest
// ====================

EmbedUtils.pinterestEmbedCallbacks = [];
EmbedUtils.initPinterest = function(callback) {
  var initInterval,
    ticks = 0,
    MAX_TICKS = 20;

  if (_.isFunction(window.parsePinBtns)) {
    return callback();
  }

  EmbedUtils.pinterestEmbedCallbacks.push(callback);

  // Разбито на две подстроки, чтобы не было комбинации /js/ - иначе при билде на бой ссылка заменится на урл в Amazon S3
  // TODO: Оптимизировать билд на бой
  $('body > script[src^="assets.pinterest.com/' + 'js/pinit.js"]').remove(); // Удаляем предыдущие недокачанные, неудаленные скрипты из тела страницы.
  $('body').prepend(
    $('<script defer src="//assets.pinterest.com/' + 'js/pinit.js" data-pin-build="parsePinBtns"></script>')
  );

  initInterval = setInterval(function() {
    ticks++;
    if (_.isFunction(window.parsePinBtns) || ticks > MAX_TICKS) {
      clearInterval(initInterval);
      callback();
    }
  }, 100);
};

EmbedPlugins.Pinterest = EmbedPluginClass.extend({
  embed_type: 'pinterest',
  containerSelector: 'a',
  resizable: false,
  forceFrameSize: true,

  // У разных вариаций пинтереста - разные контейнеры
  containerSelectors: {
    buttonPin: 'span',
    buttonFollow: 'span',
    embedPin: 'span',
    embedUser: 'span',
    embedBoard: 'span',
  },

  parse: function(raw_code, callback) {
    var match = raw_code.match(/data-pin-do=\"(\S+)\"/);

    if (_.isArray(match) && match[1]) {
      this.pinterestType = match[1];

      this.containerSelector = this.containerSelectors[this.pinterestType] || this.containerSelector;
    }

    return callback && callback(raw_code.replace(/<script.*script>/i, ''));
  },

  draw: function($data, $container, callback) {
    EmbedUtils.initPinterest(
      function() {
        var $target,
          isHeightPositive,
          w,
          h,
          iframeInterval,
          ticks_counter = 0,
          timeout = 100,
          MAX_LOADER_TICKS = 20, // 2s мы можем позволить себе крутить лоадер
          MAX_TICKS = 50, // 5s мы надеемся дождаться $target
          loaderTicksCallbackFired = false,
          $extra;

        $container.html($data);

        // при вызове из viewer/widgets/iframe.js → start() parse не делается
        // и pinterestType не выставляется совсем, а containerSelector не выставляются верно.
        if (!this.pinterestType) {
          this.parse($data[0].outerHTML);
        }

        this.pinterestType && $container.addClass(this.pinterestType);

        window.parsePinBtns($container.get(0));

        iframeInterval = setInterval(
          function() {
            ticks_counter++;

            $target = $container.children(this.containerSelector).filter(function() {
              return /PIN_\d+/.test(this.className);
            });

            // пинтерест считаем загрузившимся, если высота его контейнера больше нуля.
            isHeightPositive = $target.height();

            // пинтерест загрузился или хватит крутить лоадер — возвращаем колбек.
            if (isHeightPositive || ticks_counter > MAX_LOADER_TICKS) {
              // пинтерест загрузился или хватит ждать $target
              if (isHeightPositive || ticks_counter > MAX_TICKS) {
                // для Конструктора свои уникальные действия.
                // также срабатывает в Превью, но задача этой логики
                // сократить кол-во излишних операций для Вьювера
                if (isHeightPositive && RM.common.mode === 'constructor') {
                  w = $target.outerWidth(true);
                  h = $target.outerHeight(true);

                  // для кнопки follow прибавляем еще ширину внутреннего элемента, чтобы корректно рисовалась рамка
                  if (this.pinterestType == 'buttonFollow') {
                    $extra = $target.children('i');

                    $extra.length && (w += ($extra.outerWidth() || 1) - 1);
                  }
                }

                clearInterval(iframeInterval);

                callback(w, h);

                return;
              }

              if (loaderTicksCallbackFired) return;

              loaderTicksCallbackFired = true;

              callback();
            }
          }.bind(this),
          timeout
        );
      }.bind(this)
    );
  },
});

// Facebook video
// ====================
EmbedPlugins.FacebookVideo = EmbedPluginClass.extend({
  embed_type: 'fbvideo',
  containerSelector: 'iframe',
  resizable: true,
  proportional: true,
  forceFrameSize: true,

  initialize: function(opts) {
    EmbedPluginClass.prototype.initialize.apply(this, arguments);

    if (this.block) {
      _.extend(this.block.frame, {
        minwidth: 220,
        minheight: 124,
      });
    }
  },

  parse: function(raw_code, callback) {
    // Убираем любые скрипты и <div id="fb-root"></div>
    return callback(
      raw_code.replace(/<script.*script>/gi, '').replace(/<div\s+id=(\'|\")fb-root(\'|\")><\/div>/gi, '')
    );
  },

  draw: function($data, $container, callback) {
    var $target, w, h;

    $container.html($data);

    InitUtils.initFacebookAPI(
      function() {
        window.FB.XFBML.parse(
          $container.get(0),
          function() {
            $target = $container.find(this.containerSelector);

            w = $target.width();
            h = $target.height();

            callback(w, h);
          }.bind(this)
        );
      }.bind(this)
    );
  },
});

// Readymag
// ====================

EmbedUtils.readymagEmbedCallbacks = [];

EmbedUtils.initReadymag = function(callback) {
  if (RM.common.isReadymagEmbedInited && _.isFunction(window.RM_EMBED_parse)) {
    return callback();
  }

  EmbedUtils.readymagEmbedCallbacks.push(callback);

  // Функция вызывается после подгрузки основного скрипта эмбедов
  window.RM_EMBED_initAsync = function() {
    RM.common.isReadymagEmbedInited = true;

    // Процессим очередь коллбэков
    _.each(EmbedUtils.readymagEmbedCallbacks, function(cb, idx) {
      if (_.isFunction(cb)) {
        cb();
      }
    });
    EmbedUtils.readymagEmbedCallbacks = [];
  };

  if (EmbedUtils.readymagInitXhr) return;

  $('body > script[src~="RM_EMBED_"]').remove(); // Удаляем предыдущие недокачанные, неудаленные скрипты из тела страницы.
  EmbedUtils.readymagInitXhr = $.ajax({
    url: Constants.EMBED_SCRIPT_MAIN,
    dataType: 'script',
  });
};

EmbedPlugins.Readymag = EmbedPluginClass.extend({
  embed_type: 'readymag',
  containerSelector: 'iframe',
  resizable: true,
  // proportional: true,

  initialize: function(opts) {
    EmbedPluginClass.prototype.initialize.apply(this, arguments);

    if (this.block && this.block.frame) {
      _.extend(this.block.frame, {
        minwidth: 288,
        minheight: 236,
        maxwidth: 512,
        maxheight: 383,
      });
    }
  },

  parse: function(raw_code, callback) {
    return callback(raw_code.replace(/<script.*script>/i, ''));
  },

  draw: function($data, $container, callback) {
    $container.html($data);

    EmbedUtils.initReadymag(
      function() {
        // После инициализации скрипта эмбедов эта функция доступна глобально для окна
        window.RM_EMBED_parse(
          function() {
            var $iframe = $container.find(this.containerSelector);
            var w = $iframe.width();
            var h = $iframe.height();

            // Размер ифрейма получается на 2px меньше заданного (внутренний css, чтобы обеспечить тень),
            // это приводит к зацикливанию перерисовки с постепенным уменьшением ширины
            // По-этому передаем отрисованную ширину на 2 писеля больше
            callback(w + 2, h);
          }.bind(this)
        );
      }.bind(this)
    );
  },
});

// iFrame
// ====================

EmbedPlugins.IFrame = EmbedPluginClass.extend({
  embed_type: 'simple-iframe',
  containerSelector: 'iframe',
  resizable: true,

  parse: function(raw_code, callback) {
    var match,
      regex = /[\s\S]*?(\<iframe[\s\S]*?\>)[\s\S]*?(\<\/iframe\>)[\s\S]*?/i; // поправил недостаток regex: js бьет строки на отдельные части по переносу строки \n и применяет регекс к каждому куску по-отдельности
    // в результате корректный код с переносами не будет принят
    // решение – вместо dotall .* использовать [\s\S]*? (http://stackoverflow.com/questions/16424235/ignoring-line-breaks-in-javascript-regex)

    match = raw_code.match(regex);

    if (!(match && match[1] && match[2])) {
      return callback(null);
    }
    return callback(match[1] + match[2]);
  },

  // Возвращает НОВЫЙ ифрейм только с теми свойствами от старого, которые разрешены
  sanitize: function(raw_code) {
    var $el = $(raw_code);
    if (!(typeof $el === 'object' && $el.length)) {
      return $el;
    }

    var $new_iframe = $('<iframe></iframe>'),
      attrs = $el.get(0).attributes,
      ALLOWED_ATTRS = [
        'allowtransparency',
        'height',
        'name',
        'sandbox',
        'scrolling',
        'seamless',
        'src',
        'width',
        'allow',
      ];

    var sandboxAttrs;

    _(attrs).each(function(a) {
      if (typeof a === 'object' && a.specified && _.contains(ALLOWED_ATTRS, a.name.toLowerCase())) {
        var name = a.name.toLowerCase(),
          value = a.value;
        if (name == 'src' && window.location.protocol == 'https:') {
          // Для http (например на кастомных доменах), заменять протокол на // нельзя, а для https айфрейм с http не покажется.
          value = value.replace(/^http(s?):\/\//i, '//');
        }
        if (name === 'sandbox') {
          sandboxAttrs = value.split(' ');
        }
        $new_iframe.attr(name, value);
      }
    });

    // В конструкторе сэндбоксим iframe
    if (!this.isInViewer) {
      sandboxIframe($new_iframe, sandboxAttrs);
    }

    return $new_iframe;
  },

  draw: function($data, $container, callback) {
    var url = $data[0].src;

    if (!url || !url.trim()) {
      callback();
      return;
    }

    // для Скриншотера в случае Ютуб или Вимео видео показываем постер,
    // т.к. скриншотер работает на Фаерфоксе, а ФФ + Флеш — выдаст ошибку
    // и видео не будет на скриншоте, а то и других виджетов, которые
    // стояли в очереди на рендер после этого эмбед-виджета.
    if (RM.screenshot) {
      if (VideoUtils.getVideoProvider(url)) {
        this.setPosterInsteadOfVideo(url, $container, callback);
        // для Скриншотера в случае Ютуб или Вимео
        // остальные действия нас не интересуют.
        // колбек придет из setPosterInsteadOfVideo
        return;
      }
    }

    $container.html($data);

    var $target,
      isHeightPositive,
      w,
      h,
      iframeInterval,
      ticks_counter = 0,
      timeout = 100,
      MAX_LOADER_TICKS = 20, // 2s мы можем позволить себе крутить лоадер
      MAX_TICKS = 50, // 5s мы надеемся дождаться $target
      loaderTicksCallbackFired = false;

    iframeInterval = setInterval(
      function() {
        ticks_counter++;
        $target = $container.children(this.containerSelector);

        // плагин считаем загрузившимся, если высота его контейнера больше нуля.
        isHeightPositive = $target.height();

        // плагин загрузился или хватит крутить лоадер — возвращаем колбек.
        if (isHeightPositive || ticks_counter > MAX_LOADER_TICKS) {
          // плагин загрузился или хватит ждать $target
          if (isHeightPositive || ticks_counter > MAX_TICKS) {
            // для Конструктора свои уникальные действия.
            // также срабатывает в Превью, но задача этой логики
            // сократить кол-во излишних операций для Вьювера
            if (isHeightPositive && RM.common.mode === 'constructor') {
              w = $target.width();
              h = $target.height();
            }
            clearInterval(iframeInterval);
            callback(w, h);

            return;
          }

          if (loaderTicksCallbackFired) return;

          loaderTicksCallbackFired = true;

          callback();
        }
      }.bind(this),
      timeout
    );
  },

  setPosterInsteadOfVideo: function(url, $container, callback) {
    var providerName = VideoUtils.getVideoProvider(url),
      videoPosterUrl = '',
      $videoPoster = $('<img/>');

    VideoUtils.getEmbedData(
      url,
      function(data) {
        VideoUtils.getHighResVideoThumbnail({
          provider: providerName,
          thumbnail_url: data.thumbnail_url,
        }).then(
          _.bind(function(hiResThumbUrl) {
            videoPosterUrl = hiResThumbUrl;
            renderPoster(videoPosterUrl);
          }, this)
        );
      },
      function() {
        if (typeof callback === 'function') {
          callback();
        }
      }
    );

    function renderPoster(videoPosterUrl) {
      $videoPoster[0].src = videoPosterUrl;

      // фон контейнера делаем черным,
      // имитируя подложку под картинкой реального плеера.
      $container.css({
        'background-color': '#000',
      });

      // картинку показываем c пропорциональной высотой,
      // по центру контейнера, имитируя реальный плеер.
      $videoPoster.css({
        position: 'absolute',
        top: 0,
        bottom: 0,
        margin: 'auto',
        width: '100%',
        height: 'auto',
      });

      $container.append($videoPoster);
    }
  },
});

// Code Injection
// ====================

EmbedPlugins.CodeInjection = EmbedPluginClass.extend({
  embed_type: 'code-injection',
  containerSelector: 'rm-code-injection',
  resizable: true,

  // Селектор для выбора элементов из HEAD для проброса внутрь
  // iframe в конструкторе
  // HEAD_PASSTHROUGH_SELECTOR: 'style, link[rel="stylesheet"]',
  HEAD_PASSTHROUGH_SELECTOR: '', // TODO: Пока убран проброс стилей, из-за бага хрома - прозрачных шрифтов. Разобраться.

  parse: function(raw_code, callback) {
    return callback(raw_code);
  },

  // jQuery parseHTML в любом случае возвращает валидный HTML код
  sanitize: function(raw_code) {
    var $code = $.parseHTML(raw_code, document, true);
    return $code;
  },

  draw: function($data, $container, callback) {
    var $head = $(document).find('head'),
      head = '<head>',
      body = '<body style="padding:0; margin: 0; width: 100%; height: 100%; min-width: initial; min-height: auto;">',
      html = '<!DOCTYPE html><html style="width: 100%; height: 100%; min-width: initial; min-height: auto;">',
      $headElements = $head.find(this.HEAD_PASSTHROUGH_SELECTOR),
      $iframe = $('<iframe seamless style="width: 100%; height: 100%;"></iframe>'),
      $fakediv = $('<div></div>');

    // В конструкторе, либо стоит флаг использовать ифрейм
    if (
      !this.isInViewer ||
      (this.block && this.block.model.get('use_iframe')) ||
      (this.widget && this.widget.use_iframe)
    ) {
      // В конструкторе сэндбоксим iframe, что-бы не пполомать кодом конструктор
      if (!this.isInViewer) {
        sandboxIframe($iframe);
      }
      // Предварительно формируем текстовый код для HEAD и BODY,
      // затем, на основе него создаем iframe
      // Не добавляем элементы в готовый ифрейм, т.к. в этом случае
      // элементы <script> не отработают
      $headElements.each(function() {
        head += this.outerHTML;
      });
      head += '<script src="https://unpkg.com/jquery@2.2.3/dist/jquery.min.js"></script>';
      head += '</head>';

      $fakediv.html($data);
      body += $fakediv[0].innerHTML + '</body>';

      var src = html + head + body + '</html>';
      $container.html($iframe);
      $iframe[0].contentWindow.document.open();
      $iframe[0].contentWindow.document.write(src);
      $iframe[0].contentWindow.document.close();

      return callback($iframe.width(), $iframe.height());

      // Во вьювере
    } else {
      $data = $($data);

      // ================
      // Метод рендера 1
      $fakediv.html($data);

      var $scripts = $fakediv.find('script');
      $scripts.each(function() {
        var script = this,
          inlineScript;

        // Скрипты, у которых есть и src, и тело,
        // разбиваем на два скрипта
        if (script.src && script.text) {
          inlineScript = document.createElement('script');
          inlineScript.text = script.text;
          script.text = '';
          $(inlineScript).insertAfter($(script));
        }
      });

      postscribe($container, $fakediv[0].innerHTML, {
        done: function() {
          return callback(null, null);
        },
      });

      // Чтобы не цеплял дефолтный для вьювера шрифт
      $container.css({
        'font-family': 'initial',
        'font-weight': 'initial',
      });

      // ================
      // Метод рендера 2

      // this.loadNodeWithScripts($container, $data, function() {
      // 	return callback(null, null, true)
      // });

      // return callback(null, null, true)
    }
  },

  // Динамически загружает контент в элемент.
  // Необходимо потому, что динамически добавленные в DOM скрипты
  // исполняются не в порядке добавления, а непредсказуемо
  // По-этому найденные в кастомном коде скрипты будем грузить по очереди
  loadNodeWithScripts: function($parent, $set, callback) {
    var $fakediv = $('<div id="fake"></div>'),
      $allEls,
      $scripts,
      scriptData = [];

    $fakediv.html($set);
    $scripts = $fakediv.find('script');

    // Данные об src и текстах скриптов
    $scripts.each(function() {
      if (this.src && this.text) {
        // Если у скрипта есть и внешний урл, и тело,
        // то будем делать из такого скрипта - два
        scriptData.push({ src: this.src });
        scriptData.push({ text: this.text });
      } else {
        scriptData.push({
          src: this.src,
          text: this.text,
        });
      }
    });

    var scriptWithSrc = _.find(scriptData, function(s) {
      return !!s.src;
    });

    // Если нет скриптов, или нет внешних скриптов,
    // или есть внешний скрипт, но нет других,
    // то не будем ничего оптимизировать, вставляем как есть
    if (!scriptData.length || !scriptWithSrc || scriptData < 2) {
      $parent.html($fakediv[0].innerHTML);
      return;
    }

    // Удаляем все скрипты. Будем вставлять без них
    $scripts.remove();

    var cnt = 0;
    $parent.html($fakediv[0].innerHTML);
    addScript();

    function addScript() {
      var data = scriptData[cnt],
        script,
        waitInterval,
        waitCnt,
        MAX_WAIT_TICKS = 10; // Сколько секунд ждем загрузки очередного скрипта

      if (!data) {
        return callback();
      }
      cnt++;

      script = document.createElement('script');
      if (data.src) {
        script.src = data.src;
      }
      if (data.text) {
        script.text = data.text;
      }

      if (!data.src) {
        // Инлайновый скрипт добавляем без ожидания загрузки
        $parent[0].appendChild(script);
        return addScript();
      } else if (data.src) {
        // Для внешнего скрипта будем ожидать загрузки,
        // и только потом пойдем дальше
        script.onload = function() {
          clearInterval(waitInterval);
          addScript();
        };

        // Если скрипт не грузится, то идем дальше,
        // может остальное заработает
        waitCnt = 0;
        waitInterval = setInterval(function() {
          waitCnt++;
          if (waitCnt > MAX_WAIT_TICKS) {
            script.onload = null;
            addScript();
          }
        }, 1000);

        $parent[0].appendChild(script);
      }
    }
  },
});

// ecommerce
// ====================
EmbedPlugins.Ecommerce = EmbedPlugins.CodeInjection.extend({
  embed_type: 'ecommerce',
});

function sandboxIframe($iframe, values) {
  if (!$iframe) return;

  var forbiddenValues = [
    'allow-top-navigation',
    'allow-top-navigation-by-user-activation',
    'allow-modals',
    'allow-popups',
    'allow-popups-to-escape-sandbox',
  ];
  var defaultValues = ['allow-scripts', 'allow-forms', 'allow-same-origin'];
  var sandboxValues =
    values && Array.isArray(values)
      ? values.filter(function(value) {
          return !_.contains(forbiddenValues, value.toLowerCase());
        })
      : defaultValues;

  $iframe.attr('sandbox', sandboxValues.join(' '));
}

export default EmbedUtils;
