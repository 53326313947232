/**
 * Фоновое видео. Часть блока бэкграунд.
 */
import _ from '@rm/underscore';
import Backbone from 'backbone';
import Widgets from '../../viewer/widgets';

const BackgroundVideoBlock = Backbone.View.extend({
  excludedFromLib: true,

  initialize: function(params) {
    this.parent = params.block;

    this.getEmbed = Widgets.video.prototype.getEmbed;
  },

  render: function() {
    this.parent.$el.find('.video').get(0);
    this.setElement(this.parent.$el.find('.video'));

    if (this.parent.model.get('video')) {
      var data = _.extend({ real: false, id: this.parent.id }, this.parent.model.get('video'));

      this.$el.css({
        opacity: this.parent.model.get('videoopacity'),
      });

      this.$el.find('.container').html(this.getEmbed(data));

      this.rendered = true;
    }
  },

  show: function() {
    if (!this.rendered) this.render();

    this.$el.stop().animate({ opacity: this.parent.model.get('videoopacity') });
  },

  hide: function() {
    this.$el.stop().animate({ opacity: 0 }, { queue: false });
  },

  toggle: function() {
    if (!this.rendered) this.render();

    this.$el.stop().toggleFade();
  },
});

export default BackgroundVideoBlock;
