/**
 *
 * конструктор для виджета Hotspot
 *
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockClass from '../block';
import ShapeSVG from '../../common/shape-svg';
import Viewports from '../../common/viewports';
import HotspotWidgetClass from '../../common/hotspot-widget';
import Workspace_inside_block from '../workspace-inside-block';
import SVGUtils from '../../common/svgutils';

const HotspotBlock = BlockClass.extend(ShapeSVG.prototype).extend(
  {
    name: 'Hotspot',
    sort_index: 8, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'hotspot',

    icon_color: '#B641EA',

    dontSaveOnResize: true,

    viewport_fields: Viewports.viewport_fields.hotspot,

    initial_controls: [
      'hotspot_add',
      'hotspot_pin_settings',
      'hotspot_tip_settings',
      'common_animation',
      'common_position',
      'common_layer',
      'common_lock',
    ],

    settingsOnCreate: false,

    initialize: function(model, workspace) {
      this.mag = workspace.mag;
      this._id = model.get('_id');
      this.hotspots_group_id = model.get('hotspots_group_id');
      this.current_icon_data = null;

      this.initBlock(model, workspace);

      this.initHotspotsCollection();

      // вложенный воркспейс
      this.child_workspace = null;

      this.on_icon_change.__debounced = _.debounce(this.on_icon_change, 20);

      this.rasterizeOnUndo.__debounced = _.debounce(this.rasterizeOnUndo, 20);
    },

    initHotspotsCollection: function(options) {
      options = options || {};

      var hotspots_group_id;

      if (!this.hotspots_group_id) {
        // значит, что хотспот виджет создан из виджет-селектора.
        // такой хотспот виджет задает айди группы
        // для хотспот виджетов, которые будут созданы
        // от него через контрол-плюсик (+).

        hotspots_group_id = 'Hotspots_' + this._id;

        this.model.save(
          {
            hotspots_group_id: this._id,
          },
          {
            skipHistory: true,
            silent: true,
            patch: true,
          }
        );
      } else {
        // значит, что ранее созданный
        // хотспот виджет просто инициализируется,
        // добавляясь в коллекцию
        // или
        // значит, что хотспот виджет создан
        // через контрол-плюсик (+) от другого хотспот виджета
        // и во время создания ему была задана такая же группа.
        // принадлежность к группе означает, что точки
        // этих хотспот виджетов имеют одинаковый визуальный стиль.
        // при изменении стиля точки одного из хотспот виджетов в группе,
        // этот стиль точки автоматически применяется
        // к точкам других хотспот виджетов в группе.

        hotspots_group_id = 'Hotspots_' + this.hotspots_group_id;
      }

      RM.collections[hotspots_group_id] = RM.collections[hotspots_group_id] || new Backbone.Collection();

      this.hotspots_group_collection = RM.collections[hotspots_group_id];

      this.hotspots_group_collection.add(this.model);
    },

    getGroupBlocks: function() {
      return this.workspace.blocks.filter(function(b) {
        return this.hotspots_group_collection.contains(b.model);
      }, this);
    },

    render: function() {
      var model = this.model.attributes;

      this.create();

      this.$el.addClass('hotspot');

      // создаем и изначально отрисовываем  общий какркас виджета.
      // в конструкторе тут на самом деле ничего не происходит практически
      // при изначальном рендере, но в дальнейшем общие методы по отрисовке
      // и позиционированию подсказки используются из общего кода.

      this.hotspotWidget = new HotspotWidgetClass({
        model: model,
        $container: this.$content,
        environment: 'constructor',
        mag: this.mag,
        block: this,
      });

      // изначально отрисовываем точку.
      this.on_icon_change();

      // инициализируем и изначально отрисовываем
      // внутриблоковый воркспейс подсказки (он же и является подсказкой)
      // и вложенные виджеты.
      this.tip_workspace = new Workspace_inside_block({
        // объяснения параметров см. в workspace-inside-block.js → initialize().

        $container: this.$('.common-hotspot'),

        block: this,

        page_workspace: this.workspace,

        resize_handles_positions: ['top', 'right', 'left', 'bottom'],

        // to prevent «download picture» button from cropping.
        min_width: 40,
        min_height: 64, // min compact widget-selector height
      });

      // после того как внутриблоковый воркспейс
      // с вложенными виджетами инициализирован и отрисован
      // вместе с виджет-селекторм,
      // то только теперь мы может точно знать как правильно
      // отпозиционировать подсказку (она же внутриблоковый воркспейс).
      this.hotspotWidget.apply_tip_position(model);

      // кэшируем поиск часто используемых элементов.

      this.bind_model_events();

      this.controls = this.initial_controls;

      // для каждой точки ресайза в DOM проставляет свойство data-visual-direction.
      // в данном случае это делается,
      // чтобы сразу после рендера Кнопки, при наведении на точки ресайза,
      // показывались стрелки, т.к. они в стилях зависят от аттрибута
      // data-visual-direction.
      this.frame.recalcPointsDirections();

      this.listenTo(this.tip_workspace, 'childBlockEvent', this.onChildBlockEvent);

      this.listenTo(
        this.tip_workspace,
        'w-text-entered-edit-mode',
        _.bind(function() {
          this.tip_workspace.$el.addClass('text-edit-mode');
        }, this)
      );

      this.listenTo(
        this.tip_workspace,
        'w-text-leaved-edit-mode',
        _.bind(function() {
          this.tip_workspace.$el.removeClass('text-edit-mode');
        }, this)
      );

      this.triggerReady();
    },

    onChildBlockEvent: function(name, args) {
      // Ловим событие от картиночного виджета
      if (name == 'modechanged') {
        var isCropMode = args[0];
        this.disableDragging = isCropMode;
        this.$el.toggleClass('no-resize', isCropMode);
      }
    },

    // Возвращает массив моделей вложенных блоков без лишних аттрибутов
    getNestedWidgetsJSON: function(dropid = false) {
      var models = (this.model.widgets_collection && this.model.widgets_collection.toJSON()) || [];
      // Т.к. удаленные вложенные виджеты не удаляются из коллекции
      // (вложенные виджеты могут быть удалены только при Undo свеого первого добавления-создания, в остальных случая они скрываются),
      // то проверяем фактическую добавленность вложенного виджета наличием его айдишника в wids.
      models = _.filter(
        models,
        function(model) {
          return this.model.get('wids').indexOf(model._id) > -1;
        }.bind(this)
      );
      return models.map(function(m) {
        return _.omit(m || {}, 'id', '__v', 'pid', 'parent_wid', dropid ? '_id' : undefined);
      });
    },

    getIconStyle: function() {
      if (!this.current_icon_data || !this.current_icon_data.$svg) {
        return BlockClass.prototype.getIconStyle.apply(this, arguments);
      }

      return {
        'background-image': '',
        'background-color': this.icon_color,
      };
    },

    getIconData: function() {
      if (!this.current_icon_data || !this.current_icon_data.$svg) {
        return null;
      }

      var $svg = this.current_icon_data.$svg.clone();
      $svg.attr('fill', '#ffffff').attr('fill-opacity', '1');
      return {
        $svg: $svg,
        pinType: this.model.get('pin_type'),
      };
    },

    get_icon_SVG: function(id, icon_url, callback) {
      if (!id) {
        return;
      }

      var cached_icon_data, req_data, icon_data;

      this.icon_XHR && this.icon_XHR.abort();

      // cмотрим, есть ли данные иконки в кэше.
      cached_icon_data = window.iconSVGCache && _.findWhere(window.iconSVGCache, { id: id });

      if (cached_icon_data) {
        // возвращаем данные иконки из кэша, если там нашлось.

        icon_data = {
          noun_url: cached_icon_data.noun_url,
          $svg: cached_icon_data.$svg,
        };

        // обновляем данные о текущей иконке.
        this.current_icon_data = icon_data;

        return callback(null, icon_data);
      }

      // подготавливаемся к запросу SVG иконки.

      var onSucceess = function(data) {
        // Данные могут быть самые разные, включая SVG на верхнем уровне.
        var $svg = $('<div></div>')
          .append($(data.svg))
          .find('svg');

        if (!$svg.length) return;

        $svg = this.prepareIconSVG($svg);

        icon_data = {
          noun_url: data.new_url,
          $svg: $svg,
        };

        // обновляем данные о текущей иконке.
        this.current_icon_data = icon_data;

        // сохраняем SVG-данные иконки в кэш для последующего реиспользования.
        window.iconSVGCache.push(_.extend(icon_data, { id: id }));

        if (window.iconSVGCache.length > this.MAX_ICON_CACHE_SIZE) {
          window.iconSVGCache.shift();
        }

        return callback(null, {
          $svg: $svg,
          noun_url: data.new_url,
        });
      }.bind(this);

      req_data = {
        method: 'POST',
        url: '/api/authservice/noun/icon',
        data: {
          id: id,
          url: icon_url,
        },
        dataType: 'json',

        success: function(data) {
          this.icon_svg_request_in_progress = false;

          onSucceess(data);
        },

        error: function(xhr) {
          this.icon_svg_request_in_progress = false;

          console.log('icon xhr : ', xhr);

          return callback(xhr);
        },
      };

      // если id иконки начинается с «rm», то это не науновская иконка,
      // а наша и грузить ее нужно по-другому.
      if (/^rm/.test(id)) {
        _.extend(req_data, {
          method: 'GET',
          url: icon_url,

          // иначе хром берет кешированную картинку
          // и не подставляет нужные заголовки для кроссдоменного запроса.
          // и сам же блокирует запрос.
          cache: false,

          success: function(data) {
            this.icon_svg_request_in_progress = false;

            onSucceess({
              svg: $(data).find('svg'),
              new_url: icon_url,
            });
          },
          data: null,
          dataType: null,
        });
      }

      this.icon_svg_request_in_progress = true;

      // делаем запрос на SVG иконки.
      this.icon_XHR = $.ajax(req_data);
    },

    prepareIconSVG: function($raw_svg) {
      var $tmpSVG = $raw_svg.clone().appendTo($('body')),
        $iconSVG = $raw_svg.clone();

      $tmpSVG.get(0).setAttributeNS(null, 'viewBox', '0 0 ' + $tmpSVG.width() + ' ' + $tmpSVG.height());

      var bbox = $tmpSVG.get(0).getBBox();
      $tmpSVG.remove();

      $iconSVG.removeAttr('width');
      $iconSVG.removeAttr('height');

      // именно так, потому что jQuery не может нормально назначить аттрибуты для SVG.
      $iconSVG.get(0).setAttributeNS(null, 'viewBox', bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height);
      $iconSVG.get(0).setAttributeNS(null, 'preserveaspect_ratio', 'xMidYMid meet');

      $iconSVG.css({
        width: '100%',
        height: '100%',
      });

      return $iconSVG;
    },

    render_pin_shape: function(params) {
      params = params || {};

      // вызываем метод генерации svg.
      // он описан в /common/shape-svg.js, потому что он общий и для конструктора и для вьювера.
      var svg = this.generateShapeSVG(
        'constructor',
        this.model,
        params.w || this.model.get('w'),
        params.h || this.model.get('h'),
        params.pin_type
      );

      this.$content.find('.pin').html(svg);
    },

    // изменяет размер блока под размер SVG иконки,
    // вписывая ее в текущий размер блока
    // при этом размер большей из сторон остается неизменным.
    adjust_box_to_icon: function() {
      var $svg,
        aspect_ratio,
        box = this.getBoxData(),
        bbox,
        shift_x,
        shift_y,
        old_w,
        old_h,
        max = Math.max(box.w, box.h);

      $svg = this.$('.pin').find('svg');

      if (!$svg.length) {
        return;
      }

      bbox = $svg.get(0).getBBox();

      aspect_ratio = bbox.width / bbox.height;

      old_w = box.w;
      old_h = box.h;

      if (bbox.width > bbox.height) {
        box.w = max;
        box.h = Math.floor(box.w / aspect_ratio);
      } else {
        box.h = max;
        box.w = Math.floor(box.h * aspect_ratio);
      }

      shift_x = Math.floor((old_w - box.w) / 2);
      shift_y = Math.floor((old_h - box.h) / 2);
      box.x = box.x + shift_x;
      box.y = box.y + shift_y;

      return box;
    },

    rasterize_icon_SVG: function() {
      var $svg = this.$('.pin').find('svg');
      if (!$svg.length) return;

      this.rasterizeXhr && this.rasterizeXhr.abort();

      this.rasterizeXhr = SVGUtils.rasterize({
        widgets: this.hotspots_group_collection.models,
        svg: $svg,
        mid: this.workspace.mag.get('_id'),
        viewport: this.model.getViewport(),
        size: {
          width: this.model.get('w'),
          height: this.model.get('h'),
        },
      });
    },

    getPeerIds: function() {
      return _.map(this.hotspots_group_collection.models, function(model) {
        return model.get('_id');
      });
    },

    onResizeStart: function(event, drag) {
      this.rasterizeXhr && this.rasterizeXhr.abort();
    },

    // Переопределяем базовую функцию,
    // чтобы подправить аттрибуты бокса перед сохранением,
    // если тип точки — иконка.
    onResizeEnd: function(event, drag, options) {
      options = options || {};

      // сохраняем новые размеры и положения для всех хотспотов в группе
      var group_changeset = [];

      this.hotspots_group_collection.models.forEach(
        function(model) {
          var block = this.workspace.findBlock(model.get('_id'));

          if (block) {
            group_changeset.push(
              _.extend(
                {
                  _id: model.get('_id'),
                },
                block.adjust_box_to_icon()
              )
            );
          }
        }.bind(this)
      );

      // делаем групповой сет для остальных хотспотов в группе.
      options.isGroupResize
        ? this.workspace.set_group(group_changeset, options)
        : this.workspace.save_group(group_changeset, options);

      if (this.model.get('pin_type') === 'icon') {
        // растеризуем иконку и сохраняем для всех хотспотов в группе
        this.rasterize_icon_SVG();
      }
    },

    css: function(params, currentResizePoint) {
      params = params || {};

      // если виджет выделен, то завышаем уме з-индекс,
      // чтобы подсказка была выше других виджетов.
      if (this.selected) {
        params['z-index'] = this.MAX_Z_INDEX;
      } else {
        params['z-index'] = this.model.get('z');
      }

      BlockClass.prototype.css.apply(this, [params, currentResizePoint]);

      if (params.height != undefined && params.width != undefined) {
        this.render_pin_shape({
          w: params.width,
          h: params.height,
        });
      }

      if (currentResizePoint || params.isGroupResize) {
        // значит, что .css на этот точку хотспота был
        // вызван напрямую во время ресайза,
        // а раз так, то делаем такой же css
        // ширины и высоты для других точек в группе.

        this.hotspots_group_collection.models.forEach(
          function(model) {
            var id = model.get('_id');
            // Ресайзить если это не один и тот же блок, а если групповой ресайз, то этого блока нет в группе (если есть в группе — и так ресайзится)
            var blockNotInGroup = params.groupedIds && params.groupedIds.indexOf(id) === -1;
            if (this.model.get('_id') !== id && (!params.isGroupResize || blockNotInGroup)) {
              // для текущего блока уже не надо запусать .css().

              var block = this.workspace.findBlock(id);

              // эти точки в свою очередь
              // уже не запустят такой перерисовки для других в группы,
              // т.к. аргумент currentResizePoint не передается.
              block &&
                block.css({
                  width: params.width,
                  height: params.height,
                  doNotRedrawPacksFrames: params.doNotRedrawPacksFrames,
                });
            }
          }.bind(this)
        );
      }
    },

    // вся перерисовка Хотспота происходит исключительно
    // по событияем изменения модели, чтобы корректно отрабатывал Undo/Redo, который
    // пачкой откатывает все model.set, сделанные к моменту последнего model.save.
    // также удобно то, что все отрисовочные методы находятся в общем коде
    // для Конструктора и Вьювера common/hotspot-widget.js. эти отрисовочные методы
    // отрабатывает исключительно от переданных данных модели,
    // что и придает им универсальность.
    bind_model_events: function() {
      this.model.on('change:tip_background-color change:tip_background-color-opacity', this.apply_tip_bg_color, this);

      this.model.on('change:tip_border-radius', this.apply_tip_border_radius, this);

      this.model.on('change:tip_box-shadow', this.apply_tip_box_shadow, this);

      // c дебонсом, т.к. эти изменения приходят вместе друг за другом.
      this.model.on(
        'change:noun_id change:rm_id change:noun_url change:pin_type change:borders change:color change:opacity change:bg_color change:bg_opacity',
        this.on_icon_change.__debounced,
        this
      );

      // c дебонсом, т.к. эти изменения приходят вместе друг за другом.
      this.model.on(
        'change:noun_id change:rm_id change:noun_url change:pin_type change:bg_color change:bg_opacity change:w change:h',
        this.rasterizeOnUndo.__debounced,
        this
      );

      // /gwidget - предварительные заготовки. Могут поменяться
      // this.model.on('change:is_global', this.on_global_change.__debounced) // менять для всей группы хотспотов
      // this.model.on('change:is_above_pages', this.on_above_pages_change.__debounced) // менять для всей группы хотспотов
    },

    // растеризация не пишется в историю так как происходит асинхронно после всех сейвов
    // поэтому при ундо/редо операциях (но не при тимворке!) также перезапускать растеризацию
    // но только для первого хотспота в группе, так как иконки у всех одинаковые и первый растеризует себе и остальным
    rasterizeOnUndo: function(model, value, options) {
      if (!options.undo && !options.redo) return;

      if (this.model.get('pin_type') != 'icon') return;

      if (this.hotspots_group_collection.at(0) != this.model) return;

      this.rasterize_icon_SVG();
    },

    on_icon_change: function() {
      this.determine_is_block_proportional();

      var pin_type = this.model.get('pin_type');

      if (pin_type === 'ellipse' || pin_type === 'rectangle') {
        this.render_pin_shape();
      }

      if (pin_type === 'icon') {
        // также важно, что get_icon_SVG обновляет данные this.currentIconData.
        this.get_icon_SVG(
          this.model.get('noun_id') || this.model.get('rm_id'),
          this.model.get('noun_url'),
          function(err, data) {
            if (err || !data.$svg) return;
            this.render_pin_shape();
          }.bind(this)
        );
      }
    },

    apply_tip_bg_color: function() {
      this.hotspotWidget.apply_tip_bg_color(this.model.attributes);
    },

    apply_tip_border_radius: function() {
      this.hotspotWidget.apply_tip_border_radius(this.model.attributes);
    },

    apply_tip_box_shadow: function() {
      this.hotspotWidget.apply_tip_box_shadow(this.model.attributes);
    },

    determine_is_block_proportional: function() {
      var pin_type = this.model.get('pin_type');

      // точка хотспота типа «ellipse» всегда круглая,
      // поэтому блок  должен быть пропорциональный.
      // с типом «icon» тоже.
      this.proportional = pin_type === 'ellipse' || pin_type === 'icon';
    },

    apply_tip_container_size: function(data) {
      this.hotspotWidget.apply_tip_container_size(data);
    },

    apply_tip_position: function() {
      var model = this.model.attributes;
      if (!this.hotspotWidget) {
        console.log(this.model, this, 'NO Hotspot widget');
        return;
      }

      // применяем общий метод отрисовки
      // для конструктора и вьювера.
      this.hotspotWidget.apply_tip_position(model);
    },

    // вызывается каждый раз когда выделяем виджет(ы) на рабочей области
    // кликом, кликом с шифтом, рамкой выделения
    // (если в результате перечисленных манипуляций не оказывается ни одного виджета в выделении, тогда это событие не стреляет)
    onWorkspaceBlocksSelect: function(blocks) {
      BlockClass.prototype.onWorkspaceBlocksSelect.apply(this, arguments);

      // защита от ситуация когда два текстовых виджета могут находиться в состоянии редактирования
      // дело в том, что если делать клик по виджету с зажатым шифтом, то у нас это означает добавить/удалить из текущего списка выделенных виджетов
      // а при таком действии deselect не вызывается (смотрим block.js) и соответственно если у нас уже есть текстовый виджет который в режиме редактирования
      // и мы делаем двойной клик с зажатым шифтом по другому текстовому виджету, то старый текстовый виджет не выходит из режима редактирования так как нет события deselect

      if (!this.rendered) return;

      // смотрим что у нас в текущем выделении текущий блок или еще что-то
      var isOnlyMeSelected = blocks && blocks.length == 1 && blocks[0] == this;

      // если страница не заблокирована
      if (!RM.constructorRouter.isPageLocked(this.model.get('pid'))) {
        // показываем подсказку при выделении виджета только если он один в выделении
        // иначе скрываем уже открытую
        // на deselect тоже код закрытия, потому что onWorkspaceBlocksSelect не выстреливает если вообще нет выделенных блоков
        this.toggleTip(isOnlyMeSelected);
      }
    },

    // метод переопределен,
    // чтобы скрывать подсказку при деселекте хотспота.
    deselect: function() {
      BlockClass.prototype.deselect.apply(this, arguments);
      this.toggleTip(false);

      this.child_workspace && this.child_workspace.onDeselect();
    },

    // показывает или скрывает подсказку в зависимости от флага state
    toggleTip: function(state) {
      this.$('.tip').css('visibility', state ? 'inherit' : 'hidden'); // чтобы при ундо редо внутри вложенных виджетов правильно производилсь расчеты

      // чтобы з-индекс переставился
      // см. объяснение в this.css()
      this.css();

      if (this.tip_workspace && this.tip_workspace.resize_handles_manager) {
        state ? this.tip_workspace.resize_handles_manager.show() : this.tip_workspace.resize_handles_manager.hide();
      }
    },

    destroy: function() {
      this.stopListening(this.tip_workspace);

      this.tip_workspace && this.tip_workspace.destroy();

      this.hotspotWidget && this.hotspotWidget.destroy();
      this.hotspotWidget = null;

      this.rasterizeXhr && this.rasterizeXhr.abort();

      this.icon_XHR && this.icon_XHR.abort();

      // удаляем всех слушателей изменений модели,
      // созданные этой вьюхой.
      this.model.off(null, null, this);

      // удаляем модел из коллекции хотсвотоп объединенных в группу
      // удалять саму коллекцию при удалении из нее последного хотспота смысла нет
      // поскольку эта коллекция не сохраняется на сервере и при каждом заходе генерится заново (т.е. чисто для рантайм нужд)
      this.hotspots_group_collection && this.hotspots_group_collection.remove(this.model);

      BlockClass.prototype.destroy.apply(this, arguments);
    },
  },
  {
    defaults: {
      // тип точки.
      pin_type: 'ellipse', // «ellipse» — круг, «rectangle» — прозрачная область || «icon»

      // размеры точки.
      w: 30,
      h: 30,

      // стили точки.
      bg_color: '0078FF',
      bg_opacity: 1,
      borders: 0,
      color: '000000',
      opacity: 1,
      tip_show_on: 'click',

      // noun_id: null,
      // rm_id: null,
      // noun_url: null,

      // хотспот с типом «icon» растеризуется в .png для вьювере.
      // rasterUrl: null,
      // raster2xUrl: null,

      // по центру оси x холста.
      x: Math.round(1024 / 2 - 30 / 2),

      // размеры подсказки.
      tip_w: 240,
      tip_h: 120,

      // настроки подсказки.
      tip_pos: 'top',
      'tip_background-color': 'ffffff',
      'tip_background-color-opacity': 100,
      'tip_border-radius': 8,
      'tip_box-shadow': true,

      // массив под айдишники вложенных виджетов.
      wids: [],
    },
  }
);

export default HotspotBlock;
