/**
 * Виджет Твиттер изображений
 */
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import ShareUtils from '../../libs/shareutils';
import InitUtils from '../../common/init-utils';
import templates from '../../../templates/constructor/blocks/twitter.tpl';

const TwitterWidget = WidgetClass.extend({
  CONTENT_WAIT_TICK: 100, // Интервал между опросами iframe на наличие контента
  CONTENT_WAIT_MAX_TICKS: 20, // Максимальное кол-во интервалов для опросов
  CONTENT_IMG_WAIT: 1000, // Таймаут для ожидания загрузки картинок в виджет после появления самой разметки.

  DO_NOT_WAIT_FOR_PRELOAD: true, // флаг что при прелоадинге виджетов ждать его загрузки не надо (но не для скриншотера)

  // достаточно версии прототипа
  // initialize: function(data, page) {},

  render: function() {
    this.makeBox('widget-twitter');

    this.rendered = true;

    var template, data;

    if (!this.current_type) {
      this.widgetIsLoaded();
      return this;
    }

    data = this[this.current_type];

    // Задаем перманентный урл мэга или страницы
    if (data.use_own_url === 'mag') {
      data.url = this.page.mag.getUrl();
    } else if (data.use_own_url === 'page' || !data.url) {
      data.url = this.page.getUrl();
    }

    if (!RM.screenshot)
      data.text = ShareUtils.getFinalShare(
        this.page,
        _.extend(this.page.mag.model.toJSON(), { user: this.page.mag.user }),
        { forProject: data.use_own_url === 'mag', customDomain: RM.common.isDomainViewer }
      ).tweet;

    template = templates['template-constructor-block-twitter-' + this.current_type];

    this.$el.html(template(data));

    // Проставляем ширину и высоту для таймлайна в аттрибутах. Твиттер их учтет при формировании iframe
    if (this.current_type === 'type_timeline') {
      this.$el.find('a').attr({
        width: this.w,
        height: this.h,
      });
    }

    InitUtils.initTwitterAPI(
      function() {
        if (this.destroyed) return;

        window.twttr && window.twttr.widgets && _.isFunction(window.twttr.widgets.load) && window.twttr.widgets.load();

        // TODO: Пока понятно, как отлавивать загрузку контента тольк для таймлайна и единичного
        // твитта, т.к. мы можем лазать в их iframe. Найти способ делать это и для кнопок.
        if (_(['type_timeline', 'type_embed']).contains(this.current_type)) {
          this.waitForTwitterContent();
        } else {
          this.widgetIsLoaded();
        }
      }.bind(this)
    );

    return this;
  },

  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  // достаточно версии прототипа
  // isValid: function() {},

  // достаточно версии прототипа
  // start: function() {},

  // достаточно версии прототипа
  // stop: function() {},

  // достаточно версии прототипа
  // destroy: function() {},

  // Ожидает появления контента твиттера или окончания макс. интервала ожидания
  // После чего сообщает о загрузке контента виджета
  waitForTwitterContent: function() {
    var interval,
      counter = 0,
      $frame,
      content_ready = false,
      selector,
      type_selectors = {
        // CSS селекторы, которые ищем в iframe  для каждого типа твиттера
        type_timeline: '.timeline-Widget',
        // 'type_embed'		:'.root.standalone-tweet',
        type_embed: 'blockquote.tweet',
        // 'type_tweet'		:'.btn-o',
        // 'type_follow'		:'.btn-o',
        // 'type_hashtag'		:'.btn-o'
      };

    clearInterval(interval);
    interval = setInterval(
      _.bind(function() {
        counter++;

        if (this.destroyed) {
          clearInterval(interval);
          return;
        }

        $frame = this.$el.find('iframe');
        if ($frame.length == 1) {
          selector = type_selectors[this.current_type];
          content_ready = $frame.contents().find(selector).length > 0;
        }

        if (content_ready || counter > this.CONTENT_WAIT_MAX_TICKS) {
          // Для единичного твитта убираем у iframe вредные параметры и устанавливаем нужные
          // только после того, как сформирована реальная высота за счет контента
          if (this.current_type == 'type_embed') {
            $frame.css({
              margin: 0,
              'max-width': '100%',
              // 'height': '100%'
              // '-webkit-transform': 'none' // Убираем трансформацию, чтобы не забивать видеопамять. Она была нужна на момент отрисовки, чтобы не глючили рамки
            });
          }

          if (this.current_type == 'type_timeline') {
            console.log('$frame : ', $frame);
            $frame.css({
              width: '100%',
              height: '100%!important',
              'max-width': '100%',
            });
          }

          clearInterval(interval);

          setTimeout(
            _.bind(function() {
              this.widgetIsLoaded();
            }, this),
            this.CONTENT_IMG_WAIT
          );
        }
      }, this),
      this.CONTENT_WAIT_TICK
    );
  },
});

export default TwitterWidget;
