<template>
  <input
    :value="computedValue"
    class="param-input"
    type="text"
    spellcheck="false"
    :data-useFloat="useFloat"
    :data-step="step"
    :data-min="min"
    :data-max="max"
    :data-mouseDir="dir"
    :data-mouseSpeed="speed"
    :data-shiftStep="shiftStep"
    :data-autofocus="autofocus"
    :data-autoSize="autosize"
    :wait="wait"
    :data-mouseOnly="mouseOnly"
    :readonly="readonly || mouseOnly"
    :disabled="disabled"
    :data-mouseUse="mouseUse + ''"
    :data-thousandDelimiter="thousandDelimiter"
    :data-debounceKeyboard="debounceKeyboard"
  />
</template>

<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import Vue from 'vue';
import { Utils } from '../../js/common/utils';

export default {
  // двухсторонний биндинг
  // https://vuejs.org/v2/guide/components.html#Customizing-Component-v-model
  model: {
    event: 'change',
  },

  props: {
    value: Number,
    autofocus: {
      type: Boolean,
      default: false,
    },
    autosize: {
      type: Boolean,
      default: false,
    },
    wait: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: Number.NEGATIVE_INFINITY,
    },
    max: {
      type: Number,
      default: Number.POSITIVE_INFINITY,
    },
    dir: {
      type: String,
      default: 'v',
    },
    speed: {
      type: Number,
      default: 3,
    },
    step: {
      type: Number,
      default: 1,
    },
    labelSelector: {
      type: String,
      default: '',
    },
    shiftStep: {
      type: Number,
      default: 10,
    },
    useFloat: {
      type: Boolean,
      default: false,
    },
    mouseOnly: {
      type: Boolean,
      default: false,
    },
    mouseUse: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    thousandDelimiter: {
      type: String,
      default: '',
    },
    debounceKeyboard: {
      type: Number,
      default: undefined,
    },
  },

  computed: {
    computedValue: {
      get: function() {
        var value;

        if (this.$$el) {
          value = this.$$el.data('formatValue')(this.value);
        } else {
          value = this.value;
        }

        if (this.autosize) {
          Vue.nextTick(() => {
            Utils.setInputSize($(this.$el), 150);
          });
        }

        return value;
      },
    },
  },

  watch: {
    mouseUse: function(val) {
      Vue.nextTick(() => {
        var destroy = this.$$el.data('destroy');
        if (_.isFunction(destroy)) {
          destroy();
        }
        $(this.$$el).RMNumericInput({ onChange: this.onChange, labelSelector: this.labelSelector });
      });
    },
    min: function(val) {
      this.$$el.data('changeMinMax')(val);
    },
    max: function(val) {
      this.$$el.data('changeMinMax')(undefined, val);
    },
  },

  mounted: function() {
    // $el — HTMLElement
    this.$$el = $(this.$el);

    var init = function() {
      $(this.$$el).RMNumericInput({ onChange: this.onChange, labelSelector: this.labelSelector });
      this.$$el.data('formatValue')(this.value);
      if (this.autosize) {
        Utils.setInputSize($(this.$el), 150, this.$$el.val()); // Именно по фактичкскому содержимого инпута
      }
    }.bind(this);

    // Если нужно, дождаться окончания транзишена, и потом инициализировать
    if (this.wait) {
      Utils.waitForTransitionEnd(
        this.$$el,
        this.wait,
        'opacity',
        function() {
          init();
        }.bind(this)
      );
    } else {
      init();
    }
  },

  methods: {
    onChange: function($input, value) {
      if (!this.disabled) {
        this.$emit('change', value);
      }
    },

    updateSize: function() {
      Utils.setInputSize(this.$$el, 200);
    },
  },
};
</script>

<style lang="less">
.param-input {
  &.inactive {
    opacity: 0.5;
  }
}
</style>
