/**
 * Виджет iFrame
 */
import WidgetClass from '../widget';
import _ from '@rm/underscore';
import embedutils from '../../common/embed-plugins';

const iframeWidget = WidgetClass.extend({
  // достаточно версии прототипа
  // initialize: function(data, page) {},

  render: function() {
    this.makeBox('widget-iframe');

    this.rendered = true;

    var pluginClass = this.getPluginClass();

    if (!pluginClass) {
      this.widgetIsLoaded();
      return this;
    }

    this.plugin = new pluginClass({ widget: this });
    this.$el.addClass(this.plugin.embed_type);

    if (this.started) {
      this.drawPlugin();
    } else {
      this.contentCleared = true; // Чтобы сработал drawPlugin при старте
      // если же нам сказали рендер, но мы видим что виджет не просили активизироваться (!this.started)
      // это значит что рендерим не на той странице которая сейчас по центру экрана
      // для режима вьювера выстрелим событие загрузки сразу, чтобы не затупил прелоадер
      // а скриншотер все нормально отработает и дождется лоада, поскольку у него одна страница и она и стартится и рендерится
      !RM.screenshot && !this.drawInProgress && this.widgetIsLoaded();
    }

    return this;
  },

  // запуск фактического рендера эмбеда
  // можеть быть запущен из render() или из start()
  drawPlugin: function() {
    // вычищаем и отключаем старое наследие.
    this.$el.empty();

    this.contentCleared = false;
    this.drawInProgress = true;

    this.plugin.parse(
      this.code,
      function(parsed) {
        // небходимо делать каждый раз при запуске drawPlugin, т.к. санизированный
        // элемент может быть удален из ДОМа и больше быть не доступен по this.$data
        this.$data = this.plugin.sanitize(parsed);

        if (RM.screenshot) {
          setTimeout(
            _.bind(function() {
              this.widgetIsLoaded();
            }, this),
            5000
          );
        }

        this.plugin.draw(
          this.$data,
          this.$el,

          function(w, h) {
            var timeout = RM.screenshot ? 5000 : 2000;

            this.loadTimeout = setTimeout(
              function() {
                this.drawInProgress = false;

                if (this.destroyed) return;

                // хватит крутить спиннер, что бы ни было.
                this.widgetIsLoaded();

                this.$data.off && this.$data.off('load');

                // Пока отрисовывались, виджет успели застопить
                if (!this.started) {
                  this.clearContent();
                }
              }.bind(this),
              timeout
            );
          }.bind(this)
        );

        if (!RM.screenshot) {
          if (!_.isFunction(this.$data.one)) {
            return;
          }

          this.$data.one(
            'load',
            function() {
              this.drawInProgress = false;

              if (this.destroyed || !this.started) {
                return;
              }

              this.widgetIsLoaded();

              clearTimeout(this.loadTimeout);
            }.bind(this)
          );
        }
      }.bind(this)
    );
  },

  // версия прототипа не подходит, т.к. она запускает start(),
  // а у нас в старте, если что и происходит, то drawPlugin или прицепление.
  widgetIsLoaded: function() {
    if (this.loaded || this.destroyed) return;

    this.loaded = true;
    this.trigger('loaded');
  },

  isValid: function() {
    return this.code && this.getPluginClass();
  },

  start: function() {
    this.started = true;

    if (this.destroyed || !this.rendered) return this;

    if (this.contentCleared) {
      this.drawPlugin();
    }

    return this;
  },

  stop: function() {
    this.started = false;

    // стоп может запуститсья раньше рендера, где определяется плагин.
    if (!this.plugin) return this;

    this.widgetIsLoaded();

    // Чтобы не валились ошибки во время отрисовки. По окончании отрисовки,
    // виджет сам увидит, что застоплен и очистит контент
    if (this.drawInProgress) {
      return;
    }

    this.clearContent();

    return this;
  },

  clearContent: function() {
    if (!this.clear_content) {
      return;
    }

    this.$el.empty();
    this.contentCleared = true;
  },

  getPluginClass: function() {
    return embedutils.findPlugin(this);
  },

  destroy: function() {
    this.clearContent();

    if (this.plugin) {
      // удаляем вьюху стандартным методом бекбона:
      // удаляет елемент $el из дум-дерева, удаляет всех слушателей которые вьюха создавала через listenTo (но не через on, естественно)
      this.plugin.remove();
    }

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default iframeWidget;
