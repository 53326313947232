/**
 * Класс для главного вида мэга
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import Analytics from '../common/analytics_connector';
import ServicePage from '../common/service-pages';
import Alert from '../common/alert';
import Login from '../common/login';
import ShareUtils from '../libs/shareutils';
import MagClass from '../viewer/mag';
import CreateMag from '../common/create-mag';
import MagPasswordClass from '../viewer/mag-password';
import { Utils, Constants } from '../common/utils';

const ViewerRouter = Backbone.Router.extend({
  /**
   * Возможные адреса
   */
  routes: {
    ':user(/)(:mag)(/)(:pagenum)(/)': 'start',
  },

  mags: {},

  initialize: function() {
    Modernizr.addTest('chrome', function() {
      return _.isObject(window.navigator) && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    });

    // Адреса в виде регекспов
    this.route(/^(\d+)\/*(\d*)\/*$/, 'start'); // :mag/:page

    // в режиме кастомного домена добавляем пустой маршрут
    if (RM.common.isDomainViewer) {
      this.route('(:mag)(/)(:pagenum)(/)', 'start');
    }

    if (RM.common.isDownloadedSource) {
      this.route('(:pagenum)', 'start');

      if (/msie [6-9]/i.test(navigator.userAgent)) {
        location.href = 'sorry.html';
      }
    }

    $('html').toggleClass('viewer-embed-mode', !!RM.common.embedMode);

    // в режиме кастомного домена грузим залогиненого бзера по особенному
    // через ифрейм из-за кукисов которые есть только для readymag.com

    _.bindAll(this);

    this.environment = Constants.environment.viewer;

    this.started = false;

    if (!RM.common.isDownloadedSource && RM.data.usersLoader.me) {
      this.me = RM.data.usersLoader.me;
    }

    this.$mags_container = $('#mags');

    this.on('route', this.onRoute);

    this.magsData = window.ServerData && window.ServerData.mags;

    this.internalHistory = []; // Используется для ссылок типа Go Back, т.к. перемещение по страницам не попадает в историю Backbone

    if (this.magsData) {
      // закешировать то, что было получено с сервера
      this.cachedMag = this.magsData.mag;

      if (!this.cachedMag || !this.cachedMag.user || !this.cachedMag.user._id) return;

      if (!RM.common.isDownloadedSource) {
        this.cachedMag.user.isMe = this.me && this.me.get('_id') == this.cachedMag.user._id; // в превью режиме юзер this.me уже есть
      }

      // в режиме кастомного домена то сохраняем ури пользователя и мега
      // для дальнейшего использования
      if (RM.common.isDomainViewer) {
        this.magUri = this.cachedMag.uri;
        this.userUri = this.cachedMag.user && this.cachedMag.user.uri;
      }
    }

    if (!RM.screenshot) {
      RM.analytics = RM.analytics || new Analytics({ router: this });
      this.analytics = RM.analytics;

      if (!RM.common.isDomainViewer) {
        Utils.processCustomFacebookEventCookies();
      }
    }

    if (!this.isPreview) {
      window.sessionStorage && window.sessionStorage.removeItem('constructorViewport');
    }
  },

  /**
   * Переход на заданную страницу.
   * Используется в начале.
   */
  start: function(user, mag, pagenum) {
    // проверяем на ошибки в режиме кастомного домена
    if (
      RM.common.isDomainViewer &&
      (this.magsData.magNotFound ||
        this.magsData.domainNotFound ||
        this.magsData.magNotPublished ||
        this.magsData.subscriptionExpired)
    ) {
      var pageDomainError = new ServicePage({
        type: 'domain-error',
        $container: $('#service-pages'),
        templateData: {
          state: this.magsData,
        },
      });

      pageDomainError.render();

      return;
    }

    if (Modernizr.msie || Modernizr.msie11) {
      var alertIE = new Alert({
        $parent: $('body > .popups'),
        router: this,
        opts: { type: 'type-browsers-viewer' },
      });
      alertIE.render();
      return;
    }

    // в режиме кастомного домена работаем с урлами по другому
    if (RM.common.isDomainViewer && !RM.common.customDomainProfile) {
      if (parseInt(user, 10) == user || !user) {
        // Если первая часть урла состоит только из цифр (или если пусто), то это номер страницы
        pagenum = user;
        user = this.userUri;
        mag = this.magUri;
      } else if (/^p\d+\/?$/i.test(user)) {
        // если p<цифры>, то это короткий урл страницы,
        pagenum = null;
        mag = user;
        user = null;
      } else {
        pagenum = user;
        user = this.userUri;
        mag = this.magUri;
      }
    } else if (RM.common.isDownloadedSource) {
      pagenum = user;
      (user = null), (mag = null);
    } else {
      // Если состоит только из цифр, то это мэг, если p<цифры>, то это короткий урл страницы
      if (parseInt(user, 10) == user || /^p\d+\/?$/i.test(user)) {
        var _temp = mag;
        mag = user;
        pagenum = _temp;
        user = null;
      } else if (RM.common.customDomainProfile) {
        // В урле передан uri мэга
        var _temp = mag;
        mag = user;
        pagenum = _temp;
        user = this.userUri; // Задан при начальной загрузке страницы кастомного домена
      } else if (!mag) {
        return this.notFound();
      }
    }

    this.latestFragment = Backbone.history.getFragment();

    pagenum = pagenum || 1;

    var mag_hash = user + '_' + mag;

    this.username = user;

    // Не загружать, если уже загружаем
    if (this.started == mag_hash && this.loaded != mag_hash) return;

    this.started = mag_hash;

    // реакция на изменения адреса в строке браузера: смена страницы
    if (this.loaded == this.started) {
      this.go(pagenum, { animation: false }); // Раньше была анимация, если переходим на соседнюю страницу, но отменили https://trello.com/c/S0PD0RVa/262--
      return;
    }

    if (this.cachedMag && !_.isEmpty(this.cachedMag)) {
      if (!(this.cachedMag instanceof MagClass) && !this.cachedMag.forbidden && !this.cachedMag.is_suspended) {
        this.cachedMag = new MagClass(this.cachedMag, this);
      }

      this.mag = this.cachedMag;

      this.onLoadMag(pagenum);
      return;
    }

    this._loadingMagDataXHR && this._loadingMagDataXHR.abort();

    // Запросится только для запароленного мэга
    this._loadingMagDataXHR = this.fetchData(this.username, mag, {
      success: _.bind(function(result) {
        if (this.mag && this.mag.num_id && result && result.num_id != this.mag.num_id) window.location.reload(); // обрабатываем кнопки Back / Forward в браузере

        this.mag = new MagClass(result, this);

        this.cachedMag = this.mag;

        this.onLoadMag(pagenum);
      }, this),

      error: this.notFound,
    });
  },

  onLoadMag: function(pagenum) {
    var _pagenum;

    this.loaded = this.started || true;

    if (this.mag.forbidden) {
      this.requestMagPassword(this.latestFragment, _.clone(this.mag));
      delete this.cachedMag; // Удаляем мэг, чтобы после авторизации его снова загрузило
      delete this.loaded;
      delete this.started;
      return;
    }

    if (this.mag.is_suspended) {
      this.showSuspended();
      return;
    }

    if (!RM.common.isDownloadedSource) {
      $.post('/api/countview/' + this.mag.num_id); // Счетчик просмотров
      // дублируем просмотры проектов в аналитику с помощью кастомой метрики
      RM.analytics && (RM.analytics._triggerProjectView = true);
    }

    // Если в данных мэга есть _requested_page - номер запрошенной страницы, значит зашли по прямому урлу страницы
    if (this.mag._requested_page) {
      _pagenum = this.mag._requested_page;
      delete this.mag._requested_page; // На всякий случай удаляем, чтобы больше не влияло на навигацию
    } else {
      _pagenum = pagenum;
    }

    Modernizr.addTest('screenshot-mode', function() {
      return RM.screenshot;
    });

    this.mag.render();
    this.go(_pagenum, { animation: false });

    RM.common.mode = 'mag';
  },

  getAnalyticsDimensions: function() {
    return {
      magId: (this.mag && this.mag.model && this.mag.model.get('num_id')) || (this.mag && this.mag.num_id),
      magCreatorId: (this.mag && this.mag.model && this.mag.model.user.get('_id')) || (this.mag && this.mag.user._id),
      pageType: 'viewer',
      userId: this.me && this.me.get('_id'),
    };
  },

  /**
   * Загружает данные мэга ajax запросом
   */
  fetchData: function(user, mag, options) {
    var options = options || {};

    if (user) {
      options.url = '/api/readymag/' + user + '/' + mag;
    } else {
      options.url = '/api/readymag/' + mag;
    }

    return $.ajax(options);
  },

  sendReadyEvent: function(opts) {
    opts = opts || {};
    opts = _.defaults(opts, { page: {} });

    // TODO: === Выпилить старый метод нотификации готовности. Он для старого скриншотера
    var element = document.createElement('MyExtensionDataElement');

    element.setAttribute('width', opts.page.width);
    element.setAttribute('height', opts.page.height);
    element.setAttribute('_id', opts.page._id);

    document.documentElement.appendChild(element);

    var evt = document.createEvent('Events');

    if (opts && opts.error) evt.initEvent('MyErrorEvent', true, false);
    else evt.initEvent('MyExtensionEvent', true, false);
    element.dispatchEvent(evt);
    // end TODO ===

    // chrome-remote-interface adding this loadNotifier
    if (window.loadNotifier) {
      if (opts.error) {
        return window.loadNotifier({ error: opts.error });
      }
      window.loadNotifier({ width: opts.page.width, height: opts.page.height });
    }
  },

  /**
   * Устанавливает заголовок окна
   */
  setTitle: function() {
    if (!this.mag || !this.mag.currentPage) return;

    var mag = _.extend({}, this.mag.model.toJSON());
    mag.user = this.mag.user;

    // эскейпить не надо, должно автоматом эскейпить
    document.title = ShareUtils.getFinalSEO(this.mag.currentPage, mag, {
      customDomain: !!RM.common.isDomainViewer,
    }).title;
  },

  /**
   * Трекает каждый переход на страницу для создателя мэга в гугль аналитике
   */
  trackPage: function() {
    if (!RM.screenshot) {
      _.defer(
        _.bind(function() {
          this.analytics && this.analytics.trackPage();
        }, this)
      );
    }
  },

  /**
   * Переходит на заданную страницу. Если такая страница найдена.
   */
  go: function(pageuri, params) {
    pageuri = pageuri || 1;

    this.mag.showPage(pageuri, params);
  },

  goBack: function() {
    var pageuri = this.internalHistory[this.internalHistory.length - 2];
    if (pageuri) {
      pageuri && this.go(pageuri, { skipInternalHistory: true });
      this.internalHistory.pop(); // Удаляем из истории страницу, на которой были перед переходом по Go Back
    }
  },

  /**
   * Изменить номер страницы в урле
   */
  setUrlString: function(options) {
    if (this.noUrlRewrite) {
      return;
    }

    options = options || {};
    if (RM.screenshot) return;

    var urlFragment,
      page = this.mag.currentPage;

    // в режиме кастомного домена мэга (но не профиля) урл мега будет пустой
    if ((RM.common.isDomainViewer && !RM.common.customDomainProfile) || RM.common.isDownloadedSource) {
      urlFragment = '';
    } else if (RM.common.customDomainProfile) {
      urlFragment = this.getMagUri(this.mag);
    } else {
      if (this.username) urlFragment = this.mag.user.uri + '/' + (this.mag.uri || this.mag.num_id) + '/';
      else urlFragment = this.isPreview ? this.mag.num_id + '/' : this.getMagUri(this.mag);
    }

    if (this.isPreview) urlFragment += 'preview/';

    if (page && page.num != '1') {
      urlFragment += (page.uri || page.num.toString()) + '/';
    }

    if (!options.skipInternalHistory) {
      this.internalHistory.push(page.num.toString());
    } else {
      // Переход по Go Back ссылке виджета
      RM.analytics && RM.analytics.sendEvent('Back Link', urlFragment);
    }

    if (urlFragment === Backbone.history.getFragment()) return;

    var oldPageNum = parseInt(_.last(Backbone.history.getFragment(), '/')) || 1;
    var replace = oldPageNum == page.num || options.replace;

    if (RM.common.isDownloadedSource && _.last(urlFragment) !== '/') {
      urlFragment += '/';
    }

    // Если страница инжектится в homepage, то не нужно менять роут
    if (RM.common.homepageRewrite) return;

    this.navigate(urlFragment, { replace: replace });
  },

  getPreviousFragment: function() {
    return this.internalHistory[this.internalHistory.length - 2];
  },

  getMagUri: function(mag) {
    if (RM.common.customDomainProfile) {
      return (mag.uri || mag.num_id) + '/';
    }

    // в режиме кастомного домена урл мега будет пустой
    else if (RM.common.isDomainViewer) {
      return '';
    }

    if (_.isEmpty(mag.user)) return mag.num_id + '/';
    return mag.user.uri + '/' + (mag.uri || mag.num_id) + '/';
  },

  /**
   * Вызывается, если была запрошена страница мэга, которой нет
   */
  onError: function() {
    // console.error('Navigation error! page not found: ' + Backbone.history.getFragment());
    if (this.mag) {
      this.go(1, { animation: false });
    }
  },

  // для формы джойна в лэндингах (в ифрейме с landing_signup.html)
  // вызывается изнутри самого ифрейма
  createLoginViewForIframe: function(node, joinCallback, errorMessages) {
    new Login({
      useNavigate: false,
      $node: $(node),
      router: this,
      joinCallback: joinCallback,
      errorMessages: errorMessages,
    }).render();
  },

  // вызывается из текстового виджета
  // просим создать новый мэг из темплейта
  // или показать форму join если юзер незалогинен
  createMagFromTemplate: function(tp, templateID) {
    new CreateMag({
      router: this,
      me: this.me,
      tp: tp,
      templateID: templateID,
    });
  },

  /**
   * Уничтожает всё
   */
  destroy: function() {
    this.cachedMag && this.cachedMag.destroy();
    this.magPasswordView && this.magPasswordView.remove();
    this.magSuspendedView && this.magSuspendedView.remove();
  },

  requestMagPassword: function(mag_url, mag) {
    if (!this.magPasswordView) {
      this.magPasswordView = new MagPasswordClass({
        router: this,
        $parent: $('#mags'),
      });
    }

    this.magPasswordView.render({
      mag: mag,
      mag_url: mag_url,
    });
  },

  showSuspended: function() {
    var pageSuspended = new ServicePage({
      type: 'mag-suspended',
      $container: $('#service-pages'),
    });

    pageSuspended.render();
  },

  notFound: function() {
    _.defer(function() {
      var page404 = new ServicePage({
        type: '404',
        $container: $('#service-pages'),
      });

      page404.render();
    });
  },
});

export default ViewerRouter;
