/**
 * Конструктор для виджета Твиттер
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import { sanitize } from 'validator';
import BlockFrameClass from '../block-frame';
import BlockClass from '../block';
import templates from '../../../templates/constructor/blocks/twitter.tpl';
import InitUtils from '../../common/init-utils';
import { Constants } from '../../common/utils';

const TwitterBlock = BlockClass.extend(
  {
    // excludedFromLib: true, // Временно скрыто из палитры виджетов

    name: 'Twitter',
    sort_index: 9, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'twitter',

    icon_color: '#1DA1F2',

    initialize: function(model, workspace) {
      var self = this;
      this.mag = workspace.mag;

      this.initBlock(model, workspace);

      this.frameClass = twitterFrame;

      this.model.validate = function(attrs) {
        var type = self.model.get('current_type');

        // Добавляем к урлу http:// при необходимости
        if (attrs[type].url) {
          attrs[type].url = self.fixUrl(attrs[type].url);
        }

        // Вычищаем пробелы из атрибутов
        if (attrs.type_hashtag && attrs.type_hashtag.hash) {
          attrs.type_hashtag.hash = attrs.type_hashtag.hash.replace(/\s/g, '');
        }
        if (attrs.type_follow && attrs.type_follow.username) {
          attrs.type_follow.username = attrs.type_follow.username.replace(/\s/g, '');
        }
      };

      // здесь вешаем только на изменение параметров из панели
      // потому что onParamsChange может менять еще другие параметры модели
      this.model.on('change:current_type', this.onParamsChange);
      this.model.on('change:type_timeline', this.onParamsChange);
      this.model.on('change:type_embed', this.onParamsChange);
      this.model.on('change:type_tweet', this.onParamsChange);
      this.model.on('change:type_follow', this.onParamsChange);
      this.model.on('change:type_hashtag', this.onParamsChange);

      this.controls = ['twitter_settings', 'common_position', 'common_layer', 'common_lock'];
    },

    render: function() {
      this.create();

      this.$el.addClass('twitter');

      // изначально отрисовываем виджет
      this.redraw(this.model, {
        skipHistory: true,
        skipTriggerRedraw: true,
        doNotRedrawPacksFrames: true,
        doNotRedrawBottomShiftLine: true,
      });

      this.onParamsChange();
      this.triggerReady();
    },

    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      var type = this.model.get('current_type'),
        changed_attrs = this.model.changedAttributes(),
        // was_moved = _.isObject(changed_attrs) && (_.has(changed_attrs, 'x') || _.has(changed_attrs, 'y') || _.has(changed_attrs, 'z')),
        was_moved_or_pack_unpack =
          _.isObject(changed_attrs) && _.without(_.keys(changed_attrs), 'x', 'y', 'z', 'pack_id').length == 0,
        was_resized =
          _.isObject(changed_attrs) &&
          (_.has(changed_attrs, 'w') || _.has(changed_attrs, 'h')) &&
          !changed_attrs.current_type,
        needs_redraw_twitter = !(
          was_moved_or_pack_unpack ||
          (was_resized && (type === 'type_timeline' || type === 'type_embed'))
        );

      BlockClass.prototype.redraw.apply(this, arguments);

      // Контрольный ресайз ембед твитта, на случай, если он поменял высоту после последнего ресайза во время драга
      if (type === 'type_embed') {
        this.adjustTweetHeight({ skipHistory: options && (options.undo || options.redo || options.skipHistory) });
      }

      // Обновляем содержимое твиттера только если нужно
      if (needs_redraw_twitter) {
        this.redrawTwitter(model.get('w'), model.get('h'), options);
      }
    },

    css: function(params) {
      var $frame = this.$el.find('iframe'),
        tl_header_h,
        tl_footer_h,
        tl_stream_h;

      BlockClass.prototype.css.apply(this, arguments);

      // Пытаемся установить высоту ленты внутри ифрейма, чтобы визуально улучшить во время ресайза блока
      if (
        this.model.get('current_type') === 'type_timeline' &&
        $frame.length == 1 &&
        $frame.hasClass('twitter-timeline')
      ) {
        tl_header_h = $frame
          .contents()
          .find('body .timeline-header')
          .outerHeight();
        tl_footer_h = $frame
          .contents()
          .find('body .timeline-footer')
          .outerHeight();
        tl_stream_h = $frame.height() - tl_header_h - tl_footer_h;
        if (tl_header_h && tl_footer_h) {
          $frame
            .contents()
            .find('body .stream')
            .height(tl_stream_h);
        }
      }
    },

    onParamsChange: function() {
      // после изменения параметров надо расчитать новый размер виджета
      // this.model.set('h', /*в зависимости от настроек виджета проставить новую высоту */);
      // this.model.set('w', /*в зависимости от настроек виджета проставить новую ширину */);

      var type = this.model.get('current_type'),
        types = ['type_timeline', 'type_embed', 'type_tweet', 'type_follow', 'type_hashtag'];

      if (_.contains(['type_timeline', 'type_embed'], type)) {
        // Проверяем вставленный код, чистим его от лишнего и XSS угроз
        this.validateCode(type);
      } else if (_.contains(['type_tweet', 'type_follow', 'type_hashtag'], type)) {
      }

      // Добавляем элементу блока класс текущего типа (нужно для отображения/скрытия кружков ресайза)
      this.$el.removeClass(types.join(' '));
      this.$el.addClass(type);

      // устанавливаем новые ограничения на минимальные размеры виджета
      this.setSizeConstraints();
    },

    // устанавливаем ограничения на минимальные размеры виджета
    setSizeConstraints: function(opts) {
      var type = this.model.get('current_type'),
        $frame,
        height;

      if (_.contains(['type_tweet', 'type_follow', 'type_hashtag'], type)) {
        // Для кнопок устанавливаем ограничения согласно фактическим размерам фрейма, и чтобы не ресайзились
        $frame = this.$el.find('iframe');

        if ($frame.length > 0) {
          _.extend(this.frame, {
            minwidth: $frame.width() || 120,
            maxwidth: $frame.width() || 120,
            minheight: $frame.height() || 20,
            maxheight: $frame.height() || 20,
          });
        } else {
          _.extend(this.frame, {
            minwidth: 120,
            maxwidth: 120,
            minheight: 20,
            maxheight: 20,
          });
        }
      } else if (type === 'type_timeline') {
        // Для таймлайнов, согласно документации твиттера
        _.extend(this.frame, {
          minwidth: 180,
          maxwidth: 520,
          minheight: 200,
          maxheight: 9999,
        });
      } else if (type === 'type_embed') {
        // Для единичных твиттов
        $frame = this.$el.find('twitterwidget.twitter-tweet');
        _.extend(this.frame, {
          minwidth: 220,
          maxwidth: 500,
          minheight: $frame.length && $frame.height() > 1 ? $frame.height() : 163, // Присутствует нужный фрейм и уже сформировалась реальная высота?
          maxheight: $frame.length && $frame.height() > 1 ? $frame.height() : 163,
        });
      }

      // Если ограничения заданы в парамтерах, переопределяем
      if (opts) {
        _.extend(this.frame, opts);
      }
    },

    // Перерисовываем рамку, чтобы она подстроилась под новые ограничения
    doResize: function(options) {
      options = options || {};
      var type = this.model.get('current_type');

      if (this.model.get('w') < this.frame.minwidth) {
        this.model.set('w', this.frame.minwidth, { silent: true });
      }
      if (this.model.get('w') > this.frame.maxwidth) {
        this.model.set('w', this.frame.maxwidth, { silent: true });
      }
      if (this.model.get('h') < this.frame.minheight) {
        this.model.set('h', this.frame.minheight, { silent: true });
      }
      if (this.model.get('h') > this.frame.maxheight) {
        this.model.set('h', this.frame.maxheight, { silent: true });
      }
      if (_.contains(['type_tweet', 'type_follow', 'type_hashtag'], type)) {
        this.model.set('w', this.frame.minwidth, { silent: true });
        this.model.set('h', this.frame.minheight, { silent: true });
      }

      if (!_.isEmpty(_.pick(this.model.changed, 'w', 'h'))) {
        this.model.save({}, { skipHistory: options.skipHistory, noSocketUpdate: options.socketUpdate });
      }

      this.frame.doResize({
        left: this.model.get('x'),
        top: this.model.get('y'),
        width: this.model.get('w'),
        height: this.model.get('h'),
      });
    },

    redrawTwitter: function(block_w, block_h, options) {
      var self = this,
        type = this.model.get('current_type'),
        data = _.clone(this.model.get(type)),
        template = templates['template-constructor-block-twitter-' + type],
        html,
        $frame,
        isFrameVisible;

      // Задаем перманентный урл мэга или страницы, чтобы он не отличался в конструкторе и на фронте
      if (data.use_own_url === 'mag') {
        data.url = this.getMagUrl();
      } else if (data.use_own_url === 'page' || !data.url) {
        data.url = this.getPageUrl();
      }

      html = template(data);

      this.$content.html(html);
      this.$overlay = this.$content.find('.overlay');

      // Проставляем ширину и высоту для таймлайна в аттрибутах. Твиттер их учтет при формировании iframe
      if (type === 'type_timeline') {
        this.$content.find('a').attr({
          width: this.model.get('w'),
          height: this.model.get('h'),
        });
      }

      // Запоминаем состояние и скрываем рамку вокруг виджета на время рендеринга твиттера, чтобы не скакала
      isFrameVisible = this.frame.isFrameVisible();
      this.frame.hide();

      // Переинициализируем скрипт, формирующий все виджеты
      InitUtils.initTwitterAPI(
        function() {
          var onRender = function(e) {
            var node = e.target;
            var $block = $(node).closest('.block');

            if ($block.attr('data-id') !== this.model.get('_id')) {
              return;
            } // Ловим только события рендена своего блока

            window.twttr.events.unbind('rendered', onRender);

            this.waitForIframe(
              this.$content,
              20,
              100,
              function() {
                var skipHistory = options && (options.undo || options.redo);

                if (type === 'type_timeline') {
                  this.$content.find('iframe').css({
                    width: '100%',
                    height: '100%',
                    'max-width': '100%',
                  });
                }

                if (type === 'type_embed') {
                  this.adjustTweetHeight({ skipHistory: skipHistory });
                } else {
                  this.setSizeConstraints();
                  this.doResize(_.extend({}, options, { skipHistory: type === 'type_embed' || skipHistory }));
                }

                // Восстанавливаем состояние рамки вокруг виджета
                if (isFrameVisible) {
                  this.frame.show();
                }
              }.bind(this)
            );
          }.bind(this);

          window.twttr.events.bind('rendered', onRender);
          window.twttr &&
            window.twttr.widgets &&
            _.isFunction(window.twttr.widgets.load) &&
            window.twttr.widgets.load(this.$el[0]);
        }.bind(this)
      );
    },

    // Выбирает из вставленного html кода только то, что нужно. Если нет того, что нужно, возвращает пустую строку
    validateCode: function(type) {
      var match,
        data,
        code,
        regex = {
          type_timeline: /^\<a.*\>.*\<\/a\>/i,
          type_embed: /^\<blockquote.*\>.*\<\/blockquote\>/i,
        };

      code = this.model.get(type).code;
      code = sanitize(code).xss(); // Вычищаем опасный xss код

      match = code.match(regex[type]);
      data = _.clone(this.model.get(type));
      data.code = match ? match[0] : '';
      this.model.set(type, data);
    },

    waitForIframe: function($parent, tick, max_ticks, callback) {
      var iframeInterval,
        ticks_counter = 0;

      iframeInterval = setInterval(function() {
        var $iframe = $parent.find('iframe, twitterwidget');

        ticks_counter++;

        // Установка инлайн стиля размеров у ифрейма более-менее достоверно говорит, о том, что его можно замерять.
        if ($iframe.length && (parseInt($iframe[0].style.width) > 0 || parseInt($iframe[0].style.height)) > 0) {
          clearInterval(iframeInterval);

          // Задержка нужна, т.к. твиттер может продолжать дорендеривать внутренности айфрейма
          _.delay(
            function() {
              callback();
            }.bind(this),
            100
          );
        }
      }, tick);
    },

    // Устанавливает высоту блока по фактической высоте iframe твитта, когда iframe будет сформирован асинхронно
    adjustTweetHeight: function(options) {
      var self = this,
        $frame = this.$content.find('twitterwidget.twitter-tweet, iframe.twitter-tweet'),
        type = self.model.get('current_type'),
        height = $frame.height();

      if (type !== 'type_embed') {
        return;
      }

      // Убираем у iframe вредные параметры и устанавливаем нужные
      $frame.css({
        margin: 0,
        'max-width': '100%',
        // 'height': '100%', // Устанавливаем только после того, как сформирована реальная высота
        '-webkit-transform': 'none', // Убираем трансформацию, чтобы не забивать видеопамять. Она была нужна на момент отрисовки, чтобы не глючили рамки
        width: '100%',
      });

      // Сбрасываем все транзишены, которые могут быть внутри iframe, чтобы его высота менялась мгновенно. Нужно для ресайза без перерисовки
      $frame
        .contents()
        .find('body *')
        .css({
          '-webkit-transition-duration': '0s',
          'transition-duration': '0s',
        });

      self.setSizeConstraints();
      self.doResize(options);
    },

    getMagUrl: function() {
      return this.mag && Constants.readymag_host + '/' + this.mag.get('num_id') + '/';
    },

    getPageUrl: function() {
      return Constants.readymag_host + '/' + 'p' + this.workspace.page.get('num_id') + '/';
    },

    fixUrl: function(url) {
      var new_url = url;
      if (url) {
        new_url = (/^http(s?):\/\//i.test(url) ? '' : 'http://') + url;
      }
      return new_url;
    },
  },
  {
    defaults: {
      current_type: 'type_timeline',

      type_timeline: {
        // По-умолчанию вставляем редимэговскую ленту
        code:
          '<a class="twitter-timeline" href="https://twitter.com/readymag" data-widget-id="324216476359852033">Tweets by @readymag</a>',
        widget_id: '',
      },

      type_embed: {
        code:
          '<blockquote class="twitter-tweet"><p>Here we go: <a href="http://t.co/yY6jqsSg" title="https://readymag.com/">readymag.com</a></p>&mdash; Readymag (@readymag) <a href="https://twitter.com/readymag/status/225550067766005760">July 18, 2012</a></blockquote>',
      },

      type_tweet: {
        url: '',
        use_own_url: 'mag', // '', 'mag' или 'page' для использования текущих урлов мэга или страницы
        show_count: true,
        large_button: false,
      },

      type_follow: {
        username: 'Readymag',
        show_name: true,
        large_button: false,
      },

      type_hashtag: {
        hash: 'Readymag',
        large_button: false,
      },
    },
  }
);

var twitterFrame = BlockFrameClass.extend({
  customResizeHandler: function(box, resizePoint) {
    this.block.setSizeConstraints();
    return box;
  },
});

export default TwitterBlock;
