/**
 * Конструктор для контрола настроек корзины
 */
import VueControlClass from '../vue-control';

const cart_settings = VueControlClass.extend({
  name: 'cart_settings',
  alt: 'Settings',
  panelComponent: 'panel-cart-settings',
});

export default cart_settings;
