/**
 * Конструктор для контрола настроек шейпа
 */
import PreloadDesignImages from '../../common/preload-design-images';
import VueControlClass from '../vue-control';

const ShapeSettings = VueControlClass.extend({
  name: 'shape_settings',
  alt: 'Settings',
  panelComponent: 'panel-shape-settings',
  onPanelChange: function(update) {
    VueControlClass.prototype.onPanelChange.apply(this, arguments);
  },

  select: function() {
    VueControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-shape_settings');
  },

  /**
   * Переопределяем реакцию закрытия паельки контрола
   */
  deselect: function() {
    if (this.colorbox_visible) this.closeColorBox();

    if (this.stroke_popup_visible) this.closeStrokePopup();

    VueControlClass.prototype.deselect.apply(this, arguments);
  },
});

export default ShapeSettings;
