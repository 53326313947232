import _ from '@rm/underscore';
import $ from '@rm/jquery';
import templates from '../../templates/viewer/cart-sidebar.tpl';
import { Constants, Utils } from '../common/utils';
import { formatCurrency } from '../common/intl';
import device from '../common/device';
import rmNativeSelect from '../../components/common/rm-native-select.vue';
import { countries } from '../common/countries';
import SVGInjector from 'svg-injector';
import { getContrast, getLuminance, darken, lighten } from 'polished';
import Vue from 'vue';

const CartSidebarClass = Backbone.View.extend({
  template: templates['template-viewer-cart-sidebar'],
  // Custom styling can be passed to options when creating an Element.
  cardStyle: {
    base: {
      color: '#0080FF',
      fontSize: '16px',
      fontFamily:
        "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu, 'Fira Sans', Roboto, 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },

  getRenderStatus: function() {
    return this.rendered;
  },

  initialize: function(mag) {
    _.bindAll(this);
    this.mag = mag;
    this.environment = this.mag.router.environment;
    this.rendered = false;
    this.haveSkus = false;
    this.checkoutCompete = false;
    this.isHiddenSettings = true;
    this.activeTab = 'tab-items';
    this.shippingFormErrors = [];
    this.shippingFormValues = {
      name: null,
      phone: null,
      line1: null,
      line2: null,
      city: null,
      state: null,
      country: 'default',
      postal_code: null,
    };
    this.clearShippingFormErrors_debounced = _.debounce(this.clearShippingFormErrors, 50);
    return this;
  },

  render: function() {
    if (this.rendered) return;
    this.rendered = true;

    this.setElement(this.template({}));

    this.$el.appendTo($('#mags .mag'));
    this.$toolbar = $('.toolbar.for-viewer');
    this.$tabs = this.$el.find('.cart-sidebar-tabs');
    this.$items = this.$el.find('.cart-items-list');
    this.$closeButton = this.$el.find('.close-button');
    this.$footer = this.$el.find('.sidebar-footer');
    this.$navButtons = this.$el.find('.sidebar-btn');

    this.$orderDetails = this.$tabs.find('.order-details');
    this.$orderItems = this.$tabs.find('.order-items-list');
    this.$orderError = this.$tabs.find('.order-error');
    this.$orderShippingAddress = this.$tabs.find('.order-shipping-address');
    this.$orderShippingMethod = this.$tabs.find('.order-shipping-method');
    this.$orderShippingEmail = this.$tabs.find('.order-shipping-email');
    this.$orderTax = this.$tabs.find('.order-tax');
    this.$orderTotalPrice = this.$tabs.find('.order-total-price');
    this.$orderButtons = this.$tabs.find('.order-btns');
    this.$paymentSuccess = this.$tabs.find('#payment-success-msg');
    this.$paymentFailed = this.$tabs.find('#payment-failed-msg');

    this.cartWidgetData = this.mag.eCommerceManager.getCartWidgetData();

    // Кнопка настроек только в превью
    if (this.environment === Constants.environment.preview) {
      // Если есть валидные данные виджета корзины
      if (this.cartWidgetData && this.cartWidgetData['_id']) {
        this.setupSettingsPanel();
      }
      // При переходе назад в конструктор возвращаем toolbar в начальное положение
      RM.views.toolbar.on('previewClose', () => {
        $('body').removeClass('cart-sidebar-shown');
      });
    }

    const svgs = this.$el.find('.svg');
    SVGInjector(svgs);

    this.$el.find('[data-alt]:not(.rmalttext)').RMAltText();

    this.renderCartItems();
    this.renderShippingForm();
    this.initNavigation();

    this.bindDomEvents();

    this.mag.eCommerceManager.events.on('ecommerce:cartsidebar:visibility:changed', this.toggleSidebar);
    this.mag.eCommerceManager.events.on('ecommerce:cartdata:changed', this.renderCartItems);
  },

  bindDomEvents: function() {
    this.$closeButton.on('click', this.closeSideBar.bind(this));
    this.$orderButtons.find('.cart-cancel-btn').on('click', this.cancelOrder.bind(this));
  },

  unbindDomEvents: function() {
    this.$closeButton.off('click', this.closeSideBar);
    this.$orderButtons.find('.cart-cancel-btn').off('click', this.cancelOrder);
  },

  generateSidebarCSS(data) {
    const id = `cart_sidebar_styles_${data['_id']}`;
    const color = Utils.getRGBA(data['bar-color'], data['bar-color-opacity'] / 100);
    const backgroundColor = Utils.getRGBA(data['bar-background-color'], data['bar-background-color-opacity'] / 100);
    const secondaryColor = Utils.getRGBA(data['bar-color'], (data['bar-color-opacity'] * 0.75) / 100);

    const secondaryBackgroundColor = this.getAdditionalColor(backgroundColor);

    let styles = `\
    .cart-sidebar {\n
      color: ${color};
      background-color: ${backgroundColor};
      box-shadow: ${data['bar-shadow'] ? '-1px 0 2px rgba(0, 0, 0, 0.24)' : 'none'};
      font-family: ${data['bar-font-family']};
      font-weight: ${data['bar-font-weight']};
      font-style: ${data['bar-font-style']};
    }\n
    .cart-sidebar .sidebar-btn {\n
      border-color: ${secondaryColor};
    }\n
    .cart-sidebar .sidebar-btn path {\n
      fill: ${color};
    }\n
    .cart-sidebar .cart-cancel-btn {\n
      color: ${secondaryColor};
    }\n
    .cart-sidebar .cart-pay-btn {\n
      background-color: ${secondaryBackgroundColor};
    }\n
    .cart-sidebar .sidebar-body .cart-item-block:hover {\n
      background-color: ${secondaryBackgroundColor};
    }\n
    .cart-sidebar .sidebar-body .cart-item-block .cart-item-attributes {\n
      color: ${secondaryColor};
    }\n
    .cart-sidebar .sidebar-body .cart-item-block .cart-item-price {\n
      color: ${secondaryColor};
    }\n
    .cart-sidebar .sidebar-body .cart-item-block .cart-items-quantity {\n
      color: ${secondaryColor};
    }\n
    .cart-sidebar .sidebar-body .cart-item-block .cart-item-remove-btn-icon path {\n
      fill: ${color};
    }\n
    .cart-sidebar .sidebar-body .cart-item-block .cart-item-decrease-btn-icon rect {\n
      fill: ${color};
    }\n
    .cart-sidebar .sidebar-body .cart-item-block .cart-item-increase-btn-icon path {\n
      fill: ${color};
    }\n
    .cart-sidebar .sidebar-body .shipping-form, .cart-sidebar .sidebar-body .shipping-form input {\n
      color: ${color};
    }\n
    .cart-sidebar .sidebar-body .shipping-form input::placeholder {\n
      color: ${color};
    }\n
    .cart-sidebar .sidebar-body .shipping-form svg path {\n
      fill: ${color};
    }\n
    .cart-sidebar .sidebar-body .shipping-form .shipping-form-title {\n
      color: ${secondaryColor};
    }\n
    .cart-sidebar .sidebar-body .shipping-form .rm-native-select-popup {\n
      background-color: ${secondaryBackgroundColor};
    }\n
    .cart-sidebar .tab-order .order-details .order-title-row {\n
      color: ${secondaryColor};
    }\n
    .cart-sidebar .sidebar-footer .power-by-stripe path {\n
      fill: ${secondaryColor};
    }\n
    .cart-sidebar .sidebar-footer .settings-button path {\n
      stroke: ${color};
    }\n
    .cart-sidebar .sidebar-footer .settings-button:hover path {\n
      stroke: ${secondaryColor};
    }\n
    .isphone .cart-sidebar .sidebar-body .cart-item-block {\n
      background-color: ${secondaryBackgroundColor};
    }`;

    $(`#${id}`).remove();

    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = id;
    style.className = 'cart-sidebar-styles';
    style.appendChild(document.createTextNode(styles));
    document.querySelector('head').appendChild(style);
  },

  /**
   * Для переданного цвета возвращает дполнительный
   * осветленный или затемненный в зависимости от исходного цвета
   * логика определения такая: если контарст с белый сильно выше,
   * а освещеность цвета низка значит дополнительный цвет будет светлее
   * @param {string} color - цвет rgb, rgba, hex
   */
  getAdditionalColor(color) {
    return getLuminance(color) < 0.05 && getContrast('#fff', color) - getContrast('#000', color) >= 8
      ? lighten(0.1, color)
      : darken(0.1, color);
  },

  // TODO: переделать на использование шаблона
  renderCartItems: function() {
    this.$items.empty();
    this.haveSkus = false;
    const cartData = this.mag.eCommerceManager.getCartData();

    if (!cartData || !cartData.skus) {
      return;
    }

    for (var sku_id in cartData.skus) {
      const sku = cartData.skus[sku_id];
      if (!sku.active || !cartData.skus.hasOwnProperty(sku_id)) {
        continue;
      }
      if (!this.haveSkus) {
        this.haveSkus = true;
      }
      /**
       * Элемент корзины
       */
      const item = $('<div/>', { class: 'cart-item-block' });
      const itemTitle = $('<div/>', { class: 'cart-item-title' }).html(sku.productObject.name);

      // Изображение товара, смотрим у артикула или у товара
      if (sku.image || (sku.productObject.images && sku.productObject.images.length)) {
        const itemImage = $('<div/>', {
          class: 'cart-item-image',
          css: {
            'background-position': 'center center',
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'background-image': `url(${sku.image ? sku.image : sku.productObject.images[0]})`,
          },
        });
        item.append(itemImage);
      }

      // Цена товара
      const itemsCount = sku.cart_count;
      const oneItemPrice = (sku.price / 100).toFixed(2);
      const oneItemPriceStr = formatCurrency(oneItemPrice, sku.currency);
      const allItemPriceStr = formatCurrency((oneItemPrice * itemsCount).toFixed(2), sku.currency);
      const itemPrice = $('<div/>', { class: 'cart-item-price' }).html(
        itemsCount > 1 ? `Price: ${oneItemPriceStr} × ${itemsCount} = ${allItemPriceStr}` : `Price: ${oneItemPriceStr}`
      );

      // Свойства товара
      const itemAttributes = $('<div/>', { class: 'cart-item-attributes' });
      const attributes = sku.attributes;
      for (const attr in attributes) {
        const attributeItem = $('<div/>', { class: 'cart-item-attribute' }).html(
          `${Utils.capitalize(attr)}: ${attributes[attr]}`
        );
        itemAttributes.append(attributeItem);
      }

      const itemQuantity = $('<div/>', { class: 'cart-items-quantity' }).html(`Quantity: ${itemsCount}`);

      const itemRemoveBtn = $('<div/>', {
        class: 'cart-item-remove-btn',
        title: 'remove',
      }).attr('data-sku-id', sku_id);
      const itemRemoveBtnIcon = $('<img/>', {
        class: 'svg cart-item-remove-btn-icon',
        src: '/img/viewer/cart-sidebar/cart-item-delete.svg',
      });
      itemRemoveBtn.append(itemRemoveBtnIcon);

      const editGroup = $('<div/>', { class: 'cart-item-edit-group' });
      if (itemsCount > 1) {
        const decreaseBtn = $('<div/>', {
          class: `cart-item-decrease-btn decrease-btn-${sku_id}`,
        }).attr('data-sku-id', sku_id);
        const decreaseBtnIcon = $('<img/>', {
          class: 'svg cart-item-decrease-btn-icon',
          src: '/img/viewer/cart-sidebar/cart-item-decrease.svg',
        });
        decreaseBtn.append(decreaseBtnIcon);
        editGroup.append(decreaseBtn);
        decreaseBtn.on('click', this.onDecreaseItem);
      } else {
        const decreaseBtn = this.$items.find(`decrease-btn-${sku_id}`);
        decreaseBtn.off('click', this.onDecreaseItem);
        decreaseBtn.remove();
      }
      const increaseBtn = $('<div/>', {
        class: 'cart-item-increase-btn',
      }).attr('data-sku-id', sku_id);
      const increaseBtnIcon = $('<img/>', {
        class: 'svg cart-item-increase-btn-icon',
        src: '/img/viewer/cart-sidebar/cart-item-increase.svg',
      });
      increaseBtn.append(increaseBtnIcon);
      editGroup.append(increaseBtn);

      item
        .append(itemTitle)
        .append(itemAttributes)
        .append(itemPrice)
        .append(itemQuantity)
        .append(itemRemoveBtn)
        .append(editGroup);
      this.$items.append(item);

      itemRemoveBtn.on('click', this.onRemoveItem);
      increaseBtn.on('click', this.onIncreaseItem);
    }

    const itemsSvgs = this.$items.find('.svg');
    SVGInjector(itemsSvgs);
    this.generateSidebarCSS(this.cartWidgetData);
  },

  renderShippingForm() {
    const countriesWithDefault = _.clone(countries);
    countriesWithDefault.unshift({ value: 'default', caption: 'Сountry' });

    const formData = this.mag.eCommerceManager.getShippingForm();
    if (formData && typeof formData === 'object') {
      this.shippingFormValues = formData;
    }

    const RmNativeSelect = Vue.extend(rmNativeSelect);
    this.countrySelect = new RmNativeSelect({
      el: this.$tabs.find('.countries-select').get(0),
      propsData: {
        options: countriesWithDefault,
        value: this.shippingFormValues.country,
        isDesktop: device.isDesktop,
        callback: this.onCountryChange,
      },
    });

    this.$tabs.find('.shipping-form .form-input').each((i, input) => {
      const fieldName = input.getAttribute('name');
      if (this.shippingFormValues[fieldName]) {
        input.value = this.shippingFormValues[fieldName];
      }
      $(input).on('input', this.onShippingFormChange);
    });
  },

  renderShippingFormErrors() {
    const $container = this.$tabs.find('.shipping-errors');
    this.shippingFormErrors.forEach(error => {
      $container.append(`<div class="error-item">${error}</div>`);
    });
  },

  clearShippingFormErrors() {
    if (this.shippingFormErrors.length) {
      this.shippingFormErrors = [];
      this.$tabs.find('.shipping-errors').empty();
    }
  },

  onCountryChange(newValue) {
    this.shippingFormValues.country = newValue;
    this.countrySelect.value = newValue;
    this.clearShippingFormErrors_debounced();
  },

  onShippingFormChange(e) {
    const fieldName = e.target.getAttribute('name');
    if (this.shippingFormValues[fieldName] !== undefined) {
      this.shippingFormValues[fieldName] = e.target.value;
      this.clearShippingFormErrors_debounced();
    }
  },

  validateShippingForm() {
    const errors = [];

    if (this.shippingFormValues.country === 'default') {
      errors.push('Country is required');
    }

    if (!this.shippingFormValues.name) {
      errors.push('Person is required');
    }

    if (!this.shippingFormValues.line1) {
      errors.push('Address is required');
    }

    if (!this.shippingFormValues.city) {
      errors.push('City is required');
    }

    if (errors.length) {
      this.shippingFormErrors = errors;
      return false;
    }

    return true;
  },

  initNavigation: function() {
    this.$tabs.find("[data-tab^='tab-']").each(
      function(i, btn) {
        $(btn).on(
          'click',
          function() {
            // Дополнительная логика при переходе на определенные табы
            if (this.haveSkus) {
              // Тут суть такая, что если нет продуктов с доставкой то пропускаем один шаг
              if (btn.dataset.tab === 'tab-inputs' && !this.checkShippableInCart()) {
                if (this.activeTab === 'tab-items') {
                  this.renderOrderTab();
                  this.goToTabByClassname('.tab-order');
                } else if (this.activeTab === 'tab-order') {
                  this.goToTabByClassname('.tab-items');
                }
                return;
              }
              // Переходы на страницу создания заказа
              // если переход со страницы формы то нужна валидация формы
              if (this.activeTab === 'tab-inputs' && btn.dataset.tab === 'tab-order') {
                if (this.validateShippingForm()) {
                  this.clearShippingFormErrors();
                  this.mag.eCommerceManager.setShippingForm(this.shippingFormValues);
                  this.renderOrderTab();
                } else {
                  this.renderShippingFormErrors();
                  return;
                }
              } else if (btn.dataset.tab === 'tab-order') {
                this.renderOrderTab();
              }
              if (btn.dataset.tab === 'tab-checkout') {
                this.renderCheckoutForm();
              }
              this.goToTabByClassname('.' + btn.dataset.tab);
            }
          }.bind(this)
        );
      }.bind(this)
    );
  },

  goToTabByClassname: function(tabClassname) {
    this.$tabs.find('.cart-sidebar-tab').each(function(i, tab) {
      $(tab).removeClass('active');
    });
    this.$tabs.find(tabClassname).addClass('active');
    this.activeTab = tabClassname.slice(1); // сохраняем название активной вкладки
  },

  toggleSidebar: function(isSidebarHidden) {
    if (!isSidebarHidden) {
      this.$el.addClass('show');
      $('body').addClass('cart-sidebar-shown');
    } else {
      this.$el.removeClass('show');
      $('body').removeClass('cart-sidebar-shown');

      // TODO: сделать систему для очистки состояния и перехода на нужный шаг

      // При закрытии, если оплата завершилась убираемся за собой =)
      if (this.checkoutCompete) {
        this.$paymentSuccess.empty();
        this.$paymentFailed.empty();
      }

      // Убираем ошибку создания заказа
      this.clearOrderError();
    }
  },

  closeSideBar: function() {
    this.mag.eCommerceManager.changeCartSidebarVisibility();
  },

  toogleSettings: function() {
    this.isHiddenSettings = !this.isHiddenSettings;
    if (this.settingsPanel) {
      this.settingsPanel.isHidden = this.isHiddenSettings;
    }
  },

  onRemoveItem: function(e) {
    if (e.currentTarget.dataset.skuId) {
      this.mag.eCommerceManager.removeFromCart(e.currentTarget.dataset.skuId);
    } else {
      console.log("Sku id wasn't found at this item");
    }
  },

  onIncreaseItem: function(e) {
    if (e.currentTarget.dataset.skuId) {
      this.mag.eCommerceManager.increaseCartItem(e.currentTarget.dataset.skuId);
    } else {
      console.log("Sku id wasn't found at this item");
    }
  },

  onDecreaseItem: function(e) {
    if (e.currentTarget.dataset.skuId) {
      this.mag.eCommerceManager.decreaseCartItem(e.currentTarget.dataset.skuId);
    } else {
      console.log("Sku id wasn't found at this item");
    }
  },

  renderOrderTab: function() {
    var orderDetails = this.mag.eCommerceManager.getOrder();
    console.log('orderDetails', orderDetails);

    // Если у нас сохранен заказ то рендерим его
    if (orderDetails) {
      this.renderOrderDetails();
      return;
    }

    // Обновляем данные артикулов лежащих в корзине
    this.mag.eCommerceManager
      .updateCartData()
      .then(
        function() {
          // Получаем данные для создания заказа
          var requestData = this.generateOrderRequestData();

          if (requestData && requestData instanceof Error) {
            this.renderOrderError(requestData.message);
          } else if (requestData && requestData.currency) {
            $.ajax({
              type: 'POST',
              url: '/api/projects/' + this.mag.num_id + '/ecommerce/stripe/orders/',
              data: JSON.stringify(requestData),
              contentType: 'application/json; charset=utf-8',
              dataType: 'json',
              success: function(result) {
                if (result && result.data) {
                  this.mag.eCommerceManager.setOrder(result.data);
                  this.renderOrderDetails();
                }
              }.bind(this),
              error: function(jqXHR) {
                console.log('jqXHR', jqXHR.responseText);
              },
            });
          }
        }.bind(this)
      )
      .catch(
        function(error) {
          if (error && error.message) {
            this.renderOrderError(error.message);
          }
        }.bind(this)
      );
  },

  renderOrderDetails: function() {
    var orderDetails = this.mag.eCommerceManager.getOrder();
    // Очишаем блоки
    this.$orderItems.empty();
    this.$orderShippingAddress.empty();
    this.$orderShippingMethod.empty();
    this.$orderShippingEmail.empty();
    this.$orderTax.empty();
    this.$orderTotalPrice.empty();

    var tax, shipping;
    orderDetails.items.forEach(
      function(item) {
        if (item.type === 'tax') {
          tax = item;
        } else if (item.type === 'shipping') {
          shipping = item;
        } else {
          var shippingItem = $('<div/>', { class: 'order-item' }).html(item.quantity + ' × ' + item.description);
          const $shippingItemPrice = $('<div/>', { class: 'order-item-price' }).text(
            formatCurrency((item.amount / 100).toFixed(2), item.currency)
          );
          shippingItem.append($shippingItemPrice);
          this.$orderItems.append(shippingItem);
        }
      }.bind(this)
    );

    if (orderDetails.shipping) {
      const addSpace = str => (str ? ` ${str}` : '');
      const shippingAddress = orderDetails.shipping.address;
      let addressString = `${shippingAddress.line1}${addSpace(shippingAddress.line2)} ${
        shippingAddress.city
      },${addSpace(shippingAddress.state)}${addSpace(shippingAddress.postal_code)} ${shippingAddress.country}`;

      this.$orderShippingAddress.text(addressString);
    }

    if (shipping) {
      this.$orderShippingMethod.text(
        shipping.description + ' ' + formatCurrency((shipping.amount / 100).toFixed(2), shipping.currency)
      );
    }

    if (orderDetails.email) {
      this.$orderShippingEmail.removeClass('hidden').text(orderDetails.email);
    }

    if (orderDetails.phone) {
      this.$orderDetails
        .find('.shipping-phone')
        .removeClass('hidden')
        .text(orderDetails.phone);
    }

    if (tax) {
      this.$orderTax.text(tax.description + ' ' + formatCurrency((tax.amount / 100).toFixed(2), tax.currency));
    }

    this.$orderTotalPrice.text(formatCurrency((orderDetails.amount / 100).toFixed(2), orderDetails.currency));

    this.$orderButtons.find('.cart-pay-btn').removeClass('hidden');
  },

  renderOrderError: function(message) {
    this.$orderError
      .removeClass('hidden')
      .empty()
      .text(message);
    this.$orderDetails.addClass('hidden');
  },

  clearOrderError: function() {
    this.$orderError.addClass('hidden').empty();
    this.$orderDetails.removeClass('hidden');
  },

  transformShippingFormToOrderRequest() {
    const isShippable = this.checkShippableInCart();
    const requestData = {
      currency: 'usd',
      items: [],
    };

    if (!isShippable) {
      return requestData;
    }

    // TODO:  сдесь могло бы быть красивше
    requestData.shipping = {
      name: this.shippingFormValues.name,
      phone: this.shippingFormValues.phone,
      address: {
        line1: this.shippingFormValues.line1,
        line2: this.shippingFormValues.line2,
        city: this.shippingFormValues.city,
        state: this.shippingFormValues.state,
        country: this.shippingFormValues.country,
        postal_code: this.shippingFormValues.postal_code,
      },
    };

    return requestData;
  },

  generateOrderRequestData: function() {
    var cartData = this.mag.eCommerceManager.getCartData();

    if (!cartData || !cartData.skus) {
      return new Error('Empty cart');
    }

    var currencies = [];
    var requestData = this.transformShippingFormToOrderRequest();

    // TODO: сделать валидацию на наличие артикулов
    // И выводить ошибку при создании заказа
    for (var sku_id in cartData.skus) {
      if (!cartData.skus[sku_id].active) {
        continue;
      }

      requestData.items.push({
        type: cartData.skus[sku_id].object,
        parent: sku_id,
        currency: cartData.skus[sku_id].currency,
        quantity: cartData.skus[sku_id].cart_count,
      });

      if (currencies.indexOf(cartData.skus[sku_id].currency) === -1) {
        currencies.push(cartData.skus[sku_id].currency);
      }
    }

    // Для одного заказа должна быть одна валюта!
    if (currencies.length > 1) {
      return new Error('mixed currencies');
    }

    requestData.currency = currencies.pop();
    return requestData;
  },

  cancelOrder: function() {
    this.mag.eCommerceManager
      .cancelOrder()
      .then(result => {
        if (result.data && result.data.status === 'canceled') {
          this.mag.eCommerceManager.deleteOrder();
          this.goToTabByClassname('.tab-items'); // Переходим на первую вкладку
        }
      })
      .catch(error => {
        if (error instanceof Error) {
          console.error('Cancel Order error:', error.message);
        }
      });
  },

  renderCheckoutForm: function() {
    var stripeConnectedUserId = this.mag.eCommerceManager.getStripeConnectedUserId();
    var stripe = Stripe(Constants.STRIPE_TEST_PUBLIC_KEY, {
      stripeAccount: stripeConnectedUserId,
    });
    var elements = stripe.elements();
    this.card = elements.create('card', { style: this.cardStyle, hidePostalCode: true });
    this.card.mount('#card-element');

    this.card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    this.form = document.getElementById('payment-form');
    this.form.addEventListener(
      'submit',
      function(event) {
        event.preventDefault();

        stripe.createToken(this.card).then(
          function(result) {
            if (result.error) {
              // Inform the customer that there was an error.
              var errorElement = document.getElementById('card-errors');
              errorElement.textContent = result.error.message;
            } else {
              // Send the token to your server.
              this.stripeTokenHandler(result.token);
            }
          }.bind(this)
        );
      }.bind(this)
    );
  },

  stripeTokenHandler: function(token) {
    var order = this.mag.eCommerceManager.getOrder();

    if (!order) {
      this.$paymentFailed.text('Order not found');
      return;
    }

    var checkoutEmail = $('#checkout-email').val();

    if (!checkoutEmail) {
      this.$paymentFailed.text('Email is required');
      return;
    }

    $.ajax({
      type: 'POST',
      url: '/api/projects/' + this.mag.num_id + '/ecommerce/stripe/orders/' + order.id + '/pay/',
      data: JSON.stringify({ stripe_token: token.id, email: checkoutEmail }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      success: function(result) {
        if (result && result.data) {
          this.mag.eCommerceManager.deleteOrder();
          if (result.data.status === 'paid') {
            this.$paymentSuccess.text('Payment Success');
          }
          this.checkoutCompete = true;
        }
      }.bind(this),
      error: function(jqXHR) {
        console.log('pay order Error', jqXHR.responseText);
        this.$paymentFailed.text('Payment Failed');
        this.checkoutCompete = true;
      },
    });
  },

  getSettingsData: function() {
    return {
      isHidden: this.isHiddenSettings,
      model: this.cartWidgetModel ? _.clone(this.cartWidgetModel.attributes) : {},
    };
  },

  setupSettingsPanel: function() {
    // Создаем модель для виджета корзины
    const WidgetModel = require('../constructor/models/widget').default;
    const MagEditParams = require('../constructor/models/mag-edit-params').default;
    const FontsModel = require('../constructor/models/fonts').default;
    this.cartWidgetModel = new WidgetModel(this.cartWidgetData);
    this.saveModelChanges_debounced = _.debounce(this.saveModelChanges.bind(this), 80);

    // Если у нас не инициализорована модель мага, то делаем это руками
    if (RM.constructorRouter && RM.constructorRouter.mag && !RM.constructorRouter.mag.get('_id')) {
      RM.constructorRouter.mag.setMag(this.mag.rawMagData);
      RM.constructorRouter.mag.edit_params = new MagEditParams(
        RM.constructorRouter.mag.get('edit_params'),
        RM.constructorRouter.mag
      );
      RM.constructorRouter.mag.unset('edit_params');
      RM.constructorRouter.isOwner = _.isEmpty(RM.constructorRouter.mag.get('shared'));
      RM.constructorRouter.fonts = new FontsModel(RM.constructorRouter.mag.edit_params);
    }
    // Добавляем кнопку настроек на сайдбар
    this.$settingButton = $('<div>', {
      class: 'settings-button-wrap',
      attr: {
        'data-alt': 'Settings',
        'data-alt-pos': 'top',
        'data-alt-offset': '9',
      },
    });
    this.$settingButton.append(
      $('<img>', {
        class: 'settings-button svg',
        src: '/img/constructor/controls/cart_settings/cart-sidebar-settings.svg',
      })
    );
    this.$footer.append(this.$settingButton);
    this.$settingButton.on('click', this.toogleSettings);

    // Создаем vue виджет панели и добавляем его рядом с кнопкой настроек
    const SettingsPanel = require('../../components/constructor/controls/panel-cart-sidebar.vue').default;
    const SettingsPanelVue = Vue.extend(SettingsPanel);
    this.settingsPanel = new SettingsPanelVue({
      el: $('<div id="settings-panel">')
        .appendTo(this.$footer)
        .get(0),
      data: this.getSettingsData(),
    });

    this.settingsPanel.$on('change', this.onModelChange);
  },

  onModelChange(newData) {
    this.saveModelChanges_debounced(newData);
  },

  saveModelChanges(newData) {
    this.cartWidgetData = newData;
    this.generateSidebarCSS(newData);
    if (this.cartWidgetModel) {
      this.cartWidgetModel.save(newData);
    }
  },

  checkShippableInCart: function() {
    const cartData = this.mag.eCommerceManager.getCartData();
    for (const key in cartData.skus) {
      if (cartData.skus[key].productObject.shippable) {
        return true;
      }
    }
    return false;
  },

  destroy: function() {
    this.mag.eCommerceManager.events.off('ecommerce:cartsidebar:visibility:changed');
    this.mag.eCommerceManager.events.off('ecommerce:cartdata:changed');
    this.form.removeEventListener('submit');
    this.card.removeEventListener('change');
    this.$tabs.find("[data-tab^='tab-']").each(function(i, btn) {
      $(btn).off('click');
    });
    this.$items.find('.cart-item-remove-btn').each(function(i, btn) {
      $(btn).off('click');
    });
    this.$tabs.find('.shipping-form .form-input').each((i, input) => {
      $(input).off('input', this.onShippingFormChange);
    });
    this.unbindDomEvents();
    $('body').removeClass('cart-sidebar-shown');
    if (this.settingsPanel) {
      this.settingsPanel.$destroy();
    }
    if (this.$settingButton) {
      this.$settingButton.off('click', this.toogleSettings);
    }
  },
});

export default CartSidebarClass;
