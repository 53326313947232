/**
 * Lazyload иконок в конструкторе
 *
 * Подставляет в путь RM_PUBLIC_PATH, который устанавливается в webpack.config
 */
export const preloadDesignImagesList = {
  'collector-mobile-header': {
    prefix: 'img/_tablet/collector/header/',
    imgs: ['back-active.png'],
  },

  'collector-mobile-mags': {
    prefix: 'img/_tablet/collector/mags-list/',
    imgs: ['close.png'],
  },

  constructor: {
    prefix: 'img/constructor/blocks/',
    imgs: ['block-dot-plus.png'],
  },

  controls: {
    prefix: 'img/constructor/controls/',
    imgs: ['arrow-hover.png'],
  },

  'controls-common_align': {
    prefix: 'img/constructor/controls/common_align/',
    imgs: [
      'bottom-hover.png',
      'center-hover.png',
      'icon-hover.png',
      'left-hover.png',
      'middle-hover.png',
      'right-hover.png',
      'top-hover.png',
    ],
  },

  'controls-common_layer': {
    prefix: 'img/constructor/controls/common_layer/',
    imgs: ['icon-hover.png'],
  },

  'controls-text_align': {
    prefix: 'img/constructor/controls/text_align/',
    imgs: ['center-hover.png', 'icon-hover.png', 'justify-hover.png', 'left-hover.png', 'right-hover.png'],
  },

  'controls-text_bius': {
    prefix: 'img/constructor/controls/text_bius/',
    imgs: [
      'all-caps-hover.png',
      'bold-hover.png',
      'icon-hover.png',
      'italic-hover.png',
      'strike-hover.png',
      'underline-hover.png',
    ],
  },

  'controls-text_columns': {
    prefix: 'img/constructor/controls/text_columns/',
    imgs: ['icon-hover.png'],
  },

  'controls-text_edit': {
    prefix: 'img/constructor/controls/text_edit/',
    imgs: ['icon-hover.png'],
  },

  'controls-text_link': {
    prefix: 'img/constructor/controls/text_link/',
    imgs: [
      'add-hover.png',
      'apply.png',
      'apply-hover.png',
      'cancel.png',
      'cancel-hover.png',
      'delete.png',
      'delete-hover.png',
      'icon-hover.png',
      'menu-arrow.png',
      'menu-arrow-hover.png',
      'placeholder-arrow-hover.png',
      'popup-arrow-hover.png',
      'target.png',
      'target-hover.png',
      'target-active.png',
    ],
  },

  'controls-text_typography': {
    prefix: 'img/constructor/controls/text_typography/',
    imgs: [
      'font-arrows-hover.png',
      'icon-hover.png',
      'point-active.png',
      'point-hover.png',
      'style-arrows-hover.png',
      'unlinked.png',
    ],
  },

  'controls-text_styles': {
    prefix: 'img/constructor/controls/text_styles/',
    imgs: [
      'add-hover.png',
      'align-center-active.png',
      'align-justify-active.png',
      'align-left-active.png',
      'align-right-active.png',
      'cancel-hover.png',
      'menu-arrow.png',
      'menu-arrow-hover.png',
      'point-active.png',
      'point-hover.png',
      'popup-arrow-hover.png',
      'text-strike-active.png',
      'text-transform-active.png',
      'text-underline-active.png',
    ],
  },

  'controls-slideshow_manager': {
    prefix: 'img/constructor/controls/slideshow_manager/',
    imgs: [
      'add-hover.png',
      'image-placeholder.png',
      'remove.png',
      'remove-hover.png',
      'update.png',
      'update-hover.png',
      'preloader.png',
    ],
  },

  'controls-shape_settings': {
    prefix: 'img/constructor/controls/shape_settings/',
    imgs: [
      'color-arrow.png',
      'color-arrow-hover.png',
      'icon.png',
      'icon-hover.png',
      'opacity-grid.png',
      'point-active.png',
      'point-hover.png',
      'popup-arrow.png',
      'popup-arrow-hover.png',
      'stroke-dashed.png',
      'stroke-dotted.png',
      'stroke-double.png',
      'stroke-solid.png',
    ],
  },

  'controls-video_settings': {
    prefix: 'img/constructor/controls/video_settings/',
    imgs: [
      'social-upload.png',
      'social-upload-hover.png',
      'delete.png',
      'delete-hover.png',
      'social-youtube.png',
      'social-youtube-hover.png',
      'social-vimeo.png',
      'social-vimeo-hover.png',
      'replace-thumbnail.png',
    ],
  },

  'controls-common_rotation': {
    prefix: 'img/constructor/controls/common_rotation/',
    imgs: ['flip-h.png', 'flip-h-hover.png', 'flip-v.png', 'flip-v-hover.png', 'rotate90.png', 'rotate90-hover.png'],
  },

  'controls-common_position': {
    prefix: 'img/constructor/controls/common_position/',
    imgs: ['icon.png', 'icon-hover.png'],
  },

  'controls-audio_settings': {
    prefix: 'img/constructor/controls/audio_settings/',
    imgs: [
      'close.png',
      'close-hover.png',
      'soundcloud.png',
      'soundcloud-hover.png',
      'soundcloud-logo-small.png',
      'soundcloud-logo-small-hover.png',
      'spin-l-hover.png',
      'spin-r-hover.png',
    ],
  },

  'controls-picture_alt': {
    prefix: 'img/constructor/controls/picture_alt/',
    imgs: ['icon-hover.png'],
  },

  colorbox: {
    prefix: 'img/constructor/helpers/colorbox/',
    imgs: ['add-hover.png', 'fav-active.png', 'fav-hover.png', 'trash-active.png', 'trash-hover.png'],
  },

  fontexplorer: {
    prefix: 'img/constructor/helpers/font-explorer/',
    imgs: [
      'add-font.png',
      'close-typekit-popup.png',
      'back-hover.png',
      'classification-blackletter-active.png',
      'classification-decorative-active.png',
      'classification-handmade-active.png',
      'classification-monospaced-active.png',
      'classification-sans-serif-active.png',
      'classification-script-active.png',
      'classification-serif-active.png',
      'classification-slab-serif-active.png',
      'filter-capitals-all-or-small-caps-active.png',
      'filter-capitals-uppercase-lowercase-active.png',
      'filter-contrast-high-active.png',
      'filter-contrast-low-active.png',
      'filter-contrast-regular-active.png',
      'filter-number-style-lowercase-active.png',
      'filter-number-style-uppercase-active.png',
      'filter-weight-heavy-active.png',
      'filter-weight-light-active.png',
      'filter-weight-regular-active.png',
      'filter-width-condensed-active.png',
      'filter-width-extended-active.png',
      'filter-width-regular-active.png',
      'filter-x-height-high-active.png',
      'filter-x-height-low-active.png',
      'filter-x-height-regular-active.png',
      'grid-view-black.png',
      'list-view-white.png',
      'provider-logo-google-hover.png',
      'provider-logo-typekit-hover.png',
      'provider-logo-webtype-hover.png',
      'provider-logo-typetoday-hover.png',
      'remove-font.png',
      'search-active.png',
      'search-corner.png',
    ],
  },

  fontselector: {
    prefix: 'img/constructor/helpers/font-selector/',
    imgs: [
      'add-hover.png',
      'delete.png',
      'google.png',
      'restore.png',
      'typekit.png',
      'webtype.png',
      'gear.png',
      'gear-hover.png',
    ],
  },

  fontuploader: {
    prefix: 'img/constructor/helpers/font-selector/',
    imgs: ['refresh.png', 'refresh-hover.png', 'close.png', 'close-hover.png'],
  },

  'page-settings': {
    prefix: 'img/constructor/helpers/page-settings/',
    imgs: ['arrow-hover.png', 'restore-hover.png', 'upload-hover.png'],
  },

  pagespanel: {
    prefix: 'img/constructor/pages-panel',
    imgs: [
      '/back-click.png',
      '/back-hover.png',
      '/list-view-active.png',
      '/list-view-hover.png',
      '/plus-click.png',
      '/plus-hover.png',
      '/settings.png',
      '/settings-hover.png',
      '/slide-view.png',
      '/slide-view-active.png',
      '/slide-view-hover.png',
      '/trash-active.png',
      '/trash-full-active.png',
      '/trash-full-hover.png',
      '/trash-full.png',
      '/trash-hover.png',
      '/trash.png',
      '/archive-hover.png',
      '/archive.png',
      '-contents/eye-invisible.png',
      '-contents/eye-invisible-active.png',
      '-contents/eye-visible.png',
      '-contents/eye-visible-active.png',
      '-contents/submenu-settings-hover.png',
      '-contents/submenu-clone-hover.png',
      '-contents/submenu-edit-hover.png',
      '-contents/submenu-invisible.png',
      '-contents/submenu-invisible-hover.png',
      '-contents/submenu-remove-hover.png',
      '-contents/submenu-visible.png',
      '-contents/submenu-visible-hover.png',
    ],
  },

  settings: {
    prefix: 'img/constructor/pages-panel-settings/',
    imgs: [
      'context-panel-corner.png',
      'link-status-error.png',
      'link-status-ok.png',
      'republish-arrow.png',
      'republish-arrow-hover.png',
      'republish-settings.png',
      'settings-corner.png',
      'share-facebook.png',
      'share-facebook-hover.png',
      'share-mail.png',
      'share-mail-hover.png',
      'share-twitter.png',
      'share-twitter-hover.png',
      'textured-panel-corner.png',
      'switcher-pass-texture.png',
    ],
  },

  'help-tour': {
    prefix: 'img/constructor/help-tour/',
    imgs: ['cursor.png', 'icon-tour-hover.png', 'star.png'],
  },

  'help-panel': {
    prefix: 'img/constructor/help-panel/',
    imgs: ['mac_keyboard/single.png', 'win_keyboard/single.png', 'mac_keyboard/blank.png', 'win_keyboard/blank.png'],
  },

  'help-shortcuts': {
    prefix: 'img/constructor/help-panel/',
    imgs: [
      'mac_keyboard/alt.png',
      'mac_keyboard/cmd.png',
      'mac_keyboard/shift_cmd.png',
      'arrow.png',
      'mac_keyboard/cmd_symbol.png',
      'win_keyboard/alt.png',
      'win_keyboard/ctrl.png',
      'win_keyboard/ctrl_shift.png',
    ],
  },

  templates: {
    prefix: 'img/constructor/',
    imgs: [
      'pages-panel-templates/arrow.png',
      'pages-panel-templates/arrow-active.png',
      'pages-panel-templates/long.png',
      'pages-panel-templates/del.png',
      'pages-panel-templates/del-hover.png',
      'pages-panel-templates/plus.png',
      'pages-panel-templates/plus-hover.png',
      'helpers/template-center/search.png',
      'helpers/template-center/search-active.png',
      'helpers/template-center/back.png',
      'helpers/template-center/back-hover.png',
      'helpers/template-center/checkmark.png',
    ],
  },

  'panels-background': {
    prefix: 'img/constructor/widgetbar/background/',
    imgs: [
      'delete.png',
      'color.svg',
      'mask.png',
      'opacity-handle-hover.png',
      'picture.svg',
      'flickr.svg',
      'unsplash.svg',
      'search.svg',
      'slideshow.svg',
      'upload.svg',
      'video_bg.svg',
      'video.svg',
      'vimeo.svg',
      'youtube.svg',
      'slideshow-add-btn.svg',
      'update-slideshow-img.png',
      'update-slideshow-img-hover.png',
      'delete-slideshow-img.png',
      'delete-slideshow-img-hover.png',
      'slideshow-settings-divider@2x.png',
    ],
  },

  'panels-twitter': {
    prefix: 'img/constructor/panels/twitter/',
    imgs: [
      'input-delete-hover.png',
      'input-target-hover.png',
      'input-go-hover.png',
      'point-hover.png',
      'point-active.png',
    ],
  },

  'panels-shape': {
    prefix: 'img/constructor/panels/shape/',
    imgs: [
      'ellipse.png',
      'ellipse-active.png',
      'ellipse-hover.png',
      'icon-ellipse.png',
      'icon-line.png',
      'icon-polygon.png',
      'icon-rectangle.png',
      'line.png',
      'line-active.png',
      'line-hover.png',
      'polygon.png',
      'polygon-active.png',
      'polygon-hover.png',
      'rectangle.png',
      'rectangle-active.png',
      'rectangle-hover.png',
    ],
  },

  searchpanel: {
    prefix: 'img/constructor/search-panels/',
    imgs: ['clear.png', 'clear-hover.png', 'back-icon-hover.png', 'search-hover.png'],
  },

  'searchpanel-bing': {
    prefix: 'img/constructor/search-panels/bing/',
    imgs: [
      'arrow-hover.png',
      'monochrome-circle.png',
      'corner-color.png',
      'curr.png',
      'error.png',
      'logo-hover.png',
      'point-hover.png',
    ],
  },

  'searchpanel-flickr': {
    prefix: 'img/constructor/search-panels/flickr/',
    imgs: ['connect-logo.png', 'logo-hover.png', 'error.png'],
  },

  'searchpanel-unsplash': {
    prefix: 'img/constructor/search-panels/unsplash/',
    imgs: ['connect.png', 'logo-hover.png'],
  },

  'searchpanel-youtube': {
    prefix: 'img/constructor/search-panels/youtube/',
    imgs: ['connect-logo.png', 'logo-hover.png'],
  },

  'searchpanel-vimeo': {
    prefix: 'img/constructor/search-panels/vimeo/',
    imgs: ['connect-logo.png', 'logo-hover.png'],
  },

  'searchpanel-videocommon': {
    prefix: 'img/constructor/search-panels/common-video/',
    imgs: ['play.png', 'play-hover.png', 'user.png'],
  },

  widgetbar: {
    prefix: 'img/constructor/widgetbar/',
    imgs: [
      'redo-active.png',
      'redo-active-hover.png',
      'undo-active.png',
      'undo-active-hover.png',
      'arrow-left-hover.png',
      'arrow-right-hover.png',
      'dot.png',
      'ellipsis.png',
      'ellipsis-hover.png',
      'eye-back.png',
      'eye-back-hidden.png',
      'eye-back-hidden-hover.png',
      'eye-back-hover.png',
      'eye-front.png',
      'eye-front-hover.png',
      'i.png',
      'i-hover.png',
      'selection-point.png',
      'x.png',
      'x-hover.png',
    ],
  },

  widgetselector: {
    prefix: 'img/constructor/widget-selector/',
    imgs: [],
  },

  'collector-menu': {
    prefix: 'img/common/user-menu/',
    imgs: ['create-hover.png', 'settings-hover.png', 'logout-hover.png', 'unlogged-hover.png'],
  },

  'collector-maglist': {
    prefix: 'img/collector/mags-list/',
    imgs: [
      'arrow.png',
      'arrow-hover.png',
      'close.png',
      'close-hover.png',
      'edit.png',
      'edit-hover.png',
      'private.png',
      'menu-bg.png',
      'menu-corner.png',
      'menu-panel-bg.png',
      'settings.png',
      'settings-hover.png',
      'updated.png',
      'updated-hover.png',
      'suspended.png',
      'suspended-hover.png',
      'popup-close.png',
      'popup-close-hover.png',
      'eye.png',
      'eye-hover.png',
    ],
  },

  'user-settings': {
    prefix: 'img/',
    imgs: [
      'constructor/preloader-dark.png',
      'collector/profile-edit/checkmark_ok.png',
      'collector/profile-edit/checkmark_bad.png',
      'collector/profile-edit/del_userpic_hover.png',
      'collector/profile-edit/preloader_grey_arc.png',
      'collector/profile-edit/icon-profile-active.png',
      'collector/profile-edit/icon-password-active.png',
      'collector/profile-edit/icon-billing-active.png',
      'collector/profile-edit/close-hover.png',
      'collector/profile-edit-subscription/main-bg.jpg',
      'collector/profile-edit-subscription/new-publisher.png',
      'collector/profile-edit-subscription/stripe-hover.png',
      'collector/profile-edit-subscription/upper-block-bg.jpg',
      'collector/profile-edit-subscription/arrow-back-hover.png',
      'collector/profile-edit-subscription/arrow-discount.png',
      'collector/profile-edit-subscription/arrow-discount-white.png',
      'collector/profile-edit-subscription/radio-hover.png',
      'collector/profile-edit-subscription/radio-checked.png',
    ],
  },

  homepage: {
    prefix: 'img/homepage/',
    imgs: [
      'menu/logo-black.png',
      'menu/logo-blue.png',
      'menu/logo-blue-light.png',
      'menu/logo-white.png',
      'tour/left-arrow-hover.png',
      'tour/popup-close-hover.png',
      'tour/right-arrow-hover.png',
      'tour/responsive-play-hover.png',
      'tour/top-categories-img1-hover.png',
      'tour/top-categories-img2-hover.png',
      'tour/top-categories-img3-hover.png',
      'tour/top-categories-img4-hover.png',
      'tour/top-categories-img5-hover.png',
      'pricing/star-part-0@2x.png',
      'pricing/star-part-1@2x.png',
      'pricing/star-part-2@2x.png',
      'pricing/star-part-3@2x.png',
      'pricing/star-part-4@2x.png',
      'pricing/star-part-5@2x.png',
      'pricing/star-part-6@2x.png',
      'pricing/star-part-7@2x.png',
    ],
  },

  'whats-new': {
    prefix: 'img/common/whats-new/',
    imgs: ['close-hover.png', 'arrow-up-hover.png', 'arrow-down-hover.png', '2017-09-20/bg-button-hover.png'],
  },
};

export default function preloadDesignImages(tp) {
  if (!preloadDesignImagesList[tp]) return;

  if (preloadDesignImagesList[tp].preloaded) return;

  preloadDesignImagesList[tp].preloaded = true;

  var prefix = preloadDesignImagesList[tp].prefix,
    list = preloadDesignImagesList[tp].imgs,
    imgs = [];

  for (var i = 0; i < list.length; i++) {
    imgs[i] = new Image();

    const filename = list[i];
    const retinaFilename = filename.replace(/\.(png|gif|jpg)$/, '@2x$&');
    imgs[i].src = RM_PUBLIC_PATH + prefix + (Modernizr.retina && !filename.includes('@2x') ? retinaFilename : filename);
  }
}
