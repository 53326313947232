<template>
  <div class="variant-input">
    <input v-model="url" :readonly="!!data.use_own_url" placeholder="URL" class="input tweet-url" />
    <div
      v-if="data.use_own_url || (data.url && data.url === url)"
      class="input-control clear"
      @click.stop="updateUrlData('', '')"
    ></div>
    <div v-else-if="!data.use_own_url && !data.url && !url" class="input-control target">
      <div class="use-url-popup">
        <ul>
          <li @click.stop="updateUrlData('', 'mag')">Using the project URL</li>
          <li @click.stop="updateUrlData('', 'page')">Using the page URL</li>
        </ul>
        <div class="corner-wrapper">
          <div class="corner"></div>
        </div>
        <div class="hover-zone"></div>
      </div>
    </div>
    <div v-else-if="url !== data.url" class="input-control apply" @click.stop="updateUrlData(url, '')"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },

  data: function() {
    return {
      url: '',
    };
  },

  ounted: function() {
    this.url = this.updateUrl();
  },

  methods: {
    updateUrl: function() {
      if (this.data.use_own_url === 'page') {
        return 'Using the page URL';
      }

      if (this.data.use_own_url === 'mag') {
        return 'Using the project URL';
      }

      return this.data.url;
    },

    updateUrlData: function(url, use_own_url) {
      this.data.url = url;
      this.data.use_own_url = use_own_url;
      this.url = this.updateUrl();
    },
  },
};
</script>

<style lang="less">
@import (reference) '../../css/common/fonts.less';
@import (reference) '../../css/common/constants.less';

.variant-input {
  .input {
    .avenir_demi;
    font-size: 14px;
    color: #979ea8;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    line-height: 25px;
    padding: 2px 26px 1px 8px;

    &::-webkit-input-placeholder {
      color: #979ea8;
      opacity: 0.5;
    }

    &:-moz-placeholder {
      color: #979ea8;
      opacity: 0.5;
    }
  }

  .input-prefix {
    .avenir_demi;
    font-size: 14px;
    color: #979ea8;
    top: 9px;
    position: absolute;
    left: 8px;
  }

  .input-control {
    position: absolute;
    top: 5px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    right: 6px;
    .transition(0.2s, background);

    &.clear {
      background: url('../../img/constructor/panels/twitter/input-delete.png') no-repeat 0 0;

      &:hover {
        background: url('../../img/constructor/panels/twitter/input-delete-hover.png') no-repeat 0 0;
      }
    }

    &.target {
      background: url('../../img/constructor/panels/twitter/input-target.png') no-repeat 0 0;

      &:hover {
        background: url('../../img/constructor/panels/twitter/input-target-hover.png') no-repeat 0 0;
        .use-url-popup {
          bottom: 27px;
          opacity: 1;
          visibility: visible;
        }
      }

      .use-url-popup {
        opacity: 0;
        visibility: hidden;
        bottom: 19px;
        @popup-width: 168px;
        position: absolute;
        width: @popup-width;
        background-color: #363234;

        border-radius: 4px;
        .centered-block(@popup-width);

        .multi-transition(opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, bottom 0.2s ease-in-out;);

        &.hidden {
          opacity: 0;
          visibility: hidden;
          bottom: 19px;
        }

        li {
          @height: 34px;
          height: @height;
          line-height: @height;
          display: block;
          font-size: 14px;
          color: white;
          text-align: center;
          .avenir_medium;
          .transition(0.2s, color);

          &:hover {
            color: #1ce4c8;
          }

          &:after {
            content: '';
            display: block;
            height: 1px;
            background-color: #262324;
          }

          &:last-child:after {
            display: none;
          }
        }

        .corner-wrapper {
          .panel-corner ((@popup-width / 2), 8px);

          .corner {
            background-color: #363234;

            // Оверррайд классов родителей
            box-shadow: none;
            margin-left: 0;
          }
        }

        .hover-zone {
          @width: 60px;
          width: @width;
          position: absolute;
          height: 15px;
          bottom: -15px;
          .centered-block(@width);
        }
      }
    }

    &.apply {
      background: url('../../img/constructor/panels/twitter/input-go.png') no-repeat 0 0;

      &:hover {
        background: url('../../img/constructor/panels/twitter/input-go-hover.png') no-repeat 0 0;
      }
    }

    &.hidden {
      display: none;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  .variant-input {
    .input-control {
      &.clear {
        background: url('../../img/constructor/panels/twitter/input-delete@2x.png') no-repeat 0 0;
        background-size: 18px 18px;

        &:hover {
          background: url('../../img/constructor/panels/twitter/input-delete-hover@2x.png') no-repeat 0 0;
          background-size: 18px 18px;
        }
      }

      &.target {
        background: url('../../img/constructor/panels/twitter/input-target@2x.png') no-repeat 0 0;
        background-size: 18px 18px;

        &:hover {
          background: url('../../img/constructor/panels/twitter/input-target-hover@2x.png') no-repeat 0 0;
          background-size: 18px 18px;
        }
      }

      &.apply {
        background: url('../../img/constructor/panels/twitter/input-go@2x.png') no-repeat 0 0;
        background-size: 18px 18px;

        &:hover {
          background: url('../../img/constructor/panels/twitter/input-go-hover@2x.png') no-repeat 0 0;
          background-size: 18px 18px;
        }
      }
    }
  }
}
</style>
