/**
 * Конструктор для контрола поворота виджета
 */
import VueControlClass from '../vue-control';

const CommonRotation = VueControlClass.extend({
  name: 'common_rotation',
  alt: 'Rotation',
  panelComponent: 'panel-rotation',
  keyboardShortcut: 'r',
  onPanelChange: function(update) {
    VueControlClass.prototype.onPanelChange.apply(this, arguments);

    // При флипе сохранить
    if (this.model.changed.hasOwnProperty('flip_v') || this.model.changed.hasOwnProperty('flip_h')) {
      this.model.save({});
    }
  },
  select: function() {
    VueControlClass.prototype.select.apply(this, arguments);
    this.block.frame.setRotationState();
  },
  deselect: function() {
    this.block.frame.removeRotationState();
    VueControlClass.prototype.deselect.apply(this, arguments);
  },
  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();
    return blocks.length && !blocks[0].isFullWidth() && !blocks[0].isFullHeight();
  },
});

export default CommonRotation;
