/**
 * Модуль Backbone модели данных пользователя системы
 * @module User
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Constants, Utils } from '../../common/utils';
import { FolderListCollection } from './folders';
import { MagList } from './mag-list';

/**
 * Модель данных пользователя
 * @class UserModel
 */
export const UserModel = Backbone.Model.extend({
  userpic_sizes: [64, 96, 128, 192, 256],

  mergePermissionsList: ['can_publish'],

  idAttribute: 'uri',

  //		TODO: В будущем отказаться от этого урла, переписав метод save
  url: '/api/me/',
  SSLDOMAIN_URL: '/api/user/domain/ssl',

  initialize: function(params, options) {
    _.bindAll(this);
  },

  /**
   * Сохнаяет на сервер список свойств модели.
   * @method store
   * @param fields {Object} Объект со списком полей и их значениями, которые требуется сохранить
   */
  store: function(fields, opts) {
    if (!fields.name || /^\s$/.test(fields.name)) {
      fields.name = this.get('name');
    } // Если имя пользователя пустое, сбрасываем в оригинальное значение

    fields = this.changedAttributes(fields);

    this.set(fields);

    if (!this.validate(fields)) {
      $.ajax({
        type: 'PUT',
        url: this.url,
        data: fields,
        success: opts && typeof opts.success === 'function' && opts.success,
        error: opts && typeof opts.error === 'function' && opts.error,
      });
    }
  },

  /**
   * Удаляет юзерпик пользователя
   * @method deletePic
   */
  deletePic: function() {
    var self = this;
    $.ajax({
      type: 'DELETE',
      url: '/api/me/pic/',
      success: function() {
        self.set('pic', '');
      },
    });
  },

  validate: function(attrs) {
    if (attrs.name !== undefined && _.isEmpty($.trim(attrs.name))) return 'Name yourself';
  },

  /**
   * Возвращает прямой URL на юзерпик нужного размера либо URL на картику-заглушку
   * @method getUserpic
   * @param size {Number} Горизонтальный размер юзерпика в пикселях
   * @return {String} URL
   */
  getUserpic: function(size) {
    var pic = this.get('pic');
    var firstAlphaNumeric;

    if (Modernizr.retina) size = size * 2;

    // Не используем юзерпики меньше 64. Размер меняется незначительно а качество падает
    size = _.find(this.userpic_sizes, function(s) {
      return s >= size;
    });

    if (pic) {
      pic = Utils.addFilenameComponent(pic, size);
      if (pic.indexOf('https://') == 0 || RM.common.isDownloadedSource) return pic;
      if (pic.indexOf('/api/upload/') == -1) pic = '/api/upload/' + pic;
      return pic;
    } else {
      firstAlphaNumeric = this.get('name').match(/^[a-z0-9]{1}/i); // Проверяем, является ли первый символ латинской буквой или цифрой

      var slash = RM.common.isDownloadedSource ? '' : RM_PUBLIC_PATH;
      if (firstAlphaNumeric) {
        return slash + 'img/stubs/avatar/' + firstAlphaNumeric[0].toLowerCase() + '.gif';
      } else {
        return slash + 'img/stubs/avatar/' + size + '.gif';
      }
    }
  },

  /**
   * Возвращает прямой URL на профиль пользователя
   * @method getLink
   * @return {String} URL
   */
  getLink: function() {
    if (RM.common.customDomainProfile) return '/';
    else if (RM.common.isDomainViewer) return Constants.readymag_host + '/' + this.get('uri');

    return '/' + this.get('uri');
  },

  /**
   * Возвращает объект со ссылкой и доменом сайта пользователя для отображения в профиле
   * @method getWebsiteButified
   * @return {Object} webiste
   */
  getWebsiteButified: function() {
    var urlparts,
      website = { link: '', label: '' };

    if (this.get('website')) {
      urlparts = Utils.URLParts(this.get('website'));
      urlparts.protocol = urlparts.protocol || 'http://';
      website.link = urlparts.protocol + urlparts.hostname + urlparts.path;
      website.label = urlparts.hostname;
    }

    return website;
  },

  /**
   * Возвращает объект со ссылкой и ником твиттера пользователя для отображения в профиле
   * @method getTwitterButified
   * @return {Object} twitter
   */
  getTwitterButified: function() {
    var urlparts,
      twitter = { link: '', nick: '' };

    if (this.get('twitter')) {
      urlparts = Utils.URLParts(this.get('twitter'));
      if (!urlparts.protocol && !urlparts.path) {
        // Задан только ник твиттера?
        urlparts.protocol = 'http://';
        twitter.nick = urlparts.hostname;
        urlparts.path = '/' + twitter.nick;
        urlparts.hostname = 'twitter.com';
      } else {
        urlparts.protocol = urlparts.protocol || 'http://';
        twitter.nick = urlparts.path.split('/')[1];
      }

      twitter.link = urlparts.protocol + urlparts.hostname + urlparts.path;
    }

    return twitter;
  },

  /**
   * Возвращает объект со ссылкой и ником фэйсбука пользователя для отображения в профиле
   * @method getFacebookButified
   * @return {Object} facebook
   */
  getFacebookButified: function() {
    var urlparts,
      facebook = { link: '', nick: '' };

    if (this.get('fb')) {
      urlparts = Utils.URLParts(this.get('fb'));
      try {
        if (urlparts.path.indexOf('profile.php') != -1) {
          // Урл с айдишником пользователя?
          facebook.nick = 'facebook';
        } else if (urlparts.path.split('/')[1] == 'pages') {
          // Урл фэйсбук-страницы?
          facebook.nick = decodeURIComponent(urlparts.path.split('/')[2]) || '';
        } else if (!urlparts.protocol && !urlparts.path) {
          // Задано простое, непарсящееся слово? Считаем это именем юзера фэйсбука
          facebook.nick = urlparts.hostname;
          urlparts.hostname = 'facebook.com';
          urlparts.path = '/' + facebook.nick;
        } else {
          facebook.nick = urlparts.path.split('/')[1].split('?')[0] || '';
        }

        urlparts.protocol = urlparts.protocol || 'http://';
        facebook.link = urlparts.protocol + urlparts.hostname + urlparts.path;
      } catch (e) {
        console.log('Error parsing FB URL: ', e);
      }
    }

    return facebook;
  },

  mergePermissions: function(permissions) {
    var oldPermissions = _.clone(this.get('permissions'));
    this.set('permissions', permissions);

    _.each(
      this.mergePermissionsList,
      function(p) {
        this.attributes.permissions[p] = this.attributes.permissions[p] && oldPermissions[p];
      },
      this
    );
  },

  resendConfirmationEmail: function() {
    $.get('/api/user/confirm/resend');
  },

  isPublisher: function() {
    return this.get('permissions').can_publish;
  },

  checkDomain: function(domainName, callback) {
    return $.ajax({
      type: 'POST',
      url: '/api/checkdomain',
      data: { type: 'user', domain: domainName },
      success: _.bind(function(data) {
        this.set({ last_checked_domain: domainName }, { silent: true });
        callback && callback(data);
      }, this),
      error: _.bind(function() {
        console.error(arguments);
        callback && callback(null);
      }, this),
    });
  },

  mapDomain: function(domainName, callbacks) {
    callbacks = callbacks || {};
    return $.ajax({
      type: 'POST',
      url: '/api/user/domain/',
      data: { domain: domainName, status: 'on' },
      success: _.bind(function(data) {
        this.set({ domain: domainName }, { silent: true });
        callbacks.success && callbacks.success(data);
      }, this),
      error: _.bind(function(xhr) {
        if (xhr && xhr.status >= 500) return callbacks.error(null);
        return callbacks.error && callbacks.error({ badDNSSettings: true });
      }, this),
    });
  },

  unmapDomain: function(callback) {
    return $.ajax({
      type: 'POST',
      url: '/api/user/domain/',
      data: { status: 'off' },
      success: _.bind(function(data) {
        this.unset('domain', { silent: true });
        callback && callback(data);
      }, this),
      error: _.bind(function() {
        console.error(arguments);
        callback && callback(null);
      }, this),
    });
  },

  changeSSL: function(domainName, value, callbacks) {
    callbacks = callbacks || {};
    return $.ajax({
      type: 'POST',
      url: this.SSLDOMAIN_URL,
      data: { domain: domainName, status: value ? 'on' : 'off' },
      success: _.bind(function(data) {
        callbacks.success && callbacks.success(data);
      }, this),
      error: _.bind(function(xhr) {
        console.log(xhr);
        if (xhr && xhr.status >= 500) return callbacks.error(null);
        return callbacks.error && callbacks.error(xhr.responseJSON);
      }, this),
    });
  },

  getSSLState: function(domain, callbacks) {
    return $.ajax({
      type: 'GET',
      url: this.SSLDOMAIN_URL,
      data: {
        domain: domain,
        type: 'user',
      },
      success: _.bind(function(data) {
        callbacks.success && callbacks.success(data);
      }),
      error: _.bind(function(xhr) {
        if (xhr && xhr.status >= 500) return callbacks.error(null);
        return callbacks.error && callbacks.error(xhr.responseJSON);
      }),
    });
  },

  isBetaTester: function() {
    // return true
    var permissions = this.get('permissions') || {};
    return !!permissions.can_use_beta_testing;
  },
});

/**
 * Коллекция пользователей. Пополняется постепенно по мере запроса данных о конкретных пользователях.
 * @class UsersCollection
 */
export const UsersCollection = Backbone.Collection.extend({
  model: UserModel,
});

/**
 * Класс-загрузчик пользователей. Содежрит в себе коллекцию пользователей {{#crossLink "UsersCollection"}}{{/crossLink}}
 * @class UsersLoader
 */
export const UsersLoader = function() {
  this.allUsers = new UsersCollection();
  this.userMags = {};
  this.userFolders = {};

  _.bindAll(this);
};

UsersLoader.prototype = {
  LOAD_URL: '/api/readymags/user/',

  /**
   * Загружает данные о пользователе по его URI, и пополняет коллекцию allUsers. По окончании загрузки вызывает коллбэки
   * @method loadByUsername
   * @param options {Object} объект c URI пользователя, коллбэками и признаком, что запрошенный пользователь это мы {user_uri:, success:, error:, is_me:, force:}
   * Если force: true, то юзер и мэги в любом случае загружаются с сервера
   * @async
   */
  loadByUsername: function(options) {
    var user, mags;

    if (RM.common.customDomainProfile && this.allUsers.at(0)) {
      var user = this.allUsers.at(0);
      var mags = this.userMags[this.allUsers.at(0).get('uri')];

      user.folders = this.userFolders[this.allUsers.at(0).get('uri')];

      return (
        options.success &&
        options.success({
          user: user,
          mags: mags,
        })
      );
    }

    var requestedUser = this.allUsers.find(function(u) {
      return u.get('uri').toLowerCase() == options.user_uri.toLowerCase();
    });

    var self = this;

    var onSuccess = _.bind(function(server_response) {
      var user, mags;

      if (!server_response.user) {
        return options.success({
          user: server_response,
        });
      }

      this.load(server_response, options.is_me);

      user = this.allUsers.get(server_response.user.uri);
      mags = this.userMags[server_response.user.uri];
      user.folders = this.userFolders[server_response.user.uri];

      options.success &&
        options.success({
          user: user,
          mags: mags,
        });
    }, this);

    if (requestedUser && !options.force) {
      mags = this.userMags[requestedUser.get('uri')];
      requestedUser.folders = this.userFolders[requestedUser.get('uri')];

      options.success({
        user: requestedUser,
        mags: mags,
      });
    } else {
      this.request(options.user_uri, {
        success: onSuccess,
        error: options.error,
      });
    }
  },

  load: function(server_response, isMe) {
    var userUri;
    if (_.isEmpty(server_response)) return;

    var user = server_response.user || server_response;
    var mags = server_response.mags;
    var folders = user.folders || [];

    if (user) {
      userUri = user.uri;

      if (FolderListCollection) {
        // На главной у нас нет этих моделей. И не надо
        this.userFolders[userUri] = new FolderListCollection(folders, { parse: true });
        delete user.folders;
      }

      this.allUsers.remove(userUri); // Этой строкой мы также отключаем кэширование (см. выше еще)
      this.allUsers.add(user);

      if (isMe && user.contributors) {
        _.each(user.contributors, function(contributor) {
          if (contributor.member) contributor.member = new UserModel(contributor.member);
        });
      }

      var shortUser = _.pick(user, '_id', 'uri', 'desc', 'pic');
      if (isMe) shortUser.isMe = isMe;

      _.each(mags, function(m) {
        m.user = _.clone(shortUser);
      });

      if (MagList) {
        // На главной у нас нет этих моделей. И не надо

        this.userMags[userUri] = new MagList(mags, { parse: true });

        //				Если запрошенный пользователь это мы, то проставляем атрибут всем нашим мэгам
        if (isMe && !_.isEmpty(mags)) {
          this.userMags[userUri].each(function(mag) {
            mag.user.set('isMe', true);
          });
        }
      }
    }
  },

  loadShared: function(shared) {
    if (_.isEmpty(shared)) return;

    this.sharedMags = {};

    _.each(
      shared,
      function(s) {
        var shortUser = _.pick(s.user, '_id', 'uri', 'desc', 'pic');

        _.each(s.mags, function(m) {
          m.user = _.clone(shortUser);
        });

        this.sharedMags[s.user.uri.toLowerCase()] = {
          user: new UserModel(s.user),
          mags: new MagList(s.mags, { parse: true }),
        };
      },
      this
    );
  },

  request: function(uri, options) {
    if (RM.common.customDomainProfile) {
      this.LOAD_URL = '/api/domain/readymags/user/';
      uri = ''; // Под кастомным доменом можно смотреть только текущего юзера
    }

    options = options || {};

    this.abortLoading();

    var callback = options.success || $.noop;

    this.loadingXHR = $.ajax(
      _.extend(options, {
        type: 'GET',
        url: this.LOAD_URL + uri,
      })
    );
  },

  abortLoading: function() {
    this.loadingXHR && this.loadingXHR.abort && this.loadingXHR.abort();
  },
};

$(function() {
  RM.data.usersLoader = new UsersLoader();

  var preloaded_me = window.ServerData.me;
  RM.data.usersLoader.load(preloaded_me, true);

  if (preloaded_me) {
    preloaded_me = preloaded_me.user || preloaded_me;

    if (preloaded_me.uri) RM.data.usersLoader.me = RM.data.usersLoader.allUsers.get(preloaded_me.uri);
  } else {
    RM.data.usersLoader.me = false;
  }
  if (
    window.ServerData.mags &&
    window.ServerData.mags.user &&
    window.ServerData.mags.mags &&
    (!RM.data.usersLoader.me || RM.data.usersLoader.me.get('uri') != window.ServerData.mags.user.uri)
  ) {
    RM.data.usersLoader.load(window.ServerData.mags);
  }

  if (window.ServerData.shared) {
    RM.data.usersLoader.loadShared(window.ServerData.shared);
  }

  if (!_.isEmpty(ServerData.sharedError)) {
    RM.data.usersLoader.sharedError = ServerData.sharedError;
  }
});
