/**
 * jQuery + Модули
 *
 * Пакет подключает jQuery и патчит его зависимостями
 */
const jquery = require('jquery');
const instance = jquery.noConflict(true);
window.$ = window.jQuery = instance;

require('./vendor/jquery.hotkeys');
require('./vendor/jquery-ui.min');
require('./vendor/jquery.dotdotdot');
require('./vendor/jquery.event.drag-2.2');
require('./vendor/jquery.fileupload');
require('./vendor/jquery.keycodes');
require('./vendor/jquery.rmalttext');
require('./vendor/jquery.rmautoresizetextarea');
require('./vendor/jquery.rmdrag');
require('./vendor/jquery.rmmousewheel');
require('./vendor/jquery.rmnumericinput');
require('./vendor/jquery.rmopacitydrag');
require('./vendor/jquery.rmscroll');
require('./vendor/jquery.rmswitcher');
require('./vendor/jquery.rmswipe');
require('./vendor/utils');
require('./vendor/stickyfill');
require('./vendor/swipejs-2.0.js');

/**
 * Экспортируем сразу несколько вариантов
 * import jQuery from '@rm/jquery'
 * import { $, jQuery } from '@rm/jquery'
 */
export default instance;
export const jQuery = instance;
export const $ = instance;
