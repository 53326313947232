/**
 * Набор полезных функций для математики
 */
import _ from '@rm/underscore';

const MathUtils = {
  /**
   * Углы сторон прямоугольника
   */
  sideAngles: {
    top: 0,
    right: Math.PI * 0.5,
    bottom: Math.PI,
    left: Math.PI * 1.5,
  },

  // блок функций для определения пересечения выпуклых многоугольников (в нашем случае это рамка выделения и повернутый блок)
  // векторное произведение
  calcOrientedTriangleSquare: function(a, b, c) {
    return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x);
  },

  // проверка пересечения прямых по одной из проекций (передаються либо иксы двух прямых, либо игреки)
  intersectsOnAxisProjection: function(a, b, c, d) {
    var t;
    if (a > b) {
      t = a;
      a = b;
      b = t;
    }
    if (c > d) {
      t = c;
      c = d;
      d = t;
    }
    if (a < c) a = c;
    if (b > d) b = d;
    return a <= b;
  },

  // проверка пересечения линий
  isLinesIntersects: function(a, b, c, d) {
    return (
      MathUtils.intersectsOnAxisProjection(a.x, b.x, c.x, d.x) &&
      MathUtils.intersectsOnAxisProjection(a.y, b.y, c.y, d.y) &&
      MathUtils.calcOrientedTriangleSquare(a, b, c) * MathUtils.calcOrientedTriangleSquare(a, b, d) <= 0 &&
      MathUtils.calcOrientedTriangleSquare(c, d, a) * MathUtils.calcOrientedTriangleSquare(c, d, b) <= 0
    );
  },

  // проверка нахождения точки внутри выпуклого полигона либо на его границе
  isConvexPolygonContainsPoint: function(r, p) {
    for (var i = 0; i < r.length; i++) {
      var j = (i + 1) % r.length;
      if (MathUtils.calcOrientedTriangleSquare(r[i], r[j], p) < 0) return false;
    }
    return true;
  },

  // проверка пересечения выпуклых полигонов
  // считаем что полигоны пересекаются, если либо:
  // 1. любая из точек первого многоугольника лежит внутри второго
  // 2. любая из точек второго многоугольника лежит внутри первого
  // 3. любой из отрезков (сторон) одного многоугольника пересекаеться с любым из отрезков другого
  // алгоритм получился не самый эффективный, но зато самый короткий и понятный
  // а выигрывать доли миллисекунды нам тут нет никакой нужды
  isConvexPolygonsIntersects: function(r1, r2) {
    var i, i1, j, j1;

    for (i = 0; i < r1.length; i++) if (MathUtils.isConvexPolygonContainsPoint(r2, r1[i])) return true;

    for (i = 0; i < r2.length; i++) if (MathUtils.isConvexPolygonContainsPoint(r1, r2[i])) return true;

    for (i = 0; i < r1.length; i++) {
      i1 = (i + 1) % r1.length;
      for (j = 0; j < r2.length; j++) {
        j1 = (j + 1) % r2.length;
        if (MathUtils.isLinesIntersects(r1[i], r1[i1], r2[j], r2[j1])) return true;
      }
    }

    return false;
  },

  /**
   * Выясняет, пересекаются ли два bounding box'а
   * @keywords overlap, intersect
   * @param {Object} box1 Объект, который возвращает getBoundingClientRect
   * @param {Object} box2
   * @returns {boolean}
   */
  doBoundingBoxesIntersect: function(box1, box2) {
    return (
      box1 &&
      box2 &&
      !(box2.left > box1.right || box2.right < box1.left || box2.top > box1.bottom || box2.bottom < box1.top)
    );
  },

  // расчитывает положение "ограничивающего бокса" текущего блока
  // это нужно при ротайшенах и используется в снепинге, в контроле выравнивания, в формировании рамки группы и еще наверное где-нибудь
  // если у блока нет ротэйшена, то просто вернет его текущие left-top-width-height
  /**
   * @param {Object} data {width: number, height: number, left: number, top: number, sinAngle: number, cosAngle: number}
   * @param {String|Boolean} [fixed]
   * @param {Boolean} [noPrefix] Не добавлять префиксы bb_
   * @returns {{bb_width: number, bb_height: number, bb_left: *, bb_top: *}} или {{width: number, height: number, left: *, top: *}}
   */
  calcBoundingBox: function(data, fixed, noPrefix) {
    var rotatedBox = MathUtils.calcRotatedBox(data),
      xCoords = _.pluck(rotatedBox, 'x'),
      yCoords = _.pluck(rotatedBox, 'y');

    var minX = Math.min.apply(null, xCoords),
      maxX = Math.max.apply(null, xCoords),
      minY = Math.min.apply(null, yCoords),
      maxY = Math.max.apply(null, yCoords),
      left = minX,
      top = minY,
      width = maxX - minX,
      height = maxY - minY;

    if (fixed) {
      if (['n', 'c', 's'].indexOf(fixed) > -1) left = data.left;
      if (['w', 'c', 'e'].indexOf(fixed) > -1) top = data.top;
    }

    return noPrefix
      ? {
          width: width,
          height: height,
          left: left,
          top: top,
        }
      : {
          bb_width: width,
          bb_height: height,
          bb_left: left,
          bb_top: top,
        };
  },

  /**
   * Приводит бокс к формату getBoundingClientRect()
   * @param {Object} box {width: number, height: number, left: number, top: number}
   * @return {{width: number, height: number, left: number, right: number, top: number, bottom: number}}
   */
  boxToRect: function(box) {
    return {
      width: box.width,
      height: box.height,
      top: box.top,
      bottom: box.top + box.height,
      left: box.left,
      right: box.left + box.width,
    };
  },

  /**
   * Рассчитывает общий bounding box для нескольких элементов
   * @param {Array<Object>} boxes
   */
  getBoundingBoxOfMany: function(boxes) {
    var verticals = [];
    var horizontals = [];

    _.each(boxes, function(box) {
      var rect = typeof box.right !== 'undefined' ? _.extend({}, box) : MathUtils.boxToRect(box);
      verticals.push(rect.left);
      verticals.push(rect.right);
      horizontals.push(rect.top);
      horizontals.push(rect.bottom);
    });

    var box = {
      top: Math.min.apply(null, horizontals),
      right: Math.max.apply(null, verticals),
      bottom: Math.max.apply(null, horizontals),
      left: Math.min.apply(null, verticals),
    };
    box.width = box.right - box.left;
    box.height = box.bottom - box.top;

    return box;
  },

  // расчитывает координаты углов повернутого бокса
  // для неповернутого просто вернет координаты углов бокса (такие же координаты вернет getBoxData)
  calcRotatedBox: function(data) {
    var coords = [],
      // точки углов блока по оси X
      DOTS_POS_X = [0, 1, 1, 0],
      // точки углов блока по оси Y
      DOTS_POS_Y = [0, 0, 1, 1],
      cx = data.left + data.width / 2, // находим центр вращения (центр бокса)
      cy = data.top + data.height / 2,
      sinAngle = data.sinAngle,
      cosAngle = data.cosAngle;

    _.each(DOTS_POS_X, function(val, ind) {
      var dot_x = data.left + data.width * DOTS_POS_X[ind],
        dot_y = data.top + data.height * DOTS_POS_Y[ind],
        dx = dot_x - cx, // находим смещение точки от центра вращения (неповернутой точки)
        dy = cy - dot_y,
        dot_x = cx + cosAngle * dx + sinAngle * dy,
        dot_y = cy + sinAngle * dx - cosAngle * dy;

      coords.push({ x: dot_x, y: dot_y });
    });

    return coords;
  },

  /**
   * Рассчитывает расстояние между двумя точками
   * @param {Object} point1
   * @param {Number} point1.x
   * @param {Number} point1.y
   * @param {Object} point2
   * @returns {number}
   */
  getDistance: function(point1, point2) {
    return Math.sqrt((point1.x - point2.x) * (point1.x - point2.x) + (point1.y - point2.y) * (point1.y - point2.y));
  },

  /**
   * Возвращает оптимальные стороны для обоих блоков
   * @param {Object} fromBox
   * @param {Object} toBox
   * @return {Object}
   */
  getBestPoints: function(fromBox, toBox) {
    var sides = ['right', 'left', 'bottom', 'top'];
    var bestPoints = {
      distance: Infinity,
      fromPoint: {},
      toPoint: {},
    };
    var distance;
    var fromPoint;
    var toPoint;

    for (var f = 0; f < sides.length; f++) {
      for (var t = 0; t < sides.length; t++) {
        fromPoint = MathUtils.getSideCenter(fromBox, sides[f]);
        toPoint = MathUtils.getSideCenter(toBox, sides[t]);
        distance = MathUtils.getDistance(fromPoint, toPoint);
        bestPoints =
          distance < bestPoints.distance
            ? {
                distance: distance,
                fromSide: sides[f],
                fromPoint: fromPoint,
                toSide: sides[t],
                toPoint: toPoint,
              }
            : bestPoints;
      }
    }

    return bestPoints;
  },

  /**
   * Возвращает центр стороны прямоугольника
   * @param {Object} box
   * @param {String} side
   * @returns {{x: Number, y: Number}}
   */
  getSideCenter: function(box, side) {
    var point;
    switch (side) {
      case 'left':
        point = {
          x: box.left,
          y: box.top + box.height / 2,
        };
        break;
      case 'top':
        point = {
          x: box.left + box.width / 2,
          y: box.top,
        };
        break;
      case 'bottom':
        point = {
          x: box.left + box.width / 2,
          y: box.top + box.height,
        };
        break;
      case 'right':
      default:
        point = {
          x: box.left + box.width,
          y: box.top + box.height / 2,
        };
        break;
    }

    point = {
      x: (point.x - box.centerX) * box.cos - (point.y - box.centerY) * box.sin + box.centerX,
      y: (point.y - box.centerY) * box.cos + (point.x - box.centerX) * box.sin + box.centerY,
    };

    return point;
  },

  getPerpendicularEnd: function(side, point, box, length) {
    var perpendicularAngle = box.angle + MathUtils.sideAngles[side];
    return {
      x: length * Math.sin(perpendicularAngle) + point.x,
      y: -length * Math.cos(perpendicularAngle) + point.y,
    };
  },

  /**
   * Возвращает прямоугольник для элемента: почти то же самое, что возвращает getBoundingClientRect + угол + координаты центра
   * @param {View|jQuery|HTMLElement} block Вью блок, jQuery-элемент или html-элемент
   * @returns {Object}
   * @returns {Number} Object.left
   * @returns {Number} Object.right
   * @returns {Number} Object.top
   * @returns {Number} Object.bottom
   * @returns {Number} Object.width
   * @returns {Number} Object.height
   * @returns {Number} Object.angle Угол в радианах
   * @returns {Number} Object.sin
   * @returns {Number} Object.cos
   * @returns {Number} Object.centerX
   * @returns {Number} Object.centerY
   */
  getBox: function(block) {
    var element = (block.$el && block.$el[0]) || (block.length && block[0]) || block;
    // Получать координаты и размеры из модели не получится: координаты в модели обновляются после окончания перемещения.
    var box = element.getBoundingClientRect();

    // Берём угол из модели — там он самый актуальный
    var angle = block.model ? ((block.model.attributes.angle || 0) * Math.PI) / 180 : 0;
    box = _.extend(box, {
      // Угол в радианах
      angle: angle,
      // Синус и косинус, которые несколько раз используются в расчётах
      sin: Math.sin(angle),
      cos: Math.cos(angle),
    });

    // Добавим координаты центра
    box = _.extend(box, {
      centerX: box.left + box.width / 2,
      centerY: box.top + box.height / 2,
    });

    return box;
  },

  /**
   * Получает вложенный бокс после ресайза,
   * зная собственный бокс до ресайза, родительский бокс до ресайза и родительский бокс после ресайза
   * @param {Object} oldBox
   * @param {Object} oldPackBox
   * @param {Object} newPackBox
   * @param {String} [singleFactor] width или height Ориентироваться только на ширину или только на высоту
   * @returns {Object} {width: number, height: number, left: number, top: number}
   */
  getResizedBoxNested: function(oldBox, oldPackBox, newPackBox, singleFactor) {
    var widthFactor = newPackBox.width / oldPackBox.width;
    var heightFactor = newPackBox.height / oldPackBox.height;
    widthFactor = singleFactor === 'height' ? heightFactor : widthFactor;
    heightFactor = singleFactor === 'width' ? widthFactor : heightFactor;
    return {
      left: (oldBox.left - oldPackBox.left) * widthFactor + newPackBox.left,
      top: (oldBox.top - oldPackBox.top) * heightFactor + newPackBox.top,
      width: oldBox.width * widthFactor,
      height: oldBox.height * heightFactor,
    };
  },

  /**
   * Получает бокс модели после ресайза (то есть css-параметры width, height, left, top),
   * зная собственный бокс до ресайза, bounding box до ресайза и bounding box после ресайза
   * @param {Object} oldBox
   * @param {Object} oldBB
   * @param {Object} newBB
   * @returns {Object} {width: number, height: number, left: number, top: number}
   */
  getResizedBoxByBB: function(oldBox, oldBB, newBB) {
    var newBBCenter = {
      left: newBB.left + newBB.width / 2,
      top: newBB.top + newBB.height / 2,
    };
    var widthFactor = newBB.width / oldBB.width;
    var heightFactor = newBB.height / oldBB.height;
    var newWidth = oldBox.width * widthFactor;
    var newHeight = oldBox.height * heightFactor;
    return {
      left: newBBCenter.left - newWidth / 2,
      top: newBBCenter.top - newHeight / 2,
      width: newWidth,
      height: newHeight,
    };
  },

  /**
   * Возвращает предельный отмасштабированный бокс, размеры которого не меньше минимальных и не больше максимальных
   * @param {Object} box {width: number, height: number}
   * @param {Object} limits {minwidth: number, minheight: number, maxwidth: number, maxheight: number}
   * @return {{minwidth: number, minheight: number, maxwidth: number, maxheight: number}}
   */
  getBoxLimits: function(box, limits) {
    var isHorizontal = box.width / box.height > 1;
    var downscaleFactor = isHorizontal ? limits.minheight / box.height : limits.minwidth / box.width;
    var upscaleFactor = isHorizontal ? limits.maxwidth / box.width : limits.maxheight / box.height;
    return {
      minwidth: limits.minwidth ? Math.round(box.width * downscaleFactor) : 1,
      minheight: limits.minheight ? Math.round(box.height * downscaleFactor) : 1,
      maxwidth: limits.maxwidth ? Math.round(box.width * upscaleFactor) : Infinity,
      maxheight: limits.maxheight ? Math.round(box.height * upscaleFactor) : Infinity,
    };
  },
};

export default MathUtils;
