/**
 * Класс для всплывающих алертов
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/common/alert.tpl';

const Alert = Backbone.View.extend({
  template: templates['template-common-alert'],

  events: {
    'click .button': 'onButtonClick', // Должно стоять в начале!

    click: 'hideOnBgClick',
    'click .button.upgrade': 'upgrade',
    'click .button.cancel': 'hide',
    'click .button.close': 'hide',
    'click .button.ok': 'hide',
    'click .contact-link': 'hide',
    'click .button.resend': 'resendConfirmationEmail',
  },

  initialize: function(params) {
    _.bindAll(this);
    _.extend(this, params);
    this.opts = this.opts || {};

    this.router = this.router || RM.homepageRouter || RM.collectorRouter || RM.constructorRouter;
    if (this.router && this.router.analytics) {
      this.router.analytics.sendEvent('Alert Show', {
        label: this.alert_source,
        _alert_source: this.alert_source,
      });
    }
  },

  render: function() {
    this.setElement($(this.template({ opts: this.opts })));

    if (this.$parent) {
      this.$parent.append(this.$el).addClass('visible');
    }

    _.delay(
      _.bind(function() {
        this.$el.addClass('show');
      }, this),
      50
    );

    $('body').on('keyup', this.onBodyKeyUp);

    RM.common.disableShortcuts['alert'] = true;
  },

  hide: function() {
    delete RM.common.disableShortcuts['alert'];

    $('body').off('keyup', this.onBodyKeyUp);

    this.$el.removeClass('show').addClass('hide');

    _.delay(
      _.bind(function() {
        this.remove();
        this.$parent && this.$parent.removeClass('visible');
      }, this),
      400
    );

    this.trigger('hide', { lastButtonClicked: this.lastButtonClicked });
  },

  upgrade: function(e) {
    Modernizr.sessionstorage && window.sessionStorage.setItem('rm.accountUpgradeReturnUrl', window.location.pathname);

    if (this.router && this.router.analytics) {
      this.router.analytics.sendEvent('Alert Upgrade', {
        label: this.alert_source,
        _alert_source: this.alert_source,
      });
    }

    if (e.which == 2 || e.metaKey || e.ctrlKey) return;

    if (this.router && this.router == RM.collectorRouter) {
      this.hide();
      this.router.navigate('/settings#change-plan', { trigger: true });
      e.preventDefault();
    }
  },

  onBodyKeyUp: function(e) {
    if (e.keyCode == 27) {
      e.stopPropagation();
      e.preventDefault();
      this.hide();
    }
  },

  hideOnBgClick: function(e) {
    if ($(e.target).closest('.panel').length) {
      return;
    } // Отсеиваем клики внутри панели

    this.hide();
  },

  resendConfirmationEmail: function() {
    $.get('/api/user/confirm/resend');
    this.hide();
  },

  onButtonClick: function(e) {
    var $button = $(e.currentTarget),
      type = $button.attr('data-type');

    this.lastButtonClicked = type;
  },
});

export default Alert;
