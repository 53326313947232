module.exports = {["template-viewer-widget-button"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n    <input class="text"\n           value="'+
((__t=(data.text))==null?'':_.escape(__t))+
'"\n           type="text"\n           disabled\n           readonly\n           autocapitalize="off"\n           autocorrect="off"\n           autocomplete="off"\n           spellcheck="false"\n           role="button"\n           name="'+
((__t=(data.text))==null?'':_.escape(__t))+
'"/>\n\n';
}
return __p;
},}