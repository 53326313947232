/**
 * Конструктор для контрола редактирования Google Maps
 */
import $ from '@rm/jquery';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/gmaps_edit.tpl';

const GmapsEdit = ControlClass.extend({
  name: 'gmaps_edit', // должно совпадать с классом вьюхи

  className: 'control gmaps_edit',

  saveOnDeselect: true,
  saveOnDestroy: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-gmaps_edit'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);
    this.block.enterEditState();
    this.$('.icon').addClass('edit');
  },

  deselect: function() {
    this.block.exitEditState();
    ControlClass.prototype.deselect.apply(this, arguments);
    this.$('.icon').removeClass('edit');
  },

  bindLogic: function() {
    this.block.on('editMapClick', this.onClick);
  },

  unBindLogic: function() {
    this.block.off('editMapClick', this.onClick);
  },

  save: function() {
    this.model.save({});
  },
});

export default GmapsEdit;
