/**
 * Конструктор для контрола поворота виджета
 */
import VueControlClass from '../vue-control';

const IframeEdit = VueControlClass.extend({
  name: 'iframe_edit',
  alt: 'Edit Code',

  select: function() {
    VueControlClass.prototype.select.apply(this, arguments);
    this.block.showPanel();
  },

  deselect: function() {
    this.block.hidePanel();
    VueControlClass.prototype.deselect.apply(this, arguments);
  },

  bindLogic: function() {
    this.block.on('editCodeClick', this.onClick);
  },

  unBindLogic: function() {
    this.block.off('editCodeClick');
  },
});

export default IframeEdit;
