module.exports = {["template-constructor-block-facebook-type_like_button"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="fb-like fb" data-href="'+
((__t=(data.url ? data.url : 'http://readymag.com'))==null?'':_.escape(__t))+
'" '+
((__t=(data.share_button || data.send_button ? 'data-share="true"' : ''))==null?'':__t)+
' data-width="'+
((__t=(data.w))==null?'':__t)+
'" data-show-faces="'+
((__t=(data.show_faces))==null?'':__t)+
'" data-colorscheme="'+
((__t=(data.theme))==null?'':__t)+
'" data-layout="'+
((__t=(data.layout))==null?'':__t)+
'" data-type="data.type_like_button"></div>\n	<div class="overlay"></div>\n';
}
return __p;
},["template-constructor-block-facebook-type_page"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="fb-page fb" data-href="'+
((__t=(data.url ? data.url : 'https://www.facebook.com/readymag'))==null?'':_.escape(__t))+
'" data-width="'+
((__t=(data.w))==null?'':__t)+
'" data-height="'+
((__t=(data.h))==null?'':__t)+
'" data-show-facepile="'+
((__t=(data.show_faces))==null?'':__t)+
'" data-colorscheme="'+
((__t=(data.theme))==null?'':__t)+
'" data-tabs="'+
((__t=(data.show_stream ? 'timeline, events, messages' : ''))==null?'':__t)+
'" data-show-border="true" data-small-header="'+
((__t=(data.small_header))==null?'':__t)+
'" data-adapt-container-width="true"></div>\n	<div class="overlay"></div>\n';
}
return __p;
},["template-constructor-block-facebook-type_comments"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="fb-comments fb" data-href="'+
((__t=(data.url ? data.url : 'http://readymag.com'))==null?'':_.escape(__t))+
'" data-width="'+
((__t=(data.w))==null?'':__t)+
'" data-num-posts="'+
((__t=(data.num_posts))==null?'':__t)+
'" data-colorscheme="'+
((__t=(data.theme))==null?'':__t)+
'"></div>\n	<div class="overlay"></div>\n';
}
return __p;
},}