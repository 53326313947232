import _ from '@rm/underscore';
import TextUtils from '../../common/textutils';

/**
 * Модель данных для доступных шрифтов и избранных
 */

const FontsModel = function(edit_params) {
  this.edit_params = edit_params;
  this.proxyMethods(['set', 'get', 'fetch', 'on', 'off', 'save']);
  this.updateTypetodayVariations();
};

FontsModel.prototype = {
  id: '_fonts',

  MAX_VISIBLE_TYPEKIT_FONTS: 5,

  FVD_NAMES: {
    n1: 'Thin',
    n2: 'ExtraLight',
    n3: 'Light',
    n4: 'Regular',
    n5: 'Medium',
    n6: 'SemiBold',
    n7: 'Bold',
    n8: 'ExtraBold',
    n9: 'Black',
    i1: 'Thin Italic',
    i2: 'ExtraLight Italic',
    i3: 'Light Italic',
    i4: 'Italic',
    i5: 'Medium Italic',
    i6: 'SemiBold Italic',
    i7: 'Bold Italic',
    i8: 'ExtraBold Italic',
    i9: 'Black Italic',
  },

  // просто так этот список руками НЕ ТРОГАТЬ!
  PROVIDERS: {
    system: {
      title: 'System Fonts',
      url: '',
    },
    google: {
      title: 'Google Fonts',
      url: 'http://www.google.com/fonts',
    },
    typekit: {
      title: 'Typekit',
      url: 'http://www.typekit.com/',
    },
    webtype: {
      title: '<Webtype>',
      url: 'http://www.webtype.com/',
    },
    typetoday: {
      title: 'Type.today',
      url: 'https://type.today/',
    },
    custom: {
      title: 'Custom web font',
      url: '',
    },
  },

  /**
   * Проксировать некоторые методы на объект this.edit_params
   */
  proxyMethods: function(methods) {
    var self = this;
    var edit_params = this.edit_params;

    _.each(methods, function(method) {
      self[method] = function() {
        return edit_params[method].apply(edit_params, arguments);
      };
    });
  },

  /**
   * Исключает устаревшие начертания typetoday и сохраняет шрифты, если после этого они изменились
   */
  updateTypetodayVariations: function() {
    // У typetoday могли поменяться начертания.
    // Добавим новые начертания, оставим старые, и сохраним изменения, если они есть.
    var willUpdate = false;
    var updatedFonts = _.map(this.get('fonts'), function(font) {
      if (font.provider === 'typetoday') {
        var upToDateFont = _.find(TextUtils.fontsShortList.typetoday, { name: font.css_name });
        // Проверим, есть ли новые начертания
        var newVariationsAdded = Boolean(upToDateFont && _.difference(upToDateFont.variations, font.variations).length);
        willUpdate = willUpdate || newVariationsAdded;
        return newVariationsAdded
          ? _.extend({}, font, { variations: _.union(font.variations, upToDateFont && upToDateFont.variations) })
          : font;
      } else {
        return font;
      }
    });
    willUpdate && this.save({ fonts: updatedFonts }, { silent: true });
  },

  // функция загружает все шрифты добавленые юзером в фонт селектор из фонт эксплорера
  // формат типа: {provider: 'typekit', css_name: 'hmqz', name: 'Adobe Caslon Pro', variations: ['n4','i4','n6','i6','n7','i7']},
  // умеет работать с провайдерами: google, webtype, typekit, typetoday (webtype, typetoday все у нас, self-hosted)
  // и самое главное: каждый раз при вызове сканирует список уже загруженный css и смотрит какие шрифты уже доступны
  // и выкидывает их из списка fontsList (так что можно вызывать сколько угодно раз)
  appendFontsCssToDocument: function(params, callback) {
    TextUtils.appendFontsCssToDocument(_.extend({ fonts: this.get('fonts') }, params), callback);
  },

  findFontByCSSName: function(font_family) {
    return (
      _.find(this.get('fonts'), function(font) {
        return font.css_name.toLowerCase() == font_family.toLowerCase();
      }) || { css_name: font_family, name: font_family }
    ); // защита от полного падения текстового виджета в случае если в нем используется шрифт которого нет в фонт селекторе, но то бажный случай и все равно надо разбираться почему так произошло
  },

  getProviderData: function(provider) {
    var providerData = this.PROVIDERS[provider] || {};
    _.defaults(providerData, { title: '', url: '' });
    return providerData;
  },

  getStyleName: function(font_weight, font_style, font_name) {
    var fvd = (font_style.toLowerCase() == 'italic' ? 'i' : 'n') + font_weight / 100;
    var custom_fvd_name;
    if (font_name) {
      custom_fvd_name = TextUtils.getCustomVariationNameByFVD(font_name, fvd);
    }

    return custom_fvd_name || this.FVD_NAMES[fvd];
  },

  // возвращает для заданной комбинации имени шрифта, его веса (100-900) и стиля (normal, italic)
  // значения веса и стиля которые отобразятся браузером, учитывая faux bold, faux italic, faux bold italic (синтезированные начертания)
  // примеры:
  // если у нас css для текста задан как Tahoma, 400, italic   то функция вернет 400, italic (хотя Tahoma и не имеет шрифта 400, italic браузер его синтезирует)
  // примеры посложнее:
  // если у нас css для текста задан как Lobster, 600, normal   то функция вернет 700, normal (хотя Lobster и не имеет ни шрифта 600, normal ни 700, normal браузер его синтезирует как 700, normal)
  // если у нас css для текста задан как Tahoma, 600, normal   то функция вернет 700, normal (хотя Tahoma и не имеет шрифта 600, normal она имеет 700, normal браузер его и возьмет)
  // если у нас css для текста задан как Tahoma, 500, normal   то функция вернет 400, normal (хотя Tahoma и не имеет шрифта 500, normal она имеет 400, normal браузер его и возьмет)
  getVisualRenderedFontStyles: function(font_family, font_weight, font_style) {
    var res = { 'font-weight': font_weight };

    // италик всегда синтезируется, поэтому любой шрифт у которого font_style = italic италиком и отрендерится
    res['font-style'] = font_style;

    // с весом сложнее
    // получаем список начертаний доступных для шрифта
    var font = this.findFontByCSSName(font_family),
      minDist = 9999,
      dist;
    if (font) {
      res['font-family'] = font_family;

      _.each(font.variations, function(variation) {
        var variation_weight = (variation.substr(1, 1) - 0) * 100,
          // смотрим модуль расстояние веса доступного начертания шрифта и того который мы просим отрендерить
          dist = Math.abs(variation_weight - font_weight);
        // запоминаем ближайшее по весу начертание
        // когда есть несколько равных по расстоянию весов, бразер выберет наименьший вес (т.е. просим 200, а есть толко 100, 300, 400 браузер отрендерит 100)
        // поэтому <, а не <=  (у нас список доступных стилей в шрифте отсортирован по возрастанию)
        // кстати я не знаю как в таком случае расчитывается faux bold, щас я его просто тупо вынес дальше, наверное это не совсем верно, но такой конфликт - один на миллион, и ничего если и неправильно посчитается
        if (dist < minDist) {
          res['font-weight'] = variation_weight;
          minDist = dist;
        }
      });

      // проверяем на синтезированный болд
      // в доступных начертаниях шрифта его может и не быть,
      // но если он окажется ближе к весу который мы хотим отрендерить, то браузер будет его синтезировать
      dist = Math.abs(700 - font_weight);
      // запоминаем ближайшее по весу начертание
      if (dist < minDist) res['font-weight'] = 700;
    }

    return res;
  },

  findCommonFontsStylesAvailable: function(fonts_list) {
    var res = [];
    _.each(
      fonts_list,
      _.bind(function(font) {
        font = this.findFontByCSSName(font);
        if (font) {
          var styles = [];
          _.each(font.variations, function(variation) {
            var variation_weight = (variation.substr(1, 1) - 0) * 100,
              variation_style = variation.substr(0, 1) == 'n' ? 'normal' : 'italic';

            styles.push(variation_weight + '-' + variation_style);
          });
          res.push(styles);
        }
      }, this)
    );

    if (res.length > 0) res = _.intersection.apply(this, res);
    return res;
  },

  typekitFontsCount: function(fonts) {
    var typekitAll = _.where(fonts, { provider: 'typekit' }).length,
      typekitHidden = _.where(fonts, { provider: 'typekit', hidden: true }).length;

    return {
      visible: typekitAll - typekitHidden,
      hidden: typekitHidden,
    };
  },

  // mag_user и mag_num_id опциональные параметры, нужны когда действие выполняет коллаборатор
  changeFontHidden: function(css_name, hiddenState, mag_user, mag_num_id) {
    var new_fonts = _.cloneWithObjects(this.get('fonts'));

    var font =
      _.find(new_fonts, function(font) {
        return font.css_name.toLowerCase() == css_name.toLowerCase();
      }) || {};

    // в полученном объекте менем поле hidden
    font.hidden = hiddenState;

    // Принято решение прятать/восстанавливать кастомный шрифт
    // сквозно для всех проектов юзера. По-этому признак hidden
    // сохраняется так же в сам шрифт, а при загрузке в конструктор
    // этот признак в mag_edit_params на бэке оверрайдится значением из шрифта
    // https://trello.com/c/0FExyVs4/207-custom-fonts
    if (font._id && font.is_personal) {
      $.ajax({
        url: '/api/customfonts',
        method: 'PATCH',
        dataType: 'json',
        contentTypeType: 'application/json',
        data: {
          _id: font._id,
          hidden: font.hidden,
          mag_user: mag_user,
          mag_num_id: mag_num_id,
        },
      });
    }

    if (this.typekitFontsCount(new_fonts).visible > this.MAX_VISIBLE_TYPEKIT_FONTS) {
      return { error: 'typekit-full-house' };
    } else {
      // Везде save обязателен для коллаборации
      this.save('fonts', new_fonts);
      return {};
    }
  },

  getUsedFontsCache: function() {
    var res = {};

    _.each(this.get('fonts'), function(font) {
      res[font.css_name] = { temporary: font.temporary, hidden: font.hidden };
    });

    return res;
  },

  addFonts: function(fonts, initiator) {
    var new_fonts = _.cloneWithObjects(this.get('fonts')),
      font_exists,
      typo;

    _.each(
      fonts,
      _.bind(function(font) {
        if (_.contains(['template', 'paste'], initiator)) {
          // удаляем информацию о фактически использованных начертаниях, если она есть
          // она может быть в случае, если addFonts вызывается при создании страницы из шаблона
          // просто там используется универсальный механизм поиска использованных шрифтов и начертаний
          // и некоторые данные которые он возвращает нам не нужны в фонт селекторе
          delete font.used_variations;
        }

        font_exists = _.find(new_fonts, function(item) {
          return item.css_name.toLowerCase() == font.css_name.toLowerCase();
        });

        // при добавлении шрифтов из фонт эксплорера или загрузки кастомных шрифтов:
        // шрифт которого нет в панельке (ни активного, ни удаленного) - добавлем активным
        // шрифт которого есть в панельке но он удален - снимаем удаление (делаем активным)
        if (_.contains(['font-explorer', 'manual'], initiator)) {
          if (!font_exists) {
            new_fonts.push(font);
          } else {
            font_exists.hidden = false;
          }
        }

        // Из загрузчика шрифтов: либо добавляем, либо обновляем шрифт
        if (initiator == 'font-upload') {
          if (!font_exists) {
            new_fonts.push(font);
          } else {
            _.extend(font_exists, fonts[0]);
          }
        }

        // при добавлении шрифтов при создании страницы из шаблона:
        // шрифт которого нет в панельке (ни активного, ни удаленного) - добавлем удаленным
        // шрифт которого есть в панельке (активный или удаленный) - ничего не делаем
        if (_.contains(['template', 'paste'], initiator)) {
          if (!font_exists) {
            font.hidden = true;
            new_fonts.push(font);
          }
        }
      }, this)
    );

    if (initiator == 'font-explorer' && this.typekitFontsCount(new_fonts).visible > this.MAX_VISIBLE_TYPEKIT_FONTS) {
      return { error: 'typekit-full-house' };
    }

    // безопасно вызываем длиную цепочку для поиска контрола типографики
    (typo = RM.constructorRouter) &&
      (typo = typo.workspace) &&
      (typo = typo.controls) &&
      (typo = typo.findControl('text_typography')) &&
      typo.plusAnimation();

    // если шрифты были добавлены из темплейта (когда из него делали страницу)
    // тогда надо принудительно сделать save
    // иначе будут косяки если после добавления шрифтов из темплейта ни разу не открыть фонт-селектор
    // то новые добавленные шрифты не сохранятся, поскольку сохранения шрифтов происходит при закрытии фонт селектора

    // Везде save обязателен для коллаборации

    this.save({ fonts: new_fonts }, { silent: _.contains(['template', 'paste', 'manual'], initiator) });

    this.appendFontsCssToDocument({ version: 'edit' }, function() {
      RM.constructorRouter.trigger('font-added');
    });

    return {};
  },

  removeFonts: function(css_names) {
    var new_fonts = _.cloneWithObjects(this.get('fonts'));

    _.each(new_fonts, function(font) {
      _.each(css_names, function(css_name) {
        if (font.css_name.toLowerCase() == css_name.toLowerCase()) font.hidden = true;
      });
    });

    // Везде save обязателен для коллаборации
    this.save('fonts', new_fonts);
  },

  clearTempFonts: function() {
    var temp_fonts = _.findWhere(this.get('fonts'), { temporary: true });

    if (temp_fonts) {
      var new_fonts = _.cloneWithObjects(this.get('fonts'));

      new_fonts = _.filter(new_fonts, function(font) {
        var res = !(font.temporary && font.hidden);
        delete font.temporary;
        return res;
      });

      // Везде save обязателен для коллаборации
      this.save('fonts', new_fonts);
    }
  },
};

export default FontsModel;
