/**
 * Контрол псевдокропа для растянутых картинок
 */
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/picture_fwpos.tpl';

var _super = ControlClass.prototype;

const PictureFWPos = ControlClass.extend({
  name: 'picture_fwpos',

  className: 'control picture_fwpos',

  initialize: function(params) {
    this.template = templates['template-constructor-control-picture_fwpos'];

    this.initControl(params);

    this.block = this.blocks[0];

    if (!this.block.model.get('picture')) {
      this.$el.hide();
    }
  },

  onClick: function() {
    if (!this.block.$picture) return;

    if (this.$el.hasClass('inactive')) {
      return;
    }
    _super.onClick.apply(this, arguments);
  },

  select: function() {
    this.block.enterFWPosMode();

    this.$el.addClass('active');

    _super.select.apply(this, arguments);
  },

  deselect: function() {
    this.block.leaveFWPosMode();

    this.$el.removeClass('active');

    _super.deselect.apply(this, arguments);
  },

  onPictureLoaded: function() {
    this.$el.show();
  },

  bindLogic: function() {
    this.block.on('picture_added', this.onPictureLoaded);
    this.block.on('fwpos_click', this.onClick);
  },

  unBindLogic: function() {
    this.block.off('crop_click', this.onClick);
    this.block.off('picture_added', this.onPictureLoaded);
  },

  // Показываем контрол, только если у блока есть картинка
  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();

    return (
      blocks.length == 1 &&
      !(blocks[0].isPictureEmpty && blocks[0].isPictureEmpty()) &&
      (blocks[0].isFullWidth() || blocks[0].isFullHeight())
    );
  },

  onEnterKey: function() {
    if (this.selected) {
      this.master.deselect();
    }
  },
});

export default PictureFWPos;
