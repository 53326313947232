/**
 * Конструктор для контрола twitter
 */
import VueControlClass from '../vue-control';

const FacebookSettings = VueControlClass.extend({
  name: 'facebook_settings',
  alt: 'Settings',
  panelComponent: 'panel-facebook-settings',
});

export default FacebookSettings;
