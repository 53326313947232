<template>
  <div :class="{ 'font-family-container': true, 'container-disabled': disabled }">
    <span class="example" :style="exStyle" @click="toggleVisible">{{ family || 'Font' }}</span>
    <div class="expand-arrow" @click="toggleVisible"></div>
    <div class="box_container" :class="{ visible: visibleBox }" :style="boxStyles"></div>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import FontSelector from '../../js/constructor/helpers/font-selector';

export default {
  props: {
    family: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vAlign: {
      type: String,
      default: 'auto',
    },
    hAlign: {
      type: String,
      default: 'right',
    },
    hOffset: {
      type: Number,
      default: 0,
    },
  },

  model: {
    event: 'change',
  },

  data: function() {
    return {
      visibleBox: false,
      boxStyles: {},
      exStyle: {
        'font-family':
          this.family ||
          "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu, 'Fira Sans', Roboto, 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      },
    };
  },

  watch: {
    family(val) {
      if (!val && this.visibleBox) {
        this.visibleBox = !this.visibleBox;
      }
    },
  },

  mounted: function() {
    if (this.vAlign === 'auto') {
      var rect = this.$el.getBoundingClientRect(),
        prop = 'top';
      if (rect.top > window.innerHeight / 2) {
        prop = 'bottom';
      }
      this.boxStyles[prop] = rect.height + 'px';
    }
    this.boxStyles[this.hAlign] = this.hOffset + 'px';

    this.fontselector = new FontSelector({ $parent: $(this.$el.querySelector('.box_container')) });
    this.fontselector.on('selectfont', this.fontChanged);
    this.fontselector.show();
  },
  methods: {
    fontChanged: function(font) {
      this.exStyle['font-family'] = font;
      this.$emit('change', font);
    },

    toggleVisible: function() {
      if (this.disabled) {
        return;
      }
      if (!this.visibleBox) {
        this.$emit('showPopup', this);
      }
      this.visibleBox = !this.visibleBox;
    },
  },
};
</script>

<style lang="less">
@import (reference) '../../css/common/constants.less';
.font-family-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.container-disabled {
    color: #808080;
    .example,
    .expand-arrow {
      cursor: default;
    }
  }

  .example {
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    flex: 0 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;

    &:after {
    }
  }

  .box_container {
    -webkit-transform: translate3d(0, 0, 0);
    position: absolute;
    z-index: 1;
    display: none;
    width: 216px;
    height: 408px;
    &.visible {
      display: block;
    }
  }

  .expand-arrow {
    flex: 0 0 17px;
    width: 7px;
    height: 5px;
    background-repeat: no-repeat;
    background-size: 7px 5px;
    background-image: url('../../img/constructor/controls/button_settings/arrow.png');
    .transition();
    cursor: pointer;
  }
  &:hover {
    .expand-arrow {
      background-image: url('../../img/constructor/controls/button_settings/arrow-active.png');
    }
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  .font-family-container {
    .expand-arrow {
      background-size: 7px 5px;
      background-image: url('../../img/constructor/controls/button_settings/arrow@2x.png');
    }
    &:hover {
      .expand-arrow {
        background-size: 7px 5px;
        background-image: url('../../img/constructor/controls/button_settings/arrow-active@2x.png');
      }
    }
  }
}
</style>
