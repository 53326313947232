/**
 * Конструктор для виджета Shape
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockClass from '../block';
import ShapeSVG from '../../common/shape-svg';
import Viewports from '../../common/viewports';
import SVGUtils from '../../common/svgutils';

const ShapeBlock = BlockClass.extend(ShapeSVG.prototype).extend(
  {
    name: 'Shape',
    sort_index: 6, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'shape',

    icon_color: '#F2C95C',

    dontSaveOnResize: true,

    viewport_fields: Viewports.viewport_fields.shape,

    LINE_BLOCK_HEIGH: 24, // Высота блока для типа "линия"

    initial_controls: [
      'shape_settings',
      'shape_color',
      'shape_1x1',
      'common_animation',
      'picture_link',
      'common_rotation',
      'common_position',
      'common_layer',
      'common_lock',
    ],

    initialize: function(model, workspace) {
      this.mag = workspace.mag;

      this.initBlock(model, workspace);

      this.renderSVGContent.__debounced = _.debounce(this.renderSVGContent, 0);

      this.iconData = null; // Чтобы Vue в панели слоев изначально заобзервил свойство
    },

    render: function() {
      this.create();

      this.$el.addClass('shape');

      this.setControls();

      this.$el.on('dblclick', this.onDoubleClick);

      // изначально отрисовываем виджет
      this.redraw(this.model, {
        skipTriggerRedraw: true,
        doNotRedrawPacksFrames: true,
        doNotRedrawBottomShiftLine: true,
      });

      this.model.on('change:tp', this.onTypeChange);
      this.model.on('change:is_full_width', this.onStrechBlockChange);
      this.model.on('change:is_full_height', this.onStrechBlockChange);
      this.onTypeChange();

      this.triggerReady();
    },

    onDoubleClick: function(e) {
      if (!$(e.target).closest('.content').length) {
        return;
      } // Чтобы панели не открывались по даблклику по плашке размеров

      this.showControl('shape_settings');
    },

    onStrechBlockChange: function() {
      this.setControls();
      this.updateControls();
    },

    showControl: function(control_name) {
      var workspaceControls = this.workspace && this.workspace.controls,
        control;

      if (!workspaceControls) return;

      control = workspaceControls.findControl(control_name);
      if (!control) return;

      !control.selected && control.onClick();
    },

    setControls: function() {
      var tp = this.model.get('tp');
      if (tp == 'line' || tp == 'icon') {
        this.controls = _.without(this.initial_controls, 'shape_1x1');
      } else {
        this.controls = this.initial_controls;
      }
    },

    onTypeChange: function(model, val, options) {
      var tp = this.model.get('tp'),
        onlyTypeChanged = model && _.without(_.keys(model.changedAttributes()), 'tp').length == 0;

      this.proportional = tp == 'icon';

      if (tp == 'icon') {
        if (this.isRealChangeByMe(options)) {
          // Открываем панель поиска иконок. С задержкой, чтобы контрол успел создаться и показаться
          _.delay(
            function() {
              this.showControl('shape_icon');
            }.bind(this),
            300
          );
        }
        this.resetFullWidth();
        this.resetFullHeight();
      }

      if (tp == 'line' && onlyTypeChanged) {
        this.fixLineHeight();
      }

      // при любом изменении типа шейпа нам надо определить показывать контрол 1:1 или нет (только для линии не показываем)
      this.setControls();

      // код расположен в block.js
      this.updateControls();

      // Ограничим размеры рамки для иконок, потому что только они растеризуются на сервере через api /rasterize
      _.extend(
        this.frame,
        this.model.get('tp') === 'icon'
          ? { maxwidth: 9999, maxheight: 9999 }
          : { maxwidth: Infinity, maxheight: Infinity }
      );
    },

    redraw: function(model, options) {
      options = options || {};
      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      BlockClass.prototype.redraw.apply(this, arguments);

      var changed_attrs = this.model.changedAttributes(),
        only_moved = false,
        has_changed_type,
        has_changed_icon,
        need_rasterize_icon,
        only_raster_urls_changed,
        saveData;

      if (_.isObject(changed_attrs)) {
        only_moved = _.without(_.keys(changed_attrs), 'x', 'y', 'z', 'sticked_margin', 'sticked').length == 0;
        only_raster_urls_changed = _.without(_.keys(changed_attrs), 'rasterUrl', 'raster2xUrl').length == 0;
        has_changed_type = !!changed_attrs.tp;
        has_changed_icon = !!(changed_attrs.noun_id || changed_attrs.rm_id);
      }

      if (only_raster_urls_changed) {
        return;
      }

      // Обновляем содержимое только если не перемещали или не меняли зиндекс
      if (!only_moved || this.model.isGlobal()) {
        // Для иконок все сложней: нужно получить SVG от noun и сгенерить растеризованную картинку
        if (model.get('tp') == 'icon') {
          need_rasterize_icon = !!_.intersection(_.keys(changed_attrs), ['w', 'h', 'noun_id']).length;

          // Запоминаем вьюпорт до вызова асинхронной функции, чтобы сохранить результаты именно в этот вьюпорт
          // skipHistory — только при групповогом ресайзе.
          // Выставлять opts.skipHistory в зависимости от options.skipHistory — нельзя, потому что например,
          // при индивидуальном ресайззе redraw вызывается из fixIconSVGSize с этим флагом, но передавать его в model.save — не нужно
          var saveOptions = {
            viewport: this.model.getViewport(),
            skipHistory: options.isGroupResize,
          };
          // Если изменения из-за анду/реду, то урлы на старые растеризованные картинки уже вернулись. Картинки не удаляются из S3
          var willRasterize = need_rasterize_icon && !(options.undo || options.redo);
          var fixSize = has_changed_type || has_changed_icon;
          var redrawIconBound = this.redrawIcon.bind(this, model, saveOptions, fixSize, willRasterize);

          // При групповом ресайзе придётся таймаутить, чтобы rasterize вызвался после общего сейва моделей
          if (options.isGroupResize) {
            _.delay(redrawIconBound);
          } else {
            redrawIconBound();
          }
        } else {
          this.redrawShape(model.get('w'), model.get('h'));
          if (has_changed_type) {
            if (model.get('tp') == 'ellipse') {
              this.makeSquare();
            }
          }
        }
      }
    },

    redrawIcon: function(model, options, fixSize, willRasterize) {
      this.getIconSVG(
        model.get('noun_id') || model.get('rm_id'),
        model.get('noun_url'),
        function(err, data) {
          if (err || !data.$svg) {
            return;
          }

          if (this.destroyed) {
            return;
          }

          // Фиксим размеры блока только если была смена типа шейпа или смена иконки
          // Каждый раз нельзя, т.к. ресайз вызывает запрос на растеризацию
          this.updateIconSVGData(data, fixSize);

          if (willRasterize) {
            this.rasterizeSVG();
          }
        }.bind(this)
      );
    },

    getIconStyle: function(options) {
      var res = {};

      var path = RM_PUBLIC_PATH + 'img/constructor/widgetbar/icons/';

      if (options && options.small) {
        path += 'small/';

        if (this.icon_color) {
          res['background-color'] = this.icon_color;
        }
      }

      if (this.model.get('tp') == 'icon') {
        res['background-color'] = this.icon_color;

        if (!options || !options.small) {
          res['border-radius'] = '8px';
        }
      } else {
        var image = path + 'shape-' + this.model.get('tp') + '.svg';

        res['background-image'] = 'url(' + image + ')';
      }

      return res;
    },

    getIconData: function() {
      if (this.model.get('tp') == 'icon') {
        return this.iconData;
      }

      return null;
    },

    updateIconSVGData: function(data, fixSize) {
      this.iconData = data;
      this.redrawShape(this.model.get('w'), this.model.get('h'));

      fixSize = fixSize || (this.model.get('tp') == 'icon' && this.model.get('w') != this.model.get('h'));

      if (fixSize) {
        this.fixIconSVGSize();
      }
    },

    css: function(params) {
      BlockClass.prototype.css.apply(this, arguments);

      if (params.height != undefined && params.width != undefined) this.redrawShape(params.width, params.height);
    },

    redrawShape: function(w, h) {
      // вызываем метод генерации svg
      // он описан в /common/shape-svg.js, потому что он общий и для конструктора и для вьювера
      var svg = this.generateShapeSVG('constructor', this.model, w, h);

      this.renderSVGContent(svg);

      // FF некорректно применяет маски для шейпа на невидимой странице
      // https://trello.com/c/GjsRi2m9/152-ff
      if (Modernizr.firefox) {
        this.renderSVGContent.__debounced(svg);
      }
    },

    renderSVGContent: function(content) {
      this.$content.html(content);
    },

    // Изменяет размер блока под размер SVG иконки, вписывая ее в текущий размер блока
    // При этом размер большей из сторон остается неизменным
    fixIconSVGSize: function(options) {
      var w = this.model.get('w'),
        h = this.model.get('h'),
        $svg,
        aspectRatio,
        box = this.getBoxData(),
        bbox,
        shift_x,
        shift_y,
        old_w,
        old_h,
        min = Math.min(box.w, box.h);

      $svg = this.$content.find('svg');

      if (!$svg.length) {
        return;
      }

      bbox = $svg.get(0).getBBox();
      aspectRatio = Math.round(bbox.width) / Math.round(bbox.height);

      old_w = box.w;
      old_h = box.h;

      if (bbox.width < bbox.height) {
        box.w = min;
        box.h = Math.ceil(box.w / aspectRatio);
      } else {
        box.h = min;
        box.w = Math.ceil(box.h * aspectRatio);
      }

      shift_x = Math.floor((old_w - box.w) / 2);
      shift_y = Math.floor((old_h - box.h) / 2);
      box.x = box.x + shift_x;
      box.y = box.y + shift_y;

      this.model.set(box, _.extend({ skipHistory: true }, options));
    },

    rasterizeSVG: function() {
      var $svg = this.$content.find('svg');

      if (!$svg.length) return;

      this.rasterizeXhr && this.rasterizeXhr.abort();
      this.rasterizeXhr = SVGUtils.rasterize({
        widgets: [this.model],
        svg: $svg,
        mid: this.workspace.mag.get('_id'),
        viewport: this.model.getViewport(),
        size: {
          width: this.model.get('w'),
          height: this.model.get('h'),
        },
      });
    },

    saveNewColor: function() {
      this.rasterizeSVG();
    },

    onResizeStart: function(event, drag) {
      this.rasterizeXhr && this.rasterizeXhr.abort();
    },

    // Переопределяем базовую функцию, чтобы обеспечить изменение модели, но при этом не сохранять ее, если тип - иконка
    onResizeEnd: function(event, drag, options) {
      if (this.model.get('tp') !== 'icon') {
        return this.saveBox(options);
      } else {
        this.fixIconSVGSize(options);
      }
    },

    saveBox: function(options) {
      BlockClass.prototype.saveBox.apply(this, arguments);
    },

    // SVG утки. Нужен для моментального отрбражения шейпа типа иконка, если еще не выбрана реальная иконка
    getDefaultIconSVG: function() {
      return this.prepareIconSVG(
        $(
          '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="283.5px" height="283.5px" viewBox="0 0 283.5 283.5" enable-background="new 0 0 283.5 283.5" xml:space="preserve"><g><g><path fill-rule="evenodd" clip-rule="evenodd" fill="#231F20" d="M258.3,114.1c-14.1,0-23.1,25-30.1,33.8 c-7,8.8-33.3,2.7-44.1,0.7c-10.7-2.1-38.7-3.9-38.7-3.9s47.4-18,47.4-65c0-47.7-54.9-75.3-73.5-78.1c-17-2.8-23.8,5.6-25.4,7.8 c-1.6,2.2-4.2,4.1-8,5.9c-3.9,1.8-1.5-0.1-15.4,9.8c-13.9,9.8-41.8-4-44.1-4.6c-2.3-0.5-6.8-1.8-8,4.6c-1.5,9.6,6.7,25.4,6.7,25.4 s-2.2,0.2-8,3.3c-5.8,3.1-2.8,7.7-1.3,9.1c1.4,1.4,27.9,16.3,32.1,18.9c10.6,5.3,20.3,31.6-7.3,51.4C-3.1,163,0.4,198.7,0.4,198.7 s-12,85.2,126.9,85.2c139,0,155.6-96.1,155.6-124.2C283,131.5,272.4,114.1,258.3,114.1z M89.5,50.2c-7.1,6.5-15.7,8-19.3,3.5 c-3.6-4.6-0.7-13.5,6.3-20c7.1-6.5,15.7-8,19.3-3.5C99.4,34.7,96.6,43.7,89.5,50.2z M76.1,38.9c-4.7,4.3-6.6,10.3-4.2,13.3 c2.4,3,8.1,2,12.9-2.3c4.7-4.3,6.6-10.3,4.2-13.3C86.6,33.6,80.9,34.6,76.1,38.9z"/></g></g></svg>'
        )
      );
    },

    getIconSVG: function(id, icon_url, options, callback) {
      var cacheItem, req_data;

      // options argument is optional
      if (arguments.length < 4) {
        callback = options;
        options = {};
      }

      this.iconXhr && this.iconXhr.abort();

      if (!id) {
        return callback(null, { $svg: this.getDefaultIconSVG(), noun_url: null });
      }

      if (!options.noCache) {
        cacheItem = this.getSVGCacheItem(id);

        if (cacheItem) {
          var item = this.processNounIcon(cacheItem);
          return callback(null, item);
        }
      }

      this.iconRequestInProgress = true;

      var onSuccess = function(data) {
        if (!data.noCache) this.cacheSVGData({ id: id, svg: data.svg, noun_url: data.new_url });

        var processedIconData = this.processNounIcon(data);
        return callback(null, processedIconData);
      }.bind(this);

      req_data = {
        method: 'POST',
        url: '/api/authservice/noun/icon',
        data: {
          id: id,
          url: icon_url,
        },
        dataType: 'json',
        success: function(data) {
          onSuccess(data);
        },
        error: function(xhr) {
          if (!xhr.statusText == 'abort') {
            console.log('icon xhr : ', xhr);
          }
          return callback(xhr);
        },
        complete: function() {
          // this.iconRequestInProgress = false;
        },
        context: this,
      };

      // Если id иконки начинается с "rm", то это не науновская иконка, а наша
      // и грузить ее нужно по-другому
      if (/^rm/.test(id)) {
        _.extend(req_data, {
          method: 'GET',
          url: icon_url,
          cache: false, // Иначе хром берет прокешированную картинку и не подставляет нужные заголовки для кроссдоменного запроса. И сам же блокирует запрос.
          success: function(data) {
            onSuccess({ svg: $(data).find('svg'), new_url: icon_url, noCache: true });
          },
          data: null,
          dataType: null,
        });
      }

      this.iconXhr = $.ajax(req_data);
    },

    processNounIcon: function(data) {
      var $svg = $('<div></div>')
        .append($(data.svg))
        .find('svg'); // Данные могут быть самые разные, включая SVG на верхнем уровне

      if (!$svg.length) {
        return;
      }

      $svg = this.prepareIconSVG($svg);

      return {
        $svg: $svg,
        noun_url: data.new_url,
      };
    },

    prepareIconSVG: function($raw_svg) {
      var $tmpSVG = $raw_svg.clone().appendTo($('body')),
        $iconSVG = $raw_svg.clone();

      $tmpSVG.get(0).setAttributeNS(null, 'viewBox', '0 0 ' + $tmpSVG.width() + ' ' + $tmpSVG.height());

      var bbox = $tmpSVG.get(0).getBBox();
      $tmpSVG.remove();

      this.currenIconSVGAcpectRatio = bbox.width / bbox.height;

      $iconSVG.removeAttr('width');
      $iconSVG.removeAttr('height');

      // Именно так, потому что jQuery не может нормально назначить аттрибуты для SVG
      $iconSVG.get(0).setAttributeNS(null, 'viewBox', bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height);
      $iconSVG.get(0).setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');

      $iconSVG.css({
        width: '100%',
        height: '100%',
      });

      return $iconSVG;
    },

    cacheSVGData: function(data) {
      var cache = JSON.parse(window.localStorage.getItem('noun_icons')) || {};

      cache[data.id] = _.omit(data, 'id');

      try {
        window.localStorage.setItem('noun_icons', JSON.stringify(cache));
      } catch (e) {
        // Превысили размер квоты на localStorage или он отключен вовсе
        window.localStorage.removeItem('noun_icons');
      }
    },

    getSVGCacheItem: function(id) {
      var cache = JSON.parse(window.localStorage.getItem('noun_icons')) || {};

      var iconData = cache[id];

      if (iconData) return _.extend({ id: id }, iconData);
    },

    fixLineHeight: function() {
      var h = this.model.get('h'),
        y = this.model.get('y'),
        weight = this.model.get('weight'),
        new_height = weight > this.LINE_BLOCK_HEIGH ? weight : this.LINE_BLOCK_HEIGH,
        shift = Math.floor((h - new_height) / 2);

      this.model.set({
        h: new_height,
        y: y + shift,
      });
    },

    makeSquare: function() {
      var x = this.model.get('x'),
        w = this.model.get('w'),
        h = this.model.get('h'),
        shift = Math.floor((w - h) / 2);

      this.model.set({
        w: h,
        x: x + shift, // Оставляем центр виджета на месте
      });
    },

    destroy: function() {
      this.model.off('change:tp', this.onTypeChange);
      this.model.off('change:is_full_width', this.onStrechBlockChange);
      this.model.off('change:is_full_height', this.onStrechBlockChange);
      BlockClass.prototype.destroy.apply(this, arguments);
      this.stopListening(this);
      this.$el.off();
    },
  },
  {
    defaults: {
      tp: 'rectangle',
      bg_color: '000000',
      bg_opacity: 1,
      color: 'ffffff',
      opacity: 1,
      borders: 0,
      radius: 0,
      sides: 3,
      weight: 1, // толщина для шейпа с типом "линия"
      stroke: 'solid', // solid, double, dotted, dashed тип для шейпа с типом "линия"
    },
  }
);

export default ShapeBlock;
