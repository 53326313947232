/**
 * Конструктор для контрола цвета шейпа
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import Colorbox from '../helpers/colorbox';
import templates from '../../../templates/constructor/controls/shape_color.tpl';

const ShapeColor = ControlClass.extend({
  name: 'shape_color', // должно совпадать с классом вьюхи

  className: 'control shape_color',

  saveOnDeselect: true,
  saveOnDestroy: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-shape_color'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;

    _.bindAll(this);
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.colorbox = new Colorbox({ $parent: this.$panel, type: 'small' });

    this.colorbox.on('colorchange', this.colorChanged);
    this.colorbox.on('opacitychange', this.colorChanged);

    this.model.on('change:bg_color', this.onColorChange);
    this.model.on('change:bg_opacity', this.onColorChange);

    this.updateState();
  },

  unBindLogic: function() {
    this.colorbox && this.colorbox.off('colorchange', this.colorChanged);
    this.colorbox && this.colorbox.off('opacitychange', this.colorChanged);
    this.colorbox && this.colorbox.destroy();

    this.model.off('change:bg_color', this.onColorChange);
    this.model.off('change:bg_opacity', this.onColorChange);
  },

  updateIconState: function(color, opacity) {
    if (!color || opacity == undefined) return;
    if (!this.$icon) return;

    this.$icon.find('.color-circle').css({
      background: '#' + color,
      opacity: opacity,
    });
  },

  updateColorboxState: function(color, opacity) {
    if (!color || opacity == undefined) return;
    if (!this.colorbox) return;

    this.colorbox.setOpacity(opacity);
    this.colorbox.setColor(color);
  },

  colorChanged: function(r, g, b, a) {
    var color = this.colorbox && this.colorbox.rgb2hex([r, g, b]);

    this.updateIconState(color, a);

    this.model.set({ bg_color: color, bg_opacity: a });
  },

  updateState: function() {
    var color = this.model.get('bg_color'),
      opacity = this.model.get('bg_opacity');

    this.updateIconState(color, opacity);
    this.updateColorboxState(color, opacity);
  },

  onColorChange: function(model, val, options) {
    if (options.undo || options.redo) this.updateState();
  },

  save: function() {
    if (this.model.get('tp') !== 'icon') {
      this.model.save({});
    } else {
      this.block.saveNewColor();
    }
  },
});

export default ShapeColor;
