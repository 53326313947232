<template>
  <div class="panel">
    <div class="panel-title">Audio</div>

    <a v-if="!m.url" class="soundcloud-logo" href="https://soundcloud.com/" target="_blank"></a>

    <div class="search">
      <input
        v-model="search"
        class="search-input"
        :class="{ 'with-icon': (isUrl && !m.url) || m.url }"
        placeholder="URL of track or user"
        @keyup.enter="loadAudio"
      />
      <div v-if="isUrl && !m.url" class="sicon apply-btn" @click.stop="loadAudio"></div>
      <div v-if="m.url" class="sicon remove-btn" @click.stop="resetAudio"></div>
    </div>

    <a v-if="m.url" class="soundcloud-logo-small" href="https://soundcloud.com/" target="_blank"></a>

    <div v-if="!!errorMessage" class="error-message">{{ errorMessage }}</div>

    <div v-if="!!m.url" class="settings">
      <div class="description">{{ m.title }}</div>

      <div class="separator"></div>

      <rm-spinner
        v-model="m.current_type"
        :variants="[
          {
            value: 'visual',
            title: 'Visual Player',
          },
          {
            value: 'standard',
            title: 'Original Player',
          },
          {
            value: 'minimal',
            title: 'Minimal',
          },
        ]"
      ></rm-spinner>

      <div class="row">
        <div class="caption">Comments</div>
        <rm-switcher v-model="m.comments"></rm-switcher>
      </div>

      <div class="row">
        <div class="caption">Artwork</div>
        <rm-switcher v-model="m.artwork"></rm-switcher>
      </div>

      <div class="row">
        <div class="caption">Social</div>
        <rm-switcher v-model="m.socials"></rm-switcher>
      </div>

      <div class="row">
        <div class="caption">Color</div>
        <color-picker
          :color="m.color"
          :show-alpha="false"
          @showPopup="closeOthersPopups"
          @update:color="col => (m.color = col)"
        ></color-picker>
      </div>
    </div>
  </div>
</template>

<script>
import _ from '@rm/underscore';
import { Constants } from '../../../js/common/utils';
import Panel from './panel.vue';
import AudioLogic from './mixins/audio_logic.vue';
import RmSwitcher from '../../common/rm-switcher.vue';
import RmSpinner from '../../common/rm-spinner.vue';
import ColorPicker from '../../common/color-picker.vue';

export default {
  mixins: [Panel, AudioLogic],
  components: { RmSwitcher, RmSpinner, ColorPicker },
  mounted: function() {
    this.$on('audioWrongUrl', this.onAudioWrongUrl);
    this.$on('audioEmbedError', this.onAudioEmbedError);
    this.$on('audioEmbedLoaded', this.onAudioEmbedLoaded);
    this.$on('audioDataObtained', this.onAudioDataObtained);

    this.search = this.m.url;
  },
  computed: {
    isUrl: function() {
      return this.isURL(this.search);
    },

    currInd: function() {
      let ind = this.playerVar.indexOf(this.m.current_type);
      return ind < 0 ? 0 : ind;
    },
  },

  data: function() {
    return {
      state: 'default',
      errorMessage: '',
      apiKey: Constants.SOUNDCLOUD_API,
      search: this.url,
      playerVar: ['visual', 'standard', 'minimal'],
      playerTitle: ['Visual Player', 'Original Player', 'Minimal'],
    };
  },
  methods: {
    spinType: function(dir) {
      this.m.current_type = this.playerVar[(this.currInd + dir) % 3];
    },

    loadAudio: function() {
      this.getAudioFromUrl(this.search);
    },

    resetAudio: function() {
      var data = {
        url: '',
        parsed_url: '',
        thumbnail_url: '',
        title: '',
        embed_height: undefined,
        resized: false,
      };

      this.search = '';

      this.$parent.$emit('change', data);
      this.errorMessage = '';
    },

    onAudioWrongUrl: function() {
      console.log('onAudioWrongUrl', arguments);
    },

    onAudioEmbedError: function() {
      console.log('onAudioEmbedError', arguments);
      this.errorMessage = "Can't add track due to it's privacy settings. Try change the sharing settings of your track";
    },

    onAudioEmbedLoaded: function(data) {
      if (!data.thumbnail_url) {
        this.onAudioEmbedError();
        return;
      }

      data = _.pick(data, 'url', 'thumbnail_url', 'title', 'parsed_url', 'height');

      // сохраняем высоту ифрейма которую нам рекомендует soundcloud для данного конкретного трека/юзера/сета/группы...
      data.embed_height = data.height;
      data.height = undefined;
      data.resized = false; // флаг показывает что нужно расширять плеер по высоте если в нем находится список треков

      this.$parent.$emit('change', data);
      this.errorMessage = '';
    },

    onAudioDataObtained: function() {
      console.log('onAudioDataObtained', arguments);
      this.errorMessage = '';
    },
  },
};
</script>
