import { Utils } from './utils';

var AB_TRACK_COOKIE_NAME = '_rm_ab_group';

const extendAnalyticsCommon = {
  // Аналитика Facebook Pixel
  sendFacebookPixelEvent: Utils.sendFacebookPixelEvent.bind(this),

  getABTestingValue: () => {
    const valueMap = {
      a: 'OLD',
      b: 'NEW',
    };

    const abCookie = Utils.getCookie(AB_TRACK_COOKIE_NAME);
    return valueMap[abCookie];
  },
};

export default extendAnalyticsCommon;
