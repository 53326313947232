/**
 * История всех событий
 */
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from '../../common/utils';
import Viewports from '../../common/viewports';

const HistoryModel = _.extend({}, Backbone.Events, {
  initialize: function(syncManager) {
    this.undoStacks = {};
    this.redoStacks = {};
    this.pendingTransactions = {};

    this.syncManager = syncManager;

    return this;
  },

  // actions: [ {model: RM.Model, attrs: {<model.attributes>} ]
  add: function(actions, options) {
    options = options || {};
    _.defaults(options, {
      redo: false,
      undo: false,
    });

    // Если изменения затрагивают несколько страниц - делим по страницам и добавим в undo конкретных страниц
    if (!options.page) {
      var pagesGroup = _.groupBy(actions, function(action) {
        return action.model.getPageId();
      });
      if (_.keys(pagesGroup) > 1) {
        _.each(pagesGroup, function(actionsArray, pageId) {
          this.add(actionsArray, options);
        });

        return;
      }
    }

    // Если нужно объединить шаги, то просто ничего не делаем (возможно стоит добавлять отсутствующие в предыдущем шаге модели к шагу истории )
    if (options.replace) {
      return;
    }

    var pageId = options.page || actions[0].model.getPageId();
    if (!pageId) return console.error('cannot save history without pageId', actions);

    this.redoStacks[pageId] = this.redoStacks[pageId] || [];
    this.undoStacks[pageId] = this.undoStacks[pageId] || [];

    if (options.undo) {
      this.redoStacks[pageId].push(actions);
    } else {
      this.undoStacks[pageId].push(actions);

      if (!options.redo) {
        this.redoStacks[pageId] = [];
      }
    }

    this.triggerChange(pageId);
  },

  transactionStart: function(modelsData, options) {
    options = options || {};
    modelsData = _.isArray(modelsData) ? modelsData : [modelsData];
    var transactionId = Utils.generateUUID();
    this.pendingTransactions[transactionId] = {
      entry:
        modelsData && modelsData.length
          ? this.syncManager.getHistoryDict(this.syncManager.getChangeset(modelsData))
          : [],
      options: options,
    };
    return transactionId;
  },

  addToTransaction: function(transactionId, modelsData) {
    modelsData = _.isArray(modelsData) ? modelsData : [modelsData];
    var pendingTransaction = this.pendingTransactions[transactionId] || {};
    if (modelsData && modelsData.length) {
      var entry = this.syncManager.getHistoryDict(this.syncManager.getChangeset(modelsData));
      this.pendingTransactions[transactionId].entry = [].concat(pendingTransaction.entry || [], entry);
    }
  },

  transactionEnd: function(transactionId) {
    var pendingTransaction = this.pendingTransactions[transactionId];
    if (pendingTransaction) {
      this.syncManager.addHistoryStep(pendingTransaction.entry, pendingTransaction.options);
      delete this.pendingTransactions[transactionId];
    }
  },

  undo: function(pageId) {
    if (!pageId) return console.error('cannot undo without pageId parameter');

    if (_.isEmpty(this.undoStacks[pageId])) {
      return this.triggerChange(pageId);
    }

    var actions = this.undoStacks[pageId].pop();
    var opts = { undo: true, page: pageId };

    this.fillUndoKeys(actions);
    this.syncManager.saveGroup(_.pluck(actions, 'attrs'), opts);

    this.triggerUndo(pageId);
  },

  redo: function(pageId) {
    if (!pageId) return console.error('cannot redo without pageId parameter');

    if (_.isEmpty(this.redoStacks[pageId])) {
      return this.triggerChange(pageId);
    }

    var actions = this.redoStacks[pageId].pop();
    var opts = { redo: true, page: pageId };

    this.fillUndoKeys(actions);
    this.syncManager.saveGroup(_.pluck(actions, 'attrs'), opts);

    this.triggerRedo(pageId);
  },

  fillUndoKeys: function(actions) {
    _.each(actions, function(action) {
      // Обрабатываем ключи, которые должны игнорироваться при работе истории
      var json = action.model.toJSON();
      _.each(action.model.nonUndoKeys, function(key) {
        action.attrs[key] = json[key];

        // Для виджетов нужно проставить nonUndoKey для вьюпортов
        if (action.model.name == 'Widget') {
          var viewportKeys = Viewports.widgetViewportFields(action.model.get('type'));
          _.each(Viewports.viewport_list, function(viewport) {
            if (!json[viewport] || viewportKeys.indexOf(key) == -1) return;

            action.attrs[viewport] = action.attrs[viewport] || {};
            action.attrs[viewport][key] = json[viewport][key];
          });
        }
      });

      // Обрабатываем модели, у которых лишь часть ключей работает с историей (Page / Mag)
      if (!_.isEmpty(action.model.undoKeys)) {
        var json = action.model.toJSON();
        json = _.extend(json, action.attrs);
        action.attrs = json;
      }
    });
  },

  // Очищает историю
  clearHistory: function(pageId) {
    this.redoStacks[pageId] = [];
    this.undoStacks[pageId] = [];
    this.triggerChange(pageId);
  },

  // Посылает событие о том, что лина очереди изменилась
  triggerChange: function(pageId) {
    this.trigger('change', this.getLength(pageId));
  },

  // Посылает событие о том, что Undo
  triggerUndo: function(pageId) {
    this.trigger('undo', pageId);
  },

  // Посылает событие о том, что Redo
  triggerRedo: function(pageId) {
    this.trigger('redo', pageId);
  },

  getLength: function(pageId) {
    return {
      undo: (this.undoStacks[pageId] || []).length,
      redo: (this.redoStacks[pageId] || []).length,
      page: pageId,
    };
  },

  // 3 функции подменяют undo/redo, каждая из них получает на вход pageId
  // getLength - используется для триггера длины undo/redo, чтобы виджетбар мог показать стрелки
  // undo и redo должны сами обрабатывать случай, когда нечего отменять
  // Действует только внутри одной страницы, отключать при переходе нужно самостоятельно
  setCustomHandlers: function(handlers) {
    if (this.saved) return;

    var pageId = RM.constructorRouter.workspace.page.id;
    this.saved = { undo: this.undo, redo: this.redo, getLength: this.getLength };

    this.undo = handlers.undo;
    this.redo = handlers.redo;
    this.getLength = handlers.getLength;

    this.triggerChange(pageId);

    // возвращаем функции с помощью которых надо сообщать об изменениях
    return {
      triggerChange: _.bind(this.triggerChange, this),
      triggerUndo: _.bind(this.triggerUndo, this),
      triggerRedo: _.bind(this.triggerRedo, this),
    };
  },

  removeCustomHandlers: function() {
    if (!this.saved) return;

    _.extend(this, this.saved);
    this.saved = undefined;

    var pageId = RM.constructorRouter.workspace.page.id;
    this.triggerChange(pageId);
  },

  setEmptyHandlers: function() {
    this.setCustomHandlers({
      undo: function() {},
      redo: function() {},
      getLength: function(pageId) {
        return { undo: 0, redo: 0, page: pageId };
      },
    });
  },
});

export default HistoryModel;
