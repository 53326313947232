/**
 * Контрол настроек виджета Форм
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import Colorbox from '../helpers/colorbox';
import FontSelector from '../helpers/font-selector';
import { Utils } from '../../common/utils';
import templates from '../../../templates/constructor/controls/form_styles.tpl';

const FormStyles = ControlClass.extend({
  name: 'form_styles', // должно совпадать с классом вьюхи.

  className: 'control form_styles',

  // запустит this.save() при this.deselect() Контрола.
  saveOnDeselect: true,

  // запустит this.save() при дестрое Контрола.
  // важно, т.к. дестрой Контрола происходит при переходе в Превью.
  // и, если панель была открыта, сделаны изменения, то это позволит их сохранить.
  saveOnDestroy: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-form_styles'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;

    this.state = 'fields';
    this.buttonState = 'default';
  },

  bindLogic: function() {
    var self = this;

    // кэшируем поиск часто используемых элементов.
    this.$colorbox_container = this.$('.colorbox_container');

    // при клике в контейнер панельки,
    // скрываем все выпадалки (Колорбокс, Фонт селектор.)
    this.$panel.on('click', '.edit-wrapper', this.closeAllEditPopups);

    this.$panel.on('click', '.edit-wrapper .layout-wrapper .layout-switcher', this.onLayoutSwitchClick);
    this.$panel.on('click', '.edit-wrapper .style-wrapper .prev-style', this.onStyleSwitchClick);
    this.$panel.on('click', '.edit-wrapper .style-wrapper .next-style', this.onStyleSwitchClick);
    this.$panel.on('click', '.edit-wrapper .type-wrapper .type-switcher', this.onTypeSwitchClick);
    this.$panel.on('click', '.edit-wrapper .button-switcher-wrapper .button-switcher', this.onButtonSwitchClick);

    // инициализация, рендеринг Колорбокса для панели Контрола настроек.
    // навешивание слушателей на него.
    // будет использоваться для изменения параметров Кнопки, связанных с выбором цвета.
    this.$panel.on('click', '.edit-wrapper .color-click-area', this.onColorboxShowClick);
    this.colorbox = new Colorbox({ $parent: this.$colorbox_container, type: 'small' });
    this.colorbox.on('colorchange', this.colorOpacityChanged);
    this.colorbox.on('opacitychange', this.colorOpacityChanged);

    // инициализация, рендеринг Фонт селектора для панели Контрола настроек.
    // навешивание слушателей на него.
    // будет использоваться для изменения параметров Кнопки, связанных с выбором шрифта.
    this.fontselector = new FontSelector({ $parent: this.$('.fontselector_container') });
    this.fontselector.on('selectfont', this.fontChanged);
    this.$panel.on('click', '.edit-wrapper .font-family-click-area', this.onFontSelectorShowClick);
    this.$panel.on('click', '.edit-wrapper .font-style-click-area', this.onFontStyleShowClick);

    this.$panel.on('click', '.edit-wrapper .align', this.onAlignChangeClick);

    this.model.on('change', this.onChange);

    this.inputs = {
      fields: {},
      'button-default': {},
      'button-hover': {},
    };

    // ------------------------ инпуты для полей ------------------------------------------
    initInputEdit('fields', 'gutter', { min: -99, max: 999, mouseSpeed: 4, onChange: this.onEditInputChanged });
    initInputEdit('fields', 'border-radius', { min: 0, max: 999, mouseSpeed: 4, onChange: this.onEditInputChanged });
    initInputEdit('fields', 'border-width', { min: 0, max: 999, mouseSpeed: 4, onChange: this.onEditInputChanged });
    initInputEdit('fields', 'underline-width', { min: 0, max: 99, mouseSpeed: 4, onChange: this.onEditInputChanged });

    initInputEdit('fields', 'font-size', {
      min: this.block.MIN_FONT_SIZE,
      max: this.block.MAX_FONT_SIZE,
      mouseSpeed: 4,
      onChange: this.onEditInputChanged,
    });

    initInputEdit('fields', 'letter-spacing', {
      min: this.block.MIN_LETTER_SPACING,
      max: this.block.MAX_LETTER_SPACING,
      mouseDir: 'h',
      useFloat: true,
      step: 0.2,
      mouseSpeed: 4,
      onChange: this.onEditInputChanged,
    });

    // ------------------------ инпуты для кнопки в дефолте ------------------------------------------
    initInputEdit('button-default', 'gutter', { min: 0, max: 999, mouseSpeed: 4, onChange: this.onEditInputChanged });
    initInputEdit('button-default', 'border-radius', {
      min: 0,
      max: 999,
      mouseSpeed: 4,
      onChange: this.onEditInputChanged,
    });
    initInputEdit('button-default', 'border-width', {
      min: 0,
      max: 999,
      mouseSpeed: 4,
      onChange: this.onEditInputChanged,
    });

    initInputEdit('button-default', 'font-size', {
      min: this.block.MIN_FONT_SIZE,
      max: this.block.MAX_FONT_SIZE,
      mouseSpeed: 4,
      onChange: this.onEditInputChanged,
    });

    initInputEdit('button-default', 'letter-spacing', {
      min: this.block.MIN_LETTER_SPACING,
      max: this.block.MAX_LETTER_SPACING,
      mouseDir: 'h',
      useFloat: true,
      step: 0.2,
      mouseSpeed: 4,
      onChange: this.onEditInputChanged,
    });

    // ------------------------ инпуты для кнопки в ховере ------------------------------------------
    initInputEdit('button-hover', 'border-width', {
      min: 0,
      max: 999,
      mouseSpeed: 4,
      onChange: this.onEditInputChanged,
    });

    $(this.$panel.find('.edit-wrapper .params-block.params-button-hover .edit-item[data-field="transition"] .switcher'))
      .RMSwitcher(
        {
          state: this.getCurrentStyleParam('button-hover', 'transition'),
          width: 44,
          height: 26,
          'text-size-0': 0,
          'text-size-1': 0,
          'color-0': '#0078ff',
        },
        function(state) {
          // отложено для завершения анимации переключения свитчера
          _.delay(
            function() {
              this.setCurrentStyleParam('button-hover', 'transition', state);
            }.bind(this),
            300
          );
        }.bind(this)
      )
      .data('switcher');

    this.setAlignText();

    this.updateControlState();

    this.updateStylesState();

    function initInputEdit(tp, paramName, settings) {
      self.inputs[tp][paramName] = $(
        self.$panel.find(
          '.edit-wrapper .params-block.params-' + tp + ' .edit-item[data-field="' + paramName + '"] input'
        )
      )
        .RMNumericInput(settings)
        .data('changeValue');
    }
  },

  unBindLogic: function() {
    this.$panel.off('click', '.edit-wrapper', this.closeAllEditPopups);

    this.$panel.off('click', '.edit-wrapper .layout-wrapper .layout-switcher', this.onLayoutSwitchClick);
    this.$panel.off('click', '.edit-wrapper .style-wrapper .prev-style', this.onStyleSwitchClick);
    this.$panel.off('click', '.edit-wrapper .style-wrapper .next-style', this.onStyleSwitchClick);
    this.$panel.off('click', '.edit-wrapper .type-wrapper .type-switcher', this.onTypeSwitchClick);
    this.$panel.off('click', '.edit-wrapper .button-switcher-wrapper .button-switcher', this.onButtonSwitchClick);

    this.$panel.off('click', '.edit-wrapper .color-click-area', this.onColorboxShowClick);

    this.$panel.off('click', '.edit-wrapper .align', this.onAlignChangeClick);

    if (this.colorbox) {
      this.colorbox.off('colorchange', this.colorOpacityChanged);
      this.colorbox.off('opacitychange', this.colorOpacityChanged);
      this.colorbox.destroy();
      this.colorbox = null;
    }

    if (this.fontselector) {
      this.fontselector.off('selectfont', this.fontChanged);
      this.$panel.off('click', '.edit-wrapper .font-family-click-area', this.onFontSelectorShowClick);
      this.$panel.off('click', '.edit-wrapper .font-style-click-area', this.onFontStyleShowClick);
      this.fontselector.destroy();
      this.fontselector = null;
    }

    // удаляем всех слушателей изменений модели,
    // созданные этой вьюхой.
    this.model && this.model.off(null, null, this);
  },

  onChange: function(model, options) {
    if (options.undo || options.redo) {
      this.updateControlState();
      this.updateStylesState(options);
    }
  },

  onAlignChangeClick: function() {
    // если не задан, значит берем значение по умолчанию
    var cur_align = this.getCurrentStyleParam(this.getCurrentState(), 'text-align') || 'center';

    this.setCurrentStyleParam(this.getCurrentState(), 'text-align', cur_align === 'center' ? 'left' : 'center');

    this.setAlignText();

    this.updateControlState();

    this.updateStylesState();
  },

  setAlignText: function() {
    var $target = $('.edit-wrapper .align .caption'),
      // если не задан, значит берем значение по умолчанию
      cur_align = this.getCurrentStyleParam(this.getCurrentState(), 'text-align') || 'center',
      alignText = cur_align === 'center' ? 'center' : 'left';

    this.setCurrentStyleParam(this.getCurrentState(), 'text-align', cur_align);

    $target.text(`Aligned ${alignText}`);
  },

  onLayoutSwitchClick: function(e) {
    var $target = $(e.currentTarget);

    if ($target.hasClass('layout-vertical')) {
      this.model.set({
        layout: 'vertical',
      });
    } else {
      this.model.set({
        layout: 'horizontal',
      });
    }

    var defDim = this.block.getDefaultDimensions();

    this.model.set({
      w: defDim.width,
      h: defDim.height,
    });

    this.updateControlState();
    this.updateStylesState();
  },

  onStyleSwitchClick: function(e) {
    var $target = $(e.currentTarget),
      dir = $target.hasClass('prev-style') ? -1 : 1,
      styles = ['colored', 'outlined', 'underlined'],
      curStyleInd = _.indexOf(styles, this.model.get('style')),
      newStyleInd = (curStyleInd + styles.length + dir) % styles.length;

    this.model.set('style', styles[newStyleInd]);

    this.setAlignText();

    this.updateControlState();

    this.updateStylesState();
  },

  onTypeSwitchClick: function(e) {
    var $target = $(e.currentTarget);

    if ($target.hasClass('type-fields')) {
      this.state = 'fields';
    } else {
      this.state = 'button';
    }

    this.updateControlState();

    this.updateStylesState();
  },

  onButtonSwitchClick: function(e) {
    var $target = $(e.currentTarget);

    if ($target.hasClass('button-default')) {
      this.buttonState = 'default';
    } else {
      this.buttonState = 'hover';
    }

    this.updateControlState();

    this.updateStylesState();
  },

  // получает значение для параметра текущего выбранного стиля
  getCurrentStyleParam: function(tp, param) {
    var fieldName = 'style-' + this.model.get('style') + '-' + tp,
      value = this.model.get(fieldName)[param];

    // стиль для Кнопки
    // могут наследоваться от стилей полей, если
    // стоит значение 'inherit'.
    if (value === 'inherit') {
      if (tp == 'button-default') {
        fieldName = 'style-' + this.model.get('style') + '-fields';
        value = this.model.get(fieldName)[param];
      }
    }

    // ховер стиль для Кнопки
    // могут наследоваться от обычного состояния, если
    // стоит значение 'inherit'.
    if (value === 'inherit') {
      if (tp == 'button-hover') {
        fieldName = 'style-' + this.model.get('style') + '-button-default';
        value = this.model.get(fieldName)[param];

        if (value === 'inherit') {
          fieldName = 'style-' + this.model.get('style') + '-fields';
          value = this.model.get(fieldName)[param];
        }
      }
    }

    return value;
  },

  // устанавливает новое значение для параметра текущего выбранного стиля
  setCurrentStyleParam: function(tp, param, value) {
    var fieldName = 'style-' + this.model.get('style') + '-' + tp,
      styleData = _.clone(this.model.get(fieldName));

    styleData[param] = value;

    // Если меняем название шрифта, то нужно проверить, есть ли у него
    // начертание, которое установлено как текущее
    if (param == 'font-family') {
      var font_weight = this.getCurrentStyleParam(this.getCurrentState(), 'font-weight');
      var font_style = this.getCurrentStyleParam(this.getCurrentState(), 'font-style');
      var font = RM.constructorRouter.fonts.findFontByCSSName(value);
      var fvd = (font_style.toLowerCase() == 'italic' ? 'i' : 'n') + Math.floor(font_weight / 100);

      // Если в вариациях нет текущего начертания, то присваиваем параметру font-weight
      // вес первого поддерживаемого начертания у целевого шрифта
      if (!_.contains(font.variations, fvd)) {
        styleData['font-weight'] = (font.variations[0] || 'n4').slice(1) * 100;
      }
    }

    this.model.set(fieldName, styleData);
  },

  getCurrentState: function() {
    var state = this.state;

    if (state == 'button') {
      state += '-' + this.buttonState;
    }

    return state;
  },

  // проставляем текущий лейаут, стиль и его название и все классы которые управляют показом паарметров для выбранных настроек
  updateControlState: function() {
    this.$('.edit-wrapper')
      .attr('data-state', this.state)
      .attr('data-button-state', this.buttonState)
      .attr('data-style', this.model.get('style'))
      .attr('data-layout', this.model.get('layout'));

    this.$('.style-wrapper .style-caption').text(Utils.capitalize(this.model.get('style')));

    if (this.state == 'button' && this.buttonState == 'hover') {
      this.block.setButtonHoverState();
    } else {
      this.block.unsetButtonHoverState();
    }
  },

  updateStylesState: function(options) {
    options = options || {};

    var state = this.getCurrentState(),
      inputs = this.inputs[state],
      $params = this.$panel.find('.edit-wrapper .params-block.params-' + state),
      t;

    // устанавливаем значения инпутов для текущих видимых настроек
    ['gutter', 'border-radius', 'border-width', 'underline-width', 'font-size', 'letter-spacing', 'text-align'].forEach(
      function(param) {
        var $input = $params.find('.edit-item[data-field=' + param + '] input'),
          changeFunc = inputs[param],
          val = this.getCurrentStyleParam(state, param);

        if (!changeFunc) {
          return;
        }

        changeFunc(val, true);
        if (!options.undo && !options.redo) {
          this.onEditInputChanged($input, val); // Возможны пересчеты габаритов при смене глобального стиля
        }
      }.bind(this)
    );

    // заполняем цвета
    this.getCurrentStyleParam(state, 'color') &&
      $params
        .find('.edit-item[data-field="color"] .color-circle')
        .css(
          'background-color',
          Utils.getRGBA(this.getCurrentStyleParam(state, 'color'), this.getCurrentStyleParam(state, 'opacity') / 100)
        );

    this.getCurrentStyleParam(state, 'border-color') &&
      $params
        .find('.edit-item[data-field="border-color"] .color-circle')
        .css(
          'background-color',
          Utils.getRGBA(
            this.getCurrentStyleParam(state, 'border-color'),
            this.getCurrentStyleParam(state, 'border-opacity') / 100
          )
        );

    this.getCurrentStyleParam(state, 'underline-color') &&
      $params
        .find('.edit-item[data-field="underline-color"] .color-circle')
        .css(
          'background-color',
          Utils.getRGBA(
            this.getCurrentStyleParam(state, 'underline-color'),
            this.getCurrentStyleParam(state, 'underline-opacity') / 100
          )
        );

    this.getCurrentStyleParam(state, 'text-color') &&
      $params
        .find('.edit-item[data-field="text-color"] .color-circle')
        .css(
          'background-color',
          Utils.getRGBA(
            this.getCurrentStyleParam(state, 'text-color'),
            this.getCurrentStyleParam(state, 'text-opacity') / 100
          )
        );

    // зщаполняем шрифты
    if ($params.find('.font-family').length) {
      // Font Family, Style & Weight
      var font = RM.constructorRouter.fonts.findFontByCSSName(this.getCurrentStyleParam(state, 'font-family')),
        fontStyleName = RM.constructorRouter.fonts.getStyleName(
          this.getCurrentStyleParam(state, 'font-weight'),
          this.getCurrentStyleParam(state, 'font-style'),
          font.name
        );

      $params
        .find('.font-family')
        .text(font.name)
        .css('font-family', font.css_name);

      $params
        .find('.font-style')
        .text(fontStyleName)
        .css({
          'font-family': font.css_name,
          'font-style': this.getCurrentStyleParam(state, 'font-style'),
          'font-weight': this.getCurrentStyleParam(state, 'font-weight'),
        });
    }
  },

  onEditInputChanged: function($input, new_num) {
    var editParam = $input.closest('.edit-item').attr('data-field'),
      prev_num = this.getCurrentStyleParam(this.getCurrentState(), editParam),
      delta = (new_num - prev_num) * (this.state == 'fields' ? this.model.get('fields').length : 1),
      layout = this.model.get('layout'),
      dimension = layout == 'vertical' ? 'h' : 'w';

    this.setCurrentStyleParam(this.getCurrentState(), editParam, new_num);

    // при изменении гаттеров должны изменяться габариты виджета, а размер полей
    // должен оставаться неизменным
    if (editParam == 'gutter') {
      this.model.set(dimension, this.model.get(dimension) + delta);
    }

    // при смене этих параметров надо заново проверить минимальные размеры виджета по высоте/ширине
    if (editParam == 'font-size' || editParam == 'underline-width') {
      var minDim = this.block.getMinimalDimensions();

      if (minDim.width > this.model.get('w') || minDim.height > this.model.get('h')) {
        this.model.set({
          w: Math.max(minDim.width, this.model.get('w')),
          h: Math.max(minDim.height, this.model.get('h')),
        });
      }
    }
  },

  // при смене шрифта через Фонт селектор.
  fontChanged: function(font_family) {
    this.setCurrentStyleParam(this.getCurrentState(), 'font-family', font_family);

    this.updateStylesState();
  },

  // при выборе стиля шрифта в выпадалке Стиля шрифта.
  fontStyleChanged: function(e) {
    this.setCurrentStyleParam(this.getCurrentState(), 'font-weight', $(e.currentTarget).attr('data-font-weight'));

    this.setCurrentStyleParam(this.getCurrentState(), 'font-style', $(e.currentTarget).attr('data-font-style'));

    this.closeFontStyle();

    this.updateStylesState();
  },

  // при изменении цвета-прозрачности через Колорбокс.
  colorOpacityChanged: function(r, g, b, a) {
    var new_color = this.colorbox.rgb2hex([r, g, b]),
      new_color_opacity = a * 100;

    this.setCurrentStyleParam(this.getCurrentState(), this.colorbox_for_field, new_color);

    this.setCurrentStyleParam(this.getCurrentState(), this.colorbox_for_field_dop, new_color_opacity);

    this.updateStylesState();
  },

  // при клике по полю с именем шрифта (показ\скрытие Фонт селектора).
  onFontSelectorShowClick: function(e) {
    if (this.fontselector_visible) {
      this.closeFontSelector();
      return;
    }

    this.closeAllEditPopups();

    this.fontselector_visible = true;

    this.fontselector && this.fontselector.show([this.getCurrentStyleParam(this.getCurrentState(), 'font-family')]);

    this.positionPopup(this.$('.fontselector_container'), $(e.currentTarget));
  },

  // при клике по полю с Стилем шрифта (показ\скрытие попапа Стиля шрифта).
  onFontStyleShowClick: function(e) {
    if (this.fontstyle_visible) {
      this.closeFontStyle();
      return;
    }

    this.closeAllEditPopups();

    // заполняем выпадалку стилей.
    var $container = this.$('.fontstyle_container .font-style-wrapper');
    $container.empty();

    var font = RM.constructorRouter.fonts.findFontByCSSName(
      this.getCurrentStyleParam(this.getCurrentState(), 'font-family')
    );

    if (!font) return;

    _.each(
      font.variations,
      function(variation) {
        var variation_weight = (variation.substr(1, 1) - 0) * 100,
          variation_style = variation.substr(0, 1) == 'n' ? 'normal' : 'italic',
          variation_name = RM.constructorRouter.fonts.getStyleName(variation_weight, variation_style, font.name);

        var $item = $('<div>')
          .appendTo($container)
          .addClass('font-style-item')
          .text(variation_name)
          .css({
            'font-family': font.css_name,
            'font-weight': variation_weight + '',
            'font-style': variation_style,
          })
          .attr('data-font-weight', variation_weight)
          .attr('data-font-style', variation_style)
          .on('click', this.fontStyleChanged);

        // элемент «точка», которая высвечивается слева от
        // опции при ховере, а также помежает текущую опцию.
        var $point = $('<div>')
          .appendTo($item)
          .addClass('point');

        // отмечаем текущий стиль.
        if (
          variation_weight == this.getCurrentStyleParam(this.getCurrentState(), 'font-weight') &&
          variation_style == this.getCurrentStyleParam(this.getCurrentState(), 'font-style')
        ) {
          $item.addClass('curr');
        }
      }.bind(this)
    );

    this.fontstyle_visible = true;

    this.$('.fontstyle_container').fadeIn(200);

    this.positionPopup(this.$('.fontstyle_container'), $(e.currentTarget));
  },

  // при клике по полю с цветом какого-либо параметра панели — показ\скрытие Колорбокса.
  onColorboxShowClick: function(e) {
    var $item = $(e.currentTarget),
      field = $item.attr('data-field'),
      field_dop = $item.attr('data-field-dop'),
      fadeSpeed = 200;

    if (this.colorbox_visible) {
      // смотрим кликнули по полю для текущего Колорбокса или по другому.
      if (field == this.colorbox_for_field) {
        this.closeColorBox();
        return;
      } else {
        this.closeColorBox(true);
        fadeSpeed = 0;
      }
    }

    // скрываем все впадалки, показываем Колорбокс.
    this.closeAllEditPopups();
    this.colorbox_visible = true;

    this.$colorbox_container.fadeIn(fadeSpeed);

    // Если показываем колорбокс цвета текста, покажем value в инпутах,
    // чтобы был виден настоящий цвет текста, а не бледные плейсхолдеры.
    if (field === 'text-color' && this.state === 'fields') {
      this.block.toggleInputValue(true);
    }

    // сохраняем для colorOpacityChanged.
    this.colorbox_for_field = field;
    this.colorbox_for_field_dop = field_dop;

    // устанавливаем параметры в Колорбоксе.
    this.colorbox.setOpacity(this.getCurrentStyleParam(this.getCurrentState(), field_dop) / 100);
    this.colorbox.setColor(this.getCurrentStyleParam(this.getCurrentState(), field));

    this.positionPopup(this.$colorbox_container, $(e.currentTarget));
  },

  // позиционирует попап фонтселектора так, чтобы он был по центру панели.
  positionPopup: function($container, $menuItem) {
    var $content = $container.children().first(),
      popupH = $content.height(),
      panelH = this.$panel.height(),
      centerY = Math.floor((panelH - popupH) / 2),
      menuItemY = $menuItem.offset().top - this.$panel.offset().top,
      overflow = Math.max(menuItemY + popupH - panelH + 16, 0);

    $container.css({ top: Math.max(centerY, menuItemY - overflow) });
  },

  // закрывает Фонт селектор.
  closeFontSelector: function() {
    this.fontselector_visible = false;
    this.fontselector.hide();
  },

  // закрывает выпадалку Стиля шрифта.
  closeFontStyle: function() {
    this.fontstyle_visible = false;
    this.$('.fontstyle_container').fadeOut(200);
  },

  // закрывает Колорбокс.
  closeColorBox: function(noAnimation) {
    this.colorbox_visible = false;

    this.$colorbox_container.fadeOut(noAnimation ? 0 : 200);

    // Если прячем колорбокс цвета текста, уберём value в инпутах (value показывали чтобы был виден цвет текста)
    if (this.colorbox_for_field === 'text-color' && this.state === 'fields') {
      this.block.toggleInputValue(false);
    }
  },

  // расширяем метод клика по иконке Контрола.
  onClick: function() {
    if (this.selected) {
      if (this.colorbox_visible || this.fontselector_visible || this.fontstyle_visible) {
        this.closeAllEditPopups();
        return;
      }
    }

    ControlClass.prototype.onClick.apply(this, arguments);
  },

  // закрыть все выпадалки.
  closeAllEditPopups: function(e) {
    if (e) {
      // ничего не делаем, если кликнули в:

      // любой параметр цвета.
      if ($(e.target).closest('.color-click-area').length > 0) return;

      // параметр выбора шрифта.
      if ($(e.target).closest('.font-family-click-area').length > 0) return;

      // параметр выбора стиля шрифта.
      if ($(e.target).closest('.font-style-click-area').length > 0) return;
    }

    // скрываем Колорбокс.
    if (this.colorbox_visible) this.closeColorBox();

    // скрываем Фонт селектор.
    if (this.fontselector_visible) this.closeFontSelector();

    // скрываем попап Стиля шрифта.
    if (this.fontstyle_visible) this.closeFontStyle();
  },

  /**
   * расширяем функцию которая решает поглощает контрол событие
   * или нет (обычно это событие deselect воркспейса).
   */
  canControlBeClosed: function() {
    // если у нас открыт Контрол (показана панелька),
    // а в ней открыли Колорбокс для какого-то параметра,
    // то при клике куда-либо снаружи панельки — закроется Колорбокс,
    // а панель останется еще открытой.
    if (this.colorbox_visible || this.fontselector_visible || this.fontstyle_visible) {
      this.closeAllEditPopups();
      return true;
    }

    return ControlClass.prototype.canControlBeClosed.apply(this, arguments);
  },

  save: function() {
    this.model.save({});
  },

  // расширяем метод который срабатывает при открытии панели контрола
  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    // чтобы ечли выбраны стили кнопки и ховера сам блок также показал кнопку в состоянии ховера
    this.updateControlState();
  },

  deselect: function() {
    this.closeAllEditPopups();

    // меняем состояние панельки и Кнопки на Default.
    if (this.state == 'button' && this.buttonState == 'hover') {
      setTimeout(
        function() {
          this.block.unsetButtonHoverState();
        }.bind(this),
        200 + 50 // 200ms время анимации скрытия панели.
      );
    }

    ControlClass.prototype.deselect.apply(this, arguments);
  },

  /**
   * переопределено,
   * чтобы менять состояние Кнопки в Default при дестрое Контрола.
   * важно, т.к. дестрой Контрола происходит при переходе в Превью.
   */
  destroy: function(animation) {
    // меняем состояние панельки и Кнопки на Default.
    // скопировано из this.deselect().
    if (this.state == 'button' && this.buttonState == 'hover') {
      setTimeout(
        function() {
          this.block.unsetButtonHoverState();
        }.bind(this),
        200 + 50 // 200ms время анимации скрытия панели.
      );
    }

    ControlClass.prototype.destroy.apply(this, arguments);
  },
});

export default FormStyles;
