/**
 * Плагин для авторесайза text area + ограничивает высоту, после которой уже нельзя вводить символы или копи-пастить
 *
 * Для работы необходим  jQuery, underscore, RMDrag
 *
 *
 * пример использования можно поглядеть в pages-panel-settings.js
 *
 */
import _ from '@rm/underscore';

(function($, undefined) {
  $.fn.RMAutoResizeTextArea = function(options) {
    var AutoResize = function(options, $input) {
      this.options = _.clone(options);
      this.$input = $input;

      //maxHeight это максимальная высота блока текста в textarea
      //maxVisibleHeight это максимальная высота самой textarea
      //примеры (при высоте строки в 20px):
      //  maxHeight=100, maxVisibleHeight=10000  -  можно ввести не более 5 строк текста, соответственно и высота текстарии будет не больше 5 строк
      //  maxHeight=10000, maxVisibleHeight=200  -  можно ввести не более 500 строк текста, но высота текстарии будет не больше 10 строк (внутри будет скролл)

      _.defaults(this.options, {
        maxHeight: 9999,
        maxVisibleHeight: 9999,
        disableMultiline: false,
        $scroll: null,
      });

      this.$scroll = this.options.$scroll;
    };

    AutoResize.prototype = {
      initialize: function() {
        _.bindAll(this);

        this.$input.on('input', this.resizeTextArea);
        this.$input.on('keydown', this.keyDown);

        if (this.options.$scroll) {
          this.$input.on('scroll', this.onScroll);

          this.$input.bind('mousewheel', this.onMouseWheel);
          this.$scroll.bind('mousewheel', this.onMouseWheel);

          $(this.$scroll).RMDrag({
            start: this.startScrollDrag,
            move: this.moveScrollDrag,
            end: this.stopScrollDrag,
            silent: true,
            preventDefault: true,
          });
        }

        this.data = {
          handle_pos: 0,
          handle_size: 0,
          container_size: 0,
          content_size: 0,
          scroll_pos: 0,
          scroll_percent: 0,
        };

        var pos = '0% 0%';

        //если вызывается на элементе которы не приаатачен к думу, .css(...) выдаст ошибку
        try {
          pos = this.$input.css('background-position');
        } catch (e) {}

        var pos_arr = pos.split(' ');

        this.background_x = parseInt(pos_arr[0], 10);
        this.background_y = parseInt(pos_arr[1], 10);

        this.prevText = this.$input.val();

        this.resizeTextArea();
      },

      onMouseWheel: function(e, d) {
        if (!this.options.$scroll) return;

        var scrollTo = -Math.ceil(d * 0.1);

        if (scrollTo) {
          e.preventDefault();

          if (scrollTo > 0 && scrollTo < 1) scrollTo = 1;
          if (scrollTo < 0 && scrollTo > -1) scrollTo = -1;

          var newScrollTo = scrollTo + this.$input.scrollTop();
          this.$input.scrollTop(newScrollTo);
        }
      },

      onScroll: function(e) {
        if (!this.options.$scroll) return;

        this.data.scroll_pos = this.$input.scrollTop();

        var delta = this.data.content_size - this.data.container_size;

        if (this.data.scroll_pos > delta) this.data.scroll_pos = delta;

        if (delta > 0) this.data.scroll_percent = this.data.scroll_pos / delta;
        else this.data.scroll_percent = 0;

        this.data.handle_pos = Math.ceil(this.data.scroll_percent * (this.data.container_size - this.data.handle_size));

        this.$scroll.css('top', this.data.handle_pos);

        this.$input.css(
          'background-position',
          this.background_x + 'px ' + (this.background_y - this.data.scroll_pos) + 'px'
        );
      },

      recalcScroll: function() {
        if (!this.options.$scroll) return;

        this.data.handle_size = this.data.container_size / this.data.content_size;
        this.data.handle_size = Math.max(Math.min(this.data.handle_size, 1), 0);
        this.data.handle_size = Math.sqrt(this.data.handle_size);
        this.data.handle_size = Math.ceil(this.data.handle_size * this.data.container_size);

        if (this.data.container_size >= this.data.content_size) this.$scroll.css('display', 'none');
        else this.$scroll.css('display', 'block');

        this.$scroll.css('height', this.data.handle_size);

        this.onScroll();
      },

      startScrollDrag: function(e) {
        this.$scroll.addClass('dragging');
        this.oldDeltaY = 0;
      },

      moveScrollDrag: function(e) {
        var offset = e.deltaY - this.oldDeltaY,
          minOffset =
            (this.data.container_size - this.data.handle_size) / (this.data.content_size - this.data.container_size);

        if (offset < 0 && offset > -minOffset) offset = -minOffset;
        if (offset > 0 && offset < minOffset) offset = minOffset;

        var new_pos = this.data.handle_pos + offset,
          percent = new_pos / (this.data.container_size - this.data.handle_size);

        percent = Math.min(Math.max(percent, 0), 1);
        var scrollPos = percent * (this.data.content_size - this.data.container_size);

        this.$input.scrollTop(scrollPos);

        this.oldDeltaY = e.deltaY;
      },

      stopScrollDrag: function(e) {
        this.$scroll.removeClass('dragging');
      },

      keyDown: function() {
        this.st = this.$input[0].selectionStart;
        this.ed = this.$input[0].selectionEnd;
      },

      resizeTextArea: function() {
        //если вызывается на элементе которы не приаатачен к думу, .css(...) выдаст ошибку
        //и всякие .height() тоже
        //потому сразу проверяем что дело труба и ничего не делаем
        try {
          this.$input.css('background-position');
        } catch (e) {
          return;
        }

        var text = this.$input.val(),
          scrollTop = this.$input.scrollTop();

        if (this.options.disableMultiline) {
          var newText = text.split('\n').join('');
          if (newText != text) {
            this.$input.val(newText);
            _.defer(
              _.bind(function() {
                this.$input[0].selectionStart = this.st;
                this.$input[0].selectionEnd = this.ed;
              }, this)
            );
            this.resizeTextArea();
            return;
          }
        }

        var height = this.calcHeight();

        if (height > this.options.maxHeight) {
          //текст не помещается потому что ввели один символ
          if (text.length - this.prevText.length == 1) {
            //просто возвращаем предыдущий текст
            this.$input.val(this.prevText);
          } else {
            //ввели несколько символов - копи-пасте или драг и дроп
            //режем текст методом бисекции пытаясь найти то количество символов которое поместится в максимальную высоту
            var st = 0,
              ed = text.length - 1;
            while (st < ed) {
              var mid = Math.floor((st + ed) / 2),
                newText = text.substring(0, mid + 1);

              this.$input.val(newText);
              height = this.calcHeight();

              if (height > this.options.maxHeight) {
                //отрезали половину - а текст все равно не поместился
                ed = mid;
              } else {
                //отрезали половину - и текст поместился
                st = mid + 1;
              }
            }

            this.$input.val(text.substring(0, ed));
          }

          _.defer(
            _.bind(function() {
              this.$input[0].selectionStart = this.st;
              this.$input[0].selectionEnd = this.ed;
            }, this)
          );
          this.resizeTextArea();
          return;
        } else {
          if (height > this.options.maxVisibleHeight) {
            this.$input.height(this.options.maxVisibleHeight);
            this.data.container_size = this.options.maxVisibleHeight;
            this.data.content_size = height;
          } else {
            this.$input.height(height);
            this.data.container_size = height;
            this.data.content_size = height;
          }
        }

        this.options.onChange && this.options.onChange(text);
        this.$input.scrollTop(scrollTop);
        this.prevText = text;
        this.recalcScroll();
      },

      calcHeight: function() {
        this.$input.height(0);
        this.$input.scrollTop('9999');
        return this.$input.height() + this.$input.scrollTop();
      },
    };

    var $elem = $(this);

    var autoresize = new AutoResize(options, $elem);
    autoresize.initialize();

    $elem.data('autoresize', autoresize);

    return this;
  };
})(jQuery);
