/**
 * Расширение базового сласса контрола чтобы делать контролы с панельками которые юзер может ресайзить по высоте
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from './control';

const ControlResizableClass = ControlClass.extend({
  MIN_PANEL_HEIGHT: 100,

  MAX_PANEL_HEIGHT: 672,

  PANEL_MARGIN: 20,

  SCROLL_GAP_START: 16,
  SCROLL_GAP_END: 16,

  /**
   * Переопределяем метод отрисовки контрола
   */
  render: function() {
    this.panelSizes = {
      resizable: this.getControlParam('height') || this.MIN_PANEL_HEIGHT,
    };

    ControlClass.prototype.render.apply(this, arguments);

    this.$el.addClass('control-resizable');

    this.resizableScroll = $(this.$el.find('.resizable-scroll-wrapper'))
      .RMScroll({
        $container: this.$el.find('.resizable-content-wrapper'),
        $content: this.$el.find('.resizable-content'),
        $handle: this.$el.find('.resizable-scroll'),
        wheelScrollSpeed: 0.4,
        gap_start: this.SCROLL_GAP_START,
        gap_end: this.SCROLL_GAP_END,
        onScroll: this.onPanelScroll,
      })
      .data('scroll');

    this.__scrollRecalc_debounced = _.debounce(
      _.bind(function() {
        this.resizableScroll.recalc();
        this.onScrollRecalc('control-resize');
      }, this),
      300
    );

    $(this.$('.resize-handle-top')).RMDrag({
      start: this.startHandleDrag,
      move: this.moveHandleDrag,
      end: this.endHandleDrag,
      silent: true,
      preventDefault: true,
    });

    $(this.$('.resize-handle-bottom')).RMDrag({
      start: this.startHandleDrag,
      move: this.moveHandleDrag,
      end: this.endHandleDrag,
      silent: true,
      preventDefault: true,
    });

    this.updatePanelHeight();
  },

  onResize: function(e) {
    this.updatePanelHeight();
  },

  updatePanelHeight: function(h) {
    if (this.destroyed) return;

    // если не передали параметр высоты, тогда просто восстановим ту высоту которая была на последний момент изменений
    // полезно например при обработке смены высоты экрана, когда надо просто применить констрейнты по высоте
    h = h || this.panelSizes[this.fixedPanelSize ? 'fixed' : 'resizable'];

    // применяем ограничения по размерам панельки
    // только для ресайз состояний
    if (!this.fixedPanelSize) {
      // приводим ограничения к четному виду, иначе будет скакать панелька по вертикали при нечетных размерах
      var min_mod_2 = Math.ceil(this.MIN_PANEL_HEIGHT / 2) * 2,
        max_mod_2 = Math.ceil(this.MAX_PANEL_HEIGHT / 2) * 2,
        window_h = $(window).height() - this.PANEL_MARGIN * 2,
        window_h_mod_2 = Math.ceil(window_h / 2) * 2;
      h = Math.max(h, min_mod_2);
      h = Math.min(h, max_mod_2);
      h = Math.min(h, window_h_mod_2);
    }

    this.$panel.height(h);

    // сохраняем последний размер для выбранного типа панельки (фиксированная или ресайзабл)
    this.panelSizes[this.fixedPanelSize ? 'fixed' : 'resizable'] = h;

    // позиционируем панельку так, чтобы она была четко по центру окна
    // либо для фиксированного размера - по центру иконки, например для панели анимаций когда они выключены
    if (this.fixedPanelSize) {
      this.$panel.css('top', -Math.ceil((h - this.master.ICON_HEIGHT) / 2));
    } else {
      var iconTop = parseInt(this.$el.css('top'));
      this.$panel.css('top', -Math.ceil(h / 2) - iconTop);
    }

    if (!this.fixedPanelSize) {
      this.__scrollRecalc_debounced && this.__scrollRecalc_debounced();
    }
  },

  startHandleDrag: function(e) {
    var $handle = $(e.currentTarget);

    this.resizeParams = {
      $handle: $handle,
      dir: $handle.hasClass('resize-handle-top') ? -1 : 1,
      initial_h: this.panelSizes['resizable'],
    };

    $handle.addClass('dragging');

    this.$panel.addClass('no-transitions');
  },

  moveHandleDrag: function(e) {
    var new_h = this.resizeParams.initial_h + e.deltaY * this.resizeParams.dir * 2; //* 2 потому что панельку ресайзим в обе стороны

    this.updatePanelHeight(new_h);

    this.resizableScroll && this.resizableScroll.recalc();

    this.onScrollRecalc('control-resize');
  },

  endHandleDrag: function(e) {
    this.resizeParams.$handle.removeClass('dragging');

    this.$panel.removeClass('no-transitions');
  },

  // переключает панельку в режим фиксированного размера
  // когда нельзя ресайзить и когда ее положение центрируется по иконке, а не блоку контролов
  // высота в случае фиксед размера передается тут же
  // в случае если фиксед отключаем высота восстанавливается из последнего размера при ресайз виде панельки
  toggleFixedPanelSize: function(state, height) {
    this.fixedPanelSize = state;

    this.$('.resize-handle-top').toggle(!state);
    this.$('.resize-handle-bottom').toggle(!state);

    this.updatePanelHeight(state ? height : this.panelSizes['resizable']);
  },

  onScrollRecalc: function(initiator) {},

  onPanelScroll: function() {},

  /**
   * Переопределяем метод показа контрола
   */
  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    this.resizableScroll && this.resizableScroll.recalc();

    this.onScrollRecalc('control-show');

    if (!this.resizeScrollBinded) {
      $(window).on('resize', this.onResize);
      this.resizeScrollBinded = true;
    }
  },

  deselect: function() {
    ControlClass.prototype.deselect.apply(this, arguments);
    if (!this.$panel) return;

    if (this.panelSizes['resizable'] != this.getControlParam('height')) {
      this.saveControlParam({ height: this.panelSizes['resizable'] });
    }

    $(window).off('resize', this.onResize);
    this.resizeScrollBinded = false;
  },

  destroy: function() {
    if (this.panelSizes['resizable'] != this.getControlParam('height')) {
      this.saveControlParam({ height: this.panelSizes['resizable'] });
    }

    $(window).off('resize', this.onResize);
    this.resizeScrollBinded = false;

    ControlClass.prototype.destroy.apply(this, arguments);
  },
});

export default ControlResizableClass;
