<template>
  <div
    class="rm-opacity-slider"
    :style="{ background: `linear-gradient(to right, ${gradientLeft}, ${gradientRight})` }"
    @mouseenter="opacityMouseEnter"
    @mouseleave="opacityMouseLeave"
  >
    <div ref="handle" class="opacity-handle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import { Utils } from '../../js/common/utils';

export default {
  // двухсторонний биндинг
  // https://vuejs.org/v2/guide/components.html#Customizing-Component-v-model
  model: {
    event: 'change',
  },

  computed: {
    percent: function() {
      return Math.round(this.value * 100);
    },

    gradientLeft: function() {
      return Utils.getRGBA(this.gradientColor, 1);
    },

    gradientRight: function() {
      return Utils.getRGBA(this.gradientColor, 0);
    },
  },

  watch: {
    value: function(val) {
      // Изменения извне
      if (val != $(this.$el).data('opacity')) {
        $(this.$el).data('rmopacitydragChange')({ opacity: val });
      }
    },
  },

  props: {
    value: {
      type: Number,
      default: 1,
    },

    width: {
      type: Number,
      default: 120,
    },
    handleWidth: {
      type: Number,
      default: 30,
    },

    gradientColor: {
      type: String,
      default: 'none',
    },

    gap: {
      type: Number,
      default: 0,
    },
  },

  mounted: function() {
    // $el — HTMLElement
    var $el = $(this.$el);

    $el
      .rmOpacityDrag({
        opacity: this.value,
        onchange: this.onOpacityChange,
        onenddrag: this.onOpacityEndDrag,
        width: this.width,
        handleWidth: this.handleWidth,
        gap: this.gap,
      })
      .data('rmopacitydrag-change');
  },
  methods: {
    opacityMouseEnter: function() {
      this.$emit('opacity-active', true);
    },

    opacityMouseLeave: function() {
      this.$emit('opacity-active', false);
    },

    onOpacityEndDrag: function() {
      this.$emit('opacity-active', false);
    },

    onOpacityChange: function(opacity) {
      if ($(this.$refs.handle).hasClass('opacity-dragging')) this.$emit('opacity-active', true);

      if (Math.abs(this.value - opacity) > 0.001) {
        this.$emit('change', opacity);
      }
    },
  },
};
</script>

<style lang="less">
@import (reference) '../../css/common/constants.less';

.rm-opacity-slider {
  border-radius: 15px;
  cursor: pointer;

  position: absolute;
  top: 39px;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  height: 30px;

  background-image: url('../../img/constructor/controls/picture_settings/opacity-bg.png');
  background-repeat: no-repeat;
  background-size: 120px 30px;

  .opacity-handle {
    cursor: hand;
    cursor: ew-resize;

    border-radius: 50%;

    box-shadow: inset 0 0 0 2px #3d3d3d;

    position: absolute;
    width: 26px;
    height: 26px;
    top: 0px;
    left: 0px;
    margin: 2px 0 0 -13px;

    .transition(0.2s, box-shadow, ease-in-out);

    &:hover,
    &.opacity-dragging {
      box-shadow: inset 0 0 0 2px #0078ff;
    }
  }
}
</style>
