/**
 * Общий для конструктора и вьювера код генерации svg по параметрам (для виджета шейпа)
 */
import $ from '@rm/jquery';

window.iconSVGCache = [];

const ShapeSVG = function() {};

ShapeSVG.prototype = {
  MAX_ICON_CACHE_SIZE: 30,

  generateShapeSVG: function(caller, model, w, h, shape_type) {
    var PRECISION = 100000;
    // У старых виджетов во вьюпорте может не быть bg_color - берем тогда из дефолта
    var bg_color = model.get('bg_color') || (model.viewport_default && model.viewport_default.bg_color);

    var fill_color = this.getRGBA(bg_color || 'ffffff', model.get('bg_opacity')),
      stroke_color = this.getRGBA(model.get('color'), model.get('opacity')),
      svg;

    if (model.get('type') === 'hotspot') {
      shape_type = shape_type || model.get('pin_type');
    } else {
      shape_type = shape_type || model.get('tp');
    }

    if (shape_type == 'ellipse') {
      var cx = w / 2,
        cy = h / 2,
        border = model.get('borders'),
        border = Math.min(border, cx, cy);

      // формируем окончательный svg где есть бордеры и заливка
      svg = generateFinalSvg(
        'ellipse',
        { cx: cx, cy: cy, rx: cx, ry: cy },
        fill_color,
        stroke_color,
        border,
        model.id + '_' + caller,
        model.get('opacity')
      );
    }

    if (shape_type == 'rectangle' || shape_type == 'polygon') {
      if (model.get('type') === 'hotspot') {
        // у хотспота tp « ractangle» — это форма точки
        // прозрачный прямоугольник.
        fill_color = 'rgba(0, 0, 0, 0)';
        stroke_color = fill_color;
      }

      var n = shape_type == 'rectangle' ? 4 : model.get('sides'),
        border = model.get('borders'),
        radius = model.get('radius') || 0;

      // создаем полигон с n сторонами (сначала делаем правильный многоугольник в блоке 1х1, потом масштабируем его чтобы он занял WxH)
      var points = createPolygon(n, w, h, border);

      // считаем длины сторон (заодно получаем минимальную по длине сторону)
      var minSide = calcSidesLength(points);

      // расчитываем координаты точек с учетом скруглений (если radius = 0 то вернет undefined)
      // если есть задан радиус и задан бордер, тогда радиус скруглений для каждого угла будет индивидуальный
      // и бдет зависить от внутреннего полигона
      var pointsRounded = calcRoundedPoints(points, radius, minSide);

      // из расчитанных вершин и расчитанныз точек скругления формируем строку path
      var path = generatePointsSVG(points, pointsRounded.points, pointsRounded.radius);

      // формируем окончательный svg где есть бордеры и заливка
      svg = generateFinalSvg(
        'path',
        { path: path },
        fill_color,
        stroke_color,
        border,
        model.id + '_' + caller,
        model.get('opacity')
      );
    }

    if (shape_type == 'line') {
      var border = model.get('weight'),
        cy = Math.round(h / 2) + (border % 2 == 1 ? 0.5 : 0),
        path = '',
        dashArray = [(border * 13) / 3, (border * 2) / 3].join(' '),
        dotsArray = border > 1 ? [0.001, (border * 5) / 3].join(' ') : [1, 2].join(' '),
        stX = model.get('stroke') == 'dotted' ? border / 2 : 0;

      path = 'M' + stX + ' ' + cy + ' L' + w + ' ' + cy;

      if (model.get('stroke') == 'solid')
        svg = '<path d="' + path + '" style="fill:none; stroke:' + fill_color + ';stroke-width:' + border + '"/>';

      if (model.get('stroke') == 'double') {
        var path1 = 'M' + stX + ' ' + (cy - border) + ' L' + w + ' ' + (cy - border),
          path2 = 'M' + stX + ' ' + (cy + border) + ' L' + w + ' ' + (cy + border);

        svg = '<path d="' + path1 + '" style="fill:none; stroke:' + fill_color + ';stroke-width:' + border + '"/>';
        svg += '<path d="' + path2 + '" style="fill:none; stroke:' + fill_color + ';stroke-width:' + border + '"/>';
      }

      if (model.get('stroke') == 'dotted')
        svg =
          '<path d="' +
          path +
          '" stroke-linecap="round" stroke-dasharray="' +
          dotsArray +
          '" style=" fill:none; stroke:' +
          fill_color +
          ';stroke-width:' +
          border +
          '"/>';

      if (model.get('stroke') == 'dashed')
        svg =
          '<path d="' +
          path +
          '" stroke-dasharray="' +
          dashArray +
          '" style=" fill:none; stroke:' +
          fill_color +
          ';stroke-width:' +
          border +
          '"/>';
    }

    if (shape_type == 'icon') {
      var icon_data = this.iconData || this.current_icon_data;

      if (!icon_data) {
        return null;
      }

      var $iconSVG = icon_data.$svg.clone();

      $iconSVG.css({
        width: '100%',
        height: '100%',
      });

      // Нельзя задавать у SVG  fill через rgba, только через rgb.
      // Иначе растеризатор inkscape на бэке сделает картинку полностью прозрачной.
      // Вместо этого прозрачность задается в атрибуте fill-opacity ниже
      fill_color = this.getRGBA(bg_color, 1);

      $iconSVG.find('*:not(.fixed-color)').each(function() {
        var $this = $(this);
        $this.css({ fill: '', 'fill-opacity': '' });

        $this.get(0).removeAttribute('fill');
        $this.get(0).removeAttribute('fill-opacity');
        // }
      });

      $iconSVG.get(0).setAttribute('fill', fill_color);
      $iconSVG.get(0).setAttribute('fill-opacity', model.get('bg_opacity'));

      return $iconSVG;
    }

    return (
      '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="' + w + 'px" height="' + h + 'px">' + svg + '</svg>'
    );

    function generateFinalSvg(tp, params, fill_color, stroke_color, border, uniqID, opacity) {
      var maskID = 'mask_' + uniqID,
        clipID = 'clip_' + uniqID,
        svg = '',
        k = 2;

      // если задан бордер и он не прозрачный
      // тогда внутреннюю заливку делаем чуть больше, чтобы не было артефактов при переходе от заливки к бордеру
      // (тогда заливка просто чуть заползает под бордер)
      if (border > 0 && opacity > 0.99) k = 1;

      svg = '<defs>';

      if (border > 0) {
        svg += '<mask id="' + maskID + '">';
        if (tp == 'path')
          svg += '<path d="' + params.path + '" style="fill:white; stroke:black; stroke-width:' + border * k + '"/>';
        if (tp == 'ellipse')
          svg +=
            '<ellipse cx="' +
            params.cx +
            '" cy="' +
            params.cy +
            '" rx="' +
            params.rx +
            '" ry="' +
            params.ry +
            '" style="fill:white; stroke:black; stroke-width:' +
            border * k +
            '"/>';
        svg += '</mask>';
      }

      svg += '<clippath id="' + clipID + '">';
      if (tp == 'path') svg += '<path d="' + params.path + '"/>';
      if (tp == 'ellipse')
        svg += '<ellipse cx="' + params.cx + '" cy="' + params.cy + '" rx="' + params.rx + '" ry="' + params.ry + '"/>';
      svg += '</clippath>';

      svg += '</defs>';

      // эта строчка формирует заливку
      // просто заполняем периметр, а потом по маске убираем ту часть которая перекрывается с бордером (который мы рисуем внутри элемента, а не снаружи)
      if (tp == 'path')
        svg +=
          '<path mask="url(#' +
          maskID +
          ')" d="' +
          params.path +
          '" style="fill:' +
          fill_color +
          '; stroke:none; stroke-width:0; pointer-events: visiblePainted;"/>';
      if (tp == 'ellipse')
        svg +=
          '<ellipse mask="url(#' +
          maskID +
          ')" cx="' +
          params.cx +
          '" cy="' +
          params.cy +
          '" rx="' +
          params.rx +
          '" ry="' +
          params.ry +
          '" style="fill:' +
          fill_color +
          '; stroke:none; stroke-width:0; pointer-events: visiblePainted;"/>';

      // эта строчка формирует бордер
      // бордер указывается двойной, потому что svg рисует бордер и наружу и внутрь периметра,
      // просто мы потом клипом отрезаем все что снаружи и получаем одинарный (с наружным невозможно нормально работать для наших целей)
      if (border == 0) return svg;

      if (tp == 'path')
        svg +=
          '<path clip-path="url(#' +
          clipID +
          ')" d="' +
          params.path +
          '" style="fill:none; stroke:' +
          stroke_color +
          '; stroke-width:' +
          border * 2 +
          '; pointer-events: visiblePainted;"/>';
      if (tp == 'ellipse')
        svg +=
          '<ellipse clip-path="url(#' +
          clipID +
          ')" cx="' +
          params.cx +
          '" cy="' +
          params.cy +
          '" rx="' +
          params.rx +
          '" ry="' +
          params.ry +
          '" style="fill:none; stroke:' +
          stroke_color +
          '; stroke-width:' +
          border * 2 +
          '; pointer-events: visiblePainted;"/>';

      return svg;
    }

    function createPolygon(n, w, h, border) {
      var centerAng = (2 * Math.PI) / n,
        startAng = Math.PI / 2 - (n % 2 == 1 ? 0 : centerAng / 2),
        points = [],
        ang,
        vx,
        vy;

      // находим координаты правильного многоугольника в блоке 1х1
      // +находим Bounding Box нашего расчитаного многоугольника 1х1

      var minX = 999999,
        maxX = -999999,
        minY = 999999,
        maxY = -999999;

      for (var i = 0; i < n; i++) {
        ang = startAng + i * centerAng;
        vx = 0.5 + 0.5 * Math.cos(ang);
        vy = 0.5 - 0.5 * Math.sin(ang);
        points.push({ x: vx, y: vy });

        if (vx < minX) minX = vx;
        if (vx > maxX) maxX = vx;
        if (vy < minY) minY = vy;
        if (vy > maxY) maxY = vy;
      }

      // масштабируем наши координаты так, чтобы многоугольник полностью занял все доступное пространство в блоке
      for (var i = 0; i < n; i++) {
        points[i].x = (points[i].x - minX) * (w / (maxX - minX));
        points[i].y = (points[i].y - minY) * (h / (maxY - minY));

        // добиваемся определенной точности
        points[i].x = Math.round(points[i].x * PRECISION) / PRECISION;
        points[i].y = Math.round(points[i].y * PRECISION) / PRECISION;
      }

      return points;
    }

    function calcSidesLength(points) {
      if (!points) return 99999;

      var minSide = 99999,
        n = points.length,
        side;

      for (var i = 0; i < n; i++) {
        var dx = points[i].x - points[(i + 1) % n].x,
          dy = points[i].y - points[(i + 1) % n].y;

        side = Math.sqrt(dx * dx + dy * dy);
        if (side < minSide) minSide = side;
        points[i].side = side;
      }

      return minSide;
    }

    function calcRoundedPoints(points, radius, minSide, refineFromInner, border, innerPoints) {
      if (!points) return {};

      if (radius <= 0.0001) return {};

      var pointsRounded = [],
        n = points.length;

      radius = Math.min(radius, minSide / 2);

      // скругляем углы
      for (var i = 0; i < n; i++) {
        var p1 = points[i],
          p2 = points[(i + 1) % n],
          len = points[i].side,
          dx = (p2.x - p1.x) / len,
          dy = (p2.y - p1.y) / len,
          p1x,
          p1y,
          p2x,
          p2y;

        p1x = p1.x + dx * radius;
        p1y = p1.y + dy * radius;
        p2x = p2.x - dx * radius;
        p2y = p2.y - dy * radius;

        // добиваемся определенной точности
        p1x = Math.round(p1x * PRECISION) / PRECISION;
        p1y = Math.round(p1y * PRECISION) / PRECISION;
        p2x = Math.round(p2x * PRECISION) / PRECISION;
        p2y = Math.round(p2y * PRECISION) / PRECISION;

        pointsRounded.push({ x: p1x, y: p1y });
        pointsRounded.push({ x: p2x, y: p2y });
      }

      return {
        points: pointsRounded,
        radius: radius,
      };
    }

    function generatePointsSVG(points, pointsRounded, radius) {
      if (!points) return '';

      var n = points.length,
        path = '';

      for (var i = 0; i < n; i++) {
        var vInd = (i + 1) % n,
          p1Ind = i * 2 + 1,
          p2Ind = (i * 2 + 2) % (n * 2);

        path += i == 0 ? 'M' : 'L';

        if (pointsRounded) {
          path += pointsRounded[p1Ind].x + ' ' + pointsRounded[p1Ind].y + ' ';

          // через кубического безье получаются некорректные скругления
          // path += 'Q' + points[vInd].x + ' ' + points[vInd].y + ' ' + pointsRounded[p2Ind].x + ' ' + pointsRounded[p2Ind].y + ' ';

          // делаем скругления дугами
          // ищем радиус окружности дуга которой должна проходить между pointsRounded[p1Ind] и pointsRounded[p2Ind]
          // а центр в точке пересечения высот проведенных из этих точек
          var dx = pointsRounded[p1Ind].x - pointsRounded[p2Ind].x,
            dy = pointsRounded[p1Ind].y - pointsRounded[p2Ind].y,
            pDist = (dx * dx + dy * dy) / 4,
            tmp = 1 / pDist - 1 / (radius * radius),
            arcRadius = Math.sqrt(1 / tmp);

          path +=
            'A' + arcRadius + ' ' + arcRadius + ' 0 0 0 ' + pointsRounded[p2Ind].x + ' ' + pointsRounded[p2Ind].y + ' ';
        } else {
          path += points[i].x + ' ' + points[i].y + ' ';
        }
      }

      return path + ' Z ';
    }
  },

  getRGBA: function(color, opacity) {
    if (opacity == 0) return 'none';

    var rgb = [
      parseInt(color.substring(0, 2), 16),
      parseInt(color.substring(2, 4), 16),
      parseInt(color.substring(4, 6), 16),
    ];

    if (opacity > 0.99) return 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
    else return 'rgba(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ', ' + opacity + ')';
  },
};

export default ShapeSVG;
