/**
 * Функция запуска Backbone.history
 * корректирует поведение ссылок в браузерах, не поддерживающих History API (IE9)
 * для современных браузеров она просто вызывает Backbone.history.start(options);
 * */
import _ from '@rm/underscore';

const backboneInit = b => {
  _.extend(b.History.prototype, {
    universalStart: function(options) {
      if (!Modernizr.history) {
        options.pushState = false;
        options.silent = true;
        options.hashChange = true;

        b.history.start(options);
        var rootLength = b.history.options.root.length;
        var fragment = window.location.pathname.substr(rootLength);
        b.history.navigate(fragment, { trigger: true });
      } else {
        b.history.start(options);
      }
    },
  });

  // Заставляем роутер дописывать querystring при каждом navigate
  var oldNavigate = b.Router.prototype.navigate;
  b.Router.prototype.navigate = function(fragment, options) {
    if (fragment.indexOf('?') > -1 || (options && options.skipQueryString)) return oldNavigate.apply(this, arguments);

    fragment += window.location.search;

    return oldNavigate.call(this, fragment, options);
  };

  // Заполняем отсутсвующие ключи из source в data значениями undefined (для корректных событий change при .set())
  b.Model.prototype.getResetAttrs = function(model, data) {
    var source = model.toJSON();
    _.each(
      source,
      function(value, key) {
        if (_.isObject(source[key] && _.isObject(data[key]))) {
          data[key] = this.getResetAttrs(source[key], data[key]);
        }

        if (_.has(source, key) && !_.has(data, key)) {
          data[key] = undefined;
        }
      },
      this
    );

    return data;
  };

  return b;
};

export default backboneInit;
