<template>
  <div ref="scrollWrapper" class="resizable-scroll-wrapper">
    <div ref="contentWrapper" class="resizable-content-wrapper">
      <div ref="content" class="resizable-content">
        <slot></slot>
      </div>
    </div>
    <div ref="scroll" class="resizable-scroll"></div>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';
const SCROLL_GAP_START = 16;
const SCROLL_GAP_END = 16;

export default {
  props: {
    onPanelScroll: {
      type: Function,
      default: () => {},
    },

    onScrollRecalc: {
      type: Function,
      default: () => {},
    },

    scrollGapStart: {
      type: Number,
      default: SCROLL_GAP_START,
    },

    scrollGapEnd: {
      type: Number,
      default: SCROLL_GAP_END,
    },

    systemScroll: {
      type: Boolean,
      default: false,
    },

    savedScrollPosition: {
      type: Number,
      default: undefined,
    },
  },

  mounted: function() {
    this.resizableScroll = $(this.$refs.scrollWrapper)
      .RMScroll({
        $container: $(this.$refs.contentWrapper),
        $content: $(this.$refs.content),
        $handle: $(this.$refs.scroll),
        wheelScrollSpeed: 0.4,
        gap_start: this.scrollGapStart,
        gap_end: this.scrollGapEnd,
        systemScroll: this.systemScroll,
        onScroll: this.onPanelScroll,
        savedScrollPosition: this.savedScrollPosition,
      })
      .data('scroll');
    this.__scrollRecalc_debounced = _.debounce(
      _.bind(function() {
        this.resizableScroll.recalc();
        this.onScrollRecalc('control-resize');
      }, this),
      300
    );
  },

  methods: {
    recalcScroll: function() {
      this.__scrollRecalc_debounced();
    },
  },

  beforeUpdate: function() {
    if (this.savedScrollPosition === 0) {
      this.resizableScroll.clearScroll();
    }
  },
};
</script>
