/**
 * Конструктор для виджета текста
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import Colorbox from '../helpers/colorbox';
import templates from '../../../templates/constructor/controls/text_columns.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const TextColumns = ControlClass.extend({
  name: 'text_columns', // должно совпадать с классом вьюхи

  className: 'control text_columns',

  MAX_COLUMN_COUNT: 12,

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_columns'];

    this.initControl(params);
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-text_columns');
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.$panel.on('click', '.substract', this.substract);
    this.$panel.on('click', '.add', this.add);
    this.$panel.on('click', '.bg-color', this.toggleColorbox);
    this.$panel.on('click', this.closeColorBox);

    $(this.$(this.$panel.find('.gap'))).RMNumericInput({
      min: 0,
      max: 999,
      mouseDir: 'h',
      mouseSpeed: 1,
      onChange: this.onGapChanged,
    });

    this.colorbox = new Colorbox({ $parent: this.$('.colorbox_container'), type: 'small' });
    this.colorbox.on('colorchange', this.colorChanged);
    this.colorbox.on('opacitychange', this.opacityChanged);

    // первоначально проставляем состояние контрола в зависимости от текущего стиля выделения в редакторе
    if (this.blocks)
      this.updateState({
        column_count: this.blocks[0].model.get('column_count'),
        column_gap: this.blocks[0].model.get('column_gap'),
        bg_color: this.blocks[0].model.get('bg_color'),
        bg_opacity: this.blocks[0].model.get('bg_opacity'),
      });
  },

  unBindLogic: function() {
    this.$panel.off('click', '.substract', this.substract);
    this.$panel.off('click', '.add', this.add);
    this.$panel.off('click', '.bg-color', this.toggleColorbox);
    this.$panel.off('click', this.closeColorBox);

    this.colorbox && this.colorbox.off('colorchange', this.colorChanged);
    this.colorbox && this.colorbox.off('opacitychange', this.opacityChanged);
    this.colorbox && this.colorbox.destroy();
  },

  updateState: function(param) {
    if (param.column_count != undefined) {
      this.$panel.find('.number').text(param.column_count);
      this.$panel.toggleClass('one-column', param.column_count == 1);
      if (param.column_count > 1) {
        this.$panel.find('.gap, .grid').css('display', 'block');
      } else {
        setTimeout(
          _.bind(function() {
            this.$panel.find('.gap, .grid').css('display', 'none');
          }, this),
          500
        );
      }
    }

    if (param.column_gap != undefined) {
      this.$panel.find('.gap').val(param.column_gap);
    }

    if (param.bg_color != undefined && param.bg_opacity != undefined) {
      this.$('.bg-color .color').css({
        'background-color': '#' + param.bg_color,
        opacity: param.bg_opacity,
      });
    }
  },

  substract: function(e) {
    var num = this.$panel.find('.number').text() - 0;
    if (num == 1) return;
    num--;
    if (num < 1) num = 1; // защита на всякий случай

    var param = { column_count: num };
    this.updateState(param);
    if (this.blocks) this.blocks[0].trigger('changeColumns', param);
  },

  add: function(e) {
    var num = this.$panel.find('.number').text() - 0;
    if (num == this.MAX_COLUMN_COUNT) return;
    num++;
    if (num > this.MAX_COLUMN_COUNT) num = this.MAX_COLUMN_COUNT; // защита на всякий случай

    var param = { column_count: num };
    this.updateState(param);
    if (this.blocks) this.blocks[0].trigger('changeColumns', param);
  },

  onGapChanged: function($input, val) {
    var param = { column_gap: val };
    this.updateState(param);
    if (this.blocks) this.blocks[0].trigger('changeColumns', param);
  },

  toggleColorbox: function(e) {
    if (this.colorbox_visible) {
      this.closeColorBox();
      return;
    }

    this.colorbox_visible = true;

    this.$('.colorbox_container').fadeIn(200);

    // устанавливаем параметры в колорбоксе
    this.colorbox.setOpacity(this.blocks[0].model.get('bg_opacity'));
    this.colorbox.setColor(this.blocks[0].model.get('bg_color'));
  },

  closeColorBox: function(e) {
    if (e) {
      if ($(e.target).closest('.colorbox_container').length > 0) return;
      if ($(e.target).closest('.bg-color').length > 0) return;
    }

    this.colorbox_visible = false;
    this.$('.colorbox_container').fadeOut(200);
  },

  colorChanged: function(r, g, b, a) {
    // если у нас сейчас установлен цвет ffffff и прозрачность 0
    // и при этом юзер меняет цвет, то подразумеваем что он делает это в первый раз
    // и поэтому плавно устанавливаем прозрачность в 1, чтобы юзер понял что происходит
    // а то по дефолту прозрачность 0 и как цвет не меняй ничего не будет меняться в виджете визуально
    if (
      this.blocks[0].model.get('bg_color') == 'ffffff' &&
      this.blocks[0].model.get('bg_opacity') == 0 &&
      !this.firstColorChange
    ) {
      this.firstColorChange = true;
      this.colorbox.setOpacity(1, true);
      a = 1;
    }

    this.colorOpacityChanged(r, g, b, a);
  },

  opacityChanged: function(r, g, b, a) {
    this.colorOpacityChanged(r, g, b, a);
  },

  colorOpacityChanged: function(r, g, b, a) {
    var color = this.colorbox && this.colorbox.rgb2hex([r, g, b]),
      param = { bg_color: color, bg_opacity: a };

    this.updateState(param);

    if (this.blocks) this.blocks[0].trigger('changeColumns', param);
  },

  /**
   * Переопределяем реакцию закрытия паельки контрола
   */
  deselect: function() {
    if (this.colorbox_visible) this.closeColorBox();

    ControlClass.prototype.deselect.apply(this, arguments);
  },

  // переопределяем метод клика по иконке контрола
  onClick: function() {
    if (this.selected) {
      if (this.colorbox_visible) {
        this.closeColorBox();
        return;
      }
    }

    ControlClass.prototype.onClick.apply(this, arguments);
  },

  // переопределяем метод реакции на кнопку Esc
  onEscKey: function() {
    if (this.selected) {
      if (this.colorbox_visible) {
        this.closeColorBox();
        return;
      }
    }

    ControlClass.prototype.onEscKey.apply(this, arguments);
  },

  /**
   * Переопределяем функцию которая решает поглощает контрол событие или нет (обычно это событие deselect воркспейса)
   */
  canControlBeClosed: function() {
    if (this.colorbox_visible) {
      this.closeColorBox();
      return true;
    }

    return ControlClass.prototype.canControlBeClosed.apply(this, arguments);
  },
});

export default TextColumns;
