import $ from '@rm/jquery';
import Vue from 'vue';
import WidgetClass from '../widget';
import RmCookies from '../../../components/common/rm-cookies.vue';

const rmCookies = WidgetClass.extend({
  ignoreScale: true,

  render: function() {
    this.makeBox('widget-rm-cookies');

    const rmCookiesVue = Vue.extend(RmCookies);

    this.vue = new rmCookiesVue({
      el: $('<div>')
        .appendTo(this.$el)
        .get(0),
    });

    this.rendered = true;
    this.widgetIsLoaded();

    return this;
  },

  destroy: function() {
    this.vue && this.vue.$destroy();

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default rmCookies;
