/**
 * Модель и коллекция папок
 */
import Backbone from 'backbone';

export const FolderModel = Backbone.Model.extend({
  idAttribute: '_id',
  urlRoot: '/api/folders/',
});

export const FolderListCollection = Backbone.Collection.extend({
  model: FolderModel,
  getByUri: function(uri) {
    return this.findWhere({ uri: uri });
  },
});
