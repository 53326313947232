/**
 * Конструктор контрола иконок для шейпов
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlResizableClass from '../control-resizable';
import templates from '../../../templates/constructor/controls/shape_icon.tpl';

const ShapeIcon = ControlResizableClass.extend({
  name: 'shape_icon', // должно совпадать с классом вьюхи

  className: 'control shape_icon',

  MIN_PANEL_HEIGHT: 264,

  MAX_PANEL_HEIGHT: 640,

  SCROLL_GAP_START: Modernizr.isboxversion ? 8 : 0,
  SCROLL_GAP_END: 8,

  API_URL: '/api/authservice/noun?uri=',

  LIMIT_TO_PUBLIC_DOMAIN: false, // true для тестирования на бесплатном аккаунте
  DEFAULT_COLLECTION_ID: 1335, // Noun ID коллекции, которую нужно показывать по-умолчанию

  // это для сохранения сортировки списка стилей
  saveOnDestroy: true,
  saveOnDeselect: true,

  events: {
    'click .icon-item': 'onIconItemClick',
  },

  initialize: function(params) {
    this.template = templates['template-constructor-control-shape_icon'];
    this.templateItem = templates['template-constructor-control-shape_icon-item'];

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;
  },

  render: function() {
    ControlResizableClass.prototype.render.apply(this, arguments);
    this.$result = this.$('.panel .resizable-content');

    // Если есть кэшированные результаты поиска, восстанавливаем их
    if (window.prevSVGSearch) {
      this.nextPage = window.prevSVGSearch.nextPage;
      this.$('.search').val(window.prevSVGSearch.phrase);
      this.search_string = window.prevSVGSearch.phrase;
      this.drawSearchResults(window.prevSVGSearch);
      this.$('.loupe').addClass('clear');
    } else {
      this.getDefaultCollection();
    }
  },

  bindLogic: function() {
    this.$('.search').on('keyup', this.onSearchKeyUp);
    this.$('.loupe:not(.preloading)').on('click', this.onLoupeClick);
    this.listenTo(this.block.model, 'change:tp', this.onShapeTypeChange);
  },

  unBindLogic: function() {
    this.$('.search').off('keyup', this.onSearchKeyUp);
    this.$('.loupe').off('click', this.onLoupeClick);
    this.stopListening(this.block.model);
  },

  onSearchKeyUp: function(e) {
    if (e.keyCode !== $.keycodes.enter) {
      return;
    }

    this.doSearch();
  },

  onLoupeClick: function(e) {
    if (this.$('.loupe').hasClass('clear')) {
      this.clearSearch();
    } else {
      this.doSearch();
    }
  },

  doSearch: function() {
    var string = this.$('.search')
      .val()
      .trim();
    if (!string || this.search_string == string || this.requestInProgress) {
      return;
    }

    this.search_string = string;
    this.$result.empty();
    delete window.prevSVGSearch;
    this.$('.loupe').addClass('clear');
    // this.__scrollRecalc_debounced();

    this.nextPage = 1;

    this.getDataPage(this.nextPage);
  },

  clearSearch: function() {
    this.$('.loupe').removeClass('clear');
    delete window.prevSVGSearch;
    this.$('.search').val('');
    this.search_string = '';
    this.$result.empty();
    this.getDefaultCollection();
  },

  getDataPage: function(page) {
    var uri;

    if (!this.search_string || this.requestInProgress) {
      return;
    }

    uri = '/icons/' + this.search_string + '?page=' + page;
    if (this.LIMIT_TO_PUBLIC_DOMAIN) {
      uri += '&limit_to_public_domain=1';
    }

    this.requestIcons(
      uri,
      function(err, data) {
        if (err) {
          delete this.nextPage; // Удаляем номер следующей страницы. Нечего больше грузить
          return;
        }

        // Кэшируем результаты предыдущего поиска,
        // включая иконки, последнюю пэйджинированную страницу и строку поиска
        // FIXME: переделать на кэш внутри компонента, не надо патчить window
        window.prevSVGSearch = window.prevSVGSearch || { icons: [] };
        window.prevSVGSearch.icons = window.prevSVGSearch.icons.concat(data.icons);
        window.prevSVGSearch.phrase = this.search_string;
        window.prevSVGSearch.nextPage = this.nextPage;

        this.drawSearchResults(data);
      }.bind(this)
    );
  },

  requestIcons: function(uri, callback) {
    this.searchXhr && this.searchXhr.abort();
    this.searchInProgress = true;
    this.showSearchPreloader();

    this.searchXhr = $.ajax({
      method: 'GET',
      url: this.API_URL + encodeURIComponent(uri),
      dataType: 'json',
      success: function(data) {
        callback(null, data);
      },
      error: function(xhr) {
        callback(xhr);
      },
      complete: function() {
        this.searchInProgress = false;
        this.hideSearchPreloader();
      },
      context: this,
    });
  },

  getDefaultCollection: function() {
    this.drawSearchResults(this.defaultCollection);
  },

  drawSearchResults: function(data) {
    if (!(_.isArray(data.icons) && data.icons.length)) {
      delete this.nextPage; // Удаляем номер следующей страницы. Нечего больше грузить
      return;
    }

    _.each(
      data.icons,
      function(icon) {
        this.$result.append(this.templateItem({ icon: icon }));
      }.bind(this)
    );

    this.__scrollRecalc_debounced();
  },

  // расширяем метод который срабатывает при открытии панели контрола
  select: function() {
    ControlResizableClass.prototype.select.apply(this, arguments);

    _.delay(
      function() {
        this.$('.search')
          .focus()
          .select();
      }.bind(this),
      400
    );
    // PreloadDesignImages('controls-text_styles');
  },

  save: function() {
    this.model.save();
    ControlResizableClass.prototype.save.apply(this, arguments);
  },

  onIconItemClick: function(e) {
    var $item = $(e.currentTarget),
      $icon = $item.find('.noun-icon'),
      noun_id = $icon.attr('data-id'), // Урл иконки noun
      rm_id = $icon.attr('data-rm_id'), // Урл нашей иконки
      icon_url = $icon.attr('data-url');

    if (this.$('.resizable-content').hasClass('preloading')) {
      return;
    }

    this.hideIconPreloader();
    this.showIconPreloader($item);

    this.block.getIconSVG(
      noun_id || rm_id,
      icon_url,
      function(err, data) {
        var set;
        this.hideIconPreloader();

        if (err) {
          return;
        }

        this.$('.icon-item').removeClass('selected');
        $item.addClass('selected');

        set = {
          noun_url: icon_url,
        };

        // При задании одного типа айдишника обязательно сбрасывать другой
        // т.к. наличие или отсутствие соотв. айдишника и является признаком, чья иконка - науна или наша
        if (noun_id) {
          _.extend(set, { noun_id: noun_id, rm_id: null });
        } else if (rm_id) {
          _.extend(set, { noun_id: null, rm_id: rm_id });
        }

        this.block.model.set(set);
      }.bind(this)
    );
  },

  onPanelScroll: function(scroll_data) {
    if (!this.selected) {
      return;
    }

    // если до конца прокрутки осталось менее 32 пикселей и есть еще что грузить
    if (
      scroll_data.content_size - scroll_data.container_size - scroll_data.scroll_pos < 32 &&
      this.nextPage &&
      !this.searchInProgress
    ) {
      this.getDataPage(++this.nextPage);
    }
  },

  abortRequests: function() {
    this.searchXhr && this.searchXhr.abort();
    this.searchInProgress = false;
  },

  onShapeTypeChange: function() {
    if (this.block.model.get('tp') !== 'icon') {
      this.abortRequests();
    }
  },

  showSearchPreloader: function() {
    this.$('.loupe').addClass('preloading');
  },

  hideSearchPreloader: function() {
    this.$('.loupe').removeClass('preloading');
  },

  showIconPreloader: function($icon) {
    $icon.append($('<div class="rmpreloader"><div class="arc"></div></div>'));
    this.$('.resizable-content')
      .add($icon)
      .addClass('preloading');
  },

  hideIconPreloader: function($icon) {
    this.$('.resizable-content')
      .find('.rmpreloader')
      .remove();
    this.$('.resizable-content')
      .add(this.$('.icon-item'))
      .removeClass('preloading');
  },

  destroy: function() {
    this.abortRequests();
    ControlResizableClass.prototype.destroy.apply(this, arguments);
  },
});

export default ShapeIcon;
