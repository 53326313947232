/**
 * Набор полезных функций для работы с текстом и ...
 */

import $ from '@rm/jquery';
import _ from '@rm/underscore';
import InitUtils from './init-utils';
import Vue from 'vue';
import Device from './device';

var filters = {
  isURL: function(text) {
    return /^http(s?)\:\/\//i.test(text); // eslint-disable-line
  },
};

_.keys(filters).forEach(function(key) {
  Vue.filter(key, filters[key]);
});

/**
 * Глобальные константы
 */
export const Constants = _.extend(
  {
    UPLOAD_IMAGE_SIZE_LIMIT: 6291456, // Максимальный размер для загружаемых картинок
    MSG_UPLOAD_IMAGE_SIZE_ERROR: 'File size should be less than 6 Mb.',
    MSG_UPLOAD_IMAGE_SUPPORTED_ERROR: 'We support only JPG, GIF, PNG, SVG and BMP picture formats.',
    MSG_UPLOAD_ONLY_SINGLE_FILE: 'We support only 1 file upload via drop on workspace',

    THUMB_SIZE: 48, // Размер дефолтной генерируемой thumb после аплоада
    BG_EFFECT_THUMBSIZE: 40, // Размер thumb для BG-Widget

    UPLOAD_IMAGE_FORMATS: 'jpeg|jpg|png|gif|bmp|svg\\+xml', // Поддерживаемые на данный момент нами форматы файлов (для RegExp)

    EMBED_SCRIPT_INIT: window.ServerData.config.readymag_host + '/specials/assets/embed_init.js',
    EMBED_SCRIPT_MAIN: window.ServerData.config.readymag_host + '/specials/assets/embed_main.js',

    IS_LIVE: !!(window.ServerData && window.ServerData.stripe_live),
    IS_FILE_PROTOCOL: window.location.protocol == 'file:',
    AVAILABLE_TEXT_TAGS: ['p', 'h1', 'h2', 'h3', 'h4'],

    //		TRANSFORM_STYLE_NAME: 					Modernizr.prefixed('transform'), //если вообще не поддерживается то вернет false
    //		TRANSITION_STYLE_NAME: 					Modernizr.prefixed('transition')

    // E-COMMERCE
    ecommerceCartBlockName: 'ecommercecart',
    addToCartBlockName: 'addtocart',
    environment: {
      constructor: 'constructor',
      preview: 'preview',
      viewer: 'viewer',
    },
  },
  window.ServerData.config
);

// delete window.ServerData.config

export const Utils = {
  picSizes: [256, 304, 512, 608, 1024], // размеры у скриншотилки

  // проверка что страница сейас отскейлена нативным зумом на ипаде-ифоне
  isPageNativelyScaled: function() {
    return window.innerWidth != document.documentElement.clientWidth;
  },

  /**
   * 		Параметры функции:
      a - множественное число (родительный падеж)
      b - единственное число (именительный падеж)
      c - множественное число (именительный падеж)
      s - количество
    */
  declination: function(a, b, c, s, isRus) {
    var words = [a, b, c];
    var index = s % 100;

    if (index >= 11 && index <= 14) {
      index = 0;
    } else {
      index = (index %= 10) < 5 ? (index > 2 ? 2 : index) : 0;
    }

    // Упрощаем правило для английского
    if (!isRus) {
      index = s == 1 ? 1 : 0;
    }

    return words[index];
  },

  queryUrlGetParam: function(variable, url) {
    try {
      var q = url ? url.split('?')[1] : location.search.substring(1);
      var v = q ? q.split('&') : [];
      for (var i = 0; i < v.length; i++) {
        var p = v[i].split('=');
        if (p[0] == variable)
          if (p.length > 1) {
            return decodeURIComponent(p[1]);
          } else {
            return true; // если просто параметр без значения
          }
      }
    } catch (e) {
      console.log(e);
    }
  },

  // функция для автоматической прокрутки экрана когда мышь доходит до его края сверху или снизу при зажатой левой кнопке
  // используется в конструкторе когда мы перетаскиваем или ресайзим блоки
  autoWindowScroll: function(e, $scrollBox, cb) {
    this.autoWindowScrollClear();

    var border = 10,
      scroll_speed = 0,
      timeout = 100,
      windowH = $(window).height();

    if (e.pageY < border) scroll_speed = -Math.abs(Math.floor((border - e.pageY) * 3));
    if (e.pageY > windowH - border) scroll_speed = Math.abs(Math.floor((border - (windowH - e.pageY)) * 3));

    scroll_speed = Math.max(Math.min(20, scroll_speed), -20);

    if (scroll_speed != 0) {
      $scrollBox.scrollTop($scrollBox.scrollTop() + scroll_speed);
      RM.common.autoWindowScrollTimeout = setTimeout(function() {
        cb && cb();
      }, timeout);
    }
  },

  autoWindowScrollClear: function() {
    clearTimeout(RM.common.autoWindowScrollTimeout);
  },

  isValidEmail: function(email, strict) {
    if (!strict) email = email.replace(/^\s+|\s+$/g, '');
    return /^([a-z0-9_\-]+[\.\+])*[a-z0-9_\-]+@([a-z0-9][a-z0-9\-]*[a-z0-9]\.)+[a-z]{2,}$/i.test(email);
  },

  isValidEmailLink: function(link) {
    return Utils.isValidEmail(link.split('?')[0]);
  },

  // color это шестизначный hex, opacity: 0..1
  getRGBA: function(color, opacity) {
    var col = '';
    if (color) {
      var rbg = [
        parseInt(color.substring(0, 2), 16),
        parseInt(color.substring(2, 4), 16),
        parseInt(color.substring(4, 6), 16),
      ];

      if (opacity > 0.99) col = 'rgb(' + rbg[0] + ',' + rbg[1] + ',' + rbg[2] + ')';
      else col = 'rgba(' + rbg[0] + ',' + rbg[1] + ',' + rbg[2] + ', ' + opacity + ')';
    }

    return col;
  },

  // RFC4122 version 4 compliant solution
  // offsetting the first 13 hex numbers by a hex portion of the timestamp
  // in case of Math.random is on the same seed
  generateUUID: function() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  },

  escapeSpecial: function(str, wrapToSingleQuotes) {
    // экранируя спец символы, потому что мы будем использовать эту строку в регулярке
    str = (str + '').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, '\\$1');

    // если есть строка и задан флаг wrapToSingleQuotes, то оборачиваем строку в одинарные кавычки
    // это чтобы использовать в jQuery :contains('...')
    if (str && wrapToSingleQuotes) str = "'" + str + "'";

    return str;
  },

  // функция находит урлы и email в строке и превращает их в линки <a></a>
  scanForURIs: function(str, cl, collapseToHost) {
    function replacerURI(str, p1, p2, p3, p4, p5) {
      var str_uri = str;
      if (p1 == undefined || p1 == '') str_uri = 'http://' + str_uri;
      var str_view = p2;
      if (p3 != undefined && p3 != '') str_view += '.' + p3;
      id++;
      if (!collapseToHost) {
        str_view = str_uri;
        str_view = str_view.replace(/^https?:\/\//, '');
      }
      data[id] =
        '<a ' +
        (cl ? 'class = "' + cl + '"' : '') +
        ' href="' +
        str_uri +
        '" target="_blank" title="' +
        str_uri +
        '">' +
        str_view +
        '</a>';
      return 'INNER_TMP_BLOCK_' + id + '_INNER_TMP_BLOCK';
    }

    function replacerEMAIL(str) {
      id++;
      data[id] = '<a ' + (cl ? 'class = "' + cl + '"' : '') + ' href="mailto:' + str + '">' + str + '</a>';
      return 'INNER_TMP_BLOCK_' + id + '_INNER_TMP_BLOCK';
    }

    function replacerALL(str, p1) {
      return data[p1];
    }

    var data = [],
      id = 0;

    str += ' ';
    str = str.replace(
      /\b([a-z0-9_\.-]+)@([\da-z\.-]+)\.(biz|com|edu|gov|net|org|us|ru|ua|uk|su|se|co|no|jp|it|in|il|gb|fr|fi|es|de|cz|ch|ca|by|at|au)/gim,
      replacerEMAIL
    );
    str = str.replace(
      /\b(https?:\/\/)?([\da-z\.-]+)\.(biz|com|edu|gov|net|org|us|ru|ua|uk|su|se|co|no|jp|it|in|il|gb|fr|fi|es|de|cz|ch|ca|by|at|au)(\/[^\s\(\)\[\]\{\}\<\>]*)*/gim,
      replacerURI
    );
    str = str.replace(/INNER_TMP_BLOCK_([\d]+)_INNER_TMP_BLOCK/gim, replacerALL);

    return str;
  },

  // escaping all tags, settings lines breakes to <br/>
  // if opts.detectLinks == true  transforming URIs and EMAILs to links
  plainTextToHtml: function(str, opts) {
    str = str || '';

    str = str.replace(/\&/gim, '&amp;');
    str = str.replace(/\</gim, '&lt;');
    str = str.replace(/\>/gim, '&gt;');

    str = str.replace(/\n/gim, '<br/>');
    str = str.replace(/\s\s/gim, ' &nbsp;');

    if (opts && opts.detectLinks) {
      str = Utils.scanForURIs(str);
    }

    return str;
  },

  // Возвращает подходящий размер скриншота в соответствии с размера скриншотилки
  // Всегда выдаст существующий размер
  // Если упущены размеры - добавляйте
  screenshotSize: function(size) {
    if (Modernizr.retina) size *= 2;

    if (this.picSizes.indexOf(size) == -1) {
      size = _.filter(this.picSizes, function(s) {
        return s > size;
      })[0];
    }

    if (this.picSizes.indexOf(size) == -1) {
      console.error('screenshot size not found! size: ' + size);
      return 512; // по дефолту будем возвращать 512 если запросили что-то совсем странное
    }
    return size;
  },

  // 'file.ext' -> 'file_component.ext'
  addFilenameComponent: function(path, component) {
    var arr = path.split('.');
    if (arr.length > 1) {
      arr[arr.length - 2] += '_' + component; // все что до расширения файла
      return arr.join('.');
    }

    return (path += '_' + component);
  },

  /**
   * Возвращает части заданного урла в виде объекта
   * @method URLParts
   * @param url {String} - полный или частичный URL
   * @return {Object} Объект с разными частями урла в виде свойств
   */
  URLParts: function(url) {
    var pattern = /(.+:\/\/)?([^\/]+)(\/.*)*/i;
    var arr = pattern.exec(url);
    arr = arr || [];
    return {
      url: arr[0] || '',
      protocol: arr[1] || '',
      hostname: arr[2] || '',
      path: arr[3] || '',
    };
  },

  /**
   * Не дает вводить не ASCII символы в HTML input
   * @method filterNonAscii
   * @param $input {jQuery} - HTML input, обернутый в jQuery
   * @return {Boolean} Возвращает true, если были отфильтрованы символы
   */
  filterNonAscii: function($input) {
    if (!$input || !$input.val) {
      return;
    }

    var val = $input.val();
    if (/[^\x00-\x7f]/.test(val)) {
      val = val.replace(/[^\x00-\x7f]/g, '');
      $input.val(val);
      return true;
    }

    return false;
  },

  applyTransform: function($obj, str) {
    $obj.css({
      '-webkit-transform': str,
      transform: str,
    });
  },

  // для заданного элемента возвращает значения 6 переменных матрицы трансформаии,
  // используется еще в плагине /libs/jquery.rmswipe.js
  getCurrentTranslate: function(obj) {
    var cs = window.getComputedStyle(obj, null);

    var tr = cs.getPropertyValue('-webkit-transform') || cs.getPropertyValue('transform');

    if (!tr || tr == 'none') return [0, 0];

    // getComputedStyle всегда возвращает матрицу трансформации,
    // где учтены уже повороты, скейлы, сдвиги и skew
    var res = tr.split('(')[1],
      res = res.split(')')[0],
      res = res.split(',');

    return [res[4] - 0, res[5] - 0]; // приводим к числу из строки
  },

  applyTransition: function($obj, str) {
    $obj.css({
      '-webkit-transition': str.replace('transform', '-webkit-transform'),
      transition: str,
    });
  },

  waitForTransitionEnd: function($obj, safeTimeout, animationName, cb) {
    return this.animationTransitionEndHandler('transition', $obj, safeTimeout, animationName, cb);
  },

  waitForAnimationEnd: function($obj, safeTimeout, property, cb) {
    return this.animationTransitionEndHandler('animation', $obj, safeTimeout, property, cb);
  },

  // грамотный код ожидания transition/animation end
  // учитывает:
  // -что событий окончания несколько с вендорными префиксами, навешивает только на один
  // -что собыие баблится и может придти от чайлда, чего нам не надо
  // -что событие может не выстрелить, тогда вызовет по таймеру безопасности
  // -что для мультипл транзишенов нам надо отловить окончание тодько нашего транзишена, а не всех подряд свойств
  animationTransitionEndHandler: function(type, $obj, safeTimeout, property, cb) {
    $obj = $obj instanceof jQuery ? $obj : $($obj);

    // это код который рекомендует использовать сам модернизр
    var transEndEventNames = {
        WebkitTransition: 'webkitTransitionEnd',
        MozTransition: 'transitionend',
        OTransition: 'oTransitionEnd',
        msTransition: 'MSTransitionEnd',
        transition: 'transitionend',
      },
      animEndEventNames = {
        WebkitAnimation: 'webkitAnimationEnd',
        MozAnimation: 'animationend',
        OTransition: 'oAnimationEnd',
        msTransition: 'MSAnimationEnd',
        animation: 'animationend',
      };

    var transEndEventName =
      type === 'transition'
        ? transEndEventNames[Modernizr.prefixed('transition')]
        : animEndEventNames[Modernizr.prefixed('animation')];

    var transitionEndCallback = function(e) {
        // проверяем что событие пришло непосредственно от того, на кого навешивали
        // например в случае тулбара был ньюанс, сто сначала срабатывал
        // transition end от самой кнопки меню изнутри тулбара, на которой был транзишен на ховер
        // transition end срабатываем в конце КАЖДОГО анимируемого свойства, а также для чайлдов элемента
        // если это мультипл событие то нам надо сомтреть какое именно свойство закончило анимироваться
        // и ждать только того которое нам нужно
        if (e) {
          var prop =
            type === 'transition'
              ? e.originalEvent.propertyName.toLowerCase()
              : e.originalEvent.animationName.toLowerCase();

          if (type === 'transition' && !$(e.target).is($obj)) return;
          if (prop.indexOf(property) === -1) return;
        }

        // сбрасываем обработчики transition end их много может идти еще
        $obj.off(transEndEventName, transitionEndCallback);

        // сбрасываем таймер безопасности
        clearTimeout(transitionSafeTimeout);

        cb();
      },
      resetAllHandlers = function() {
        // сбрасываем обработчики transition end
        $obj.off(transEndEventName, transitionEndCallback);

        // сбрасываем таймер безопасности
        clearTimeout(transitionSafeTimeout);
      };

    // ожидаем событие окончания анимации, его надо навесить обязательно до applyTransform && applyTransition
    // навешивать на все события сразу (как делают многие в этих ваших энторнетах) не стоит
    // например на iOS выстреливают сразу два события webkitTransitionEnd и transitionend
    // а .on вызовется для обоих, и так хватает проблем с тем, что Transition End вызывается отдельно для каждого анимируемого свойства
    $obj.on(transEndEventName, transitionEndCallback);

    // таймер безопасности, на случай если transitionend вообще не выстрелит (есть условия при которых это происходит)
    var transitionSafeTimeout = setTimeout(transitionEndCallback, safeTimeout);

    return resetAllHandlers;
  },

  /**
   * Показывает элемент, добавляя и убирая css-классы по той же логике, как и у vue.js
   * Нужен на время переходного периода, когда одни и те же элементы могут быть vue-компонентами и backbone-компонентами
   * @param {jQuery} $element
   * @param {String} name Имя (префикс css-класса)
   */
  vueTransitionsShow: function($element, name) {
    var enterClass = name + '-enter';
    var enterActiveClass = name + '-enter-active';
    $element.addClass(enterClass);
    window.requestAnimationFrame(function() {
      $element.removeClass(enterClass);
      $element.addClass(enterActiveClass);
      Utils.waitForTransitionEnd($element, 200, 'opacity', function() {
        $element.removeClass(enterActiveClass);
      });
    });
  },

  /**
   * Прячет элемент, добавляя и убирая css-классы по той же логике, как и у vue.js
   * Нужен на время переходного периода, когда одни и те же элементы могут быть vue-компонентами и backbone-компонентами
   * @param {jQuery} $element
   * @param {String} name Имя (префикс css-класса)
   * @returns {Promise}
   */
  vueTransitionsHide: function($element, name) {
    var leaveToClass = name + '-leave-to';
    var leaveActiveClass = name + '-leave-active';
    $element.addClass(leaveToClass + ' ' + leaveActiveClass);
    return new window.Promise(function(resolve) {
      Utils.waitForTransitionEnd($element, 200, 'opacity', resolve);
    }).then(function() {
      $element.removeClass(leaveToClass + ' ' + leaveActiveClass);
    });
  },

  // Получаем css для виджетов, спозиционированных относительно какой-либо точки
  // похожий код есть в widgets.applyTransformations
  // scale это для вьювера под мобильными девайсами, где мы скейлим контент
  // с учетом этого параметра фиксед располагается так, чтобы после того как ему применят трансформ с этим скейлом он встал где надо
  getFixedPositionCSS: function(position, params, scale) {
    var x = params.left * scale,
      y = params.top * scale,
      w = params.width,
      h = params.height,
      dw2 = (w * (1 - scale)) / 2,
      dh2 = (h * (1 - scale)) / 2,
      css = { left: '', top: '', bottom: '', right: '', 'margin-left': '', 'margin-top': '' };

    if (!position) return _.extend({}, css, params);

    if (position != 'c') {
      if (position.indexOf('n') > -1) css.top = y - dh2;
      if (position.indexOf('w') > -1) css.left = x - dw2;
      if (position.indexOf('e') > -1) css.right = x - dw2;
      if (position.indexOf('s') > -1) css.bottom = y - dh2;
    }

    if (['n', 'c', 's'].indexOf(position) > -1) {
      css['left'] = '50%';
      css['margin-left'] = -w / 2 + x;
    }

    if (['w', 'c', 'e'].indexOf(position) > -1) {
      css['top'] = '50%';
      css['margin-top'] = -h / 2 + y;
    }

    return css;
  },

  /**
   * Возвращает бокс для fixed position, такой же как возвращал бы getBoundingClientRect()
   * (но getBoundingClientRect() можно вызывать только на видимом элементе)
   * @param {String} position Например, w или se
   * @param {Object} dimensions {x: <Number>, y: <Number>, w: <Number>, h: <Number>}
   * @param {Number} scale Масштаб
   * @param {Object} containerBox {width: <Number>, height: <Number>}
   * @returns {{left: number, top: number, width: number, height: number}}
   */
  getFixedPositionBox: function(position, dimensions, scale, containerBox) {
    scale = scale || 1;
    var x = dimensions.x * scale,
      y = dimensions.y * scale,
      w = dimensions.w * scale,
      h = dimensions.h * scale,
      box = { left: x, top: y, width: w, height: h };

    if (!position) {
      return box;
    }

    if (position !== 'c') {
      if (position.indexOf('s') !== -1) {
        box.top = containerBox.height - y - h;
      }
      if (position.indexOf('e') !== -1) {
        box.left = containerBox.width - x - w;
      }
    }
    if (['n', 'c', 's'].indexOf(position) !== -1) {
      box.left = containerBox.width / 2 - w / 2 + x;
    }

    if (['w', 'c', 'e'].indexOf(position) !== -1) {
      box.top = containerBox.height / 2 - h / 2 + y;
    }
    box.bottom = box.top + box.height;
    box.right = box.left + box.width;
    return box;
  },

  // Округляет число до заданного кол-ва десятичных цифр только если это требуется
  // 5 -> 5
  // 5.8 -> 5.8
  // 5.823 - 5.82
  decimals: function(num, digits) {
    var multiplier = Math.pow(10, digits);
    return Math.round(num * multiplier) / multiplier;
  },

  // Забирает фокус у чего угодно (например iframe) и отдает текущему документу
  getFocusBack: function() {
    // ни в коем случае не забирать фокус если у нас мобильное устройство
    // во-первых, там это не нужно вовсе, клавиатура не задействована
    // во-вторых, это приводит к багам, например в слайдшоу при переходе в режим фулскрина (не настоящего, а просто на все боди) у нас из-за этого кода срабатывал аппаратный зум
    if (!Device.isDesktop) return;

    $('<input style="position: absolute;left: -999px;" type="text"/>')
      .appendTo('body')
      .css({ top: $(window).scrollTop() })
      .focus()
      .remove();
  },

  hex2rgb: function(param) {
    if (!param) {
      return;
    }
    return [
      parseInt(param.substring(0, 2), 16),
      parseInt(param.substring(2, 4), 16),
      parseInt(param.substring(4, 6), 16),
    ];
  },

  rgba2hex: function(param) {
    if (!param) {
      return;
    }

    var rgb = param.match(/^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*(\d*)\.?(\d*)\s*)?\)$/);
    return (
      '#' +
      ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
      ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
      ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
    );
  },

  rgba2alpha: function(param) {
    if (!param) {
      return;
    }

    var rgb = param.match(/^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*(\d*)\.?(\d*)\s*)?\)$/);
    return parseFloat(rgb[4].slice(1));
  },

  // грузит текущего залогиненого юзера через ифрейм с my.readymag.com
  loadLoggedUser: function(callback) {
    // для Cypress + Basic Auth не создаем iframe, чтобы избежать браузерного окна с именем/паролем
    if (window.Cypress && window.Cypress.env('BASICAUTH_USER')) {
      return callback && callback();
    }

    if (RM.common.isDownloadedSource && !RM.common.homepageRewrite) {
      return callback && callback();
    }

    if (window.ServerData && window.ServerData.me && window.ServerData.me.user) {
      return callback && callback();
    }

    Utils.__loadLoggedUserCallbackStack = Utils.__loadLoggedUserCallbackStack || [];
    if (callback && typeof callback === 'function') {
      Utils.__loadLoggedUserCallbackStack.push(callback);
    }

    // выходим при повторных вызовах
    if (Utils.__loadLoggedUserLoadStarted) return;
    Utils.__loadLoggedUserLoadStarted = true;

    // слушаем событие postMessage от ифрейма который скажет данные о залогиненом пользователе
    $(window).on(
      'message',
      _.bind(function(e) {
        e = e.originalEvent || {};

        if (e.origin == Constants.readymag_auth_host) {
          if (typeof e.data !== 'string') {
            return;
          }
          var data = JSON.parse(e.data);
          if (data.event != 'user') return;

          var user = data.message;
          // сохраняем данные о текущем юзере туда где они обычно лежат
          if (window.ServerData) window.ServerData.me = { user: user };

          // callback && callback(user)
          for (var i = 0; i < Utils.__loadLoggedUserCallbackStack.length; i++) {
            Utils.__loadLoggedUserCallbackStack[i].call(this, user);
          }

          Utils.__loadLoggedUserCallbackStack = null;
          Utils.__loadLoggedUserLoadStarted = null;

          $frame.remove();
        }
      }, this)
    );

    // создаем ифрейм через который мы будем получать данные о текущем пользователе, если он залогинен на РМ
    // потом ждем пока ифрейм загрузиться

    var $frame = $('<iframe>')
      .attr('width', '0')
      .attr('height', '0')
      .css({ position: 'absolute', top: '-999px' })
      .on('load', function() {
        // просим ифрейм сказать нам данные по залогиненому юзеру
        // это нужно для того, чтобы код в ифрейме знал кому отсылать данные о юзере (e.source.postMessage...)
        $frame[0].contentWindow.postMessage('GetLoggedUser', '*');
      })
      .attr('src', Constants.readymag_auth_host + '/get_user_cookies.' + Date.now()) // надо делать после установки обработчика .on('load'...
      .appendTo('body');
  },

  selectProtocol: function(url) {
    if (!url || !url.length) return url;

    if (url.indexOf('//') == 0 && Constants.IS_FILE_PROTOCOL) url = 'http:' + url;

    return url;
  },

  /**
   * Посылает запрос на обновления кэша url в facebook graph
   * @url url, кэш которого надо обновить
   */
  _sendFBGraphRequest: function(upd_url) {
    $.ajax({
      type: 'GET',
      url: 'https://graph.facebook.com/?id=' + encodeURIComponent(upd_url) + '&scrape=true&method=post',
      success: function(result) {},
    });
    // 	$.ajax({
    // 		type: 'POST',
    // 		url: 'https://graph.facebook.com/',
    // 		data: {
    // 			id: upd_url,
    // 			scrape: true
    // 		},
    // 		success: function(result) {
    //
    // 		}
    // 	});
  },

  /**
   * Обновляет кэш всех страниц мэга по прямым и пользовательским ссылкам
   * @data объект, возвращаемый запросом к /api/mag/fbcache/:mag_numid
   */
  facebookGraphRefresh: function(data) {
    var RMDomain = Constants.readymag_host || window.location.origin,
      magDomain,
      magUserDomain,
      magMainUri,
      domain;

    // основная ссылка readymag.com
    magMainUri = RMDomain + '/' + data.user_uri + '/' + data.mag_uri + '/';
    this._sendFBGraphRequest(magMainUri);

    // прямая ссылка на мэг по num_id на readymag.com
    var magMainNumid = RMDomain + '/' + data.mag_numid + '/';
    this._sendFBGraphRequest(magMainNumid);

    // если привязан домен
    if (data.mag_domain) {
      magDomain = 'http://' + data.mag_domain + '/';
      this._sendFBGraphRequest(magDomain);
    }
    // если у пользователя есть домен
    else if (data.user_domain) {
      magUserDomain = 'http://' + data.user_domain + '/' + data.mag_uri + '/';
      this._sendFBGraphRequest(magUserDomain);
    }

    var pages = data.pages;

    if (pages.length == 0) return;

    domain = magDomain || magUserDomain || RMDomain + '/';

    for (var page in pages) {
      page = pages[page];
      var pageCanonicalUrl = RMDomain + 'p' + page.num_id + '/';
      this._sendFBGraphRequest(pageCanonicalUrl);
    }
  },

  simulateEvent: function(element, eventName) {
    if (!element) return;

    var eventMatchers = {
      HTMLEvents: /^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
      MouseEvents: /^(?:click|dblclick|mouse(?:down|up|over|move|out))$/,
    };

    var defaultOptions = {
      pointerX: 0,
      pointerY: 0,
      button: 0,
      ctrlKey: false,
      altKey: false,
      shiftKey: false,
      metaKey: false,
      bubbles: true,
      cancelable: true,
    };

    var options = extend(defaultOptions, arguments[2] || {});
    var oEvent,
      eventType = null;

    for (var name in eventMatchers) {
      if (eventMatchers[name].test(eventName)) {
        eventType = name;
        break;
      }
    }

    if (!eventType) throw new SyntaxError('Only HTMLEvents and MouseEvents interfaces are supported');

    if (document.createEvent) {
      oEvent = document.createEvent(eventType);
      if (eventType === 'HTMLEvents') {
        oEvent.initEvent(eventName, options.bubbles, options.cancelable);
      } else {
        oEvent.initMouseEvent(
          eventName,
          options.bubbles,
          options.cancelable,
          document.defaultView,
          options.button,
          options.pointerX,
          options.pointerY,
          options.pointerX,
          options.pointerY,
          options.ctrlKey,
          options.altKey,
          options.shiftKey,
          options.metaKey,
          options.button,
          element
        );
      }
      element.dispatchEvent(oEvent);
    } else {
      options.clientX = options.pointerX;
      options.clientY = options.pointerY;
      var evt = document.createEventObject();
      oEvent = extend(evt, options);
      element.fireEvent('on' + eventName, oEvent);
    }
    return element;

    function extend(destination, source) {
      for (var property in source) destination[property] = source[property];
      return destination;
    }
  },

  // возващает индекс юзера для АB тестирования
  // т.е. идентифицирует юзера и говорит какой индекс он имеет при AB, ABC, ABCD, и ABCDE тестировании (индексы идут от 0 до 1..4)
  getTestIndexes: function() {
    // берем индексы из кэша
    if (RM.common.rm_test) return RM.common.rm_test;

    // настройки идентификаторов юзеров
    // на данный момент мы выставили что можем тестировать AB, ABC, ABCD или ABCDE варианты
    // если в будущем потребуется больше - просто надо увеличить testsMax
    // но особо много лучше не делать
    // поскольку все эти индексы также валятся в микспанел чтобы потом собственно можно было сравнивать варианты
    var testsMin = 2,
      testsMax = 5,
      res = {},
      i,
      t;

    // по дефолту заполняем значениями -1 чтобы знать что юзер не может быть определен
    for (i = testsMin; i <= testsMax; i++) res['_test_' + i] = -1;

    if (Modernizr.localstorage) {
      for (i = testsMin; i <= testsMax; i++) {
        t = '_test_' + i;
        res[t] = localStorage.getItem('rm_test_' + i);

        // проверяем что полученная строка число, а не пуcтота или undefined или null
        // и что ее значение лежит в правильном диапазоне
        if (parseInt(res[t]) != res[t] || res[t] < 0 || res[t] >= i) {
          res[t] = Math.floor(Math.random() * i);
          localStorage.setItem('rm_test_' + i, res[t]);
        }

        res[t] -= 0; // приводим в number
      }
    }

    // кешируем индексы
    RM.common.rm_test = res;

    return res;
  },

  // Устанавливает размер инпута точно по размеру введенного текста.
  // используется как минимум в constructor/pages-panel-contents.js
  // и в constructor/blocks/button.js.
  setInputSize: function($input, maxwidth, val) {
    var $tmp = $(
      '<div style="position:absolute; left:-9999px; right:auto; margin:0; white-space:pre; width:auto"></div>'
    ).appendTo($input.parent());
    $tmp[0].className = $input[0].className;
    $tmp.text(val || $input.val());
    var w = $tmp.width();

    // Поскольку поле ввода позиционируется по центру, нам нужна четная ширина
    // иначе сам блок при нечетной располагался на нецелых границах и все рендерилось некрасиво, в частности курсор.
    // Если устанавливать ширину меньше 1, происходит полная херня, инпут теряет фокус, а нам этого совсем не надо
    // это уличная магия
    w = Math.ceil(w / 2) * 2 + 2;

    if (maxwidth && w > maxwidth) {
      w = maxwidth;
    }

    $input.width(w);
    $tmp.remove();
  },

  // Универсально управляет листенерами видимости страницы. Пример использования:
  // utils.PageVisibilityManager.addEventListener(function() {
  // 	console.log('Page is hidden?: ' + utils.PageVisibilityManager.isPageHidden());
  // });
  PageVisibilityManager: (function() {
    // Set the name of the hidden property and the change event for visibility
    var hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.mozHidden !== 'undefined') {
      hidden = 'mozHidden';
      visibilityChange = 'mozvisibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    var add = function(callback) {
      if (!hidden) {
        return false;
      }
      document.addEventListener(visibilityChange, callback);
    };

    var remove = function(callback) {
      if (!hidden) {
        return false;
      }
      document.removeEventListener(visibilityChange, callback);
    };

    var isHidden = function() {
      return document[hidden];
    };

    return {
      addEventListener: add,
      removeEventListener: remove,
      isPageHidden: isHidden,
    };
  })(),

  getCookie: function(name) {
    var matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  },

  deleteCookie: function(name, opts) {
    opts = opts || {};

    // Если домен не задан, берем наш корневой домен, чтобы кука наверняка удалилась
    var domain = Constants.readymag_host.replace(/https?:\/\//i, '');
    domain = domain.replace('/', '');

    opts.domain = opts.domain || domain;
    document.cookie = name + '=; Path=/; domain=' + opts.domain + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },

  createCookie: function(name, value, days) {
    days = days || 100 * 365;
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = '; expires=' + date.toGMTString();

    var domain;
    domain = Constants.readymag_host.replace(/https?:\/\//i, '');
    domain = domain.replace('/', '');

    document.cookie = name + '=' + value + expires + '; path=/; domain=.' + domain;
  },

  isMongoObjectId: function(str) {
    var obj_id_regexp;
    obj_id_regexp = /^[0-9a-fA-F]{24}$/;
    return obj_id_regexp.test(str);
  },

  setNoTransitions: function(state) {
    state = !!state || state === undefined;
    Constants.noanimations = state;
    $('html').toggleClass('notransitions');
    return 'Transitions are ' + (state ? 'disabled' : 'enabled');
  },

  // Отсылает единичное кастомное событие в нашу аналитику через Measurement Protocol, минуя основной механизм
  // Нужно, чтобы временно быстро потрекать какое-то собрытие и его параметры
  // https://developers.google.com/analytics/devguides/collection/protocol/v1/devguide
  sendAnalyticsCustomEvent: function(category, action, label, value, cid) {
    var basePayload = {
      v: '1',
      t: 'event',
      tid: Constants.GA_ID,
    };

    var eventPayload = _.extend({}, basePayload, {
      ec: encodeURIComponent(category),
      ea: encodeURIComponent(action),
    });

    if (label) {
      _.extend(eventPayload, { el: encodeURIComponent(label) });
    }
    if (value) {
      _.extend(eventPayload, { ev: encodeURIComponent(value) });
    }
    _.extend(eventPayload, { cid: cid || this.getGAcid() });

    var payloadString = Object.keys(eventPayload)
      .map(function(key) {
        return key + '=' + eventPayload[key];
      })
      .join('&');

    $.ajax({
      url: 'https://www.google-analytics.com/collect',
      method: 'POST',
      data: payloadString,
      processData: false,
      contentType: 'text/plain',
    });
  },

  // Получает Google Analytics ClientId из куки
  getGAcid: function() {
    var gacid_cookie = this.getCookie('_ga'),
      result = null;

    if (gacid_cookie) {
      gacid_cookie = gacid_cookie.match(/GA[0-9]+\.[0-9]+\.(.*)/);
      if (gacid_cookie.length > 1) {
        result = gacid_cookie[1];
      }
    }

    if (!result) {
      result = this.generateUUID();
    }
    return result;
  },

  // Проверяет есть ли сигнальные куки от бэка, чтобы отправлять события в Facebook Pixel
  // (бэк решает, нужно ли отправлять события, а реально отправлять умеет только фронт)
  processCustomFacebookEventCookies: function() {
    var cookieEventMap = {
      _rm_send_event_user: 'Mi_Usuario',
      _rm_send_event_subscriber: 'CLient',
    };

    var cookie;
    for (var key in cookieEventMap) {
      cookie = Utils.getCookie(key);
      if (cookie) {
        Utils.sendFacebookPixelEvent(cookieEventMap[key]);
        Utils.deleteCookie(key);
      }
    }
  },

  sendFacebookPixelEvent: function(eventName, eventParams = {}) {
    let pixelEventName;
    let pixelEventParams = {};

    switch (eventName) {
      case 'PageView':
        pixelEventName = 'PageView';
        break;
      case 'Joined':
        pixelEventName = 'CompleteRegistration';
        pixelEventParams = _.pick(eventParams, 'value', 'currency');

        break;
      case 'Subscribed':
        pixelEventName = 'Customer Created';
        pixelEventParams = _.pick(eventParams, 'currency', 'value');
        break;
      case 'Create Mag':
        pixelEventName = 'Create Mag';
        break;
      case 'Publish Mag':
        pixelEventName = 'Publish';
        break;
      case 'Republish Mag':
        pixelEventName = 'Republish';
        break;
      case 'Purchase':
        pixelEventName = 'Purchase';
        pixelEventParams = _.pick(eventParams, 'content_name', 'content_category', 'content_type', 'value', 'currency');
        break;
      case 'Mi_Usuario':
        pixelEventName = 'Mi_Usuario';
        break;
      case 'CLient':
        pixelEventName = 'CLient';
        break;
    }

    if (!pixelEventName) {
      return;
    }

    InitUtils.initFacebookPixel().then(() => {
      if (typeof window.fbq !== 'function') {
        return;
      }
      window.fbq('trackCustom', pixelEventName, pixelEventParams);
    });
  },

  toHttps: function(str) {
    return str.replace(/^http:\/\//i, 'https://');
  },

  escapeRegExp: function(str) {
    var specialChars = ['$', '^', '*', '(', ')', '+', '[', ']', '{', '}', '\\', '|', '.', '?', '/'];
    var regex = new RegExp('(\\' + specialChars.join('|\\') + ')', 'g');
    return str.replace(regex, '\\$1');
  },

  // string -> String
  capitalize: function(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  filters,
};
