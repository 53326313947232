/**
 * Плагин со списком всех кодов клавиш
 */
(function($) {
  $.keycodes = {
    enter: 13,
    esc: 27,

    tab: 9,

    alt: 18,
    shift: 16,

    pgUp: 33,
    pgDn: 34,

    left: 37,
    right: 39,
    up: 38,
    down: 40,

    del: 46,
    backspace: 8,

    // задизаблен shortcut e
    //https://www.notion.so/readymag/E-000968b918df4ec98177805530c04f2c
    //		e: 69,

    f: 70,

    comma: 188,

    g: 71,
    h: 72,
    s: 83,
    w: 87,
    v: 86,
    x: 88,
    b: 66,

    m: 77,
  };
})(jQuery);
