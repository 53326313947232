<template>
  <div ref="button" :class="{ 'color-picker-container': true, 'color-picker-disabled': disabled }">
    <div v-if="showAlpha" class="opacity-grid"></div>
    <div v-if="!alwaysVisible" class="color-preview" :style="{ backgroundColor: bg }" @click.stop="onColorClick">
      <img class="disabled-icon" v-if="disabled && disabledIcon" :src="disabledIcon" />
      <img class="custom-icon" v-if="!disabled && customIcon" :src="customIcon" />
    </div>
    <div v-if="showArrowIcon" class="arrow-icon" @click.stop="onColorClick"></div>
    <div class="colorbox_container" :class="{ visible: visibleBox }" :style="colorBoxStyles" @click.stop></div>
  </div>
</template>

<script>
const COLORPICKER_HEIGHT = 240;
const COLORPICKER_WIDTH = 152;
const COLORPICKER_VERTICAL_WIDTH = 168;

import $ from '@rm/jquery';
import { Utils } from '../../js/common/utils';
import Colorbox from '../../js/constructor/helpers/colorbox';

export default {
  model: {
    event: 'change',
  },
  computed: {
    bg() {
      if (this.disabled && !this.mutableColor) {
        return '#b8b8b8';
      }
      return Utils.getRGBA(this.mutableColor, this.showAlpha ? this.opacity100based : 1);
    },
    opacity100based() {
      return this.mutableAlpha / 100;
    },
    opacityResult() {
      return this.mutableAlpha / this.opacityMultiplier;
    },
  },
  watch: {
    color(val) {
      this.mutableColor = val;
      if (!this.colorbox) {
        return false;
      }
      // Если значение не определено
      if (!this.mutableColor) {
        // Если при этом открыт colorpicker - закрываем его
        if (this.visibleBox) {
          this.hide();
        }
        return false;
      }
      this.colorbox.setColor(this.mutableColor);
    },
    alpha(val) {
      this.mutableAlpha = val;
    },
  },
  data() {
    return {
      mutableColor: this.color,
      mutableAlpha: this.showAlpha ? this.alpha * this.opacityMultiplier : 1,
      visibleBox: false,
      colorBoxStyles: {},
    };
  },
  props: {
    color: String,
    alpha: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledIcon: String,
    customIcon: String,
    showAlpha: {
      type: Boolean,
      default: true,
    },
    showArrowIcon: {
      type: Boolean,
      default: false,
    },
    hOffset: {
      type: Number,
      default: 0,
    },
    opacityMultiplier: {
      type: Number,
      default: 1,
    },
    vOffset: {
      type: Number,
      default: 0,
    },
    alwaysVisible: {
      tyoe: Boolean,
      default: false,
    },
    colorboxType: {
      type: String,
      default: 'small',
    },
  },
  mounted() {
    var lineOffset = 8;
    if (!this.alwaysVisible) {
      if ($(this.$el).hasClass('round')) {
        lineOffset = 7;
      }

      var rect = this.$el.getBoundingClientRect();
      if (rect.top > window.innerHeight / 2 || this.vAlign == 'top') {
        this.colorBoxStyles['margin-top'] = -(COLORPICKER_HEIGHT + lineOffset + this.vOffset) + 'px';
      } else {
        this.colorBoxStyles['margin-top'] = $(this.$refs.button).height() + lineOffset + this.vOffset + 'px';
      }

      this.colorBoxStyles['margin-left'] = -(this.hOffset + COLORPICKER_WIDTH - $(this.$refs.button).width()) + 'px';
    } else {
      this.colorBoxStyles['width'] = COLORPICKER_VERTICAL_WIDTH + 'px';
      this.colorBoxStyles['overflow'] = 'hidden';
      this.colorBoxStyles['border-radius'] = '6px 6px 0 0';
      this.onColorClick();
    }
  },
  methods: {
    onColorClick() {
      if (this.disabled) {
        return;
      }
      if (!this.colorbox) {
        this.colorbox = new Colorbox({
          $parent: this.$el.querySelector('.colorbox_container'),
          type: this.colorboxType,
          show_opacity: this.showAlpha,
        });
        this.colorbox.on('colorchange', this.colorOpacityChanged);
        this.colorbox.on('opacitychange', this.colorOpacityChanged);
      }

      this.colorbox.setColor(this.mutableColor);
      this.colorbox.setOpacity(this.opacity100based);

      this.$emit(this.visibleBox ? 'hidePopup' : 'showPopup', this);
      this.visibleBox = !this.visibleBox;
    },
    colorOpacityChanged(r, g, b, a) {
      this.mutableColor = this.colorbox.rgb2hex([r, g, b]);
      this.mutableAlpha = a * 100;
      this.$emit('update:all', { color: this.mutableColor, alpha: this.opacityResult });
      this.$emit('update:color', this.mutableColor);
      this.$emit('update:alpha', this.opacityResult);
    },
    hide() {
      this.visibleBox = false;
      this.$emit('hidePopup');
    },
  },
};
</script>

<style lang="less">
@import (reference) '../../css/common/constants.less';

.color-picker-container {
  position: relative;
  width: 36px;
  height: 20px;
  border-radius: 10px;

  &.round {
    width: 28px;
    height: 28px;
    border-radius: 100%;

    .arrow-icon {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-image: url('../../img/constructor/controls/shape_settings/color-arrow.png');
      background-repeat: no-repeat;
      background-color: white;
    }

    &:hover .arrow-icon {
      background-image: url('../../img/constructor/controls/shape_settings/color-arrow-hover.png');
    }
  }

  .opacity-grid,
  .color-preview {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
  }

  .opacity-grid {
    background: url('../../img/constructor/controls/text_link/opacity-grid.png') 0 0;
  }

  .colorbox_container {
    .transition(0.2s, opacity, ease-in-out);
    -webkit-transform: translate3d(0, 0, 0);
    position: fixed;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    width: 152px;
    height: 240px;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .color-preview,
  .arrow-icon {
    cursor: pointer;
  }

  &.color-picker-disabled {
    .color-preview,
    .arrow-icon {
      cursor: default;
    }
  }
}
</style>
