/**
 * класс виджета Hotspot.
 * !!! - общий для Конструктора и для Вьювера.
 *
 * общие методы рендеринга.
 *
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import templates from '../../templates/common/hotspot-widget.tpl';
import { Utils } from '../common/utils';

const HotspotWidgetClass = Backbone.View.extend({
  TIP_GAP: 15,

  initialize: function(params) {
    if (!params) return;

    _.bindAll(this);

    this.block = params.block;

    // в конструкторе params.model — это _.clone(this.model.attributes) ,
    // во вьювере — это this вьюхи виджета, которая содержит все необходимые аттрибуты модели.
    this.model = params.model;

    this.$container = params.$container;
    this.environment = params.environment;
    this.mag = params.mag;

    this.template = templates['template-common-hotspot-widget'];

    this.render();
  },

  render: function() {
    // создаем $el из шаблона, но пока не вставляем в ДОМ.
    // вставляем после применения визуального состояния
    // для производительности.
    this.setElement($(this.template({ data: this.model })));

    // кэшируем поиск часто используемых элементов.

    // применяем визуальное состояние.

    if (this.environment === 'viewer') {
      // в конструкторе элемент подсказки
      // рендерится позже, поэтому там этот метод не сработает
      // во время изначальной отрисовки.
      this.apply_tip_container_size({
        width: this.model.tip_w,
        height: this.model.tip_h,
      });

      this.apply_tip_position(this.model);
    }

    // вставляем в ДОМ.
    this.$el.appendTo(this.$container);
  },

  apply_tip_container_size: function(data) {
    if (data && data.height === 0) {
      data.height = 120; // для виджетселектора
    }

    data && this.$('.tip').css(data);
  },

  apply_tip_bg_color: function(model) {
    this.$('.blocks-wrapper').css(
      'background-color',
      Utils.getRGBA(model['tip_background-color'], model['tip_background-color-opacity'] / 100)
    );
  },

  apply_tip_border_radius: function(model) {
    this.$('.blocks-wrapper')
      .add(this.$('.tip'))
      .css('border-radius', model['tip_border-radius'] + 'px');
  },

  apply_tip_box_shadow: function(model) {
    this.$('.tip').toggleClass('box-shadow', !!model['tip_box-shadow']);
  },

  apply_tip_position: function(model, options) {
    options = options || {};

    var $tip = this.$('.tip'),
      tip_position = model.tip_pos,
      tip_width = model.tip_w,
      tip_height = model.tip_h || 120, // для виджетселектора
      pin_width = model.w,
      pin_height = model.h,
      top = '',
      right = '',
      bottom = '',
      left = '',
      gap = this.TIP_GAP,
      result;

    if (tip_position === 'top') {
      top = -(gap + tip_height);
      left = pin_width / 2 - tip_width / 2;
    } else if (tip_position === 'right') {
      top = pin_height / 2 - tip_height / 2;
      left = pin_width + gap;
    } else if (tip_position === 'bottom') {
      top = pin_height + gap;
      left = pin_width / 2 - tip_width / 2;
    } else if (tip_position === 'left') {
      top = pin_height / 2 - tip_height / 2;
      left = -(gap + tip_width);
    }

    result = {
      top: top,
      left: left,
    };

    $tip.css(result);

    return result;
  },

  destroy: function() {
    this.remove();
  },
});

export default HotspotWidgetClass;
