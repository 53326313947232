/**
 * Плагин для реализации кастомного вертикального скрола
 *
 * Для работы необходим RMDrag, jQuery, underscore
 *
 *
 * Суть работы в том, что мы оставляем стандартный скрол (чтобы не заморачиваться с колесиком и прочими прелестями)
 * но мы его скрываем (за границами области скролинга) а в нужном месте показываем свой кастомный скрол
 *
 * базовая верстка для работы:
 *
 * 	<div class="scroll-wrapper">
 * 		<div class="results-wrapper">
 * 			<div class="results">
 *
 * 			</div>
 * 		</div>
 * 		<div class="gutter"></div>
 * 		<div class="scroll"></div>
 * 	</div>
 *
 * scroll-wrapper - базовый блок, нужен для двух вещей
 * 	- обрезать системный скролбар который есть в results-wrapper
 * 	- разместить в нем кастомную "планку" скрола (в results-wrapper его помещать нельзя поскольку там он будет скролится вместе с остальным контентом)
 *
 * results-wrapper - собственно блок который скролит контент
 * results - собственно контент
 * scroll - наш кастомный скролл (вертикальная планочка)
 * gutter - поле в которой двигается кастомный скролл (его можно и не указывать)
 *
 *
 * вот пример css для правильной работы:
 *
 * .scroll-wrapper {
 * 	position: relative;
 * 	width: 300px;
 * 	height: 200px;
 * 	overflow: hidden;
 *
 * 	.scroll {
 * 		position: absolute;
 * 		display: none; //изначально скрыт
 * 		width: 8px;
 * 		border-radius: 4px;
 * 		background: rgba(182, 182, 188, 0.4);
 * 		right: 2px;
 *
 * 		&:hover, &.dragging {
 * 			right: 0px;
 * 			width: 12px;
 * 			border-radius: 6px;
 * 		}
 * 	}
 *
 * 	.results-wrapper {
 * 		position: relative;
 * 		width: 100%;
 * 		padding-right: 30px; //от балды, чтобы скрыть системный скроллбар
 * 		height: 100%;
 * 		overflow-y: scroll; //!!! важно
 *
 * 		.results {
 * 			position: absolute;
 * 			top: 0px;
 * 			left: 0px;
 * 			width: 280px; //ширину контента надо задавать явно, поскольку мы не знаем ширину системного скролбара в results-wrapper
 * 			min-height: 100%;
 * 		}
 * 	}
 * }
 *
 *
 * пример применения:
 *
 * 	this.scroll = this.$('.scroll-wrapper').RMScroll({
 * 		$container: this.$('.scroll-wrapper .results-wrapper'),
 * 		$content: this.$('.scroll-wrapper .results-wrapper .results'),
 * 		$handle: this.$('.scroll-wrapper .scroll'),
 * 		$gutter: this.$('.scroll-wrapper .gutter'), //опционально, если указан, то этот элемент будет скрываться вместе со скролом если скролить нечего
 * 		onScroll: this.onScroll
 * 	}).data('scroll');
 *
 * 1. Плагин возвращает объект через который моно получить доступ к его функциям и данным (например так this.scroll && this.scroll.recalc())
 * 2. Можно подписаться на событие onScroll, но оно возвращает не событие, а объект со всеми необходимыми данными
 *
 *
 * пример использования можно поглядеть в searchGoogle.js или pages-panel-contents.js
 *
 *
 *
 */
import _ from '@rm/underscore';

(function($, undefined) {
  $.fn.RMScroll = function(options) {
    var Scroll = function(options, $elem) {
      this.options = _.clone(options);
      this.$scroll = $elem;
      this.incDelta = 0;

      this.mouseWheelProceedThrottled = _.throttle(this.mouseWheelProceed, 50);

      //отступы "планки" скрола от начала и от конца относительно контейнера scroll-wrapper
      _.defaults(this.options, {
        gap_start: 0,
        gap_end: 0,
        $gutter: null,
        tp: 'vertical', //тип скрола по умолчанию
        wheelScrollSpeed: 1,
        scrollStep: 1,
        maxHandleSize: 9999, //максимальная ширина scroll handle, по умолчанию любая
        dragScroll: false, //возможность скролить просто таская мышкой за контент
        systemScroll: false, //если true, тогда событие от колесика будет обрабатываться системным скролом а не нами
        //иногда нужно нами, когда мы глобально отключаем скролл везде
        //иногда нужно системной, когда мы хотим, чтобы при конце прокрутки коесиком начинали скролится другие скролы более высокого уровня
        needRecalc: true, // например, Слайдшоу не нужен рекалк и он передает false.
        savedScrollPosition: 0,
      });
    };

    Scroll.prototype = {
      initialize: function() {
        _.bindAll(this);

        this.options.$container.bind('scroll', this.onScroll);

        if (!this.options.systemScroll) this.options.$container.bind('mousewheel', this.onMouseWheel);

        $(this.options.$handle).RMDrag({
          start: this.startScrollDrag,
          move: this.moveScrollDrag,
          end: this.stopScrollDrag,
          silent: true,
          preventDefault: true,
        });

        if (this.options.dragScroll)
          $(this.options.$content).RMDrag({
            start: this.startContentDrag,
            move: this.moveContentDrag,
            end: this.stopContentDrag,
            silent: true,
            preventDefault: true,
          });

        this.data = {
          handle_pos: 0,
          handle_size: 0,
          container_size: 0,
          content_size: 0,
          scroll_pos: 0,
          scroll_percent: 0,
          save: true,
        };

        if (this.options.needRecalc) this.recalc();
      },

      onMouseWheel: function(e, d, dx, dy) {
        this.options.savedScrollPosition = 0;

        e.preventDefault();
        if (dx || dy) {
          if (this.options.tp == 'vertical') {
            d = dy;
          } else {
            d = dx || dy; //(|| dy потому что когда крутим колесиком мыши dx всегда 0, вместо него берем значение в dy)

            //для горизонтального скрола всегда блокируем баблинг события
            e.stopPropagation();
          }
        }

        var scrollTo = -Math.ceil(d * this.options.wheelScrollSpeed);

        this.incDelta += scrollTo;

        this.mouseWheelProceedThrottled(this.incDelta);
      },

      mouseWheelProceed: function(scrollTo) {
        if (scrollTo > 0 && scrollTo < this.options.scrollStep) scrollTo = this.options.scrollStep;
        if (scrollTo < 0 && scrollTo > -this.options.scrollStep) scrollTo = -this.options.scrollStep;

        if (this.options.tp == 'vertical') {
          var newScrollTo = scrollTo + this.options.$container.scrollTop();
          newScrollTo -= newScrollTo % this.options.scrollStep;
          this.options.$container.scrollTop(newScrollTo);
        } else {
          var newScrollTo = scrollTo + this.options.$container.scrollLeft();
          newScrollTo -= newScrollTo % this.options.scrollStep;
          this.options.$container.scrollLeft(newScrollTo);
        }

        this.incDelta = 0;
      },

      changeGaps: function(params) {
        params = params || {};
        if (params.gap_start != undefined) this.options.gap_start = params.gap_start;
        if (params.gap_end != undefined) this.options.gap_end = params.gap_end;
        this.recalc();
      },

      recalc: function() {
        this.data.container_size =
          this.options.tp == 'vertical' ? this.options.$container.height() : this.options.$container.width();
        this.data.container_size_with_gaps = this.data.container_size - this.options.gap_start - this.options.gap_end;
        this.data.content_size =
          this.options.tp == 'vertical'
            ? this.options.$content.outerHeight(true)
            : this.options.$content.outerWidth(true); //height width paddings, borders and margins

        this.data.handle_size = this.data.container_size_with_gaps / this.data.content_size;
        this.data.handle_size = Math.max(Math.min(this.data.handle_size, 1), 0);
        this.data.handle_size = Math.sqrt(this.data.handle_size);
        this.data.handle_size = Math.ceil(this.data.handle_size * this.data.container_size_with_gaps);

        this.data.handle_size = Math.min(this.data.handle_size, this.options.maxHandleSize);

        if (this.data.container_size >= this.data.content_size) {
          this.options.$handle.css('display', 'none');
          this.options.$gutter && this.options.$gutter.css('display', 'none');
        } else {
          this.options.$handle.css('display', 'block');
          this.options.$gutter && this.options.$gutter.css('display', 'block');
        }

        this.options.$handle.css(this.options.tp == 'vertical' ? 'height' : 'width', this.data.handle_size);

        this.onScroll();
      },

      clearScroll: function() {
        this.options.$container.scrollTop(0);
      },

      onScroll: function(e) {
        this.data.scroll_pos =
          this.options.tp == 'vertical' ? this.options.$container.scrollTop() : this.options.$container.scrollLeft();

        var delta = this.data.content_size - this.data.container_size;

        if (this.data.scroll_pos > delta) this.data.scroll_pos = delta;

        if (delta > 0) this.data.scroll_percent = this.data.scroll_pos / delta;
        else this.data.scroll_percent = 0;

        this.data.handle_pos =
          this.options.gap_start +
          Math.ceil(this.data.scroll_percent * (this.data.container_size_with_gaps - this.data.handle_size));

        this.options.$handle.css(this.options.tp == 'vertical' ? 'top' : 'left', this.data.handle_pos);

        this.data.scroll_event = e;

        if (this.options.savedScrollPosition != 0) {
          this.options.$container.scrollTop(this.options.savedScrollPosition);
        }

        if (typeof this.options.onScroll === 'function') this.options.onScroll(this.data);
      },

      startScrollDrag: function(e) {
        this.options.savedScrollPosition = 0;
        this.options.$handle.addClass('dragging');
        this.oldHandlePos = this.data.handle_pos;
      },

      moveScrollDrag: function(e) {
        var offset = this.options.tp == 'vertical' ? e.deltaY : e.deltaX;

        var new_pos = this.oldHandlePos + offset - this.options.gap_start,
          percent = new_pos / (this.data.container_size_with_gaps - this.data.handle_size);

        percent = Math.min(Math.max(percent, 0), 1);
        var scrollPos = percent * (this.data.content_size - this.data.container_size);

        if (this.options.tp == 'vertical') this.options.$container.scrollTop(scrollPos);
        else this.options.$container.scrollLeft(scrollPos);
      },

      stopScrollDrag: function(e) {
        this.options.$handle.removeClass('dragging');
      },

      startContentDrag: function(e) {
        if (this.options.tp == 'vertical') this.oldDragPos = this.options.$container.scrollTop();
        else this.oldDragPos = this.options.$container.scrollLeft();

        this.maxDragShift = 0;

        this.wasContentDrag = false;
      },

      moveContentDrag: function(e) {
        var offset = this.options.tp == 'vertical' ? e.deltaY : e.deltaX;

        this.maxDragShift = Math.max(this.maxDragShift, Math.abs(offset));

        var newPos = this.oldDragPos - offset;

        newPos = Math.min(Math.max(newPos, 0), this.data.content_size - this.data.container_size);

        if (this.options.tp == 'vertical') this.options.$container.scrollTop(newPos);
        else this.options.$container.scrollLeft(newPos);
      },

      stopContentDrag: function(e) {
        this.wasContentDrag = this.maxDragShift > 5; //ставим флаг того, что клик обрабатывать не надо, поскольку юзер таскал мышой
      },

      destroy: function() {},
    };

    var $elem = $(this);

    var scroll = new Scroll(options, $elem);
    scroll.initialize();

    $elem.data('scroll', scroll);

    return this;
  };
})(jQuery);
