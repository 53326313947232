/**
 * Модель данных виджета
 */
import Backbone from 'backbone';
import _ from '@rm/underscore';
import Viewports from '../../common/viewports';
import GlobalWidgets from '../../common/global-widget';
import Events from '../../common/events';
import ModelClass from './model';
import Blocks from '../blocks/index';

const WidgetModel = ModelClass.extend(
  {
    idAttribute: '_id',

    urlRoot: '/api/widget/',

    defaults: {
      // по центру оси x холста.
      x: Math.round(1024 / 2 - 480 / 2),
      y: 157,
      w: 480,
      h: 360,
    },

    name: 'Widget',

    widgets_with_inner_widgets: ['hotspot'],

    nonUndoKeys: ['animation'],

    viewport_required_fields: ['x', 'y', 'w', 'h'], // Если полей по какой-то причине не оказалось во вьюпорте, добавим их из дефолта
    onlyRootFields: ['_id', 'modelType'].concat(Viewports.viewport_list),

    initialize: function(params, options) {
      options = options || {};

      this.Block = Blocks[params.type];

      if (!this.Block) return;

      this.hasNested = _.contains(this.widgets_with_inner_widgets, params.type);
      this.isNested = !!params.parent_wid;

      if (this.hasNested) {
        // если данный виджет предполагает наличие других вложенных
        // внутрь него виджетов, то инициализируем
        // модели этих вложенных виджетов.

        this.init_inner_widgets_models(params, options);
      }

      this.on('change:is_global', this.onGlobalChange);
    },

    // инициализирует модели вложенных виджетов.
    init_inner_widgets_models: function(params, options) {
      var dataset, wids;

      // не портим входные данные.
      dataset = _.clone(params);

      // может выдаваться сервером по-разному.
      wids = dataset.widgets || dataset.wids || [];

      wids = this.sanitizeWidgets(wids);

      // создаем коллекцию моделей внутренних виджетов.
      // эта коллекция затем используется
      // при инициализации, рендеринге
      // и работе с внутренними виджетами
      // через внутриблоковоый воркспейс workspace-inside-block.js,
      // создаваемый родительским виджетом.
      this.widgets_collection = new WidgetsCollection(wids, {
        parent_widget: this,
        parse: options.parse,

        // чтобы у моделей вложенных виджетов работали методы
        // initViewport, getViewport и т.п., запрашивающие
        // this.collection.page
        page: this.collection.page,
      });

      // с сервера аттрибут wids приходит заполненный raw json
      // моделями вложенным виджетов для удобства, хотя по идее там только
      // айдишники вложенных виджетов хранятся в массиве.
      // исправляем это перед началом работы.
      dataset.wids = _.pluck(wids, '_id');

      this.set(dataset, {
        silent: true,
      });

      // при добавлении\удалении внутренних виджетов.
      this.on('change:wids', this.onWidsChange);
    },

    parse: function(data, options) {
      if (!this.collection || !this.collection.page) return data;
      if (!this.Block) this.Block = Blocks[data.type];

      var viewport = this.isNested ? 'default' : this.collection.page.getCurrentViewport();

      if (!_.isEmpty(data)) {
        // Не обрабатываем Patch запросы
        _.each(
          Viewports.viewport_list,
          function(v) {
            this[v] = data[v];

            // // Если поле не найдено в данных вьюпорта, но должно там быть, то берем значение этого поля
            // // из дефолтного вьюпорта.
            // // Пока только для кнопок. Сделано, потому что из-за бага терялись стили кнопок в мобильных вьюпортах.
            // // Аналогичный код есть в page.js:getWidgetViewportData во вьювере
            if (v !== 'default' && data[v] && data.type === 'button') {
              _.each(
                Viewports.viewport_fields[data.type] || Viewports.viewport_fields_common,
                function(field) {
                  this[v][field] = !_.has(data[v], field) ? data[field] : data[v][field];
                }.bind(this)
              );
            }
            this[v] = _.omit(this[v], this.onlyRootFields); // Если во вьюпорт случайно попали поля, которые могут быть только в корне JSON

            delete data[v];
            delete this.attributes[v];
          },
          this
        );

        this.storeViewport('default', data);

        _.extend(data, _.defaults(this['viewport_' + viewport], this.viewport_fields_defaults));
      }

      if (options && options.xhr) options.xhr.skipResponse = true; // Не берем данные с серверного ответа для виджетов

      if (_.has(data, 'w') && !_.isNumber(data.w)) data.w = this.defaults.w;
      if (_.has(data, 'h') && !_.isNumber(data.h)) data.h = this.defaults.h;

      return ModelClass.prototype.parse.apply(this, arguments);
    },

    set: function(key, val, options) {
      var attrs;

      // Handle both `("key", val)` and `({key: val})` -style calls.
      if (typeof key === 'object') {
        attrs = key;
        options = val;
      } else {
        (attrs = {})[key] = val;
      }

      if (!this.isGlobal() || !this.collection || !this.collection.page || (options && options.noSaveUnique)) {
        return ModelClass.prototype.set.apply(this, arguments);
      }

      var uniqueAttrs = GlobalWidgets.fillUniqueAttributeSets(attrs, this.attributes, this.collection.page.id);

      if (!_.isEmpty(uniqueAttrs)) {
        _.extend(attrs, uniqueAttrs);
      }

      return ModelClass.prototype.set.apply(this, [attrs, options]);
    },

    get: function(attr) {
      var val = ModelClass.prototype.get.apply(this, arguments);
      // Уникальные названия полей берем только для глобальных виджетов. Список полей перечислен в GlobalWidgets.GLOBAL_UNIQUE_KEYS, но для Above Pages виджетов не используется поле 'z'
      if (
        !this.attributes.is_global ||
        (this.attributes.is_above && attr === 'z') ||
        !_.contains(GlobalWidgets.GLOBAL_UNIQUE_KEYS, attr) ||
        !this.collection ||
        !this.collection.page
      ) {
        return val;
      }

      var uniqueVal = GlobalWidgets.getUniqueValue(attr, this.attributes, this.collection.page.id);

      return uniqueVal === undefined ? val : uniqueVal; // Может быть и false, и 0, и null
    },

    validate: function(attrs) {
      if (!attrs.type) {
        return 'missing widget type';
      }
    },

    triggerReady: function() {
      Events.trigger('ready:model', this);
    },

    // АХТУНГ! Нельзя использовать метод для простого получения джейсона текущего состояния атрибутов
    // Он возвращает полный набор данных со всеми вьюпортами и предназначен
    // для отправки данных на сервер, копи-пасты и создания шаблонов
    // Вместо этого надо использовать напрямую _.clone(model.attributes)
    toJSON: function(options) {
      if (!this.collection || !this.collection.page) return this.attributes;
      var viewport = this.collection.page.getCurrentViewport();

      var attrs = _.clone(this.attributes);

      // Вложенные виджеты работают всегда в дефолтном вьюпорте.
      if (viewport !== 'default' && !this.isNew() && !this.isNested) {
        attrs['viewport_' + viewport] = _.pick(attrs, this.viewport_fields);
        attrs['viewport_' + viewport] = _.omit(attrs['viewport_' + viewport], this.onlyRootFields);
        _.extend(attrs, this.viewport_fields_defaults, this['viewport_default']);
      }
      _.each(
        Viewports.viewport_list,
        function(v) {
          if (!_.isEmpty(this[v]) && !attrs[v]) attrs[v] = this[v];
        },
        this
      );

      return attrs;
    },

    initViewport: function(options) {
      var viewport = this.collection.page.getCurrentViewport(),
        sourceData = this.attributes;

      this['viewport_' + viewport] = _.extend(
        _.pick(sourceData, this.Block.prototype.viewport_fields),
        this.Block.getViewportDefaults(this, viewport, options)
      );
    },

    getViewport: function() {
      // вложенные виджеты всегда работают в дефолтном вьюпорте.
      return this.isNested
        ? 'default'
        : this.collection && this.collection.page && this.collection.page.getCurrentViewport();
    },

    changeViewport: function(opts) {
      opts = opts || {};
      var prevViewport = this.collection.page.getPreviousViewport();

      this.storeViewport(prevViewport);
      this.restoreViewport();
    },

    // Сохраняет состояние текущего вьюпорта во внутреннем представлении
    storeViewport: function(viewport, sourceData) {
      viewport = viewport || this.collection.page.getCurrentViewport();
      sourceData = sourceData || this.attributes;

      this['viewport_' + viewport] = _.pick(sourceData, this.viewport_fields);
    },

    // Назначает модели свойства нового вьюпорта
    restoreViewport: function(viewport) {
      viewport = viewport || this.collection.page.getCurrentViewport();
      if (_.isEmpty(this['viewport_' + viewport])) {
        if (viewport !== 'default') {
          this.restoreViewport('default');
        }
        this.storeViewport(viewport);

        this.set(this.Block.getViewportDefaults(this, viewport));
        this.save({}, { silent: true, skipHistory: true });
      } else {
        var sourceData = _.defaults({}, this['viewport_' + viewport], this.viewport_fields_defaults);
        if (this.get('is_global')) {
          if (this.get('is_above')) delete sourceData['_z']; // Для is_above виджетов нельзя переопределять z-index на страницах

          GlobalWidgets.fillUniqueValues(sourceData, this.collection.page.id);
        }

        this.set(sourceData, { first: true, viewportChange: true, noSaveUnique: true });
      }
      if (viewport !== 'default') this.validateViewport(viewport);
    },

    // При переключении вьюпорта у нас должен быть минимум необходимых полей чтобы не сломать виджет
    validateViewport: function(viewport) {
      var valid = _.all(
        this.viewport_required_fields,
        function(field) {
          var value = this.get(field);
          return value !== undefined && value !== null && !isNaN(value) && value !== '';
        },
        this
      );

      if (!valid) {
        var data = this.viewport_default;
        this.set(_.pick(data, this.viewport_required_fields));
        this.save({}, { silent: true, first: true });
      }
    },

    /**
     * Заполняет переданные поля значениями из дефолтного вьюпорта
     * @param {Array<String>} fields
     */
    fillMissingFieldsFromDefaultViewport: function(fields) {
      var update = {};
      _.each(
        fields,
        function(field) {
          var defaultViewportValue = this.viewport_default && this.viewport_default[field];
          if (this.get(field) === undefined && defaultViewportValue) {
            update[field] = _.cloneWithObjects(defaultViewportValue);
          }
        }.bind(this)
      );

      if (!_.isEmpty(update)) {
        this.set(update, { silent: true });
      }
    },

    // json содержащий изменения для вьюпортов делится на данные для вьюпорта и данные для десктопной версии
    splitChangesToViewport: function(attrs, viewport) {
      // Данные нужно разделять только для мобильных вьюпортов
      if (!viewport || viewport === 'default') return attrs;

      // возьмем данные из корня JSON и положим их в viewport_phone_portrait или viewport_tablet_portrait объект
      var viewportKeys = this.viewport_fields;
      var viewportAttributes = _.pick(attrs, viewportKeys);

      // Если ключ передан и в корне JSON и в объекте вьюпорта, то отбросим эти ключи
      _.each(viewportKeys, function(key) {
        if (attrs['viewport_' + viewport] && attrs['viewport_' + viewport][key]) {
          delete viewportAttributes[key];
        }
      });

      if (!_.isEmpty(viewportAttributes)) {
        attrs['viewport_' + viewport] = _.extend({}, attrs['viewport_' + viewport], viewportAttributes);
        attrs = _.omit(attrs, viewportKeys);
      }

      return attrs;
    },

    getPageId: function() {
      return this.collection && this.collection.getPageId();
    },

    // метод создает модель нового виджета-ребенка,
    // сохраняет её на сервер,
    // сохраняет её id в массив wids: []
    // модели данного родительского виджета (onAddWidget колбэк).
    addWidget: function(params, options) {
      options = options || {};

      if (!params || !params.type) {
        console.error('Adding widget with no type into another widget.');
        return;
      }

      if (!Blocks[params.type]) {
        console.error('Adding unknown widget into another widget.');
        return;
      }

      params.parent_wid = this.get('_id');
      params.pid = this.get('pid');

      // путем добавления raw json в коллекцию,
      // создается модель виджета.
      // Обязательно пробрасываем options. В них может передаваться, например,
      // флаг copy - копирования родителя
      var widget = this.widgets_collection.add(params, options);

      // ставим флаг, что модель впервые создана.
      // например, при создании текстового виджета
      // это даст сигнал виджету сразу войти в режим редактирования.
      widget.created = !options.copy && !options.clone;

      if (!widget.id) {
        widget.save(
          {},
          {
            success: this.getAddCallback(options),
            error: options.error,
          }
        );
      } else {
        widget.fetch({
          success: this.getAddCallback(options),
          error: options.error,
        });
      }
    },

    // колбэк для метода addWidget.
    // сохраняет id модели виджета-ребенка
    // в модель данного родительского виджета
    // в массив аттрибута wids: [].
    onAddWidget: function(widget, options) {
      if (!widget.get('type')) return false;

      var wids;

      wids = _.clone(this.get('wids'));
      wids.push(widget.id);
      wids = _(wids)
        .chain()
        .uniq()
        .compact()
        .value();

      // см. слушатель change:wids в workspace-inside-block.js
      this.set(
        {
          wids: wids,
        },
        options
      );
    },

    getAddCallback: function(options) {
      var options = options || {},
        self = this;

      return function(widget) {
        self.onAddWidget(widget, options);

        options.success && options.success(widget);
      };
    },

    // при добавлении\удалении внутренних виджетов.
    onWidsChange: function(model, wids, options) {
      var wids = this.get('wids');

      // при работе в тимворке
      // при разблокировке страницы
      // вызывается this.mag.pages.fetchFullPage(pageId)
      // который засетит в wids
      // модели вложенных виджетов.
      // специально, чтобы создать вложенные виджеты.
      // в тимворке они не синхронизируются во время работы,
      // а грузятся только при разблокировке страницы.
      if (wids && _.isObject(wids[0])) {
        // не портим входные данные.
        var _wids = _.clone(wids);

        _wids = this.sanitizeWidgets(_wids);

        this.widgets_collection.set(_wids, {
          parent_widget: this,

          // чтобы у моделей вложенных виджетов работали методы
          // initViewport, getViewport и т.п., запрашивающие
          // this.collection.page
          page: this.collection.page,
        });

        wids = _.pluck(wids, '_id');

        this.set('wids', wids, { silent: true });
      }

      var newWids = this.get('wids'),
        oldWids = model.previous('wids') || [];

      if (newWids) {
        var addedWids = _.difference(newWids, oldWids),
          deletedWids = _.difference(oldWids, newWids);

        _.each(
          addedWids,
          function(wid) {
            var widget = model.widgets_collection.get(wid);

            widget && this.trigger('widget:create', widget, options);
          }.bind(this)
        );

        _.each(
          deletedWids,
          function(wid) {
            var widget = model.widgets_collection.get(wid);

            widget && this.trigger('widget:remove', widget, options);
          }.bind(this)
        );
      }
    },

    // фильтрует виджеты:
    // только те, для которых есть код в конструкторе,
    // могут быть использованы.
    sanitizeWidgets: function(wids) {
      return _.filter(wids, function(widget) {
        return !!Blocks[widget.type];
      });
    },

    isGlobal: function() {
      return !!this.get('is_global');
    },

    onGlobalChange: function() {
      var is_global = this.get('is_global');

      if (is_global) {
        // При включении глобальности убедмся, что для всех существующих вьюпортов
        // создана дефолтная запись для получения уникального значения для страницы
        _(Viewports.viewport_listall).each(
          function(v_name) {
            if (!_.isEmpty(this[v_name])) {
              // для текущего вьюпорта значения должны попасть в attributes
              if ('viewport_' + this.getViewport() === v_name) {
                GlobalWidgets.ensurePageDefRecord(this['attributes']);
              }
              GlobalWidgets.ensurePageDefRecord(this[v_name]);
            }
          }.bind(this)
        );

        this.triggerGlobalAppear();
      } else {
        this.triggerGlobalDisappear();
      }
    },

    triggerGlobalAppear: function() {
      Events.trigger('global_appear:widget', this);
    },

    triggerGlobalDisappear: function() {
      Events.trigger('global_disappear:widget', this);
    },
  },
  {
    viewports: Viewports.viewport_list,
  }
);

Object.defineProperty(WidgetModel.prototype, 'viewport_fields', {
  get: function() {
    if (!this.Block.prototype.viewport_and_unique_fields) {
      // Во вьюпортах так же должны сохраняться поля с уникальными свойствами страниц
      this.Block.prototype.viewport_and_unique_fields = _.union(
        this.Block.prototype.viewport_fields,
        GlobalWidgets.GLOBAL_UNIQUE_KEYS.map(function(gkey) {
          return GlobalWidgets.GLOBAL_UNIQUE_KEY_PREFIX + gkey;
        })
      );
    }

    return this.Block.prototype.viewport_and_unique_fields;
  },
});

Object.defineProperty(WidgetModel.prototype, 'viewport_fields_defaults', {
  get: function() {
    if (!this.Block.prototype.viewport_fields_defaults) {
      this.Block.prototype.viewport_fields_defaults = {};

      _.each(
        this.viewport_fields,
        function(f) {
          this.Block.prototype.viewport_fields_defaults[f] = undefined;
        },
        this
      );
    }

    return this.Block.prototype.viewport_fields_defaults;
  },
});

export const WidgetsCollection = Backbone.Collection.extend({
  model: WidgetModel,

  initialize: function(models, options) {
    if (options && options.page) this.page = options.page;

    this.on(
      'remove',
      function(model) {
        this.page && this.page.trigger('widget:remove', model, {});
      },
      this
    );
  },

  getPageId: function() {
    return this.page.id;
  },
});

export default WidgetModel;
