import $ from '@rm/jquery';
import Vue from 'vue';
import BlockClass from '../block';
import RmFooter from '../../../components/common/rm-footer.vue';

const rmFooter = BlockClass.extend(
  {
    name: 'RM Footer',
    thumb: 'rm-footer',
    icon_color: '#282828',

    initial_controls: ['common_position', 'common_lock'],

    restrictions: function() {
      const permissions = RM.constructorRouter && RM.constructorRouter.me && RM.constructorRouter.me.get('permissions');
      return permissions && permissions.can_use_rm_widgets;
    },

    initialize: function(model, workspace) {
      this.initBlock(model, workspace);
    },

    render: function() {
      this.create();
      this.$el.addClass('rm-footer');
      this.controls = this.initial_controls;

      const rmFooterVue = Vue.extend(RmFooter);
      this.vue = new rmFooterVue({
        el: $('<div>')
          .appendTo(this.$content)
          .get(0),
      });

      this.fixMobileViewport();

      this.rendered = true;

      this.triggerReady();
    },

    redraw: function() {
      this.fixMobileViewport();
      return BlockClass.prototype.redraw.apply(this, arguments);
    },

    /**
     * Фикс мобильных стилей в превью
     *
     * Если зайти в превью, то будет показываться десктоп стиль,
     * т.к. мы ориентируемся на @media width
     * Эта функция добавляет необходимый стиль
     */
    fixMobileViewport: function() {
      const isDesktop = this.model.getViewport() === 'default';
      $('body').toggleClass('rm-footer-mobile', !isDesktop);
    },

    destroy: function() {
      this.vue && this.vue.$destroy();
      return BlockClass.prototype.destroy.apply(this, arguments);
    },
  },
  {
    defaults: {
      full_width_initial_w: 480,
      full_width_initial_x: 0,
      full_width_margin: 0,
      is_above: false,
      is_full_width: true,
      modelType: 'widget',
      sticked: 'bottom',
      sticked_margin: 0,
      h: 360,
      w: $(window).width(),
      y: 656,
    },
  }
);

export default rmFooter;
