/**
 * класс виджета Кнопки.
 * !!! - общий для Конструктора и для Вьювера.
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import buttonWidgetTpl from '../../templates/common/button-widget.tpl';
import buttonConstructorTpl from '../../templates/constructor/blocks/button.tpl';
import buttonViewerTpl from '../../templates/viewer/widgets/button.tpl';
import ButtonUtils from './buttonutils';

const templates = { ...buttonWidgetTpl, ...buttonConstructorTpl, ...buttonViewerTpl };

const ButtonWidgetClass = Backbone.View.extend({
  // минимальные отступы для воздуха вокруг Иконки.
  // используется по-разному.
  MIN_ICON_MARGIN: 8,

  initialize: function(params) {
    if (!params) return;

    _.bindAll(this);

    this.block = params.block;
    this.model = params.block && params.block.model;
    this.model = params.model;
    this.$container = params.$container;
    this.environment = params.environment;
    this.mag = params.mag;

    _.extend(this.model, { env: this.environment });

    // как можно раньше генерируем индивидуальные стили
    // в <style/>, а то они потом с задержкой применяются,
    // и кнопка при рендеринге выглядит на момент голой.
    // UPD: не помогает. новое решение — выставлять
    // стили дефолтного состояния через аттрибут, тогда кнопка сразу
    // застиленной появляется, а потом, практически сразу,
    // убирать их из аттрибута.
    this.generateIndividualStyleCSS({
      env: this.environment,
      model: this.model,
    });

    this.template = templates['template-common-button-widget'];

    if (this.environment === 'constructor')
      // для Конструктора элемент под текст Кнопки — input
      this.text_template = templates['template-constructor-block-button'];
    // для Вьювера элемент под текст Кнопки — input задисейбленный
    else this.text_template = templates['template-viewer-widget-button'];

    this.render();
  },

  render: function() {
    var buttonType = this.model.tp,
      individual_styles = ButtonUtils.generateStylesStr({
        model: this.model,
      });

    // создаем $el из шаблона, но пока не вставляем в ДОМ.
    // вставляем после применения визуального состояния для производительности.
    this.setElement($(this.template({ data: this.model })));

    $(this.text_template({ data: this.model })).appendTo(this.$el);

    // кэшируем поиск часто используемых элементов.
    this.$text = this.$('.text');
    this.$icon = this.$('.icon');
    this.$button = this.$text.closest('.common-button');

    // сразу же выставляем стили дефолтного состояния
    // через аттрибут, тогда кнопка сразу
    // застиленной появляется, а потом, практически сразу,
    // убираем их из аттрибута. см. ниже.
    this.$button.attr('style', individual_styles);

    // изначально применяем визуальное состояние.

    this.applyButtonType(this.model.tp);

    if (this.model.form === 'circle') {
      this.applyCircleRadius(this.model.w);
    }

    this.applyTextContainerSize(this.model);

    this.applyIconContainerSize(this.model);

    this.applyIconPosition(this.model);

    if (this.environment === 'viewer') {
      // если мы во вьювере, то контейнеру
      // задаем такой же бордер-радиус,
      // чтобы ховер не срабатывал при наведении на угол кнопки,
      // хотя визуально там угла нет.
      // особенно для формы круга тупо выходит.

      this.$container.css('border-radius', this.$button.css('border-radius'));
    }

    this.$el.appendTo(this.$container);

    // с задержкой, потому что
    // стили через <style/> на кнопку чуть позже накидываются.
    this.renderFinishTimeout = setTimeout(
      function() {
        var individual_styles = [
          // Default
          'background-color',
          'background-color-opacity',

          'border-width',
          'border-color',
          'border-color-opacity',

          'font-family',
          'font-style',
          'font-weight',

          'color',
          'color-opacity',
          'font-size',
          'letter-spacing',
        ];

        // если кнопка имеет форму круга,
        // у неё будет стоят в аттрибуте border-radius: 100%
        // и мы его обнулим. так что не делаем этого.
        if (this.model.form !== 'circle') {
          individual_styles.push('border-radius');
        }

        // снимаем ранее выставленные стили через аттрибуты,
        // стили из <style/> уже должны примениться.
        individual_styles.forEach(
          function(style) {
            this.$button.css(style, '');
          }.bind(this)
        );

        // навешиваем транзишен, если он включен.
        this.applyTransition();
      }.bind(this),
      500
    );
  },

  // Изменить текст кнопки
  renderButtonText: function(text) {
    this.$el.find('.text').val(text);
  },

  forceRenderButtonText: function(text) {
    this.$el
      .find('.text')
      .val(text)
      .css({ display: text ? 'block' : 'none' });
  },

  applyTransition: function() {
    if (this.environment === 'viewer' && !Modernizr.touch) {
      // если мы во вьювере, проект открыт не на тач дейвайсе,
      // у кнопки включена опция транзишенов
      // то включаем транзишены для кнопки.

      var transitionEnabled = this.model.transition;

      if (transitionEnabled) {
        this.$button.addClass('transition');
      }
    }
  },

  applyButtonType: function(button_type) {
    var displayShowValue = 'inline-block';

    if (button_type === 'text') {
      this.$text.css('display', displayShowValue);

      this.$icon.css('display', 'none');
    } else if (button_type === 'icon') {
      this.$icon.css('display', displayShowValue);

      this.$text.css('display', 'none');
    } else if (button_type === 'text_and_icon') {
      this.$icon.add(this.$text).css('display', displayShowValue);
    }
  },

  applyCircleRadius: function(w) {
    this.$button.css('border-radius', '100%');
  },

  applyTextContainerSize: function(model) {
    var letter_spacing = model['letter-spacing'],
      padding_left = 0,
      text_indent = 0;

    if (letter_spacing > 0) {
      // положительный межбуквенный добавляет
      // тексту экстра-ширины справа (как-будто там символ-воздух)
      // экстра-ширина равна значению межбуквенного.
      // контейнер текста у нас включает эту экстра-ширину в свой размер.
      // у контейнера  text-align: center — текст получается смещенным влево,
      // как-будто после текста символ (воздух) равный межбуквенному.

      // т.е. у нас справа от текста воздух.
      // компенсируем его таким же по ширине воздухом слева.
      // таким образом получается, что у нашеё кнопки положительный
      // межбуквенный добавляет по дополнительному
      // отступу слева и справа от текста (помимо em_w / 2),
      // что органично для типографики, когда
      // у нас слева или справа иконка. т.к. внешний воздух должен быть
      // всегда больше внутреннего (который увеличился из-за межбуквенного).

      // для положительного межбуквенного используем padding,
      // т.к. он точнее text-indent, который мы изспользуем
      // при отрицательном межбуквенном.
      padding_left = letter_spacing;
    } else if (letter_spacing < 0) {
      // отрицательный межбуквенный затягивает текст вправо
      // на размер межбуквенного,
      // как будето после текста стоит символ отрицательного воздуха.

      // для отрицатеьного межбуквенного вытягиваем текста обратно
      // с помощью text-indent.
      // текст-индент не очень точен, но эта неточность
      // заметна только при значения больше -100, а у нас минимальный
      // отрицательный межбуквеный это -18. (upd. 22.01.2016 теперь до -99 но менять ничего не буду)
      // (поэтому мы не используем текст-индент при компенсации
      // положительного межбуквенного).
      // минус добавлять не надо, он у нас и так в значении letter-spacing.
      text_indent = letter_spacing;
    }

    this.$text.css({
      width: model.text_w + 'px',
      'padding-left': padding_left + 'px',
      'text-indent': text_indent + 'px',

      height: model.text_h + 'px',

      // важно, задавать такую же высоту линии, как и высоту блока,
      // чтобы было было ровное вертикльное центрирование текста в своем контейнере .text,
      // высота текста округляется в большую сторону и делается четной.
      // note: adjusted_h в $textInputSizeAdjuster считался
      // с line-height: 1.4, чтобы все висячие
      // штуки у букв влезли.
      // если оставить у текстового блока рассчитанный браузером
      // line-height: 1.4, то он на пиксель может не совпасть с высотой
      // текстового блока и текст будет не идеально вертикально центрирован.
      'line-height': model.text_h + 'px',
    });
  },

  applyIconContainerSize: function(model) {
    var buttonType = model.tp,
      icon_w = model.icon_w,
      icon_h = model.icon_h,
      position = model.icon_pos,
      marginLeft = 0,
      marginRight = 0;

    if (buttonType === 'text_and_icon') {
      // если Кнопка с Текстом и Иконкой,
      // и Иконка слева или справа,
      // то задаем отступ от Иконки.
      // if (position === 'left') {
      //
      //	marginLeft = 0;
      //	marginRight = this.calcIconMarginFromText(icon_w);
      //
      // } else if (position === 'right') {
      //
      //	marginLeft = this.calcIconMarginFromText(icon_w);
      //	marginRight = 0;
      //
      // } else {
      //
      //	marginLeft = 0;
      //	marginRight = 0
      //
      // }
    }

    this.$icon.css({
      width: icon_w + 'px',
      height: icon_h + 'px',
      'margin-right': marginRight + 'px',
      'margin-left': marginLeft + 'px',
    });
  },

  // высчитывает отступ Иконки от текста,
  // отталкиваясь от ширины Иконки.
  calcIconMarginFromText: function(icon_w) {
    return Math.ceil(icon_w / 4);
  },

  // когда Кнопка с текстом и Иконкой,
  // и Иконка выше текста,
  // высчитывает минимальный воздух вокруг контента,
  // отталкиваясь от высоты Иконки.
  calcIconTopBottomMargin: function(icon_h) {
    return Math.ceil(icon_h / 3);
  },

  // когда Кнопка с текстом или с текстом
  // и Иконкой (и текст выше Иконки),
  // высчитывает минимальный воздух вокруг контента,
  // отталкиваясь от высоты текста.
  // стоить иметь ввиду, что текст уже имеет кое-какой воздух
  // сверху и снизу, т.к. высота его контейнера считалась
  // с line-height: 1.4
  calcTextTopBottomMargin: function(font_size) {
    return Math.ceil(font_size / 6);
  },

  applyIconPosition: function(model) {
    var iconPosition = model.icon_pos;

    if (iconPosition === 'left') {
      this.$button.css({
        'flex-direction': 'row',
        '-webkit-flex-direction': 'row',
      });
    } else if (iconPosition === 'right') {
      this.$button.css({
        'flex-direction': 'row-reverse',
        '-webkit-flex-direction': 'row-reverse',
      });
    }
  },

  generateIndividualStyleCSS: function(params) {
    ButtonUtils.generateIndividualStyleCSS({
      env: params.env,
      model: params.model,
    });
  },

  destroy: function() {
    clearTimeout(this.renderFinishTimeout);
    // удаляем индивидуальные стили кнопки,
    // сгенерированные в <style/>
    ButtonUtils.destroyIndividualStyleCSS({
      env: this.environment,
      model: this.model,
    });

    this.$el && this.$el.remove();
  },
});

export default ButtonWidgetClass;
