/**
 * Конструктор для виджета текста
 */
import ControlClass from '../control';
import Colorbox from '../helpers/colorbox';
import templates from '../../../templates/constructor/controls/text_color.tpl';

const TextColor = ControlClass.extend({
  name: 'text_color', // должно совпадать с классом вьюхи

  className: 'control text_color',

  initialize: function(params) {
    this.template = templates['template-constructor-control-text_color'];

    this.initControl(params);
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.colorbox = new Colorbox({ $parent: this.$panel, type: 'small' });

    this.colorbox.on('colorchange', this.colorChanged);
    this.colorbox.on('opacitychange', this.colorChanged);

    // случаем событие изменения стиля выделения, генерируется редактором
    this.blocks && this.blocks[0].on('selection_styles_changed', this.selectionStylesChanged);

    // первоначально проставляем состояние контрола в зависимости от текущего стиля выделения в редакторе
    this.blocks && this.selectionStylesChanged(this.blocks[0].cur_selection_styles);
  },

  unBindLogic: function() {
    this.colorbox && this.colorbox.off('colorchange', this.colorChanged);
    this.colorbox && this.colorbox.off('opacitychange', this.colorChanged);
    this.colorbox && this.colorbox.destroy();
    this.blocks && this.blocks[0].off('selection_styles_changed', this.selectionStylesChanged);
  },

  selectionStylesChanged: function(styles) {
    if (!this.blocks) return;
    if (this.blocks[0].initiatorControl == this.name) return;

    var color = styles.color,
      opacity = styles.opacity;

    // в выделении несколько цветов, берем первый
    if (color == this.blocks[0].params.multipleName) {
      if (this.blocks[0].cur_selection_styles_all.length > 0)
        color = this.blocks[0].cur_selection_styles_all[0]['color'];
      else return; // вот такого быть не должно, но в схожей ситуации в типографике было один раз, на всяий случай
    }

    // в выделении несколько прозрачностей цветов, берем первую
    if (opacity == this.blocks[0].params.multipleName) {
      if (this.blocks[0].cur_selection_styles_all.length > 0)
        opacity = this.blocks[0].cur_selection_styles_all[0]['opacity'];
      else return; // вот такого быть не должно, но в схожей ситуации в типографике было один раз, на всяий случай
    }

    this.updateIconState(color, opacity / 100);
    this.updateColorboxState(color, opacity / 100);
  },

  updateIconState: function(color, opacity) {
    if (!color || !opacity) return;
    if (!this.$icon) return;
    this.$icon.find('.color-circle').css({
      background: '#' + color,
      opacity: opacity,
    });
  },

  updateColorboxState: function(color, opacity) {
    if (!color || opacity == undefined) return;
    if (!this.colorbox) return;
    this.colorbox.setOpacity(opacity);
    this.colorbox.setColor(color);
  },

  colorChanged: function(r, g, b, a) {
    var color = '';
    if (a > 0.99) color = 'rgb(' + r + ',' + g + ',' + b + ')';
    else color = 'rgba(' + r + ',' + g + ',' + b + ', ' + a + ')';

    var hex = this.colorbox && this.colorbox.rgb2hex([r, g, b]);

    if (this.blocks) {
      this.blocks[0].initiatorControl = this.name;
      this.blocks[0].trigger('changeStyleSpan', { color: color }, { color: hex, opacity: a * 100 });
    }

    this.updateIconState(hex, a);
  },
});

export default TextColor;
