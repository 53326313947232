/**
 * Конструктор для виджета текста
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/common_align.tpl';
import PreloadDesignImages from '../../common/preload-design-images';

const CommonAlignClass = ControlClass.extend({
  name: 'common_align', // должно совпадать с классом вьюхи

  className: 'control common_align',

  initialize: function(params) {
    this.template = templates['template-constructor-control-common_align'];

    this.initControl(params);
  },

  select: function() {
    ControlClass.prototype.select.apply(this, arguments);

    PreloadDesignImages('controls-common_align');
  },

  /**
   * Переопределяем метод отрисовки виджета
   */
  bindLogic: function() {
    this.$panel.on('click', 'div', this.storeFixed);

    this.$panel.on('click', '.left', this.left);
    this.$panel.on('click', '.top', this.top);
    this.$panel.on('click', '.bottom', this.bottom);
    this.$panel.on('click', '.right', this.right);
    this.$panel.on('click', '.center', this.center);
    this.$panel.on('click', '.middle', this.middle);

    this.$panel.on('click', 'div', this.restoreFixed);
  },

  storeFixed: function() {
    _.invoke(this.blocks, 'storeFixedPosition');
  },

  restoreFixed: function() {
    _.invoke(this.blocks, 'restoreFixedPosition');

    this.master.workspace.save_group(_.pluck(this.blocks, 'model'));
  },

  setParam: function(key, value) {
    var new_data = _.map(this.blocks, function(block) {
      var res = { _id: block.model.id };

      res[key] = _.isFunction(value) ? value(block) : value;

      return res;
    });

    this.master.workspace.set_group(new_data);
  },

  // Показываем его когда в выделении больше одного виджета (группу виджетов считаем за один)
  restrictions: function(workspace) {
    var packs = {};

    var moreThanOne =
      _.reduce(
        workspace.getSelectedBlocks(),
        function(memo, block) {
          // если у виджета нет pack_id то просто добавляем  к кол-ву выделенных элементов
          // если же pack_id есть, тогда виджеты с одинаковым pack_id считаем за один раз
          var pack_id = block.model.get('pack_id'),
            addition = pack_id ? (packs[pack_id] ? 0 : 1) : 1;

          packs[pack_id] = 1;

          return memo + addition;
        },
        0
      ) > 1;

    // Показываем только если выбраны обычные виджеты и fixed виджеты с одной и той же точкой отсчета
    var sameFixedPosition =
      _(workspace.getSelectedBlocks())
        .chain()
        .map(function(b) {
          return b.model.get('fixed_position');
        })
        .compact()
        .uniq()
        .value().length <= 1;

    return moreThanOne && sameFixedPosition;
  },

  // пробегается по всем переданым моделям блоков и ищет те которые относятся к группам
  // поле чего формирует хеш идишников групп по каждому из которых можно узнать координаты и ширину-высоку группы
  getPacksDimensions: function(blocks) {
    var packsData = {};

    // бежим по всем блокам и если находим тот, который относитья к какой-либо группе
    // то заносим к себе в список что такая-то группа и изменяем ее размер
    // изначально размер группы и положение не определены, первый найденый блок группы устанавливает размеры и положение группы
    // в свои собственные, каждый последующий блок расширяет рамку и положение группы своими размерами и положением
    _.each(blocks, function(block) {
      var pack_id = block.model.get('pack_id');

      if (pack_id) {
        packsData[pack_id] = packsData[pack_id] || {
          t: Number.POSITIVE_INFINITY,
          l: Number.POSITIVE_INFINITY,
          b: Number.NEGATIVE_INFINITY,
          r: Number.NEGATIVE_INFINITY,
        };

        var pack = packsData[pack_id];

        // При рассчетах будем учитывать габариты повернутых блоков
        var boxData = block.getBoxData({ includeBoundingBox: true });

        pack.t = Math.min(pack.t, boxData.bb_y);
        pack.l = Math.min(pack.l, boxData.bb_x);
        pack.b = Math.max(pack.b, boxData.bb_y + boxData.bb_h);
        pack.r = Math.max(pack.r, boxData.bb_x + boxData.bb_w);
      }
    });

    // преобразуем координаты границ групп в координаты верхнего левого угла + ширина-высота
    _.each(packsData, function(pack) {
      pack.x = pack.l;
      pack.y = pack.t;
      pack.w = pack.r - pack.l;
      pack.h = pack.b - pack.t;

      delete pack.t;
      delete pack.l;
      delete pack.b;
      delete pack.r;
    });

    return packsData;
  },

  top: function(event) {
    // находим координаты-размеры групп виджетов
    var packsDimensions = this.getPacksDimensions(this.blocks);

    // для виджетов которые входят в группы, размеры-координаты берем не от самого виджета а от его группы
    var y = Math.min.apply(
      null,
      _.map(this.blocks, function(block) {
        var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
        var boxData = block.getBoxData({ includeBoundingBox: true });
        return packDims ? packDims.y : boxData.bb_y;
      })
    );

    // при простановке новых координат учитываем, что виджеты в группах не должны сдвигаться внутри группы
    // а должны перемещаться как единое целое
    // Так же учитываем, что повернутые виждеты надо смещать на расстояние,
    // меньшее на разницу между его реальным и габаритным размером
    this.setParam('y', function(block) {
      var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
      var boxData = block.getBoxData({ includeBoundingBox: true });
      return packDims ? boxData.y + (y - packDims.y) : y - (boxData.bb_y - boxData.y);
    });
  },

  left: function(event) {
    // находим координаты-размеры групп виджетов
    var packsDimensions = this.getPacksDimensions(this.blocks);

    // для виджетов которые входят в группы, размеры-координаты берем не от самого виджета а от его группы
    var x = Math.min.apply(
      null,
      _.map(this.blocks, function(block) {
        var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
        var boxData = block.getBoxData({ includeBoundingBox: true });
        return packDims ? packDims.x : boxData.bb_x;
      })
    );

    // при простановке новых координат учитываем, что виджеты в группах не должны сдвигаться внутри группы
    // а должны перемещаться как единое целое
    this.setParam('x', function(block) {
      var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
      var boxData = block.getBoxData({ includeBoundingBox: true });
      return packDims ? boxData.x + (x - packDims.x) : x - (boxData.bb_x - boxData.x);
    });
  },

  right: function(event) {
    // находим координаты-размеры групп виджетов
    var packsDimensions = this.getPacksDimensions(this.blocks);

    // для виджетов которые входят в группы, размеры-координаты берем не от самого виджета а от его группы
    var right = Math.max.apply(
      null,
      _.map(this.blocks, function(block) {
        var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
        var boxData = block.getBoxData({ includeBoundingBox: true });
        return packDims ? packDims.x + packDims.w : boxData.bb_x + boxData.bb_w;
      })
    );

    // при простановке новых координат учитываем, что виджеты в группах не должны сдвигаться внутри группы
    // а должны перемещаться как единое целое
    this.setParam('x', function(block) {
      var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
      var boxData = block.getBoxData({ includeBoundingBox: true });
      return packDims
        ? boxData.x + (right - packDims.w - packDims.x)
        : right - (boxData.bb_w - (boxData.bb_w - boxData.w) / 2);
    });
  },
  bottom: function(event) {
    // находим координаты-размеры групп виджетов
    var packsDimensions = this.getPacksDimensions(this.blocks);

    // для виджетов которые входят в группы, размеры-координаты берем не от самого виджета а от его группы
    var bottom = Math.max.apply(
      null,
      _.map(this.blocks, function(block) {
        var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
        var boxData = block.getBoxData({ includeBoundingBox: true });
        return packDims ? packDims.y + packDims.h : boxData.bb_y + boxData.bb_h;
      })
    );

    // при простановке новых координат учитываем, что виджеты в группах не должны сдвигаться внутри группы
    // а должны перемещаться как единое целое
    this.setParam('y', function(block) {
      var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
      var boxData = block.getBoxData({ includeBoundingBox: true });
      return packDims
        ? boxData.y + (bottom - packDims.h - packDims.y)
        : bottom - (boxData.bb_h - (boxData.bb_h - boxData.h) / 2);
    });
  },

  middle: function(event) {
    // находим координаты-размеры групп виджетов
    var packsDimensions = this.getPacksDimensions(this.blocks);

    // для виджетов которые входят в группы, размеры-координаты берем не от самого виджета а от его группы
    var middles = _.map(this.blocks, function(block) {
      var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
      var boxData = block.getBoxData({ includeBoundingBox: true });
      return packDims ? packDims.y + packDims.h / 2 : boxData.bb_y + boxData.bb_h / 2;
    });

    var middle = middles[Math.floor(middles.length / 2)];

    // при простановке новых координат учитываем, что виджеты в группах не должны сдвигаться внутри группы
    // а должны перемещаться как единое целое
    this.setParam('y', function(block) {
      var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
      var boxData = block.getBoxData({ includeBoundingBox: true });
      return packDims ? boxData.y + (middle - packDims.h / 2 - packDims.y) : middle - boxData.h / 2;
    });
  },

  center: function(event) {
    // находим координаты-размеры групп виджетов
    var packsDimensions = this.getPacksDimensions(this.blocks);

    // для виджетов которые входят в группы, размеры-координаты берем не от самого виджета а от его группы
    var max_width = 0;
    var max_width_index = 0;
    var centers = _.map(this.blocks, function(block, idx) {
      var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
      var boxData = block.getBoxData({ includeBoundingBox: true });
      var w = packDims ? packDims.w : boxData.bb_w;

      // Будем выравнивать по центру растянутого блока, либо по центру самого широкого блока
      if (block.isFullWidth() || w > max_width) {
        max_width = w;
        max_width_index = idx;
      }

      return packDims ? packDims.x + packDims.w / 2 : boxData.bb_x + boxData.bb_w / 2;
    });

    var center = centers[max_width_index];

    // при простановке новых координат учитываем, что виджеты в группах не должны сдвигаться внутри группы
    // а должны перемещаться как единое целое
    this.setParam('x', function(block) {
      var packDims = block.model.get('pack_id') && packsDimensions[block.model.get('pack_id')];
      var boxData = block.getBoxData({ includeBoundingBox: true });
      return packDims ? boxData.x + (center - packDims.w / 2 - packDims.x) : center - boxData.w / 2;
    });
  },
});

export default CommonAlignClass;
