<template>
  <div class="panel rmheader_settings">
    <div class="param-caption">
      <span>RM Header</span>
    </div>

    <rm-select
      v-model="m.activeLink"
      :options="[
        { value: 'none', caption: 'None' },
        { value: 'features', caption: 'Features' },
        { value: 'pricing', caption: 'Pricing' },
        { value: 'examples', caption: 'Examples' },
        { value: 'learn', caption: 'Learn' },
      ]"
    ></rm-select>
  </div>
</template>

<script>
import Panel from './panel.vue';
import RmSelect from '../../common/rm-select.vue';

export default {
  mixins: [Panel],
  components: { RmSelect },

  data: () => ({
    m: {
      activeLink: '',
    },
  }),
};
</script>

<style lang="less">
@import '../../../css/common/constants.less';

.controls .control .rmheader_settings {
  height: auto;
  top: -66px;
  box-sizing: border-box;
  width: 167px;
  right: 47px;
  padding: 10px 16px 26px;
  border-radius: 9px;
  background-color: rgb(242, 242, 242);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0666);
  letter-spacing: -0.08px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: #282828;
  .transition(0.2s, opacity, ease-in-out);

  .param-caption {
    position: relative;
    font-weight: 500;
    text-align: left;
    padding-bottom: 15px;
    color: #282828;
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 20px;
    position: relative;
    left: 0;
    width: 100%;
  }
}

.controls .control.rmheader_settings {
  .icon {
    background: url('../../../img/constructor/controls/shape_settings/icon.png') no-repeat 0 0,
      url('../../../img/constructor/controls/arrow.png') no-repeat 30px 0;

    &:hover {
      background: url('../../../img/constructor/controls/shape_settings/icon-hover.png') no-repeat 0 0,
        url('../../../img/constructor/controls/arrow-hover.png') no-repeat 30px 0;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  .controls .control.rmheader_settings {
    .icon {
      background-image: url('../../../img/constructor/controls/shape_settings/icon@2x.png'),
        url('../../../img/constructor/controls/arrow@2x.png');
      background-size: 30px 30px, 30px 30px;
      &:hover {
        background-image: url('../../../img/constructor/controls/shape_settings/icon-hover@2x.png'),
          url('../../../img/constructor/controls/arrow-hover@2x.png');
        background-size: 30px 30px, 30px 30px;
      }
    }
  }
}
</style>
