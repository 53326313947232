/**
 * Конструктор для контрола twitter
 */
import VueControlClass from '../vue-control';

const TwitterSettings = VueControlClass.extend({
  name: 'twitter_settings',
  alt: 'Settings',
  panelComponent: 'panel-twitter-settings',
});

export default TwitterSettings;
