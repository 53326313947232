/**
 * Тестовый конструктор для видео на пробу
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockClass from '../block';
import Widgets from '../../viewer/widgets';
import $f from 'froogaloop';

const VideoBlock = BlockClass.extend(
  {
    name: 'Video',
    sort_index: 4, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'video',

    icon_color: '#00D73E',

    MIN_WIDTH: 85,
    MIN_HEIGHT: 85,

    video_controls: ['video_settings'],

    settingsOnCreate: true,

    initialize: function(model, workspace) {
      this.controls = _.union(this.video_controls, this.controls);

      this.initBlock(model, workspace);

      if (this.model.get('info') === undefined) {
        this.model.set('info', true, { silent: true });
      }

      model.on('change:video_id', this.draw);

      this.getEmbed = Widgets.video.prototype.getEmbed;
      this.getCustomVideoPoster = Widgets.video.prototype.getCustomVideoPoster;

      _.bindAll(this);

      this.saveDebounced = _.debounce(
        _.bind(function() {
          if (this.panel) this.panel.save();
          else model.save();
        }, this),
        2000
      );
    },

    /**
     * Переопределяем метод отрисовки виджета
     */
    render: function() {
      this.create();
      this.frame.minwidth = this.MIN_WIDTH;
      this.frame.minheight = this.MIN_HEIGHT;

      this.$el.addClass('video');
      this.draw();

      this.triggerReady();
    },

    draw: function() {
      var $overlay = $('<div>').css({
        width: '100%',
        height: '100%',
        position: 'absolute',
      });

      this.$content
        .empty()
        .append($overlay)
        .append(
          this.getEmbed(
            _.extend(_.clone(this.model.attributes), { real: false }) // _.clone !! чтобы real не попал в данные блока
          )
        );

      this.$iframe = this.$('iframe');
      this.$poster = this.$('.poster');
      this.$f = null;

      if (this.model.get('video_id')) {
        this.$el.removeClass('empty');

        if (this.model.get('provider_name') === 'Vimeo') {
          this.$f = $f(this.$iframe.get(0));
          this.$f.addEvent('ready', this.setColor);
        }
      }
    },

    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      BlockClass.prototype.redraw.apply(this, arguments);

      if (model.hasChanged('info') || model.hasChanged('theme')) {
        this.draw();
      }

      if (model.hasChanged('color')) {
        this.setColor();
      }

      if (model.hasChanged('custom_thumbnail_url') || model.hasChanged('custom_thumbnail')) {
        this.onCustomThumbnailChange(model);
      }

      this.workspace.trigger('redraw');
    },

    // если добавили кастомный постер, то перезагрузим виджет,
    // если удалили, то просто уберем постер, т.к. видео iframe лежит под ним
    onCustomThumbnailChange: function(model) {
      if (
        (model.changed.custom_thumbnail === true && model.attributes.custom_thumbnail_url) ||
        model.changed.custom_thumbnail_url
      ) {
        this.draw();
      } else {
        this.$iframe && this.$iframe.css('visibility', 'visible');
        if (this.$poster) {
          this.$poster.off();
          this.$poster.remove();
        }
      }
    },

    setColor: function(color) {
      if (this.model.get('provider_name') && this.model.get('provider_name').toLowerCase() !== 'vimeo') return;

      if (!this.$f) this.$f = $f(this.$iframe.get(0));

      this.$f.api('setColor', this.model.get('color'));

      if (this.model.hasChanged('color')) this.saveDebounced();
    },

    /**
     * Переопределение родительсокго метода
     */
    css: function(changeset) {
      BlockClass.prototype.css.apply(this, arguments);

      // ресайз ифрейма с видео или кастомного превью при изменении размеров блока
      if (changeset.width) {
        this.$iframe && this.$iframe.attr('width', changeset.width);
        this.$poster && this.$poster.css({ width: changeset.width });
      }

      if (changeset.height) {
        this.$iframe && this.$iframe.attr('height', changeset.height);
        this.$poster && this.$poster.css({ height: changeset.height });
      }
    },

    getIconStyle: function(options) {
      var res = {};
      var path = RM_PUBLIC_PATH + 'img/constructor/widgetbar/icons/';
      var thumbnail_url = this.model.get('thumbnail_url');
      var icon;
      var bg;

      icon = path + 'video-overlay.svg';

      // если задан текущий ресурс (трек, юзер и пр.) и у него есть картинка
      // тогда формируем строку с multiple background, чтобы поверх маленькой превьюшки у нас
      // шел полупрозрачный оверлей, а над ним шла иконка аудио виджета (нота)
      if (thumbnail_url) {
        bg = path + 'video-overlay-bg.svg';

        res['background-image'] =
          'url(' + icon + '), url(' + bg + '), url(' + thumbnail_url.replace('-t300x300', '-t80x80') + ')';
        res['background-size'] = 'contain, cover, auto 150%';
      } else {
        res['background-image'] = 'url(' + icon + ')';
        res['background-size'] = 'contain';
        res['background-color'] = this.icon_color;
      }

      if (!options || !options.small) {
        res['border-radius'] = '8px';
      }

      return res;
    },
  },
  {
    defaults: {
      thumbnail_url: '',
    },
  }
);

export default VideoBlock;
