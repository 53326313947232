module.exports = {["template-constructor-helpers-colorbox"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="rmcolorbox '+
((__t=(show_opacity? '': 'without-opacity'))==null?'':__t)+
'" style="width:'+
((__t=(width))==null?'':__t)+
'px;height:'+
((__t=(height))==null?'':__t)+
'px;">\n		<div class="rmcolorbox-color">\n			<div class="rmcolorbox-map" style="left:'+
((__t=(hue_width))==null?'':__t)+
'px;width:'+
((__t=(map_width))==null?'':__t)+
'px;height:'+
((__t=(map_height))==null?'':__t)+
'px;">\n				<canvas width="'+
((__t=(map_width))==null?'':__t)+
'" height="'+
((__t=(map_height))==null?'':__t)+
'"></canvas>\n				<div class="rmcolorbox-map-handle"></div>\n			</div>\n			';
 if (type != 'vertical') { 
__p+='\n			<div class="rmcolorbox-map-under-arrow" style="width:'+
((__t=(hue_width))==null?'':__t)+
'px;height:'+
((__t=(map_height))==null?'':__t)+
'px;">\n				<canvas width="'+
((__t=(hue_width))==null?'':__t)+
'" height="'+
((__t=(map_height))==null?'':__t)+
'"></canvas>\n			</div>\n			';
 } 
__p+='\n\n			';
 if (type != 'vertical') { 
__p+='\n			<div class="rmcolorbox-hue" style="height:'+
((__t=(height))==null?'':__t)+
'px;width:'+
((__t=(hue_width || width))==null?'':__t)+
'px;">\n				<canvas class="rmcolorbox-hue-canvas1" width="'+
((__t=(hue_width_opened-3))==null?'':__t)+
'" height="'+
((__t=(height))==null?'':__t)+
'" style="height:'+
((__t=(height))==null?'':__t)+
'px;width:'+
((__t=(hue_width-3))==null?'':__t)+
'px;"></canvas>\n				<canvas class="rmcolorbox-hue-canvas2" width="3" height="'+
((__t=(height))==null?'':__t)+
'"></canvas>\n			</div>\n			';
 } 
__p+='\n\n			<div class="rmcolorbox-opacity">\n				<div class="rmcolorbox-opacity-handle"><div class="rmcolorbox-opacity-val">&nbsp;</div></div>\n			</div>\n		</div>\n\n\n		<div class="rmcolorbox-swatches hidden" style="width:'+
((__t=(width))==null?'':__t)+
'px;height:'+
((__t=(height))==null?'':__t)+
'px;">\n			<div class="rmcolorbox-swatches-placeholdes-wrapper" style="width:'+
((__t=(width))==null?'':__t)+
'px;height:'+
((__t=(placeholdes_height))==null?'':__t)+
'px;">\n				<div class="rmcolorbox-swatches-placeholdes" style="width:'+
((__t=(width))==null?'':__t)+
'px;"></div>\n				<div class="rmcolorbox-swatches-colors" style="width:'+
((__t=(width))==null?'':__t)+
'px;"></div>\n				<div class="rmcolorbox-swatches-scroll-handle"></div>\n			</div>\n		</div>\n\n\n		<div class="rmcolorbox-rgb-block-wrapper">\n			<div class="rmcolorbox-rgb-block input-style-1">\n				';
 if (type != 'vertical') { 
__p+=' <span>#</span> ';
 } 
__p+=' <input class="rmcolorbox-rgb-block-input color-input input-style-1"  spellcheck="false" maxlength="6" type="text"/>\n			</div>\n		</div>\n		';
 if (type != 'vertical') { 
__p+='\n		<div class="rmcolorbox-r-g-b-block-wrapper">\n			<div class="rmcolorbox-r-g-b-block input-style-2">\n				<span>R</span><input class="rmcolorbox-r-block-input color-input input-style-2"  spellcheck="false" data-param="r" maxlength="3" type="text"/><span>G</span><input class="rmcolorbox-g-block-input color-input input-style-2"  spellcheck="false" data-param="g" maxlength="3" type="text"/><span>B</span><input class="rmcolorbox-b-block-input color-input input-style-2"  spellcheck="false" data-param="b" maxlength="3" type="text"/>\n			</div>\n		</div>\n\n		';
 } 
__p+='\n\n		';
 if (type == 'vertical') { 
__p+='\n		<div class="rmcolorbox-hue" style="height:'+
((__t=(hue_height))==null?'':__t)+
'px; width: 100%;">\n			<canvas class="rmcolorbox-hue-canvas1" width="'+
((__t=(width))==null?'':__t)+
'" height="'+
((__t=(hue_height))==null?'':__t)+
'" style="height:'+
((__t=(hue_height))==null?'':__t)+
'px;width:100%;"></canvas>\n			<img src="https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/helpers/colorbox/selector.svg" class="hue-line"/>\n		</div>\n		';
 } 
__p+='\n\n		<div class="rmcolorbox-color-input-blocker hidden" style="height:'+
((__t=(bottom_panel_height))==null?'':__t)+
'px;"></div>\n		<div class="rmcolorbox-swatches-button" title="Swatches"></div>\n		<div class="rmcolorbox-add-to-swatches-button" title="Add Color to Swatches"></div>\n		<div class="rmcolorbox-remove-from-swatches-button hidden" title="Remove from Swatches"></div>\n		<div class="rmcolorbox-animation-box hidden" style="width:'+
((__t=(width))==null?'':__t)+
'px;height:'+
((__t=(height))==null?'':__t)+
'px;">\n			<canvas width="'+
((__t=(width))==null?'':__t)+
'" height="'+
((__t=(height))==null?'':__t)+
'"></canvas>\n		</div>\n	 </div>\n\n';
}
return __p;
},}