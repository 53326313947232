/**
 * Модель данных страницы
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import ModelClass from './model';
import Events from '../../common/events';
import SocketModel from './socket-model';
import WidgetModel, { WidgetsCollection } from './widget';
import Viewports from '../../common/viewports';
import { Utils } from '../../common/utils';
import TextUtils from '../../common/textutils';
import History from './history';
import Blocks from '../blocks';

var ViewportStub = ModelClass.extend({
  sync: function() {
    return {
      error: $.noop,
      success: $.noop,
    };
  },

  idAttribute: '_id',

  skipMagChanging: true,
  fakeSave: true,

  isNew: function() {
    return false;
  },
  getPageId: function() {
    return this.page.id;
  },

  parse: function(data) {
    if (data.page) {
      this.page = data.page;
      delete data.page;
    }
    return data;
  },
});

const PageClass = ModelClass.extend(
  {
    // localStorage: new Store("rm.pages"),

    idAttribute: '_id',

    urlRoot: '/api/page/',

    undoKeys: ['wids'],
    mergeUndoSteps: false,

    name: 'Page',

    initialize: function(params, options) {
      _.bindAll(this);

      options = options || {};

      this.loaded = true;
      this.trigger('loaded');

      this.on('change:wids', this.onChange);

      this.widgets = this.widgets || new WidgetsCollection([], { page: this });

      this.widgets.on('change', this.setEdited, this);
      this.on('change:wids', this.setEdited, this);

      this.viewportStub = new ViewportStub(
        { viewport: 'default', page: this, _id: this.id + '_viewport' },
        { parse: true }
      );
      this.viewportStub.on(
        'change:viewport',
        function(model, value, options) {
          if (!options || (!options.undo && !options.redo)) return;

          if (!this.get('viewport_' + value) || !this.get('viewport_' + value).enabled) {
            this.save('viewport_' + value, { enabled: true }, { patch: true }); // включаем вьюпорт если undo затрагивает изменения выключенного
          }

          this.workspace.setViewport(value, options);
        },
        this
      );

      this.on(
        'change:viewport',
        function(model, value, options) {
          if (options && (options.undo || options.redo)) {
            return;
          }
          this.viewportStub.save({ viewport: this.get('viewport') });
        },
        this
      );

      Events.trigger('ready:model', this.viewportStub);
      Events.trigger('ready:model', this);
    },

    parse: function(data, options) {
      // Нумерация страниц не должна меняться при undo/redo
      if (options && (options.undo || options.redo)) {
        data.num = this.get('num');
      }

      var dataset = _.clone(data); // не портим входные данные

      var wids = dataset.widgets || dataset.wids || []; // В разных вариантах может выдаваться сервером
      options = options || {};

      wids = this.sanitizeWidgets(wids);
      if (!_.isEmpty(wids)) {
        this.widgets = this.widgets || new WidgetsCollection([], { page: this });
        this.widgets.reset(wids, { page: this, parse: options.parse });
        dataset.wids = _.pluck(wids, '_id');

        this.checkEmpty();
      }

      delete dataset.widgets;

      return ModelClass.prototype.parse.call(this, dataset, options);
    },

    setEdited: function(model, options) {
      this.isEmptyPage = false;
    },

    // По виджету бэкграунда определяем пустая ли страница. Если пустая - то она не попадает в корзину при удалении
    checkEmpty: function() {
      if (this.widgets.length > 1) return false;

      if (!this.widgets.length) return false;

      if (this.widgets.at(0).get('type') != 'background') return false;

      if (this.widgets.at(0).get('color') == 'ffffff' && this.widgets.at(0).get('selectedType') === undefined) {
        // Пытаемся определить что страница действительно пустая
        this.isEmptyPage = true;
        return true;
      }

      return false;
    },

    toJSON: function() {
      var attrs = ModelClass.prototype.toJSON.apply(this, arguments);

      delete attrs.viewport;
      return attrs;
    },

    /**
     * Метод добавляет виджет к странице, сохраняет его и вызывает событие о добавлении
     */
    addWidget: function(params, options) {
      options = options || {};
      var widget;

      // Передали готовый виджет? (напр. глобальный)
      if (params instanceof WidgetModel) {
        widget = params;
        this.widgets.add(widget);
      } else {
        if (!params || !params.type) {
          console.error('Adding widget with no type');
          return;
        }
        if (!Blocks[params.type]) {
          console.error('Adding unknown widget');
          return;
        }

        params.pid = this.id;

        // Скрываем виджет во всех вьюпортах кроме текущего, если создали не в дефолтном
        var viewport = this.getCurrentViewport();
        if (viewport != 'default') {
          params.hidden = true;
        }
        _.each(Viewports.viewport_list, function(v) {
          if (params[v] && v.indexOf(viewport) == -1 && viewport != 'default') params[v].hidden = true;
        });

        // Для виджета карт берем апи ключ из mag.opts и подставляем в наш новый виджет
        if (params.type === 'gmaps') {
          var opts = this.workspace && this.workspace.mag && this.workspace.mag.get('opts');
          if (opts && opts.gmaps_key) {
            params.api_key = opts.gmaps_key;
          }
        }

        widget = this.widgets.add(params);
      }

      if (!widget.id) {
        widget.created = !options.copy && !options.clone;

        var viewportParams = {};
        if (this.getCurrentViewport() != 'default') {
          widget.initViewport(options);
          // В текущем вьюпорте виджет должен всегда должен показаться не скрытым
          widget['viewport_' + this.getCurrentViewport()].hidden = false;
        }

        widget.save(
          {},
          {
            success: this.getAddCallback(options),
            error: options.error,
            clone: options.clone,
          }
        );
      } else if (options.nosave) {
        this.getAddCallback(options)(widget);
      } else {
        widget.fetch({
          success: this.getAddCallback(options),
          error: options.error,
        });
      }
    },

    /**
     * Колбэк для метода addWidget
     */
    onAdd: function(widget, options) {
      if (!widget.get('type')) {
        return false;
      }

      var wids;
      wids = _.clone(this.get('wids'));
      wids.push(widget.id);
      wids = _(wids)
        .chain()
        .uniq()
        .compact()
        .value();

      if (!options || (!options.group && !options.nosave)) {
        this.save({ wids: wids });
      } else {
        // Атрибут silent очень важен для клонирования виджетов,
        // чтобы не сработали листенеры на изменение, т.к. при добавлении
        // клонированного виджета не должен создаваться и рендериться новый блок,
        // вместо этого он использует DOM-элемент, который был создан во время перетаскивания с Alt
        // См. workspace.js:cloneBlocks
        this.set({ wids: wids }, { copy: options.copy, silent: !!options.silent, isMultiple: options.isMultiple });
      }
      if (!options.silent && widget.is_global) {
        widget.triggerGlobalAppear();
      }

      this.sendAnalyticsEvent('Create Widget', {
        _widget_type: widget.get('type'),
        label: widget.get('type'),
      });
    },

    getAddCallback: function(options) {
      options = options || {};
      var self = this;

      return function(widget) {
        self.onAdd(widget, options);
        options.success && options.success(widget, options);
      };
    },

    /**
     * При изменении
     */
    onChange: function(model, wids, options) {
      var newWids = model.changedAttributes().wids;
      var oldWids = model.previous('wids') || [];

      if (newWids) {
        var addedWids = _.difference(newWids, oldWids);
        var deletedWids = _.difference(oldWids, newWids);
        _.each(
          addedWids,
          function(wid) {
            var widget = model.widgets.get(wid);

            // Глоб. виджеты обычно рендерятся динамически при заходе на воркспейс
            // Мы должны отдельно сообщить, что виджет рендерится в следствие того,
            // что он действительно добавлен (напр. при клонировании), а не просто
            // рендерится как все уже существующие глоб. виджеты
            // Это нужно, в частности, для того, чтобы виджету сдело select() после рендера
            options.is_new_global = widget.isGlobal();
            // isMultiple — добавление нескольких виджетов. Используется, например, чтобы не показывать рамку блока,
            // если выделенно несколько блоков или если блоки сгруппированы
            options.isMultiple = addedWids.length > 1 || options.isMultiple;

            widget && this.trigger('widget:create', widget, options);
            _.defer(
              function() {
                widget && widget.isGlobal() && widget.get('pid') == this.id && widget.triggerGlobalAppear();
              }.bind(this)
            );
          },
          this
        );

        _.each(
          deletedWids,
          function(wid) {
            var widget = model.widgets.get(wid);
            widget && this.trigger('widget:remove', widget, options);

            _.defer(
              function() {
                widget && widget.isGlobal() && widget.get('pid') == this.id && widget.triggerGlobalDisappear();
              }.bind(this)
            );
          },
          this
        );
      }
    },

    /**
     *  Создает копию страницы и возвращает ее в колбэке
     */
    duplicate: function(callback) {
      var self = this;
      var globalWidgetsId = [];
      RM.constructorRouter.mag.globalWidgets.each(w => {
        globalWidgetsId.push(w.get('_id'));
      });

      $.ajax({
        url: this.urlRoot + 'duplicate/',
        type: 'POST',
        dataType: 'json',
        data: {
          _id: this.get('_id'),
          num: parseInt(this.get('num'), 10) + 1,
          mid: this.get('mid'),
          globalWidgets: globalWidgetsId,
        },
        success: function(data) {
          var pageData = data.page;
          if (data.widgets) {
            // были при дублированы затронуты глобальные виджеты, их нужно обновить на клиенте
            _.each(data.widgets, w => {
              var vModel = RM.constructorRouter.mag.globalWidgets.find({ _id: w._id });
              vModel.set(w);
              vModel.save({}, { silent: true, skipHistory: true });
            });
          }
          // на сервере идет обновление данных связей виджетов по _id
          $.get(
            '/api/pageFullById/' + pageData._id,
            function(actualPageData) {
              var page = new PageClass(actualPageData, { parse: true });
              page.set('screenshot', self.get('screenshot'));

              this.sendAnalyticsEvent('Create Page', {
                _page_type: 'duplicate',
              });

              callback && callback(page);
            }.bind(this)
          ).fail(function(err, xhr) {
            alert(xhr.responseText);
          });
        },
        error: function(xhr) {
          if (xhr.status == 400) {
            try {
              var body = JSON.parse(xhr.responseText);
              if (body && body.err == 'permission_denied') {
                return callback(body);
              }
            } catch (e) {
              alert(xhr.responseText);
            }
          } else alert(xhr.responseText);
        },
        context: this,
      });
    },

    sendAnalyticsEvent: function(eventName, params) {
      var router = RM.constructorRouter || RM.collectorRouter || RM.viewerRouter || RM.homepageRouter;
      router && router.analytics && router.analytics.sendEvent(eventName, params);
    },

    /**
     * Возвращает высоту, которая вместит все виджеты
     */
    getEffectiveHeight: function() {
      var currentViewportMinHeight = this.getCurrentViewportSetting('min_height');

      // обязательно надо округлить, а то бывает получаются дробные значения
      // и панелька page-format, которая юзает эту функцию, тупит
      // например, ставит высоту 6315 (потому что getEffectiveHeight вернула 631.5)
      // вообще, это проблема плагина RMNumericInput, но он как бы предназначен только для целых чисел
      // а писать в нем округление до целого не есть правильно
      // поскольку если ему вдруг дали дробное число, значит мы где-то ошиблись в логике приложения
      // https://trello.com/c/QYag7rOv/165-viewport-photostory-austria-author-6315px
      return Math.round(
        this.widgets.reduce(function(memo, widget) {
          if (widget.get('type') == 'background') return memo;
          return Math.max((widget.get('y') || 0) + (widget.get('h') || 0), memo);
        }, currentViewportMinHeight)
      );
    },

    getWidgetsQuantity: function() {
      return this.get('wids').length;
    },

    removeWidgets: function(wids, options) {
      options = options || {};
      if (_.isEmpty(wids)) return;
      // /gwidget
      if ((options.viewport || this.getCurrentViewport()) != 'default' && !options.forceRemove) {
        this.trigger('toggleWidgets', { wids: wids, hidden: true });
        return;
      }

      this.trigger('widgets:remove', wids);

      var newWids = _.difference(this.get('wids'), wids);

      if (options.nosave) {
        this.set({ wids: newWids }, { silent: !!options.silent });
      } else {
        this.save({ wids: newWids });
      }
    },

    // также вызывается напрямую из page-sttings
    // потому что нам надо в процессе ввода знать правильно вводим или нет
    validate: function(attrs) {
      if (_.isArray(attrs.tags)) {
        attrs.tags = _.uniq(attrs.tags);
      }

      if (attrs.uri) {
        if (/(^\-)|(\-$)/.test(attrs.uri)) return { hyphen: true };

        if (/^[0-9]+$/.test(attrs.uri)) return { onlyNumbers: true };

        if (attrs.uri.length > 128) return { uriLength: true };

        var current = this;
        if (this.collection) {
          if (
            this.collection.any(function(p) {
              if (p == current) return;
              return (p.get('uri') || '').toLowerCase() == attrs.uri.toLowerCase();
            })
          )
            return { used: true };
        }
      }
    },

    groupSaveWids: function(widgets, options) {
      var wids = _.pluck(widgets, 'id');
      var currentWids = _.clone(this.get('wids'));
      options = options || {};
      this.set('wids', wids, { silent: true });
      this.save({ wids: currentWids }, options);
    },

    /**
     * Фильтрует виджеты: только те, для которых есть код в конструкторе могут быть использованы
     */
    sanitizeWidgets: function(wids) {
      return _.filter(wids, function(wgt) {
        return !!Blocks[wgt.type];
      });
    },

    getPageId: function() {
      return this.id;
    },

    renewScreenshot: function() {
      delete this.specialScreenshot;
      return this; // for chaining
    },

    getScreenshot: function(size) {
      if (!this.specialScreenshot) {
        size = Utils.screenshotSize(size || 256);

        if (this.get('screenshot')) return Utils.addFilenameComponent(this.get('screenshot'), size);
        else
          return [
            '/api/screenshot/renew',
            RM.constructorRouter.mag.get('num_id'),
            this.get('_id'),
            '?redirect=true&size=' + size,
          ].join('/');
      } else {
        return this.specialScreenshot;
      }
    },

    requestNewScreenshot: function(callback) {
      $.get(
        ['/api/screenshot/renew', RM.constructorRouter.mag.get('num_id'), this.get('_id')].join('/'),
        _.bind(function(data) {
          var screenshot = data.screenshot;

          if (screenshot) {
            this.save('screenshot', screenshot, { patch: true });
            callback();
          }
        }, this)
      );
    },

    requestActualScreenshot: function(callback) {
      $.get(
        this.urlRoot + this.id + '/screenshot',
        _.bind(function(data) {
          var screenshot = data.screenshot;

          if (screenshot) {
            this.save('screenshot', screenshot, { patch: true });
            callback();
          }
        }, this)
      );
    },

    // метод возвратит настройки вьюпорта (width, min_height...) (по умолчанию для текущего вьюпорта)
    // значения берутся из настроек вьюпорта в VIEWPORTS.JS
    getCurrentViewportSetting: function(settingName, viewportName) {
      viewportName = viewportName || this.getCurrentViewport();

      var data = _.findWhere(Viewports.viewports, { name: viewportName }) || {};

      return data[settingName];
    },

    // метод возвратит значение параметра для текущего вьюпорта (type, height)
    // значения берутся из МОДЕЛИ
    getViewportParam: function(viewport, paramName) {
      var data = this.get('viewport_' + viewport) || {};

      // если данных по вьюпорту нет, тогда возьмем данные из десктопа (this.get(paramName))
      return typeof data[paramName] !== 'undefined' ? data[paramName] : this.get(paramName);
    },

    // метод установит значение параметра для текущего вьюпорта (type, height)
    setViewportParam: function(viewport, paramName, paramValue, options) {
      var data = _.clone(this.get('viewport_' + viewport) || {}),
        changes = {};

      data[paramName] = paramValue;

      // обновляем настройки вьюпорта в модели страницы
      if (viewport === 'default') changes[paramName] = paramValue;
      else changes['viewport_' + viewport] = data;

      this.set(changes, options || {});
    },

    // метод возвратит значение параметра для текущего вьюпорта (type, height)
    // значения берутся из МОДЕЛИ
    getCurrentViewportParam: function(paramName) {
      // если данных по вьюпорту нет, тогда возьмем данные из десктопа (this.get(paramName))
      return this.getViewportParam(this.getCurrentViewport(), paramName);
    },

    // метод установит значение параметра для текущего вьюпорта (type, height)
    setCurrentViewportParam: function(paramName, paramValue, options) {
      this.setViewportParam(this.getCurrentViewport(), paramName, paramValue, options);
    },

    getViewport: function() {
      return this.getCurrentViewport();
    },

    // метод возвратит имя текущего вьюпорта в режиме которого сейчас отображается воркспейс
    getCurrentViewport: function() {
      return this.get('viewport') || 'default';
    },

    getPreviousViewport: function() {
      return this.previous('viewport') || 'default';
    },

    // возвращает кастомные шрифты использованные на странице (реально задействованые, и не просто шрифты а их конкретные начертания)
    getUsedFonts: function(params) {
      params = params || {};

      var widgets = [];

      _.each(this.widgets.models, function(widget) {
        widgets.push(widget);
      });

      return TextUtils.getUsedFontsFromWidgetsModels(_.extend(params, { models: widgets }));
    },

    getOwnGlobalWidgets: function() {
      return this.widgets.filter(
        function(w) {
          return w.isGlobal() && w.get('pid') == this.id;
        }.bind(this)
      );
    },
  },
  {
    // Статические методы модели

    // метод возвратит настройки вьюпорта (width, min_height...)
    // значения берутся из настроек вьюпорта в VIEWPORTS.JS
    getViewportSetting: function(settingName, viewportName) {
      var data = _.findWhere(Viewports.viewports, { name: viewportName }) || {};

      return data[settingName];
    },
  }
);

export const PagesCollection = Backbone.Collection.extend({
  model: PageClass,

  comparator: function(page) {
    return page.get('num');
  },

  fetchFullPage: function(id, options) {
    options = options || {};

    if (this.fetchingPageXHR) return;

    this.fetchingPageXHR = $.get(
      '/api/pageFullById/' + id,
      _.bind(function(pageData) {
        var page = this.get(id);

        if (!page) {
          page = new PageClass(pageData, { parse: true });
          RM.constructorRouter.mag.onAdd(page, options);
        }

        delete page.needUpdate;

        History.clearHistory(page.get('_id'));

        page.widgets.set(pageData.wids, { parse: true, resetPage: true });

        var wids = _.pluck(pageData.wids, '_id');
        console.log('RESET');
        page.set(_.extend({}, pageData, { wids: wids }), { resetPage: true });

        options.success && options.success();

        delete this.fetchingPageXHR;
      }, this)
    ).fail(
      _.bind(function() {
        delete this.fetchingPageXHR;
        alert('failed to load page, please, refresh the page');
        options.success && options.success();
      }, this)
    );
  },
});

export const TrashPagesCollection = Backbone.Collection.extend({
  model: PageClass,

  name: 'TrashPages',

  initialize: function() {
    this.on(
      'remove',
      function() {
        this.triggerSocketUpdate(this, this.pluck('_id'));
      },
      this
    );
  },

  triggerSocketUpdate: SocketModel.prototype.triggerSocketUpdate,
});

export default PageClass;
