/**
 * Конструктор для виджета Фейсбука
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockFrameClass from '../block-frame';
import BlockClass from '../block';
import templates from '../../../templates/constructor/blocks/facebook.tpl';
import InitUtils from '../../common/init-utils';
import { Constants } from '../../common/utils';

const FacebookBlock = BlockClass.extend(
  {
    // excludedFromLib: true, // Временно скрыто из палитры виджетов

    name: 'Facebook',
    sort_index: 10, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'facebook',

    icon_color: '#3C5A99',

    initialize: function(model, workspace) {
      var self = this,
        tmp_like_button,
        tmp_like_box;

      this.mag = workspace.mag;

      this.initBlock(model, workspace);

      // Совместимость со старым параметром send_button. Меняем его на share_button (Из-за того, что Facebook его заменил в своем плагине)
      if (this.model.get('current_type') == 'type_like_button' && this.model.get('type_like_button').send_button) {
        tmp_like_button = _.clone(this.model.get('type_like_button'));
        tmp_like_button.share_button = tmp_like_button.send_button;
        delete tmp_like_button.send_button;
        this.model.set('type_like_button', tmp_like_button, { silent: true });
      }

      // Совместимость со старым плагином like_box. Меняем его на Page
      if (!_.isEmpty(this.model.get('type_like_box'))) {
        tmp_like_box = _.clone(this.model.get('type_like_box'));
        tmp_like_box.small_header = !tmp_like_box.show_header;
        delete tmp_like_box.show_header;
        this.model.unset('type_like_box', { silent: true });
        this.model.set(
          {
            type_page: tmp_like_box,
          },
          { silent: true }
        );
        if (this.model.get('current_type') == 'type_like_box') {
          this.model.set(
            {
              current_type: 'type_page',
            },
            { silent: true }
          );
        }
      }

      this.frameClass = facebookFrame;

      this.model.validate = function(attrs) {
        var type = self.model.get('current_type');

        // Добавляем к урлу http:// при необходимости
        if (attrs[type].url) {
          attrs[type].url = self.fixUrl(attrs[type].url);
        }
      };

      this.controls = ['facebook_settings', 'common_position', 'common_layer', 'common_lock'];
    },

    render: function() {
      this.create();

      this.$el.addClass('facebook');

      // изначально отрисовываем виджет
      this.redraw(this.model, {
        skipTriggerRedraw: true,
        doNotRedrawPacksFrames: true,
        doNotRedrawBottomShiftLine: true,
      });
      this.triggerReady();
    },

    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      var type = this.model.get('current_type'),
        data = this.model.get(type),
        changed_attrs = this.model.changedAttributes(),
        // /gwidget
        was_only_moved_or_pack_unpack =
          _.isObject(changed_attrs) && _.without(_.keys(changed_attrs), 'x', 'y', 'z', 'pack_id').length == 0,
        needs_redraw_facebook = !was_only_moved_or_pack_unpack,
        types = ['type_like_button', 'type_page', 'type_comments'];

      BlockClass.prototype.redraw.apply(this, arguments);

      // Добавляем элементу блока класс текущего типа (нужно для отображения/скрытия кружков ресайза)
      this.$el.removeClass(types.join(' '));
      this.$el.addClass(type);

      // Для Like Box добавляем еще класс типа лейаута, чтобы скрывать кружки ресайза у нужных
      if (type === 'type_like_button') {
        this.$el.removeClass('standard box_count button_count').addClass(data.layout);
      }

      // Если у Like Box отключен стрим и лица, то не даем ресайзить по вертикали
      this.$el.toggleClass('no-v-resize', type === 'type_page' && !data.show_stream && !data.show_faces);

      // Для Like Box и Comments устанавливаем размеры ДО рендеринга, т.к. сам рендеринг ориентируется на них
      // (например Like Box решает, показывать лица или нет в зависимости от доступной высоты)
      // Для Like Button будем устанавливать размеры ПОСЛЕ рендеринга
      if (_(['type_page', 'type_comments']).contains(type)) {
        this.setSizeConstraints();
        if (!options || !(options.undo || options.redo)) this.doResize();
      }

      // Обновляем содержимое только если нужно
      if (needs_redraw_facebook) {
        this.redrawFacebook(model.get('w'), model.get('h'), options);
      }
    },

    // устанавливаем ограничения на минимальные размеры виджета
    setSizeConstraints: function(opts) {
      var type = this.model.get('current_type'),
        data = this.model.get(type),
        $frame,
        set_actual_size,
        min_h,
        max_h;

      // $frame = this.$el.find('div.fb');
      $frame = this.$el.find('iframe');

      if (type === 'type_like_button') {
        if ($frame.length !== 1) {
          return;
        }
        set_actual_size = data.layout === 'button_count' || data.layout === 'box_count'; // Если один из этих лэйаутов, то размеры будем выставлять по фактическим размера ифрейма
        _.extend(this.frame, {
          minwidth: set_actual_size ? $frame.width() : 285,
          maxwidth: set_actual_size ? $frame.width() : 900,
          minheight: $frame.height(),
          maxheight: $frame.height(),
        });
      } else if (type === 'type_page') {
        // Задаем минимальные размеры, в зависимости от включенных параметров
        min_h = 63;
        min_h += data.show_faces ? 122 : 0;
        min_h += data.show_header && (data.show_faces || data.show_stream) ? 30 : 0;
        min_h += data.show_stream ? 300 : 0;
        max_h = !data.show_faces & !data.show_stream ? 63 : 9999;
        _.extend(this.frame, {
          minwidth: 292,
          maxwidth: 900,
          minheight: min_h,
          maxheight: max_h,
        });
      } else if (type === 'type_comments') {
        _.extend(this.frame, {
          minwidth: 400,
          maxwidth: 900,
          minheight: 250,
          maxheight: 9999,
        });
      }

      // Если ограничения заданы в парамтерах, переопределяем
      if (opts) {
        _.extend(this.frame, opts);
      }
    },

    // Применяем ограничения к модели и сохраняем их, чтобы учесть изменения, произошедшие после очередного рендера.
    doResize: function(options) {
      if (this.model.get('w') < this.frame.minwidth) {
        this.model.set('w', this.frame.minwidth, { silent: true });
      }
      if (this.model.get('w') > this.frame.maxwidth) {
        this.model.set('w', this.frame.maxwidth, { silent: true });
      }
      if (this.model.get('h') < this.frame.minheight) {
        this.model.set('h', this.frame.minheight, { silent: true });
      }
      if (this.model.get('h') > this.frame.maxheight) {
        this.model.set('h', this.frame.maxheight, { silent: true });
      }

      // Если что-то поменяли в размерах, то сохраняем это
      if (!_.isEmpty(_.pick(this.model.changed, 'w', 'h'))) {
        this.model.save({}, { skipHistory: true, noSocketUpdate: options && options.socketUpdate });
      }

      this.frame.doResize({
        left: this.model.get('x'),
        top: this.model.get('y'),
        width: this.model.get('w'),
        height: this.model.get('h'),
      });
    },

    redrawFacebook: function(block_w, block_h, options) {
      var self = this,
        type = this.model.get('current_type'),
        data = _.clone(this.model.get(type)),
        template = templates['template-constructor-block-facebook-' + type],
        html,
        $frame,
        isFrameVisible;

      data.w = this.model.get('w');
      data.h = this.model.get('h');

      // Задаем перманентный урл мэга или страницы, чтобы он не отличался в конструкторе и на фронте
      if (data.use_own_url === 'mag') {
        data.url = this.getMagUrl();
      } else if (data.use_own_url === 'page' || !data.url) {
        data.url = this.getPageUrl();
      }

      // Дополняем урл префиксом перед рендерингом. Для старых урлов, которые ранее не дополнялись при сохранении
      data.url = this.fixUrl(data.url);

      html = template({ data: data });

      this.$content.html(html);
      this.$overlay = this.$content.find('.overlay');

      // Чтобы комменты скроллились внутри блока
      this.$content.toggleClass('scrollable', type === 'type_comments');

      InitUtils.initFacebookAPI(
        function() {
          // Парсим только наш конкретный эелемент, а не все на странице
          window.FB.XFBML.parse(
            this.$el.get(0),
            function() {
              // Задаем размеры элементам, чтобы не было пустых пространств при ресайзе
              // this.$('.fb').css('width', '100%');
              // this.$('.fb > span').css('width', '100%');
              // this.$('.fb iframe').css('width', '100%');

              // Добавляем оверлей внутрь отрендеренного дива,
              // т.к. обычный оверлей не перекрывает весь контент
              // TODO: Лучше заставить нормально перекрывать обычный оверлей
              if (type === 'type_comments') {
                this.$('.fb-comments').append($('<div class="overlay"></div>'));
              }

              // Для Like Button устанавливаем размеры ПОСЛЕ рендеринга, подгоняя их под то, что получилось
              if (type === 'type_like_button') {
                $frame = this.$el.find('iframe');
                var ticks_counter = 0,
                  max_ticks = 20;

                // Ждем, пока айфрейм не обретет размеры
                var iframeInterval = setInterval(
                  function() {
                    ticks_counter++;
                    if ($frame.height() > 0 || ticks_counter > max_ticks) {
                      clearInterval(iframeInterval);
                      this.setSizeConstraints();
                      this.doResize(options);
                    }
                  }.bind(this),
                  100
                );
              }
            }.bind(this)
          );
        }.bind(this)
      );
    },

    getMagUrl: function() {
      return this.mag && Constants.readymag_host + '/' + this.mag.get('num_id') + '/';
    },

    getPageUrl: function() {
      return Constants.readymag_host + '/' + 'p' + this.workspace.page.get('num_id') + '/';
    },

    fixUrl: function(url) {
      var new_url = url;
      if (url) {
        new_url = (/^http(s?):\/\//i.test(url) ? '' : 'http://') + url;
      }
      return new_url;
    },
  },
  {
    defaults: {
      current_type: 'type_like_button',
      x: Math.round(1024 / 2 - 61 / 2),
      w: 61,
      h: 20,

      type_like_button: {
        url: '',
        use_own_url: 'mag', // '', 'mag' или 'page' для использования текущих урлов мэга или страницы
        layout: 'button_count', // 'standard', 'button_count', 'box_count'
        share_button: false,
        show_faces: false,
        theme: 'light',
      },

      type_page: {
        url: 'https://www.facebook.com/readymag',
        show_stream: true,
        small_header: false,
        show_faces: false,
        theme: 'light',
      },

      type_comments: {
        url: '',
        use_own_url: 'mag', // '', 'mag' или 'page' для использования текущих урлов мэга или страницы
        num_posts: 5,
        theme: 'light',
      },
    },
  }
);

var facebookFrame = BlockFrameClass.extend({});

export default FacebookBlock;
