/**
 * Конструктор для виджета Form
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockFrameClass from '../block-frame';
import BlockClass from '../block';
import Viewports from '../../common/viewports';
import FormWidgetClass from '../../common/form-widget';

var DEFAULT_FIELD_HEIGHT = 48;
var DEFAULT_FIELD_GUTTER = 20;
var DEFAULT_FIELD_WIDTH_V = 286;
var DEFAULT_FIELD_WIDTH_H = 200;
var DEFAULT_FIELDS = [
  { tp: 'name', caption: 'Name', optional: false, sort: 0 },
  { tp: 'email', caption: 'Email', optional: false, sort: 1 },
];

const FormBlock = BlockClass.extend(
  {
    name: 'Form',
    sort_index: 12, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'form',

    icon_color: '#FFFFFF',

    DEFAULT_HORIZONTAL_SIZE: 40,
    DEFAULT_VERTICAL_SIZE: 300,

    // используются в Контроле настроек стиля
    MIN_FONT_SIZE: 8,
    MAX_FONT_SIZE: 999,
    MIN_LETTER_SPACING: -99,
    MAX_LETTER_SPACING: 999,

    form_controls: ['form_content', 'form_styles', 'form_endpoints'],

    viewport_fields: Viewports.viewport_fields.form,

    initialize: function(model, workspace) {
      this.mag = workspace.mag;
      this.controls = _.union(this.form_controls, this.controls);

      this.frameClass = formFrame;

      this.initBlock(model, workspace);
      this.saveThrottled = _.throttle(this.model.save.bind(this.model), 700, { leading: false });
    },

    render: function() {
      this.create();

      this.$el.addClass('form');

      // создаем и изначально отрисовываем виджет.
      this.formWidget = new FormWidgetClass({
        data: this.model.attributes,
        environment: 'constructor',
        $container: this.$content,
      });

      this.formWidget.render();

      this.minimalDimensions = this.formWidget.getMinimalDimensions();
      // Событие, которое вызывается только при добавлении блока (при рефреше — нет, хотя иконка в виджетбаре так же создаётся)
      this.triggerReady();
    },

    onCreate: function() {
      this.openContentControl();
    },

    bindEvents: function() {
      BlockClass.prototype.bindEvents.apply(this, arguments);
      this.$el.on('dblclick', this.onDoubleClick);
      this.$el.on('click', '.js-add-line', this.onAddLineClick);
      this.$el.on('click', '.js-remove-line', this.onRemoveLineClick);
    },

    css: function(params, resizePoint) {
      BlockClass.prototype.css.apply(this, arguments);

      if (params.height != undefined || params.width != undefined) {
        this.formWidget.recalcStyles({
          h: params.height,
          w: params.width,
        });
      }
    },

    /**
     * Дозаполняет недостаюшие поля из дефолтного вьюпорта
     */
    validate: function() {
      // Поля, связанные с внешним видом, иногда пропадают из вьюпорта
      var styleFields = _.difference(Viewports.viewport_fields.form, Viewports.viewport_fields_common);
      this.model.fillMissingFieldsFromDefaultViewport(styleFields);
    },

    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model)) return;
      var isHistoryStep = options.undo || options.redo;

      this.validate();

      var $inputWrapper,
        inputWrapperSize,
        layout = this.model.get('layout'),
        style = this.model.get('style'),
        gutter = this.model.get('style-' + style + '-fields').gutter,
        changed_attrs = this.model.changedAttributes(),
        prev_attrs = this.model.previousAttributes(),
        fieldsAddedCount,
        rowsAddedCount,
        dimension = layout == 'vertical' ? 'h' : 'w';

      // При смене ориентации формы нужно принудительно ре-рендерить из-за обвязки для добавления строк в текстовый инпут
      if (isHistoryStep || (changed_attrs.layout && changed_attrs.layout !== prev_attrs.layout)) {
        this.formWidget.render({ force: true });
      } else if (changed_attrs.fields) {
        // Нужно пересчитать высоту/ширину блока в соответствии с добавленными или удаленными полями,
        // и сделать полный перерендер
        fieldsAddedCount = changed_attrs.fields.length - prev_attrs.fields.length;
        rowsAddedCount =
          this.formWidget.getRowsCount(changed_attrs.fields) - this.formWidget.getRowsCount(prev_attrs.fields);

        $inputWrapper = $(this.formWidget.$('.input-wrapper').get(0));
        if (layout == 'vertical') {
          inputWrapperSize = $inputWrapper.height();
        } else {
          inputWrapperSize = $inputWrapper.width();
        }

        _.defer(
          function() {
            var delta = (inputWrapperSize + gutter) * fieldsAddedCount;
            // Если не добавлено полей, но добавлены строки, рассчитаем приращение высоты по-другому
            if (!fieldsAddedCount && rowsAddedCount) {
              delta = rowsAddedCount * this.formWidget.getLineHeight().input;
            }
            this.model.set(dimension, this.model.get(dimension) + delta);

            // Рендер тоже откладываем, чтобы лишний раз не мигало
            this.formWidget.render();
          }.bind(this)
        );
      } else {
        this.formWidget.recalcStyles();
      }

      this.minimalDimensions = this.formWidget.getMinimalDimensions();

      BlockClass.prototype.redraw.apply(this, arguments);
    },

    onDoubleClick: function() {
      this.openContentControl();
    },

    onAddLineClick: function(event) {
      this.addOrRemoveLine(event, true);
    },

    onRemoveLineClick: function(event) {
      this.addOrRemoveLine(event, false);
    },

    addOrRemoveLine: function(event, add) {
      var $wrapper = $(event.target).closest('.js-input-wrapper');
      var $input = $wrapper.find('.js-input');
      var sort = $input.data('sort');
      var fields = this.model.get('fields');
      var field = _.find(fields, { sort: sort });
      if (field) {
        var rows = field.rows || 1;
        if (add) {
          rows = rows + 1;
        } else {
          rows = rows > 1 ? rows - 1 : 1;
        }
        this.changeField({ sort: sort }, { rows: rows });
      }
      this.trigger('resize');
      this.saveThrottled();
    },

    openContentControl: function() {
      var workspaceControls = this.workspace && this.workspace.controls;
      var control = workspaceControls && workspaceControls.findControl('form_content');
      if (control) {
        control.master.select(control);
      }
    },

    setButtonHoverState: function() {
      this.formWidget.setButtonHoverState();
    },

    unsetButtonHoverState: function() {
      this.formWidget.unsetButtonHoverState();
    },

    toggleInputValue: function(show) {
      if (show) {
        this.formWidget.showInputPlaceholdersAsValues(true);
      } else {
        // Перерисуем блок полностью, вместо того чтобы просто убрать value у полей, иначе в Safari 10 (Mac OS Sierra) пропадают плейсхолдеры
        // https://trello.com/c/BMVrcRfM/293-w-form (один из пунктов в задаче)
        this.formWidget.render();
      }
    },

    getMinimalDimensions: function() {
      return this.minimalDimensions;
    },

    getDefaultDimensions: function() {
      var minDim = this.getMinimalDimensions(),
        data = this.model.attributes,
        styleFields = data['style-' + data.style + '-fields'],
        styleButton = data['style-' + data.style + '-button-default'],
        fields = this.model.get('fields');

      if (this.model.get('layout') == 'vertical') {
        return {
          width: this.DEFAULT_VERTICAL_SIZE,
          height: minDim.height,
        };
      } else {
        return {
          width: (fields.length + 1) * DEFAULT_FIELD_WIDTH_H + fields.length * styleFields.gutter + styleButton.gutter,
          height: this.DEFAULT_HORIZONTAL_SIZE,
        };
      }
    },

    changeField: function(predicate, changes) {
      // Если не использовать clone и просто получать массив полей, то в методе model.set не стриггерится события change:fields и change
      // потому что массив будет измененён до вызова model.set
      var fields = _.clone(this.model.get('fields'));
      var index = _.findIndex(fields, predicate);
      var field = fields[index];

      // Запомним предыдущие значения чтобы передать их при вызове кастомных событий
      var previousValues = {};
      _.each(changes, function(value, key) {
        previousValues[key] = _.clone(field[key]);
      });

      // Склонируем изменяемое поле по той же причине, что и выше (массив мы склонировали, а элементы в нём — нет)
      field = _.extend(_.clone(field), changes);
      // Если опции обнулены, удалим их совсем
      if (field.items === null) {
        delete field.items;
      }
      fields[index] = field;
      this.model.set('fields', fields);

      // Кастомные события вида field:caption:change, указывающее, что именно изменилось в поле.
      _.each(
        changes,
        function(value, key) {
          // Передадим предыдущее значение свойства поля: например, его желательно знать при смене типа поля на дропдаун и обратно
          this.model.trigger('field:' + key + ':change', field, previousValues[key]);
        }.bind(this)
      );
      // Общее кастомное событие по аналогии с field:add и field:delete
      this.model.trigger('field:change', field);
    },
  },
  {
    defaults: {
      layout: 'vertical',
      style: 'colored',
      'button-caption': 'Text',
      button_caption_after_submit: '',
      submit_mode: 'textAfterSubmit',
      link_after_submit: null,

      // Клонируем поля с элементами любой вложенности — там может быть большая вложенность, если есть дропдауны
      fields: JSON.parse(JSON.stringify(DEFAULT_FIELDS)),

      // Высота чтобы поместились поля по умолчанию и кнопка + расстояния между ними
      h: DEFAULT_FIELD_HEIGHT * (DEFAULT_FIELDS.length + 1) + DEFAULT_FIELD_GUTTER * DEFAULT_FIELDS.length,
      w: DEFAULT_FIELD_WIDTH_V,
      x: 'center',

      'style-colored-fields': {
        gutter: 20,
        color: 'f7f7f7',
        opacity: 100,
        'border-radius': 0,
        'border-width': 0,
        'border-color': '000000',
        'border-opacity': 100,
        'font-family': 'Roboto',
        'font-style': 'normal',
        'font-weight': 400,
        'text-color': '000000',
        'text-opacity': 100,
        'font-size': 18,
        'letter-spacing': 0,
      },

      'style-colored-button-default': {
        gutter: 0,
        color: '4c4c4c',
        opacity: 100,
        'border-radius': 'inherit',
        'border-width': 0,
        'border-color': '000000',
        'border-opacity': 100,
        'font-family': 'inherit',
        'font-style': 'inherit',
        'font-weight': 'inherit',
        'text-color': 'ffffff',
        'text-opacity': 100,
        'font-size': 'inherit',
        'letter-spacing': 'inherit',
        'text-align': 'center',
      },

      'style-colored-button-hover': {
        color: 'inherit',
        opacity: 90,
        'border-width': 'inherit',
        'border-color': 'inherit',
        'border-opacity': 'inherit',
        'text-color': 'inherit',
        'text-opacity': 'inherit',
        'text-align': 'inherit',
        transition: false,
      },

      'style-outlined-fields': {
        gutter: 20,
        color: 'ffffff',
        opacity: 0,
        'border-radius': 0,
        'border-width': 1,
        'border-color': 'e5e5e5',
        'border-opacity': 100,
        'font-family': 'Roboto',
        'font-style': 'normal',
        'font-weight': 400,
        'text-color': '000000',
        'text-opacity': 100,
        'font-size': 18,
        'letter-spacing': 0,
      },

      'style-outlined-button-default': {
        gutter: 0,
        color: 'e8e8e8',
        opacity: 60,
        'border-radius': 'inherit',
        'border-width': 0,
        'border-color': '000000',
        'border-opacity': 100,
        'font-family': 'inherit',
        'font-style': 'inherit',
        'font-weight': 'inherit',
        'text-color': '000000',
        'text-opacity': 60,
        'font-size': 'inherit',
        'letter-spacing': 'inherit',
        'text-align': 'inherit',
      },

      'style-outlined-button-hover': {
        color: 'inherit',
        opacity: 90,
        'border-width': 'inherit',
        'border-color': 'inherit',
        'border-opacity': 'inherit',
        'text-color': 'inherit',
        'text-opacity': 80,
        'text-align': 'inherit',
        transition: false,
      },

      'style-underlined-fields': {
        gutter: 20,
        'underline-width': 1,
        'underline-color': '000000',
        'underline-opacity': 100,
        'font-family': 'Roboto',
        'font-style': 'normal',
        'font-weight': 400,
        'text-color': '000000',
        'text-opacity': 100,
        'font-size': 18,
        'letter-spacing': 0,
      },

      'style-underlined-button-default': {
        gutter: 0,
        color: '000000',
        opacity: 100,
        'border-radius': 0,
        'border-width': 0,
        'border-color': '000000',
        'border-opacity': 100,
        'font-family': 'inherit',
        'font-style': 'inherit',
        'font-weight': 'inherit',
        'text-color': 'ffffff',
        'text-opacity': 100,
        'font-size': 'inherit',
        'letter-spacing': 'inherit',
        'text-align': 'inherit',
      },

      'style-underlined-button-hover': {
        color: 'inherit',
        opacity: 80,
        'border-width': 'inherit',
        'border-color': 'inherit',
        'border-opacity': 'inherit',
        'text-color': 'inherit',
        'text-opacity': 'inherit',
        'text-align': 'inherit',
        transition: false,
      },
    },
  }
);

var formFrame = BlockFrameClass.extend({
  applyExtraConstraints: function(box, resizePoint) {
    // Отличается от обычной проверки минимальной ширины и высоты тем, что в форме минимальные размеры могут меняться (?)
    var minDim = this.block.getMinimalDimensions();

    if (box.height < minDim.height) {
      box.height = minDim.height;
    }

    if (box.width < minDim.width) {
      box.width = minDim.width;
    }

    return box;
  },
});

export default FormBlock;
