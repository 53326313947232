/**
 * Конструктор для контрола alt текста картинки
 */
import VueControlClass from '../vue-control';
import PreloadDesignImages from '../../common/preload-design-images';

const picture_alt = VueControlClass.extend({
  name: 'picture_alt',
  alt: 'Alt text for picture',
  panelComponent: 'panel-picture-alt',

  select: function() {
    VueControlClass.prototype.select.apply(this, arguments);
    PreloadDesignImages('controls-picture_alt');
  },

  restrictions: function(workspace) {
    var permissions = RM.constructorRouter && RM.constructorRouter.me && RM.constructorRouter.me.get('permissions');
    // показываем контрол, только если:
    // - выбран один виджет,
    // - картинка уже загружена в виджет
    // - у пользователя есть пермишшен на это (платный план)
    var blocks = workspace.getSelectedBlocks();
    return blocks.length === 1 && blocks[0].model.get('picture') && permissions && permissions.can_use_picture_alt;
  },
});

export default picture_alt;
