/**
 * Единый объект через который надо исполнть запрос на создание мэга (как при регистрации юзера, так и из лэндинга с шаблоном, так и из профиля юзера)
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import { Constants } from '../common/utils';
import Login from '../common/login';
import Alert from './alert';
import Device from './device';

const CreateMag = function(params) {
  _.bindAll(this);
  _.extend(this, params); // router, me, template, tp

  if (RM.common.magCreationInProgress) return;

  this.analytics = this.router && this.router.analytics;

  if (!this.router) this.routerName = 'error';
  else if (this.router == RM.viewerRouter) this.routerName = 'viewer';
  else if (this.router == RM.collectorRouter) this.routerName = 'collector';
  else if (this.router == RM.homepageRouter) this.routerName = 'homepage';
  else if (this.router == RM.constructorRouter) this.routerName = 'constructor';
  else this.routerName = 'unknown';

  // noCreateRequestTracking значит что событие было затрекано уже
  // например если просят создать мэг из шаблона, а сами не залогинены
  // сначала затрекается реквест создания а потом покажется форма джойна
  // после джойна снова пойдет запрос  на создание мега, но его трекать уже не надо
  if (this.analytics && !this.noCreateRequestTracking) {
    this.analytics.sendEvent('Create Mag Request', {
      _tp:
        this.tp +
        '_' +
        (this.templateID ? 'template' : 'blank') +
        '_' +
        this.routerName +
        '_' +
        (this.me ? 'has-me' : 'no-me'),
      _template_id: this.templateID,
    });
  }

  // если функцию умудрись вызвать с мобильного - тогда показываем алерт
  if (!Device.isDesktop) {
    this.showMobilePopupWarning();
    return;
  }

  // если функцию вызвать с IE - тогда показываем алерт
  if (Modernizr.msie || Modernizr.msie11) {
    this.showIEPopupWarning();
    return;
  }

  // если нет me тогда показываем форму джойна
  // если юзер заджойнится то мег будет автоматом создан вызовом уже оттуда
  if (!this.me) {
    this.showJoinForm();
    return;
  }

  // me может быть не моделью, а просто объектом (с одним параметром permissions, смотри в login.js)
  var permissions = typeof this.me.get === 'function' ? this.me.get('permissions') : this.me['permissions'];

  if (permissions && permissions.can_create_mag) this.innerCreateMag();
  else this.showCreatePopupWarning();
};

CreateMag.prototype.innerCreateMag = function() {
  var data = {};

  if (this.analytics) {
    this.analytics.sendEvent('Create Mag', {
      _tp: this.tp + '_' + (this.templateID ? 'template' : 'blank') + '_' + this.routerName,
      _template_id: this.templateID,
    });
  }

  if (this.currentFolderId) {
    data.folder = this.currentFolderId;
  }
  if (this.sharedUser) {
    data.user = this.sharedUser;
  }

  RM.common.magCreationInProgress = true;

  $.ajax({
    url: Constants.readymag_auth_host + (this.templateID ? '/api/magFromTemplate/' + this.templateID : '/api/mag/'),
    type: 'POST',
    data: data,
    success: _.bind(function(mag) {
      if (typeof this.success === 'function') this.success();
      RM.common.magCreationInProgress = false;
      window.location.href = Constants.readymag_auth_host + '/edit/' + mag.num_id + '/contents/';
    }, this),
    error: _.bind(function() {
      if (typeof this.error === 'function') this.error();
      RM.common.magCreationInProgress = false;
      console.log(arguments);
      // __magterm
      alert('Creating project problem. Contact tech support, please: ' + Constants.SUPPORT_MAIL);
      // переходим в профиль
      if (this.me) {
        var uri = typeof this.me.get === 'function' ? this.me.get('uri') : this.me['uri'];
        if (uri) window.location.href = Constants.readymag_auth_host + '/' + uri;
      }
    }, this),
  });
};

CreateMag.prototype.showCreatePopupWarning = function() {
  if (!Alert) return;

  var alertPublisher = new Alert({
    $parent: $('body > .popups'),
    router: this.router,
    opts: { type: 'type-create' },
    alert_source: 'create mag',
  });

  alertPublisher.render();
};

CreateMag.prototype.showMobilePopupWarning = function() {
  if (!Alert) return;

  var alertMobile = new Alert({
    $parent: $('body > .popups'),
    router: this.router,
    opts: { type: 'type-switch-desktop-create' },
    alert_source: 'create mag mobile',
  });

  alertMobile.render();
};

CreateMag.prototype.showIEPopupWarning = function() {
  if (!Alert) return;

  var alertIE = new Alert({
    $parent: $('body > .popups'),
    router: this.router,
    opts: { type: 'type-browsers' },
  });

  alertIE.render();
};

CreateMag.prototype.showJoinForm = function() {
  // форма логина-регистрации-восстановления пароля
  var joinForm = new Login({
    router: this.router,
    useNavigate: false,
    $container: $('body'),
  });

  joinForm.render();
  joinForm.$el.css('z-index', 9999);

  // сохраняем в форме логина ИД шаблона из которого надо создать мег после регистрации
  joinForm.createMagParams = {
    templateID: this.templateID,
    tp: this.tp,
    noCreateRequestTracking: true,
  };

  _.delay(
    _.bind(function() {
      joinForm.switchTo('join');
      joinForm.show();
    }, this),
    100
  );
};

export default CreateMag;
