/**
 * Конструктор для контрола настройки экспорта данных форм
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlClass from '../control';
import { Constants } from '../../common/utils';
import templates from '../../../templates/constructor/controls/form_endpoints.tpl';

var _super = ControlClass.prototype;

const FormEndpoints = ControlClass.extend({
  name: 'form_endpoints', // должно совпадать с классом вьюхи

  className: 'control form_endpoints',

  events: {
    'click .connect-button:not(.disabled)': 'onConnectButtonClick',
    'click .action-icon': 'onActionIconClick',
    'input .reach-input': 'onReachInputChange',
    'change .list-select': 'onListSelectChange',
    'click .try-another': 'tryAnotherClick',
    'click .change-email': 'changeEmailClick',
    'click .cancel': 'cancelRequestClick',
    'click .resend': 'resendCodeClick',
    'click .back': 'backClick',
    'change .choice-email': 'onEmailsSelectChange',
  },

  saveOnDeselect: true,
  saveOnDestroy: true,
  GOOGLE_DEFAULT_SHEET_NAME: 'New Form',

  initialize: function(params) {
    this.template = templates['template-constructor-control-form_endpoints'];
    this.listOptionsTemplate = templates['template-constructor-control-form_endpoints-list-selector'];

    this.initControl(params);
    this.block = this.blocks[0];
    this.model = this.block.model;

    this.switchers = {};
  },

  render: function() {
    var confirmedEmailsArr = [],
      userEmailsArr = RM.constructorRouter.me.get('additionalEmails');

    if (userEmailsArr && userEmailsArr.length > 0) {
      userEmailsArr.forEach(function(confirmedEmail) {
        if (confirmedEmail.confirmed === true) {
          confirmedEmailsArr.push(confirmedEmail);
        }
      });
      this.model.set('endpoint_additonal_emails', confirmedEmailsArr);
    }

    var endpoint;
    _super.render.apply(this, arguments);
    this.createSwitchers();

    this.$confirmationView = this.$('.panel-section.second_email .confirmation');
    this.$emailNew = this.$('.panel-section.second_email .new-email');
    this.$inputWrapper = this.$('.panel-section.second_email .input-wrapper');
    this.$selectWrapper = this.$('.panel-section.second_email .choice-email');
    this.$changeEmail = this.$('.panel-section.second_email .change-email');

    this.$('.current-email').text(RM.constructorRouter.me.get('email'));

    var $sheetName = this.$('.input-sheet-name'),
      sheet_name = this.getEndpointParam('google', 'sheet_name');
    if (sheet_name) {
      $sheetName.val(sheet_name);
      this.setReachInputIcon($sheetName, 'remove-icon');
    }
    var $customUrl = this.$('.input-custom-url'),
      custom_url = this.getEndpointParam('url', 'custom_url') || '';
    $customUrl.val(custom_url);
    this.setReachInputIcon($customUrl, custom_url ? 'remove-icon' : 'hidden');

    this.$panel = this.$('.panel');

    if (RM.constructorRouter.mag.get('shared')) {
      this.$el.addClass('disabled no-hover');
    }
    this.setEmailsView();
    $(window).on('resize', this.updateSwitchers);
  },

  renderListOptions: function(lists, selected) {
    this.$('.list-select').html(this.listOptionsTemplate({ lists: lists, selected: selected, _ }));
  },

  setEndpointParam: function(provider, param, value, opts) {
    var key = 'endpoint_' + provider,
      endpoint = this.model.get(key) || {},
      extension = {},
      arr;

    // Если измеили название таблицы, удаляем айдишник ранее созданной таблицы
    // Это будет поводом для бэка создать новую таблицу
    if (provider == 'google' && param == 'sheet_name' && value !== endpoint.sheet_name) {
      delete endpoint.sheet_id;
    }

    extension[param] = value;

    if (provider == 'additonal_emails') {
      arr = _.isEmpty(endpoint) ? [] : endpoint;
      arr.push(extension);
      this.model.set(key, arr);
      return;
    }

    this.model.set(key, _.extend({}, endpoint, extension), opts);
  },

  getEndpointParam: function(provider, param) {
    var key = 'endpoint_' + provider,
      endpoint = this.model.get(key) || {};

    return endpoint[param];
  },

  onReachInputChange: function(e) {
    var $input = e instanceof $ ? e : $(e.currentTarget),
      val = $input.val().trim();

    this.setReachInputIcon($input, val && this.validateInput($input) ? 'apply-icon' : 'hidden');
  },

  validateInput: function($input) {
    var val = $input.val().trim(),
      revision = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      provider = $input.attr('data-provider');

    if (provider === 'url') {
      return /http(s?):\/\/.+/i.test(val);
    } else if (provider === 'second_email') {
      return revision.test(val);
    } else if (provider === 'confirmation-code') {
      return val.length === 4 && !isNaN(val);
    } else {
      return true;
    }
  },

  setReachInputIcon: function($input, className) {
    var $wrapper = $input.closest('.input-wrapper'),
      $icon = $wrapper.find('.action-icon'),
      ALL_CLASSES = ['apply-icon', 'remove-icon', 'preloader-icon', 'hidden'];

    $icon.removeClass(ALL_CLASSES.join(' ')).addClass(className);
    // $icon.removeClass(ALL_CLASSES.join(' '))
  },

  checkPanelPosition: function(provider) {
    if (!$('.control.form_endpoints').hasClass('checked')) {
      return;
    }

    var panelHeight = $('.panel' + '.' + provider).outerHeight(),
      windowHeight = $(window).height();

    if (windowHeight <= 768 && panelHeight >= 450) {
      $('.panel').css({ top: -Math.round(panelHeight / 2.3) });
    }

    if (panelHeight < 450) {
      //по умолчанию
      $('.panel').css({ top: -149 });
    }
  },

  createSwitchers: function() {
    var m = this.model,
      $switchers = this.$('.switcher'),
      PROVIDERS_TO_CHECK_AUTH = ['google', 'mailchimp'];

    _.each(
      $switchers,
      function(s) {
        var $s = $(s),
          provider = $s.attr('data-provider');

        // this.$('.panel-section.' + provider + ' .switcher-wrapper').toggleClass('disconnected', !endpoint)

        var switcher = (this.switchers[provider] = $s
          .RMSwitcher(
            {
              state: false, // Чтобы по updateSwitchers отработала вся логика
              height: 26,
              width: 44,
              'color-0': '#0078ff',
              'color-1': '#c9c8c9',
              'text-color-0': 'transparent',
              'text-color-1': 'transparent',
            },
            this.getSwitcherHandler(provider)
          )
          .data('switcher'));
        // handler(state)

        if (_.contains(PROVIDERS_TO_CHECK_AUTH, provider)) {
          this.checkAccountConnected(provider);
        }
      }.bind(this)
    );

    // this.onFieldsChange()
    // this.updateSwitchers()
    this.updateState();
  },

  getSwitcherHandler: function(provider) {
    return function(state) {
      _.delay(
        function() {
          // Отложено для завершения анимации переключения, иначе во время работы ajax переключатель свитчера движется неплавно (дергается)
          this.setEndpointParam(provider, 'enabled', state);
        }.bind(this),
        300
      );
    }.bind(this);
  },

  checkAccountConnected: function(provider, callback) {
    var $button = this.$('.connect-button[data-provider=' + provider + ']');
    $button.addClass('disabled');

    this.xhr = $.ajax({
      dataType: 'json',
      url: '/api/connect/forms/' + provider + '/check',
      method: 'GET',
      success: function(data) {
        this.onSuccessfulConnect(provider, data);
      }.bind(this),
      error: function() {
        this.switchers[provider].setState(false);
      }.bind(this),
      complete: function() {
        $button.removeClass('disabled');
      }.bind(this),
    });
  },

  onSuccessfulConnect: function(provider, data) {
    data = data || {};
    var $button = this.$('.connect-button[data-provider=' + provider + ']');
    $button.closest('.switcher-wrapper').removeClass('disconnected');

    if (provider == 'mailchimp' && data.accessToken) {
      this.updateMailchimpList(data);
    }
  },

  updateMailchimpList: function(connectData) {
    var $input = this.$('.input-list-name'),
      $select = this.$('.list-select');
    this.setReachInputIcon($input, 'preloader-icon');
    $select.prop('disabled', true);

    this.xhr = $.ajax({
      dataType: 'json',
      url: '/api/connect/forms/mailchimp/lists',
      method: 'GET',
      success: function(data) {
        data = data || {};
        var lists = data.lists || [];

        if (!lists.length) {
          $input.val('No lists');
          return;
        }

        var selected = this.getEndpointParam('mailchimp', 'list_id');
        this.renderListOptions(lists, selected);
        this.onListSelectChange();
        $select.prop('disabled', false);
      }.bind(this),
      error: function(xhr) {
        console.log('xhr : ', xhr);
      }.bind(this),
      complete: function() {
        this.setReachInputIcon($input, 'hidden');
      }.bind(this),
    });
  },

  onListSelectChange: function() {
    var $select = this.$('.list-select'),
      val = $select.val(),
      $option = $select.find('option:selected');

    this.$('.input-list-name').val($option.text());
    this.setEndpointParam('mailchimp', 'list_id', $option.val());
    RM.analytics && RM.analytics.sendEvent('Form Storage Delete Endpoint Param', 'mailchimp ' + $option.text());
  },

  processWindowMessage: function(e) {
    var result, provider;
    e = e.originalEvent || {};

    if (e.origin == Constants.readymag_auth_host) {
      try {
        result = JSON.parse(decodeURIComponent(e.data));
      } catch (e) {}

      if (result && result.event == 'social-auth') {
        $(window).off('message', this.processWindowMessage);
        this.AuthWindow && this.AuthWindow.close();
        this.AuthWindow = null;
        this.handleAuthResult(result.message);
      }
    }
  },

  startOauth: function(provider) {
    this.AuthWindow && this.AuthWindow.close();

    $(window).on('message', this.processWindowMessage);

    var sw = screen.width,
      sh = screen.height,
      ww = Math.min(1000, sw),
      wh = Math.min(700, sh - 40),
      wl = Math.ceil((sw - ww) / 2),
      wt = Math.ceil((sh - wh) / 2);

    this.AuthWindow = window.open(
      '/api/connect/forms/' + provider + '/connect',
      '_blank',
      [
        'toolbar=no',
        'location=' + (window.opera ? 'no' : 'yes'),
        'directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no',
        'width=' + ww,
        'height=' + wh,
        'top=' + wt,
        'left=' + wl,
      ].join()
    );
  },

  handleAuthResult: function(authResult) {
    authResult = authResult || {};
    var provider = (authResult.data || {}).provider;

    if (!provider) {
      return;
    }

    this.onSuccessfulConnect(provider, authResult.data);
    this.switchers[provider].setState(true);

    RM.analytics && RM.analytics.sendEvent('Form Storage Successful OAuth Result', provider);
  },

  onConnectButtonClick: function(e) {
    var $button = $(e.currentTarget),
      provider = $button.attr('data-provider');

    if (provider == 'google') {
      this.startOauth('google');
    }

    if (provider == 'mailchimp') {
      this.startOauth('mailchimp');
    }

    RM.analytics && RM.analytics.sendEvent('Form Storage Connect Click', provider);
  },

  onActionIconClick: function(e) {
    var $icon = $(e.currentTarget),
      $input = $icon.closest('.input-wrapper').find('input'),
      val = $input.val().trim(),
      provider = $input.attr('data-provider');

    if ($icon.hasClass('remove-icon')) {
      $input.val('');
      this.onReachInputChange($input);

      if (provider == 'google') {
        this.setEndpointParam('google', 'sheet_name', null);
        this.setEndpointParam('google', 'sheet_id', null);
      }

      if (provider == 'url') {
        this.setEndpointParam('url', 'custom_url', null);
      }

      RM.analytics && RM.analytics.sendEvent('Form Storage Delete Endpoint Param', provider);
    } else if ($icon.hasClass('apply-icon')) {
      this.setReachInputIcon($input, 'remove-icon');

      if (provider == 'google') {
        var isSameName = this.getEndpointParam('google', 'sheet_name') == val;
        if (!isSameName) {
          this.setReachInputIcon($input, 'preloader-icon');
          this.createSheet(
            val,
            function(err, sheet) {
              this.setReachInputIcon($input, err ? 'apply-icon' : 'remove-icon');
              if (!err && sheet.spreadsheetId) {
                this.setEndpointParam('google', 'sheet_name', val);
                this.setEndpointParam('google', 'sheet_id', sheet.spreadsheetId);
                RM.analytics && RM.analytics.sendEvent('Form Storage Set Endpoint Param', provider + ' "' + val + '"');
              } else {
                RM.analytics && RM.analytics.sendEvent('Form Storage Set Endpoint Param', provider + ' error');
              }
            }.bind(this)
          );
        }
      }

      if (provider == 'url') {
        this.setEndpointParam('url', 'custom_url', val);
        RM.analytics && RM.analytics.sendEvent('Form Storage Set Endpoint Param', provider + ' ' + val);
      }

      if (provider == 'second_email') {
        this.addEmail(val, $input);
        RM.analytics && RM.analytics.sendEvent('Form Storage Set Endpoint Param', provider + ' ' + val);
      }

      if (provider == 'confirmation-code') {
        this.checkConfirmationCode(val, $input);
      }
    }
  },

  addEmail: function(email, $input) {
    var data = {
      additonal_email: email,
      mag_title: RM.constructorRouter.mag.get('title') ? RM.constructorRouter.mag.get('title') : 'Project Title',
      page_number: RM.constructorRouter.workspace.getPageNum(),
    };

    this.xhr = $.ajax({
      dataType: 'json',
      url: '/api/me/addemail',
      method: 'POST',
      data: data,
      success: function() {
        if (!this.$('.panel-section.second_email .back').hasClass('hidden')) {
          this.$('.panel-section.second_email .back').addClass('hidden');
        }
        this.$confirmationView.removeClass('hidden');
        $input.val('');
        this.$('.panel-section.second_email .email-for-confirmation').text(email);
        $input.attr('data-provider', 'confirmation-code');
        this.onReachInputChange($input);
        $input.addClass('padding-right-zero'); // для того чтобы текст placeholder'a полностью поместился
        $input.attr('type', 'text'); // чтобы не появлялся pop-up о необходимости ввести @ для type="email"
        $input.attr('placeholder', 'and paste code');
        this.checkPanelPosition('second_email');
      }.bind(this),
      error: function(xhr) {
        this.$inputWrapper.addClass('hidden');
        this.$('.panel-section.second_email .error.email-for-confirmation').text(email);
        this.$('.panel-section.second_email .error-block').removeClass('hidden');
        console.log('xhr : ', xhr);
      }.bind(this),
    });
  },

  setEmailsView: function(email) {
    var emailsCount = this.model.get('endpoint_additonal_emails')
        ? this.model.get('endpoint_additonal_emails').length
        : 0,
      selectedEmail = this.getEndpointParam('selected_email', 'last_selected_email')
        ? this.getEndpointParam('selected_email', 'last_selected_email')
        : '',
      emailsArray,
      email = email || selectedEmail,
      $selectBox,
      $additionalOption,
      arr;

    if (emailsCount === 0) {
      this.setInputView();
    } else if (emailsCount === 1) {
      this.$emailNew.removeClass('hidden');
      this.$emailNew.text(email);
      if (!selectedEmail) {
        this.setEndpointParam('selected_email', 'last_selected_email', email);
      }
      this.$inputWrapper.addClass('hidden');
      this.$changeEmail.removeClass('hidden');
    } else {
      emailsArray = this.model.get('endpoint_additonal_emails');
      $selectBox = $('.panel-section.second_email .choice-email select');

      if ($selectBox.children('option').length !== 0) {
        $selectBox.children('option').remove();
      }

      arr = emailsArray.map(function(obj) {
        return obj.additonal_email;
      });

      if (selectedEmail && !arr.includes(selectedEmail)) {
        arr.push(selectedEmail);
      }

      for (var i = 0; i < arr.length; i++) {
        $additionalOption = $('<option></option>').text(arr[i]);
        $selectBox[0].add($additionalOption[0]);
      }

      $selectBox
        .children('option')
        .filter(function() {
          return $(this).text() === email;
        })
        .prop('selected', true);

      this.setEndpointParam('selected_email', 'last_selected_email', email);
      this.$inputWrapper.addClass('hidden');
      this.$changeEmail.removeClass('hidden');
      this.$selectWrapper.removeClass('hidden');
    }
  },

  checkConfirmationCode: function(code, $input) {
    var data = { confirmation_code: code },
      email = this.$('.panel-section.second_email .confirmation .confirmation.email-for-confirmation').text();

    this.xhr = $.ajax({
      dataType: 'json',
      url: '/api/me/checkcode',
      method: 'POST',
      data: data,
      success: function() {
        this.setEndpointParam('additonal_emails', 'additonal_email', email);
        this.setEmailsView(email);
        this.$confirmationView.addClass('hidden');
      }.bind(this),
      error: function(xhr) {
        $input.addClass('err-code');
        setTimeout(
          _.bind(function() {
            $input.removeClass('err-code');
            $input.val('');
            this.$('.panel-section.second_email .input-wrapper .action-icon')
              .removeClass('remove-icon')
              .addClass('hidden');
          }, this),
          3000
        );
        console.log('xhr : ', xhr);
      }.bind(this),
    });
  },

  backClick: function() {
    var email = this.getEndpointParam('selected_email', 'last_selected_email');
    this.setEmailsView(email);
    this.$('.panel-section.second_email .back').addClass('hidden');
  },

  tryAnotherClick: function() {
    this.$inputWrapper.removeClass('hidden');
    this.$('.panel-section.second_email .error-block').addClass('hidden');
  },

  setInputView: function() {
    var $input = this.$('.panel-section.second_email .input-second-email');
    $input.val('');
    $input.removeClass('padding-right-zero');
    $input.attr('type', 'email');
    $input.attr('placeholder', 'Add email');
    this.$inputWrapper.removeClass('hidden');
    $input.attr('data-provider', 'second_email');
    this.$('.panel-section.second_email .input-wrapper .action-icon')
      .removeClass('remove-icon')
      .addClass('hidden');
  },

  changeEmailClick: function() {
    var $input = this.$('.panel-section.second_email .input-second-email');

    this.$('.panel-section.second_email .back').removeClass('hidden');

    if (this.model.get('endpoint_additonal_emails').length > 1) {
      this.$('.panel-section.second_email .back').text('Back to list');
    }

    if (!this.$selectWrapper.hasClass('hidden')) {
      this.$selectWrapper.addClass('hidden');
    }
    this.setInputView();
    this.$emailNew.addClass('hidden');
    this.$changeEmail.addClass('hidden');
  },

  onEmailsSelectChange: function() {
    var selectedEmail;

    $('.choice-email option:selected').each(function() {
      selectedEmail = $(this).val();
    });

    this.setEndpointParam('selected_email', 'last_selected_email', selectedEmail);
  },

  resendCodeClick: function() {
    var data = {
      additonal_email: this.$('.panel-section.second_email .confirmation .email-for-confirmation').text(),
      mag_title: RM.constructorRouter.mag.get('title') ? RM.constructorRouter.mag.get('title') : 'Project Title',
      page_number: RM.constructorRouter.workspace.getPageNum(),
    };

    this.xhr = $.ajax({
      dataType: 'json',
      url: '/api/me/resendcode',
      method: 'POST',
      data: data,
      success: function() {
        setTimeout(
          _.bind(function() {
            this.$('.panel-section.second_email .confirmation.resend').text('Resend confirmation');
          }, this),
          4000
        );

        this.$('.panel-section.second_email .confirmation.resend').text('Confirmation sent');
      }.bind(this),
      error: function(xhr) {
        console.log('xhr : ', xhr);
      }.bind(this),
    });
  },

  cancelRequestClick: function() {
    var email = this.$('.panel-section.second_email .confirmation .email-for-confirmation').text(),
      data = {
        additonal_email: email,
      };

    this.xhr = $.ajax({
      dataType: 'json',
      url: '/api/me/cancelrequest',
      method: 'POST',
      data: data,
      success: function() {
        this.setEmailsView();
        this.$confirmationView.addClass('hidden');
      }.bind(this),
      error: function(xhr) {
        console.log('xhr : ', xhr);
      }.bind(this),
    });
  },

  createSheet: function(title, callback) {
    this.xhr = $.ajax({
      dataType: 'json',
      url: '/api/connect/forms/google/createsheet',
      method: 'POST',
      data: { title: title },
      success: function(data) {
        callback(null, data.sheet);
      }.bind(this),
      error: function(xhr) {
        console.log('xhr : ', xhr);
        callback(xhr.responseText);
      }.bind(this),
    });
  },

  setSectionCollapsed: function(section, state) {
    var $section = this.$('.panel-section.' + section);
    $section.toggleClass('collapsed', state);
    this.$panel.toggleClass(section, !state); // Для показа/скрытия разделителей. У включенной секции виден разделитель сверху и снизу
  },

  hasMailchimpFields: function() {
    var fields = this.model.get('fields');
    return _.any(fields, function(f) {
      return f.tp == 'email' && !f.optional;
    });
  },

  onFieldsChange: function() {
    this.updateState();
  },

  onEndpointsChange: function() {
    this.updateState();
  },

  getEndpointProviders: function() {
    return _.map(this.$('.switcher'), function(sw) {
      return $(sw).attr('data-provider');
    });
  },

  updateState: function() {
    var hasMailchimpFields = this.hasMailchimpFields(),
      providers = this.getEndpointProviders();

    this.allExceptEmailDisabled = true;

    _.each(
      _.without(providers, 'email'),
      function(provider) {
        var enabled = !!this.getEndpointParam(provider, 'enabled');

        if (provider == 'mailchimp') {
          if (!hasMailchimpFields) {
            this.setEndpointParam(provider, 'enabled', false, { silent: true });
            enabled = false;
          }

          // Оверлей свитчера с подсказкой
          this.$('.panel-section.mailchimp')
            .find('.switcher-wrapper')
            .toggleClass('forbidden', !hasMailchimpFields);
        }

        if (enabled) {
          this.allExceptEmailDisabled = false;
        }
      }.bind(this)
    );

    // Если все остальрные выключены, почту включаем принудительно
    if (this.allExceptEmailDisabled) {
      this.setEndpointParam('email', 'enabled', true, { silent: true });
    }

    this.updateSwitchers();
  },

  updateSwitchers: function() {
    _.each(
      _.keys(this.switchers),
      function(provider) {
        var switcher = this.switchers[provider],
          state = !!this.getEndpointParam(provider, 'enabled');

        switcher.setState(state, true);
        this.setSectionCollapsed(provider, !state);

        if (provider == 'email') {
          switcher.setDisabled(!!this.allExceptEmailDisabled);
        }

        if (state) {
          this.checkPanelPosition(provider);
        }
      }.bind(this)
    );
  },

  bindLogic: function() {
    this.listenTo(this.model, 'change:fields', this.onFieldsChange);
    this.listenTo(
      this.model,
      'change:endpoint_email change:endpoint_second_email change:endpoint_google change:endpoint_mailchimp change:endpoint_url',
      this.onEndpointsChange
    );
  },

  unBindLogic: function() {
    this.stopListening(this.model);
  },

  save: function() {
    this.model.save({});
  },

  deselect: function() {
    if (!this.getEndpointParam('google', 'sheet_id')) {
      this.setEndpointParam('google', 'enabled', false);
    }
    if (!this.getEndpointParam('mailchimp', 'list_id')) {
      this.setEndpointParam('mailchimp', 'enabled', false);
    }
    if (!this.getEndpointParam('url', 'custom_url')) {
      this.setEndpointParam('url', 'enabled', false);
    }

    this.updateState();

    _super.deselect.apply(this, arguments);
  },

  destroy: function() {
    this.xhr && this.xhr.abort();
    $(window).off('message', this.processWindowMessage);
    _super.destroy.apply(this, arguments);
  },
});

export default FormEndpoints;
