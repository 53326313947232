module.exports = {["template-viewer-toolbar"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="toolbar for-viewer">\n\n		';
	var offscreenClass = (isPreview || common.embedMode) ? '' : 'offscreen-initially'; 
__p+='\n\n		';
 if (hasPageCounter && !pageCounterInsideMenu) { 
__p+='\n			<div class="page-counter-wrapper '+
((__t=(offscreenClass))==null?'':__t)+
'">\n				<div class="page-counter"><span class="cur"></span> of <span class="total">'+
((__t=(totalPages))==null?'':__t)+
'</span></div>\n			</div>\n		';
 } 
__p+='\n\n		';
 if (hasShare) { 
__p+='\n			<div class="share-button sprite '+
((__t=(offscreenClass))==null?'':__t)+
'" data-alt="Share" data-alt-pos="bottom" data-alt-offset="9"></div>\n		';
 } 
__p+='\n\n		';
 if (hasMenu) { 
__p+='\n			<div class="menu-button '+
((__t=(offscreenClass))==null?'':__t)+
'" data-alt="Menu (M)" data-alt-pos="bottom" data-alt-offset="9">\n				';
 if (hasPageCounter && pageCounterInsideMenu) { 
__p+='\n					<div class="page-counter"><span class="cur"></span> of <span class="total">'+
((__t=(totalPages))==null?'':__t)+
'</span></div>\n				';
 } 
__p+='\n				<div class="line-1"></div>\n				<div class="line-2"></div>\n				<div class="line-3"></div>\n				<div class="page-preloader sprite"></div>\n			</div>\n		';
 } 
__p+='\n\n		';
 if (common.embedMode) { 
__p+='\n			<div class="embed-close-button '+
((__t=(offscreenClass))==null?'':__t)+
'">Close</div>\n		';
 } 
__p+='\n\n	</div>\n\n';
}
return __p;
},}