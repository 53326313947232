/**
 * Окно для работы со списками шрифтов от фонт провайдеров
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from '../../common/utils';
import TextUtils from '../../common/textutils';
import templates from '../../../templates/constructor/helpers/font-explorer.tpl';
import TypekitPopup from './typekit-popup';
import PreloadDesignImages from '../../common/preload-design-images';

var FontExplorer = Backbone.View.extend({
  events: {
    'click .view-wrapper .grid-view, .view-wrapper .list-view': 'switchView',
    'click .size-wrapper .top-letter, .size-wrapper .bottom-letter': 'onSizeLetterClick',
    'click .right-panel .providers-switcher li': 'switchProvider',
    'click .right-panel .provider-filter .language-wrapper': 'toggleLanguage',
    'click .scroll-wrapper.main .results .font-item .list-add-remove-font': 'addRemoveFont',
    'mouseenter .scroll-wrapper.main .results .font-item ': 'checkAddRemoveState',
    'click .right-panel .font-info .bottom-info .info-add-remove-font': 'addRemoveFont',
    'mousedown .scroll-wrapper.main .results .font-item': 'onFontItemMouseDown',
    'mousemove .scroll-wrapper.main .results .font-item': 'onFontItemMouseMove',
    'mouseup .scroll-wrapper.main .results .font-item': 'onFontItemMouseUp',
    'click .scroll-wrapper.main .results .font-item': 'openInfo',
    'click .search-icon': 'toggleSearch',
    'click .back-icon': 'closeInfo',
    'input .search-wrapper .search-string': 'onSearchStringChange',
    'keypress .search-wrapper .search-string': 'onSearchStringKey',
    'keyup .search-wrapper .search-string': 'onSearchStringKey',
    'keydown .search-wrapper .search-string': 'onSearchStringKey',
    'click .search-wrapper .clear': 'onClearSearchString',
    click: 'closeLanguage',
    'click .filter div': 'onFilterItemClick',
    'mouseenter .filter div': 'onFilterMouseEnter',
    'mouseleave .filter div': 'onFilterMouseLeave',
  },

  PAGINATION: 4,

  DATA_URL: window.fontsConfig.fontslist,
  IMGS_URL: window.fontsConfig.fontsimgs,

  // карта соответсвий названия или абревиатуры языка списку в charsetStrings которым мы должны его отрендерить
  // списки названий/абревиатур взяты из тайпкита, гугла и вебтайпа
  // данные из font_preview_generator.html
  LANGUAGE_MAP: {
    // для гугла (получилось что совпадают, потому что у гугла выбиралка не языка, а фактически алфавита)
    cyrillic: 'cyrillic',
    devanagari: 'devanagari',
    greek: 'greek',
    khmer: 'khmer',
    latin: 'latin',
    vietnamese: 'vietnamese',

    // для тайпкита (адревиатуры соответствуют ISO 639-1   http://ssgfi.geo-guide.de/info/tools/languagecode.html)
    ca: 'latin', // Catalan
    cs: 'latin', // Czech
    de: 'latin', // German
    en: 'latin', // English
    es: 'latin', // Spanish
    fr: 'latin', // French
    it: 'latin', // Italian
    nl: 'latin', // Dutch
    pl: 'latin', // Polish
    pt: 'latin', // Portuguese
    ru: 'cyrillic', // Russian
    sv: 'latin', //Swedish

    //для вебтайпа
    //поскольку пока нет апишки мы используем скачанные шрифты и сами формируем по ним данные
    //у нас там сейчас всего 12 шрифтов и все поддерживают только латиницу
    //latin: 'latin', у гугла уже есть такая запись
  },

  // карта преобразования абревиатуры языка в его название
  LANGUAGE_NAMES: {
    // для гугла (получилось что совпадают, потому что у гугла выбиралка не языка, а фактически алфавита)
    cyrillic: 'Cyrillic',
    devanagari: 'Devanagari',
    greek: 'Greek',
    khmer: 'Khmer',
    latin: 'Latin',
    vietnamese: 'Vietnamese',

    // для тайпкита (адревиатуры соответствуют ISO 639-1   http://ssgfi.geo-guide.de/info/tools/languagecode.html)
    ca: 'Catalan',
    cs: 'Czech',
    de: 'German',
    en: 'English',
    es: 'Spanish',
    fr: 'French',
    it: 'Italian',
    nl: 'Dutch',
    pl: 'Polish',
    pt: 'Portuguese',
    ru: 'Russian',
    sv: 'Swedish',
    mt: 'Maltese',
    sl: 'Slovenian',

    // для вебтайпа
    // поскольку пока нет апишки мы используем скачанные шрифты и сами формируем по ним данные
    // у нас там сейчас всего 12 шрифтов и все поддерживают только латиницу
    // latin: 'Latin', у гугла уже есть такая запись
  },

  FVD_NAMES: {
    n1: 'Thin',
    n2: 'ExtraLight',
    n3: 'Light',
    n4: 'Regular',
    n5: 'Medium',
    n6: 'SemiBold',
    n7: 'Bold',
    n8: 'ExtraBold',
    n9: 'Black',
    i1: 'Thin Italic',
    i2: 'ExtraLight Italic',
    i3: 'Light Italic',
    i4: 'Italic',
    i5: 'Medium Italic',
    i6: 'SemiBold Italic',
    i7: 'Bold Italic',
    i8: 'ExtraBold Italic',
    i9: 'Black Italic',
  },

  // приоритет чарсетов при показе с включеной фильтрацией по языкам
  CHARSETS_PRIORITY_FILTER_ON: ['cyrillic', 'greek', 'vietnamese', 'devanagari', 'khmer', 'latin'],

  // приоритет чарсетов при показе с ВЫключеной фильтрацией по языкам
  CHARSETS_PRIORITY_FILTER_OFF: ['latin', 'cyrillic', 'greek', 'vietnamese', 'devanagari', 'khmer'],

  // приоритет начертаний если фильтр веса установлен в regular или вообще не установлен
  FVD_PRIORITY_REGULAR: [
    'n4',
    'i4',
    'n3',
    'i3',
    'n5',
    'i5',
    'n2',
    'i2',
    'n6',
    'i6',
    'n1',
    'i1',
    'n7',
    'i7',
    'n8',
    'i8',
    'n9',
    'i9',
  ],

  // приоритет начертаний если фильтр веса установлен в light
  FVD_PRIORITY_LIGHT: [
    'n1',
    'i1',
    'n2',
    'i2',
    'n3',
    'i3',
    'n4',
    'i4',
    'n5',
    'i5',
    'n6',
    'i6',
    'n7',
    'i7',
    'n8',
    'i8',
    'n9',
    'i9',
  ],

  // приоритет начертаний если фильтр веса установлен в heavy
  FVD_PRIORITY_HEAVY: [
    'n9',
    'i9',
    'n8',
    'i8',
    'n7',
    'i7',
    'n6',
    'i6',
    'n5',
    'i5',
    'n4',
    'i4',
    'n3',
    'i3',
    'n2',
    'i2',
    'n1',
    'i1',
  ],

  initialize: function(options) {
    _.bindAll(this);

    this.template = templates['template-constructor-helpers-font-explorer'];

    this.itemTemplate = templates['template-constructor-helpers-font-explorer-item'];

    this.noFoundTemplate = templates['template-constructor-helpers-font-explorer-no-found'];

    this.applyFilter.__debounced = _.debounce(this.applyFilter, 150);

    this.loadVisibleFontPreviews.__debounced = _.debounce(this.loadVisibleFontPreviews, 300);
  },

  /**
   *
   */
  render: function() {
    this.setElement($(this.template({})).insertAfter('#constructor'));

    this.$mainList = this.$('.scroll-wrapper.main .results-wrapper .results');
    this.$infoList = this.$('.scroll-wrapper.info .results-wrapper .results');

    this.mainScroll = $(this.$mainList.closest('.scroll-wrapper'))
      .RMScroll({
        $container: this.$mainList.closest('.results-wrapper'),
        $content: this.$mainList,
        $handle: this.$mainList.closest('.scroll-wrapper').find('.scroll'),
        onScroll: this.onFontsScroll,
        gap_start: 8,
        gap_end: 8,
      })
      .data('scroll');

    this.infoScroll = $(this.$infoList.closest('.scroll-wrapper'))
      .RMScroll({
        $container: this.$infoList.closest('.results-wrapper'),
        $content: this.$infoList,
        $handle: this.$infoList.closest('.scroll-wrapper').find('.scroll'),
        gap_start: 8,
        gap_end: 8,
      })
      .data('scroll');

    var $slider = $(this.$('.size-wrapper .slider'));

    this.sizeSlider = $slider.slider({
      min: 1,
      max: 7,
      value: 4,
      orientation: 'vertical',
      start: function() {
        $('body').addClass('ns-resize-cursor');
        $slider.addClass('dragging');
      },
      stop: function() {
        $('body').removeClass('ns-resize-cursor');
        $slider.removeClass('dragging');
      },
      change: this.sizeChanged,
      slide: this.sizeChanged,
    });

    // В превью другая верстка
    if (RM.constructorRouter && RM.constructorRouter.previewMode) {
      const elementTop = Math.round(Math.abs(window.innerHeight - this.$el.height()) / 2);
      this.$el.css({
        position: 'fixed',
        'margin-top': 0,
        top: elementTop + 'px',
      });
    }

    this.loadFontsData();

    PreloadDesignImages('fontexplorer');

    this.rendered = true;
  },

  loadFontsData: function() {
    var self = this;

    var dt = new Date(),
      timestamp = dt.getDate() + '-' + (dt.getMonth() + 1) + '-' + dt.getFullYear();

    $.ajax({
      type: 'GET',
      url: this.DATA_URL + '?timestamp=' + timestamp + '&callback=?',
      jsonpCallback: 'fontsListCallback',
      contentType: 'application/json',
      cache: true,
      dataType: 'jsonp',
      success: function(data) {
        self.$('.overlay').remove();
        // Исключим устаревшие начертания для typetoday
        data.typetoday.forEach(function(item) {
          if (item.deprecated_variations && item.deprecated_variations.length) {
            item.variations = _.difference(item.variations, item.deprecated_variations);
          }
        });
        self.fontsData = data;
        self.applyFilter();
      },
      error: function(e) {
        console.log(arguments);
      },
    });
  },

  buildFilter: function() {
    var provider = this.$('.right-panel .providers-switcher li.active').attr('data-name'),
      $providerFilters = this.$('.right-panel .provider-filter[data-name="' + provider + '"]'),
      filter = {},
      isSearch = this.$('.search-icon').hasClass('active'),
      search = '';

    if (isSearch) {
      search = $.trim(this.$('.search-wrapper .search-string').val());
    } else {
      $providerFilters.find('.filter div.active').each(function() {
        var $item = $(this),
          name = $item.parent().attr('data-name'),
          value = $item.attr('data-name');

        if (!filter[name]) filter[name] = [];

        filter[name].push(value);
      });
    }

    return { provider: provider, filter: filter, search: search };
  },

  applyFilter: function() {
    if (!this.fontsData) return;

    var tmp = this.buildFilter(),
      provider = tmp.provider,
      filter = tmp.filter,
      search = tmp.search,
      data = this.fontsData[provider],
      filterName,
      dataValue,
      filterValue,
      res;

    this.$('.search-wrapper .search-string').attr('placeholder', 'Search ' + data.length + ' fonts');

    if (JSON.stringify(this.filter) == JSON.stringify(tmp)) return;

    // ищем среди всех шрифтов провайдера те, которые попадают под параметры filter
    data = _.filter(data, function(item) {
      // для вебтайпа и тайпкита параметры фильтрации лежат внутри  browse_info
      item = item.browse_info ? item.browse_info : item;
      res = true;
      for (filterName in filter) {
        filterValue = filter[filterName];
        dataValue = _.isArray(item[filterName]) ? item[filterName] : [item[filterName]];
        res = res && _.intersection(filterValue, dataValue).length;
        if (!res) return;
      }
      return res;
    });

    var searchWords = _.compact(Utils.escapeRegExp(search).split(' ')),
      searchRegExp = new RegExp(searchWords.join('.*?'), 'i');

    // ищем среди всех шрифтов провайдера те, название которых подходит под search
    data = _.filter(data, function(item) {
      // для вебтайпа и тайпкита параметры фильтрации лежат внутри  browse_info
      var name = item.name || item.family;

      return searchRegExp.test(name);
    });

    this.filteredFonts = data;
    this.filter = tmp;

    this.$mainList.empty();

    // автоматом вызовет showFilteredFontsPortion
    this.mainScroll.recalc();
  },

  showFilteredFontsPortion: function() {
    if (!this.filteredFonts) return;

    var self = this,
      retina = Modernizr.retina ? '@2x' : '';

    var curRenderedFonts = this.$mainList.find('.font-item').length,
      totalFonts = this.filteredFonts.length,
      from = curRenderedFonts,
      cnt = Math.min(totalFonts - curRenderedFonts, this.PAGINATION),
      provider = this.filter.provider,
      filter = this.filter.filter,
      weights = (provider == 'google' ? [] : filter.weight) || [],
      langs = (provider == 'google' ? filter.subsets : filter.language) || [];

    if (totalFonts == 0) {
      this.$mainList.append(this.noFoundTemplate({}));
      return;
    }

    if (cnt == 0) return;

    for (var i = from; i < from + cnt; i++) {
      var font = this.filteredFonts[i],
        fontID = escapeFontID(font.id || font.family || font.name),
        charset = getFontCharset(
          provider == 'google' ? font.subsets : font.browse_info ? font.browse_info.language : ['en'],
          langs
        ),
        fvd = getFVD(font.variations, weights),
        imageUrl = provider + '_' + fontID + '_' + charset + '_' + fvd;

      this.$mainList.append(
        this.itemTemplate({
          img1: imageUrl,
          img2: retina + '.png?t=' + font.lastModified,
          text: font.name || font.family,
          name: font.name || font.family,
          id: font.id || font.name || font.family,
          styles: font.variations.length,
          provider,
          Utils,
        })
      );
    }

    this.mainScroll.recalc();

    function escapeFontID(fontID) {
      return fontID
        .toLowerCase()
        .split(' ')
        .join('-');
    }

    function getFontCharset(fontLanguages, filterLanguages) {
      fontLanguages = filterLanguages.length ? _.intersection(fontLanguages, filterLanguages) : fontLanguages;
      fontLanguages = fontLanguages.length ? fontLanguages : ['latin'];

      var charsets = getUsedCharsets(fontLanguages),
        priority = filterLanguages.length ? self.CHARSETS_PRIORITY_FILTER_ON : self.CHARSETS_PRIORITY_FILTER_OFF;

      // просто смотрим пересечения двух массивов, поскольку порядок элементов сохраняется как в первом массиве
      // на выходе мы получим массив charsets, но отсортированный с порядке приоритетов в соответсвии с CHARSETS_PRIORITY
      // из него просто берем первый элемент!
      return _.intersection(priority, charsets)[0];
    }

    function getFVD(fontVariations, filterWeights) {
      filterWeights = filterWeights.length ? filterWeights : ['regular'];

      var weight = filterWeights[0],
        priority;

      if (weight == 'light') priority = self.FVD_PRIORITY_LIGHT;
      if (weight == 'regular') priority = self.FVD_PRIORITY_REGULAR;
      if (weight == 'heavy') priority = self.FVD_PRIORITY_HEAVY;

      // просто смотрим пересечения двух массивов, поскольку порядок элементов сохраняется как в первом массиве
      // на выходе мы получим массив charsets, но отсортированный с порядке приоритетов в соответсвии с CHARSETS_PRIORITY
      // из него просто берем первый элемент!
      return _.intersection(priority, fontVariations)[0];
    }

    // из списка поддерживаемых языков получаем список чарсетов для этих языков
    // см language_Map
    function getUsedCharsets(langs) {
      var res = {};

      for (var i = 0; i < langs.length; i++) if (self.LANGUAGE_MAP[langs[i]]) res[self.LANGUAGE_MAP[langs[i]]] = true;

      return Object.keys(res);
    }
  },

  loadVisibleFontPreviews: function() {
    var prefix = this.IMGS_URL + this.$mainList.attr('data-view') + '_',
      scrollTop = this.$mainList.parent().scrollTop(),
      scrollHeight = this.$mainList.parent().height();

    this.$mainList.find('.font-item').each(function() {
      var $item = $(this),
        fileName = prefix + $item.attr('data-img1') + $item.attr('data-img2');

      if (!$item.attr('data-loaded')) {
        var h = $item.height(),
          t = $item.position().top;

        if (t + h > scrollTop && t < scrollHeight + scrollTop) {
          $item.css('background-image', 'url("' + fileName + '")');
          $item.attr('data-loaded', true);
        }
      }
    });
  },

  onFontsScroll: function(data) {
    // если до конца прокрутки осталось менее 100 пикселей
    if (data.content_size - data.container_size - data.scroll_pos < 100) this.showFilteredFontsPortion();

    this.loadVisibleFontPreviews.__debounced();
  },

  switchView: function(e) {
    var $target = $(e.target);

    if ($target.hasClass('active')) return;

    $target
      .addClass('active')
      .siblings()
      .removeClass('active');

    var scrollTop = this.$mainList.parent().scrollTop(),
      $item,
      offset = 0;

    this.$mainList.find('.font-item').each(function() {
      $item = $(this);
      offset = $item.position().top - scrollTop;
      if (offset >= 0) return false;
    });

    this.$mainList.attr('data-view', $target.attr('data-name'));

    this.$mainList
      .find('.font-item')
      .removeAttr('data-loaded')
      .css('background-image', '');

    this.mainScroll.recalc();

    this.$mainList.parent().scrollTop($item.position().top - offset);
  },

  switchProvider: function(e) {
    var $target = $(e.target);

    if ($target.hasClass('active')) return;

    $target
      .addClass('active')
      .siblings()
      .removeClass('active');

    this.$('.provider-filter').addClass('invisible');
    this.$('.provider-filter[data-name="' + $target.attr('data-name') + '"]').removeClass('invisible');

    this.applyFilter();
  },

  onFilterItemClick: function(e) {
    var $item = $(e.target),
      $parent = $item.closest('.filter'),
      multiple = !!($parent.attr('data-multiple') - 0);

    if (!$item.attr('data-name')) return;

    $item.toggleClass('active');

    if (!multiple) $item.siblings('div').removeClass('active');

    if ($parent.hasClass('language')) this.updateLangs($parent);

    this.applyFilter();
  },

  sizeChanged: function(e, ui) {
    this.$mainList.attr('data-size', ui.value);
    this.mainScroll.recalc();

    this.$infoList.attr('data-size', ui.value);
    this.infoScroll.recalc();
  },

  onSearchStringKey: function(e) {
    e.stopPropagation();

    if (e.keyCode == $.keycodes.esc) {
      e.preventDefault();
    }

    if (e.type == 'keyup') {
      // если нажали Esc и поле фильтра не пустое, то очищаем его
      // если нажали ESC когда поле фильтра пустое, то всю панель надо закрывать

      if (e.keyCode == $.keycodes.esc) {
        // esc
        if (this.$('.search-wrapper .search-string').val() != '') {
          // долбанный ФФ не отрабатывает preventDefault на ESC если значение поля было изменено программно
          // суть бага: если мы по первому ESC очищаем поле программно(this.emptySearchInput())
          // то по второму ESC гребаный ФФ восстанавливает предыдущее значение (то которое было до программной очистки)
          // и соответственно у нас this.$('.search-input').val() != '' всегда равно true
          // потому используем хак с потерей фокуса, и повторной его установкой

          // * If the escape key is pressed when a text input has not been changed manually
          // * since being focused, the text input will revert to its previous value.
          // * Firefox does not honor preventDefault for the escape key. The revert happens
          // * after the keydown event and before every keypress.
          this.clearSearchString();
        } else {
          this.toggleSearch();
        }
        return;
      }

      if (e.keyCode == $.keycodes.enter) {
        e.preventDefault();
        this.applyFilter();
      }
    }
  },

  onSearchStringChange: function() {
    var str = this.$('.search-wrapper .search-string').val();

    this.$('.search-wrapper .clear').toggleClass('invisible', str == '');

    this.applyFilter.__debounced();
  },

  clearSearchString: function() {
    // почему делаем блюр и фокус объясняется в onInputKey
    this.$('.search-wrapper .search-string')
      .blur()
      .val('')
      .focus();

    this.onSearchStringChange();
  },

  onClearSearchString: function() {
    this.clearSearchString();

    this.toggleSearch();
  },

  toggleSearch: function() {
    clearTimeout(this.searchFocusTimeout);

    this.$('.search-icon').toggleClass('active');

    this.$('.search-wrapper').toggleClass('shift-down');

    this.$('.scroll-wrapper.main').toggleClass('shift-down');

    if (this.$('.search-icon').hasClass('active')) {
      this.searchFocusTimeout = setTimeout(
        _.bind(function() {
          this.$('.search-wrapper .search-string').focus();
        }, this),
        700
      );
    } else {
      // форсируем потерю фокуса у нашего поискового инпута
      Utils.getFocusBack();
    }

    this.applyFilter();
    setTimeout(
      function() {
        // поиск вылезает с анимацией .2s, после завершения нужно пересчитать скролл
        // не очень критичное место, решил просто таймаутом
        this.mainScroll.recalc();
      }.bind(this),
      300
    );
  },

  addRemoveFont: function(e) {
    /* var $target = $(e.target).closest('.font-item');

    var tmp = $target.attr('data-img1').split('_'),
      provider = tmp[0],
      font = $target.attr('data-font-id'),
      variations = tmp[3].substr(0, 2),
      language = tmp[2],
      tmp = [
        'provider=' + encodeURIComponent(provider),
        'font=' + encodeURIComponent(font),
        'variations=' + encodeURIComponent(variations),
        'language=' + encodeURIComponent(language)
      ]

    var url = '/build/font_preview_generator.html?' + tmp.join('&');

    window.open(url,'_blank');*/

    e.stopPropagation();

    var $target = $(e.target).closest('.font-item, .info-add-remove-font'),
      action = $target.hasClass('removable') ? 'remove' : 'add',
      provider = $target.attr('data-font-provider'),
      css_name = $target.attr('data-font-id'),
      name = $target.attr('data-font-name'),
      searchField = {};

    if (provider == 'typekit') searchField = { id: css_name };
    if (provider == 'webtype') searchField = { name: css_name };
    if (provider == 'google') searchField = { family: css_name };
    if (provider == 'typetoday') searchField = { name: css_name };

    var fontData = _.findWhere(this.fontsData[provider], searchField);

    if (action == 'remove') RM.constructorRouter.fonts.removeFonts([css_name]);
    else {
      var res = RM.constructorRouter.fonts.addFonts(
        [
          {
            provider: provider,
            css_name: css_name,
            name: name,
            variations: fontData.variations,
            temporary: true,
          },
        ],
        'font-explorer'
      );

      if (res.error)
        new TypekitPopup({
          $parent: $target.hasClass('font-item') ? $target.find('.list-add-remove-font') : $target,
        });
    }

    this.checkAddRemoveState($target);

    RM.analytics && RM.analytics.sendEvent('Font Explorer Add/Remove from Library', action);
  },

  checkAddRemoveState: function(param) {
    var $target = $(param.target || param);

    var usedFontsCache = RM.constructorRouter.fonts.getUsedFontsCache(),
      css_name = $target.attr('data-font-id'),
      usedFont = usedFontsCache[css_name],
      removable = usedFont && !usedFont.hidden;

    $target.toggleClass('removable', !!removable);

    if ($target.hasClass('info-add-remove-font'))
      $target.text(removable ? 'Remove from Font Selector' : 'Add to Font Selector');
  },

  closeInfo: function() {
    this.$('.font-explorer-wrapper').removeClass('info-mode');
  },

  onFontItemMouseDown: function() {
    this.fontItemMouseDown = true;
  },

  onFontItemMouseMove: function() {
    if (this.fontItemMouseDown) this.preventFontItemClick = true;
  },

  onFontItemMouseUp: function() {
    this.fontItemMouseDown = false;
  },

  openInfo: function(e) {
    if (this.preventFontItemClick) {
      this.preventFontItemClick = false;
      return;
    }

    var $target = $(e.target).closest('.font-item');

    this.$('.font-explorer-wrapper').addClass('info-mode');

    this.$infoList.empty();

    var self = this,
      provider = $target.attr('data-font-provider'),
      ID = $target.attr('data-font-id'),
      name = $target.attr('data-font-name');

    var searchField = {};
    if (provider == 'typekit') searchField = { id: ID };
    if (provider == 'webtype') searchField = { name: ID };
    if (provider == 'google') searchField = { family: ID };
    if (provider == 'typetoday') searchField = { name: ID };

    var font = _.findWhere(this.fontsData[provider], searchField),
      foundry = provider == 'google' ? 'Google Fonts' : font.foundry,
      url = provider == 'google' ? 'http://www.google.com/fonts/specimen/' + name.split(' ').join('+') : font.web_link,
      description = font.description,
      variations = font.variations,
      img1 = $target.attr('data-img1'),
      img2 = $target.attr('data-img2'),
      languages,
      languages_all,
      recommended,
      $languages_more_link = $('<a target="_blank" href="' + font.web_link + '">More...</a>');

    languages_all = font.subsets || (font.browse_info && font.browse_info.language) || [];
    languages_all = _.map(languages_all, function(item) {
      return Utils.capitalize(self.LANGUAGE_NAMES[item] || item);
    });

    // Заполняем список языков, вписывая их кол-во строк, умещающееся во внешнем контейнере
    var $languageContainer = $('.font-info .language-wrapper .language');
    var $clampContainer = $('.font-info .language-clamp');
    for (var i = languages_all.length; i > 0; i--) {
      languages = languages_all.slice(0, i).join(', ');

      if (i == languages_all.length) {
        // Первый проход?
        $clampContainer.empty().text(languages);
        // Все умещается?
        if ($clampContainer.height() <= $languageContainer.height()) {
          break;
        }
      }

      $clampContainer
        .empty()
        .text(languages + ', ')
        .append($languages_more_link);
      if ($clampContainer.height() <= $languageContainer.height()) {
        break;
      }
    }

    (recommended = (font.browse_info && font.browse_info.recommended_for) || []),
      (recommended = _.map(recommended, function(item) {
        return Utils.capitalize(self.LANGUAGE_NAMES[item] || item);
      }));
    recommended = recommended.join(' & ');

    for (var i = 0; i < variations.length; i++) {
      var $item = $(
        this.itemTemplate({
          img1: '',
          img2: '',
          text: TextUtils.getCustomVariationNameByFVD(font.name, variations[i]) || this.FVD_NAMES[variations[i]],
          name: '',
          id: '',
          styles: '',
          provider: '',
          Utils,
        })
      );

      var fileName = this.IMGS_URL + 'list_' + img1.substr(0, img1.length - 2) + variations[i] + img2;

      $item.css('background-image', 'url("' + fileName + '")');

      this.$infoList.append($item);

      $item.attr('data-loaded', true);

      /* (function(){
        var $i = $item;
        _.delay(function(){$i.attr('data-loaded', true)}, 50);
      })();*/
    }

    this.infoScroll.recalc();

    var $fontInfo = this.$('.right-panel .font-info'),
      $addRemoveButton = $('.bottom-info .info-add-remove-font', $fontInfo);

    $('.name', $fontInfo).text(name);
    $('.foundry', $fontInfo).toggleClass('hidden', !foundry);
    $('.foundry a', $fontInfo)
      .text(foundry)
      .attr('href', url);
    $('.description-wrapper', $fontInfo).toggleClass('hidden', !description);
    $('.description-wrapper .description', $fontInfo).text(description);
    $('.description-wrapper .more', $fontInfo).attr('href', url);
    $('.language-wrapper', $fontInfo).toggleClass('hidden', !languages);
    $('.recommended-wrapper', $fontInfo).toggleClass('hidden', !recommended);
    $('.recommended-wrapper .recommended', $fontInfo).text(recommended);

    $('.promo', $fontInfo).toggleClass('hidden', provider !== 'typetoday');
    $('.promo a', $fontInfo).attr('href', font.web_link);

    var $desc = $('.description-wrapper .description', $fontInfo);
    $('.description-wrapper', $fontInfo).toggleClass('huge', $desc.height() == parseInt($desc.css('max-height'), 10));

    $addRemoveButton
      .attr('data-font-provider', provider)
      .attr('data-font-id', ID)
      .attr('data-font-name', name);

    this.checkAddRemoveState($addRemoveButton);
  },

  toggleLanguage: function(e) {
    var $target = $(e.currentTarget);

    if ($(e.target).closest('.filter.language').length) return;

    $target.toggleClass('active');
  },

  closeLanguage: function(e) {
    if ($(e.target).closest('.language-wrapper').length) return;
    this.$('.language-wrapper').removeClass('active');
  },

  updateLangs: function($langWrapper) {
    var $langs = $langWrapper.find('div.active'),
      langs = [];

    $langs.each(function() {
      langs.push($(this).text());
    });

    $langWrapper
      .closest('.language-wrapper')
      .find('.caption')
      .text(langs.length ? langs.join(', ') : 'Language Support');
  },

  onFilterMouseEnter: function(e) {
    var $item = $(e.target);

    if (!$item.attr('data-alt')) return;

    var $caption = $item
      .parent()
      .prevAll('.filter-caption')
      .first();

    $caption.text($item.attr('data-alt'));

    clearTimeout($caption.data('clear-timer'));
  },

  onFilterMouseLeave: function(e) {
    var $item = $(e.target);

    if (!$item.attr('data-alt')) return;

    var $caption = $item
      .parent()
      .prevAll('.filter-caption')
      .first();

    // конкретно для данного заголовка ставим таймаут на его очищение
    // и сохраняем ссылку на таймер в заголовке, чтобы потом можно было отменить
    // нежно чтобы заголовок не сразу возвращался в начальное состояние, а с небольшой задержкой
    // чтобюы не было быстрых смен текстовок когда быстро водим по элементам
    $caption.data(
      'clear-timer',
      setTimeout(function() {
        $caption.text($caption.attr('data-initial'));
      }, 100)
    );
  },

  onSizeLetterClick: function(e) {
    var $item = $(e.target),
      cur = this.sizeSlider.slider('value'),
      dir = $item.hasClass('top-letter') ? 1 : -1;

    this.sizeSlider.slider('value', cur + dir);
  },

  onBodyKey: function(e) {
    if (this.$('.search-input').is(':focus')) return;

    if (e.keyCode == $.keycodes.esc || e.keyCode == $.keycodes.backspace) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.type == 'keyup') {
      if (e.keyCode == $.keycodes.esc)
        if (this.$('.font-explorer-wrapper').hasClass('info-mode')) this.closeInfo();
        else this.hide();

      if (e.keyCode == $.keycodes.backspace) {
        if (this.$('.font-explorer-wrapper').hasClass('info-mode')) this.closeInfo();
        else this.hide();
      }

      if (e.keyCode == $.keycodes.f) this.toggleSearch();
    }
  },

  onMouseWheel: function(e) {
    e && e.stopPropagation();
    e && e.preventDefault();
  },

  disableMouseWheel: function() {
    $(document).bind('mousewheel', this.onMouseWheel);
  },

  enableMouseWheel: function() {
    $(document).unbind('mousewheel', this.onMouseWheel);
  },

  show: function() {
    if (!this.rendered) {
      this.render();
      _.defer(this.show);
      return;
    }

    this.disableMouseWheel();

    this.$block_layer = RM.constructorRouter.showBlockLayer(this.hide);

    this.$el
      .removeClass('show-out')
      .addClass('show-in')
      .css('display', 'block');

    // без задержки анимация с транзишином не работает, из-за того что элемент пока еще не стал виден (js поток не завершил исполнение)
    // а транзишн не отрабатывается для невидимых элементов
    setTimeout(
      _.bind(function() {
        this.$el.removeClass('show-in');
      }, this),
      20
    );

    // прям вообще жуткий костыль, но только так и работает
    // из-за того, что происходит транзишн анимация 3d трансформации, по ее завершении
    // хоть на элементе и не остается никаких свойств с трансформациями, плашка совсем не работает в сафари и в хроме
    // транзишины не отрабатываются, фокус на поле ввода не ставится
    // эти две строчки меняют размеры блока, провоцируя его полную перерисовку, только так и заработало
    setTimeout(
      _.bind(function() {
        this.$el.css('width', '+=1');
      }, this),
      300
    );
    setTimeout(
      _.bind(function() {
        this.$el.css('width', '-=1');
      }, this),
      300
    );

    $('body').on('keypress keyup keydown', this.onBodyKey);

    this.shown = true;
  },

  hide: function(e) {
    e && e.stopPropagation();

    this.enableMouseWheel();

    RM.constructorRouter.hideBlockLayer(this.$block_layer);

    $('body').off('keypress keyup keydown', this.onBodyKey);

    this.$el.addClass('show-out');
    setTimeout(
      _.bind(function() {
        this.$el.css('display', 'none');
      }, this),
      200
    );

    // форсируем потерю фокуса у нашего поискового инпута
    Utils.getFocusBack();

    this.$('.language-wrapper').removeClass('active');

    RM.constructorRouter.fonts.clearTempFonts();

    this.shown = false;
  },

  toggle: function() {
    if (this.shown) {
      this.hide();
    } else {
      this.show();
    }
    this.trigger('toggle');
  },
});

export default FontExplorer;
