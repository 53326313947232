//все написано через жопу
//Надо переделать на VUE!
(function($) {
  $.fn.RMSwitcher = function(options, onChange) {
    var $elem = $(this);

    var switcher = new Switcher(options, onChange);

    $elem.data('switcher', switcher);

    return this;

    function Switcher(options, onChange) {
      'use strict';

      this.settings = {
        width: 64,
        height: 28,
        shadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.32)',
        'color-0': '#1ce4c8',
        'text-0': 'On',
        'text-size-0': 12,
        'text-color-0': '#ffffff',
        'color-1': '#989ea8',
        'text-1': 'Off',
        'text-size-1': 12,
        'text-color-1': '#ffffff',
        'handle-color-top': '#f5f5f5',
        'handle-color-bottom': '#e7e7e7',
        'handle-shadow': '0 0 0 1px rgba(40, 40, 40, 0.12)',
        state: false,
      };

      var $panel, $body, $panelBack, timer, isDisabled;

      if (options) $.extend(this.settings, options);

      this.settings['color-array-0'] = convertColorToArray(this.settings['color-0']);
      this.settings['color-array-1'] = convertColorToArray(this.settings['color-1']);

      var handle_size = this.settings.height - 2;
      var full_width = (this.settings.width - handle_size - 1) * 2 + handle_size;
      var text_width = Math.round(this.settings.width * 0.75 - 8 - 8);
      var shift_width = this.settings.width - full_width;

      var body_style =
        '-webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; position:relative; cursor:pointer; width:' +
        this.settings.width +
        'px; height:' +
        this.settings.height +
        'px; box-shadow:' +
        this.settings.shadow +
        '; overflow:hidden; border-radius: ' +
        Math.floor(this.settings.height / 2) +
        'px;';
      var panel_style = 'position:relative; top:0; width:' + full_width + 'px; height:' + this.settings.height + 'px;';
      var text_style =
        'position:absolute; top:0; width:' +
        text_width +
        'px; white-space:nowrap; text-align:center; overflow:hidden; text-overflow:ellipsis; line-height:' +
        this.settings.height +
        'px;';
      var text0_style =
        text_style +
        "font-family:'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:" +
        this.settings['text-size-0'] +
        'px; color:' +
        this.settings['text-color-0'] +
        '; left:6px';
      var text1_style =
        text_style +
        "font-family:'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:" +
        this.settings['text-size-1'] +
        'px; color:' +
        this.settings['text-color-1'] +
        '; right:6px';
      var c1 = this.settings['handle-color-top'];
      var c2 = this.settings['handle-color-bottom'];
      var handle_color =
        'background: ' +
        colorTransform(convertColorToArray(c1), convertColorToArray(c2), 0.5) +
        '; background: -webkit-gradient(linear, left top, left bottom, from(' +
        c1 +
        '), to(' +
        c2 +
        ')); background: -moz-linear-gradient(top,  ' +
        c1 +
        ',  ' +
        c2 +
        '); background: -o-linear-gradient(top,  ' +
        c1 +
        ',  ' +
        c2 +
        '); background: -ms-linear-gradient(top,  ' +
        c1 +
        ',  ' +
        c2 +
        ');';
      var handle_style =
        'position:absolute; top:1px; left:50%; margin-left:-' +
        Math.floor(handle_size / 2) +
        'px;width:' +
        handle_size +
        'px; height:' +
        handle_size +
        'px; border-radius: ' +
        Math.floor(handle_size / 2) +
        'px; box-shadow:' +
        this.settings['handle-shadow'] +
        '; ' +
        handle_color;

      var s =
        '<div class="rmswitcher-body" style="' +
        body_style +
        '">\
						<div class="rmswitcher-panel-back"></div>\
						<div class="rmswitcher-panel" style="' +
        panel_style +
        '">\
							<div class="rmswitcher-panel-on" style="' +
        text0_style +
        '">' +
        this.settings['text-0'] +
        '</div>\
							<div class="rmswitcher-panel-off" style="' +
        text1_style +
        '">' +
        this.settings['text-1'] +
        '</div>\
							<div class="rmswitcher-panel-slider" style="' +
        handle_style +
        '"></div>\
						</div>\
					 </div>';
      // rmswitcher-panel-back переопределяется на уровне конкретного контекста использования.
      // Нужен для задания картиночных фонов панельке переключателя

      $elem.append(s);

      $body = $elem.find('.rmswitcher-body');
      $panel = $elem.find('.rmswitcher-panel');
      $panelBack = $elem.find('.rmswitcher-panel-back');
      $panel.on('click', onClick);

      var that = this;

      setSwitcherPos(this.settings.state ? 0 : 1);

      //публичные методы
      this.setState = setState;
      this.setDisabled = setDisabled;
      this.setUndefinedState = setUndefinedState;

      if (this.settings.disabled) {
        this.setDisabled(true);
      }

      function onClick() {
        if (isDisabled) return;
        setState(!that.settings.state);
      }

      function setState(state, silent) {
        if (that.settings.state != state && typeof onChange == 'function')
          if (!silent) {
            that.settings.state = state;
            onChange(state, that);
          }
        that.settings.state = state;
        clearInterval(timer);
        timer = setInterval(function() {
          oneStepSwitcherPos(state);
        }, 20);
      }

      function oneStepSwitcherPos(state) {
        state = state ? 0 : 1;
        var dir = state < that.settings.cur_state ? -1 : 1;
        var new_state = Math.max(Math.min(that.settings.cur_state + 0.1 * dir, 1), 0);
        if ((dir > 0 && new_state >= state) || (dir < 0 && new_state <= state)) {
          new_state = state;
          clearInterval(timer);
        }
        setSwitcherPos(new_state);
      }

      function setSwitcherPos(percent) {
        that.settings.cur_state = percent;
        $body.css(
          'background-color',
          colorTransform(that.settings['color-array-0'], that.settings['color-array-1'], percent)
        );
        $panel.css('left', Math.round(shift_width * percent));
        $panelBack.css('opacity', (1 - percent).toFixed(2));
      }

      function convertColorToArray(color) {
        return [
          parseInt(color.substring(1, 3), 16),
          parseInt(color.substring(3, 5), 16),
          parseInt(color.substring(5, 7), 16),
        ];
      }

      function colorTransform(color1, color2, percent) {
        return (
          'rgb(' +
          Math.max(Math.min(parseInt(percent * (color2[0] - color1[0]) + color1[0], 10), 255), 0) +
          ',' +
          Math.max(Math.min(parseInt(percent * (color2[1] - color1[1]) + color1[1], 10), 255), 0) +
          ',' +
          Math.max(Math.min(parseInt(percent * (color2[2] - color1[2]) + color1[2], 10), 255), 0) +
          ')'
        );
      }

      function setDisabled(val) {
        if (val === undefined) {
          return;
        }

        val = !!val;
        isDisabled = val;
        $body.css({
          opacity: val ? 0.5 : 1,
          cursor: val ? 'default' : 'pointer',
        });
      }

      /**
       * Устанавливает свитчер в неопределенное состояние - посредине
       * Только визуально! Внутреннее состояние не изменяется
       */
      function setUndefinedState() {
        setSwitcherPos(0.5);
        that.settings.state = undefined;
      }

      return this;
    }
  };
})(jQuery);
