/**
 * набор полезных функций и констант
 * для работы с виджетом Кнопки.
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import { Utils } from '../common/utils';

const ButtonUtils = {
  // префиксы для генерации стилей
  STYLE_PREFIXES: {
    viewer: ['.rmwidget.widget-button .common-button'],
    // У этого селектора должна быть такая же специфичность, как .rmwidget.widget-button .common-button.hovered, иначе к current-кнопки не будет применяться ховер-состояние
    viewerCurrentLink: ['.maglink.current .widget-button .common-button'],
    constructor: [
      '.block.button .common-button',
      // + для Контрола стилей Кнопки в будущем
      // надо будет сделать.
      // зависит от того, как там стили будут
      // представлены — названиями (тут не надо ничего будет делать)
      // или имитацией Кнопки.
    ],
  },

  // генерирует <style> с CSS для
  // индивидуального Стиля Кнопки.
  // индивидуальные стили Кнопки задаются через
  // Контрол Настроек Кнопки — иконка с ползунками.
  generateIndividualStyleCSS: function(params) {
    var selectors = this.STYLE_PREFIXES[params.env],
      model = params.model,
      button_type = model.tp,
      self = this;
    var generate = function(state) {
      state = state || 'default';

      var cssClassMap = {
        default: '',
        hover: 'hovered',
        current: 'current',
      };

      var prefixMap = {
        default: '',
        hover: 'hover-',
        current: 'current-',
      };

      var cssClass = !_.isUndefined(cssClassMap[state]) ? cssClassMap[state] : cssClassMap.default;
      var prefix = !_.isUndefined(prefixMap[state]) ? prefixMap[state] : prefixMap.default;

      // стиль для Кнопки и текста (текст наследует все что нужно от Кнопки).
      var s =
        _.map(selectors, function(selector) {
          return selector + '[data-id="' + model._id + '"]' + (cssClass ? '.' + cssClass : '');
        }).join(',\n') + ' {\n';

      s += self.generateStylesStr({
        model: model,
        attrPrefix: prefix,
        indentation: true,
        lineBreaks: true,
      });

      s += '}\n\n';

      if (params.env === 'viewer') {
        // Во вьюере current-состояние присваивается ссылке, в которую обёрнута кнопка, а не самой кнопке. Сгенерим правило вида .maglink.current .common_button { ... }
        if (state === 'current') {
          s += self.STYLE_PREFIXES.viewerCurrentLink + '[data-id="' + model._id + '"] {\n';
          s += self.generateStylesStr({
            model: model,
            attrPrefix: prefix,
            indentation: true,
            lineBreaks: true,
          });
          s += '}\n\n';
        }

        // только во Вьювере генерим стиль для Иконки,
        // в Конструкторе через js SVG-шкой управляем.
        if (button_type === 'icon' || button_type === 'text_and_icon') {
          // ховер стиль для Иконки.
          s +=
            _.map(selectors, function(selector) {
              return selector + '[data-id="' + model._id + '"]' + (cssClass ? '.' + cssClass : '') + ' .icon';
            }).join(',\n') + ' {\n';

          s +=
            '\t ' +
            'background-image: url("' +
            (Modernizr.retina ? model[prefix + 'icon_raster2xUrl'] : model[prefix + 'icon_rasterUrl']) +
            '");\n';

          s += '}\n\n';

          if (state === 'current') {
            s += self.STYLE_PREFIXES.viewerCurrentLink + '[data-id="' + model._id + '"] .icon {\n';
            s +=
              '\t ' +
              'background-image: url("' +
              (Modernizr.retina ? model[prefix + 'icon_raster2xUrl'] : model[prefix + 'icon_rasterUrl']) +
              '");\n';
            s += '}\n\n';
          }
        }
      }

      return s;
    };

    var s = generate('default');

    // Current перед ховером, чтобы ховер перекрывал current
    var hasCurrent = _.find(_.keys(model), function(key) {
      return /^current/.test(key);
    });
    // Если есть атрибуты для текущего стиля, сгенерировать
    if (hasCurrent) {
      s += generate('current');
    }

    s += generate('hover');

    var id = 'individual_button_style_' + model._id + '_' + params.env;

    // удаляем прежний набор стилей.
    $('#' + id).remove();

    var style = document.createElement('style');
    style.type = 'text/css';
    style.id = id;
    style.className = 'button_styles';
    style.appendChild(document.createTextNode(s));
    document.getElementsByTagName('head')[0].appendChild(style);
  },

  // из объекта со стилями генерирует css-нотацию этих стилей.
  generateStylesStr: function(params) {
    var s = '',
      color,
      size,
      model = params.model,
      pre = params.indentation ? '\t' : '',
      post = ';' + (params.lineBreaks ? '\n' : ''),
      attrPrefix = params.attrPrefix || '';

    // Shape Color
    s +=
      pre +
      'background-color: ' +
      Utils.getRGBA(attr('background-color'), attr('background-color-opacity') / 100) +
      post;

    // Radius
    s += pre + 'border-radius: ' + px(attr('border-radius')) + post;

    // Border
    s += pre + 'border-width: ' + px(attr('border-width')) + post;

    // Border Color
    s += pre + 'border-color: ' + Utils.getRGBA(attr('border-color'), attr('border-color-opacity') / 100) + post;

    // Font Family param
    s += pre + 'font-family: ' + attr('font-family') + post;

    // Font Style param
    s += pre + 'font-weight: ' + attr('font-weight') + post; // bold || normal
    s += pre + 'font-style: ' + attr('font-style') + post; // italic || normal

    // Text Color
    s += pre + 'color: ' + Utils.getRGBA(attr('color'), attr('color-opacity') / 100) + post;

    // Text Size
    s += pre + 'font-size: ' + px(attr('font-size')) + post;

    // Letter Spacing
    s += pre + 'letter-spacing: ' + px(attr('letter-spacing')) + post;

    return s;

    function attr(s) {
      var res = model[attrPrefix + s];

      // ховер-аттрибуты стиля для Ховер-состоянии Кнопки (например, hover-background-color)
      // наследуются от обычного состояния, если
      // у ховер-аттрибута стиля значение 'inherit' или
      // ховер-аттрибут стиля вовсе не сохраняетя в модель,
      // т.к. никогда не изменяется в ховер состоянии (например, letter-spacing),
      // и тогда model[attrPrefix + s] выдаст undefined.
      if ((res === 'inherit' && attrPrefix) || res === undefined) res = model[s];

      // т.к. у нас у бордера транзишен может быть,
      // то надо делать следующую проверку:
      // если в одном из состояний у бордера нулевой размер,
      // то тут в стилях мы его цвет подменяем на цвет из противоположного состояния,
      // чтобы транзишен цвета нормально прошел.
      // а то транзишен из нуля ширины пойдет с изначальным цветом этого состояния,
      // но цвета-то то этого и в помине не видно нигде.
      // (Для current-состояния не делаем)
      if (s === 'border-color' && attrPrefix === 'hover-') {
        if (model['hover-border-width'] == 0) {
          res = model['border-color'];
        }
      } else if (s === 'border-color' && attrPrefix !== 'current-') {
        if (model['border-width'] == 0) {
          res = model['hover-border-color'];
        }
      }

      // то же самое для прозрачности цвета, как и выше.
      if (s === 'border-color-opacity' && attrPrefix === 'hover-') {
        if (model['hover-border-width'] == 0) {
          res = model['border-color-opacity'];
        }
      } else if (s === 'border-color-opacity') {
        if (model['border-width'] == 0) {
          res = model['hover-border-color-opacity'];
        }
      }

      return res;
    }

    function px(s) {
      return s + (!/px/i.test(s) ? 'px' : '');
    }
  },

  destroyIndividualStyleCSS: function(params) {
    var model = params.model,
      id = 'individual_button_style_' + model._id + '_' + params.env;

    // удаляем набор стилей.
    $('#' + id).remove();
  },
};

export default ButtonUtils;
