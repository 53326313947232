/**
 * виджет Button
 */
import $ from '@rm/jquery';
import WidgetClass from '../widget';
import ButtonWidgetClass from '../../common/button-widget';
import Device from '../../common/device';

const ButtonWidget = WidgetClass.extend({
  DO_NOT_WAIT_FOR_PRELOAD: true, // флаг что при прелоадинге виджетов ждать его загрузки не надо (но не для скриншотера)

  antialiasRotation: true,

  // достаточно версии прототипа
  // initialize: function(data, page) {},

  mailchimpMatcher: /__rm_mailchimp_(.+)/i,
  anchorRegexp: /__rm_anchor/i,
  shareRegexp: /^share\.(facebook|twitter|pinterest|gplus|linkedin|email)\.(mag|page)$/i,

  render: function() {
    // как можно раньше подгружаем картинку дефолтной иконки.
    this.preloadIconImage({ default: true });

    var params;

    this.makeBox('widget-button');

    if (this.hasHoverAnimation) {
      this.$el.addClass('has-onhover-animation');
    }

    this.rendered = true;

    this.seamlessFontsShow(
      function() {
        this.$el.removeClass('fonts-pending');
      }.bind(this),
      function() {
        this.$el.addClass('fonts-pending');
      }.bind(this)
    );

    params = {
      // первый параметр this передает Кнопке объект в котором находятся
      // все нужные параметры (моделей во Вьвере у нас нет).
      // в Конструкторе например при создании плеера передается _.clone(this.model.attributes) вместо this.
      model: this,
      $container: this.$el,
      environment: 'viewer',
      mag: this.page && this.page.mag,
      block: null,
    };
    this.buttonWidget = new ButtonWidgetClass(params);

    // заранее подгружаем картинку иконки в ховер-состоянии.
    this.preloadIconImage({ hover: true });

    // обработка наведения на Кнопку.
    this.$el.on(Device.isDesktop ? 'mouseenter' : 'touchstart', this.onButtonHover);

    this.$el.on(Device.isDesktop ? 'mouseleave' : 'touchend', this.onButtonHoverOut);

    // обработка клика на Кнопку.
    this.$el.on('click', this.onButtonClick);

    // метод оборачивающий Кнопку в линк, если он на неё навешан.
    this.checkLink();

    // ожидаем загрузку шрифта и картинки иконки
    // только в режиме Cкриншотера
    // для Вьювера этого делать не надо из соображений скорости,
    // да и там не важно этого знать, считаем что виджет грузится мгновенно.
    if (RM.screenshot) {
      // внутри логика, определяющая надо ли ждать
      // загрузки картинки иконки.
      this.waitForIconImageLoad();

      // внутри логика, определяющая надо ли ждать
      // загрузки шрифта.
      this.waitForUsedFontsLoad();
    } else {
      this.widgetIsLoaded();
    }

    return this;
  },

  // Позволяет изменить текст кнопки динамически
  renderButtonText: function(text) {
    this.buttonWidget.renderButtonText(text);
  },

  forceRenderButtonText: function(text) {
    this.buttonWidget.forceRenderButtonText(text);
  },

  // ждать загрузку шрифта надо только, если кнопка с текстом.
  hasFontsToLoad: function() {
    return this.tp == 'text' || this.tp == 'text_and_icon';
  },

  // обработка наведения на Кнопку.
  onButtonHover: function(e) {
    this.$('.common-button').addClass('hovered');
  },

  // обработка наведения на Кнопку.
  onButtonHoverOut: function(e) {
    this.$('.common-button').removeClass('hovered');
  },

  // обработка клика на Кнопку — он же клик по линку, который содержит в себе Кнопку.
  // проверяем, что кликнули по линку создания Мэга из Темплейта.
  onButtonClick: function(e) {
    // оставляем дефолтное поведение браузера.
    if (e.which == 2 || e.metaKey || e.ctrlKey) return;

    var $link = this.$el.closest('a'),
      href = $.trim($link.attr('href')),
      tmp = href.match(/\/start\-with\-template\/(\d+)$/i),
      templateID = tmp && tmp[1];

    // вторая проверка для режима превью.
    if (templateID && RM.viewerRouter && RM.viewerRouter.createMagFromTemplate) {
      e.preventDefault();
      RM.viewerRouter.createMagFromTemplate('template-link', templateID);
    }
  },

  preloadIconImage: function(options) {
    options = options || {};

    var button_type = this.tp;

    if (button_type === 'icon' || button_type === 'text_and_icon') {
      if (options.default) {
        var default_img = new Image();
        default_img.src = Modernizr.retina
          ? this['icon_raster2xUrl'] || this['icon_rasterUrl']
          : this['icon_rasterUrl'];
      }

      if (options.hover) {
        var hover_img = new Image();
        hover_img.src = Modernizr.retina
          ? this['hover-icon_raster2xUrl'] || this['hover-icon_rasterUrl']
          : this['hover-icon_rasterUrl'];
      }
    }
  },

  _widgetIsLoaded: function() {
    if (this.loaded || this.destroyed) return;

    // в режиме Скриншотера нужно убедиться, что шрифт и картинка иконки загружены,
    // чтобы кнопка нормально отобразилась на скриншоте с нужным шрифтом и иконкой,
    // каждое из событий ожидания загрузки шрифта — this.waitForUsedFontsLoad,
    // и загрузки картинки — this.waitForIconImageLoad, запускает
    // колбек выставляющий флаг this.fontLoaded или this.iconImageLoaded,
    // а потом вызывает this._widgetIsLoaded.
    // уже тут внутри мы проверяем надо ли шрифту дожаться загрузки иконки и наоборот,
    // прежде чем выстрелить событие _widgetIsLoaded.
    if (RM.screenshot) {
      if (this.iconImageLoaded && this.fontLoaded) {
        this.widgetIsLoaded();
      }
    } else {
      this.widgetIsLoaded();
    }
  },

  // ожидает загрузки картинки иконки,
  // выставляет флаг this.iconImageLoaded = true
  // и выстреливает this._widgetIsLoaded().
  // нужно в режиме Скришнотера.
  waitForIconImageLoad: function() {
    this.iconImageLoaded = false;

    // ждать загрузку картинки иконки надо только если кнопка с иконкой.
    // и при этом у нас есть растеризованная картинка
    if ((this.tp === 'icon' || this.tp === 'text_and_icon') && this.icon_rasterUrl) {
      $('<img>')
        .on('load', this.onIconImageLoaded)
        .on('error', this.onIconImageLoaded)
        .attr('src', Modernizr.retina ? this.icon_raster2xUrl || this.icon_rasterUrl || '' : this.icon_rasterUrl || '');
    } else {
      this.onIconImageLoaded();
    }
  },

  // нужно в режиме Скришнотера.
  onIconImageLoaded: function() {
    this.iconImageLoaded = true;
    this._widgetIsLoaded();
  },

  // ожидает загрузки шрифта кнопки,
  // выставляет флаг this.fontLoaded = true
  // и выстреливает this._widgetIsLoaded().
  waitForUsedFontsLoad: function() {
    this.fontLoaded = false;

    if (this.hasFontsToLoad()) {
      this.page.addFontsToLoad(this, this.onFontLoaded);
    } else {
      this.onFontLoaded();
    }
  },

  // нужно в режиме Скришнотера.
  onFontLoaded: function() {
    this.fontLoaded = true;
    this._widgetIsLoaded();
  },

  destroy: function() {
    this.buttonWidget && this.buttonWidget.destroy();
    this.buttonWidget = null;

    this.$el.off(Device.isDesktop ? 'mouseenter' : 'touchstart', this.onButtonHover);

    this.$el.off(Device.isDesktop ? 'mouseleave' : 'touchend', this.onButtonHoverOut);

    this.$el.off('click', this.onButtonClick);

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default ButtonWidget;
