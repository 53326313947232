/**
 * Нужные тесты для modernizr
 *
 * !!! В название теста должны быть только строчные буквы
 */
(function() {
  Modernizr.addTest('retina', function() {
    return window.devicePixelRatio > 1;
  });

  Modernizr.addTest('android', function() {
    return window.navigator.userAgent.toLowerCase().match(/Android/i);
  });

  Modernizr.addTest('amazonsilk', function() {
    return /\bSilk\b/.test(navigator.userAgent);
  });

  Modernizr.addTest('Mac', function() {
    return window.navigator.userAgent.indexOf('Mac') != -1;
  });

  Modernizr.addTest('webkit', function() {
    return window.navigator.userAgent.indexOf('AppleWebKit') != -1;
  });

  Modernizr.addTest('opera', function() {
    return !!(window.opera && window.opera.buildNumber);
  });

  Modernizr.addTest('msie', function() {
    if (Function('/*@cc_on return document.documentMode===10@*/')()) {
      return true;
    }
    return false;
  });

  Modernizr.addTest('msie11', function() {
    return (
      navigator.userAgent.indexOf('Trident/') != -1 &&
      (navigator.userAgent.indexOf('rv:') != -1 || navigator.appName.indexOf('Netscape') != -1)
    );
  });

  Modernizr.addTest('winphone8', function() {
    return (
      navigator.userAgent.indexOf('Trident/') != -1 &&
      (navigator.userAgent.indexOf('Phone') != -1 || navigator.userAgent.indexOf('IEMobile/') != -1)
    );
  });

  Modernizr.addTest('safari', function() {
    return (
      navigator.userAgent.indexOf('Safari') != -1 &&
      (navigator.userAgent.indexOf('Mac') != -1 || navigator.userAgent.indexOf('Windows')) &&
      navigator.userAgent.indexOf('Chrome') == -1
    );
  });

  Modernizr.addTest('safari5', function() {
    return (
      !!navigator.userAgent.match(' Safari/') &&
      !navigator.userAgent.match(' Chrom') &&
      !!navigator.userAgent.match(' Version/5.')
    );
  });

  Modernizr.addTest('isboxversion', function() {
    return window.isboxversion === true;
  });

  Modernizr.addTest('isipad', function() {
    return window.navigator.userAgent.toLowerCase().match(/ipad/i);
  });

  Modernizr.addTest('ismobile', function() {
    return window.navigator.userAgent
      .toLowerCase()
      .match(/android|webos|iphone|ipad|ipod|blackberry|playbook|iemobile|windows phone|kindle|silk|opera mini/i);
  });

  Modernizr.addTest('firefox', function() {
    return window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  });

  Modernizr.addTest('edge', function() {
    return window.navigator.userAgent.toLowerCase().indexOf('edge') > -1;
  });

  //используется как глобальный класс для мобильныз устройств, проверяет css поддежку sticky position
  Modernizr.addTest('csspositionsticky', function() {
    var prop = 'position:';
    var value = 'sticky';
    var el = document.createElement('modernizr');
    var mStyle = el.style;

    mStyle.cssText = prop + Modernizr._prefixes.join(value + ';' + prop).slice(0, -prop.length);

    return mStyle.position.indexOf(value) !== -1;
  });
})();
