<template>
  <div
    class="panel resizable"
    :data-current-type="block.tp"
    :class="{ 'no-search': Globals.Modernizr.isboxversion, 'overflow-visible': stroke_popup_visible }"
    @click="closeOtherPopups"
  >
    <div class="param-caption">Shape</div>
    <div class="selector" :data-current-type="block.tp">
      <div
        v-for="shape in ['rectangle', 'polygon', 'ellipse', 'line', 'icon']"
        class="shape"
        :data-type="shape"
        @click="selectShape(shape)"
      ></div>
      <div class="separator"></div>
    </div>

    <div class="details">
      <div class="weight param-component">
        <div class="param-caption">Weight</div>
        <numeric-input
          v-model="block.weight"
          :autofocus="true"
          :autosize="false"
          :min="1"
          :max="999"
          dir="v"
          :speed="5"
        />
        <div class="separator"></div>
      </div>

      <div class="sides param-component">
        <div class="param-caption">Sides</div>
        <numeric-input
          v-model="block.sides"
          :autofocus="true"
          :autosize="false"
          :min="3"
          :max="30"
          dir="v"
          :speed="10"
        />
        <div class="separator"></div>
      </div>

      <div class="borders param-component">
        <div class="param-caption name-border">{{ block.tp == 'ellipse' ? 'Border' : 'Borders' }}</div>
        <numeric-input
          v-model="block.borders"
          :autofocus="true"
          :autosize="false"
          :min="0"
          :max="999"
          dir="v"
          :speed="5"
          :style="{ width: '66px' }"
        />

        <div class="border-color" title="Color">
          <color-picker
            ref="colorpicker"
            class="color round"
            :color="block.color"
            :alpha="block.opacity"
            :opacity-multiplier="100"
            :v-offset="8"
            :show-alpha="true"
            :show-arrow-icon="true"
            @update:all="updateColor"
          ></color-picker>
        </div>

        <div class="colorbox_container"></div>

        <div class="separator"></div>
      </div>

      <div class="radius param-component">
        <div class="param-caption">Radius</div>
        <numeric-input
          v-model="block.radius"
          :autofocus="true"
          :autosize="false"
          :min="0"
          :max="999"
          dir="v"
          :speed="5"
        />
      </div>

      <div class="stroke param-component">
        <div class="param-caption">Stroke</div>

        <div
          class="stroke-selector"
          :data-current-type="block.stroke"
          :class="{ opened: stroke_popup_visible }"
          @click.stop="toggleStrokePopup"
        >
          <div class="stroke-popup">
            <div
              v-for="stroke in ['solid', 'double', 'dotted', 'dashed']"
              :data-type="stroke"
              class="stroke-item"
              @click="selectStroke(stroke)"
            >
              <div class="preview"></div>
              <div class="point"></div>
            </div>
          </div>
          <div class="current"></div>
          <div class="popup-arrow"></div>
        </div>
      </div>
    </div>
    <shape-search @dataObtained="onDataObtained" ref="shapeSearch" />
  </div>
</template>

<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import Vue from 'vue';
import iconsutils from '../../../js/common/iconsutils';
import Panel from './panel.vue';
import RmScroll from '../../common/rm-scroll.vue';
import NumericInput from '../../common/numeric-input.vue';
import ColorPicker from '../../common/color-picker.vue';
import ShapeSearch from '../../common/shape-search.vue';

export default {
  mixins: [Panel],
  components: { RmScroll, NumericInput, ColorPicker, ShapeSearch },

  data: function() {
    return {
      stroke_popup_visible: false,
      icons: iconsutils.prevSVGSearch.icons || iconsutils.defaultIcons,
    };
  },

  mounted: function() {
    this.iconSelected = iconsutils.findIcon(_.pick(this.block, 'noun_id', 'rm_id'));
  },

  watch: {
    'block.tp': {
      handler: function() {
        Vue.nextTick(
          function() {
            this.$emit('resize');
          }.bind(this)
        );
      },
      immediate: true,
    },
  },

  methods: {
    selectShape: function(shape) {
      this.block.tp = shape;

      if (shape !== 'icon') {
        this.abortRequests();
      } else {
        this.updateShapeSearchScroll();
      }
    },

    updateShapeSearchScroll: function() {
      this.$refs['shapeSearch'].$refs['rmscroll'].recalcScroll();
    },

    onInputChange: function(key, value) {
      this.block[key] = value;
    },

    updateColor: function(data) {
      this.block.color = data.color;
      this.block.opacity = data.alpha;
    },

    closeOtherPopups: function() {
      this.$refs.colorpicker.hide();
      this.stroke_popup_visible = false;
    },

    toggleStrokePopup: function(e) {
      if ($(e.target).closest('.stroke-selector .stroke-popup').length > 0) return;
      this.stroke_popup_visible = !this.stroke_popup_visible;
    },

    selectStroke: function(stroke) {
      this.block.stroke = stroke;
      this.stroke_popup_visible = false;
    },

    onDataObtained: function(icon) {
      this.raw_block.getIconSVG(icon.id || icon.rm_id, icon.icon_url, { noCache: true }, (err, data) => {
        var set;

        if (err) {
          return;
        }

        set = {
          noun_url: icon.icon_url,
        };

        // При задании одного типа айдишника обязательно сбрасывать другой
        // т.к. наличие или отсутствие соотв. айдишника и является признаком, чья иконка - науна или наша
        if (icon.id) {
          _.extend(set, { noun_id: icon.id, rm_id: null });
        } else if (icon.rm_id) {
          _.extend(set, { noun_id: null, rm_id: icon.rm_id });
        }

        this.raw_block.model.set(set);
      });
    },

    abortRequests: function() {
      iconsutils.clearSearch();
    },
  },
};
</script>
<style lang="less" scoped>
.panel {
  .selector {
    margin-top: 39px;
  }
}
</style>
