module.exports = {["template-picture-preview-box"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <div>\n    <div class="picture-preview-box">\n      <div class="picture-preview-box-overlay" style="background-color: '+
((__t=(overlayColor))==null?'':__t)+
';"></div>\n      <img class="picture-preview-box-image" srcset="'+
((__t=(previewSrc))==null?'':__t)+
'" style="'+
((__t=( Object.keys(originalStyle).map(function(key) { return key + ':' + originalStyle[key] }).join('; ') ))==null?'':__t)+
'" />\n    </div>\n  </div>\n';
}
return __p;
},}