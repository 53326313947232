<template>
  <div class="rm-native-select-container" role="combobox" :aria-expanded="isOpened">
    <input
      type="text"
      class="rm-native-fake-select"
      readonly="readonly"
      tabindex="-1"
      :value="caption"
      @click="onClick"
    />
    <div class="expand-arrow">
      <svg
        width="10px"
        height="7px"
        viewBox="0 0 10 7"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1110.000000, -138.000000)" fill="#FFFFFF">
            <g transform="translate(904.000000, 101.000000)">
              <path
                d="M208.385799,39.7407434 L212.849209,35.914964 C213.268534,35.5555419 213.899834,35.6041034 214.259257,36.0234293 C214.414608,36.2046724 214.5,36.4355094 214.5,36.6742206 L214.5,44.3257794 C214.5,44.8780641 214.052285,45.3257794 213.5,45.3257794 C213.261289,45.3257794 213.030452,45.2403872 212.849209,45.085036 L208.385799,41.2592566 C207.966474,40.8998345 207.917912,40.2685345 208.277334,39.8492086 C208.310659,39.8103298 208.346921,39.7740681 208.385799,39.7407434 Z"
                id="arrow-copy"
                transform="translate(211.000000, 40.500000) scale(-1, -1) rotate(-270.000000) translate(-211.000000, -40.500000) "
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <select v-if="!isDesktop" class="rm-native-select" @change="onChange($event)">
      <option v-for="option in options" class="rm-native-select-item" :key="option.value" :value="option.value">{{
        option.caption
      }}</option>
    </select>
    <div v-if="isDesktop" class="rm-native-select-popup" :class="{ opened: isOpened }" role="listbox">
      <div
        class="rm-native-select-popup-item"
        :class="{ current: option.value === value }"
        v-for="option in options"
        :key="option.value"
        @click="onItemClick(option)"
      >
        {{ option.caption }}
      </div>
    </div>
  </div>
</template>

<script>
// Дропдаун. Ожидает на входе в свойство options массив из объектов вида
// [{value: <уникальный айдишник айтема>, caption: <текст пункта айтема>}]
// свойство isDesktop переключает нативный select на кастомный dropdown

export default {
  props: {
    value: {},
    options: {
      type: Array,
      default: [],
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      isOpened: false,
    };
  },

  computed: {
    caption: function() {
      const currentOption = this.options.find(option => option.value === this.value);
      return currentOption ? currentOption.caption : undefined;
    },
  },

  methods: {
    onClick: function() {
      if (this.isDesktop) {
        this.isOpened = !this.isOpened;
      }
    },

    onChange: function(e) {
      this.$emit('change', e.target.value);
      this.callback(e.target.value);
    },

    onItemClick: function(option) {
      this.isOpened = !this.isOpened;
      this.$emit('change', option.value);
      this.callback(option.value);
    },
  },
};
</script>

<style lang="less" scoped>
@import (reference) '../../css/common/constants.less';
@import (reference) '../../css/common/fonts.less';

.rm-native-select-container {
  width: 100%;
  height: 24px;
  position: relative;
  margin: 0 auto;

  .rm-native-select {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
  }

  .rm-native-fake-select {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    vertical-align: middle;
    text-overflow: ellipsis;
    background-color: transparent;
    font-size: 16px;
    cursor: default;
    user-select: none;
    outline: none;
    border-style: none;
    resize: none;
  }

  .expand-arrow {
    position: absolute;
    top: 5px;
    right: 0;
  }

  .rm-native-select-popup {
    width: 100%;
    min-height: 24px;
    max-height: 256px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    text-align: left;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    .transition(0.2s, all, ease-in-out);

    &.opened {
      opacity: 1;
      visibility: visible;
    }

    .rm-native-select-popup-item {
      position: relative;
      .overflow-ellipsis;
      cursor: pointer;
      padding: 8px 12px;

      &.current {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>
