<template>
  <div
    :class="{
      panel: true,
      'addtocart-settings-panel': true,
      'settings-panel-long': selectionState === 'selects' || stylingState === 'hover',
    }"
  >
    <div class="title-row">
      <div class="both-header header" v-if="selectionState === 'both' && haveProductAttributes">
        <div class="title">Layout</div>
        <div class="layout-controls" v-svginject>
          <div
            :class="{ 'layout-btn': true, 'horizontal-layout': true, active: m.layout === 'horizontal' }"
            data-layout-type="horizontal"
            @click="onChangeLayout"
          >
            <img
              class="svg layout-icon"
              title="horizontal layout"
              :src="`${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/layout-horizontal.svg`"
            />
          </div>
          <div
            :class="{ 'layout-btn': true, 'vertical-layout': true, active: m.layout === 'vertical' }"
            data-layout-type="vertical"
            @click="onChangeLayout"
          >
            <img
              class="svg layout-icon"
              title="vertical layout"
              :src="`${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/layout-vertical.svg`"
            />
          </div>
        </div>
      </div>
      <div
        class="button-header header"
        v-if="selectionState === 'button' || (selectionState === 'both' && !haveProductAttributes)"
      >
        Button
      </div>
      <div class="selects-header header" v-if="selectionState === 'selects'">Dropdown</div>
    </div>

    <div class="styling-state-switcher">
      <span :class="{ btn: true, active: stylingState === 'default' }" @click="onChangeStylingState('default')"
        >Default</span
      >
      <span :class="{ btn: true, active: stylingState === 'hover' }" @click="onChangeStylingState('hover')">Hover</span>
    </div>

    <div class="separator-filled"></div>

    <div class="settings-panel" v-if="stylingState === 'default'">
      <div class="row">
        <div class="caption">Shape color</div>
        <color-picker
          class="round"
          :color="getColorValue('background-color')"
          :alpha="getColorValue('background-color-opacity')"
          :customIcon="
            isDisabledValue('background-color') || isDisabledValue('background-color-opacity')
              ? `${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/color-disabled.svg`
              : null
          "
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('background-color', $event)"
          @update:alpha="changePropHandler('background-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Radius</div>
        <div class="num-input-wrap">
          <img
            :class="{ 'disabled-numeric': true, 'show-disabled-icon': isDisabledValue('border-radius') }"
            :src="`${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/number-disabled.svg`"
          />
          <numeric-input
            :autosize="true"
            :value="getNumValue('border-radius')"
            :class="{ 'num-input': true, 'hidden-num-input': isDisabledValue('border-radius') }"
            :min="0"
            :max="999"
            @change="changePropHandler('border-radius', $event)"
          ></numeric-input>
        </div>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Border</div>
        <div class="num-input-wrap">
          <img
            :class="{ 'disabled-numeric': true, 'show-disabled-icon': isDisabledValue('border-width') }"
            :src="`${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/number-disabled.svg`"
          />
          <numeric-input
            :autosize="true"
            :value="getNumValue('border-width')"
            :class="{ 'num-input': true, 'hidden-num-input': isDisabledValue('border-width') }"
            :min="0"
            :max="maxBtnBorderWidth"
            @change="changePropHandler('border-width', $event)"
          ></numeric-input>
        </div>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Border color</div>
        <color-picker
          class="round"
          :color="getColorValue('border-color')"
          :alpha="getColorValue('border-color-opacity')"
          :customIcon="
            isDisabledValue('border-color') || isDisabledValue('border-color-opacity')
              ? `${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/color-disabled.svg`
              : null
          "
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('border-color', $event)"
          @update:alpha="changePropHandler('border-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <font-family-selector
          :family="getPropValue('font-family')"
          @showPopup="closeOthersPopups"
          @change="changePropHandler('font-family', $event)"
        ></font-family-selector>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Size</div>
        <div class="num-input-wrap">
          <img
            :class="{ 'disabled-numeric': true, 'show-disabled-icon': isDisabledValue('font-size') }"
            :src="`${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/number-disabled.svg`"
          />
          <numeric-input
            :autosize="true"
            :value="getNumValue('font-size')"
            :class="{ 'num-input': true, 'hidden-num-input': isDisabledValue('font-size') }"
            :min="8"
            :max="999"
            @change="changePropHandler('font-size', $event)"
          ></numeric-input>
        </div>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Text color</div>
        <color-picker
          class="round"
          :color="getColorValue('color')"
          :alpha="getColorValue('color-opacity')"
          :customIcon="
            isDisabledValue('color') || isDisabledValue('color-opacity')
              ? `${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/color-disabled.svg`
              : null
          "
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('color', $event)"
          @update:alpha="changePropHandler('color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted" v-if="selectionState === 'selects'"></div>

      <div class="row" v-if="selectionState === 'selects'">
        <div class="caption">Icon size</div>
        <numeric-input
          :autosize="true"
          :value="m['attrs-icon-size']"
          class="num-input"
          :min="1"
          :max="10"
          :step="0.1"
          :useFloat="true"
          @change="changeSinglePropHandler('attrs-icon-size', $event)"
        ></numeric-input>
      </div>
    </div>

    <div class="settings-panel" v-if="stylingState === 'hover'">
      <div class="row">
        <div class="caption">Shape color</div>
        <color-picker
          class="round"
          :color="getColorValue('hover-background-color')"
          :alpha="getColorValue('hover-background-color-opacity')"
          :customIcon="
            isDisabledValue('hover-background-color') || isDisabledValue('hover-background-color-opacity')
              ? `${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/color-disabled.svg`
              : null
          "
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('hover-background-color', $event)"
          @update:alpha="changePropHandler('hover-background-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Radius</div>
        <div class="num-input-wrap">
          <img
            :class="{ 'disabled-numeric': true, 'show-disabled-icon': isDisabledValue('hover-border-radius') }"
            :src="`${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/number-disabled.svg`"
          />
          <numeric-input
            :autosize="true"
            :value="getNumValue('hover-border-radius')"
            :class="{ 'num-input': true, 'hidden-num-input': isDisabledValue('hover-border-radius') }"
            :min="0"
            :max="999"
            @change="changePropHandler('hover-border-radius', $event)"
          ></numeric-input>
        </div>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Border</div>
        <div class="num-input-wrap">
          <img
            :class="{ 'disabled-numeric': true, 'show-disabled-icon': isDisabledValue('hover-border-width') }"
            :src="`${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/number-disabled.svg`"
          />
          <numeric-input
            :autosize="true"
            :value="getNumValue('hover-border-width')"
            :class="{ 'num-input': true, 'hidden-num-input': isDisabledValue('hover-border-width') }"
            :min="0"
            :max="maxBtnBorderWidth"
            @change="changePropHandler('hover-border-width', $event)"
          ></numeric-input>
        </div>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Border color</div>
        <color-picker
          class="round"
          :color="getColorValue('hover-border-color')"
          :alpha="getColorValue('hover-border-color-opacity')"
          :customIcon="
            isDisabledValue('hover-border-color') || isDisabledValue('hover-border-color-opacity')
              ? `${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/color-disabled.svg`
              : null
          "
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('hover-border-color', $event)"
          @update:alpha="changePropHandler('hover-border-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <font-family-selector
          :family="getPropValue('hover-font-family')"
          @showPopup="closeOthersPopups"
          @change="changePropHandler('hover-font-family', $event)"
        ></font-family-selector>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Size</div>
        <div class="num-input-wrap">
          <img
            :class="{ 'disabled-numeric': true, 'show-disabled-icon': isDisabledValue('hover-font-size') }"
            :src="`${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/number-disabled.svg`"
          />
          <numeric-input
            :autosize="true"
            :value="getNumValue('hover-font-size')"
            :class="{ 'num-input': true, 'hidden-num-input': isDisabledValue('hover-font-size') }"
            :min="8"
            :max="999"
            @change="changePropHandler('hover-font-size', $event)"
          ></numeric-input>
        </div>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Text color</div>
        <color-picker
          class="round"
          :color="getColorValue('hover-color')"
          :alpha="getColorValue('hover-color-opacity')"
          :customIcon="
            isDisabledValue('hover-color') || isDisabledValue('hover-color-opacity')
              ? `${RM_PUBLIC_PATH}img/constructor/controls/add_to_cart_settings/color-disabled.svg`
              : null
          "
          @showPopup="closeOthersPopups"
          @update:color="changePropHandler('hover-color', $event)"
          @update:alpha="changePropHandler('hover-color-opacity', $event)"
        ></color-picker>
      </div>
      <div class="separator-dotted"></div>

      <div class="row">
        <div class="caption">Transition</div>
        <numeric-input
          :autosize="true"
          :value="m['hover-tansition-delay']"
          class="num-input"
          :min="0"
          :max="2"
          :step="0.1"
          :useFloat="true"
          @change="changeSinglePropHandler('hover-tansition-delay', $event)"
        ></numeric-input>
      </div>
    </div>
  </div>
</template>
<script>
import Panel from './panel.vue';
import ColorPicker from '../../common/color-picker.vue';
import NumericInput from '../../common/numeric-input.vue';
import FontFamilySelector from '../../common/font-family-selector.vue';

export default {
  mixins: [Panel],
  components: { ColorPicker, NumericInput, FontFamilySelector },

  data: function() {
    return {
      stylingState: 'default',
      RM_PUBLIC_PATH: RM_PUBLIC_PATH,
      haveProductAttributes: this.raw_block.haveProductAttributes(),
    };
  },

  computed: {
    maxBtnBorderWidth() {
      return Math.ceil(Math.min(this.m.btn_w, this.m.btn_h) / 2);
    },
    selectionState() {
      return this.raw_block.selectionState;
    },
  },

  watch: {
    stylingState(state) {
      this.raw_block.$el.toggleClass('hovered', state === 'hover');
    },
  },

  destroyed() {
    this.onChangeStylingState('default');
  },

  methods: {
    onChangeLayout: function(e) {
      const newLayout = e.currentTarget.dataset.layoutType;
      if (newLayout && this.m.layout && this.m.layout !== newLayout) {
        this.m.layout = newLayout;
      }
    },

    onChangeStylingState: function(newState) {
      if (newState && newState !== this.stylingState) {
        this.stylingState = newState;
        this.raw_block.changeStylingState(this.stylingState);
      }
    },

    changeSinglePropHandler(propType, val) {
      this.m[propType] = val;
    },

    changePropHandler(propType, val) {
      const edited = {};
      if (propType === 'color') edited['icon_color'] = val;
      if (propType === 'color-opacity') edited['icon_color_opacity'] = val;
      if (propType === 'hover-color') edited['hover-icon_color'] = val;
      if (propType === 'hover-color-opacity') edited['hover-icon_color_opacity'] = val;
      if (this.selectionState === 'both') {
        // Что бы не делать 2 отдельных изменения
        edited[propType] = val;
        edited['attrs-' + propType] = val;
      }
      if (this.selectionState === 'button') {
        edited[propType] = val;
      }
      if (this.selectionState === 'selects') {
        edited['attrs-' + propType] = val;
      }
      this.m = Object.assign(this.m, edited);
    },

    getPropValue(prop) {
      if (this.selectionState === 'both') {
        if (this.m[prop] === this.m['attrs-' + prop]) {
          return this.m[prop];
        } else {
          return undefined;
        }
      }
      if (this.selectionState === 'button') {
        return this.m[prop];
      }
      if (this.selectionState === 'selects') {
        return this.m['attrs-' + prop];
      }
    },

    getColorValue(prop) {
      if (this.selectionState === 'both') {
        if (this.m[prop] === this.m['attrs-' + prop]) {
          return this.m[prop];
        } else {
          return '000000';
        }
      }
      if (this.selectionState === 'button') {
        return this.m[prop];
      }
      if (this.selectionState === 'selects') {
        return this.m['attrs-' + prop];
      }
    },

    getNumValue(prop) {
      if (this.selectionState === 'both') {
        if (this.m[prop] === this.m['attrs-' + prop]) {
          return this.m[prop];
        } else {
          return Math.min(this.m[prop], this.m['attrs-' + prop]);
        }
      }
      if (this.selectionState === 'button') {
        return this.m[prop];
      }
      if (this.selectionState === 'selects') {
        return this.m['attrs-' + prop];
      }
    },

    isDisabledValue(prop) {
      if (this.selectionState === 'both') {
        // console.log('prop', prop, this.m[prop], this.m['attrs-' + prop]);
        if (this.m[prop] !== this.m['attrs-' + prop]) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="less">
@import (reference) '../../../css/common/constants.less';
@import (reference) '../../../css/common/fonts.less';

@panel-bg-color: #f4f4f4;
@panel-border-color: #404040;
@panel-disable-color: fade(@new-black, 40%);

.addtocart-settings-panel {
  width: 168px;
  height: 360px;
  box-sizing: border-box;
  padding: 13px 15px;
  right: 47px !important;
  top: -112px;
  border-radius: 8px;
  background-color: fade(@panel-bg-color, 96%);
  border: 1px solid fade(@panel-border-color, 8%);
  .native_font;
  font-size: 12px;
  color: @new-black;

  &.settings-panel-long {
    height: 400px;
  }

  .title-row {
    font-size: 16px;

    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    .title {
      position: relative;
      top: -4px;
    }

    .layout-controls {
      height: 12px;
      width: 72px;
      display: flex;
      justify-content: space-between;

      .layout-btn {
        width: 32px;
        height: 100%;

        &.active {
          .layout-icon rect {
            fill: @new-black;
          }
        }

        &:hover {
          cursor: pointer;
        }

        &.horizontal-layout {
          position: relative;
          top: -6px;
        }

        &.vertical-layout {
          position: relative;
          top: -2px;
        }

        .layout-icon rect {
          fill: fade(#282828, 40%);
        }
      }
    }
  }

  .styling-state-switcher {
    margin-top: 24px;
    margin-bottom: 13px;

    .btn {
      color: #808080;

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: @new-black;
      }

      & + span {
        margin-left: 8px;
      }
    }
  }

  .settings-panel {
    .row {
      width: 100%;
      height: 38px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        border-bottom: none;
      }

      .color-picker-container.round {
        width: 26px;
        height: 26px;
      }

      .num-input-wrap {
        .num-input {
          padding: 0;
          border: 0;
          font-size: 24px;
          color: @new-black;
          background: transparent;
          text-align: right;
          cursor: ns-resize;
          opacity: 1;
          transition: all 0.1s ease-out;

          &.hidden-num-input {
            opacity: 0;
          }
        }

        .disabled-numeric {
          position: absolute;
          top: 17px;
          right: 0;
          display: none;

          &.show-disabled-icon {
            display: block;
          }
        }
      }

      .num-input {
        padding: 0;
        border: 0;
        font-size: 24px;
        color: @new-black;
        background: transparent;
        text-align: right;
        cursor: ns-resize;
        opacity: 1;
        transition: all 0.1s ease-out;

        &.hidden-num-input {
          opacity: 0;
        }
      }

      .disabled-numeric {
        display: none;

        &.show-disabled-icon {
          display: block;
        }
      }

      .font-family-container {
        .expand-arrow {
          width: 7px;
          height: 12px;
          background-size: 12px 7px;
          background-image: url('../../../img/constructor/controls/add_to_cart_settings/expand-arrow.svg');
          flex: 0 0 12px;
          position: relative;
          top: 3px;
          left: 1px;
        }

        &.container-disabled {
          &:hover {
            .expand-arrow {
              background-image: url('../../../img/constructor/controls/add_to_cart_settings/expand-arrow.svg');
            }
          }
        }

        &:hover {
          .expand-arrow {
            background-image: url('../../../img/constructor/controls/add_to_cart_settings/expand-arrow-active.svg');
          }
        }
      }
    }
  }
}
</style>
