/**
 * класс виджета Формы.
 * !!! - общий для Конструктора и для Вьювера.
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import { Utils } from './utils';
import templates from '../../templates/common/form.tpl';
import Device from './device';

const FormWidgetClass = Backbone.View.extend({
  MIN_FIELD_WIDTH: 50,
  MIN_FIELD_HEIGHT: 30,
  LINE_HEIGHT_FACTOR: 1.2, // line-height: normal
  INVALID_COLOR: 'ff4828', // Есть так же в form-widget.less
  INVALID_BORDER_WIDTH: 2,
  DROPDOWN_TRIANGLE: {
    SVG:
      '<svg class="form-dropdown-triangle" width="9" height="6" preserveAspectRatio="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg"><path d="M3.763 5.196L.536 1.676A1 1 0 0 1 1.273 0h6.454a1 1 0 0 1 .737 1.676l-3.227 3.52a1 1 0 0 1-1.474 0z" fill-rule="evenodd"/></svg>',
    OPACITY_MULTIPLIER: 0.4, // Насколько треугольник бледней цвета текста, чтобы соответствовать цвету плейсхолдеров
    W: 9,
    H: 6,
    FONT_SIZE: 18,
  },
  CHECKBOX_TICK: {
    SVG:
      '<svg width="19" height="14" preserveAspectRatio="none" viewBox="0 0 19 14" xmlns="http://www.w3.org/2000/svg"><path d="M6.657 10.9L17.263.292a1 1 0 1 1 1.415 1.414L7.364 13.021a.997.997 0 0 1-1.414 0L.293 7.364A1 1 0 1 1 1.707 5.95l4.95 4.95z" fill-rule="evenodd"/></svg>',
    W: 19,
    H: 14,
    FONT_SIZE: 18,
  },

  initialize: function(params) {
    _.bindAll(this);
    this.data = params.data;
    this.$container = params.$container;
    this.environment = params.environment;
    this.buttonTemplate = templates['template-common-form-button'];
  },

  render: function(options) {
    options = options || {};

    if (!this.rendered) {
      this.setElement($('<div>'));

      this.$el.addClass('form-wrapper').appendTo(this.$container);

      if (this.environment !== 'constructor') {
        this.$el
          .on(Device.isDesktop ? 'mouseenter' : 'touchstart', '.button', this.onButtonHover)
          .on(Device.isDesktop ? 'mouseleave' : 'touchend', '.button', this.onButtonHoverOut);
      }

      this.$el
        .on('change', 'select', this.onDropdownChange)
        .on('mouseenter', '.input-wrapper', this.onInputHover)
        .on('mouseleave', '.input-wrapper', this.onInputHoverOut);

      this.rendered = true;
    } else if (options.force) {
      this.$el && this.$el.empty();
    }

    var data = this.data,
      fields = data.fields,
      $form = $('<div>'),
      isMultilineTextPossible,
      $input;

    for (var i = 0; i < fields.length; i++) {
      var field = fields[i];

      isMultilineTextPossible = data.layout === 'vertical' && field.tp === 'text';
      $input = $(isMultilineTextPossible ? '<textarea>' : '<input>');

      $input.addClass('js-input');

      $input
        .attr('type', 'text')
        .attr('autocapitalize', field.tp == 'name' ? 'on' : 'off')
        .attr('autocomplete', 'off')
        .attr('autocorrect', 'off')
        .attr('name', field.caption)
        .attr('placeholder', field.caption + (field.optional && this.environment !== 'viewer' ? ' (optional)' : ''))
        .attr('spellcheck', false)
        .attr('data-sort', field.sort);

      // не ставим особые типы для инпутов чтобы не нарваться на browser-specific обработку этих полей
      // использовать встроенные средства для верификации ввода мы не будем
      // поэжтому просто ставим правильный inputmode, чтобы на девайсах были правильные клавиатуры
      if (field.tp == 'email') {
        $input.attr('inputmode', 'email');
      }

      if (field.tp == 'phone') {
        $input.attr('inputmode', 'tel');
      }

      if (field.tp == 'number') {
        $input.attr('inputmode', 'numeric');
      }

      if (field.tp === 'checkbox') {
        $input.attr('type', 'checkbox');
      }

      // для дропдауна тоже создаем поле readonly просто чтобы все стили были унифицированы и выглядели одинаково
      if (field.tp == 'dropdown') {
        // Чтобы на текстовом инпуте, который поверх селекта, не останавливался таб.
        // Это можно было сделать через атрибут disabled, но он влияет на цвет текста в поле в некоторых браузерах.
        $input.attr({ tabindex: -1 });
        $input.prop({ readonly: true });
        $input.addClass('fake-dropdown');
      }

      var $wrapper = $('<div class="input-wrapper js-input-wrapper"/>');
      $wrapper.append($input);
      $wrapper.append('<div class="overlay"></div>');

      // Если это текстовое поле и мы в конструкторе, добавим кнопки для добавления строчек
      // Только в вертикальном режиме
      if (this.environment !== 'viewer' && isMultilineTextPossible) {
        var $increase = $('<span class="input-line-control input-line-control-add js-add-line"></span>');
        var $decrease = $('<span class="input-line-control input-line-control-remove js-remove-line"></span>');
        $wrapper.append($increase).append($decrease);
      }

      $wrapper.appendTo($form);

      if (field.tp == 'dropdown' && field.items) {
        var $select = $('<select>'),
          $triangle = $(this.DROPDOWN_TRIANGLE.SVG);

        $select.attr('name', field.caption);

        // Добавим первый пустой option, чтобы при выборе первой "настоящей" опции вызывалось событие onchange
        $('<option value="" selected></option>')
          .prop('disabled', !field.optional)
          .appendTo($select);

        for (var j = 0; j < field.items.length; j++) {
          $('<option>')
            .attr('value', field.items[j])
            .text(field.items[j])
            .appendTo($select);
        }

        $input
          .parent()
          .append($triangle)
          .append($select);
      }

      if (field.tp === 'checkbox') {
        var inputMobileClass = Device.isDesktop ? '' : 'input-checkbox-wrapper-mobile';
        $input.wrap('<label class="input-checkbox-wrapper ' + inputMobileClass + '"></label>');
        $input.after(
          '\
						<div class="input-checkbox-inner"> \
							<span class="label">' +
            $input.attr('placeholder') +
            '</span> \
							<span class="tick">' +
            this.CHECKBOX_TICK.SVG +
            '</span> \
						</div>'
        );
      }
    }

    $(this.buttonTemplate()).appendTo($form);

    this.$el.html($form.html()).addClass('common-form');

    this.$button = this.$('.button');
    this.$buttonIcons = this.$('.button svg');
    this.$inputs = this.$('.input-wrapper');
    this.$triangles = this.$('.form-dropdown-triangle');

    this.recalcStyles();
  },

  onDropdownChange: function(e) {
    $(e.currentTarget)
      .parent()
      .find('.js-input')
      .val($(e.currentTarget).val());
  },

  // просим визуально показывать всегда ховер состояние для кнопки (важно при настройке стилей в режиме конструктора)
  setButtonHoverState: function() {
    this.forcePseudoState = 'hover';
    this.applyButtonStyle();
  },

  // просим визуально показывать всегда обычное состояние для кнопки (важно при настройке стилей в режиме конструктора)
  unsetButtonHoverState: function() {
    this.forcePseudoState = false;
    this.applyButtonStyle();
  },

  // при смене высоты надо менять и стили кнопки/инпутов, потому что у них меняются высоты line-height
  recalcStyles: function(params) {
    this.$el.attr('data-layout', this.data.layout).attr('data-style', this.data.style);

    this.$button.find('.caption').text(this.data['button-caption']);

    this.resizeElements(params);
    this.applyInputsStyle(this.$inputs);
    this.applyButtonStyle(params);
  },

  getMinimalDimensions: function() {
    var data = this.data,
      fields = data.fields,
      styleFields = data['style-' + data.style + '-fields'],
      styleButton = data['style-' + data.style + '-button-default'],
      getButtonValue = this.getButtonValue.bind(this, styleButton, styleFields),
      marginsTotal = styleButton.gutter + fields.length * styleFields.gutter,
      underlineWidth = styleFields['underline-width'] || 0,
      buttonMinHeight =
        Math.max(Math.ceil(getButtonValue('font-size') * this.LINE_HEIGHT_FACTOR), this.MIN_FIELD_HEIGHT) +
        underlineWidth,
      inputMinHeight =
        Math.max(Math.ceil(styleFields['font-size'] * this.LINE_HEIGHT_FACTOR), this.MIN_FIELD_HEIGHT) + underlineWidth,
      itemMinHeight = Math.max(buttonMinHeight, inputMinHeight),
      res;

    if (data.layout == 'vertical') {
      res = {
        width: this.MIN_FIELD_WIDTH,
        // Высота всех полей + высота всех инпутов + высота кнопки
        height: marginsTotal + this.getTotalInputsHeight(fields, itemMinHeight) + itemMinHeight,
      };
    }

    if (data.layout == 'horizontal') {
      res = {
        width: marginsTotal + (fields.length + 1) * this.MIN_FIELD_WIDTH,
        height: itemMinHeight,
      };
    }

    return res;
  },

  /**
   * Возвращает суммарную высоту всех инпутов, не считая кнопки,
   * с учётом количества строк в текстовых инпутах
   * @param {Array} fields
   * @param {Number} itemSize Высота текстового инпута с одной строкой
   * @returns {Number}
   */
  getTotalInputsHeight: function(fields, itemSize) {
    return _.reduce(
      fields,
      function(length, field) {
        if (field.tp === 'text') {
          var dimensions = this.getExtraTextareaDimensions(field.rows, itemSize);
          return length + dimensions.height;
        } else {
          return length + itemSize;
        }
      }.bind(this),
      0
    );
  },

  /**
   * Считает количество строк в форме
   * @param {Array} fields
   * @returns {Number}
   */
  getRowsCount: function(fields) {
    return _.reduce(
      fields,
      function(rowsCount, field) {
        // Учитываем количество строк только у текстовых инпутов. Для остальных инпутов количество строк всегда считаем = 1
        var rows = (field.tp === 'text' && field.rows) || 1;
        return rowsCount + rows;
      },
      0
    );
  },

  /**
   * Возвращает размеры инпутов и расстояние между инпутами
   * @param {Object} params
   * @returns {Object}
   */
  getInputDimensions: function(params) {
    var data = this.data;
    var fields = data.fields;
    var itemsCount = fields.length + 1;
    var styleButton = data['style-' + data.style + '-button-default'];
    var styleFields = data['style-' + data.style + '-fields'];
    var marginsTotal = styleButton.gutter + fields.length * styleFields.gutter;
    var ww = params && params.w != undefined ? params.w : data.w;
    var wh = params && params.h != undefined ? params.h : data.h;
    var isVertical = data.layout === 'vertical';
    var widgetSize = Math.floor(isVertical ? wh : ww);
    var totalInputSize = widgetSize - marginsTotal;
    // считаем размер инпута/кнопки вычитая из размера виджета все фиксированные внутренние марджины
    var itemSize;
    var overflow;
    // Для вертикальных форм более сложная математика: нужно учитывать инпуты, у которых несколько строчек
    if (isVertical) {
      var lineHeight = this.getLineHeight();
      var rowsCount = this.getRowsCount(fields);
      // У кнопки другая высота линии, но здесь это не важно. Если мы будем учитывать здесь высоту линии кнопки, то получим неправильные вертикальные расстояния
      var padding = totalInputSize - rowsCount * lineHeight.input - lineHeight.input;
      var oneItemPadding = Math.ceil(padding / itemsCount);
      itemSize = lineHeight.input + oneItemPadding;
      overflow = this.getTotalInputsHeight(fields, itemSize) + itemSize + marginsTotal - widgetSize;
    } else {
      itemSize = Math.ceil(totalInputSize / itemsCount);
      // поскольку в итоге суммарные размеры почти всегда получаться больше чем размер виджета, часть полей надо сделать меньше на 1px
      // overflow это на сколько пикселей мы превысили размер виджета, заодно это и число полей (включая кнопку) с конца, которые надо сделать меньше на 1px чем itemSize
      overflow = itemSize * (fields.length + 1) + marginsTotal - widgetSize;
    }
    return {
      overflow: overflow,
      itemSize: itemSize,
      itemOtherSize: isVertical ? ww : wh,
      gutter: styleFields.gutter,
      lastGutter: styleButton.gutter + styleFields.gutter,
    };
  },

  /**
   * Возвращает высоту линии для инпутов и кнопки, в пикселах
   * @returns {Object}
   */
  getLineHeight: function() {
    var data = this.data || {};
    var lineHeight = this.LINE_HEIGHT_FACTOR;
    // font-size кнопки может быть inherit, поэтому получаем его через метод
    var buttonFontSize = this.getButtonValue(
      data['style-' + data.style + '-button-default'],
      data['style-' + data.style + '-fields'],
      'font-size'
    );
    var inputFontSize = data['style-' + data.style + '-fields']['font-size'];
    return {
      // Вычислим line-height в пикселах. Если line-height дробное число — это множитель, умножим его на font-size
      input: Math.floor(lineHeight < 10 ? lineHeight * inputFontSize : lineHeight),
      button: Math.floor(lineHeight < 10 ? lineHeight * buttonFontSize : lineHeight),
    };
  },

  /**
   * Взвращает размеры для текстового инпута с несколькими строками
   * @param {Number} rows
   * @param {Number} itemSize
   * @returns {{height: number, top: number, bottom: number}}
   */
  getExtraTextareaDimensions: function(rows, itemSize) {
    rows = rows || 1;
    var lineHeight = this.getLineHeight();
    var verticalSpace = Math.round(itemSize - lineHeight.input);
    var isUnderlined = this.data && this.data.style == 'underlined';
    // Если разница между высотой блока и линии — нечётная, верхнее расстояние больше
    var top = isUnderlined ? verticalSpace : Math.ceil(verticalSpace / 2);
    var bottom = isUnderlined ? 0 : Math.floor(verticalSpace / 2);
    return {
      height: rows * lineHeight.input + top + bottom,
      top: top,
      bottom: bottom,
    };
  },

  resizeElements: function(params) {
    var data = this.data;
    var isVertical = data.layout === 'vertical';
    var fields = data.fields;
    var length = fields.length;
    var dimensions = this.getInputDimensions(params);
    var overflow = dimensions.overflow;
    var style = data.style;
    var styleFields = data['style-' + style + '-fields'];
    // Компенсация underline width и нижнего паддинга для мультистрочного текстового инпута (textarea)
    var underlineWidthCompensation = ((styleFields && styleFields['underline-width']) || 0) + 2;

    this.$inputs.add(this.$button).each(
      function(ind, item) {
        var params = {};
        var itemSize = dimensions.itemSize;
        var isLastMargin = ind === length - 1;
        var hasMargin = ind < length;
        var field = fields[ind];
        var $wrapper = $(item);
        var compensateOverflow = ind >= length + 1 - overflow;

        // определенную часть последних полей/кнопку надо сделать на 1px меньше (fields.length + 1 это плюс кнопка)
        if (compensateOverflow) {
          itemSize--;
        }

        params[isVertical ? 'height' : 'width'] = itemSize + 'px';
        params[isVertical ? 'width' : 'height'] = dimensions.itemOtherSize + 'px';
        if (hasMargin) {
          params[isVertical ? 'margin-bottom' : 'margin-right'] = isLastMargin
            ? dimensions.lastGutter
            : dimensions.gutter;
        }
        params[isVertical ? 'margin-right' : 'margin-bottom'] = '';

        // Для текстового поля посчитаем его высоту в зависимости от количества строк
        if (isVertical && field && field.tp === 'text') {
          var rows = field.rows || 1;
          // В массиве this.$inputs находятся html-элементы wrapper'ов, а не самих инпутов
          var $input = $wrapper.find('.js-input');
          var $addLine = $wrapper.find('.js-add-line');
          var $removeLine = $wrapper.find('.js-remove-line');
          var textDimensions = this.getExtraTextareaDimensions(rows, dimensions.itemSize);
          params.height = textDimensions.height + (compensateOverflow ? -1 : 0) + 'px';
          // Если это стиль underlined и задана высота подчёркивания, то нужно её вычесть
          $input.css({ top: textDimensions.top - underlineWidthCompensation, bottom: textDimensions.bottom });
          $input.attr({ rows: rows });
          // Чтобы иконки + и - позиционировались правильно (+ на первой базовой линии, - — на последней),
          // им нужно задавать top, bottom и font-size такой же, как у текстового инпута (font-size задаётся в другом месте)
          $addLine.css({ top: textDimensions.top - underlineWidthCompensation });
          $removeLine.css({ bottom: textDimensions.bottom + underlineWidthCompensation });
          $wrapper.toggleClass('is-multiline', rows > 1);
        }

        $wrapper.css(params);
      }.bind(this)
    );
  },

  showInputPlaceholdersAsValues: function(show) {
    this.$inputs.each(function(index, item) {
      var $input = $(item).find('.js-input');

      if ($input.attr('type') === 'checkbox') {
        $input
          .parent()
          .find('.label')
          .addClass('input-checkbox-preview');
      } else {
        $input.val(show ? $input.attr('placeholder') : '');
      }
    });
  },

  applyInputsStyle: function($inputs, opts) {
    opts = opts || {};
    var data = this.data,
      fields = data.fields,
      styleFields = _.clone(data['style-' + data.style + '-fields']),
      paramsWrapper;

    if (data.style == 'underlined') {
      if (opts.invalid) {
        styleFields['underline-color'] = this.INVALID_COLOR;
        styleFields['underline-opacity'] = 100;
        styleFields['underline-width'] = styleFields['underline-width'] || this.INVALID_BORDER_WIDTH;
      }

      paramsWrapper = {
        'background-color': 'transparent',
        'border-radius': 0,
        'box-shadow':
          'inset 0 ' +
          -styleFields['underline-width'] +
          'px 0 0 ' +
          this.getRgba(styleFields['underline-color'], styleFields['underline-opacity']),
      };
    } else {
      if (opts.invalid) {
        styleFields['border-color'] = this.INVALID_COLOR;
        styleFields['border-opacity'] = 100;
        styleFields['border-width'] = styleFields['border-width'] || this.INVALID_BORDER_WIDTH;
      }

      paramsWrapper = {
        'background-color': this.getRgba(styleFields.color, styleFields.opacity),
        'border-radius': styleFields['border-radius'] + 'px',
        'box-shadow':
          'inset 0 0 0 ' +
          styleFields['border-width'] +
          'px ' +
          this.getRgba(styleFields['border-color'], styleFields['border-opacity']),
      };
    }

    var paramsInput = {
      'font-family': styleFields['font-family'],
      'font-style': styleFields['font-style'],
      'font-weight': styleFields['font-weight'],
      color: this.getRgba(styleFields['text-color'], styleFields['text-opacity']),
      'font-size': styleFields['font-size'],
      'letter-spacing': styleFields['letter-spacing'],
      'margin-bottom': styleFields['underline-width'] ? styleFields['underline-width'] + 'px' : 0, // Приподнимаем инпут над подчеркиванием
    };

    $inputs
      .css(paramsWrapper)
      .find('.js-input, select')
      .css(paramsInput);

    $inputs.find('.input-checkbox-inner .label').css(paramsInput);

    // Чтобы иконки + и - позиционировались правильно (+ на первой базовой линии, - — на последней),
    // им нужно задавать font-size такой же, как у текстового инпута
    $inputs.find('.js-add-line, .js-remove-line').css({ 'font-size': styleFields['font-size'] });

    $inputs.find('.form-dropdown-triangle').each(
      function(ind, item) {
        var $item = $(item),
          $wrapper = $item.parent(),
          $input = $wrapper.find('.js-input'),
          top = $input.position().top,
          bottom = $wrapper.height() - $input.height() - top,
          size = this.getSvgSizesFromFontSize(styleFields['font-size'], 'DROPDOWN_TRIANGLE');

        $item.css({
          fill: this.getRgba(
            styleFields['text-color'],
            styleFields['text-opacity'] * this.DROPDOWN_TRIANGLE.OPACITY_MULTIPLIER
          ),
          top: top,
          bottom: bottom,
          width: size.w,
          height: size.h,
        });
      }.bind(this)
    );

    $inputs.find('.input-checkbox-inner svg').each((ind, item) => {
      var $item = $(item);
      var size = this.getSvgSizesFromFontSize(styleFields['font-size'], 'CHECKBOX_TICK');

      $item.css({
        fill: this.getRgba(styleFields['text-color'], styleFields['text-opacity']),
        width: size.w,
        height: size.h,
      });
    });
  },

  applyButtonStyle: function() {
    var data = this.data,
      fields = data.fields,
      styleFields = data['style-' + data.style + '-fields'],
      styleButton = data['style-' + data.style + '-button-default'],
      styleButtonHover = data['style-' + data.style + '-button-hover'];

    var getButtonValue = this.getButtonValue.bind(this, styleButton, styleFields);
    var getHoverValue = this.getHoverValue.bind(this, styleButtonHover, styleButton, styleFields);
    var letterSpacing = getButtonValue('letter-spacing');

    this.$button.css({
      'line-height': this.$button.height() + 'px',
      'background-color': this.getRgba(getButtonValue('color'), getButtonValue('opacity')),
      'border-radius': getButtonValue('border-radius') + 'px',
      'box-shadow':
        'inset 0 0 0 ' +
        getButtonValue('border-width') +
        'px ' +
        this.getRgba(getButtonValue('border-color'), getButtonValue('border-opacity')),
      'font-family': getButtonValue('font-family'),
      'font-style': getButtonValue('font-style'),
      'font-weight': getButtonValue('font-weight'),
      color: this.getRgba(getButtonValue('text-color'), getButtonValue('text-opacity')),
      'font-size': getButtonValue('font-size'),
      'letter-spacing': letterSpacing,
      'text-align': getButtonValue('text-align'),
      // Добавим text-indent, чтобы компенсировать смещение центрирования из-за letter-spacing (заметно при больших значениях)
      'text-indent': letterSpacing / 2 || 0,
    });

    this.$buttonIcons.css({
      fill: this.getRgba(getButtonValue('text-color'), getButtonValue('text-opacity')),
    });

    // сохраняем дефолтный сгенерированный стиль, чтобы потом использовать вего при ховере/анховере
    this.$button.attr('data-default', this.$button.attr('style'));

    // дефолтный не очищаем, надо чтобы ховер заэкстендил дефолтный
    // мы при ховере/анховере целиком менять будем строки стилей
    this.$button.css({
      'background-color': this.getRgba(getHoverValue('color'), getHoverValue('opacity')),
      'box-shadow':
        'inset 0 0 0 ' +
        getHoverValue('border-width') +
        'px ' +
        this.getRgba(getHoverValue('border-color'), getHoverValue('border-opacity')),
      color: this.getRgba(getHoverValue('text-color'), getHoverValue('text-opacity')),
    });

    // сохраняем ховер сгенерированный стиль, чтобы потом использовать его при ховере/анховере
    this.$button.attr('data-hover', this.$button.attr('style'));

    if (!this.forcePseudoState) {
      // применяем обратно дефолтный стиль
      this.$button.attr('style', this.$button.attr('data-default'));
    }

    this.$button.outerHeight(); // чтобы транзишен не сработал при первоначальном задании стиля

    this.$button.toggleClass('with-transition', !!styleButtonHover.transition && this.environment == 'viewer');
  },

  getRgba: function(color, opacity) {
    var rgb = Utils.hex2rgb(color);
    return 'rgba(' + rgb[0] + ', ' + rgb[1] + ',' + rgb[2] + ',' + opacity / 100 + ')';
  },

  getButtonValue: function(styleButton, styleFields, param) {
    return styleButton[param] == 'inherit' ? styleFields[param] : styleButton[param];
  },

  getHoverValue: function(styleButtonHover, styleButton, styleFields, param) {
    return styleButtonHover[param] == 'inherit'
      ? this.getButtonValue(styleButton, styleFields, param)
      : styleButtonHover[param];
  },

  // обработка наведения на инпут.
  onInputHover: function(e) {
    var data = this.data,
      styleFields = _.clone(data['style-' + data.style + '-fields']);

    $(e.currentTarget)
      .find('.form-dropdown-triangle')
      .css('fill', this.getRgba(styleFields['text-color'], styleFields['text-opacity']));
  },

  onInputHoverOut: function(e) {
    var data = this.data,
      styleFields = _.clone(data['style-' + data.style + '-fields']);

    $(e.currentTarget)
      .find('.form-dropdown-triangle')
      .css(
        'fill',
        this.getRgba(styleFields['text-color'], styleFields['text-opacity'] * this.DROPDOWN_TRIANGLE.OPACITY_MULTIPLIER)
      );
  },

  // обработка наведения на Кнопку.
  onButtonHover: function(e) {
    if (this.$button.hasClass('submitted')) {
      return;
    }
    this.$button.attr('style', this.$button.attr('data-hover'));
  },

  // обработка наведения на Кнопку.
  onButtonHoverOut: function(e) {
    this.$button.attr('style', this.$button.attr('data-default'));
  },

  destroy: function() {
    this.$el
      .off(Device.isDesktop ? 'mouseenter' : 'touchstart', '.button', this.onButtonHover)
      .off(Device.isDesktop ? 'mouseleave' : 'touchend', '.button', this.onButtonHoverOut)
      .off('change', 'select', this.onDropdownChange);

    this.$el && this.$el.remove();
  },

  getSvgSizesFromFontSize: function(newFontSize, type) {
    var h = this[type].H,
      w = this[type].W,
      fontSize = this[type].FONT_SIZE;

    return {
      h: (newFontSize * h) / fontSize,
      w: (newFontSize * w) / fontSize,
    };
  },
});

export default FormWidgetClass;
