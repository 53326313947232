<template>
  <div
    class="panel control-panel video-panel"
    :class="{
      error: isError,
      loading: isLoading,
      loaded: hasVideo,
      youtube: isYoutube,
      vimeo: isVimeo,
      'custom-thumb': isCustomThumbnail,
    }"
  >
    <div class="video-panel-header">Video</div>

    <video-search
      v-model="m.url"
      :is-video-processed="m.video_id"
      :show-social="!isLoading && !hasVideo"
      :highlight-on-error="true"
      @dataObtained="onDataObtained"
      @loaded="onLoaded"
      @error="onVideoSearchError"
      @remove="onRemove"
    />

    <transition name="fade">
      <div v-if="showPreview" class="video-panel-preview-logo" :class="logoClass"></div>
    </transition>
    <transition name="fade">
      <div v-if="hasVideo" class="video-panel-preview-caption">{{ m.title }}</div>
      <div v-if="isPrivate" class="video-panel-preview-caption video-panel-preview-caption-private">Private video</div>
    </transition>

    <transition name="slide">
      <div v-if="showPreview" class="video-panel-preview-wrapper">
        <div class="video-panel-preview" :class="{ 'custom-thumb': isCustomThumbnail, vimeo: isVimeo }">
          <transition name="fade">
            <div class="video-panel-preview-image-container" :class="{ vimeo: isVimeo, dragging: isDragging }">
              <div
                v-if="m.custom_thumbnail_url && !isDragging && !isUploading"
                class="video-panel-preview-image-wrapper"
              >
                <div class="video-panel-preview-image" :style="{ backgroundImage: previewBackground }"></div>
              </div>
              <div v-if="isCustomThumbnail && !isDragging" class="video-panel-preview-mask"></div>
            </div>
          </transition>

          <transition name="fade">
            <div v-if="hasVideo" class="video-panel-settings">
              <div class="video-panel-setting autoplay">
                <div class="video-panel-setting-caption">Autoplay</div>
                <rm-switcher
                  v-model="isAutoplay"
                  class="video-panel-switcher"
                  :state="isAutoplay"
                  color-off="rgb(125, 125, 125)"
                />
              </div>
              <div class="video-panel-setting loop">
                <div class="video-panel-setting-caption">Loop</div>
                <rm-switcher
                  v-model="m.loop"
                  class="video-panel-switcher"
                  :state="m.loop"
                  color-off="rgb(125, 125, 125)"
                />
              </div>
              <div class="video-panel-setting mute">
                <div class="video-panel-setting-caption">
                  Mute
                  <inline-help href="http://help.readymag.com/hc/en-us/articles/360020792871-Video" />
                </div>
                <rm-switcher
                  v-model="m.mute"
                  class="video-panel-switcher"
                  :state="m.mute"
                  :is-disabled="isAutoplay"
                  color-off="rgb(125, 125, 125)"
                />
              </div>
              <div v-if="!isYoutube" class="video-panel-setting info">
                <div class="video-panel-setting-caption">Show Info</div>
                <rm-switcher
                  v-model="m.info"
                  class="video-panel-switcher"
                  :state="m.info"
                  color-off="rgb(125, 125, 125)"
                />
              </div>
              <div v-if="isVimeo" class="video-panel-setting video-panel-setting-color video-panel-setting-color-vimeo">
                <div class="video-panel-setting-caption">Color</div>
                <div class="video-panel-switcher video-panel-switcher-vimeo">
                  <color-picker
                    :color="backgroundColor"
                    :show-alpha="false"
                    class="video-panel-color-picker-vimeo"
                    @update:color="color => (m['color'] = color)"
                  ></color-picker>
                </div>
              </div>
              <div class="video-panel-setting thumb">
                <div class="video-panel-setting-caption">Thumbnail</div>
                <rm-switcher
                  v-model="hasCustomThumbnail"
                  class="video-panel-switcher"
                  :state="hasCustomThumbnail"
                  :is-disabled="isAutoplay"
                  color-off="rgb(125, 125, 125)"
                />
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div v-if="isLoading" class="video-panel-preview-preloader rmpreloader">
              <div class="shadow"></div>
              <div class="arc"></div>
            </div>
          </transition>

          <input ref="uploadInput" class="uploadInput" type="file" name="upload" data-url="/api/upload/" />

          <transition name="fade">
            <div ref="uploadInputDropZone">
              <div v-if="isCustomThumbnail" class="video-panel-dropzone-container">
                <div
                  v-if="m.custom_thumbnail_url && !isDragging && !isUploading"
                  class="video-panel-icon-replace"
                  @click="replaceCustomThumbnail()"
                ></div>
                <div class="video-panel-thumb-upload-container">
                  <div class="upload">
                    <div class="upload-wrapper">
                      <div class="center-wrapper" @click="uploadCustomThumbnail($event)">
                        <div v-if="!isUploading" class="caption-upload-dop caption-upload-dop-light">
                          <span v-if="!m.custom_thumbnail_url">{{ isDragging ? 'Drop here' : 'Drop file here' }}</span>
                          <span v-else>{{ isDragging ? 'Drop here' : '' }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="isUploading" class="video-panel-thumb-preloader preloader">
                  <div class="rmpreloader">
                    <div class="arc"></div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="isError" class="video-panel-error" @click="dismissError">
        <div class="error-text">{{ errorText }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import PreloadDesignImages from '../../../js/common/preload-design-images';
import Uploader from '../../../js/constructor/helpers/uploader';
import InlineHelp from '../../common/inline-help.vue';
import VideoSearch from '../../common/video-search.vue';
import Panel from './panel.vue';
import RmSwitcher from '../../common/rm-switcher.vue';
import ColorPicker from '../../common/color-picker.vue';

export default {
  mixins: [Panel],
  components: {
    InlineHelp,
    VideoSearch,
    RmSwitcher,
    ColorPicker,
  },
  data: function() {
    return {
      isLoading: false,
      isError: false,
      isUploading: false,
      isDragging: false,
      isDropZoneEnter: false,
      isCustomThumbnail: false,
      errorText: '',
      m: {
        url: null,
        video_id: null,
        provider_name: null,
        thumbnail_url: null,
        custom_thumbnail: false,
        custom_thumbnail_url: null,
        title: null,
        start_time: null,
        autoplay: false,
        loop: false,
        mute: false,
        info: false,
        theme: null,
        color: null,
      },
    };
  },
  beforeDestroy: function() {
    this.abortAjaxLoading();
    this.raw_block.workspace.off('main-dragover', this.highlightDropZone, this);
  },

  created: function() {
    // получаем статус isCustomThumbnail в created лайфцикле, чтобы в момент
    // открытия панели переключатель уже стоял в нужном положении
    this.isCustomThumbnail = this.m.custom_thumbnail === true;
  },

  mounted: function() {
    PreloadDesignImages('controls-video_settings');

    // если открываем существующее видео, подключение аплоадера сработает отсюда,
    // а если добавляем новое видео, тогда из метода onLoaded()
    this.mountUploader();
  },

  computed: {
    logoClass: function() {
      var providerName = this.m.provider_name && this.m.provider_name.toLowerCase();
      var classObject = {};
      classObject[providerName] = true;
      return classObject;
    },
    hasVideo: function() {
      return Boolean(this.m.video_id);
    },
    providerName: function() {
      var providerName = this.m && this.m.provider_name;
      return providerName && providerName.toLowerCase();
    },
    isYoutube: function() {
      return this.providerName === 'youtube';
    },
    isVimeo: function() {
      return this.providerName === 'vimeo';
    },
    previewBackground: function() {
      if (!this.m.custom_thumbnail) return null;

      var thumbnailUrl = this.m.custom_thumbnail_url;
      return thumbnailUrl ? 'url(' + thumbnailUrl + ')' : null;
    },
    isPrivate: function() {
      return this.hasVideo && !this.m.title && !this.m.thumbnail_url;
    },
    backgroundColor: function() {
      return this.m.color || '3ab9ff';
    },
    showPreview: function() {
      return this.isLoading || this.hasVideo;
    },
    isAutoplay: {
      get: function() {
        return this.m.autoplay;
      },
      set: function(value) {
        this.m.autoplay = value;
        this.m.mute = value;
        // при включенном автовоспроизведении нет смысла в кастомном превью видео
        if (value) {
          this.isCustomThumbnail = !value;
        }
      },
    },
    hasCustomThumbnail: {
      get: function() {
        return this.isCustomThumbnail;
      },
      set: function(value) {
        this.isCustomThumbnail = value;
        this.m.custom_thumbnail = value;

        if (!value) {
          if (this.$dropCaption) {
            this.$dropCaption.hide();
            this.$replaceIcon.hide();
            this.$dropCaption.css('opacity', 0);
          }
          // после включения триггера Thumbnail мы на время скрываем надпись Drop file here,
          // или иконку replace thumbnail, иначе они выезжает вместе с краем панели, вместо этого делаем
          // им fade-in после того, как отработала анимация панельки
        } else {
          // получение контейнера с текстом делаем в следующем рантайм-цикле, т.к. сразу
          // при включении тумблера его еще нет в DOM, вью не отрисован
          setTimeout(() => {
            this.$dropCaption = $('.video-panel-dropzone-container .caption-upload-dop');
            this.$replaceIcon = $('.video-panel-icon-replace');
            this.$dropCaption.css('opacity', 0);
          }, 0);
          // если сделать сразу show и opacity, то не отработает transition на тексте,
          // поэтому для плавности эффекта немного разделяем их по времени
          setTimeout(() => {
            this.$dropCaption.show();
          }, 150);
          setTimeout(() => {
            this.$dropCaption.css('opacity', 0.9);
          }, 200);
          setTimeout(() => {
            this.$replaceIcon.show();
          }, 300);
        }
      },
    },
  },

  methods: {
    abortAjaxLoading: function() {
      // отменяем ajax запрос который возвращает данные по видео (картинка, заголовок и пр.)
      this.videoEmbedXHR && this.videoEmbedXHR.abort();
      this.isLoading = false;
    },

    onLoaded: function(data) {
      this.isLoading = false;

      // если мы не получили html код ембеда, значит какая-то ошибка
      // возвращаем панельку в то состояние в котором она была до начала загрузки
      if (!data.html) {
        this.onVideoSearchError();
        return;
      }

      data = _.pick(data, 'url', 'video_id', 'provider_name', 'thumbnail_url', 'title', 'start_time');

      if (this.m.start_time) data.start_time = data.start_time || null;

      // сохраняем данные по видео которые мы уже знаем + картинка и заголовок которые вы получаем от апи соответствующего сервиса
      // панелька обновит свое состояние в this.redraw
      let update = _.extend(data, {
        autoplay: Boolean(this.m.autoplay),
        loop: Boolean(this.m.loop),
        mute: Boolean(this.m.mute),
        info: Boolean(this.m.info),
        theme: this.m.theme || 'dark',
        color: this.m.color || '3ab9ff',
      });
      _.extend(this.m, update);

      this.isCustomThumbnail = Boolean(this.m.custom_thumbnail && this.m.custom_thumbnail_url);

      // Аплоадер на панели лежит внутри блока div, видимость которого
      // определяется наличием video_id.
      // Пока он не отработал, инпута аплоадера еще нет в DOM,
      // поэтому нам нужен следующий js цикл, чтобы корректно создать
      // аплоадер превью для только что добавленного видео
      setTimeout(() => {
        this.mountUploader();
      }, 0);
    },

    onRemove: function() {
      _.extend(this.m, {
        video_id: '',
        provider_name: '',
        thumbnail_url: '',
        custom_thumbnail: false,
        custom_thumbnail_url: '',
        title: '',
      });
    },

    onDataObtained: function(video_data) {
      clearTimeout(this.errorRemovalTimeout);
      // если то же самое видео что и сейчас, то игнорируем
      if (this.m.url === video_data.url) {
        this.abortAjaxLoading();
      } else {
        // поскольку сообщение об ошибке держится 2 секунды а потом исчезает (а именно вызывает redraw чтобы привести панельку к текущему состоянию модели)
        // возможны ситуации, когда onDataObtained вызовется до того как пройдут 2 секунды (например введут другой урл и нажмут enter)
        // скрываем боковую панельку и дизейблим кнопку ее открытия
        this.isLoading = true;
        this.isError = false;
        this.m.provider_name = video_data.provider_name;
        this.m.url = video_data.url;
      }
    },

    onVideoSearchError: function() {
      this.showError("Sorry, but we can't get this video due to its limitations");
    },

    showError: function(text) {
      this.errorText = text;
      this.isError = true;
      this.isLoading = false;
      this.errorRemovalTimeout = setTimeout(() => {
        this.isError = false;
        this.errorText = '';
      }, 2000);
    },

    dismissError: function() {
      this.isError = false;
      this.errorText = '';
      clearTimeout(this.errorRemovalTimeout);
    },

    mountUploader: function() {
      // если нет инпута или дропзоны, или уже создан аплоадер, ничего не делаем
      if (!this.$refs.uploadInput || !this.$refs.uploadInputDropZone || this.uploader) {
        return;
      }

      // аплоадер для загрузки кастомного превью для видео
      this.uploader = new Uploader(this, {
        fileInput: $(this.$refs.uploadInput),
        dropZone: $(this.$refs.uploadInputDropZone),
      });
      this.uploader.on('start', this.onStartUpload);
      this.uploader.on('done', this.onDoneUpload);
      this.uploader.on('fail', this.onFailUpload);
      this.uploader.on('dragover', this.highlightDropZoneEnter);

      // подсвечиваем дроп-зону, когда файл занесли в воркспейс
      this.raw_block.workspace.on('main-dragover', this.highlightDropZone, this);
    },

    uploadCustomThumbnail: function(e) {
      if (e && $(e.target).hasClass('uploadInput')) return;

      if (this.isUploading) {
        $(this.$refs.uploadInput).trigger('click', 'stop');
      }

      $(this.$refs.uploadInput).trigger('click');
    },

    replaceCustomThumbnail: function() {
      if (!this.isCustomThumbnail) return;
      $(this.$refs.uploadInput).trigger('click');
    },

    onStartUpload: function(e, data) {
      this.isUploading = true;
    },

    onDoneUpload: function(e, data) {
      this.isUploading = false;

      if (!data || !data.result) {
        this.showError("Sorry, but we couldn't upload a custom thumbnail");
        this.isCustomThumbnail = false;
        return;
      }

      var url = data.result.picture && data.result.picture.url;

      // если в ответе аплоадера получили url, сохраняем его в кастом параметре
      if (url) {
        this.isCustomThumbnail = true;
        _.extend(this.m, {
          custom_thumbnail_url: url,
        });
      } else {
        this.isCustomThumbnail = false;
        this.showError("Sorry, but we couldn't upload a custom thumbnail");
      }
    },

    onFailUpload: function() {
      this.isUploading = false;
    },

    cancelUpload: function() {
      this.isUploading = false;
    },

    // когда вносим картинку в воркспейс, подсвечиваем нашу дроп-зону,
    // чтобы было видно, куда бросать
    highlightDropZone: function(e) {
      this.isDragging = true;
      clearTimeout(this.highlightTimeout);
      this.highlightTimeout = setTimeout(() => {
        this.isDragging = false;
      }, 500);
    },

    // отдельное событие на вход в дроп-зону. при необходимости
    // можно ее дополнительно подсвечивать или менять текст
    highlightDropZoneEnter: function(e) {
      this.isDropZoneEnter = true;
      clearTimeout(this.dropZoneTimeout);
      this.dropZoneTimeout = setTimeout(() => {
        this.isDropZoneEnter = false;
      }, 500);
    },
  },
};
</script>

<style lang="less" type="text/less">
@import (reference) '../../../css/common/constants.less';
@import (reference) '../../../css/constructor/controls.less';
@import (reference) '../../../css/common/fonts.less';

@video-panel-width: @control-panel-width;
@video-panel-horizontal-margin: 15px;
@video-panel-top-margin: 11px;
@video-panel-bottom-margin: 15px;
@video-panel-contrast-color: #f2f2f2;
@video-panel-switcher-width: 36px;
@video-panel-switcher-height: 20px;
@video-panel-height: 160px;
@video-panel-preview-height: 135px;
@video-panel-preview-image-container-height: 103px;
@video-panel-preview-image-container-top: 97px;
@video-panel-height-with-preview: @video-panel-height + @video-panel-preview-height;
@video-panel-extra-height-vimeo: 58px;
@video-panel-extra-height-custom-thumb: 65px;
@video-panel-thumb-height: 48px;

.video_settings {
  .icon {
    background: url('../../../img/constructor/controls/video_settings/icon.png') no-repeat 0 0;
    background-size: 30px 30px;
    &:hover {
      background-image: url('../../../img/constructor/controls/video_settings/icon-hover.png');
    }
  }
}

.control-panel {
  .control-panel-common();
  .picture-upload-panel();
}

.video-panel {
  height: @video-panel-height;
  .transition(0.3s, height, ease-in-out);
  padding: @video-panel-top-margin @video-panel-horizontal-margin @video-panel-bottom-margin;
  &.error {
    height: 153px;
  }
  &.loaded,
  &.loading {
    height: @video-panel-height-with-preview;
    border-radius: @control-panel-border-radius;
    &.vimeo {
      height: @video-panel-height-with-preview + @video-panel-extra-height-vimeo;
    }
    &.custom-thumb {
      height: @video-panel-height-with-preview + @video-panel-extra-height-custom-thumb;
    }
    &.vimeo.custom-thumb {
      height: @video-panel-height-with-preview + @video-panel-extra-height-vimeo +
        @video-panel-extra-height-custom-thumb;
    }
  }
}
.video-panel-header {
  text-align: center;
  margin-bottom: 14px;
}
.video-panel-settings {
  position: absolute;
  top: 0;
  padding: 12px @video-panel-horizontal-margin;
  width: 100%;
  .border-box();
}
.video-panel-setting {
  position: relative;
  height: @video-panel-switcher-height;
  padding-right: @video-panel-switcher-width + 10px;
  margin: 10px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.video-panel-setting.thumb {
  z-index: 1;
}
.video-panel-setting-caption {
  color: @video-panel-contrast-color;
  line-height: @video-panel-switcher-height;
}

.video-panel-switcher {
  position: absolute;
  top: 0;
  right: 0;
}

.video-panel-dropzone-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: @video-panel-preview-image-container-height;
  .video-panel-icon-replace {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0 auto;
    top: 40px;
    background-image: url('../../../img/constructor/controls/video_settings/replace-thumbnail.png');
    background-size: auto 90%;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0.4;
    display: none;
    .transition(0.2s, opacity, ease-in-out);
  }
  &:hover .video-panel-icon-replace {
    opacity: 0.9;
  }
  .caption-upload-dop {
    display: none;
    opacity: 0;
  }
}

.video-panel-thumb-upload-container {
  margin: 0;
  padding: 0;
  height: @video-panel-thumb-height;
  width: 100%;
  position: absolute;
  top: 5px;
}
.video-panel-thumb-preloader {
  position: absolute;
  top: 40px;
  width: 100%;
  > .rmpreloader {
    margin: 0 auto;
  }
}

.video-panel-preview-wrapper {
  position: absolute;
  left: 0;
  top: @video-panel-height;
  width: 100%;
  height: @video-panel-preview-height;
  background-color: rgb(40, 40, 40);

  .video-panel.vimeo & {
    height: @video-panel-preview-height + @video-panel-extra-height-vimeo;
  }
  &.slide-leave-to {
    visibility: hidden;
  }
  &.slide-leave-active {
    .multi-transition(e('visibility 0s ease-out 0.3s'));
  }
  &.slide-enter,
  &.slide-leave-active,
  &.slide-enter-active {
    overflow: hidden;
  }
}

.video-panel-preview {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgb(40, 40, 40);
  height: @video-panel-preview-height;
  border-radius: 0 0 (@control-panel-border-radius - 2px) (@control-panel-border-radius - 2px);
  transform: none;
  .video-panel.vimeo & {
    height: @video-panel-preview-height + @video-panel-extra-height-vimeo;
  }
  .video-panel.custom-thumb & {
    height: @video-panel-preview-height + @video-panel-extra-height-custom-thumb;
  }
  .video-panel.vimeo.custom-thumb & {
    height: @video-panel-preview-height + @video-panel-extra-height-vimeo + @video-panel-extra-height-custom-thumb;
  }
  .multi-transition(e('transform 0.3s ease-out'));
  .transition(0.3s, height, ease-in-out);
  .slide-enter &,
  .slide-leave-to & {
    transform: translateY(-100%);
  }
  .uploadInput {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
@caption-lines-count: 2;
@caption-height: @control-panel-font-size * @control-panel-line-height * @caption-lines-count;
.video-panel-preview-caption {
  position: absolute;
  left: @video-panel-horizontal-margin;
  right: @video-panel-horizontal-margin;
  top: 110px;
  display: block;
  display: -webkit-box;
  width: @video-panel-width - @video-panel-horizontal-margin * 2;
  height: @caption-height;
  -webkit-line-clamp: @caption-lines-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  .video-panel-preview-caption-private {
    line-height: @caption-height;
  }
}
.video-panel-preview-logo {
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  margin: 0 auto;

  &.vimeo {
    width: 46px;
    height: 13px;
    background: url('../../../img/constructor/controls/video_settings/vimeo.png') no-repeat 0 0;
    background-size: 46px 13px;
  }
  &.youtube {
    width: 40px;
    height: 17px;
    background: url('../../../img/constructor/controls/video_settings/youtube.png') no-repeat 0 0;
    background-size: 40px 17px;
  }
}

.video-panel-preview-image-container {
  position: absolute;
  top: @video-panel-preview-image-container-top;
  left: 0;
  height: @video-panel-preview-image-container-height;
  width: 100%;
  border-radius: 0 0 (@control-panel-border-radius - 2px) (@control-panel-border-radius - 2px);
  .transition(0.2s, background, ease-in);
  &.vimeo {
    top: @video-panel-preview-image-container-top + (@video-panel-extra-height-vimeo + 2px);
    height: (@video-panel-preview-image-container-height - 2px);
  }
  &.dragging {
    background-color: rgb(68, 68, 68);
  }
}
.video-panel-preview-image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0 0 (@control-panel-border-radius + 4px) (@control-panel-border-radius + 4px);
  overflow: hidden;
}
.video-panel-preview-image {
  width: 100%;
  height: 100%;
  padding: 1px;
  // Больший border-radius, чем у маски, чтобы не было видно светлых пикселов на скруглённых углах на тёмном фоне
  border-radius: 0 0 (@control-panel-border-radius + 4px) (@control-panel-border-radius + 4px);
  margin-top: -1px;
  margin-left: -1px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 150%;
  .transition(0.5s, background, ease-in);
}
.video-panel-preview-mask {
  -webkit-transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 0 (@control-panel-border-radius + 4px) (@control-panel-border-radius + 4px);
  background: url('../../../img/constructor/controls/video_settings/mask.png') no-repeat 0 0;
  opacity: 0.9;
}

.video-panel-preview-preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -22px;
  margin-left: -22px;
  .shadow {
    display: none;
  }
}
.video-panel-error {
  .error-text {
    width: 122px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  .video_settings {
    .icon {
      background-image: url('../../../img/constructor/controls/video_settings/icon@2x.png');
      &:hover {
        background-image: url('../../../img/constructor/controls/video_settings/icon-hover@2x.png');
      }
    }
  }
  .video-panel-preview-logo {
    &.vimeo {
      background-image: url('../../../img/constructor/controls/video_settings/vimeo@2x.png');
    }
    &.youtube {
      background-image: url('../../../img/constructor/controls/video_settings/youtube@2x.png');
    }
  }
  .video-panel-preview-mask {
    background-image: url('../../../img/constructor/controls/video_settings/mask@2x.png');
    background-size: cover;
  }
}
</style>
