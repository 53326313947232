module.exports = {["template-viewer-share"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	';
 if (hasOverlay) {
__p+='\n		<div class="share-popup-overlay hidden"></div>\n	';
}
__p+='\n\n	<div class="share-popup hidden">\n		<div class="content-wrapper">\n\n			<div class="share-wrapper">\n				<div class="caption">Share</div>\n\n				<div class="type-selector">\n					<!--__magterm-->\n					<div class="type-full">Project</div>\n					<div class="type-page">Current Page</div>\n					<div class="arrow sprite"></div>\n				</div>\n\n				<div class="buttons-wrapper">\n					<div class="share-facebook button" data-tp="facebook"><div class="icon sprite"></div>Share</div>\n					<div class="share-twitter button" data-tp="twitter"><div class="icon sprite"></div>Tweet</div>\n					<div class="share-gplus button" data-tp="gplus"><div class="icon sprite"></div>Google+</div>\n					<div class="share-pinterest button" data-tp="pinterest"><div class="icon sprite"></div>Pin</div>\n					<div class="share-linkedin button" data-tp="linkedin"><div class="icon sprite"></div>Linkedin</div>\n					<div class="share-mail button" data-tp="email"><div class="icon sprite"></div>Email</div>\n				</div>\n\n				';
 if (!Modernizr.isboxversion && !common.isDownloadedSource) { 
__p+='\n					<div class="open-embed"><div class="icon sprite"></div>Embed</div>\n					<div class="close">Close</div>\n					<div class="send-report" data-tp="report"><div class="icon sprite"></div>Report</div>\n				';
 } 
__p+='\n			</div>\n\n			';
 if (!Modernizr.isboxversion && !common.isDownloadedSource) { 
__p+='\n				<div class="embed-wrapper">\n					<div class="caption">Embed</div>\n\n					<div class="size-selector" data-selected="responsive">\n						<div class="size responsive" data-size="responsive">Responsive</div>\n						<div class="size big" data-size="big">512×383</div>\n						<div class="size small" data-size="small">288×236</div>\n						<div class="arrow sprite"></div>\n					</div>\n\n					<div class="code-block">\n						<div class="thumb">\n							<img class="thumb-pic" src="" alt=""/>\n							<div class="thumb-toolbar">\n								<div class="icon arrows"></div>\n								<div class="icon open"></div>\n							</div>\n						</div>\n\n						<textarea class="embed-code"></textarea>\n					</div>\n\n					<div class="back">Back</div>\n				</div>\n			';
 } 
__p+='\n		</div>\n\n		<div class="corner-wrapper">\n			<div class="corner"></div>\n		</div>\n	</div>\n\n';
}
return __p;
},}