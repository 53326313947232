<script>
/**
 * @desc Базовый миксин для контрол-панели. Подмешивается в vue-компонент вот так:
 * {
 *  mixins: [Panel],
 * ...
 * }
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';

export default {
  props: {
    block: Object,
    raw_block: Object,
  },

  data: function() {
    return {
      // Свойства бэкбон-модели хранятся в отдельном объекте, чтобы их можно было
      // динамически добавлять через Vue.set(), если их изначально не бло в данных блока
      // Заодно всегда понятно, что мы работаем со свойствами, которые напрямую пробрасываются
      // и сеттятся в модель
      m: {},
    };
  },

  computed: {
    Globals: function() {
      return window;
    },
  },

  created: function() {
    // Задаем начальные параметры из модели блока
    this.assignBlockState(this.block);
  },

  mounted: function() {
    // Вотчим и эмитим сразу весь объект данных для бэкбон модели,
    // а модель сама триггернет change только нато, что фактически изменится
    this.$watch(
      'm',
      newVal => {
        this.$parent.$emit('change', newVal);
      },
      { deep: true }
    );
  },

  watch: {
    // Следить за изменением состояния блока
    block: {
      handler: function(value) {
        this.assignBlockState(value);
      },
      deep: true,
    },
  },

  methods: {
    $: function(selector) {
      return $(this.$el.querySelector(selector));
    },

    assignBlockState: function(data) {
      _.each(data, (val, key) => this.$set(this.m, key, val));
    },

    // Проверяем не открыта ли панель какого-либо внутреннего компонента, который сообщает об этом через visibleBox
    canClose: function() {
      var canClose = true;
      _.each(this.$children, function(ctrl) {
        canClose = !ctrl.visibleBox && canClose;
        ctrl.visibleBox = false;
      });
      return canClose;
    },

    // Закрываем все панели внутренних компонентов
    closeOthersPopups: function(emitter) {
      if (!emitter) return;

      _.each(this.$children, function(ctrl) {
        if (ctrl != emitter) {
          ctrl.visibleBox = false;
        }
      });
    },

    hide: function() {
      this.$parent.deselect();
    },
  },
};
</script>
