/**
 *	Event-manager для обмена событиями
 * Вместо прямого доступа к глобальным объектам / роутерам, лучше пропускать все событиями через общую шину
 */

import Backbone from 'backbone';
import _ from '@rm/underscore';

const Events =
  window.RM && window.RM.DEBUG
    ? {
        on: function(event, func) {
          console.log('RM.Events: subscribe to "' + event + '": ', func);

          Backbone.Events.on.apply(this, arguments);
        },
        trigger: function(event, params) {
          console.log('RM.Events: "' + event + '": ', params);

          Backbone.Events.trigger.apply(this, arguments);
        },
      }
    : {};

export default _.extend(Events, Backbone.Events);
