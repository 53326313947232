/**
 * Виджет Cart
 */

import { isEnabled } from '../../common/flags';
import ButtonWidget from './button';

var ecommerceCartWidget = ButtonWidget.extend({
  render: function() {
    ButtonWidget.prototype.render.apply(this, arguments);
    this.widgetIsLoaded();
    this.renderSidebar();
    this.mag.eCommerceManager.events.on('ecommerce:cartdata:changed', this.renderTotalItems);
    this.renderTotalItems(this.mag.eCommerceManager.getCartData());
  },

  renderSidebar: function() {
    if (this.mag.eCommerceManager.isMagConnectedToStripe() && this.mag.cartSidebar) {
      this.mag.cartSidebar.render();
    }
  },

  // Добавляет общий прайс содержимого корзины к тексту кнопки
  renderTotalItems: function(cartData) {
    if (!cartData || !cartData.skus) {
      return;
    }
    let totalCount = 0;

    for (const id in cartData.skus) {
      if (cartData.skus.hasOwnProperty(id)) {
        totalCount += cartData.skus[id].cart_count;
      }
    }

    const text = totalCount ? `${this.text} ${totalCount}` : this.text;

    this.forceRenderButtonText(text);
  },

  onButtonClick: function() {
    this.mag.eCommerceManager.changeCartSidebarVisibility();
  },

  destroy: function() {
    this.mag.eCommerceManager.events.off('ecommerce:cartdata:changed', this.renderTotalItems);
    ButtonWidget.prototype.render.apply(this, arguments);
  },
});

export default isEnabled('viewer.eCommerce') ? ecommerceCartWidget : undefined;
