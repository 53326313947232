import BlockFrameClass from './block-frame';
import { Utils } from '../common/utils';

var AddToCartFrame = BlockFrameClass.extend({
  initialize: function() {
    BlockFrameClass.prototype.initialize.apply(this, arguments);
    this.listenTo(this.block, 'addToCartChangeSelectionState', this.changeSelectionState);
    this.$frameBorder = this.$el.children('.frameborder');
  },

  destroy: function() {
    this.stopListening(this.block, 'addToCartChangeSelectionState', this.changeSelectionState);
    BlockFrameClass.prototype.destroy.apply(this, arguments);
  },

  changeSelectionState: function(state) {
    if (state === 'button') {
      this.$frameBorder.css({
        'border-color': Utils.getRGBA(this.block.frameColor.substring(1, 7), 0.5), // нужно тк цвет храниться с # в начале
      });
      this.block.$buttonWrap.css({
        'box-shadow': '0px 0px 0px 1px ' + this.block.frameColor + ' inset',
      });
    } else if (state === 'selects') {
      this.$frameBorder.css({
        'border-color': Utils.getRGBA(this.block.frameColor.substring(1, 7), 0.5), // нужно тк цвет храниться с # в начале
      });
      this.block.$attributesBox.css({
        'box-shadow': '0px 0px 0px 1px ' + this.block.frameColor + ' inset',
      });
    } else if (state === 'both') {
      this.$frameBorder.css({
        'border-color': this.block.frameColor,
      });

      this.block.$attributesBox.css({
        'box-shadow': 'none',
      });

      this.block.$buttonWrap.css({
        'box-shadow': 'none',
      });
    }
  },
});

export default AddToCartFrame;
