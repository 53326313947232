import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockFrameClass from '../block-frame';
import BlockClass from '../block';
import AudioPlayer from '../../common/audio-player';
import templates from '../../../templates/constructor/blocks/audio.tpl';

/**
 * конструктор для аудио (soundcloud) блок в конструкторе (block)
 */

const AudioBlock = BlockClass.extend(
  {
    name: 'Music',
    sort_index: 5, // временное решение, порядок сортировки в боксе выбора виджетов (WidgetSelector). TO FIX.
    thumb: 'audio',

    icon_color: '#FF5700',

    initialize: function(model, workspace) {
      this.initBlock(model, workspace);

      this.inner_template = templates['template-constructor-block-audio'];

      this.frameClass = audioFrame;
      this.controls = ['audio_settings', 'common_animation', 'common_position', 'common_layer', 'common_lock'];

      this.drawDebounced = _.debounce(this.draw, 500);
    },

    /**
     * Переопределяем метод отрисовки виджета
     */
    render: function() {
      this.create();
      this.applySizeConstraints();

      $(this.inner_template(this.model.attributes)).appendTo(this.$content);

      this.currentPlayer = null;
      this.lastPlayer = null;

      this.$el.addClass('audio');
      this.draw();

      // проставляем сначала заглушку виджета
      // как только плеер отрендерится он исчезнет
      this.$el.addClass('empty');

      this.triggerReady();
    },

    // устанавливаем ограничения размеров виджета в зависимости от текущего типа
    // также проверяет если текущие размеры за пределами ограничений то приводит их в рамки
    applySizeConstraints: function() {
      var minW, minH, maxW, maxH;

      // если новый стандартный плеер soundcloud ифрейм
      if (this.model.get('current_type') == 'visual') {
        minW = 200;
        minH = 200;
      }

      // если стандартный плеер soundcloud ифрейм
      if (this.model.get('current_type') == 'standard') {
        minW = 200;
        minH = 166;

        // если рекомендуемая высота ифрейма равна 166 (об этом нам говорит сам soundcloud когда мы получаем данные по введенному пользователем урлу)
        // это значит что снизу нет списка треков и ресайзить виджет по высоте нет смысла (minH == maxH == 166)
        if (this.model.get('embed_height') == 166) {
          maxH = 166;
        }
      }

      // если наш плеер
      if (this.model.get('current_type') == 'minimal') {
        minW = 200;
        minH = 224;

        // если отключеня картинка-обложка
        if (!this.model.get('artwork')) {
          minH = 83;
          maxH = 83;
        }

        // если отключена картинка-обложка и название/автор композиции
        if (!this.model.get('artwork') && !this.model.get('info')) {
          minH = 26;
          maxH = 26;
        }
      }

      // отключаем точки ресайза по высоте если надо
      this.$el.toggleClass('no-height-resize', minH == maxH);

      // устанавливаем ограничения на ресайз, они обрабатываются в Frame который в block.js
      _.extend(this.frame, {
        minwidth: minW,
        maxwidth: maxW,
        minheight: minH,
        maxheight: maxH,
      });

      // если размеры виджета не вписываются в текущие ограничения - коррестируем текущие размеры
      if (this.model.get('w') < this.frame.minwidth) {
        this.model.set('w', this.frame.minwidth, { silent: true });
      }
      if (this.model.get('w') > this.frame.maxwidth) {
        this.model.set('w', this.frame.maxwidth, { silent: true });
      }
      if (this.model.get('h') < this.frame.minheight) {
        this.model.set('h', this.frame.minheight, { silent: true });
      }
      if (this.model.get('h') > this.frame.maxheight) {
        this.model.set('h', this.frame.maxheight, { silent: true });
      }

      // для стандартного плеера если мы знаем что в ифрейме есть список треков (('embed_height') > 166)
      // а у нас текущий размер стоит меньше, тогда разворачивает ифрейм по высоте больше 166 чтобы юзер увидел что есть список треков
      // разморачиваем мы его на рекомендуемую высоту, но не более 450
      if (
        !this.model.get('resized') &&
        this.model.get('current_type') == 'standard' &&
        this.model.get('embed_height') > 166
      ) {
        this.model.set('h', Math.min(this.model.get('embed_height'), 450), { silent: true });
      }

      // применяем текущий размер к блоку
      // тут перерендерится рамка и пр.
      // + сработает наш кастомный фильтр customResizeHandler который указан ниже, в расширении класса Frame
      this.frame.doResize({
        left: this.model.get('x'),
        top: this.model.get('y'),
        width: this.model.get('w'),
        height: this.model.get('h'),
      });
    },

    // отображает плеер в соответствии с текущими настройками
    draw: function(options) {
      if (this.model.get('parsed_url')) {
        // создаем плеер и запоминаем его в переменной, как последный созданный плеер
        // дело в том, что плеер зачастую рендерится достаточно долго
        // и нам не надо показывать его до тех пор пока он не отрендерится полностью
        // чтобы не было мигания плеера при изменении параметров
        // эта переменная понадобится нам позже
        this.lastPlayer = new AudioPlayer(
          _.clone(this.model.attributes),
          this.$content.find('.player-wrapper'),
          this.playerReady.bind(this)
        );
      } else {
        // если у нас не задан url ресурса (только созадли виджета или удалили прежниый урл)

        // удаляем все плееры
        this.lastPlayer && this.lastPlayer.destroy();
        this.currentPlayer && this.lastPlayer != this.currentPlayer && this.currentPlayer.destroy();

        this.currentPlayer = null;
        this.lastPlayer = null;

        this.$el.addClass('empty');
      }

      if (options && options.triggerRedraw) this.workspace.trigger('redraw');
    },

    getIconStyle: function(options) {
      var res = {};
      var path = RM_PUBLIC_PATH + 'img/constructor/widgetbar/icons/';
      var thumbnail_url = this.model.get('thumbnail_url');
      var icon;
      var bg;

      icon = path + 'audio-overlay.svg';

      // если задан текущий ресурс (трек, юзер и пр.) и у него есть картинка
      // тогда формируем строку с multiple background, чтобы поверх маленькой превьюшки у нас
      // шел полупрозрачный оверлей, а над ним шла иконка аудио виджета (нота)
      if (thumbnail_url) {
        bg = path + 'audio-overlay-bg.svg';

        res['background-image'] =
          'url(' + icon + '), url(' + bg + '), url(' + thumbnail_url.replace('-t300x300', '-t80x80') + ')';
        res['background-size'] = 'contain, cover, cover';
      } else {
        res['background-image'] = 'url(' + icon + ')';
        res['background-size'] = 'contain';
        res['background-color'] = this.icon_color;
      }

      if (!options || !options.small) {
        res['border-radius'] = '8px';
      }

      return res;
    },

    // функция для изменения внешнего вида кастомного плера без задержки - на лету
    applyMinimalPlayerStyle: function(params) {
      this.currentPlayer && this.currentPlayer.applyPlayerStyle && this.currentPlayer.applyPlayerStyle(params);

      this.workspace.trigger('redraw');
    },

    // срабатывает при любых измененияз параметров модели
    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      BlockClass.prototype.redraw.apply(this, arguments);

      var changed_attrs = this.model.changedAttributes(),
        // /gwidget
        box_changed =
          _.isObject(changed_attrs) && _.without(_.keys(changed_attrs), 'w', 'h', 'x', 'y', 'z').length == 0;

      // если изменилость что-то кроме 'w', 'h', 'x', 'y', 'z' тогда пересчитываем новые ограничения которые мы наложим на размеры виджета
      if (!box_changed) this.applySizeConstraints();

      // если непонятно что изменилось перерендерим все
      if (!_.isObject(changed_attrs)) {
        this.drawDebounced();
        return;
      }

      // если изменился урл ресурса - перерендериваем сразу
      if (changed_attrs.url != undefined) {
        this.draw({ triggerRedraw: true });
        return;
      }

      // если изменился тип плеера (standart or minimal) - перерендериваем c отсрочкой
      if (changed_attrs.current_type) {
        this.drawDebounced();
        return;
      }

      // здесь смотрим какие параметры плеера влияют на его внешний вид и функционал
      // например autoplay нам не интересен, потому на его изменение не будет никакой реакции (данный параметр важен только на фронте во вьювере)
      var params_attrs_standard = ['comments', 'artwork', 'socials', 'playcount', 'color'],
        params_attrs_minimal = ['artwork', 'info', 'color'];

      // если тип плеер standard или visual тогда проверяем на измененность параметров 'comments', 'artwork', 'socials', 'playcount', 'color'
      if (_(['standard', 'visual']).contains(this.model.get('current_type'))) {
        if (_.intersection(_.keys(changed_attrs), params_attrs_standard).length) this.drawDebounced();
      }

      // если тип плеер minimal тогда проверяем на измененность параметров 'artwork', 'info', 'color'
      if (this.model.get('current_type') == 'minimal') {
        if (_.intersection(_.keys(changed_attrs), params_attrs_minimal).length)
          this.applyMinimalPlayerStyle(_.clone(this.model.attributes));
      }
    },

    // интересная функция
    // вызывается каждый раз когда плеер будет готов к показу
    // все дело в том, что почти любое изменение параметров влечет за собой перерендеривание плеера
    // наш кастомный мы перерендериваем мгновенно через applyMinimalPlayerStyle (без пересоздания)
    // а вот смена типа плеера (с standard на minimal или наоборот) или изменение параметров стандартного (ифрейм) плеера
    // требует его полного пересоздания, и каждый раз когда плеер будет готов вызывается эта функция
    // вся фишка в том, что пока плеер не готов, мы видим предыдущий плеер (плеер с предыдущими параметрами, чтобы не было мигания при перерендеринге)
    // поэтому здесь мы должны удалить старые плееры и показать тот который вызвал playerReady (но только в том лучае если он последний созданный на данный момент)
    playerReady: function(player) {
      this.$el.removeClass('empty');

      // если плеер сказал что он ready, но при этом мы знаем что этот плеер не был создан последним, просто удаляем его
      // это значит что плеер "промежуточный", был создан при изменении параметров, но после этого параметры снова менялись и были созданы другие плееры под новые параметры
      // например мы быстро меняли параметры стандартного плеера, на каждое такое изменение у нас создается новая версия плеера
      // с нужными параметрами, так вот все эти созданные плееры рано или поздно скажут ready, но только один из них
      // который создан последним заслуживает жить, здесь это и проверяется this.lastPlayer != player
      if (this.lastPlayer != player) {
        player.destroy();
        return;
      }

      // раз мы здесь, значит пеер который сказал playerReady последний из созданный и именно его надо показать
      // а предыдущий видимый плеер надо удалить
      this.currentPlayer && player != this.currentPlayer && this.currentPlayer.destroy();

      // запоминаем плеер как текущий видимый и показываем его
      if (this.model.get('parsed_url')) {
        this.currentPlayer = player;
        this.currentPlayer.show();
      }
    },

    // Сохраняем флаг resized, чтобы не расширять плеер по высоте, если в нем список треков а мы отресайзили его
    getBoxData: function() {
      return _.extend(BlockClass.prototype.getBoxData.apply(this, arguments), { resized: true });
    },
  },
  {
    defaults: {
      // current_type: 'standard', //'visual,' 'standard', 'minimal'
      current_type: 'visual', // 'visual,' 'standard', 'minimal'
      autoplay: false,
      comments: false,
      artwork: true,
      socials: true,
      playcount: true,
      info: true,
      color: 'ff5100',
      thumbnail_url: '',
      // h: 166
      w: 400,
      // по центру оси x холста.
      x: Math.round(1024 / 2 - 400 / 2),
      h: 400,
    },
  }
);

var audioFrame = BlockFrameClass.extend({
  customResizeHandler: function(box, resizePoint, resizeByMouse) {
    // в случае если у нас кастомный плеер и есть артворк ресайз всегда должен работать так, чтобы артворк был квадратным
    // для этого достаточно чтобы высота = ширина + 24px
    if (this.block.model.get('current_type') == 'minimal' && this.block.model.get('artwork')) {
      if (!resizePoint) {
        box.height = box.width + 24;
      } else {
        if (resizePoint == 'n' || resizePoint == 's') box.width = box.height - 24;

        if (resizePoint == 'w' || resizePoint == 'e') box.height = box.width + 24;

        if (resizePoint == 'se') {
          if (box.width > box.height - 24) box.height = box.width + 24;
          else box.width = box.height - 24;
        }

        if (resizePoint == 'nw' || resizePoint == 'ne' || resizePoint == 'sw' || resizePoint == 'se') {
          var x = box.width + box.left,
            y = box.height + box.top;

          if (box.width > box.height - 24) box.height = box.width + 24;
          else box.width = box.height - 24;

          if (resizePoint == 'nw') {
            box.left = x - box.width;
            box.top = y - box.height;
          }

          if (resizePoint == 'ne') {
            box.top = y - box.height;
          }

          if (resizePoint == 'sw') {
            box.left = x - box.width;
          }

          if (resizePoint == 'se') {
            // здесь ничего не надо
          }
        }
      }
    }

    //			if (!_.isEmpty(this.model.changed)) { this.model.save(); }

    // resizeByMouse - флажок который говорит функция вызвана при ресайзе мышкой
    // нужно для сложных customResizeHandler, когда frame.doResize() можеты вызываться из разных мест
    // в частности если он вызвался из frame.onResize (при ресайзе мышкой),
    // тогда мы знаем что при отпускании мыши изменения размеров-положения бокса сохраняться в модели (saveBox в onResizeEnd)
    // а если мы вызвали frame.doResize() сами (например это есть в виджете аудио или фб), тогда нам надо знать
    // что customResizeHandler вызвался не из-за мыши, а из-за того что мы сами вызвали frame.doResize()
    // и соответсвенно нам надо сделать saveBox самим
    if (!resizeByMouse) {
      var modelDims = _.pick(this.block.model.attributes, 'x', 'y', 'w', 'h');
      if (modelDims.x != box.left || modelDims.y != box.top || modelDims.w != box.width || modelDims.h != box.height) {
        this.block._saveXHR && this.block._saveXHR.abort();
        this.block._saveXHR = this.block.model.save({
          x: box.left,
          y: box.top,
          w: box.width,
          h: box.height,
        });
      }
    }

    return box;
  },
});

export default AudioBlock;
