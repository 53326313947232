import text from './text';
import video from './video';
import audio from './audio';
import backgroundVideo from './background-video';
import background from './background';
import picture from './picture';
import slideshow from './slideshow';
import twitter from './twitter';
import facebook from './facebook';
import shape from './shape';
import iframe from './iframe';
import gmaps from './gmaps';
import button from './button';
import hotspot from './hotspot';
import form from './form';
import ecommercecart from './e-commerce-cart';
import addtocart from './add-to-cart';
import rmheader from './rm-header';
import rmfooter from './rm-footer';
import rmcookies from './rm-cookies';

export default {
  text,
  video,
  audio,
  backgroundVideo,
  background,
  picture,
  slideshow,
  twitter,
  facebook,
  shape,
  iframe,
  gmaps,
  button,
  hotspot,
  form,
  ecommercecart,
  addtocart,
  rmheader,
  rmfooter,
  rmcookies,
};
