import _ from '@rm/underscore';
import BlockFrameClass from './block-frame';
import MathUtils from '../common/mathutils';

const PackFrameClass = BlockFrameClass.extend({
  baseDimension: 'width',
  initialize: function(options) {
    BlockFrameClass.prototype.initialize.apply(this, arguments);
    this.setSizeConstraints();
  },
  setSizeConstraints: function() {
    var limits = this.getLimits();
    this.minwidth = Math.max(15, limits.minwidth || 0);
    this.minheight = Math.max(15, limits.minheight || 0);
    this.maxwidth = Math.min(Infinity, limits.maxwidth || Infinity);
    this.maxheight = Math.min(Infinity, limits.maxheight || Infinity);
  },
  getLimits: function() {
    var oldPackBox = this.block.getModelBox();
    // Если группа full-width (в ней есть хотя бы один full-width-виджет), рассчитываем размеры блоков по изменению высоты
    // В остальных случаях — по ширине
    var baseDimension = this.block.isFullWidth() ? 'height' : this.baseDimension;
    var factors = _.reduce(
      this.block.blocks,
      function(factors, block) {
        var oldBox = block.getModelBox();
        var limits = {
          minwidth: block.frame && block.frame.minwidth,
          minheight: block.frame && block.frame.minheight,
          maxwidth: block.frame && block.frame.maxwidth,
          maxheight: block.frame && block.frame.maxheight,
        };
        if (block.proportional) {
          limits = MathUtils.getBoxLimits(oldBox, limits);
        }
        if (block.frame && block.frame.applyExtraConstraints) {
          var extraConstraints = block.frame.applyExtraConstraints({
            width: limits.minwidth,
            height: limits.minheight,
          });
          limits.minwidth = extraConstraints.width;
          limits.minheight = extraConstraints.height;
        }
        // Если блок повёрнут, работаем с bounding box вместо бокса модели (так же, как при ресайзе)
        var oldBB = oldBox.angle ? MathUtils.calcBoundingBox(oldBox, false, true) : oldBox;
        var downsizeFactor =
          (oldBox.angle ? oldBB[baseDimension] : oldBox[baseDimension]) / limits['min' + baseDimension];
        var upsizeFactor =
          (oldBox.angle ? oldBB[baseDimension] : oldBox[baseDimension]) / limits['max' + baseDimension];
        return {
          min: Math.min(downsizeFactor, factors.min),
          max: Math.max(upsizeFactor, factors.max),
        };
      },
      { min: Infinity, max: 0 }
    );
    return {
      minwidth: oldPackBox.width / factors.min,
      minheight: oldPackBox.height / factors.min,
      maxwidth: oldPackBox.width / factors.max,
      maxheight: oldPackBox.height / factors.max,
    };
  },
  show: function(fromWidgetBar) {
    _.each(this.block.blocks, function(block) {
      // Рамки блоков внутри группы и так не видны (прячутся css-ом),
      // Но они прячутся явно, чтобы отвязать обработчики mousemove, которые пересчитывают точки на рамке каждого блока.
      block.frame.hide();
    });
    BlockFrameClass.prototype.show.apply(this, arguments);
  },
  onResizeStart: function(event, drag) {
    BlockFrameClass.prototype.onResizeStart.apply(this, arguments);
    _.each(this.block.blocks, function(block) {
      block.frame.onResizeStart(event, drag, { isGroupResize: true });
    });
  },
  onResizeEnd: function(event, drag) {
    // Сначала общий сейв боксов всех блоков в onResizeEnd группы,
    // потом rasterize и imagescale, которые вызываются в onResizeEnd блоков
    BlockFrameClass.prototype.onResizeEnd.apply(this, arguments);
    _.each(this.block.blocks, function(block) {
      block.frame.onResizeEnd(event, drag, { skipHistory: true, skipPersist: true, isGroupResize: true });
    });
  },
  doResize: function(newBox, resizeByMouse, direction, fromCenter) {
    this.block.line &&
      this.block.line.update(
        newBox,
        this.block.isVisible(),
        this.block.model.get('fixed_position'),
        this.block.model.getViewport()
      );
    var oldBox = this.block.getModelBox();
    var resizeBlockBound = this.resizeBlock.bind(this, oldBox, newBox, direction, fromCenter);
    var newBlockBoxes = _.map(this.block.blocks, resizeBlockBound);
    // Скорректируем общую рамку по вычисленным боксам отдельных блоков,
    // иначе рамка иногда будет визуально не совпадать с фактическим общим боксом.
    newBox = MathUtils.getBoundingBoxOfMany(newBlockBoxes);
    this.block.css(_.extend({}, newBox, { doNotRedrawPacksFrames: true }), this.currentResizePoint);
    return newBox;
  },
  resizeBlock: function(oldPackBox, newPackBox, direction, fromCenter, block) {
    var oldBox = block.getModelBox();
    var angle = oldBox.angle;
    var oldBB = angle ? MathUtils.calcBoundingBox(oldBox, false, true) : oldBox;
    // Если блок повёрнут, работаем с bounding box вместо бокса модели.
    // Если группа full-width (в ней есть хотя бы один full-width-виджет), рассчитываем новые размеры блоков только по изменению высоты
    var newBB = MathUtils.getResizedBoxNested(
      oldBB,
      oldPackBox,
      newPackBox,
      this.block.isFullWidth() ? 'height' : null
    );

    // Применим ограничения, в том числе пропорциональный ресайз (как для изображений)
    // или минимальные / фиксированные размеры (как для фейсбук-кнопки)
    newBB = block.frame.applyConstraints(newBB, {
      direction: direction,
      fromCenter: fromCenter,
      packBox: newPackBox,
      // Для повёрнутых блоков нужно явно передавать коэффициент сторон bbox-а, иначе этот коэффициент вычислится из бокса модели
      // и повёрнутые пропорциональные блоки будут ресайзиться неправильно
      ratio: angle
        ? oldBB[this.baseDimension] / oldBB[this.baseDimension === 'width' ? 'height' : 'width']
        : block.ratio,
      baseDimension: this.baseDimension,
    });

    // Для повёрнутых блоков мы вычислили изменённый bounding box, а не бокс модели (то есть не css-параметры).
    // Нужно перевести bounding box обратно в бокс модели
    var cssBox = angle ? MathUtils.getResizedBoxByBB(oldBox, oldBB, newBB) : newBB;
    block.css(
      _.extend(
        {},
        {
          // Округляем размеры бокса только перед тем, как сделать из него css-стиль. В вычислениях используем дробные значения.
          left: Math.round(cssBox.left),
          top: Math.round(cssBox.top),
          width: Math.round(cssBox.width),
          height: Math.round(cssBox.height),
        },
        {
          doNotRedrawPacksFrames: true,
          isGroupResize: true,
          // Хотспоту из-за того что он ресайзится одновременно с другими хотспотами, нужно знать id блоков в группе
          groupedIds: block.model.get('type') === 'hotspot' ? _.map(this.block.blocks, 'id') : [],
        }
      )
    );

    block.recalcFixedLine();
    block.recalcStickedLine();
    // Вернём bounding box (для не повёрнутых блоков он совпадает с боксом модели).
    // Этот bounding box будет использоваться для корректировки общего bounding box'а группы
    return newBB;
  },
});

export default PackFrameClass;
