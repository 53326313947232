/**
 * jQuery модули из common/utils.js
 */

(function($) {
  // Хак, используемый для выделения символа в невидимом инпуте
  // Сам символ - непечатаемый
  // Суть хака - позволить событиям copy & paste происходить на воркспейсе и блоках
  $.fn.selectText = function() {
    var doc = document,
      element = this[0],
      range,
      selection;

    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    this.focus();
    element.selectionStart = 0;
    element.selectionEnd = 1;
  };

  // Определяет позицию курсора в инпуте
  $.fn.setCursorPos = function(pos) {
    var $input, input;

    $input = this.eq(0);
    if (!($input && $input.is('input'))) {
      return;
    }
    input = $input.get(0);

    if (input.setSelectionRange) {
      // Standard-compliant browsers
      input.focus();
      input.setSelectionRange(pos, pos);
    } else if (input.createTextRange) {
      // IE
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  };

  // Устанавливает позицию курсора в инпуте
  $.fn.getCursorPos = function() {
    var $input, input;

    $input = this.eq(0);
    if (!($input && $input.is('input'))) {
      return;
    }
    input = $input.get(0);

    if (input.selectionStart) {
      // Standard-compliant browsers
      return input.selectionStart;
    } else if (document.selection) {
      // IE
      input.focus();
      var sel = document.selection.createRange();
      var selLen = document.selection.createRange().text.length;
      sel.moveStart('character', -input.value.length);
      return sel.text.length - selLen;
    }
  };

  // Присваивает элементу класс на заданное время,
  // затем убирает
  $.fn.flashClass = function(className, delay) {
    var self = this,
      timeout;
    this.addClass(className);
    clearTimeout(this.data('timeout'));
    timeout = setTimeout(function() {
      if (typeof self === 'object' && self.length) {
        self.removeClass(className);
        self.data('timeout', null);
      }
    }, delay);
    this.data('timeout', timeout);
    return this;
  };

  $.fn.cardNumberFormatter = function() {
    this.on(
      'input',
      function() {
        var card_digits, card_type, replacer, cursor_pos;

        // Не даем вводить ничего кроме цифр и пробелов
        cursor_pos = this.getCursorPos();
        if (/[^\d\s]/g.test(this.val())) {
          this.val(this.val().replace(/[^\d\s]/g, ''));
          this.setCursorPos(cursor_pos - 1);
        }

        card_digits = this.val().replace(/\s/g, '');

        this.data('prev_card_digits', this.data('prev_card_digits') || card_digits);

        if (window.Stripe && window.Stripe.card && window.Stripe.card.cardType) {
          card_type = Stripe.card.cardType(card_digits);
        }

        if (card_type == 'American Express') {
          replacer = function(string) {
            // Разбиение для Amrican Express
            return string.replace(/^(\d{4})(\d{6})?/, '$1 $2 ').replace(/\s+/g, ' ');
          };
        } else {
          replacer = function(string) {
            // Разбиение на группы по 4
            return $.trim(string.replace(/(\d{4})(\d{4})?(\d{4})?(\d{3,4})?/, '$1 $2 $3 $4').replace(/\s+/g, ' '));
          };
        }

        // Форматируем поле карты, если цифры добавляются, а не стираются
        if (
          !this.data('prev_card_digits') ||
          (card_digits.length > this.data('prev_card_digits').length && card_digits.length < 17)
        ) {
          cursor_pos = this.getCursorPos();
          this.val(replacer(card_digits));

          // Устанавливаем курсор, только если он находился где-то в середине
          if (cursor_pos < $.trim(this.val()).length - 1 && this.data('prev_card_digits')) {
            this.setCursorPos(cursor_pos);
          }
        }
        this.data('prev_card_digits', card_digits);
      }.bind(this)
    );
  };

  // добавляем новый псевдо селектор :icontains для jquery чтобы искать ноды с вхождениями текста без учета регистра
  // существующий :contains ищет ноды с учетом регистра
  $.expr[':'].icontains = function(obj, index, meta, stack) {
    var filter = meta[3] + '',
      text = obj.textContent || obj.innerText || $(obj).text() || '',
      regex = new RegExp('(' + filter + ')', 'i');

    return !!text.match(regex);
  };

  // добавляем новый всевдо селектор :econtains для jquery чтобы искать ноды с конкретным текстом
  // существующий :contains ищет ноды по вхождению текста, а не по точному совпадению
  $.expr[':'].econtains = function(obj, index, meta, stack) {
    var filter = meta[3] + '',
      text = obj.textContent || obj.innerText || $(obj).text() || '',
      regex = new RegExp('(^' + filter + '$)', '');

    return !!text.match(regex);
  };
})($);
