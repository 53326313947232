import $ from '@rm/jquery';
import _ from '@rm/underscore';
import WidgetClass from '../widget';
import SlideshowPlayer from '../../common/slideshow-player';

/**
 * Виджет Галерея изображений
 */
const SlideshowWidget = WidgetClass.extend({
  // достаточно версии прототипа
  // initialize: function(data, page) {},

  render: function() {
    this.makeBox('widget-slideshow');

    // выкидываем картинки которые загружены с ошибками
    this.pictures = _.filter(this.pictures, function(picture) {
      return !picture.error;
    });
    // создаем слайдшоу плеер который сам знает как рендерится
    // первый параметр this передает плееру объект в котором находятся все нужные параметры (моделей во вьвере у нас нет)
    // в констркторе например при создании плеера передается this.model.toJSON() вместо this
    // также передаем Мэг для того, чтобы корректно отработал .getPicsTransitionTime()
    this.player = new SlideshowPlayer(this, this.$el, 'viewer', null, this.page && this.page.mag);

    this.player.on(
      'ready',
      _.bind(function() {
        setTimeout(this.widgetIsLoaded, 100);
      }, this)
    );

    // если стрелки скрыты или используются не науновские
    if (this.theme_data.arrows && this.theme_data.arrows_type === 'noun') {
      this.renderNounIcons();
    }

    this.rendered = true;

    return this;
  },

  renderNounIcons: function() {
    // если еще растеризованные картинки не пришли то используется фолбэк base64(svgutils.js)
    var leftArrowData =
        this.left_arrow_noun && this.left_arrow_noun.rasterUrl ? this.left_arrow_noun : this.theme_data.left_icon_noun,
      rightArrowData =
        this.right_arrow_noun && this.right_arrow_noun.rasterUrl
          ? this.right_arrow_noun
          : this.theme_data.right_icon_noun,
      positionOfActiveArrows = this.active_arrows;

    if (leftArrowData && leftArrowData.rasterUrl) {
      var $prevImg = $('<img>');
      $prevImg
        .on(
          'load',
          _.bind(function() {
            if (positionOfActiveArrows === 'bottom') {
              this.$el
                .find('.prev-picture-arrow-bottom')
                .empty()
                .html($prevImg.clone());
            } else {
              this.$el
                .find('.prev-picture-arrow-middle')
                .empty()
                .html($prevImg.clone());
            }
          }, this)
        )
        .on('error', this.widgetIsLoaded);

      $prevImg.attr(
        'src',
        Modernizr.retina ? leftArrowData.raster2xUrl || leftArrowData.rasterUrl || '' : leftArrowData.rasterUrl || ''
      );
    }

    if (rightArrowData && rightArrowData.rasterUrl) {
      var $nextImg = $('<img>');
      $nextImg
        .on(
          'load',
          _.bind(function() {
            if (positionOfActiveArrows === 'bottom') {
              this.$el
                .find('.next-picture-arrow-bottom')
                .empty()
                .html($nextImg.clone());
            } else {
              this.$el
                .find('.next-picture-arrow-middle')
                .empty()
                .html($nextImg.clone());
            }
          }, this)
        )
        .on('error', this.widgetIsLoaded);

      $nextImg.attr(
        'src',
        Modernizr.retina ? rightArrowData.raster2xUrl || rightArrowData.rasterUrl || '' : rightArrowData.rasterUrl || ''
      );
    }
  },
  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  // метод должен вернуть true если с виджетом все ок и его надо показать во вьювере,
  // в противном случае виджета даже не будет создан
  isValid: function() {
    // если в виджете загружена хоть одна картинка
    return this.pictures && this.pictures.length;
  },

  start: function() {
    this.started = true;

    if (!RM.screenshot && !this.destroyed && this.player && this.theme_data.autoplay && this.rendered && this.loaded) {
      this.player.startAutoplay();
    }

    return this;
  },

  stop: function() {
    this.started = false;

    if (!RM.screenshot && !this.destroyed && this.player && this.rendered && this.loaded) {
      this.player.stopAutoplay();
    }

    return this;
  },

  destroy: function() {
    this.player && this.player.off();

    this.player && this.player.destroy();

    this.player = null;

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },
});

export default SlideshowWidget;
