import Vue from 'vue';
import BlockClass from '../block';
import RmCookies from '../../../components/common/rm-cookies.vue';

const rmCookies = BlockClass.extend(
  {
    name: 'RM Cookies',
    thumb: 'rm-cookies',
    icon_color: '#282828',

    initial_controls: ['common_position', 'common_lock'],

    restrictions: function() {
      const permissions = RM.constructorRouter && RM.constructorRouter.me && RM.constructorRouter.me.get('permissions');
      return permissions && permissions.can_use_rm_widgets;
    },

    initialize: function(model, workspace) {
      this.initBlock(model, workspace);
    },

    render: function() {
      this.create();
      this.$el.addClass('rm-cookies');
      this.controls = this.initial_controls;

      const rmCookiesVue = Vue.extend(RmCookies);
      this.vue = new rmCookiesVue({
        el: this.$content.get(0),
      });

      this.rendered = true;

      this.triggerReady();
    },

    destroy: function() {
      if (this.vue) {
        this.vue.$destroy();
      }

      return BlockClass.prototype.destroy.apply(this, arguments);
    },
  },
  {
    defaults: {
      w: 324 + 16 * 2,
      h: 84 + 16 * 2,
    },
  }
);

export default rmCookies;
