module.exports = {["template-common-login"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n	<div class="login-popup hidden">\n\n		<div class="login-popup-wrapper">\n			<div class="login ljr-panel hidden">\n				';
 if (!Modernizr.isboxversion) { 
__p+='\n					<div class="header"><a href="/join/" class="link">Join</a> or <span>Log in</span></div>\n				';
 } else { 
__p+='\n					<div class="header"><span>Log in</span></div>\n				';
 } 
__p+='\n				<form method="post" action="/api/redirect" id="login_form">\n					<input name="username" autocapitalize="off" autocorrect="off" required="required" class="email-input input-template"  autocomplete="on" type="email" spellcheck="false" value="" placeholder="Email"/>\n					<input name="password" autocapitalize="off" autocorrect="off" required="required" class="password-input input-template" autocomplete="on"  type="password" spellcheck="false" value=""  placeholder="Password" />\n					<input name="url" type="hidden" value="" />\n					<div class="go">Log in</div>\n				</form>\n				';
 if (!Modernizr.isboxversion) { 
__p+='\n					<div class="social-caption">Log in with</div>\n					<div class="social-auth" data-provider=\'facebook\'>\n						<span class="icon"></span>\n						<span class="buttonText">Facebook</span>\n					</div>\n					<div class="social-auth" data-provider=\'google\'>\n						<span class="icon"></span>\n						<span class="buttonText">Google</span>\n					</div>\n				';
 } 
__p+='\n				<a href="/recover/" class="link forgot">Forgot password?</a>\n				<div class="error">Invalid email or password</div>\n			</div>\n\n\n			';
 if (!Modernizr.isboxversion) { 
__p+='\n				<div class="join ljr-panel hidden">\n					<div class="header"><span>Join</span> or <a href="/login/" class="link">Log in</a></div>\n					<input autocapitalize="on"  autocorrect="off" required="required" class="fullname-input input-template for-validate"  maxlength="24" type="text"     spellcheck="false" value="" placeholder="Name" />\n					<input autocapitalize="off" autocorrect="off" required="required" class="email-input input-template for-validate"     type="email"    spellcheck="false" value="" placeholder="Email" />\n					<input autocapitalize="off" autocorrect="off" required="required" class="password-input input-template for-validate"  type="password" spellcheck="false" value="" placeholder="Password" />\n\n					<div class="checkbox-container">\n						<label>\n							<input type="checkbox" class="terms-checkbox for-validate" required="required">\n							<span class="checkmark"></span>\n						</label>\n						<p>I accept Readymag’s\n							<a href="https://readymag.com/readymag/terms-and-privacy/" target="_blank">Terms and Privacy Policy</a>\n						</p>\n					</div>\n\n\n					<div class="checkbox-container">\n						<label>\n							<input type="checkbox" class="subscribe-checkbox">\n							<span class="checkmark"></span>\n						</label>\n						<p>Кeep me updated on news and offers</p>\n					</div>\n\n\n					<div class="go">Join</div>\n\n					<div class="social-caption">Sign up with</div>\n					<div class="social-auth" data-provider=\'facebook\'>\n						<span class="icon"></span>\n						<span class="buttonText">Facebook</span>\n					</div>\n					<div class="social-auth" data-provider=\'google\'>\n						<span class="icon"></span>\n						<span class="buttonText">Google</span>\n					</div>\n				<div class="error"></div>\n\n				</div>\n			';
 } 
__p+='\n\n			<div class="recover ljr-panel hidden">\n				<div class="header"><span>Reset Password</span></div>\n				<div class="wrapper">\n					<div class="info">Please enter your email address and we\'ll send you instructions:</div>\n					<input name="username" autocapitalize="off" autocorrect="off" required="required" class="email-input input-template"  autocomplete="on" type="email" spellcheck="false" value="" placeholder="Email"/>\n					<div class="go">Submit</div>\n				</div>\n				<div class="send-message">Done! You should receive an email shortly.</div>\n				<div class="go-back">Go Back</div>\n			</div>\n\n			<div class="reset ljr-panel hidden">\n				<div class="header"><span>Reset Password</span></div>\n				<input autocapitalize="off" autocorrect="off" required="required" class="new-password-input input-template"  type="password" spellcheck="false" value="" placeholder="New Password" />\n				<input autocapitalize="off" autocorrect="off" required="required" class="verify-password-input input-template"  type="password" spellcheck="false" value="" placeholder="Verify Password" />\n				<div class="go">Save</div>\n				<div class="error"></div>\n			</div>\n\n			';
 if (!Modernizr.isboxversion) { 
__p+='\n				<div class="close-popup"></div>\n				<a href="/" class="link go-main"></a>\n			';
 } 
__p+='\n		</div>\n	</div>\n\n';
}
return __p;
},["template-common-login-preloader"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n    <div class="rmpreloader button">\n        <div class="arc"></div>\n    </div>\n';
}
return __p;
},}