<template>
  <div class="icons">
    <div class="search-block">
      <input
        class="search"
        v-model="searchString"
        type="text"
        :placeholder="placeholder"
        :raw_block="raw_block"
        @keyup.enter="doSearch"
      />
      <div class="loupe" :class="{ clear: isSearch, preloading: iconsListLoading }" @click="onLoupeClick">
        <div class="rmpreloader hidden">
          <div class="arc"></div>
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="common-wrapper">
      <rm-scroll
        ref="rmscroll"
        :on-panel-scroll="onPanelScroll"
        :scroll-gap-start="8"
        :scroll-gap-end="8"
        :saved-scroll-position="savedScrollPosition"
      >
        <div
          v-for="icon in icons || []"
          class="icon-item"
          :class="{ preloading: iconLoading == icon, selected: iconSelected == icon }"
          @click="onIconItemClick(icon)"
        >
          <div
            class="noun-icon"
            :style="{ 'background-image': `url('${icon.preview_url_42}')` }"
            :data-id="icon.id"
            :data-rm_id="icon.rm_id"
            :data-url="icon.icon_url"
          ></div>
          <div v-if="iconLoading == icon" class="rmpreloader">
            <div class="arc"></div>
          </div>
        </div>
      </rm-scroll>
    </div>
  </div>
</template>

<script>
import _ from '@rm/underscore';
import Vue from 'vue';
import iconsutils from '../../js/common/iconsutils';
import RmScroll from '../common/rm-scroll.vue';

export default {
  components: { RmScroll },
  data: function() {
    return {
      icons: iconsutils.prevSVGSearch.icons || iconsutils.defaultIcons,
      searchString: iconsutils.prevSVGSearch.query,
      currentSearchString: iconsutils.prevSVGSearch.query,
      isSearch: !!iconsutils.prevSVGSearch.query,
      iconsListLoading: false,
      iconLoading: null,
      iconSelected: false,
      savedScrollPosition: iconsutils.prevSVGSearch.savedScrollPosition || 0,
    };
  },

  mounted: function() {
    this.iconSelected = iconsutils.findIcon(_.pick(this.block, 'noun_id', 'rm_id'));
    this._scroll = this.$refs['rmscroll'];
  },

  watch: {
    'block.tp': {
      handler: function() {
        Vue.nextTick(
          function() {
            this.$emit('resize');
          }.bind(this)
        );
      },
      immediate: true,
    },
  },
  props: {
    raw_block: Object,
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  methods: {
    onLoupeClick: function(e) {
      if (this.isSearch) {
        this.clearSearch();
      } else {
        this.doSearch();
      }
    },

    doSearch: function() {
      if (!this.searchString || this.currentSearchString == this.searchString || iconsutils.searchInProgress) return;
      this.savedScrollPosition = 0;
      this.currentSearchString = this.searchString;
      this.icons = [];
      delete window.prevSVGSearch;
      this.isSearch = true;
      this.fetchIcons();
    },

    clearSearch: function() {
      this.savedScrollPosition = 0;
      this.isSearch = false;
      this.searchString = '';
      this.currentSearchString = '';
      this.icons = iconsutils.defaultIcons;
      iconsutils.clearSearch();
    },

    onPanelScroll: function(scroll_data) {
      iconsutils.prevSVGSearch.savedScrollPosition = scroll_data.scroll_pos;
      this.savedScrollPosition = scroll_data.scroll_pos;

      // если до конца прокрутки осталось менее 32 пикселей и есть еще что грузить
      if (
        scroll_data.content_size - scroll_data.container_size - scroll_data.scroll_pos < 32 &&
        iconsutils.prevSVGSearch.nextPage &&
        !iconsutils.searchInProgress
      ) {
        this.fetchIcons();
      }
    },

    fetchIcons: async function() {
      this.iconsListLoading = true;
      this.icons = await iconsutils.fetchIconsPage(this.searchString);
      this.iconsListLoading = false;
      this._scroll.recalcScroll();
    },

    onIconItemClick: function(icon) {
      if (this.iconLoading) {
        return;
      }

      this.$emit('dataObtained', icon);

      this.iconLoading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.panel {
  .selector {
    margin-top: 39px;
  }
}
</style>
