/**
 * Виджет Фон
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import VideoUtils from '../../common/videoutils';
import WidgetClass from '../widget';
import templates from '../../../templates/viewer/widgets/background.tpl';
import BackgroundSlideshowWidget from '../../common/background-slideshow';
import VideoWidget from './video';

const backgroundWidget = WidgetClass.extend({
  // достаточно версии прототипа
  // initialize: function(data, page) {},

  render: function() {
    this.rendered = true;

    this.page.on('resize', this.onResize, this);

    this.template = templates['template-viewer-widget-background'];

    // создаем вьюху стандартым методом бекбона:
    // устанавливаем el, $el и делегацию событий из списка events
    this.setElement(this.template({ data: this }));

    // обязательно prependTo, чтобы ДО фонового фиджета с low-quality контентом
    // нельзя использовать зиндексы для фона, поскольку в вертикальном вьювере content-bounds сдвигается с помощью трансформа, а он "спекает" всю ноду в один слой и без зиндекса и у нас фон становится выше всех обычных виджетов (с фикседами такого не происходит потому что если на странице есть фикседы то мы сдвигаем с помощью топа а не трансформа)
    this.$el.prependTo(this.page.$fixedBgContainer);

    this.$picture = this.$('.picture-background');
    this.$video = this.$('.video-background');
    this.$videoContainer = this.$('.video-container');
    this.$slideshow = this.$('.slideshow-container'); // Контейнер для слайдшоу
    this.$slideshowPreload = this.$('.slideshow-preload'); // Контейнер для предзагрузки изображений

    if (this.picture && this.selectedType === 'picture') {
      this.renderPicture();
    } else if (!_.isEmpty(this.video) && this.selectedType === 'video') {
      this.renderVideo();
    } else if (this.slideshow && this.selectedType === 'slideshow') {
      this.renderSlideshow();
    } else {
      this.widgetIsLoaded();
    }

    return this;
  },

  // достаточно версии прототипа
  // widgetIsLoaded: function() {},

  // достаточно версии прототипа
  // isValid: function() {},

  start: function() {
    this.started = true;

    if (this.selectedType === 'video' && !RM.screenshot && this.rendered && this.videoWidget && !this.destroyed) {
      this.videoWidget.start();
      this.onResize();
    }

    if (
      this.selectedType === 'slideshow' &&
      !RM.screenshot &&
      this.rendered &&
      !this.destroyed &&
      this.slideshowWidget &&
      !this.slideshowWidget.isAnimationRunning
    ) {
      this.slideshowWidget.startAnimationLoop();
    }

    return this;
  },

  stop: function() {
    this.started = false;

    if (this.selectedType === 'video' && !RM.screenshot && this.rendered && this.videoWidget && !this.destroyed) {
      this.videoWidget.stop();
    }

    if (
      this.selectedType === 'slideshow' &&
      !RM.screenshot &&
      this.rendered &&
      !this.destroyed &&
      this.slideshowWidget &&
      this.slideshowWidget.isAnimationRunning
    ) {
      this.slideshowWidget.stopAnimationLoop();
    }

    return this;
  },

  // Вызывается при закрытии страницы
  destroy: function() {
    this.page.off('resize', this.onResize, this);

    if (this.selectedType === 'video') {
      this.videoWidget && this.videoWidget.destroy();
      this.videoWidget = null;
    }

    if (this.selectedType === 'slideshow') {
      this.slideshowWidget && this.slideshowWidget.destroy();
      this.slideshowWidget = null;
    }

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },

  // опрашивается в page.js для того чтобы знать что у нас еть видео фон
  // начало проигрывания которого также надо дождаться чтобы перестать крутить прелоадер
  // обязательно надо проверять что не в скриншот режиме, потому что для скриншотера мы не делаем видео фонов, также как и для мобильных девайсов
  hasVideoBG: function() {
    return !!(!RM.screenshot && this.selectedType === 'video' && this.video && this.video.url);
  },

  onResize: function() {
    if (this.destroyed || !this.$videoContainer || !this.video) {
      return;
    }

    VideoUtils.setVideoPosition({
      mag: this.page.mag,
      $container: this.$videoContainer,
      $media: this.$videoContainer.children(), // может быть и постер и ифрейм и оба сразу
      provider: this.video.provider_name,
      aspect_poster: this.video.aspect_poster || (this.videoWidget && this.videoWidget.aspect_poster), // для старых видосов aspect_poster нету, поэтому его надо взять из  videoWidget, там он появляется при загрузке постера видео
      aspect_real: this.video.aspect_real,
      controls_remove: true,
    });
  },

  renderPicture: function() {
    if (!this.picture) {
      this.widgetIsLoaded();
      return;
    }

    var url = this.picture.finalUrl || this.picture.effectUrl || this.picture.url,
      $imgLoader = $('<img/>');

    $imgLoader
      .on(
        'load',
        _.bind(function() {
          this.$picture.css({
            'background-image': 'url(' + url + ')',
          });

          if (RM.screenshot) setTimeout(this.widgetIsLoaded, 200);
          else this.widgetIsLoaded();
        }, this)
      )
      .on('error', this.widgetIsLoaded)
      .attr('src', url);
  },

  renderSlideshow: function() {
    this.slideshowWidget = new BackgroundSlideshowWidget({
      data: this.slideshow,
      container: this.$slideshow,
      preloadContainer: this.$slideshowPreload,
      name: this.page.cid,
    });

    if (RM.screenshot) {
      this.slideshowWidget.render();
      this.slideshowWidget.on(
        'loaded',
        function() {
          this.widgetIsLoaded();
        }.bind(this)
      );
    } else {
      // Если находимся не на текущей страницы, то не запускаем анимацию
      this.slideshowWidget.render(!this.page.isCurrent());
      this.widgetIsLoaded();
    }
  },

  renderVideo: function() {
    this.video.id = this.id;
    this.video.mute = this.mute;
    this.video.loop = this.loop;
    this.video.controls = false;

    this.videoWidget = new VideoWidget(this.video, this.page, this.$videoContainer);

    this.videoWidget.on(
      'loaded',
      _.bind(function() {
        this.widgetIsLoaded();
        this.onResize();
      }, this)
    );

    this.videoWidget.on(
      'playStarted',
      _.bind(function() {
        this.trigger('videoBGStarted');
      }, this)
    );

    this.videoWidget.render();
  },
});

export default backgroundWidget;
