module.exports = {["template-common-audio-player-standard"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<iframe class="common-audio-player standard" width="100%" height="100%" scrolling="no" frameborder="no" src="'+
((__t=(src))==null?'':_.escape(__t))+
'"></iframe>\n';
}
return __p;
},["template-common-audio-player-minimal"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="common-audio-player minimal loading">\n\n		<div class="sc-artwork-list">\n			<div class="sc-loaded-artwork"></div>\n			<div class="sc-artworks-overlay"></div>\n		</div>\n\n		<div class="sc-info">\n			<h3></h3>\n			<h4></h4>\n		</div>\n\n		<div class="sc-scrubber no-rmswipe">\n			<div class="sc-hover-indicator"></div>\n			<div class="sc-time-span">\n				<div class="sc-buffer"></div>\n				<div class="sc-played"></div>\n				<div class="sc-button-wrapper">\n					<div class="sc-button">\n					<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="12" height="12"><path d="M0 0 L12 6 L0 12  Z " style="fill:#1d1a1b; stroke:none; stroke-width:0"></path></svg>\n					</div>\n				</div>\n			</div>\n			<div class="sc-time-indicators">\n				<span class="sc-left"></span>\n			</div>\n		</div>\n\n	</div>\n';
}
return __p;
},["template-common-audio-player-engine-html5"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="common-audio-player-engine">\n		<audio preload="auto"></audio>\n	</div>\n';
}
return __p;
},["template-common-audio-player-engine-flash"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="common-audio-player-engine">\n		<object height="100%" width="100%" id="'+
((__t=(id))==null?'':_.escape(__t))+
'">\n			<embed allowscriptaccess="always" height="100%" width="100%" src="'+
((__t=(swf))==null?'':__t)+
'" type="application/x-shockwave-flash" name="'+
((__t=(id))==null?'':_.escape(__t))+
'" />\n	    </object>\n	</div>\n';
}
return __p;
},["template-common-audio-player-engine-flash-ie"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n	<div class="common-audio-player-engine">\n		<object height="100%" width="100%" id="'+
((__t=(id))==null?'':_.escape(__t))+
'" classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" data="'+
((__t=(swf))==null?'':_.escape(__t))+
'">\n			<param name="movie" value="'+
((__t=(swf))==null?'':_.escape(__t))+
'" />\n			<param name="allowscriptaccess" value="always" />\n		</object>\n	</div>\n';
}
return __p;
},}