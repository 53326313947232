<template>
  <div class="spinner">
    <transition name="fade">
      <template v-for="(v, ind) in variants">
        <div v-if="v.value == value" class="spin-title">{{ v.title }}</div>
      </template>
    </transition>

    <div class="spin left" @click.stop="spinType(-1)"></div>
    <div class="spin right" @click.stop="spinType(+1)"></div>
  </div>
</template>

<script>
import $ from '@rm/jquery';
import _ from '@rm/underscore';

export default {
  model: {
    event: 'change',
  },

  props: {
    value: '',
    variants: {
      type: Array,
      default: [],
    },
  },

  methods: {
    spinType: function(d) {
      var self = this,
        cInd = 0;
      _.each(this.variants, function(v, ind) {
        if (v.value === self.value) {
          cInd = ind;
        }
      });
      cInd += d;
      cInd = cInd < 0 ? cInd + this.variants.length : cInd % this.variants.length;

      // TODO:: придумать по другому
      // смысл в том что нужно задавать направление карусельки
      $(this.$el).toggleClass('negative', d < 0);

      this.$emit('change', this.variants[cInd].value);
    },
  },
};
</script>
<style lang="less">
@import (reference) '../../css/common/fonts.less';

.spinner {
  position: relative;
  margin: 14px 0 11px -5px;
  height: 19px;
  letter-spacing: -0.1px;

  .spin-title {
    .avenir_demi;
    font-size: 12px;
    color: #5d5d5d;
    text-align: center;
    padding-top: 4px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    cursor: default;
  }

  .spin {
    position: absolute;
    width: 12px;
    height: 20px;
    top: 0;
    cursor: pointer;
    &.left {
      left: 2px;
      background-image: url('../../img/constructor/controls/audio_settings/spin-l.png');
      &:hover {
        background-image: url('../../img/constructor/controls/audio_settings/spin-l-hover.png');
      }
    }

    &.right {
      right: 1px;
      background-image: url('../../img/constructor/controls/audio_settings/spin-r.png');
      &:hover {
        background-image: url('../../img/constructor/controls/audio_settings/spin-r-hover.png');
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: 0.3s ease;
  }
  .fade-enter {
    transform: translateX(15px);
    opacity: 0;
  }
  .fade-enter-to {
    transform: translateX(0px);
    opacity: 1;
  }
  .fade-leave-to /* .fade-leave-active in <2.1.8 */
		 {
    opacity: 0;
    transform: translateX(-15px);
  }

  &.negative {
    .fade-enter {
      transform: translateX(-15px);
    }

    .fade-leave-to /* .fade-leave-active in <2.1.8 */
			 {
      transform: translateX(15px);
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  .spinner {
    .spin {
      background-size: 12px 20px;
      &.left {
        background-image: url('../../img/constructor/controls/audio_settings/spin-l@2x.png');
        &:hover {
          background-image: url('../../img/constructor/controls/audio_settings/spin-l-hover@2x.png');
        }
      }

      &.right {
        background-image: url('../../img/constructor/controls/audio_settings/spin-r@2x.png');
        &:hover {
          background-image: url('../../img/constructor/controls/audio_settings/spin-r-hover@2x.png');
        }
      }
    }
  }
}
</style>
