/**
 * Плагин для реализации свайп жестов утягивания-вытягивания элементов
 * Используется в телефонной и планшетной версиях
 * Используется в меню Вьювера, которое можно вытянуть наверх за кнопку и утянуть обратно вниз,
 * свайпинге Страниц Мэга и свайпинге Картинок Слайдшоу.
 * Внимание! Использует RM.utils, Modernizr, jQuery, и наверное еще что-то
 */
import _ from '@rm/underscore';

(function($, undefined) {
  var utils = {
    // для заданного элемента возвращает значения 6 переменных матрицы трансформаии,
    // используется еще в плагине /libs/jquery.rmswipe.js
    getCurrentTranslate: function(obj) {
      var cs = window.getComputedStyle(obj, null);

      var tr = cs.getPropertyValue('-webkit-transform') || cs.getPropertyValue('transform');

      if (!tr || tr == 'none') return [0, 0];

      // getComputedStyle всегда возвращает матрицу трансформации,
      // где учтены уже повороты, скейлы, сдвиги и skew
      var res = tr.split('(')[1],
        res = res.split(')')[0],
        res = res.split(',');

      return [res[4] - 0, res[5] - 0]; // приводим к числу из строки
    },

    // грамотный код ожидания transition end
    // учитывает:
    // -что событий окончания несколько с вендорными префиксами, навешивает только на один
    // -что собыие баблится и может придти от чайлда, чего нам не надо
    // -что событие может не выстрелить, тогда вызовет по таймеру безопасности
    // -что для мультипл транзишенов нам надо отловить окончание тодько нашего транзишена, а не всех подряд свойств
    waitForTransitionEnd: function($obj, safeTimeout, property, cb) {
      $obj = $obj instanceof $ ? $obj : $($obj);

      // это код который рекомендует использовать сам модернизр
      var transEndEventNames = {
          WebkitTransition: 'webkitTransitionEnd',
          MozTransition: 'transitionend',
          OTransition: 'oTransitionEnd',
          msTransition: 'MSTransitionEnd',
          transition: 'transitionend',
        },
        transEndEventName = transEndEventNames[Modernizr.prefixed('transition')],
        transitionEndCallback = function(e) {
          // проверяем что событие пришло непосредственно от того, на кого навешивали
          // например в случае тулбара был ньюанс, сто сначала срабатывал
          // transition end от самой кнопки меню изнутри тулбара, на которой был транзишен на ховер
          // transition end срабатываем в конце КАЖДОГО анимируемого свойства, а также для чайлдов элемента
          // если это мультипл событие то нам надо сомтреть какое именно свойство закончило анимироваться
          // и ждать только того которое нам нужно
          if (e) {
            var prop = e.originalEvent.propertyName.toLowerCase();
            if (!$(e.target).is($obj)) return;
            if (prop.indexOf(property) == -1) return;
          }

          // сбрасываем обработчики transition end их много может идти еще
          $obj.off(transEndEventName, transitionEndCallback);

          // сбрасываем таймер безопасности
          clearTimeout(transitionSafeTimeout);

          cb();
        },
        resetAllHandlers = function() {
          // сбрасываем обработчики transition end
          $obj.off(transEndEventName, transitionEndCallback);

          // сбрасываем таймер безопасности
          clearTimeout(transitionSafeTimeout);
        };

      // ожидаем событие окончания анимации, его надо навесить обязательно до applyTransform && applyTransition
      // навешивать на все события сразу (как делают многие в этих ваших энторнетах) не стоит
      // например на iOS выстреливают сразу два события webkitTransitionEnd и transitionend
      // а .on вызовется для обоих, и так хватает проблем с тем, что Transition End вызывается отдельно для каждого анимируемого свойства
      $obj.on(transEndEventName, transitionEndCallback);

      // таймер безопасности, на случай если transitionend вообще не выстрелит (есть условия при которых это происходит)
      var transitionSafeTimeout = setTimeout(transitionEndCallback, safeTimeout);

      return resetAllHandlers;
    },
  };

  $.fn.RMSwipe = function(options) {
    var Swipe = function(options, $elems) {
      this.options = _.clone(options);

      this.$swipes = $elems;

      _.defaults(this.options, {
        actionTreshold: 25, //сколько надо пройти пальцем чтобы считать что был жест вытягивания/утягивания
        rubberBand: true, //флаг что нужна пружинка
        rubberBandForce: 1 / 1.55, //чем больше значение дроби, тем жесче "пружина", 1/1 нет пружины, 1/<1 обратная "пружина"
        tolerance: 5, //сколько надо сдвинуть пальцем в нужном направлении, чтобы понять что мы хотим свайпить
        disableOnScaled: true, //запрещаем что либо обрабатывать если экран отскейлен
        autoAnimate: true, //флаг что если мы прошли пальцем достаточное расстояние для перехода в новое состояния, мы сами плавно доанимируем его до этого состояния, а потому уже вызовем колбек
        immediateCallback: false, //флаг что если мы прошли пальцем достаточное расстояние для перехода в новое состояния, то мы сразу вызываем колбек, независимо от того будем ли мы сами доанимировать элемент до нового состояния или нет
        maxTransitionSpeed: 400,
        minTransitionSpeed: 100,
        stopOnItems: ['input', 'textarea', '.no-rmswipe'], //jQuery селекторы элементов над которыми плагин не должен начинать свайпить
      });
    };

    Swipe.prototype = {
      initialize: function() {
        _.bindAll(this);

        this.$swipes.on('touchstart', this.onTouchStart);
        this.$swipes.on('touchmove', this.onTouchMove);
        this.$swipes.on('touchend touchcancel', this.onTouchEnd);
      },

      destroy: function() {
        if (this.$swipes) {
          this.$swipes.off('touchstart', this.onTouchStart);
          this.$swipes.off('touchmove', this.onTouchMove);
          this.$swipes.off('touchend touchcancel', this.onTouchEnd);
          this.$swipes.data('swipe', null);
        }

        return this.$swipes;
      },

      onTouchStart: function(e) {
        e = e.originalEvent;
        var touches = e.touches,
          options = this.options,
          self = this,
          isScaled = window.innerWidth != document.documentElement.clientWidth;

        // не начинаем детектить жест, если палец над запрещенным элементом.
        // например, у картинок Слайдшоу, которые свайпятся, один из родителей
        // имеет .rm-noswipe — это помогает предотвратить свайп Страниц Мэга, когда мы свайпим Слайдшоу.
        if (options.stopOnItems && options.stopOnItems.length) {
          var $closest = $(e.target).closest(options.stopOnItems.join(', '));
          if ($closest.length && this.$swipes.find($closest).length) return;
        }

        //если уже идет анимации доводки до нового состояния  тогда выходим
        if (this.animating) {
          return;
        }

        //если уже отловили одно нажатие пальца и следим за ним
        //все последующие пальцы игнорируем
        if (this.data) return;

        //если на экране сразу больше одного пальца тогда ничего не делаем,
        //следить ни за чем не будем, пока юзер сам не отпустит все пальцы, и не начнет с одного пальца
        if (touches.length != 1) return;

        //если есть нативный скейл окна тогда смотрим должны мы выйти или нет
        if (((e.scale && e.scale !== 1) || isScaled) && options.disableOnScaled) return;

        this.data = {};
        //получаем данные о том, в каких направлениях можно двигать объект
        //его граничные положения, и на сколько надо сдвинуть чтобы он сместился с одного граниченого положения на другое
        this.data.constraints = options.getCurrentConstraints($(e.target));

        this.data.id = touches[0].identifier; //сохраняем идентификатор касания, чтобы потом реагировать только на него
        this.data.clientX = touches[0].clientX;
        this.data.clientY = touches[0].clientY;
        this.data.lastTime = +new Date();
        this.data.shift = 0;
        this.data.velocity = 0;

        //если передан список элементов которые должны смещаться при движении пальзем по this.$swipes
        //тогда двигаем только элементы из этого списка, иначе дефолтное поведение - кого пальцем тянем, того и двигаем
        this.data.$moveItems = this.data.constraints.$moveItems || this.$swipes;

        this.data.$moveItems = this.data.$moveItems.filter(function() {
          return this.nodeType != 3;
        }); //убираем текстовые ноды если таковые попались всреди элементов

        //элемент по которому мы будем определять окончание транзишенов
        //а также по котору будем смотреть есть сейчас анимация (не наша) или нет
        //например в панельке шера можно двигать за черный оверлей, но вот смотреть по нему транзишены
        //или определять анимируется он сейчас или нет нельзя, потому что сдвиг применяется только к самой панельке
        //шера, ее и надо указывать в референсе
        //или для сдвига страниц, там в референсе нельзя указывать центральную страницу, ибо у нее транзишен короче
        //+ если она последняя то она не двигается при показе файлна пейджа, т.е. те же проблемы что и с шером
        this.data.$referenceItem = this.data.constraints.$referenceItem || this.data.$moveItems.last();

        //фикс непонятного бага, видел на андроиде, что this.data.$referenceItem пустой, как и почему не понял
        if (!this.data.$referenceItem.length) {
          this.data.$referenceItem = this.data.$moveItems.last();
          console.log(
            'No reference object found, use substitution: ',
            this.data.constraints,
            $(e.target),
            this.data.$moveItems,
            this.$swipes
          );
        }

        //заранее в начале тача получаем даные о стилях и положении элементов
        //для тача это незначительная задержка, зато потом если это все-таки жест, будет гораздо оперативнее реагировать на первый сдвиг пальцем
        this.data.translate = [];
        this.data.oldStyle = [];
        this.data.$moveItems.each(function() {
          var $obj = $(this);
          self.data.translate.push(utils.getCurrentTranslate(this));
          self.data.oldStyle.push($obj.attr('style'));
        });

        this.data.isTargetScrollable = !!(
          options.$scrollElements && $(e.target).closest(options.$scrollElements).length
        );

        //сохраняем текущее положение блока за который собираемся тянуть
        //если к моменту когда мы определим что происходит нужный нам жест мы увидим
        //что блок сместился, дл нас это плохой сигнал
        //лучше жест не применять, сокрее всего происходит какая либо анимация через транзишены и мы только ве испортим
        var rect = this.data.$referenceItem[0].getBoundingClientRect();
        this.data.targetX = rect.left;
        this.data.targetY = rect.top;
      },

      onTouchMove: function(e) {
        e = e.originalEvent;

        var touches = e.touches,
          touch = touches[0],
          data = this.data,
          options = this.options,
          tolerance = options.tolerance,
          constraints = data && data.constraints,
          self = this,
          shift,
          dirs,
          dir;

        //если уже идет анимации доводки до нового состояния  тогда выходим
        if (this.animating) {
          return;
        }

        //если onTouchStart мы инициализировали отслеживание за текущим жестом в onTouchStart
        //тогда выходим, например если изначально прикоснулись двумя пальцами, а потом один убрали
        if (!data) return;

        //если на экране сразу больше одного пальца тогда ничего не делаем
        if (touches.length != 1) return;

        //если есть нативный скейл окна тогда смотрим должны мы выйти или нет
        if (e.scale && e.scale !== 1 && options.disableOnScaled) return;

        //если текущее касание не то, с которого начинали
        //тогда выходим, например если коснулись одним пальцем, потом другим, потом первый отпустили
        if (touch.identifier != data.id) return;

        //если мы еще не поняли жест это или нет, или если поняли но он был в запрещенную сторону (тогда проверим еще раз в вдруг в нужную сторону уже сдвинли сколько надо)
        //отдельно рассматриваем ситуацию если жест был сделан на достаточное расстояние в запрещенном направлении и при этом мы на "разрешенном" для скрола объекте
        //типа лента скриншоов в меню, в таком случае считаем, что там уже начался скрол и наш жест уже точно не стоит запускать
        if (!this.started || (data.noActionConstraints && !data.isTargetScrollable)) {
          //сначала смотрим в какие стороны палец на сколько ушел
          dirs = {
            up: data.clientY - touch.clientY,
            down: touch.clientY - data.clientY,
            left: data.clientX - touch.clientX,
            right: touch.clientX - data.clientX,
          };

          var max = 0,
            dir = '';

          //теперь смотрим в какую сторону он шел больше всего
          if (dirs['up'] > max) {
            dir = 'up';
            max = dirs['up'];
          }
          if (dirs['down'] > max) {
            dir = 'down';
            max = dirs['down'];
          }
          if (dirs['left'] > max) {
            dir = 'left';
            max = dirs['left'];
          }
          if (dirs['right'] > max) {
            dir = 'right';
            max = dirs['right'];
          }

          //если уже стартовали, но находимся здесь значит изначально стартовали в запрещенном направлении
          //а теперь просто постоянно проверяем нет ли сдвига в разрешенном направлении чтобы включить реакцию на жест
          //т.е. мы тут если выполнилась часть ...data.noActionConstraints && (!options.$sc...
          //в данном случае нам не надо меряться сдвигами, а достаточно чтоюы был сдвиг больше tolerance в разрешенную сторону
          var enoughAllowedDirectionMovement = false;

          if (this.started) {
            enoughAllowedDirectionMovement =
              (constraints['up'] && dirs['up'] > tolerance) ||
              (constraints['down'] && dirs['down'] > tolerance) ||
              (constraints['left'] && dirs['left'] > tolerance) ||
              (constraints['right'] && dirs['right'] > tolerance);
          }

          //если максимальный сдвиг достаточен для начала жеста, тогда смотрим куда был сдвиг: в разрешенном направлении или нет
          if (max >= tolerance) {
            //помечаем для себя что мы опознали жест и будем теперь следить за ним
            //а вот будем ли мы на него реагировать, это решим дальше
            //не реагируем мы если изначально повели пальцем в запрещенную сторону,
            //или если элемент под пальцем уже сейчас анимируется, но не нами
            this.started = true;

            if (constraints[dir] || enoughAllowedDirectionMovement) {
              //проверяем текущее положение блока за который собираемся тянуть
              //если к моменту когда мы определим что происходит нужный нам жест мы увидим
              //что блок сместился, для нас это плохой сигнал
              //будем все отрабатывать, но объекты за пальцем таскать не будем, и не будем
              //важно обрабатывать жест но не реагировать на него чтобы в нужное время вызывался preventDefault, поскольку иначе у нас начнет работать глобальный скрол и пр.
              //лучше жест не применять, скорее всего происходит какая либо анимация через транзишены и мы только все испортим
              var rect = data.$referenceItem[0].getBoundingClientRect();
              if (data.targetX != rect.left || data.targetY != rect.top) {
                data.noActionMovement = true; //ставим пометку почему мы просто слежим за жестом но не реагируем
              }

              //определем ось по которой мы будем двигать объект
              var x = constraints['left'] || constraints['right'] ? Math.abs(data.clientX - touch.clientX) : 0,
                y = constraints['up'] || constraints['down'] ? Math.abs(data.clientY - touch.clientY) : 0;

              data.axis = x > y ? 'x' : 'y';
              data.translateInd = data.axis == 'x' ? 0 : 1; //индекс в массиве транслейтов который нужно менять чтобы осуществлять сдвиг по нужной оси

              if (!data.noActionMovement) {
                //перебираем все объекты (например для меню мэга их там 3 штуки которые должны двигаться и реагировать синхронно)
                //и сохраняем их текущую скалькулированную матрицу трансформации, а также инлайн стиль целиком

                //начинаем отсчитывать от текущего положения, чтобы не было рывка при вклюении реакции на жест,так то
                data.clientX = touch.clientX;
                data.clientY = touch.clientY;

                data.$moveItems.addClass('swiping');
              }
              data.noActionConstraints = false; //снимаем пометку если она стояла ранее

              options.onSwipeStart && options.onSwipeStart();
            } else {
              //в данном направлении запрещены жесты
              data.noActionConstraints = true; //ставим пометку почему мы просто следим за жестом но не реагируем
            }
          }
        }

        //если начали обслуживать жест (даже если start только что установили чуть выше)
        //то блокируем всякие дефолтные скролы
        if (this.started) {
          //если мы свайпим поверх элемента с разрешенным скролом,
          //тогда preventDefault не надо
          if (data.isTargetScrollable) {
            //блокируем любое дефолтное поведение браузера на разрешенном блоке скрола (в том числе и его внутренний скрол)
            //если основной элемент свайпа сейчас в процессе анимации (не нашей)
            //или если мы совершаем сдвиг в разрешщенном направлении
            if (!data.noActionConstraints || data.noActionMovement) e.preventDefault();
          } else {
            e.preventDefault(); //раз мы вошли в режим жеста, то блокируем любое дефолтное поведение браузера включая скролл
          }
        }

        //если жест начат и нет флага что мы за ним просто наблюдаем но никак не реагируем
        if (this.started && !data.noActionConstraints && !data.noActionMovement) {
          if (data.axis == 'x') {
            shift = touch.clientX - data.clientX;
            if (shift < 0) {
              //left
              dir = 'left';
              shift = -normalizeShift(-shift, constraints['left']);
            } else if (shift > 0) {
              //right
              dir = 'right';
              shift = normalizeShift(shift, constraints['right']);
            }
          } else {
            shift = touch.clientY - data.clientY;
            if (shift < 0) {
              //up
              dir = 'up';
              shift = -normalizeShift(-shift, constraints['up']);
            } else if (shift > 0) {
              //down
              dir = 'down';
              shift = normalizeShift(shift, constraints['down']);
            }
          }

          data.$moveItems.each(function(ind, elem) {
            var translate = data.translate[ind],
              old = translate[data.translateInd];

            //здесь кастомная проверка на то, надо ли сдвигать сейчас данный конкретный элемент или не стоит
            //если не стоит то его надо вернуть в исходное положение
            if (!options.customMoveCheck || options.customMoveCheck($(this), shift)) {
              translate[data.translateInd] += shift;
            }

            self.applyTransform(this, data.oldStyle[ind], translate, 0);

            translate[data.translateInd] = old;
          });

          var oldShift = data.shift,
            //скорость текущего сдвига
            shiftVelocity = Math.abs(oldShift - shift) / (1 + +new Date() - data.lastTime);

          data.shift = shift; //это нужно для touchend
          data.curDir = dir; //это нужно для touchend

          //амортизируем текущую сорость пальца предыдущими замерами
          data.velocity = 0.8 * shiftVelocity + 0.2 * data.velocity;
        }

        data.lastTime = +new Date();

        function normalizeShift(shift, constraint) {
          if (!constraint) return 0; //если в данном направлении вобще нельзя двигать тогда аннулируем сдвиг

          if (shift > constraint.max) {
            //если сдвиг больше максимума, тогда применяем пружинку, если она разрешена
            return (
              constraint.max + (options.rubberBand ? Math.pow(shift - constraint.max, options.rubberBandForce) : 0)
            );
          } else {
            return shift;
          }
        }
      },

      onTouchEnd: function(e) {
        e = e.originalEvent;

        var touches = e.touches,
          data = this.data,
          options = this.options,
          constraints = data && data.constraints,
          self = this;

        //если уже идет анимации доводки до нового состояния  тогда выходим
        if (this.animating) {
          return;
        }

        //если на экране еще остались пальцы то выходим, подожем когда все отпустят
        //или же проверяем что тип события touchcancel
        if (touches.length && e.type != 'touchcancel') return;

        //если мы даже не отслеживали жест это или нет, тогда можно смело игнорировать это событие
        if (!data) return;

        //если мы отслеживали жест это иил нет, но так и не поняли этого, тогда выходим и сбрасываем данные о начале слежения
        if (!this.started) {
          this.data = null;
          return;
        }

        //закомментил, фастклик и так умеет супресить клик после драга
        //e.stopPropagation(); //чтобы не отрабатывал какой-либо клик по элементу за который тащили

        if (!data.noActionConstraints && !data.noActionMovement) {
          //проверяем можно ли тянуть в ту сторону в которую тянули
          //был ли сдвиг достаточным для срабатывания перехода в противоположное состояние
          //и можно ли при сдвиге в данном направлении производить смену состояния
          if (
            constraints[data.curDir] &&
            !constraints[data.curDir].noTrigger &&
            Math.abs(data.shift) >= options.actionTreshold
          ) {
            //запрашиваем дополнительные параметры авто анимации и времени вызова колбека непосредственно после отпускания пальца
            //если есть соответсвующий колбек, бывают моменты когда важно понять это именно в самый последний момент
            var dopParams = options.beforeAutoAnimation ? options.beforeAutoAnimation(data.curDir, data.shift) : null,
              autoAnimate = dopParams ? dopParams.autoAnimate : options.autoAnimate,
              immediateCallback = dopParams ? dopParams.immediateCallback : options.immediateCallback;

            if (autoAnimate) {
              //запускаем доводчик до нового состояния
              this.animating = true;
              var //рассчитываем сколько времени надо анимировать чтобы при текущей скорости пальца доанимировать до крайнего положения
                calculatedDuration = Math.abs(Math.abs(data.shift) - constraints[data.curDir].max) / data.velocity,
                maxTransitionSpeed = options.getMaxTransitionSpeed
                  ? options.getMaxTransitionSpeed()
                  : options.maxTransitionSpeed,
                //ограничиваем расчитанное время, чтобы не больше изначально выделенного на весь транзишен (по дизайну), и не меньше 100, чтобы не было мгновенного рывка при резком жесте пальцем
                duration = Math.max(Math.min(maxTransitionSpeed, calculatedDuration), options.minTransitionSpeed),
                isWaiting = false;

              //анимируем все объекты до их положения в новом состоянии
              data.$moveItems.each(function(ind, elem) {
                //здесь кастомная проверка на то, надо ли сдвигать сейчас данный конкретный элемент или не стоит
                if (!options.customMoveCheck || options.customMoveCheck($(this), data.shift)) {
                  var translate = data.translate[ind];

                  if (data.curDir == 'up' || data.curDir == 'left')
                    translate[data.translateInd] -= constraints[data.curDir].max;
                  else translate[data.translateInd] += constraints[data.curDir].max;

                  if (!isWaiting) {
                    //навешивать надо до установки трансформа
                    utils.waitForTransitionEnd($(this), duration + 1000, 'transform', function() {
                      self.animating = false;
                      //на страх и риск колбек вызываем ДО возврата инлайн стиля
                      //иначе проблемы с транзишенами:
                      //если ставить поле, то возможна ситуация когда в колбеке идет какое либо обращение
                      //к свойствам дум элементов которые могут принудительно заставить браузер сделать рефлоу (например считать положение объекта)
                      //и тогда если на элементе есть транзишены, то браузер их применит и элемент начнет возвращаться в исходное состояние
                      //даже несмотря на то, что в колбеке по идее будет проставляться класс, который должен установить лемент в конечное состояние в которое его автоматически довел плагин
                      if (!immediateCallback) options.callback(data.curDir);

                      //возвращаем обратно как было, пустая строка обязательно с пробелом, иначе функция не сбросит стиль, а просто вернет его значение
                      data.$moveItems.each(function(ind, elem) {
                        this.style.cssText = data.oldStyle[ind] || ' ';
                      });

                      data.$moveItems.removeClass('swiping');
                    });

                    isWaiting = true;
                  }

                  self.applyTransform(this, data.oldStyle[ind], translate, duration);
                } else {
                  //возвращаем обратно как было, пустая строка обязательно с пробелом, иначе функция не сбросит стиль, а просто вернет его значение
                  this.style.cssText = data.oldStyle[ind] || ' ';
                }
              });

              if (immediateCallback) options.callback(data.curDir);
            } else {
              //возвращаем обратно как было, пустая строка обязательно с пробелом, иначе функция не сбросит стиль, а просто вернет его значение
              data.$moveItems.each(function(ind, elem) {
                this.style.cssText = data.oldStyle[ind] || ' ';
              });

              options.callback(data.curDir);

              data.$moveItems.removeClass('swiping');
            }
          } else {
            data.$moveItems.removeClass('swiping');

            //возвращаем обратно как было, пустая строка обязательно с пробелом, иначе функция не сбросит стиль, а просто вернет его значение
            data.$moveItems.each(function(ind, elem) {
              this.style.cssText = data.oldStyle[ind] || ' ';
            });
          }
        }

        this.data = null;
        this.started = null;
      },

      //трансформ и транзишен надо за один раз назначать, чтобы не было двух пересчетов стилей
      //да и делать это надо нативно, причем не считыва заново obj.style.cssText
      //а использовать исходное сохраненное значение и к нему добавлять трансформы и транзишены
      applyTransform: function(obj, oldStyle, translate, duration) {
        //очень важно чтобы в матрице translate не было чисел в экспонентном виде,
        //поскольку мы имеем дело с числами с плавающей точкой,
        //то 0 вполне может быть представлен (и как выяснилось представляется в реальности) например в виде -0.00000000000000024492935982947064
        //а потом эта белиберда в процессе работы трансформируется во что-то типа -2.4492935982947064e-16 (например если сделать массиву с такими числами .join(','))
        //а такая запись если использовать ее для матрицы трансформации в css уже не является валидной, шах и мат
        //вот потому мы и отрезаем незначащие разряды (но это не дает гарантии что )
        var x = Math.round(translate[0] * 100000) / 100000,
          y = Math.round(translate[1] * 100000) / 100000,
          transform = 'translateX(' + x + 'px) translateY(' + y + 'px) translateZ(0)',
          transition = 'transform ' + duration + 'ms ease',
          transformStr = '',
          transitionStr = '';

        transformStr += '-webkit-transform: ' + transform + ';';
        transformStr += '-ms-transform: ' + transform + ';';
        transformStr += 'transform: ' + transform + ';';

        transitionStr += '-webkit-transition: -webkit-' + transition + ';';
        transitionStr += 'transition: ' + transition + ';';

        obj.style.cssText = oldStyle + ';' + transformStr + transitionStr;
      },
    };

    var $elems = $(this);

    var swipe = new Swipe(options, $elems);

    swipe.initialize();

    $elems.data('swipe', swipe);

    return this;
  };
})(jQuery);
