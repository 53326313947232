/**
 * Конструктор для контрола поворота виджета
 */
import VueControlClass from '../vue-control';

const ButtonSettings = VueControlClass.extend({
  name: 'button_settings',
  alt: 'Settings',
  panelComponent: 'panel-button-settings',
});

export default ButtonSettings;
