/**
 * Контрола иконок для виджета Button
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import ControlResizableClass from '../control-resizable';
import templates from '../../../templates/constructor/controls/button_icon.tpl';

const ButtonIcon = ControlResizableClass.extend({
  name: 'button_icon', // должно совпадать с классом вьюхи.

  className: 'control button_icon',

  MIN_PANEL_HEIGHT: 274,

  MAX_PANEL_HEIGHT: 640,

  // чтобы не прижимался вплотную
  // к разделительной линии между поиском и иконками.
  SCROLL_GAP_START: 4,
  SCROLL_GAP_END: 4,

  API_URL: '/api/authservice/noun?uri=',

  LIMIT_TO_PUBLIC_DOMAIN: false, // true для тестирования на бесплатном аккаунте
  DEFAULT_COLLECTION_ID: 1335, // Noun ID коллекции, которую нужно показывать по-умолчанию

  saveOnDestroy: true,
  saveOnDeselect: true,

  initialize: function(params) {
    this.template = templates['template-constructor-control-button_icon'];
    this.templateItem = templates['template-constructor-control-button_icon-item'];

    params = params || {};

    this.initControl(params);

    this.block = this.blocks[0];
    this.model = this.block.model;

    this.applyIconSizeParamVisualState.__debounced = _.debounce(this.applyIconSizeParamVisualState, 20);

    this.bindModelEvents();
  },

  render: function() {
    ControlResizableClass.prototype.render.apply(this, arguments);

    this.$result = this.$('.panel .resizable-content');

    // если есть кэшированные результаты поиска, восстанавливаем их.
    if (window.prevSVGSearch) {
      this.nextPage = window.prevSVGSearch.nextPage;
      this.$('.search').val(window.prevSVGSearch.phrase);
      this.search_string = window.prevSVGSearch.phrase;
      this.drawSearchResults(window.prevSVGSearch);
      this.$('.loupe').addClass('clear');
    } else {
      this.getDefaultIconsCollection();
    }
  },

  bindLogic: function() {
    this.$el.on('click', '.icon-item', this.onIconItemClick);
    this.$('.search').on('keyup', this.onSearchKeyUp);
    this.$('.loupe:not(.preloading)').on('click', this.onLoupeClick);

    // кэшируем поиск часто используемых элементов.
    this.$iconPositionParam = this.$panel.find('.edit-item[data-field="icon_pos"]');
    this.$iconPositions = this.$iconPositionParam.find('.position');
    this.$disableIcon = this.$panel.find('.disable-icon');
    this.$lastIcon = this.$panel.find('.last-icon');

    this.$disableIcon.on('click', this.onDisableIconClick);

    // Size. инициализация плагина для инпута.
    this.iconSizeEditParamInput = $(this.$panel.find('.edit-item[data-field="icon_h"] input'))
      .RMNumericInput({
        min: this.block.MIN_FONT_SIZE,
        max: this.block.MAX_FONT_SIZE,
        mouseSpeed: 4,
        onChange: this.onEditInputChanged,
      })
      .data('changeValue');

    // Position.
    this.$iconPositionParam.find('.position').on('click', this.onPositionClick);

    // изначально применяем визуальное состояние Панели.
    this.applyIconEnabledVisualState();
    this.applyIconSizeParamVisualState();
    this.applyIconPositionParamVisualState();
  },

  unBindLogic: function() {
    this.$el.off('click', '.icon-item', this.onIconItemClick);
    this.$('.search').off('keyup', this.onSearchKeyUp);
    this.$('.loupe:not(.preloading)').off('click', this.onLoupeClick);
    this.$iconPositions.off('click', this.onPositionClick);
    this.$disableIcon.off('click', this.onDisableIconClick);

    // удаляем всех слушателей изменений модели,
    // созданные этой вьюхой.
    this.model && this.model.off(null, null, this);
  },

  // вся перерисовка состояния редактируемых параметров
  // в панели происходит исключительно
  // по событияем изменения модели Кнопки, чтобы корректно отрабатывал Undo/Redo, который
  // пачкой откатывает все model.set, сделанные к моменту последнего model.save.
  bindModelEvents: function() {
    this.model.on('change:icon_h', this.applyIconSizeParamVisualState.__debounced, this);

    this.model.on('change:icon_pos', this.applyIconPositionParamVisualState, this);

    this.model.on('change:icon_enabled', this.applyIconEnabledVisualState);
  },

  onEditInputChanged: function($input, new_num) {
    this.model.set($input.closest('.edit-item').attr('data-field'), new_num);
  },

  onPositionClick: function(e) {
    var position = $(e.currentTarget).data('position');

    this.model.set('icon_pos', position);
  },

  applyIconEnabledVisualState: function() {
    var icon_enabled = this.model.get('icon_enabled');

    this.$disableIcon.add(this.$lastIcon).removeClass('active');

    if (icon_enabled) {
      this.$lastIcon.addClass('active');
    } else {
      this.$disableIcon.addClass('active');
    }
  },

  // перерисовает состояние параметра Size,
  // отталкиваясь от значения в модели.
  applyIconSizeParamVisualState: function() {
    // Numerics
    // Size
    this.iconSizeEditParamInput(this.model.get('icon_h'), true);
  },

  // перерисовает состояние параметра Position,
  // отталкиваясь от значения в модели.
  applyIconPositionParamVisualState: function() {
    this.$iconPositionParam.find('.current').removeClass('current');

    this.$iconPositionParam.find('.' + this.model.get('icon_pos')).addClass('current');
  },

  onSearchKeyUp: function(e) {
    if (e.keyCode !== $.keycodes.enter) {
      return;
    }

    this.doSearch();
  },

  onLoupeClick: function(e) {
    if (this.$('.loupe').hasClass('clear')) {
      this.clearSearch();
    } else {
      this.doSearch();
    }
  },

  doSearch: function() {
    var string = this.$('.search')
      .val()
      .trim();
    if (!string || this.search_string == string || this.requestInProgress) {
      return;
    }

    this.search_string = string;
    this.$result.find('.icon-item').remove();
    delete window.prevSVGSearch;
    this.$('.loupe').addClass('clear');
    // this.__scrollRecalc_debounced();

    this.nextPage = 1;

    this.getDataPage(this.nextPage);
  },

  clearSearch: function() {
    this.$('.loupe').removeClass('clear');
    delete window.prevSVGSearch;
    this.$('.search').val('');
    this.search_string = '';
    this.$result.find('.icon-item').remove();
    this.getDefaultIconsCollection();
  },

  getDataPage: function(page) {
    var uri;

    if (!this.search_string || this.requestInProgress) {
      return;
    }

    uri = '/icons/' + this.search_string + '?page=' + page;
    if (this.LIMIT_TO_PUBLIC_DOMAIN) {
      uri += '&limit_to_public_domain=1';
    }

    this.requestIcons(
      uri,
      function(err, data) {
        if (err) {
          delete this.nextPage; // Удаляем номер следующей страницы. Нечего больше грузить
          return;
        }

        // Кэшируем результаты предыдущего поиска,
        // включая иконки, последнюю пэйджинированную страницу и строку поиска
        // FIXME: переделать на кэш внутри компонента, не надо патчить window
        window.prevSVGSearch = window.prevSVGSearch || { icons: [] };
        window.prevSVGSearch.icons = window.prevSVGSearch.icons.concat(data.icons);
        window.prevSVGSearch.phrase = this.search_string;
        window.prevSVGSearch.nextPage = this.nextPage;

        this.drawSearchResults(data);
      }.bind(this)
    );
  },

  requestIcons: function(uri, callback) {
    this.searchXhr && this.searchXhr.abort();
    this.searchInProgress = true;
    this.showSearchPreloader();

    this.searchXhr = $.ajax({
      method: 'GET',
      url: this.API_URL + encodeURIComponent(uri),
      dataType: 'json',
      success: function(data) {
        callback(null, data);
      },
      error: function(xhr) {
        callback(xhr);
      },
      complete: function() {
        this.searchInProgress = false;
        this.hideSearchPreloader();
      }.bind(this),
      context: this,
    });
  },

  getDefaultIconsCollection: function() {
    this.drawSearchResults(this.defaultIconsCollection);
  },

  drawSearchResults: function(data) {
    if (!(_.isArray(data.icons) && data.icons.length)) {
      delete this.nextPage; // Удаляем номер следующей страницы. Нечего больше грузить
      return;
    }

    _.each(
      data.icons,
      function(icon) {
        this.$result.append(this.templateItem({ icon: icon }));
      }.bind(this)
    );

    this.__scrollRecalc_debounced();
  },

  // расширяем метод, который срабатывает при открытии панели Контрола.
  select: function() {
    ControlResizableClass.prototype.select.apply(this, arguments);

    _.delay(
      function() {
        this.$('.search')
          .focus()
          .select();
      }.bind(this),
      400
    );
  },

  save: function() {
    this.block.saveWidgetData();

    ControlResizableClass.prototype.save.apply(this, arguments);
  },

  onIconItemClick: function(e) {
    var $item = $(e.currentTarget),
      $icon = $item.find('.noun-icon'),
      noun_id = $icon.attr('data-id'), // урл иконки Noun.
      rm_id = $icon.attr('data-rm_id'), // урл нашей иконки.
      icon_url = $icon.attr('data-url');

    if (this.$('.resizable-content').hasClass('preloading')) {
      return;
    }

    this.hideIconPreloader();
    this.showIconPreloader($item);

    this.block.getIconSVG(
      noun_id || rm_id,
      icon_url,
      function(err, data) {
        var modelSetObject,
          icon_enabled = this.model.get('icon_enabled');

        this.hideIconPreloader();

        if (err) {
          return;
        }

        this.$('.icon-item.selected').removeClass('selected');
        $item.addClass('selected');

        modelSetObject = {
          icon_noun_url: icon_url,
        };

        // при задании одного типа айдишника надо обязательно сбрасывать другой,
        // т.к. наличие или отсутствие соответствующего
        // айдишника и является признаком принадлежности иконки - Noun или наша.
        if (noun_id) {
          _.extend(modelSetObject, { icon_noun_id: noun_id, icon_rm_id: null });
        } else if (rm_id) {
          _.extend(modelSetObject, { icon_noun_id: null, icon_rm_id: rm_id });
        }

        // если у Кнопки до этого не стояло Иконки,
        // ставим флаг, что теперь Кнопка с Иконкой.
        // это возовет логику пеопределения типа Кнопки в button.js.
        if (!icon_enabled) {
          _.extend(modelSetObject, { icon_enabled: true });
        }

        // изменение аттрибута icon в модели вызовет
        // перасчет ширины контейнера Иконки Кнопки, что
        // может вызвать пересчет ширины Кнопки,
        // а потом новая Иконка отрендерится в Кнопку.
        this.block.model.set(modelSetObject);

        // обновляем картинку в контейнере последней иконки.
        this.$lastIcon.find('.noun-icon').css({
          'background-image': 'url("' + data.noun_url + '")',
        });
      }.bind(this)
    );
  },

  onDisableIconClick: function() {
    var icon_enabled = this.model.get('icon_enabled');

    // снимаем выделение с последней иконки.
    this.$('.icon-item.selected').removeClass('selected');

    // это вызовет логику перопределения типа Кнопки в button.js.
    if (icon_enabled) {
      this.model.set('icon_enabled', false);

      // стираем SVG, чтобы потом, если Иконку вновь
      // включат, то старая не мелькнула перед новой.
      this.block.$buttonIcon.empty();

      // пересчитает минимальный размер Кнопки.
      this.block.recalcButtonSize();
    }
  },

  onPanelScroll: function(scroll_data) {
    if (!this.selected) {
      return;
    }

    // если до конца прокрутки осталось менее 32 пикселей и есть еще что грузить
    if (
      scroll_data.content_size - scroll_data.container_size - scroll_data.scroll_pos < 32 &&
      this.nextPage &&
      !this.searchInProgress
    ) {
      this.getDataPage(++this.nextPage);
    }
  },

  abortRequests: function() {
    this.searchXhr && this.searchXhr.abort();
    this.searchInProgress = false;
  },

  showSearchPreloader: function() {
    this.$('.loupe').addClass('preloading');
  },

  hideSearchPreloader: function() {
    this.$('.loupe').removeClass('preloading');
  },

  showIconPreloader: function($icon) {
    $icon.append($('<div class="rmpreloader"><div class="arc"></div></div>'));

    this.$('.resizable-content')
      .add($icon)
      .addClass('preloading');
  },

  hideIconPreloader: function($icon) {
    this.$('.resizable-content')
      .find('.rmpreloader')
      .remove();
    this.$('.resizable-content')
      .add(this.$('.icon-item'))
      .removeClass('preloading');
  },

  destroy: function() {
    this.abortRequests();

    ControlResizableClass.prototype.destroy.apply(this, arguments);
  },
});

ButtonIcon.prototype.defaultIconsCollection = {
  icons: [
    {
      rm_id: 'rm0',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/00-star.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/00-star.svg',
    },
    {
      rm_id: 'rm1',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/01-bolt.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/01-bolt.svg',
    },
    {
      rm_id: 'rm2',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/02-bubble.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/02-bubble.svg',
    },
    {
      rm_id: 'rm3',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/03-camera.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/03-camera.svg',
    },
    {
      rm_id: 'rm4',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/04-cloud.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/04-cloud.svg',
    },
    {
      rm_id: 'rm5',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/05-download1.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/05-download1.svg',
    },
    {
      rm_id: 'rm6',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/06-download2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/06-download2.svg',
    },
    {
      rm_id: 'rm7',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/07-globe2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/07-globe2.svg',
    },
    {
      rm_id: 'rm8',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/08-globe.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/08-globe.svg',
    },
    {
      rm_id: 'rm9',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/09-pin.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/09-pin.svg',
    },
    {
      rm_id: 'rm10',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/10-share1.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/10-share1.svg',
    },
    {
      rm_id: 'rm11',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/11-share2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/11-share2.svg',
    },
    {
      rm_id: 'rm12',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/12-send.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/12-send.svg',
    },
    {
      rm_id: 'rm13',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/13-checkmark.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/13-checkmark.svg',
    },
    {
      rm_id: 'rm14',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/14-x.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/14-x.svg',
    },
    {
      rm_id: 'rm15',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/15-minus.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/15-minus.svg',
    },
    {
      rm_id: 'rm16',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/16-plus.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/16-plus.svg',
    },
    {
      rm_id: 'rm17',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/17-mail1.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/17-mail1.svg',
    },
    {
      rm_id: 'rm18',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/18-mail2.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/18-mail2.svg',
    },
    {
      rm_id: 'rm19',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/19-user.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/19-user.svg',
    },
    {
      rm_id: 'rm20',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/20-clock.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/20-clock.svg',
    },
    {
      rm_id: 'rm21',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/21-embed.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/21-embed.svg',
    },
    {
      rm_id: 'rm22',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/22-flag.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/22-flag.svg',
    },
    {
      rm_id: 'rm23',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/23-heart.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/23-heart.svg',
    },
    {
      rm_id: 'rm24',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/24-itunes.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/24-itunes.svg',
    },
    {
      rm_id: 'rm25',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/25-like.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/25-like.svg',
    },
    {
      rm_id: 'rm26',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/26-music.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/26-music.svg',
    },
    {
      rm_id: 'rm27',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/27-play.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/27-play.svg',
    },
    {
      rm_id: 'rm28',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/28-video.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/28-video.svg',
    },
    {
      rm_id: 'rm29',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/29-facebook.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/29-facebook.svg',
    },
    {
      rm_id: 'rm30',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/30-twitter.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/30-twitter.svg',
    },
    {
      rm_id: 'rm31',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/31-linkedin.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/31-linkedin.svg',
    },
    {
      rm_id: 'rm32',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/32-pinterest.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/32-pinterest.svg',
    },
    {
      rm_id: 'rm33',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/33-instagram.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/33-instagram.svg',
    },
    {
      rm_id: 'rm34',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/34-googleplus.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/34-googleplus.svg',
    },
    {
      rm_id: 'rm35',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/35-tumblr.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/35-tumblr.svg',
    },
    {
      rm_id: 'rm36',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/36-youtube.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/36-youtube.svg',
    },
    {
      rm_id: 'rm37',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/37-vimeo.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/37-vimeo.svg',
    },
    {
      rm_id: 'rm38',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/38-flickr.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/38-flickr.svg',
    },
    {
      rm_id: 'rm39',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/39-blogger.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/39-blogger.svg',
    },
    {
      rm_id: 'rm40',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/40-dribbble.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/40-dribbble.svg',
    },
    {
      rm_id: 'rm41',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/41-behance.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/41-behance.svg',
    },
    {
      rm_id: 'rm42',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/42-rss.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/42-rss.svg',
    },
    {
      rm_id: 'rm43',
      icon_url: '/img/constructor/blocks/button/default-icons-collection/43-at.svg',
      preview_url_42: '/img/constructor/blocks/button/default-icons-collection/43-at.svg',
    },
  ],
};

export default ButtonIcon;
