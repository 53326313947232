/**
 * Плагин ограничивает ввод в input только чисел
 * опционально добавляет возможность менять число с помощью мыши или клавиш вверх-вниз
 *
 *
 * Для работы необходим RMDrag, RM.utils, jQuery, underscore
 * Можна натравливать на несколько инпутов сразу
 *
 *
 * Параметры:
 *   min 				- минимальное число. Default: -infinite
 *   max 				- максимальное число. Default: +infinite
 *   step 				- на сколько приращивать число при использовании клавиатуры или мыши (можно бробное если useFloat включен)
 *   mouseUse 			- использовать ли мышь для изменения числа. Default: true
 *   mouseSpeed 		- на сколько пикселей смещения мыши будет приходиться +-step в инпуте. Default: 3
 *   mouseDir 			- направление в котором мышь должна двигаться: 'v' или 'h'. Default: 'v'
 *   keyboardUse 		- использвать ли стрелки вверх-вниз клавиатуры чтобы изменить +-step в инпуте. Default: true
 *   stopPropagation 	- пробрасывать ли дальше события клавиатры. Default: true
 *   setCursors 		- должен ли плагин сам устанавливать курсоры стрелочки для инпута. Default: true
 *   labelSelector 		- если параметр задан, то изменять значения инпута можно мышкой не только по самому инпуту, но и по доп. элементу (например иконке радом с инпутом). Default: undefined
 *   onChange 			- вызывается при каждом изменении числа в инпуте. Default: function() {}
 *   onBeforeChange		- вызывается перед тем, как вызвать onChange, используется как кастомный фильтр значений, получает на входе текущее и предудущее значение, должен вернуть профильтрованное значение. Default: function($input, curr, prev) {return curr}
 *   invertMouse		- задает в какую сторону будет меняться значение при работе с мышкой. Default: false
 *   invertKeyboard		- задает в какую сторону будет меняться значение при работе стрелки вверх-вниз клавиатуры. Default: false
 *   shiftStep			- если задано число, то при нажатой кнопке Shift значение будет изменяться на это число
 *   useFloat			- разрешены ли дробные числа
 *   floatDigits		- до скольки знаков округлять дробное число
 *   autoSize			- автоматически подстраивать размер инпута под контент (по ширине)
 *
 * Дополнение по параметру labelSelector: в этот параметр надо записывать css селектор элемента, который мы хотим
 * использовать для прокрутки мышкой значений инпута. Причем селектор должне работать не на глобальном уровне,
 * а на уровне родителя инпута!

 * Например:
 *   <div class="sample">
 *		<div class="icon"></div>
 *		<input type="text" />
 *	</div>
 *
 * если мы применили плагин к инпуту и хотим чтобы и по иконке можно было крутить значения мышкой
 * то указываем labelSelector = '.icon'
 *
 *
 *
 * Когда применяем плагин к группе инпутов, убодно именть особые параметры для некоторых из этих инпутов.
 * Это реализуется кастомными data- аттрибутами в HTML теге инпута
 * например data-mouseDir="v" или data-max="99"
 *
 *
 * Внимание! При изменении значения инпута напрямую через JS, событие onChange в плагине не генерируется!
 *
 */
import _ from '@rm/underscore';

(function($, undefined) {
  /**
   * NOTE: utils из common/utils.js
   */
  var utils = {
    // Устанавливает размер инпута точно по размеру введенного текста.
    // используется как минимум в constructor/pages-panel-contents.js
    // и в constructor/blocks/button.js.
    setInputSize: function($input, maxwidth, val) {
      var $tmp = $(
        '<div style="position:absolute; left:-9999px; right:auto; margin:0; white-space:pre; width:auto"></div>'
      ).appendTo($input.parent());
      $tmp[0].className = $input[0].className;
      $tmp.text(val || $input.val());
      var w = $tmp.width();

      // Поскольку поле ввода позиционируется по центру, нам нужна четная ширина
      // иначе сам блок при нечетной располагался на нецелых границах и все рендерилось некрасиво, в частности курсор.
      // Если устанавливать ширину меньше 1, происходит полная херня, инпут теряет фокус, а нам этого совсем не надо
      // это уличная магия
      w = Math.ceil(w / 2) * 2 + 2;

      if (maxwidth && w > maxwidth) {
        w = maxwidth;
      }

      $input.width(w);
      $tmp.remove();
    },
  };

  $.fn.RMNumericInput = function(options) {
    /**
     * Список возможных параметров плагина
     */
    var defaults = $.extend(
      {
        min: Number.NEGATIVE_INFINITY,
        max: Number.POSITIVE_INFINITY,
        mouseUse: true,
        mouseSpeed: 3,
        mouseDir: 'v',
        keyboardUse: true,
        stopPropagation: true,
        setCursors: true,
        labelSelector: undefined,
        invertMouse: false,
        invertKeyboard: false,
        onChange: function() {},
        onBeforeChange: function($input, curr, prev) {
          return curr;
        },
        step: 1,
        shiftStep: 10,
        useFloat: false,
        floatDigits: 1,
        autoSize: false,
        autofocus: false,
        mouseOnly: false,
        thousandDelimiter: '',
        debounceKeyboard: 0,
      },
      options
    );

    // Выбираем только инпуты, на всякий случай
    this.filter('input').each(function() {
      var $input = $(this),
        settings = _.clone(defaults),
        curValue,
        oldDelta,
        lastValue = $input.val(),
        $label,
        leaveTimeout;

      // применяем кастомные data- аттрибуты для индивидуальной настройки инпута
      _.each(settings, function(attr, key) {
        var val = $input.attr('data-' + key);
        if (val != undefined) {
          settings[key] = val;

          // поскольку мы получаем строки ($input.attr('data-' + key)), надо их привести к нужному типу
          if (_.contains(['min', 'max', 'step', 'mouseSpeed', 'shiftStep', 'floatDigits', 'debounceKeyboard'], key))
            settings[key] = parseFloat(settings[key]);

          if (
            _.contains(
              [
                'mouseUse',
                'keyboardUse',
                'stopPropagation',
                'setCursors',
                'invertMouse',
                'invertKeyboard',
                'useFloat',
                'autoSize',
                'autofocus',
              ],
              key
            )
          )
            settings[key] = settings[key] == 'true';
        }
      });

      settings.onChange.__debounced = _.debounce(settings.onChange, settings.debounceKeyboard);

      // если нужно менять значения мышкой
      if (settings.mouseUse) {
        // плагин сам ставит нужный курсор
        if (settings.setCursors) {
          if (settings.mouseDir == 'v') $input.css({ cursor: 'ns-resize' });
          if (settings.mouseDir == 'h') $input.css({ cursor: 'ew-resize' });
        }

        // применяем RMDrag для работы с мышью
        $input.RMDrag({
          start: startMouse,
          move: moveMouse,
          end: stopMouse,
          silent: true,
          preventDefault: true,
        });

        if (settings.labelSelector) {
          // если первый символ < тогда просят взять closest
          if (settings.labelSelector.substr(0, 1) == '<') {
            $label = $input.closest(settings.labelSelector.substr(1));
          } else {
            $label = $input.parent().find(settings.labelSelector);
          }

          // если мы нашли этот лейбл рядом с инпутом
          if ($label.length == 1) {
            // плагин сам ставит нужный курсор
            if (settings.setCursors) {
              if (settings.mouseDir == 'v') $label.css({ cursor: 'ns-resize' });
              if (settings.mouseDir == 'h') $label.css({ cursor: 'ew-resize' });
            }

            // применяем RMDrag для работы с мышью
            $label.RMDrag({
              start: startMouse,
              move: moveMouse,
              end: stopMouse,
              silent: true,
              preventDefault: true,
            });
          }
        }
      }

      $input
        .add($label || $())
        .on('mouseenter', onMouseEnter)
        .on('mouseleave', onMouseLeave);

      // обработчик любого изменения инпута
      $input.on('input propertychange', onInputChanged);

      $input.on('keydown keypressed keyup', onKey);

      $input.data('changeValue', changeValue);
      $input.data('formatValue', formatValue);
      $input.data('getValue', getValue);
      $input.data('changeMinMax', changeMinMax);

      if (settings.autofocus) {
        $input
          .addClass('transparent-selection')
          .focus()
          .select()
          .one('focus', function() {
            $(this).removeClass('transparent-selection');
          }); // При начально селекте делаем цвет селекшена прозрачным. При следующем - нормальным
      } else {
        $input.on('focus', onFocus);
      }

      $input.on('blur', onBlur);

      function changeMinMax(min, max) {
        if (min != undefined) settings.min = parseFloat(min);

        if (max != undefined) settings.max = parseFloat(max);
      }

      function changeValue(val, noOnChangeExec) {
        if (settings.useFloat) {
          val = stripFloat(val);
        } else {
          val = Math.round(val);
        }

        formatValue(val); // вводим значение
        formatValue(getValueFromInput().num); // затем фильтруем
        onInputChanged(undefined, noOnChangeExec, 'change-callback');
      }

      function getValue() {
        return getValueFromInput().num;
      }

      function onMouseEnter() {
        clearTimeout(leaveTimeout);
        $input.addClass('hovered');
        $label && $label.addClass('hovered');
      }

      function onMouseLeave() {
        leaveTimeout = setTimeout(function() {
          $input.removeClass('hovered');
          $label && $label.removeClass('hovered');
        }, 100);
      }

      function onFocus() {
        $input.addClass('focused');
        $label && $label.addClass('focused');
      }

      function onBlur(event, parameters) {
        $input.removeClass('focused');
        $label && $label.removeClass('focused');

        formatValue(getValueFromInput().num);
        onInputChanged(
          undefined,
          undefined,
          parameters && parameters.changeSource ? parameters.changeSource : 'filter-on-exit'
        );
      }

      function getValueFromInput(noMinMaxCheck) {
        var num = $.trim($input.val() + ''),
          res = {
            num: 0,
            onlyMinus: false,
            pointAtEnd: false,
          };

        // если все стерли не оставляем поле пустым
        if (num == '') {
          if (!noMinMaxCheck) res.num = Math.max(settings.min, 0);
          return res;
        }

        if (settings.useFloat) {
          num = num.replace(/\,/gim, '.');
        }

        if (settings.min >= 0) {
          // удаляем все что не является цифрой (или точкой)
          if (settings.useFloat) {
            num = num.replace(/([^\d\.]+)/gim, '');
          } else {
            num = num.replace(/([^\d]+)/gim, '');
          }
        } else {
          // если ввели минус когда поле с нулем (спец костыль для того чтобы можно было просто поставить минус)
          if (num == '-0' || num == '0-' || num == '-') {
            $input.val('-');
            res.onlyMinus = true;
          }

          // удаляем все что не является цифрой  или минусом (или точкой)
          if (settings.useFloat) {
            num = num.replace(/([^\d\-\.]+)/gim, '');
          } else {
            num = num.replace(/([^\d\-]+)/gim, '');
          }

          // удаляем любой минус который идет не в начале числа
          num = num.replace(/([\S\s]+?)(\-)/gim, function(str, p1, p2, offset, s) {
            return p1;
          });

          // удаляем любую точку в числе кроме первой
          // и даже первую если она идет до числа
          if (settings.useFloat) {
            num = num.replace(/^\./gim, '');
            num = num.replace(/^(.*?\..*?)(\.)/gim, function(str, p1, p2, offset, s) {
              return p1;
            });
          }
        }

        // если сейчас в конце стоит точка то оставляем ее, ведь юзер вводит число по одному символу,
        // т.е. надо дать возмоность ввожить невалидное число и не фиксить его в инпуте
        if (settings.useFloat && /\.$/.test(num)) {
          res.pointAtEnd = true;
          res.num = num;
          return res;
        }

        // превращаем в число
        num = num - 0;

        if (isNaN(num)) num = Math.max(settings.min, 0);

        if (settings.useFloat) {
          num = stripFloat(num);
        }

        if (!noMinMaxCheck) {
          if (num < settings.min) num = settings.min;
          if (num > settings.max) num = settings.max;
        }

        res.num = num;

        return res;
      }

      function onInputChanged(e, noCallbackExec, changeSource) {
        var res = getValueFromInput();
        changeSource = changeSource || 'keyboard';

        // точная проверка на содержимое
        // введено что-то, что не походит под описание: число из диапазона min-max
        if (res.num + '' != $input.val() + '' && !res.onlyMinus && !res.pointAtEnd) {
          // правим значение в инпуте, но аккуратно
          // даем возможность ввести любое число
          // например 2, когда min=8, а max = 999, потому что пользователь хочет ввести 23, начинает-то он вводить с 2, а потом уже пишет 3
          var noRangeNum = getValueFromInput(true).num,
            length =
              (noRangeNum < 0 ? settings.min + '' : settings.max + '').length +
              (settings.useFloat ? 1 + settings.floatDigits : 0),
            rangedNum = (noRangeNum + '').substr(0, length);
          formatValue(rangedNum);
          if (noRangeNum < settings.min || noRangeNum > settings.max) {
            if (settings.autoSize) {
              utils.setInputSize($input, 200);
            }
            return; // т.е. onChange вызывать ненадо!
          }
        } else if (res.onlyMinus) {
          return;
        } else {
          formatValue(res.num); // Чтобы отформатировало, если надо
        }

        // $input.css('opacity') == 0 спец костыль для текстового виджета, контрола типографики
        // чтобы обновлялось значение при multiple
        var new_num = settings.onBeforeChange.call(null, $input, res.num, lastValue - 0, changeSource);
        if ((lastValue != new_num || $input.css('opacity') == 0) && !res.onlyMinus && !res.pointAtEnd) {
          if (settings.useFloat) {
            new_num = stripFloat(new_num);
          }

          if (Math.abs(new_num - res.num) > 0.000001) {
            formatValue(new_num);
          }

          if (!noCallbackExec) {
            if (settings.debounceKeyboard && changeSource !== 'arrows' && changeSource !== 'mouse') {
              settings.onChange.__debounced.call(null, $input, new_num);
            } else {
              settings.onChange.call(null, $input, new_num);
            }
          }
          lastValue = new_num;
        }

        if (settings.autoSize) {
          utils.setInputSize($input, 200);
        }
      }

      function onKey(e) {
        if (settings.stopPropagation) e.stopPropagation();

        // enter, up, down
        if (settings.keyboardUse && (e.keyCode == 13 || e.keyCode == 38 || e.keyCode == 40)) {
          e.preventDefault();
        }

        var num = getValueFromInput().num;

        if (e.type == 'keyup') {
          // enter
          if (settings.keyboardUse && e.keyCode == 13) {
            var value = getValueFromInput();
            // Если на конце значения точка, то попробуем ее убрать и получить число
            if (value.pointAtEnd) {
              var newNum = value.num.substring(0, value.num.length - 1);
              newNum = newNum - 0;
              if (isNaN(newNum)) newNum = Math.max(settings.min, 0);
              newNum = stripFloat(newNum);
              if (newNum < settings.min) newNum = settings.min;
              if (newNum > settings.max) newNum = settings.max;
              changeValue(newNum);
            }
            $input.blur();
            return;
          }
        }

        if (e.type == 'keydown') {
          // up or down
          if (settings.keyboardUse && (e.keyCode == 38 || e.keyCode == 40)) {
            var step = e.shiftKey ? settings.shiftStep : settings.step;
            num = num + (e.keyCode == 38 ? step : -step) * (settings.invertKeyboard ? -1 : 1);
            num = Math.max(Math.min(num, settings.max), settings.min);

            if (settings.useFloat) {
              num = stripFloat(num);
            }

            formatValue(num);

            onInputChanged(undefined, undefined, 'arrows');
          }
        }
      }

      function stripFloat(num) {
        var m = Math.pow(10, settings.floatDigits),
          nm = num * m;

        // 5.5999999 надо преобразовать в 6, потому что это явно 6 просто в таком виде из-за формата хранения чисел в JS (c плавающей точкой)
        // а вот 5.57 надо преобразовать в 5, потому что юзер вводил 5.5 а потом добавил 7, а поскольку мы обрезаем
        // кодво цифр после точки свойством floatDigits нам не надо округлять 5.57 до 5.6 нам надо просто обюрезать лишнюю 7
        if (Math.abs(nm - Math.round(nm)) < 0.00001) {
          return Math.round(nm) / m;
        } else {
          return Math.floor(nm) / m;
        }
      }

      function startMouse(e) {
        if (settings.setCursors) {
          $('body')
            .removeClass('ns-resize-cursor')
            .removeClass('ew-resize-cursor');
          if (settings.mouseDir == 'v') $('body').addClass('ns-resize-cursor');
          if (settings.mouseDir == 'h') $('body').addClass('ew-resize-cursor');
        }

        // После начала драга мышью, нужно триггерить blur с changeSource = mouse и передавать его дальше в onInputChanged,
        // чтобы там не вызвался метод onChange c дебаунсом в 500 мс.
        // Иначе через 500 мс после начала ресайза через плашку sizes (в ней используется jquery.rmnumericinput) драгом на цифровом поле,
        // изменения в поле сбрасываются. Визуально выглядит как скачок ширины / высоты блока.
        // https://readymag.monday.com/boards/55520426/pulses/55705459, пункт "Скачки ширины / высоты" в чеклисте.
        $input.trigger('blur', { changeSource: 'mouse' });
        oldDelta = 0;

        $input.addClass('dragging');

        $label && $label.addClass('dragging');
      }

      function moveMouse(e) {
        if ($input.get(0).readOnly && !settings.mouseOnly) {
          return;
        }
        var delta = 0;
        if (settings.mouseDir == 'h') delta = Math.round(e.deltaX / settings.mouseSpeed);
        if (settings.mouseDir == 'v') delta = -Math.round(e.deltaY / settings.mouseSpeed);

        var min = parseFloat($input.attr('data-min')),
          max = parseFloat($input.attr('data-max'));
        settings.min = isFinite(min) ? min : settings.min;
        settings.max = isFinite(max) ? max : settings.max;

        curValue = getValueFromInput().num;

        var num =
          curValue +
          (delta - oldDelta) * (settings.invertMouse ? -1 : 1) * (e.shiftKey ? settings.shiftStep : settings.step);
        num = Math.max(Math.min(num, settings.max), settings.min);

        if (settings.useFloat) {
          num = stripFloat(num);
        }

        formatValue(num);
        onInputChanged(undefined, undefined, 'mouse');
        oldDelta = delta;
      }

      function stopMouse(e) {
        $input.removeClass('dragging');

        $label && $label.removeClass('dragging');

        if (settings.setCursors) {
          $('body')
            .removeClass('ns-resize-cursor')
            .removeClass('ew-resize-cursor');
        }

        if (!e.moved) $input.focus().select();
      }

      function formatValue(val) {
        if (!settings.thousandDelimiter) {
          $input.val(val);
          return val;
        }

        var strVal = val.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1' + settings.thousandDelimiter);
        $input.val(strVal);
        return strVal;
      }

      // Лучшего места для функции дестроя не придумал
      $input.data('destroy', function() {
        $input.css('cursor', '');
        $input.removeClass('hovered');
        $input.removeClass('focused');
        $input.removeClass('dragging');
        $input.removeClass('transparent-selection');
        $('body')
          .removeClass('ns-resize-cursor')
          .removeClass('ew-resize-cursor');

        $input.off('input propertychange', onInputChanged);
        $input.off('keydown keypressed keyup', onKey);
        $input.off('focus', onFocus);
        $input.off('blur', onBlur);

        $input.data('changeValue', null);
        $input.data('changeMinMax', null);

        $input.removeData('destroy');
        return this;
      });
    });

    // maintain chainability
    return this;
  };
})(jQuery);
