<template>
  <div class="panel picture-alt">
    <div class="param-caption">
      <span>Semantics</span>
      <!-- TODO: need to set up a correct link to help article -->
      <a
        href="https://help.readymag.com/hc/en-us/articles/360019989052-Picture"
        rel="noreferrer noopener"
        target="_blank"
        class="question-icon"
        data-alt-style="controls-picture-alt-tooltip"
      >
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle cx="12" cy="12" r="12" />
            <path
              d="M8 9.259C8.06 7.371 9.294 6 11.542 6 13.615 6 15 7.259 15 9.02c0 1.219-.575 2.072-1.604 2.717-.992.614-1.272 1.02-1.272 1.809V14H10.37l-.008-.542c-.068-1.195.37-1.912 1.415-2.558.939-.597 1.256-1.02 1.256-1.808 0-.83-.635-1.427-1.589-1.427-.976 0-1.604.622-1.665 1.594H8zM11.5 18c-.904 0-1.5-.592-1.5-1.495 0-.913.596-1.505 1.5-1.505.922 0 1.5.592 1.5 1.505 0 .903-.578 1.495-1.5 1.495z"
            />
          </g>
        </svg>
      </a>
    </div>

    <div class="param" data-param="alt_text">
      <textarea v-model="m.alt" placeholder="Descriptive alt text" maxlength="100"></textarea>
    </div>
  </div>
</template>

<script>
import Panel from './panel.vue';

export default {
  mixins: [Panel],

  data: function() {
    return {
      m: {
        alt: '',
      },
    };
  },
};
</script>

<style lang="less" scoped>
@import (reference) '../../../css/common/constants.less';

.controls .control .picture-alt {
  height: 162px;
  top: -66px;
  box-sizing: border-box;
  width: 167px;
  right: 47px;
  padding: 10px 16px 4px 16px;
  border-radius: 9px;
  background-color: rgb(242, 242, 242);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0666);
  letter-spacing: -0.08px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: #282828;
  .transition(0.2s, opacity, ease-in-out);

  .param-caption {
    position: relative;
    font-weight: 500;
    text-align: left;
    padding-bottom: 15px;
    color: #282828;
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 20px;
    position: relative;
    left: 0;
    width: 100%;

    .question-icon {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 0;
      margin-top: -2px;
      z-index: 2;
      right: 0;

      circle {
        fill: transparent;
        .transition(0.2s, fill);
      }

      path {
        fill: #b8b8b8;
        .transition(0.2s, fill);
      }

      &:hover {
        circle {
          fill: #808080;
        }

        path {
          fill: #fff;
        }
      }
    }
  }

  .param {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 112px;

    textarea {
      position: relative;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
      letter-spacing: -0.08px;
      padding: 7px 15px 4px 8px;
      margin: 0;
      margin-left: -9px;
      overflow-y: hidden;
      overflow-x: hidden;
      outline: none;
      border: 1px solid rgba(184, 184, 184, 0.24);
      border-color: transparent;
      resize: none;
      height: 100%;
      width: ~'calc(100% + 18px)';
      background-color: transparent;
      color: #282828;
      line-height: 16px;
      word-break: break-word;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility !important;
      .border-box();
      .transition(0.2s);

      &:focus {
        background-color: white;
        border: 1px solid rgba(184, 184, 184, 0.24);
      }

      &::placeholder {
        color: #b8b8b8;
      }
    }
  }
}
</style>
