/**
 * Базовый класс блока для виджетов, работающих с картинками
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockClass from '../block';
import { Constants } from '../../common/utils';

const BlockCommonPictureClass = BlockClass.extend({
  ALLEFFECTS_URL: '/api/imageeffects/all',
  EFFECT_URL: '/api/imageeffect/',
  RESCALE_URL: '/api/imagescale',
  FINAL_URL: '/api/imagefinal',
  PREVIEW_URL: '/api/imagepreview',
  SEARCH_URL: '/api/upload/fromurl',

  SVG_URL: '/api/imagesvg',

  effectNames: ['pluto', 'jupiter', 'neptune', 'saturn', 'makemake', 'venus', 'noise', 'blur'],

  removePicture: function() {
    this.model.unset('picture');
    this.model.save({}, { silent: true, toHistory: true });
    this.effectsUrl = '';

    this._fromUrlXHR && this._fromUrlXHR.abort();
    this._getFinalRequest && this._getFinalRequest.abort();
  },

  loadEffects: function(callbacks) {
    if (this.effectsUrl) {
      callbacks && callbacks.success(this.effectNames, this.effectsUrl);
      return;
    }

    if (!this.model.get('picture') || !this.model.get('picture').thumbUrl)
      return callbacks && callbacks.error && callbacks.error();

    // Здесь должна быть предзагрузка картинки эффектов, но т.к. отдается не "статикой", то нужно самому контролировать Last-Modified и прочие заголовки
    // в итоге пока убрана предзагрузка и спрайт сразу подставляется в background-image

    var url = this.ALLEFFECTS_URL + '?url=' + encodeURIComponent(this.model.get('picture').thumbUrl);
    return callbacks && callbacks.success(this.effectNames, url);
  },

  createEffect: function(effect, callbacks) {
    var picture = this.model.get('picture');

    return $.ajax({
      type: 'GET',
      url: this.EFFECT_URL + effect,
      data: {
        url: picture.rasterUrl || picture.url,
        id: this.model.id,
        type: this.name,
        mid: this.workspace.mag.get('num_id'),
      },
      success: function(data) {
        var picture = _.extend(
          {},
          this.model.get('picture'),
          {
            effect: effect,
          },
          data
        );

        this.model.set({ picture: picture });

        callbacks && callbacks.success(data);

        return null;
      },
      error: callbacks && callbacks.error,
      context: this,
    });
  },

  removeEffect: function() {
    var picture = this.model.get('picture');
    if (!picture) return;

    this.model.set({ picture: _.omit(picture, 'effectUrl', 'effect', 'originalEffectUrl') });
  },

  getPictureUrl: function() {
    var picture = this.model.get('picture');

    if (!picture) return '';

    return (Modernizr.retina && picture.final2xUrl) || picture.finalUrl || picture.effectUrl || picture.url;
  },

  getVectorUrl: function() {
    var picture = this.model.get('picture');
    if (!picture) return '';

    return picture.editedVectorUrl || picture.url;
  },

  getOpacity: function() {
    var opacity = this.model.get('opacity');
    if (!_.isNumber(opacity)) return 1;
    return opacity;
  },

  getFinalImage: function(callback, params) {
    params = params || {};

    var picture = this.model.get('picture');

    if (!picture || !picture.url) {
      return callback && callback(null);
    }

    // Вложенная картинка работает всегда в дефолтном вьюпорте.
    var viewport = this.model.isNested ? 'default' : params.viewport || this.model.collection.page.getCurrentViewport();
    var w = this.model.get('w');

    this._getFinalRequest && this._getFinalRequest.abort();
    this._getFinalRequest = $.ajax({
      type: 'POST',
      data: this.model.attributes,
      url: this.FINAL_URL,
      success: function(data) {
        if (w != this.model.get('w')) return; // Сделали undo или redo или ручками вернули размер к исходной картинке

        // Если блок растянут, оверрайдим длину, иначе визуально пропадет растягивание
        if (this.isFullWidth()) {
          this.model.set('w', this.getFullWidthDims().w, { silent: true });
        }

        if (this.isFullHeight()) {
          this.model.set('h', this.getFullHeightDims().h, { silent: true });
        }

        this.model.save('picture', _.extend({}, picture, data), _.extend(params || {}, { viewport: viewport }));
        callback && callback(null, data);
      },
      error: function(xhr) {
        callback && callback(xhr.responseText);
      },
      context: this,
    });
  },

  requestFinalImage: function(data, callback) {
    $.ajax({
      type: 'POST',
      data: data,
      url: this.FINAL_URL,
      success: function(data) {
        callback && callback(data);
      },
    });
  },

  loadPreviewUrl: function(size, options) {
    var picture = this.model.get('picture');
    if (!picture) return;
    $.ajax({
      type: 'GET',
      data: {
        width: size.w,
        height: size.h,
        url: picture.rasterUrl || picture.url,
        mid: this.workspace.mag.get('num_id'),
      },
      url: this.PREVIEW_URL,
      success: _.bind(function(data) {
        picture.previewUrl = data && data.previewUrl;
        this.model.save({ picture: picture }, { silent: true, toHistory: true });
        options && options.success(data);
      }, this),
    });
  },

  loadFromUrl: function(url, options) {
    options = options || {};
    this._fromUrlXHR && this._fromUrlXHR.abort();
    this._fromUrlXHR = $.ajax({
      type: 'POST',
      data: {
        url: url,
        id: this.model.id,
        name: this.name,
        uploadType: 'picture',
        mid: this.workspace.mag.get('num_id'),
      },
      url: this.SEARCH_URL,
      context: this,
      success: function(data) {
        this.changePicture(data, options);

        options.success && options.success();
      },
      error: function(jqXHR, data) {
        options.error && options.error();

        if (data && data == 'abort') return;

        if (jqXHR && jqXHR.status == 413) {
          return alert(Constants.MSG_UPLOAD_IMAGE_SIZE_ERROR);
        }

        alert("There's something wrong with this picture. Please, try the different one.");
      },
    });

    return this._fromUrlXHR;
  },

  isVector: function(url) {
    url = url || (this.model.get('picture') && this.model.get('picture').url);
    return url && _.last(url.split('.')).indexOf('svg') == 0;
  },

  isAnimated: function() {
    return this.model.get('picture') && this.model.get('picture').type == 'gif';
  },

  // Грузим по урлу svg картинку и сохраняем ее данные во внутренних переменных для дальнейшей обработки
  loadVector: function(options) {
    options = options || {};
    this.svgAjax && this.svgAjax.abort();
    this.$svg && this.$svg.remove();

    delete this.$svg;
    delete this.$svgColoredElems;
    delete this.originalSvg;
    delete this.originalSize;

    if (!this.isVector()) return;

    var vectorUrl = this.getVectorUrl();

    this.svgAjax = $.get(
      vectorUrl + '?c',
      _.bind(function(svgData) {
        // Попытка избежать кеширования неверных настроек CORS

        this.$svg = $(svgData).find('svg');

        this.originalSize = {
          width: this.$svg.attr('width'),
          height: this.$svg.attr('height'),
        };
        this.$svg.removeAttr('width').removeAttr('height');
        var svg = $(svgData)
          .find('svg')
          .get(0)
          .cloneNode(true);

        var img = new Image();
        $(img).on(
          'load error',
          _.bind(function() {
            // В Chrome 50 сломался триггер load для SVG в которых пустые аттрибуты width & height (именно пустые а не отсутствующие)
            $(img)
              .appendTo(window.document.body)
              .css({ width: '100%', height: '100%' });
            var $svg = $(svg);

            $svg.get(0).setAttribute('viewBox', '0 0 ' + $(img).width() + ' ' + $(img).height());
            $svg.appendTo(window.document.body);
            var bbox = $svg.get(0).getBBox();

            this.$svg.get(0).setAttribute('viewBox', bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height);
            this.$svg.get(0).setAttribute('preserveAspectRatio', 'xMidYMid meet');

            $(img).remove();
            $svg.remove();
          }, this)
        );

        img.src = this.getVectorUrl();

        this.originalSvg = svgData;

        if (this.getVectorUrl() != vectorUrl) return;

        options.success && options.success(this.$svg);

        if (!this.model.get('picture').editedVectorUrl) this.pickVectorColor(options);
      }, this)
    )
      .always(
        _.bind(function() {
          delete this.svgAjax;
        }, this)
      )
      .fail(options.error || $.noop);
  },

  // Определяем основной цвет svg изображения, заодно помещает все элементы содержащие fill во внутренний массив
  pickVectorColor: function(options) {
    if (!this.$svg) return;

    var colors = [],
      counts = [],
      elems = [];
    var setCounts = function(color, elem) {
      var i = colors.indexOf(color);

      if (color && /^#\w+$/.test(color)) {
        if (i == -1) {
          colors.push(color);
          counts.push(1);
        } else {
          counts[i]++;
        }
      }

      elems.push(elem);
    };
    var $fake = $('<div></div>');
    this.$svg.find('*').each(function() {
      $fake.attr('style', $(this).attr('style'));
      var fill = $fake.css('fill');

      if (fill) return setCounts(fill, $(this));
      else if ($(this).attr('fill')) {
        return setCounts($(this).attr('fill'), $(this));
      }
    });

    var maxCountIndex = counts.indexOf(_.max(counts));
    var svgColor = colors[maxCountIndex];

    if (svgColor && /^#?\w\w\w$/.test(svgColor))
      svgColor = [svgColor[0], svgColor[1], svgColor[1], svgColor[2], svgColor[2], svgColor[3], svgColor[3]].join('');

    if (!svgColor) svgColor = '#ffffff';
    svgColor = svgColor && svgColor.toLowerCase();

    if (!this.model.get('pic_color')) {
      svgColor &&
        this.model.set({ pic_color: svgColor.substr(1), pic_opacity: 1 }, { patch: true, skipTriggerRedraw: true });
      this.changeControls && this.changeControls();
    }
    if (svgColor) this.$svgColoredElems = elems;
  },

  // Очищаем у всех внутренних элементов все аттрибуты fill и выставляем общий fill для всего svg
  setVectorColor: function(hexColor, alpha) {
    if (this.$svgColoredElems) {
      var $fake = $('<div></div>');
      _.each(this.$svgColoredElems, function($elem) {
        $fake.attr('style', $elem.attr('style'));

        $fake.css({ fill: '', 'fill-opacity': '' });
        $elem.get(0).setAttributeNS(null, 'style', $fake.attr('style'));

        $elem.get(0).setAttributeNS(null, 'fill', '');
        $elem.get(0).setAttributeNS(null, 'fill-opacity', '');
      });

      delete this.$svgColoredElems;
    }

    this.$svg.get(0).setAttributeNS(null, 'fill', '#' + hexColor);
    this.$svg.get(0).setAttributeNS(null, 'fill-opacity', alpha);
  },

  // Сохраняем на сервер новую svg, измененную в браузере - с новым цветом
  saveSvg: function(callback) {
    if (!this.originalSvg || !this.originalSize) return;

    var imported = this.originalSvg.importNode(this.$svg[0], true); // Обязательный хак, xml schema svg может содержать набор левых аттрибутов, которые вырежутся "обычным" сериализатором

    $(imported)
      .attr('width', this.originalSize.width)
      .attr('height', this.originalSize.height)
      .css('display', '');

    return $.ajax({
      url: this.SVG_URL,
      type: 'POST',
      data: {
        svg: new XMLSerializer().serializeToString(imported),
        mid: this.workspace.mag.get('num_id'),
      },
      success: function(data) {
        this.model.get('picture').editedVectorUrl = data && data.editedVectorUrl;
        delete this.model.get('picture').finalUrl;
        delete this.model.get('picture').final2xUrl;
        callback && callback();
      },
      context: this,
    });
  },
});

export default BlockCommonPictureClass;
