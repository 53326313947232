import '../common/init';
import '../vendor/modernizr-2.8.3.js';
import '../libs/modernizr.tests.js';
import $ from '@rm/jquery';
import backboneInit from '../common/backbone-init';
import Backbone from 'backbone';
import { Constants, Utils } from '../common/utils';
import ViewerRouter from './router';
import Device from '../common/device';
import '../../css/viewer_desktop.less';

RM.common = { ...RM.common, ...window.viewerConfig };

$(function() {
  Device.setPageClass();
  window.Backbone = backboneInit(Backbone);

  RM.common.embedMode = Utils.queryUrlGetParam('embedMode') === 'true';
  RM.common.embedDomainName = Utils.queryUrlGetParam('embedDomainName') || '';
  RM.common.embedDomainType = Utils.queryUrlGetParam('embedDomainType') || '';

  RM.viewerRouter = new ViewerRouter();

  window.Backbone.history.universalStart({
    pushState:
      !Constants.IS_FILE_PROTOCOL && (RM.config && RM.config.pushState !== undefined ? RM.config.pushState : true),
    root: (RM.config && RM.config.root) || '/',
  });

  $(window).on('load', function() {
    $('html').addClass('page-loaded');
  });
});
