/**
 * Блок бэкграунд виджета
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import BlockClass from '../block';
import BackgroundVideoBlock from '../blocks/background-video';
import BlockCommonPicture from './common-picture';
import Viewports from '../../common/viewports';
import BackgroundSlideshowWidget from '../../common/background-slideshow';
import WidgetModel from '../models/widget';
import VideoUtils from '../../common/videoutils';
import { Utils } from '../../common/utils';

const BackgroundBlock = BlockCommonPicture.extend(
  {
    name: 'Background',
    thumb: 'bg',

    outofbox: true,

    excludedFromLib: true,

    immortal: true,
    controls: [],

    defaultSlideshow: {
      delay: 4,
      crossfade: 0.5,
      pictures: [],
    },

    viewport_fields: Viewports.viewport_fields.background,

    initialize: function(model, workspace) {
      this.initBlock(model, workspace);

      if (this.model.get('mute') === undefined) {
        this.model.set('mute', true, { silent: true });
      }
      if (this.model.get('loop') === undefined) {
        this.model.set('loop', true, { silent: true });
      }

      this.createEl();

      this.hide();

      this.workspace.on('show', this.show);
      this.workspace.on('hide', this.hide);

      this.cachedEffects = [];

      this.workspace.$el.parent().on('scroll', this.onScroll);

      this.on('loadimage', this.onLoadImage);
      this.on('unloadimage', this.onUnloadImage);
    },

    createEl: function() {
      this.setElement(
        $(
          '<div class="background"><div class="picture smooth3d"></div> \
      <div class="slideshow-container"></div><div class="slideshow-preload"></div><div class="video"> \
      <div class="overlay"></div> \
      <div class="container"></div> \
      </div></div></div>'
        ).appendTo($('#main-background'))
      );

      this.$picture = this.$('.picture');
      this.$video = this.$('.video');
      this.$color = this.$el;
      this.$slideshow = this.$('.slideshow-container'); // Контейнер для слайдшоу
      this.$slideshowPreload = this.$('.slideshow-preload'); // Контейнер для предзагрузки изображений

      this.video = new BackgroundVideoBlock({ block: this });
      // Виджет слайдшоу
      this.slideshowWidget = new BackgroundSlideshowWidget({
        data: this.model.get('slideshow'),
        container: this.$slideshow,
        preloadContainer: this.$slideshowPreload,
      });
    },

    /**
     * Дозаполняет недостаюшие поля из дефолтного вьюпорта
     */
    validate: function() {
      // Поля, связанные с внешним видом, иногда пропадают из вьюпорта
      var styleFields = _.difference(Viewports.viewport_fields.background, Viewports.viewport_fields_common);
      this.model.fillMissingFieldsFromDefaultViewport(styleFields);
    },

    render: function() {
      this.updateBGColor();

      if (this.model.get('selectedType') == 'picture') {
        this.loadPic({ success: this.showPic });
      }

      if (this.model.get('selectedType') == 'video') {
        this.video.render();

        this.onResize(); // пересчитываем положение видеофона
      }

      if (this.model.get('selectedType') === 'slideshow') {
        this.slideshowWidget.render(true);
      }

      this.rendered = true;
      this.triggerReady();
    },

    updateBGColor: function() {
      var BGColor = this.model.get('color') || 'FFFFFF';

      this.$color.css({ 'background-color': '#' + BGColor });
    },

    // обновляем положение фона при смене типа вьюпорта или размеров вьюпорта
    onViewportUpdate: function(params) {
      // если текущий вьюпорт десктопный то возвращаем все как было прежде - фон на весь экран
      if (params.viewport == 'default') {
        this.$el.removeClass('is-viewport');
        this.$picture.css({
          width: '',
          height: '',
          top: '',
          left: '',
          'margin-left': '',
          'margin-top': '',
        });
        this.$slideshow.css({
          width: '',
          height: '',
          top: '',
          left: '',
          'margin-left': '',
          'margin-top': '',
        });
      } else {
        this.$el.addClass('is-viewport');

        this.$picture.css({
          width: params.width,
          height: params.min_height,
          top: '50%',
          left: '50%',
          'margin-left': -params.width / 2,
          'margin-top': -params.min_height / 2,
        });

        this.$slideshow.css({
          width: params.width,
          height: params.min_height,
          top: '50%',
          left: '50%',
          'margin-left': -params.width / 2,
          'margin-top': -params.min_height / 2,
        });
      }

      this.lastViewportParams = params;
    },

    getIconStyle: function() {
      var pic = this.model.get('picture');
      var video = this.model.get('video');
      var selectedType = this.model.get('selectedType');

      var style = {
        background: '#' + this.model.get('color'),
      };

      if (selectedType == 'picture') {
        if (pic && pic.thumbUrl) {
          return { 'background-image': 'url(' + pic.thumbUrl + ')' };
        }
      } else if (selectedType == 'video') {
        if (video.thumbnail_url) {
          return { 'background-image': 'url(' + video.thumbnail_url + ')' };
        }
      } else if (selectedType === 'slideshow') {
        var slideshow = this.model.get('slideshow');
        if (slideshow && slideshow.pictures && Array.isArray(slideshow.pictures) && slideshow.pictures.length) {
          var thumbUrl = _.sortBy(slideshow.pictures, 'num')[0].thumbUrl;
          return { 'background-image': 'url(' + thumbUrl + ')' };
        }
      }

      return style;
    },

    changePicture: function(pictureData, options) {
      var curr_viewport = this.workspace.page.getCurrentViewport();

      options = _.defaults(options || {}, { save: true });

      delete this.effectsUrl;

      if (pictureData.picture && this.model.get('picture') && this.model.get('picture').effect) {
        pictureData.picture.effect = this.model.get('picture').effect;
        options.silent = true;
      }

      var data = {
        picture: pictureData.picture,
      };

      // Если меняем кртинку в дефолтном вьюпорте, то заменяем ее, так же, во всех активных вьюпортах
      if (curr_viewport == 'default') {
        _.each(
          WidgetModel.viewports,
          function(vp) {
            if (!_.isEmpty(this.model[vp])) {
              this.model.set({ picture: data.picture }, { viewport: vp.replace(/^viewport_/, ''), silent: true });
            }
          }.bind(this)
        );
      }

      if (options.save) {
        this.model.save(data, options);
      } else {
        this.model.set(data, options);
      }

      if (pictureData.picture && pictureData.picture.effect) {
        this.createEffect(pictureData.picture.effect, {
          success: _.bind(function() {
            var picture = this.model.get('picture');
            this.model.unset('picture', { silent: true });
            this.model.save({ picture: picture });
          }, this),
        });
      }
    },

    onResize: function() {
      if (this.model.get('selectedType') != 'video') return;

      var video = this.model.get('video'),
        $container = this.$el.find('.container');

      if (_.isEmpty(video) || !video.provider_name) return;

      VideoUtils.setVideoPosition({
        $container: $container,
        $media: $container.find('iframe'),
        provider: video.provider_name,
        aspect_poster: video.aspect_poster,

        // видео нам тут нужно только для показа встроенного в него постера
        // который качественнее чем тот, который мы получаем через oEmbed
        // но проблема в том, что ютуб постер показывает в ифрейме без черных полос
        // потому для вимео мы передаем аспект реальной картинки внутри видео
        // а для ютуба аспект самого постера
        aspect_real: video.provider_name.toLowerCase() == 'vimeo' ? video.aspect_real : video.aspect_poster,
        controls_remove: true,
      });
    },

    show: function() {
      var $parent = this.$el.parent();
      this.$el
        .detach()
        .appendTo($parent)
        .stop()
        .animate({ opacity: 1 });

      this.onResize();

      // пересчитываем положение видеофона
      $(window).on('resize', this.onResize);
    },

    hide: function() {
      this.$el.stop().animate({ opacity: 0 });

      if (
        this.model.get('selectedType') === 'slideshow' &&
        this.slideshowWidget &&
        this.slideshowWidget.isAnimationRunning
      ) {
        this.slideshowWidget.stopAnimationLoop();
      }

      $(window).off('resize', this.onResize);
    },

    // Добавляет изображение в массив слайдшоу и сохраняет его
    saveSlideshowPicture: function(pictureData, options) {
      var curr_viewport = this.workspace.page.getCurrentViewport();
      var slideshow = this.model.get('slideshow');
      var notEmptySlideshow =
        slideshow && slideshow.pictures && Array.isArray(slideshow.pictures) && slideshow.pictures.length;
      var saveData = Object.assign({}, pictureData.picture, pictureData.size);
      var currentNum = 1;
      var newPicture;
      var newSlideshow;

      // Слайдшоу уже существует в бд
      if (notEmptySlideshow) {
        newSlideshow = _.cloneWithObjects(slideshow);
        currentNum = this.getMaxNum(slideshow.pictures) + 1;
        newPicture = Object.assign(saveData, { num: currentNum, uuid: Utils.generateUUID() });
        newSlideshow.pictures.push(newPicture);
        newSlideshow.pictures = _.sortBy(newSlideshow.pictures, 'num');
      } else {
        newSlideshow = _.cloneWithObjects(this.defaultSlideshow);
        newPicture = Object.assign(saveData, { num: currentNum, uuid: Utils.generateUUID() });
        newSlideshow.pictures = [newPicture];
      }

      var data = {
        slideshow: newSlideshow,
      };

      // Если меняем слайдшоу в дефолтном вьюпорте, то заменяем ее, так же, во всех активных вьюпортах
      if (curr_viewport == 'default') {
        _.each(
          WidgetModel.viewports,
          function(vp) {
            if (!_.isEmpty(this.model[vp])) {
              this.model.set({ slideshow: data.slideshow }, { viewport: vp.replace(/^viewport_/, ''), silent: true });
            }
          }.bind(this)
        );
      }

      if (options.save) {
        this.model.save(data, options);
      } else {
        this.model.set(data, options);
      }

      // Добавляем изображение в виджет слайдшоу
      this.addPictureToSlideshow(newPicture);
    },

    updateSlideshowPicture: function(pictureData, options) {
      var slideshow = this.model.get('slideshow');
      var updateIndex = slideshow.pictures.findIndex(function(picture) {
        return picture.uuid === options.id;
      });

      var updatedPicture = Object.assign({}, slideshow.pictures[updateIndex], pictureData.picture, pictureData.size);
      var newSlideshow = _.cloneWithObjects(slideshow);

      newSlideshow.pictures[updateIndex] = updatedPicture;

      var data = {
        slideshow: newSlideshow,
      };

      this.model.save(data, options);

      this.slideshowWidget.updatePicture(updatedPicture);
    },

    selectSlideshowPicture: function(uuid) {
      this.slideshowWidget.selectSlide(uuid);
    },

    deleteSlideshowPicture: function(uuid) {
      this.slideshowWidget.removePicture(uuid);
    },

    updateSlideshowPicturePosition: function(recalcedPictures) {
      this.slideshowWidget.updatePicturePosition(recalcedPictures);
    },

    // Возвращает максимальное значение num в массиве картинок слайдшоу
    getMaxNum: function(pictures) {
      if (_.isEmpty(pictures)) return 0;
      return _.max(_.pluck(pictures, 'num'));
    },

    // Добавляет картинку (пока в конец слайдшоу)
    addPictureToSlideshow: function(picture) {
      this.slideshowWidget.addPicture(picture);
    },

    // Устанавливает параметр задержки слайдшоу
    updateSideshowDelay: function(newDelay) {
      this.slideshowWidget.setDelay(newDelay);
    },

    // Устанавливает параметр скорости анимации для слайдшоу
    updateSideshowCrossfade: function(newCrossfade) {
      this.slideshowWidget.setCrossfade(newCrossfade);
    },

    runSlideshow: function() {
      if (this.slideshowWidget) {
        this.slideshowWidget.runAnimation();
      }
    },

    stopSlideshow: function() {
      if (this.slideshowWidget) {
        this.slideshowWidget.stopAnimationLoop();
      }
    },

    /**
     * Ждет пока загрузится картинка, после этого выполняет колбэк
     * Если была команда грузить новую картинку, то забывает про старую
     */
    loadPic: function(options) {
      options = options || {};

      this.cancelPictureLoading();

      if (!options.url) {
        options.url = this.getPictureUrl();
      }

      this.picUrl = options.url;

      var cb = _.isFunction(options.success) ? options.success : $.noop;
      var eb = _.isFunction(options.error) ? options.error : $.noop;

      if (!this.picUrl) {
        return cb();
      }

      var localPic = (this._loadingPic = $('<img>')
        .addClass('preload_image')
        .appendTo('body')
        .attr('src', this.picUrl)
        .on('load', cb)
        .on('error', eb)
        .on(
          'load',
          _.bind(function() {
            this.trigger('loadimage', 'main');
            _.defer(
              function() {
                if (localPic == this._loadingPic) {
                  this.cancelPictureLoading();
                }
                localPic = null;
              }.bind(this)
            );
          }, this)
        )
        .on(
          'error',
          _.bind(function() {
            _.defer(
              function() {
                if (localPic == this._loadingPic) {
                  this.cancelPictureLoading();
                }
                localPic = null;
              }.bind(this)
            );
          }, this)
        ));
    },

    cancelPictureLoading: function() {
      this._loadingPic &&
        this._loadingPic
          .off('load')
          .off('error')
          .removeAttr('src')
          .remove();
      this._loadingPic = undefined;
    },

    /**
     * Просто отображает картинку с определенным урлом на фоне, нужно например для превью картинок из панелки поиска гугла
     */
    previewPic: function(url, callbacks) {
      if (url && _.last(url.split('.')) == 'svg') return callbacks && callbacks.error && callbacks.error();

      callbacks = callbacks || {};

      this.loadPic({
        url: url,
        error: callbacks.error,
        success: _.bind(function() {
          this.renewPic();
          _.isFunction(callbacks.success) && callbacks.success();
        }, this),
      });
    },

    /**
     * Реагирует на свои события 'loadimage'
     */
    onLoadImage: function(type) {
      if (type == 'preview') this.previewLoaded = true;
      if (type == 'main') this.mainLoaded = true;

      if (this.panel) {
        this.previewLoaded && this.mainLoaded && this.trigger('imagesReady');
      } else {
        this.mainLoaded && this.trigger('imagesReady');
      }
    },

    /**
     * Реагирует на свои события 'unloadimage'
     */
    onUnloadImage: function(type) {
      if (type == 'preview') this.previewLoaded = false;
      if (type == 'main') this.mainLoaded = false;
    },

    showPic: function() {
      this.$picture.stop().animate({ opacity: this.model.get('opacity') });
      this.$picture.css({
        'background-image': this.picUrl ? 'url(' + this.picUrl + ')' : '',
      });
    },

    redraw: function(model, options) {
      options = options || {};

      // у виджетов есть общие свойства, смена которых не должна приводить к перерисовке
      // даже строго противопоказана, например для свойство анимаций
      if (!this.checkNeedRedraw(model, options)) return;

      if (_.isEmpty(model.changed)) return;

      this.validate();

      if (model.hasChanged('color')) {
        this.updateBGColor();
        // TODO: video overlay
      }

      // options.first - widget.js/restoreViewport, но он тут что-бы не вызывать лишний rerender
      // Вызывается при открытие конструктора не в стандартном вьюпорте либо при изменение вьюпорта
      // TODO: не очень нравится мне это решение но оно работает
      if (options.viewportChange && !options.first && model.get('selectedType') === 'slideshow') {
        this.slideshowWidget.rerender(model.get('slideshow'));
      }

      if (
        model.hasChanged('picture') &&
        this.getPictureUrl() != this.picUrl &&
        model.get('selectedType') == 'picture'
      ) {
        if (this.getPictureUrl()) {
          this.trigger('unloadimage', 'main');
          this.on('imagesReady', this.renewPic);
          this.loadPic();
        } else {
          this.loadPic({ success: this.showPic });
        }
      }

      if (model.hasChanged('opacity')) {
        this.$picture.css('opacity', this.model.get('opacity'));
      }

      if (model.get('selectedType') == 'video') {
        if (model.hasChanged('videoopacity')) {
          this.$video.css('opacity', this.model.get('videoopacity'));
        }

        if (model.hasChanged('video')) {
          this.onResize(); // пересчитываем положение видеофона
        }
      }

      if (model.hasChanged('selectedType')) {
        if (this.model.get('selectedType') == 'picture') {
          this.loadPic({
            success: _.bind(function() {
              this.video.hide();
              this.$slideshow.animate({ opacity: 0 });
              this.slideshowWidget.stopAnimationLoop();
              this.showPic();
            }, this),
          });
        }
        if (this.model.get('selectedType') == 'video') {
          this.$picture.stop().animate({ opacity: 0 });
          this.$slideshow.animate({ opacity: 0 });
          this.slideshowWidget.stopAnimationLoop();
          this.video.show();

          this.onResize(); // пересчитываем положение видеофона
        }
        if (this.model.get('selectedType') === 'slideshow') {
          this.$picture.stop().animate({ opacity: 0 });
          this.video.hide();
          this.$slideshow.animate({ opacity: 1 });
          this.slideshowWidget.runAnimation();
        } else {
          this.video.hide();
          this.$picture.stop().animate({ opacity: 0 });
          this.slideshowWidget.stopAnimationLoop();
          this.$slideshow.animate({ opacity: 0 });

          if (!this.model.get('selectedType')) this.model.set('selectedType', 'color', { first: true });
        }
      }

      this.workspace.trigger('redraw');
    },

    /**
     * Обновляет картинку на фоне: отрабатывает, когда происходит смена картинок: загрузка новой или применение фильтра
     */
    renewPic: function() {
      this.off('imagesReady', this.renewPic);

      if (this.skipRenew) {
        this.skipRenew = false;
        return;
      }

      var $oldEl = this.$el;

      this.createEl();

      this.onViewportUpdate(this.lastViewportParams);

      this.updateBGColor();

      this.$el.css({ opacity: 0 });

      this.$el.find('.picture').css({
        'background-image': this.picUrl ? 'url("' + this.picUrl + '")' : '',
        opacity: this.model.get('opacity'),
      });

      var newOpacity = this.workspace != RM.constructorRouter.workspace ? 0 : 1; // Не показываем новый бэкграунд если он зааплоадился после перехода на другую страницу

      this.$el.animate({ opacity: newOpacity }, function() {
        $oldEl.remove();
      });
    },

    removePicture: function() {
      // BlockCommonPicture.prototype.removePicture.apply(this, arguments)
      this.model.set('picture', null);
      this.model.save({}, { silent: true, toHistory: true });
      this.effectsUrl = '';
      this.$picture.stop().css('background-image', '');
    },

    /**
     * Подгружает фон, чтобы при переходе на страницу не было большой задержки на загрузку картинки
     */
    preload: function() {
      if (this.preloaded) return;
      this.preloaded = true;

      this.loadPic({
        success: _.bind(function() {
          if (this.model.get('selectedType') == 'picture') {
            _.delay(this.showPic, 1000);
          }
        }, this),
      });
    },

    /**
     * Вызывается при скролле страницы в конструкторе
     */
    onScroll: function() {
      // На время скролла переводит фон в "плоский" режим, чтобы вебкит показывал скроллбар в макоси в режиме скрытых скроллов
      // Потом надо вернуть 3d режим, чтобы не было искажения на фоне.

      if (!Modernizr.mac || !Modernizr.webkit) return true;

      var $pic = this.$picture;

      $pic.removeClass('smooth3d');

      clearTimeout(this._remove3dTimeout);
      this._remove3dTimeout = setTimeout(function() {
        $pic.addClass('smooth3d');
      }, 1000);
    },
  },
  {
    // Статические методы

    // При создании вьюпорта у виджета его текущие аттрибуты расширятся результатом данного метода
    getViewportDefaults: function(model, viewportName, options) {
      // обязательно вызываем базовый метод!
      // там есть код который обязательно должне вызваться для всех (т.е. вернуть расширенные параметры для любого виджета)
      var extendedParams = BlockClass.getViewportDefaults(model, viewportName, options);

      // запрещаем видеофоны у мобильных вьюпортов
      if (model.get('selectedType') == 'video') extendedParams = _.extend(extendedParams, { selectedType: 'color' });

      return extendedParams;
    },

    defaults: {
      slideshow: null,
    },
  }
);

export default BackgroundBlock;
