module.exports = {["template-common-hotspot-widget"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n    <div class="common-hotspot">\n\n	    <div class="pin"></div>\n\n    </div>\n\n';
}
return __p;
},["template-common-hotspot-widget-mobile-wrapper"]: function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n\n    <div class="hotspot-fullscreen-wrapper invisible">\n        <div class="center-table">\n            <div class="center-cell">\n\n            </div>\n        </div>\n    </div>\n\n';
}
return __p;
},}