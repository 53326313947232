/**
 * Конструктор для контрола убирает группировку со всех групп которые попали в выделение
 */
import _ from '@rm/underscore';
import ControlClass from '../control';
import templates from '../../../templates/constructor/controls/common_unpack.tpl';

const CommonUnpack = ControlClass.extend({
  name: 'common_unpack', // должно совпадать с классом вьюхи

  className: 'control common_unpack',

  initialize: function(params) {
    this.template = templates['template-constructor-control-common_unpack'];

    this.initControl(params);
  },

  /**
   * Переопределяем в потомках, если надо.
   */
  bindLogic: function() {
    RM.constructorRouter.bindGlobalKeyPress([
      { key: 'g', handler: this.onClick, optionKeys: !Modernizr.mac ? ['ctrl', 'shift'] : ['meta', 'shift'] },
    ]);
  },

  /**
   * Переопределяем в потомках, если надо.
   */
  unBindLogic: function() {
    RM.constructorRouter.unbindGlobalKeyPress('g', this.onClick);
  },

  // Показываем его когда в выделении есть виджеты с проставленым pack_id
  restrictions: function(workspace) {
    var packs = {};

    return (
      _.reduce(
        workspace.getSelectedBlocks(),
        function(memo, block) {
          return memo + (block.model.get('pack_id') ? 1 : 0);
        },
        0
      ) > 0
    );
  },

  /**
   * Переопределяем метод нажатия на иконку контрола
   */
  onClick: function() {
    var new_data = _.map(this.models, function(model) {
      var data = {
        _id: model.id,
        pack_id: null,
      };

      // Всегда снимаем блокировку с блоков при изменении группировки. То же самое делается в common_pack.js и block.js в onPackChange
      if (model.get('is_locked')) {
        model.set('is_locked', false);
        _.extend(data, { is_locked: false });
      }

      return data;
    });

    this.master.workspace.save_group(new_data);

    // закрываем другие открытые контролы
    this.master.deselect();
  },
});

export default CommonUnpack;
