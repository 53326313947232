/**
 * Кривые безье
 * строит линию со стрелкой от блока fromBlock
 * до блока toBlock внутри элемента $el
 * с классом cssClass
 * Метод update надо вызывать при изменении или перемещении блоов или полотна
 */

import MathUtils from './mathutils';

var CubicBezier = function(data) {
  this.fromBlock = data.fromBlock;
  this.toBlock = data.toBlock;
  this.$el = data.$el;

  this.root = document.createElementNS(CubicBezier.svgns, 'svg');
  this.root.setAttributeNS(null, 'class', data.cssClass);

  this.curve = document.createElementNS(CubicBezier.svgns, 'path');
  this.root.appendChild(this.curve);

  this.arrow = document.createElementNS(CubicBezier.svgns, 'polygon');
  this.root.appendChild(this.arrow);

  this.$el.append(this.root);

  this.update();
};

CubicBezier.svgns = 'http://www.w3.org/2000/svg';
CubicBezier.minHandleLength = 65;
CubicBezier.lineEndOffset = 7;

CubicBezier.prototype.update = function() {
  var fromBox = MathUtils.getBox(this.fromBlock);
  var toBox = MathUtils.getBox(this.toBlock);

  var bestPoints = MathUtils.getBestPoints(fromBox, toBox);

  var fromPoint = bestPoints.fromPoint;
  var toPoint = bestPoints.toPoint;
  // Точка будет находится на небольшом расстоянии от блока
  toPoint = MathUtils.getPerpendicularEnd(bestPoints.toSide, toPoint, toBox, CubicBezier.lineEndOffset);

  var distance = MathUtils.getDistance(fromPoint, toPoint);

  var handleLength = Math.max(distance / 2, CubicBezier.minHandleLength);
  var controlPoint1 = MathUtils.getPerpendicularEnd(bestPoints.fromSide, fromPoint, fromBox, handleLength);
  var controlPoint2 = MathUtils.getPerpendicularEnd(bestPoints.toSide, toPoint, toBox, handleLength);

  //  <path d="M0 50 C 100 50, 100 150, 200 150" stroke="black" fill="transparent"></path>
  var value =
    'M' +
    fromPoint.x +
    ' ' +
    fromPoint.y +
    ' C ' +
    controlPoint1.x +
    ' ' +
    controlPoint1.y +
    ', ' +
    controlPoint2.x +
    ' ' +
    controlPoint2.y +
    ', ' +
    toPoint.x +
    ' ' +
    toPoint.y;
  this.curve.setAttributeNS(null, 'd', value);

  this.updateArrow(bestPoints.toSide, toPoint, toBox);
};

CubicBezier.prototype.updateArrow = function(side, point, box) {
  // <polygon points="0 1 0 11 11 5.5" stroke="green" fill="transparent" stroke-width="1"/>
  var x = point.x;
  var y = point.y;
  var angleInDegrees = Math.round(((box.angle + MathUtils.sideAngles[side]) * 180) / Math.PI);

  var length = 9;
  var halfWidth = 5.5;
  var value = x + ' ' + y + ' ' + (x - halfWidth) + ' ' + (y - length) + ' ' + (x + halfWidth) + ' ' + (y - length);
  var rotate = 'rotate(' + angleInDegrees + ', ' + x + ', ' + y + ')';

  this.arrow.setAttributeNS(null, 'points', value);
  this.arrow.setAttributeNS(null, 'transform', rotate);
};

CubicBezier.prototype.remove = function() {
  this.root && this.root.parentNode.removeChild(this.root);
};

export default CubicBezier;
