import $ from '@rm/jquery';
import Vue from 'vue';
import WidgetClass from '../widget';
import RmFooter from '../../../components/common/rm-footer.vue';
import Scale from '../../common/scale';

const rmFooter = WidgetClass.extend({
  ignoreScale: true,

  render: function() {
    this.makeBox('widget-rm-footer');

    const rmFooterVue = Vue.extend(RmFooter);

    this.vue = new rmFooterVue({
      el: $('<div>')
        .appendTo(this.$el)
        .get(0),
    });

    this.setStyles();

    this.rendered = true;
    this.widgetIsLoaded();

    return this;
  },

  destroy: function() {
    this.vue && this.vue.$destroy();

    return WidgetClass.prototype.destroy.apply(this, arguments);
  },

  setStyles: function() {
    if (!Scale.isAllowed() || $(window).width() < 1024) return;

    const $el = $(this.vue.$el);
    const unscale = 1 / Scale.getRatio();
    const isZoom = Scale.isZoom();
    const isTransform = Scale.isTransform();

    $el.css({
      zoom: isZoom ? `${unscale}` : null,
      transform: isTransform ? `scale(${unscale})` : null,
    });

    $el.toggleClass('transform', isTransform);
  },

  onPageResize: function() {
    WidgetClass.prototype.onPageResize.apply(this, arguments);
    this.setStyles();
  },
});

export default rmFooter;
