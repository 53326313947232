/**
 * Конструктор для контрола назначения линка картиночному виджету и виджету шейпа,
 * наследуется от более сложного контрола линков для текстового виджета в сторону упрощения, так исторически сложилось
 */
import $ from '@rm/jquery';
import _ from '@rm/underscore';
import PreloadDesignImages from '../../common/preload-design-images';
import text_link from './text_link';
import { Utils } from '../../common/utils';
import ControlClass from '../control';

const PictureLink = text_link.extend({
  name: 'picture_link', // должно совпадать с классом вьюхи

  className: 'control picture_link',

  // это для переопределения тру в текстовом контроле
  saveOnDestroy: false,

  bindLogicSpecific: function() {
    this.listenTo(this.blocks[0].model, 'change:clickLink change:clickTarget', this.updateState);

    this.updateState();
  },

  unBindLogicSpecific: function() {
    this.stopListening(this.model);
  },

  onTargetInputChange: function() {
    var checked = this.$target_input.prop('checked');

    this.models[0].save({
      clickTarget: checked ? '_blank' : '_self',
    });
  },

  updateState: function(param) {
    var link = this.models[0].get('clickLink'),
      target = this.models[0].get('clickTarget');

    this.$panel.toggleClass('collapsed', !link);

    if (this.placeholder_popup_visible) this.closePlaceholderPopup();

    // если линков в выделении нет
    if (!link) {
      this.$input.val('');
      this.onInputChange();
      this.setPlaceholder('initial');
    } else {
      this.$target_wrapper.addClass('disabled');

      if (/^mailto\:/i.test(link)) this.setPlaceholder('email');
      else if (/^tel\:/i.test(link)) this.setPlaceholder('phone');
      else if (this.backRegexp.test(link)) this.setPlaceholder('back');
      else if (this.gobackRegexp.test(link)) this.setPlaceholder('goback');
      else if (this.mailchimpRegexp.test(link)) this.setPlaceholder('mailchimp');
      else if (this.anchorRegexp.test(link)) this.setPlaceholder('anchor');
      else {
        this.setPlaceholder('url');
        this.$target_wrapper.removeClass('disabled');
      }

      // пытаемся получить айдишник страницы, если линк ведет на страницу мега (аттрибут data-pid у линка)
      // если это не валидный урл тогда смотрим, а не введен ли урл без http(s)
      link = link.replace(/^mailto\:/i, ''); // убираем mailto: в начеле строки, если есть
      link = link.replace(/^tel\:/i, '');
      var isUrl =
        this.urlRegexp.test(link) ||
        this.shortUrlRegexp.test(link) ||
        Utils.isValidEmailLink(link) ||
        this.telRegexp.test(link);

      // если линк ведет на страницу мэга
      if (!isUrl) {
        // может быть указан Back to Top либо номер страницы
        if (this.backRegexp.test(link)) {
          this.setPlaceholder('back');
          this.$input.val(this.backDisplayName); // Заменяем внутреннее значение ссылки на отображаемое
        } else if (this.gobackRegexp.test(link)) {
          this.setPlaceholder('goback');
          this.$input.val(this.gobackDisplayName); // Заменяем внутреннее значение ссылки на отображаемое
        } else if (this.mailchimpRegexp.test(link)) {
          this.$input.val(this.mailchimpDisplayName);
        } else if (this.anchorRegexp.test(link)) {
          var y = this.models[0].get('anchor_link_pos') || 0;
          this.anchorEnterMode(y);
          this.$input.val(y + 'px');
        } else {
          this.setPlaceholder('page');

          this.$target_wrapper.addClass('disabled');

          // получаем страницу мэга по её айдишнику
          var pagUri = RM.constructorRouter.mag.getPageUri(link);

          // если страница существует
          if (pagUri) this.$input.val(pagUri);
          else {
            this.$input.val('');
            this.setPlaceholder('no-page');
          }
        }
      } else {
        // если обычный урл, т.е. pageID == undefined
        this.$input.val(link);
      }

      this.onInputChange();
      this.$action_icon.addClass('remove-icon');
      this.$action_icon.removeClass('hidden'); // просто все иконки хайдятся если введен неправильный урл, в частности "Page no longer exists", а нам надо крестик показывать когда линк указывает на несуществующую страницу
      this.$placeholder_icon.addClass('hidden');

      // если у всех линков в выделении target=_blank тогда зачекаем инпут (уник по всем таргеам находит одного)
      this.$target_input.prop('checked', target == '_blank');
    }

    this.$icon.toggleClass('highlighted', !!link);
  },

  onInputKey: function(e) {
    if (e.keyCode == $.keycodes.enter) this.applyLink();

    // если у нас в выделении или под курсором линк, мы все стерли в поле ввода и нажали на del, тогда удаляем линк
    if (e.keyCode == $.keycodes.del && this.$input.val() == '' && this.models[0].get('clickLink')) {
      this.removeLink();
    }
  },

  applyLink: function() {
    var url = this.$input.val(),
      data = {};

    // если введен урл или номер страницы которая есть в меге
    var checkInput = this.getUrlData(url);
    if (!checkInput.result) return;

    if (checkInput.isEmail) checkInput.url = 'mailto:' + checkInput.url;

    if (checkInput.isTel) checkInput.url = 'tel:' + checkInput.url;

    if (checkInput.isBack) {
      checkInput.url = this.backLink;
    }

    if (checkInput.isGoBack) {
      checkInput.url = this.gobackLink;
    }

    if (checkInput.isAnchor) {
      data.anchor_link_pos = parseInt(checkInput.url, 10) || 0;
      checkInput.url = this.anchorLink;
      checkInput.page = this.blocks[0].workspace.page.id;
    } else {
      this.anchorExitMode();
    }

    if (checkInput.isValidPageNumber) checkInput.url = RM.constructorRouter.mag.getPageId(checkInput.url);

    var target = checkInput.isURL ? '_blank' : '_self'; // дефолтный таргет для всех новых линков

    _.extend(data, {
      clickLink: checkInput.url,
      clickTarget: target,
    });

    if (checkInput.page) data.clickPage = checkInput.page;

    this.models[0].save(data);

    this.updateState();

    $('#main').focus();
  },

  removeLink: function() {
    this.models[0].unset('clickLink');
    this.models[0].unset('clickTarget');
    this.models[0].save();

    this.updateState();
  },

  // в случае картинки показываем контрол, только если у блока есть картинка
  restrictions: function(workspace) {
    var blocks = workspace.getSelectedBlocks();

    if (blocks.length == 1 && blocks[0].name == 'Picture' && blocks[0].isPictureEmpty()) {
      return false;
    } else {
      return true;
    }
  },

  save: function() {
    // убираем логику сохранения сортировки стилей в контроле линков для текста
  },

  // расширяем метод уничтожения контрола, чтобы переопределить действия в родительском text_link
  destroy: function() {
    this.anchorExitMode();

    ControlClass.prototype.destroy.apply(this, arguments);
  },

  // расширяем метод который срабатывает при открытии панели контрола, чтобы переопределить действия в родительском text_link
  select: function() {
    // if (this.$placeholder.text() == this.$placeholder.attr('data-anchor')) { this.anchorEnterMode(); }

    ControlClass.prototype.select.apply(this, arguments);

    if (this.isPreviewEnabled()) {
      return this.$panel.addClass('show-picture-preview-error');
    }
    this.$panel.removeClass('show-picture-preview-error');

    PreloadDesignImages('controls-text_link');
  },

  isPreviewEnabled: function() {
    return !!this.model.get('preview_enabled');
  },
});

export default PictureLink;
