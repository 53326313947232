import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import BlockClass from './block';
import templates from '../../templates/constructor/pack.tpl';
import blockFrameTpl from '../../templates/constructor/block-frame.tpl';
import PackFrameClass from './pack-frame';

export const WidgetPackModel = Backbone.Model.extend({
  defaults: {
    type: 'pack',
    visible: false,
    x: Number.POSITIVE_INFINITY,
    y: Number.POSITIVE_INFINITY,
    r: Number.NEGATIVE_INFINITY,
    b: Number.NEGATIVE_INFINITY,
    w: 0,
    h: 0,
    aspect: null,
    angle: 0,
    fixed_position: null,
    is_full_width: false,
    is_full_height: false,
    flip_v: false,
    flip_h: false,
    sinAngle: 0,
    cosAngle: 1,
    viewport: 'default',
  },
  getViewport: function() {
    return this.get('viewport');
  },
  reset: function() {
    this.set(this.defaults);
  },
});

export const WidgetPack = BlockClass.extend({
  /**
   * Совпадает с pack id блока
   */
  id: null,
  // Если задать начальное значение blocks: [], то этот массив у экземпляров будет общим с прототипом
  // (в итоге массив с блоками никогда не будет обнуляться, только увеличиваться)
  blocks: null,
  workspace: null,
  latestPosSizeAngle: null,
  frame: null,
  line: null,
  proportional: true,
  initialize: function(options) {
    // Для консистентности с классом Block
    _.bindAll(this);
    this.blocks = [];
    this.latestPosSizeAngle = {};
    this.model = new WidgetPackModel();
    this.workspace = options.workspace;
  },
  render: function() {
    var html = templates['template-pack']({ id: this.id, templates: { ...blockFrameTpl } });
    this.setElement($(html));
    this.bindHandlers();
    this.frame = new PackFrameClass({ packId: this.id, block: this, blocks: this.blocks });
    this.rendered = true;
    return this.$el;
  },
  getCss: function(workspaceLeft, workspaceTop) {
    var attributes = this.model.attributes;
    return {
      left: attributes.x + (attributes.fixed_position ? workspaceLeft : 0),
      top: attributes.y + (attributes.fixed_position ? workspaceTop : 0),
      width: attributes.w,
      height: attributes.h,
      position: attributes.fixed_position ? 'fixed' : 'absolute',
      'z-index': attributes.fixed_position ? 999 : undefined,
    };
  },
  update: function() {
    var css = this.getCss(this.workspace.position.left, this.workspace.position.top);
    this.$el.css(css);
    if (this.isVisible() && !this.frame.isFrameVisible()) {
      this.frame.show();
    } else if (!this.isVisible()) {
      this.frame.hide();
    }
    this.$el.toggleClass('fixed-frame', this.isFixed());
    this.$el.toggleClass('full-width', this.isFullWidth());
    this.$el.toggleClass('full-height', this.isFullHeight());

    this.recalcFixedLine();
  },
  recalcFixedLine: function(selected) {
    var position = this.model.get('fixed_position') || this.restoreFixed;
    var isSelected = this.isVisible() || selected;

    // Если это fixed-группа и видна рамка группы и это группа, а не выделение нескольких блоков, то показать линию.
    // При выделении нескольких блоков у каждого fixed-блока своя линия
    if (position && isSelected && !this.isSelection()) {
      this.updateFixedLine(position);
    } else {
      this.removeFixedLine();
    }
  },
  destroy: function() {
    this.removeFixedLine();
    BlockClass.prototype.destroy.apply(this, arguments);
  },
  bindHandlers: function() {
    const el = $(this.$el);
    el.drag('start', this.onDragStart, { not: '.no-drag' });
    el.drag(this.onDragMove, { delay: 100 });
    el.drag('end', this.onDragEnd);
    el.on('click', this.onPackClick);
  },
  addBlock: function(block) {
    this.blocks.push(block);
    // isPackFrameHidden — флаг, означающий "не показывать рамку группы".
    // Например, нужно прятать рамки группы при редактировании анимации блока
    if (!block.isPackFrameHidden) {
      var blockBox = block.getBoxData({ includeBoundingBox: true, checkFixedPosition: true });
      var left = Math.min(this.model.get('x'), blockBox.bb_x);
      var top = Math.min(this.model.get('y'), blockBox.bb_y);
      var bottom = Math.max(this.model.get('b'), blockBox.bb_y + blockBox.bb_h);
      var right = Math.max(this.model.get('r'), blockBox.bb_x + blockBox.bb_w);
      var fixedPosition = block.model.get('fixed_position');
      var isFullWidth = block.model.get('is_full_width');
      var isFullHeight = block.model.get('is_full_height');

      this.model.set({
        y: top,
        x: left,
        b: bottom,
        r: right,
        w: right - left,
        h: bottom - top,
        visible: _.every(this.blocks, 'selected'),
        fixed_position: fixedPosition || this.model.get('fixed_position'),
        is_full_width: isFullWidth || this.model.get('is_full_width'),
        is_full_height: isFullHeight || this.model.get('is_full_height'),
        viewport: block.model.getViewport(),
      });
      // Вызов recalcFixedLine гарантирует, что линии блоков будут пересчитаны при группировке / разгруппировке
      block.recalcFixedLine();
      this.frame && this.frame.setSizeConstraints();
    }
    this.latestPosSizeAngle = this.getModelBox();
  },

  reset: function() {
    this.blocks = [];
    this.model.reset();
  },

  isFixed: function() {
    return Boolean(this.model.get('fixed_position'));
  },

  isSelection: function() {
    return this.id === 'selected';
  },

  isVisible: function() {
    return Boolean(this.model.get('visible'));
  },

  export: function() {
    return _.extend({}, this.model.attributes, { id: this.id });
  },

  /**
   * Событие начала перетаскивания за рамку группы
   * Просто смотрим какую группу тянем, находим в ней любой блок (для удобства первый)
   * и просто эмулируем действия как будто юзер тащит мышкой за этот блок (а там функция сама знает как правильно тянуть блоки из групп)
   */
  onDragStart: function(event, drag) {
    this.anyBlockInPackForDragging = _.first(this.blocks);
    // вызываем функцию начала драга блока из block.js и передаем ей блок который мы "типа" тащим (параметр this, контекст исполнения в общем)
    // в общем тащим рамку группы, а эмулируем как будто тянем за виджет группы
    // чтобы кучу кода не дублировать
    BlockClass.prototype.onDragStart.call(this.anyBlockInPackForDragging, event, drag);
  },

  /**
   * Событие перетаскивания за рамку группы (код слизан с похожей же функции из block.js)
   * просто эмулируем действия как будто юзер тащит мышкой за этот блок (а там функция сама знает как правильно тянуть блоки из групп)
   */
  onDragMove: function(event, drag) {
    // вызываем функцию драга блока из block.js и передаем ей блок который мы "типа" тащим (параметр this, контекст исполнения в общем)
    // в общем тащим рамку группы, а эмулируем как будто тянем за виджет группы
    // чтобы кучу кода не дублировать
    this.anyBlockInPackForDragging && BlockClass.prototype.onDragMove.call(this.anyBlockInPackForDragging, event, drag);
  },

  /**
   * Событие окончания перетаскивания за рамку группы (код слизан с похожей же функции из block.js)
   * просто эмулируем действия как будто юзер тащит мышкой за этот блок (а там функция сама знает как правильно тянуть блоки из групп)
   */
  onDragEnd: function(event, drag) {
    // вызываем функцию окончания драга блока из block.js и передаем ей блок который мы "типа" тащим (параметр this, контекст исполнения в общем)
    // в общем тащим рамку группы, а эмулируем как будто тянем за виджет группы
    // чтобы кучу кода не дублировать
    this.anyBlockInPackForDragging && BlockClass.prototype.onDragEnd.call(this.anyBlockInPackForDragging, event, drag);
  },

  // клик по рамке группы (выделяет все виджеты группы или наоборот, если с шифтом по уже выделеным)
  onPackClick: function(e) {
    if (this._justResized) {
      this._justResized = false;
      return;
    }
    if (window.suppressClick && window.suppressClick > +new Date()) {
      return;
    }
    this.trigger('click', e);
  },

  storeFixedPosition: function() {
    var position = this.model.get('fixed_position');
    position ? (this.restoreFixed = position) : null;
  },

  restoreFixedPosition: function() {
    delete this.restoreFixed;
    _.each(this.blocks, function(block) {
      // Восстанавливать fixed-позицию каждого блока нужно до общего сохранения, иначе позиция fixed-блоков сохранится неправильно
      block.restoreFixedPosition();
    });
  },

  getBoxData: function(params) {
    var box = BlockClass.prototype.getBoxData.apply(this, arguments);
    box.b = box.y + box.h;
    box.r = box.x + box.w;
    return box;
  },

  getHotspotExternalPeerIds: function() {
    var blockIds = _.map(this.blocks, 'id');
    return _.reduce(
      this.blocks,
      function(peerIdsExternalGroup, block) {
        var otherIds = block.model.get('type') === 'hotspot' && _.difference(block.getPeerIds(), blockIds);
        _.each(otherIds, function(id) {
          peerIdsExternalGroup.indexOf(id) === -1 && peerIdsExternalGroup.push(id);
        });
        return peerIdsExternalGroup;
      },
      []
    );
  },

  getHotspotPeerData: function() {
    var externalPeerIds = this.getHotspotExternalPeerIds();
    return _.map(
      externalPeerIds,
      function(id) {
        var block = this.workspace.findBlock(id);
        return _.extend({}, block.getSaveBoxData(), { _id: id });
      }.bind(this),
      {}
    );
  },

  saveBox: function(options, otherBlocksData) {
    var update = _.pick(this.getBoxData(), ['w', 'h', 'x', 'y']);
    this.model.set(update);

    // Сохраняем все модели сразу, а не по отдельности
    var data = _.map(
      this.blocks,
      function(block) {
        // Не передавайте options в getSaveBoxData. options имеют значение только в индивидуальном ресайзе.
        // Если передать options при групповом ресайзе, то slideshow (на этот момент options нужны только ему)
        // будет вести себя непредсказуемо.
        return _.extend({}, block.getSaveBoxData(), { _id: block.id });
      }.bind(this)
    );

    data = _.uniq([].concat(data, this.getHotspotPeerData(), otherBlocksData || []), function(item) {
      return item._id;
    });

    this._saveXHR && this._saveXHR.abort();
    this.workspace.set_group(data, _.extend({ isGroupResize: true }, options));
    this.workspace.save_group(data, _.extend({ isGroupResize: true }, options));
  },
});
