/**
 * Базовый класс для рамки выделения
 */
import $ from '@rm/jquery';
import Backbone from 'backbone';
import _ from '@rm/underscore';
import MathUtils from '../common/mathutils';
import { Utils } from '../common/utils';
import BlockClass from './block';

// FIXME: циклическая зависимость
let WidgetPack;

const BlockFrame = Backbone.View.extend(
  {
    minwidth: 15,
    minheight: 15,
    maxwidth: Infinity,
    maxheight: Infinity,

    // отражение точек ресайза по вертикали (какая перезодит в какую) (менять тут ничего нельзя!)
    FLIP_H: { ne: 'nw', n: 'n', nw: 'ne', w: 'e', sw: 'se', s: 's', se: 'sw', e: 'w' },
    // отражение точек ресайза по горизонтали (какая перезодит в какую) (менять тут ничего нельзя!)
    FLIP_V: { sw: 'nw', s: 'n', se: 'ne', e: 'e', ne: 'se', n: 's', nw: 'sw', w: 'w' },
    // просто список точек в порядке обхода (менять тут ничего нельзя!)
    DOTS: ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'],
    // положение точек ресайза на рамке блока по оси X, в порядке обхода DOTS (менять тут ничего нельзя!)
    DOTS_POS_X: [0, 0.5, 1, 1, 1, 0.5, 0, 0],
    // положение точек ресайза на рамке блока по оси Y, в порядке обхода DOTS (менять тут ничего нельзя!)
    DOTS_POS_Y: [0, 0, 0, 0.5, 1, 1, 1, 0.5],
    // точки обратные текущей, данные получены на основании текущего поведения (просто ресайзил картинку за все точки и смотрел ту точку которая остается на месте)
    // результат несколько озадачил, потому что на лицо явное неравномерное распределение, например se стала опорной один раз, фигня какая-то, но, как говориться, не ломать же что уже сделано
    FIXED_DOTS: { nw: 'se', n: 'sw', ne: 'sw', e: 'nw', se: 'nw', s: 'nw', sw: 'ne', w: 'ne' },

    /**
     * Общие для всех рамок вещи
     */
    initialize: function(options) {
      _.bindAll(this);

      WidgetPack = require('./widget-pack').WidgetPack;

      this.block = options.block;

      this.setElement(this.block.$el);

      this.$dots = this.$('.dot');
      this.$triangles = this.$('.triangle');

      this.$dots
        .drag('start', this.onDotDragStart)
        .drag(this.onDotDrag)
        .drag('end', this.onDotDragEnd);

      this.$triangles
        .on('mouseenter', this.onTriangleMouseEnter)
        .drag('start', this.onTriangleDragStart)
        .drag(this.onTriangleDrag)
        .on('mouseleave', this.onTriangleMouseLeave)
        .drag('end', this.onTriangleDragEnd);

      this.recalcDotsSizes({ pageX: 0, pageY: 0 });

      this.mouseProceeding = true;

      this.listenTo(this.block, 'textWidgetMouseSelectionStart', this.disableMouseProceeding);
      this.listenTo(this.block, 'textWidgetMouseSelectionEnd', this.enableMouseProceeding);
      this.listenTo(this.block, 'textWidgetMarginDragStart', this.disableMouseProceeding);
      this.listenTo(this.block, 'textWidgetMarginDragEnd', this.enableMouseProceeding);

      this.onSizeInputChangeStart.__debounced = _.debounce(this.onSizeInputChangeStart, 200, true);
      this.onSizeInputChangeEnd.__debounced = _.debounce(this.onSizeInputChangeEnd, 200);
    },

    disableMouseProceeding: function() {
      // скрываем точки
      // this.recalcDotsSizes({pageX: 0, pageY: 0});
      this.$dots.animate({ width: 0, height: 0, margin: 0 }, 'fast');
      this.mouseProceeding = false;
    },

    enableMouseProceeding: function() {
      this.mouseProceeding = true;
    },

    /**
     * Показать рамку
     */
    show: function(fromWidgetBar) {
      this.$el.addClass('frame');
      if (fromWidgetBar) {
        this.$el.addClass('frameWB');
      }

      this.bindMouseMove();

      this.mouseProceeding = true;
      this.frameVisible = true;

      this.recalcPointsDirections();

      this.showSizes();

      if (this.isRedBorderNeeded()) {
        this.checkHeightOfTextWidget();
      }
    },

    /**
     * Визуально скрыть рамку (сам элемент остается чтобы ловить события мыши)
     */
    hide: function() {
      this.$el.removeClass('frame');
      this.$el.removeClass('frameWB');

      this.unbindMouseMove();
      this.frameVisible = false;

      this.hideSizes();
    },

    isFrameVisible: function() {
      return this.frameVisible;
    },

    bindMouseMove: function() {
      $(document).on('mousemove', this.recalcDotsSizes);
    },

    unbindMouseMove: function() {
      $(document).off('mousemove', this.recalcDotsSizes);
    },

    // показывает размеры виджета на воркспейсе
    showSizes: function() {
      if (
        !this.areSizesShown &&
        (this.canChangeDimension('width') || this.canChangeDimension('height')) &&
        !this.block.has_parent_block
      ) {
        this.$el.children('.frameborder').after('<div id="sizes_input">');

        this.sizes = new RM.vue.Sizes({
          el: '#sizes_input',
          propsData: {
            block: this.block,
            frame: this,
          },
        });

        // У onSizeInputChangeStart дебаунс on leading edge, у onSizeInputChangeEnd — на trailing (стандартно)
        // Таким образом onSizeInputChangeStart вызывается в начале периода ресайза, onSizeInputChangeEnd — в конце
        // Важно повешать onSizeInputChangeStart на событие beforeChange, которое происходит до события change.
        // @see sizes.vue, метод resize
        this.sizes.$on('beforeChange', this.onSizeInputChangeStart.__debounced);
        this.sizes.$on('afterChange', this.onSizeInputChangeEnd.__debounced);
        this.sizes.$on('change', this.onSizeInputChange);

        this.areSizesShown = true;
      }
    },

    hideSizes: function() {
      if (!this.sizes || !this.areSizesShown) return;

      this.sizes.$destroy();
      this.sizes.$el.remove();
      this.areSizesShown = false;
    },

    onSizeInputChangeStart: function() {
      this.onResizeStart.apply(this, arguments);
    },

    onSizeInputChangeEnd: function() {
      this.block.saveBox();
      this.onResizeEnd.apply(this, arguments);
    },

    onSizeInputChange: function(box, direction) {
      this.block.recalcFixedLine();
      this.block.recalcStickedLine();

      this.doResize(box, false, direction);
    },

    /**
     * Делает кружок нижнего ресайза больше, всегда видимым и с плюсиком, это для текстового виджета
     */
    setResizeBottomPlus: function(active) {
      this.$el.toggleClass('resize-bottom-plus', active);

      if (this.block.model.get('column_count') === 1 && !this.block.$el.hasClass('text-autosize')) {
        this.checkHeightOfTextWidget();
      }
    },

    /**
     * TODO: зарефакторить в отдельный класс: рамку текстового виджета
     *
     * Cделать выделение на рамке едва видимым, нужно например для текстоовго виджета у которого
     * в редиме редактирования рамка всегда должна быть едва заметна независимо от таскания и пр. т.е. чтобы
     * это выделение не зависело от класса blocks-is-moving + должны остаться все точечки ресайза но они отодвинутся
     */
    setEditorState: function() {
      this.block.workspace.disableDragging = true;
      this.$el.addClass('editor-state');
      this.resetRotation();
    },

    /**
     * Вернуть вид рамки в нормальное состояние
     */
    removeEditorState: function() {
      this.$el.removeClass('editor-state');
      this.block.workspace.disableDragging = false;
      this.restoreRotation();
      this.block.css(); // Возвращаем нормальные z-index
    },

    setRotationState: function() {
      if (this.rotationState) {
        return;
      }

      this.rotationState = true;
      this.$el.addClass('rotation-state');

      this.block.workspace.redrawBottomShiftLine({ tp: 'rotation' });
    },

    removeRotationState: function() {
      if (!this.rotationState) {
        return;
      }

      this.rotationState = false;
      this.$el.removeClass('rotation-state');

      this.block.workspace.redrawBottomShiftLine({ tp: 'rotation' });
    },

    // Временно сбрасывает поворот если он был, не изменяя модели
    resetRotation: function() {
      var latest = _.pick(this.block.latestPosSizeAngle, 'angle', 'flip_h', 'flip_v');

      if (latest.angle || latest.flip_h || latest.flip_v) {
        this.block.$el.flashClass('transitions', 500);
        this.block.forbidVisualRotation = true;
        this.block.css(); // Без параметров, потому что в css учитывается флаг forbidVisualRotation
        this.recalcPointsDirections();
      }
    },

    // Восстанавливает поворот из ранее сохраненных данных (а не из модели!)
    // Т.к. за время сброса поворота могли вызываться функция css и даже обновиться свойства модели
    // (например, ресайз текстового виджета во время редактирования)
    restoreRotation: function() {
      if (this.block.forbidVisualRotation) {
        this.block.$el.flashClass('transitions', 500);
        this.block.forbidVisualRotation = false;
        this.block.css();
        this.recalcPointsDirections();
      }
    },

    /**
     * Расчитываем размеры точек на рамке в зависимости от положения мыши (чем ближе мышь к точке, тем она больше)
     * + размеры и положение треугольника для Bundle drag
     */
    recalcDotsSizes: function(e) {
      if (!this.mouseProceeding) return;

      var boxData = this.block.getBoxData({ includeRotationData: true, checkFixedPosition: true });

      var x = e.pageX,
        y = e.pageY,
        minDist = 99999,
        minInd = 0,
        curSize = 0,
        ind = 0,
        parentOffset = this.$el.parent().offset(),
        l = boxData.x + parentOffset.left,
        t = boxData.y + parentOffset.top,
        w = boxData.w,
        h = boxData.h,
        editorState = this.$el.hasClass('editor-state'),
        dotsDeltaPosWEditor = [-13, 0, 14, 14, 14, 0, -13, -13], // это смещения точек по X в режиме редактирования текстового виджета
        dotsDeltaPosHEditor = [-13, -13, -13, 0, 14, 14, 14, 0], // это смещения точек по Y в режиме редактирования текстового виджета
        dotsDeltaMarginW = ['left', 'left', 'right', 'right', 'right', 'left', 'left', 'left'],
        dotsDeltaMarginH = ['top', 'top', 'top', 'top', 'bottom', 'bottom', 'bottom', 'top'],
        sizeMin = 1,
        sizeMax = 13,
        appearRadius = 70,
        sinAngle = this.block.latestPosSizeAngle.sinAngle,
        cosAngle = this.block.latestPosSizeAngle.cosAngle,
        self = this,
        css = {},
        correctionX = 0,
        correctionY = 0,
        bottomDotX = 0,
        bottomDotY = 0,
        bottomDotInd = -1,
        topDotY = 99999999,
        topDotX = 0,
        topDotInd = -1;

      // Фиксед виджеты всегда в одном и том же месте экрана, не нужно ничего рассчитывать
      if (this.block.model.get('fixed_position')) {
        var offset = this.$el.offset();
        l = offset.left;
        t = offset.top;
      }

      // для супер маленьких блоков показываем только одну точку ресайза - ту за которую тянули последний раз, либо если такой нет, то нижнюю правую
      if (w < this.block.SUPER_SMALL_TRESHOLD && h < this.block.SUPER_SMALL_TRESHOLD) {
        minInd = this.lastResizePointIndex || 4; // всегда показываем ту точку за которую тянули в прошлый раз или. если ее нет 4ю нижнюю-правую
        curSize = sizeMax; // максимально возможным размером, без реакции на мышь

        correctionX = [-1, -1, 1, 1, 1, 0, -1, -1][minInd]; // супер маленькие блоки сейас используются для шагов анимаций, и там при 0 скейле надо чуть сдвинуть точку чтобы она была на одной линии с ромбиком добавления нового шага, просто визуальная мелочь, значения подобраны эмпирически
        correctionY = [-1, -1, -1, -1, 1, 1, 1, -1][minInd];
      } else {
        this.$dots.each(function() {
          var dot_x = l + w * self.DOTS_POS_X[ind] + (editorState ? dotsDeltaPosWEditor[ind] : 0),
            dot_y = t + h * self.DOTS_POS_Y[ind] + (editorState ? dotsDeltaPosHEditor[ind] : 0);

          // если бокс был повернут или отражен по горизонтали или вертикали, нам надо подкорректировать
          // расчитанное положение точки на экране с учетом этого самого вращения-отражения
          if ((boxData.angle || boxData.flip_v || boxData.flip_h) && !self.block.forbidVisualRotation) {
            var cx = l + w / 2, // находим центр вращения (центр бокса)
              cy = t + h / 2,
              dx = dot_x - cx, // находим смещение точки от центра вращения (неповернутой точки)
              dy = cy - dot_y,
              flip_x = boxData.flip_h ? -dx : dx, // если надо - отражаем точку по вертикали-горизонтали
              flip_y = boxData.flip_v ? -dy : dy;

            dot_x = cx + cosAngle * flip_x + sinAngle * flip_y;
            dot_y = cy + sinAngle * flip_x - cosAngle * flip_y;
          }

          var dist = Math.sqrt(Math.pow(dot_x - x, 2) + Math.pow(dot_y - y, 2)) - sizeMax;
          if (dist < minDist) {
            minDist = dist;
            minInd = ind;
          }
          // выбираем крайние точки, если есть поворот
          // либо берем ту, что по середине крайней стороны
          if (
            (dot_y > bottomDotY && Math.abs(dot_y - bottomDotY) > 0.5) ||
            (Math.abs(dot_y - bottomDotY) < 0.5 && ind % 2 == 1)
          ) {
            bottomDotY = dot_y;
            bottomDotInd = ind;
            bottomDotX = dot_x;
          }

          if (
            (dot_y < topDotY && Math.abs(dot_y - topDotY) > 0.5) ||
            (Math.abs(dot_y - topDotY) < 0.5 && ind % 2 == 1)
          ) {
            topDotY = dot_y;
            topDotInd = ind;
            topDotX = dot_x;
          }
          ind++;
        });
      }

      if (bottomDotInd === minInd || topDotInd === minInd) {
        topDotY -= 20;
        bottomDotY += 20;
        var triangleX = topDotInd === minInd ? topDotX : bottomDotX,
          triangleY = topDotInd === minInd ? topDotY : bottomDotY,
          triangleDist = Math.sqrt(Math.pow(triangleX - x, 2) + Math.pow(triangleY - y, 2)) - sizeMax;
        if (triangleDist < minDist) {
          minDist = triangleDist;
        }
      }

      curSize = ((appearRadius - minDist) / appearRadius) * (sizeMax - sizeMin) + sizeMin;
      if (curSize < 2) {
        curSize = 0;
      } else if (curSize > sizeMax) {
        curSize = sizeMax;
      } else {
        curSize = 2 * Math.floor(curSize / 2) + 1;
      }

      // this.$dots.removeAttr('style');
      if (!this.denyRecalcDotSize) {
        this.$dots.attr('style', ''); // Сбрасываем CSS не через removeAttr('style'), изза того что removeAttr глючит в долбанном сафари с JQuery 2.0
        this.$triangles.attr('style', ''); // Сбрасываем CSS не через removeAttr('style'), изза того что removeAttr глючит в долбанном сафари с JQuery 2.0
      }
      css.width = curSize;
      css.height = curSize;
      css['margin-' + dotsDeltaMarginW[minInd]] = -Math.floor(curSize / 2) + correctionX;
      css['margin-' + dotsDeltaMarginH[minInd]] = -Math.floor(curSize / 2) + correctionY;

      var relH = ['left', '', 'right', 'right', 'right', '', 'left', 'left'],
        relV = ['top', 'top', 'top', '', 'bottom', 'bottom', 'bottom', ''],
        triangleSize = Math.min(1, Math.max(appearRadius - minDist, 0) / appearRadius),
        triangleMaxWidth = 19,
        triangleMaxHeight = 17,
        triangleWidth = Math.floor((triangleSize * triangleMaxWidth) / 2) * 2 + 1,
        triangleHeight = Math.floor((triangleSize * triangleMaxHeight) / 2) * 2 + 1,
        triangleCss = {
          transform: 'scale(' + triangleSize + ') rotateZ(' + -this.block.latestPosSizeAngle.angle + 'deg)',
        };

      if (this.block.frameColor) {
        css['background-color'] = this.block.frameColor;
        // TODO: стили так и не меняются, разобраться
        triangleCss['border-top-color'] = this.block.frameColor;
        triangleCss['border-color'] = this.block.frameColor;
      }

      var angle =
        this.block.latestPosSizeAngle.angle > 0
          ? this.block.latestPosSizeAngle.angle
          : 360 + this.block.latestPosSizeAngle.angle;

      if (angle > 90 && angle < 180) {
        angle = 180 - angle;
      }

      if (angle > 270 && angle < 360) {
        angle = 360 - angle;
      }

      var s = Math.sin(((angle % 180) / 180) * Math.PI),
        c = Math.cos(((angle % 180) / 180) * Math.PI),
        nearDot = this.$dots.eq(minInd),
        dH = 8, // ;((triangleMaxHeight-triangleHeight)/2 + triangleHeight / 2),
        dW = 9, // ((triangleMaxWidth-triangleWidth)/2 + triangleWidth / 2),
        triangleMargin = bottomDotInd === minInd ? 12 : 8;

      if (relH[minInd] === '') {
        triangleCss['left'] = 'calc(50% - ' + dW + 'px)';
      } else {
        triangleCss[relH[minInd]] = -dW;
      }
      if (relV[minInd] === '') {
        triangleCss['top'] = 'calc(50% - ' + dH + 'px)';
      } else {
        triangleCss[relV[minInd]] = -dH;
      }

      triangleCss['margin-' + dotsDeltaMarginW[minInd]] = -Math.floor((triangleMargin + curSize) * s);
      triangleCss['margin-' + dotsDeltaMarginH[minInd]] = -Math.floor((triangleMargin + curSize) * c);

      if (!this.denyRecalcDotSize) {
        nearDot.css(css);
        if (
          (bottomDotInd === minInd || topDotInd === minInd) &&
          !this.block.model.get('fixed_position') &&
          this.block.workspace &&
          this.block.workspace.page.get('type') !== 'basic' &&
          ((bottomDotInd === minInd && this.$el.hasClass('bundle-allow-bottom')) ||
            (topDotInd === minInd && this.$el.hasClass('bundle-allow-top')))
        ) {
          this.$triangles
            .eq(minInd)

            .css(triangleCss)
            .data('bundle-align', bottomDotInd === minInd ? 'bottom' : 'top');
        }
      }
    },

    // для каждой точки ресайза в DOM проставляет свойство data-visual-direction
    // в котором храниться одна из 8 сторон света куда направлена эта точка от центра блока (при флипах и поворотах очень актуально)
    // эта функция вызывается из css блока при поворотах
    // нужно это на данный момент для двух вещей:
    // 1. чтобы показывать правильные курсоры ресайза для точек повернутого блока
    // 2. чтобы правильно понять направление снэпинга при ресайзе повернутого блока
    recalcPointsDirections: function() {
      var boxData = this.block.getBoxData({ includeRotationData: true }),
        angle = this.block.forbidVisualRotation ? 0 : -boxData.angle,
        sectorShift = Math.floor((angle + 360 + 45 / 2) / 45) % 8; // на сколько "сторон света" мы сдвинулись из за текущего угла поворота (+360 чтобы не работать с отрицательными углами, 45 ширина сектора стороны света, 8 число сторон света)

      // пробегаемся по всем точкам ресайза и смотрим в какую часть света они смотрят
      // с учетом поворота и флипа
      this.$dots.each(
        _.bind(function(ind, elem) {
          var dir = this.DOTS[ind];

          if (boxData.flip_v && !this.block.forbidVisualRotation) dir = this.FLIP_V[dir];
          if (boxData.flip_h && !this.block.forbidVisualRotation) dir = this.FLIP_H[dir];

          // сдвигаем стороны света на текущий угол поворота
          dir = this.DOTS[(_.indexOf(this.DOTS, dir) - sectorShift + 8) % 8];

          $(elem).attr('data-visual-direction', dir);
        }, this)
      );
    },

    // Прокси функции для вызова соответствующих обработчиков
    // в зависимости от текущего состояния
    onDotDragStart: function() {
      if (this.rotationState) {
        return this.onRotateStart.apply(this, arguments);
      }

      this.onResizeStart.apply(this, arguments);
    },

    onDotDrag: function() {
      if (this.rotationState) {
        return this.onRotate.apply(this, arguments);
      }

      this.onResize.apply(this, arguments);
    },

    onDotDragEnd: function() {
      if (this.rotationState) {
        return this.onRotateEnd.apply(this, arguments);
      }

      this.block.workspace.checkBundleDragAbility();

      this.onResizeEnd.apply(this, arguments);
    },

    onTriangleMouseEnter: function(e) {
      var $currentTarget = $(e.currentTarget);
      if (!this.block.workspace._inTriangleBundleDrag && !this.denyRecalcDotSize) {
        this.block.workspace.checkBundleDragMode(e, $currentTarget.data('bundle-align'));
      }
    },

    onTriangleMouseLeave: function(e) {
      var $currentTarget = $(e.currentTarget);
      if (!this.block.workspace._inTriangleBundleDrag && !this.denyRecalcDotSize) {
        this.block.workspace.checkBundleDragMode(e, true);
      }
    },
    // Прокси функции для вызова соответствующих обработчиков
    // в зависимости от текущего состояния
    onTriangleDragStart: function(e) {
      if (this.rotationState) {
        return this.onRotateStart.apply(this, arguments);
      }

      var $currentTarget = $(e.currentTarget);
      this.denyRecalcDotSize = true;
      $currentTarget.addClass('moved');
      this.block.workspace._inTriangleBundleDrag = true;
      this.block.workspace.checkBundleDragMode(e, $currentTarget.data('bundle-align'));
      this.block.workspace.$el.addClass('bundle-drag-cursor');
      this.block.onDragStart.apply(this.block, arguments);
    },

    onTriangleDrag: function() {
      if (this.rotationState) {
        return this.onRotate.apply(this, arguments);
      }

      this.block.onDragMove.apply(this.block, arguments);
    },

    onTriangleDragEnd: function(e) {
      if (this.rotationState) {
        return this.onRotateEnd.apply(this, arguments);
      }
      this.block.workspace._inTriangleBundleDrag = false;
      var $currentTarget = $(e.currentTarget);
      $currentTarget.removeClass('moved');
      this.denyRecalcDotSize = false;
      this.block.workspace.checkBundleDragMode(e, true);
      this.block.workspace.$el.removeClass('bundle-drag-cursor');
      this.block.onDragEnd.apply(this.block, arguments);
    },

    /**
     * Начало ресайза
     */
    onResizeStart: function(event, drag, options) {
      options = options || {};
      this.block.storeFixedPosition();

      //
      // this.unbindMouseMove();

      $('html').addClass('blocks-is-resizing');
      this.denyRecalcDotSize = true;

      this.$el.find('.frameborder').css('outline', '1px solid transparent'); // фикс бага chrome когда не перерисовывается рамка при ресайзе, если медленно ресайзить, например, вниз, когда курсор всегда на точке (https://trello.com/c/V3k31esR/223--)

      $('html').removeClass('alt-key-pressed');
      this._dragEvent = event;

      this.block.workspace.on('specialKeydown specialKeyup', this.onSpecialKey);

      if (RM.constructorRouter.gg.snap && !this.block.model.get('fixed_position') && !options.isGroupResize) {
        RM.constructorRouter.gg.handleMoveStart(this.block instanceof WidgetPack ? this.block.blocks : [this.block]);
      }

      this.block.onResizeStart && this.block.onResizeStart(event, drag);

      // Обозначения направления: географические. Северозапад, север, и так далее.
      this.currentResizePoint = ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'][$(event.currentTarget).attr('data-ind')];
      this.block.workspace.widgetResizingOrRotating = true;

      this.lastResizePointIndex = $(event.currentTarget).attr('data-ind');

      drag.scrollTop = this.block.workspace.$container.scrollTop();
    },

    customResizeHandler: function(box, resizePoint) {
      return box;
    },

    applyExtraConstraints: function(box, resizePoint) {
      var widthParams = this.block.getFullWidthDims(),
        heightParams = this.block.getFullHeightDims();

      if (this.block.model.get('is_full_width')) {
        _.extend(box, {
          left: widthParams.x,
          width: widthParams.w,
        });
      }

      if (this.block.model.get('is_full_height')) {
        _.extend(box, {
          top: heightParams.y,
          height: heightParams.h,
        });
      }

      return box;
    },

    applyConstraints: function(box, options) {
      options = _.extend(
        {
          direction: 'se',
          fromCenter: false,
          isSidePointProportional: false,
          currentResizePoint: this.currentResizePoint,
          isProportional: this.block.proportional,
          initialBox: this.block.getModelBox(),
          constraints: this.constraints,
          minwidth: this.minwidth,
          minheight: this.minheight,
          maxwidth: this.maxwidth,
          maxheight: this.maxheight,
          ratio: this.block.ratio,
          aspect: this.block.model.get('aspect'),
        },
        options
      );
      return BlockFrame.applyConstraintsAbstract.apply(this, [box, options, this.applyExtraConstraints.bind(this)]);
    },

    onRotateStart: function(event, drag) {
      this.block.storeFixedPosition();

      var boxData = this.block.getBoxData({ includeRotationData: true }),
        parentOffset = this.$el.parent().offset(); // Т.к. начало координат виджетов не совпадает с начало координат документа

      // Вычисяем и кэшируем центр блока, начальный угол поворота и запоминаем координаты мыши
      this.rotationData = {
        center: {
          x: boxData.x + parentOffset.left + boxData.w / 2,
          y: boxData.y + parentOffset.top + boxData.h / 2,
        },

        // Текущие координаты мыши
        x: event.pageX,
        y: event.pageY,
        initialAngle: boxData.angle,
        prevAngle: boxData.angle,
      };

      $('body').addClass('grabbing-cursor');

      this.block.workspace.widgetResizingOrRotating = true;
    },

    /**
     * Вращение за точки
     * Функция на кажом шаге вычисляет угол между векторами,
     * направленными из центра блока к текущему и предыдущему положениям мыши
     */
    onRotate: function(event, drag) {
      var v1,
        v2,
        cos,
        rad,
        deltaAngle,
        angle,
        visualAngle,
        sign,
        SHIFT_STEP = 15; // Шаг угла при зажатом Shift

      var data = this.rotationData;

      // Вектор предыдущего шага
      v1 = {
        x: event.pageX - data.center.x,
        y: event.pageY - data.center.y,
      };

      // Вектор поворота на текущем шаге
      v2 = {
        x: data.x - data.center.x,
        y: data.y - data.center.y,
      };

      sign = v1.x * v2.y - v1.y * v2.x < 0 ? -1 : 1; // Знак угла поворота

      cos = (v1.x * v2.x + v1.y * v2.y) / (Math.sqrt(v1.x * v1.x + v1.y * v1.y) * Math.sqrt(v2.x * v2.x + v2.y * v2.y));
      cos = cos > 1 ? 1 : cos; // Защита от ошибки вычисления косинуса. Он иногда может вычисляться как 1.000000002
      rad = sign * Math.acos(cos);

      deltaAngle = (rad / Math.PI) * 180;

      // // Поворачиваем с шагом при зажатом Shift
      // if (event.shiftKey) { deltaAngle = Math.floor(deltaAngle / SHIFT_STEP) * SHIFT_STEP; }

      angle = data.prevAngle - deltaAngle;

      // Поворачиваем с шагом при зажатом Shift
      if (event.shiftKey) {
        visualAngle = Math.floor(angle / SHIFT_STEP) * SHIFT_STEP + (data.initialAngle % SHIFT_STEP);
      } else {
        visualAngle = angle;
      }

      angle = angle >= 360 ? angle - 360 : angle;
      angle = angle <= -360 ? angle + 360 : angle;

      visualAngle = visualAngle >= 360 ? visualAngle - 360 : visualAngle;
      visualAngle = visualAngle <= -360 ? visualAngle + 360 : visualAngle;
      visualAngle = Math.floor(visualAngle);

      this.block.css({ angle: visualAngle });

      this.block.trigger('rotate', { angle: visualAngle });

      this.block.model.set({ angle: visualAngle });

      data.x = event.pageX;
      data.y = event.pageY;
      data.prevAngle = angle;
    },

    onRotateEnd: function(event, drag) {
      // Не сохраняем модель! Сохранение данных только после закрытия панели.

      $('body').removeClass('grabbing-cursor');

      this.block.workspace.widgetResizingOrRotating = false;

      this.block.restoreFixedPosition();
      this.block.workspace.checkBundleDragAbility();
    },

    // функционал красной границы работает только с одноколонночным текстом без авторесайза
    checkHeightOfTextWidget: function(resizeResult) {
      var allowableHeight = this.block.getAllowableHeight(),
        boxHeight = resizeResult ? resizeResult.height : this.block.getModelBox().height;

      // функцию, "приклеивающая"" нижнюю линию, вызываем только во время ресайза и
      // если тянут за одну из "нижних" точек
      if (resizeResult && _.contains(['sw', 'se', 's'], this.currentResizePoint)) {
        this.stickBtmBorderOfText(allowableHeight, resizeResult);
      }

      this.$el.find('.frameborder').toggleClass('red-border', boxHeight < allowableHeight);
    },

    stickBtmBorderOfText: function(allowableHeight, resizeResult) {
      var ceilBox = BlockFrame.ceilObject({
        width: resizeResult.width,
        top: resizeResult.top,
        left: resizeResult.left,
      });
      // взял из grid-guides-controller.js, данное значение используется, как дефолтное, для снэпа
      var sticky = 8,
        delta,
        box;

      delta = Math.abs(resizeResult.height - allowableHeight);

      if (delta < sticky) {
        ceilBox.height = allowableHeight;
        box = this.customResizeHandler($.extend(box, ceilBox), this.currentResizePoint);
        this.block.css(box, this.currentResizePoint);
      }
    },

    /**
     * Ресайз
     */
    onResize: function(event, drag) {
      if (this.block._saveXHR) {
        this.block._saveXHR.abort && this.block._saveXHR.abort();
        delete this.block._saveXHR;
      }

      var workspace = this.block.workspace,
        blocks = this.block instanceof WidgetPack ? this.block.blocks : [this.block];

      if (workspace && !(this.block instanceof WidgetPack)) {
        BlockClass.prototype.collectBottomBloсks(workspace, blocks, event);
      }

      // если стоит флаг резайтить от центра, тогда всегда это делаем (просто эмулируем зажатый альт)
      var fromCenter = this.block.resizeFromCenter || event.altKey;
      var isShift = event.shiftKey;

      this._dragEvent = event;

      if ($('html').hasClass('blocks-is-moving')) return;

      var stY = drag.startY + drag.scrollTop,
        edY = event.pageY + this.block.workspace.$container.scrollTop();

      drag.deltaY = edY - stY;

      Utils.autoWindowScroll(
        event,
        this.block.workspace.$container,
        _.bind(function() {
          this.onResize(event, drag);
        }, this)
      );

      var box = this.block.getModelBox();

      var boxOrig = _.clone(box);

      // просто синусы и косинусы угла поворота блока, а также последжние актуальные данные о размере-положении-повороте-отражении блока
      var sinAngle = this.block.latestPosSizeAngle.sinAngle,
        cosAngle = this.block.latestPosSizeAngle.cosAngle,
        boxData = this.block.getBoxData({ includeRotationData: true }),
        savedDeltaX = drag.deltaX,
        savedDeltaY = drag.deltaY;

      // если бокс был повернут нам надо подкорректировать дельты смещения мыши
      // мы просто поворочиваем их на минус угол поворота блока (т.е. как бы переводим их в пространственные координаты повернутого блока)
      if (boxData.angle && !this.block.forbidVisualRotation) {
        var nx = cosAngle * drag.deltaX + sinAngle * drag.deltaY,
          ny = -sinAngle * drag.deltaX + cosAngle * drag.deltaY;
        drag.deltaY = ny;
        drag.deltaX = nx;
      }

      // Обозначения направления: географические. Северозапад, север, и так далее.
      var direction = this.DOTS[$(event.currentTarget).attr('data-ind')];

      // если у нас есть зеркальные отражения, то мы просто меняем текущую точку на зеркально отраженную
      // т.е. например при горизоантальном отражении точка N станет S, и наоборот
      // а например при вертикальном отражении, точка NE станет NW и наоборот
      if (boxData.flip_v && !this.block.forbidVisualRotation) direction = this.FLIP_V[direction];
      if (boxData.flip_h && !this.block.forbidVisualRotation) direction = this.FLIP_H[direction];

      var isSidePointProportional =
        _.contains(['w', 'e', 'n', 's'], direction) && Boolean(isShift || this.block.proportional);
      direction = this.modifyOnShift(event, drag, box, {
        direction: direction,
        isSidePointProportional: isSidePointProportional,
      });

      if (!fromCenter) {
        switch (direction) {
          case 'nw':
          case 'ne':
          case 'n': // север наверху
            box.top += drag.deltaY;
            box.height -= drag.deltaY;
            break;
          case 'sw':
          case 'se':
          case 's': // юг снизу
            box.height += drag.deltaY;
            break;
        }

        switch (direction) {
          case 'nw':
          case 'sw':
          case 'w': // запад слева
            box.left += drag.deltaX;
            box.width -= drag.deltaX;
            break;
          case 'se':
          case 'ne':
          case 'e': // восток справа
            box.width += drag.deltaX;
            break;
        }

        if (isSidePointProportional) {
          switch (direction) {
            case 'e':
              box.top -= drag.deltaY / 2;
              box.height += drag.deltaY;
              break;
            case 'w':
              box.top += drag.deltaY / 2;
              box.height -= drag.deltaY;
              break;
            case 'n':
              box.left += drag.deltaX / 2;
              box.width -= drag.deltaX;
              break;
            case 's':
              box.left -= drag.deltaX / 2;
              box.width += drag.deltaX;
          }
        }
      } else {
        // alt-mode: mirror

        switch (direction) {
          case 'nw':
          case 'ne':
          case 'n': // север наверху
            box.top += drag.deltaY;
            box.height -= 2 * drag.deltaY;
            break;
          case 'sw':
          case 'se':
          case 's': // юг снизу
            box.top -= drag.deltaY;
            box.height += 2 * drag.deltaY;
            break;
        }

        switch (direction) {
          case 'nw':
          case 'sw':
          case 'w': // запад слева
            box.left += drag.deltaX;
            box.width -= 2 * drag.deltaX;
            break;
          case 'se':
          case 'ne':
          case 'e': // восток справа
            box.left -= drag.deltaX;
            box.width += 2 * drag.deltaX;
            break;
        }
      }

      // скорректировать по снэпу, с зажатым ctrl не ресайзим без привязки к гайдам
      // такой простой снэп только в том случае, если блок не повернут (или неповернут только визуально, когда виджет в режиме редактирования)
      if (RM.constructorRouter.gg.snap && !event.ctrlKey) {
        if ((!boxData.angle && !boxData.flip_v && !boxData.flip_h) || this.block.forbidVisualRotation) {
          box = RM.constructorRouter.gg.handleSnapResize(box, this.block.model, direction);
        }
      }

      // если есть поворот блока и если ресайз не зеркальный, т.е. не относительно центра виджета (без зажатого альта) (при зеркальном все и так работает, поскольку точка преобразования - центр бокса, который не меняется при повороте)
      // тогда ищем точку которая должна остаться на месте и расчитываем на сколько нужно сдвинуть виджет
      // чтобы при текущих своих размерах эта точка визуально осталась на месте
      // для начала находим точку обратную той за которую мы тянем
      // это будет та точка которая должна оставаться на месте при ресайзе
      var fixedDot = this.FIXED_DOTS[direction],
        fixedDotInd = _.indexOf(this.DOTS, fixedDot);

      box = this.applyConstraints(box, {
        boxOrig: boxOrig,
        direction: direction,
        fromCenter: fromCenter,
        isSidePointProportional: isSidePointProportional,
        fixedDot: fixedDot,
        fixedDotInd: fixedDotInd,
        DOTS_POS_Y: this.DOTS_POS_Y[fixedDotInd],
        DOTS_POS_X: this.DOTS_POS_X[fixedDotInd],
        sinAngle: sinAngle,
        cosAngle: cosAngle,
      });

      if (boxData.angle && !fromCenter && !this.block.forbidVisualRotation) {
        var rotatedBoxDelta = BlockFrame.getRotatedBoxDelta(boxOrig, box, boxData.angle, direction);
        box.left += rotatedBoxDelta.left;
        box.top += rotatedBoxDelta.top;
      }

      // скорректировать по снэпу, с зажатым ctrl не ресайзим без привязки к гайдам
      // тут снеп гораздо сложнее, в случае вращения виджета
      // во-первых, в случае врашения, расчитать снэп надо после всех ограничений (мин-макс, пропорций и пр.)
      // поскольку модифицированнй блок нам надо повернуть чтобы расчитать его bounding box
      // так вот, если это сделать до ограничений например пропорций, то получиться полная лажа (долго объяснять, кому интересно может просто этот блок поместить выше к обычному снэпу и рендерить текущий box чтобы понять почему будет плохо)
      // во-вторых, тут мы не будем модифицировать box, потому что нельзя (блок с проверками на пропорции и мин-макс мы оставили выше)
      // и потому что все равно нормально не сможем, потому что
      // снэпу передаем bounding box и он нам возвращает отснэпленый bounding box, и как его потом преобразовать в координаты и размеры повернутого блока вовсе не ясно
      // поэтому мы пойдем таким путем: расчитаем bounding box для нашего текущего отресайзеного и повернутого блока
      // и передадим его снэпу, снеп отработает и вернет отснэпленый bounding box
      // по нему мы поймем, на сколько он изменился и в какую сторону по оси x и по оси y
      // и полученные значения добавим к текущим deltaX и deltaY смещения мыши
      // т.е. просто "послушаем" советы снэпа и сделаем вид что мы мышкой сами доснэпили
      // и потом заново вызовем сами себя (onResize(event, drag)), но с модифицированными значениями смещения мыши
      // и со специальным ключом, чтобы заново снэп не расчитывать и не зациклиться
      if (RM.constructorRouter.gg.snap && !event.ctrlKey && !drag.disableSnap) {
        if ((boxData.angle || boxData.flip_v || boxData.flip_h) && !this.block.forbidVisualRotation) {
          var tmp = _.extend(
              {
                sinAngle: this.block.latestPosSizeAngle.sinAngle,
                cosAngle: this.block.latestPosSizeAngle.cosAngle,
              },
              box
            ),
            bBox = MathUtils.calcBoundingBox(tmp);

          bBox.left = bBox.bb_left;
          bBox.top = bBox.bb_top;
          bBox.width = bBox.bb_width;
          bBox.height = bBox.bb_height;

          // с направлением снэпинга еще надо разобраться
          // с ним тоже все очень непросто - нам надо определить к какой "части света"
          // относится та точка, за которую мы ресайзим (т.е. где она находиться визуально относительно центра бокса)
          // но это уже сделано заранее в функции recalcPointsDirections
          var visualDirection = $(event.currentTarget).attr('data-visual-direction');

          // а сейчас делаем такой хитрый ход:
          // направление ресайза мы теперь знаем (visualDirection)
          // теперь находим эту виртуальную точку на границе bounding box
          // после чего найдем эту же точку на границе отснэпленого bounding box
          // разница между координатами этих двух точек и будет коррекция дельты мыши для реализации "советов" снэпинга

          var virtualDotInd = _.indexOf(this.DOTS, visualDirection),
            virtualDotX1 = bBox.left + bBox.width * this.DOTS_POS_X[virtualDotInd],
            virtualDotY1 = bBox.top + bBox.height * this.DOTS_POS_Y[virtualDotInd];

          // расчитываем отснэпленый bounding box
          var snappedBBox = RM.constructorRouter.gg.handleSnapResize(bBox, this.block.model, visualDirection);

          var virtualDotX2 = snappedBBox.left + snappedBBox.width * this.DOTS_POS_X[virtualDotInd],
            virtualDotY2 = snappedBBox.top + snappedBBox.height * this.DOTS_POS_Y[virtualDotInd];

          var deltaX = virtualDotX2 - virtualDotX1,
            deltaY = virtualDotY2 - virtualDotY1;

          if (deltaX || deltaY) {
            drag.deltaX = savedDeltaX + deltaX;
            drag.deltaY = savedDeltaY + deltaY;

            drag.disableSnap = true;
            this.onResize(event, drag);
            return;
          }
        }
      }

      drag.disableSnap = false;

      this.block.recalcFixedLine();

      this.block.recalcStickedLine();

      var resizeResult = this.doResize(box, true, direction, fromCenter);

      if (workspace && !(this.block instanceof WidgetPack)) {
        var bbm = workspace.bottomBlocksToMove,
          bBoxOrig = MathUtils.calcBoundingBox(
            _.extend(
              {
                sinAngle: this.block.latestPosSizeAngle.sinAngle,
                cosAngle: this.block.latestPosSizeAngle.cosAngle,
              },
              boxOrig
            )
          ),
          bBoxResized = MathUtils.calcBoundingBox(
            _.extend(
              {
                sinAngle: this.block.latestPosSizeAngle.sinAngle,
                cosAngle: this.block.latestPosSizeAngle.cosAngle,
              },
              box
            )
          ),
          relativeDelta = bBoxResized.bb_top - bBoxOrig.bb_top;

        if (bbm && bbm.vPressed) {
          relativeDelta += bBoxResized.bb_height - bBoxOrig.bb_height;
        }

        BlockClass.prototype.moveBottomBlocks(workspace, relativeDelta, false, { y: 0 });
      }

      if (this.isRedBorderNeeded()) {
        this.checkHeightOfTextWidget(resizeResult);
      }

      return resizeResult;
      // box = this.customResizeHandler(box, this.currentResizePoint);

      // this.block.css(box, this.currentResizePoint);
    },

    // resizeByMouse - флажок который говорит функция вызвана при ресайзе мышкой
    // нужно для сложных customResizeHandler, когда frame.doResize() можеты вызываться из разных мест
    // в частности если он вызвался из frame.onResize (при ресайзе мышкой),
    // тогда мы знаем что при отпускании мыши изменения размеров-положения бокса сохраняться в модели (saveBox в onResizeEnd)
    // а если мы вызвали frame.doResize() сами (например это есть в виджете аудио или фб), тогда нам надо знать
    // что customResizeHandler вызвался не из-за мыши, а из-за того что мы сами вызвали frame.doResize()
    // и соответсвенно нам надо сделать saveBox самим
    // REFACTOR vmkcom: слишком сложно, надо переделать. Десяток разных методов с названием "resize" никуда не годится
    doResize: function(box, resizeByMouse, direction, fromCenter) {
      const ceilBox = BlockFrame.ceilObject({
        height: box.height,
        width: box.width,
        top: box.top,
        left: box.left,
      });

      box = this.customResizeHandler($.extend(box, ceilBox), this.currentResizePoint, resizeByMouse);

      this.block.css(box, this.currentResizePoint);

      this.block.trigger('resize', box);

      return box;
    },

    // проверяет является ли текст одноколоночным с выключенным авторесайзом
    isRedBorderNeeded: function() {
      return !!(
        this.block.$el.hasClass('block-text') &&
        this.block.model.get('column_count') === 1 &&
        !this.block.$el.hasClass('text-autosize')
      );
    },

    modifyOnShift: function(event, drag, box, options) {
      var isShift = event.shiftKey;
      var direction = options.direction;
      if ((isShift && !this.block.proportional) || options.isSidePointProportional) {
        // некоторые блоки сами знают свой аспект (например блоки шагов анимаций)
        // к тому же по другому там нельзя, ведь они могут ресайзиться в 0 и аспект 0/0 весьма неясен
        var aspect = this.block.model.get('aspect') || box.width / box.height,
          reverseAspect = 1 / aspect;

        if (_.contains(['w', 'e'], direction)) {
          drag.deltaY = drag.deltaX * reverseAspect;
          return direction;
        }
        if (_.contains(['n', 's'], direction)) {
          drag.deltaX = drag.deltaY * aspect;
          return direction;
        }

        if (_.contains(['nw', 'se'], direction)) {
          var k = reverseAspect;
        }

        if (_.contains(['sw', 'ne'], direction)) {
          k = -reverseAspect;
        }

        var c = drag.deltaY + drag.deltaX / k;

        drag.deltaX = c / (k + 1 / k);
        drag.deltaY = drag.deltaX * k;

        return direction;
      } else {
        return direction;
      }
    },

    /**
     * Конец ресайза
     */
    onResizeEnd: function(event, drag, options) {
      options = options || {};
      Utils.autoWindowScrollClear();

      this.block.restoreFixedPosition();
      this.block.recalcFixedLine();
      this.block.recalcStickedLine();

      this.block._justResized = true;
      this.$el.find('.frameborder').css('outline', '');

      if (!this.block.dontSaveOnResize) {
        var isPack = this.block instanceof WidgetPack;
        var bbm = this.block.workspace.bottomBlocksToMove; // шорткатим
        var spacesAffected =
          (this.block.workspace.spacesController && this.block.workspace.spacesController.getBlocksToSave()) || [];
        var saveOptions = _.extend({ resizePoint: this.currentResizePoint }, options);
        var blocks = isPack ? this.block.blocks : [this.block];
        var new_data = _.map([].concat(blocks, (bbm && bbm.tosave) || [], spacesAffected), function(block) {
          return _.extend(
            {
              _id: block.id,
            },
            block.getSaveBoxData(saveOptions)
          );
        });

        // если сдвигали с v/f у нас менялас ьи высота страницы, сохраним изменения если они есть
        if (
          bbm &&
          bbm.initPageHeight != this.block.workspace.page.getCurrentViewportParam('height') &&
          bbm.tosave &&
          bbm.tosave.length
        ) {
          this.block.workspace.page.save();
        }
        isPack
          ? this.block.saveBox(_.extend({ resizePoint: this.currentResizePoint }, options), new_data)
          : this.block.workspace.save_group(new_data);
        this.block.workspace.clearBottomBlocksToMove();
      }

      this.currentResizePoint = undefined;

      this.denyRecalcDotSize = false;
      $('html').removeClass('blocks-is-resizing');
      // this.bindMouseMove();

      if (RM.constructorRouter.gg.snap) {
        RM.constructorRouter.gg.handleMoveEnd();
      }

      this.block.onResizeEnd && this.block.onResizeEnd(event, drag, options);
      this.block.workspace.off('specialKeydown specialKeyup', this.onSpecialKey);

      this.block.workspace.widgetResizingOrRotating = false;
      this.block.workspace.checkBundleDragAbility();
    },

    // Моментальный вызов события onDrag на нажатие и отпускание alt & shift
    onSpecialKey: function(event) {
      if (event.keyCode == $.keycodes.shift && this.block.proportional) return;

      if (event.type == 'keydown') {
        this._dragEvent.altKey = event.altKey;
        this._dragEvent.shiftKey = event.shiftKey;
      }

      if (event.type == 'keyup') {
        if (event.keyCode == $.keycodes.shift) this._dragEvent.shiftKey = false;
        if (event.keyCode == $.keycodes.alt) this._dragEvent.altKey = false;
      }

      this.$dots.trigger(this._dragEvent);
    },

    canChangeDimension: function(dimension) {
      switch (dimension) {
        case 'width':
          return !this.block.isFullWidth() && (_.isUndefined(this.minwidth) || this.minwidth !== this.maxwidth);
        case 'height':
          return !this.block.isFullHeight() && (_.isUndefined(this.minheight) || this.minheight !== this.maxheight);
        default:
          return true;
      }
    },

    /**
     * Очищаем навешанные обработчики элемента, на всякий случай
     */
    destroy: function() {
      this.hide(); // чтобы сбросить слушатель мыши, есть ситуации когда фрейм удаляется без вызова hide

      this.retargetScroll && this.retargetScroll.restore && this.retargetScroll.restore();

      this.stopListening(this.block, 'textWidgetMouseSelectionStart', this.disableMouseProceeding);
      this.stopListening(this.block, 'textWidgetMouseSelectionEnd', this.enableMouseProceeding);
      this.stopListening(this.block, 'textWidgetMarginDragStart', this.disableMouseProceeding);
      this.stopListening(this.block, 'textWidgetMarginDragEnd', this.enableMouseProceeding);

      this.block.workspace.off(null, null, this);
    },

    /**
     * Статические методы
     */
  },
  {
    /**
     * @param {Object} box Бокс рамки {left: Number, top: Number, width: Number, height: Number}
     * @param {Object} options Опции ресайза
     * @param {Boolean} options.fromCenter
     * @param {String} options.direction
     * @param {String} options.isSidePointProportional Пропорциональный ресайз от боковой точки, при котором противоположная боковая точка остаётся неподвижной
     * @param {String} options.currentResizePoint direction может отличаться от currentResizePoint, хотя они почти всегда совпадают
     * @param {Boolean} options.isProportional
     * @param {Object} options.initialBox Бокс до ресайза (до начала драга) {left: Number, top: Number, width: Number, height: Number}
     * @param {Object} options.constraints Ограничивающий бокс. Может задаваться при кропе {left: Number, top: Number, width: Number, height: Number}
     * @param {Object} options.packBox Бокс группы {left: Number, top: Number, width: Number, height: Number}
     * @param {String} options.baseDimension width или height. Измерение, на которое нужно ориентироваться при пропорциональном ресайзе
     * @param {Number} options.minwidth Минимальные и максимальные ширина и высота
     * @param {Number} options.minheight
     * @param {Number} options.maxwidth
     * @param {Number} options.maxheight
     * @param {Number} options.ratio
     * @param {Number} options.aspect
     * @param {Function} constraintsCallback Дополнительный callback с ограничениями
     * @returns {*}
     */
    applyConstraintsAbstract: function(box, options, constraintsCallback) {
      options = options || {};
      var direction = options.direction;
      var fromCenter = options.fromCenter;
      if (options.isProportional) {
        // Более правильное ограничение при пропорциональном ресайзе, по большей стороне.
        // Без getBoxLimits, если есть ограничение 9999 на ширину и высоту для картинки 3:2,
        // то после выставления высоты в 9999 applyConstraints вернёт ширину 14999. Limits вернёт высоту 6666 и ширину 9999
        options = _.extend(
          {},
          options,
          MathUtils.getBoxLimits(options.initialBox, {
            minwidth: options.minwidth,
            maxwidth: options.maxwidth,
            minheight: options.minheight,
            maxheight: options.maxheight,
          })
        );
      }

      var isNorthOrSouthProportional = options.isSidePointProportional && (direction === 'n' || direction === 's');
      var isEastOrWestProportional = options.isSidePointProportional && (direction === 'e' || direction === 'w');

      // минимальные и максимальные значения ширины и высоты
      if (box.width < options.minwidth) {
        if (_.contains(['nw', 'sw', 'w'], direction) || isNorthOrSouthProportional) {
          var delta = box.width - options.minwidth;
          box.left += fromCenter || isNorthOrSouthProportional ? delta / 2 : delta;
        }

        if (_.contains(['se', 'ne', 'e'], direction) && fromCenter) {
          box.left += (box.width - options.minwidth) / 2;
        }

        box.width = options.minwidth;
      }
      if (box.height < options.minheight) {
        if (_.contains(['nw', 'ne', 'n'], direction) || isEastOrWestProportional) {
          delta = box.height - options.minheight;
          box.top += fromCenter || isEastOrWestProportional ? delta / 2 : delta;
        }

        if (_.contains(['sw', 'se', 's'], direction) && fromCenter) {
          box.top += (box.height - options.minheight) / 2;
        }

        box.height = options.minheight;
      }
      if (options.maxwidth && box.width > options.maxwidth) {
        if (_.contains(['nw', 'sw', 'w'], direction) || isNorthOrSouthProportional) {
          delta = options.maxwidth - box.width;
          box.left -= fromCenter || isNorthOrSouthProportional ? delta / 2 : delta;
        }

        if (_.contains(['ne', 'se', 'e'], direction) && fromCenter) {
          box.left -= (options.maxwidth - box.width) / 2;
        }

        box.width = options.maxwidth;
      }
      if (options.maxheight && box.height > options.maxheight) {
        if (_.contains(['nw', 'ne', 'n'], direction) || isEastOrWestProportional) {
          delta = options.maxheight - box.height;
          box.top -= fromCenter || isEastOrWestProportional ? delta / 2 : delta;
        }

        if (_.contains(['sw', 'se', 's'], direction) && fromCenter) {
          box.top -= (options.maxheight - box.height) / 2;
        }
        box.height = options.maxheight;
      }

      // Пропорциональное изменение размеров блока
      if (options.isProportional) {
        box = BlockFrame.makeProportional(box, options);
      }

      if (constraintsCallback && _.isFunction(constraintsCallback)) {
        box = constraintsCallback(box, options.currentResizePoint);
      }

      // Ограничения бокса. Могут задаваться при кропе, например.
      if (options.constraints) {
        if (box.left < options.constraints.left) {
          box.width += box.left - options.constraints.left;
          box.left = options.constraints.left;
        }
        if (box.top < options.constraints.top) {
          box.height += box.top - options.constraints.top;
          box.top = options.constraints.top;
        }
        if (box.left + box.width > options.constraints.left + options.constraints.width) {
          box.width = options.constraints.left + options.constraints.width - box.left;
        }
        if (box.top + box.height > options.constraints.top + options.constraints.height) {
          box.height = options.constraints.top + options.constraints.height - box.top;
        }
      }

      if (options.packBox) {
        var fitWidth = Math.min(options.packBox.width, box.width);
        var fitHeight = Math.min(options.packBox.height, box.height);
        box = {
          // Высота или ширина рамки группы может оказаться меньше рассчитанного без учёта ограничений блока,
          // поэтому выбираем наименьшее из двух значений — блок не должен оказаться больше рамки группы.
          width: fitWidth,
          height: fitHeight,
          // Не вылезать за верхнюю границу, не вылезать за нижнюю
          top: Math.min(
            options.packBox.top + options.packBox.height - fitHeight,
            Math.max(box.top, options.packBox.top)
          ),
          // Не вылезать за левую границу, не вылезать за правую
          left: Math.min(
            options.packBox.left + options.packBox.width - fitWidth,
            Math.max(box.left, options.packBox.left)
          ),
        };
      }

      // ограничение на ресайз вверх

      if (this.block.workspace.spacesController && !options.isSizes) {
        box = this.block.workspace.spacesController.recalcBoxOnResize(box, options, this.block.model);
      }

      return box;
    },

    /**
     * Коррекция ресайза для пропорциональных блоков
     * @param {Object} box Бокс рамки {left: Number, top: Number, width: Number, height: Number}
     * @param {Object} options Опции ресайза
     * @param {Boolean} options.fromCenter
     * @param {String} options.direction
     * @param {Object} options.initialBox Бокс до ресайза (до начала драга) {left: Number, top: Number, width: Number, height: Number}
     * @param {Object} options.packBox Бокс группы {left: Number, top: Number, width: Number, height: Number}
     * @param {String} options.baseDimension width или height. Измерение, на которое нужно ориентироваться при пропорциональном ресайзе
     * @param {Number} options.ratio
     * @param {Number} options.aspect
     * @returns {Object}
     */
    makeProportional: function(box, options) {
      var initial = options.initialBox || {};
      var direction = options.direction;
      var fromCenter = options.fromCenter;

      // Одно из измерений основное, сохраняет свое зачение.
      // Дополняющее измерение подстраивается под основное.
      var basic, additional;

      switch (direction) {
        case 'n':
        case 's': // вверх/вниз: высота главнее
          basic = 'height';
          additional = 'width';
          break;
        case 'e':
        case 'w': // таскаем за левые и правый бока: ширина главная
          basic = 'width';
          additional = 'height';
          break;
        default:
          if (box.width / initial.width > box.height / initial.height) {
            // Таскаем за углы: кто сильнее увеличивает, тот и главный
            basic = 'width';
            additional = 'height';
          } else {
            basic = 'height';
            additional = 'width';
          }
          break;
      }
      if (options.baseDimension) {
        basic = options.baseDimension;
        additional = options.baseDimension === 'width' ? 'height' : 'width';
      }

      var ratio = initial[additional] / initial[basic];

      if (options.ratio) {
        ratio = additional == 'width' ? options.ratio : 1 / options.ratio;
      }

      if (options.aspect) {
        ratio = additional == 'width' ? options.aspect : 1 / options.aspect;
      }

      // новое значение дополняющего измерения
      var additional_dimension = Math.round(box[basic] * ratio);

      // коррекция по изменению положения
      if (_.contains(['nw', 'sw', 'w'], direction) && additional == 'width') {
        var delta = additional_dimension - box[additional];
        box.left -= fromCenter ? delta / 2 : delta;
      }

      if (_.contains(['ne', 'se', 'e'], direction) && additional == 'width' && fromCenter) {
        box.left -= (additional_dimension - box[additional]) / 2;
      }

      if (_.contains(['nw', 'ne', 'n'], direction) && additional == 'height') {
        delta = additional_dimension - box[additional];
        box.top -= fromCenter ? delta / 2 : delta;
      }

      if (_.contains(['sw', 'se', 's'], direction) && additional == 'height' && fromCenter) {
        box.top -= (additional_dimension - box[additional]) / 2;
      }

      if (_.contains(['n', 's'], direction) && fromCenter) {
        delta = additional_dimension - box[additional];
        box.left -= delta / 2;
      }

      if (_.contains(['w', 'e'], direction) && fromCenter) {
        delta = additional_dimension - box[additional];
        box.top -= delta / 2;
      }

      box[additional] = additional_dimension;

      return box;
    },

    getRotatedBoxDelta: function(oldBox, newBox, angle, direction) {
      var DOTS_POS_X = BlockFrame.prototype.DOTS_POS_X;
      var DOTS_POS_Y = BlockFrame.prototype.DOTS_POS_Y;
      var FIXED_DOTS = BlockFrame.prototype.FIXED_DOTS;
      var DOTS = BlockFrame.prototype.DOTS;
      var sinAngle = Math.sin(((angle || 0) * Math.PI) / 180);
      var cosAngle = Math.cos(((angle || 0) * Math.PI) / 180);
      // для начала находим точку обратную той за которую мы тянем
      // это будет та точка которая должна оставаться на месте при ресайзе
      var fixedDotInd = _.indexOf(DOTS, FIXED_DOTS[direction]);

      // находим ее положение на экране для НЕ ресайзнутого блока (в неповернутом состоянии и в повернутом)
      var oldPlainX = oldBox.left + oldBox.width * DOTS_POS_X[fixedDotInd],
        oldPlainY = oldBox.top + oldBox.height * DOTS_POS_Y[fixedDotInd],
        oldPlainCX = oldBox.left + oldBox.width / 2, // центр бокса
        oldPlainCY = oldBox.top + oldBox.height / 2,
        oldPlainDX = oldPlainX - oldPlainCX, // находим смещение точки от центра вращения (неповернутой точки)
        oldPlainDY = oldPlainCY - oldPlainY,
        oldRotX = oldPlainCX + cosAngle * oldPlainDX + sinAngle * oldPlainDY, // точка с учетом поворота
        oldRotY = oldPlainCY + sinAngle * oldPlainDX - cosAngle * oldPlainDY;

      // находим ее положение на экране для ресайзнутого блока (в неповернутом состоянии и в повернутом)
      var newPlainX = newBox.left + newBox.width * DOTS_POS_X[fixedDotInd],
        newPlainY = newBox.top + newBox.height * DOTS_POS_Y[fixedDotInd],
        newPlainCX = newBox.left + newBox.width / 2, // центр бокса
        newPlainCY = newBox.top + newBox.height / 2,
        newPlainDX = newPlainX - newPlainCX, // находим смещение точки от центра вращения (неповернутой точки)
        newPlainDY = newPlainCY - newPlainY,
        newRotX = newPlainCX + cosAngle * newPlainDX + sinAngle * newPlainDY, // точка с учетом поворота
        newRotY = newPlainCY + sinAngle * newPlainDX - cosAngle * newPlainDY;

      // смотрим насколько сместился виджет относительно своего расчитанного неповернутого сосвтояния (смотрим конкретную точку fixedDot)
      // и смещаем виджет на эти расстояния, визуально получаем эффект когда точка противоположная ресайзу остается на одном и том же месте
      return {
        left: oldRotX - newRotX,
        top: oldRotY - newRotY,
      };
    },

    /**
     * ceilObject - Округляет все элементы объекта
     *
     * @param  {Object} obj объект
     * @return {Object}
     */
    ceilObject(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = Math.ceil(obj[key]);
        }
      }

      return obj;
    },
  }
);

export default BlockFrame;
