<template>
  <div class="apply-input">
    <input v-model="text" :class="{ 'no-prefix': !prefix }" placeholder="Username" class="input" />
    <div class="input-prefix">{{ prefix }}</div>
    <div v-if="value != text" class="input-control apply" @click.stop="$emit('input', text)"></div>
    <div
      v-else-if="value"
      class="input-control clear"
      @click.stop="
        $emit('input', '');
        text = '';
      "
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },

    prefix: {
      type: String,
      default: '',
    },
  },

  data: function() {
    return {
      text: '',
    };
  },

  mounted: function() {
    this.text = this.value;
  },
};
</script>

<style lang="less">
@import (reference) '../../css/common/fonts.less';
@import (reference) '../../css/common/constants.less';
.apply-input {
  .input {
    .avenir_demi;
    font-size: 14px;
    color: #979ea8;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    line-height: 25px;
    padding: 2px 25px 1px;
    &.no-prefix {
      padding: 2px 26px 1px 8px;
    }

    &::-webkit-input-placeholder {
      color: #979ea8;
      opacity: 0.5;
    }

    &:-moz-placeholder {
      color: #979ea8;
      opacity: 0.5;
    }
  }

  .input-prefix {
    .avenir_demi;
    font-size: 14px;
    color: #979ea8;
    top: 9px;
    position: absolute;
    left: 8px;
  }

  .input-control {
    position: absolute;
    top: 5px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    right: 6px;
    .transition(0.2s, background);

    &.clear {
      background: url('../../img/constructor/panels/twitter/input-delete.png') no-repeat 0 0;

      &:hover {
        background: url('../../img/constructor/panels/twitter/input-delete-hover.png') no-repeat 0 0;
      }
    }

    &.apply {
      background: url('../../img/constructor/panels/twitter/input-go.png') no-repeat 0 0;

      &:hover {
        background: url('../../img/constructor/panels/twitter/input-go-hover.png') no-repeat 0 0;
      }
    }

    &.hidden {
      display: none;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  .apply-input {
    .input-control {
      &.clear {
        background: url('../../img/constructor/panels/twitter/input-delete@2x.png') no-repeat 0 0;
        background-size: 18px 18px;

        &:hover {
          background: url('../../img/constructor/panels/twitter/input-delete-hover@2x.png') no-repeat 0 0;
          background-size: 18px 18px;
        }
      }

      &.apply {
        background: url('../../img/constructor/panels/twitter/input-go@2x.png') no-repeat 0 0;
        background-size: 18px 18px;

        &:hover {
          background: url('../../img/constructor/panels/twitter/input-go-hover@2x.png') no-repeat 0 0;
          background-size: 18px 18px;
        }
      }
    }
  }
}
</style>
